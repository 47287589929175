import {Component, OnDestroy, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';
import {HttpClient} from '@angular/common/http';
import {Clipboard} from '@angular/cdk/clipboard';
import {CurrencyWallet} from '../../../../../../services/user.service';

@Component({
  selector: 'app-deposit-ethereum-desktop',
  templateUrl: './deposit-ethereum-desktop.component.html',
  styleUrl: './deposit-ethereum-desktop.component.css'
})
export class DepositEthereumDesktopComponent implements OnInit, OnDestroy{
  crypto_wallet: CurrencyWallet = {} as CurrencyWallet;

  constructor(public managerService: ManagerService, private httpClient: HttpClient, private clipboard: Clipboard) {
    this.managerService.setUserCryptoWalletList();
  }

  ngOnInit(): void {
    this.crypto_wallet = this.managerService.operationsService.user_crypto_currency_wallet_list[1];
  }

  copyAddress(): void {
    this.clipboard.copy(this.managerService.operationsService.selected_user_currency_wallet.address);
    this.managerService.popSnackBar(true, this.managerService.config.DEPOSIT.COPIED);
  }

  selectCurrencyWallet(crypto_wallet: CurrencyWallet): void {
    if (!this.managerService.operationsService.ethereum_deposit_rules_accepted_status) {
      this.managerService.openModal('ethereum-deposit-rules-modal');
    }
    this.managerService.operationsService.selected_user_currency_wallet = crypto_wallet;
  }

  ngOnDestroy(): void {
    this.managerService.operationsService.crypto_deposit_rules_accepted_status = false;
    // this.subscription_list.forEach((subscription) => {
    //   subscription.unsubscribe();
    // });
  }
}
