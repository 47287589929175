<div class="p-4 d-flex flex-column align-items-center justify-content-center" style="max-width: 320px;">
  <div class="w-100 d-flex align-items-center justify-content-between" style="padding: 0 20px; font-family: Roboto, sans-serif; font-size: 18px; line-height: 1.2; color: #272a3b;">
    {{managerService.config.FORGOT_PASSWORD.HEADING | managerPipe}}
    <svg (click)="managerService.closeModal(modal_id);" style="height: 24px; width: 24px;">
      <use [attr.xlink:href]='"./assets/images/close.svg#close"' fill="#272a3b"/>
    </svg>
  </div>
  <form [formGroup]="forgotPasswordForm" class="d-flex flex-column align-items-center justify-content-center my-4" style="width: 100%; height: fit-content; z-index: 2">
    <div class="w-100 p-3 text-center" style="font-family: Roboto, sans-serif; font-size: 12px; line-height: 1.15; letter-spacing: normal; color: #404352;">
      {{managerService.config.FORGOT_PASSWORD.INFO}}
    </div>
    <div class="d-flex flex-column" style="width: 50%; min-width: 240px;">
      <div class="w-100 d-flex flex-column">
        <span class='input-heading' style="color: #404352">{{managerService.config.LOGIN.E_MAIL | managerPipe}}</span>
        <label>
          <input class="input-field" formControlName="email" placeholder="{{managerService.config.LOGIN.E_MAIL_PLACEHOLDER | managerPipe}}"
                 required style="border-radius: 4px; border: solid 1px #404352; background-color: #ffffff; box-shadow: none;"
                 type="email">
        </label>
      </div>
      <button (click)="forgotPassword()" class="btn-native" id="forgot-password-submit-button">
        {{managerService.config.FORGOT_PASSWORD.SEND | managerPipe}}
      </button>
      <div #warning class="d-none warning" style="margin: 10px 20px 0"></div>
    </div>
  </form>
</div>
