<div class="h-100 w-100 d-flex flex-column align-items-center justify-content-start py-2 p-1">
    <div class="d-flex w-100 flex-column" style="box-shadow: 2px 2px 9px 0 rgba(193, 196, 206, 0.5); background-color: #fcfdff; border-radius: 4px;padding-bottom: 8px">
        <button (click)="this.managerService.navigationManager({url: '/admin-panel', current_component_index: 0});"
                *ngIf="managerService.userService.is_admin | managerPipe"
                [class.navigation-bar-item-active]="managerService.active_navigation_bar_panel | managerPipe: managerService.mpa_template.ACTIVE_NAVIGATION_BAR_PANEL_ADMIN"
                class="navbar-native-item btn-native"
                class="navigation-bar-item btn-native" type="button">
            <svg style="height: 24px; width: 24px; margin: 0 12px;">
                <use [attr.xlink:href]='"./assets/images/admin.svg#admin"' fill="inherit"/>
            </svg>
            {{managerService.config.HEADER.ADMIN | managerPipe}}
        </button>
        <button (click)="managerService.navigationManager({url: '/account/dashboard', current_component_index: 0});"
                [class.navigation-bar-item-active]="managerService.active_navigation_bar_panel | managerPipe: managerService.mpa_template.ACTIVE_NAVIGATION_BAR_PANEL_DASHBOARD"
                class="navigation-bar-item btn-native"
                type="button">
            <svg style="height: 24px; width: 24px; margin: 0 8px">
                <use [attr.xlink:href]='"./assets/images/panel.svg#panel"' fill="var(--text-color-artiox-black, #131515)"/>
            </svg>
            {{managerService.config.HEADER.DASHBOARD | managerPipe}}
        </button>
        <button (click)="managerService.navigationManager({url: '/account/user-info', current_component_index: 0});"
                [class.navigation-bar-item-active]="managerService.active_navigation_bar_panel | managerPipe: managerService.mpa_template.ACTIVE_NAVIGATION_BAR_PANEL_USER_INFORMATION"
                class="navigation-bar-item btn-native"
                type="button">
            <svg style="height: 24px; width: 24px; margin: 0 8px">
                <use [attr.xlink:href]='"./assets/images/user_info.svg#user_info"' fill="var(--text-color-artiox-black, #131515)"/>
            </svg>
            {{managerService.config.HEADER.USER_INFORMATION | managerPipe}}
        </button>
        <button (click)="managerService.navigationManager({url: '/account/balance', current_component_index: 0});"
                [class.navigation-bar-item-active]="managerService.active_navigation_bar_panel | managerPipe: managerService.mpa_template.ACTIVE_NAVIGATION_BAR_PANEL_BALANCE"
                class="navigation-bar-item btn-native"
                type="button">
            <svg style="height: 24px; width: 24px; margin: 0 8px;">
                <use [attr.xlink:href]='"./assets/images/wallet_full.svg#wallet_full"' fill="var(--text-color-artiox-black, #131515)"/>
            </svg>
            {{managerService.config.HEADER.WALLET | managerPipe}}
        </button>
        <button (click)="managerService.navigationManager({url: '/account/deposit', current_component_index: 0});"
                [class.navigation-bar-item-active]="managerService.active_navigation_bar_panel | managerPipe: managerService.mpa_template.ACTIVE_NAVIGATION_BAR_PANEL_DEPOSIT"
                class="navigation-bar-item btn-native"
                type="button">
            <svg style="height: 24px; width: 24px; margin: 0 8px">
                <use [attr.xlink:href]='"./assets/images/deposit.svg#deposit"' fill="var(--text-color-artiox-black, #131515)"/>
            </svg>
            {{managerService.config.HEADER.DEPOSIT | managerPipe}}
        </button>
        <button (click)="managerService.navigationManager({url: '/account/withdrawal', current_component_index: 0});"
                [class.navigation-bar-item-active]="managerService.active_navigation_bar_panel | managerPipe: managerService.mpa_template.ACTIVE_NAVIGATION_BAR_PANEL_WITHDRAWAL"
                class="navigation-bar-item btn-native"
                type="button">
            <svg style="height: 24px; width: 24px; margin: 0 8px;">
                <use [attr.xlink:href]='"./assets/images/withdrawal.svg#withdrawal"' fill="var(--text-color-artiox-black, #131515)"/>
            </svg>
            {{managerService.config.HEADER.WITHDRAW | managerPipe}}
        </button>
        <button (click)="managerService.navigationManager({url: '/account/auction', current_component_index: 0});"
                [class.navigation-bar-item-active]="managerService.active_navigation_bar_panel | managerPipe: managerService.mpa_template.ACTIVE_NAVIGATION_BAR_PANEL_AUCTION"
                class="navigation-bar-item btn-native"
                type="button">
            <svg style="height: 24px; width: 24px; margin: 0 8px">
                <use [attr.xlink:href]='"./assets/images/gavel.svg#gavel"' fill="none"
                     stroke="var(--text-color-artiox-black, #131515)"/>
            </svg>
            {{managerService.config.HEADER.AUCTION | managerPipe}}
        </button>
        <button
            (click)="managerService.navigationManager({url: '/account/security', active_security_panel: managerService.active_security_panel_template.CHANGE_PASSWORD, current_component_index: 0});"
            [class.navigation-bar-item-active]="managerService.active_navigation_bar_panel | managerPipe: managerService.mpa_template.ACTIVE_NAVIGATION_BAR_PANEL_SECURITY"
            class="navigation-bar-item btn-native"
            type="button">
            <svg style="height: 24px; width: 24px; margin: 0 8px;">
                <use [attr.xlink:href]='"./assets/images/security.svg#security"' fill="var(--text-color-artiox-black, #131515)"/>
            </svg>
            {{managerService.config.HEADER.SECURITY | managerPipe}}
        </button>
        <button (click)="managerService.logout()" class="navigation-bar-item btn-native"
                type="button">
            <svg style="height: 24px; width: 24px; margin: 0 8px;">
                <use [attr.xlink:href]='"./assets/images/logout.svg#logout"' fill="var(--text-color-artiox-black, #131515)"/>
            </svg>
            {{managerService.config.HEADER.LOGOUT | managerPipe}}
        </button>
    </div>
</div>
