<app-slider-desktop></app-slider-desktop>
<div style="background-color: #fcfdff;">
<!--  <app-announcement-slider-desktop></app-announcement-slider-desktop>-->

  <div class="w-75 d-flex flex-column align-items-start justify-content-center m-auto p-3">
    <div class="exo header4 medium"
         style="color: #404352; margin-top: 64px; margin-bottom: 32px!important;">
      Artiox Piyasalar</div>
    <div class="w-100 d-flex flex-row align-items-center justify-content-around pl-5 m-1" style="height: 50px; border-radius: 4px; box-shadow: 1px 1px 2px 0 #dbdbdf; background-color: #ffffff;
    font-family: Roboto, sans-serif;font-size: 16px; letter-spacing: 0.6px; text-align: left; color: #595e7e;">
      <span style="min-width: 6%;">{{managerService.config.HOME.PAIR_HEADER.ART_PRODUCT | managerPipe}}</span>
      <span [class.d-none]="managerService.inner_width < 1000" style="min-width: 29%; max-width: 30%"></span>
      <span style="min-width: 17.5%;">{{managerService.config.HOME.PAIR_HEADER.LAST_PRICE | managerPipe}}</span>
      <span style="min-width: 15%;">{{managerService.config.HOME.PAIR_HEADER.CHANGE_24_HOUR | managerPipe}}</span>
      <span [class.d-none]="managerService.inner_width < 1200"
            class="pl-3" style="min-width: 15%;">{{managerService.config.HOME.PAIR_HEADER.MARKET | managerPipe}}</span>
      <span style="min-width: 17.5%;"></span>
    </div>
    <div *ngFor="let pair of (managerService.pair_pipe | managerPipe: managerService.mpa_template.GET_LISTED_PAIR_LIST)"
         class="w-100 d-flex flex-row align-items-center justify-content-around pl-5 m-1 pair-row"
         style="height: 50px; border-radius: 4px; box-shadow: 1px 1px 2px 0 #dbdbdf; font-family: Roboto, sans-serif;font-size: 18px; letter-spacing: 0.6px; text-align: left; color: #595e7e;">
      <span class="font-weight-bold"
            style="min-width: 6%;">{{pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_SYMBOL}}</span>
      <span [class.d-none]="managerService.inner_width < 1000" class="text-left"
            style="min-width: 29%; max-width: 30%">"{{pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_NAME_UPPERCASE: managerService.language_pipe}}"</span>
      <div class="font-weight-bold d-flex flex-row align-items-center justify-content-start" style="min-width: 17.5%;">
        <span class="px-2">{{pair.price | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
        <span
          style="font-family: Roboto, sans-serif; font-size: 10px; letter-spacing: 0.5px; font-weight: 300; color: #9da5b7;">
          {{managerService.pairService.root_currency?.symbol | managerPipe}}
        </span>
      </div>
      <span [style]="pair.change | managerPipe: managerService.mpa_template.PAIR_CHANGE_COLOR" class="font-weight-bold px-2"
            style="min-width: 15%;">
        {{pair.change | managerPipe: managerService.mpa_template.FANCY_NUMBER}}%</span>
      <span [class.d-none]="managerService.inner_width < 1200"
            style="min-width: 15%;">
        <svg height="30px" viewBox="0 0 300 100" xmlns="http://www.w3.org/2000/svg">
          <polyline
            [attr.points]="pair.id | managerPipe: managerService.mpa_template.GET_TRADE_CHART_DATA_SVG | async"
            fill="#b9edf5"
            stroke="#4fc0d2"
            stroke-width="2"/>
        </svg>
      </span>
      <span style="min-width: 17.5%;">
          <button (click)="managerService.exchangeNavigate(pair, 'advanced');"
                  class="w-75 btn-native home-buy-button my-2 mx-auto p-1"
                  style="font-family: Raleway, sans-serif; font-size: 16px; font-weight: bold; letter-spacing: 3.5px; color: #ffffff; border-radius: 18px;">
            {{pair.sale_state | managerPipe: managerService.mpa_template.SALE_STATE_BUTTON: managerService.language_pipe}}
          </button>
      </span>
    </div>
  </div>
  <div class="w-75 d-flex flex-column align-items-start justify-content-center m-auto"
       style="margin-top: 50px!important;">
    <div class="my-4 exo header4 medium px-3"
         style=" color: #404352; margin-bottom: 75px!important;">
      {{managerService.config.HOME.SUMMARY.HEADING_DESKTOP | managerPipe}}</div>
    <div class="w-100 d-flex flex-row flex-wrap align-items-start justify-content-around mb-4"
         style="margin-bottom: 50px!important;">
      <div *ngFor="let topic of managerService.config.HOME.SUMMARY.TOPICS | managerPipe" class="d-flex flex-column"
           style="width: 200px;">
        <img alt="topic" class="mb-3" height="100px" width="200px" src="{{topic.SRC | managerPipe}}">
        <p style="font-family: Roboto ,sans-serif; font-size: 16px; text-align: center; color: #404352;">
          {{topic.TEXT | managerPipe}}
        </p>
      </div>
    </div>
  </div>
</div>
