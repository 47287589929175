import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';
import {environment} from '../../environments/environment';

export class ArtProduct {
  public id: number;
  public name: string;
  public symbol: string;
  public value: number;
  public value_type: string;
  public pre_sale_supply_ratio: number;
  public pre_sale_circulating_supply: number;
  public pre_sale_supply_stock: number;
  public public_sale_supply_ratio: number;
  public public_sale_circulating_supply: number;
  public public_sale_supply_stock: number;
  public contract_address: string;
  public circulating_supply: number;
  public circulating_supply_ratio: number;
  public total_supply: number;
  public creation_time: number;
  public color: [string, string];
  public status: string;

  constructor(art_product_primitive: any) {
    this.id = art_product_primitive.id;
    this.name = art_product_primitive.name;
    this.symbol = art_product_primitive.symbol;
    this.value = art_product_primitive.value;
    this.value_type = art_product_primitive.value_type;

    this.pre_sale_supply_ratio = art_product_primitive.pre_sale_supply_ratio;
    this.pre_sale_circulating_supply = art_product_primitive.pre_sale_circulating_supply;
    this.pre_sale_supply_stock = art_product_primitive.pre_sale_supply_stock;

    this.public_sale_supply_ratio = art_product_primitive.public_sale_supply_ratio;
    this.public_sale_circulating_supply = art_product_primitive.public_sale_circulating_supply;
    this.public_sale_supply_stock = art_product_primitive.public_sale_supply_stock;

    this.total_supply = art_product_primitive.total_supply;
    this.circulating_supply_ratio = art_product_primitive.circulating_supply_ratio;
    this.circulating_supply = art_product_primitive.circulating_supply;

    this.contract_address = art_product_primitive.contract_address;
    this.creation_time = art_product_primitive.creation_time / 1000000;
    this.color = art_product_primitive.color;
    this.status = art_product_primitive.status;
  }
}

export class Currency {
  static empty_currency = new Currency({
    id: -1,
    name: '',
    symbol: '',
    value: 0,
    value_type: '',
    stable: false,
    color: ['#000000', '#000000'],
    root: false,
    type: '',
    contract_address: '',
    abi: '',
    decimals: 0,
    supply_stock: 0,
    block_chain: ''
  });

  public id: number;
  public name: string;
  public symbol: string;
  public value: number;
  public value_type: string;
  public stable: boolean;
  public color: [string, string];
  public root: boolean;
  public type: string = '';
  public contract_address: string = '';
  public abi: string = '';
  public decimals: number;
  public supply_stock: number;
  public block_chain: string;
  public kind: string;

  constructor(currency_primitive: any) {
    this.id = currency_primitive.id;
    this.name = currency_primitive.name;
    this.symbol = currency_primitive.symbol;
    this.value = currency_primitive.value;
    this.value_type = currency_primitive.value_type;
    this.stable = currency_primitive.stable;
    this.color = currency_primitive.color;
    this.root = currency_primitive.root;
    this.type = currency_primitive.type;
    this.contract_address = currency_primitive.contract_address;
    this.abi = currency_primitive.abi;
    this.decimals = currency_primitive.decimals;
    this.supply_stock = currency_primitive.supply_stock;
    this.block_chain = currency_primitive.block_chain;
    this.kind = currency_primitive.kind;
  }
}

export class Pair {
  public id: [number, number];
  public symbol: string;
  public price: number;

  public pre_sale_price: number;
  public pre_sale_start_date: number;
  public pre_sale_end_date: number;

  public public_sale_price: number;
  public public_sale_start_date: number;
  public public_sale_end_date: number;

  public listing_date: number;

  public high: number;
  public low: number;
  public change: number;
  public volume: number;
  public featured: boolean;
  public sale_state: 'announcement' | 'featuring' | 'pre_sale' | 'public_sale' | 'listing' | 'delisted';

  constructor(pair_primitive: any) {
    this.id = pair_primitive.id;
    this.symbol = pair_primitive.symbol;
    this.price = pair_primitive.price;

    this.pre_sale_price = pair_primitive.pre_sale_price;
    this.pre_sale_start_date = pair_primitive.pre_sale_start_date / 1000000;
    this.pre_sale_end_date = pair_primitive.pre_sale_end_date / 1000000;

    this.public_sale_price = pair_primitive.public_sale_price;
    this.public_sale_start_date = pair_primitive.public_sale_start_date / 1000000;
    this.public_sale_end_date = pair_primitive.public_sale_end_date / 1000000;

    this.listing_date = pair_primitive.listing_date / 1000000;

    this.high = pair_primitive.high;
    this.low = pair_primitive.low;
    this.change = pair_primitive.change;
    this.volume = pair_primitive.volume;
    this.featured = pair_primitive.featured;
    this.sale_state = pair_primitive.sale_state;
  }

  update(pair: Pair): void {
    this.price = pair.price;
    this.high = pair.high;
    this.low = pair.low;
    this.change = pair.change;
    this.volume = pair.volume;
    this.sale_state = pair.sale_state;
  }
}

export class CurrencyPair {
  public id: [number, number];
  public symbol: string;
  public price: number;

  constructor(currency_pair_primitive: any) {
    this.id = currency_pair_primitive.id;
    this.symbol = currency_pair_primitive.symbol;
    this.price = currency_pair_primitive.price;
  }
}

export class AnnouncementArtProduct {
  public id: number;
  public name: string;
  public symbol: string;
  public value: number;
  public value_type: string;
  public pre_sale_supply_ratio: number;
  public pre_sale_circulating_supply: number;
  public pre_sale_supply_stock: number;
  public public_sale_supply_ratio: number;
  public public_sale_circulating_supply: number;
  public public_sale_supply_stock: number;
  public contract_address: string;
  public circulating_supply: number;
  public circulating_supply_ratio: number;
  public total_supply: number;
  public creation_time: number;
  public color: [string, string];
  public status: string;

  constructor() {
    this.id = -1;
    this.name = 'New Art Product';
    this.symbol = 'NEW';
    this.value = -1;
    this.value_type = '';

    this.pre_sale_supply_ratio = -1;
    this.pre_sale_circulating_supply = -1;
    this.pre_sale_supply_stock = -1;

    this.public_sale_supply_ratio = -1;
    this.public_sale_circulating_supply = -1;
    this.public_sale_supply_stock = -1;

    this.total_supply = -1;
    this.circulating_supply_ratio = -1;
    this.circulating_supply = -1;

    this.contract_address = '';
    this.creation_time = 0;
    this.color = ['000000', '000000'];
    this.status = 'active';
  }
}

export class AnnouncementPair {
  public id: [number, number];
  public symbol: string;
  public price: number;

  public pre_sale_price: number;
  public pre_sale_start_date: number;
  public pre_sale_end_date: number;

  public public_sale_price: number;
  public public_sale_start_date: number;
  public public_sale_end_date: number;

  public listing_date: number;

  public high: number;
  public low: number;
  public change: number;
  public volume: number;
  public featured: boolean;
  public sale_state: 'announcement' | 'featuring' | 'pre_sale' | 'public_sale' | 'listing' | 'delisted';

  constructor() {
    this.id = [-1, -1];
    this.symbol = '';
    this.price = -1;

    this.pre_sale_price = -1;
    this.pre_sale_start_date = 0;
    this.pre_sale_end_date = 0;

    this.public_sale_price = -1;
    this.public_sale_start_date = 0;
    this.public_sale_end_date = 0;

    this.listing_date = 0;

    this.high = -1;
    this.low = -1;
    this.change = -1;
    this.volume = -1;
    this.featured = true;
    this.sale_state = 'announcement';
  }

  update(pair: Pair): void {
    this.price = pair.price;
    this.high = pair.high;
    this.low = pair.low;
    this.change = pair.change;
    this.volume = pair.volume;
    this.sale_state = pair.sale_state;
  }

}

export enum PairListSortingTypeTemplate {
  PAIR,
  PRICE,
  CHANGE,
  FAVORITE
}

export enum PairListSortingDirectionTemplate {
  DOWN,
  UP
}

@Injectable({
  providedIn: 'root'
})
export class PairService {

  public art_product_list_ready: boolean = false;
  public currency_list_ready: boolean = false;
  public pair_list_ready: boolean = false;
  public currency_pair_list_ready: boolean = false;

  public active_pair_ready: boolean = false;
  public active_featured_pair_ready: boolean = false;
  public active_currency_pair_ready: boolean = false;

  public active_featured_pair_pipe: boolean = false;
  public currency_list_pipe: boolean = false;
  public currency_pair_list_pipe: boolean = false;

  @Output() active_pair_changed: EventEmitter<any> = new EventEmitter();
  @Output() active_featured_pair_changed: EventEmitter<any> = new EventEmitter();
  @Output() active_currency_pair_changed: EventEmitter<any> = new EventEmitter();
  @Output() active_pair_selected: EventEmitter<any> = new EventEmitter();
  @Output() active_featured_pair_selected: EventEmitter<any> = new EventEmitter();
  @Output() active_currency_pair_selected: EventEmitter<any> = new EventEmitter();
  @Output() art_product_list_changed: EventEmitter<any> = new EventEmitter();
  @Output() currency_list_changed: EventEmitter<any> = new EventEmitter();
  @Output() pair_list_changed: EventEmitter<any> = new EventEmitter();
  @Output() currency_pair_list_changed: EventEmitter<any> = new EventEmitter();

  public art_product_list: ArtProduct[] = [];
  public currency_list: Currency[] = [];
  public pair_list: Pair[] = [];
  public currency_pair_list: CurrencyPair[] = [];

  public active_pair: Pair | undefined = undefined;
  public active_featured_pair: Pair | undefined = undefined;
  public active_currency_pair: CurrencyPair | undefined = undefined;

  public root_currency: Currency | undefined = undefined;

  public announcement_art_product = new AnnouncementArtProduct();
  public announcement_pair = new AnnouncementPair();
  public announcement_active: boolean = false;

  public environment = environment;

  constructor(private httpClient: HttpClient) {
  }

  getArtProductList(): ArtProduct[] {
    return this.art_product_list;
  }

  setArtProductList(art_product_list_primitive: any[]): void {
    this.art_product_list = art_product_list_primitive.map(x => new ArtProduct(x));
  }

  getArtProductById(id: number): ArtProduct | undefined {
    for (const art_product of this.art_product_list) {
      if (art_product.id === id) {
        return art_product;
      }
    }
    return undefined;
  }

  updateArtProduct(_art_product: ArtProduct): void {
    for (let i = 0; i < this.art_product_list.length; i++) {
      if (this.art_product_list[i].id === _art_product.id) {
        this.art_product_list[i] = _art_product;
        return;
      }
    }
  }

  getCurrencyList(): Currency[] {
    return this.currency_list;
  }

  setCurrencyList(currency_list_primitive: any[]): void {
    this.currency_list = currency_list_primitive.map(x => new Currency(x));
    this.currency_list_pipe = !this.currency_list_pipe;
    this.currency_list_changed.emit();
  }

  getCurrencyById(id: number): Currency | undefined {
    for (const currency of this.currency_list) {
      if (currency.id === id) {
        return currency;
      }
    }
    return undefined;
  }

  updateCurrency(_currency: Currency): void {
    for (let i = 0; i < this.currency_list.length; i++) {
      if (this.currency_list[i].id === _currency.id) {
        this.currency_list[i] = _currency;
        return;
      }
    }
  }

  getPairList(): Pair[] {
    return this.pair_list;
  }

  setPairList(pair_list_primitive: any[]): void {
    this.pair_list = pair_list_primitive.map(x => new Pair(x));
  }

  getPairById(id: [number, number]): Pair | undefined {
    for (const pair of this.pair_list) {
      if (pair.id[0] === id[0] && pair.id[1] === id[1]) {
        return pair;
      }
    }
    return undefined;
  }

  getPairBySymbol(symbol: string): Pair | undefined {
    for (const pair of this.pair_list) {
      if (pair.symbol === symbol) {
        return pair;
      }
    }
    return undefined;
  }

  getListedPairList(): Pair[] {
    return this.pair_list.filter(x => x.sale_state === 'listing');
  }

  getNotListedPairList(): Pair[] {
    return this.pair_list.filter(x => x.sale_state !== 'listing' && x.sale_state !== 'delisted');
  }

  getNotDelistedPairList(): Pair[] {
    return this.pair_list.filter(x => x.sale_state !== 'delisted');
  }

  getFeaturedPairList(): Pair[] {
    return this.pair_list.filter(x => x.featured).slice().reverse();
  }

  getActivePair(): Pair | undefined {
    return this.active_pair;
  }

  setActivePair(pair: Pair): void {
    this.active_pair = pair;
    this.active_pair_changed.emit();
  }

  updateActivePair(pair: Pair): void {
    this.active_pair?.update(pair);
    this.active_pair_changed.emit();
  }

  getActiveFeaturedPair(): Pair | undefined {
    return this.active_featured_pair;
  }

  setActiveFeaturedPair(pair: Pair): void {
    this.active_featured_pair = pair;
    this.active_featured_pair_changed.emit();
    this.active_featured_pair_pipe = !this.active_featured_pair_pipe;
  }

  updateActiveFeaturedPair(pair: Pair): void {
    this.active_featured_pair?.update(pair);
    this.active_featured_pair_changed.emit();
  }

  updatePair(_pair: Pair): void {
    if (this.active_pair !== undefined && this.active_pair.id[0] === _pair.id[0] && this.active_pair.id[1] === _pair.id[1]) {
      this.setActivePair(_pair);
    }
    if (this.active_featured_pair !== undefined && this.active_featured_pair.id[0] === _pair.id[0] && this.active_featured_pair.id[1] === _pair.id[1]) {
      this.updateActiveFeaturedPair(_pair);
    }

    for (let i = 0; i < this.pair_list.length; i++) {
      if (this.pair_list[i].id[0] === _pair.id[0] && this.pair_list[i].id[1] === _pair.id[1]) {
        this.pair_list[i] = _pair;
        return;
      }
    }
  }

  checkActivePair(_pair: Pair): boolean {
    return this.active_pair !== undefined && this.active_pair.id[0] === _pair.id[0] && this.active_pair.id[1] === _pair.id[1];
  }

  checkActiveFeaturedPair(_pair: Pair): boolean {
    return this.active_featured_pair !== undefined && this.active_featured_pair.id[0] === _pair.id[0] && this.active_featured_pair.id[1] === _pair.id[1];
  }

  getCurrencyPairList(): CurrencyPair[] {
    return this.currency_pair_list;
  }

  setCurrencyPairList(currency_pair_list_primitive: any[]): void {
    this.currency_pair_list = currency_pair_list_primitive.map(x => new CurrencyPair(x));
    this.currency_pair_list_pipe = !this.currency_pair_list_pipe;
  }

  getCurrencyPairById(id: [number, number]): CurrencyPair | undefined {
    for (const currency_pair of this.currency_pair_list) {
      if (currency_pair.id[0] === id[0] && currency_pair.id[1] === id[1]) {
        return currency_pair;
      }
    }
    return undefined;
  }

  getActiveCurrencyPair(): CurrencyPair | undefined {
    return this.active_currency_pair;
  }

  setActiveCurrencyPair(currency_pair: CurrencyPair): void {
    this.active_currency_pair = currency_pair;
    this.active_currency_pair_changed.emit();
    this.currency_pair_list_pipe = !this.currency_pair_list_pipe;
  }

  updateCurrencyPair(_currency_pair: CurrencyPair): void {

    if (this.active_currency_pair !== undefined && this.active_currency_pair.id[0] === _currency_pair.id[0] && this.active_currency_pair.id[1] === _currency_pair.id[1]) {
      this.setActiveCurrencyPair(_currency_pair);
    }

    for (let i = 0; i < this.currency_pair_list.length; i++) {
      if (this.currency_pair_list[i].id[0] === _currency_pair.id[0] && this.currency_pair_list[i].id[1] === _currency_pair.id[1]) {
        this.currency_pair_list[i] = _currency_pair;
        return;
      }
    }

  }

  refreshArtProductList(resolve?: any): void {
    this.httpClient.get<any[]>(this.environment.serverAPI + 'get_art_products_service').subscribe(
      response => {
        this.setArtProductList(response);
        this.art_product_list_ready = true;
        if (this.announcement_active) {
          this.art_product_list.push(this.announcement_art_product);
        }
        this.art_product_list_changed.emit();
        if (resolve !== undefined) {
          resolve();
        }
      });
  }

  refreshCurrencyList(resolve?: any): void {
    this.httpClient.get<any[]>(this.environment.serverAPI + 'get_currencies_service').subscribe(
      response => {
        this.setCurrencyList(response);
        this.root_currency = this.currency_list[0];
        this.currency_list_ready = true;
        if (resolve !== undefined) {
          resolve();
        }
      });
  }

  getLastNotListedPair(): Pair {
    const active_featured_pair_indexer = this.getFeaturedPairList().filter(x => x.sale_state !== 'listing' && x.sale_state !== 'delisted').length;
    return this.getFeaturedPairList()[active_featured_pair_indexer ? active_featured_pair_indexer - 1 : 0];
  }

  refreshPairList(resolve?: any): void {
    this.httpClient.get<any[]>(this.environment.serverAPI + 'get_pairs_service').subscribe(
      response => {
        this.setPairList(response);
        if (this.getActivePair() === undefined && this.pair_list.length > 0) {
          this.setActivePair(this.getListedPairList()[this.getListedPairList().length - 1]);
          this.active_pair_selected.emit();
        }
        if (this.getActiveFeaturedPair() === undefined && this.pair_list.length > 0) {
          const active_featured_pair_indexer = this.getFeaturedPairList().filter(x => x.sale_state !== 'listing' && x.sale_state !== 'delisted').length;
          this.setActiveFeaturedPair(this.getFeaturedPairList()[active_featured_pair_indexer ? active_featured_pair_indexer - 1 : 0]);
          this.active_featured_pair_selected.emit();
        } else if (this.getActiveFeaturedPair() !== undefined && this.getActiveFeaturedPair()?.sale_state === 'listing') {
          const active_featured_pair_indexer = this.getFeaturedPairList().filter(x => x.sale_state !== 'listing' && x.sale_state !== 'delisted').length;
          this.setActiveFeaturedPair(this.getFeaturedPairList()[active_featured_pair_indexer ? active_featured_pair_indexer - 1 : 0]);
          this.active_featured_pair_selected.emit();
        }
        this.pair_list_ready = true;
        this.active_pair_ready = true;
        this.active_featured_pair_ready = true;
        if (this.announcement_active) {
          this.pair_list.push(this.announcement_pair);
        }
        this.pair_list_changed.emit();
        if (resolve !== undefined) {
          resolve();
        }
      });
  }

  refreshCurrencyPairList(resolve?: any): void {
    this.httpClient.get<any[]>(this.environment.serverAPI + 'get_currency_pairs_service').subscribe(
      response => {
        this.setCurrencyPairList(response);
        if (this.getActiveCurrencyPair() === undefined && this.currency_pair_list.length > 0) {
          this.setActiveCurrencyPair(this.currency_pair_list[this.currency_pair_list.length - 1]);
          this.active_currency_pair_selected.emit();
        }
        this.currency_pair_list_ready = true;
        this.active_currency_pair_ready = true;
        this.currency_pair_list_changed.emit();
        if (resolve !== undefined) {
          resolve();
        }
      });
  }

  refreshArtProduct(art_product_id: number, resolve?: any): void {
    const data = {art_product_id};
    this.httpClient.post<any>(this.environment.serverAPI + 'get_art_product_service', data).subscribe(
      response => {
        this.updateArtProduct(new ArtProduct(response));
        if (resolve !== undefined) {
          resolve();
        }
      });
  }

  refreshCurrency(currency_id: number, resolve?: any): void {
    const data = {currency_id};
    this.httpClient.post<any>(this.environment.serverAPI + 'get_currency_service', data).subscribe(
      response => {
        this.updateCurrency(new Currency(response));
        if (resolve !== undefined) {
          resolve();
        }
      });
  }

  refreshPair(pair_id: [number, number], resolve?: any): void {
    const data = {pair_id};
    this.httpClient.post<any>(this.environment.serverAPI + 'get_pair_service', data).subscribe(
      response => {
        this.updatePair(new Pair(response));
        if (resolve !== undefined) {
          resolve();
        }
      });
  }

  refreshCurrencyPair(currency_pair_id: [number, number], resolve?: any): void {
    const data = {currency_pair_id};
    this.httpClient.post<any>(this.environment.serverAPI + 'get_currency_pair_service', data).subscribe(
      response => {
        this.updateCurrencyPair(new CurrencyPair(response));
        if (resolve !== undefined) {
          resolve();
        }
      });
  }
}
