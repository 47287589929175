<div class="d-flex flex-column position-relative w-100 h-100 px-4" >
  <div class="d-flex justify-content-start mt-3">
    <svg (click)="this.managerService.previousPageNavigation()" style=" height: 24px; width: 24px;">
      <use [attr.xlink:href]='"./assets/images/v2/ic_left.svg#ic_left"' stroke="#131515"/>
    </svg>
  </div>
  <div class="d-flex justify-content-center h-100 w-100">
    <div class="d-flex flex-column justify-content-center" >
      <span class="mb-2 header3 medium exo" style="color: var(--text-color-artiox-black) ">{{managerService.config.SIGN_UP.CREATE_PASSWORD}}</span>
      <span class="caption regular barlow" style="color: var(--text-color-artiox-grey); margin-bottom: 32px">{{managerService.config.SIGN_UP.CREATE_PASSWORD_TEXT}}</span>

      <div class="d-flex flex-row " [formGroup]="changePasswordForm" style="">
        <div class="d-flex form-group focused ">
          <label class=" d-flex form-label caption barlow medium position-relative" for="password">
            {{managerService.config.SIGN_UP.PASSWORD | managerPipe}}
            <svg (click)="password_again_visible = !password_again_visible" class="password-eye" style="height: 24px; width: 24px; cursor: pointer" >
              <use *ngIf="(!password_again_visible | managerPipe)"  [attr.xlink:href]='"./assets/images/v2/ic_eye_close.svg#ic_eye_close"' />
              <use *ngIf="(password_again_visible | managerPipe)" [attr.xlink:href]='"./assets/images/v2/ic_eye_open.svg#ic_eye_open"'/>
            </svg>
          </label>

          <input #password_input class="w-100 form-input body2 barlow regular" formControlName="new_password" id="password"
                 [type]="(password_again_visible | managerPipe) ? 'text': 'password'" placeholder="{{managerService.config.LOGIN.PASSWORD_PLACEHOLDER}}" [maxLength]="50">
          <div  #password_error  class="d-flex d-none flex-row " style="gap: 8px; margin-top: 12px" >
            <svg style=" height: 16px; width: 16px; ">
              <use [attr.xlink:href]='"./assets/images/error_icon.svg#error_icon"' fill="#EA3943"/>
            </svg>
            <div  class="caption barlow regular"  style="color: var(--secondary-colors-artiox-red-base)"></div>
          </div>
          <!--                        rezil bi implementation -->
          <div   *ngFor="let n of [2014,2015,2016,2017]; index as i " style="margin-top: 12px; gap: 4px">
            <div  #password_pattern_error class="d-flex d-none flex-row" style="gap: 8px;"  >
              <svg class="warning-checkbox d-flex justify-content-center align-items-center " style=" height: 16px; width: 16px; ">
                <use *ngIf="password_pattern_error.classList.contains('pattern-error')" [attr.xlink:href]='"./assets/images/checkbox_error.svg#checkbox_error"' stroke="#EA3943"/>
                <use *ngIf="password_pattern_error.classList.contains('pattern-success')" [attr.xlink:href]='"./assets/images/checkbox.svg#checkbox"' fill="#66BB6A"/>
              </svg>
              <div  class="caption barlow regular text">{{(managerService.config.RESPONSE_TABLE | managerPipe :managerService.mpa_template.RETURN_INDEX: n).message}}</div>
            </div>
          </div>
        </div>



      </div>
      <button (click)="changePassword()" #submit_button type="button" class="w-100  d-flex justify-content-center py-2 login-button mt-5" style="border-width: 0" disabled >
        <span class="body2 exo " style="color:#FFFFFF; font-weight: 600">{{managerService.config.SIGN_UP.COMPLETE | managerPipe}}</span>
      </button>
    </div>
  </div >

</div>