import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
  selector: 'app-commissions-mobile',
  templateUrl: './commissions-mobile.component.html',
  styleUrls: ['./commissions-mobile.component.css']
})
export class CommissionsMobileComponent implements OnInit {

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

}
