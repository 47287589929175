import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AuctionProduct} from '../../../../../services/auction.service';
import {Subscription} from 'rxjs';
import {ManagerService} from '../../../../../services/manager.service';
import {BillingAddress, Delivery, DeliveryAddress, DeliveryType} from '../../../../../services/user.service';
import {first} from 'rxjs/operators';

@Component({
    selector: 'app-auction-payment-page-mobile',
    templateUrl: './auction-payment-mobile.component.html',
    styleUrls: ['./auction-payment-mobile.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AuctionPaymentMobileComponent implements OnInit, OnDestroy {
    auction_product: AuctionProduct;

    delivery_address_count: number;
    billing_address_count: number;

    selected_delivery_address: DeliveryAddress | undefined;
    selected_billing_address: BillingAddress | undefined;

    user_deliveries: Delivery[];
    // @ts-ignore
    selected_user_delivery: Delivery;

    readonly DeliveryType = DeliveryType;
    selected_delivery_type: DeliveryType = DeliveryType.Cargo;

    subscription_list: (Subscription | undefined)[] = [];

    // @ts-ignore
    delivery_fetched = false;

    constructor(public managerService: ManagerService) {
        this.auction_product = this.managerService.auctionService.active_auction_product;

        this.delivery_address_count = this.managerService.userService.user_info?.delivery_addresses?.length || 0;
        this.billing_address_count = this.managerService.userService.user_info?.billing_information?.length || 0;
        this.subscription_list.push(this.managerService.userService.user_info_changed.subscribe(() => {
            this.delivery_address_count = this.managerService.userService.user_info?.delivery_addresses?.length || 0;
            this.billing_address_count = this.managerService.userService.user_info?.billing_information?.length || 0;
        }));

        this.user_deliveries = this.managerService.userService.getUserDeliveries();

        if (this.user_deliveries){
            const delivery = this.user_deliveries.find(deliveryInfo => deliveryInfo.auction_product_id === this.auction_product.id);
            if(delivery) {
                this.selected_user_delivery = delivery;

                if (this.selected_user_delivery.status !== 'pending') {
                    this.selected_delivery_type = this.selected_user_delivery.type === 'cargo' ? DeliveryType.Cargo : DeliveryType.HandDelivery;
                    if (this.selected_user_delivery.delivery_address.address_id !== -1) {
                        this.selected_delivery_address = this.selected_user_delivery.delivery_address;
                    } else {
                        this.selected_delivery_address = DeliveryAddress.artiox_delivery_address;
                    }
                    this.selected_billing_address = this.selected_user_delivery.billing_address;
                }
                this.delivery_fetched = true

            }
            else{
                this.managerService.navigationManager({url: '/account/auction', current_component_index: 0});
            }
        }
        else {
            // @ts-ignore
            this.subscription_list.push(this.managerService.userService.user_deliveries_changed.pipe(first()).subscribe(() => {
                this.user_deliveries = this.managerService.userService.getUserDeliveries();
                const delivery = this.user_deliveries.find(deliveryInfo => deliveryInfo.auction_product_id === this.auction_product.id);
                if (delivery) {
                    this.selected_user_delivery = delivery;

                    if (this.selected_user_delivery.status !== 'pending') {
                        this.selected_delivery_type = this.selected_user_delivery.type === 'cargo' ? DeliveryType.Cargo : DeliveryType.HandDelivery;
                        if (this.selected_user_delivery.delivery_address.address_id !== -1) {
                            this.selected_delivery_address = this.selected_user_delivery.delivery_address;
                        } else {
                            this.selected_delivery_address = DeliveryAddress.artiox_delivery_address;
                        }
                        this.selected_billing_address = this.selected_user_delivery.billing_address;
                    }
                    this.delivery_fetched = true;
                } else {
                    this.managerService.navigationManager({url: '/account/auction', current_component_index: 0});
                }
            }));
        }
    }

    ngOnInit(): void {
    }

    completePayment(): void {
        if (this.selected_delivery_address !== undefined && this.selected_billing_address !== undefined) {
            this.managerService.userService.selected_delivery_id = this.selected_user_delivery.delivery_id;
            this.managerService.userService.selected_delivery_type = this.selected_delivery_type;
            this.managerService.userService.selected_delivery_address_id = this.selected_delivery_address.address_id;
            this.managerService.userService.selected_billing_address_id = this.selected_billing_address.address_id;

            this.managerService.openModal('approve-payment-modal');
        } else {
            // @ts-ignore
            const content = this.managerService.config.RESPONSE_TABLE[2012];
            this.managerService.popSnackBar(content.is_okay, content.message);
        }
    }

    toggleEdit(div: HTMLDivElement): void {
        if (div.style.display === 'block') {
            div.style.display = 'none';
        } else {
            div.style.display = 'block';
        }
    }

    ngOnDestroy(): void {
        this.subscription_list.forEach((subscription) => {
            subscription?.unsubscribe();
        });
    }
}
