<div class="d-flex flex-column w-100" style="background-color: #fcfdff;">
  <div class="position-relative py-2 pl-3" style="color: #272a3b;">
        <span style="font-family: Roboto, sans-serif; font-size: 12px; letter-spacing: 0.35px;">
          {{managerService.config.BALANCE.TOTAL_BALANCE | managerPipe}}
        </span>
    <div style="font-family: 'Roboto Condensed', sans-serif; font-size: 18px; letter-spacing: 0.9px;">
        <span>
          {{!balance_visible ? ('***' | managerPipe) :
          (managerService.user_pipe | managerPipe: managerService.mpa_template.GET_USER_TOTAL_ART_PRODUCT_TYPE_OF_CURRENCY: managerService.pair_pipe: managerService.currency_pair_pipe) + (managerService.user_pipe | managerPipe: managerService.mpa_template.GET_USER_TOTAL_CURRENCY_TYPE_OF_CURRENCY: managerService.pair_pipe: managerService.currency_pair_pipe) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
          ₺
        </span>
      <svg (click)="balance_visible = !balance_visible" class="password-eye">
        <use *ngIf="(!balance_visible | managerPipe)" [attr.xlink:href]='"./assets/images/eye_hidden.svg#eye_hidden"' fill="#9DA5B7"/>
        <use *ngIf="(balance_visible | managerPipe)" [attr.xlink:href]='"./assets/images/eye_visible.svg#eye_visible"' fill="#9DA5B7"/>
      </svg>
    </div>
  </div>

  <div class="mx-3">
    <div *ngFor="let user_asset of (managerService.user_pipe | managerPipe: managerService.mpa_template.GET_USER_FIAT_CURRENCY_LIST: managerService.pair_pipe)" class="my-2">
      <div (click)="more.classList.toggle('d-none')"
           class="position-relative d-flex flex-row justify-content-between align-items-center"
           style="height: 41px; font-family: Roboto, sans-serif; border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; color: var(--artiox-blue); background-color: #ffffff;">
        <div class="px-3" style="font-size: 13px; letter-spacing: 0.4px;">{{user_asset.currency_id | managerPipe: managerService.mpa_template.GET_CURRENCY_NAME_BY_ID : managerService.language_pipe}}</div>
        <div class="pr-3" style="font-size: 12px; color: #404352;">
          {{!balance_visible ? ('***' | managerPipe) : (user_asset.total_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER)}}
          {{managerService.pairService.currency_list_pipe | managerPipe: managerService.mpa_template.ROOT_CURRENCY_SYMBOL}}
        </div>
      </div>

      <div #more class="d-none" style="border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; margin-top: -20px; padding-top: 20px; background-color: #ffffff;">
        <div class="d-flex flex-column justify-content-start align-items-center"
             style="border-radius: 4px;">
          <div class="w-100">
            <div class="w-100 d-flex flex-row align-items-start justify-content-start px-3 pt-2 pb-1"
                 style="font-family: Roboto, sans-serif; font-size: 11px; color: #272a3b;">
              <div class="text-left" style="width: 25%">{{managerService.config.BALANCE.SYMBOL | managerPipe}}</div>
              <div class="text-right" style="width: 25%">{{managerService.config.BALANCE.AVAILABLE_AMOUNT | managerPipe}}</div>
              <div class="text-right" style="width: 25%">{{managerService.config.BALANCE.IN_ORDER | managerPipe}}</div>
              <div class="text-right" style="width: 25%">{{managerService.config.BALANCE.TOTAL_AMOUNT | managerPipe}}</div>
            </div>
            <div class="user-asset">
              <div class="w-100 d-flex flex-row align-items-center justify-content-center px-3 py-2 "
                   style="font-family: Roboto, sans-serif; font-size: 11px; color: #272a3b;">
                  <span class="text-left ml-1"
                        style="width: 25%; color: #4a4e70; font-weight: 500; letter-spacing: 0.7px;">{{user_asset | managerPipe: managerService.mpa_template.GET_USER_ASSETS_SYMBOL}}</span>
                <span class="text-right"
                      style="width: 25%">{{user_asset.available_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
                <span class="text-right"
                      style="width: 25%">{{user_asset| managerPipe: managerService.mpa_template.USER_INORDER_AMOUNT: managerService.user_open_orders_history_pipe | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
                <span class="text-right"
                      style="width: 25%">{{user_asset | managerPipe: managerService.mpa_template.GET_USER_ASSETS_VALUE : managerService.pair_pipe: managerService.currency_pair_pipe}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngFor="let user_asset of (managerService.user_pipe | managerPipe: managerService.mpa_template.GET_USER_CRYPTO_CURRENCY_LIST: managerService.pair_pipe)" class="my-2">
      <div (click)="openCryptoCurrencyDetails(crypto_currency)"
           class="position-relative d-flex flex-row justify-content-between align-items-center"
           style="height: 41px; font-family: Roboto, sans-serif; border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; color: var(--artiox-blue); background-color: #ffffff;">
        <div class="px-3" style="font-size: 13px; letter-spacing: 0.4px;">{{user_asset.currency_id | managerPipe: managerService.mpa_template.GET_CURRENCY_NAME_BY_ID : managerService.language_pipe}}</div>
        <div class="pr-3" style="font-size: 12px; color: #404352;">
          {{!balance_visible ? ('***' | managerPipe) : (user_asset.total_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER_DECIMAL: (user_asset.currency_id === 2 ? 6 : 2))}}
          {{user_asset.currency_id | managerPipe: managerService.mpa_template.GET_CURRENCY_SYMBOL_BY_ID}}
        </div>
      </div>

      <div #crypto_currency class="d-none w-100 d-flex flex-column justify-content-between align-items-center" style="border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; margin-top: -20px; padding-top: 20px; background-color: #ffffff;"
           [style]="(crypto_currency.offsetTop | managerPipe: managerService.mpa_template.CALLBACK: getFillingHeight : crypto_currency.offsetTop) | managerPipe: managerService.mpa_template.SET_HEIGHT">
        <div class="w-100 d-flex flex-column justify-content-start align-items-center"
             style="border-radius: 4px;">
          <div class="w-100">
            <div class="w-100 d-flex flex-row align-items-start justify-content-start px-3 pt-2 pb-1"
                 style="font-family: Roboto, sans-serif; font-size: 11px; color: #272a3b;">
              <div class="text-left" style="width: 25%">{{managerService.config.BALANCE.SYMBOL | managerPipe}}</div>
              <div class="text-right" style="width: 25%">{{managerService.config.BALANCE.AVAILABLE_AMOUNT | managerPipe}}</div>
              <div class="text-right" style="width: 25%">{{managerService.config.BALANCE.IN_ORDER | managerPipe}}</div>
              <div class="text-right" style="width: 25%">{{managerService.config.BALANCE.TOTAL_AMOUNT | managerPipe}}</div>
            </div>
            <div class="user-asset">
              <div class="w-100 d-flex flex-row align-items-center justify-content-center px-3 py-2 "
                   style="font-family: Roboto, sans-serif; font-size: 11px; color: #272a3b;">
                  <span class="text-left ml-1"
                        style="width: 25%; color: #4a4e70; font-weight: 500; letter-spacing: 0.7px;">{{user_asset | managerPipe: managerService.mpa_template.GET_USER_ASSETS_SYMBOL}}</span>
                <span class="text-right"
                      style="width: 25%">{{user_asset.available_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER_DECIMAL: (user_asset.currency_id === 2 ? 6 : 2)}}</span>
                <span class="text-right"
                      style="width: 25%">{{user_asset| managerPipe: managerService.mpa_template.USER_INORDER_AMOUNT: managerService.user_open_orders_history_pipe | managerPipe: managerService.mpa_template.FANCY_NUMBER_DECIMAL: (user_asset.currency_id === 2 ? 6 : 2)}}</span>
                <span class="text-right"
                      style="width: 25%">{{user_asset.total_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER_DECIMAL: (user_asset.currency_id === 2 ? 6 : 2)}}</span>
              </div>
            </div>
          </div>
        </div>
        <div
            class="w-100 d-flex flex-row align-items-center justify-content-center mt-2 mb-3" style="font-size: 13px; letter-spacing: 0.39px; ">
            <div (click)="this.managerService.setActiveCurrencyConverterPanel(managerService.active_currency_converter_panel_template.BUY);managerService.setActiveCurrencyPairByCurrencyId(user_asset.currency_id); managerService.openModal('currency-converter-modal');"
                 class="transaction-button" style="width: 36vw">
              {{user_asset.currency_id | managerPipe: managerService.mpa_template.GET_CURRENCY_SYMBOL_BY_ID}}
              {{managerService.config.DASHBOARD.BUY| managerPipe}}
            </div>
            <div (click)="this.managerService.setActiveCurrencyConverterPanel(managerService.active_currency_converter_panel_template.BUY);managerService.setActiveCurrencyPairByCurrencyId(user_asset.currency_id); managerService.openModal('currency-converter-modal');"
                 class="transaction-button" style="width: 36vw">
              {{user_asset.currency_id | managerPipe: managerService.mpa_template.GET_CURRENCY_SYMBOL_BY_ID}}
              {{managerService.config.DASHBOARD.SELL| managerPipe}}
            </div>
          </div>
      </div>
    </div>

  </div>

  <div class="mx-3">
    <div (click)="art_products.classList.toggle('d-none')"
         class="position-relative d-flex flex-row justify-content-between align-items-center"
         style="height: 41px; font-family: Roboto, sans-serif; border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; color: var(--artiox-blue); background-color: #ffffff;">
      <div class="px-3" style="font-size: 13px; letter-spacing: 0.4px;">{{managerService.config.BALANCE.ART_PRODUCTS| managerPipe}}</div>
      <div class="pr-3" style="font-size: 12px; color: #404352;">
        {{!balance_visible ? ('***' | managerPipe) :
        "~"+((managerService.user_pipe | managerPipe: managerService.mpa_template.GET_USER_TOTAL_ART_PRODUCT_TYPE_OF_CURRENCY: managerService.pair_pipe: managerService.currency_pair_pipe) | managerPipe : managerService.mpa_template.FANCY_NUMBER)+"₺"}}
      </div>
    </div>
    <div #art_products class="d-none" style="border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; margin-top: -20px; padding-top: 20px; background-color: #ffffff;">
      <div class="d-flex flex-column justify-content-start align-items-center"
           style="border-radius: 4px;">
        <div class="w-100">
          <div class="w-100 d-flex flex-row align-items-start justify-content-start px-3 pt-2 pb-1"
               style="font-family: Roboto, sans-serif; font-size: 11px; color: #272a3b;">
            <div class="text-left ml-1" style="width: 20%">{{managerService.config.BALANCE.SYMBOL | managerPipe}}</div>
            <div class="text-right" style="width: 34%">{{managerService.config.BALANCE.AVAILABLE_AMOUNT | managerPipe}}</div>
            <div class="text-right" style="width: 34%">{{managerService.config.BALANCE.VALUE | managerPipe}}</div>
            <div class="text-right mr-1" style="width: 12%"></div>
          </div>
          <div *ngIf="!(managerService.user_pipe | managerPipe: managerService.mpa_template.GET_USER_OWNED_ART_PRODUCT_LIST: managerService.pair_pipe).length | managerPipe"
               class="mx-3 mt-2 mb-3 text-info" style="font-size: 12px;">
            <svg style="margin-top: -2px; height: 12px; width: 12px;">
              <use [attr.xlink:href]='"./assets/images/info.svg#info"' fill="#17a2b8"/>
            </svg>
            {{managerService.config.BALANCE.NO_TOKEN | managerPipe}}
          </div>
          <div *ngFor="let user_asset of (managerService.user_pipe | managerPipe: managerService.mpa_template.GET_USER_OWNED_ART_PRODUCT_LIST: managerService.pair_pipe)" class="user-asset">
            <div class="w-100 d-flex flex-row align-items-center justify-content-center px-3 py-2 "
                 style="font-family: Roboto, sans-serif; font-size: 11px; color: #272a3b;">
              <span class="text-left ml-1"
                    style="width: 20%; color: #4a4e70; font-weight: 500; letter-spacing: 0.7px;">{{user_asset | managerPipe: managerService.mpa_template.GET_USER_ASSETS_SYMBOL}}</span>
              <span class="text-right"
                    style="width: 34%">{{user_asset | managerPipe: managerService.mpa_template.USER_BALANCE_SUMMARY_AMOUNT}}</span>
              <span class="text-right"
                    style="width: 34%">{{user_asset | managerPipe: managerService.mpa_template.GET_USER_ASSETS_VALUE : managerService.pair_pipe: managerService.currency_pair_pipe}}</span>
              <span (click)="more_div.classList.toggle('d-none'); more_div.classList.toggle('d-flex')" class="text-right mr-1"
                    style="width: 12%; font-family: Roboto, sans-serif; font-style: italic; font-weight: 500; font-size: 9px; color: #9da5b7;"
                    type="button">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.MORE | managerPipe}}
                  </span>
            </div>
            <div #more_div class="w-100 d-none flex-row flex-wrap align-items-center justify-content-around px-3 pb-1"
                 style="font-family: Roboto, sans-serif;font-size: 10px; font-weight: 500; line-height: 1.2; letter-spacing: 0.7px; color: #4a4e70;">
              <span class="w-50 pl-1 my-1 text-left">{{managerService.config.BALANCE.IN_ORDER | managerPipe}}</span>
              <span class="w-50 pr-1 my-1 text-right">{{user_asset | managerPipe: managerService.mpa_template.USER_INORDER_AMOUNT: managerService.user_open_orders_history_pipe | managerPipe: managerService.mpa_template.USER_BALANCE_SUMMARY_AMOUNT: user_asset}}</span>
              <span class="w-50 pl-1 my-1 text-left">{{managerService.config.BALANCE.TOTAL_AMOUNT | managerPipe}}</span>
              <span class="w-50 pr-1 my-1 text-right">{{user_asset | managerPipe: managerService.mpa_template.USER_BALANCE_SUMMARY_TOTAL_AMOUNT}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-100 d-flex flex-column" style="color: #FFFFFF;">
    <div class="w-100 d-flex flex-row align-items-center justify-content-center mt-2 mb-3" style="font-size: 13px; letter-spacing: 0.39px; ">
      <div (click)="managerService.navigationManager({url: '/account/deposit', current_component_index: 0})" class="transaction-button" style="width: 36vw">
        <svg style="height:16px; width: 16px; margin-right: 4px; ">
          <use [attr.xlink:href]='"./assets/images/deposit_white.svg#deposit_white"' class="w-100" fill="#fcfdff"/>
        </svg>
        {{managerService.config.DASHBOARD.DEPOSIT| managerPipe}}
      </div>
      <div (click)="managerService.navigationManager({url: '/account/withdrawal', current_component_index: 0})" class="transaction-button" style="width: 36vw">
        <svg style="height:16px; width: 16px;  margin-right: 4px;">
          <use [attr.xlink:href]='"./assets/images/withdrawal_white.svg#withdrawal_white"' class="w-100" fill="#fcfdff"/>
        </svg>
        {{managerService.config.DASHBOARD.WITHDRAWAL| managerPipe}}
      </div>
    </div>
  </div>

  <div class="w-100 d-flex flex-column align-items-start justify-content-start" style="background-color: #fcfdff;">
    <div class="w-100 d-flex flex-column align-items-center justify-content-between"
         style="font-family: babes-neue-normal, sans-serif;font-size: 14px; letter-spacing: 0.7px; color: #272a3b;">
      <div class="align-self-start py-2 mx-3">{{managerService.config.BALANCE.USER_OPEN_ORDERS_UPPERCASE | managerPipe}}</div>
      <div class=" w-100 d-flex flex-row justify-content-end align-items-end pb-2 px-3">
        <div class="d-flex flex-row align-self-end">
          <div class="mx-1 mb-1">
            <button
              (click)="filter_menu.classList.toggle('d-none')"
              class="btn-native filter-dropdown-button"
              style="font-family: Roboto, sans-serif; font-weight: normal; font-size: 12px; color: #9da5b7; width: 81px; height: 20px;">
              {{pair_selected_placeholder | managerPipe}}
              <svg style="height: 14px; width: 14px;">
                <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#595e7e"/>
              </svg>
            </button>
            <div #filter_menu class="filter-dropdown-menu d-none align-self-center">
              <div
                (click)="pair_selected = undefined; pair_selected_placeholder = managerService.config.BALANCE.ALL; filter_menu.classList.add('d-none');"
                [style.background-color]="((pair_selected_placeholder === managerService.config.BALANCE.ALL) | managerPipe) ? '#f4f5f9' : ''"
                class="btn-native filter-dropdown-item text-center py-1">
                {{managerService.config.BALANCE.ALL | managerPipe}}
              </div>
              <div
                (click)="pair_selected = pair.id; pair_selected_placeholder = pair.symbol; filter_menu.classList.add('d-none');"
                *ngFor="let pair of (managerService.pair_pipe | managerPipe: managerService.mpa_template.GET_SORTED_LISTED_PAIR_LIST)"
                [style.background-color]="((pair_selected_placeholder === pair.symbol) | managerPipe) ? '#f4f5f9' : ''"
                class="btn-native filter-dropdown-item text-center py-1">
                {{pair.symbol | managerPipe}}
              </div>
            </div>
          </div>
          <div class="mx-1 mb-1">
            <button
              (click)="filter_type_menu.classList.toggle('d-none')"
              class="btn-native filter-dropdown-button text-left"
              style="font-family: Roboto, sans-serif; font-weight: normal; font-size: 12px; color: #9da5b7; width: 81px; height: 20px;">
              <div [style]="order_type_selected_placeholder | managerPipe: managerService.mpa_template.GET_USER_SUMMARY_FILTER_BACKGROUND_COLOR" class="d-inline-block ml-2 mr-1"
                   style="height: 8px; width: 8px;"></div>
              {{order_type_selected_placeholder | managerPipe}}
              <svg style="height: 14px; width: 14px;">
                <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#595e7e"/>
              </svg>
            </button>
            <div #filter_type_menu class="filter-dropdown-menu d-none">
              <div
                (click)="order_type_selected = undefined; order_type_selected_placeholder = managerService.config.BALANCE.ALL; filter_type_menu.classList.add('d-none');"
                [style.background-color]="((order_type_selected_placeholder === managerService.config.BALANCE.ALL) | managerPipe) ? '#f4f5f9' : ''"
                class="btn-native filter-dropdown-item text-center py-1">
                {{managerService.config.BALANCE.ALL | managerPipe}}
              </div>
              <div
                (click)="order_type_selected = 'buy'; order_type_selected_placeholder = managerService.config.BALANCE.BUY; filter_type_menu.classList.add('d-none');"
                [style.background-color]="((order_type_selected_placeholder === managerService.config.BALANCE.BUY) | managerPipe) ? '#f4f5f9' : ''"
                class="btn-native filter-dropdown-item py-1">
                <div class="d-inline-block ml-2 mr-1" style="background-color: #2ad8a9; height: 8px; width: 8px;"></div>
                {{managerService.config.BALANCE.BUY | managerPipe}}
              </div>
              <div
                (click)="order_type_selected = 'sell'; order_type_selected_placeholder = managerService.config.BALANCE.SELL; filter_type_menu.classList.add('d-none');"
                [style.background-color]="((order_type_selected_placeholder === managerService.config.BALANCE.SELL) | managerPipe) ? '#f4f5f9' : ''"
                class="btn-native filter-dropdown-item py-1">
                <div class="d-inline-block ml-2 mr-1" style="background-color: #ff207d; height: 8px; width: 8px;"></div>
                {{managerService.config.BALANCE.SELL | managerPipe}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-100 d-flex flex-row align-items-center justify-content-around px-3 py-1"
         style="font-family: Roboto, sans-serif;font-size: 10px; color: #272a3b;">
      <span class="text-left"
            style="width: 20%">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.PAIR | managerPipe}}</span>
      <span class="text-center"
            style="width: 20%">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.TYPE | managerPipe}}</span>
      <span class="text-center"
            style="width: 15%">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.PRICE | managerPipe}}</span>
      <span class="text-center"
            style="width: 15%">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.AMOUNT | managerPipe}}</span>
      <span class="text-center"
            style="width: 15%">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.CANCEL | managerPipe}}</span>
      <span class="pr-4 text-right" style="width: 15%"></span>
    </div>

    <div class="w-100 d-flex flex-column overflow-auto mb-1" style="min-height: 240px; max-height: 240px;">
      <div
        *ngFor="let user_open_order of (managerService.user_open_orders_history_pipe | managerPipe: managerService.mpa_template.GET_USER_OPEN_ORDERS: pair_selected: order_type_selected)"
        class="d-flex flex-row align-items-center justify-content-around flex-wrap user-open-order flex-shrink-0"
        style="font-family: Roboto, sans-serif;font-size: 10px; font-weight: 500; letter-spacing: 0.7px; color: #4a4e70;">
        <div class="w-100 d-flex flex-row align-items-center justify-content-around px-3 py-2 ">
          <span class="text-left "
                style="width: 20%">{{user_open_order.pair_id | managerPipe: managerService.mpa_template.PAIR_SYMBOL: managerService.pair_pipe}}</span>
          <span [style]="user_open_order | managerPipe: managerService.mpa_template.GET_USER_OPEN_ORDERS_BACKGROUND_COLOR"
                class="text-center"
                style="width: 20%">{{user_open_order.type | managerPipe: managerService.mpa_template.USER_OPEN_ORDER_TYPE}}</span>
          <span [style]="user_open_order | managerPipe: managerService.mpa_template.GET_USER_OPEN_ORDERS_BACKGROUND_COLOR"
                class="text-center"
                style="width: 15%">{{user_open_order.price | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
          <span [style]="user_open_order | managerPipe: managerService.mpa_template.GET_USER_OPEN_ORDERS_BACKGROUND_COLOR"
                class="text-center"
                style="width: 15%">{{user_open_order.amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
          <span class="text-center"
                style="width: 15%">
          <svg (click)="managerService.cancelUserOrder(user_open_order);" style="height: 16px; width: 16px;" type="button">
            <use [attr.xlink:href]='"./assets/images/close.svg#close"' fill="#4fc0d2"/>
          </svg>
          </span>
          <span (click)="more_div.classList.toggle('d-none'); more_div.classList.toggle('d-flex')" class="text-right"
                style="width: 15%; font-family: Roboto, sans-serif; font-style: italic; font-size: 9px; font-weight: 500;  letter-spacing: normal; color: #9da5b7;"
                type="button">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.MORE | managerPipe}}
          </span>
        </div>
        <div #more_div class="w-100 d-none flex-row flex-wrap align-items-center justify-content-around px-3 pb-1"
             style="font-family: Roboto, sans-serif; font-size: 9px; line-height: 1.2; letter-spacing: 0.7px; color: #4a4e70;">
          <span class="w-50  my-1 text-left">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.TIME | managerPipe}}</span>
          <span class="w-50  my-1 text-right">{{user_open_order.creation_time | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</span>
          <span class="w-50  my-1 text-left">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.FILLED_PERCENTAGE | managerPipe}}</span>
          <span class="w-50  my-1 text-right">{{(1 - user_open_order.amount / user_open_order.init_amount) * 100 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}%</span>
          <span class="w-50  my-1 text-left">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.FILLED_AMOUNT | managerPipe}}</span>
          <span class="w-50  my-1 text-right">{{user_open_order.init_amount - user_open_order.amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
          <span class="w-50  my-1 text-left">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.FILLED_VOLUME | managerPipe}}</span>
          <span class="w-50  my-1 text-right">{{user_open_order.price * (user_open_order.init_amount - user_open_order.amount) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
          <span class="w-50  my-1 text-left">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.TOTAL_AMOUNT | managerPipe}}</span>
          <span class="w-50  my-1 text-right">{{user_open_order.init_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
          <span class="w-50  my-1 text-left">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.TOTAL_VOLUME | managerPipe}}</span>
          <span class="w-50  my-1 text-right">{{user_open_order.price * user_open_order.init_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
