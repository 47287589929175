import {Component, OnDestroy, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';
import {first} from 'rxjs/operators';
import {NavigationService} from '../../../../../services/navigation.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-support-category-desktop',
  templateUrl: './support-category-desktop.component.html',
  styleUrl: './support-category-desktop.component.css'
})
export class SupportCategoryDesktopComponent {
  currentSupportCategoryIndex: number = 0;
  currentSupportCategory: any ;

  subscriptionList: Subscription[] = []

  constructor(protected managerService: ManagerService,) {
    this.currentSupportCategory = managerService.config.SUPPORT.CATEGORIES[this.currentSupportCategoryIndex];
    setTimeout(() => {
      const url = this.managerService.navigationService.current_routing.url
      const path = url.split('/');
      const param = path[path.length - 1].split('?')[0];
      this.currentSupportCategoryIndex = managerService.config.SUPPORT.CATEGORIES.findIndex(category => category.PATH === param)
      this.currentSupportCategory = managerService.config.SUPPORT.CATEGORIES[this.currentSupportCategoryIndex];
    })

  }

}
