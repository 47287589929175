import { Component, OnInit } from '@angular/core';
import {ManagerService} from '../../../../services/manager.service';

@Component({
  selector: 'app-support-desktop',
  templateUrl: './support-desktop.component.html',
  styleUrls: ['./support-desktop.component.css']
})
export class SupportDesktopComponent implements OnInit {

  constructor(public managerService: ManagerService) { }

  ngOnInit(): void {
  }

  navigateToCategory(index: number): void{
    let navigationPath = `/sss/${this.managerService.config.SUPPORT.CATEGORIES[index].PATH}`

    this.managerService.navigationManager({url: navigationPath, current_component_index: index});
  }

}
