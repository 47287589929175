<div class="d-flex flex-column" style="min-height: 600px;">
  <div [class.d-none]="managerService.navigationService.artist_announcement_active_index !== -1 | managerPipe">
    <div class="d-flex flex-row flex-wrap" style="margin: 12px;">
      <div (click)="managerService.navigationManager({url: managerService.config.ZENDESK_ACTIVE ? '/about-artist' : '/corporate', artist_announcement_index: i, current_component_index: 0})"
           *ngFor="let artist_announcement of (managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST | managerPipe); let i=index;"
           class="position-relative"
           style="flex-basis: calc(50% - 8px); margin: 4px; border-radius: 4px; box-shadow: 5px 5px 10px 0 rgba(45, 52, 104, 0.5); cursor: pointer;">
        <img src="{{artist_announcement.ARTIST_IMAGE_SRC}}"
             class="artist-image" alt="artist" #artist
             (error)="artist.src = artist.src.replace('.webp', '.jpg');">
        <div class="w-100 d-flex flex-column align-items-center justify-content-end position-absolute" style="color: #ffffff; bottom: 20px;">
          <div style="font-family: bebas-neue-bold, sans-serif; font-size: 14px; letter-spacing: 0.15px; line-height: 1;">
            {{artist_announcement.NAME_UPPERCASE | managerPipe}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="(managerService.navigationService.artist_announcement_active_index !== -1 | managerPipe)" class="mx-auto"
       style="width: 100%; max-width: 1200px;">
    <div [class]="!managerService.navigationService.artist_announcement_gallery_active ? 'd-flex' : 'd-none'" class="position-relative w-100 flex-column justify-content-start align-items-center">
      <div *ngIf="(managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].TEMPLATE === 0) | managerPipe"
           class="w-100 position-relative d-flex flex-column justify-content-start align-items-center">
        <img src="{{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].IMAGE_BACKGROUND_MOBILE}}"
             class=" w-100" style="height: 106px; width: 100%; object-fit: cover;" alt="background" #background
             (error)="background.src = background.src.replace('.webp', '.jpg');">
        <img src="{{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].ARTIST_IMAGE_SRC}}"
             style="position: absolute; top: 64px; height: 84px; width: 84px; border-radius: 84px;" alt="artist" #artist
             (error)="artist.src = artist.src.replace('.webp', '.jpg');">
        <div class="d-flex justify-content-between" style="width: 80%; margin: 18px 2px; font-family: Roboto, sans-serif; font-size: 13px; letter-spacing: 0.4px; color: #1e1f25;">

          <div (click)="managerService.navigationService.artist_announcement_active_index=managerService.navigationService.artist_announcement_active_index-1;
              managerService.navigationService.artist_announcement_active_index=managerService.navigationService.artist_announcement_active_index + managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST.length;
              managerService.navigationService.artist_announcement_active_index=managerService.navigationService.artist_announcement_active_index % managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST.length;
              managerService.navigationService.setActiveArtist(managerService.navigationService.artist_announcement_active_index);"
               style="font-family: bebas-neue-bold, sans-serif; font-size: 11px; line-height: 1.1; color: #1e1f25;">
            <div class="d-flex flex-column align-items-center">
              <svg style="height: 14px; width: 14px;">
                <use [attr.xlink:href]='"./assets/images/announcement_left_arrow.svg#announcement_left_arrow"' fill="#4D6557"/>
              </svg>
              <div>
                {{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.FOOTER.PREVIOUS_ARTIST | managerPipe}}
              </div>
            </div>
          </div>

          <div (click)="managerService.navigationService.artist_announcement_active_index=managerService.navigationService.artist_announcement_active_index+1;
              managerService.navigationService.artist_announcement_active_index=managerService.navigationService.artist_announcement_active_index + managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST.length;
              managerService.navigationService.artist_announcement_active_index=managerService.navigationService.artist_announcement_active_index % managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST.length;
              managerService.navigationService.setActiveArtist(managerService.navigationService.artist_announcement_active_index);"
               style="font-family: bebas-neue-bold, sans-serif; font-size: 11px; line-height: 1.1; color: #1e1f25;">
            <div class="d-flex flex-column align-items-center">
              <svg style="height: 14px; width: 14px;">
                <use [attr.xlink:href]='"./assets/images/announcement_right_arrow.svg#announcement_right_arrow"' fill="#4D6557"/>
              </svg>
              <div>
                {{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.FOOTER.NEXT_ARTIST | managerPipe}}
              </div>
            </div>
          </div>

        </div>
        <div class="d-flex flex-row align-items-center justify-content-center"
             style="width: 80%; font-family: bebas-neue-bold, sans-serif; font-size: 18px; line-height: 1.2; letter-spacing: 0.15px; color: #404352; margin-top: 16px;">
          {{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].TOPIC_UPPERCASE | managerPipe}}
        </div>
        <div class="announcement-article-container" style="width: 80%; margin-top: 10px;">
          <div class="announcement-article-text">
        <span
          [innerHTML]="( '“'+  managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].TEXT) | managerPipe">
        </span>
            <div [innerHTML]="managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].REFERENCES | managerPipe"
                 style="font-size: 10px; margin: -24px 4px -24px 12px;"></div>
          </div>
        </div>
      </div>

      <div *ngIf="(managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].TEMPLATE === 1) | managerPipe"
           class="w-100 position-relative d-flex flex-column justify-content-start align-items-center">
        <img src="{{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].IMAGE_BACKGROUND_MOBILE}}"
             class=" w-100" style="height: 106px; width: 100%; object-fit: cover;" alt="background" #background
             (error)="background.src = background.src.replace('.webp', '.jpg');">
        <img src="{{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].ARTIST_IMAGE_SRC}}"
             style="position: absolute; top: 64px; height: 84px; width: 84px; border-radius: 84px;" alt="artist" #artist
             (error)="artist.src = artist.src.replace('.webp', '.jpg');">
        <div class="d-flex justify-content-between" style="width: 80%; margin: 18px 2px; font-family: Roboto, sans-serif; font-size: 13px; letter-spacing: 0.4px; color: #1e1f25;">

          <div (click)="managerService.navigationService.artist_announcement_active_index=managerService.navigationService.artist_announcement_active_index-1;
              managerService.navigationService.artist_announcement_active_index=managerService.navigationService.artist_announcement_active_index + managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST.length;
              managerService.navigationService.artist_announcement_active_index=managerService.navigationService.artist_announcement_active_index % managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST.length;
              managerService.navigationService.setActiveArtist(managerService.navigationService.artist_announcement_active_index);"
               style="font-family: bebas-neue-bold, sans-serif; font-size: 11px; line-height: 1.1; color: #1e1f25;">
            <div class="d-flex flex-column align-items-center">
              <svg style="height: 14px; width: 14px;">
                <use [attr.xlink:href]='"./assets/images/announcement_left_arrow.svg#announcement_left_arrow"' fill="#4D6557"/>
              </svg>
              <div>
                {{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.FOOTER.PREVIOUS_ARTIST | managerPipe}}
              </div>
            </div>
          </div>

          <div (click)="managerService.navigationService.artist_announcement_active_index=managerService.navigationService.artist_announcement_active_index+1;
              managerService.navigationService.artist_announcement_active_index=managerService.navigationService.artist_announcement_active_index + managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST.length;
              managerService.navigationService.artist_announcement_active_index=managerService.navigationService.artist_announcement_active_index % managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST.length;
              managerService.navigationService.setActiveArtist(managerService.navigationService.artist_announcement_active_index);"
               style="font-family: bebas-neue-bold, sans-serif; font-size: 11px; line-height: 1.1; color: #1e1f25;">
            <div class="d-flex flex-column align-items-center">
              <svg style="height: 14px; width: 14px;">
                <use [attr.xlink:href]='"./assets/images/announcement_right_arrow.svg#announcement_right_arrow"' fill="#4D6557"/>
              </svg>
              <div>
                {{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.FOOTER.NEXT_ARTIST | managerPipe}}
              </div>
            </div>
          </div>

        </div>

        <div class="d-flex flex-row align-items-center justify-content-center"
             style="width: 80%; font-family: bebas-neue-bold, sans-serif; font-size: 18px; line-height: 1.2; letter-spacing: 0.15px; color: #404352; margin-top: 16px;">
          {{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].TOPIC_UPPERCASE | managerPipe}}
        </div>
        <div class="announcement-article-container" style="width: 80%; margin-top: 10px;">
          <div class="announcement-article-text">
            <span
              [innerHTML]="( '“'+  managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].TEXT) | managerPipe">
            </span>
          </div>
        </div>

        <div class="d-flex flex-row align-items-center justify-content-center"
             style="width: 80%; font-family: bebas-neue-bold, sans-serif; font-size: 18px; line-height: 1.2; letter-spacing: 0.15px; color: #404352; margin-top: 16px;">
          {{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].ARTIST_LIST_HEADING_UPPERCASE | managerPipe}}
        </div>

        <div
          *ngFor="let artist of managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].NESTED_ARTIST_LIST | managerPipe"
          class="px-3">

          <div class="announcement-article-text mt-3">
            <div [style]="{'background-image': 'url(' + (artist.PHOTO) + ')'} | managerPipe"
                 class="mr-2"
                 style="height: 56px; width: 56px; border-radius: 28px; background-size: contain; float: left;">
            </div>
            <div style="color: var(--artiox-blue)">
              {{artist.HEADING}}
            </div>
            <div [innerHTML]="artist.TEXT"></div>
          </div>
        </div>

      </div>

    </div>

    <div [class]="managerService.navigationService.artist_announcement_gallery_active ? 'd-flex' : 'd-none'" class="w-100 flex-column justify-content-start align-items-center"
         style="background-color: #fcfdff;">
      <div style="padding: 26px 4px; font-family: bebas-neue-bold, sans-serif; font-size: 20px; letter-spacing: 1px; text-align: center; color: #404352;">
        {{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].ARTWORKS}}
      </div>

      <div class="w-100 d-flex flex-row justify-content-between" style="padding: 2px 24px;">
        <div class="d-flex flex-column justify-content-start align-items-center" style="width: calc(50% - 6px);">
          <div
            *ngFor="let artwork of (managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].ARTWORKS_LIST_MOBILE | managerPipe); let i = index"
            class="position-relative">
            <div [class.invisible]="i%2===1">
              <img [alt]="artwork.DESCRIPTION" [src]="artwork.SRC" class="w-100" #artwork_img
                   (error)="artwork_img.src = artwork_img.src.replace('.webp', '.jpg')">
              <div style="font-family: Roboto, sans-serif; font-size: 10px; font-weight: 300; color: #1e1f25;">
                {{artwork.DESCRIPTION}}
              </div>
            </div>
            <div
              [class.invisible]="i%2===0 || i===0 || i===managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].ARTWORKS_LIST_MOBILE.length-1"
              class="position-absolute"
              style="height: calc(100% - 36px); width: 1px; min-width: 1px; border-radius:2px; background-color: #1e1f25; top: 12px; left: 50%;">
            </div>
            <div
              [class.invisible]="i%2===0 || i!==0 && i!==managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].ARTWORKS_LIST_MOBILE.length-1"
              class="position-absolute"
              style="width: 100%; height: 1px; min-height: 1px; border-radius:2px; background-color: #1e1f25; top: 40%; left: 0">
            </div>
          </div>
        </div>

        <div class="d-flex flex-column justify-content-start align-items-center" style="width: calc(50% - 6px)">
          <div
            *ngFor="let artwork of (managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].ARTWORKS_LIST_MOBILE | managerPipe); let i = index"
            class="position-relative text-right">
            <div [class.invisible]="i%2===0">
              <img [alt]="artwork.DESCRIPTION" [src]="artwork.SRC" class="w-100" #artwork_img
                   (error)="artwork_img.src = artwork_img.src.replace('.webp', '.jpg')">
              <div style="font-family: Roboto, sans-serif; font-size: 10px; font-weight: 300; color: #1e1f25;">
                {{artwork.DESCRIPTION}}
              </div>
            </div>
            <div
              [class.invisible]="i%2===1 || i===0 || i===managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].ARTWORKS_LIST_MOBILE.length-1"
              class="position-absolute"
              style="height: calc(100% - 36px); width: 1px; min-width: 1px; border-radius:2px; background-color: #1e1f25; top: 12px; left: 50%;">
            </div>
            <div
              [class.invisible]="i%2===1 || i!==0 && i!==managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].ARTWORKS_LIST_MOBILE.length-1"
              class="position-absolute"
              style="width: 100%; height: 1px; min-height: 1px; border-radius:2px; background-color: #1e1f25; top: 40%; left: 0">
            </div>
          </div>
        </div>
      </div>

      <div style="margin-top: 50px; margin-left: 50px; font-family: Roboto, sans-serif; font-size: 14px; letter-spacing: 0.4px; color: #1e1f25; font-weight: 500;">
        <div
          *ngFor="let reference of (managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].ARTWORK_REFERENCES | managerPipe)"
          [innerHTML]="reference | managerPipe" style="margin-left: -24px; font-size: 10px; color: #b1b3c5; font-style: italic; margin-bottom: 6px;">
        </div>
      </div>
    </div>
    <div class="w-100 d-flex justify-content-center position-sticky"
         style="bottom: 50px; height: 90px; padding: 25px 4px; font-family: Roboto, sans-serif; font-size: 13px; letter-spacing: 0.4px; color: #1e1f25;">
      <div (click)="managerService.navigationService.seeArtworks()"
           [class.d-none]="managerService.navigationService.artist_announcement_gallery_active">
        <div class="btn-native btn-simple navigation-button d-flex flex-column align-items-center" type="button">
          <div style="font-family: Roboto, sans-serif; font-size: 12px; ">
            {{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.FOOTER.SEE_ARTWORKS | managerPipe}}
          </div>
          <div style="margin: auto;">
            <svg style="height: 14px; width: 14px; margin-top: -14px;">
              <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#ffffff"/>
            </svg>
          </div>
        </div>
      </div>
      <div (click)="managerService.navigationService.backToArtworks()"
           [class.d-none]="!managerService.navigationService.artist_announcement_gallery_active">
        <div class="btn-native btn-simple navigation-button" type="button">
          <svg style="height: 14px; width: 14px; margin-bottom: 4px;">
            <use [attr.xlink:href]='"./assets/images/announcement_up_arrow.svg#announcement_up_arrow"' fill="#ffffff"/>
          </svg>
          <span style="font-family: Roboto, sans-serif; font-size: 12px; ">
            {{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.FOOTER.BACK_TO_ARTIST | managerPipe}}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
