<div [style]="managerService.show_sign_up_or_login | managerPipe: managerService.mpa_template.GET_FILLING_BODY_HEIGHT"
     class="w-100 d-flex flex-column overflow-hidden" style="background-color: #2a2b32;">
  <app-pre-sale-header-desktop style="box-shadow: 1px 2px 4px 0 rgba(45, 52, 104, 0.32); background-color: #323441;">
  </app-pre-sale-header-desktop>
  <div [class]="(managerService.inner_width >= 1200 | managerPipe) ? 'justify-content-center':'justify-content-start'" class="d-flex flex-row align-items-start overflow-auto">
    <div style="width: 400px; min-width: 400px;">
      <app-left-info-desktop></app-left-info-desktop>
    </div>
    <div class="d-flex flex-column align-items-center justify-content-center" style="width: 45%; min-width: 500px; gap: 32px;">
      <app-pre-sale-art-product-canvas-desktop class="w-100"></app-pre-sale-art-product-canvas-desktop>
      <app-pre-sale-trade-desktop class="w-100" style="max-width: 750px;"></app-pre-sale-trade-desktop>
      <app-pre-sale-art-product-info-desktop class="w-100" style="max-width: 750px;"></app-pre-sale-art-product-info-desktop>
    </div>
    <div style="width: 300px; min-width: 300px; gap: 32px;">
      <app-pre-sale-art-product-report-list-desktop>
      </app-pre-sale-art-product-report-list-desktop>
    </div>
  </div>
</div>
