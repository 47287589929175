<div class="d-flex flex-row h-100" style="min-height: 100%; margin-bottom: 0 ">
    <!--    Artiox Purple background and image -->
    <div class="d-flex flex-column align-items-center justify-content-between w-100"
         style="background: linear-gradient(180deg, var(--main-color-artiox-purple-500) 0%, var(--main-color-artiox-purple-base) 100%);">
        <div class="position-absolute" style="left: 120px; top: 64px; cursor: pointer" (click)="this.managerService.backToHome()">
            <svg style="height: 30px; width: 130px;">
                <use [attr.xlink:href]='"./assets/images/artiox_logo.svg#artiox_logo"' fill="white"/>
            </svg>
        </div>
        <div class="h-100 d-flex justify-content-center align-items-center">

            <span class="header3 medium exo text-center"
                  style="color: var(--text-color-artiox-white, #FFFFFF); margin-top: 200px; width: 480px">{{managerService.config.SIGN_UP.CREATE_PASSWORD}}</span>
        </div>
        <div>
            <img alt="" src="./assets/screen_shots/desktop/forgot_password_phone_mock_up.png"
                 style="width: auto;height: 50dvh; min-height: 600px">
        </div>
    </div>

    <!--    Login elements half-->
    <div class="d-flex position-relative w-100">

        <div class="d-flex justify-content-center h-100 w-100">
            <div class="d-flex flex-column justify-content-center" style="max-width: 364px">
                <span class="mb-2 header5 medium exo" style="color: var(--text-color-artiox-black) "></span>
                <span class="body2 regular barlow"
                      style="color: var(--text-color-artiox-grey); margin-bottom: 32px">{{managerService.config.SIGN_UP.CREATE_PASSWORD_TEXT}}</span>

                <div [formGroup]="changePasswordForm" class="d-flex flex-row " style="">
                    <div class="d-flex form-group focused ">
                        <label class=" d-flex form-label body2 barlow medium position-relative" for="password">
                            {{managerService.config.SIGN_UP.PASSWORD | managerPipe}}
                            <svg (click)="password_again_visible = !password_again_visible" class="password-eye"
                                 style="height: 24px; width: 24px; cursor: pointer">
                                <use *ngIf="(!password_again_visible | managerPipe)"
                                     [attr.xlink:href]='"./assets/images/v2/ic_eye_close.svg#ic_eye_close"'/>
                                <use *ngIf="(password_again_visible | managerPipe)"
                                     [attr.xlink:href]='"./assets/images/v2/ic_eye_open.svg#ic_eye_open"'/>
                            </svg>
                        </label>

                        <input #password_input [maxLength]="50"
                               [type]="(password_again_visible | managerPipe) ? 'text': 'password'" class="w-100 form-input body1 barlow regular"
                               formControlName="new_password"
                               id="password" placeholder="{{managerService.config.LOGIN.PASSWORD_PLACEHOLDER}}">
                        <div #password_error class="d-flex d-none flex-row " style="gap: 8px; margin-top: 12px">
                            <svg style=" height: 16px; width: 16px; ">
                                <use [attr.xlink:href]='"./assets/images/error_icon.svg#error_icon"' fill="#EA3943"/>
                            </svg>
                            <div class="body2 barlow regular"
                                 style="color: var(--secondary-colors-artiox-red-base)"></div>
                        </div>
                        <!--                        rezil bi implementation -->
                        <div *ngFor="let n of [2014,2015,2016,2017]; index as i " style="margin-top: 12px; gap: 4px">
                            <div #password_pattern_error class="d-flex d-none flex-row" style="gap: 8px;">
                                <svg class="warning-checkbox d-flex justify-content-center align-items-center "
                                     style=" height: 16px; width: 16px; ">
                                    <use *ngIf="password_pattern_error.classList.contains('pattern-error')"
                                         [attr.xlink:href]='"./assets/images/checkbox_error.svg#checkbox_error"'
                                         stroke="#EA3943"/>
                                    <use *ngIf="password_pattern_error.classList.contains('pattern-success')"
                                         [attr.xlink:href]='"./assets/images/checkbox.svg#checkbox"' fill="#66BB6A"/>
                                </svg>
                                <div class="body2 barlow regular text">{{(managerService.config.RESPONSE_TABLE | managerPipe :managerService.mpa_template.RETURN_INDEX: n).message}}</div>
                            </div>
                        </div>
                    </div>


                </div>
                <button #submit_button (click)="changePassword()" class="w-100  d-flex justify-content-center py-2 login-button mt-3"
                        disabled style="border-width: 0"
                        type="button">
                    <span class="body1 exo "
                          style="color:#FFFFFF; font-weight: 600">{{managerService.config.SIGN_UP.COMPLETE | managerPipe}}</span>
                </button>
            </div>
        </div>

    </div>
</div>
