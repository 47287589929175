<div>
  <div class="d-flex flex-row justify-content-center align-items-center px-4 py-2"
       style="font-family: Roboto, sans-serif; font-size: 10px; color: #ffffff; background-color: #272a3b; box-shadow: 1px 1px 2px 0 #0e111f;">
    <table class="w-100 text-center" style="">
      <tr>
        <td class="w-33 pr-2 pb-2 text-left"></td>
        <td [innerHTML]="managerService.config.SERVICE.RULES.DAILY_DEPOSIT_WITHDRAWAL | managerPipe" class="w-33 px-2 pb-2"
            style="font-weight: 300;"></td>
        <td [innerHTML]="managerService.config.SERVICE.RULES.MONTHLY_DEPOSIT_WITHDRAWAL | managerPipe" class="w-33 px-2 pb-2"
            style="font-weight: 300;"></td>
      </tr>
      <tr style="border-bottom: solid 1px rgba(240, 242, 247, 0.3);">
        <td class="w-33 pr-2 pb-2 text-left" style="font-size: 12px; color: var(--artiox-blue);">{{managerService.config.SERVICE.RULES.LEVEL | managerPipe}} 0</td>
        <td class="w-33 px-2 pb-2" style="border-right: solid 1px rgba(240, 242, 247, 0.3);">{{0 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}₺</td>
        <td class="w-33 px-2 pb-2">{{0 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}₺</td>
      </tr>
      <tr style="border-bottom: solid 1px rgba(240, 242, 247, 0.3);">
        <td class="w-33 pr-2 py-2 text-left" style="font-size: 12px; color: var(--artiox-blue);">{{managerService.config.SERVICE.RULES.LEVEL | managerPipe}} 1</td>
        <td class="w-33 px-2 py-2" style="border-right: solid 1px rgba(240, 242, 247, 0.3);">{{5000 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}₺</td>
        <td class="w-33 px-2 py-2">{{100000 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}₺</td>
      </tr>
      <tr>
        <td class="w-33 pr-2 pt-2 text-left" style="font-size: 12px; color: var(--artiox-blue);">{{managerService.config.SERVICE.RULES.LEVEL | managerPipe}} 2</td>
        <td class="w-33 px-2 pt-2" style="border-right: solid 1px rgba(240, 242, 247, 0.3);">{{500000 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}₺</td>
        <td class="w-33 px-2 pt-2">{{3000000 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}₺</td>
      </tr>
    </table>
  </div>
</div>
<div class="px-3">


  <div class="pt-2" style="font-family: Roboto, sans-serif; font-size: 11px; line-height: 1.45; color: #707070;">
    <div>
      <span style="color: var(--artiox-blue)">
        {{managerService.config.SERVICE.RULES.LEVEL | managerPipe}} 0:
      </span>
      <span>
        {{managerService.config.SERVICE.RULES.LEVEL_0_DESCRIPTION | managerPipe}}
      </span>
    </div>
    <div>
      <span style="color: var(--artiox-blue)">
        {{managerService.config.SERVICE.RULES.LEVEL | managerPipe}} 1:
      </span>
      <span>
        {{managerService.config.SERVICE.RULES.LEVEL_1_DESCRIPTION | managerPipe}}
      </span>
    </div>
    <div>
      <span style="color: var(--artiox-blue)">
        {{managerService.config.SERVICE.RULES.LEVEL | managerPipe}} 2:
      </span>
      <span>
        {{managerService.config.SERVICE.RULES.LEVEL_2_DESCRIPTION | managerPipe}}
      </span>
    </div>
  </div>

  <div class="">
    <div class="pt-3 pb-1" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500; line-height: 1.35; color: #4a4e70;">
      {{managerService.config.SERVICE.RULES.TL_DEPOSIT_RULES_HEADING | managerPipe}}
    </div>
    <div class="p-1" style="background-color: #f4f5f9;">
      <ul style="padding-left: 20px; margin-bottom: 4px;">
        <li *ngFor="let rule of managerService.config.SERVICE.RULES.TL_DEPOSIT_RULES | managerPipe"
            style="font-family: Roboto, sans-serif; font-size: 11px; font-style: italic; color: #4a4e70;">{{rule}}</li>
      </ul>
    </div>
  </div>

  <div class="mb-3">
    <div class="pt-3 pb-1" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500; line-height: 1.35; color: #4a4e70;">
      {{managerService.config.SERVICE.RULES.TL_WITHDRAWAL_RULES_HEADING | managerPipe}}
    </div>
    <div class="p-1" style="background-color: #f4f5f9;">
      <ul style="padding-left: 20px; margin-bottom: 4px;">
        <li *ngFor="let rule of managerService.config.SERVICE.RULES.TL_WITHDRAWAL_RULES | managerPipe"
            style="font-family: Roboto, sans-serif; font-size: 11px; font-style: italic; color: #4a4e70;">{{rule}}</li>
      </ul>
    </div>
  </div>

</div>
