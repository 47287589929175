import {Component, OnDestroy, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';
import {Delivery} from '../../../../../../services/user.service';

@Component({
    selector: 'app-my-wons-desktop',
    templateUrl: './my-wons-desktop.component.html',
    styleUrls: ['./my-wons-desktop.component.css']
})
export class MyWonsDesktopComponent implements OnInit, OnDestroy {
    protected deliveryInfo: Delivery[] = [];
    private subscriptionList: any[] = [];

    constructor(public managerService: ManagerService) {
    }

    ngOnInit(): void {
        this.subscriptionList.push(this.managerService.userService.user_deliveries_changed.subscribe(() => {
            this.deliveryInfo = this.managerService.userService.getUserDeliveries();

        }));
    }

    ngOnDestroy(): void {
        this.subscriptionList.forEach(subscription => subscription.unsubscribe());
    }

}
