import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Swap, Trade} from './trade-history.service';
import {Pair} from './pair.service';
import {Order} from './order-book.service';
import {Bid} from './bid.service';
import {environment} from '../../environments/environment';
import {AuctionProduct} from './auction.service';
import {UtilityService} from "./utility.service";

export class ArtProductWallet {
  public art_product_id: number;
  public available_amount: number;
  public total_amount: number;
  public address: string;

  constructor(art_product_wallet_primitive: any) {
    this.art_product_id = art_product_wallet_primitive.art_product_id;
    this.available_amount = art_product_wallet_primitive.available_amount;
    this.total_amount = art_product_wallet_primitive.total_amount;
    this.address = art_product_wallet_primitive.address;
  }
}

export class CurrencyWallet {
  static empty_currency_wallet = new CurrencyWallet({
    currency_id: -1,
    available_amount: 0,
    total_amount: 0,
    address: ''
  });

  public currency_id: number;
  public available_amount: number;
  public total_amount: number;
  public address: string;

  constructor(currency_primitive: any) {
    this.currency_id = currency_primitive.currency_id;
    this.available_amount = currency_primitive.available_amount;
    this.total_amount = currency_primitive.total_amount;
    this.address = currency_primitive.address;
  }
}

// export class PairEntry {
//   public pair_id: [number, number];
//   public is_favorite: boolean;
//
//   constructor(pair_entry_primitive: any) {
//     this.pair_id = pair_entry_primitive.pair_id;
//     this.is_favorite = pair_entry_primitive.is_favorite;
//   }
// }
//
// export class AuctionProductEntry {
//   public auction_product_id: number;
//   public is_favorite: boolean;
//
//   constructor(pair_entry_primitive: any) {
//     this.auction_product_id = pair_entry_primitive.auction_product_id;
//     this.is_favorite = pair_entry_primitive.is_favorite;
//   }
// }

export class ArtProductOperation {
  public id: number;
  public art_product_id: number;
  public user_id: number;
  public type: string;
  public kind: string;
  public amount: number;
  public status: string;
  public block_chain_id: number;
  public transaction_hash: string;
  public creation_time: number;
  public address: string;
  public nonce: number;
  public retry: number;
  public transaction_fee: number;

  constructor(art_product_operation_primitive: any) {
    this.id = art_product_operation_primitive.id;
    this.art_product_id = art_product_operation_primitive.art_product_id;
    this.user_id = art_product_operation_primitive.user_id;
    this.type = art_product_operation_primitive.type;
    this.kind = art_product_operation_primitive.kind;
    this.amount = art_product_operation_primitive.amount;
    this.status = art_product_operation_primitive.status;
    this.transaction_hash = art_product_operation_primitive.transaction_hash;
    this.creation_time = art_product_operation_primitive.creation_time / 1000000;
    this.address = art_product_operation_primitive.address;
    this.block_chain_id = art_product_operation_primitive.block_chain_id;
    this.nonce = art_product_operation_primitive.nonce;
    this.retry = art_product_operation_primitive.retry;
    this.transaction_fee = art_product_operation_primitive.transaction_fee;
  }
}

export class CurrencyOperation {
  public id: number;
  public currency_id: number;
  public user_id: number;
  public type: string;
  public kind: string;
  public amount: number;
  public status: string;
  public block_chain_id: number;
  public transaction_hash: string;
  public creation_time: number;
  public address: string;
  public nonce: number;
  public retry: number;
  public transaction_fee: number;

  constructor(currency_operation_primitive: any) {
    this.id = currency_operation_primitive.id;
    this.currency_id = currency_operation_primitive.currency_id;
    this.user_id = currency_operation_primitive.user_id;
    this.type = currency_operation_primitive.type;
    this.kind = currency_operation_primitive.kind;
    this.amount = currency_operation_primitive.amount;
    this.status = currency_operation_primitive.status;
    this.transaction_hash = currency_operation_primitive.transaction_hash;
    this.creation_time = currency_operation_primitive.creation_time / 1000000;
    this.address = currency_operation_primitive.address;
    this.block_chain_id = currency_operation_primitive.block_chain_id;
    this.nonce = currency_operation_primitive.nonce;
    this.retry = currency_operation_primitive.retry;
    this.transaction_fee = currency_operation_primitive.transaction_fee;
  }
}

export class CurrencyWithdrawalOperation extends CurrencyOperation {
  public iban: string;
  public bank_account_name: string;
  public bank: string;

  constructor(currency_operation_primitive: any) {
    super(currency_operation_primitive);
    this.iban = currency_operation_primitive.iban;
    this.bank_account_name = currency_operation_primitive.bank_account_name;
    this.bank = currency_operation_primitive.bank;
  }
}

export class UserTrade extends Trade {
  public side: string;

  constructor(trade_primitive: any) {
    super(trade_primitive);
    this.side = trade_primitive.side;
  }
}

export class UserSwap extends Swap {
  public side: string;

  constructor(swap_primitive: any) {
    super(swap_primitive);
    this.side = swap_primitive.side;
  }
}

export class UserBid extends Bid {
}

export class BalanceEntry {
  public total_balance: number;
  public creation_time: number;

  constructor(balance_entry_primitive: any) {
    this.total_balance = balance_entry_primitive.total_balance;
    this.creation_time = balance_entry_primitive.creation_time / 1000000;
  }
}

export class ActivityRecord {
  public id: number;
  public ip: string;
  public city: string;
  public country: string;
  public loc: string;
  public attempt: boolean;  // is_okay
  public reason: string;
  public creation_time: number;
  public code: number;

  constructor(activity_record_primitive: any) {
    this.id = activity_record_primitive.id;
    this.ip = activity_record_primitive.ip;
    this.city = activity_record_primitive.city;
    this.country = activity_record_primitive.country;
    this.loc = activity_record_primitive.loc;
    this.reason = activity_record_primitive.reason;
    this.attempt = activity_record_primitive.attempt;
    this.creation_time = activity_record_primitive.creation_time / 1000000;
    this.code = activity_record_primitive.code;
  }
}

export class UserBankAccount {
  static empty_bank_account = new UserBankAccount({iban: '', bank: '', name: '', label: ''});

  public iban: string;
  public bank: string;
  public name: string;
  public label: string;

  constructor(user_bank_account_primitive: any) {
    this.iban = user_bank_account_primitive.iban;
    this.bank = user_bank_account_primitive.bank;
    this.name = user_bank_account_primitive.name;
    this.label = user_bank_account_primitive.label;
  }
}

export class UserCryptoAccount {
  static empty_crypto_account = new UserCryptoAccount({block_chain: '', address: '', label: ''});

  public block_chain: string;
  public address: string;
  public label: string;

  constructor(user_crypto_account_primitive: any) {
    this.block_chain = user_crypto_account_primitive.block_chain;
    this.address = user_crypto_account_primitive.address;
    this.label = user_crypto_account_primitive.label;
  }
}

export class UserInfo {
  static empty_user_info = new UserInfo({
    id: -1,
    name: '',
    surname: '',
    tc_no: '',
    birth_year: '',
    town: '',
    address: '',
    delivery_addresses: [],
    billing_information: []
  });

  public id: number;
  public name: string;
  public surname: string;
  public tc_no: string;
  public birth_year: string;
  public town: string;
  public address: string;
  public phone_number: string;
  public delivery_addresses: DeliveryAddress[] = [];
  public billing_information: BillingAddress[] = [];

  constructor(user_info_primitive: any) {
    this.id = user_info_primitive.id;
    this.name = user_info_primitive.name;
    this.surname = user_info_primitive.surname;
    this.tc_no = user_info_primitive.tc_no;
    this.birth_year = user_info_primitive.birth_year;
    this.town = user_info_primitive.town;
    this.address = user_info_primitive.address;
    this.phone_number = user_info_primitive.phone_number;
    this.setUserDeliveryAddress(user_info_primitive.delivery_addresses);
    this.setUserBillingAddress(user_info_primitive.billing_information);

  }

  setUserBillingAddress(user_billing_address_primitive: any[]): void {
    this.billing_information = user_billing_address_primitive.map(x => new BillingAddress(x));
  }

  setUserDeliveryAddress(user_delivery_address_primitive: any[]): void {
    this.delivery_addresses = user_delivery_address_primitive.map(x => new DeliveryAddress(x));
  }
}

export class BillingAddress {
  public address_id: number;
  public address_type: string;
  public label: string;
  public company_name: string;
  public tax_administration: string;
  public tax_number: string;
  public province: string;
  public district: string;
  public address: string;
  public phone_number: string;

  constructor(user_address_primitive: any) {
    this.address_id = user_address_primitive.billing_address_id;
    this.address_type = user_address_primitive.type;
    this.label = user_address_primitive.label;
    this.company_name = user_address_primitive.company_name;
    this.tax_administration = user_address_primitive.tax_administration;
    this.tax_number = user_address_primitive.tax_number;
    this.province = user_address_primitive.province;
    this.district = user_address_primitive.district;
    this.address = user_address_primitive.address;
    this.phone_number = user_address_primitive.phone_number;
  }
}

export class BillingAddressExtension extends BillingAddress {
  public tc_no: string;
  public name: string;
  public surname: string;

  constructor(user_address_primitive: any) {
    super(user_address_primitive);
    this.tc_no = user_address_primitive.tc_no;
    this.name = user_address_primitive.name;
    this.surname = user_address_primitive.surname;
  }
}

export enum DeliveryType {
  Cargo = 0,
  HandDelivery = 1
}

export class DeliveryAddress {
  static artiox_delivery_address: DeliveryAddress = {
    address_id: -1,
    label: 'ARTIOX',
    province: 'Ankara',
    district: 'Çankaya',
    address: 'Kızılırmak Mah. YDA Center A/2 Kat:8 No:287 Artiox Çankaya/Ankara',
    phone_number: '-'
  };

  public address_id: number;
  public label: string;
  public province: string;
  public district: string;
  public address: string;
  public phone_number: string;

  constructor(user_address_primitive: any) {
    this.address_id = user_address_primitive.delivery_address_id;
    this.label = user_address_primitive.label;
    this.province = user_address_primitive.province;
    this.district = user_address_primitive.district;
    this.address = user_address_primitive.address;
    this.phone_number = user_address_primitive.phone_number;
  }
}

export class DeliveryAddressExtension extends DeliveryAddress {
  public tc_no: string;
  public name: string;
  public surname: string;

  constructor(user_address_primitive: any) {
    super(user_address_primitive);
    this.tc_no = user_address_primitive.tc_no;
    this.name = user_address_primitive.name;
    this.surname = user_address_primitive.surname;
  }
}

export class Delivery {
  public delivery_id: number;
  public user_id: number;
  public bid_id: number;
  public auction_product_id: number;
  public type: string;
  public billing_address: BillingAddressExtension;
  public delivery_address: DeliveryAddressExtension;
  public status: string;
  public company: string;
  public tracking_number: string;
  public tracking_link: string;
  public tracking_status: string;
  public estimated_arrival_time: string;
  public creation_time: number;

  constructor(delivery_info_primitive: any) {
    this.delivery_id = delivery_info_primitive.id;
    this.user_id = delivery_info_primitive.user_id;
    this.bid_id = delivery_info_primitive.bid_id;
    this.auction_product_id = delivery_info_primitive.auction_product_id;
    this.type = delivery_info_primitive.type;
    this.billing_address = new BillingAddressExtension(delivery_info_primitive.billing_address);
    this.delivery_address = new DeliveryAddressExtension(delivery_info_primitive.delivery_address);
    this.status = delivery_info_primitive.status;
    this.company = delivery_info_primitive.company;
    this.tracking_number = delivery_info_primitive.tracking_number;
    this.tracking_link = delivery_info_primitive.tracking_link;
    this.tracking_status = delivery_info_primitive.tracking_status;
    this.estimated_arrival_time = delivery_info_primitive.estimated_arrival_time;
    this.creation_time = delivery_info_primitive.creation_time;
  }
}

export class User {
  static empty_user = new User({
    id: -1,
    email: '',
    art_product_list: [],
    currency_list: [],
    pair_list: [],
    auction_product_list: [],
    account_verified: false,
    kyc_verified: false,
    second_level_kyc_verified: false,
    second_level_kyc_status: '',
    two_factor_auth: false,
    creation_time: '',
    account_level: -1,
    daily_deposit_limit: 0,
    daily_withdrawal_limit: 0,
    monthly_deposit_limit: 0,
    monthly_withdrawal_limit: 0,
    open_tooltip: false,
    deposit_reference_code: ''
  });

  public id: number;
  public email: string;
  public art_product_list: ArtProductWallet[] = [];
  public currency_list: CurrencyWallet[] = [];
  public pair_list: [number, number][] = [];
  public auction_product_list: number[] = [];
  // public favorite_auction_product_id_list: number[] = [];
  public account_verified: boolean;
  public kyc_verified: boolean;
  public second_level_kyc_verified: boolean;
  public second_level_kyc_status: string;
  public two_factor_auth: boolean;
  public creation_time: number;
  public account_level: number;
  public daily_fiat_deposit_limit: number;
  public daily_fiat_withdrawal_limit: number;
  public monthly_fiat_deposit_limit: number;
  public monthly_fiat_withdrawal_limit: number;
  public daily_crypto_deposit_limit: number;
  public daily_crypto_withdrawal_limit: number;
  public monthly_crypto_deposit_limit: number;
  public monthly_crypto_withdrawal_limit: number;
  public open_tooltip: boolean;
  public deposit_reference_code: string;

  constructor(user_primitive: any) {
    this.id = user_primitive.id;
    this.email = user_primitive.email;
    this.setUserArtProductList(user_primitive.art_product_list);
    this.setUserCurrencyList(user_primitive.currency_list);
    this.setUserPairList(user_primitive.pair_list);
    this.setUserAuctionProductList(user_primitive.auction_product_list);
    this.account_verified = user_primitive.account_verified;
    this.kyc_verified = user_primitive.kyc_verified;
    this.second_level_kyc_verified = user_primitive.second_level_kyc_verified;
    this.second_level_kyc_status = user_primitive.second_level_kyc_status;
    this.two_factor_auth = user_primitive.two_factor_auth;
    this.creation_time = user_primitive.creation_time / 1000000;
    this.account_level = user_primitive.account_level;
    this.daily_fiat_deposit_limit = user_primitive.daily_fiat_deposit_limit;
    this.daily_fiat_withdrawal_limit = user_primitive.daily_fiat_withdrawal_limit;
    this.monthly_fiat_deposit_limit = user_primitive.monthly_fiat_deposit_limit;
    this.monthly_fiat_withdrawal_limit = user_primitive.monthly_fiat_withdrawal_limit;
    this.daily_crypto_deposit_limit = user_primitive.daily_crypto_deposit_limit;
    this.daily_crypto_withdrawal_limit = user_primitive.daily_crypto_withdrawal_limit;
    this.monthly_crypto_deposit_limit = user_primitive.monthly_crypto_deposit_limit;
    this.monthly_crypto_withdrawal_limit = user_primitive.monthly_crypto_withdrawal_limit;
    this.open_tooltip = user_primitive.open_tooltip;
    this.deposit_reference_code = user_primitive.deposit_reference_code;
  }

  setUserArtProductList(art_product_list_primitive: any[]): void {
    this.art_product_list = art_product_list_primitive.map(x => new ArtProductWallet(x));
  }

  setUserCurrencyList(currency_list_primitive: any[]): void {
    this.currency_list = currency_list_primitive.map(x => new CurrencyWallet(x));
  }

  setUserPairList(pair_list_primitive: any[]): void {
    this.pair_list = pair_list_primitive;
    // this.pair_list = pair_list_primitive.map(x => new PairEntry(x));
  }

  setUserAuctionProductList(auction_product_list_primitive: any[]): void {
    this.auction_product_list = auction_product_list_primitive;
    // this.auction_product_list = auction_product_list_primitive.map(x => {
    //   const temp = new AuctionProductEntry(x);
    //   if (temp.is_favorite) {
    //     this.favorite_auction_product_id_list.push(temp.auction_product_id);
    //   }
    //   return temp;
    // });
  }

  // toggleAuctionProductFavorite(auction_product_id: number): void {
  //     for (const item of this.auction_product_list) {
  //         if (item.auction_product_id === auction_product_id) {
  //             item.is_favorite = !item.is_favorite;
  //             if (item.is_favorite) {
  //                 this.favorite_auction_product_id_list.push(auction_product_id);
  //             } else {
  //                 this.favorite_auction_product_id_list.splice(this.favorite_auction_product_id_list.indexOf(auction_product_id), 1);
  //             }
  //             return;
  //         }
  //     }
  // }


  isPairFavorite(pair: Pair): boolean | undefined {
    for (const item of this.pair_list) {
      if (item[0] === pair.id[0] && item[1] === pair.id[1]) {
        return true;
      }
    }
    return false;
  }

  isAuctionProductFavorite(auction_product: AuctionProduct): boolean | undefined {
    for (const item of this.auction_product_list) {
      if (item === auction_product.id) {
        return true;
      }
    }
    return false;
  }

  getArtProductAvailableAmount(pair: Pair): number | undefined {
    for (const art_product of this.art_product_list) {
      if (art_product.art_product_id === pair.id[0]) {
        return art_product.available_amount;
      }
    }
    return undefined;
  }

  getCurrencyAvailableAmount(pair: Pair): number | undefined {
    for (const currency of this.currency_list) {
      if (currency.currency_id === pair.id[1]) {
        return currency.available_amount;
      }
    }
    return undefined;
  }

  getTRYAvailableAmount(): number {
    for (const currency_wallet of this.currency_list) {
      if (currency_wallet.currency_id === 0) {
        return currency_wallet.available_amount;
      }
    }
    return 0;
  }

  getCryptocurrencyAvailableAmount(currency_id: number): number {
    for (const currency_wallet of this.currency_list) {
      if (currency_wallet.currency_id === currency_id) {
        return currency_wallet.available_amount;
      }
    }
    return 0;
  }

}

export class UserLoginData {
  private readonly user_email: string;
  private readonly password: string;

  // todo expire_time

  constructor(user_verification_credentials_primitive: any) {
    this.user_email = user_verification_credentials_primitive.user_email;
    this.password = user_verification_credentials_primitive.password;
  }

  get getEmail(): string {
    return this.user_email;
  }

  get getPassword(): string {
    return this.password;
  }
}

export class UserAccountVerificationCredentials {
  private readonly user_email: string;
  private readonly verification_code: string;

  constructor(user_verification_credentials_primitive: {user_email: string, verification_code: string}) {
    this.user_email = user_verification_credentials_primitive.user_email;
    this.verification_code = user_verification_credentials_primitive.verification_code;
  }

  get getEmail(): string {
    return this.user_email;
  }

  get getVerificationCode(): string {
    return this.verification_code;
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public user: User | undefined;
  public user_info: UserInfo | undefined;
  public user_art_product_deposits: ArtProductOperation[] = [];
  public user_art_product_withdrawals: ArtProductOperation[] = [];
  public user_currency_deposits: CurrencyOperation[] = [];
  public user_currency_withdrawals: CurrencyWithdrawalOperation[] = [];
  public user_open_buy_orders: Order[] = [];
  public user_open_sell_orders: Order[] = [];
  public user_trade_history: UserTrade[] = [];
  public user_swap_history: UserSwap[] = [];
  public user_bids: UserBid[] = [];
  public user_balance_history: BalanceEntry[] = [];
  public user_activity_records: ActivityRecord[] = [];
  public user_bank_accounts: UserBankAccount[] = [];
  public user_crypto_accounts: UserCryptoAccount[] = [];
  public user_deliveries: Delivery[] = [];
  public user_account_verification_credentials: UserAccountVerificationCredentials | undefined;


  @Output() user_changed: EventEmitter<any> = new EventEmitter();
  @Output() user_info_changed: EventEmitter<any> = new EventEmitter();
  @Output() user_balance_history_chart_changed: EventEmitter<any> = new EventEmitter();
  @Output() user_balance_pie_chart_changed: EventEmitter<any> = new EventEmitter();
  @Output() user_bank_accounts_changed: EventEmitter<any> = new EventEmitter();
  @Output() user_crypto_account_changed: EventEmitter<any> = new EventEmitter();
  @Output() user_bank_account_added: EventEmitter<any> = new EventEmitter();
  @Output() user_crypto_account_added: EventEmitter<any> = new EventEmitter();
  @Output() user_deliveries_changed: EventEmitter<any> = new EventEmitter();
  @Output() selected_user_delivery_address_changed: EventEmitter<DeliveryAddress | null> = new EventEmitter<DeliveryAddress | null>();
  @Output() selected_user_billing_address_changed: EventEmitter<BillingAddress | null> = new EventEmitter<BillingAddress | null>();
  @Output() user_account_verification_credentials_changed: EventEmitter<any> = new EventEmitter<any>();

  public user_ready: boolean = false;
  public user_info_ready: boolean = false;
  public user_art_product_operations_ready: boolean = false;
  public user_currency_operations_ready: boolean = false;
  public user_operation_history_ready: boolean = false;
  public user_open_buy_orders_ready: boolean = false;
  public user_open_sell_orders_ready: boolean = false;
  public user_open_orders_ready: boolean = false;
  public user_trade_history_ready: boolean = false;
  public user_swap_history_ready: boolean = false;
  public user_bids_ready: boolean = false;
  public user_balance_history_ready: boolean = false;
  public user_activity_records_ready: boolean = false;
  public user_bank_accounts_ready: boolean = false;
  public user_crypto_accounts_ready: boolean = false;
  public user_deliveries_ready: boolean = false;

  public selected_user_address_id: number = -1;
  public selected_user_address_type: 'delivery' | 'billing' = 'delivery';
  public selected_user_address_operation: 'add' | 'update' = 'add';

  public selected_delivery_id: number | undefined;
  public selected_delivery_type: DeliveryType | undefined;
  public selected_delivery_address_id: number | undefined;
  public selected_billing_address_id: number | undefined;

  public is_admin: boolean = false;
  public environment = environment;
  private user_login_data: UserLoginData | undefined;

  constructor(private utilityService: UtilityService, private httpClient: HttpClient) {
  }

  getUser(): User | undefined {
    return this.user;
  }

  setUser(user_primitive: any): void {
    this.user = new User(user_primitive);
    this.user_changed.emit();
  }

  getUserInfo(): UserInfo | undefined {
    return this.user_info;
  }

  setUserInfo(user_info_primitive: any): void {
    this.user_info = new UserInfo(user_info_primitive);
    this.user_info_changed.emit();
  }

  getUserLoginData(): UserLoginData | undefined {
    if (!this.user_login_data && this.user_account_verification_credentials) {
      this.readUserLoginData(this.user_account_verification_credentials.getEmail);
    }
    return this.user_login_data;
  }

  createUserLoginData(user_verification_credentials_primitive: { user_email: string, password: string }): void {
    this.user_login_data = new UserLoginData(user_verification_credentials_primitive);
    // this.user_verification_credentials_changed.emit();
    this.writeUserLoginData(this.user_login_data);
  }

  writeUserLoginData(user_verification_credentials: UserLoginData): void {
    localStorage.setItem('user_verification_credentials', this.utilityService.encryptAES(JSON.stringify(this.user_login_data), user_verification_credentials.getEmail));
  }

  readUserLoginData(email: string): void {
    const temp = localStorage.getItem('user_verification_credentials');
    if (temp) {
      this.user_login_data = new UserLoginData(JSON.parse(this.utilityService.decryptAES(temp, email)));
    }
  }

  deleteUserLoginData(): void {
    localStorage.removeItem('user_verification_credentials');
    delete this.user_login_data;
    // delete this.user_account_verification_credentials;
  }

  getUserCurrencyWalletByID(currency_id: number): CurrencyWallet | undefined {
    if (this.user !== undefined) {
      for (const currency_wallet of this.user.currency_list) {
        if (currency_wallet.currency_id === currency_id) {
          return currency_wallet;
        }
      }
      return undefined;
    } else {
      return undefined;
    }
  }

  getUserArtProductWalletByID(art_product_id: number): ArtProductWallet | undefined {
    if (this.user !== undefined) {
      for (const art_product_wallet of this.user.art_product_list) {
        if (art_product_wallet.art_product_id === art_product_id) {
          return art_product_wallet;
        }
      }
      return undefined;
    } else {
      return undefined;
    }
  }

  getUserArtProductDeposits(): ArtProductOperation[] {
    return this.user_art_product_deposits;
  }

  setUserArtProductDeposits(user_art_product_deposits_primitive: any[]): void {
    this.user_art_product_deposits = user_art_product_deposits_primitive.map(x => new ArtProductOperation(x));
  }

  getUserArtProductWithdrawals(): ArtProductOperation[] {
    return this.user_art_product_withdrawals;
  }

  setUserArtProductWithdrawals(user_art_product_withdrawals_primitive: any[]): void {
    this.user_art_product_withdrawals = user_art_product_withdrawals_primitive.map(x => new ArtProductOperation(x));
  }

  getUserCurrencyDeposits(): CurrencyOperation[] {
    return this.user_currency_deposits;
  }

  setUserCurrencyDeposits(user_currency_deposits_primitive: any[]): void {
    this.user_currency_deposits = user_currency_deposits_primitive.map(x => new CurrencyOperation(x));
  }

  getUserCurrencyWithdrawals(): CurrencyOperation[] {
    return this.user_currency_withdrawals;
  }

  setUserCurrencyWithdrawals(user_currency_withdrawals_primitive: any[]): void {
    this.user_currency_withdrawals = user_currency_withdrawals_primitive.map(x => new CurrencyWithdrawalOperation(x));
  }

  getUserOpenBuyOrders(): Order[] {
    return this.user_open_buy_orders;
  }

  setUserOpenBuyOrders(user_open_buy_orders_primitive: any[]): void {
    this.user_open_buy_orders = user_open_buy_orders_primitive.map(x => new Order(x));
  }

  getUserOpenSellOrders(): Order[] {
    return this.user_open_sell_orders;
  }

  setUserOpenSellOrders(user_open_sell_orders_primitive: any[]): void {
    this.user_open_sell_orders = user_open_sell_orders_primitive.map(x => new Order(x));
  }

  getUserOpenOrders(): Order[] {
    return this.user_open_buy_orders.concat(this.user_open_sell_orders).sort((x, y) => {
      return y.creation_time - x.creation_time;
    });
  }

  filterUserOpenBuyOrders(pair_id: [number, number]): Order[] {
    return this.user_open_buy_orders.filter((x) => {
      if (pair_id[0] === -1 && pair_id[1] === -1) {
        return true;
      } else if (x.pair_id[0] === pair_id[0] && x.pair_id[1] === pair_id[1]) {
        return true;
      } else {
        return false;
      }
    });
  }

  filterUserOpenSellOrders(pair_id: [number, number]): Order[] {
    return this.user_open_sell_orders.filter((x) => {
      if (pair_id[0] === -1 && pair_id[1] === -1) {
        return true;
      } else {
        return x.pair_id[0] === pair_id[0] && x.pair_id[1] === pair_id[1];
      }
    });
  }

  filterUserOpenOrders(pair_id: [number, number], type?: string): Order[] {
    if (type === 'buy') {
      return this.filterUserOpenBuyOrders(pair_id);
    } else if (type === 'sell') {
      return this.filterUserOpenSellOrders(pair_id);
    } else {
      return this.getUserOpenOrders().filter((x) => {
        if (pair_id[0] === -1 && pair_id[1] === -1) {
          return true;
        } else {
          return x.pair_id[0] === pair_id[0] && x.pair_id[1] === pair_id[1];
        }
      });
    }
  }

  removeUserOpenOrder(order: Order): void {
    if (order.type === 'buy') {
      this.user_open_buy_orders.splice(this.user_open_buy_orders.indexOf(order), 1);
    } else if (order.type === 'sell') {
      this.user_open_sell_orders.splice(this.user_open_sell_orders.indexOf(order), 1);
    }
  }

  getUserTradeHistory(): UserTrade[] {
    return this.user_trade_history;
  }

  getUserSwapHistory(): UserSwap[] {
    return this.user_swap_history;
  }

  groupByKey(xs: any, key: any): any {
    return Object.values(xs.reduce((rv: any, x: any) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {}));
  }

  setUserTradeHistory(user_trade_history_primitive: any[]): void {
    this.user_trade_history = [];
    this.groupByKey(user_trade_history_primitive, 'pair_id').forEach((array: any) => {
      const user_buy_trade_list = JSON.parse(JSON.stringify(array.filter((x: any) => x.buyer_id === this.user?.id && x.seller_id !== this.user?.id)));
      const user_sell_trade_list = JSON.parse(JSON.stringify(array.filter((x: any) => x.buyer_id !== this.user?.id && x.seller_id === this.user?.id)));
      const user_own_trade_list = JSON.parse(JSON.stringify(array.filter((x: any) => x.buyer_id === this.user?.id && x.seller_id === this.user?.id)));
      const user_own_trade_list_copy = JSON.parse(JSON.stringify(array.filter((x: any) => x.buyer_id === this.user?.id && x.seller_id === this.user?.id)));
      this.groupByKey(user_buy_trade_list, 'buy_order_id').forEach((_array: any) => {
        const _element = _array[0];
        for (let i = 1; i < _array.length; i++) {
          _element.price = (_element.price * _element.amount + _array[i].price * _array[i].amount) / (_element.amount + _array[i].amount);
          _element.amount += _array[i].amount;
        }
        this.user_trade_history.push(new UserTrade(_element));
      });
      this.groupByKey(user_sell_trade_list, 'sell_order_id').forEach((_array: any) => {
        const _element = _array[0];
        for (let i = 1; i < _array.length; i++) {
          _element.price = (_element.price * _element.amount + _array[i].price * _array[i].amount) / (_element.amount + _array[i].amount);
          _element.amount += _array[i].amount;
        }
        this.user_trade_history.push(new UserTrade(_element));
      });
      this.groupByKey(user_own_trade_list, 'buy_order_id').forEach((_array: any) => {
        const _element = _array[0];
        _element.side = 'buy';
        for (let i = 1; i < _array.length; i++) {
          _element.price = (_element.price * _element.amount + _array[i].price * _array[i].amount) / (_element.amount + _array[i].amount);
          _element.amount += _array[i].amount;
        }
        this.user_trade_history.push(new UserTrade(_element));
      });
      this.groupByKey(user_own_trade_list_copy, 'sell_order_id').forEach((_array: any) => {
        const _element = _array[0];
        _element.side = 'sell';
        for (let i = 1; i < _array.length; i++) {
          _element.price = (_element.price * _element.amount + _array[i].price * _array[i].amount) / (_element.amount + _array[i].amount);
          _element.amount += _array[i].amount;
        }
        this.user_trade_history.push(new UserTrade(_element));
      });
    });
    this.user_trade_history = this.user_trade_history.sort((x, y) => {
      return y.creation_time - x.creation_time;
    });
  }

  filterUserTradeHistory(pair_id: [number, number], active_sides: string[] = ['buy', 'sell']): UserTrade[] {
    return this.user_trade_history.filter((x) => {
      if (pair_id[0] === -1 && pair_id[1] === -1) {
        return true;
      } else {
        return x.pair_id[0] === pair_id[0] && x.pair_id[1] === pair_id[1];
      }
    }).filter(x => active_sides.includes(x.side));
  }

  setUserSwapHistory(user_swap_history_primitive: any[]): void {
    this.user_swap_history = user_swap_history_primitive.sort((x, y) => {
      return y.creation_time - x.creation_time;
    }).map(x => new UserSwap(x));
  }

  setUserBids(user_bids_primitive: any[]): void {
    this.user_bids = user_bids_primitive.sort((x, y) => {
      return y.creation_time - x.creation_time;
    }).map(x => new UserBid(x));
  }

  public getUserBalanceHistory(): BalanceEntry[] {
    return this.user_balance_history;
  }

  public setUserBalanceHistory(user_balance_history_primitive: any[]): void {
    this.user_balance_history = user_balance_history_primitive.map(x => new BalanceEntry(x));
  }

  public getUserDeliveries(): Delivery[] {
    return this.user_deliveries;
  }

  public setUserDeliveryInfo(user_delivery_info_primitive: any[]): void {
    this.user_deliveries = user_delivery_info_primitive.map(x => new Delivery(x));
    this.user_deliveries_changed.emit();
  }

  getUserActivityRecords(): ActivityRecord[] {
    return this.user_activity_records;
  }

  setUserActivityRecords(user_activity_records_primitive: any[]): void {
    this.user_activity_records = user_activity_records_primitive.map(x => new ActivityRecord(x));
  }

  getUserBankAccounts(): UserBankAccount[] {
    return this.user_bank_accounts;
  }

  getCryptoAccounts(): UserCryptoAccount[] {
    return this.user_crypto_accounts;
  }

  setUserBankAccounts(user_bank_accounts_primitive: any[]): void {
    this.user_bank_accounts = user_bank_accounts_primitive.map(x => new UserBankAccount(x));
  }

  setUserCryptoAccounts(user_crypto_accounts_primitive: any[]): void {
    this.user_crypto_accounts = user_crypto_accounts_primitive.map(x => new UserCryptoAccount(x));
  }

  refreshUser(resolve?: any, reject?: any): void {
    const data = {};
    this.httpClient.post<any>(this.environment.serverAPI + 'get_user_service', data).subscribe(
      response => {
        if (response.is_okay !== undefined && !response.is_okay) {
          reject();
        } else {
          this.setUser(response);
          this.user_ready = true;
          this.user_balance_pie_chart_changed.emit();
          if (resolve !== undefined) {
            resolve();
          }
        }
      }, error => {
        reject();
      });
  }

  refreshUserInfo(resolve?: any, reject?: any): void {
    const data = {};
    this.httpClient.post<any>(this.environment.serverAPI + 'get_user_information_service', data).subscribe(
      response => {
        if (response.is_okay !== undefined && !response.is_okay) {
          if (response.code === 234) {  // lvl 1 req
            resolve();
          } else {
            reject();
          }
        } else {
          this.setUserInfo(response);
          this.user_info_ready = true;
          this.user_info_changed.emit();
          if (resolve !== undefined) {
            resolve();
          }
        }
      }, error => {
        reject();
      });
  }


  // refreshUserBillingAddress(resolve?: any, reject?: any): void {
  //     const data = {};
  //     this.httpClient.post<any>(this.environment.serverAPI + 'get_user_billing_address_service', data).subscribe(
  //         response => {
  //             if (response.is_okay !== undefined && !response.is_okay) {
  //                 reject();
  //             } else {
  //                 console.log('billing', response);
  //                 this.setUserBillingAddress(response);
  //                 this.user_billing_address_ready = true;
  //                 if (resolve !== undefined) {
  //                     resolve();
  //                 }
  //             }
  //         }, error => {
  //             reject();
  //         });
  // }
  // refreshUserDeliveryAddress(resolve?: any, reject?: any): void {
  //     const data = {};
  //     this.httpClient.post<any>(this.environment.serverAPI + 'get_user_delivery_address_service', data).subscribe(
  //         response => {
  //             if (response.is_okay !== undefined && !response.is_okay) {
  //                 reject();
  //             } else {
  //                 console.log('delivery', response);
  //                 this.setUserDeliveryAddress(response);
  //                 this.user_delivery_address_ready = true;
  //                 if (resolve !== undefined) {
  //                     resolve();
  //                 }
  //             }
  //         }, error => {
  //             reject();
  //         });
  // }

  refreshUserArtProductOperationHistory(resolve?: any): void {
    const data = {};
    this.httpClient.post<any[]>(this.environment.serverAPI + 'get_user_art_product_operation_history_service', data).subscribe(
      response => {
        this.setUserArtProductDeposits(response.filter(x => x.type === 'deposit'));
        this.setUserArtProductWithdrawals(response.filter(x => x.type === 'withdrawal'));
        this.user_art_product_operations_ready = true;
        this.user_operation_history_ready = this.user_art_product_operations_ready && this.user_currency_operations_ready;
        if (resolve !== undefined) {
          resolve();
        }
      });
  }

  refreshUserCurrencyOperationHistory(resolve?: any): void {
    const data = {};
    this.httpClient.post<Array<any>>(this.environment.serverAPI + 'get_user_currency_operation_history_service', data).subscribe(
      response => {
        this.setUserCurrencyDeposits(response.filter(x => x.type === 'deposit'));
        this.setUserCurrencyWithdrawals(response.filter(x => x.type === 'withdrawal'));
        this.user_currency_operations_ready = true;
        this.user_operation_history_ready = this.user_art_product_operations_ready && this.user_currency_operations_ready;
        if (resolve !== undefined) {
          resolve();
        }
      });
  }

  refreshUserOpenOrders(resolve?: any): void {
    const data = {};
    this.httpClient.post<Array<any>>(this.environment.serverAPI + 'get_user_open_orders_service', data).subscribe(
      response => {
        this.setUserOpenBuyOrders(response.filter(x => x.type === 'buy'));
        this.setUserOpenSellOrders(response.filter(x => x.type === 'sell'));
        this.user_open_buy_orders_ready = true;
        this.user_open_sell_orders_ready = true;
        this.user_open_orders_ready = true;
        if (resolve !== undefined) {
          resolve();
        }
      });
  }

  refreshUserTradeHistory(resolve?: any): void {
    const data = {};
    this.httpClient.post<Array<any>>(this.environment.serverAPI + 'get_user_trade_history_service', data).subscribe(
      response => {
        this.setUserTradeHistory(response.sort((x, y) => {
            return y.creation_time - x.creation_time;
          })
        );
        this.user_trade_history_ready = true;
        if (resolve !== undefined) {
          resolve();
        }
      });
  }

  refreshUserSwapHistory(resolve?: any): void {
    const data = {};
    this.httpClient.post<Array<any>>(this.environment.serverAPI + 'get_user_swap_history_service', data).subscribe(
      response => {
        this.setUserSwapHistory(response);
        this.user_swap_history_ready = true;
        if (resolve !== undefined) {
          resolve();
        }
      });
  }

  refreshUserBids(resolve?: any): void {
    const data = {};
    this.httpClient.post<Array<any>>(this.environment.serverAPI + 'get_user_bids_service', data).subscribe(
      response => {
        this.setUserBids(response);
        this.user_bids_ready = true;
        if (resolve !== undefined) {
          resolve();
        }
      }
    );
  }

  refreshUserBalanceHistory(resolve?: any): void {
    const data = {};
    this.httpClient.post<Array<any>>(this.environment.serverAPI + 'get_user_balance_history_service', data).subscribe(
      response => {
        this.setUserBalanceHistory(response);
        this.user_balance_history_ready = true;
        this.user_balance_history_chart_changed.emit();
        if (resolve !== undefined) {
          resolve();
        }
      });
  }

  refreshUserActivityRecords(resolve?: any): void {
    const data = {};
    this.httpClient.post<Array<any>>(this.environment.serverAPI + 'get_user_activity_records_service', data).subscribe(
      response => {
        this.setUserActivityRecords(response);
        this.user_activity_records_ready = true;
        if (resolve !== undefined) {
          resolve();
        }
      });
  }

  refreshUserBankAccounts(resolve?: any): void {
    const data = {};
    this.httpClient.post<Array<any>>(this.environment.serverAPI + 'get_user_bank_account_list_service', data).subscribe(
      response => {
        this.setUserBankAccounts(response);
        this.user_bank_accounts_ready = true;
        if (resolve !== undefined) {
          resolve();
        }
      });
  }

  refreshUserCryptoAccounts(resolve?: any): void {
    const data = {};
    this.httpClient.post<Array<any>>(this.environment.serverAPI + 'get_user_crypto_account_list_service', data).subscribe(
      response => {
        this.setUserCryptoAccounts(response);
        this.user_crypto_accounts_ready = true;
        if (resolve !== undefined) {
          resolve();
        }
      });
  }


  refreshUserDeliveryInfo(resolve?: any): void {
    const data = {};
    this.httpClient.post<Array<any>>(this.environment.serverAPI + 'get_user_deliveries_service', data).subscribe(
      response => {
        this.setUserDeliveryInfo(response);
        this.user_deliveries_ready = true;
        // this.user_deliveries_changed.emit();
        if (resolve !== undefined) {
          resolve();
        }
      });
  }


  cancelUserOrder(order: Order, resolve?: any): void {
    this.removeUserOpenOrder(order);
    const data = {pair_id: order.pair_id, order_id: order.id, order_type: order.type};
    this.httpClient.post<any>(this.environment.serverAPI + 'cancel_user_order_service', data).subscribe(
      response => {
        if (resolve !== undefined) {
          resolve(response);
        }
      }
    );
  }

  cancelUserOrders(resolve?: any): void {
    this.user_open_buy_orders = [];
    this.user_open_sell_orders = [];
    const data = {};
    this.httpClient.post<any>(this.environment.serverAPI + 'cancel_user_orders_service', data).subscribe(
      response => {
        if (resolve !== undefined) {
          resolve(response);
        }
      }
    );
  }

  isAuctionRulesAccepted(resolve?: any, reject?: any): void {
    const data = {email: this.user?.email};
    this.httpClient.post<any>(this.environment.serverAPI + 'is_auction_rules_accepted_service', data).subscribe(response => {
      if (response.auction_rules_accepted) {
        resolve();
      } else {
        reject();
      }
    });
  }

  setAuctionRulesAccepted(resolve: any, reject: any): void {
    const data = {email: this.user?.email};
    this.httpClient.post<any>(this.environment.serverAPI + 'set_auction_rules_accepted_service', data).subscribe(response => {
      if (response.auction_rules_accepted) {
        resolve();
      } else {
        reject();
      }
    });
  }

  isUserAgreementAccepted(resolve?: any, reject?: any): void {
    const data = {email: this.user?.email};
    this.httpClient.post<any>(this.environment.serverAPI + 'is_user_agreement_accepted', data).subscribe(response => {
      if (response.user_agreement_accepted) {
        resolve();
      } else {
        reject();
      }
    });
  }

  setUserAgreementAccepted(resolve: any, reject: any): void {
    const data = {email: this.user?.email};
    this.httpClient.post<any>(this.environment.serverAPI + 'set_user_agreement_accepted', data).subscribe(response => {
      if (response.user_agreement_accepted) {
        localStorage.setItem('user_agreement_checked', 'true');
        resolve();
      } else {
        reject();
      }
    });
  }

  setUserAccountVerificationCredentials(user_email: string, verification_code: string): void{
    this.user_account_verification_credentials =  new UserAccountVerificationCredentials({user_email, verification_code});
    this.user_account_verification_credentials_changed.emit();
  }

  userLogout(): void {
    this.user = undefined;
    this.user_art_product_deposits = [];
    this.user_art_product_withdrawals = [];
    this.user_currency_deposits = [];
    this.user_currency_withdrawals = [];
    this.user_open_buy_orders = [];
    this.user_open_sell_orders = [];
    this.user_trade_history = [];
    this.user_balance_history = [];
    this.user_activity_records = [];
    this.user_bank_accounts = [];
    this.user_deliveries = [];

    this.user_ready = false;
    this.user_art_product_operations_ready = false;
    this.user_currency_operations_ready = false;
    this.user_operation_history_ready = false;
    this.user_open_buy_orders_ready = false;
    this.user_open_sell_orders_ready = false;
    this.user_open_orders_ready = false;
    this.user_trade_history_ready = false;
    this.user_balance_history_ready = false;
    this.user_activity_records_ready = false;
    this.user_bank_accounts_ready = false;
    this.user_deliveries_ready = false;

    this.user_balance_history_chart_changed.emit();
    this.user_balance_pie_chart_changed.emit();
  }


  checkAdmin(resolve?: any, reject?: any): void {
    const data = {};
    this.httpClient.post<any>(this.environment.serverAPI + 'admin_service', data).subscribe(
      response => {
        if (response.is_okay) {
          this.is_admin = true;
          if (resolve !== undefined) {
            resolve();
          }
        } else {
          this.is_admin = false;
          if (reject !== undefined) {
            reject();
          }
        }
      });
  }

  isAdmin(): boolean {
    return this.is_admin;
  }
}
