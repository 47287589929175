import {Component, HostListener, Input, ViewChild, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-support-drawer',
  templateUrl: './support-drawer.component.html',
  standalone: true,
  styleUrls: ['./support-drawer.component.css']
})
export class SupportDrawerComponent {

  @Input('drawerHeader') drawerHeader: string = '';
  @Input('drawerText') drawerText: string = '';
  @ViewChild('arrow') arrow: any;
  @ViewChild('body') body: any;

  @HostListener('click') onClick(): void{
    this.arrow.nativeElement.classList.toggle('clicked');
    this.body.nativeElement.classList.toggle('clicked');
  }

}
