<div class="d-flex w-100 flex-column justify-content-center" style="margin-top: 10px;">
    <div *ngIf="user_following_auction_products.length === 0"
         class="px-4" style="box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22);  background-color: #fcfdff; min-height: 650px">
        <div class="pt-3 mb-2" style="font-family: Roboto, sans-serif; font-size: 20px; font-weight: bold; line-height: 2; letter-spacing: 1px; color: #4a4e70;">
            {{managerService.config.ACCOUNT_AUCTION.NO_USER_FOLLOWING_HEADING | managerPipe}}
        </div>
        <div class="my-2" style="font-family: Roboto, sans-serif; font-size: 14px; color: #4a4e70;">
            {{managerService.config.ACCOUNT_AUCTION.NO_USER_FOLLOWING_DESCRIPTION | managerPipe}}
        </div>

        <button (click)="managerService.navigationManager({url: '/auction', current_component_index: 0});"
                class="btn-native sign-up-login-button mx-4 my-3"
                style="height: 40px; width:160px; border-radius: 20px;
              font-family: Roboto, sans-serif; font-size: 16px; line-height: 1.3; letter-spacing: 0.3px; color: #ffffff;">
            {{managerService.config.ACCOUNT_AUCTION.SEE_ARTWORKS | managerPipe}}
        </button>
    </div>

    <div
        *ngFor="let auction_product of user_following_auction_products"
        [ngClass]="auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_STATUS_STYLE"
        class="d-flex  flex-column  justify-content-center align-items-center lot-card"
        style="margin-bottom: 10px;margin-left: 20px;margin-right: 20px;  border-radius: 4px; box-shadow: 5px 5px 10px 0 rgba(45, 52, 104, 0.5); position: relative; padding-left: 10px; padding-right: 10px; ">

        <!--card background-->
        <div #favCard class="flex-column w-100 pb-2" style="display: flex">
            <!--Lot Number div-->

            <div class="d-flex justify-content-center self align-items-center mb-4">
                <div class="lot-number"
                     style="background: #2AD8A9; box-shadow: 0px 4px 4px 0px #FFFFFF40; border-radius: 0px 0px 4px 4px; gap: 8px; color: #FFFFFF; width: 80px;
                   height: 21px; text-align: center; align-content: center;
                   font-weight: 700; font-size: 10px; line-height: 20px; letter-spacing: 0.25px">
                    LOT {{auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_LOT }}
                </div>
            </div>
            <div (click)="toggleFollowedProduct(auction_product, undoCard, favCard)" class="d-flex" style="cursor: pointer;position: absolute;left: 36px;top: 36px">
                <svg style=" fill: #ffbc04;height: 25px; width: 25px;">
                    <use [attr.xlink:href]='"./assets/images/star.svg#star"'/>
                </svg>
            </div>
            <div class="d-flex flex-column justify-content-between align-items-center w-100 h-100">
                <div class="d-flex w-100 flex-column justify-content-center align-items-center" style="gap: 24px">
                    <div class="d-flex">
                        <img #catalog_img
                             (error)="catalog_img.src = catalog_img.src.replace('.webp', '.jpg');" alt="catalog_img"
                             src="{{auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_THUMBNAIL | managerPipe: managerService.mpa_template.LOCAL_IMAGE_MANAGER_TO_BE}}"
                             style="max-width: 140px;max-height: 140px;border-radius: 0.375rem;background:#FCFDFF;box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.15); object-position:center;overflow: clip"
                        >
                    </div>
                    <div class="d-flex flex-column h-100 justify-content-between ">
                        <div style="font-family: Roboto,sans-serif; text-align: center; max-width: 200px">
                            <div class="mb-2 line-clamp-1"
                                 style="font-family: Roboto, sans-serif;font-style: normal;font-weight: 500;font-size: 14px;line-height: 16px;letter-spacing: 0.001em;color: #404352;">
                                {{ auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_ARTIST_NAME }}
                            </div>
                            <div class="mb-2 line-clamp-1"
                                 style="font-family: Roboto, sans-serif;font-style: italic;font-weight: 500;font-size: 13px;line-height: 15px;letter-spacing: 0.001em;color: #686C75;">
                                {{ auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_NAME }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-row justify-content-center align-items-center" style="gap: 8px;margin-top: 24px">
                    <div class="my-auto text-center">
                        <div class="mb-1"
                             style="color: #49566B;font-family: Roboto,sans-serif;font-size: 1rem;font-style: normal;font-weight: 700;line-height: normal;letter-spacing: 0.00131rem;">{{auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_OPENING_VALUE | managerPipe: managerService.mpa_template.FANCY_NUMBER_NO_DECIMAL}}
                            TL
                        </div>
                        <div
                            style="font-family: Roboto, sans-serif;color: #AAAEB5;font-style: normal;font-weight: 400;font-size: 12px;line-height: 14px;display: flex;align-items: center;letter-spacing: 0.06px;">{{managerService.config.ACCOUNT_AUCTION.GIVEN_BID | managerPipe}}</div>
                    </div>
                    <span style="width: 1px; height: 40px; background-color: #d8d8d8;margin-left: 24px;margin-right: 24px;"></span>
                    <div class="my-auto text-center">
                        <div class="mb-1"
                             style="font-family: Roboto, sans-serif;font-style: normal;font-weight: 700;font-size: 16px;line-height: 19px;letter-spacing: 0.0015em;color: #28A79B;">{{auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_CURRENT_PRICE}}

                        </div>
                        <div
                            style="font-family: Roboto, sans-serif;font-style: normal;font-weight: 400;font-size: 12px;line-height: 14px;letter-spacing: 0.06px;color: #AAAEB5;">{{managerService.config.ACCOUNT_AUCTION.CURRENT_BID | managerPipe}}</div>
                    </div>
                </div>
                <div class="d-flex w-100 justify-content-center align-items-center" style="padding-left: 36px;padding-right: 36px;margin-top: 24px; margin-bottom: 24px">
                    <div class="w-100 d-flex justify-content-center align-items-center"
                         (click)="managerService.navigateIfLoggedIn({url: '/auction/auction_product', active_auction_product_hash: auction_product.hash, current_component_index: 3});"
                         style="cursor: pointer;padding:8px; border-radius: 4px;background: #28A79B;box-shadow: 1px 1px 2px 0px #D5D8E2;color: #FFF;font-family: Roboto,sans-serif;font-size: 1rem;font-style: normal;font-weight: 400;line-height: 1.1875rem; /* 135.714% */">
                        {{managerService.config.ACCOUNT_AUCTION.PRODUCT_DETAIL | managerPipe}}
                    </div>

                </div>
            </div>
        </div>
        <div #undoCard  class="flex-column w-100 py-4 align-items-end justify-content-center" style="display: none;">
            <div class="d-flex w-25">
                <div class="d-flex w-100 justify-content-center align-items-center"
                     (click)="toggleFollowedProduct(auction_product, favCard, undoCard)"
                     style="cursor: pointer;color: #404352;font-family: Roboto,sans-serif;font-size: 14px;font-style: normal;font-weight: 500;line-height: 19px;">
                    Geri Al
                    <svg style="height: 20px; width: 20px;">
                        <use [attr.xlink:href]='"./assets/images/undo.svg#undo"' fill="#9DA5B7"/>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</div>
