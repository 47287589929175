import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ManagerService} from "../../../../../services/manager.service";

@Component({
  selector: 'app-auction-duration-rules-mobile',
  templateUrl: './auction-duration-rules-mobile.component.html',
  styleUrls: ['./auction-duration-rules-mobile.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class AuctionDurationRulesMobileComponent implements OnInit {

  @Input() modal_id: string = '';

  constructor(public managerService: ManagerService) {
  }


  ngOnInit(): void {
  }

}
