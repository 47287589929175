import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
  selector: 'app-pre-sale-header-mobile',
  templateUrl: './pre-sale-header-mobile.component.html',
  styleUrls: ['./pre-sale-header-mobile.component.css']
})
export class PreSaleHeaderMobileComponent implements OnInit {

  public info_swap: boolean = false;

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

  swap(): void {
    this.info_swap = !this.info_swap;
  }
}
