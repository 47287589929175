import {Component, ElementRef, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
  selector: 'app-pre-sale-art-product-report-list-desktop',
  templateUrl: './pre-sale-art-product-report-list-desktop.component.html',
  styleUrls: ['./pre-sale-art-product-report-list-desktop.component.css']
})
export class PreSaleArtProductReportListDesktopComponent implements OnInit {

  constructor(public managerService: ManagerService, public element: ElementRef) {
    this.element.nativeElement.addEventListener('click', (el: any) => {
      if (el.target.id === 'art_product_info_container') {
        this.managerService.art_product_info_display_status = false;
      }
    });
  }

  ngOnInit(): void {
  }

}
