<div class="p-5"style="max-width: 600px">
  <div class="d-flex w-100 justify-content-end">
    <svg style="margin-top: -16px; margin-right: -8px; height: 18px; width: 18px;" (click)="managerService.closeModal(modal_id)">
      <use [attr.xlink:href]='"./assets/images/exit_icon.svg#exit_icon"' fill="#404352"/>
    </svg>
  </div>
  <div class="mx-5 mt-3 mb-4">
    <div *ngFor="let item of managerService.config.ONE_PAGER.DESCRIPTION | managerPipe" class="mb-3" [style.font-weight]="item.WEIGHT | managerPipe">
      {{item.TEXT | managerPipe}}
    </div>
    <div class="w-100 d-flex justify-content-center mt-5">
      <a href="{{managerService.config.ONE_PAGER.BUTTON_LINK.url | managerPipe}}" class="btn-native sign-up-login-button mx-auto py-2"
         style="font-family: Roboto, sans-serif; font-size: 13px; color: #ffffff; border-radius: 6px; font-weight: 500; letter-spacing: 1.3px; text-decoration: none; padding: 60px"
         type="button">{{managerService.config.ONE_PAGER.BUTTON_TEXT | managerPipe}}</a>
    </div>
  </div>
</div>
