<div style="width: 557px; background: #FFFFFF;height: 100vh">

    <div *ngIf="!is_max_bidder || approved" class="d-flex w-100 flex-column">
        <div class="d-flex w-100 justify-content-center">
            <div class="d-flex flex-column" style="gap: 16px;padding: 64px 48px 48px 48px">
                <div class="d-flex header6 exo bold"
                   style="color: #8266F0">{{managerService.config.AUCTION.BID_REPORT.TITLE | managerPipe}}</div>
                <div class="d-flex body2 barlow regular"
                   style="color: #6F6F6F">
                    {{[managerService.auctionService.active_auction_product.year, managerService.auctionService.active_auction_product.lot] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.AUCTION.BID_REPORT.DESCRIPTION}}
                </div>
            </div>
        </div>
        <div class="pl-5 pr-5 mb-3 w-100 d-flex flex-column align-items-start justify-content-center">
            <div class="d-flex w-100 align-items-stretch justify-content-between">
                <div class=" d-flex w-100 justify-content-between">
                 <span class="barlow subtitle1 medium"
                     style="display: flex;align-items: center;text-align: center;color: #B5B5B5;">
                     {{managerService.config.AUCTION.BID_REPORT.BID_PRICE | managerPipe}}
                 </span>
                    <span style="color: #6F6F6F">
                    {{managerService.auctionService.active_bid_price_pipe | managerPipe: managerService.mpa_template.ACTIVE_BID_PRICE: managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
                        {{managerService.auctionService.active_auction_product.value_type | managerPipe: managerService.mpa_template.VALUE_TYPE}}
                </span>
                </div>
            </div>
        </div>

        <div class="pl-5 pr-5 mb-3 w-100 d-flex flex-column align-items-start justify-content-center">
            <div class="d-flex w-100 align-items-stretch justify-content-between">
                <div class=" d-flex w-100 justify-content-between">
                 <span class="barlow subtitle1 medium"
                     style="display: flex;align-items: center;text-align: center;color: #B5B5B5;">
                     {{[managerService.auctionService.active_auction_product.buyer_fee * 100] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.AUCTION.BID_REPORT.COMMISSIONS}}
                 </span>
                    <span style="color: #6F6F6F">
                    {{managerService.auctionService.active_bid_price_pipe | managerPipe: managerService.mpa_template.ACTIVE_BID_COMMISSION: managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
                        {{managerService.auctionService.active_auction_product.value_type | managerPipe: managerService.mpa_template.VALUE_TYPE}}
                </span>
                </div>
            </div>
        </div>

        <div class="pl-5 pr-5 mb-3 w-100 d-flex flex-column align-items-start justify-content-center">
            <div class="d-flex w-100 align-items-stretch justify-content-between">
                <div class=" d-flex w-100 justify-content-between">
                                 <span class="barlow subtitle1 medium"
                                       style="display: flex;align-items: center;text-align: center;color: #B5B5B5;">
                     {{[(managerService.auctionService.active_auction_product.tax)*100] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.AUCTION.BID_REPORT.TAX}}
                 </span>
                    <span style="color: #6F6F6F">
                    {{managerService.auctionService.active_bid_price_pipe | managerPipe: managerService.mpa_template.ACTIVE_BID_TAX: managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
                        {{managerService.auctionService.active_auction_product.value_type | managerPipe: managerService.mpa_template.VALUE_TYPE}}
                </span>
                </div>
            </div>
        </div>

        <!--    <div style="width: 445px;height: 0px;border: 0.1px solid #D8D8D8; border-radius: 4px; margin-left: 40px; margin-right: 40px; margin-bottom: 10px"></div>-->

        <div class="pl-5 pr-5 mb-4 w-100 d-flex flex-column align-items-start justify-content-center">
            <div class="d-flex w-100 align-items-stretch justify-content-between">
                <div class=" d-flex w-100 justify-content-between"
                     style="border-top: 0.4px solid #D8D8D8; padding-top: 10px; ">
                 <span class="barlow body1 bold"
                       style="display: flex;align-items: center;text-align: center;color: #6F6F6F;">
                     {{managerService.config.AUCTION.BID_REPORT.TOTAL | managerPipe}}
                 </span>
                    <span
                        style="font-family: Roboto, sans-serif;font-style: normal;font-weight: 700;font-size: 24px;line-height: 28px;display: flex;align-items: center;text-align: center;color: #8266F0;">
                    {{(managerService.auctionService.active_bid_price_pipe | managerPipe: managerService.mpa_template.ACTIVE_BID_TOTAL: managerService.auctionService.active_auction_product_pipe) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
                        {{managerService.auctionService.active_auction_product.value_type | managerPipe: managerService.mpa_template.VALUE_TYPE}}
                </span>
                </div>
            </div>
        </div>
        <div class="px-5 d-flex w-100 justify-content-between mb-3">
        <span [innerHTML]="managerService.config.AUCTION.BID_REPORT.WARNING | managerPipe"
              class="body2 regular barlow  d-inline "
              style="display: flex;align-items: center; color: var(--secondary-colors-artiox-red-base, #EA3943); ">

        </span>
        </div>
        <div class="px-5 d-flex w-100 justify-content-between">
        <span [innerHTML]="managerService.config.AUCTION.BID_REPORT.LEGAL | managerPipe"
            class="body2 regular barlow bid-report-legal d-inline "
            style="display: flex;align-items: center; color: #686C75; ">

        </span>
        </div>

        <div class="d-flex w-100 flex-row justify-content-center " style="margin-top: 48px ;gap: 32px">
            <div (click)="managerService.closeModal(modal_id)"
                 class="d-flex "
                 style="font-family: barlow-regular, sans-serif;cursor: pointer;font-size: 16px;font-style: normal;font-weight: 600;line-height: normal; display: flex;flex-direction: row;justify-content: center;align-items: center;padding: 0px;gap: 8px;width: 140px;height: 44px;background: #FDEBEC;border-radius: 6px;color: #EA3943">
                {{managerService.config.AUCTION.BID_REPORT.REJECT | managerPipe}}
            </div>
            <div (click)="bid()"
                 class="d-flex "
                 style="font-family: barlow-regular, sans-serif;cursor: pointer;font-style: normal;font-style: normal;font-weight: 600;line-height: normal; display: flex;flex-direction: row;justify-content: center;align-items: center;padding: 0px;gap: 8px;width: 140px;height: 44px;background: #8266F0;;border-radius: 6px; color: #FFFFFF">
                {{managerService.config.AUCTION.BID_REPORT.ACCEPT | managerPipe}}
            </div>
        </div>
    </div>

    <div *ngIf="is_max_bidder && !approved" class="d-flex w-100 flex-column">

        <div class="d-flex w-100 justify-content-start">
            <div class="d-flex flex-column" style="gap: 16px;padding: 64px 48px">
                <span class="header6 exo bold"
                   style="color: #8266F0">{{managerService.config.AUCTION.BID_REPORT.LOT_IS_YOURS.TITLE | managerPipe}}</span>
                <span class="body2 barlow regular"
                   style="color: #6F6F6F">{{managerService.config.AUCTION.BID_REPORT.LOT_IS_YOURS.TEXT | managerPipe}}</span>
            </div>
        </div>

        <div class="d-flex w-100 justify-content-center align-items-center">
            <div
                style="display: flex;width: 180px;height: 180px;padding: 36px;justify-content: center;align-items: center;flex-shrink: 0;border-radius: 100px;background: var(--main-color-artiox-purple-200, #ECE8FD);">
                <svg style="height: 108px; width: 108px">
                    <use [attr.xlink:href]='"./assets/images/exclamation_purple.svg#exclamation_purple"'/>
                </svg>
            </div>
        </div>

        <div class="d-flex w-100 ">
            <div class="d-flex flex-column" style="gap: 16px;padding-left: 48px;padding-right: 48px;padding-top: 48px">
                <span class="body1 barlow regular"
                   style="color: #6F6F6F">{{managerService.config.AUCTION.BID_REPORT.LOT_IS_YOURS.BODY | managerPipe}}</span>
            </div>
        </div>

        <div class="d-flex w-100 flex-row justify-content-center pt-5 pb-5">
            <div (click)="managerService.closeModal(modal_id)"
                 style="cursor: pointer; display: flex;flex-direction: row;justify-content: center;align-items: center;margin-right: 16px;gap: 8px;width: 140px;height: 44px;background: #FDEBEC;border-radius: 6px;color: #EA3943">
                {{managerService.config.AUCTION.BID_REPORT.LOT_IS_YOURS.CANCEL | managerPipe}}
            </div>
            <div (click)="approve()"
                 style="cursor: pointer; display: flex;flex-direction: row;justify-content: center;align-items: center;margin-left: 16px;gap: 8px;width: 140px;height: 44px;background: #8266F0;;border-radius: 6px; color: #FFFFFF">
                {{managerService.config.AUCTION.BID_REPORT.LOT_IS_YOURS.BID | managerPipe}}
            </div>
        </div>
    </div>

    <div class="d-flex w-100 justify-content-center align-items-end" style="position:absolute; bottom: 10px">
        <svg style=" height: 32px; width: 138px; margin-top: 32px; margin-bottom: 60px">
            <use [attr.xlink:href]='"./assets/images/artiox_logo.svg#artiox_logo"' fill="#000"/>
        </svg>
    </div>
</div>
