<div
  *ngIf="(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_FEATURING: managerService.pair_pipe) || (managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PRE_SALE: managerService.pair_pipe)"
  class="d-flex flex-row align-items-center justify-content-between mx-auto mt-3"
  style="margin-top: 40px; width: 90%">
    <span
      style="font-family: 'Roboto Condensed', sans-serif; font-size: 10px; line-height: 1.2; letter-spacing: 1px; color: #ffffff; font-weight: 300;">
    {{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_STATE_TIME_HEADING: managerService.language_pipe: managerService.pair_pipe}}</span>
  <span style="font-family: Roboto, sans-serif; font-size: 10px; line-height: 1.2; letter-spacing: 2px; color: #ffffff;">
      <span
        *ngIf="(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_SUPPLY_RATIO: managerService.pair_pipe) !== 0">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_DATE: managerService.language_pipe: managerService.pair_pipe}}</span>
      <span
        *ngIf="(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_SUPPLY_RATIO: managerService.pair_pipe) === 0">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_DATE_MOBILE: managerService.language_pipe: managerService.pair_pipe}}</span>
  </span>
</div>

<div *ngIf="(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PUBLIC_SALE: managerService.pair_pipe)"
     class="d-flex flex-row align-items-center justify-content-between mx-auto mt-3"
     style="margin-top: 40px; width: 90%">
  <span
    style="font-family: 'Roboto Condensed', sans-serif; font-size: 10px; line-height: 1.2; letter-spacing: 1px; color: #ffffff; font-weight: 300;">
    {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_STATE_TIME_HEADING: managerService.language_pipe: managerService.pairService.active_featured_pair_pipe}}</span>
  <span
    style="font-family: Roboto, sans-serif; font-size: 10px; line-height: 1.2; letter-spacing: 2px; color: #ffffff;">
    {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_DATE_MOBILE: managerService.language_pipe: managerService.pairService.active_featured_pair_pipe}}</span>
</div>

<div
  *ngIf="(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_FEATURING: managerService.pair_pipe) || (managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PRE_SALE: managerService.pair_pipe) || (managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PUBLIC_SALE: managerService.pair_pipe)"
  class="w-100 d-flex flex-row align-items-center justify-content-around mx-auto mt-1 px-3 py-1"
  style="box-shadow: 7px 10px 9px 0 rgba(0, 0, 0, 0.2); background-image: linear-gradient(100deg, #333546 9%, #282937 96%); width: 90%;">
  <!--  <div class="w-100 d-flex flex-row align-items-center justify-content-around my-2"-->
  <!--       style=" font-family: bebas-neue-regular, sans-serif;-->
  <!--             font-size: 12px; line-height: 1.2; letter-spacing: 1.2px; color: white; padding-right: 60px;">-->
  <!--    <span class="w-25 text-right">{{managerService.config.HOME.SLIDER.DAY | managerPipe}}</span>-->
  <!--    <span class="w-25 text-right">{{managerService.config.HOME.SLIDER.HOUR | managerPipe}}</span>-->
  <!--    <span class="w-25 text-right">{{managerService.config.HOME.SLIDER.MIN | managerPipe}}</span>-->
  <!--    <span class="w-25 text-right">{{managerService.config.HOME.SLIDER.SEC | managerPipe}}</span>-->
  <!--  </div>-->
  <!--  <div-->
  <!--    [innerHTML]="(managerService.pairService.active_featured_pair?.id | managerPipe: managerService.mpa_template.SALE_STATE_TIME_COUNTDOWN: managerService.pairService.active_featured_pair?.sale_state) | async | managerPipe: managerService.mpa_template.TIME_FORMAT"-->
  <!--    class="w-100 d-flex flex-row align-items-center justify-content-around my-2"-->
  <!--    style=" font-family: bebas-neue-regular, sans-serif;-->
  <!--             font-size: 26px; line-height: 1.2; letter-spacing: 1.2px; color: white; padding-right: 60px;"></div>-->
  <div>
    <span style="font-family: bebas-neue-regular, sans-serif; font-size: 20px; letter-spacing: 3.1px; color: #ffffff;">
      {{((managerService.pairService.active_featured_pair?.id | managerPipe: managerService.mpa_template.SALE_STATE_TIME_COUNTDOWN: managerService.pairService.active_featured_pair?.sale_state) | async) | managerPipe: managerService.mpa_template.GET_DAYS}}
    </span>
    <span style="font-family: bebas-neue-regular, sans-serif; font-size: 9px; letter-spacing: 3.1px; color: #9da5b7;">
      {{managerService.config.HOME.SLIDER.DAY | managerPipe}}
    </span>
  </div>
  <div>
    <span style="font-family: bebas-neue-regular, sans-serif; font-size: 20px; letter-spacing: 3.1px; color: #ffffff;">
      {{((managerService.pairService.active_featured_pair?.id | managerPipe: managerService.mpa_template.SALE_STATE_TIME_COUNTDOWN: managerService.pairService.active_featured_pair?.sale_state) | async) | managerPipe: managerService.mpa_template.GET_HOURS}}
    </span>
    <span style="font-family: bebas-neue-regular, sans-serif; font-size: 9px; letter-spacing: 3.1px; color: #9da5b7;">
      {{managerService.config.HOME.SLIDER.HOUR | managerPipe}}
    </span>
  </div>
  <div>
    <span style="font-family: bebas-neue-regular, sans-serif; font-size: 20px; letter-spacing: 3.1px; color: #ffffff;">
      {{((managerService.pairService.active_featured_pair?.id | managerPipe: managerService.mpa_template.SALE_STATE_TIME_COUNTDOWN: managerService.pairService.active_featured_pair?.sale_state) | async) | managerPipe: managerService.mpa_template.GET_MINUTES}}
    </span>
    <span style="font-family: bebas-neue-regular, sans-serif; font-size: 9px; letter-spacing: 3.1px; color: #9da5b7;">
      {{managerService.config.HOME.SLIDER.MIN | managerPipe}}
    </span>
  </div>
  <div>
    <span style="font-family: bebas-neue-regular, sans-serif; font-size: 20px; letter-spacing: 3.1px; color: #ffffff;">
      {{((managerService.pairService.active_featured_pair?.id | managerPipe: managerService.mpa_template.SALE_STATE_TIME_COUNTDOWN: managerService.pairService.active_featured_pair?.sale_state) | async) | managerPipe: managerService.mpa_template.GET_SECONDS}}
    </span>
    <span style="font-family: bebas-neue-regular, sans-serif; font-size: 9px; letter-spacing: 3.1px; color: #9da5b7;">
      {{managerService.config.HOME.SLIDER.SEC | managerPipe}}
    </span>
  </div>
</div>

<div *ngIf="(managerService.pairService.pair_list_ready && 1 < (managerService.pair_pipe | managerPipe: managerService.mpa_template.GET_NOT_LISTED_PAIR_LIST).length) | managerPipe"
     class="w-100 d-flex flex-row justify-content-start align-items-center py-2" style="border-top: 1px solid #272a3b; background-color: #2b2d3c">
  <div class="w-50 text-end" style="font-family: bebas-neue-bold, sans-serif; font-size: 13px; letter-spacing: 1.2px; color: #d5d8e2; opacity: 0.3">
    {{managerService.config.PRE_SALE.PUBLIC_SALE_ARTWORKS | managerPipe}}
  </div>
  <div class="w-50 d-flex flex-row align-self-center justify-content-center">
    <div *ngFor="let pair of managerService.pair_pipe | managerPipe: managerService.mpa_template.GET_NOT_LISTED_PAIR_LIST; let i = index;"
         class="d-flex flex-row" style="cursor: pointer">
      <div class="px-3" [style.opacity]="((pair | managerPipe: managerService.mpa_template.CHECK_ACTIVE_FEATURED_PAIR: managerService.pairService.active_featured_pair_pipe) ? 1 : 0.3) | managerPipe"
           style="font-family: bebas-neue-bold, sans-serif; font-size: 13px; letter-spacing: 1.2px; color: #d5d8e2;"
           (click)="managerService.navigateIfLoggedIn({url: '/presale', active_featured_pair_id: pair.id, current_component_index: 0})">{{pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_SYMBOL}}</div>
      <div [class.d-none]="((managerService.pair_pipe | managerPipe: managerService.mpa_template.GET_NOT_LISTED_PAIR_LIST).length === i+1) | managerPipe"
           style="height: 80%; width: 1px; background-color: #d5d8e2; opacity: 0.3"></div>
    </div>
  </div>
</div>

<div
  *ngIf="(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_FEATURING: managerService.pair_pipe) || (managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PRE_SALE: managerService.pair_pipe) || (managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PUBLIC_SALE: managerService.pair_pipe)"
  class="vw-100 min-vw-100 d-flex flex-row align-items-center justify-content-center mt-3" style="height: 50px;">
  <div class="h-100 d-flex flex-row align-items-start justify-content-end text-uppercase text-right pl-3"
       style="font-family: Roboto, sans-serif; font-size: 16px; color: white; font-weight: 500;">
    <span
      style="width: 160px;">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_NAME: managerService.pair_pipe}}
    </span>
  </div>
  <span class="h-100"
        style="width: 1px; min-width: 1px; margin: auto 10px; border-radius:1px; background-color: #9da5b7;"></span>
  <div
    *ngIf="(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_FEATURING: managerService.pair_pipe) || (managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PRE_SALE: managerService.pair_pipe)"
    class="h-100 d-flex flex-column flex-wrap align-items-start justify-content-start"
    style="font-family: Roboto, sans-serif; font-size: 20px; color: white;">
    <span
      style="width: 160px;">
      <span
        *ngIf="(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_SUPPLY_RATIO: managerService.pair_pipe) !== 0">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_PRICE: managerService.pair_pipe}}</span>
      <span
        *ngIf="(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_SUPPLY_RATIO: managerService.pair_pipe) === 0">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_PRICE: managerService.pair_pipe}}₺</span>
    </span>
    <span style="width: 160px; font-family: Roboto, sans-serif; font-size: 12px; color: white;">
      <span
        *ngIf="(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_SUPPLY_RATIO: managerService.pair_pipe) !== 0">{{managerService.config.HOME.SLIDER.TOKENMETRICS.PRE_SALE_PRICE | managerPipe}}</span>
      <span
        *ngIf="(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_SUPPLY_RATIO: managerService.pair_pipe) === 0">{{managerService.config.HOME.SLIDER.TOKENMETRICS.PUBLIC_SALE_PRICE | managerPipe}}</span>
    </span>
  </div>
  <div *ngIf="(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PUBLIC_SALE: managerService.pair_pipe)"
       class="h-100 d-flex flex-column flex-wrap align-items-start justify-content-start"
       style="font-family: Roboto, sans-serif; font-size: 20px; color: white;">
    <span
      style="width: 120px;">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_PRICE: managerService.pair_pipe}}₺</span>
    <span style="width: 120px; font-family: Roboto, sans-serif; font-size: 12px; color: white;">
      {{managerService.config.HOME.SLIDER.TOKENMETRICS.PUBLIC_SALE_PRICE | managerPipe}}
    </span>
  </div>
</div>

<div
  *ngIf="(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_FEATURING: managerService.pair_pipe) || (managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PRE_SALE: managerService.pair_pipe) || (managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PUBLIC_SALE: managerService.pair_pipe)"
  class="vw-100 min-vw-100 d-flex flex-row align-items-center justify-content-center mt-2" style="height: 16px;">
  <div class="h-100 w-50 text-center text-info"
       style="font-family: Roboto, sans-serif; font-size: 12px; color: white;">
    {{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.TOKEN_LEFT_TITLE: managerService.language_pipe: managerService.pair_pipe: managerService.art_product_pipe}}
  </div>
  <div class="h-100 w-50 text-center text-info"
       style="font-family: Roboto, sans-serif; font-size: 12px; color: white;">
    {{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_TOKEN_LEFT: managerService.pair_pipe: managerService.art_product_pipe  | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
    <span>
      {{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SYMBOL: managerService.pair_pipe}}
    </span>
  </div>
</div>

<div *ngIf="(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_LISTING: managerService.pair_pipe)"
     class="vw-100 min-vw-100 d-flex flex-row align-items-center justify-content-center mt-3 mb-2" style="height: 60px;">
  <div class="h-100 d-flex flex-row align-items-start justify-content-end text-uppercase text-right"
       style="font-family: Roboto, sans-serif; font-size: 16px; color: white; font-weight: 500;">
    <span
      style="width: 160px;">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_NAME: managerService.pair_pipe}}
    </span>
  </div>
  <span class="h-100" style="width: 1px; min-width: 1px; margin: auto 10px; border-radius:1px; background-color: white;"></span>
  <div *ngIf="info_swap |managerPipe"
       class="h-100 d-flex flex-column align-items-start justify-content-start"
       style="font-family: Roboto, sans-serif; font-size: 20px; color: white;">
    <span style="width: 160px;">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_PAIR_PRICE: managerService.pair_pipe}}₺</span>
    <span style="width: 160px; font-family: Roboto, sans-serif; font-size: 12px; color: white;">
      {{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_PAIR_SYMBOL: managerService.pair_pipe}}
      <svg (click)="swap()" class="ml-1" style="height: 16px; width: 16px;">
        <use [attr.xlink:href]='"./assets/images/switch_icon.svg#switch_icon"' fill="white"/>
      </svg>
    </span>
  </div>
  <div *ngIf="!info_swap |managerPipe"
       class="h-100 d-flex flex-column align-items-start justify-content-start"
       style="font-family: Roboto, sans-serif; font-size: 20px; color: white;">
    <span style="width: 160px;">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_PAIR_VALUE: managerService.pair_pipe}}₺</span>
    <span style="width: 160px; font-family: Roboto, sans-serif; font-size: 12px; color: white;">
      {{managerService.config.BASIC.PAIR_HEADER.ART_PRODUCT_MARKET_VALUE | managerPipe}}
      <svg (click)="swap()" class="ml-1" style="height: 16px; width: 16px;">
        <use [attr.xlink:href]='"./assets/images/switch_icon.svg#switch_icon"' fill="white"/>
      </svg>
    </span>
  </div>
</div>
