<div class="w-100 d-flex flex-column align-items-center justify-content-center p-3" style="max-width: 300px">
  <div class="p-2" style="font-family: bebas-neue-regular, sans-serif;font-size: 17px; font-weight: bold; line-height: 1.2; letter-spacing: 2.5px;  color: #404352;">
    {{managerService.config.TEST_ENVIRONMENT.TITLE | managerPipe}}
  </div>
  <div style="max-height: 400px; overflow: auto;">
    <div style="font-family: Roboto, sans-serif; font-size: 12px; line-height: 1.1; letter-spacing: 0.4px; color: #4a4e70;">
      <div [innerHTML]="managerService.config.TEST_ENVIRONMENT.DESCRIPTION | managerPipe" class="mb-2">
      </div>
    </div>
    <div style="font-family: Roboto, sans-serif; font-size: 12px; font-style: italic; line-height: 1.1; letter-spacing: 0.4px; color: #4a4e70;">
      <div *ngFor="let rule of managerService.config.TEST_ENVIRONMENT.RULES | managerPipe" class="mb-2">
        - {{rule | managerPipe}}
      </div>
    </div>
  </div>
  <label class="align-self-start d-flex flex-row align-items-start justify-content-start checkbox-text-black">
    <input #accepted class="checkbox-field" style="height: 20px; min-width: 20px;" type="checkbox">
    <span class="mx-1 my-1">{{ managerService.config.MISC.READ_ACCEPTED | managerPipe}}</span>
  </label>
  <button (click)="managerService.test_environment_accepted_changed.emit(accepted.checked);"
          [class.button-inactive]="!accepted.checked" [disabled]="!accepted.checked"
          class="btn-native checkbox-button">
    {{managerService.config.MISC.CONTINUE | managerPipe}}
  </button>
</div>
