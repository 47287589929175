import {Component, OnDestroy, OnInit} from '@angular/core';
import {ManagerService} from '../../../services/manager.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit, OnDestroy {

  public subscription_list: Subscription[] = [];

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription_list.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

}
