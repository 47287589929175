<div style="width: 605px;height: 423px;flex-shrink: 0;">
    <div class="d-flex w-100 justify-content-start">
        <div
            style="margin-top: 32px; margin-left: 32px; color: #404352;font-family: bebas-neue-regular, sans-serif;font-size: 24px; font-weight: bold; line-height: 1.2; letter-spacing: 2.5px;  color: #404352;">
            {{managerService.config.AUCTION.DELETE_ADDRESS.HEADER | managerPipe}}
        </div>
    </div>

    <div class="d-flex w-100 justify-content-center align-content-center" style="margin-top: 32px; margin-bottom: 64px">
        <svg style="height: 108px; width: 108px;">
            <use [attr.xlink:href]='"./assets/images/exclamation.svg#exclamation"'/>
        </svg>

    </div>

    <div class="d-flex w-100 justify-content-center align-items-center flex-row" style="gap: 8px; margin-bottom: 32px">
        <span
            style="color: #404352;font-family: Roboto,sans-serif;font-size: 14px;font-style: normal;font-weight: 400;line-height: normal;letter-spacing: 0.021px;">{{managerService.config.AUCTION.DELETE_ADDRESS.BODY | managerPipe}}</span>
    </div>

    <div class="d-flex w-100 justify-content-center flex-row" style="gap: 16px">
        <div (click)="managerService.closeModal('delete-address-modal')"
             style="user-select: none; cursor:pointer; display: flex;height: 40px;padding: 8px 16px;justify-content: center;align-items: center;gap: 8px;border-radius: 6px;border: 1px solid #9DA5B7;">
            {{managerService.config.AUCTION.DELETE_ADDRESS.CANCEL | managerPipe}}
        </div>
        <div (click)="removeAddress();managerService.closeModal('delete-address-modal')"
             style=" user-select: none; display: flex;height: 40px;padding: 8px 16px;justify-content: center;align-items: center;gap: 8px;border-radius: 8px;background: #F11658;box-shadow: 0px 2px 6px 0px rgba(160, 162, 180, 0.42);">
            {{managerService.config.AUCTION.DELETE_ADDRESS.APPROVE | managerPipe}}
        </div>
    </div>
</div>
