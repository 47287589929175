import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';

@Component({
    selector: 'app-basic-pair-list-mobile',
    templateUrl: './basic-pair-list-mobile.component.html',
    styleUrls: ['./basic-pair-list-mobile.component.css']
})
export class BasicPairListMobileComponent implements OnInit {

    constructor(public managerService: ManagerService) {
    }

    ngOnInit(): void {
    }
}
