<div>
  <div class="w-100 d-flex flex-column align-items-center justify-content-center"
       style="box-shadow: 1px 2px 4px 0 rgba(45, 52, 104, 0.3); height: 64px;">
    <div class="w-100 d-flex flex-row align-items-start justify-content-start mt-2"
         style="font-family: Roboto, sans-serif;font-size: 14px; letter-spacing: 0.9px; color: #404352;">
      <span class="ml-4 mr-2 my-auto" style="height: 12px; width: 12px;"></span>
      <span class="body2 exo bold" style=" line-height: 2">{{managerService.config.ADVANCED.MARKET_ALTER}}</span>
    </div>
    <div class="w-100 d-flex flex-row align-items-start justify-content-around"
         style="user-select: none;" type="button">
      <svg (click)="managerService.setPairListSortingType(managerService.pair_list_sorting_type_template.FAVORITE)" class="ml-4 mr-2 my-auto" id="star" style="height: 12px; width: 12px;"
           viewBox="0 0 353.5 352.89"
           xmlns="http://www.w3.org/2000/svg">
        <path
          [style]="(managerService.pair_list_sorting_type === managerService.pair_list_sorting_type_template.FAVORITE && managerService.pair_list_sorting_direction !== managerService.pair_list_sorting_direction_template.DOWN | managerPipe) ? {fill: '#ffbc04'} : {fill: '#abafc7'}"
          d="M66.26,352.77,110.71,210,0,133H133.33L176.24,0c2.27,6.71,4.41,12.72,6.43,18.83q18.06,54.9,36,109.89a5,5,0,0,0,5.69,4q62.76,0,125.54,0h3.6v.69l-34.79,23.8L243.6,208.64c-1.94,1.29-1.62,2.55-1,4.33q10,31.11,19.92,62.26l24.84,77.66L176.2,263.75Z"/>
      </svg>
      <p (click)="managerService.setPairListSortingType(managerService.pair_list_sorting_type_template.PAIR)"
         class="my-1 exo body2 bold"
         style="width: 30%;  line-height: 2;  color: #404352;">
        {{managerService.config.ADVANCED.PAIR | managerPipe}}
        <svg
          *ngIf="(managerService.pair_list_sorting_type === managerService.pair_list_sorting_type_template.PAIR && managerService.pair_list_sorting_direction !== managerService.pair_list_sorting_direction_template.DOWN | managerPipe)"
          style="height:10px; width: 10px; margin-bottom: 2px;">
          <use [attr.xlink:href]='"./assets/images/down_arrow.svg#down_arrow"' class="w-100" fill="#404352"/>
        </svg>
        <svg
          *ngIf="(managerService.pair_list_sorting_type === managerService.pair_list_sorting_type_template.PAIR && managerService.pair_list_sorting_direction !== managerService.pair_list_sorting_direction_template.UP | managerPipe)"
          style="height:10px; width: 10px; margin-bottom: 2px;">
          <use [attr.xlink:href]='"./assets/images/up_arrow.svg#up_arrow"' class="w-100" fill="#404352"/>
        </svg>
      </p>
      <p (click)="managerService.setPairListSortingType(managerService.pair_list_sorting_type_template.PRICE)"
         class="w-25 my-1 text-center exo body2 bold"
         style=" line-height: 2;  color: #404352;">
        {{managerService.config.ADVANCED.PRICE | managerPipe}}
        <svg
          *ngIf="(managerService.pair_list_sorting_type === managerService.pair_list_sorting_type_template.PRICE && managerService.pair_list_sorting_direction !== managerService.pair_list_sorting_direction_template.DOWN | managerPipe)"
          style="height:10px; width: 10px; margin-bottom: 2px;">
          <use [attr.xlink:href]='"./assets/images/down_arrow.svg#down_arrow"' class="w-100" fill="#404352"/>
        </svg>
        <svg
          *ngIf="(managerService.pair_list_sorting_type === managerService.pair_list_sorting_type_template.PRICE && managerService.pair_list_sorting_direction !== managerService.pair_list_sorting_direction_template.UP | managerPipe)"
          style="height:10px; width: 10px; margin-bottom: 2px;">
          <use [attr.xlink:href]='"./assets/images/up_arrow.svg#up_arrow"' class="w-100" fill="#404352"/>
        </svg>
      </p>
      <p (click)="managerService.setPairListSortingType(managerService.pair_list_sorting_type_template.CHANGE)"
         class="my-1 text-center exo body2 bold"
         style="width: 35%; line-height: 2;  color: #404352;">
        {{managerService.config.ADVANCED.CHANGE | managerPipe}}
        <svg
          *ngIf="(managerService.pair_list_sorting_type === managerService.pair_list_sorting_type_template.CHANGE && managerService.pair_list_sorting_direction !== managerService.pair_list_sorting_direction_template.DOWN | managerPipe)"
          style="height:10px; width: 10px; margin-bottom: 2px;">
          <use [attr.xlink:href]='"./assets/images/down_arrow.svg#down_arrow"' class="w-100" fill="#404352"/>
        </svg>
        <svg
          *ngIf="(managerService.pair_list_sorting_type === managerService.pair_list_sorting_type_template.CHANGE && managerService.pair_list_sorting_direction !== managerService.pair_list_sorting_direction_template.UP | managerPipe)"
          style="height:10px; width: 10px; margin-bottom: 2px;">
          <use [attr.xlink:href]='"./assets/images/up_arrow.svg#up_arrow"' class="w-100" fill="#404352"/>
        </svg>
      </p>
    </div>
  </div>
  <div class="overflow-auto" style="max-height: 240px">
    <div
      *ngFor="let pair of (managerService.pair_pipe | managerPipe: managerService.mpa_template.GET_SORTED_LISTED_PAIR_LIST: managerService.pair_list_sorting_type: managerService.pair_list_sorting_direction)"
      class="w-100 d-flex flex-column align-items-center justify-content-center my-1 " >
      <div
        [class.active-pair]="pair | managerPipe: managerService.mpa_template.CHECK_ACTIVE_PAIR: managerService.pair_pipe"
        class="w-100 d-flex flex-row align-items-start justify-content-around" type="button">
        <svg (click)="managerService.setUserFavoritePair(pair)" class="ml-4 mr-2 my-auto" id="star"
             style="height: 12px; width: 12px;"
             viewBox="0 0 353.5 352.89"
             xmlns="http://www.w3.org/2000/svg">
          <path
            [style]="(pair | managerPipe: managerService.mpa_template.IS_PAIR_FAVORITE: managerService.user_pipe) ? {fill: '#ffbc04'} : {fill: '#abafc7'}"
            d="M66.26,352.77,110.71,210,0,133H133.33L176.24,0c2.27,6.71,4.41,12.72,6.43,18.83q18.06,54.9,36,109.89a5,5,0,0,0,5.69,4q62.76,0,125.54,0h3.6v.69l-34.79,23.8L243.6,208.64c-1.94,1.29-1.62,2.55-1,4.33q10,31.11,19.92,62.26l24.84,77.66L176.2,263.75Z"/>
        </svg>
        <span (click)="managerService.navigationManager({url: '/exchange/advanced', active_pair_id: pair.id, current_component_index: 1})" class="my-1 barlow caption bold"
              style="width: 30%; line-height: 24px;">
        {{pair.symbol | managerPipe}}
      </span>
        <span (click)="managerService.navigationManager({url: '/exchange/advanced', active_pair_id: pair.id, current_component_index: 1})" [style]="pair.change | managerPipe: managerService.mpa_template.PAIR_CHANGE_COLOR"
              class="w-25 my-1 text-center barlow caption medium"
              style=" line-height: 24px; ">
        {{pair.price | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
      </span>
        <span (click)="managerService.navigationManager({url: '/exchange/advanced', active_pair_id: pair.id, current_component_index: 1})" [style]="pair.change | managerPipe: managerService.mpa_template.PAIR_CHANGE_COLOR"
              class="my-1 text-center barlow caption medium"
              style="width: 35%;   line-height: 24px;">
        {{pair.change | managerPipe: managerService.mpa_template.FANCY_NUMBER}}%
      </span>
      </div>
    </div>
  </div>
</div>
