import {Component, Input, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';

@Component({
  selector: 'app-withdrawal-history-mobile',
  templateUrl: './withdrawal-history-mobile.component.html',
  styleUrls: ['./withdrawal-history-mobile.component.css']
})
export class WithdrawalHistoryMobileComponent implements OnInit {

  @Input() currency_type: string = '';
  @Input() currency_id: number = 0;


  constructor(public managerService: ManagerService) { }

  ngOnInit(): void {
  }

}
