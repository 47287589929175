<div class="w-100 d-flex flex-column align-items-start justify-content-start"
     style="box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff; max-height: 1160px; border-radius: 4px;">
  <div [class]="managerService.userService.user?.two_factor_auth ? 'd-none' : 'd-flex' | managerPipe"
       class="w-100 flex-column align-items-start justify-content-start m-4 p-4"
       style="height: fit-content;">
    <div class="w-75 d-flex flex-column" style="min-width: 240px;">
      <div class="w-100 mb-3" style="font-family: bebas-neue-regular, sans-serif; font-size: 25px; font-weight: bold; line-height: 1.25; letter-spacing: 2.5px; color: #404352;">
        {{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.FORM_HEADING | managerPipe}}
      </div>
      <div class="w-100 my-3" style="font-family: Roboto, sans-serif; font-size: 14px; line-height: 1.20; letter-spacing: 1.4px; color: #404352;">
        {{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.DOWNLOAD_2FA_APP | managerPipe}}
      </div>
      <div class="w-100 my-3" style="font-family: Roboto, sans-serif; font-size: 14px; line-height: 1.20; letter-spacing: 1.4px; color: #404352;">
        {{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.INSERT_PASSWORD | managerPipe}}
      </div>
    </div>
    <form [formGroup]="passwordForm" class="w-50 d-flex flex-row align-items-start justify-content-start" style="height: fit-content;">
      <div class="w-100">
        <label class="position-relative mt-4 mb-3 d-flex flex-row" style="height: 30px;">
          <input #password_input
                 [type]="(current_password_visible | managerPipe) ? 'text' : 'password'" class="w-100 input-field-underline password-input-field"
                 formControlName="password" placeholder="{{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.CURRENT_PASSWORD | managerPipe}}" required>
          <svg (click)="current_password_visible = !current_password_visible" class="password-eye">
            <use *ngIf="(!current_password_visible | managerPipe)" [attr.xlink:href]='"./assets/images/eye_hidden.svg#eye_hidden"' fill="#9DA5B7"/>
            <use *ngIf="(current_password_visible | managerPipe)" [attr.xlink:href]='"./assets/images/eye_visible.svg#eye_visible"' fill="#9DA5B7"/>
          </svg>
        </label>
        <div #warning_password class="d-none warning" style="margin: 0px 20px 0"></div>
      </div>
      <button (click)="checkPassword()"
              [class.button-inactive]="(password_input.value.length === 0) | managerPipe"
              [class.d-none]="!two_factor_auth_div.classList.contains('d-none') | managerPipe"
              class="btn-native authentication-button m-4">
        {{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.APPLY | managerPipe}}
      </button>
    </form>
    <div #two_factor_auth_div class="w-100 d-none">
      <div class="w-100 position-relative d-flex flex-column align-items-start justify-content-start" style="height: fit-content;">
        <div class="position-relative d-flex flex-column align-items-center justify-content-center" style="width: 35%;">
          <div
            style="font-family: bebas-neue-regular, sans-serif; font-size: 25px; font-weight: bold; line-height: 1.25; letter-spacing: 2.5px; color: #404352;">{{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.QR_CODE | managerPipe}}</div>
          <svg class="position-absolute" style="height: 220px; width: 220px;">
            <use [attr.xlink:href]='"./assets/images/qr_code.svg#qr_code"' fill="#231f20"/>
          </svg>
          <canvas #qr></canvas>
          <div style="height: 20px;"></div>
        </div>
        <div class="w-100 d-flex flex-row">
          <div class="mx-2 font-weight-bold"
               style="font-family: Roboto, sans-serif; font-size: 14px; line-height: 1.6; letter-spacing: 0.4px; color: #404352;">{{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.SECRET_KEY | managerPipe}}
            :
          </div>
          <div #qr_code></div>
          <button (click)="copySecretKey()" class="mx-2 btn-native">
            <svg style="height: 20px; width: 20px;">
              <use [attr.xlink:href]='"./assets/images/copy.svg#copy"' fill="#595e7e"/>
            </svg>
          </button>
        </div>
      </div>
      <div class="w-75 d-flex flex-column" style="min-width: 240px;">
        <div class="w-100 mt-4 mb-3" style="font-family: Roboto, sans-serif; font-size: 14px; line-height: 1.20; letter-spacing: 1.4px; color: #404352;">
          {{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.INSERT_AUTH_PASSWORD | managerPipe}}
        </div>
      </div>
      <form [formGroup]="twoFactorAuthenticationForm" class="w-50 d-flex flex-column align-items-start justify-content-start" style="height: fit-content;">
        <div class="w-100">
          <label class="p-2 d-flex flex-row align-items-start justify-content-start checkbox-text-black">
            <input class="checkbox-field two-factor-authentication-input-field" formControlName="auth_accepted" style="height: 20px; min-width: 20px;" type="checkbox">
            <span class="mx-2 my-1 two-factor-authentication-input-field"
                  style="font-family: Roboto, sans-serif; font-size: 13px; font-style: italic; line-height: 1.55; letter-spacing: 1.35px; color: #595e7e;">
            {{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.AUTHENTICATION_WARNING | managerPipe}}
          </span>
          </label>
        </div>
        <div class="w-100 d-flex flex-row align-items-start justify-content-start">
          <div class="w-100">
            <label class="position-relative mt-4 mb-3 d-flex flex-row" style="height: 30px;">
              <input [type]="(auth_password_visible | managerPipe) ? 'text' : 'password'" class="w-100 input-field-underline two-factor-authentication-input-field" formControlName="auth_password"
                     placeholder="{{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.AUTH_PASSWORD | managerPipe}}" required>
              <svg (click)="auth_password_visible = !auth_password_visible" class="password-eye">
                <use *ngIf="(!auth_password_visible | managerPipe)" [attr.xlink:href]='"./assets/images/eye_hidden.svg#eye_hidden"' fill="#9DA5B7"/>
                <use *ngIf="(auth_password_visible | managerPipe)" [attr.xlink:href]='"./assets/images/eye_visible.svg#eye_visible"' fill="#9DA5B7"/>
              </svg>
            </label>
            <div #warning_auth_password class="d-none warning" style="margin: 0px 20px 0"></div>
          </div>
          <button (click)="checkAuthPassword()"
                  [class.button-inactive]="twoFactorAuthenticationForm.invalid"
                  [disabled]="twoFactorAuthenticationForm.invalid"
                  class="btn-native authentication-button m-4">
            {{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.COMPLETE | managerPipe}}
          </button>
        </div>
      </form>
    </div>
  </div>
  <div [class]="managerService.userService.user?.two_factor_auth ? 'd-flex' : 'd-none' | managerPipe"
       class="w-100 flex-column align-items-start justify-content-center m-4 p-4"
       style="font-family: Roboto, sans-serif; font-size: 14px; line-height: 1.5; letter-spacing: 1.4px; color: #404352;">
    <div class="w-75 d-flex flex-column" style="min-width: 240px;">
      <div class="w-100 mb-3" style="font-family: bebas-neue-regular, sans-serif; font-size: 25px; font-weight: bold; line-height: 1.25; letter-spacing: 2.5px; color: #404352;">
        {{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.HEADING_REMOVE | managerPipe}}
      </div>
      <div class="w-100 my-3" style="font-family: Roboto, sans-serif; font-size: 14px; line-height: 1.20; letter-spacing: 1.4px; color: #404352;"
           [innerHTML]="managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.ACTIVE | managerPipe">
      </div>
      <div class="w-100 my-3" style="font-family: Roboto, sans-serif; font-size: 14px; line-height: 1.20; letter-spacing: 1.4px; color: #404352;">
        {{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.INSERT_PASSWORD_TO_REMOVE | managerPipe}}
      </div>
      <form [formGroup]="remove2FAPasswordForm" class="w-50 d-flex flex-column align-items-start justify-content-start" style="height: fit-content;">
        <div class="w-100">
          <label class="position-relative mt-4 mb-3 d-flex flex-row" style="height: 30px;">
            <input #remove_2fa_password_input
                   [type]="(current_password_visible | managerPipe) ? 'text' : 'password'" class="w-100 input-field-underline password-input-field"
                   formControlName="password" placeholder="{{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.CURRENT_PASSWORD | managerPipe}}" required>
            <svg (click)="current_password_visible = !current_password_visible" class="password-eye">
              <use *ngIf="(!current_password_visible | managerPipe)" [attr.xlink:href]='"./assets/images/eye_hidden.svg#eye_hidden"' fill="#9DA5B7"/>
              <use *ngIf="(current_password_visible | managerPipe)" [attr.xlink:href]='"./assets/images/eye_visible.svg#eye_visible"' fill="#9DA5B7"/>
            </svg>
          </label>
          <div #warning_password_to_remove class="d-none warning" style="margin: 0px 20px 0"></div>
        </div>
        <button (click)="validatePassword()"
                [class.button-inactive]="(remove_2fa_password_input.value.length === 0) | managerPipe"
                [class.d-none]="password_validated | managerPipe"
                class="btn-native authentication-button m-4">
          {{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.APPLY | managerPipe}}
        </button>
      </form>
      <div class="w-100" [class.d-none]="!password_validated | managerPipe">
        <div class="w-100 my-3" style="font-family: Roboto, sans-serif; font-size: 14px; line-height: 1.20; letter-spacing: 1.4px; color: #404352;">
          {{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.INSERT_AUTH_PASSWORD_TO_REMOVE | managerPipe}}
        </div>
        <form [formGroup]="remove2FAKeyForm"
              class="w-50 d-flex flex-column align-items-start justify-content-start" style="height: fit-content;">
          <div class="w-100">
            <label class="position-relative mt-4 mb-3 d-flex flex-row" style="height: 30px;">
              <input #remove_2fa_auth_password_input
                     [type]="(auth_password_visible | managerPipe) ? 'text' : 'password'" class="w-100 input-field-underline password-input-field"
                     formControlName="auth_password" placeholder="{{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.AUTH_PASSWORD | managerPipe}}" required>
              <svg (click)="auth_password_visible = !auth_password_visible" class="password-eye">
                <use *ngIf="(!auth_password_visible | managerPipe)" [attr.xlink:href]='"./assets/images/eye_hidden.svg#eye_hidden"' fill="#9DA5B7"/>
                <use *ngIf="(auth_password_visible | managerPipe)" [attr.xlink:href]='"./assets/images/eye_visible.svg#eye_visible"' fill="#9DA5B7"/>
              </svg>
            </label>
          </div>
          <button (click)="removeTwoFactorAuth()" [class.button-inactive]="(remove_2fa_auth_password_input.value.length === 0) | managerPipe"
                  class="btn-native authentication-button m-4">
            {{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.APPLY | managerPipe}}
          </button>
        </form>
      </div>
    </div>
  </div>
</div>


