<div class="w-100 d-flex flex-column align-items-center justify-content-start z-index-inherit"
     style="background-image: linear-gradient(135deg, #8266F0  ,#D8D0FA 300%); z-index: 1;">
    <svg class="position-absolute w-100" style="z-index: 0; height: 500px!important;">
        <use [attr.xlink:href]='"./assets/images/landing_page_bg_texture.svg#landing_page_bg_texture"' class="h-100" fill="#ffffff" opacity="10%"
             transform="translate(400 0)"/>
    </svg>
    <div (swipeleft)="swipeLeft()" (swiperight)="swipeRight()" class="w-100 d-flex flex-row overflow-hidden"
         style="height: 450px;">
<!--        <div-->
<!--            [style]="current_slider_index | managerPipe : managerService.mpa_template.SLIDER_BY_VW"-->
<!--            class="vw-100 min-vw-100 d-flex flex-row align-items-center justify-content-center"-->
<!--            style="transition: 0.4s ease-in-out;">-->
<!--            <div class="h-100 d-flex flex-column align-items-center justify-content-center mx-3">-->
<!--                <img #app_announcement (error)="app_announcement.src = app_announcement.src.replace('.webp', '.jpg');"-->
<!--                     alt="app_announcement"-->
<!--                     src="{{managerService.config.APP_ANNOUNCEMENT.DESKTOP_IMAGE_THUMBNAIL | managerPipe: managerService.mpa_template.LOCAL_IMAGE_MANAGER}}"-->
<!--                     style="height: 300px; pointer-events: none">-->
<!--            </div>-->
<!--            <div class="h-100 d-flex flex-column align-items-center justify-content-center mx-3" style="min-width: 300px;">-->
<!--                <div class="flex-column align-items-center justify-content-center" style="height: 300px;">-->
<!--                    <p [innerHTML]="managerService.config.APP_ANNOUNCEMENT.DESCRIPTION | managerPipe"-->
<!--                       style="font-family: Roboto, sans-serif; font-size: 28px; font-weight: 500; line-height: 1.4; letter-spacing: 1.15px; color: #f9f9f9; margin-bottom: 60px">-->
<!--                    </p>-->
<!--                    <span>-->
<!--            <a (click)="managerService.marketingService.logEvent('app_store_navigation', {})" aria-label="google play" class="m-3"-->
<!--               href="{{managerService.config.APP_ANNOUNCEMENT.APP_STORE_LINK_DESKTOP | managerPipe}}">-->
<!--              <svg style="height:45px; width: 135px;">-->
<!--                <use [attr.xlink:href]="managerService.config.APP_ANNOUNCEMENT.APP_STORE_BADGE | managerPipe" fill="#f9f9f9"/>-->
<!--              </svg>-->
<!--            </a>-->
<!--          </span>-->
<!--                    <span>-->
<!--            <a (click)="managerService.marketingService.logEvent('google_play_navigation', {})" aria-label="app store" class="m-3"-->
<!--               href="{{managerService.config.APP_ANNOUNCEMENT.GOOGLE_PLAY_LINK | managerPipe}}">-->
<!--              <svg style="height:45px; width: 135px;">-->
<!--                <use [attr.xlink:href]="managerService.config.APP_ANNOUNCEMENT.GOOGLE_PLAY_BADGE | managerPipe" fill="#f9f9f9"/>-->
<!--              </svg>-->
<!--            </a>-->
<!--          </span>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <div
            *ngFor="let pair of (managerService.pair_pipe | managerPipe: managerService.mpa_template.GET_FEATURED_PAIR_LIST: (slider_window))"
            [style]="current_slider_index | managerPipe : managerService.mpa_template.SLIDER_BY_VW"
            class="vw-100 min-vw-100 d-flex flex-row align-items-center justify-content-center"
            style="transition: 0.4s ease-in-out;">
            <div *ngIf="pair.sale_state !== 'listing' && pair.sale_state !== 'delisted' && pair.id[0] !== -1 | managerPipe" [class]="managerService.inner_width < 1000 ? 'd-none':'d-flex'"
                 class="h-100 flex-column align-items-end justify-content-center mx-5"
                 style="min-width: 400px; width: 400px;">
                <p class="mt-3 exo bold header6" style="color: #FFFFFF">
                    {{pair | managerPipe: managerService.mpa_template.SALE_STATE_TIME_HEADING: managerService.language_pipe}}</p>
                <div class="w-100 d-flex flex-row align-items-center justify-content-around my-2 exo subtitle2 medium" style="color:  #37324D;">
                    <span class="w-25 text-right">{{managerService.config.HOME.SLIDER.DAY | managerPipe}}</span>
                    <span class="w-25 text-right">{{managerService.config.HOME.SLIDER.HOUR | managerPipe}}</span>
                    <span class="w-25 text-right">{{managerService.config.HOME.SLIDER.MIN | managerPipe}}</span>
                    <span class="w-25 text-right">{{managerService.config.HOME.SLIDER.SEC | managerPipe}}</span>
                </div>
                <div [innerHTML]="(pair.id | managerPipe: managerService.mpa_template.SALE_STATE_TIME_COUNTDOWN: pair.sale_state) | async | managerPipe: managerService.mpa_template.TIME_FORMAT"
                     class="w-100 d-flex flex-row align-items-center justify-content-around mt-2 mb-4 exo regular subtitle2"
                     style=" color: white;"></div>
                <p class="exo medium subtitle1" style=" color: #ffffff;">
                    {{managerService.config.HOME.SLIDER.TOKENMETRICS.HEADING | managerPipe}}</p>
                <p class="exo regular subtitle2" style=" color: #ffffff;">
                    {{managerService.config.HOME.SLIDER.TOKENMETRICS.SYMBOL | managerPipe}}: <strong>{{pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_SYMBOL}}</strong></p>
                <p class="exo regular subtitle2" style=" color: #ffffff;">
                    {{managerService.config.HOME.SLIDER.TOKENMETRICS.TOTAL_SUPPLY | managerPipe}}: <strong>{{pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_TOTAL_SUPPLY}}</strong>
                </p>
                <p class="exo regular subtitle2" style=" color: #ffffff;">
                    {{managerService.config.HOME.SLIDER.TOKENMETRICS.CIRCULATING_SUPPLY | managerPipe}}:
                    <strong>{{pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_CIRCULATING_SUPPLY}}</strong>
                </p>
                <p class="exo regular subtitle2" style=" color: #ffffff;">
                    {{managerService.config.HOME.SLIDER.TOKENMETRICS.PRE_SALE_CIRCULATING_SUPPLY | managerPipe}}:
                    <strong>{{pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_PRE_SALE_CIRCULATING_SUPPLY}}</strong></p>
                <p class="exo regular subtitle2" style=" color: #ffffff;">
                    {{managerService.config.HOME.SLIDER.TOKENMETRICS.PRE_SALE_PRICE | managerPipe}}:
                    <strong>{{pair.pre_sale_price | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_PRICE}}</strong></p>
                <p class="exo regular subtitle2" style=" color: #ffffff;">
                    {{managerService.config.HOME.SLIDER.TOKENMETRICS.PUBLIC_SALE_CIRCULATING_SUPPLY | managerPipe}}:
                    <strong>{{pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_PUBLIC_SALE_CIRCULATING_SUPPLY}}₺</strong></p>
                <p class="exo regular subtitle2" style=" color: #ffffff;">
                    {{managerService.config.HOME.SLIDER.TOKENMETRICS.PUBLIC_SALE_PRICE | managerPipe}}: <strong>{{pair.public_sale_price | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
                    ₺</strong></p>
            </div>

            <div *ngIf="pair.sale_state === 'listing' && pair.sale_state !== 'delisted' && pair.id[0] !== -1 | managerPipe" [class]="managerService.inner_width < 1000 ? 'd-none':'d-flex'"
                 class="h-100 flex-column align-items-end justify-content-center mx-5"
                 style="min-width: 400px; width: 400px;">
                <p class="mt-5 exo bold header6" style=" color: #ffffff;">
                    {{pair | managerPipe: managerService.mpa_template.SALE_STATE_TIME_HEADING: managerService.language_pipe}}</p>
                <div class="w-100 d-flex flex-row align-items-center justify-content-around my-2 exo subtitle2 medium"
                     style=" color: #37324D;">
                    <span class="w-25 text-right">{{managerService.config.HOME.SLIDER.YEAR | managerPipe}}</span>
                    <span class="w-25 text-right">{{managerService.config.HOME.SLIDER.MONTH | managerPipe}}</span>
                    <span class="w-25 text-right">{{managerService.config.HOME.SLIDER.DAY | managerPipe}}</span>
                    <span class="w-25 text-right">{{managerService.config.HOME.SLIDER.HOUR | managerPipe}}</span>
                </div>
                <div [innerHTML]="(pair.id | managerPipe: managerService.mpa_template.SALE_STATE_TIME_COUNTDOWN: pair.sale_state) | managerPipe: managerService.mpa_template.TIME_FORMAT"
                     class="w-100 d-flex flex-row align-items-center justify-content-around mt-2 mb-4 exo regular subtitle2"
                     style="color: white;"></div>
                <p class="exo medium subtitle1" style=" color: #ffffff;">
                    {{managerService.config.HOME.SLIDER.ART_PRODUCT_INFO.HEADING | managerPipe}}</p>
                <p class="exo regular subtitle2" style=" color: #ffffff;">
                    {{managerService.config.HOME.SLIDER.ART_PRODUCT_INFO.LAST_PRICE | managerPipe}}: <strong>{{pair.price | managerPipe: managerService.mpa_template.FANCY_NUMBER}}₺</strong></p>
                <p class="exo regular subtitle2" style=" color: #ffffff;">
                    {{managerService.config.HOME.SLIDER.ART_PRODUCT_INFO.CHANGE_24_HOUR | managerPipe}}: <strong>{{pair.change | managerPipe: managerService.mpa_template.FANCY_NUMBER}}%</strong></p>
                <p class="exo regular subtitle2" style=" color: #ffffff;">
                    {{managerService.config.HOME.SLIDER.ART_PRODUCT_INFO.LOW_24_HOUR}}: <strong>{{pair.low | managerPipe: managerService.mpa_template.FANCY_NUMBER}}₺</strong></p>
                <p class="exo regular subtitle2" style=" color: #ffffff;">
                    {{managerService.config.HOME.SLIDER.ART_PRODUCT_INFO.HIGH_24_HOUR}} <strong>{{pair.high | managerPipe: managerService.mpa_template.FANCY_NUMBER}}₺</strong></p>
            </div>

            <div (dblclick)="managerService.photoSwipeOpen(pair)" class="h-100 d-flex flex-column align-items-center justify-content-center  mx-5">
                <img
                    #art_product (error)="art_product.src = art_product.src.replace('.webp', '.jpg');"
                    alt="art_product" height="300px" width="400px"
                    src="{{(pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_IMAGE_DESKTOP)[0]  | managerPipe: managerService.mpa_template.LOCAL_IMAGE_MANAGER}}"
                    style="pointer-events: none">
            </div>

            <div class="h-100 d-flex flex-column align-items-start justify-content-center  mx-5" style="min-width: 400px; width: 400px;">
                <div class="flex-column align-items-center justify-content-start">
                    <p class="mb-0 exo medium header5" style=" color: white;">
                        "{{pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_NAME}}"
                    </p>
                    <span class="d-flex flex-row align-items-center justify-content-start mb-4" style="line-height: 36px;">
            <p class="m-2 barlow body1 medium" style="color: white;">{{pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_ARTIST_NAME: managerService.language_pipe}}</p>
            <p [class.d-none]="(pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_ARTIST_BORN_DEATH) | managerPipe: managerService.mpa_template.CHECK_LENGTH_SHORTER_THAN : 4"
               class="m-2 barlow body1 medium" style=" color: white;">({{pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_ARTIST_BORN_DEATH: managerService.language_pipe}}
                )</p>
          </span>
                    <p class="m-2 barlow body1 regular" style=" color: white;">{{pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_SIGNATURE: managerService.language_pipe}}</p>
                    <p class="m-2 barlow body1 regular" style=" color: white;">{{pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_MEDIUM: managerService.language_pipe}}</p>
                    <p class="m-2 barlow body1 regular" style=" color: white;">{{pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_SIZE: managerService.language_pipe}}</p>
                    <p class="m-2 barlow body1 regular" style=" color: white;">{{pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_YEAR: managerService.language_pipe}}</p>
                    <button (click)="managerService.exchangeNavigate(pair);"
                            *ngIf="pair.id[0] !== -1 | managerPipe"
                            class="mt-5 btn-native slider-sale-state-button">{{pair.sale_state | managerPipe: managerService.mpa_template.SALE_STATE_BUTTON: managerService.language_pipe}}</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="slider_window > 1" class="d-flex flex-row align-items-center justify-content-center mb-4">
        <button (click)="current_slider_index=0"
                [style]="current_slider_index | managerPipe: managerService.mpa_template.SLIDER_BUTTON:0"
                aria-label="Slider" class="btn-native"
                style="width: 15px; height: 15px; margin: 7px; border-radius: 1000px; background-color: #F6F6F6; transition: 0.3s ease-in-out;"></button>
        <button (click)="current_slider_index=(i+1)"
                *ngFor="let i = index; let pair of (managerService.pair_pipe | managerPipe: managerService.mpa_template.GET_FEATURED_PAIR_LIST: (slider_window - 1))"
                [style]="current_slider_index | managerPipe: managerService.mpa_template.SLIDER_BUTTON:(i+1)"
                aria-label="Slider" class="btn-native"
                style="width: 15px; height: 15px; margin: 7px; border-radius: 1000px; background-color: #F6F6F6; transition: 0.3s ease-in-out;"></button>
    </div>
</div>
