<div class="p-5 d-flex flex-column align-items-center justify-content-center" style="background-image: radial-gradient(circle 1000px at 72.3% 1991.3%, #1e2028 0%, #21232d 100%)">
  <div class="d-flex w-100 justify-content-end">
    <svg style="margin-top: -16px; margin-right: -8px; height: 18px; width: 18px;" (click)="managerService.closeModal(modal_id)">
      <use [attr.xlink:href]='"./assets/images/exit_icon.svg#exit_icon"' fill="white"/>
    </svg>
  </div>
  <div class="pt-3 w-100 d-flex align-items-center justify-content-between" style="padding: 0 20px; font-family: Roboto, sans-serif; font-size: 25px;
  line-height: 1.2; letter-spacing: 2.5px; color: #ffffff;">
    {{managerService.config.FORGOT_PASSWORD.HEADING | managerPipe}}

  </div>
  <form [formGroup]="forgotPasswordForm" class="d-flex flex-column align-items-center justify-content-center my-4" style="width: 75%; height: fit-content; z-index: 2">
    <div class="w-100 p-3 text-center" style="font-family: Roboto, sans-serif; font-size: 16px; line-height: 1.6; letter-spacing: 1.65px; color: #ffffff;">
      {{managerService.config.FORGOT_PASSWORD.INFO}}
    </div>
    <div class="d-flex flex-column" style="width: 50%; min-width: 240px;">
      <div class="w-100 d-flex flex-column">
        <span class='input-heading' style="color: #f4f5f9">{{managerService.config.LOGIN.E_MAIL | managerPipe}}</span>
        <label>
          <input class="input-field" formControlName="email" placeholder="{{managerService.config.LOGIN.E_MAIL_PLACEHOLDER | managerPipe}}"
                 required style="border-radius: 4px; border: solid 1px #ffffff; background-color: #ffffff; box-shadow: none;"
                 type="email">
        </label>
      </div>
      <button (click)="forgotPassword()" class="btn-native" id="forgot-password-submit-button">
        {{managerService.config.FORGOT_PASSWORD.SEND | managerPipe}}
      </button>
      <div #warning class="d-none warning" style="margin: 10px 20px 0"></div>
    </div>
  </form>
</div>
