import {Component, Input, OnInit} from '@angular/core';
import {ManagerService} from "../../../../../services/manager.service";

@Component({
  selector: 'app-auction-bid-approved-mobile',
  templateUrl: './auction-bid-approved-mobile.component.html',
  styleUrls: ['./auction-bid-approved-mobile.component.css']
})
export class AuctionBidApprovedMobileComponent implements OnInit {

  @Input() modal_id: string = '';

  constructor(public managerService: ManagerService) { }

  ngOnInit(): void {
  }

}
