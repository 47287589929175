import {Component, Input, ViewChild} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
  selector: 'app-ethereum-deposit-rules-mobile',
  templateUrl: './ethereum-deposit-rules-mobile.component.html',
  styleUrl: './ethereum-deposit-rules-mobile.component.css'
})
export class EthereumDepositRulesMobileComponent {
  @ViewChild('checkbox_field_mobile') checkbox_field_mobile: any = undefined;

  @Input() modal_id: string = '';

  constructor(public managerService: ManagerService) { }

  ngOnInit(): void {
  }

  onAccept(): void {
    this.managerService.operationsService.crypto_deposit_rules_accepted_status = true;
    this.managerService.closeModal(this.modal_id);
    this.checkbox_field_mobile.checked = false;
    this.managerService.marketingService.logEvent('deposit_rules_approved', {symbol: 'ETH'});
  }
}
