<div *ngIf="!managerService.environment.app | managerPipe" class="d-flex flex-column w-100 h-100" style="min-height: 420px;">
    <div class="w-100 d-flex flex-column" style=" background-color: #fcfdff; box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5); margin-bottom: 4px;">
        <div class="mt-4 mb-2 ml-3"
             style="font-family: Roboto, sans-serif;font-size: 14px; letter-spacing: 0.7px; color: #272a3b;">
      <span>
        <svg style="height: 24px; width: 24px; margin-top: -4px;">
          <use [attr.xlink:href]='"./assets/images/user_information.svg#user_information"' fill="#272a3b"/>
        </svg>
      </span>
            {{managerService.config.USER_INFORMATION.HEADING | managerPipe}}
        </div>
    </div>

    <div *ngIf="((!managerService.logged_in) || (0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO)) | managerPipe"
         class="p-3">
        <div class="my-2" style="font-family: Roboto, sans-serif; font-size: 16px; font-weight: bold; line-height: 1.2; letter-spacing: 1px; color: #4a4e70;">
            {{managerService.config.USER_INFORMATION.NO_KYC_HEADING | managerPipe}}
        </div>
        <div class="my-2" style="font-family: Roboto, sans-serif; font-size: 12px; color: #4a4e70;">
            {{managerService.config.USER_INFORMATION.NO_KYC_DESCRIPTION | managerPipe}}
        </div>
        <div class="d-flex flex-row justify-content-center">
            <button
                (click)="managerService.navigationManager({url: '/account/security', active_security_panel: managerService.active_security_panel_template.IDENTITY_VERIFICATION, current_component_index: 0});"
                class="btn-native sign-up-login-button my-3"
                style="height: 30px; width:120px; border-radius: 16px;
              font-family: Roboto, sans-serif; font-size: 12px; line-height: 1.3; letter-spacing: 0.3px; color: #ffffff;">
                {{managerService.config.DASHBOARD.LEVEL_UP | managerPipe}}
            </button>
        </div>

    </div>

    <app-user-info-profile-mobile></app-user-info-profile-mobile>
<!--    app-user-info-auction-address-mobile ?? -->
</div>


