<div class="d-flex flex-row align-items-center justify-content-around py-1 my-1 exo caption bold"
     style=" line-height: 2.7;  color: #4a4e70;">
  <span class="pl-4 text-center"
        style="width: 16%">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.PAIR | managerPipe}}</span>
  <span class="text-center"
        style="width: 16%">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.TYPE | managerPipe}}</span>
  <span class="text-center"
        style="width: 12%">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.PRICE | managerPipe}}</span>
  <span class="text-center"
        style="width: 16%">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.AMOUNT | managerPipe}}</span>
  <span class="text-center"
        style="width: 16%">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.TIME | managerPipe}}</span>
  <span class="text-center"
        style="width: 8%">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.CANCEL | managerPipe}}</span>
  <span class="pr-4 text-right" style="width: 12%"></span>
</div>
<div class="d-flex flex-column overflow-auto" style="min-height: 200px; max-height: 200px;">
  <div
    *ngFor="let user_open_order of (managerService.user_open_orders_history_pipe | managerPipe: managerService.mpa_template.GET_USER_OPEN_ORDERS)"
    class="d-flex flex-row align-items-center justify-content-around flex-wrap user-open-order flex-shrink-0 barlow caption medium"
    style=" line-height: 2.7; color: #4a4e70; padding: 2px 0;">
    <span class="pl-4 text-center"
          style="width: 16%">{{user_open_order.pair_id | managerPipe: managerService.mpa_template.PAIR_SYMBOL: managerService.pair_pipe}}</span>
    <span [style]="user_open_order | managerPipe: managerService.mpa_template.GET_USER_OPEN_ORDERS_BACKGROUND_COLOR"
          class="text-center"
          style="width: 16%">{{user_open_order.type | managerPipe: managerService.mpa_template.USER_OPEN_ORDER_TYPE}}</span>
    <span class="text-center"
          style="width: 12%">{{user_open_order.price | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
    <span class="text-center"
          style="width: 16%">{{user_open_order.amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
    <span class="text-center"
          style="width: 16%">{{user_open_order.creation_time | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</span>
    <span class="text-center" style="width: 8%">
      <svg (click)="managerService.cancelUserOrder(user_open_order);" style="height: 20px; width: 20px;" type="button">
        <use [attr.xlink:href]='"./assets/images/close.svg#close"' fill="#4fc0d2"/>
      </svg>
    </span>
    <span (click)="more_div.classList.toggle('d-none'); more_div.classList.toggle('d-flex')" class="pr-4 text-right" style="width: 12%; color: #9da5b7;"
          type="button">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.MORE | managerPipe}}</span>
    <div #more_div class="w-100 d-none flex-row flex-wrap align-items-center justify-content-around py-1 barlow caption medium"
         style=" line-height: 1.2; color: #4a4e70;">
      <span class="w-50 pl-4 my-1 text-left">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.FILLED_PERCENTAGE | managerPipe}}</span>
      <span class="w-50 pr-4 my-1 text-right">{{(1 - user_open_order.amount / user_open_order.init_amount) * 100 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}%</span>
      <span class="w-50 pl-4 my-1 text-left">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.FILLED_AMOUNT | managerPipe}}</span>
      <span class="w-50 pr-4 my-1 text-right">{{user_open_order.init_amount - user_open_order.amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
      <span class="w-50 pl-4 my-1 text-left">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.FILLED_VOLUME | managerPipe}}</span>
      <span class="w-50 pr-4 my-1 text-right">{{user_open_order.price * (user_open_order.init_amount - user_open_order.amount) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
      <span class="w-50 pl-4 my-1 text-left">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.TOTAL_AMOUNT | managerPipe}}</span>
      <span class="w-50 pr-4 my-1 text-right">{{user_open_order.init_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
      <span class="w-50 pl-4 my-1 text-left">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.TOTAL_VOLUME | managerPipe}}</span>
      <span class="w-50 pr-4 my-1 text-right">{{user_open_order.price * user_open_order.init_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
    </div>
  </div>
</div>
