import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
    selector: 'app-auction-agreement-desktop',
    templateUrl: './auction-agreement-desktop.component.html',
    styleUrls: ['./auction-agreement-desktop.component.css']
})
export class AuctionAgreementDesktopComponent implements OnInit {
    @Input() modal_id: string = '';
    @ViewChild('agreement_button') agreementButton: any;
    public checked: boolean = false;

    constructor(public managerService: ManagerService) {
    }

    ngOnInit(): void {
    }

    acceptAuctionAgreement(): void {
            new Promise((resolve, reject) => {
                this.managerService.userService.setAuctionRulesAccepted(resolve, reject);
            }).then(() => {
                localStorage.setItem('auction_agreement_checked', 'true');
                this.managerService.modalService.close(this.modal_id);

                this.managerService.marketingService.logEvent('auction_rules_approved', {});
            }).catch(() => {
                this.managerService.marketingService.logEvent('auction_rules_approvement_error', {});
            });
    }

    rejectAuctionAgreement(): void {
        this.managerService.modalService.close(this.modal_id);
        this.managerService.navigationManager({url: '/home', current_component_index: 0});
    }

    checkBoxToggle(): void {
        this.checked = !this.checked;
        this.agreementButton.nativeElement.disabled = !this.checked;
    }

}
