<div class="d-flex flex-column" style="background: #FFFFFF; padding: 24px; margin: 12px">
    <div class="d-flex w-100 justify-content-start">
        <div  style="margin-top: 12px; margin-left: 12px; color: #404352;font-family: bebas-neue-regular, sans-serif;font-size: 24px; font-weight: bold; line-height: 1.2; letter-spacing: 2.5px;  color: #404352;">
            {{managerService.config.AUCTION.APPROVE_PAYMENT.HEADER | managerPipe}}
        </div>
    </div>

    <div class="d-flex w-100 justify-content-center align-content-center" style="margin-top: 12px; margin-bottom: 12px">
        <svg fill="none" height="108" viewBox="0 0 108 108" width="108" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1329_33031)">
                <path
                    d="M101.016 28.7225L101.016 28.7224C100.566 27.8562 100.107 26.9859 99.3845 26.379C98.4355 25.583 97.2366 25.4613 95.9376 26.1154C94.9962 26.591 94.436 27.2197 94.198 27.9695C93.7861 29.2688 94.4586 30.5856 95.0658 31.7295C100.942 42.7832 102.276 54.3395 99.0195 66.0524C96.4711 75.2207 92.2333 82.6568 86.3337 88.2974C80.4337 93.9385 72.8951 97.7615 63.78 99.7389L63.7799 99.7389C45.8303 103.629 30.5447 98.3529 18.4141 84.0767C3.88477 66.986 3.57186 42.0425 17.6678 24.7551L17.6678 24.755C32.1665 6.97645 56.2662 2.07118 76.2607 12.8433C77.2164 13.3576 77.9966 13.7374 78.8378 13.8145C79.2533 13.8526 79.7401 13.8169 80.2248 13.6008C80.7043 13.387 81.2151 12.9824 81.6637 12.228L81.6638 12.2277C82.2125 11.3062 82.3482 10.4583 82.1387 9.68065L82.1386 9.68027C81.8149 8.47484 80.7442 7.70052 79.4433 6.98109L79.4432 6.98103C68.6607 1.0148 55.9335 -0.9193 43.6045 1.54146C31.2257 4.01263 20.2418 10.7251 12.6731 20.4385C6.16809 28.7875 2.23413 37.6427 0.959028 46.7537C-0.333956 56.0058 1.10626 65.5515 5.25987 75.1365L5.25995 75.1367C9.54061 85.0261 15.6448 92.7778 23.3828 98.1978C31.1206 103.618 40.5043 106.707 51.2756 107.349L51.2761 107.349C52.3779 107.416 53.4748 107.447 54.5668 107.444L54.5703 107.444C67.4941 107.497 79.962 102.648 89.4822 93.8678L89.4829 93.8671C94.9163 88.8767 99.2496 82.8538 102.361 75.965L102.817 76.1708L102.361 75.965C105.541 68.9241 107.273 61.347 107.508 53.4402V53.3907L101.016 28.7225ZM101.016 28.7225C105.136 36.6544 107.438 45.418 107.508 53.3894L101.016 28.7225Z"
                    fill="#4BC4D6" stroke="#4BC4D6"/>
                <path
                    d="M71.0616 40.9958C70.1253 41.9006 69.1632 42.817 68.2327 43.7026C65.4757 46.3256 62.6247 49.0381 59.9961 51.9123C57.7432 54.3746 55.2574 56.7251 52.853 58.9901C50.9843 60.7535 49.0581 62.5708 47.2172 64.4632C45.6073 62.4553 43.7358 60.7689 41.9179 59.1306C40.4076 57.7705 38.9817 56.4855 37.7109 55.0426C36.5917 53.772 35.3813 53.1174 34.1125 53.1059C33.3344 53.0982 32.1739 53.3388 31.0632 54.543C30.0091 55.6865 29.6363 57.0592 30.0158 58.4077C30.3407 59.5628 31.1658 60.482 31.7542 61.0721C32.632 61.9487 33.5104 62.8246 34.3895 63.6999C37.4695 66.7705 40.6548 69.945 43.729 73.1128C44.9183 74.3382 46.119 74.9417 47.4003 74.9465H47.4252C48.6451 74.9465 49.8209 74.3854 50.9211 73.2803C59.4742 64.6859 68.0267 56.0915 76.5785 47.497C76.9532 47.1207 78.0926 45.9752 78.1788 44.18V44.1011C78.1913 42.3184 77.372 40.941 75.8732 40.221C74.1962 39.4153 72.3974 39.7041 71.0616 40.9958Z"
                    fill="#4BC4D6"/>
            </g>
            <defs>
                <clipPath id="clip0_1329_33031">
                    <rect fill="white" height="108" width="108"/>
                </clipPath>
            </defs>
        </svg>
    </div>

    <div class="d-flex w-100 justify-content-center align-items-center flex-row" style="gap: 8px; margin-bottom: 12px">
        <input #check class="checkbox-field" (change)="approve(check.checked)"
               style="cursor: pointer; height: 16px; min-width: 16px; border-radius: 2px; box-shadow: 1px 1px 2px 0 #d8d8d8;"
               type="checkbox">
        <span
            style="color: #404352;font-family: Roboto,sans-serif;font-size: 14px;font-style: normal;font-weight: 400;line-height: normal;letter-spacing: 0.021px;">{{managerService.config.AUCTION.APPROVE_PAYMENT.BODY | managerPipe}}</span>
    </div>

    <div class="d-flex w-100 justify-content-center flex-row" style="gap: 12px">
        <div (click)="managerService.closeModal('approve-payment-modal')"
             style="user-select: none; cursor:pointer; display: flex;height: 40px;padding: 8px 16px;justify-content: center;align-items: center;gap: 8px;border-radius: 6px;border: 1px solid #9DA5B7;">
            {{managerService.config.AUCTION.APPROVE_PAYMENT.CANCEL | managerPipe}}
        </div>
        <div [style.opacity]="approved ? 1 : 0.5" [style.cursor]="approved ? 'pointer' : 'default'" (click)="approvePayment()"
             style=" user-select: none; display: flex;height: 40px;padding: 8px 16px;justify-content: center;align-items: center;gap: 8px;border-radius: 8px;background: #4BC4D6;box-shadow: 0px 2px 6px 0px rgba(160, 162, 180, 0.42);">
            {{managerService.config.AUCTION.APPROVE_PAYMENT.APPROVE | managerPipe}}
        </div>
    </div>
</div>
