import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
  selector: 'app-artist-announcement-list-mobile',
  templateUrl: './artist-announcement-list-mobile.component.html',
  styleUrls: ['./artist-announcement-list-mobile.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ArtistAnnouncementListMobileComponent implements OnInit {

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

}
