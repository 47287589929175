<div  class="d-flex justify-content-start ml-4 position-relative"  style="overflow: hidden; min-height: 162px" >
    <svg (click)="this.managerService.previousPageNavigation()" class="mt-3" style=" height: 24px; width: 24px;">
        <use [attr.xlink:href]='"./assets/images/v2/ic_left.svg#ic_left"' stroke="#131515"/>
    </svg>
    <svg  style="width: 162px; height: auto; right: -81px; top: 16px; position: absolute">
        <use [attr.xlink:href]='"./assets/images/artiox_icon.svg#artiox_icon"' fill="var(--main-color-artiox-purple-100)"/>
    </svg>
</div>
<div class="d-flex flex-column  h-100 w-100 px-4" >

    <div class="d-flex justify-content-center h-100 w-100">
        <div class="d-flex flex-column justify-content-center" [formGroup]="userSignUpForm">
            <span class="mb-2 header3 medium exo" style="color: var(--text-color-artiox-black) ">{{managerService.config.SIGN_UP.CREATE_ACCOUNT | managerPipe}}</span>
            <span class="caption regular barlow mb-4" style="color: var(--text-color-artiox-grey); max-width: 364px">{{managerService.config.SIGN_UP.SIGN_UP_TEXT}}</span>

            <form class="d-flex form-group focused mb-4" [formGroup]="userSignUpForm" autocomplete="off">
                <label class=" d-flex form-label caption barlow medium" for="email">
                    {{managerService.config.SIGN_UP.E_MAIL | managerPipe}}</label>
                <input #email_input autocomplete="off" class="w-100 form-input body2 barlow regular" id="email" formControlName="email" type="text" placeholder="{{managerService.config.LOGIN.E_MAIL_PLACEHOLDER}}">
                <div  #email_error  class="d-flex d-none flex-row " style="gap: 8px; margin-top: 12px" >
                    <svg style=" height: 16px; width: 16px; ">
                        <use [attr.xlink:href]='"./assets/images/error_icon.svg#error_icon"' fill="#EA3943"/>
                    </svg>
                    <div  class="caption barlow regular" style="color: var(--secondary-colors-artiox-red-base)"></div>
                </div>
            </form>
            <div class="d-flex flex-row  " style="margin-bottom: 32px">
                <form class="d-flex form-group focused " [formGroup]="userSignUpForm" autocomplete="off">
                    <label class=" d-flex form-label caption barlow medium position-relative" for="password">
                        {{managerService.config.SIGN_UP.PASSWORD | managerPipe}}
                        <svg (click)="passwordPipe = !passwordPipe" class="password-eye" style="height: 24px; width: 24px; cursor: pointer" >
                            <use *ngIf="(!passwordPipe | managerPipe)"  [attr.xlink:href]='"./assets/images/v2/ic_eye_close.svg#ic_eye_close"' />
                            <use *ngIf="(passwordPipe | managerPipe)" [attr.xlink:href]='"./assets/images/v2/ic_eye_open.svg#ic_eye_open"'/>
                        </svg>
                    </label>

                    <input #password_input autocomplete="off" class="w-100 form-input body2 barlow regular" formControlName="password" id="password" [type]="(passwordPipe | managerPipe) ? 'text': 'password'" placeholder="{{managerService.config.LOGIN.PASSWORD_PLACEHOLDER}}">
                    <div  #password_error  class="d-flex d-none flex-row " style="gap: 8px; margin-top: 12px" >
                        <svg style=" height: 16px; width: 16px; ">
                            <use [attr.xlink:href]='"./assets/images/error_icon.svg#error_icon"' fill="#EA3943"/>
                        </svg>
                        <div  class="caption barlow regular"  style="color: var(--secondary-colors-artiox-red-base)"></div>
                    </div>
                    <!--                        rezil bi implementation -->
                    <div *ngFor="let n of [2014,2015,2017]; index as i " style="margin-top: 12px; gap: 4px;">
                        <div  #password_pattern_error class="d-flex d-none flex-row" style="gap: 8px;"  >
                            <svg class="warning-checkbox d-flex justify-content-center align-items-center " style=" height: 16px; width: 16px; ">
                                <use *ngIf="password_pattern_error.classList.contains('pattern-error')" [attr.xlink:href]='"./assets/images/checkbox_error.svg#checkbox_error"' stroke="#EA3943"/>
                                <use *ngIf="password_pattern_error.classList.contains('pattern-success')" [attr.xlink:href]='"./assets/images/checkbox.svg#checkbox"' fill="#66BB6A"/>
                            </svg>
                            <div  class="caption barlow regular text">{{(managerService.config.RESPONSE_TABLE | managerPipe :managerService.mpa_template.RETURN_INDEX: n).message}}</div>
                        </div>
                    </div>

                </form>


            </div>
            <div *ngFor="let string of checkBoxes; index as i " class="d-flex flex-column" style="margin-bottom: 16px">
                <div class="d-flex flex-row" style="gap: 8px;">
                    <div class="d-flex login-checkbox align-items-center " style="min-width: 16px" (click)="userSignUpForm.get(string)?.setValue(!userSignUpForm.get(string)?.value)" [class.login-checkbox-selected]="checkBoxes[i]">
                        <svg *ngIf="userSignUpForm.get(string)?.value" style="height: 16px; width: 16px;">
                            <use [attr.xlink:href]='"./assets/images/checkbox.svg#checkbox"' fill="#8266F0"/>
                        </svg>
                    </div>
                    <span  [innerHTML]="i | managerPipe: managerService.mpa_template.SIGN_UP_AGREEMENTS" class="d-flex caption barlow regular mb-0 " style="color: var(--text-color-artiox-light-black); max-width: 339px "></span>

                </div>
                <div  #checkbox_error  class="d-flex d-none flex-row ml-4"  >
                    <span class="caption barlow regular" style="color: var(--secondary-colors-artiox-red-base)">*</span>
                    <span  class="caption barlow regular"  style="color: var(--secondary-colors-artiox-red-base)"></span>
                </div>
            </div>
            <div (click)="signUpFormValidator()" class="w-100  d-flex justify-content-center py-2 login-button mt-5">
                <span  class="body2 exo " style="color:#FFFFFF; font-weight: 600">{{managerService.config.SIGN_UP.SIGN_UP | managerPipe}}</span>
            </div>
            <div class="d-flex w-100 justify-content-center mt-3">
                <span  class="caption regular exo" style="color: var(--text-color-artiox-light-black)">{{managerService.config.SIGN_UP.HAVE_ACCOUNT | managerPipe}}</span>
                <span (click)="managerService.navigationManager({url: '/login', current_component_index: 0});"  class="caption regular exo clickable_text" > &nbsp; {{managerService.config.SIGN_UP.LOGIN | managerPipe}}</span>
            </div>

        </div>
    </div>
</div >