<div class="vw-100 min-vw-100 d-flex flex-row align-items-center justify-content-center mt-4" style="height: 60px;">
  <div class="h-100 w-50 d-flex flex-row align-items-center justify-content-end text-uppercase text-right"
       style="font-family: Roboto, sans-serif; font-size: 16px; color: #404352;">
    <span style="width: 140px;">{{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_NAME}}
    </span>
  </div>
  <span class="h-100" style="width: 1px; min-width: 1px; margin: auto 10px; border-radius:1px; background-color: #9da5b7;"></span>
  <div *ngIf="!info_swap |managerPipe"
       class="h-100 w-50 d-flex flex-column align-items-start justify-content-center"
       style="font-family: Roboto, sans-serif; font-size: 16px; color: #404352;">
    <div class="w-100">{{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_PRICE}}₺</div>
    <div class="w-100" style="font-family: Roboto, sans-serif; font-size: 12px; color: #9da5b7;">
      {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_SYMBOL}}
      <svg (click)="swap()" class="ml-1" style="height: 16px; width: 16px;">
        <use [attr.xlink:href]='"./assets/images/switch_icon.svg#switch_icon"' fill="#9da5b7"/>
      </svg>
    </div>
  </div>
  <div *ngIf="info_swap |managerPipe"
       class="h-100 w-50 d-flex flex-column align-items-start justify-content-center"
       style="font-family: Roboto, sans-serif; font-size: 16px; color: #404352;">
    <div class="w-100">{{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_VALUE}}₺</div>
    <div class="w-100 d-flex flex-row" style="font-family: Roboto, sans-serif; font-size: 12px; color: #9da5b7;">
      <div class="d-flex flex-column align-items-center justify-content-center" style="width: 16px; height: 16px;">
        <svg (click)="managerService.toggleTooltip('art-product-market-value-tooltip')"
             class="mr-1" style="height: 14px; width: 14px;">
          <use [attr.xlink:href]='"./assets/images/info.svg#info"' fill="#9da5b7"/>
        </svg>
        <app-tooltip [direction]="managerService.tooltipService.tooltip_direction_template.BOTTOM | managerPipe" class="d-none position-relative"
                     id="art-product-market-value-tooltip">
          {{managerService.config.TOOLTIP.BASIC.ART_PRODUCT_MARKET_VALUE_TOOLTIP | managerPipe}}
        </app-tooltip>
      </div>
      {{managerService.config.BASIC.PAIR_HEADER.ART_PRODUCT_MARKET_VALUE | managerPipe}}
      <svg (click)="swap()" class="ml-1" style="height: 16px; width: 16px;">
        <use [attr.xlink:href]='"./assets/images/switch_icon.svg#switch_icon"' fill="#9da5b7"/>
      </svg>
    </div>
  </div>
</div>
<div class="vw-100 min-vw-100 d-flex flex-row align-items-center justify-content-center my-2" style="height: 30px;">
  <div class="h-100 w-50 text-center"
       style="font-family: Roboto, sans-serif; font-size: 12px; color: #9da5b7;">
    {{managerService.config.BASIC.PAIR_HEADER.VOLUME_24_HOUR_MOBILE | managerPipe}}
    {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_VOLUME}}
    {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_CURRENCY_SYMBOL}}
  </div>
  <div class="h-100 w-50 text-center"
       style="font-family: Roboto, sans-serif; font-size: 12px; color: #9da5b7;">
    {{managerService.config.BASIC.PAIR_HEADER.CHANGE_24_HOUR_MOBILE | managerPipe}}
    <span [style]="managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_CHANGE | managerPipe: managerService.mpa_template.PAIR_CHANGE_COLOR">
      {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_CHANGE}}%
    </span>
  </div>
</div>
