<div class="d-flex flex-column" style="background: #FFFFFF; padding: 48px 64px">
    <!--    Header and close button row -->
    <div class="d-flex flex-row justify-content-between align-items-center pb-4">
        <h1>{{managerService.config.BILLING_ADDRESS.HEADER| managerPipe }}</h1>
        <div (click)="closeModal()" style="cursor: pointer">
            <img alt="close" src="assets/images/close.svg" style="width: 24px; height: 24px">
        </div>

    </div>

    <h2 style="margin-bottom: 32px">{{managerService.config.BILLING_ADDRESS.MODAL_INFO | managerPipe}}</h2>

    <div class="d-flex flex-row" style="gap: 32px; margin-bottom: 32px">
        <div (click)="billingOption = BillingOption.INDIVIDUAL; activeFormGroup = individualFormGroup" class="d-flex align-items-center flex-row "
             style="gap: 8px">
            <div class="d-flex align-items-center justify-content-center circle ">
                <div [class.selected_circle]="billingOption === BillingOption.INDIVIDUAL" class="d-flex"></div>
            </div>
            <h3 class="text-center"
                style="margin-bottom: 0">{{managerService.config.BILLING_ADDRESS.INDIVIDUAL_OPTION | managerPipe}}</h3>

        </div>
        <div (click)="billingOption = BillingOption.CORPORATE; activeFormGroup = corporateFormGroup" class="d-flex flex-row align-items-center "
             style="gap: 8px">
            <div class="d-flex align-items-center justify-content-center circle ">
                <div [class.selected_circle]="billingOption === BillingOption.CORPORATE" class="d-flex"></div>
            </div>
            <h3 style="margin-bottom: 0">{{managerService.config.BILLING_ADDRESS.CORPORATE_OPTION | managerPipe}}</h3>
        </div>
    </div>
    <p [innerHTML]="managerService.config.PAYMENT.MANDATORY_FIELDS | managerPipe" style="margin-bottom: 24px"></p>
    <!--  Individual  Form Group -->
    <div *ngIf="billingOption === BillingOption.INDIVIDUAL" [formGroup]="individualFormGroup"
         class="d-flex flex-column w-100"
         style="gap: 32px;margin-top:24px; margin-bottom: 16px">
        <!--        Address Title -->
        <div class="d-flex form-group focused">
            <label class=" d-flex form-label" for="label_individual">
                {{managerService.config.BILLING_ADDRESS.INDIVIDUAL_HEADERS.ADDRESS_HEADER}}</label>
            <input class="w-100 readonly-input" formControlName="label" id="label_individual" style="color: #404352;"
                   type="text" value="Bugra Senocak Caddesi">
        </div>
        <!--        Name Surname Identity Number -->
        <div class="w-100 d-flex flex-row" style="gap: 80px">
            <!--            Name -->
            <div class="d-flex form-group focused">
                <label class=" d-flex form-label" for="name_individual">
                    {{managerService.config.BILLING_ADDRESS.INDIVIDUAL_HEADERS.NAME | managerPipe}}</label>
                <input class="w-100 readonly-input" id="name_individual" readonly="readonly" type="text"
                       value="{{managerService.userService.user_info?.name}}">
            </div>
            <!--            Surname -->
            <div class="d-flex form-group focused">
                <label class=" d-flex form-label" for="surname_individual">
                    {{managerService.config.BILLING_ADDRESS.INDIVIDUAL_HEADERS.SURNAME | managerPipe}}</label>
                <input class="w-100 readonly-input" id="surname_individual" readonly="readonly" type="text"
                       value="{{managerService.userService.user_info?.surname}}">
            </div>
            <!--            Identity Number -->
            <div class="d-flex form-group focused">
                <label class=" d-flex form-label"
                       for="idNumber_individual"> {{managerService.config.BILLING_ADDRESS.INDIVIDUAL_HEADERS.ID_NUMBER | managerPipe}}</label>
                <input [type]="(tc_number_visible | managerPipe) ? 'text' : 'password'" class="w-100 readonly-input"
                       id="idNumber_individual" readonly="readonly" value="{{managerService.userService.user_info?.tc_no}}">
                <svg (click)="tc_number_visible = !tc_number_visible" class="password-eye" style="width: 20px; height: 20px">
                    <use *ngIf="(!tc_number_visible | managerPipe)"
                         [attr.xlink:href]='"./assets/images/eye_hidden.svg#eye_hidden"'
                         fill="#9DA5B7"/>
                    <use *ngIf="(tc_number_visible | managerPipe)"
                         [attr.xlink:href]='"./assets/images/eye_visible.svg#eye_visible"'
                         fill="#9DA5B7"/>
                </svg>
            </div>
        </div>
        <div class="w-100 d-flex flex-row" style="gap: 80px">
            <!--            Phone Number -->
            <div class="d-flex form-group focused">
                <label class=" d-flex form-label" for="phoneNumberIndividual">
                    <span
                        class="asterix">*</span>{{managerService.config.BILLING_ADDRESS.INDIVIDUAL_HEADERS.PHONE_NUMBER | managerPipe}}
                </label>
                <input class="w-100 form-input" formControlName="phone_number" id="phoneNumberIndividual" placeholder="(___) ___ __ __"
                       style="color: #404352;" type="text">
            </div>
            <!--            Province Dropdown -->
            <div (click)="town_dropdown_menu.classList.remove('d-none')"
                 class="d-flex form-group focused dropdown-native-button">
                <label class="d-flex form-label" for="province_individual">
                    <span
                        class="asterix">*</span> {{managerService.config.BILLING_ADDRESS.INDIVIDUAL_HEADERS.PROVINCE | managerPipe}}
                </label>
                <input #province class="w-100 readonly-input" formControlName="province" id="province_individual"
                       readonly="readonly"
                       style="cursor: pointer;color: #404352" type="text" value="Select City">
                <svg class="dropdown-icon">
                    <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#9DA5B7"/>
                </svg>
                <div #town_dropdown_menu class="dropdown-native-menu-white d-none" style="margin-top: 32px">
                    <button (click)="town_dropdown_menu.classList.add('d-none'); activeFormGroup.get('province')?.setValue(town); activeFormGroup.get('district')?.reset();"
                            *ngFor="let town of (null | managerPipe: managerService.mpa_template.GET_CITIES_LIST)"
                            class="dropdown-native-item-white btn-native justify-content-center" type="button">
                        {{town | managerPipe}}
                    </button>
                </div>
            </div>


            <div (click)="district_dropdown_menu.classList.remove('d-none')"
                 class="d-flex form-group focused dropdown-native-button">
                <label class="d-flex form-label" for="district_individual">
                    <span
                        class="asterix">*</span>{{managerService.config.BILLING_ADDRESS.INDIVIDUAL_HEADERS.DISTRICT | managerPipe}}
                </label>
                <input #district class="w-100 readonly-input" formControlName="district" id="district_individual"
                       readonly="readonly"
                       style="cursor: pointer;color: #404352" type="text" value="Select City">
                <svg class="dropdown-icon">
                    <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#9DA5B7"/>
                </svg>
                <div #district_dropdown_menu class="dropdown-native-menu-white d-none" style="margin-top: 32px">
                    <button (click)="district_dropdown_menu.classList.add('d-none'); activeFormGroup.get('district')?.setValue(dist) "
                            *ngFor="let dist of (province.value | managerPipe: managerService.mpa_template.GET_DISTRICT_LIST)"
                            class="dropdown-native-item-white btn-native justify-content-center" type="button">
                        {{dist | managerPipe}}
                    </button>
                </div>
            </div>
        </div>
        <div class="d-flex form-group focused" style="width: 560px">
            <label class=" d-flex form-label" for="address_individual">
                <span
                    class="asterix">*</span>{{managerService.config.BILLING_ADDRESS.INDIVIDUAL_HEADERS.ADDRESS | managerPipe}}
            </label>
            <input class="w-100  readonly-input"
                   formControlName="address"
                   id="address_individual"
                   style="color: #404352;font-family: Roboto,sans-serif;font-size: 12px;font-style: normal;font-weight: 400;line-height: normal;" type="text">
        </div>
    </div>
    <div *ngIf="billingOption === BillingOption.CORPORATE" [formGroup]="corporateFormGroup"
         class="d-flex flex-column w-100"
         style="gap: 32px;margin-top:24px; margin-bottom: 16px">
        <!--        Address Title -->
        <div class="d-flex form-group focused">
            <label class=" d-flex form-label" for="label_corporate">
                {{managerService.config.BILLING_ADDRESS.CORPORATE_HEADERS.ADDRESS_HEADER | managerPipe}}</label>
            <input class="w-100 readonly-input" formControlName="label" id="label_corporate" style="color: #404352;"
                   type="text">
        </div>
        <!--        Name Surname Identity Number -->
        <div class="w-100 d-flex flex-row" style="gap: 80px">
            <!--            Corporate Name -->
            <div class="d-flex form-group focused">
                <label class=" d-flex form-label" for="corporateName">
                    <span
                        class="asterix">*</span>{{managerService.config.BILLING_ADDRESS.CORPORATE_HEADERS.CORPORATE_NAME | managerPipe}}
                </label>
                <input class="w-100 form-input" formControlName="corporateName" id="corporateName" style="color: #404352;"
                       type="text">
            </div>
            <!--            Tax Administration -->
            <div class="d-flex form-group focused">
                <label class=" d-flex form-label" for="taxAdministration">
                    <span
                        class="asterix">*</span>{{managerService.config.BILLING_ADDRESS.CORPORATE_HEADERS.TAX_ADMINISTRATION | managerPipe}}
                </label>
                <input class="w-100 form-input" formControlName="taxAdministration" id="taxAdministration" style="color: #404352;"
                       type="text">
            </div>
            <!--            Tax Number -->
            <div class="d-flex form-group focused">
                <label class=" d-flex form-label"
                       for="taxNumber"> <span
                    class="asterix">*</span>{{managerService.config.BILLING_ADDRESS.CORPORATE_HEADERS.TAX_NUMBER | managerPipe}}
                </label>
                <input [maxLength]="10"
                       class="w-100 form-input" formControlName="taxNumber" id="taxNumber"
                       oninput="this.value = this.value.slice(0,this.maxLength)" style="color: #404352;"
                       type="number">
            </div>
        </div>
        <div class="w-100 d-flex flex-row" style="gap: 80px">
            <!--            Phone Number -->
            <div class="d-flex form-group focused">
                <label class=" d-flex form-label" for="phoneNumberCorporate">
                    <span
                        class="asterix">*</span>{{managerService.config.BILLING_ADDRESS.CORPORATE_HEADERS.PHONE_NUMBER | managerPipe}}
                </label>
                <input class="w-100 form-input" formControlName="phone_number" id="phoneNumberCorporate" placeholder="(___) ___ __ __"
                       style="color: #404352;" type="text">
            </div>
            <!--            Province Dropdown -->
            <div (click)="town_dropdown_menu_corporate.classList.remove('d-none')"
                 class="d-flex form-group focused dropdown-native-button">
                <label class="d-flex form-label" for="provinceCorporate">
                    <span
                        class="asterix">*</span>{{managerService.config.BILLING_ADDRESS.CORPORATE_HEADERS.PROVINCE | managerPipe}}
                </label>
                <input #province_corporate class="w-100 readonly-input" formControlName="province" id="provinceCorporate"
                       readonly="readonly"
                       style="cursor: pointer;color: #404352" type="text" value="Select City">
                <svg class="dropdown-icon">
                    <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#9DA5B7"/>
                </svg>
                <div #town_dropdown_menu_corporate class="dropdown-native-menu-white d-none" style="margin-top: 32px">
                    <button
                        (click)="town_dropdown_menu_corporate.classList.add('d-none'); activeFormGroup.get('province')?.setValue(town); activeFormGroup.get('district')?.reset(); "
                        *ngFor="let town of (null | managerPipe: managerService.mpa_template.GET_CITIES_LIST)"
                        class="dropdown-native-item-white btn-native justify-content-center" type="button">
                        {{town | managerPipe}}
                    </button>
                </div>
            </div>


            <div (click)="district_dropdown_menu_corporate.classList.remove('d-none')"
                 class="d-flex form-group focused dropdown-native-button">
                <label class="d-flex form-label" for="districtCorporate">
                    <span
                        class="asterix">*</span>{{managerService.config.BILLING_ADDRESS.CORPORATE_HEADERS.DISTRICT | managerPipe}}
                </label>
                <input #district_corporate class="w-100 readonly-input" formControlName="district" id="districtCorporate"
                       readonly="readonly"
                       style="cursor: pointer;color: #404352" type="text" value="Select City">
                <svg class="dropdown-icon">
                    <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#9DA5B7"/>
                </svg>
                <div #district_dropdown_menu_corporate class="dropdown-native-menu-white d-none"
                     style="margin-top: 32px">
                    <button (click)="district_dropdown_menu_corporate.classList.add('d-none'); activeFormGroup.get('district')?.setValue(dist) "
                            *ngFor="let dist of (province_corporate.value | managerPipe: managerService.mpa_template.GET_DISTRICT_LIST)"
                            class="dropdown-native-item-white btn-native justify-content-center" type="button">
                        {{dist | managerPipe}}
                    </button>
                </div>
            </div>
        </div>
        <div class="d-flex form-group focused" style="width: 560px">
            <label class=" d-flex form-label" for="addressCorporate">
                <span
                    class="asterix">*</span>{{managerService.config.BILLING_ADDRESS.CORPORATE_HEADERS.ADDRESS | managerPipe}}
            </label>
            <input class="w-100  readonly-input"
                   formControlName="address"
                   id="addressCorporate"
                   style="color: #404352;font-family: Roboto,sans-serif;font-size: 12px;font-style: normal;font-weight: 400;line-height: normal;" type="text">
        </div>
    </div>
    <!--    Check Box-->
    <div *ngIf="billingOption === BillingOption.CORPORATE" class="d-flex flex-row align-items-center"
         style="gap: 8px; margin-bottom: 32px">
        <div (click)="checkboxSelected = !checkboxSelected" [class.address-checkbox-selected]="checkboxSelected"
             class="d-flex address-checkbox align-items-center">
            <img *ngIf="checkboxSelected" alt="checkbox" height="20" src="assets/images/checkbox.svg" style="user-select: none"
                 width="20">
        </div>
        <p class="d-flex mb-0">{{managerService.config.BILLING_ADDRESS.CORPORATE_HEADERS.E_INVOICE}}</p>
    </div>

    <!--    Buttons -->
    <div class="d-flex flex-row justify-content-center" style="gap: 32px ">
        <div (click)="closeModal()" class="cancel-button" style="cursor: pointer">
            <span class="text">{{managerService.config.AUCTION.BILLING.CANCEL | managerPipe}}</span>
        </div>
        <div (click)="saveBillingAddress()" class="save-button"
             style="cursor: pointer">
            <span class="text">{{managerService.config.AUCTION.BILLING.SAVE_ADDRESS | managerPipe}}</span>
        </div>

    </div>
</div>
