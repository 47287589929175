<div #main_page class="d-flex flex-column h-100">

    <div class="d-flex justify-content-end mt-2 mb-4"  >
        <svg (click)="this.managerService.previousPageNavigation()" style=" height: 24px; width: 24px; margin-right: 24px ">
            <use [attr.xlink:href]='"./assets/images/v2/ic_x.svg#ic_x"' stroke="#8266F0"/>
        </svg>
    </div>
    <div class="d-flex  h-100 align-items-center justify-content-center  w-100" >
        <div class="d-flex  flex-row justify-content-center  position-relative" style="height: 45dvh">

        <img  src="./assets/images/scattered_paint.svg" alt="scattered_paint" style="z-index: -1; position: absolute; height: 38dvh; align-self: center">
<!--        Video -->
        <div class="d-flex h-100 w-100 justify-content-end align-items-start mr-3"  >

            <video #app_video (canplay)="app_video.play()" (loadedmetadata)="app_video.muted=true" autoplay  loop muted
                   playsinline style="height:40dvh;border-radius: 8px;
                    border: 4px solid var(--secondary-colors-artiox-gray-base, #E2E2E2);">
                <source class="w-100" src="./assets/animations/app.webm" type='video/webm; codecs="vp8.0"'/>
                <source class="w-100" src="./assets/animations/app.mp4" type='video/mp4;'/>
            </video>
        </div>
<!--        Image -->
        <div class="d-flex flex-column justify-content-end align-items-start w-100 ml-3">
            <img  src="./assets/screen_shots/desktop/market_full.png" alt="market" style="height: 35dvh;
         ">
        </div>
        </div>
    </div>

    <div class="d-flex flex-column justify-content-end  mx-4" style="margin-top: 60px">
        <span class="exo header3 medium mb-3" style="color: var(--main-color-artiox-purple-base, #8266F0);">{{managerService.config.LOGIN.WELCOME_TO_ARTIOX | managerPipe}}</span>
        <span class="barlow caption regular mb-5" style="color: var(--text-color-artiox-grey, #6F6F6F);">{{managerService.config.SIGN_UP.SIGN_UP_HEADER_TEXT | managerPipe}}</span>

        <div class="w-100  d-flex justify-content-center py-2 login-button mb-3" (click)="openLogin()">
            <span class="body2 exo " style="color:#FFFFFF; font-weight: 600">{{managerService.config.LOGIN.LOGIN | managerPipe}}</span>
        </div>
        <div class="w-100  d-flex justify-content-center py-2 sign-up-button mb-5" (click)="managerService.navigationManager({url: '/sign-up', current_component_index: 0});">
            <span class="body2 exo " style="color: var(--main-color-artiox-purple-base, #8266F0); font-weight: 600">{{managerService.config.SIGN_UP.SIGN_UP | managerPipe}}</span>
        </div>
    </div>
</div>

<div #login_page class="d-flex d-none flex-column position-relative w-100 h-100 px-4" style="overflow: hidden">

        <svg  style="width: 162px; height: auto; overflow: hidden; right: -81px; top: 16px; position: absolute">
        <use [attr.xlink:href]='"./assets/images/artiox_icon.svg#artiox_icon"' fill="var(--main-color-artiox-purple-100)"/>
        </svg>

    <div (click)="closeLogin()" class="d-flex justify-content-start mt-3 mb-4"  >
        <svg style=" height: 24px; width: 24px;">
            <use [attr.xlink:href]='"./assets/images/v2/ic_left.svg#ic_left"' stroke="#131515"/>
        </svg>
    </div>
    <div class="d-flex justify-content-center h-100 w-100" [formGroup]="loginForm">
        <div *ngIf="login_step !== LoginStep.TWO_FACTOR" class="d-flex flex-column justify-content-center">
            <span class="mb-2 header3 medium exo" style="color: var(--text-color-artiox-black) ">{{managerService.config.LOGIN.WELCOME_TO_ARTIOX | managerPipe}}</span>
            <span class="caption regular barlow  mb-5" style="color: var(--text-color-artiox-grey); max-width: 320px">{{managerService.config.LOGIN.SUBTITLE | managerPipe}}</span>

            <div class="d-flex form-group focused mb-4">
                <label class=" d-flex form-label caption barlow medium" for="email">
                    {{managerService.config.LOGIN.E_MAIL | managerPipe}}</label>
                <input class="w-100 form-input body2 barlow regular" formControlName="email" id="email" type="text" placeholder="{{managerService.config.LOGIN.E_MAIL_PLACEHOLDER}}">
                <div  #email_error  class="d-flex d-none flex-row " style="gap: 8px; margin-top: 12px" >
                    <svg style=" height: 16px; width: 16px; ">
                        <use [attr.xlink:href]='"./assets/images/error_icon.svg#error_icon"' fill="#EA3943"/>
                    </svg>
                    <div  class="caption barlow regular"  style="color: var(--secondary-colors-artiox-red-base)"></div>
                </div>
            </div>
            <div class="d-flex flex-row  " >
                <div class="d-flex form-group focused mb-3 ">
                    <label class=" d-flex form-label caption barlow medium position-relative" for="password">
                        {{managerService.config.LOGIN.PASSWORD | managerPipe}}
                        <svg (click)="passwordPipe = !passwordPipe" class="password-eye" style="height: 24px; width: 24px; cursor: pointer" >
                            <use *ngIf="(!passwordPipe | managerPipe)"  [attr.xlink:href]='"./assets/images/v2/ic_eye_close.svg#ic_eye_close"' />
                            <use *ngIf="(passwordPipe | managerPipe)" [attr.xlink:href]='"./assets/images/v2/ic_eye_open.svg#ic_eye_open"'/>
                        </svg>
                    </label>
                    <input class="w-100 form-input caption barlow regular" formControlName="password" id="password" [type]="(passwordPipe | managerPipe) ? 'text': 'password'" placeholder="{{managerService.config.LOGIN.PASSWORD_PLACEHOLDER}}">
                    <div #password_error  class="d-flex d-none flex-row" style="gap: 8px; margin-top: 12px" >
                        <svg style=" height: 16px; width: 16px; ">
                            <use [attr.xlink:href]='"./assets/images/error_icon.svg#error_icon"' fill="#EA3943"/>
                        </svg>
                        <span class="body2 barlow regular"  style="color: var(--secondary-colors-artiox-red-base)"></span>
                    </div>
                </div>


            </div>
            <div class="d-flex flex-row justify-content-end">
<!--                <div class="d-flex flex-row align-items-center" style="gap: 8px; margin-bottom: 32px">-->
<!--                    <div  class="d-flex login-checkbox align-items-center"  (click)="checkboxSelected = !checkboxSelected" [class.login-checkbox-selected]="checkboxSelected">-->
<!--                        <svg *ngIf="checkboxSelected" style="height: 16px; width: 16px;">-->
<!--                            <use [attr.xlink:href]='"./assets/images/checkbox.svg#checkbox"' fill="#8266F0"/>-->
<!--                        </svg>-->
<!--                    </div>-->
<!--                    <span class="d-flex caption barlow regular" style="color: var(--text-color-artiox-light-black)">Hesabimi hatirla</span>-->
<!--                </div>-->
                <span class="caption regular barlow clickable_text" (click)="managerService.navigationManager({url: '/forgot-password', current_component_index: 0});" >{{managerService.config.LOGIN.FORGOT_PASSWORD | managerPipe}}</span>
            </div>

        </div>
        <div *ngIf="login_step === LoginStep.TWO_FACTOR" class="d-flex flex-column justify-content-center">
            <span class="mb-2 header3 medium exo" style="color: var(--text-color-artiox-black) ">{{managerService.config.LOGIN.AUTH_TITLE | managerPipe}}</span>
            <span class="caption regular barlow " style="color: var(--text-color-artiox-grey); margin-bottom: 32px">{{managerService.config.LOGIN.AUTH_SUBTITLE | managerPipe}}</span>

            <div class="d-flex form-group focused mb-4"  [formGroup]="loginForm">
                <label class=" d-flex form-label caption barlow medium" for="auth_password">
                    {{managerService.config.LOGIN.AUTH | managerPipe}}</label>
                <input class="w-100 form-input body2 barlow regular" formControlName="auth_password"  id="auth_password" type="text" placeholder="{{managerService.config.LOGIN.AUTH_PLACEHOLDER}}">
                <div id="auth_password_error"  class="d-flex d-none flex-row" style="gap: 8px; margin-top: 12px" >
                    <svg style=" height: 16px; width: 16px; ">
                        <use [attr.xlink:href]='"./assets/images/error_icon.svg#error_icon"' fill="#EA3943"/>
                    </svg>
                    <span class="caption barlow regular"  style="color: var(--secondary-colors-artiox-red-base)"></span>
                </div>
            </div>
            <div class="w-100  d-flex justify-content-center py-2 login-button" (click)="validateFormGroup()" id="submit-button">
                <span class="body2 exo " style="color:#FFFFFF; font-weight: 600">{{managerService.config.LOGIN.LOGIN | managerPipe}}</span>
            </div>
        </div>

    </div>
    <div *ngIf="login_step !== LoginStep.TWO_FACTOR" class="d-flex justify-content-center align-items-center flex-column w-100 mb-5" >
        <div class="w-100  d-flex justify-content-center py-2 login-button" style="max-width: 320px"(click)="validateFormGroup()">
            <span class="body2 exo " style="color:#FFFFFF; font-weight: 600">{{managerService.config.LOGIN.LOGIN | managerPipe}}</span>
        </div>
        <div class="d-flex w-100 justify-content-center mt-3">
            <span  class="caption regular exo" style="color: var(--text-color-artiox-light-black)">{{managerService.config.LOGIN.NO_ACCOUNT | managerPipe}}</span>
            <span  class="caption regular exo clickable_text" (click)="managerService.navigationManager({url: '/sign-up', current_component_index: 0});" > &nbsp; {{managerService.config.LOGIN.CREATE_NEW_ACCOUNT | managerPipe}}</span>
        </div>
    </div>
</div >


<app-modal #login_captcha *ngIf="login_step === LoginStep.PUZZLE" class="d-flex" (click)="login_step = LoginStep.BASE;">
    <app-captcha-desktop  (response)="loginStepResolver($event);" (click)="$event.stopPropagation()"></app-captcha-desktop>
</app-modal>
