import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../services/manager.service';

@Component({
  selector: 'app-home-desktop',
  templateUrl: './home-desktop.component.html',
  styleUrls: ['./home-desktop.component.css']
})
export class HomeDesktopComponent implements OnInit, AfterViewInit {

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.managerService.open_announcement_modal) {
        this.managerService.openModal('announcement-modal');
        this.managerService.open_announcement_modal = false;
      }
    }, 1000);
  }

}
