<div class="mx-auto my-5" style="width: 1200px">
    <div class="d-flex flex-row"
         style="height: 32px; border-radius: 8px;border: 1px solid var(--secondary-colors-artiox-gray-base, #E2E2E2);background: #FCFDFF;">
        <div class="d-flex justify-content-center align-items-center barlow body2 medium"
             style="width: 100px; border-radius: 4px 0 0 4px; background: #ECE8FD; color: #8266F0;">
            {{managerService.config.AUCTION.LAST_BIDS.TEXT | managerPipe}}
        </div>
        <div class="marquee-container"
             style="width: 1100px; height: 32px;">
            <div
                style="width: 100%; transform: translateX(100%); position: absolute;top: 3px ;height: 19px;margin-bottom: 2px">
                <div class="marquee-content ">
                    <span
                        (click)="managerService.navigateIfLoggedIn({url: '/auction/auction_product', active_auction_product_hash: this.managerService.auctionService.auction_product_list.getAuctionProductById(bid.auction_product_id).hash, current_component_index: 3})"
                        *ngFor="let bid of (managerService.bidService.bid_tickers_pipe | managerPipe: managerService.mpa_template.GET_BID_TICKERS)"
                        class=" marquee-item d-flex align-items-center flex-row" style="margin-top: 4px">
                        <div class="d-flex align-items-center" style="gap: 8px">
                            <span class="barlow body2 bold "
                                  style="padding-right: 2px;color: var(--text-color-artiox-light-black, #37324D);">
                            LOT {{managerService.auctionService.auction_product_list.getAuctionProductById(bid.auction_product_id).lot}}

                        </span>
                        <span class="barlow body2 regular"
                              style="color: var(--text-color-artiox-light-black, #37324D);">
                            {{ managerService.auctionService.auction_product_list.getAuctionProductById(bid.auction_product_id).name}}
                        </span>
                        <span class="barlow body2 bold" style="color:#66BB6A">
                            {{bid.price | managerPipe: managerService.mpa_template.FANCY_NUMBER_NO_DECIMAL}} TL
                        </span>
                        </div>
                        <div class="d-flex justify-content-center align-items-center"
                             style="width: 24px; height: 4px; border-radius: 4px;background: #E2E2E2; margin: 0 16px"></div>
                    </span>
                </div>
            </div>
            <div
                style="width: 100%; transform: translateX(100%); position: absolute;top: 3px ;height: 19px;margin-bottom: 2px">
                <div class="marquee-content " style="animation-delay: 20s">
                    <span
                        (click)="managerService.navigateIfLoggedIn({url: '/auction/auction_product', active_auction_product_hash: this.managerService.auctionService.auction_product_list.getAuctionProductById(bid.auction_product_id).hash, current_component_index: 3})"
                        *ngFor="let bid of (managerService.bidService.bid_tickers_pipe | managerPipe: managerService.mpa_template.GET_BID_TICKERS)"
                        class=" marquee-item d-flex align-items-center flex-row" style="margin-top: 4px">
                        <div class="d-flex align-items-center" style="gap: 8px">
                            <span class="barlow body2 bold "
                                  style="padding-right: 2px;color: var(--text-color-artiox-light-black, #37324D);">
                            LOT {{managerService.auctionService.auction_product_list.getAuctionProductById(bid.auction_product_id).lot}}

                        </span>
                        <span class="barlow body2 regular"
                              style="color: var(--text-color-artiox-light-black, #37324D);">
                            {{ managerService.auctionService.auction_product_list.getAuctionProductById(bid.auction_product_id).name}}
                        </span>
                        <span class="barlow body2 bold" style="color:#66BB6A">
                            {{bid.price | managerPipe: managerService.mpa_template.FANCY_NUMBER_NO_DECIMAL}} TL
                        </span>
                        </div>
                        <div class="d-flex justify-content-center align-items-center"
                             style="width: 24px; height: 4px; border-radius: 4px;background: #E2E2E2; margin: 0 16px"></div>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex flex-row justify-content-between align-items-center mt-5">
        <div (click)="toggleFilterForm()" class="d-flex flex-row py-3"
             style="cursor: pointer; user-select: none;">
            <svg style="cursor: pointer; height: 24px; width: 24px;">
                <use [attr.xlink:href]='"./assets/images/filter.svg#filter"' stroke="#37324D"/>
            </svg>
            <span class="ms-2 exo subtitle1 medium" style="color: #37324D">
                {{managerService.config.AUCTION.FILTER.TITLE | managerPipe}}
            </span>
        </div>
        <div class="d-flex flex-row justify-content-center align-items-center dropdown-native-button">
            <div class="d-flex px-2"
                 style="width: 200px; height: 40px; border-radius: 4px; border: 1px solid var(--secondary-colors-artiox-gray-base, #E2E2E2); background-color: #FFFFFF; ">
                <div class="d-flex flex-row align-items-center justify-content-between w-100">
                    <div class="d-flex barlow body2 regular"
                         style="color: var(--text-color-artiox-grey, #6F6F6F);padding-left: 8px">
                        {{managerService.auctionService.active_catalog_sorting_type_pipe | managerPipe: managerService.mpa_template.AUCTION_CATALOG_ACTIVE_SORT_OPTION: managerService.language_pipe}}
                    </div>
                    <div class="d-flex">
                        <svg style=" height: 16px; width: 16px;">
                            <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#404352"/>
                        </svg>
                    </div>
                </div>
                <div class="dropdown-native-menu overflow-visible "
                     style="cursor:pointer;width: 200px; border-radius: 4px; background-color: #FFFFFF;margin-left: -9px; margin-top: 38px;border: 1px solid var(--secondary-colors-artiox-gray-base, #E2E2E2);">
                    <div
                        (click)="managerService.stopProp($event); managerService.auctionService.setActiveCatalogSortingType(i);"
                        *ngFor="let option of (managerService.language_pipe | managerPipe: managerService.mpa_template.AUCTION_CATALOG_SORT_OPTIONS); index as i"
                        class="dropdown-native-item-white  btn-native justify-content-start "
                        style="width: inherit;  height: 40px;">
                        <div
                            class="barlow body2 regular "
                            style="color: var(--text-color-artiox-grey, #6F6F6F); padding-left: 16px ">
                            {{option}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex flex-row">
        <div [class.d-none]="!showFilterForm">
            <form [formGroup]="filterForm"
                  style="width: 354px; padding: 24px;margin-top: 48px; background: #FFFFFF;box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.24), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);border-radius: 6px;">
                <div class="d-flex justify-content-between">
                    <div class="d-flex exo subtitle1 medium"
                         style="user-select: none;color: var(--text-color-artiox-light-black, #37324D); margin-top: 20px">
                        {{managerService.config.AUCTION.FILTER.FILTERS | managerPipe}}
                    </div>
                    <div #clear_all (click)="clearAll()"
                         class="exo subtitle2 regular"
                         style="display: none; cursor:pointer; color: #EA3943;margin-top: 20px">
                        {{managerService.config.AUCTION.FILTER.CLEAR | managerPipe}}
                    </div>
                </div>
                <div #display_filters class="w-100 justify-content-center" style="display: none; margin-top: 10px;">
                    <div class="d-flex w-100" style="gap: 8px">
                        <div #clear_keyword
                             class="exo subtitle2 medium align-items-center"
                             style="display:none;border-radius: 4px;background: var(--main-color-artiox-purple-200, #ECE8FD); padding: 8px;color: #8266F0">
                            <div>
                                {{managerService.config.AUCTION.FILTER.ARTIST_PRODUCT | managerPipe}}
                            </div>
                            <div (click)="clearKeyword()" style="cursor: pointer;">
                                <svg style="margin-left: 8px; height: 18px; width: 18px;">
                                    <use
                                        [attr.xlink:href]='"./assets/images/close_with_circle.svg#close_with_circle"'
                                        stroke="#8266F0"/>
                                </svg>
                            </div>
                        </div>
                        <div #clear_price
                             class="exo subtitle2 medium align-items-center"
                             style="display:none;border-radius: 4px;background: var(--main-color-artiox-purple-200, #ECE8FD); padding: 8px;color: #8266F0">
                            <div>
                                {{managerService.config.AUCTION.FILTER.PRICE | managerPipe}}
                            </div>
                            <div (click)="clearPrice()" style="cursor: pointer;">
                                <svg style="margin-left: 8px; height: 18px; width: 18px;">
                                    <use
                                        [attr.xlink:href]='"./assets/images/close_with_circle.svg#close_with_circle"'
                                        stroke="#8266F0"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div (click)="display_keyword = !display_keyword"
                     class="d-flex flex-row justify-content-between align-items-center w-100 exo subtitle1 medium"
                     style="margin-top: 24px">
                    <div class="d-flex align-items-center" style="user-select: none;cursor:pointer;color: #404352;">
                        {{managerService.config.AUCTION.FILTER.ARTIST_PRODUCT | managerPipe}}

                    </div>
                    <div class="d-flex align-items-center">
                        <svg style=" height: 10px; width: 10px;">
                            <use
                                [attr.xlink:href]=' display_keyword ? "./assets/images/up_arrow.svg#up_arrow"  : "./assets/images/down_arrow.svg#down_arrow"'
                                fill="#131515"/>
                        </svg>
                    </div>
                </div>
                <div [style.display]="display_keyword ? 'flex' : 'none'"
                     class="w-100 justify-content-center barlow body1 regular"
                     style="display: none; margin-top: 16px;position: relative">
                    <input class="w-100" formControlName="keyword"
                           placeholder="{{managerService.config.AUCTION.FILTER.KEYWORD | managerPipe}}"
                           style="display: flex;padding: 12px 16px;border: 1px solid var(--secondary-colors-artiox-gray-400, #CBCBCB);border-radius: 4px;"
                           type="text">
                    <svg
                        style=" height: 16px; width: 16px;position: absolute; right: 16px; top: 20px">
                        <use [attr.xlink:href]='"./assets/images/search.svg#search"' fill="#B5B5B5"/>
                    </svg>
                </div>
                <div (click)="display_price = !display_price"
                     class="d-flex flex-row justify-content-between align-items-center w-100 exo subtitle1 medium"
                     style="margin-top: 24px">
                    <div class="d-flex align-items-center" style="user-select: none;cursor:pointer;color: #404352;">
                        {{managerService.config.AUCTION.FILTER.PRICE | managerPipe}}
                    </div>
                    <div class="d-flex">
                        <svg style=" height: 10px; width: 10px;">
                            <use
                                [attr.xlink:href]=' display_price ? "./assets/images/up_arrow.svg#up_arrow"  : "./assets/images/down_arrow.svg#down_arrow"'
                                fill="#131515"/>
                        </svg>
                    </div>
                </div>
                <div [style.display]="display_price ? 'flex' : 'none'" class="w-100 justify-content-center"
                     style="display: none;margin-top: 16px; margin-bottom: 20px">
                    <div class="d-flex w-100 barlow body1 regular">
                        <input class="w-50" formControlName="min"
                               placeholder="{{managerService.config.AUCTION.FILTER.PRICE_MIN | managerPipe}}"
                               style="display: flex;padding: 12px 16px;border: 1px solid var(--secondary-colors-artiox-gray-400, #CBCBCB);border-radius: 4px; "
                               type="number">
                        <svg
                            style="cursor: pointer; height: 21px; width: 14px; justify-self: center; align-self: center; margin-left: 10px; margin-right: 10px">
                            <use [attr.xlink:href]='"./assets/images/switch.svg#switch"' fill="#343640"/>
                        </svg>
                        <input class="w-50" formControlName="max"
                               placeholder="{{managerService.config.AUCTION.FILTER.PRICE_MAX | managerPipe}}"
                               style="display: flex;padding: 12px 16px;border: 1px solid var(--secondary-colors-artiox-gray-400, #CBCBCB);border-radius: 4px;"
                               type="number">
                    </div>
                </div>

                <div (click)="addFilter()" class="d-flex justify-content-center w-100"
                     style="cursor: pointer; user-select: none;border-radius: 4px;background: #8266F0;padding: 8px 16px;margin: 24px 0 24px 0;">
                    <span class="barlow"
                          style=" margin-left:10px;color: #FFFFFF;font-size: 16px;font-style: normal;font-weight: 600;line-height: normal;">
                            {{managerService.config.AUCTION.FILTER.APPLY | managerPipe}}
                        </span>
                    <svg style="cursor: pointer; height: 20px; width: 20px;margin-left: 8px">
                        <use [attr.xlink:href]='"./assets/images/filter.svg#filter"' stroke="#FFFFFF"/>
                    </svg>
                </div>
            </form>
        </div>
        <div
            *ngIf="(managerService.auctionService.auction_product_list_pipe | managerPipe: managerService.mpa_template.GET_FILTERED_SORTED_AUCTION_PRODUCT_LIST: managerService.auctionService.auction_product_filter_pipe: managerService.auctionService.active_catalog_sorting_type_pipe).length === 0"
            class="p-3 pt-0 mr-4 my-4 w-100 barlow body2 medium"
            style="color: #404352; min-width: 600px">
            {{managerService.config.AUCTION.FILTER.NO_RESULT | managerPipe}}
        </div>
        <div class="w-100 d-flex flex-row flex-wrap justify-content-start">
            <div
                (click)="managerService.navigateIfLoggedIn({url: '/auction/auction_product', active_auction_product_hash: auction_product.hash, current_component_index: 3});"
                *ngFor="let auction_product of (managerService.auctionService.auction_product_list_pipe | managerPipe: managerService.mpa_template.GET_FILTERED_SORTED_AUCTION_PRODUCT_LIST: managerService.auctionService.auction_product_filter_pipe: managerService.auctionService.active_catalog_sorting_type_pipe) "
                [ngClass]="[(auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_STATUS_STYLE), (showFilterForm ? 'lot-card-two-cols' : 'lot-card-three-cols')]"
                class="d-flex flex-column justify-content-start align-items-center lot-card mt-5 pb-4 overflow-hidden"
                style="width: 357px; height: 792px; border: 1px solid var(--secondary-colors-artiox-gray-base, #E2E2E2); border-radius: 16px; box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.24), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); cursor: pointer; background: #FFF; ">
                <div
                    *ngIf="(auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_OWNERSHIP_STATUS: managerService.user_bids_pipe) "
                    class="d-flex w-100 justify-content-center align-items-center exo subtitle1 bold"
                    style="height: 32px; padding: 4px 8px;margin-bottom: 32px;background: var(--main-color-artiox-purple-200, #ECE8FD);color: #8266F0">
                    LOT {{auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_LOT }} {{managerService.config.AUCTION.CATALOG_LOT_IS_YOURS | managerPipe}}
                </div>
                <div
                    *ngIf="!(auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_OWNERSHIP_STATUS: managerService.user_bids_pipe) "
                    class="d-flex w-100 justify-content-center align-items-center exo subtitle1 bold"
                    style="height: 32px; padding: 4px 8px;margin-bottom: 32px;background: var(--secondary-colors-artiox-gray-300, #F6F6F6);color: #6F6F6F">
                    Lot {{auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_LOT}}
                </div>
                <div class="d-flex flex-column justify-content-center " style="gap: 32px;">
                    <div class="d-flex  justify-content-center w-100 "
                         style="width: 310px; height:250px;">
                        <img #catalog_img
                             (error)="catalog_img.src = catalog_img.src.replace('.webp', '.jpg');" alt="catalog_img"
                             src="{{auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_THUMBNAIL | managerPipe: managerService.mpa_template.LOCAL_IMAGE_MANAGER_TO_BE}}"
                             style="display: flex;  width: 310px; height:250px; pointer-events: none"
                        >
                    </div>
                    <div class="d-flex flex-column w-100" style="text-align: center; gap: 8px">
                        <div class="exo subtitle1 bold line-clamp-1"
                             style="color: #404352;">
                            {{ auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_ARTIST_NAME }}
                        </div>
                        <div class="line-clamp-1 exo subtitle2 medium"
                        >
                            {{ auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_NAME }}
                        </div>
                        <div
                            [innerHTML]="auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_TEXT: managerService.language_pipe"
                            class="barlow body2 medium " style="line-height: 24px"
                        >
                        </div>
                    </div>
                    <div class="d-flex w-100 flex-column barlow body2 medium"
                         style="">
                        <div
                            class="w-100 d-flex flex-row justify-content-end"
                            style="cursor: pointer; height: 20px;">
                            <!--                            <svg style="height: 12px; width: 12px; margin-right: 6px">-->
                            <!--                                <use [attr.xlink:href]='"./assets/images/info.svg#info"' fill="#111111"/>-->
                            <!--                            </svg>-->
                            <span [style]=" (auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_COUNTDOWN ) | async | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_TIME_STYLE: auction_product"
                                  class="barlow body2 bold">
                        {{(auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_COUNTDOWN) | async | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_REMAIN_TIME: auction_product}}
                    </span>
                        </div>
                        <div
                            [style]="(auction_product| managerPipe: managerService.mpa_template.AUCTION_PRODUCT_COUNTDOWN) | async | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_COUNTDOWN_BAR : auction_product"
                            class="public-sale-bar"
                            style="align-self: stretch; height: 10px; border-radius: 100px;margin: 8px 0"></div>
                        <div
                            [ngClass]="auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_STATUS_STYLE"
                            class="w-100 d-flex flex-row justify-content-end"
                            style="height: 20px;">
                  <span style="color: #B5B5B5">
                    {{(auction_product| managerPipe: managerService.mpa_template.AUCTION_PRODUCT_END_DATE) | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME }}
                      /&nbsp;
                  </span>
                            <span class="end-date" style="color: #FFBC04">
                    {{auction_product| managerPipe: managerService.mpa_template.AUCTION_PRODUCT_TIME_INCREMENT_TEXT}}
                  </span>
                            <span [style.display]=""
                                  class="end-text barlow body2 medium" style="color: #EA3943;">
                        {{managerService.config.AUCTION.END_TEXT | managerPipe}}
                    </span>
                        </div>
                    </div>
                    <div class="w-100 d-flex flex-row justify-content-center">
                        <div class="my-auto text-center">
                            <div class="barlow body1 medium"
                                 style="color: var(--text-color-artiox-grey, #6F6F6F);margin-bottom: 8px">{{auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_OPENING_VALUE | managerPipe: managerService.mpa_template.FANCY_NUMBER_NO_DECIMAL}}
                                TL
                            </div>
                            <div class="barlow body2 regular"
                                 style="color: var(--secondary-colors-artiox-gray-500, #B5B5B5);">{{managerService.config.AUCTION.OPENING_PRICE | managerPipe}}</div>
                        </div>
                        <span
                            style="width: 1px; height: 66px;margin: 0 28px 0 28px; background-color: #d8d8d8;"></span>
                        <div class="my-auto text-center">
                            <div class="barlow body1 medium"
                                 style="color: var(--text-color-artiox-grey, #6F6F6F);margin-bottom: 8px">{{ auction_product| managerPipe: managerService.mpa_template.AUCTION_PRODUCT_CURRENT_VALUE: auction_product.active_bid_price_pipe}}
                                TL
                            </div>
                            <div
                                class="barlow body2 regular"
                                style="color: var(--secondary-colors-artiox-gray-500, #B5B5B5);">{{managerService.config.AUCTION.CURRENT_PRICE | managerPipe}}</div>
                        </div>
                    </div>
                    <div *ngIf="auction_product.auction_state === 'live'"
                         class="d-flex w-100 flex-row justify-content-center align-items-center" style="gap: 16px">
                        <div (click)="managerService.stopProp($event)" class="d-flex justify-content-between"
                             style="padding: 6px;border-radius: 6px;border: 1px solid var(--secondary-colors-artiox-gray-base, #E2E2E2);background: var(--background-color-artiox-background, #FFF);width: 170px;">
                            <div class="d-flex" style="width: 24px">
                                <div
                                    (click)="auction_product.setActiveBidPrice(auction_product.active_bid_price - auction_product.value_step)"
                                    *ngIf="auction_product.active_bid_price > (auction_product.current_value + auction_product.value_step) && auction_product.active_bid_price > auction_product.opening_value"
                                    style="cursor: pointer; user-select: none">
                                    <svg style="height: 24px; width: 24px">
                                        <use [attr.xlink:href]='"./assets/images/decrement.svg#decrement"'/>
                                    </svg>
                                </div>
                            </div>
                            <div class="barlow body0 medium" style="color: #6F6F6F">
                                {{(auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_ACTIVE_BID : auction_product.active_bid_price_pipe | managerPipe: managerService.mpa_template.FANCY_NUMBER_NO_DECIMAL)}}
                                TL
                            </div>
                            <div class="d-flex" style="width: 24px">
                                <div
                                    (click)="auction_product.setActiveBidPrice(auction_product.active_bid_price + auction_product.value_step)"
                                    style="cursor: pointer; user-select: none">
                                    <svg style="height: 24px; width: 24px">
                                        <use [attr.xlink:href]='"./assets/images/increment.svg#increment"'/>
                                    </svg>
                                </div>
                            </div>

                        </div>
                        <div
                            (click)="managerService.stopProp($event);managerService.setActiveAuctionProduct(auction_product, false);managerService.auctionService.setActiveBidPrice(managerService.auctionService.active_auction_product.active_bid_price);managerService.openModalWithKyc('auction-bid-report-modal', 1);managerService.report_bid_modal.emit()"
                            class="d-flex text-center quick-bid-button barlow body1 medium"
                            style="cursor: pointer; width: 133px; background: #8266F0; border-radius: 6px; color:#FFFFFF; padding: 8px 16px 8px 16px ">
                            {{managerService.config.AUCTION.BID | managerPipe}}
                            <svg class="ms-2" style="height: 20px; width: 20px;">
                                <use [attr.xlink:href]='"./assets/images/gavel_white.svg#gavel_white"'
                                     stroke="white"/>
                            </svg>
                        </div>
                    </div>
                    <div *ngIf="auction_product.auction_state !== 'live'"
                         class="d-flex w-100 flex-row justify-content-center align-items-center" >
                        <div
                            (click)="managerService.stopProp($event);managerService.setActiveAuctionProduct(auction_product, false);managerService.openModal('auction-product-information-modal');"
                            class="d-flex text-center"
                            style="cursor: pointer;font-family: barlow-regular, sans-serif;font-style: normal;font-style: normal;font-weight: 600;line-height: normal;  width: 175px; background: #F3F0FE; border-radius: 6px; color:#8266F0; padding: 8px 16px 8px 16px ">
                            {{managerService.config.AUCTION.PRODUCT_INFO | managerPipe}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
