<p style="text-indent: 0pt;text-align: left;"><br/></p>
<h1 style="padding-top: 5pt;padding-left: 70pt;text-indent: 0pt;text-align: center;">KİŞİSEL VERİ SAHİBİ BAŞVURU FORMU</h1>
<p style="text-indent: 0pt;text-align: left;"><br/></p>
<h1 style="padding-left: 70pt;text-indent: 0pt;line-height: 112%;text-align: center;">(6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU GEREĞİNCE)</h1>
<h1 style="padding-top: 10pt;padding-left: 102pt;text-indent: -61pt;line-height: 112%;text-align: left;">(KİŞİSEL VERİ SAHİBİ TARAFINDAN VERİ SORUMLUSUNA YAPILACAK BAŞVURULARA İLİŞKİN</h1>
<p style="text-indent: 0pt;text-align: left;"><br/></p>
<p class="s1" style="padding-left: 17pt;text-indent: 0pt;text-align: justify;">GENEL AÇIKLAMALAR:</p>
<p style="text-indent: 0pt;text-align: left;"><br/></p>
<p style="padding-left: 17pt;text-indent: 0pt;line-height: 112%;text-align: justify;">6698 Sayılı Kişisel Verilerin Korunması Kanunu’nda ilgili kişi olarak tanımlanan kişisel veri sahiplerine Kanun’un
    11inci maddesinde kişisel verilerinin işlenmesine ilişkin belirli haklar tanınmıştır.</p>
<p style="padding-top: 10pt;padding-left: 17pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Kanunun 13üncü maddesinin 1inci fıkrası uyarınca; veri sorumlusu olan Şirketimize bu haklara
    ilişkin olarak yapılacak başvuruların yazılı olarak veya Kişisel Verilerin Korunması Kurulu tarafından belirlenen diğer yöntemlerle tarafımıza iletilmesi gerekmektedir.</p>
<p style="padding-top: 10pt;padding-left: 17pt;text-indent: 2pt;line-height: 112%;text-align: justify;">Bu çerçevede “yazılı” olarak Şirketimize yapılacak başvuruların, işbu formun çıktısı alınarak
    ;</p>
<ul id="l1">
    <li data-list-text="●"><p style="padding-top: 10pt;padding-left: 49pt;text-indent: -17pt;text-align: justify;">Başvuru Sahibi’nin şahsen başvurusu ile</p></li>
    <li data-list-text="●"><p style="padding-top: 1pt;padding-left: 49pt;text-indent: -17pt;text-align: justify;">Noter vasıtası ile,</p></li>
    <li data-list-text="●"><p style="padding-top: 1pt;padding-left: 50pt;text-indent: -18pt;line-height: 112%;text-align: justify;">Başvuru Sahibi’nce 5070 Sayılı Elektronik İmza Kanununda tanımlı
        olan “güvenli elektronik imza” ile imzalanarak Şirket kayıtlı elektronik posta adresine gönderilmek suretiyle</p></li>
    <li data-list-text="●"><p style="padding-left: 50pt;text-indent: -18pt;line-height: 112%;text-align: justify;">Daha önce Veri Sorumlusu’na kimliğinizin teyit edildiği bir e-posta adresi
        vermişseniz bu e-posta adresinden başvurmak suretiyle</p></li>
</ul>
<p style="padding-top: 10pt;padding-left: 17pt;text-indent: 0pt;text-align: justify;">tarafımıza iletilmesi gerekmektedir.</p>
<p style="padding-top: 3pt;padding-left: 17pt;text-indent: 0pt;line-height: 112%;text-align: left;">Aşağıda, yazılı başvuruların ne şekilde tarafımıza ulaştırılacağına ilişkin yazılı başvuru kanalları
    özelinde bilgiler verilmektedir.</p>
<p style="text-indent: 0pt;text-align: left;"><br/></p>
<table cellspacing="0" style="border-collapse:collapse;margin-left:13.625pt">
    <tr style="height:69pt">
        <td style="width:127pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br/></p>
            <p class="s2" style="padding-left: 13pt;text-indent: 0pt;text-align: left;">Başvuru Yöntemi</p></td>
        <td style="width:135pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br/></p>
            <p class="s2" style="padding-left: 16pt;padding-right: 21pt;text-indent: 13pt;line-height: 112%;text-align: left;">Başvurunun Yapılacağı Adres</p></td>
        <td style="width:158pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s2" style="padding-top: 2pt;padding-left: 27pt;text-indent: 18pt;line-height: 112%;text-align: left;">Başvuru Gönderiminde</p>
            <p class="s2" style="padding-top: 10pt;padding-left: 31pt;text-indent: 0pt;text-align: left;">Belirtilecek Bilgi</p></td>
    </tr>
    <tr style="height:114pt">
        <td style="width:127pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s3" style="padding-top: 2pt;padding-left: 2pt;text-indent: 0pt;line-height: 112%;text-align: left;">Şahsen Başvuru (Başvuru sahibinin bizzat gelerek kimliğini tevsik edici
                belge</p>
            <p class="s3" style="padding-top: 10pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">ile başvurması)</p></td>
        <td style="width:135pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br/></p>
            <p class="s3" style="padding-left: 2pt;padding-right: 1pt;text-indent: 0pt;line-height: 112%;text-align: left;">Mustafa Kemal Mah. Dumlupınar Blv. No: 280 G İç Kapı No: 1233</p>
            <p class="s3" style="padding-left: 2pt;text-indent: 0pt;line-height: 13pt;text-align: left;">Çankaya/ Ankara</p></td>
        <td style="width:158pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s3" style="padding-top: 2pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">Zarfın üzerine “Kişisel</p>
            <p style="text-indent: 0pt;text-align: left;"><br/></p>
            <p class="s3" style="padding-left: 2pt;padding-right: 2pt;text-indent: 0pt;line-height: 112%;text-align: left;">Verilerin Korunması Kanunu Kapsamında Bilgi Talebi” yazılacaktır.</p></td>
    </tr>
    <tr style="height:84pt">
        <td style="width:127pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br/></p>
            <p class="s3" style="padding-left: 2pt;padding-right: 33pt;text-indent: 0pt;line-height: 112%;text-align: left;">Noter vasıtasıyla tebligat</p></td>
        <td style="width:135pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s3" style="padding-top: 7pt;padding-left: 2pt;padding-right: 1pt;text-indent: 0pt;line-height: 112%;text-align: left;">Mustafa Kemal Mah. Dumlupınar Blv. No: 280 G İç Kapı No:
                1233</p>
            <p class="s3" style="padding-left: 2pt;text-indent: 0pt;line-height: 13pt;text-align: left;">Çankaya/ Ankara</p></td>
        <td style="width:158pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s3" style="padding-top: 2pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">Tebligat zarfına “Kişisel</p>
            <p style="text-indent: 0pt;text-align: left;"><br/></p>
            <p class="s3" style="padding-left: 2pt;padding-right: 2pt;text-indent: 0pt;line-height: 112%;text-align: left;">Verilerin Korunması Kanunu Kapsamında Bilgi Talebi” yazılacaktır.</p></td>
    </tr>
    <tr style="height:84pt">
        <td style="width:127pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br/></p>
            <p class="s3" style="padding-left: 2pt;padding-right: 22pt;text-indent: 0pt;line-height: 112%;text-align: left;">Güvenli Elektronik İmza kullanılarak</p></td>
        <td style="width:135pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br/></p>
            <p style="padding-left: 2pt;text-indent: 0pt;text-align: left;"><a class="s4" href="mailto:info@artiox.com">info&#64;artiox.com</a></p></td>
        <td style="width:158pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s3" style="padding-top: 2pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">E-posta’nın konu kısmına</p>
            <p style="text-indent: 0pt;text-align: left;"><br/></p>
            <p class="s3" style="padding-left: 2pt;text-indent: 0pt;line-height: 112%;text-align: left;">“Kişisel Verilerin Korunması Kanunu Bilgi Talebi” yazılacaktır.</p></td>
    </tr>
    <tr style="height:84pt">
        <td style="width:127pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br/></p>
            <p class="s3" style="padding-left: 2pt;padding-right: 25pt;text-indent: 0pt;line-height: 112%;text-align: left;">Kayıtlı elektronik posta kullanılarak</p></td>
        <td style="width:135pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br/></p>
            <p class="s3" style="padding-left: 2pt;text-indent: 0pt;text-align: left;">kep adresi</p></td>
        <td style="width:158pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s3" style="padding-top: 2pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">E-posta’nın konu kısmına</p>
            <p style="text-indent: 0pt;text-align: left;"><br/></p>
            <p class="s3" style="padding-left: 2pt;text-indent: 0pt;line-height: 112%;text-align: left;">“Kişisel Verilerin Korunması Kanunu Bilgi Talebi” yazılacaktır.</p></td>
    </tr>
    <tr style="height:93pt">
        <td style="width:127pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s3" style="padding-left: 2pt;padding-right: 11pt;text-indent: 0pt;line-height: 15pt;text-align: left;">Daha önce Veri Sorumlusu’na kimliğinizin teyit edildiği bir e-posta adresi
                vermişseniz bu e-posta</p></td>
        <td style="width:135pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br/></p>
            <p style="padding-left: 2pt;text-indent: 0pt;text-align: left;"><a class="s4" href="mailto:info@artiox.com">info&#64;artiox.com</a></p></td>
        <td style="width:158pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s3" style="padding-top: 2pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">E-posta’nın konu kısmına</p>
            <p style="text-indent: 0pt;text-align: left;"><br/></p>
            <p class="s3" style="padding-left: 2pt;text-indent: 0pt;line-height: 112%;text-align: left;">“Kişisel Verilerin Korunması Kanunu Bilgi Talebi” yazılacaktır.</p></td>
    </tr>
</table>
<table cellspacing="0" style="border-collapse:collapse;margin-left:13.625pt">
    <tr style="height:43pt">
        <td style="width:127pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s3" style="padding-top: 2pt;padding-left: 2pt;padding-right: 11pt;text-indent: 0pt;line-height: 112%;text-align: left;">adresinden başvurmak suretiyle</p></td>
        <td style="width:135pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br/></p></td>
        <td style="width:158pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br/></p></td>
    </tr>
</table>
<p style="text-indent: 0pt;text-align: left;"><br/></p>
<p style="text-indent: 0pt;text-align: left;"><br/></p>
<p style="padding-left: 17pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Ayrıca, Kurul’un belirleyeceği diğer yöntemler duyurulduktan sonra bu yöntemler üzerinden de başvuruların ne
    şekilde alınacağı Şirketimizce duyurulacaktır.</p>
<p style="padding-top: 10pt;padding-left: 17pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Tarafımıza iletilmiş olan başvurular KVK Kanunu’nun 13’üncü maddesinin 2’inci fıkrası gereğince,
    talebin niteliğine göre talebin bizlere yukarıda tariflenen yöntemlerden birisi ile tarafımıza ulaştığı tarihten itibaren otuz (30) gün içinde yanıtlandırılacaktır. Yanıtlarımız ilgili KVK
    Kanunu’nun 13’üncü maddesi hükmü gereğince yazılı veya elektronik ortamdan başvuru sahibine ulaştırılacaktır.</p>
<p style="text-indent: 0pt;text-align: left;"><br/></p>
<ol id="l2">
    <li data-list-text="A."><h1 style="padding-left: 43pt;text-indent: -27pt;line-height: 112%;text-align: left;">Başvuru sahibinin tanınmasına ve başvurusu ile ilgili kendisiyle iletişim kurulmasına
        ilişkin bilgiler:</h1>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <table cellspacing="0" style="border-collapse:collapse">
            <tr style="height:28pt">
                <td style="width:128pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s2" style="padding-top: 2pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">İsim:</p></td>
                <td style="width:330pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p style="text-indent: 0pt;text-align: left;"><br/></p></td>
            </tr>
            <tr style="height:28pt">
                <td style="width:128pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s2" style="padding-top: 2pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">Soy İsim:</p></td>
                <td style="width:330pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p style="text-indent: 0pt;text-align: left;"><br/></p></td>
            </tr>
            <tr style="height:28pt">
                <td style="width:128pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s2" style="padding-top: 2pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">TC Kimlik Numarası:</p></td>
                <td style="width:330pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p style="text-indent: 0pt;text-align: left;"><br/></p></td>
            </tr>
            <tr style="height:28pt">
                <td style="width:128pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s2" style="padding-top: 2pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">Telefon Numarası:</p></td>
                <td style="width:330pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p style="text-indent: 0pt;text-align: left;"><br/></p></td>
            </tr>
            <tr style="height:84pt">
                <td style="width:128pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s2" style="padding-top: 2pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">E-posta:</p>
                    <p style="text-indent: 0pt;text-align: left;"><br/></p>
                    <p class="s5" style="padding-left: 2pt;padding-right: 11pt;text-indent: 0pt;line-height: 112%;text-align: left;">(Belirtmeniz halinde size daha hızlı yanıt verebileceğiz.)</p></td>
                <td style="width:330pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p style="text-indent: 0pt;text-align: left;"><br/></p></td>
            </tr>
        </table>
        <table cellspacing="0" style="border-collapse:collapse">
            <tr style="height:48pt">
                <td style="width:128pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s2" style="padding-top: 2pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">Adres:</p></td>
                <td style="width:330pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p style="text-indent: 0pt;text-align: left;"><br/></p></td>
            </tr>
        </table>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
    <li data-list-text="B."><h1 style="padding-left: 50pt;text-indent: -27pt;line-height: 112%;text-align: left;">Lütfen Şirketimiz ile olan ilişkinizi belirtiniz: (Müşteri, İş Ortağı, Çalışan</h1>
        <h1 style="padding-top: 10pt;padding-left: 45pt;text-indent: 0pt;text-align: left;">Adayı, <i>Eski Çalışan, Üçüncü Taraf Firma Çalışan, Hissedar</i></h1>
        <p class="s6" style="padding-top: 1pt;padding-left: 14pt;text-indent: 0pt;text-align: left;">gibi)</p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <table cellspacing="0" style="border-collapse:collapse">
            <tr style="height:62pt">
                <td style="width:147pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p style="text-indent: 0pt;text-align: left;"><br/></p></td>
                <td style="width:296pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p style="text-indent: 0pt;text-align: left;"><br/></p></td>
            </tr>
            <tr style="height:72pt">
                <td colspan="2"
                    style="width:443pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt"><p class="s3" style="padding-top: 5pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">Şirketimiz içerisinde iletişimde olduğunuz birim:</p>
                    <p class="s3" style="padding-top: 1pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">................................................................................</p>
                    <p style="text-indent: 0pt;text-align: left;"><br/></p>
                    <p class="s3" style="padding-left: 2pt;text-indent: 0pt;text-align: left;">Konu:
                        ....................................................................................................................................................</p></td>
            </tr>
        </table>
        <ul id="l3">
            <li data-list-text="☐"><p class="s3" style="padding-top: 5pt;padding-left: 16pt;text-indent: -13pt;text-align: left;">Müşteri</p></li>
            <li data-list-text="☐"><p class="s3" style="padding-left: 16pt;text-indent: -13pt;text-align: left;">Ziyaretçi</p></li>
        </ul>
        <ul id="l4">
            <li data-list-text="☐"><p class="s3" style="padding-top: 5pt;padding-left: 16pt;text-indent: -13pt;text-align: left;">İş Ortağı</p></li>
            <li data-list-text="☐"><p class="s3" style="padding-left: 16pt;text-indent: -13pt;text-align: left;">Diğer : ......................................................................</p></li>
        </ul>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <table cellspacing="0" style="border-collapse:collapse">
            <tr style="height:92pt">
                <td style="width:193pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p style="text-indent: 0pt;text-align: left;"><br/></p>
                    <p class="s5" style="padding-top: 8pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">Çalıştığım Yıllar :........................................</p></td>
                <td style="width:250pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s5" style="padding-top: 10pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">Tarih :</p>
                    <p style="text-indent: 0pt;text-align: left;"><br/></p>
                    <p class="s5" style="padding-left: 2pt;text-indent: 0pt;text-align: left;">....................................................................................</p></td>
            </tr>
            <tr style="height:20pt">
                <td style="width:193pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt"><p
                    style="text-indent: 0pt;text-align: left;"><br/></p></td>
                <td style="width:250pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt"></td>
            </tr>
            <tr style="height:41pt">
                <td style="width:193pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt"><p style="text-indent: 0pt;text-align: left;"><br/></p></td>
                <td style="width:250pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt"><p class="s5"
                                                                                                                                         style="padding-top: 6pt;padding-left: 2pt;text-indent: 0pt;line-height: 112%;text-align: left;">
                    Lütfen çalıştığınız firma ve pozisyon bilgisini belirtiniz</p></td>
            </tr>
            <tr style="height:32pt">
                <td style="width:193pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt"><p class="s3"
                                                                                                                                                                                           style="padding-top: 6pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
                    ...................................................................</p></td>
                <td style="width:250pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt"><p class="s5"
                                                                                                                                                                                           style="padding-top: 6pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">
                    .......................................................................................</p></td>
            </tr>
        </table>
        <ul id="l5">
            <li data-list-text="☐"><p class="s3" style="padding-left: 16pt;text-indent: -13pt;line-height: 13pt;text-align: left;">Eski çalışanım.</p></li>
        </ul>
        <ul id="l6">
            <li data-list-text="☐"><p class="s3" style="padding-left: 2pt;padding-right: 42pt;text-indent: 0pt;line-height: 112%;text-align: left;">İş Başvurusu / Özgeçmiş paylaşımı yaptım.</p></li>
        </ul>
        <ul id="l7">
            <li data-list-text="☐"><p class="s3" style="padding-left: 16pt;text-indent: -13pt;line-height: 13pt;text-align: left;">Üçüncü firma çalışanıyım.</p></li>
        </ul>
        <ul id="l8">
            <li data-list-text="☐"><p class="s3" style="padding-left: 16pt;text-indent: -13pt;text-align: left;">Diğer : ...................................................</p></li>
        </ul>
        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
    <li data-list-text="C."><h1 style="padding-left: 43pt;text-indent: -20pt;line-height: 112%;text-align: left;">Lütfen KVK Kanunu kapsamındaki talebinizi detaylı olarak belirtiniz:</h1>
        <p style="padding-top: 3pt;padding-left: 17pt;text-indent: 0pt;text-align: left;">
            ..............................................................................................................................</p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <p style="padding-left: 17pt;text-indent: 0pt;text-align: left;">
            ..............................................................................................................................</p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <p style="padding-left: 17pt;text-indent: 0pt;text-align: left;">
            ..............................................................................................................................</p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <p style="padding-left: 17pt;text-indent: 0pt;text-align: left;">
            ..............................................................................................................................</p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <p style="padding-left: 17pt;text-indent: 0pt;text-align: left;">
            ..............................................................................................................................</p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <p style="padding-left: 17pt;text-indent: 0pt;text-align: left;">
            ..............................................................................................................................</p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <p style="padding-left: 17pt;text-indent: 0pt;text-align: left;">
            ..............................................................................................................................</p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <p style="padding-left: 17pt;text-indent: 0pt;text-align: left;">
            ..............................................................................................................................</p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <p style="padding-left: 17pt;text-indent: 0pt;text-align: left;">
            ..............................................................................................................................</p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <p style="padding-left: 17pt;text-indent: 0pt;text-align: left;">
            ..............................................................................................................................</p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <p style="padding-left: 17pt;text-indent: 0pt;text-align: left;">
            ..............................................................................................................................</p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <p style="padding-left: 17pt;text-indent: 0pt;text-align: left;">
            ..............................................................................................................................</p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <p style="padding-left: 17pt;text-indent: 0pt;text-align: left;">
            ..............................................................................................................................</p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <p style="padding-left: 17pt;text-indent: 0pt;text-align: left;">
            ..............................................................................................................................</p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <p style="padding-left: 17pt;text-indent: 0pt;text-align: left;">
            ..............................................................................................................................</p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <p style="padding-left: 17pt;text-indent: 0pt;text-align: left;">
            ..............................................................................................................................</p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
    <li data-list-text="D."><h1 style="padding-left: 44pt;text-indent: -27pt;line-height: 112%;text-align: left;">Lütfen başvurunuza vereceğimiz yanıtın tarafınıza bildirilme yönetimini seçiniz:</h1>
        <ul id="l9">
            <li data-list-text="☐"><p style="padding-top: 10pt;padding-left: 59pt;text-indent: -16pt;text-align: left;">Adresime gönderilmesini istiyorum.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="☐"><p style="padding-left: 56pt;text-indent: -13pt;text-align: left;">E-posta adresime gönderilmesini istiyorum.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p class="s6" style="padding-left: 43pt;text-indent: 14pt;line-height: 112%;text-align: left;">(E-posta yöntemini seçmeniz halinde size daha hızlı yanıt verebileceğiz)</p></li>
            <li data-list-text="☐"><p style="padding-top: 10pt;padding-left: 57pt;text-indent: -13pt;text-align: left;">Elden teslim almak istiyorum.</p></li>
        </ul>
    </li>
</ol>
<p class="s6" style="padding-top: 3pt;padding-left: 59pt;text-indent: 2pt;line-height: 112%;text-align: left;">(Vekâleten teslim alınması durumunda noter tasdikli vekâletname veya yetki belgesi olması
    gerekmektedir.)</p>
<p style="padding-top: 10pt;padding-left: 17pt;text-indent: 0pt;line-height: 112%;text-align: justify;">İşbu başvuru formu, Şirketimiz ile olan ilişkinizi tespit ederek, varsa, Şirketimiz tarafından
    işlenen kişisel verilerinizi eksiksiz olarak belirleyerek, ilgili başvurunuza doğru ve kanuni süresinde cevap verilebilmesi için tanzim edilmiştir. Hukuka aykırı ve haksız bir şekilde veri
    paylaşımından kaynaklanabilecek hukuki risklerin bertaraf edilmesi ve özellikle kişisel verilerinizin güvenliğinin sağlanması amacıyla, kimlik ve yetki tespiti için Şirketimiz ek evrak ve malumat
    (Nüfus cüzdanı veya sürücü belgesi sureti vb.) talep etme hakkını saklı tutar. Form kapsamında iletmekte olduğunuz taleplerinize ilişkin bilgilerin doğru ve güncel olmaması ya da yetkisiz bir
    başvuru yapılması halinde Şirketimiz, söz konusu yanlış bilgi ya da yetkisiz başvuru kaynaklı taleplerden dolayı mesuliyet kabul etmemektedir.</p>
<h1 style="padding-top: 10pt;padding-left: 17pt;text-indent: 0pt;line-height: 190%;text-align: left;">Başvuruda Bulunan İlgili Kişi (Kişisel Veri Sahibi) Adı Soyadı :</h1>
<h1 style="padding-left: 17pt;text-indent: 0pt;line-height: 190%;text-align: left;">Başvuru Tarihi : İmza :</h1>
