<div (mouseenter)="pre_sale_hover=true;" (mouseleave)="pre_sale_hover=false;" id="hover-area">
  <div *ngIf="!(managerService.pairService.active_featured_pair | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_LISTING)"
       class="d-flex flex-row align-items-start justify-content-center m-auto" style="width: 90%;">
    <form [formGroup]="marketBuyForm" class="d-flex flex-column" style="width: 42.5%">
      <h2 #container_header
          [innerHTML]="[managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SYMBOL: managerService.pair_pipe] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.BASIC.TRADE.AMOUNT_TO_BUY_MOBILE"
          class="text-center"
          style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: bold; letter-spacing: 2px; color: white;">
      </h2>
      <label #container_input>
        <input class="w-100 input-field-black" formControlName="amount" min="0.00" placeholder="0"
               required type="number">
      </label>
    </form>
    <div class="align-self-end" style="padding: 10px 4px; height: 100%">
      <svg  style="height: 24px; width: 24px;">
        <use [attr.xlink:href]='"./assets/images/slide_icon.svg#slide_icon"' fill="#444550"/>
      </svg>
    </div>
    <div class="d-flex flex-column" style="width: 42.5%">
      <h2
        [innerHTML]="[managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_CURRENCY_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.BASIC.TRADE.AVERAGE_COST_MOBILE"
        class="text-center"
        style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: bold; letter-spacing: 2px; color: white;">
      </h2>
      <label>
        <input class="w-100 input-field-black" disabled min="0.00" placeholder="0" readonly
               type="number"
               value="{{market_buy_average_cost | managerPipe}}">
      </label>
    </div>
  </div>

  <div class="pl-3 mt-n2">
    <span style="font-family: Raleway, sans-serif; font-size: 12px; font-weight: normal; text-align: center; color: #9d9fa6;">
      {{[managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_CURRENCY_SYMBOL: managerService.pair_pipe] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.BASIC.TRADE.AVAILABLE_AMOUNT}}
      {{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.USER_ACTIVE_CURRENCY_AVAILABLE_AMOUNT: managerService.user_pipe: managerService.pair_pipe}}
    </span>
  </div>
  <button (click)="preSaleBuy()" [class]="((managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_FEATURING: managerService.pairService.active_featured_pair_pipe) ? 'pre-sale-buy-button-featuring' : 'pre-sale-buy-button') | managerPipe"
          class="d-block btn-native my-2 mx-auto p-3" style="width: 90%;">
    {{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.PRE_SALE_BUY_BUTTON: pre_sale_hover: managerService.language_pipe: managerService.pair_pipe}}
  </button>
</div>
