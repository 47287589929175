<!--<div class="d-flex w-100 flex-column" style="background: var(&#45;&#45;text-color-artiox-black, #131515);height: 360px; padding: 48px 120px">-->
<!--  <div class="d-flex w-100 flex-row justify-content-between" style="padding-bottom: 55px">-->
<!--    <div class="d-flex flex-column" style="width: 220px; gap: 32px">-->
<!--      <div class="d-flex w-100">-->
<!--        <svg style=" height: 38px; width: 140px">-->
<!--          <use [attr.xlink:href]='"./assets/images/artiox_logo.svg#artiox_logo"' fill="#FFF"/>-->
<!--        </svg>-->
<!--      </div>-->
<!--      <div class="d-flex w-100 barlow caption regular" style="color: var(&#45;&#45;secondary-colors-artiox-gray-base, #E2E2E2)" >-->
<!--        {{managerService.config.FOOTER.ADDRESS | managerPipe}}-->
<!--      </div>-->
<!--      <div class="d-flex w-100 flex-row" style="gap: 16px">-->
<!--        <img ngSrc="./assets/images/google-play-store.png " alt="google-play" width="100" height="30" (click)="openGooglePlay()" style="cursor: pointer"/>-->
<!--        <img ngSrc="./assets/images/apple-app-store.png " alt="app-store" width="100" height="30" (click)="openAppStore()" style="cursor: pointer" />-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="d-flex flex-row">-->
<!--      <div class="d-flex flex-column">-->
<!--        <div class="d-flex barlow body1 medium" style="color: var(&#45;&#45;text-color-artiox-white, #FFFFFF); padding-bottom: 16px">-->
<!--          {{managerService.config.FOOTER.LEGAL.HEADING | managerPipe}}-->
<!--        </div>-->
<!--        <div class="d-flex flex-column" style="gap: 8px; color: var(&#45;&#45;secondary-colors-artiox-gray-500, #B5B5B5)">-->
<!--          <div class="d-flex footer-link barlow body2 regular" (click)="managerService.navigationManager(managerService.config.FOOTER.LEGAL.LIMITS_LINK)" style="cursor: pointer">-->
<!--            {{managerService.config.FOOTER.LEGAL.LIMITS | managerPipe}}-->
<!--          </div>-->
<!--          <div class="d-flex footer-link barlow body2 regular" (click)="managerService.navigationManager(managerService.config.FOOTER.LEGAL.USER_AGREEMENT_LINK)" style="cursor: pointer">-->
<!--            {{managerService.config.FOOTER.LEGAL.USER_AGREEMENT | managerPipe}}-->
<!--          </div>-->
<!--          <div class="d-flex footer-link barlow body2 regular" (click)="managerService.navigationManager(managerService.config.FOOTER.LEGAL.KVKK_LINK)" style="cursor: pointer">-->
<!--            {{managerService.config.FOOTER.LEGAL.KVKK | managerPipe}}-->
<!--          </div>-->
<!--          <div class="d-flex footer-link barlow body2 regular" (click)="managerService.navigationManager(managerService.config.FOOTER.LEGAL.COOKIES_LINK)" style="cursor: pointer">-->
<!--            {{managerService.config.FOOTER.LEGAL.COOKIES | managerPipe}}-->
<!--          </div>-->
<!--          <div class="d-flex footer-link barlow body2 regular" (click)="managerService.navigationManager(managerService.config.FOOTER.LEGAL.COMMISSIONS_LINK)" style="cursor: pointer">-->
<!--            {{managerService.config.FOOTER.LEGAL.COMMISSIONS | managerPipe}}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="d-flex flex-row">-->
<!--      <div class="d-flex flex-column">-->
<!--        <div class="d-flex barlow body1 medium" style="color: var(&#45;&#45;text-color-artiox-white, #FFFFFF); padding-bottom: 16px">-->
<!--          {{managerService.config.FOOTER.ARTIOX_SECTION.HEADER | managerPipe}}-->
<!--        </div>-->
<!--        <div class="d-flex flex-column" style="gap: 16px; color: var(&#45;&#45;secondary-colors-artiox-gray-500, #B5B5B5)">-->
<!--          <div class="d-flex footer-link barlow body2 regular" (click)="managerService.navigateIfLoggedIn({url: '/exchange/basic', current_component_index: 0})" style="cursor: pointer">-->
<!--            {{managerService.config.FOOTER.ARTIOX_SECTION.BASIC | managerPipe}}-->
<!--          </div>-->
<!--          <div class="d-flex footer-link barlow body2 regular" (click)="managerService.navigateIfLoggedIn({url: '/exchange/advanced', current_component_index: 0})" style="cursor: pointer">-->
<!--            {{managerService.config.FOOTER.ARTIOX_SECTION.ADVANCED | managerPipe}}-->
<!--          </div>-->
<!--          <div class="d-flex footer-link barlow body2 regular" (click)="managerService.navigateIfLoggedIn({url: '/auction', current_component_index: 1})" style="cursor: pointer">-->
<!--            {{managerService.config.FOOTER.ARTIOX_SECTION.CATALOG | managerPipe}}-->
<!--          </div>-->
<!--          <div class="d-flex footer-link barlow body2 regular" (click)="managerService.navigateIfLoggedIn({url: '/auction/catalog', active_auction_catalog_hash: '2023_DAILY', current_component_index: 2})" style="cursor: pointer">-->
<!--            {{managerService.config.FOOTER.ARTIOX_SECTION.DAILY | managerPipe}}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="d-flex flex-row">-->
<!--      <div class="d-flex flex-column">-->
<!--        <div class="d-flex barlow body1 medium" style="color: var(&#45;&#45;text-color-artiox-white, #FFFFFF); padding-bottom: 16px">-->
<!--          {{managerService.config.FOOTER.ARTISTS.HEADER | managerPipe}}-->
<!--        </div>-->
<!--        <div class="d-flex flex-column" style="gap: 16px; color: var(&#45;&#45;secondary-colors-artiox-gray-500, #B5B5B5)">-->
<!--          <div *ngFor="let artist of artist_list">-->
<!--            <div class="d-flex footer-link barlow body2 regular" (click)="managerService.navigateIfLoggedIn({url: '/about-artist',artist_announcement_index: artist, current_component_index: 0})" style="cursor: pointer">-->
<!--              {{this.managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[artist].NAME | managerPipe}}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="d-flex footer-link barlow body2 regular" (click)="managerService.navigateIfLoggedIn({url: '/about-artist', current_component_index: 0})" style="cursor: pointer">-->
<!--            {{managerService.config.FOOTER.ARTISTS.ALL_ARTISTS | managerPipe}}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="d-flex flex-row" style="gap: 24px">-->
<!--      <div class="d-flex">-->
<!--        <svg style=" height: 32px; width: 32px; cursor: pointer" (click)="instagram()">-->
<!--          <use [attr.xlink:href]='"./assets/images/instagram.svg#instagram"'  stroke="#B5B5B5"/>-->
<!--        </svg>-->
<!--      </div>-->
<!--      <div class="d-flex">-->
<!--        <svg style=" height: 26px; width: 26px; cursor: pointer" (click)="twitter()">-->
<!--          <use [attr.xlink:href]='"./assets/images/x.svg#x"'  fill="#B5B5B5"/>-->
<!--        </svg>-->
<!--      </div>-->
<!--      <div class="d-flex">-->
<!--        <svg style=" height: 32px; width: 32px; cursor: pointer" (click)="linkedin()">-->
<!--          <use [attr.xlink:href]='"./assets/images/linkedin.svg#linkedin"'  stroke="#B5B5B5"/>-->
<!--        </svg>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="d-flex w-100 justify-content-between" style="padding-top: 16px; border-top: 1px solid #E2E2E2;color: var(&#45;&#45;secondary-colors-artiox-gray-500, #B5B5B5)">-->
<!--    <div class="d-flex barlow overline regular" [innerHTML]="managerService.config.FOOTER.RIGHTS | managerPipe: managerService.mpa_template.SET_CURRENT_YEAR"></div>-->
<!--    <div class="d-flex barlow overline regular" [innerHTML]="managerService.config.FOOTER.VERSION"></div>-->
<!--  </div>-->
<!--</div>-->

<div class="d-flex flex-column justify-content-center align-items-center"
     style="background: var(--text-color-artiox-black, #131515); padding: 16px 16px 80px;gap: 16px; color: var(--text-color-artiox-white, #FFFFFF);">

    <svg style=" height: 38px; width: 140px">
        <use [attr.xlink:href]='"./assets/images/artiox_logo.svg#artiox_logo"' fill="#FFF"/>
    </svg>
    <div class="text-center barlow caption regular" style="color: var(--secondary-colors-artiox-gray-base, #E2E2E2)">
        {{managerService.config.FOOTER.ADDRESS | managerPipe}}
    </div>
    <div class="w-100 d-flex flex-row justify-content-center align-items-center" style="gap: 16px">
        <a (click)="managerService.marketingService.logEvent('google_play_navigation', {})" aria-label="app store"
           href="{{managerService.config.APP_ANNOUNCEMENT.GOOGLE_PLAY_LINK | managerPipe}}">
            <svg style="height:30px; width: 90px;">
                <use [attr.xlink:href]="managerService.config.APP_ANNOUNCEMENT.GOOGLE_PLAY_BADGE | managerPipe" fill="#f9f9f9"/>
            </svg>
        </a>
        <a (click)="managerService.marketingService.logEvent('app_store_navigation', {})" aria-label="google play"
           href="{{managerService.config.APP_ANNOUNCEMENT.APP_STORE_LINK_DESKTOP | managerPipe}}">
            <svg style="height:30px; width: 90px;">
                <use [attr.xlink:href]="managerService.config.APP_ANNOUNCEMENT.APP_STORE_BADGE | managerPipe" fill="#f9f9f9"/>
            </svg>
        </a>
    </div>

    <div class="barlow body1 medium">
        {{managerService.config.FOOTER.LEGAL.HEADING | managerPipe}}
    </div>
    <div class="text-center d-flex flex-column justify-content-center align-items-center" style="gap: 8px; color: var(--secondary-colors-artiox-gray-500, #B5B5B5)">
        <div *ngFor="let link of managerService.config.FOOTER.LEGAL.LINKS" (click)="managerService.navigationManager(link.URL)" class="d-flex barlow body2 regular"
             style="cursor: pointer">
            {{link.TEXT | managerPipe}}
        </div>
<!--        <div (click)="managerService.navigationManager(managerService.config.FOOTER.LEGAL.LIMITS_LINK)" class="d-flex barlow body2 regular"-->
<!--             style="cursor: pointer">-->
<!--            {{managerService.config.FOOTER.LEGAL.LIMITS | managerPipe}}-->
<!--        </div>-->
<!--        <div (click)="managerService.navigationManager(managerService.config.FOOTER.LEGAL.USER_AGREEMENT_LINK)" class="d-flex barlow body2 regular"-->
<!--             style="cursor: pointer">-->
<!--            {{managerService.config.FOOTER.LEGAL.USER_AGREEMENT | managerPipe}}-->
<!--        </div>-->
<!--        <div (click)="managerService.navigationManager(managerService.config.FOOTER.LEGAL.KVKK_LINK)" class="d-flex barlow body2 regular"-->
<!--             style="cursor: pointer">-->
<!--            {{managerService.config.FOOTER.LEGAL.KVKK | managerPipe}}-->
<!--        </div>-->
<!--        <div (click)="managerService.navigationManager(managerService.config.FOOTER.LEGAL.COOKIES_LINK)" class="d-flex barlow body2 regular"-->
<!--             style="cursor: pointer">-->
<!--            {{managerService.config.FOOTER.LEGAL.COOKIES | managerPipe}}-->
<!--        </div>-->
<!--        <div (click)="managerService.navigationManager(managerService.config.FOOTER.LEGAL.COMMISSIONS_LINK)" class="d-flex barlow body2 regular"-->
<!--             style="cursor: pointer">-->
<!--            {{managerService.config.FOOTER.LEGAL.COMMISSIONS | managerPipe}}-->
<!--        </div>-->
    </div>

    <div class="barlow body1 medium">
        {{managerService.config.FOOTER.ARTIOX_SECTION.HEADER | managerPipe}}
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center text-center" style="gap: 16px; color: var(--secondary-colors-artiox-gray-500, #B5B5B5)">
        <div (click)="managerService.navigateIfLoggedIn({url: '/exchange/basic', current_component_index: 0})" class="d-flex barlow body2 regular"
             style="cursor: pointer">
            {{managerService.config.FOOTER.ARTIOX_SECTION.BASIC | managerPipe}}
        </div>
        <div (click)="managerService.navigateIfLoggedIn({url: '/exchange/advanced', current_component_index: 0})" class="d-flex barlow body2 regular"
             style="cursor: pointer">
            {{managerService.config.FOOTER.ARTIOX_SECTION.ADVANCED | managerPipe}}
        </div>
        <div (click)="managerService.navigateIfLoggedIn({url: '/auction', current_component_index: 1})" class="d-flex barlow body2 regular"
             style="cursor: pointer">
            {{managerService.config.FOOTER.ARTIOX_SECTION.CATALOG | managerPipe}}
        </div>
        <div (click)="managerService.navigateIfLoggedIn({url: '/auction/catalog', active_auction_catalog_hash: '2024_DAILY', current_component_index: 2})"
             class="footer-link barlow body2 regular"
             style="cursor: pointer">
            {{managerService.config.FOOTER.ARTIOX_SECTION.DAILY | managerPipe}}
        </div>
    </div>

    <div class="barlow body1 medium">
        {{managerService.config.FOOTER.ARTISTS.HEADER | managerPipe}}
    </div>
    <div class="text-center d-flex flex-column justify-content-center align-items-center" style="gap: 16px; color: var(--secondary-colors-artiox-gray-500, #B5B5B5)">
        <div *ngFor="let artist of artist_list">
            <div (click)="managerService.navigateIfLoggedIn({url: '/about-artist', artist_announcement_index: artist.ID, current_component_index: 0})"
                 class="d-flex barlow body2 regular" style="cursor: pointer">
                {{artist.NAME | managerPipe}}
            </div>
        </div>
        <div (click)="managerService.navigateIfLoggedIn({url: '/about-artist', current_component_index: 0})" class="footer-link barlow body2 regular"
             style="cursor: pointer">
            {{managerService.config.FOOTER.ARTISTS.ALL_ARTISTS | managerPipe}}
        </div>
    </div>

    <div class="d-flex flex-row" style="gap: 24px">
        <svg (click)="instagram()" style=" height: 32px; width: 32px; cursor: pointer">
            <use [attr.xlink:href]='"./assets/images/instagram.svg#instagram"' stroke="#B5B5B5"/>
        </svg>
        <!--            why sizes differ ? -->
        <svg (click)="twitter()" style=" height: 24px; width: 24px; margin-top: 4px; cursor: pointer">
            <use [attr.xlink:href]='"./assets/images/x.svg#x"' fill="#B5B5B5"/>
        </svg>
        <svg (click)="linkedin()" style=" height: 32px; width: 32px; cursor: pointer">
            <use [attr.xlink:href]='"./assets/images/linkedin.svg#linkedin"' stroke="#B5B5B5"/>
        </svg>
    </div>

    <div class="text-center" style="padding-top: 16px; border-top: 1px solid #E2E2E2;color: var(--secondary-colors-artiox-gray-500, #B5B5B5)">
        <div [innerHTML]="managerService.config.FOOTER.RIGHTS | managerPipe: managerService.mpa_template.SET_CURRENT_YEAR" class="barlow overline regular"></div>
        <div [innerHTML]="managerService.config.FOOTER.VERSION" class="barlow overline regular"></div>
    </div>

</div>

