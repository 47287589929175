import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ManagerService} from '../../../../../services/manager.service';
import {HttpClient} from '@angular/common/http';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-forgot-password-desktop',
  templateUrl: './forgot-password-desktop.component.html',
  styleUrls: ['./forgot-password-desktop.component.css']
})
export class ForgotPasswordDesktopComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() modal_id: string = '';
  @ViewChild('warning') warning: any = undefined;

  forgotPasswordForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
  });

  public subscription_list: Subscription[] = [];

  constructor(public managerService: ManagerService, private httpClient: HttpClient, public elementRef: ElementRef) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  forgotPassword(): void {
    const data = {
      email: this.forgotPasswordForm.value.email,
      client_info: this.managerService.client_info
    };
    this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'user_forgot_password_service', data).subscribe(
      response => {
        // @ts-ignore
        const content = this.managerService.config.RESPONSE_TABLE[response.code];
        this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
        if (response.is_okay) {
          this.managerService.closeModal(this.modal_id);
        }
      }));
  }

  ngOnDestroy(): void {
    this.subscription_list.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

}
