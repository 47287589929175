import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ManagerService} from '../../../../../../services/manager.service';
import {HttpClient} from '@angular/common/http';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-change-password-mobile',
  templateUrl: './change-password-mobile.component.html',
  styleUrls: ['./change-password-mobile.component.css']
})
export class ChangePasswordMobileComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('success_text') success_text: any = undefined;
  @ViewChild('warning_old_password') warning_old_password: any = undefined;
  @ViewChild('info_text') info_text: any = undefined;
  @ViewChild('warning_new_password') warning_new_password: any = undefined;
  @ViewChild('warning_new_password_control') warning_new_password_control: any = undefined;

  public old_password_again_visible: boolean = false;
  public new_password_visible: boolean = false;
  public new_password_control_visible: boolean = false;

  public input_fields: any = undefined;

  changePasswordForm = new UntypedFormGroup({
    old_password: new UntypedFormControl('', [Validators.required]),
    new_password: new UntypedFormControl('', [Validators.required, Validators.pattern('.*[!@#$%^&*(),.?":{}|<>_+=/;€£\\[\\]-]+.*$'), Validators.pattern('.*[a-z]+.*$'), Validators.pattern('.*[A-Z]+.*$'), Validators.pattern('.*[0-9]+.*$'), Validators.minLength(8), Validators.maxLength(50)]),
    new_password_control: new UntypedFormControl('', [Validators.required]),
  });

  public subscription_list: (Subscription | undefined)[] = [];

  constructor(public managerService: ManagerService, private httpClient: HttpClient, public elementRef: ElementRef) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.input_fields = this.elementRef.nativeElement.getElementsByClassName('input-field-underline');

    this.subscription_list.push(this.changePasswordForm.get('old_password')?.valueChanges.subscribe(() => {
      this.success_text.nativeElement.classList.add('d-none');
      this.warning_old_password.nativeElement.classList.add('d-none');
      this.input_fields[0].classList.remove('input-field-underline-required');

      if (this.changePasswordForm.get('old_password')?.errors?.required) {
        this.input_fields[0].classList.add('input-field-underline-required');
        // @ts-ignore
        this.warning_old_password.nativeElement.innerHTML = this.managerService.config.RESPONSE_TABLE['2003'].message;
        this.success_text.nativeElement.classList.add('d-none');
        this.warning_old_password.nativeElement.classList.remove('d-none');
      }
    }));

    this.subscription_list.push(this.changePasswordForm.get('new_password')?.valueChanges.subscribe(() => {
      this.success_text.nativeElement.classList.add('d-none');
      this.warning_new_password.nativeElement.classList.add('d-none');

      if (this.changePasswordForm.invalid) {
        if (this.changePasswordForm.get('new_password')?.errors?.required) {
          this.input_fields[1].classList.add('input-field-underline-required');
          // @ts-ignore
          this.warning_new_password.nativeElement.innerHTML = this.managerService.config.RESPONSE_TABLE['2003'].message;
          this.info_text.nativeElement.classList.add('d-none');
          this.warning_new_password.nativeElement.classList.remove('d-none');
        } else if (this.changePasswordForm.get('new_password')?.errors) {
          this.input_fields[1].classList.add('input-field-underline-required');
          // @ts-ignore
          this.warning_new_password.nativeElement.innerHTML = this.managerService.config.RESPONSE_TABLE['2004'].message;
          this.info_text.nativeElement.classList.add('d-none');
          this.warning_new_password.nativeElement.classList.remove('d-none');
        } else {
          this.info_text.nativeElement.classList.remove('d-none');
          this.input_fields[1].classList.remove('input-field-underline-required');
        }
      } else {
        this.info_text.nativeElement.classList.remove('d-none');
        this.input_fields[1].classList.remove('input-field-underline-required');
      }

      if (this.changePasswordForm.get('new_password_control')?.value !== ''
        && this.changePasswordForm.get('new_password')?.value !== this.changePasswordForm.get('new_password_control')?.value) {
        this.input_fields[2].classList.add('input-field-underline-required');
        this.warning_new_password_control.nativeElement.classList.remove('d-none');
      } else {
        this.input_fields[2].classList.remove('input-field-underline-required');
        this.warning_new_password_control.nativeElement.classList.add('d-none');
      }
    }));

    this.subscription_list.push(this.changePasswordForm.get('new_password_control')?.valueChanges.subscribe(() => {
      this.success_text.nativeElement.classList.add('d-none');
      this.warning_new_password_control.nativeElement.classList.add('d-none');

      if (this.changePasswordForm.get('new_password_control')?.value !== ''
        && this.changePasswordForm.get('new_password')?.value !== this.changePasswordForm.get('new_password_control')?.value) {
        this.info_text.nativeElement.classList.add('d-none');
        this.input_fields[2].classList.add('input-field-underline-required');
        // @ts-ignore
        this.warning_new_password_control.nativeElement.innerHTML = this.managerService.config.RESPONSE_TABLE['2002'].message;
        this.warning_new_password_control.nativeElement.classList.remove('d-none');
      } else {
        this.input_fields[2].classList.remove('input-field-underline-required');
        this.warning_new_password_control.nativeElement.classList.add('d-none');
        if (this.changePasswordForm.invalid) {
          if (this.changePasswordForm.get('new_password_control')?.errors?.required) {
            this.input_fields[2].classList.add('input-field-underline-required');
            // @ts-ignore
            this.warning_new_password_control.nativeElement.innerHTML = this.managerService.config.RESPONSE_TABLE['2003'].message;
            this.warning_new_password_control.nativeElement.classList.remove('d-none');
          }
        }
      }
    }));
  }

  clearChangePasswordForm(): void {
    for (const input_field of this.input_fields) {
      input_field.classList.remove('input-field-required');
    }
    this.changePasswordForm.reset({old_password: '', new_password: '', new_password_control: ''});
    this.success_text.nativeElement.classList.add('d-none');
    this.warning_old_password.nativeElement.classList.add('d-none');
    this.info_text.nativeElement.classList.remove('d-none');
    this.warning_new_password.nativeElement.classList.add('d-none');
    this.warning_new_password_control.nativeElement.classList.add('d-none');
  }

  changePasswordValidator(): void {
    this.success_text.nativeElement.classList.add('d-none');

    for (const input_field of this.input_fields) {
      input_field.classList.remove('input-field-underline-required');
    }
    this.warning_old_password.nativeElement.classList.add('d-none');
    this.info_text.nativeElement.classList.remove('d-none');
    this.warning_new_password.nativeElement.classList.add('d-none');
    this.warning_new_password_control.nativeElement.classList.add('d-none');
    if (this.changePasswordForm.invalid) {
      if (this.changePasswordForm.get('warning_old_password')?.errors || this.changePasswordForm.get('new_password')?.errors || this.changePasswordForm.get('new_password_control')?.errors) {
        if (this.changePasswordForm.get('new_password')?.errors?.pattern) {
          this.input_fields[1].classList.add('input-field-underline-required');
          // @ts-ignore
          this.warning_new_password.nativeElement.innerHTML = this.managerService.config.RESPONSE_TABLE['2004'].message;
          this.info_text.nativeElement.classList.add('d-none');
          this.warning_new_password.nativeElement.classList.remove('d-none');
        } else {
          for (const input_field of this.input_fields) {
            input_field.classList.add('input-field-underline-required');
          }
          // @ts-ignore
          this.warning_new_password.nativeElement.innerHTML = this.managerService.config.RESPONSE_TABLE['2003'].message;
          this.info_text.nativeElement.classList.add('d-none');
          this.warning_new_password.nativeElement.classList.remove('d-none');
        }
      }
    } else {
      if (this.changePasswordForm.get('new_password')?.value !== this.changePasswordForm.get('new_password_control')?.value) {
        this.info_text.nativeElement.classList.add('d-none');
        this.input_fields[2].classList.add('input-field-underline-required');
        // @ts-ignore
        this.warning_new_password_control.nativeElement.innerHTML = this.managerService.config.RESPONSE_TABLE['2002'].message;
        this.warning_new_password_control.nativeElement.classList.remove('d-none');
      } else {
        this.changePassword();
      }
    }
  }

  changePassword(): void {
    const data = {
      old_password: this.changePasswordForm.value.old_password,
      new_password: this.changePasswordForm.value.new_password,
      client_info: this.managerService.client_info
    };

    this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'change_user_password_service', data).subscribe(
      response => {
        this.success_text.nativeElement.classList.add('d-none');
        if (response.is_okay) {
          this.clearChangePasswordForm();
          this.success_text.nativeElement.innerHTML = this.managerService.codeHandler(response);
          this.success_text.nativeElement.classList.remove('d-none');
        } else {
          if (response.code === 228) {
            this.input_fields[0].classList.add('input-field-underline-required');
            this.warning_old_password.nativeElement.innerHTML = this.managerService.codeHandler(response);
            this.warning_old_password.nativeElement.classList.remove('d-none');
          }
        }
        // @ts-ignore
        const content = this.managerService.config.RESPONSE_TABLE[response.code];
        this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
      }));
  }

  ngOnDestroy(): void {
    this.subscription_list.forEach((subscription) => {
      subscription?.unsubscribe();
    });
  }
}

