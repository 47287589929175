import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
    selector: 'app-fiat-withdrawal-request-desktop',
    templateUrl: './fiat-withdrawal-request-desktop.component.html',
    styleUrls: ['./fiat-withdrawal-request-desktop.component.css']
})
export class FiatWithdrawalRequestDesktopComponent implements OnInit {
    @ViewChild('checkbox_field_desktop') checkbox_field_desktop: any = undefined;

    @Input() modal_id: string = '';

    constructor(public managerService: ManagerService) {
    }

    ngOnInit(): void {
    }

    onAccept(): void {
        this.managerService.operationsService.sign_withdrawal_request_data.status = 'approve';
        this.managerService.operationsService.fiat_withdrawal_rules_accepted_status = true;
        this.managerService.closeFiatWithdrawalRequestModal();
        this.checkbox_field_desktop.checked = false;
        this.managerService.operationsService.bank_withdrawal_request_form_submitted.emit();
        this.managerService.marketingService.logEvent('withdrawal_rules_approved', {symbol: 'TRY'});
    }
}
