<div class="card" [ngClass]="{'non-clickable': !clickable }">
    <div class="support-svg">
        <ng-content></ng-content>
    </div>
    <span class="header d-flex text-center" style="color: var(--text-color-artiox-black, #131515)">
        {{cardTitle}}
    </span>
    <div class="body d-flex text-center" style="color: var(--text-color-artiox-light-black, #37324D)">
        <span >{{cardBody}}</span>
    </div>
</div>
