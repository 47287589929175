<div class="w-75 d-flex flex-column align-items-center justify-content-center m-auto">
  <div class="d-flex flex-row align-self-center justify-content-center">
    <img *ngIf="managerService.pairService.active_featured_pair_ready | managerPipe"
         (click)="managerService.photoSwipeOpen(managerService.pairService.active_featured_pair)" alt="art_product" #art_product
         class="p-4"
         src="{{(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_IMAGE_DESKTOP | managerPipe: managerService.mpa_template.LOCAL_IMAGE_MANAGER)}}"
         style="max-height: 400px; max-width: 90%;"
         (error)="art_product.src = art_product.src.replace('.webp', '.jpg');">
  </div>
</div>
