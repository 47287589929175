import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {attributeSelectors} from '@angular/cdk/schematics';

declare global {
    interface Window {
        dataLayer: any;
        gtag: any;
        fbq: any;
        // insider_object: any;
    }
}

@Injectable({
    providedIn: 'root'
})
export class MarketingService {
    public environment = environment;

    // private insider_script: any;

    constructor() {
        if (this.environment.production) {
            this.initGoogle();
            this.initFacebook();
            // this.initInsider();
        }
        this.initChatSimple();
    }

    initGoogle(): void {
        window.dataLayer = window.dataLayer || [];

        window.gtag = this._gtag;

        window.gtag('js', new Date());
        window.gtag('config', 'G-N1BBX6QGBG');

        const google_script = document.createElement('script');
        google_script.async = true;
        google_script.src = 'https://www.googletagmanager.com/gtag/js?id=G-N1BBX6QGBG';
        document.head.appendChild(google_script);
    }

    _gtag(): void {
        window.dataLayer.push(arguments);
    }

    initFacebook(): void {
        this._addFacebookImg();
        this._addFacebookMeta();
        this._addFacebookScript(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js', undefined, undefined, undefined);

        window.fbq('init', '734401668130798');
        window.fbq('track', 'PageView');
    }

    initChatSimple(): void{
        var chatBotEl = document.createElement('chat-bot');
        chatBotEl.setAttribute('platform_id', 'cb9d15cf-c55a-4e5d-9ed6-383b2695be43');
        chatBotEl.setAttribute('user_id', '47a944b2-9cb1-476c-97c8-741d3f9cafc0');
        chatBotEl.setAttribute('chatbot_id', '5d2bfe12-c417-4b16-aee2-4b43d6af9f43');
        let height = window.innerWidth <= 767 ? '64' : '16';
        chatBotEl.setAttribute('bottom_offset', height)
        // chatBotEl.setAttribute('show_popup_on_mobile', 'true')
        // chatBotEl.setAttribute('show_popup_on_desktop', 'true')  // todo
        var chatbotLink = document.createElement('a');
        chatbotLink.href = 'https://www.chatsimple.ai/?utm_source=widget&utm_medium=referral';
        chatbotLink.innerHTML = '[chatbot]';
        chatBotEl.appendChild(chatbotLink);

        document.body.appendChild(chatBotEl);

        // Dynamically load the external script
        var cs = document.createElement('script');
        cs.src = 'https://chatsimple-widget.s3.us-east-2.amazonaws.com/chat-bot-loader.js';
        document.body.appendChild(cs);
    }


    _addFacebookScript(f: any, b: any, e: any, v: any, n: any, t: any, s: any): void {
        if (f.fbq) {
            return;
        }
        n = f.fbq = (...args: any) => {
            n.callMethod ? n.callMethod.apply(n, args) : n.queue.push(args);
        };
        if (!f._fbq) {
            f._fbq = n;
        }
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
    }

    _addFacebookImg(): void {
        const no_script_elem = document.createElement('noscript');
        const img_elem = document.createElement('img');
        img_elem.height = 1;
        img_elem.width = 1;
        img_elem.style.display = 'none';
        img_elem.width = 1;
        img_elem.src = 'https://www.facebook.com/tr?id=734401668130798&ev=PageView&noscript=1';
        no_script_elem.appendChild(img_elem);
        document.head.appendChild(no_script_elem);
    }

    _addFacebookMeta(): void {
        const meta_elem = document.createElement('meta');
        meta_elem.name = 'facebook-domain-verification';
        meta_elem.content = 'n5dphaecxxyziytk2hbhesgjgz9vsb';
        document.head.appendChild(meta_elem);
    }

    // initInsider(): void {
    //     this.insider_script = document.createElement('script');
    //     this.insider_script.src = '//artiox.api.useinsider.com/ins.js?id=10008267';
    //     this.insider_script.async = true;
    //     document.head.appendChild(this.insider_script);
    // }

    logEvent(event: string, data: any = null): void {
        if (!this.environment.production) {
            return;
        }
        window.gtag('event', event, data);  // Google Analytics
        window.fbq('track', event, data);  // Facebook Events
    }

    // setInsiderEvent(type: string): void {
    //     window.insider_object = window.insider_object || {};
    //     window.insider_object.page = {
    //         type
    //     };
    //     // this.refreshInsider();
    // }

    // refreshInsider(): void {
    //     document.head.removeChild(this.insider_script);
    //     this.initInsider();
    //
    // }
}
