<div class="p-5 w-100"
     style="min-width: 600px; font-family: Roboto,sans-serif; color: #404352; user-select: none;">
  <div class="d-flex w-100 justify-content-end">
    <svg style="margin-top: -16px; margin-right: -8px; height: 18px; width: 18px;" (click)="managerService.closeModal(modal_id)">
      <use [attr.xlink:href]='"./assets/images/exit_icon.svg#exit_icon"' fill="#404352"/>
    </svg>
  </div>
  <div class="pt-3 w-80 mx-auto d-flex flex-column align-items-center justify-content-center">
    <div class="w-100 d-flex flex-flex justify-content-center align-items-center" style="font-family: bebas-neue-bold, sans-serif; font-size: 24px; letter-spacing: 2.5px;">
      <div (click)="this.managerService.setActiveCurrencyConverterPanel(active_currency_converter_panel_template.BUY)"
           [style]="(managerService.active_currency_converter_panel === active_currency_converter_panel_template.BUY | managerPipe)
         ? {'border-bottom': 'solid 1px var(--artiox-blue)', color: 'var(--artiox-blue)'}
         : {color: '#9da5b7'}"
           class="w-50 text-center">
        {{managerService.currency_pair_pipe | managerPipe : managerService.mpa_template.ACTIVE_BASE_CURRENCY_NAME}}
        {{managerService.config.CURRENCY_CONVERTER.BUY | managerPipe}}
      </div>
      <div (click)="this.managerService.setActiveCurrencyConverterPanel(active_currency_converter_panel_template.SELL)"
           [style]="(managerService.active_currency_converter_panel === active_currency_converter_panel_template.SELL | managerPipe)
         ? {'border-bottom': 'solid 1px var(--artiox-blue)', color: 'var(--artiox-blue)'}
         : {color: '#9da5b7'}"
           class="w-50 text-center">
        {{managerService.currency_pair_pipe | managerPipe : managerService.mpa_template.ACTIVE_BASE_CURRENCY_NAME}}
        {{managerService.config.CURRENCY_CONVERTER.SELL | managerPipe}}
      </div>
    </div>
    <div class="pt-5" style="font-size: 14px; font-weight: bold; letter-spacing: 1.4px;">
      {{managerService.config.CURRENCY_CONVERTER.LAST_PRICE | managerPipe}}
    </div>
    <div class="pt-1">
    <span style="font-size: 25px; font-weight: bold; letter-spacing: 1.75px;">
      {{managerService.pairService.currency_pair_list_pipe | managerPipe: managerService.mpa_template.ACTIVE_CURRENCY_PAIR_PRICE | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
    </span>
      <span style="font-family: 'Roboto Condensed', sans-serif; font-size: 13px; letter-spacing: 0.9px;   color: #404352;">
      {{managerService.pairService.currency_list_pipe | managerPipe: managerService.mpa_template.ROOT_CURRENCY_SYMBOL}}
    </span>
    </div>


    <div class="w-100 pt-5 d-flex flex-row justify-content-center align-items-center">
      <form [formGroup]="currencyConverterBuyForm" class="w-100"
            [class.d-none] = "managerService.active_currency_converter_panel !== active_currency_converter_panel_template.BUY | managerPipe">
          <div class="text-center" style="font-size: 12px; font-weight: bold; letter-spacing: 0.4px; color: #2a2a2a;">
            {{[managerService.currency_pair_pipe | managerPipe : managerService.mpa_template.ACTIVE_BASE_CURRENCY_NAME]
            | managerPipe: managerService.mpa_template.EVALUATE_STRING : managerService.config.CURRENCY_CONVERTER.AMOUNT_TO_BUY}}
            <label class="w-100">
              <input class="w-100 input-field-white" formControlName="amount" min="0.00" placeholder="0" required type="number">
            </label>
          </div>
      </form>

      <form [formGroup]="currencyConverterSellForm" class="w-100"
            [class.d-none] = "managerService.active_currency_converter_panel !== active_currency_converter_panel_template.SELL | managerPipe">
          <div class="text-center" style="font-size: 12px; font-weight: bold; letter-spacing: 0.4px; color: #2a2a2a;">
            {{[managerService.pairService.active_currency_pair | managerPipe : managerService.mpa_template.ACTIVE_BASE_CURRENCY_NAME]
            | managerPipe: managerService.mpa_template.EVALUATE_STRING : managerService.config.CURRENCY_CONVERTER.AMOUNT_TO_SELL}}
            <label class="w-100">
              <input class="w-100 input-field-white" formControlName="amount" min="0.00" placeholder="0" required type="number">
            </label>
          </div>
      </form>

      <div>
        <svg (click)="switch()"
             [style]="(managerService.active_currency_converter_panel === active_currency_converter_panel_template.BUY | managerPipe)
               ? {transform: 'rotateZ(180deg)'}
               : {transform: 'rotateZ(0deg)'}"
             style="height: 40px; width: 40px; transition: 0.4s; padding: 8px; margin-top: 16px" type="button">
          <use [attr.xlink:href]='"./assets/images/basic_icon.svg#basic_icon"' fill="#cfd6d8"/>
        </svg>
      </div>

      <div class="w-100 text-center" style="font-size: 11px; font-weight: bold; letter-spacing: 0.4px; color: #2a2a2a;">
      {{managerService.config.CURRENCY_CONVERTER.TL_AMOUNT | managerPipe}}
      <label class="w-100">
        <input class="w-100 input-field-white" disabled min="0.00" placeholder="0" readonly type="number" value="{{swap_average_cost | managerPipe}}"
               [style]="{color: swap_average_cost === 0 ? '#d7d9e5' : '#404352'}">
      </label>
    </div>
    </div>

    <div class="w-100" style="font-family: Raleway, sans-serif; font-size: 14px; font-weight: normal; color: #9d9fa6;">
        <div [class.d-none]="managerService.active_currency_converter_panel !== managerService.active_currency_converter_panel_template.BUY" class="text-right">
          {{[managerService.pairService.currency_pair_list_pipe | managerPipe: managerService.mpa_template.ACTIVE_QUOTE_CURRENCY_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.BASIC.TRADE.AVAILABLE_AMOUNT}}
          <strong>{{managerService.pairService.currency_pair_list_pipe | managerPipe: managerService.mpa_template.USER_ACTIVE_QUOTE_CURRENCY_AVAILABLE_AMOUNT: managerService.user_pipe}}</strong>
        </div>
        <div [class.d-none]="managerService.active_currency_converter_panel !== managerService.active_currency_converter_panel_template.SELL">
          {{[managerService.pairService.currency_pair_list_pipe | managerPipe: managerService.mpa_template.ACTIVE_BASE_CURRENCY_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.BASIC.TRADE.AVAILABLE_AMOUNT}}
          <strong>{{managerService.currency_pair_pipe | managerPipe: managerService.mpa_template.USER_ACTIVE_BASE_CURRENCY_AVAILABLE_AMOUNT: managerService.user_pipe}}</strong>
        </div>
    </div>

    <div class="w-100 my-4" style="border-top: solid 1px #d5d8e2;">
      <div class="my-3" style="font-size: 13px; font-weight: bold; letter-spacing: 1.35px; color: #404352;">
        {{managerService.config.CURRENCY_CONVERTER.SUMMARY_TITLE | managerPipe}}
      </div>
      <div class="w-100 my-2 d-flex flex-row justify-content-between" style="font-size: 11px; font-weight: 500; letter-spacing: 0.75px; color: #4a4e70;">
      <span>
        {{[managerService.currency_pair_pipe | managerPipe : managerService.mpa_template.ACTIVE_BASE_CURRENCY_SYMBOL]
        | managerPipe: managerService.mpa_template.EVALUATE_STRING
        : (managerService.active_currency_converter_panel === active_currency_converter_panel_template.BUY ? managerService.config.CURRENCY_CONVERTER.AMOUNT_TO_BUY : managerService.config.CURRENCY_CONVERTER.AMOUNT_TO_SELL)}}
      </span>
        <span>{{swap_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER_DECIMAL: (managerService.currency_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_BASE_CURRENCY_DECIMAL)}}
          {{managerService.currency_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_BASE_CURRENCY_SYMBOL}}</span>
      </div>
      <div class="w-100 my-2  d-flex flex-row justify-content-between" style="font-size: 11px; font-weight: 500; letter-spacing: 0.75px; color: #4a4e70;">
      <span>
        {{managerService.config.CURRENCY_CONVERTER.FEE | managerPipe}}
      </span>
        <span [class.d-none] = "managerService.active_currency_converter_panel !== active_currency_converter_panel_template.BUY | managerPipe">
          {{swap_fee | managerPipe: managerService.mpa_template.FANCY_NUMBER_DECIMAL: (managerService.currency_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_BASE_CURRENCY_DECIMAL)}}
          {{managerService.currency_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_BASE_CURRENCY_SYMBOL}} </span>
        <span [class.d-none] = "managerService.active_currency_converter_panel !== active_currency_converter_panel_template.SELL | managerPipe">
          {{swap_fee | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
          {{managerService.currency_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_QUOTE_CURRENCY_SYMBOL}} </span>
      </div>
      <div class="w-100 my-2  d-flex flex-row justify-content-between" style="font-size: 11px; font-weight: 500; letter-spacing: 0.75px; color: #4a4e70;">
        <span>
          {{managerService.config.CURRENCY_CONVERTER.NET_AMOUNT | managerPipe}}
        </span>
        <span [class.d-none] = "managerService.active_currency_converter_panel !== active_currency_converter_panel_template.BUY | managerPipe">
          {{net_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER_DECIMAL: (managerService.currency_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_BASE_CURRENCY_DECIMAL)}}
          {{managerService.currency_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_BASE_CURRENCY_SYMBOL}} </span>
        <span [class.d-none] = "managerService.active_currency_converter_panel !== active_currency_converter_panel_template.SELL | managerPipe">
          {{net_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
          {{managerService.currency_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_QUOTE_CURRENCY_SYMBOL}} </span>
      </div>
    </div>

    <button (click)="buyCurrency()"
            [class.d-none] = "managerService.active_currency_converter_panel !== active_currency_converter_panel_template.BUY | managerPipe"
            class="w-100 btn-native buy-button my-2 p-3">
      {{managerService.config.CURRENCY_CONVERTER.BUY | managerPipe}}
    </button>
    <button (click)="sellCurrency()"
            [class.d-none] = "managerService.active_currency_converter_panel !== active_currency_converter_panel_template.SELL | managerPipe"
            class="w-100 btn-native sell-button my-2 p-3">
      {{managerService.config.CURRENCY_CONVERTER.SELL | managerPipe}}
    </button>
  </div>
</div>
