<div style="width: 90vw; background: #FFFFFF;height: 100vh">

    <div class="d-flex w-100 flex-column">

        <div class="d-flex align-items-center justify-content-center">
            <div class="d-flex flex-column" style="gap: 4px;padding: 16px">
                <span class="header5 exo bold text-center"
                      style="color: #8266F0">{{managerService.config.USER_AGREEMENT.HEADING | managerPipe}}</span>
                <span
                    [innerHTML]="managerService.config.USER_AGREEMENT.TEXT | managerPipe" style="font-family: Barlow;font-size: 14px;font-style: normal;font-weight: 400;line-height: 16px;color: #6F6F6F; "></span>
            </div>
        </div>

        <div class="d-flex  flex-column barlow overline regular text-decoration-underline"
             style="color: #8266F0; padding: 8px">
            <ul>
                <li>
                    <a class="purple-link" href="assets/pdfs/Artiox Kullanıcı Sözleşmesi.pdf"
                       target="_blank">{{managerService.config.USER_AGREEMENT.LINK}}</a>
                </li>
            </ul>
        </div>


        <div class="d-flex  justify-content-start flex-row" style="padding: 16px;">
            <div (click)="checkBoxToggle()" [class.login-checkbox-selected]="checked"
                 class="d-flex login-checkbox align-items-center">
                <svg *ngIf="checked" style="height: 16px; width: 16px;">
                    <use [attr.xlink:href]='"./assets/images/checkbox.svg#checkbox"' fill="#8266F0"/>
                </svg>
            </div>
            <span class="d-flex overline barlow regular"
                  style="color: var(--text-color-artiox-light-black); padding-left: 8px">{{managerService.config.USER_AGREEMENT.AGREEMENT}}</span>
        </div>

        <div class="d-flex w-100 flex-row justify-content-center pb-5">
            <button (click)="rejectUserAgreement()"
                    style="cursor: pointer; display: flex;flex-direction: row;justify-content: center;align-items: center;margin-right: 16px;gap: 8px;width: 140px;height: 44px;border: none; background: #FDEBEC;border-radius: 6px;color: #EA3943">
                {{managerService.config.USER_AGREEMENT.REJECT}}
            </button>
            <button #agreement_button (click)="acceptUserAgreement()" class="agreement-button" disabled style="display: flex;flex-direction: row;justify-content: center;align-items: center;margin-left: 16px;gap: 8px;width: 140px;height: 44px;border: none; border-radius: 6px; color: #FFFFFF"
                    type="button">
                {{managerService.config.USER_AGREEMENT.ACCEPT}}
            </button>
        </div>
    </div>

    <div class="d-flex w-100 justify-content-center align-items-end" style="position:absolute; bottom: 32px">
        <svg style=" height: 32px; width: 138px; margin-top: 32px;">
            <use [attr.xlink:href]='"./assets/images/artiox_logo.svg#artiox_logo"' fill="#000"/>
        </svg>
    </div>
</div>
