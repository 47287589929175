<div style="max-width: 550px; background: #FFFFFF;height: 100vh">

    <div class="d-flex w-100 flex-column">
        <div class="d-flex w-100 justify-content-start">
            <div class="d-flex flex-column" style="gap: 16px;padding: 64px 48px">
                <p class="header6 exo bold"
                   style="color: #8266F0">{{managerService.config.AUCTION.ACCOUNT_LEVEL.TITLE | managerPipe}}</p>
                <p class="body2 barlow regular"
                   style="color: #6F6F6F">{{managerService.config.AUCTION.ACCOUNT_LEVEL.TEXT | managerPipe}}</p>
            </div>
        </div>

        <div class="d-flex w-100 justify-content-center align-items-center">
            <div
                style="display: flex;width: 180px;height: 180px;padding: 36px;justify-content: center;align-items: center;flex-shrink: 0;border-radius: 100px;background: var(--main-color-artiox-purple-200, #ECE8FD);">
                <svg style="height: 108px; width: 108px">
                    <use [attr.xlink:href]='"./assets/images/exclamation_purple.svg#exclamation_purple"'/>
                </svg>
            </div>
        </div>

        <div class="d-flex w-100 flex-row justify-content-center pt-4 pb-5" style="gap: 32px; margin-top: 48px">
            <div class="d-flex" (click)="managerService.closeModal(modal_id)"
                 style="cursor: pointer; display: flex;flex-direction: row;justify-content: center;align-items: center;padding: 0px;gap: 8px;width: 140px;height: 44px;background: #FDEBEC;border-radius: 6px;color: #EA3943">
                {{managerService.config.AUCTION.ACCOUNT_LEVEL.CANCEL | managerPipe}}
            </div>
            <div class="d-flex" (click)="managerService.navigationManager({url: '/account/security', active_security_panel: managerService.active_security_panel_template.IDENTITY_VERIFICATION, current_component_index: 0});managerService.closeModal(modal_id)"
                 style="cursor: pointer; display: flex;flex-direction: row;justify-content: center;align-items: center;padding: 0px;gap: 8px;width: 140px;height: 44px;background: #8266F0;;border-radius: 6px; color: #FFFFFF">
                {{managerService.config.AUCTION.ACCOUNT_LEVEL.LEVELUP | managerPipe}}
            </div>
        </div>
    </div>

    <div class="d-flex w-100 justify-content-center align-items-end" style="position:absolute; bottom: 10px">
        <svg style=" height: 32px; width: 138px; margin-top: 32px; margin-bottom: 60px">
            <use [attr.xlink:href]='"./assets/images/artiox_logo.svg#artiox_logo"' fill="#000"/>
        </svg>
    </div>
</div>
