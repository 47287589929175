import {Component, OnDestroy, OnInit} from '@angular/core';
import {ManagerService} from '../../../services/manager.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-account-verification',
  templateUrl: './account-verification.component.html',
  styleUrl: './account-verification.component.css',
})

export class AccountVerificationComponent {
  constructor(protected managerService: ManagerService) {

  }

}
