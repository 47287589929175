import { Component, OnInit } from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
  selector: 'app-account-auction-mobile',
  templateUrl: './account-auction-mobile.component.html',
  styleUrls: ['./account-auction-mobile.component.css']
})
export class AccountAuctionMobileComponent implements OnInit {

  constructor(public managerService: ManagerService) { }

  ngOnInit(): void {
  }

}
