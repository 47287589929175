<div class="h-100 w-100 d-flex flex-column align-items-end justify-content-center">
  <div *ngIf="(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PRE_SALE) || (managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PUBLIC_SALE)"
       class="w-100 d-flex flex-row align-items-center justify-content-between mt-4">
    <div *ngIf="(managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_SUPPLY_RATIO) !== 0" class="d-flex flex-column align-items-center justify-content-center"
         style="width: 45%;">
      <span
        style="font-family: bebas-neue-regular, sans-serif; font-size: 16px; line-height: 1.2; letter-spacing: 1px; color: #ffffff;">
        {{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_DATE_INTERVAL: managerService.language_pipe}}
      </span>
      <div class="w-100 d-flex flex-column align-items-center justify-content-center p-2"
           style="box-shadow: 7px 10px 9px 0 rgba(0, 0, 0, 0.2); background-image: linear-gradient(100deg, #333546 9%, #282937 96%);">
        <span [class.text-info]="(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PRE_SALE)"
              style="font-family: bebas-neue-regular, sans-serif; font-size: 32px; line-height: 1.2; letter-spacing: 1px; color: #ffffff;">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_PRICE}}</span>
        <span
          style="font-family: bebas-neue-regular, sans-serif; font-size: 16px; line-height: 1.2; letter-spacing: 1px; color: #ffffff;">{{managerService.config.HOME.SLIDER.TOKENMETRICS.PRE_SALE_PRICE | managerPipe}}</span>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center justify-content-center" style="width: 45%;">
      <span
        style="font-family: bebas-neue-regular, sans-serif; font-size: 16px; line-height: 1.2; letter-spacing: 1px; color: #ffffff;">
        {{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_DATE_INTERVAL: managerService.language_pipe}}
      </span>
      <div class="w-100 d-flex flex-column align-items-center justify-content-center p-2"
           style="box-shadow: 7px 10px 9px 0 rgba(0, 0, 0, 0.2); background-image: linear-gradient(100deg, #333546 9%, #282937 96%);">
        <span [class.text-info]="(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PUBLIC_SALE)"
              style="font-family: bebas-neue-regular, sans-serif; font-size: 32px; line-height: 1.2; letter-spacing: 1px; color: #ffffff;">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_PRICE}}
          <span style="font-family: Roboto, sans-serif; font-size: 28px; font-weight: 400">₺</span></span>
        <span
          style="font-family: bebas-neue-regular, sans-serif; font-size: 16px; line-height: 1.2; letter-spacing: 1px; color: #ffffff;">{{managerService.config.HOME.SLIDER.TOKENMETRICS.PUBLIC_SALE_PRICE_UPPERCASE | managerPipe}}</span>
      </div>
    </div>
  </div>

  <div *ngIf="(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_FEATURING) || (managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PRE_SALE) || (managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PUBLIC_SALE)" [class]="((managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_SUPPLY_RATIO) === 0 && (managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PUBLIC_SALE) | managerPipe) ? 'd-none':'d-flex'"
       class="w-100 flex-row align-items-center justify-content-between"
       style="margin-top: 40px;">
    <span
      *ngIf="(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_FEATURING) || (managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PRE_SALE)"
      style="font-family: bebas-neue-regular, sans-serif; font-size: 16px; line-height: 1.2; letter-spacing: 1px; color: #ffffff;">
    {{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_STATE_TIME_HEADING: managerService.language_pipe}}</span>
    <span *ngIf="managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PUBLIC_SALE"
          style="font-family: bebas-neue-regular, sans-serif; font-size: 16px; line-height: 1.2; letter-spacing: 1px; color: #ffffff;">
      {{managerService.config.PRE_SALE.PRE_SALE | managerPipe}}
    </span>
    <span *ngIf="(managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_SUPPLY_RATIO) !== 0"
          style="font-family: Roboto, sans-serif; font-size: 10px; line-height: 1.2; letter-spacing: 2px; color: #ffffff;">
    {{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_DATE: managerService.language_pipe}}</span>
    <span *ngIf="(managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_SUPPLY_RATIO) === 0"
          style="font-family: Roboto, sans-serif; font-size: 10px; line-height: 1.2; letter-spacing: 2px; color: #ffffff;">
    {{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_DATE: managerService.language_pipe}}</span>
  </div>

  <div *ngIf="(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_FEATURING) || (managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PRE_SALE) || (managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PUBLIC_SALE)"
       [class.d-none]="((managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_SUPPLY_RATIO) === 0 && (managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PUBLIC_SALE) | managerPipe)"
       class="w-100"
       style="box-shadow: 7px 10px 9px 0 rgba(0, 0, 0, 0.2); background-image: linear-gradient(100deg, #333546 9%, #282937 96%);">
    <div class="w-100 d-flex flex-row align-items-center justify-content-around my-2"
         style=" font-family: bebas-neue-regular, sans-serif;
             font-size: 12px; line-height: 1.2; letter-spacing: 1.2px; color: white; padding-right: 60px;">
      <span class="w-25 text-right">{{managerService.config.HOME.SLIDER.DAY | managerPipe}}</span>
      <span class="w-25 text-right">{{managerService.config.HOME.SLIDER.HOUR | managerPipe}}</span>
      <span class="w-25 text-right">{{managerService.config.HOME.SLIDER.MIN | managerPipe}}</span>
      <span class="w-25 text-right">{{managerService.config.HOME.SLIDER.SEC | managerPipe}}</span>
    </div>
    <div
      *ngIf="(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_FEATURING) || (managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PRE_SALE)"
      [innerHTML]="(managerService.pairService.active_featured_pair?.id | managerPipe: managerService.mpa_template.SALE_STATE_TIME_COUNTDOWN: managerService.pairService.active_featured_pair?.sale_state) | async | managerPipe: managerService.mpa_template.TIME_FORMAT"
      class="w-100 d-flex flex-row align-items-center justify-content-around my-2"
      style=" font-family: bebas-neue-regular, sans-serif;
             font-size: 26px; line-height: 1.2; letter-spacing: 1.2px; color: white; padding-right: 60px;"></div>
    <div
      *ngIf="managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PUBLIC_SALE"
      class="w-100 d-flex flex-row align-items-center justify-content-around my-2"
      style=" font-family: bebas-neue-regular, sans-serif;
             font-size: 26px; line-height: 1.2; letter-spacing: 1.2px; color: white;">
      {{managerService.config.PRE_SALE.OVER}}
    </div>
  </div>
  <div *ngIf="(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PRE_SALE) || (managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PUBLIC_SALE)" class="w-100 d-flex flex-row align-items-center justify-content-between"
       style="margin-top: 40px;">
    <span *ngIf="managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PRE_SALE"
          style="font-family: bebas-neue-regular, sans-serif; font-size: 16px; line-height: 1.2; letter-spacing: 1px; color: #ffffff;">
    {{managerService.config.PRE_SALE.PUBLIC_SALE}}</span>
    <span *ngIf="managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PUBLIC_SALE"
          style="font-family: bebas-neue-regular, sans-serif; font-size: 16px; line-height: 1.2; letter-spacing: 1px; color: #ffffff;">
    {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_STATE_TIME_HEADING: managerService.language_pipe}}</span>
    <span
      style="font-family: Roboto, sans-serif; font-size: 10px; line-height: 1.2; letter-spacing: 2px; color: #ffffff;">
    {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_DATE: managerService.language_pipe}}</span>
  </div>
  <div *ngIf="(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PRE_SALE) || (managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PUBLIC_SALE)"
       class="w-100"
       style="box-shadow: 7px 10px 9px 0 rgba(0, 0, 0, 0.2); background-image: linear-gradient(100deg, #333546 9%, #282937 96%);">
    <div class="w-100 d-flex flex-row align-items-center justify-content-around my-2"
         style=" font-family: bebas-neue-regular, sans-serif;
             font-size: 12px; line-height: 1.2; letter-spacing: 1.2px; color: white; padding-right: 60px;">
      <span class="w-25 text-right">{{managerService.config.HOME.SLIDER.DAY | managerPipe}}</span>
      <span class="w-25 text-right">{{managerService.config.HOME.SLIDER.HOUR | managerPipe}}</span>
      <span class="w-25 text-right">{{managerService.config.HOME.SLIDER.MIN | managerPipe}}</span>
      <span class="w-25 text-right">{{managerService.config.HOME.SLIDER.SEC | managerPipe}}</span>
    </div>
    <div
      *ngIf="managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PRE_SALE"
      class="w-100 d-flex flex-row align-items-center justify-content-around my-2"
      style=" font-family: bebas-neue-regular, sans-serif;
             font-size: 26px; line-height: 1.2; letter-spacing: 1.2px; color: white;">
      {{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_START_DATE: managerService.language_pipe}}
    </div>
    <div
      *ngIf="managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_PUBLIC_SALE"
      [innerHTML]="(managerService.pairService.active_featured_pair?.id | managerPipe: managerService.mpa_template.SALE_STATE_TIME_COUNTDOWN: managerService.pairService.active_featured_pair?.sale_state) | async | managerPipe: managerService.mpa_template.TIME_FORMAT"
      class="w-100 d-flex flex-row align-items-center justify-content-around my-2"
      style=" font-family: bebas-neue-regular, sans-serif;
             font-size: 26px; line-height: 1.2; letter-spacing: 1.2px; color: white; padding-right: 60px;"></div>
  </div>

  <div *ngIf="managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SALE_FEATURING"
       class="w-100 d-flex flex-row align-items-center justify-content-center mt-5"
       style="height: 80px; box-shadow: 7px 10px 9px 0 rgba(0, 0, 0, 0.2); background-image: linear-gradient(100deg, #333546 9%, #282937 96%);">
    <div class="d-flex flex-column align-items-center justify-content-center mr-4"
         style="font-family: Roboto, sans-serif; font-size: 12px; font-weight:bold; color: white; letter-spacing: 1px;">
      <span
        *ngIf="(managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_SUPPLY_RATIO) !== 0">{{managerService.config.HOME.SLIDER.TOKENMETRICS.PRE_SALE_PRICE | managerPipe}}</span>
      <span
        *ngIf="(managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_SUPPLY_RATIO) === 0">{{managerService.config.HOME.SLIDER.TOKENMETRICS.PUBLIC_SALE_PRICE | managerPipe}}</span>
      <div class="d-flex flex-row align-items-center justify-content-center">
        <span class="pr-1 text-center"
              style="font-family: 'Roboto Condensed', sans-serif; font-size: 12px; color: white; line-height: 30px;">
        1 {{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SYMBOL}}:
        </span>
        <span class="pr-1 text-center"
              style="font-family: 'Roboto Condensed', sans-serif; font-size: 16px; font-weight: bold; letter-spacing: 1px; line-height: 30px;">
          <span
            *ngIf="(managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_SUPPLY_RATIO) !== 0">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_PRICE}}</span>
          <span
            *ngIf="(managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_SUPPLY_RATIO) === 0">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_PRICE}}</span>
        </span>
        <span class="text-center"
              style="font-family: 'Roboto Condensed', sans-serif; font-size: 16px; color: white; line-height: 30px;">
          {{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_CURRENCY_SYMBOL}}
        </span>
      </div>
    </div>
    <span
      style="height: 60px; width: 1px; min-width: 1px; margin: auto 10px; border-radius:1px; background-color: #dddddd;"></span>
    <div class="d-flex flex-column align-items-center justify-content-center ml-4"
         style="font-family: Roboto, sans-serif; font-size: 12px; font-weight:bold; color: white; letter-spacing: 1px;">
      <span
        *ngIf="(managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_SUPPLY_RATIO) !== 0">{{managerService.config.HOME.SLIDER.TOKENMETRICS.PRE_SALE_CIRCULATING_SUPPLY | managerPipe}}</span>
      <span
        *ngIf="(managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_SUPPLY_RATIO) === 0">{{managerService.config.HOME.SLIDER.TOKENMETRICS.PUBLIC_SALE_CIRCULATING_SUPPLY | managerPipe}}</span>
      <div class="d-flex flex-row align-items-center justify-content-center">
        <span class="pr-1 text-center"
              style="font-family: 'Roboto Condensed', sans-serif; font-size: 16px; font-weight: bold; letter-spacing: 1px; line-height: 30px;">
          <span
            *ngIf="(managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_SUPPLY_RATIO) !== 0">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_CIRCULATING_SUPPLY | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
          <span
            *ngIf="(managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_SUPPLY_RATIO) === 0">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_CIRCULATING_SUPPLY | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
        </span>
        <span class="pr-1 text-center"
              style="font-family: 'Roboto Condensed', sans-serif; font-size: 16px; color: white; line-height: 30px;">
        {{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SYMBOL}}
        </span>
      </div>
    </div>
  </div>
  <div class="w-100 d-flex flex-row align-items-center justify-content-between mt-5" style="margin-top: 40px;">
    <span
      style="font-family: bebas-neue-regular, sans-serif; font-size: 24px; line-height: 1.2; letter-spacing: 1px; color: #ffffff;">
    {{managerService.config.HOME.SLIDER.TOKENMETRICS.HEADING | managerPipe}}</span>
  </div>
  <div class="w-100 d-flex flex-column align-items-center justify-content-center p-4 mb-4"
       style="box-shadow: 7px 10px 9px 0 rgba(0, 0, 0, 0.2); background-image: linear-gradient(100deg, #333546 9%, #282937 96%);">
    <div class="w-100 d-flex flex-row mb-2"
         style="font-family: sans-serif-demibold-flf, sans-serif; font-size: 14px; line-height: 1.2; color: #ffffff;">
      <div class="w-50 text-right">{{managerService.config.PRE_SALE.TOKENMETRICS.NAME | managerPipe}}:</div>
      <div class="w-50 text-left pl-2"
           style="font-family: Roboto, sans-serif;">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_NAME}}</div>
    </div>
    <div class="w-100 d-flex flex-row mb-2"
         style="font-family: sans-serif-demibold-flf, sans-serif; font-size: 14px; line-height: 1.2; color: #ffffff;">
      <div class="w-50 text-right">{{managerService.config.PRE_SALE.TOKENMETRICS.SYMBOL | managerPipe}}:</div>
      <div class="w-50 text-left pl-2"
           style="font-family: Roboto, sans-serif;">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SYMBOL}}</div>
    </div>
    <div class="w-100 d-flex flex-row mb-2"
         style="font-family: sans-serif-demibold-flf, sans-serif; font-size: 14px; line-height: 1.2; color: #ffffff;">
      <div class="w-50 text-right">{{managerService.config.PRE_SALE.TOKENMETRICS.TOTAL_SUPPLY | managerPipe}}:</div>
      <div class="w-50 text-left pl-2"
           style="font-family: Roboto, sans-serif;">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_TOTAL_SUPPLY}}</div>
    </div>
    <div class="w-100 d-flex flex-row mb-2"
         style="font-family: sans-serif-demibold-flf, sans-serif; font-size: 14px; line-height: 1.2; color: #ffffff;">
      <div class="w-50 text-right">{{managerService.config.PRE_SALE.TOKENMETRICS.CIRCULATING_SUPPLY | managerPipe}}:
      </div>
      <div class="w-50 text-left pl-2"
           style="font-family: Roboto, sans-serif;">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_CIRCULATING_SUPPLY | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</div>
    </div>
    <div class="w-100 d-flex flex-row mb-2"
         style="font-family: sans-serif-demibold-flf, sans-serif; font-size: 14px; line-height: 1.2; color: #ffffff;">
      <div class="w-50 text-right">{{managerService.config.PRE_SALE.TOKENMETRICS.PRE_SALE_PRICE | managerPipe}}:</div>
      <div class="w-50 text-left pl-2"
           style="font-family: Roboto, sans-serif;">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_PRICE}}</div>
    </div>
    <div class="w-100 d-flex flex-row mb-2"
         style="font-family: sans-serif-demibold-flf, sans-serif; font-size: 14px; line-height: 1.2; color: #ffffff;">
      <div
        class="w-50 text-right">{{managerService.config.PRE_SALE.TOKENMETRICS.PRE_SALE_CIRCULATING_SUPPLY | managerPipe}}
        :
      </div>
      <div class="w-50 text-left pl-2"
           style="font-family: Roboto, sans-serif;">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_CIRCULATING_SUPPLY | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</div>
    </div>
    <div class="w-100 d-flex flex-row mb-2"
         style="font-family: sans-serif-demibold-flf, sans-serif; font-size: 14px; line-height: 1.2; color: #ffffff;">
      <div class="w-50 text-right">{{managerService.config.PRE_SALE.TOKENMETRICS.PUBLIC_SALE_PRICE | managerPipe}}:
      </div>
      <div class="w-50 text-left pl-2"
           style="font-family: Roboto, sans-serif;">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_PRICE}}₺
      </div>
    </div>
    <div class="w-100 d-flex flex-row mb-2"
         style="font-family: sans-serif-demibold-flf, sans-serif; font-size: 14px; line-height: 1.2; color: #ffffff;">
      <div
        class="w-50 text-right">{{managerService.config.PRE_SALE.TOKENMETRICS.PUBLIC_SALE_CIRCULATING_SUPPLY | managerPipe}}
        :
      </div>
      <div class="w-50 text-left pl-2"
           style="font-family: Roboto, sans-serif;">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_CIRCULATING_SUPPLY | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</div>
    </div>
    <div class="w-100 d-flex flex-row mb-2"
         style="font-family: sans-serif-demibold-flf, sans-serif; font-size: 14px; line-height: 1.2; color: #ffffff;">
      <div class="w-50 text-right">{{managerService.config.PRE_SALE.TOKENMETRICS.LISTING_DATE | managerPipe}}:</div>
      <div class="w-50 text-left pl-2"
           style="font-family: Roboto, sans-serif;">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_LISTING_DATE: managerService.language_pipe}}</div>
    </div>
    <div class="w-100 d-flex flex-row mb-2"
         style="font-family: sans-serif-demibold-flf, sans-serif; font-size: 14px; line-height: 1.2; color: #ffffff;">
      <div class="w-50 text-right">{{managerService.config.PRE_SALE.TOKENMETRICS.CONTRACT_ADDRESS | managerPipe}}:</div>
      <div class="w-50 text-left pl-2" style="font-family: Roboto, sans-serif;">
        <a [href]="managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_CONTRACT_ADDRESS_LINK" class="text-info"
           target="_blank" rel="noreferrer"
           type="button">
          {{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_CONTRACT_ADDRESS}}
        </a>
      </div>
    </div>
  </div>
</div>
