import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legal-user-agreement-desktop',
  templateUrl: './legal-user-agreement-desktop.component.html',
  styleUrls: ['./legal-user-agreement-desktop.component.css']
})
export class LegalUserAgreementDesktopComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
