import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    public modals: any[] = [];

    constructor() {
    }

    add(modal: any): void {
        this.modals.push(modal);
    }

    remove(id: string): void {
        this.modals = this.modals.filter(x => x.id !== id);
    }

    open(id: string): void {
        const modal = this.modals.find(x => x.id === id);
        modal.open();
    }

    close(id: string): void {
        const modal = this.modals.find(x => x.id === id);
        modal.close();
    }

    isActive(id: string): any {
        const modal = this.modals.find(x => x.id === id);
        return modal.isActive();
    }
}
