import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../services/manager.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    constructor(public managerService: ManagerService) {
    }

    ngOnInit(): void {
    }

}
