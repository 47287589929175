import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ManagerService} from '../../../../../../../services/manager.service';
import {HttpClient} from '@angular/common/http';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-market-trade-desktop',
    templateUrl: './market-trade-desktop.component.html',
    styleUrls: ['./market-trade-desktop.component.css']
})
export class MarketTradeDesktopComponent implements OnInit, AfterViewInit, OnDestroy {

    marketBuyForm = new UntypedFormGroup({
        amount: new UntypedFormControl(null, [Validators.required, Validators.min(0)]),
    });

    marketSellForm = new UntypedFormGroup({
        amount: new UntypedFormControl(null, [Validators.required, Validators.min(0)]),
    });

    public market_buy_average_cost: number | null = null;
    public market_sell_average_cost: number | null = null;

    public subscription_list: (Subscription | undefined)[] = [];

    constructor(public managerService: ManagerService, public httpClient: HttpClient) {
        this.subscription_list.push(this.managerService.pairService.active_pair_selected.subscribe(() => {
            this.marketBuyForm.get('amount')?.setValue(null);
            this.marketSellForm.get('amount')?.setValue(null);
        }));
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.subscription_list.push(this.marketBuyForm.get('amount')?.valueChanges.subscribe(() => {
            if (!(this.marketBuyForm.get('amount')?.value ?? 0).toString().match(new RegExp('^[0-9]*(\\.[0-9]{0,2})?$'))) {
                this.marketBuyForm.get('amount')?.setValue(this.managerService.preciseRound(this.marketBuyForm.get('amount')?.value));
                return;
            }

            if (this.managerService.userService.user_ready) {
                if (this.marketBuyForm.get('amount')?.value === null) {
                    this.market_buy_average_cost = null;
                    return;
                } else {
                    this.market_buy_average_cost = this.managerService.marketBuyAverageCost(this.marketBuyForm.get('amount')?.value);
                }
                const market_buy_amount_check = this.managerService.checkMarketBuyByAmount(this.marketBuyForm.get('amount')?.value);
                if (this.marketBuyForm.get('amount')?.value !== market_buy_amount_check[0]) {
                    this.marketBuyForm.get('amount')?.setValue(market_buy_amount_check[0]);
                }
            }
        }));

        this.subscription_list.push(this.marketSellForm.get('amount')?.valueChanges.subscribe(() => {
            if (!(this.marketSellForm.get('amount')?.value ?? 0).toString().match(new RegExp('^[0-9]*(\\.[0-9]{0,2})?$'))) {
                this.marketSellForm.get('amount')?.setValue(this.managerService.preciseRound(this.marketSellForm.get('amount')?.value));
                return;
            }

            if (this.managerService.userService.user_ready) {
                if (this.marketSellForm.get('amount')?.value === null) {
                    this.market_sell_average_cost = null;
                    return;
                } else {
                    this.market_sell_average_cost = this.managerService.marketSellAverageCost(this.marketSellForm.get('amount')?.value);
                }
                const market_sell_amount_check = this.managerService.checkMarketSellAverageCost(this.marketSellForm.get('amount')?.value);
                if (this.marketSellForm.get('amount')?.value !== market_sell_amount_check) {
                    this.marketSellForm.get('amount')?.setValue(market_sell_amount_check);
                }
            }
        }));
    }

    marketBuy(): void {
        if (!this.managerService.userService.user_ready) {
            // @ts-ignore
            const content = this.managerService.config.RESPONSE_TABLE['2001'];
            this.managerService.popSnackBar(content.is_okay, content.message);
            return;
        }
        if (this.marketBuyForm.invalid || this.market_buy_average_cost === null) {
            return;
        }

        const pair: any = this.managerService.pairService.active_pair;
        const amount: any = this.marketBuyForm.get('amount')?.value;
        const volume: any = this.market_buy_average_cost;

        const data = {
            pair_id: pair?.id,
            order_type: 'buy',
            amount: volume
        };

        if (!this.managerService.utilityService.requestCriticServicePermission('market_buy_service', data)){
            this.managerService.popResponseSnackbar({code: 2019, is_okay: false, message: 'Service not allowed in dev mode.'});
            return;
        }

        this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'market_buy_service', data).subscribe(
            response => {
                // @ts-ignore
                const content = this.managerService.config.RESPONSE_TABLE[response.code];
                this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));

                if (content.is_okay){
                    // this.managerService.eventLogger('market_buy');
                    this.managerService.marketingService.logEvent('trade', {type: 'buy', kind: 'market', view: 'advanced', symbol: pair?.symbol, amount, volume});
                }
            }));
        this.marketBuyForm.get('amount')?.setValue(null);
    }

    marketSell(): void {
        if (!this.managerService.userService.user_ready) {
            // @ts-ignore
            const content = this.managerService.config.RESPONSE_TABLE['2001'];
            this.managerService.popSnackBar(content.is_okay, content.message);
            return;
        }
        if (this.marketSellForm.invalid) {
            return;
        }

        const pair: any = this.managerService.pairService.active_pair;
        const amount: any = this.marketSellForm.get('amount')?.value;
        const volume: any = this.market_sell_average_cost;

        const data = {
            pair_id: pair?.id,
            order_type: 'sell',
            amount
        };

        if (!this.managerService.utilityService.requestCriticServicePermission('market_sell_service', data)){
            this.managerService.popResponseSnackbar({code: 2019, is_okay: false, message: 'Service not allowed in dev mode.'});
            return;
        }

        this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'market_sell_service', data).subscribe(
            response => {
                // @ts-ignore
                const content = this.managerService.config.RESPONSE_TABLE[response.code];
                this.managerService.popSnackBar(content.is_okay, content.message);

                if (content.is_okay){
                    // this.managerService.eventLogger('market_sell');
                    this.managerService.marketingService.logEvent('trade', {type: 'sell', kind: 'market', view: 'advanced', symbol: pair?.symbol, amount, volume});
                }
            }));
        this.marketSellForm.get('amount')?.setValue(null);
    }


    ngOnDestroy(): void {
        this.subscription_list.forEach((subscription) => {
            subscription?.unsubscribe();
        });
    }

}
