import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../services/manager.service';


@Component({
    selector: 'app-auction-payment-page',
    templateUrl: './auction-payment.component.html',
    styleUrls: ['./auction-payment.component.css']
})

export class AuctionPaymentComponent implements OnInit {
    constructor(public managerService: ManagerService) {
    }

    ngOnInit(): void {
    }
}
