import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import * as moment from 'moment';
import {environment} from '../../environments/environment';
import {UtilityService} from './utility.service';

export class Trade {
  public id: number;
  public buyer_id: number;
  public seller_id: number;
  public buy_order_id: number;
  public sell_order_id: number;
  public pair_id: [number, number];
  public amount: number;
  public price: number;
  public type: string;
  public creation_time: number;

  constructor(trade_primitive: any) {
    this.id = trade_primitive.id;
    this.buyer_id = trade_primitive.buyer_id;
    this.seller_id = trade_primitive.seller_id;
    this.buy_order_id = trade_primitive.buy_order_id;
    this.sell_order_id = trade_primitive.sell_order_id;
    this.pair_id = trade_primitive.pair_id;
    this.amount = trade_primitive.amount;
    this.price = trade_primitive.price;
    this.type = trade_primitive.type;
    this.creation_time = trade_primitive.creation_time / 1000000;
  }
}

export class TradeChartLineEntry {
  public time: number;
  public value: number;

  constructor(data_primitive: any, from_candlestick = false) {
    if (from_candlestick) {
      this.time = data_primitive.time / 1000000000 - new Date().getTimezoneOffset() * 60;
      this.value = data_primitive.close;
    } else {
      this.time = data_primitive.time / 1000000000 - new Date().getTimezoneOffset() * 60;
      this.value = data_primitive.value;
    }
  }
}

export class TradeChartCandlestickEntry {
  public time: number;
  public open: number;
  public close: number;
  public high: number;
  public low: number;
  public value: number;
  public color: string;

  constructor(data_primitive: any) {
    this.time = data_primitive.time / 1000000000 - new Date().getTimezoneOffset() * 60;
    this.open = data_primitive.open;
    this.close = data_primitive.close;
    this.high = data_primitive.high;
    this.low = data_primitive.low;
    this.value = data_primitive.volume;
    if (this.open > this.close) {
      this.color = '#F8C2C5';
    } else {
      this.color = '#D0EAD1';

    }
  }
}

export class TradeChartDepthBookEntry {
  public amount: number;
  public price: number;
  public type: string;

  constructor(data_primitive: any) {
    this.amount = data_primitive.amount;
    this.price = data_primitive.price;
    this.type = data_primitive.type;
  }
}


export class Swap {
  public id: number;
  public user_id: number;
  public order_id: number;
  public currency_pair_id: [number, number];
  public amount: number;
  public price: number;
  public type: string;
  public creation_time: number;

  constructor(swap_primitive: any) {
    this.id = swap_primitive.id;
    this.user_id = swap_primitive.user_id;
    this.order_id = swap_primitive.order_id;
    this.currency_pair_id = swap_primitive.currency_pair_id;
    this.amount = swap_primitive.amount;
    this.price = swap_primitive.price;
    this.type = swap_primitive.type;
    this.creation_time = swap_primitive.creation_time / 1000000;
  }
}

export enum ActiveTradeTypePanelTemplate {
  BUY,
  SELL,
}

export enum ActiveTradeKindPanelTemplate {
  LIMIT,
  MARKET,
}

@Injectable({
  providedIn: 'root'
})
export class TradeHistoryService {

  public trade_history_ready: boolean = false;
  public swap_history_ready: boolean = false;
  public trade_chart_ready: boolean = false;
  public trade_chart_depth_book_ready: boolean = false;
  public zoom_level: string = 'OneDay';

  @Output() zoom_level_changed: EventEmitter<any> = new EventEmitter();
  @Output() trade_history_changed: EventEmitter<any> = new EventEmitter();
  @Output() swap_history_changed: EventEmitter<any> = new EventEmitter();
  @Output() trade_chart_line_data_changed: EventEmitter<any> = new EventEmitter();
  @Output() trade_chart_candlestick_data_changed: EventEmitter<any> = new EventEmitter();
  @Output() trade_chart_depth_book_data_changed: EventEmitter<any> = new EventEmitter();

  public trade_history: Trade[] = [];
  public swap_history: Swap[] = [];
  public trade_chart_line_data: TradeChartLineEntry[] = [];
  public trade_chart_candlestick_data: TradeChartCandlestickEntry[] = [];
  public trade_chart_bids_depth_book_data: TradeChartDepthBookEntry[] = [];
  public trade_chart_asks_depth_book_data: TradeChartDepthBookEntry[] = [];

  public environment = environment;

  constructor(private httpClient: HttpClient) {
  }

  getTradeHistory(): Trade[] {
    return this.trade_history;
  }

  setTradeHistory(trade_history_primitive: any[]): void {
    this.trade_history = trade_history_primitive.map(x => new Trade(x));
  }

  getSwapHistory(): Swap[] {
    return this.swap_history;
  }

  setSwapHistory(swap_history_primitive: any[]): void {
    this.swap_history = swap_history_primitive.map(x => new Swap(x));
  }

  addTrade(trade_primitive: any): void {
    if (this.trade_history.length < 50) {
      this.trade_history.unshift(new Trade(trade_primitive));
    } else {
      this.trade_history.unshift(new Trade(trade_primitive));
      this.trade_history.pop();
    }
    this.trade_history_changed.emit();
  }

  addSwap(swap_primitive: any): void {
    if (this.swap_history.length < 50) {
      this.swap_history.unshift(new Swap(swap_primitive));
    } else {
      this.swap_history.unshift(new Swap(swap_primitive));
      this.swap_history.pop();
    }
    this.swap_history_changed.emit();
  }

  getTradeChartData(chart_type: string): TradeChartCandlestickEntry[] | TradeChartLineEntry[] {
    if (chart_type === 'Candlestick') {
      return this.trade_chart_candlestick_data;
    } else {
      return this.trade_chart_line_data;
    }
  }

  getTradeChartDataSVG(pair_id: [number, number]): Observable<string> {
    const data = {pair_id, zoom_level: 'SevenDays', limit: 16};
    return this.httpClient.post<any[]>(this.environment.serverAPI + 'get_trade_chart_data_service', data).pipe(map((response) => {
        response = response.map(x => new TradeChartLineEntry(x, true));
        let array = response.map(x => x.value).reverse().splice(Math.max(response.length - 16, 0));
        const max = Math.max(...array);
        const min = Math.min(...array);
        if (max !== min) {
          array = array.map(x => ((x - min) / (max - min)) * 80 + 10);
        } else {
          array = array.map(x => 50);
        }
        let result: string = '0, 100\n';
        for (let i = 0; i < array.length; i++) {
          result = result + (20 * i).toString() + ',' + (100 - array[i]).toString() + '\n';
        }
        result = result + (20 * (array.length - 1)).toString() + ', 100\n';
        return result;
      })
    );
  }

  setTradeChartData(trade_chart_data_primitive: any[]): void {
    this.trade_chart_line_data = trade_chart_data_primitive.map(x => new TradeChartLineEntry(x, true));
    if (this.trade_chart_line_data.length > 1) {
      this.trade_chart_line_data[0].value = 1;
    }
    this.trade_chart_candlestick_data = trade_chart_data_primitive.map(x => new TradeChartCandlestickEntry(x));
  }

  addTradeChartDatum(trade_primitive: any): void {
    this.trade_chart_line_data.push(new TradeChartLineEntry(trade_primitive, true));
    this.trade_chart_candlestick_data.push(new TradeChartCandlestickEntry(trade_primitive));
    this.trade_chart_line_data_changed.emit();
    this.trade_chart_candlestick_data_changed.emit();
  }

  mergeTradeChartDatum(trade_primitive: any): void {
    this.trade_chart_line_data[this.trade_chart_line_data.length - 1] = new TradeChartLineEntry(trade_primitive, true);
    this.trade_chart_candlestick_data[this.trade_chart_candlestick_data.length - 1] = new TradeChartCandlestickEntry(trade_primitive);
    this.trade_chart_line_data_changed.emit();
    this.trade_chart_candlestick_data_changed.emit();
  }

  getBidsDepthBookData(): TradeChartDepthBookEntry[] {
    return this.trade_chart_bids_depth_book_data;
  }

  getAsksDepthBookData(): TradeChartDepthBookEntry[] {
    return this.trade_chart_asks_depth_book_data;
  }


  setDepthBookData(depth_book_data_primitive: any[]): void {
    this.trade_chart_bids_depth_book_data = depth_book_data_primitive.filter(x => x.type === 'buy').map(x => new TradeChartDepthBookEntry(x));
    this.trade_chart_asks_depth_book_data = depth_book_data_primitive.filter(x => x.type === 'sell').map(x => new TradeChartDepthBookEntry(x));

  }

  getZoomLevel(): string {
    return this.zoom_level;
  }

  setZoomLevel(zoom_level: string): void {
    this.zoom_level = zoom_level;
    this.zoom_level_changed.emit();
  }

  refreshTradeHistory(pair_id: [number, number] | undefined, resolve?: any): any {
    const data = {pair_id, limit: 50};
    return this.httpClient.post<any[]>(this.environment.serverAPI + 'get_trade_history_service', data).subscribe(
      response => {
        UtilityService.bubbleSortByID(response);
        this.setTradeHistory(response);
        this.trade_history_ready = true;
        this.trade_history_changed.emit();
        if (resolve !== undefined) {
          resolve();
        }
      });
  }

  refreshSwapHistory(currency_pair_id: [number, number] | undefined, resolve?: any): any {
    const data = {currency_pair_id, limit: 50};
    return this.httpClient.post<any[]>(this.environment.serverAPI + 'get_swap_history_service', data).subscribe(
      response => {
        UtilityService.bubbleSortByID(response);
        this.setSwapHistory(response);
        this.swap_history_ready = true;
        this.swap_history_changed.emit();
        if (resolve !== undefined) {
          resolve();
        }
      });
  }

  refreshTradeChartData(pair_id: [number, number] | undefined, resolve?: any): any {
    const data = {pair_id, zoom_level: this.zoom_level, limit: 200};
    return this.httpClient.post<any[]>(this.environment.serverAPI + 'get_trade_chart_data_service', data).subscribe(
      response => {
        this.setTradeChartData(response.reverse());
        this.trade_chart_ready = true;
        this.trade_chart_line_data_changed.emit();
        this.trade_chart_candlestick_data_changed.emit();
        if (resolve !== undefined) {
          resolve();
        }
      });
  }

  refreshDepthBookData(pair_id: [number, number] | undefined, resolve?: any): any {
    const data = {pair_id, limit: 200};
    return this.httpClient.post<any[]>(this.environment.serverAPI + 'get_depth_book_service', data).subscribe(
      response => {
        this.setDepthBookData(response);
        this.trade_chart_depth_book_ready = true;
        this.trade_chart_depth_book_data_changed.emit();
        if (resolve !== undefined) {
          resolve();
        }
      });
  }
}
