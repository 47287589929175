<div class="w-80 m-auto px-4 pt-4" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 700;">Front-End Queries:</div>

<form [formGroup]="searchForm" class="w-75 m-auto px-4 pt-4 d-flex flex-row align-items-center justify-content-start"
      style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 700;">
  <div class="mx-2">
    <span class="m-1">ID: </span>
    <input class="m-1" formControlName="id" required type="number">
    <button (click)="adminService.searchID(searchForm); adminService.show_searched_users = true;" class="m-1" style="color: black">
      SEARCH BY ID
    </button>
  </div>
  <div class="mx-2">
    <span class="m-1">TC NO: </span>
    <input class="m-1" formControlName="tc_no" required type="text">
    <button (click)="adminService.searchTCNO(searchForm); adminService.show_searched_users = true;" class="m-1" style="color: black">
      SEARCH BY TC NO
    </button>
  </div>
  <div class="mx-2">
    <span class="m-1">CONCATENATED NAME FIELD: </span>
    <input class="m-1" formControlName="concatenated_name_field" required type="text">
    <button (click)="adminService.searchConcatenatedNameField(searchForm); adminService.show_searched_users = true;" class="m-1" style="color: black">
      SEARCH BY CONCATENATED NAME FIELD
    </button>
  </div>
  <button (click)="adminService.show_searched_users = false; adminService.show_online_users = false;" class="m-1" style="color: black">
    CLEAR
  </button>
  <button (click)="adminService.clearCachedUsers()" class="m-1" style="color: black">
    PURGE
  </button>
</form>
<div #admin_dashboard class="w-75 m-auto p-4">
  <span class="mx-2">USERS:</span>
  <div class="d-flex flex-row align-items-center justify-content-around py-1" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 700;">
    <span class="text-center" style="width: 10%; cursor: pointer;" (click)="this.adminService.show_online_users = !this.adminService.show_online_users">
      <span class="circle" [style.background-color]="this.adminService.show_online_users ? '#0437F2':'#FF2400'"></span>
      ID {{ adminService.online_user_id_list.length }} - {{ adminService.user_count }}
    </span>
    <span class="text-center" style="width: 15%">EMAIL</span>
    <span class="text-center" style="width: 10%">ACCOUNT LEVEL</span>
    <span class="text-center" style="width: 20%">IN ORDER BALANCE</span>
    <span class="text-center" style="width: 20%">AVAILABLE BALANCE</span>
    <span class="text-center" style="width: 20%">TOTAL BALANCE</span>
    <span class="text-center" style="width: 5%">DETAILS</span>
  </div>
  <div class="d-flex flex-column overflow-auto" style="min-height: 260px; max-height: 260px;">
    <div
      [class]="(adminService.show_online_users && !adminService.online_user_id_list.includes(user.id)) || (adminService.show_searched_users && !this.adminService.user_search_list.includes(user.id)) ? 'd-none':'d-flex'"
      *ngFor="let user of (this.adminService.user_list | managerPipe)"
      class="flex-row align-items-center justify-content-around py-1 row-separator" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
    <span class="text-center" style="width: 10%">
      <span class="circle" [style.background-color]="adminService.online_user_id_list.includes(user.id) ? '#0437F2':'#FF2400'"></span>
      {{ user.id | managerPipe }}
    </span>
      <span class="text-center" style="width: 15%">{{ user.email | managerPipe }}</span>
      <span class="text-center" style="width: 10%">{{ user.account_level | managerPipe }}</span>
      <span class="text-center" style="width: 20%">
        {{ adminService.getUserTotalArtProductTypeOfRootCurrency(user) - adminService.getUserAvailableArtProductTypeOfRootCurrency(user) + adminService.getUserTotalCurrencyTypeOfRootCurrency(user) - adminService.getUserAvailableCurrencyTypeOfRootCurrency(user) | managerPipe: managerService.mpa_template.FANCY_NUMBER }}
      </span>
      <span class="text-center" style="width: 20%">
        {{ adminService.getUserAvailableArtProductTypeOfRootCurrency(user) + adminService.getUserAvailableCurrencyTypeOfRootCurrency(user) | managerPipe: managerService.mpa_template.FANCY_NUMBER }}
      </span>
      <span class="text-center" style="width: 20%">
        {{ adminService.getUserTotalArtProductTypeOfRootCurrency(user) + adminService.getUserTotalCurrencyTypeOfRootCurrency(user) | managerPipe: managerService.mpa_template.FANCY_NUMBER }}
      </span>
      <span
        (click)="adminService.selected_user=user; executeCurrencyDepositForm.get('user_id')?.setValue(user.id); adminService.selected_user_info=adminService.getCachedUserInfo(user.id); user_dashboard.classList.remove('d-none'); admin_dashboard.classList.add('d-none');"
        class="text-center"
        style="width: 5%; cursor: pointer;">DETAIL</span>
    </div>
  </div>
</div>

<div #user_dashboard class="w-75 d-none m-auto p-4">
  <div class="d-flex flex-row align-items-center justify-content-around py-1" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 700;">
    <span class="text-center" style="width: 10%">
      <span class="circle" [style.background-color]="this.adminService.show_online_users ? '#0437F2':'#FF2400'"></span>
      ID: {{ adminService.selected_user.id }}
    </span>
    <span class="text-center" style="width: 15%">EMAIL: {{ adminService.selected_user.email }}</span>
    <span class="text-center" style="width: 10%">ACCOUNT LEVEL: {{ adminService.selected_user.account_level }}</span>
    <span class="text-center"
          style="width: 20%">IN ORDER BALANCE: {{ adminService.getUserTotalArtProductTypeOfRootCurrency(adminService.selected_user) - adminService.getUserAvailableArtProductTypeOfRootCurrency(adminService.selected_user) + adminService.getUserTotalCurrencyTypeOfRootCurrency(adminService.selected_user) - adminService.getUserAvailableCurrencyTypeOfRootCurrency(adminService.selected_user) | managerPipe: managerService.mpa_template.FANCY_NUMBER }}</span>
    <span class="text-center"
          style="width: 20%">AVAILABLE BALANCE: {{ adminService.getUserAvailableArtProductTypeOfRootCurrency(adminService.selected_user) + adminService.getUserAvailableCurrencyTypeOfRootCurrency(adminService.selected_user) | managerPipe: managerService.mpa_template.FANCY_NUMBER }}</span>
    <span class="text-center"
          style="width: 20%">TOTAL BALANCE: {{ adminService.getUserTotalArtProductTypeOfRootCurrency(adminService.selected_user) + adminService.getUserTotalCurrencyTypeOfRootCurrency(adminService.selected_user) | managerPipe: managerService.mpa_template.FANCY_NUMBER }}</span>
    <span (click)="admin_dashboard.classList.remove('d-none'); user_dashboard.classList.add('d-none');" class="text-center" style="width: 5%; cursor: pointer;">BACK</span>
  </div>
  <div class="d-flex flex-column overflow-auto" style="min-height: 260px; max-height: 260px;">
    <div *ngFor="let user_asset of (adminService.getUserAssets(adminService.selected_user) | managerPipe)"
         class="d-flex flex-row align-items-center justify-content-around py-1 row-separator" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
      <span class="text-center"
            style="width: 10%"></span>
      <span class="text-center"
            style="width: 15%"></span>
      <span class="text-center"
            style="width: 10%">{{ user_asset | managerPipe: managerService.mpa_template.GET_USER_ASSETS_SYMBOL }}</span>
      <span class="text-center"
            style="width: 20%">{{ user_asset.total_amount - user_asset.available_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER }}</span>
      <span class="text-center"
            style="width: 20%">{{ user_asset.available_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER }}</span>
      <span class="text-center"
            style="width: 20%">{{ user_asset.total_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER }}</span>
      <span class="text-center"
            style="width: 5%"></span>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-around py-1 row-separator"
         style="height: 26px; font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
      <span class="text-center"
            style="width: 10%">USER INFO:</span>
      <span class="text-center"
            style="width: 15%">{{ adminService.selected_user_info.tc_no | managerPipe }}</span>
      <span class="text-center"
            style="width: 10%">{{ adminService.selected_user_info.town | managerPipe }}</span>
      <span class="text-center"
            style="width: 20%">{{ adminService.selected_user_info.name | managerPipe }}</span>
      <span class="text-center"
            style="width: 20%">{{ adminService.selected_user_info.surname | managerPipe }}</span>
      <span class="text-center"
            style="width: 10%">{{ adminService.selected_user_info.birth_year | managerPipe }}</span>
      <span class="text-center"
            style="width: 10%">{{ adminService.selected_user.deposit_reference_code | managerPipe }}</span>
      <button (click)="adminService.getUserInfo(adminService.selected_user_info.id)" class="px-3" style="width: 10%; color: black">Get User Info</button>
    </div>
    <form [formGroup]="executeCurrencyDepositForm" class="d-flex flex-row align-items-center justify-content-around py-1 row-separator"
          style="height: 26px; font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
      <span class="text-center"
            style="width: 10%"></span>
      <span class="text-center"
            style="width: 15%">{{ adminService.selected_user_info.id | managerPipe: managerService.mpa_template.ADMIN_GET_USER_PLATFORM: adminService }}</span>
      <span class="text-center"
            style="width: 10%">{{ adminService.selected_user_info.id | managerPipe }}</span>
      <div style="height: 26px; width: 20%;" class="d-flex flex-row align-items-center justify-content-center">
        <span class="text-center m-1">CURRENCY: </span>
        <select style="height: 20px;" formControlName="currency_id" required type="number">
          <option value="-1">Select</option>
          <option *ngFor="let currency of adminService.currency_list | managerPipe" [value]="currency.id">{{ currency.symbol | managerPipe }}</option>
        </select>
      </div>
      <div style="height: 26px; width: 20%;" class="d-flex flex-row align-items-center justify-content-center">
        <span class="text-center m-1">AMOUNT: </span>
        <input style="height: 20px;" formControlName="amount" required type="number">
      </div>
      <span class="text-center" style="width: 5%"></span>
      <button (click)="adminService.executeCurrencyDeposit(executeCurrencyDepositForm)" class="px-3" style="width: 10%; color: black">DEPOSIT</button>
      <button (click)="adminService.refreshUser(adminService.selected_user.id)" class="px-3" style="width: 10%; color: black">Refresh User</button>
    </form>
  </div>
</div>

<form [formGroup]="getUsersForm" class="w-75 m-auto px-4 pt-4 d-flex flex-column align-items-center justify-content-center"
      style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 700;">
  <div>
    <span class="m-1">Start: </span>
    <input class="m-1" formControlName="start_index" required type="number">
    <span class="m-1">End: </span>
    <input class="m-1" formControlName="end_index" required type="number">
    <button (click)="adminService.getUsersAndInfo(getUsersForm);" class="m-1" style="color: black">
      Refresh Users In Between
    </button>
  </div>
</form>


<div class="w-75 m-auto px-4 pt-3" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 700;">Back-End Queries:</div>

<form [formGroup]="queryForm" class="w-75 m-auto px-4 pb-5 d-flex flex-column align-items-start justify-content-start"
      style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 700;">
  <div class="mx-2 my-1">
    <span class="d-inline-block m-1 text-right" style="width: 140px">ID: </span>
    <input class="m-1" formControlName="user_id" required type="number">
    <button (click)="adminService.queryUserByID(queryForm);" class="m-1" style="color: black; width: 280px;">
      SEARCH RAW USER BY ID
    </button>
    <button (click)="adminService.getUserInfoByID(queryForm);" class="m-1" style="color: black; width: 280px;">
      SEARCH RAW USER INFO BY ID
    </button>
  </div>
  <div class="mx-2 my-1">
    <span class="d-inline-block m-1 text-right" style="width: 140px">CONCATENATED NAME: </span>
    <input class="m-1" formControlName="concatenated_name_field" required type="text">
    <button (click)="adminService.queryUserByConcatenatedNameField(queryForm);" class="m-1" style="color: black; width: 280px;">
      SEARCH RAW USER BY CONCATENATED NAME
    </button>
  </div>
  <div class="mx-2 my-1">
    <span class="d-inline-block m-1 text-right" style="width: 140px">REFERENCE CODE: </span>
    <input class="m-1" formControlName="deposit_reference_code" required type="number">
    <button (click)="adminService.queryUserByReferenceCode(queryForm);" class="m-1" style="color: black; width: 280px;">
      SEARCH RAW USER BY REFERENCE CODE
    </button>
  </div>
  <!--  todo SEARCH RAW USER BY TC NO -->
</form>

<div #user_search_result class="w-66 m-auto p-4 d-none"
     style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
  <div class="d-flex flex-row row-separator p-1">
    <span style="width: 50%">id: {{ adminService.searched_user_raw?.id | managerPipe }}</span>
    <span style="width: 50%">email: {{ adminService.searched_user_raw?.email | managerPipe }}</span>
  </div>
  <div class="d-flex flex-row row-separator p-1">
    <span style="width: 50%">account_level: {{ adminService.searched_user_raw?.account_level | managerPipe }}</span>
    <span style="width: 50%">vip: {{ adminService.searched_user_raw?.vip | managerPipe }}</span>
  </div>
  <div class="d-flex flex-row row-separator p-1">
    <span style="width: 50%">account_verified: {{ adminService.searched_user_raw?.account_verified | managerPipe }}</span>
    <span style="width: 50%">kyc_verified: {{ adminService.searched_user_raw?.kyc_verified | managerPipe }}</span>
  </div>
  <div class="row-separator p-1">
    <div class="d-inline-block" style="width: 80%">art_product_list:</div>
    <div class="d-inline-block" style="width: 20%; text-align: center" (click)="user_search_result_art_products.classList.toggle('d-none')">More</div>
    <div #user_search_result_art_products class="d-none p-3">
      <div *ngFor="let art_product of adminService.searched_user_raw?.art_product_list | managerPipe" class="row-separator px-1">
        <div class="d-inline-block" style="width: 20%">{{ managerService.pairService.getArtProductById(art_product.art_product_id)?.symbol | managerPipe }}</div>
        <div class="d-inline-block" style="width: 40%">available_amount: {{ art_product.available_amount | managerPipe }}</div>
        <div class="d-inline-block" style="width: 40%">total_amount: {{ art_product.total_amount | managerPipe }}</div>
      </div>
    </div>
  </div>
  <div class="row-separator p-1">
    <div class="d-inline-block" style="width: 80%">adminService.currency_list:</div>
    <div class="d-inline-block" style="width: 20%; text-align: center" (click)="user_search_result_currencies.classList.toggle('d-none')">More</div>
    <div #user_search_result_currencies class="d-none p-3">
      <div *ngFor="let currency of adminService.searched_user_raw?.currency_list | managerPipe" class="row-separator px-1">
        <div class="d-inline-block" style="width: 20%">{{ managerService.pairService.getCurrencyById(currency.currency_id)?.symbol | managerPipe }}</div>
        <div class="d-inline-block" style="width: 40%">available_amount: {{ currency.available_amount | managerPipe }}</div>
        <div class="d-inline-block" style="width: 40%">total_amount: {{ currency.total_amount | managerPipe }}</div>
      </div>
    </div>
  </div>
  <div class="row-separator p-1">
    <div class="d-inline-block" style="width: 80%">pair_list:</div>
    <div class="d-inline-block" style="width: 20%; text-align: center" (click)="user_search_result_pairs.classList.toggle('d-none')">More</div>
    <div #user_search_result_pairs class="d-none p-3">
      <div *ngFor="let pair of adminService.searched_user_raw?.pair_list | managerPipe" class="row-separator px-1">
        <div class="d-inline-block" style="width: 40%">{{ managerService.pairService.getPairById(pair.pair_id)?.symbol | managerPipe }}</div>
        <div class="d-inline-block" style="width:60%">is_favorite: {{ pair.is_favorite | managerPipe }}</div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row row-separator p-1">
    <span style="width: 50%">second_level_kyc_verified: {{ adminService.searched_user_raw?.second_level_kyc_verified | managerPipe }}</span>
    <span style="width: 50%">second_level_kyc_status: {{ adminService.searched_user_raw?.second_level_kyc_status | managerPipe }}</span>
  </div>
  <div class="d-flex flex-row row-separator p-1">
    <span style="width: 50%">two_factor_auth: {{ adminService.searched_user_raw?.two_factor_auth | managerPipe }}</span>
    <span style="width: 50%">creation_time: {{ adminService.searched_user_raw?.creation_time / 1000000 | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME }}</span>
  </div>
  <div class="d-flex flex-row row-separator p-1">
    <span style="width: 50%">daily_deposit_limit: {{ adminService.searched_user_raw?.daily_deposit_limit | managerPipe }}</span>
    <span style="width: 50%">daily_withdrawal_limit: {{ adminService.searched_user_raw?.daily_withdrawal_limit | managerPipe }}</span>
  </div>
  <div class="d-flex flex-row row-separator p-1">
    <span style="width: 50%">monthly_deposit_limit: {{ adminService.searched_user_raw?.monthly_deposit_limit | managerPipe }}</span>
    <span style="width: 50%">monthly_withdrawal_limit: {{ adminService.searched_user_raw?.monthly_withdrawal_limit | managerPipe }}</span>
  </div>
  <div class="d-flex flex-row row-separator p-1">
    <span style="width: 50%">deposit_reference_code: {{ adminService.searched_user_raw?.deposit_reference_code | managerPipe }}</span>
    <span style="width: 50%">open_tooltip: {{ adminService.searched_user_raw?.open_tooltip | managerPipe }}</span>
  </div>
</div>

<div #user_info_search_result class="w-66 m-auto p-4 d-none"
     style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
  <div class="d-flex flex-row row-separator p-1">
    <span style="width: 50%">id: {{ adminService.searched_user_info_raw?.id | managerPipe }}</span>
    <span style="width: 50%">tc_no: {{ adminService.searched_user_info_raw?.tc_no | managerPipe }}</span>
  </div>
  <div class="d-flex flex-row row-separator p-1">
    <span style="width: 50%">name: {{ adminService.searched_user_info_raw?.name | managerPipe }}</span>
    <span style="width: 50%">surname: {{ adminService.searched_user_info_raw?.surname | managerPipe }}</span>
  </div>
  <div class="d-flex flex-row row-separator p-1">
    <span style="width: 50%">birth_year: {{ adminService.searched_user_info_raw?.birth_year | managerPipe }}</span>
    <span style="width: 50%">town: {{ adminService.searched_user_info_raw?.town | managerPipe }}</span>
  </div>
  <div class="d-flex flex-row row-separator p-1">
    <!--    <span>address: <span style="font-weight: normal;">{{adminService.searched_user_info_raw?.address | managerPipe}}</span></span>-->
    <span style="width: 50%; font-weight: normal;">address: {{ adminService.searched_user_info_raw?.address | managerPipe }}</span>
    <span style="width: 50%">phone_number: {{ adminService.searched_user_info_raw?.phone_number | managerPipe }}</span>
  </div>
</div>

<div #concatenated_name_search_result class="w-66 m-auto p-4 d-none"
     style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
  <div *ngIf="adminService.concatenated_name_list_raw.length === 0 | managerPipe" class="text-center">Not found</div>
  <div *ngFor="let user of adminService.concatenated_name_list_raw | managerPipe" class="row-separator p-1">
    <div class="row-separator px-1">
      <div class="d-inline-block" style="width: 40%">id: {{ user?.id | managerPipe }}</div>
      <div class="d-inline-block" style="width: 40%">tc_no: {{ user?.tc_no | managerPipe }}</div>
      <div class="d-inline-block" style="width: 20%; text-align: center" (click)="concatenated_name_search_more.classList.toggle('d-none')">More</div>
    </div>
    <div #concatenated_name_search_more class="d-none p-3">
      <div class="row-separator px-1">
        <div class="d-inline-block" style="width: 50%">name: {{ user?.name | managerPipe }}</div>
        <div class="d-inline-block" style="width: 50%">surname: {{ user?.surname | managerPipe }}</div>
      </div>
      <div class="row-separator px-1">
        <div class="d-inline-block" style="width: 50%">birth_year: {{ user?.birth_year | managerPipe }}</div>
        <div class="d-inline-block" style="width: 50%">town: {{ user?.town | managerPipe }}</div>
      </div>
      <div class="row-separator px-1">
        <span>address: <span style="font-weight: normal;">{{ user?.address | managerPipe }}</span></span>
      </div>
    </div>
  </div>
</div>

<div class="w-75 m-auto p-4">
  <span class="mx-2">CURRENCY WIHDRAWAL REQUESTS:</span>
  <div class="d-flex flex-row align-items-center justify-content-around py-1" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 700;">
    <span class="text-center" style="width: 7.5%">COPY</span>
    <span class="text-center" style="width: 7.5%">TC NO</span>
    <span class="text-center" style="width: 5%">USER ID</span>
    <span class="text-center" style="width: 15%">CREATION TIME</span>
    <span class="text-center" style="width: 5%">STATUS</span>
    <span class="text-center" style="width: 7.5%">NAME</span>
    <span class="text-center" style="width: 7.5%">SURNAME</span>
    <span class="text-center" style="width: 7.5%">AMOUNT</span>
    <span class="text-center" style="width: 10%">BANK</span>
    <span class="text-center" style="width: 20%">IBAN</span>
    <span class="text-center" style="width: 7.5%">APPROVE</span>
  </div>
  <div class="d-flex flex-column overflow-auto" style="min-height: 260px; max-height: 260px;">
    <div *ngFor="let currency_withdrawal_request of (adminService.lower_currency_withdrawal_request_list | managerPipe)"
         class="d-flex flex-row align-items-center justify-content-around py-1 row-separator" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
      <button (click)="adminService.copyCurrencyWithdrawalRequest(currency_withdrawal_request)" class="text-center" style="width: 7.5%; color: black;">COPY</button>
      <span class="text-center" style="width: 7.5%;">{{ currency_withdrawal_request.tc_no | managerPipe }}</span>
      <span class="text-center" style="width: 5%">{{ currency_withdrawal_request.user_id | managerPipe }}</span>
      <span class="text-center" style="width: 15%">{{ currency_withdrawal_request.creation_time | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME }}</span>
      <span class="text-center" style="width: 5%">{{ currency_withdrawal_request.status | managerPipe }}</span>
      <span class="text-center" style="width: 7.5%">{{ currency_withdrawal_request.name | managerPipe }}</span>
      <span class="text-center" style="width: 7.5%">{{ currency_withdrawal_request.surname | managerPipe }}</span>
      <span class="text-center" style="width: 7.5%">{{ currency_withdrawal_request.amount | managerPipe }}</span>
      <span class="text-center" style="width: 10%">{{ currency_withdrawal_request.bank | managerPipe }}</span>
      <span class="text-center" style="width: 20%">{{ currency_withdrawal_request.iban | managerPipe }}</span>
      <button (click)="adminService.executeCurrencyWithdrawal(currency_withdrawal_request)" class="text-center" style="width: 7.5%; color: black;">APPROVE</button>
    </div>

    <div class="my-3" style="background: #9DA5B7;border-bottom: #9DA5B7 1px solid;"></div>

    <div *ngFor="let currency_withdrawal_request of (adminService.higher_currency_withdrawal_request_list | managerPipe)"
         class="d-flex flex-row align-items-center justify-content-around py-1 row-separator" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
      <button (click)="adminService.copyCurrencyWithdrawalRequest(currency_withdrawal_request)" class="text-center" style="width: 7.5%; color: black;">COPY</button>
      <span class="text-center" style="width: 7.5%;">{{ currency_withdrawal_request.tc_no | managerPipe }}</span>
      <span class="text-center" style="width: 5%">{{ currency_withdrawal_request.user_id | managerPipe }}</span>
      <span class="text-center" style="width: 15%">{{ currency_withdrawal_request.creation_time | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME }}</span>
      <span class="text-center" style="width: 5%">{{ currency_withdrawal_request.status | managerPipe }}</span>
      <span class="text-center" style="width: 7.5%">{{ currency_withdrawal_request.name | managerPipe }}</span>
      <span class="text-center" style="width: 7.5%">{{ currency_withdrawal_request.surname | managerPipe }}</span>
      <span class="text-center" style="width: 7.5%">{{ currency_withdrawal_request.amount | managerPipe }}</span>
      <span class="text-center" style="width: 10%">{{ currency_withdrawal_request.bank | managerPipe }}</span>
      <span class="text-center" style="width: 20%">{{ currency_withdrawal_request.iban | managerPipe }}</span>
      <button (click)="adminService.executeCurrencyWithdrawal(currency_withdrawal_request)" class="text-center" style="width: 7.5%; color: black;">APPROVE</button>
    </div>
  </div>
</div>

<div class="w-75 m-auto p-4 mb-5">
  <span class="mx-2">SECOND LEVEL KYC REQUESTS:</span>
  <div class="d-flex flex-row align-items-center justify-content-around py-1" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 700;">
    <span class="text-center" style="width: 7.5%">REJECT</span>
    <span class="text-center" style="width: 10%"></span>
    <span class="text-center" style="width: 5%">ID</span>
    <span class="text-center" style="width: 20%">EMAIL</span>
    <span class="text-center" style="width: 10%">STATUS</span>
    <span class="text-center" style="width: 10%">TC NO</span>
    <span class="text-center" style="width: 10%">NAME</span>
    <span class="text-center" style="width: 10%">SURNAME</span>
    <span class="text-center" style="width: 10%"></span>
    <span class="text-center" style="width: 7.5%">APPROVE</span>
  </div>
  <div class="d-flex flex-column overflow-auto" style="min-height: 260px; max-height: 260px;">
    <div *ngFor="let second_level_kyc_appeal of (adminService.second_level_kyc_appeal_list | managerPipe)"
         class="d-flex flex-row align-items-center justify-content-around py-1 row-separator" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
      <button (click)="adminService.executeSecondLevelKYC(second_level_kyc_appeal, 'rejected')" class="text-center" style="width: 7.5%; color: black;">REJECT</button>
      <span class="text-center" style="width: 10%"></span>
      <span class="text-center" style="width: 5%">{{ second_level_kyc_appeal.id | managerPipe }}</span>
      <span class="text-center" style="width: 20%">{{ second_level_kyc_appeal.email | managerPipe }}</span>
      <span class="text-center" style="width: 10%">{{ second_level_kyc_appeal.second_level_kyc_status | managerPipe }}</span>
      <span class="text-center"
            style="width: 10%">{{ (second_level_kyc_appeal.id | managerPipe: managerService.mpa_template.ADMIN_GET_CACHED_USER_INFO: adminService: adminService.user_info_list_pipe)?.tc_no | managerPipe }}</span>
      <span class="text-center"
            style="width: 10%">{{ (second_level_kyc_appeal.id | managerPipe: managerService.mpa_template.ADMIN_GET_CACHED_USER_INFO: adminService: adminService.user_info_list_pipe)?.name | managerPipe }}</span>
      <span class="text-center"
            style="width: 10%">{{ (second_level_kyc_appeal.id | managerPipe: managerService.mpa_template.ADMIN_GET_CACHED_USER_INFO: adminService: adminService.user_info_list_pipe)?.surname | managerPipe }}</span>
      <span class="text-center" style="width: 10%"></span>
      <button (click)="adminService.executeSecondLevelKYC(second_level_kyc_appeal, 'verified')" class="text-center" style="width: 7.5%; color: black;">APPROVE</button>
    </div>
  </div>
</div>


<div class="w-75 m-auto p-4">
  <div class="d-flex flex-row align-items-center justify-content-between ms-2">
    <span>DELIVERIES:</span>
    <button (click)="adminService.toggleActiveDeliveryList();" class="text-center " style="width: 10%; font-family: Roboto, sans-serif; font-size: 12px; color: black;">FILTER</button>
  </div>
  <div class="d-flex flex-column overflow-auto" style="min-height: 260px; max-height: 260px;">
    <div *ngFor="let delivery of (adminService.active_delivery_list | managerPipe: managerService.mpa_template.IDENTITY: adminService.delivery_list_pipe)" class="py-1 row-separator"
         style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
      <div class="d-flex flex-row align-items-center justify-content-start ps-2 ">
        <span class="px-1 text-start" style="width: 20%">DELIVERY ID: {{ delivery.delivery_id | managerPipe }}</span>
        <span class="px-1 text-start" style="width: 20%">USER ID: {{ delivery.user_id | managerPipe }}</span>
        <span class="px-1 text-start" style="width: 20%">AUC PROD ID: {{ delivery.auction_product_id | managerPipe }}</span>
        <span class="px-1 text-start" style="width: 20%">HASH: {{ (delivery.auction_product_id | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_BY_ID).hash }}</span>
        <div class="flex-grow-1"></div>
        <button (click)="changeDeliveryForm.get('delivery_id')?.setValue(delivery.delivery_id);" class="text-center" style="width: 10%; color: black;">UPDATE</button>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-start ps-2 ">
        <span class="px-1 text-start" style="width: 20%">STATUS: {{ delivery.status | managerPipe }}</span>
        <span class="px-1 text-start" style="width: 20%">TYPE: {{ delivery.type | managerPipe }}</span>
        <span class="px-1 text-start" style="width: 25%">CREATION TIME: {{ delivery.creation_time / 1000000 | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME }}</span>
      </div>

      <div class="d-flex flex-row align-items-center justify-content-start ps-2 mt-1">
        <div class="d-inline-block px-1 text-start" style="width: 80%">DELIVERY ADDRESS DETAILS:</div>
        <div class="d-inline-block" style="width: 20%; text-align: center" (click)="delivery_address_details.classList.toggle('d-none')">More</div>
      </div>
      <div #delivery_address_details class="d-none px-3 py-1">
        <div class="d-flex flex-row align-items-center justify-content-start row-separator-dark">
          <span class="px-1 text-start" style="width: 20%">ID: {{ delivery.delivery_address.address_id | managerPipe }}</span>
          <span class="px-1 text-start" style="width: 20%">NAME: {{ delivery.delivery_address.name | managerPipe }}</span>
          <span class="px-1 text-start" style="width: 20%">SURNAME: {{ delivery.delivery_address.surname | managerPipe }}</span>
          <span class="px-1 text-start" style="width: 20%">TC NO: {{ delivery.delivery_address.tc_no | managerPipe }}</span>
          <span class="px-1 text-start" style="width: 20%">PHONE NO: {{ delivery.delivery_address.phone_number | managerPipe }}</span>
        </div>
        <div class="d-flex flex-row align-items-center justify-content-start row-separator-dark">
          <span class="px-1 text-start" style="width: 20%">LABEL: {{ delivery.delivery_address.label | managerPipe }}</span>
        </div>
        <div class="d-flex flex-row align-items-center justify-content-start row-separator-dark">
          <span class="px-1 text-start font-weight-normal" style="width: 60%">ADDRESS: {{ delivery.delivery_address.address | managerPipe }}</span>
          <span class="px-1 text-start" style="width: 20%">DISTRICT: {{ delivery.delivery_address.district | managerPipe }}</span>
          <span class="px-1 text-start" style="width: 20%">PROVINCE: {{ delivery.delivery_address.province | managerPipe }}</span>
        </div>
      </div>

      <div class="d-flex flex-row align-items-center justify-content-start ps-2">
        <div class="d-inline-block px-1 text-start" style="width: 80%">BILLING ADDRESS DETAILS:</div>
        <div class="d-inline-block" style="width: 20%; text-align: center" (click)="billing_address_details.classList.toggle('d-none')">More</div>
      </div>
      <div #billing_address_details class="d-none px-3 py-1">
        <div class="d-flex flex-row align-items-center justify-content-start row-separator-dark">
          <span class="px-1 text-start" style="width: 20%">ID: {{ delivery.billing_address.address_id | managerPipe }}</span>
          <span class="px-1 text-start" style="width: 20%">NAME: {{ delivery.billing_address.name | managerPipe }}</span>
          <span class="px-1 text-start" style="width: 20%">SURNAME: {{ delivery.billing_address.surname | managerPipe }}</span>
          <span class="px-1 text-start" style="width: 20%">TC NO: {{ delivery.billing_address.tc_no | managerPipe }}</span>
          <span class="px-1 text-start" style="width: 20%">PHONE NO: {{ delivery.billing_address.phone_number | managerPipe }}</span>
        </div>
        <div class="d-flex flex-row align-items-center justify-content-start row-separator-dark">
          <span class="px-1 text-start" style="width: 20%">LABEL: {{ delivery.billing_address.label | managerPipe }}</span>
          <span class="px-1 text-start" style="width: 20%">TYPE: {{ delivery.billing_address.address_type | managerPipe }}</span>
          <span class="px-1 text-start" style="width: 20%">COMPANY: {{ delivery.billing_address.company_name | managerPipe }}</span>
          <span class="px-1 text-start" style="width: 20%">TAX ADMINISTRATION: {{ delivery.billing_address.tax_administration | managerPipe }}</span>
          <span class="px-1 text-start" style="width: 20%">TAX NUMBER: {{ delivery.billing_address.tax_number | managerPipe }}</span>
        </div>
        <div class="d-flex flex-row align-items-center justify-content-start row-separator-dark">
          <span class="px-1 text-start font-weight-normal" style="width: 60%">ADDRESS: {{ delivery.billing_address.address | managerPipe }}</span>
          <span class="px-1 text-start" style="width: 20%">DISTRICT: {{ delivery.billing_address.district | managerPipe }}</span>
          <span class="px-1 text-start" style="width: 20%">PROVINCE: {{ delivery.billing_address.province | managerPipe }}</span>
        </div>
      </div>

      <div class="d-flex flex-row align-items-center justify-content-start ps-2">
        <div class="d-inline-block px-1 text-start" style="width: 80%">CARGO DETAILS:</div>
        <div class="d-inline-block" style="width: 20%; text-align: center" (click)="cargo_details.classList.toggle('d-none')">More</div>
      </div>
      <div #cargo_details class="d-none px-3 py-1">
        <div class="d-flex flex-row align-items-center justify-content-start row-separator-dark">
          <span class="px-1 text-start" style="width: 20%">COMPANY: {{ delivery.company | managerPipe }}</span>
          <span class="px-1 text-start" style="width: 20%">TRACKING NO: {{ delivery.tracking_number | managerPipe }}</span>
          <span class="px-1 text-start" style="width: 20%">TRACKING STATUS: {{ delivery.tracking_status | managerPipe }}</span>
        </div>
        <div class="d-flex flex-row align-items-center justify-content-start row-separator-dark">
          <span class="px-1 text-start" style="width: 20%">E.T.A.: {{ delivery.estimated_arrival_time | managerPipe }}</span>
          <span (click)="managerService.navigationManager({url: delivery.tracking_link, is_inner: false, target: '_blank'})" class="px-1 text-start cursor-pointer"
                style="width: 80%">TRACKING LINK: {{ delivery.tracking_link | managerPipe }}</span>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="w-75 m-auto p-4 mb-5">
  <span class="mx-2">UPDATE DELIVERY:</span>
  <div class="d-flex flex-column overflow-auto">
    <form [formGroup]="changeDeliveryForm">
      <div class="d-flex flex-row align-items-center justify-content-around py-1 row-separator"
           style="height: 26px; font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
        <div class="d-flex flex-row align-items-center justify-content-start" style="width: 30%;">
          <div class="px-2 d-flex flex-row align-items-center justify-content-center" style="height: 26px; width: 33%;">
            <span class="text-center m-1">ID: </span>
            <select formControlName="delivery_id" required style="height: 20px;" type="number">
              <option *ngFor="let delivery of (adminService.delivery_list | managerPipe)" [ngValue]="delivery.delivery_id">{{ delivery.delivery_id | managerPipe }}</option>
            </select>
          </div>
        </div>
        <div class="px-2 d-flex flex-row align-items-center justify-content-between" style="height: 26px; width: 30%;">
          <span class="text-center m-1">USER ID: </span>
          <input formControlName="user_id" readonly="readonly" required style="height: 20px;" type="number">
        </div>
        <div class="px-2 d-flex flex-row align-items-center justify-content-between" style="height: 26px; width: 30%;">
          <span class="text-center m-1">STATUS: </span>
          <!--          <input style="height: 20px;" formControlName="status" required type="text">-->
          <select formControlName="status" required style="height: 20px;" type="text">
            <option [ngValue]="'pending'">pending</option>
            <option [ngValue]="'confirming'">confirming</option>
            <option [ngValue]="'approved'">approved</option>
            <option [ngValue]="'packed'">packed</option>
            <option [ngValue]="'delivered'">delivered</option>
          </select>
        </div>
        <span class="text-center" style="width: 10%"></span>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-around py-1 row-separator"
           style="height: 26px; font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
        <div class="px-2 d-flex flex-row align-items-center justify-content-between" style="height: 26px; width: 30%;">
          <span class="text-center m-1">COMPANY: </span>
          <input formControlName="company" required style="height: 20px;" type="text">
        </div>
        <div class="px-2 d-flex flex-row align-items-center justify-content-between" style="height: 26px; width: 30%;">
          <span class="text-center m-1">TRACKING NO: </span>
          <input formControlName="tracking_number" required style="height: 20px;" type="text">
        </div>
        <div class="px-2 d-flex flex-row align-items-center justify-content-between" style="height: 26px; width: 30%;">
          <span class="text-center m-1">TRACKING LINK: </span>
          <input formControlName="tracking_link" required style="height: 20px;" type="text">
        </div>
        <span class="text-center" style="width: 10%"></span>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-around py-1 row-separator"
           style="height: 26px; font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
        <div class="px-2 d-flex flex-row align-items-center justify-content-between" style="height: 26px; width: 30%;">
          <span class="text-center m-1">TRACKING STATUS: </span>
          <!--          <input style="height: 20px;" formControlName="tracking_status" required type="text">-->
          <select formControlName="tracking_status" required style="height: 20px;" type="text">
            <option [value]="'preparing'">preparing</option>
            <option [value]="'shipped'">shipped</option>
            <option [value]="'en_route'">en_route</option>
            <option [value]="'in_distribution'">in_distribution</option>
            <option [value]="'delivered'">delivered</option>
          </select>
        </div>
        <div class="px-2 d-flex flex-row align-items-center justify-content-between" style="height: 26px; width: 30%;">
          <span class="text-center m-1">E.T.A.: </span>
          <input formControlName="estimated_arrival_time" required style="height: 20px;" type="text">
        </div>
        <div class="px-2 d-flex flex-row align-items-center justify-content-between" style="height: 26px; width: 30%;">
        </div>
        <button (click)="adminService.changeDelivery(changeDeliveryForm);" class="px-3" style="width: 10%; color: black">CHANGE</button>
      </div>
    </form>
  </div>
</div>


<div class="w-75 m-auto p-4">
  <span class="mx-2">ADMIN DEPOSIT:</span>
  <div class="d-flex flex-row align-items-center justify-content-around py-1" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 700;">
    <span class="text-center" style="width: 10%">USER ID</span>
    <span class="text-center" style="width: 15%">OPERATION CLASS</span>
    <span class="text-center" style="width: 15%">COLLECTION ID</span>
    <span class="text-center" style="width: 15%">AMOUNT</span>
    <span class="text-center" style="width: 5%">ID</span>
    <span class="text-center" style="width: 10%">EMAIL</span>
    <span class="text-center" style="width: 10%;">NAME</span>
    <span class="text-center" style="width: 10%">SURNAME</span>
    <span class="text-center" style="width: 10%">DEPOSIT</span>
  </div>
  <div class="d-flex flex-column overflow-auto" style="min-height: 26px; max-height: 26px;">
    <form [formGroup]="adminDepositForm" class="d-flex flex-row align-items-center justify-content-around py-1 row-separator"
          style="height: 26px; font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
      <div style="height: 26px; width: 10%;" class="d-flex flex-row align-items-center justify-content-center">
        <span class="text-center m-1">USER ID: </span>
        <select style="height: 20px;" formControlName="user_id" required type="number">
          <option *ngFor="let user of this.adminService.user_list | managerPipe" [value]="user.id">{{ user.id | managerPipe }}</option>
        </select>
      </div>
      <div style="height: 26px; width: 15%;" class="d-flex flex-row align-items-center justify-content-center">
        <span class="text-center m-1">OPERATION CLASS: </span>
        <select style="height: 20px;" formControlName="operation_class" required type="text">
          <option value="Currency">Currency</option>
          <option value="Art Product">Art Product</option>
        </select>
      </div>
      <div *ngIf="adminDepositForm.get('operation_class')?.value === 'Art Product'" style="height: 26px; width: 15%;"
           class="d-flex flex-row align-items-center justify-content-center">
        <span class="text-center m-1">ART PRODUCT: </span>
        <select style="height: 20px;" formControlName="collection_id" required type="number">
          <option value="-1">Select</option>
          <option *ngFor="let art_product of adminService.art_product_list | managerPipe" [value]="art_product.id">{{ art_product.symbol | managerPipe }}</option>
        </select>
      </div>
      <div *ngIf="adminDepositForm.get('operation_class')?.value === 'Currency'" style="height: 26px; width: 15%;"
           class="d-flex flex-row align-items-center justify-content-center">
        <span class="text-center m-1">CURRENCY: </span>
        <select style="height: 20px;" formControlName="collection_id" required type="number">
          <option value="-1">Select</option>
          <option *ngFor="let currency of this.adminService.currency_list | managerPipe" [value]="currency.id">{{ currency.symbol | managerPipe }}</option>
        </select>
      </div>
      <div style="height: 26px; width: 15%;" class="d-flex flex-row align-items-center justify-content-center">
        <span class="text-center m-1">AMOUNT: </span>
        <input style="height: 20px;" formControlName="amount" required type="number">
      </div>
      <div style="width: 35%">
        <div class="d-flex flex-row align-items-center justify-content-around" style="width: 100%;" *ngIf="adminDepositForm.get('user_id')?.value">
          <span class="text-center"
                style="width: 14.5%">{{ (adminDepositForm.get('user_id')?.value | managerPipe: managerService.mpa_template.ADMIN_GET_CACHED_USER: adminService: adminService.user_list_pipe)?.id | managerPipe }}</span>
          <span class="text-center"
                style="width: 28.5%">{{ (adminDepositForm.get('user_id')?.value | managerPipe: managerService.mpa_template.ADMIN_GET_CACHED_USER: adminService: adminService.user_list_pipe)?.email | managerPipe }}</span>
          <span class="text-center"
                style="width: 28.5%">{{ (adminDepositForm.get('user_id')?.value | managerPipe: managerService.mpa_template.ADMIN_GET_CACHED_USER_INFO: adminService: adminService.user_info_list_pipe)?.name | managerPipe }}</span>
          <span class="text-center"
                style="width: 28.5%">{{ (adminDepositForm.get('user_id')?.value | managerPipe: managerService.mpa_template.ADMIN_GET_CACHED_USER_INFO: adminService: adminService.user_info_list_pipe)?.surname | managerPipe }}</span>
        </div>
      </div>
      <button (click)="adminService.adminDeposit(adminDepositForm)" class="px-3" style="width: 10%; color: black">DEPOSIT</button>
    </form>
  </div>
</div>

<div class="w-75 m-auto p-4">
  <span class="mx-2">ADMIN WITHDRAWAL:</span>
  <div class="d-flex flex-row align-items-center justify-content-around py-1" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 700;">
    <span class="text-center" style="width: 10%">USER ID</span>
    <span class="text-center" style="width: 15%">OPERATION CLASS</span>
    <span class="text-center" style="width: 15%">COLLECTION ID</span>
    <span class="text-center" style="width: 15%">AMOUNT</span>
    <span class="text-center" style="width: 5%">ID</span>
    <span class="text-center" style="width: 10%">EMAIL</span>
    <span class="text-center" style="width: 10%;">NAME</span>
    <span class="text-center" style="width: 10%">SURNAME</span>
    <span class="text-center" style="width: 10%">WITHDRAWAL</span>
  </div>
  <div class="d-flex flex-column overflow-auto" style="min-height: 26px; max-height: 26px;">
    <form [formGroup]="adminWithdrawalForm" class="d-flex flex-row align-items-center justify-content-around py-1 row-separator"
          style="height: 26px; font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
      <div style="height: 26px; width: 10%;" class="d-flex flex-row align-items-center justify-content-center">
        <span class="text-center m-1">USER ID: </span>
        <select style="height: 20px;" formControlName="user_id" required type="number">
          <option *ngFor="let user of this.adminService.user_list | managerPipe" [value]="user.id">{{ user.id | managerPipe }}</option>
        </select>
      </div>
      <div style="height: 26px; width: 15%;" class="d-flex flex-row align-items-center justify-content-center">
        <span class="text-center m-1">OPERATION CLASS: </span>
        <select style="height: 20px;" formControlName="operation_class" required type="text">
          <option value="Currency">Currency</option>
          <option value="Art Product">Art Product</option>
        </select>
      </div>
      <div *ngIf="adminWithdrawalForm.get('operation_class')?.value === 'Art Product'" style="height: 26px; width: 15%;"
           class="d-flex flex-row align-items-center justify-content-center">
        <span class="text-center m-1">ART PRODUCT: </span>
        <select style="height: 20px;" formControlName="collection_id" required type="number">
          <option value="-1">Select</option>
          <option *ngFor="let art_product of adminService.art_product_list | managerPipe" [value]="art_product.id">{{ art_product.symbol | managerPipe }}</option>
        </select>
      </div>
      <div *ngIf="adminWithdrawalForm.get('operation_class')?.value === 'Currency'" style="height: 26px; width: 15%;"
           class="d-flex flex-row align-items-center justify-content-center">
        <span class="text-center m-1">CURRENCY: </span>
        <select style="height: 20px;" formControlName="collection_id" required type="number">
          <option value="-1">Select</option>
          <option *ngFor="let currency of this.adminService.currency_list | managerPipe" [value]="currency.id">{{ currency.symbol | managerPipe }}</option>
        </select>
      </div>
      <div style="height: 26px; width: 15%;" class="d-flex flex-row align-items-center justify-content-center">
        <span class="text-center m-1">AMOUNT: </span>
        <input style="height: 20px;" formControlName="amount" required type="number">
      </div>
      <div style="width: 35%">
        <div class="d-flex flex-row align-items-center justify-content-around" style="width: 100%;" *ngIf="adminWithdrawalForm.get('user_id')?.value">
          <span class="text-center"
                style="width: 14.5%">{{ (adminWithdrawalForm.get('user_id')?.value | managerPipe: managerService.mpa_template.ADMIN_GET_CACHED_USER: adminService: adminService.user_list_pipe)?.id | managerPipe }}</span>
          <span class="text-center"
                style="width: 28.5%">{{ (adminWithdrawalForm.get('user_id')?.value | managerPipe: managerService.mpa_template.ADMIN_GET_CACHED_USER: adminService: adminService.user_list_pipe)?.email | managerPipe }}</span>
          <span class="text-center"
                style="width: 28.5%">{{ (adminWithdrawalForm.get('user_id')?.value | managerPipe: managerService.mpa_template.ADMIN_GET_CACHED_USER_INFO: adminService: adminService.user_info_list_pipe)?.name | managerPipe }}</span>
          <span class="text-center"
                style="width: 28.5%">{{ (adminWithdrawalForm.get('user_id')?.value | managerPipe: managerService.mpa_template.ADMIN_GET_CACHED_USER_INFO: adminService: adminService.user_info_list_pipe)?.surname | managerPipe }}</span>
        </div>
      </div>
      <button (click)="adminService.adminWithdrawal(adminWithdrawalForm)" class="px-3" style="width: 10%; color: black">WITHDRAWAL</button>
    </form>
  </div>
</div>

<div class="w-75 m-auto p-4">
  <span class="mx-2">ADMIN ADD ART PRODUCT:</span>
  <div class="d-flex flex-row align-items-center justify-content-around py-1" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 700;">
    <span class="text-center" style="width: 30%"></span>
    <span class="text-center" style="width: 30%"></span>
    <span class="text-center" style="width: 30%"></span>
    <span class="text-center" style="width: 10%">ADD</span>
  </div>
  <div class="d-flex flex-column overflow-auto">
    <form [formGroup]="adminAddArtProductForm">
      <div class="d-flex flex-row align-items-center justify-content-around py-1 row-separator"
           style="height: 26px; font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">NAME: </span>
          <input style="height: 20px;" formControlName="name" required type="text">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">SYMBOL: </span>
          <input style="height: 20px;" formControlName="symbol" required type="text">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">VALUE: </span>
          <input style="height: 20px;" formControlName="value" required type="number">
        </div>
        <span class="text-center" style="width: 10%"></span>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-around py-1 row-separator"
           style="height: 26px; font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">CONTRACT ADDRESS: </span>
          <input style="height: 20px;" formControlName="contract_address" required type="text">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">CIRCULATING SUPPLY RATIO: </span>
          <input style="height: 20px;" formControlName="circulating_supply_ratio" required type="number">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">PRE SALE SUPPLY RATIO: </span>
          <input style="height: 20px;" formControlName="pre_sale_supply_ratio" required type="number">
        </div>
        <span class="text-center" style="width: 10%"></span>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-around py-1 row-separator"
           style="height: 26px; font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">TOTAL SUPPLY: </span>
          <input style="height: 20px;" formControlName="total_supply" required type="number">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">RIGHT TO OWN RATIO: </span>
          <input style="height: 20px;" formControlName="right_to_own_ratio" required type="number">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">COLOR: </span>
          <input style="height: 20px;" formControlName="color" required type="text">
        </div>
        <span class="text-center" style="width: 10%"></span>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-around py-1 row-separator"
           style="height: 26px; font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">PRE SALE START DATE: </span>
          <input style="height: 20px;" formControlName="pre_sale_start_date" required type="number">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">PRE SALE END DATE: </span>
          <input style="height: 20px;" formControlName="pre_sale_end_date" required type="number">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">PUBLIC SALE START DATE: </span>
          <input style="height: 20px;" formControlName="public_sale_start_date" required type="text">
        </div>
        <span class="text-center" style="width: 10%"></span>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-around py-1 row-separator"
           style="height: 26px; font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">PUBLIC SALE END DATE: </span>
          <input style="height: 20px;" formControlName="public_sale_end_date" required type="number">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">LISTING DATE: </span>
          <input style="height: 20px;" formControlName="listing_date" required type="number">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">FEATURED: </span>
          <input style="height: 20px; width: 20px;" formControlName="featured" required type="checkbox" class="checkbox-field">
        </div>
        <button (click)="adminService.adminAddArtProduct(adminAddArtProductForm)" class="px-3" style="width: 10%; color: black">ADD</button>
      </div>
    </form>
  </div>
</div>


<div class="w-75 m-auto p-4">
  <span class="mx-2">ADMIN ADD CURRENCY:</span>
  <div class="d-flex flex-row align-items-center justify-content-around py-1" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 700;">
    <span class="text-center" style="width: 30%"></span>
    <span class="text-center" style="width: 30%"></span>
    <span class="text-center" style="width: 30%"></span>
    <span class="text-center" style="width: 10%">ADD</span>
  </div>
  <div class="d-flex flex-column overflow-auto">
    <form [formGroup]="adminAddCurrencyForm">
      <div class="d-flex flex-row align-items-center justify-content-around py-1 row-separator"
           style="height: 26px; font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">NAME: </span>
          <input style="height: 20px;" formControlName="name" required type="text">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">SYMBOL: </span>
          <input style="height: 20px;" formControlName="symbol" required type="text">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">VALUE: </span>
          <input style="height: 20px;" formControlName="value" required type="number">
        </div>
        <span class="text-center" style="width: 10%"></span>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-around py-1 row-separator"
           style="height: 26px; font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">COLOR: </span>
          <input style="height: 20px;" formControlName="color" required type="text">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
        </div>
        <button (click)="adminService.adminAddCurrency(adminAddCurrencyForm)" class="px-3" style="width: 10%; color: black">ADD</button>
      </div>
    </form>
  </div>
</div>

<div class="w-75 m-auto p-4">
  <span class="mx-2">ADMIN ADD AUCTION PRODUCT:</span>
  <div class="d-flex flex-row align-items-center justify-content-around py-1" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 700;">
    <span class="text-center" style="width: 30%"></span>
    <span class="text-center" style="width: 30%"></span>
    <span class="text-center" style="width: 30%"></span>
    <span class="text-center" style="width: 10%">ADD</span>
  </div>
  <div class="d-flex flex-column overflow-auto">
    <form [formGroup]="adminAddAuctionProductForm">
      <div class="d-flex flex-row align-items-center justify-content-around py-1 row-separator"
           style="height: 26px; font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">NAME: </span>
          <input style="height: 20px;" formControlName="name" required type="text">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">YEAR: </span>
          <input style="height: 20px;" formControlName="year" required type="number">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">CATALOG: </span>
          <input style="height: 20px;" formControlName="catalog" required type="text">
        </div>
        <span class="text-center" style="width: 10%"></span>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-around py-1 row-separator"
           style="height: 26px; font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">LOT: </span>
          <input style="height: 20px;" formControlName="lot" required type="number">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">OPENING VALUE: </span>
          <input style="height: 20px;" formControlName="opening_value" required type="number">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">CURRENT VALUE: </span>
          <input style="height: 20px;" formControlName="current_value" required type="number">
        </div>
        <span class="text-center" style="width: 10%"></span>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-around py-1 row-separator"
           style="height: 26px; font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">VALUE STEP: </span>
          <input style="height: 20px;" formControlName="value_step" required type="number">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">VALUE TYPE: </span>
          <input style="height: 20px;" formControlName="value_type" required type="text">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">AUCTION START DATE: </span>
          <input style="height: 20px;" formControlName="auction_start_date" required type="number">
        </div>
        <span class="text-center" style="width: 10%"></span>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-around py-1 row-separator"
           style="height: 26px; font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">AUCTION END DATE: </span>
          <input style="height: 20px;" formControlName="auction_end_date" required type="number">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">AUCTION INIT END DATE: </span>
          <input style="height: 20px;" formControlName="auction_init_end_date" required type="number">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">END DATE INCREMENT: </span>
          <input style="height: 20px;" formControlName="end_date_increment" required type="number">
        </div>
        <span class="text-center" style="width: 10%"></span>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-around py-1 row-separator"
           style="height: 26px; font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">FEATURED: </span>
          <input style="height: 20px; width: 20px;" formControlName="featured" required type="checkbox" class="checkbox-field">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">ASSURANCE: </span>
          <input style="height: 20px;" formControlName="assurance" required type="number">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">BUYER FEE: </span>
          <input style="height: 20px;" formControlName="buyer_fee" required type="text">
        </div>
        <span class="text-center" style="width: 10%"></span>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-around py-1 row-separator"
           style="height: 26px; font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">SELLER FEE: </span>
          <input style="height: 20px;" formControlName="seller_fee" required type="number">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">TAX: </span>
          <input style="height: 20px;" formControlName="tax" required type="number">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
        </div>
        <button (click)="adminService.adminAddAuctionProduct(adminAddAuctionProductForm)" class="px-3" style="width: 10%; color: black">ADD</button>
      </div>
    </form>
  </div>
</div>

<div class="w-75 m-auto p-4">
  <span class="mx-2">PAIRS</span>
  <div class="d-flex flex-row align-items-center justify-content-around py-1" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 700;">
    <span class="text-center" style="width: 10%">STATUS</span>
    <span class="text-center" style="width: 10%">ID</span>
    <span class="text-center" style="width: 10%">SYMBOL</span>
    <span class="text-center" style="width: 10%"></span>
    <span class="text-center" style="width: 7.5%">UNBLOCK</span>
    <span class="text-center" style="width: 10%"></span>
    <span class="text-center" style="width: 10%"></span>
    <span class="text-center" style="width: 7.5%">BLOCK</span>

  </div>
  <div class="d-flex flex-column overflow-auto" style="min-height: 260px; max-height: 260px;">
    <div *ngFor="let pair of (adminService.pair_list | managerPipe)"
         class="d-flex flex-row align-items-center justify-content-around py-1 row-separator" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
      <span class="text-center" style="width: 10%">
        <span class="circle" [style.background-color]="adminService.getOrderBookBlocked(pair) ? '#0437F2':'#FF2400'"></span>
      </span>
      <span class="text-center" style="width: 10%">{{ pair.id | managerPipe }}</span>
      <span class="text-center" style="width: 10%">{{ pair.symbol | managerPipe }}</span>
      <span class="text-center" style="width: 10%"></span>
      <button (click)="adminService.blockOrderBook(pair, false)" class="text-center" style="width: 7.5%; color: black;">UNBLOCK</button>
      <span class="text-center" style="width: 10%"></span>
      <span class="text-center" style="width: 10%"></span>
      <button #request_block (click)="confirm_block.classList.remove('d-none');request_block.classList.add('d-none');" class="text-center" style="width: 7.5%; color: black;">
        BLOCK
      </button>
      <button #confirm_block (click)="request_block.classList.remove('d-none');confirm_block.classList.add('d-none');adminService.blockOrderBook(pair, true);"
              class="d-none text-center fw-bold" style="width: 10%; color: black;">
        CONFIRM
      </button>
    </div>
  </div>
</div>


<div class="w-75 m-auto p-4 my-4">
  <span class="mx-2">DELIST PAIR:</span>
  <div class="d-flex flex-row align-items-center justify-content-around py-1" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 700;">
    <span class="text-center" style="width: 10%">SYMBOL</span>
    <span class="text-center" style="width: 10%">ID</span>
    <span class="text-center" style="width: 10%">SALE STATE</span>
    <span class="text-center" style="width: 20%">PUBLIC SALE PRICE</span>
    <span class="text-center" style="width: 20%">PRICE</span>
    <span class="text-center" style="width: 20%"></span>
    <span class="text-center" style="width: 10%">DELIST</span>
  </div>
  <div class="py-1" style="min-height: 26px; max-height: 26px;">
    <form [formGroup]="adminDelistForm" class="d-flex flex-row align-items-center justify-content-around py-1 row-separator"
          style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
      <div class="d-flex flex-row align-items-center justify-content-center" style="width: 10%;">
        <select #pair_select formControlName="pair" required style="height: 20px;" (change)="adminService.setActiveDelistPairBySymbol(pair_select.value);">
          <option *ngFor="let pair of this.adminService.pair_list | managerPipe" [value]="pair.symbol">{{ pair.symbol | managerPipe }}</option>
        </select>
      </div>
      <div class="d-flex flex-row justify-content-center align-items-center" style="width: 10%">
        <span class="m-1">{{ adminService.active_delist_pair?.id }}</span>
      </div>
      <div class="d-flex flex-row justify-content-center align-items-center" style="width: 10%">
        <span class="m-1">{{ adminService.active_delist_pair?.sale_state }}</span>
      </div>
      <div class="d-flex flex-row justify-content-center align-items-center" style="width: 20%">
        <span class="m-1">{{ adminService.active_delist_pair_public_sale_price }}</span>
      </div>
      <div class="d-flex flex-row justify-content-center align-items-center" style="width: 20%">
        <input formControlName="price" required type="number">
      </div>
      <div class="text-center" style="width: 20%"></div>
      <button #request_delist (click)="confirm_delist.classList.remove('d-none');request_delist.classList.add('d-none');"
              class="px-3" style="width: 7.5%; color: black">
        DELIST
      </button>
      <button #confirm_delist (click)="request_delist.classList.remove('d-none');confirm_delist.classList.add('d-none');adminService.delist(adminDelistForm);"
              class="px-3 d-none text-center fw-bold" style="width: 10%; color: black;">
        CONFIRM
      </button>
    </form>
  </div>
</div>


<div class="w-75 m-auto p-4">
  <span class="mx-2">CURRENCY PAIRS</span>
  <div class="d-flex flex-row align-items-center justify-content-around py-1" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 700;">
    <span class="text-center" style="width: 10%">STATUS</span>
    <span class="text-center" style="width: 10%">ID</span>
    <span class="text-center" style="width: 10%">SYMBOL</span>
    <span class="text-center" style="width: 10%"></span>
    <span class="text-center" style="width: 7.5%">UNBLOCK</span>
    <span class="text-center" style="width: 10%"></span>
    <span class="text-center" style="width: 10%"></span>
    <span class="text-center" style="width: 7.5%">BLOCK</span>

  </div>
  <div class="d-flex flex-column overflow-auto" style="min-height: 260px; max-height: 260px;">
    <div *ngFor="let currency_pair of (adminService.currency_pair_list | managerPipe)"
         class="d-flex flex-row align-items-center justify-content-around py-1 row-separator" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
      <span class="text-center" style="width: 10%">
        <span class="circle" [style.background-color]="adminService.getSwapOrderBookBlocked(currency_pair) ? '#0437F2':'#FF2400'"></span>
      </span>
      <span class="text-center" style="width: 10%">{{ currency_pair.id | managerPipe }}</span>
      <span class="text-center" style="width: 10%">{{ currency_pair.symbol | managerPipe }}</span>
      <span class="text-center" style="width: 10%"></span>
      <button (click)="adminService.blockSwapOrderBook(currency_pair, false)" class="text-center" style="width: 7.5%; color: black;">UNBLOCK</button>
      <span class="text-center" style="width: 10%"></span>
      <span class="text-center" style="width: 10%"></span>
      <button #request_block (click)="confirm_block.classList.remove('d-none');request_block.classList.add('d-none');" class="text-center" style="width: 7.5%; color: black;">
        BLOCK
      </button>
      <button #confirm_block (click)="request_block.classList.remove('d-none');confirm_block.classList.add('d-none');adminService.blockSwapOrderBook(currency_pair, true)"
              class="d-none text-center fw-bold" style="width: 10%; color: black;">
        CONFIRM
      </button>
    </div>
  </div>
</div>

<div class="w-75 m-auto p-4">
  <span class="mx-2">AUCTION PRODUCTS</span>
  <div class="d-flex flex-row align-items-center justify-content-around py-1" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 700;">
    <span class="text-center" style="width: 10%">STATUS</span>
    <span class="text-center" style="width: 5%"></span>
    <span class="text-center" style="width: 20%"></span>
    <span class="text-center" style="width: 5%"></span>
    <span class="text-center" style="width: 7.5%">UNBLOCK</span>
    <span class="text-center" style="width: 10%"></span>
    <span class="text-center" style="width: 10%"></span>
    <span class="text-center" style="width: 7.5%">BLOCK</span>
  </div>
  <div class="d-flex flex-column overflow-auto" style="min-height: 260px; max-height: 260px;">
    <div class="d-flex flex-row align-items-center justify-content-around py-1 row-separator" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
      <span class="text-center" style="width: 10%">
        <span [style.background-color]="adminService.bid_engine_status ? '#0437F2':'#FF2400'" class="circle"></span>
      </span>
      <span class="text-center" style="width: 5%"></span>
      <span class="text-center" style="width: 20%">ALL AUCTION PRODUCTS</span>
      <span class="text-center" style="width: 5%"></span>
      <button (click)="adminService.blockBidEngine( false)" class="text-center" style="width: 7.5%; color: black;">UNBLOCK</button>
      <span class="text-center" style="width: 10%"></span>
      <span class="text-center" style="width: 10%"></span>
      <button #request_block (click)="confirm_block.classList.remove('d-none');request_block.classList.add('d-none');" class="text-center" style="width: 7.5%; color: black;">
        BLOCK
      </button>
      <button #confirm_block (click)="request_block.classList.remove('d-none');confirm_block.classList.add('d-none');adminService.blockBidEngine(true)"
              class="d-none text-center fw-bold" style="width: 10%; color: black;">
        CONFIRM
      </button>
    </div>
  </div>
</div>


<div class="w-75 m-auto p-4">
  <span class="mx-2">CURRENCIES</span>
  <div class="d-flex flex-row align-items-center justify-content-around py-1" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 700;">
    <span class="text-center" style="width: 10%"></span>
    <span class="text-center" style="width: 10%">ID</span>
    <span class="text-center" style="width: 10%">SYMBOL</span>
    <span class="text-center" style="width: 10%">SUPPLY_STOCK</span>
    <span class="text-center" style="width: 20%"></span>
    <span class="text-center" style="width: 15%">PROVIDE SUPPLY</span>

  </div>
  <div class="d-flex flex-column overflow-auto" style="min-height: 260px; max-height: 260px;">
    <div *ngFor="let currency of (adminService.currency_list | managerPipe)"
         class="d-flex flex-row align-items-center justify-content-around py-1 row-separator" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
      <span class="text-center" style="width: 10%"></span>
      <span class="text-center" style="width: 10%">{{ currency.id | managerPipe }}</span>
      <span class="text-center" style="width: 10%">{{ currency.symbol | managerPipe }}</span>
      <span class="text-center" style="width: 10%">{{ currency.supply_stock | managerPipe: managerService.mpa_template.FANCY_NUMBER }}</span>
      <div style="height: 26px; width: 20%;" class="d-flex flex-row align-items-center justify-content-between">
        <input #supply_stock style="height: 20px;" required type="number">
      </div>
      <button (click)="adminService.provideSupply(currency, supply_stock.value)" class="text-center" style="width: 15%; color: black;">SUPPLY</button>
    </div>
  </div>
</div>


<div class="w-75 m-auto p-4">
  <span class="mx-2">CHANGE USER EMAIL:</span>
  <div class="d-flex flex-row align-items-center justify-content-around py-1" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 700;">
    <span class="text-center" style="width: 10%">USER ID</span>
    <span class="text-center" style="width: 20%">EMAIL</span>
    <span class="text-center" style="width: 30%">OLD EMAIL</span>
    <span class="text-center" style="width: 30%">NEW EMAIL</span>
    <span class="text-center" style="width: 10%">CHANGE</span>
  </div>
  <div class="py-1" style="min-height: 26px; max-height: 26px;">
    <form [formGroup]="changeUserEmailForm" class="d-flex flex-row align-items-center justify-content-around py-1 row-separator"
          style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
      <div class="d-flex flex-row align-items-center justify-content-center" style="width: 10%;">
        <span class="text-center m-1">USER ID: </span>
        <select formControlName="user_id" required style="height: 20px;" type="number">
          <option *ngFor="let user of this.adminService.user_list | managerPipe" [value]="user.id">{{ user.id | managerPipe }}</option>
        </select>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-center" style="width: 20%;">
              <span *ngIf="changeUserEmailForm.get('user_id')?.value | managerPipe">
                {{ (changeUserEmailForm.get('user_id')?.value | managerPipe: managerService.mpa_template.ADMIN_GET_CACHED_USER: adminService: adminService.user_list_pipe)?.email | managerPipe }}
              </span>
      </div>
      <div class="d-flex flex-row justify-content-center align-items-center" style="width: 30%">
        <span class="m-1">OLD EMAIL: </span>
        <input formControlName="old_email" required type="text">
      </div>
      <div class="d-flex flex-row justify-content-center align-items-center" style="width: 30%">
        <span class="m-1">NEW EMAIL: </span>
        <input formControlName="new_email" required type="text">
      </div>
      <button (click)="adminService.changeUserEmail(changeUserEmailForm)" class="px-3" style="width: 10%; color: black">CHANGE</button>
    </form>
  </div>
</div>


<div class="w-75 m-auto p-4">
  <span class="mx-2">CHANGE USER INFO:</span>
  <div class="d-flex flex-column overflow-auto">
    <form [formGroup]="changeUserInfoForm">
      <div class="d-flex flex-row align-items-center justify-content-around py-1 row-separator"
           style="height: 26px; font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
        <div style="width: 30%;" class="d-flex flex-row align-items-center justify-content-start">
          <div style="height: 26px; width: 33%;" class="px-2 d-flex flex-row align-items-center justify-content-center">
            <span class="text-center m-1">USER ID: </span>
            <select formControlName="user_id" required style="height: 20px;" type="number">
              <option *ngFor="let user of this.adminService.user_list | managerPipe" [value]="user.id">{{ user.id | managerPipe }}</option>
            </select>
          </div>
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">NAME: </span>
          <input style="height: 20px;" formControlName="name" required type="text">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">SURNAME: </span>
          <input style="height: 20px;" formControlName="surname" required type="text">
        </div>
        <span class="text-center" style="width: 10%"></span>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-around py-1 row-separator"
           style="height: 26px; font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">TC NO: </span>
          <input style="height: 20px;" formControlName="tc_no" required type="number">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">BIRTH YEAR: </span>
          <input style="height: 20px;" formControlName="birth_year" required type="number">
        </div>
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">TOWN: </span>
          <input style="height: 20px;" formControlName="town" required type="text">
        </div>
        <span class="text-center" style="width: 10%"></span>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-around py-1 row-separator"
           style="height: 26px; font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500;">
        <div style="height: 26px; width: 60%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">ADDRESS: </span>
          <input style="height: 20px; width: 90%;" formControlName="address" required type="text">
        </div>
        <!--        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">-->
        <!--          <span class="text-center m-1">: </span>-->
        <!--          <input style="height: 20px;" formControlName="" required type="">-->
        <!--        </div>-->
        <div style="height: 26px; width: 30%;" class="px-2 d-flex flex-row align-items-center justify-content-between">
          <span class="text-center m-1">PHONE NO: </span>
          <input style="height: 20px;" formControlName="phone_number" required type="text">
        </div>
        <button (click)="adminService.changeUserInfo(changeUserInfoForm)" class="px-3" style="width: 10%; color: black">CHANGE</button>
      </div>
    </form>
  </div>
</div>


<div class="m-5"></div>
