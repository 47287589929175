import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ManagerService} from '../../../../../services/manager.service';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
    selector: 'app-auction-share-mobile',
    templateUrl: './auction-share-mobile.component.html',
    styleUrls: ['./auction-share-mobile.component.css']
})
export class AuctionShareMobileComponent implements OnInit, OnDestroy {

    @Input() modal_id: string = '';

    url: string = '';

    public subscription_list: Subscription[] = [];

    constructor(public managerService: ManagerService, private clipboard: Clipboard) {
    }

    ngOnInit(): void {
        this.url = 'https://www.artiox.com/auction/auction_product/' + this.managerService.auctionService.active_auction_product.hash;

        this.subscription_list.push(
            this.managerService.auctionService.active_auction_product_changed.subscribe(() => {
                if (this.managerService.auctionService.auction_product_list_ready) {
                    this.url = 'https://www.artiox.com/auction/auction_product/' + this.managerService.auctionService.active_auction_product.hash;
                }
            })
        );
    }

    copy(): void {
        this.clipboard.copy(this.url);
        this.managerService.popSnackBar(true, this.managerService.config.DEPOSIT.COPIED);
    }

    whatsapp(): void {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return;
        }

        const auction_product_config = this.managerService.auctionService.active_auction_product_config;
        const hash = this.managerService.auctionService.active_auction_product.hash;

        const unevaluated = this.managerService.config.AUCTION.SHARE.WHATSAPP.TEXT;
        const tokens = ['$ARTIST_NAME', '$ART_PRODUCT_NAME', '$URL'];
        const replacements = [
            auction_product_config.ARTIST_NAME,
            auction_product_config.ART_PRODUCT_NAME,
            this.url,
        ];

        const text: string = this.managerService.evaluateString(unevaluated, tokens, replacements);
        const image_path: string = `https://www.artiox.com/assets/auction_products/${encodeURIComponent(hash)}.jpg`;  // todo

        this.managerService.marketingService.logEvent('auction_product_share', {auction_product: this.managerService.auctionService.active_auction_product.hash, platform: 'whatsapp'});

        window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(text)}&amp;data-id=image/jpeg&amp;base64=${encodeURIComponent(image_path)}`, '_blank');
        // window.open(`whatsapp://send?text=${text}`, '_blank');
    }

    twitter(): void {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return;
        }
        const auction_product_config = this.managerService.auctionService.active_auction_product_config;

        const unevaluated = this.managerService.config.AUCTION.SHARE.TWITTER.TEXT;
        const tokens = ['$ARTIST_NAME', '$ART_PRODUCT_NAME'];
        const replacements = [
            auction_product_config.ARTIST_NAME,
            auction_product_config.ART_PRODUCT_NAME,
        ];

        const text: string = this.managerService.evaluateString(unevaluated, tokens, replacements);
        const url: string = this.url;
        const hashtags: string = this.managerService.config.AUCTION.SHARE.TWITTER.HASHTAGS;
        const via: string = this.managerService.config.AUCTION.SHARE.TWITTER.VIA;
        const related: string = hashtags;  // todo ?

        this.managerService.marketingService.logEvent('auction_product_share', {auction_product: this.managerService.auctionService.active_auction_product.hash, platform: 'twitter'});

        window.open(`https://twitter.com/intent/tweet?&text=${text}&url=${url}&hashtags=${hashtags}&via=${via}&related=${related}`, '_blank');
    }

    linkedin(): void {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return;
        }

        const auction_product_config = this.managerService.auctionService.active_auction_product_config;
        const unevaluated = this.managerService.config.AUCTION.SHARE.LINKEDIN.SUMMARY;
        const tokens = ['$ARTIST_NAME', '$ART_PRODUCT_NAME'];
        const replacements = [
            auction_product_config.ARTIST_NAME,
            auction_product_config.ART_PRODUCT_NAME,
        ];

        const title: string = encodeURIComponent(this.managerService.config.AUCTION.SHARE.LINKEDIN.TITLE);  // todo ?
        const summary: string = encodeURIComponent(this.managerService.evaluateString(unevaluated, tokens, replacements));  // todo ?
        const url: string = this.url;
        const source: string = this.url;  // todo ?
        const mini: string = 'false';  // todo ?

        this.managerService.marketingService.logEvent('auction_product_share', {auction_product: this.managerService.auctionService.active_auction_product.hash, platform: 'linkedin'});

        window.open(`https://www.linkedin.com/sharing/share-offsite/?title=${title}&summary=${summary}&url=${url}&source=${source}&mini=${mini}`, '_blank');
    }

    openGooglePlay(): void {
        window.open('https://play.google.com/store/apps/details?id=com.artiox.artiox', '_blank');
    }

    openAppStore(): void {
        window.open('itms-apps://apple.com/app/id1579037676?l=tr', '_blank');
    }

    ngOnDestroy(): void {
        this.subscription_list.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }
}
