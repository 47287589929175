import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription, timer} from 'rxjs';
import {map, takeWhile} from 'rxjs/operators';
import {ManagerService} from '../../../../services/manager.service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-forgot-password-page-mobile',
  templateUrl: './forgot-password-page-mobile.component.html',
  styleUrls: ['./forgot-password-page-mobile.component.css']
})
export class ForgotPasswordPageMobileComponent implements OnInit {
  @ViewChild('email_input') email_input: any;
  @ViewChild('email_error') email_error: any;
  protected v = 60;
  protected isRequestSent: boolean = false;
  protected countdown: number = 0;
  protected isTextClickable = false;

  forgotPasswordForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
  });

  public subscription_list: Subscription[] = [];
  protected sendTimer = timer(0, 1000).pipe(
      map(n => 60 - n),
      takeWhile(n => n >= 0),
  );
  constructor(public managerService: ManagerService, private httpClient: HttpClient, ) {
  }

  ngOnInit(): void {
  }
  forgotPassword(): void {
    const data = {
      email: this.forgotPasswordForm.value.email,
      client_info: this.managerService.client_info
    };
    this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'user_forgot_password_service', data).subscribe(
        response => {
          // @ts-ignore
          const content = this.managerService.config.RESPONSE_TABLE[response.code];
          this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
          if (response.is_okay) {
            this.isRequestSent = true;
            this.subscription_list.push(this.sendTimer.subscribe(x => {
              this.countdown = x;
              if ( x <= 0 ){
                this.isTextClickable = true;
              }
            }));
          }
          else {
            this.email_input.nativeElement.classList.remove('input-success');
            this.email_input.nativeElement.classList.add('input-error');
            this.email_error.nativeElement.classList.add('d-none');
            this.email_error.nativeElement.children[1].innerHTML = content.message;
          }
        }));
  }

  ngOnDestroy(): void {
    this.subscription_list.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  validateEmail(): void {
    if (this.email_input) {
      const controlMember = this.forgotPasswordForm.get('email');
      if (controlMember?.errors) {
        this.email_input.nativeElement.classList.remove('input-success');
        this.email_input.nativeElement.classList.add('input-error');
        // @ts-ignore
        if (controlMember.errors.required){
          // @ts-ignore
          this.email_error.nativeElement.children[1].innerHTML = this.managerService.config.RESPONSE_TABLE['2003'].message;
        }
        else if (controlMember.errors.email){
          // @ts-ignore
          this.email_error.nativeElement.children[1].innerHTML = this.managerService.config.RESPONSE_TABLE[2006].message;
        }
        this.email_error.nativeElement.classList.remove('d-none');
      } else {
        this.email_input.nativeElement.classList.remove('input-error');
        this.email_input.nativeElement.classList.add('input-success');
        this.email_error.nativeElement.classList.add('d-none');
        this.forgotPassword();
      }
    }
  }

  resendEmail(): void{
    if (this.isTextClickable){
      this.isRequestSent = false;
      this.subscription_list.pop()?.unsubscribe();
      this.isTextClickable = false;
    }
  }


}
