<div *ngIf="((!managerService.logged_in) || (0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO)) | managerPipe"
     class="px-4" style="box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22);  background-color: #fcfdff; min-height: 650px">
  <div class="pt-3 mb-2" style="font-family: Roboto, sans-serif; font-size: 20px; font-weight: bold; line-height: 2; letter-spacing: 1px; color: #4a4e70;">
    {{managerService.config.DEPOSIT.NO_KYC_HEADING | managerPipe}}
  </div>
  <div class="my-2" style="font-family: Roboto, sans-serif; font-size: 14px; color: #4a4e70;">
    {{managerService.config.DEPOSIT.NO_KYC_DESCRIPTION | managerPipe}}
  </div>

  <button (click)="managerService.navigationManager({url: '/account/security', active_security_panel: managerService.active_security_panel_template.IDENTITY_VERIFICATION, current_component_index: 0});"
          class="btn-native sign-up-login-button mx-4 my-3"
          style="height: 40px; width:160px; border-radius: 20px;
              font-family: Roboto, sans-serif; font-size: 16px; line-height: 1.3; letter-spacing: 0.3px; color: #ffffff;">
    {{managerService.config.DASHBOARD.LEVEL_UP | managerPipe}}
  </button>
</div>

<div *ngIf="(managerService.logged_in && !(0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO)) | managerPipe">
  <div *ngIf="!managerService.config.DEPOSIT.TL_SUSPENSION.SUSPENDED | managerPipe"
    class="w-100 d-flex flex-column align-items-start justify-content-start pb-3"
    style="box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff; max-height: 1160px; border-radius: 4px;">
    <div class="w-100 mt-4 ml-5" style="font-family: Roboto, sans-serif; font-size: 14px; font-weight: bold; line-height: 2; letter-spacing: 1px; color: #4a4e70;">
      {{managerService.config.DEPOSIT.LIMITS}}
    </div>
    <div class="d-flex flex-row align-items-start justify-content-start mt-3 ml-5"
         style="box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff; border-radius: 4px; margin-bottom: 10px;">
      <div class="d-flex flex-column m-3">
      <span class="m-1 text-center"
            style="font-family: 'Roboto Condensed', sans-serif; font-size: 13px; line-height: 1.25; color: #272a3b; letter-spacing: 1px">{{managerService.config.DEPOSIT.DAILY_DEPOSIT_LIMIT | managerPipe}}</span>
        <span class="m-1 text-center"
              style="font-family: 'Roboto Condensed', sans-serif; font-size: 14px; line-height: 1.25; color: #272a3b; letter-spacing: 1px">{{managerService.userService.user?.daily_fiat_deposit_limit | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
          ₺</span>
      </div>
      <span
        style="height: 40px; width: 1px; min-width: 2px; margin: auto 10px; border-radius:1px; background-color: #dddddd;"></span>
      <div class="h-100 d-flex flex-column m-3">
      <span class="m-1 text-center"
            style="font-family: 'Roboto Condensed', sans-serif; font-size: 13px; line-height: 1.25; color: #272a3b; letter-spacing: 1px">{{managerService.config.DEPOSIT.MONTHLY_DEPOSIT_LIMIT | managerPipe}}</span>
        <span class="m-1 text-center"
              style="font-family: 'Roboto Condensed', sans-serif; font-size: 14px; line-height: 1.25; color: #272a3b; letter-spacing: 1px">{{managerService.userService.user?.monthly_fiat_deposit_limit | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
          ₺</span>
      </div>
    </div>

    <div class="w-100 mt-4 ml-5" style="font-family: Roboto, sans-serif; font-size: 14px; font-weight: bold; line-height: 2; letter-spacing: 1px; color: #4a4e70;">
      {{managerService.config.DEPOSIT.RULES}}
    </div>
    <div class="w-100 pl-5" >
      <div class="mt-3 pl-3 py-2" style="background-color: #f4f5f9; font-family: Roboto, sans-serif; font-size: 12px; font-style: italic;
      line-height: 1.75; letter-spacing: 0.35px; color: #4a4e70;">
        <div *ngFor="let rule of managerService.config.DEPOSIT.FIAT_DEPOSIT_RULES | managerPipe" class="w-80">
          {{rule | managerPipe}}
        </div>
      </div>
    </div>


    <div class="w-100 mt-4 ml-5" style="font-family: Roboto, sans-serif; font-size: 14px; font-weight: bold; line-height: 2; letter-spacing: 1px; color: #4a4e70;">
      3. {{managerService.config.DEPOSIT.TRANSACTION}}
    </div>
    <div class="d-flex flex-row align-items-start justify-content-start mt-3 ml-5">
      <div (click)="selectBankAccount(bank_account)"
           *ngFor="let bank_account of managerService.operationsService.artiox_bank_account_list | managerPipe;"
           [class.inactive-bank-account]="(!(managerService.operationsService.selected_artiox_bank_account.label === bank_account.label)) && (managerService.operationsService.selected_artiox_bank_account.iban !== '') | managerPipe"
           class="d-flex flex-column p-3 mx-3"
           style="border-radius: 4px; box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22);">
        <img [src]="bank_account.bank | managerPipe: managerService.mpa_template.GET_BANK_ACCOUNT_IMG_SRC" alt="bank" width="150px" #bank
             (error)="bank.src = bank.src.replace('.webp', '.jpg')">
      </div>
    </div>

    <div [class]="managerService.operationsService.fiat_deposit_rules_accepted_status ? 'd-flex':'d-none'" class="w-100 mt-4 ml-5 flex-column"
         style="font-family: Roboto, sans-serif; font-size: 14px; line-height: 1.6; letter-spacing: 0.4px; color: #404352;">
      <div class="m-1">
        <span class="font-weight-bold">{{managerService.config.DEPOSIT.BANK_NAME}}:</span><span class="mx-1">{{managerService.operationsService.selected_artiox_bank_account.bank}}</span>
        <button (click)="copyBank()" class="mx-2 btn-native">
          <svg style="height: 20px; width: 20px;">
            <use [attr.xlink:href]='"./assets/images/copy.svg#copy"' fill="#595e7e"/>
          </svg>
        </button>
      </div>
      <div class="m-1">
        <span class="font-weight-bold">{{managerService.config.DEPOSIT.ACCOUNT_NAME}}:</span><span class="mx-1">{{managerService.operationsService.selected_artiox_bank_account.name}}</span>
        <button (click)="copyName()" class="mx-2 btn-native">
          <svg style="height: 20px; width: 20px;">
            <use [attr.xlink:href]='"./assets/images/copy.svg#copy"' fill="#595e7e"/>
          </svg>
        </button>
      </div>
      <div class="m-1">
        <span class="font-weight-bold">{{managerService.config.DEPOSIT.IBAN}}:</span><span class="mx-1">{{managerService.operationsService.selected_artiox_bank_account.iban}}</span>
        <button (click)="copyIBAN()" class="mx-2 btn-native">
          <svg style="height: 20px; width: 20px;">
            <use [attr.xlink:href]='"./assets/images/copy.svg#copy"' fill="#595e7e"/>
          </svg>
        </button>
      </div>
      <div class="m-1">
        <span class="font-weight-bold">{{managerService.config.DEPOSIT.DEPOSIT_REF_CODE}}:</span>
        <span class="mx-1 font-weight-bold" style="font-size: 18px; letter-spacing: 2px;">{{managerService.userService.user?.deposit_reference_code}}</span>
        <button (click)="copyDepositRefCode()" class="mx-2 btn-native">
          <svg style="height: 20px; width: 20px;">
            <use [attr.xlink:href]='"./assets/images/copy.svg#copy"' fill="#595e7e"/>
          </svg>
        </button>
      </div>
      <div class="m-1 text-info">
        <svg style="height: 14px; width: 14px;">
          <use [attr.xlink:href]='"./assets/images/info.svg#info"' fill="#17a2b8"/>
        </svg>
        {{managerService.config.DEPOSIT.DEPOSIT_WARNING}}
      </div>
    </div>
  </div>

  <div *ngIf="managerService.config.DEPOSIT.TL_SUSPENSION.SUSPENDED | managerPipe"
       class="w-100 pt-1 pb-3"
       style="height: 400px; box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff; max-height: 1160px; border-radius: 4px;">
    <div class="w-100 mt-4 ml-5"
         style="font-family: Roboto, sans-serif; font-size: 14px; font-weight: bold; line-height: 2; letter-spacing: 1px; color: #4a4e70;"
         [innerHTML]="managerService.config.DEPOSIT.TL_SUSPENSION.TITLE | managerPipe">
    </div>

    <div class="w-100 mt-3 ml-5"
         style="font-family: Roboto, sans-serif; font-size: 12px; line-height: 1.7; color: #272a3b;"
         [innerHTML]="managerService.config.DEPOSIT.TL_SUSPENSION.DESCRIPTION | managerPipe">
    </div>
  </div>
</div>

<app-deposit-history-desktop [currency_type]="'fiat'" [currency_id]="0"></app-deposit-history-desktop>

