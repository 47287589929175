import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
    selector: 'app-user-info-mobile',
    templateUrl: './user-info-mobile.component.html',
    styleUrls: ['./user-info-mobile.component.css']
})
export class UserInfoMobileComponent implements OnInit {

    constructor(public managerService: ManagerService) {
    }

    ngOnInit(): void {
    }

}
