import {Component, ElementRef, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
  selector: 'app-left-info-mobile',
  templateUrl: './left-info-mobile.component.html',
  styleUrls: ['./left-info-mobile.component.css']
})
export class LeftInfoMobileComponent implements OnInit {

  constructor(public managerService: ManagerService, public element: ElementRef) {
    this.element.nativeElement.addEventListener('click', (el: any) => {
      if (el.target.id === 'art_product_token_info_container') {
        this.managerService.art_product_token_info_display_status = false;
      }
    });
  }

  ngOnInit(): void {
  }

}
