import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';
import {Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-delete-address-desktop',
    templateUrl: './delete-address-desktop.component.html',
    styleUrls: ['./delete-address-desktop.component.css']
})
export class DeleteAddressDesktopComponent implements OnInit {

    public subscription_list: (Subscription | undefined)[] = [];

    constructor(public managerService: ManagerService, private httpClient: HttpClient) {
    }

    ngOnInit(): void {
    }

    // deleteAddress(): void {
    //     // if (this.managerService.userService.selected_user_address_ready){
    //     // if (this.managerService.userService.selected_user_address_type === 'delivery') {
    //     //     this.removeDeliveryAddress();
    //     // } else if (this.managerService.userService.selected_user_address_type === 'billing') {
    //     //     this.removeBillingAddress();
    //     // }
    //     this.removeAddress();
    //     // }
    // }

    removeAddress(): void {
        const data: any  = {};
        let url: string  = '';
        if (this.managerService.userService.selected_user_address_type === 'delivery'){
            data.delivery_address_id = this.managerService.userService.selected_user_address_id;
            url  = 'remove_user_delivery_address_service';
        } else  {
            data.billing_address_id = this.managerService.userService.selected_user_address_id;
            url  = 'remove_user_billing_information_service';
        }
        this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + url, data).subscribe(
            response => {
                // @ts-ignore
                const content = this.managerService.config.RESPONSE_TABLE[response.code];
                this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
            }));
    }

    // removeDeliveryAddress(): void {
    //     const data = {
    //         address_id: this.managerService.userService.selected_user_address_id,
    //     };
    //
    //     this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'remove_user_delivery_address_service', data).subscribe(
    //         response => {
    //             if (!response.is_okay) {
    //                 // @ts-ignore
    //                 const content = this.managerService.config.RESPONSE_TABLE[response.code];
    //                 this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
    //             } else {
    //                 // @ts-ignore
    //                 const content = this.managerService.config.RESPONSE_TABLE[response.code];
    //                 this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
    //             }
    //         }));
    // }
    //
    // removeBillingAddress(): void {
    //     const data = {
    //         address_id: this.managerService.userService.selected_user_address_id,
    //     };
    //
    //     this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'remove_user_billing_information_service', data).subscribe(
    //         response => {
    //             // console.log(response);
    //             if (!response.is_okay) {
    //                 // @ts-ignore
    //                 const content = this.managerService.config.RESPONSE_TABLE[response.code];
    //                 this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
    //             } else {
    //                 // @ts-ignore
    //                 const content = this.managerService.config.RESPONSE_TABLE[response.code];
    //                 this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
    //             }
    //         }));
    // }

}
