import {Component, ElementRef, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';

@Component({
  selector: 'app-summary-mobile',
  templateUrl: './summary-mobile.component.html',
  styleUrls: ['./summary-mobile.component.css']
})
export class SummaryMobileComponent implements OnInit {


  @ViewChildren('crypto_currency') crypto_currencies: QueryList<ElementRef> = new QueryList<ElementRef>();

  public lock: boolean = false;

  public pair_selected: any = undefined;
  public order_type_selected: any = undefined;
  public pair_selected_placeholder: string = this.managerService.config.BALANCE.ALL;
  public order_type_selected_placeholder: string = this.managerService.config.BALANCE.ALL;

  public balance_visible: boolean = true;

  constructor(public managerService: ManagerService) {
    document.addEventListener('scroll', () => {
      if (this.lock){
        return;
      }
      this.crypto_currencies.forEach(e => e.nativeElement.classList.add('d-none'));
    });
  }

  ngOnInit(): void {
  }

  openCryptoCurrencyDetails(crypto_currency: any): void{
    crypto_currency.classList.toggle('d-none');
    this.managerService.window.scrollTo(0, 0);
    this.lock = true;
    setTimeout(() => {
      this.lock = false;
    }, 800);
  }

  getFillingHeight(top: number): number{
    const tab_bar_top: number = document.getElementById('tab-bar')?.offsetTop ?? 56;
    return tab_bar_top - top - 10;
  }

}
