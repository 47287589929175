import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
  selector: 'app-test-environment-warning-desktop',
  templateUrl: './test-environment-warning-desktop.component.html',
  styleUrls: ['./test-environment-warning-desktop.component.css']
})
export class TestEnvironmentWarningDesktopComponent implements OnInit {

  constructor(public managerService: ManagerService) { }

  ngOnInit(): void {
  }

}
