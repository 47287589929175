<div>
  <div
    class="vw-100 min-vw-100 d-flex flex-column align-items-center justify-content-center my-2"
    style="border-bottom: 1px solid rgb(51,51,51);">
    <div
      class="w-100 d-flex flex-row align-items-start justify-content-around" style="user-select: none;">
      <svg (click)="managerService.setPairListSortingType(managerService.pair_list_sorting_type_template.FAVORITE)" class="ml-4 mr-2 my-auto" id="star" style="height: 12px; width: 12px;"
           viewBox="0 0 353.5 352.89"
           xmlns="http://www.w3.org/2000/svg">
        <path
          [style]="(managerService.pair_list_sorting_type === managerService.pair_list_sorting_type_template.FAVORITE && managerService.pair_list_sorting_direction !== managerService.pair_list_sorting_direction_template.DOWN | managerPipe) ? {fill: '#ffbc04'} : {fill: '#404352'}"
          d="M66.26,352.77,110.71,210,0,133H133.33L176.24,0c2.27,6.71,4.41,12.72,6.43,18.83q18.06,54.9,36,109.89a5,5,0,0,0,5.69,4q62.76,0,125.54,0h3.6v.69l-34.79,23.8L243.6,208.64c-1.94,1.29-1.62,2.55-1,4.33q10,31.11,19.92,62.26l24.84,77.66L176.2,263.75Z"/>
      </svg>
      <p (click)="managerService.setPairListSortingType(managerService.pair_list_sorting_type_template.PAIR)"
         class="my-1"
         style="width: 30%; font-family: Raleway ,sans-serif; font-size: 12px; font-weight: bold; line-height: 2.5; letter-spacing: 0.6px; color: #ffffff;">
        {{managerService.config.ADVANCED.PAIR | managerPipe}}
        <svg
          *ngIf="(managerService.pair_list_sorting_type === managerService.pair_list_sorting_type_template.PAIR && managerService.pair_list_sorting_direction !== managerService.pair_list_sorting_direction_template.DOWN | managerPipe)"
          style="height:10px; width: 10px; margin-bottom: 2px;">
          <use [attr.xlink:href]='"./assets/images/down_arrow.svg#down_arrow"' class="w-100" fill="white"/>
        </svg>
        <svg
          *ngIf="(managerService.pair_list_sorting_type === managerService.pair_list_sorting_type_template.PAIR && managerService.pair_list_sorting_direction !== managerService.pair_list_sorting_direction_template.UP | managerPipe)"
          style="height:10px; width: 10px; margin-bottom: 2px;">
          <use [attr.xlink:href]='"./assets/images/up_arrow.svg#up_arrow"' class="w-100" fill="white"/>
        </svg>
      </p>
      <p (click)="managerService.setPairListSortingType(managerService.pair_list_sorting_type_template.PRICE)"
         class="w-25 my-1 text-center"
         style="font-family: Raleway ,sans-serif; font-size: 12px; font-weight: bold; line-height: 2.5; letter-spacing: 0.6px; color: #ffffff;">
        {{managerService.config.ADVANCED.PRICE | managerPipe}}
        <svg
          *ngIf="(managerService.pair_list_sorting_type === managerService.pair_list_sorting_type_template.PRICE && managerService.pair_list_sorting_direction !== managerService.pair_list_sorting_direction_template.DOWN | managerPipe)"
          style="height:10px; width: 10px; margin-bottom: 2px;"
        >
          <use [attr.xlink:href]='"./assets/images/down_arrow.svg#down_arrow"' class="w-100" fill="white"/>
        </svg>
        <svg
          *ngIf="(managerService.pair_list_sorting_type === managerService.pair_list_sorting_type_template.PRICE && managerService.pair_list_sorting_direction !== managerService.pair_list_sorting_direction_template.UP | managerPipe)"
          style="height:10px; width: 10px; margin-bottom: 2px;">
          <use [attr.xlink:href]='"./assets/images/up_arrow.svg#up_arrow"' class="w-100" fill="white"/>
        </svg>
      </p>
      <p (click)="managerService.setPairListSortingType(managerService.pair_list_sorting_type_template.CHANGE)"
         class="my-1 text-center"
         style="width: 35%; font-family: Raleway ,sans-serif; font-size: 12px; font-weight: bold; line-height: 2.5; letter-spacing: 0.6px; color: #ffffff;">
        {{managerService.config.ADVANCED.CHANGE | managerPipe}}
        <svg
          *ngIf="(managerService.pair_list_sorting_type === managerService.pair_list_sorting_type_template.CHANGE && managerService.pair_list_sorting_direction !== managerService.pair_list_sorting_direction_template.DOWN | managerPipe)"
          style="height:10px; width: 10px; margin-bottom: 2px;">
          <use [attr.xlink:href]='"./assets/images/down_arrow.svg#down_arrow"' class="w-100" fill="white"/>
        </svg>
        <svg
          *ngIf="(managerService.pair_list_sorting_type === managerService.pair_list_sorting_type_template.CHANGE && managerService.pair_list_sorting_direction !== managerService.pair_list_sorting_direction_template.UP | managerPipe)"
          style="height:10px; width: 10px; margin-bottom: 2px;">
          <use [attr.xlink:href]='"./assets/images/up_arrow.svg#up_arrow"' class="w-100" fill="white"/>
        </svg>
      </p>
    </div>
  </div>
  <div
    *ngFor="let pair of (managerService.pair_pipe | managerPipe: managerService.mpa_template.GET_SORTED_LISTED_PAIR_LIST: managerService.pair_list_sorting_type: managerService.pair_list_sorting_direction)"
    class="vw-100 min-vw-100 d-flex flex-column align-items-center justify-content-center my-2">
    <div
      class="w-100 d-flex flex-row align-items-start justify-content-around"
      style="border-bottom: 1px solid rgb(51,51,51);" type="button">
      <div class="my-1 text-center" style="width: 7%; margin-left: 3%" (click)="managerService.setUserFavoritePair(pair)" >
        <svg class="" id="star"
             style="height: 12px; width: 12px;"
             viewBox="0 0 353.5 352.89"
             xmlns="http://www.w3.org/2000/svg">
          <path
            [style]="(pair | managerPipe: managerService.mpa_template.IS_PAIR_FAVORITE: managerService.user_pipe) ? {fill: '#ffbc04'} : {fill: '#404352'}"
            d="M66.26,352.77,110.71,210,0,133H133.33L176.24,0c2.27,6.71,4.41,12.72,6.43,18.83q18.06,54.9,36,109.89a5,5,0,0,0,5.69,4q62.76,0,125.54,0h3.6v.69l-34.79,23.8L243.6,208.64c-1.94,1.29-1.62,2.55-1,4.33q10,31.11,19.92,62.26l24.84,77.66L176.2,263.75Z"/>
        </svg>
      </div>
      <p (click)="managerService.navigationManager({url: '/exchange/advanced', active_pair_id: pair.id, current_component_index: 1})" class="my-1"
         style="width: 30%; font-family: Raleway ,sans-serif; font-size: 12px; font-weight: bold; line-height: 2.5; letter-spacing: 0.6px; color: #ffffff;">
        {{pair.symbol | managerPipe}}
      </p>
      <p (click)="managerService.navigationManager({url: '/exchange/advanced', active_pair_id: pair.id, current_component_index: 1})" [style]="pair.change | managerPipe: managerService.mpa_template.PAIR_CHANGE_COLOR"
         class="my-1 text-center"
         style="width: 25%; font-family: Raleway ,sans-serif; font-size: 12px; font-weight: bold; line-height: 2.5; letter-spacing: 0.6px; color: #ffffff;">
        {{pair.price | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
      </p>
      <p (click)="managerService.navigationManager({url: '/exchange/advanced', active_pair_id: pair.id, current_component_index: 1})" [style]="pair.change | managerPipe: managerService.mpa_template.PAIR_CHANGE_COLOR"
         class="my-1 text-center"
         style="width: 35%; font-family: Raleway ,sans-serif; font-size: 12px; font-weight: bold; line-height: 2.5; letter-spacing: 0.6px; color: #ffffff;">
        {{pair.change | managerPipe: managerService.mpa_template.FANCY_NUMBER}}%
      </p>
    </div>
  </div>
</div>
