import {Component, OnDestroy, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-basic-pair-header-mobile',
  templateUrl: './basic-pair-header-mobile.component.html',
  styleUrls: ['./basic-pair-header-mobile.component.css']
})
export class BasicPairHeaderMobileComponent implements OnInit, OnDestroy {

  public info_swap: boolean = false;

  public subscription_list: Subscription[] = [];

  constructor(public managerService: ManagerService) {
    this.subscription_list.push(
      this.managerService.navigationService.current_component_index_changed.subscribe(() => {
        this.info_swap = false;
      })
    );
  }

  ngOnInit(): void {
  }

  swap(): void {
    this.info_swap = !this.info_swap;
  }

  ngOnDestroy(): void {
    this.subscription_list.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
