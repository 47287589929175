import {AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {LegalRoutes} from '../legal.component';

@Component({
    selector: 'app-legal-mobile',
    templateUrl: './legal-mobile.component.html',
    styleUrls: ['./legal-mobile.component.css']
})
export class LegalMobileComponent implements OnInit, AfterViewInit {


    protected readonly LegalRoutes = LegalRoutes;
    active_path: LegalRoutes = LegalRoutes.USER_AGREEMENT;
    legalRoutes = [
        LegalRoutes.USER_AGREEMENT,
        LegalRoutes.PROTECTION_LAW_POLICY,
        LegalRoutes.INFORMATION_SECURITY_POLICY,
        LegalRoutes.DATA_DELETION_POLICY,
        LegalRoutes.KVKK_APPLICATION_FORM,
        LegalRoutes.COOKIE_CLARIFICATION_TEXT,
        LegalRoutes.KVKK_CLARIFICATION,
        LegalRoutes.MARKETING_CONSENT,
        LegalRoutes.FOREIGN_CONSENT,
        LegalRoutes.DISTANCE_SELLING,
        LegalRoutes.AUCTION_CANCELLATION,
        LegalRoutes.AUCTION_USER_AGREEMENT,
        LegalRoutes.AUCTION_RULES,
        LegalRoutes.COMMISSIONS,
        LegalRoutes.LIMITS
    ];

    constructor(public managerService: ManagerService, public route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.active_path = LegalRoutes.USER_AGREEMENT;
        this.route.paramMap.subscribe((params: ParamMap) => {
            if(params.get('path') != null) {
                // @ts-ignore
                const path = params.get('path')
                // @ts-ignore
                this.active_path = path
                this.managerService.navigationManager({url:'/legal/' + path, current_component_index: 0})

            }
        })
    }

    ngAfterViewInit(): void {
        const path_id = this.active_path.replace('-','_');
        // @ts-ignore
        document.getElementById(path_id).classList.remove('d-none');
    }

}
