<div class="d-flex flex-column w-100 h-100" [style]="managerService.show_sign_up_or_login | managerPipe: managerService.mpa_template.GET_MIN_BODY_HEIGHT_OBSOLETE">
  <div class="w-100 d-flex flex-column" style="background-color: #fcfdff;  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5); margin-bottom: 4px;" >

    <div class="mx-3 mt-4 mb-3" style="font-family: Roboto, sans-serif; font-size: 14px; letter-spacing: 0.7px; color: #272a3b;">
      <span>
        <svg style="height: 20px; width: 20px;">
          <use [attr.xlink:href]='"./assets/images/deposit.svg#deposit"' fill="#272a3b"/>
        </svg>
      </span>
      {{managerService.config.DEPOSIT.HEADING | managerPipe: managerService.mpa_template.TO_LOCAL_UPPER_CASE}}
    </div>

    <div class="d-flex flex-row justify-content-around">
      <button (click)="managerService.navigationService.active_deposit_page = managerService.navigationService.deposit_page_template.TURKISH_LIRA"
              [class.btn-account-navigator-active]="managerService.navigationService.active_deposit_page === managerService.navigationService.deposit_page_template.TURKISH_LIRA | managerPipe"
              class="w-50 btn-native btn-account-navigator" style="font-size: 13px; letter-spacing: normal;" type="button">
        {{managerService.config.DEPOSIT.BANK | managerPipe}}
        <div [class.d-none]="managerService.navigationService.active_deposit_page !== managerService.navigationService.deposit_page_template.TURKISH_LIRA | managerPipe" class="mx-auto" style="width: 90px; border-bottom: 1px solid #4fc0d2; margin-top: 2px"></div>
      </button>
      <button (click)="managerService.navigationService.active_deposit_page = managerService.navigationService.deposit_page_template.TETHER"
              [class.btn-account-navigator-active]="managerService.navigationService.active_deposit_page === managerService.navigationService.deposit_page_template.TETHER | managerPipe"
              class="w-50 btn-native btn-account-navigator" style="font-size: 13px; letter-spacing: normal;" type="button">
        {{managerService.config.DEPOSIT.TETHER | managerPipe}}
        <div [class.d-none]="managerService.navigationService.active_deposit_page !== managerService.navigationService.deposit_page_template.TETHER | managerPipe" class="mx-auto" style="width: 90px; border-bottom: 1px solid #4fc0d2; margin-top: 2px"></div>
      </button>
    </div>

  </div>
  <app-deposit-fiat-mobile
      [class.d-none]="managerService.navigationService.active_deposit_page !== managerService.navigationService.deposit_page_template.TURKISH_LIRA | managerPipe"></app-deposit-fiat-mobile>
  <app-deposit-cryptocurrency-mobile
      [class.d-none]="managerService.navigationService.active_deposit_page !== managerService.navigationService.deposit_page_template.TETHER | managerPipe"></app-deposit-cryptocurrency-mobile>
</div>
