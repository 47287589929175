<div class="w-100 d-flex flex-column align-items-start justify-content-start"
     style="background-color: #fcfdff; max-height: 1160px; ">

<!--  R.I.P-->
<!--  <div class="w-100">-->
<!--    <div class="d-flex flex-row" style="font-family: Roboto,sans-serif;font-size: 14px;font-weight: 300;letter-spacing: 0.42px; background-color: #272a3b; color: #ffffff;">-->
<!--      <div [style.color]="((0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe) ? 'var(&#45;&#45;artiox-blue)' : '') | managerPipe"-->
<!--           class="w-33 py-3 text-center">-->
<!--        {{managerService.config.DASHBOARD.LEVEL | managerPipe}} 0-->
<!--      </div>-->
<!--      <span style="width: 0px; height: 29px; margin: auto 0; border-right: solid 1px #f4f5f9;"></span>-->
<!--      <div [style.color]="((1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe) ? 'var(&#45;&#45;artiox-blue)' : '') | managerPipe"-->
<!--           class="w-33 py-3 text-center">-->
<!--        {{managerService.config.DASHBOARD.LEVEL | managerPipe}} 1-->
<!--      </div>-->
<!--      <span style="width: 0px; height: 29px; margin: auto 0; border-right: solid 1px #f4f5f9;"></span>-->
<!--      <div [style.color]="((2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe) ? 'var(&#45;&#45;artiox-blue)' : '') | managerPipe"-->
<!--           class="w-33 py-3 text-center">-->
<!--        {{managerService.config.DASHBOARD.LEVEL | managerPipe}} 2-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="position-relative">-->
<!--      <div [class.d-none]="!(0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)" class="position-absolute"-->
<!--           style="height: 12px; width: 12px; transform: translateX(calc(16vw - 6px)) translateY(-6px) rotate(45deg) ; background-color: #404352;">-->
<!--      </div>-->
<!--      <div [class.d-none]="!(1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)" class="position-absolute"-->
<!--           style="height: 12px; width: 12px; transform: translateX(calc(50vw - 6px)) translateY(-6px) rotate(45deg) ; background-color: #404352;">-->
<!--      </div>-->
<!--      <div [class.d-none]="!(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)" class="position-absolute"-->
<!--           style="height: 12px; width: 12px; transform: translateX(calc(83vw - 6px)) translateY(-6px) rotate(45deg) ; background-color: #404352;">-->
<!--      </div>-->

<!--      <div class="w-100 d-flex flex-row justify-content-between align-items-center px-3"-->
<!--           style="color: #fcfdff; background-color: #404352;">-->
<!--        <div [innerHTML]="managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMIT_INFO | managerPipe" class="text-center py-1 "-->
<!--             style="font-family:'Roboto Condensed', sans-serif,sans-serif; font-size: 12px; letter-spacing: 0.6px;">-->
<!--        </div>-->
<!--        <div class=" py-1" style="font-family: Roboto, sans-serif; font-size: 10px; font-weight: 300;">-->
<!--          <table *ngIf="(0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)"-->
<!--                 class="w-100 text-right">-->
<!--            <tr>-->
<!--              <td class="pl-4"></td>-->
<!--              <td class="pl-4">{{managerService.config.DASHBOARD.DAILY_LIMIT | managerPipe}}</td>-->
<!--              <td class="pl-4">{{managerService.config.DASHBOARD.MONTHLY_LIMIT | managerPipe}}</td>-->
<!--            </tr>-->
<!--            <tr style="border-bottom: solid 1px rgba(240, 242, 247, 0.3);">-->
<!--              <td>{{managerService.config.DASHBOARD.DEPOSIT | managerPipe}}</td>-->
<!--              <td>{{0 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
<!--              <td>{{0 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td>{{managerService.config.DASHBOARD.WITHDRAWAL | managerPipe}}</td>-->
<!--              <td>{{0 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
<!--              <td>{{0 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
<!--            </tr>-->
<!--          </table>-->

<!--          <table *ngIf="(0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_GREATER_THAN: managerService.user_pipe)"-->
<!--                 class="w-100 text-right">-->
<!--            <tr>-->
<!--              <td class="pl-4"></td>-->
<!--              <td class="pl-4">{{managerService.config.DASHBOARD.DAILY_LIMIT | managerPipe}}</td>-->
<!--              <td class="pl-4">{{managerService.config.DASHBOARD.MONTHLY_LIMIT | managerPipe}}</td>-->
<!--            </tr>-->
<!--            <tr style="border-bottom: solid 1px rgba(240, 242, 247, 0.3);">-->
<!--              <td>{{managerService.config.DASHBOARD.DEPOSIT | managerPipe}}</td>-->
<!--              <td>{{managerService.userService.user?.daily_fiat_deposit_limit | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
<!--              <td>{{managerService.userService.user?.monthly_fiat_deposit_limit | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td>{{managerService.config.DASHBOARD.WITHDRAWAL | managerPipe}}</td>-->
<!--              <td>{{managerService.userService.user?.daily_fiat_withdrawal_limit | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
<!--              <td>{{managerService.userService.user?.monthly_fiat_withdrawal_limit | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
<!--            </tr>-->
<!--          </table>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

  <div class="w-100 px-3 my-2 py-2" style="font-family: Roboto, sans-serif; font-size: 10px; color: #595e7e;">
    <div class="mb-3">
      <div class="w-100 px-1 d-flex flex-row justify-content-between align-items-center">
        <div style="width: 30%; font-size: 12px">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.TL.HEADING | managerPipe}}
        </div>
        <div class="light-text" style="width: 35%;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.DEPOSIT | managerPipe}}
        </div>
        <div class="light-text" style="width: 35%;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.WITHDRAW | managerPipe}}
        </div>
      </div>
      <div [class.active-level-box]="0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe"
           class="w-100 px-1 py-2 d-flex flex-row justify-content-between" style="min-height: 48px;">
        <div [class.active-level-text]="0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe"
             style="width: 30%;" class="d-flex align-items-center light-text">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.LEVEL | managerPipe}} 0
        </div>
        <div class="d-flex align-items-center" style="width: 35%;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.TL.LEVEL_0_DESCRIPTION | managerPipe}}
        </div>
        <div class="d-flex align-items-center" style="width: 35%;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.TL.LEVEL_0_DESCRIPTION | managerPipe}}
        </div>
      </div>
      <div [class.active-level-box]="1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe"
           class="w-100 px-1 py-2 d-flex flex-row justify-content-between">
        <div [class.active-level-text]="1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe"
             style="width: 30%;" class="d-flex align-items-center light-text">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.LEVEL | managerPipe}} 1
        </div>
        <div style="width: 35%;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.TL.LEVEL_1_DAILY_LIMIT | managerPipe}}
          <span class="light-text">
            / {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.DAY | managerPipe}}
          </span>
          <br>
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.TL.LEVEL_1_MONTHLY_LIMIT | managerPipe}}
          <span class="light-text">
            / {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.MONTH | managerPipe}}
          </span>
        </div>
        <div style="width: 35%;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.TL.LEVEL_1_DAILY_LIMIT | managerPipe}}
          <span class="light-text">
            / {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.DAY | managerPipe}}
          </span>
          <br>
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.TL.LEVEL_1_MONTHLY_LIMIT | managerPipe}}
          <span class="light-text">
            / {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.MONTH | managerPipe}}
          </span>
        </div>
      </div>
      <div [class.active-level-box]="2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe"
           class="w-100 px-1 py-2 d-flex flex-row justify-content-between">
        <div [class.active-level-text]="2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe"
             style="width: 30%;" class="d-flex align-items-center light-text">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.LEVEL | managerPipe}} 2
        </div>
        <div style="width: 35%;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.TL.LEVEL_2_DAILY_LIMIT | managerPipe}}
          <span class="light-text">
            / {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.DAY | managerPipe}}
          </span>
          <br>
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.TL.LEVEL_2_MONTHLY_LIMIT | managerPipe}}
          <span class="light-text">
            / {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.MONTH | managerPipe}}
          </span>
        </div>
        <div style="width: 35%;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.TL.LEVEL_2_DAILY_LIMIT | managerPipe}}
          <span class="light-text">
            / {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.DAY | managerPipe}}
          </span>
          <br>
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.TL.LEVEL_2_MONTHLY_LIMIT | managerPipe}}
          <span class="light-text">
            / {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.MONTH | managerPipe}}
          </span>
        </div>
      </div>
    </div>
    <div>
      <div class="w-100 px-1 d-flex flex-row justify-content-between align-items-center">
        <div style="width: 30%; font-size: 12px">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.CRYPTO.HEADING | managerPipe}}
        </div>
        <div class="light-text" style="width: 35%;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.DEPOSIT | managerPipe}}
        </div>
        <div class="light-text" style="width: 35%;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.WITHDRAW | managerPipe}}
        </div>
      </div>
      <div [class.active-level-box]="0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe"
           class="w-100 px-1 py-2 d-flex flex-row justify-content-between" style="min-height: 48px;">
        <div [class.active-level-text]="0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe"
             style="width: 30%;" class="d-flex align-items-center light-text">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.LEVEL | managerPipe}} 0
        </div>
        <div class="d-flex align-items-center" style="width: 35%;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.TL.LEVEL_0_DESCRIPTION | managerPipe}}
        </div>
        <div class="d-flex align-items-center" style="width: 35%;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.TL.LEVEL_0_DESCRIPTION | managerPipe}}
        </div>
      </div>
      <div [class.active-level-box]="1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe"
           class="w-100 px-1 py-2 d-flex flex-row justify-content-between" style="min-height: 48px;">
        <div [class.active-level-text]="1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe"
             style="width: 30%;" class="d-flex align-items-center light-text">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.LEVEL | managerPipe}} 1
        </div>
        <div class="d-flex align-items-center" style="width: 35%;"
             [innerHTML]="managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.CRYPTO.DESCRIPTION | managerPipe">
        </div>
        <div class="d-flex align-items-center" style="width: 35%;"
             [innerHTML]="managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.CRYPTO.DESCRIPTION | managerPipe">
        </div>
      </div>
      <div [class.active-level-box]="2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe"
           class="w-100 px-1 py-2 d-flex flex-row justify-content-between">
        <div [class.active-level-text]="2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe"
             style="width: 30%;" class="d-flex align-items-center light-text">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.LEVEL | managerPipe}} 2
        </div>
        <div style="width: 35%;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.CRYPTO.LEVEL_2_DAILY_LIMIT | managerPipe}}
          <span class="light-text">
            / {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.DAY | managerPipe}}
          </span>
          <br>
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.CRYPTO.LEVEL_2_MONTHLY_LIMIT | managerPipe}}
          <span class="light-text">
            / {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.MONTH | managerPipe}}
          </span>
        </div>
        <div style="width: 35%;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.CRYPTO.LEVEL_2_DAILY_LIMIT | managerPipe}}
          <span class="light-text">
            / {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.DAY | managerPipe}}
          </span>
          <br>
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.CRYPTO.LEVEL_2_MONTHLY_LIMIT | managerPipe}}
          <span class="light-text">
            / {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.MONTH | managerPipe}}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div [class.d-none]="!(managerService.userService.user?.account_level === 0) | managerPipe" class="w-100" style="background-color: #f4f5f9">
    <div class="w-100 px-3 pt-3" style="font-family: 'Roboto Condensed', sans-serif; font-size: 16px; letter-spacing: 0.75px; color: #404352;">
      {{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_ONE.FORM_HEADING | managerPipe}}
    </div>
    <div class="w-100 px-3 py-2">
      <form [formGroup]="kycLevelOneForm" class="w-100 d-flex flex-column align-items-start justify-content-start" style="background-color: #ffffff">
        <div class="w-100 d-flex flex-column align-items-center justify-content-between">

          <div class="w-100 px-3 d-flex flex-column">
            <label class="position-relative my-3 d-flex flex-row" style="height: 30px;">
              <input class="w-100 input-field-underline kyc-level-one-input-field" formControlName="name"
                     placeholder="{{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_ONE.NAME | managerPipe}}"
                     required type="text">
            </label>
            <label class="position-relative my-3 d-flex flex-row" style="height: 30px;">
              <input class="w-100 input-field-underline kyc-level-one-input-field" formControlName="surname"
                     placeholder="{{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_ONE.SURNAME | managerPipe}}"
                     required type="text">
            </label>
            <div class="position-relative my-3 dropdown-native-button" style="height: 30px;">
              <label (click)="year_dropdown_menu.classList.remove('d-none')" class="position-relative d-flex flex-row" style="height: 30px; margin: 0;">
                <input class="w-100 input-field-underline kyc-level-one-input-field" formControlName="birth_year"
                       placeholder="{{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_ONE.BIRTH_YEAR | managerPipe}}" readonly required
                       style="cursor: pointer" type="number">
                <svg class="dropdown-icon">
                  <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#9DA5B7"/>
                </svg>
              </label>
              <div #year_dropdown_menu class="dropdown-native-menu-white d-none">
                <button (click)="year_dropdown_menu.classList.add('d-none'); kycLevelOneForm.get('birth_year')?.setValue(year);"
                        *ngFor="let year of (false | managerPipe: managerService.mpa_template.GET_BIRTH_YEAR_LIST)"
                        class="dropdown-native-item-white btn-native justify-content-center" type="button">
                  {{year | managerPipe}}
                </button>
              </div>
            </div>
            <label class="position-relative my-3 d-flex flex-row" style="height: 30px;">
              <input [maxLength]="11" class="w-100 input-field-underline kyc-level-one-input-field" formControlName="tc_no"
                     placeholder="{{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_ONE.TC | managerPipe}}"
                     required type="number"
                     oninput="this.value = this.value.slice(0,this.maxLength)">
            </label>
            <div class="position-relative my-3 dropdown-native-button" style="height: 30px;">
              <label (click)="town_dropdown_menu.classList.remove('d-none')" class="position-relative d-flex flex-row" style="height: 30px; margin: 0;">
                <input class="w-100 input-field-underline kyc-level-one-input-field" formControlName="town"
                       placeholder="{{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_ONE.TOWN | managerPipe}}" readonly required
                       style="cursor: pointer" type="text">
                <svg class="dropdown-icon">
                  <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#9DA5B7"/>
                </svg>
              </label>
              <div #town_dropdown_menu class="dropdown-native-menu-white d-none">
                <button (click)="town_dropdown_menu.classList.add('d-none'); kycLevelOneForm.get('town')?.setValue(town);" *ngFor="let town of (null | managerPipe: managerService.mpa_template.GET_CITIES_LIST)"
                        class="dropdown-native-item-white btn-native justify-content-center" type="button">
                  {{town | managerPipe}}
                </button>
              </div>
            </div>
            <label class="position-relative my-3 d-flex flex-row" style="height: 30px;">
              <input [maxLength]="10"
                     class="w-100 input-field-underline kyc-level-one-input-field" formControlName="phone_number" id="phone_number"
                     value=""
                     placeholder="{{managerService.config.USER_INFORMATION.PHONE_NUMBER}}"
                     oninput="this.value = this.value.slice(0,this.maxLength)"
                     type="number">
            </label>
            <label class="position-relative mt-3 d-flex flex-row" style="height: 30px;">
              <input class="w-100 input-field-underline kyc-level-one-input-field" formControlName="address"
                     placeholder="{{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_ONE.ADDRESS | managerPipe}}"
                     required type="text">
            </label>
          </div>
        </div>
        <div #warning_kyc_level_one class="invisible warning" style="margin: 4px 12px 0; font-size: 10px; letter-spacing: normal;"></div>
        <button (click)="verifyKYCLevelOne()" class="btn-native mx-auto mt-1 mb-3 kyc-button"
                style="width: 145px; height: 33px; border-radius: 19px; font-family: Roboto, sans-serif; font-size: 13px; font-weight: 300; letter-spacing: normal;"
                [disabled]="(kycLevelOneForm.invalid || level_one_button_lock) | managerPipe"
                [class.button-inactive]="(kycLevelOneForm.invalid || level_one_button_lock) | managerPipe">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_ONE.COMPLETE | managerPipe}}
        </button>
      </form>
    </div>
  </div>
  <div [class.d-none]="!(managerService.userService.user?.account_level === 1 && managerService.userService.user?.second_level_kyc_status !== 'applied') | managerPipe">
    <form [formGroup]="kycLevelTwoForm" class="d-flex flex-column align-items-start justify-content-start p-3">
      <div class="w-100" style="font-family: 'Roboto Condensed', sans-serif; font-size: 16px; letter-spacing: 0.75px; color: #404352;">
        {{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_TWO.FORM_HEADING | managerPipe}}
      </div>

      <div class="w-100 d-flex flex-row align-items-center justify-content-between">
        <div class="d-flex flex-column">
          <div class="w-100 d-flex flex-row align-items-center justify-content-center p-2 my-3"
               style="border-radius: 4px; box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #f4f5f9;">
            <div class="mx-2">
              <svg style="width: 52px; height: 30px">
                <use [attr.xlink:href]='"./assets/images/id_card.svg#id_card"' fill="#9DA5B7"/>
              </svg>
            </div>
            <span style="height: 26px; width: 1px; min-width: 1px; margin: auto 4px; border-radius:1px; background-color: #9da5b7;"></span>
            <div class="d-flex flex-column align-items-center justify-content-center mx-2"
                 style="font-family: Roboto, sans-serif; font-size: 10px; line-height: 1.3; letter-spacing: 0.3px; color: #404352;">
              {{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_TWO.ID_CARD_EXPLANATION | managerPipe}}
            </div>
          </div>
          <div class="my-1" style="font-family: Roboto, sans-serif; font-size: 12px; letter-spacing: 0.35px; color: #404352;">
            {{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_TWO.UPLOAD_FILE | managerPipe}}
          </div>
          <div class="my-1" style="font-family: Roboto, sans-serif; font-size: 11px; font-style: italic; line-height: 1.1; letter-spacing: 0.15px; color: #595e7e;">
            -{{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_TWO.ID_CARD_RULE | managerPipe}}
          </div>
          <label class="position-relative w-100 my-2 d-flex flex-row" style="height: 30px;">
            <input (change)="handleIDCardFileInput($event.target)" accept="image/*;capture=camera" class="position-absolute input-field-white" formControlName="id_card_photo_file" required
                   style="opacity: 0; cursor: pointer;" type='file'>
            <label class="input-field-white kyc-level-two-input-field">
              {{kycLevelTwoForm.get('id_card_photo_file')?.value | managerPipe: managerService.mpa_template.FILE_INPUT_TEXT_TRANSFORM: managerService.language_pipe}}
            </label>
            <button class="btn-native" type="button">
              <svg (click)="resetIDCardFile()" class="cancel-icon">
                <use [attr.xlink:href]='"./assets/images/close.svg#close"' fill="#9DA5B7"/>
              </svg>
            </button>
          </label>

          <div class="w-100 d-flex flex-row align-items-center justify-content-center p-2 mt-4 mb-3"
               style="border-radius: 4px; box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #f4f5f9;">
            <div class="mx-2">
              <svg style="width: 52px; height: 30px">
                <use [attr.xlink:href]='"./assets/images/face_and_id_card.svg#face_and_id_card"' fill="#9DA5B7"/>
              </svg>
            </div>
            <span style="height: 26px; width: 1px; min-width: 1px; margin: auto 4px; border-radius:1px; background-color: #9da5b7;"></span>
            <div class="d-flex flex-column align-items-center justify-content-center mx-2"
                 style="font-family: Roboto, sans-serif; font-size: 10px; line-height: 1.3; letter-spacing: 0.3px; color: #404352;">
              {{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_TWO.FACE_EXPLANATION | managerPipe}}
            </div>
          </div>
          <div class="my-1" style="font-family: Roboto, sans-serif; font-size: 12px; letter-spacing: 0.35px; color: #404352;">
            {{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_TWO.UPLOAD_FILE | managerPipe}}
          </div>
          <div class="my-1" style="font-family: Roboto, sans-serif; font-size: 11px; font-style: italic; line-height: 1.1; letter-spacing: 0.15px; color: #595e7e;">
            -{{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_TWO.FACE_RULE | managerPipe}}
          </div>
          <label class="position-relative w-100 my-2 d-flex flex-row" style="height: 30px;">
            <input (change)="handleFaceFileInput($event.target)" accept="image/*;capture=camera" class="position-absolute input-field-white" formControlName="face_photo_file" required
                   style="opacity: 0; cursor: pointer;" type='file'>
            <label class="input-field-white kyc-level-two-input-field">
              {{kycLevelTwoForm.get('face_photo_file')?.value | managerPipe: managerService.mpa_template.FILE_INPUT_TEXT_TRANSFORM: managerService.language_pipe}}
            </label>
            <button class="btn-native" type="button">
              <svg (click)="resetFaceFile()" class="cancel-icon">
                <use [attr.xlink:href]='"./assets/images/close.svg#close"' fill="#9DA5B7"/>
              </svg>
            </button>
          </label>
        </div>
      </div>
      <div #warning_kyc_level_two class="invisible warning" style="padding: 8px 12px 0; font-size: 10px; letter-spacing: normal;"></div>
      <button (click)="verifyKYCLevelTwo()" [class.button-inactive]="!(id_card_photo_file && face_photo_file) || level_two_button_lock | managerPipe"
              [disabled]="!(id_card_photo_file && face_photo_file) || level_two_button_lock | managerPipe"
              [style]="{'background-image': 'linear-gradient(90deg, #62e8ff 0%, #7ab7ff ' + (process_bar_percentage*100) + '%, #ffffff ' + (process_bar_percentage*100) + '%)'}"
              class="btn-native mx-auto my-2 kyc-button"
              style="width: 145px; height: 33px; border-radius: 19px; font-family: Roboto, sans-serif; font-size: 13px; font-weight: 300; letter-spacing: normal;">
        {{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_TWO.COMPLETE | managerPipe}}
      </button>
    </form>
  </div>
  <div [class.d-none]="!(managerService.userService.user?.account_level === 1 && managerService.userService.user?.second_level_kyc_status === 'applied')  | managerPipe"
       class="w-100 flex-row align-items-center justify-content-start p-3"
       style="font-family: Roboto, sans-serif; font-size: 12px; line-height: 1.5; letter-spacing: 0.5px; color: #404352; background-color: #ffffff; min-height: 400px">
    <div class="d-flex ">
      <svg class="mr-2" style="height: 30px; width: 30px; flex-shrink: 0">
        <use [attr.xlink:href]='"./assets/images/second_level_kyc_pending.svg#second_level_kyc_pending"' fill="white"/>
      </svg>
      <span [innerHTML]="managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_TWO.WAITING_FOR_APPROVAL | managerPipe"></span>
    </div>
  </div>
  <div [class.d-none]="!(managerService.userService.user?.account_level===2) | managerPipe"
       class="w-100 flex-row align-items-center justify-content-start p-3"
       style="font-family: Roboto, sans-serif; font-size: 12px; line-height: 1.5; letter-spacing: 0.5px; color: #404352; background-color: #ffffff; min-height: 400px">
    <div class="d-flex ">
      <svg class="mr-2" style="height: 30px; width: 30px; flex-shrink: 0">
        <use [attr.xlink:href]='"./assets/images/second_level_kyc_verified.svg#second_level_kyc_verified"' fill="white"/>
      </svg>
      <span [innerHTML]="managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_TWO.COMPLETED | managerPipe"></span>
    </div>
  </div>
</div>



