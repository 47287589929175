import {Component, OnInit, ViewChild} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
  selector: 'app-announcement-slider-mobile',
  templateUrl: './announcement-slider-mobile.component.html',
  styleUrls: ['./announcement-slider-mobile.component.css']
})
export class AnnouncementSliderMobileComponent implements OnInit {
  @ViewChild('announcement_card_container') announcement_card_container: any = undefined;
  public current_slider_index: number = 0;
  public interval: any;

  constructor(public managerService: ManagerService) { }

  ngOnInit(): void {
    this.interval = setInterval(() => {
      this.swipeLeft();
    }, 10000);
  }

  swipeLeft(): void {
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.swipeLeft();
    }, 10000);
    if (this.current_slider_index < (this.managerService.config.ANNOUNCEMENTS_SLIDER.length - Math.round(this.announcement_card_container.nativeElement.offsetWidth / 348))) {
      this.current_slider_index = this.current_slider_index + 1;
    } else if (this.current_slider_index === (this.managerService.config.ANNOUNCEMENTS_SLIDER.length - Math.round(this.announcement_card_container.nativeElement.offsetWidth / 348))) {
      this.current_slider_index = 0;
    }
  }

  swipeRight(): void {
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.swipeLeft();
    }, 10000);
    if (this.current_slider_index > 0) {
      this.current_slider_index = this.current_slider_index - 1;
    }
  }

}
