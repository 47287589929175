<div class="d-flex flex-column justify-content-center mb-3 mx-3">
    <div class="d-flex w-100 flex-row justify-content-between  align-items-center"
         style=" padding: 24px;">
        <div (click)="toggleFilterForm()" class="d-flex" style="cursor: pointer; user-select: none">
            <svg style="cursor: pointer; height: 22px; width: 22px;">
                <use [attr.xlink:href]='"./assets/images/filter.svg#filter"' fill="#000015"/>
            </svg>
            <span style=" margin-left:10px; font-family: Roboto, sans-serif;font-style: normal;font-weight: 500;font-size: 16px;
            line-height: 19px;display: flex;align-items: center;text-align: center;letter-spacing: 0.02px;color: #000015;">
            {{managerService.config.AUCTION.FILTER.TITLE | managerPipe}}
            </span>
        </div>
        <div class="d-flex  dropdown-native-button"
             style="width: 200px; border-radius: 4px; background-color: #FCFDFF; box-shadow: 0px 8px 24px 0px #00000026; height: 32px;">
            <div class="d-flex flex-row align-items-center justify-content-between w-100" style="">
                <div class="d-flex ml-2"
                     style="font-family: Roboto, sans-serif;font-style: normal;font-weight: 400;font-size: 14px;line-height: normal;letter-spacing: 0.01px;color: #404352;  white-space: nowrap">
                    {{managerService.auctionService.active_catalog_sorting_type_pipe | managerPipe: managerService.mpa_template.AUCTION_CATALOG_ACTIVE_SORT_OPTION: managerService.language_pipe}}

                </div>
                <div class="d-flex mx-2">
                    <svg style=" height: 16px; width: 16px;">
                        <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#404352"/>
                    </svg>
                </div>
            </div>
            <div class="dropdown-native-menu overflow-visible "
                 style="width: 200px; border-radius: 4px; margin-top: 32px;  background-color: #FCFDFF;  box-shadow: 0px 8px 24px 0px #00000026; ">
                <div
                    (click)="managerService.stopProp($event); managerService.auctionService.setActiveCatalogSortingType(i);"
                    *ngFor="let option of ( null |managerPipe: managerService.mpa_template.AUCTION_CATALOG_SORT_OPTIONS); index as i"
                    class="dropdown-native-item-white  btn-native justify-content-center "
                    style="width: inherit;  height: 40px;" type="button">
                    <div
                        style="font-family: Roboto, sans-serif; font-style: normal;font-weight: 400;font-size: 14px;line-height: normal;letter-spacing: 0.01px;color: #404352 ">
                        {{option}}
                    </div>
                </div>
            </div>

        </div>


    </div>
    <div [class.d-none]="!showFilterForm" class="d-flex w-100"
         style="margin-top: 10px;min-width: 300px; width: 22%">
        <div class="d-flex w-100">
            <form [formGroup]="filterForm"
                  class="d-flex w-100 flex-column align-self-start"
                  style="background: #FCFDFF;box-shadow: 0px 2px 9px rgba(96, 134, 195, 0.22);border-radius: 6px;">
                <div class="d-flex justify-content-between">
                    <div class="d-flex"
                         style="user-select: none; font-family: Roboto, sans-serif;font-style: normal;font-weight: 700;font-size: 16px;line-height: 19px;align-items: center;letter-spacing: 0.02px;color: #404352; margin-left: 20px;margin-top: 20px">
                        {{managerService.config.AUCTION.FILTER.FILTERS | managerPipe}}
                    </div>
                    <div #clear_all (click)="clearAll()"
                         style="display: none; cursor:pointer; color: #FF207D;font-family: Roboto,sans-serif;font-size: 12px;font-style: normal;font-weight: 400;line-height: normal;letter-spacing: 0.05px;margin-right: 20px;margin-top: 20px">
                        {{managerService.config.AUCTION.FILTER.CLEAR | managerPipe}}
                    </div>
                </div>
                <div #display_filters class="w-100 justify-content-center" style="display: none; margin-top: 10px;">
                    <div class="d-flex w-90">
                        <div #clear_keyword
                             (click)="clearKeyword()"
                             style="display:none;cursor: pointer; border: none ;box-shadow: 0px 2px 9px rgba(96, 134, 195, 0.22);border-radius: 6px; padding-left: 10px; padding-right: 10px">
                            {{managerService.config.AUCTION.FILTER.ARTIST_PRODUCT | managerPipe}}
                        </div>
                        <div #clear_price
                             (click)="clearPrice()"
                             style="cursor: pointer; display:none;border: none ;box-shadow: 0px 2px 9px rgba(96, 134, 195, 0.22);border-radius: 6px; padding-left: 10px; padding-right: 10px">
                            {{managerService.config.AUCTION.FILTER.PRICE | managerPipe}}
                        </div>
                    </div>
                </div>
                <div (click)="toggleDisplay(display_keyword)" class="d-flex w-100"
                     style="user-select: none;cursor:pointer; font-family: Roboto, sans-serif;font-style: normal;font-weight: 700;font-size: 16px;line-height: 19px;align-items: center;letter-spacing: 0.02px;color: #404352; margin-left: 20px;margin-top: 24px">
                    {{managerService.config.AUCTION.FILTER.ARTIST_PRODUCT | managerPipe}}
                </div>
                <div #display_keyword class="w-100 justify-content-center" style="display: none; margin-top: 10px;">
                    <input class="w-90" formControlName="keyword"
                           placeholder="{{managerService.config.AUCTION.FILTER.KEYWORD | managerPipe}}"
                           style="border: none ;box-shadow: 0px 2px 9px rgba(96, 134, 195, 0.22);border-radius: 6px; "
                           type="text">
                    <svg
                        style=" height: 12px; width: 12px; display: inline; justify-self: end; align-self: center; margin-left: -15px">
                        <use [attr.xlink:href]='"./assets/images/search.svg#search"' fill="#1E1F25"/>
                    </svg>
                </div>
                <div (click)="toggleDisplay(display_price)" class="d-flex w-100"
                     style="user-select: none;cursor:pointer; font-family: Roboto, sans-serif;font-style: normal;font-weight: 700;font-size: 16px;line-height: 19px;align-items: center;letter-spacing: 0.02px;color: #404352; margin-left: 20px;margin-top: 24px; margin-bottom: 20px">
                    {{managerService.config.AUCTION.FILTER.PRICE | managerPipe}}
                </div>
                <div #display_price class="w-100 justify-content-center"
                     style="display: none; margin-top: -10px; margin-bottom: 20px">
                    <div class="d-flex w-90">
                        <input class="w-50" formControlName="min"
                               placeholder="{{managerService.config.AUCTION.FILTER.PRICE_MIN | managerPipe}}"
                               style="display: flex;border: none ;box-shadow: 0px 2px 9px rgba(96, 134, 195, 0.22);border-radius: 6px; "
                               type="number">
                        <svg
                            style="cursor: pointer; height: 21px; width: 14px; justify-self: center; align-self: center; margin-left: 10px; margin-right: 10px">
                            <use [attr.xlink:href]='"./assets/images/switch.svg#switch"' fill="#343640"/>
                        </svg>
                        <input class="w-50" formControlName="max"
                               placeholder="{{managerService.config.AUCTION.FILTER.PRICE_MAX | managerPipe}}"
                               style="display: flex;border: none ;box-shadow: 0px 2px 9px rgba(96, 134, 195, 0.22);border-radius: 6px; "
                               type="number">
                    </div>
                </div>

                <div (click)="addFilter()" class="d-flex m-2 px-3 py-1 justify-content-center"
                     style="cursor: pointer; user-select: none;border-radius: 4px;background: #8266F0;">
                    <svg style="cursor: pointer; height: 22px; width: 22px;">
                        <use [attr.xlink:href]='"./assets/images/filter.svg#filter"' fill="#FFFFFF"/>
                    </svg>
                    <span
                        style=" margin-left:10px; font-family: Roboto, sans-serif;font-style: normal;font-weight: 500;font-size: 16px; line-height: 19px;display: flex;align-items: center;text-align: center;letter-spacing: 0.02px;color: #FFFFFF;">
            {{managerService.config.AUCTION.FILTER.APPLY | managerPipe}}
            </span>
                </div>
            </form>
        </div>

    </div>


    <div
        *ngIf="(managerService.auctionService.auction_product_list_pipe | managerPipe: managerService.mpa_template.GET_FILTERED_SORTED_AUCTION_PRODUCT_LIST: managerService.auctionService.auction_product_filter_pipe: managerService.auctionService.active_catalog_sorting_type_pipe).length === 0"
        class="mt-4"
        style="min-width: 290px; font-family: Roboto,sans-serif; font-weight: 500; font-size: 14px; letter-spacing: 0.02px; color: #404352;">
        {{managerService.config.AUCTION.FILTER.NO_RESULT | managerPipe}}
    </div>
    <div
        (click)="managerService.navigateIfLoggedIn({url: '/auction/auction_product', active_auction_product_hash: auction_product.hash, current_component_index: 3});"
        *ngFor="let auction_product of (managerService.auctionService.auction_product_list_pipe | managerPipe: managerService.mpa_template.GET_FILTERED_SORTED_AUCTION_PRODUCT_LIST: managerService.auctionService.auction_product_filter_pipe: managerService.auctionService.active_catalog_sorting_type_pipe) "
        [ngClass]="auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_STATUS_STYLE"
        class="d-flex w-100 flex-column justify-content-center align-items-center mt-4 lot-card"
        style=" min-width: 290px;   border-radius: 8px; box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.24), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); cursor: pointer; position: relative; background: #FCFDFF;
         border: 1px solid var(--secondary-colors-artiox-gray-base, #E2E2E2);">
        <div class="d-flex w-100 flex-column justify-content-center">
            <div
                *ngIf="(auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_OWNERSHIP_STATUS: managerService.user_bids_pipe) "
                class="d-flex w-100 justify-content-center align-items-center exo subtitle2 bold"
                style="height: 32px; background: var(--main-color-artiox-purple-200, #ECE8FD);color: #8266F0">
                LOT {{auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_LOT }} {{managerService.config.AUCTION.CATALOG_LOT_IS_YOURS | managerPipe}}
            </div>
            <div
                *ngIf="!(auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_OWNERSHIP_STATUS: managerService.user_bids_pipe) "
                class="d-flex w-100 justify-content-center align-items-center exo subtitle2 medium"
                style="height: 32px;background: var(--secondary-colors-artiox-gray-300, #F6F6F6);color: #6F6F6F">
                LOT {{auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_LOT}}
            </div>
        </div>
        <div class="d-flex w-100 flex-column justify-content-center" style="padding: 24px">

            <div class="d-flex p-4 pb-3 justify-content-center align-items-center">
                <div class="d-flex justify-content-center align-items-center" style="max-width:270px;">
                    <img #catalog_img (error)="catalog_img.src = catalog_img.src.replace('.webp', '.jpg');"
                         alt="catalog_img"
                         height="240px"
                         src="{{auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_THUMBNAIL | managerPipe: managerService.mpa_template.LOCAL_IMAGE_MANAGER_TO_BE}}"
                         width="100%">
                </div>
            </div>
            <div style="font-family: Roboto,sans-serif; text-align: center">
                <div class="mb-1 line-clamp-1"
                     style="font-family: 'Poppins', sans-serif;font-style: normal;font-weight: 500;font-size: 14px;line-height: normal;letter-spacing: 0.03px;color: #404352;">
                    {{ auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_ARTIST_NAME }}
                </div>
                <div class="mb-1 line-clamp-1"
                     style="font-family: 'Poppins', sans-serif;font-style: italic;font-weight: 500;font-size: 12px;line-height:normal;letter-spacing: 0.05px;color: #707989;">
                    {{ auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_NAME }}
                </div>
                <div [innerHTML]="auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_TEXT"
                     class="mb-1 line-clamp-2"
                     style="font-family: Roboto, sans-serif;font-style: normal;font-weight: 400;font-size: 10px;line-height: normal;letter-spacing: 0.01px;color: #404352;">
                </div>
            </div>
            <div class="d-flex w-100 flex-column mt-2"
                 style="font-family: Roboto, sans-serif;font-style: normal;font-weight: 500;font-size: 12px;line-height: 14px;letter-spacing: 0.06px;">
                <div (click)="managerService.stopProp($event);managerService.openModal('auction-duration-rules-modal')"
                     class="w-100 d-flex flex-row justify-content-end" style="cursor: pointer; height: 20px;">
                    <svg style="height: 12px; width: 12px; margin-right: 6px">
                        <use [attr.xlink:href]='"./assets/images/info.svg#info"' fill="#111111"/>
                    </svg>
                    <span
                        [style]=" (auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_COUNTDOWN ) | async | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_TIME_STYLE: auction_product">
                        {{(auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_COUNTDOWN) | async | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_REMAIN_TIME: auction_product}}
                    </span>
                </div>
                <div
                    [style]="(auction_product| managerPipe: managerService.mpa_template.AUCTION_PRODUCT_COUNTDOWN) | async | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_COUNTDOWN_BAR : auction_product"
                    class="public-sale-bar my-1"
                    style="align-self: stretch; height: 10px; border-radius: 100px"></div>
                <div [ngClass]="auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_STATUS_STYLE"
                     class="w-100 d-flex flex-row justify-content-end"
                     style="height: 20px;">
                  <span class="end-date">
                    {{(auction_product| managerPipe: managerService.mpa_template.AUCTION_PRODUCT_END_DATE) | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME }}
                      /
                  </span>
                    <span class="end-date" style="color: #FFBC04">
                    {{auction_product| managerPipe: managerService.mpa_template.AUCTION_PRODUCT_TIME_INCREMENT_TEXT}}
                  </span>
                    <span class="end-text"
                          style="font-family: Roboto, sans-serif;font-style: normal;font-weight: 500;font-size: 12px;line-height: 14px;letter-spacing: 0.06px;color: #F11658;">
                        {{managerService.config.AUCTION.END_TEXT | managerPipe}}
                    </span>
                </div>
            </div>
            <div class="w-100 my-4 d-flex flex-row justify-content-center">
                <div class="my-auto text-center">
                    <div class="mb-1"
                         style="font-family: Roboto, sans-serif;font-style: normal;font-weight: 400;font-size: 16px;line-height: normal;display: flex;align-items: center;letter-spacing: 0.01px;color: #6F6F6F;">{{auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_OPENING_VALUE| managerPipe: managerService.mpa_template.FANCY_NUMBER_NO_DECIMAL}}
                        TL
                    </div>
                    <div
                        style="font-family: Roboto, sans-serif;color: #AAAEB5;font-style: normal;font-weight: 400;font-size: 12px;line-height: normal;display: flex;align-items: center;letter-spacing: 0.05px;">
                        {{managerService.config.AUCTION.OPENING_PRICE | managerPipe}}</div>
                </div>
                <span class="mx-5" style="width: 1px; height: 60px; background-color: #d8d8d8;"></span>
                <div class="my-auto text-center">
                    <div class="mb-1"
                         style="font-family: Roboto, sans-serif;font-style: normal;font-weight: 600;font-size: 16px;line-height: normal;letter-spacing: 0.02px;color: #6F6F6F;">
                        {{auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_CURRENT_VALUE: auction_product.active_bid_price_pipe}}
                        TL
                    </div>
                    <div
                        style="font-family: Roboto, sans-serif;font-style: normal;font-weight: 400;font-size: 12px;line-height: normal;letter-spacing: 0.06px;color: #AAAEB5;">
                        {{managerService.config.AUCTION.CURRENT_PRICE | managerPipe}}</div>
                </div>
            </div>
            <div *ngIf="auction_product.auction_state === 'live'"

                 class="d-flex w-100 flex-row justify-content-center align-items-center"
                 style=" padding-bottom: 8px">
                <div class="d-flex w-100 flex-row justify-content-around" style="gap: 8px">
                    <div class="d-flex w-50 justify-content-end">
                        <div (click)="managerService.stopProp($event)"
                             class="d-flex justify-content-between quick-bid-button"
                             style="padding:  6px;border-radius: 6px;border: 1px solid var(--secondary-colors-artiox-gray-base, #E2E2E2);background: var(--background-color-artiox-background, #FFF)">
                            <div
                                (click)="auction_product.setActiveBidPrice(auction_product.active_bid_price - auction_product.value_step)"
                                *ngIf="auction_product.active_bid_price > (auction_product.current_value + auction_product.value_step) && auction_product.active_bid_price > auction_product.opening_value"
                                style="cursor: pointer; user-select: none">
                                <svg style="height: 24px; width: 24px;">
                                    <use [attr.xlink:href]='"./assets/images/decrease.svg#decrease"' fill="none"
                                         stroke="#6F6F6F"/>
                                </svg>
                            </div>
                            <div class="barlow body2 medium" style="color: #6F6F6F; padding: 0 8px">
                                {{(auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_ACTIVE_BID : auction_product.active_bid_price_pipe)}}
                                TL
                            </div>
                            <div
                                (click)="auction_product.setActiveBidPrice(auction_product.active_bid_price + auction_product.value_step)"
                                style="cursor: pointer; user-select: none">
                                <svg style="height: 24px; width: 24px">
                                    <use [attr.xlink:href]='"./assets/images/increment.svg#increment"'/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex w-50 justify-content-start">
                        <div
                            (click)="managerService.stopProp($event);managerService.setActiveAuctionProduct(auction_product, false);managerService.auctionService.setActiveBidPrice(managerService.auctionService.active_auction_product.active_bid_price);managerService.openModalWithKyc('auction-bid-report-modal', 1);managerService.report_bid_modal.emit()"
                            class=" barlow text-center"
                            style="user-select:none;cursor: pointer; background: #8266F0; color: #FFFFFF; border-radius: 8px;padding: 8px 16px;">
                            {{managerService.config.AUCTION.BID | managerPipe}}
                            <svg class="ms-2" style="height: 20px; width: 20px; margin-top: -4px">
                                <use [attr.xlink:href]='"./assets/images/gavel_white.svg#gavel_white"' fill="none" stroke="white"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="auction_product.auction_state !== 'live'"
                 class="d-flex w-100 flex-row justify-content-center align-items-center"
                 style="padding-bottom: 16px">
                <div
                    (click)="managerService.stopProp($event);managerService.setActiveAuctionProduct(auction_product, false);managerService.openModal('auction-product-information-modal');"
                    class="d-flex text-center barlow"
                    style="font-size: 16px;font-style: normal;font-weight: 600;line-height: normal;cursor: pointer; width: 175px; background: #F3F0FE; border-radius: 6px; color:#8266F0; padding: 8px 16px 8px 16px ">
                    {{managerService.config.AUCTION.PRODUCT_INFO | managerPipe}}
                </div>
            </div>
        </div>

    </div>
</div>
