<div class="d-flex flex-column" style="min-width: 350px">
    <div class="d-flex w-100 flex-column align-items-center" style="gap: 16px">
        <div class="barlow"
             style="color: var(--text-color-artiox-black, #131515);font-size: 17px;font-style: normal;font-weight: 500;line-height: 20px;text-decoration-line: underline;">
            {{ managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_ARTIST_NAME }}
        </div>
        <div class="subtitle2 medium exo" style="color: var(--text-color-artiox-grey, #6F6F6F);">
            {{ managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_NAME }}
        </div>
        <div
            [innerHTML]="managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_TEXT"
            class="caption regular barlow text-center" style="color: var(--text-color-artiox-grey, #6F6F6F);">
        </div>
    </div>
    <div class="d-flex flex-column" style="height: 60px;margin-top: 48px">
        <div (click)="managerService.openModal('auction-duration-rules-modal');"
             class="w-100 d-flex flex-row justify-content-end" style="cursor: pointer; height: 20px;">
            <svg style="height: 16px; width: 16px; margin-right: 10px">
                <use [attr.xlink:href]='"./assets/images/info.svg#info"' fill="#B5B5B5"/>
            </svg>
            <span
                [style]="(managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_COUNTDOWN) | async | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_TIME_STYLE">
                {{(managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_COUNTDOWN) | async | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_REMAIN_TIME}}</span>
        </div>
        <div
            [style]="(managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_COUNTDOWN) | async | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_COUNTDOWN_BAR"
            style="height: 10px;border-radius: 100px;margin: 5px 0px 5px 0px">
        </div>
        <div class="w-100 d-flex flex-row justify-content-end">
      <span class="barlow caption medium" style="color: var(--secondary-colors-artiox-gray-500, #B5B5B5);">
        {{managerService.config.AUCTION.AUCTION_END | managerPipe}}
          : {{managerService.auctionService.active_auction_product_pipe | managerPipe:managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_END_DATE | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME }}
          /
      </span>
            <span class="barlow caption medium" style="color: var(--secondary-colors-artiox-yellow-base, #FFC300);">
        {{managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_TIME_INCREMENT_TEXT}}
      </span>
        </div>
    </div>

    <div *ngIf=" this.managerService.auctionService.active_auction_product.auction_state  === 'live'"
         [style.padding-top]="(managerService.auctionService.active_auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_OWNERSHIP_STATUS: managerService.user_bids_pipe) ? '0px': '28px'"
         class="d-flex flex-column justify-content-center align-items-center"
         style="border-radius: 8px;border: 1px solid var(--secondary-colors-artiox-gray-base, #E2E2E2);margin-top: 48px">

        <div class="d-flex flex-row w-100 position-relative">
            <div
                *ngIf="(managerService.auctionService.active_auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_OWNERSHIP_STATUS: managerService.user_bids_pipe) "
                class="d-flex w-100 justify-content-center align-items-center exo subtitle1 bold mb-2"
                style="height: 32px; padding: 4px 8px;background: var(--main-color-artiox-purple-200, #ECE8FD);color: #8266F0">
                {{managerService.config.AUCTION.LOT_IS_YOURS | managerPipe}}
            </div>
            <div (click)="managerService.openModal('auction-bid-rules-modal')"
                 [style.top]="(managerService.auctionService.active_auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_OWNERSHIP_STATUS: managerService.user_bids_pipe) ? '0px': '-24px'"
                 class=" position-absolute"
                 style="right: 8px">
                <svg style="cursor: pointer; height: 24px; width: 24px;">
                    <use [attr.xlink:href]='"./assets/images/v2/ic_warning.svg#ic_warning"'
                         fill="none" stroke="var(--secondary-colors-artiox-gray-500, #B5B5B5)"/>
                </svg>
            </div>
        </div>


        <div class="w-100 px-5 mb-4 d-flex flex-row justify-content-center">
            <div class="my-auto text-center">
                <div class="barlow body2 medium"
                     style="color: var(--text-color-artiox-grey, #6F6F6F);margin-bottom: 8px">{{managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_OPENING_PRICE | managerPipe: managerService.mpa_template.FANCY_NUMBER_NO_DECIMAL}}
                    TL
                </div>
                <div class="barlow caption regular"
                     style="color: #B5B5B5">{{managerService.config.AUCTION.OPENING_PRICE | managerPipe}}</div>
            </div>
            <span class="mx-4" style="width: 1px; height: 60px; background-color: #d8d8d8;"></span>
            <div class="my-auto text-center">
                <div class="barlow body2 medium"
                     style="color: var(--text-color-artiox-grey, #6F6F6F);margin-bottom: 8px">
                    {{managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_CURRENT_PRICE: 0}}
<!--                    {{managerService.auctionService.active_auction_product.value_type | managerPipe}}-->
                </div>
                <div class="barlow caption regular"
                     style="color: #B5B5B5">{{managerService.config.AUCTION.CURRENT_PRICE | managerPipe}}</div>
            </div>
        </div>
        <div class="d-flex w-100 flex-row justify-content-around" style="gap: 8px">
            <div class="d-flex w-50 justify-content-end">
                <div class="d-flex justify-content-between"
                     style="padding:  6px;border-radius: 6px;border: 1px solid var(--secondary-colors-artiox-gray-base, #E2E2E2);background: var(--background-color-artiox-background, #FFF)">
                    <div
                        (click)="managerService.auctionService.setActiveAuctionProductBidPrice(managerService.auctionService.active_auction_product.active_bid_price - managerService.auctionService.active_auction_product.value_step)"
                        *ngIf="managerService.auctionService.active_auction_product.active_bid_price > (managerService.auctionService.active_auction_product.current_value + managerService.auctionService.active_auction_product.value_step) && managerService.auctionService.active_auction_product.active_bid_price > managerService.auctionService.active_auction_product.opening_value"
                        style="cursor: pointer; user-select: none">
                        <svg style="height: 24px; width: 24px;">
                            <use [attr.xlink:href]='"./assets/images/decrease.svg#decrease"' fill="none"
                                 stroke="#6F6F6F"/>
                        </svg>
                    </div>
                    <div class="barlow body2 medium" style="color: #6F6F6F; padding: 0 8px">
                        {{managerService.auctionService.active_bid_price_pipe | managerPipe: managerService.mpa_template.ACTIVE_BID_PRICE}}
                        TL
                    </div>
                    <div
                        (click)="managerService.auctionService.setActiveAuctionProductBidPrice(managerService.auctionService.active_auction_product.active_bid_price + managerService.auctionService.active_auction_product.value_step)"
                        style="cursor: pointer; user-select: none">
                        <svg style="height: 24px; width: 24px;">
                            <use [attr.xlink:href]='"./assets/images/increase.svg#increase"' fill="none"
                                 stroke="#6F6F6F"/>
                        </svg>
                    </div>
                </div>
            </div>

            <div class="d-flex w-50 justify-content-start">
                <div
                    (click)="managerService.auctionService.setActiveBidPrice(managerService.auctionService.active_auction_product.active_bid_price);managerService.openModalWithKyc('auction-bid-report-modal', 1);managerService.report_bid_modal.emit()"
                    class=" barlow text-center"
                    style="user-select:none;cursor: pointer; background: #8266F0; color: #FFFFFF; border-radius: 8px;padding: 8px 16px;">
                    {{managerService.config.AUCTION.BID | managerPipe}}
                    <svg class="ms-2" style="height: 20px; width: 20px; margin-top: -4px">
                        <use [attr.xlink:href]='"./assets/images/gavel_white.svg#gavel_white"' fill="#fff" stroke="white"/>
                    </svg>
                </div>
            </div>
        </div>

        <div class="d-flex w-100 flex-row justify-content-around " style="padding: 24px 16px">
            <div #gavel (click)="scrollBids()" style="cursor: pointer">
                <svg class="me-1" style="height: 22px; width: 22px; margin-top: -4px;">
                    <use [attr.xlink:href]='"./assets/images/gavel.svg#gavel"' fill="#6F6F6F" stroke="#6F6F6F"/>
                </svg>
                {{[managerService.bidService.bid_list_pipe | managerPipe: managerService.mpa_template.GET_LAST_BID_COUNT] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.AUCTION.BIDS}}
            </div>

            <div (click)="managerService.openModal('auction-share-modal')" style="cursor: pointer">
                <svg class="me-1" style="height: 22px; width: 19px; margin-top: -4px;">
                    <use [attr.xlink:href]='"./assets/images/share.svg#share"' fill="#6F6F6F" stroke="#6F6F6F"/>
                </svg>
                {{ managerService.config.AUCTION.SHARE.SHARE | managerPipe }}
            </div>
        </div>
    </div>
    <div *ngIf="this.managerService.auctionService.active_auction_product.auction_state  === 'knockdown'"
         class="d-flex flex-column justify-content-center align-items-center"
         style="border-radius: 8px;border: 1px solid var(--secondary-colors-artiox-gray-base, #E2E2E2);margin-top: 48px;">

        <div (click)="managerService.openModal('auction-bid-rules-modal')" class="d-flex align-self-end"
             style="padding-right: 8px; padding-top: 4px; padding-bottom: 12px">
            <svg style="cursor: pointer; height: 24px; width: 24px;">
                <use [attr.xlink:href]='"./assets/images/v2/ic_warning.svg#ic_warning"'
                     fill="none" stroke="var(--secondary-colors-artiox-gray-500, #B5B5B5)"/>
            </svg>
        </div>
        <div class="d-flex w-100 flex-column justify-content-center align-items-center text-center"
             style="padding: 0 8px">
            <span class="barlow caption regular" style="color: var(--text-color-artiox-black); margin-bottom: 40px">
                {{[managerService.auctionService.active_auction_product.auction_end_date | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.AUCTION.AUCTION_FINALIZED.HEADER}}
            </span>

            <div class="d-flex flex-row justify-content-between w-100" style="margin-bottom: 16px; padding: 0 8px">
                <span class="barlow body2 medium" style="color: var(--secondary-colors-artiox-gray-500)">
                    {{managerService.config.AUCTION.AUCTION_FINALIZED.CLOSE}}
                </span>
                <span class="barlow body2 bold" style="color:  var(--text-color-artiox-grey, #6F6F6F);">
                    {{managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_CURRENT_PRICE}}
<!--                    {{managerService.auctionService.active_auction_product.value_type | managerPipe}}-->
                </span>
            </div>
            <span
                style="width: 90%; height: 1px; background-color:var(--secondary-colors-artiox-gray-base, #E2E2E2); margin-bottom: 16px"></span>

            <div class="d-flex flex-row justify-content-between w-100" style="margin-bottom: 16px; padding: 0 8px">
                <span class="barlow body2 medium" style="color: var(--secondary-colors-artiox-gray-500)">
                    {{managerService.config.AUCTION.BID_REPORT.TOTAL}}
                </span>
                <span class="barlow body2 bold" style=" color: var(--main-color-artiox-purple-base, #8266F0);">
                    {{managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_TOTAL_PRICE}}
<!--                    {{managerService.auctionService.active_auction_product.value_type | managerPipe}}-->
                </span>
            </div>

            <span class="mb-5"
                  style="width: 90%; height: 1px; background-color:var(--secondary-colors-artiox-gray-base, #E2E2E2);"></span>

            <span class="barlow caption regular"
                  style="color: var(--text-color-artiox-grey); margin-bottom: 32px">
                {{[managerService.auctionService.active_auction_product.opening_value | managerPipe: managerService.mpa_template.FANCY_NUMBER ] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.AUCTION.AUCTION_FINALIZED.OPEN}}
                {{managerService.auctionService.active_auction_product.value_type | managerPipe: managerService.mpa_template.VALUE_TYPE}}</span>
        </div>
        <div class="d-flex w-100 flex-row justify-content-around" style="padding-bottom: 16px">
            <div (click)="managerService.openModal('auction-share-modal')" style="cursor: pointer">
                <svg class="me-1" style="height: 22px; width: 19px; margin-top: -4px;">
                    <use [attr.xlink:href]='"./assets/images/share.svg#share"' fill="#6F6F6F" stroke="#6F6F6F"/>
                </svg>
                {{ managerService.config.AUCTION.SHARE.SHARE | managerPipe }}
            </div>

            <div #gavel (click)="scrollBids()" style="cursor: pointer">
                <svg class="me-1" style="height: 22px; width: 22px; margin-top: -4px;">
                    <use [attr.xlink:href]='"./assets/images/gavel.svg#gavel"' fill="#6F6F6F" stroke="#6F6F6F"/>
                </svg>
                {{[managerService.bidService.bid_list_pipe | managerPipe: managerService.mpa_template.GET_LAST_BID_COUNT] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.AUCTION.BIDS}}
            </div>
        </div>
    </div>
</div>


