import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
    selector: 'app-account-auction-desktop',
    templateUrl: './account-auction-desktop.component.html',
    styleUrls: ['./account-auction-desktop.component.css']
})
export class AccountAuctionDesktopComponent implements OnInit {

    constructor(public managerService: ManagerService) {
    }

    ngOnInit(): void {
    }

}
