import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../services/manager.service';

@Component({
    selector: 'app-corporate',
    templateUrl: './corporate.component.html',
    styleUrls: ['./corporate.component.css']
})
export class CorporateComponent implements OnInit {

    constructor(public managerService: ManagerService) {
    }

    ngOnInit(): void {
    }
}
