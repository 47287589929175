<div [style]="managerService.show_sign_up_or_login | managerPipe: managerService.mpa_template.GET_MIN_BODY_HEIGHT_OBSOLETE" style="background-color: #fcfdff;">
    <div *ngIf="managerService.config.ZENDESK_ACTIVE | managerPipe" class="w-100 mx-auto px-2 position-relative" style="box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);  background-color: #ffffff;">
        <div class="pt-4 pb-2 pl-2" style="font-family: Roboto, sans-serif; font-size: 14px; letter-spacing: 0.7px; color: #272a3b;">
            {{managerService.config.LEGAL.HEADING | managerPipe: managerService.mpa_template.TO_LOCAL_UPPER_CASE}}
        </div>
    </div>

    <div class="px-3 pb-3">
        <!--        <div *ngFor="let text of managerService.config.LEGAL.TEXTS | managerPipe" class="my-2">-->
        <!--            <div (click)="details.classList.toggle('d-none')"-->
        <!--                 class="position-relative d-flex flex-row justify-content-between align-items-center"-->
        <!--                 style="height: 41px; font-family: Roboto, sans-serif; border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; background-color: #ffffff;">-->
        <!--                <div class="px-2" style="font-size: 12px; color: #9da5b7;">{{text.HEADING| managerPipe}}</div>-->
        <!--                <div class="px-2" style="font-size: 11px; color: var(&#45;&#45;artiox-blue)">{{managerService.config.LEGAL.INSPECT| managerPipe}}</div>-->
        <!--            </div>-->
        <!--            <div #details-->
        <!--                 class="d-none px-2 pb-2" style="border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; margin-top: -20px; padding-top: 20px; background-color: #ffffff;">-->
        <!--&lt;!&ndash;                <a (click)="managerService.navigationManager(text.LINK);" style="color: var(&#45;&#45;artiox-blue); font-size: 10px; text-decoration: underline">{{text.HEADING | managerPipe}}</a>&ndash;&gt;-->
        <!--                &lt;!&ndash;                <object [data]="text.SRC | managerPipe: managerService.mpa_template.BYPASS_SECURITY_TRUST_RESOURCE_URL" type="application/pdf" frameborder="0" style="width: 100%; min-height:400px;">&ndash;&gt;-->
        <!--                                    <embed [src]="text.SRC | managerPipe: managerService.mpa_template.BYPASS_SECURITY_TRUST_RESOURCE_URL"-->
        <!--                                           style="width: 100%; min-height:400px;"-->
        <!--                                    />-->
        <!--                &lt;!&ndash;                </object>&ndash;&gt;-->
        <!--&lt;!&ndash;                <iframe [src]="text.SRC | managerPipe: managerService.mpa_template.BYPASS_SECURITY_TRUST_RESOURCE_URL" frameborder="0" style="width: 100%; min-height:400px; border: none;"></iframe>&ndash;&gt;-->
        <!--            </div>-->
        <!--        </div>-->


        <div class="my-2">
            <div (click)="user_agreement.classList.toggle('d-none'); this.managerService.navigationManager({url:'/legal/user-agreement', current_component_index: 0})"
                 class="position-relative d-flex flex-row justify-content-between align-items-center"
                 style="height: 41px; font-family: Roboto, sans-serif; border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; background-color: #ffffff;">
                <div class="px-2" style="font-size: 12px; color: #9da5b7;">{{(managerService.config.LEGAL.TEXT_NAMES | managerPipe: managerService.mpa_template.RETURN_INDEX: 0)| managerPipe}}</div>
                <div class="px-2" style="font-size: 11px; color: var(--artiox-blue)">{{managerService.config.LEGAL.INSPECT| managerPipe}}</div>
            </div>
            <div #user_agreement id="user_agreement"
                 class="d-none px-2 pb-2" style="border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; margin-top: -20px; padding-top: 20px; background-color: #ffffff;">
                <!-- <a (click)="managerService.navigationManager(managerService.config.LEGAL.TEXTS[0].LINK);"
                   style="color: var(--artiox-blue); font-size: 10px; text-decoration: underline">{{managerService.config.LEGAL.SHOW_AS_PDF | managerPipe}}</a> -->
                <app-legal-user-agreement-desktop></app-legal-user-agreement-desktop>
            </div>
        </div>
        <div class="my-2">
            <div (click)="protection_law_policy.classList.toggle('d-none'); this.managerService.navigationManager({url:'/legal/protection-law-policy', current_component_index: 0})"
                 class="position-relative d-flex flex-row justify-content-between align-items-center"
                 style="height: 41px; font-family: Roboto, sans-serif; border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; background-color: #ffffff;">
                <div class="px-2" style="font-size: 12px; color: #9da5b7;">{{(managerService.config.LEGAL.TEXT_NAMES | managerPipe: managerService.mpa_template.RETURN_INDEX: 1)| managerPipe}}</div>
                <div class="px-2" style="font-size: 11px; color: var(--artiox-blue)">{{managerService.config.LEGAL.INSPECT| managerPipe}}</div>
            </div>
            <div #protection_law_policy id="protection_law_policy"
                 class="d-none px-2 pb-2" style="border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; margin-top: -20px; padding-top: 20px; background-color: #ffffff;">
                <!-- <a (click)="managerService.navigationManager(managerService.config.LEGAL.TEXTS[1].LINK);"
                   style="color: var(--artiox-blue); font-size: 10px; text-decoration: underline">{{managerService.config.LEGAL.SHOW_AS_PDF | managerPipe}}</a> -->
                <app-legal-protection-law-policy-desktop></app-legal-protection-law-policy-desktop>
            </div>
        </div>
        <div class="my-2">
            <div (click)="information_security_policy.classList.toggle('d-none'); this.managerService.navigationManager({url:'/legal/information-security-policy', current_component_index: 0})"
                 class="position-relative d-flex flex-row justify-content-between align-items-center"
                 style="height: 41px; font-family: Roboto, sans-serif; border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; background-color: #ffffff;">
                <div class="px-2" style="font-size: 12px; color: #9da5b7;">{{(managerService.config.LEGAL.TEXT_NAMES | managerPipe: managerService.mpa_template.RETURN_INDEX: 2)| managerPipe}}</div>
                <div class="px-2" style="font-size: 11px; color: var(--artiox-blue)">{{managerService.config.LEGAL.INSPECT| managerPipe}}</div>
            </div>
            <div #information_security_policy id="information_security_policy"
                 class="d-none px-2 pb-2" style="border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; margin-top: -20px; padding-top: 20px; background-color: #ffffff;">
                <!-- <a (click)="managerService.navigationManager(managerService.config.LEGAL.TEXTS[2].LINK);"
                   style="color: var(--artiox-blue); font-size: 10px; text-decoration: underline">{{managerService.config.LEGAL.SHOW_AS_PDF | managerPipe}}</a> -->
                <app-legal-information-security-policy-desktop></app-legal-information-security-policy-desktop>
            </div>
        </div>
        <div class="my-2">
            <div (click)="data_deletion_policy.classList.toggle('d-none');this.managerService.navigationManager({url:'/legal/data-deletion-policy', current_component_index: 0})"
                 class="position-relative d-flex flex-row justify-content-between align-items-center"
                 style="height: 41px; font-family: Roboto, sans-serif; border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; background-color: #ffffff;">
                <div class="px-2" style="font-size: 12px; color: #9da5b7;">{{(managerService.config.LEGAL.TEXT_NAMES | managerPipe: managerService.mpa_template.RETURN_INDEX: 3)| managerPipe}}</div>
                <div class="px-2" style="font-size: 11px; color: var(--artiox-blue)">{{managerService.config.LEGAL.INSPECT| managerPipe}}</div>
            </div>
            <div #data_deletion_policy id="data_deletion_policy"
                 class="d-none px-2 pb-2" style="border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; margin-top: -20px; padding-top: 20px; background-color: #ffffff;">
                <!-- <a (click)="managerService.navigationManager(managerService.config.LEGAL.TEXTS[3].LINK);"
                   style="color: var(--artiox-blue); font-size: 10px; text-decoration: underline">{{managerService.config.LEGAL.SHOW_AS_PDF | managerPipe}}</a> -->
                <app-legal-data-deletion-policy-desktop></app-legal-data-deletion-policy-desktop>
            </div>
        </div>
        <div class="my-2">
            <div (click)="kvkk_application_form.classList.toggle('d-none'); this.managerService.navigationManager({url:'/legal/kvkk-application-form', current_component_index: 0})"
                 class="position-relative d-flex flex-row justify-content-between align-items-center"
                 style="height: 41px; font-family: Roboto, sans-serif; border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; background-color: #ffffff;">
                <div class="px-2" style="font-size: 12px; color: #9da5b7;">{{(managerService.config.LEGAL.TEXT_NAMES | managerPipe: managerService.mpa_template.RETURN_INDEX: 4)| managerPipe}}</div>
                <div class="px-2" style="font-size: 11px; color: var(--artiox-blue)">{{managerService.config.LEGAL.INSPECT| managerPipe}}</div>
            </div>
            <div #kvkk_application_form id="kvkk_application_form"
                 class="d-none px-2 pb-2" style="border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; margin-top: -20px; padding-top: 20px; background-color: #ffffff;">
                <!-- <a (click)="managerService.navigationManager(managerService.config.LEGAL.TEXTS[4].LINK);"
                   style="color: var(--artiox-blue); font-size: 10px; text-decoration: underline">{{managerService.config.LEGAL.SHOW_AS_PDF | managerPipe}}</a> -->
                <app-legal-kvkk-application-form-desktop></app-legal-kvkk-application-form-desktop>
            </div>
        </div>
        <div class="my-2">
            <div (click)="cookie_clarification_text.classList.toggle('d-none'); this.managerService.navigationManager({url:'/legal/cookie-clarification-text', current_component_index: 0})"
                 class="position-relative d-flex flex-row justify-content-between align-items-center"
                 style="height: 41px; font-family: Roboto, sans-serif; border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; background-color: #ffffff;">
                <div class="px-2" style="font-size: 12px; color: #9da5b7;">{{(managerService.config.LEGAL.TEXT_NAMES | managerPipe: managerService.mpa_template.RETURN_INDEX: 5)| managerPipe}}</div>
                <div class="px-2" style="font-size: 11px; color: var(--artiox-blue)">{{managerService.config.LEGAL.INSPECT| managerPipe}}</div>
            </div>
            <div #cookie_clarification_text id="cookie_clarification_text"
                 class="d-none px-2 pb-2" style="border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; margin-top: -20px; padding-top: 20px; background-color: #ffffff;">
                <!-- <a (click)="managerService.navigationManager(managerService.config.LEGAL.TEXTS[5].LINK);"
                   style="color: var(--artiox-blue); font-size: 10px; text-decoration: underline">{{managerService.config.LEGAL.SHOW_AS_PDF | managerPipe}}</a> -->
                <app-legal-cookie-clarification-text-desktop></app-legal-cookie-clarification-text-desktop>
            </div>
        </div>
        <div class="my-2">
            <div (click)="kvkk_clarification.classList.toggle('d-none'); this.managerService.navigationManager({url:'/legal/kvkk-clarification', current_component_index: 0})"
                 class="position-relative d-flex flex-row justify-content-between align-items-center"
                 style="height: 41px; font-family: Roboto, sans-serif; border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; background-color: #ffffff;">
                <div class="px-2" style="font-size: 12px; color: #9da5b7;">{{(managerService.config.LEGAL.TEXT_NAMES | managerPipe: managerService.mpa_template.RETURN_INDEX: 6)| managerPipe}}</div>
                <div class="px-2" style="font-size: 11px; color: var(--artiox-blue)">{{managerService.config.LEGAL.INSPECT| managerPipe}}</div>
            </div>
            <div #kvkk_clarification id="kvkk_clarification"
                 class="d-none px-2 pb-2" style="border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; margin-top: -20px; padding-top: 20px; background-color: #ffffff;">
                <!-- <a (click)="managerService.navigationManager(managerService.config.LEGAL.TEXTS[6].LINK);"
                   style="color: var(--artiox-blue); font-size: 10px; text-decoration: underline">{{managerService.config.LEGAL.SHOW_AS_PDF | managerPipe}}</a> -->
                <app-legal-kvkk-clarification-desktop></app-legal-kvkk-clarification-desktop>
            </div>
        </div>
        <div class="my-2">
            <div (click)="marketing_consent.classList.toggle('d-none'); this.managerService.navigationManager({url:'/legal/marketing-consent', current_component_index: 0})"
                 class="position-relative d-flex flex-row justify-content-between align-items-center"
                 style="height: 41px; font-family: Roboto, sans-serif; border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; background-color: #ffffff;">
                <div class="px-2" style="font-size: 12px; color: #9da5b7;">{{(managerService.config.LEGAL.TEXT_NAMES | managerPipe: managerService.mpa_template.RETURN_INDEX: 7)| managerPipe}}</div>
                <div class="px-2" style="font-size: 11px; color: var(--artiox-blue)">{{managerService.config.LEGAL.INSPECT| managerPipe}}</div>
            </div>
            <div #marketing_consent id="marketing_consent"
                 class="d-none px-2 pb-2" style="border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; margin-top: -20px; padding-top: 20px; background-color: #ffffff;">
                <!-- <a (click)="managerService.navigationManager(managerService.config.LEGAL.TEXTS[7].LINK);"
                   style="color: var(--artiox-blue); font-size: 10px; text-decoration: underline">{{managerService.config.LEGAL.SHOW_AS_PDF | managerPipe}}</a> -->
                <app-legal-marketing-consent-desktop></app-legal-marketing-consent-desktop>
            </div>
        </div>
        <div class="my-2">
            <div (click)="foreign_consent.classList.toggle('d-none'); this.managerService.navigationManager({url:'/legal/foreign-consent', current_component_index: 0})"
                 class="position-relative d-flex flex-row justify-content-between align-items-center"
                 style="height: 41px; font-family: Roboto, sans-serif; border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; background-color: #ffffff;">
                <div class="px-2" style="font-size: 12px; color: #9da5b7;">{{(managerService.config.LEGAL.TEXT_NAMES | managerPipe: managerService.mpa_template.RETURN_INDEX: 8)| managerPipe}}</div>
                <div class="px-2" style="font-size: 11px; color: var(--artiox-blue)">{{managerService.config.LEGAL.INSPECT| managerPipe}}</div>
            </div>
            <div #foreign_consent id="foreign_consent"
                 class="d-none px-2 pb-2" style="border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; margin-top: -20px; padding-top: 20px; background-color: #ffffff;">
                <!-- <a (click)="managerService.navigationManager(managerService.config.LEGAL.TEXTS[8].LINK);"
                   style="color: var(--artiox-blue); font-size: 10px; text-decoration: underline">{{managerService.config.LEGAL.SHOW_AS_PDF | managerPipe}}</a> -->
                <app-legal-foreign-consent-desktop></app-legal-foreign-consent-desktop>
            </div>
        </div>
        <div class="my-2">
            <div (click)="distance_selling_contract.classList.toggle('d-none'); this.managerService.navigationManager({url:'/legal/distance-selling-contract', current_component_index: 0})"
                 class="position-relative d-flex flex-row justify-content-between align-items-center"
                 style="height: 41px; font-family: Roboto, sans-serif; border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; background-color: #ffffff;">
                <div class="px-2" style="font-size: 12px; color: #9da5b7;">{{(managerService.config.LEGAL.TEXT_NAMES | managerPipe: managerService.mpa_template.RETURN_INDEX: 9)| managerPipe}}</div>
                <div class="px-2" style="font-size: 11px; color: var(--artiox-blue)">{{managerService.config.LEGAL.INSPECT| managerPipe}}</div>
            </div>
            <div #distance_selling_contract id="distance_selling_contract"
                 class="d-none px-2 pb-2" style="border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; margin-top: -20px; padding-top: 20px; background-color: #ffffff;">
                <!-- <a (click)="managerService.navigationManager(managerService.config.LEGAL.TEXTS[8].LINK);"
                   style="color: var(--artiox-blue); font-size: 10px; text-decoration: underline">{{managerService.config.LEGAL.SHOW_AS_PDF | managerPipe}}</a> -->
                <app-legal-distance-selling-contract-desktop></app-legal-distance-selling-contract-desktop>
            </div>
        </div>
        <div class="my-2">
            <div (click)="auction_cancellation_and_refund_rules.classList.toggle('d-none'); this.managerService.navigationManager({url:'/legal/auction-cancellation-and-refund-rules', current_component_index: 0})"
                 class="position-relative d-flex flex-row justify-content-between align-items-center"
                 style="height: 41px; font-family: Roboto, sans-serif; border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; background-color: #ffffff;">
                <div class="px-2" style="font-size: 12px; color: #9da5b7;">{{(managerService.config.LEGAL.TEXT_NAMES | managerPipe: managerService.mpa_template.RETURN_INDEX: 10)| managerPipe}}</div>
                <div class="px-2" style="font-size: 11px; color: var(--artiox-blue)">{{managerService.config.LEGAL.INSPECT| managerPipe}}</div>
            </div>
            <div #auction_cancellation_and_refund_rules id="auction_cancellation_and_refund_rules"
                 class="d-none px-2 pb-2" style="border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; margin-top: -20px; padding-top: 20px; background-color: #ffffff;">
                <!-- <a (click)="managerService.navigationManager(managerService.config.LEGAL.TEXTS[8].LINK);"
                   style="color: var(--artiox-blue); font-size: 10px; text-decoration: underline">{{managerService.config.LEGAL.SHOW_AS_PDF | managerPipe}}</a> -->
                    <app-legal-auction-cancellation-and-refund-agreement-desktop> </app-legal-auction-cancellation-and-refund-agreement-desktop>
            </div>
        </div>
        <div class="my-2">
            <div (click)="auction_user_agreement.classList.toggle('d-none'); this.managerService.navigationManager({url:'/legal/auction-user-agreement', current_component_index: 0})"
                 class="position-relative d-flex flex-row justify-content-between align-items-center"
                 style="height: 41px; font-family: Roboto, sans-serif; border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; background-color: #ffffff;">
                <div class="px-2" style="font-size: 12px; color: #9da5b7;">{{(managerService.config.LEGAL.TEXT_NAMES | managerPipe: managerService.mpa_template.RETURN_INDEX: 11)| managerPipe}}</div>
                <div class="px-2" style="font-size: 11px; color: var(--artiox-blue)">{{managerService.config.LEGAL.INSPECT| managerPipe}}</div>
            </div>
            <div #auction_user_agreement id="auction_user_agreement"
                 class="d-none px-2 pb-2" style="border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; margin-top: -20px; padding-top: 20px; background-color: #ffffff;">
                <!-- <a (click)="managerService.navigationManager(managerService.config.LEGAL.TEXTS[8].LINK);"
                   style="color: var(--artiox-blue); font-size: 10px; text-decoration: underline">{{managerService.config.LEGAL.SHOW_AS_PDF | managerPipe}}</a> -->
                    <app-legal-auction-user-agreement-desktop></app-legal-auction-user-agreement-desktop>
            </div>
        </div>
        <div class="my-2">
            <div (click)="auction_rules.classList.toggle('d-none'); this.managerService.navigationManager({url:'/legal/auction-rules', current_component_index: 0})"
                 class="position-relative d-flex flex-row justify-content-between align-items-center"
                 style="height: 41px; font-family: Roboto, sans-serif; border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; background-color: #ffffff;">
                <div class="px-2" style="font-size: 12px; color: #9da5b7;">{{(managerService.config.LEGAL.TEXT_NAMES | managerPipe: managerService.mpa_template.RETURN_INDEX: 12)| managerPipe}}</div>
                <div class="px-2" style="font-size: 11px; color: var(--artiox-blue)">{{managerService.config.LEGAL.INSPECT| managerPipe}}</div>
            </div>
            <div #auction_rules id="auction_rules"
                 class="d-none px-2 pb-2" style="border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; margin-top: -20px; padding-top: 20px; background-color: #ffffff;">
                <!-- <a (click)="managerService.navigationManager(managerService.config.LEGAL.TEXTS[8].LINK);"
                   style="color: var(--artiox-blue); font-size: 10px; text-decoration: underline">{{managerService.config.LEGAL.SHOW_AS_PDF | managerPipe}}</a> -->
                <app-legal-auction-rules-desktop></app-legal-auction-rules-desktop>
            </div>
        </div>
        <div class="my-2">
            <div (click)="commissions.classList.toggle('d-none'); this.managerService.navigationManager({url:'/legal/auction-rules', current_component_index: 0})"
                 class="position-relative d-flex flex-row justify-content-between align-items-center"
                 style="height: 41px; font-family: Roboto, sans-serif; border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; background-color: #ffffff;">
                <div class="px-2" style="font-size: 12px; color: #9da5b7;">{{(managerService.config.LEGAL.TEXT_NAMES | managerPipe: managerService.mpa_template.RETURN_INDEX: 13)| managerPipe}}</div>
                <div class="px-2" style="font-size: 11px; color: var(--artiox-blue)">{{managerService.config.LEGAL.INSPECT| managerPipe}}</div>
            </div>
            <div #commissions id="commissions"
                 class="d-none px-2 pb-2" style="border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; margin-top: -20px; padding-top: 20px; background-color: #ffffff;">
                <!-- <a (click)="managerService.navigationManager(managerService.config.LEGAL.TEXTS[8].LINK);"
                   style="color: var(--artiox-blue); font-size: 10px; text-decoration: underline">{{managerService.config.LEGAL.SHOW_AS_PDF | managerPipe}}</a> -->
                <app-legal-commissions-desktop></app-legal-commissions-desktop>
            </div>
        </div>
        <div class="my-2">
            <div (click)="limits.classList.toggle('d-none'); this.managerService.navigationManager({url:'/legal/auction-rules', current_component_index: 0})"
                 class="position-relative d-flex flex-row justify-content-between align-items-center"
                 style="height: 41px; font-family: Roboto, sans-serif; border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; background-color: #ffffff;">
                <div class="px-2" style="font-size: 12px; color: #9da5b7;">{{(managerService.config.LEGAL.TEXT_NAMES | managerPipe: managerService.mpa_template.RETURN_INDEX: 14)| managerPipe}}</div>
                <div class="px-2" style="font-size: 11px; color: var(--artiox-blue)">{{managerService.config.LEGAL.INSPECT| managerPipe}}</div>
            </div>
            <div #limits id="limits"
                 class="d-none px-2 pb-2" style="border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; margin-top: -20px; padding-top: 20px; background-color: #ffffff;">
                <!-- <a (click)="managerService.navigationManager(managerService.config.LEGAL.TEXTS[8].LINK);"
                   style="color: var(--artiox-blue); font-size: 10px; text-decoration: underline">{{managerService.config.LEGAL.SHOW_AS_PDF | managerPipe}}</a> -->
                <app-legal-limits-desktop></app-legal-limits-desktop>
            </div>
        </div>
    </div>
</div>

