import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legal-protection-law-policy-desktop',
  templateUrl: './legal-protection-law-policy-desktop.component.html',
  styleUrls: ['./legal-protection-law-policy-desktop.component.css']
})
export class LegalProtectionLawPolicyDesktopComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
