<div class="w-100 d-flex flex-column align-items-start justify-content-start"
     style="box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff; height: 840px; border-radius: 4px;">
  <div class="w-100 mt-4 px-5 d-flex flex-row align-items-center justify-content-between"
       style="font-family: Roboto, sans-serif; font-size: 13px; font-weight: bold; line-height: 1.2; letter-spacing: 0.9px; color: #404352;">
    <div>{{managerService.config.BALANCE.USER_TRADE_HISTORY | managerPipe}}</div>
    <div class="d-flex flex-row"
         style="font-family: Roboto, sans-serif; font-weight: normal; font-size: 12px; line-height: 2; letter-spacing: 1.2px; color: #595e7e;">
      <div class="mx-1">{{managerService.config.BALANCE.PAIR}}:</div>
      <div class="mx-1 mr-3">
        <button
          (click)="filter_menu.classList.toggle('d-none')"
          class="btn-native filter-dropdown-button px-2">
          {{pair_selected_placeholder | managerPipe}}
          <svg style="height: 20px; width: 20px;">
            <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#595e7e"/>
          </svg>
        </button>
        <div #filter_menu class="filter-dropdown-menu d-none">
          <div
            (click)="pair_selected = undefined; pair_selected_placeholder = managerService.config.BALANCE.ALL; filter_menu.classList.add('d-none');"
            [style.border]="((pair_selected_placeholder === managerService.config.BALANCE.ALL) | managerPipe) ? 'solid 1px #f4f5f9' : ''"
            class="btn-native filter-dropdown-item py-1 text-center" style=" cursor: default">
            {{managerService.config.BALANCE.ALL | managerPipe}}
          </div>
          <div
            (click)="pair_selected = pair.id; pair_selected_placeholder = pair.symbol; filter_menu.classList.add('d-none');"
            *ngFor="let pair of (managerService.pair_pipe | managerPipe: managerService.mpa_template.GET_SORTED_LISTED_PAIR_LIST)"
            [style.border]="((pair_selected_placeholder === pair.symbol) | managerPipe) ? 'solid 1px #f4f5f9' : ''"
            class="btn-native filter-dropdown-item py-1 text-center" style=" cursor: default">
            {{pair.symbol | managerPipe}}
          </div>
          <div
            (click)="pair_selected = currency_pair.id; pair_selected_placeholder = currency_pair.symbol; filter_menu.classList.add('d-none');"
            *ngFor="let currency_pair of (managerService.currency_pair_pipe | managerPipe: managerService.mpa_template.GET_SORTED_LISTED_CURRENCY_PAIR_LIST)"
            [style.border]="((pair_selected_placeholder === currency_pair.symbol) | managerPipe) ? 'solid 1px #f4f5f9' : ''"
            class="btn-native filter-dropdown-item py-1 text-center" style=" cursor: default">
            {{currency_pair.symbol | managerPipe}}
          </div>
        </div>
      </div>
      <div class="mx-1">{{managerService.config.BALANCE.ORDER_TYPE}}:</div>
      <div class="mx-1 text-left">
        <div
          (click)="filter_type_menu.classList.toggle('d-none')"
          class="btn-native filter-dropdown-button ">
          <div [style]="trade_type_selected_placeholder | managerPipe: managerService.mpa_template.GET_USER_ORDER_HISTORY_FILTER_BACKGROUND_COLOR" class="d-inline-block ml-2 mr-1 text-left"
               style="height: 8px; width: 8px;"></div>
          {{trade_type_selected_placeholder | managerPipe}}
          <svg style="height: 20px; width: 20px;">
            <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#595e7e"/>
          </svg>
        </div>
        <div #filter_type_menu class="filter-dropdown-menu d-none text-left">
          <div
            (click)="trade_type_selected = undefined; trade_type_selected_placeholder = managerService.config.BALANCE.ALL; filter_type_menu.classList.add('d-none');"
            [style.border]="((trade_type_selected_placeholder === managerService.config.BALANCE.ALL) | managerPipe) ? 'solid 1px #f4f5f9' : ''" class="btn-native filter-dropdown-item py-1"
            style=" cursor: default">
            <div class="d-inline-block ml-2 mr-1" style=" height: 8px; width: 8px;"></div>
            {{managerService.config.BALANCE.ALL | managerPipe}}
          </div>
          <div
            (click)="trade_type_selected = 'buy'; trade_type_selected_placeholder = managerService.config.BALANCE.BUY; filter_type_menu.classList.add('d-none');"
            [style.border]="((trade_type_selected_placeholder === managerService.config.BALANCE.BUY) | managerPipe) ? 'solid 1px #f4f5f9' : ''" class="btn-native filter-dropdown-item py-1"
            style=" cursor: default">
            <div class="d-inline-block ml-2 mr-1" style="background-color: #2ad8a9; height: 8px; width: 8px;"></div>
            {{managerService.config.BALANCE.BUY | managerPipe}}
          </div>
          <div
            (click)="trade_type_selected = 'sell'; trade_type_selected_placeholder = managerService.config.BALANCE.SELL; filter_type_menu.classList.add('d-none');"
            [style.border]="((trade_type_selected_placeholder === managerService.config.BALANCE.SELL) | managerPipe) ? 'solid 1px #f4f5f9' : ''" class="btn-native filter-dropdown-item py-1"
            style=" cursor: default">
            <div class="d-inline-block ml-2 mr-1" style="background-color: #ff207d; height: 8px; width: 8px;"></div>
            {{managerService.config.BALANCE.SELL | managerPipe}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-100 d-flex flex-row align-items-center justify-content-around py-1 my-3"
       style="font-family: Roboto, sans-serif;font-size: 12px; font-weight: 500; line-height: 2.7; letter-spacing: 0.7px; color: #4a4e70;">
  <span class="pl-5 text-left"
        style="width: 16%">{{managerService.config.ADVANCED.USER_TRADE_HISTORY.PAIR | managerPipe}}</span>
    <span class="text-center"
          style="width: 14%">{{managerService.config.ADVANCED.USER_TRADE_HISTORY.TYPE | managerPipe}}</span>
    <span class="text-center"
          style="width: 16%">{{managerService.config.ADVANCED.USER_TRADE_HISTORY.PRICE | managerPipe}}</span>
    <span class="text-center"
          style="width: 16%">{{managerService.config.ADVANCED.USER_TRADE_HISTORY.AMOUNT | managerPipe}}</span>
    <span class="text-center"
          style="width: 16%">{{managerService.config.ADVANCED.USER_TRADE_HISTORY.TOTAL | managerPipe}}</span>
    <span class="text-center"
          style="width: 22%">{{managerService.config.ADVANCED.USER_TRADE_HISTORY.TIME | managerPipe}}</span>
  </div>
  <div class="w-100 d-flex flex-column overflow-auto">
    <div
      *ngFor="let user_trade of (managerService.user_trade_history_pipe | managerPipe: managerService.mpa_template.GET_USER_TRANSACTION_HISTORY: pair_selected: trade_type_selected : managerService.user_swap_history_pipe) | managerPipe: managerService.mpa_template.SORT_BY_CREATION_TIME"
      class="d-flex flex-row align-items-center justify-content-around flex-wrap py-1 user-trade-history"
      style="font-family: Roboto, sans-serif;font-size: 10px; font-weight: 500; line-height: 2.7; letter-spacing: 0.7px; color: #4a4e70;">
      <span class="pl-5 text-left"
            style="width: 16%;">{{user_trade | managerPipe: managerService.mpa_template.TRANSACTION_SYMBOL: managerService.pair_pipe}}</span>
      <span [style]="user_trade | managerPipe: managerService.mpa_template.GET_USER_TRADE_BACKGROUND_COLOR"
            class="text-center"
            style="width: 14%">{{user_trade.side | managerPipe: managerService.mpa_template.USER_TRADE_TYPE: managerService.language_pipe}}</span>
      <span class="text-center"
            style="width: 16%">{{user_trade.price | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
      <span class="text-center"
            style="width: 16%">{{user_trade | managerPipe: managerService.mpa_template.USER_ORDER_HISTORY_AMOUNT}}</span>
      <span class="text-center"
            style="width: 16%">{{user_trade.amount * user_trade.price | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
      <span class="text-center"
            style="width: 22%">{{user_trade.creation_time | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</span>
    </div>
  </div>
</div>
