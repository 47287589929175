<div class="w-100" style="background-color: #fcfdff;">
  <div class="w-75 mx-auto" style="background-color: #ffffff;"
       [style]="managerService.show_sign_up_or_login | managerPipe: managerService.mpa_template.GET_MIN_BODY_HEIGHT_OBSOLETE">
    <div class="w-100 pt-5 text-center" style="font-family: bebas-neue-bold, sans-serif; font-size: 24px; letter-spacing: 1.2px; color: #404352;">
      {{managerService.config.WITHDRAWAL_ALREADY_CONFIRMED.HEADING | managerPipe}}
    </div>
    <div class="w-100 pt-3 text-center" style="font-family: Roboto, sans-serif; font-size: 16px; letter-spacing: 0.32px; color: #404352;">
      {{managerService.config.WITHDRAWAL_ALREADY_CONFIRMED.DESCRIPTION | managerPipe}}
    </div>
    <div class="w-100 pt-3 text-center">
      <img [alt]="managerService.config.WITHDRAWAL_ALREADY_CONFIRMED.IMG_ALT | managerPipe" #confirmed
           [src]="managerService.config.WITHDRAWAL_ALREADY_CONFIRMED.IMG_SRC | managerPipe" width="300px"
           (error)="confirmed.src = confirmed.src.replace('.webp', '.jpg')">
    </div>
    <div class="w-100 pt-3 text-center">
      <button (click)="managerService.backToHome()" class="btn-native" id="back-to-home-button">
        {{managerService.config.WITHDRAWAL_ALREADY_CONFIRMED.BACK_TO_HOME | managerPipe}}
      </button>
    </div>
  </div>
</div>


