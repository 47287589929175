<div *ngIf="login_step !== 1 | managerPipe" class="d-flex align-items-center justify-content-between" style="font-size: 18px; color: #211f25">
  {{managerService.config.LOGIN.LOGIN | managerPipe}}
  <svg (click)="managerService.closeModal(modal_id); clearLoginForm();" style="height: 18px; width: 18px;">
    <use [attr.xlink:href]='"./assets/images/close.svg#close"' fill="#404352"/>
  </svg>
</div>

<app-captcha-mobile (response)="loginStepResolver($event)" *ngIf="login_step === 1 | managerPipe"></app-captcha-mobile>

<form #login_form [formGroup]="loginForm" class="d-flex flex-column align-items-start" style="width: 80vw; min-width: 240px; height: fit-content;">
  <div *ngIf="login_step === 0 | managerPipe" class="w-100 d-flex flex-column">
    <span class='input-heading'>{{managerService.config.LOGIN.E_MAIL | managerPipe}}</span>
    <label>
      <input class="input-field" formControlName="email"
             placeholder="{{managerService.config.LOGIN.E_MAIL_PLACEHOLDER | managerPipe}}" required
             type="email">
    </label>
    <span class='input-heading'>{{managerService.config.LOGIN.PASSWORD | managerPipe}}</span>
    <label class="position-relative">
      <svg (click)="password_visible = !password_visible" class="password-eye">
        <use *ngIf="(!password_visible | managerPipe)" [attr.xlink:href]='"./assets/images/eye_hidden.svg#eye_hidden"' fill="#9DA5B7"/>
        <use *ngIf="(password_visible | managerPipe)" [attr.xlink:href]='"./assets/images/eye_visible.svg#eye_visible"' fill="#9DA5B7"/>
      </svg>
      <input [type]="(password_visible | managerPipe) ? 'text' : 'password'" class="input-field" formControlName="password"
             placeholder="{{managerService.config.LOGIN.PASSWORD_PLACEHOLDER | managerPipe}}"
             (change)="autofillPassword($event)"
             required style="padding-right: 40px;">
    </label>
  </div>
  <div *ngIf="login_step === 2 | managerPipe" class="w-100 d-flex flex-column">
    <span class='input-heading'>{{managerService.config.LOGIN.AUTH | managerPipe}}</span>
    <label class="position-relative">
      <input class="input-field" formControlName="auth_password"
             placeholder="{{managerService.config.LOGIN.AUTH_PLACEHOLDER | managerPipe}}"
             required type="text">

      <div (click)="pasteAuthPassword()" class="position-absolute"
           style="top: 10px; right: 24px; font-family: Roboto, sans-serif; font-size: 16px; font-weight: 500; letter-spacing: 0.7px; color: #9DA5B7">
        {{managerService.config.LOGIN.PASTE | managerPipe}}
      </div>
    </label>
  </div>
  <button (click)="managerService.openModal('forgot-password-modal'); managerService.closeModal(modal_id);" *ngIf="login_step !== 2 | managerPipe" class="btn-native my-1"
          style="color: #4bc4d6; padding: 0 20px;"
          type="button">
    {{managerService.config.LOGIN.FORGOT_PASSWORD | managerPipe}}
  </button>
  <button (click)="loginFormValidator()" class="btn-native" id="submit-button">
    {{managerService.config.LOGIN.SUBMIT | managerPipe}}
  </button>
  <div *ngIf="login_step !== 2 | managerPipe" class="w-100 d-flex flex-column align-items-center justify-content-center" type="button">
    <span class="text-center">{{managerService.config.LOGIN.NO_ACCOUNT}}</span>
    <span (click)="managerService.navigationManager({url: '/sign-up', current_component_index: 0}); managerService.closeModal('login-modal')" class="text-center mx-1" style="color: var(--artiox-blue)">{{managerService.config.LOGIN.CREATE_NEW_ACCOUNT}}</span>
  </div>
  <div #warning class="d-none warning" style="margin: 10px 20px 0"></div>
</form>
