import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
  selector: 'app-basic-mobile',
  templateUrl: './basic-mobile.component.html',
  styleUrls: ['./basic-mobile.component.css']
})
export class BasicMobileComponent implements OnInit {
  public height: number = 0;

  public basic_pair_list: any = undefined;
  public basic_trade: any = undefined;

  constructor(public managerService: ManagerService) {
    this.managerService.navigationService.current_component_index_changed.subscribe(() => {
      this.setHeight();
    });

    this.managerService.pairService.active_pair_changed.subscribe(() => {
      this.setHeight();
    });

    this.managerService.pairService.pair_list_changed.subscribe(() => {
      this.setHeight();
    });

    this.setHeight();
  }

  ngOnInit(): void {
  }

  setHeight(): void {
    setTimeout(() => {
      if (!this.basic_pair_list || !this.basic_trade) {
        this.basic_pair_list = document.getElementById('basic_pair_list');
        this.basic_trade = document.getElementById('basic_trade');
      }

      if (this.basic_pair_list && this.basic_trade) {
        if (this.managerService.navigationService.current_component_index === 0) {
          this.height = this.basic_pair_list.offsetHeight;
        } else {
          this.height = this.basic_trade.offsetHeight;
        }
      }
    });
  }

}
