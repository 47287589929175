import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-summary-desktop',
  templateUrl: './summary-desktop.component.html',
  styleUrls: ['./summary-desktop.component.css']
})
export class SummaryDesktopComponent implements OnInit, AfterViewInit, OnDestroy {

  public pair_selected: any = undefined;
  public order_type_selected: any = undefined;
  public pair_selected_placeholder: string = this.managerService.config.BALANCE.ALL;
  public order_type_selected_placeholder: string = this.managerService.config.BALANCE.ALL;

  public subscription_list: Subscription[] = [];

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.subscription_list.push(this.managerService.language_changed.subscribe(() => {
        if (this.pair_selected === undefined){
          this.pair_selected_placeholder = this.managerService.config.BALANCE.ALL;
        }


        if (this.order_type_selected === 'sell'){
          this.order_type_selected_placeholder = this.managerService.config.BALANCE.SELL;
        }
        else if (this.order_type_selected === 'buy'){
          this.order_type_selected_placeholder = this.managerService.config.BALANCE.BUY;
        }
        else {
          this.order_type_selected_placeholder = this.managerService.config.BALANCE.ALL;
        }
      })
    );
  }


  ngOnDestroy(): void {
    this.subscription_list.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

}
