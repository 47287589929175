<div
    *ngIf="(managerService.logged_in && !(0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO)) | managerPipe">

    <div class="d-flex flex-row align-items-baseline" style="padding-left: 36px;padding-right: 64px; margin-top: 16px">
        <div>
            <svg style="height: 16px; width: 16px; margin-right: 10px">
                <use [attr.xlink:href]='"./assets/images/info.svg#info"' fill="#000"/>
            </svg>
        </div>
        <div [innerHTML]="managerService.config.USER_INFORMATION.DISCLAIMER | managerPipe"
             class="d-flex "
             style="color: #404352;font-family: Roboto,sans-serif;font-size: 12px;font-style: normal;font-weight: 400;line-height: 130%;"></div>
    </div>
    <div
        style="padding-left: 36px;margin-top:16px;color: #4BC4D6;font-family: Roboto,sans-serif;font-size: 16px;font-style: normal;font-weight: 500;line-height: normal;">
        {{managerService.config.USER_INFORMATION.ACCOUNT_INFORMATION}}</div>
    <form [formGroup]="userInformationForm">
        <div class="d-flex w-100 flex-column" style="gap: 32px;padding-left: 36px;margin-top:32px">

            <div class="w-100 d-flex flex-row" style="gap: 120px">
                <div class="d-flex form-group focused">
                    <label class=" d-flex form-label" for="name">
                        {{managerService.config.USER_INFORMATION.NAME | managerPipe}}</label>
                    <input class="w-100 readonly-input" id="name" readonly="readonly" type="text" value="{{managerService.userService.user_info?.name}}">
                </div>
                <div class="d-flex form-group focused">
                    <label class=" d-flex form-label" for="surname">
                        {{managerService.config.USER_INFORMATION.SURNAME | managerPipe}}</label>
                    <input class="w-100 readonly-input" id="surname" readonly="readonly" type="text" value="{{managerService.userService.user_info?.surname}}">
                </div>
            </div>
            <div class="w-100 d-flex flex-row" style="gap: 120px">
                <div class="d-flex form-group focused">
                    <label class=" d-flex form-label"
                           for="tc"> {{managerService.config.USER_INFORMATION.TC_NUMBER }}</label>
                    <input [type]="(tc_number_visible | managerPipe) ? 'text' : 'password'" class="w-100 readonly-input"
                           id="tc"
                           readonly="readonly" value="{{managerService.userService.user_info?.tc_no}}">
                    <svg (click)="tc_number_visible = !tc_number_visible" class="password-eye" style="width: 20px; height: 20px">
                        <use *ngIf="(!tc_number_visible | managerPipe)"
                             [attr.xlink:href]='"./assets/images/eye_hidden.svg#eye_hidden"'
                             fill="#9DA5B7"/>
                        <use *ngIf="(tc_number_visible | managerPipe)"
                             [attr.xlink:href]='"./assets/images/eye_visible.svg#eye_visible"'
                             fill="#9DA5B7"/>
                    </svg>

                </div>
                <div class="d-flex form-group focused">
                    <label class=" d-flex form-label" for="email">
                        {{managerService.config.USER_INFORMATION.EMAIL | managerPipe}}</label>
                    <input class="w-100 readonly-input" id="email" readonly="readonly" type="text"
                           value="{{managerService.userService.user?.email}}">
                </div>
            </div>
            <div class="w-100 d-flex flex-row" style="gap: 120px">
                <div class="d-flex form-group focused flex-column">
                    <label class=" d-flex form-label" for="phoneNumber">
                        {{managerService.config.USER_INFORMATION.PHONE_NUMBER | managerPipe}}</label>
                    <input #phone (keydown)="managerService.numberInputCheck($event)" [ngClass]="form_enabled ? 'form-input' : 'readonly-input' " [readOnly]="form_enabled ? '' : 'readonly-input' "
                           class="w-100 "
                           formControlName="phone_number"
                           id="phoneNumber" placeholder="(___) ___ __ __"
                           style="color: #404352;"
                           type="text">
                    <span #invalidPhone class="invalid-text" style="margin-bottom: 8px">{{managerService.config.USER_INFORMATION.INVALID_PHONE | managerPipe}}</span>
                </div>
                <div (click)="town_dropdown_menu.classList.remove('d-none')"
                     [ngStyle]="form_enabled ? {'cursor': 'pointer'} : {'pointer-events':'none'}"
                     class="d-flex form-group focused dropdown-native-button">
                    <label class="d-flex form-label" for="city">
                        {{managerService.config.USER_INFORMATION.CITY | managerPipe}}</label>
                    <input #city class="w-100 readonly-input" formControlName="city" id="city" readonly="readonly"
                           style="color: #404352" type="text"
                    >
                    <svg class="dropdown-icon">
                        <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#9DA5B7"/>
                    </svg>
                    <span #invalidCity class="invalid-text" style="margin-bottom: 8px">{{managerService.config.USER_INFORMATION.INVALID_CITY | managerPipe}}</span>

                    <div #town_dropdown_menu class="dropdown-native-menu-white d-none" style="margin-top: 32px">
                        <button (click)="town_dropdown_menu.classList.add('d-none'); this.userInformationForm.get('city')?.setValue(town)"
                                *ngFor="let town of (null | managerPipe: managerService.mpa_template.GET_CITIES_LIST)"
                                class="dropdown-native-item-white btn-native justify-content-center" type="button">
                            {{town | managerPipe}}
                        </button>
                    </div>
                </div>
            </div>
            <div class="w-100 d-flex flex-row">
                <div class="d-flex form-group focused" style="width: 560px">
                    <label class=" d-flex form-label" for="address">
                        {{managerService.config.USER_INFORMATION.ADDRESS | managerPipe}}</label>
                    <input #address [ngClass]="form_enabled ? 'form-input' : 'readonly-input' "
                           [readOnly]="form_enabled ? '' : 'readonly-input' " class="w-100  readonly-input"
                           formControlName="address"
                           id="address"
                           readonly="readonly"
                           style="color: #404352;font-family: Roboto,sans-serif;font-size: 12px;font-style: normal;font-weight: 400;line-height: normal;"
                           type="text">
                    <span #invalidAddress class="invalid-text" style="margin-bottom: 8px">{{managerService.config.USER_INFORMATION.INVALID_ADDRESS | managerPipe}}</span>

                </div>
            </div>
            <div class="w-100 d-flex flex-row">
                <div (click)="enableForm()"
                     *ngIf="!form_enabled"
                     style="display: flex;height: 40px;padding: 8px 16px;justify-content: center;align-items: center;gap: 8px;border-radius: 8px;color: #FFFFFF;cursor: pointer;user-select: none; background: #4BC4D6;box-shadow: 0px 2px 6px 0px rgba(160, 162, 180, 0.42);">
                    {{managerService.config.USER_INFORMATION.CHANGE | managerPipe}}
                </div>
                <div *ngIf="form_enabled" class="d-flex" style="gap: 16px">
                    <div
                        (click)="disableForm()"
                        style="display: flex;height: 40px;padding: 8px 16px;justify-content: center;align-items: center;gap: 8px;border-radius: 8px;color: #4BC4D6;cursor: pointer;user-select: none; border: 1px solid #4BC4D6;box-shadow: 0px 2px 6px 0px rgba(160, 162, 180, 0.42);">
                        {{managerService.config.USER_INFORMATION.CANCEL | managerPipe}}
                    </div>
                    <div
                        (click)="validateForm()"
                        style="display: flex;height: 40px;padding: 8px 16px;justify-content: center;align-items: center;gap: 8px;border-radius: 8px;color: #FFFFFF;cursor: pointer;user-select: none; background: #4BC4D6;box-shadow: 0px 2px 6px 0px rgba(160, 162, 180, 0.42);">
                        {{managerService.config.USER_INFORMATION.APPROVE | managerPipe}}
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
