<div style="max-width: 90vw; background: #FFFFFF;height: 100vh">
    <div class="d-flex w-100 justify-content-center">
        <div class="d-flex w-100 flex-column" style="gap: 16px;padding-left: 12px;padding-right: 12px;padding-top: 24px">
            <div class="d-flex flex-row justify-content-between">
                <p class="header5 exo bold"
                   style="color: #8266F0">{{managerService.config.AUCTION.SHARE.HEADER | managerPipe}}</p>
                <svg (click)="managerService.closeModal(modal_id)" style="width: 24px; height: 24px; cursor: pointer">
                    <use [attr.xlink:href]='"./assets/images/close_with_circle.svg#close_with_circle"' fill="none"
                         stroke="#B5B5B5"/>
                </svg>
            </div>
            <p class="overline barlow regular"
               style="color: #6F6F6F">{{managerService.config.AUCTION.SHARE.DESCRIPTION | managerPipe}}</p>
        </div>
    </div>

    <div class="d-flex w-100 justify-content-center align-items-center flex-column"
         style="padding: 24px 24px; gap: 24px">
        <div class="d-flex w-100 justify-content-between " style="gap: 16px">
            <div class="d-flex overflow-hidden barlow overline regular"
                 style="color: #B5B5B5;width: 364px;padding: 12px 16px;border-radius: 4px;border: 1px solid var(--secondary-colors-artiox-gray-400, #CBCBCB);background: var(--secondary-colors-artiox-gray-base, #E2E2E2);">
                {{url | managerPipe}}
            </div>
            <div class="d-flex justify-content-center align-items-center">
                <svg (click)="copy()" style="cursor:pointer;height: 24px; width: 24px">
                    <use [attr.xlink:href]='"./assets/images/copy.svg#copy"' fill="#B5B5B5"/>
                </svg>
            </div>
        </div>
        <div class="d-flex justify-content-center" style="gap: 32px">
            <div (click)="whatsapp()" class="d-flex flex-column justify-content-center align-items-center"
                 style="cursor: pointer">
                <div
                    style="display: flex;width: 32px;height: 32px;padding: 8px;justify-content: center;align-items: center;border-radius: 100px;border: 1px solid var(--main-color-artiox-purple-200, #ECE8FD);background: var(--main-color-artiox-purple-base, #8266F0);">
                    <svg style="height: 28px; width: 28px">
                        <use [attr.xlink:href]='"./assets/images/whatsapp.svg#whatsapp"' fill="#FFFFFF"/>
                    </svg>
                </div>
                <div class="d-flex barlow overline regular">
                    Whatsapp
                </div>
            </div>
            <div (click)="twitter()" class="d-flex flex-column justify-content-center align-items-center"
                 style="cursor: pointer">
                <div
                    style="display: flex;width: 32px;height: 32px;padding: 8px;justify-content: center;align-items: center;border-radius: 100px;border: 1px solid var(--main-color-artiox-purple-200, #ECE8FD);background: var(--main-color-artiox-purple-base, #8266F0);">
                    <svg style="height: 28px; width: 28px">
                        <use [attr.xlink:href]='"./assets/images/x.svg#x"' fill="#FFFFFF"/>
                    </svg>
                </div>
                <div class="d-flex  barlow overline regular">
                    Twitter
                </div>
            </div>
            <div (click)="linkedin()" class="d-flex flex-column justify-content-center align-items-center"
                 style="cursor: pointer">
                <div
                    style="display: flex;width: 32px;height: 32px;padding: 8px;justify-content: center;align-items: center;border-radius: 100px;border: 1px solid var(--main-color-artiox-purple-200, #ECE8FD);background: var(--main-color-artiox-purple-base, #8266F0);">
                    <svg style="height: 28px; width: 28px">
                        <use [attr.xlink:href]='"./assets/images/linkedin.svg#linkedin"' stroke="#FFFFFF" fill="none"/>
                    </svg>
                </div>
                <div class="d-flex barlow overline regular">
                    Linkedin
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-center align-items-center" style="margin: 24px 12px;height: 1px; border-top: 1px solid #8266F0">
        <div class="d-flex" style="padding: 24px; color: #8266F0; background: #FFF">{{managerService.config.AUCTION.SHARE.OR | managerPipe}}</div>
    </div>

<!--    <div class="d-flex w-100 justify-content-center align-items-center" style="padding: 48px">-->
<!--        <svg style="height: 192px; width: 192px">-->
<!--            <use [attr.xlink:href]='"./assets/images/qr_code_share.svg#qr_code_share"'/>-->
<!--        </svg>-->
<!--    </div>-->

    <div class="d-flex w-100 justify-content-center flex-row" style="gap: 16px;padding-top: 24px">
        <img ngSrc="./assets/images/google-play-store.png " alt="google-play" width="100" height="30" (click)="openGooglePlay()" style="cursor: pointer"/>
        <img ngSrc="./assets/images/apple-app-store.png " alt="app-store" width="100" height="30" (click)="openAppStore()" style="cursor: pointer" />
    </div>

    <div class="d-flex w-100 justify-content-center align-items-end" style="position:absolute; bottom: 10px">
        <svg style=" height: 32px; width: 138px; margin-top: 32px; margin-bottom: 32px">
            <use [attr.xlink:href]='"./assets/images/artiox_logo.svg#artiox_logo"' fill="#000"/>
        </svg>
    </div>
</div>
