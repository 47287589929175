import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    AbstractControl,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {ManagerService} from '../../../../../../services/manager.service';
import {HttpClient, HttpEventType} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {UtilityService} from "../../../../../../services/utility.service";

@Component({
    selector: 'app-identity-verification-mobile',
    templateUrl: './identity-verification-mobile.component.html',
    styleUrls: ['./identity-verification-mobile.component.css']
})
export class IdentityVerificationMobileComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('warning_kyc_level_one') warning_kyc_level_one: any = undefined;
    @ViewChild('warning_kyc_level_two') warning_kyc_level_two: any = undefined;
    @ViewChild('town_select') town_select: any = undefined;

    public process_bar_percentage: number = 1;

    public level_one_hover: boolean = false;
    public level_two_hover: boolean = false;

    public level_one_button_lock: boolean = false;
    public level_two_button_lock: boolean = false;

    public kyc_level_one_form_input_fields: any = undefined;
    public kyc_level_two_form_input_fields: any = undefined;

    public id_card_photo_file: File | null = null;
    public face_photo_file: File | null = null;

    kycLevelOneForm = new UntypedFormGroup({
        tc_no: new UntypedFormControl('', [Validators.required, Validators.minLength(11), Validators.maxLength(11)]),
        name: new UntypedFormControl('', [Validators.required]),
        surname: new UntypedFormControl('', [Validators.required]),
        birth_year: new UntypedFormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]),
        town: new UntypedFormControl('', [Validators.required]),
        phone_number: new UntypedFormControl('', [Validators.required, this.phoneNumberValidator(), Validators.maxLength(10)]),
        address: new UntypedFormControl('', [Validators.required]),
    });

    kycLevelTwoForm = new UntypedFormGroup({
        id_card_photo_file: new UntypedFormControl(null, [Validators.required]),
        face_photo_file: new UntypedFormControl(null, [Validators.required]),
    });

    public subscription_list: (Subscription | undefined)[] = [];

    constructor(public managerService: ManagerService, public elementRef: ElementRef, private httpClient: HttpClient) {
        this.subscription_list.push(this.kycLevelOneForm.get('tc_no')?.valueChanges.subscribe(() => {
            const new_value = this.kycLevelOneForm.get('tc_no')?.value ?? 0;
            if (Math.floor(new_value) !== new_value) {
                this.kycLevelOneForm.get('tc_no')?.setValue(Math.floor(new_value));
            }
        }));

    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.kyc_level_one_form_input_fields = this.elementRef.nativeElement.getElementsByClassName('kyc-level-one-input-field');
        this.kyc_level_two_form_input_fields = this.elementRef.nativeElement.getElementsByClassName('kyc-level-two-input-field');

    }
    phoneNumberValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value) {
                const phoneNumber: string = control.value?.toString().replace(/\D/g, '').substring(0, 10);
                if (UtilityService.validatePhoneNumber(phoneNumber)) {
                    return null;
                }
                return {wrongNumber: control.value};
            }
            return {wrongNumber: control.value};
        };
    }

    verifyKYCLevelOne(): void {
        if (this.level_one_button_lock){
            return;
        }
        for (const input_field of this.kyc_level_one_form_input_fields) {
            input_field.classList.remove('input-field-underline-required');
        }
        this.warning_kyc_level_one.nativeElement.classList.remove('invisible');
        if (this.kycLevelOneForm.invalid) {
            this.warning_kyc_level_one.nativeElement.classList.add('invisible');
            if (this.kycLevelOneForm.get('name')?.errors) {
                this.kyc_level_one_form_input_fields[0].classList.add('input-field-underline-required');
            }
            if (this.kycLevelOneForm.get('surname')?.errors) {
                this.kyc_level_one_form_input_fields[1].classList.add('input-field-underline-required');
            }
            if (this.kycLevelOneForm.get('birth_year')?.errors) {
                this.kyc_level_one_form_input_fields[2].classList.add('input-field-underline-required');
            }
            if (this.kycLevelOneForm.get('tc_no')?.errors) {
                this.kyc_level_one_form_input_fields[3].classList.add('input-field-underline-required');
            }
            if (this.kycLevelOneForm.get('town')?.errors) {
                this.kyc_level_one_form_input_fields[4].classList.add('input-field-underline-required');
            }
            if (this.kycLevelOneForm.get('phone_number')?.errors) {
                this.kyc_level_one_form_input_fields[5].classList.add('input-field-underline-required');
            }
            if (this.kycLevelOneForm.get('address')?.errors) {
                this.kyc_level_one_form_input_fields[6].classList.add('input-field-underline-required');
            }
            // @ts-ignore
            this.warning_kyc_level_one.nativeElement.innerHTML = this.managerService.config.RESPONSE_TABLE['2005'].message;
            this.warning_kyc_level_one.nativeElement.classList.remove('invisible');
        } else {
            const data = {
                tc_no: this.kycLevelOneForm.value.tc_no,
                name: this.kycLevelOneForm.value.name,
                surname: this.kycLevelOneForm.value.surname,
                birth_year: this.kycLevelOneForm.value.birth_year,
                town: this.kycLevelOneForm.value.town,
                phone_number: this.kycLevelOneForm.value.phone_number,
                address: this.kycLevelOneForm.value.address
            };
            this.level_one_button_lock = true;
            this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'verify_kyc_service', data).subscribe(
                response => {
                    setTimeout(() => {
                        this.level_one_button_lock = false;
                    }, 1000);
                    if (!response.is_okay) {
                        this.warning_kyc_level_one.nativeElement.innerHTML = this.managerService.codeHandler(response);
                        this.warning_kyc_level_one.nativeElement.classList.remove('invisible');
                    }
                    // @ts-ignore
                    const content = this.managerService.config.RESPONSE_TABLE[response.code];
                    this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));

                    this.managerService.marketingService.logEvent('account_level_upgrade_one', {result: content.is_okay});
                }));
        }
    }

    public resetIDCardFile(): void {
        this.kycLevelTwoForm.get('id_card_photo_file')?.setValue(null);
    }

    public resetFaceFile(): void {
        this.kycLevelTwoForm.get('face_photo_file')?.setValue(null);
    }

    handleIDCardFileInput(input_element: any): void {
        this.id_card_photo_file = input_element.files[0];
    }

    handleFaceFileInput(input_element: any): void {
        this.face_photo_file = input_element.files[0];
    }

    verifyKYCLevelTwo(): void {
        if (this.level_two_button_lock){
            return;
        }
        for (const input_field of this.kyc_level_two_form_input_fields) {
            input_field.classList.remove('input-field-required');
        }

        this.warning_kyc_level_two.nativeElement.classList.remove('invisible');

        if (this.kycLevelTwoForm.invalid) {
            if (this.kycLevelTwoForm.get('id_card_photo_file')?.errors) {
                this.kyc_level_two_form_input_fields[0].classList.add('input-field-required');
            }
            if (this.kycLevelTwoForm.get('face_photo_file')?.errors) {
                this.kyc_level_two_form_input_fields[1].classList.add('input-field-required');
            }
            // @ts-ignore
            this.warning_kyc_level_two.nativeElement.innerHTML = this.managerService.config.RESPONSE_TABLE['2005'].message;
            this.warning_kyc_level_two.nativeElement.classList.remove('invisible');
        } else {
            const data: FormData = new FormData();
            // @ts-ignore
            data.append('id_card_photo_file', this.id_card_photo_file);
            // @ts-ignore
            data.append('face_photo_file', this.face_photo_file);
            const auth = new Blob([JSON.stringify({
                sid: this.managerService.client_socket.id,
                client_ip: this.managerService.client_ip,
                cookie: this.managerService.cookie
            })], {type: 'application/json'});
            data.append('auth', auth);

            this.process_bar_percentage = 0;

            this.managerService.openModal('loading-modal');
            this.level_two_button_lock = true;
            this.httpClient.post<any>(this.managerService.environment.serverAPI + 'appeal_second_level_kyc_service', data, {
                reportProgress: true,
                observe: 'events'
            }).pipe(
                map((event) => {
                    if (event.type === HttpEventType.UploadProgress) {
                        // @ts-ignore
                        this.process_bar_percentage = event.loaded / event.total;
                    } else if (event.type === HttpEventType.Response) {
                        setTimeout(() => {
                            this.level_two_button_lock = false;
                        }, 1000);
                        const response = event.body;
                        // @ts-ignore
                        const content = this.managerService.config.RESPONSE_TABLE[response.code];
                        this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));

                        this.managerService.marketingService.logEvent('account_level_upgrade_two', {});
                    }
                })
            ).toPromise().then(() => {
                this.managerService.closeModal('loading-modal');
            });
        }
    }

    ngOnDestroy(): void {
        this.subscription_list.forEach((subscription) => {
            subscription?.unsubscribe();
        });
    }

}
