<div class="d-flex flex-column" style=" cursor: pointer">
    <div class="d-flex flex-row justify-content-between align-items-center p-2">
    <span class="sss-header">
      {{drawerHeader}}
    </span>
        <svg #arrow class="arrow" height="18px" width="18px">
            <use [attr.xlink:href]='"./assets/images/v2/ic_down.svg#ic_down"' fill="none" stroke="#37324D"></use>
        </svg>
    </div>
    <div style="height: 1px; background: #E2E2E2;"></div>
    <span [innerHTML]="drawerText" #body class="sss-body">
  </span>
</div>
