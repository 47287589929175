<div class="d-flex flex-column" style="background: #FFFFFF; padding: 48px 64px">
    <!--    Header and close button row -->
    <div class="d-flex flex-row justify-content-between align-items-center pb-4">
        <h1>{{managerService.config.BILLING_ADDRESS.HEADER| managerPipe }}</h1>
        <div (click)="closeModal()" style="cursor: pointer">
            <img alt="close" src="assets/images/close.svg" style="width: 24px; height: 24px">
        </div>

    </div>

    <h2 style="margin-bottom: 12px">{{managerService.config.USER_INFORMATION.DELIVERY_ADDRESS| managerPipe }}</h2>
    <p [innerHTML]="managerService.config.PAYMENT.MANDATORY_FIELDS | managerPipe" style="margin-bottom: 24px"></p>
    <!--    Form Group -->
    <div [formGroup]="deliveryAddressForm" class="d-flex flex-column w-100"
         style="gap: 12px;margin-top:12px; margin-bottom: 12px">
        <!--        Address Title -->
        <div class="d-flex form-group focused">
            <label class=" d-flex form-label" for="label">
                {{managerService.config.BILLING_ADDRESS.INDIVIDUAL_HEADERS.ADDRESS_HEADER}}</label>
            <input class="w-100 readonly-input" formControlName="label" id="label" style="color: #404352;"
                   type="text">
        </div>
        <!--        Name Surname Identity Number -->
        <div class="w-100 d-flex flex-column" style="gap: 12px">
            <!--            Name -->
            <div class="d-flex form-group focused">
                <label class=" d-flex form-label" for="name">
                    {{managerService.config.USER_INFORMATION.NAME | managerPipe}}</label>
                <input class="w-100 readonly-input" id="name" readonly="readonly"
                       type="text" value="{{managerService.userService.user_info?.name}}">
            </div>
            <!--            Surname -->
            <div class="d-flex form-group focused">
                <label class=" d-flex form-label" for="surname">
                    {{managerService.config.USER_INFORMATION.SURNAME | managerPipe}}</label>
                <input class="w-100 readonly-input" id="surname" readonly="readonly"
                       type="text" value="{{managerService.userService.user_info?.surname}}">
            </div>
            <!--            Identity Number -->
            <div class="d-flex form-group focused">
                <label class=" d-flex form-label"
                       for="idNumber"> {{managerService.config.USER_INFORMATION.TC_NUMBER | managerPipe}}</label>
                <input [type]="(tc_number_visible | managerPipe) ? 'text' : 'password'" class="w-100 readonly-input"
                       id="idNumber" readonly="readonly" value="{{managerService.userService.user_info?.tc_no}}">
                <svg (click)="tc_number_visible = !tc_number_visible" class="password-eye" style="width: 20px; height: 20px">
                    <use *ngIf="(!tc_number_visible | managerPipe)"
                         [attr.xlink:href]='"./assets/images/eye_hidden.svg#eye_hidden"'
                         fill="#9DA5B7"/>
                    <use *ngIf="(tc_number_visible | managerPipe)"
                         [attr.xlink:href]='"./assets/images/eye_visible.svg#eye_visible"'
                         fill="#9DA5B7"/>
                </svg>
            </div>
        </div>
        <div class="w-100 d-flex flex-column" style="gap: 12px">
            <!--            Phone Number -->
            <div class="d-flex form-group focused">
                <label class=" d-flex form-label" for="phoneNumber">
                    <span class="asterix">*</span>{{managerService.config.USER_INFORMATION.PHONE_NUMBER | managerPipe}}
                </label>
                <input class="w-100 form-input" formControlName="phone_number" id="phoneNumber" placeholder="(___) ___ __ __"
                       style="color: #404352;" type="text">
            </div>
            <!--            Province Dropdown -->
            <div (click)="town_dropdown_menu.classList.remove('d-none')"
                 class="d-flex form-group focused dropdown-native-button">
                <label class="d-flex form-label" for="province">
                    <span class="asterix">*</span> {{managerService.config.USER_INFORMATION.CITY | managerPipe}}</label>
                <input #province class="w-100 readonly-input" formControlName="province" id="province"
                       readonly="readonly"
                       style="cursor: pointer;color: #404352" type="text">
                <svg class="dropdown-icon">
                    <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#9DA5B7"/>
                </svg>
                <div #town_dropdown_menu class="dropdown-native-menu-white d-none" style="margin-top: 32px">
                    <button
                        (click)="town_dropdown_menu.classList.add('d-none'); deliveryAddressForm.get('province')?.setValue(town); deliveryAddressForm.get('district')?.reset(); "
                        *ngFor="let town of (null | managerPipe: managerService.mpa_template.GET_CITIES_LIST)"
                        class="dropdown-native-item-white btn-native justify-content-center" type="button">
                        {{town | managerPipe}}
                    </button>
                </div>
            </div>

            <div (click)="district_dropdown_menu.classList.remove('d-none')"
                 class="d-flex form-group focused dropdown-native-button">
                <label class="d-flex form-label" for="district">
                    <span
                        class="asterix">*</span>{{managerService.config.BILLING_ADDRESS.CORPORATE_HEADERS.DISTRICT | managerPipe}}
                </label>
                <input class="w-100 readonly-input" formControlName="district" id="district"
                       readonly="readonly"
                       style="cursor: pointer;color: #404352" type="text">
                <svg class="dropdown-icon">
                    <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#9DA5B7"/>
                </svg>
                <div #district_dropdown_menu class="dropdown-native-menu-white d-none" style="margin-top: 32px">
                    <button (click)="district_dropdown_menu.classList.add('d-none'); deliveryAddressForm.get('district')?.setValue(dist) "
                            *ngFor="let dist of (province.value | managerPipe: managerService.mpa_template.GET_DISTRICT_LIST)"
                            class="dropdown-native-item-white btn-native justify-content-center" type="button">
                        {{dist | managerPipe}}
                    </button>
                </div>
            </div>
        </div>
        <div class="d-flex form-group focused">
            <label class=" d-flex form-label" for="address">
                <span class="asterix">*</span>{{managerService.config.USER_INFORMATION.ADDRESS | managerPipe}}</label>
            <input class="w-100  readonly-input"
                   formControlName="address"
                   id="address"
                   style="color: #404352;font-family: Roboto,sans-serif;font-size: 12px;font-style: normal;font-weight: 400;line-height: normal;" type="text">
        </div>
    </div>
    <div class="d-flex flex-row justify-content-center" style="gap: 32px ">
        <div (click)="closeModal()" class="cancel-button" style="cursor: pointer">
            <span class="text">{{managerService.config.AUCTION.BILLING.CANCEL | managerPipe}}</span>
        </div>
        <div (click)=" saveDeliveryAddress();" class="save-button" style="cursor: pointer">
            <span class="text">{{managerService.config.AUCTION.BILLING.SAVE_ADDRESS | managerPipe}}</span>
        </div>

    </div>
</div>
