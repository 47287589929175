export const announcements_config_en =  {
    OPERATION_LIST: [
        {
            TOPIC: 'Artwork Increased Its Value 35 Times',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Andy Warhol\'s Artwork That Increases Its Value 20 Times Over Time: Four Marilyns</div>' +
                'We all know Andy Warhol\'s "Four Marilyns" painting.' +
                ' This painting, which is one of the most well-known artworks of the pop art movement, has appeared many times throughout our lives.' +
                ' Even those of us who are not very interested in contemporary arts know that this painting belongs to Andy Warhol.<br/>' +
                'As you can imagine, Four Marilyns has a price tag with plenty of zeros.' +
                ' The fact that the painting is one of Andy Warhol\'s most well-known artworks and turned it into a cultural icon of the 20th century' +
                ' is effective in the high market value of the painting.<br/>' +
                'Let\'s briefly talk about the story of the painting without going deeper into the market value of the painting.<br/>' +
                'We all know that Andy Warhol\'s artwork is fed by the pop culture of his time.' +
                ' Cinema is also one of the important components of this culture. In popular cinema, Marilyn Monroe is one of the most iconic actresses of the period.<br/>' +
                'August 6, 1962 was Andy Warhol\'s 32nd birthday.' +
                ' The headline news of the New York Times on the morning of his birthday was a harbinger that Andy Warhol would always remember this birthday.' +
                ' World-famous movie star Marilyn Monroe was found dead. In the headline of the newspaper, “The body of the star was found in the bedroom.' +
                ' There are pills nearby. Police said no notes were left.' +
                ' The official announcement will be made later.” was writing.<br/>' +
                'This death was shocking for Andy Warhol.' +
                ' Marilyn Monroe would become one of the subjects he would visit frequently in his art life.<br/>' +
                'Andy Warhol found the inspiration he needed for the Marilyn Monroe series in Serendipity 3, a sweet shop in New York.' +
                ' Marilyn Monroe was also a regular at this place.' +
                ' The candies of Serendipity 3 inspired the Marilyn series, which is dominated by candy colors.<br/>' +
                'Created by Andy Warhol in 1962, Four Marilyns is one of the most ideal artworks to explain the dynamics of the art market.' +
                ' Four Marilyns were sold by Sotheby\'s in London in 1992 with a price tag of $955,433.' +
                ' In the first months of 2015, the artwork was bought by Kemal Has Çıngıllıoğlu, a Turkish collector, for 44 million dollars.' +
                ' After adjusting for inflation, the price of the artwork had increased 27.5 times in 23 years.<br/>' +
                'The Four Marilyns were again put up for sale at an auction held by Christie\'s towards the end of 2015.' +
                ' The new price of the artwork was estimated to be between $40 and $60 million.' +
                ' However, this prediction did not come true and the piece sold for a price tag of $36,005,000.' +
                ' Experts attributed the artwork\'s failure to reach its estimated value to the stagnation of Chinese art investors.<br/>' +
                'Especially after 2010, with the increase in the number of Chinese art investors, we can say that a new competition has emerged in the art markets.' +
                ' Until this time, the market, which was generally under the control of Western collectors,' +
                ' witnessed a competitive environment in which prices went up with the participation of investors from China.' +
                ' However, the economic difficulties that China has experienced from time to time have affected the investments of Chinese investors in works of art.' +
                ' That\'s why some artworks were sold at lower than expected prices.<br/>' +
                'As we mentioned in our previous articles, the value of a artwork of art is determined according to the artist who produced the artwork,' +
                ' the place of the artwork in the art world and the financial situation of art investors.' +
                ' In the example of the Four Marilyns, we see Andy Warhol, an artist who is becoming more and more famous, a painting that has become a cultural icon,' +
                ' and Chinese investors who are affected by the general economic situation.<br/>' +
                'Although it was below the estimated sales figure in the last auction, Four Marilyns; It has increased its value more than 20 times over the years.' +
                ' The biggest reason for this is that the name Andy Warhol will always be remembered.<br/>' +
                'Pablo Picasso is one of the important names like Andy Warhol for the art world.' +
                ' It may be impossible for you to own a Pablo Picasso piece on your own.' +
                ' But with Artiox this is possible.' +
                ' Today, you can become a member of Artiox and invest in Tete d\'Histrion, a Pablo Picasso artwork in Artiox\'s portfolio, even if you have very limited investment capital.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Andy Warhol\'s Artwork That Increases Its Value 20 Times Over Time: Four Marilyns</div>' +
                'We all know Andy Warhol\'s "Four Marilyns" painting.' +
                ' This painting, which is one of the most well-known artworks of the pop art movement, has appeared many times throughout our lives.' +
                ' Even those of us who are not very interested in contemporary arts know that this painting belongs to Andy Warhol.<br/>' +
                'As you can imagine, Four Marilyns has a price tag with plenty of zeros.' +
                ' The fact that the painting is one of Andy Warhol\'s most well-known artworks and turned it into a cultural icon of the 20th century' +
                ' is effective in the high market value of the painting.<br/>' +
                'Let\'s briefly talk about the story of the painting without going deeper into the market value of the painting.<br/>' +
                'We all know that Andy Warhol\'s artwork is fed by the pop culture of his time.' +
                ' Cinema is also one of the important components of this culture. In popular cinema, Marilyn Monroe is one of the most iconic actresses of the period.<br/>' +
                'August 6, 1962 was Andy Warhol\'s 32nd birthday.' +
                ' The headline news of the New York Times on the morning of his birthday was a harbinger that Andy Warhol would always remember this birthday.' +
                ' World-famous movie star Marilyn Monroe was found dead. In the headline of the newspaper, “The body of the star was found in the bedroom.' +
                ' There are pills nearby. Police said no notes were left.' +
                ' The official announcement will be made later.” was writing.<br/>' +
                'This death was shocking for Andy Warhol.' +
                ' Marilyn Monroe would become one of the subjects he would visit frequently in his art life.<br/>' +
                'Andy Warhol found the inspiration he needed for the Marilyn Monroe series in Serendipity 3, a sweet shop in New York.' +
                ' Marilyn Monroe was also a regular at this place.' +
                ' The candies of Serendipity 3 inspired the Marilyn series, which is dominated by candy colors.<br/>' +
                'Created by Andy Warhol in 1962, Four Marilyns is one of the most ideal artworks to explain the dynamics of the art market.' +
                ' Four Marilyns were sold by Sotheby\'s in London in 1992 with a price tag of $955,433.' +
                ' In the first months of 2015, the artwork was bought by Kemal Has Çıngıllıoğlu, a Turkish collector, for 44 million dollars.' +
                ' After adjusting for inflation, the price of the artwork had increased 27.5 times in 23 years.<br/>' +
                'The Four Marilyns were again put up for sale at an auction held by Christie\'s towards the end of 2015.' +
                ' The new price of the artwork was estimated to be between $40 and $60 million.' +
                ' However, this prediction did not come true and the piece sold for a price tag of $36,005,000.' +
                ' Experts attributed the artwork\'s failure to reach its estimated value to the stagnation of Chinese art investors.<br/>' +
                'Especially after 2010, with the increase in the number of Chinese art investors, we can say that a new competition has emerged in the art markets.' +
                ' Until this time, the market, which was generally under the control of Western collectors,' +
                ' witnessed a competitive environment in which prices went up with the participation of investors from China.' +
                ' However, the economic difficulties that China has experienced from time to time have affected the investments of Chinese investors in works of art.' +
                ' That\'s why some artworks were sold at lower than expected prices.<br/>' +
                'As we mentioned in our previous articles, the value of a artwork of art is determined according to the artist who produced the artwork,' +
                ' the place of the artwork in the art world and the financial situation of art investors.' +
                ' In the example of the Four Marilyns, we see Andy Warhol, an artist who is becoming more and more famous, a painting that has become a cultural icon,' +
                ' and Chinese investors who are affected by the general economic situation.<br/>' +
                'Although it was below the estimated sales figure in the last auction, Four Marilyns; It has increased its value more than 20 times over the years.' +
                ' The biggest reason for this is that the name Andy Warhol will always be remembered.<br/>' +
                'Pablo Picasso is one of the important names like Andy Warhol for the art world.' +
                ' It may be impossible for you to own a Pablo Picasso piece on your own.' +
                ' But with Artiox this is possible.' +
                ' Today, you can become a member of Artiox and invest in Tete d\'Histrion, a Pablo Picasso artwork in Artiox\'s portfolio, even if you have very limited investment capital.',
            ROUTER_LINK: {
                TEXT: 'View Artworks',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'NFT Artwork Sales Reach $3.5 Billion',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">NFT Artwork Sales Reach $3.5 Billion</div>' +
                'The economy created by NFTs, seen as a bubble by conservative art circles, reached $3.5 billion in 2021.' +
                ' While sales of 6.8 billion dollars were made in the first half of the year,' +
                ' it is said that the total sales at the end of the year may increase even more in the second half, especially thanks to the boom in August.' +
                ' Total online artwork sales are also expected to reach $13.5 billion.<br>' +
                'For the art market, which is used to buying and selling physical artworks in the physical environment, these numbers are quite striking.' +
                ' The emerging trend indicates the entry of new investors into the art market and the formation of visibly large communities around NFT marketplaces.<br>' +
                'Behind the rapid growth of NFTs is that blockchain technology has overcome many of the problems in the art market.' +
                ' Thanks to the blockchain, it is very easy to guarantee the uniqueness of an artifact.' +
                ' Thanks to this technology, the owners of the works can continue to earn income from the works they own throughout their entire lives.' +
                ' On the other hand, the process of changing hands of artifacts is much faster than physical artifacts.' +
                ' In this way, the NFT economy has more mobility.' +
                '<div class="job-advertisement-heading">High Volatility</div>' +
                'In March 2021, the NFT market hit a peak with $356 million.' +
                ' However, a few months later, sales of $1.7 billion in August quickly turned that peak into a small hill.' +
                ' However, in September, the market returned to its old growth trend.' +
                ' This shows that the NFT market has intense volatility just like other crypto markets.<br>' +
                'If we look at the creative side, 2021 was the year when collectible creations started to come to the fore.' +
                ' While NFTs of artists who worked alone were on the agenda before, in 2021 collectible creations such as Crypto Punks and Bored Apes Yacht Club became the talk.' +
                '<div class="job-advertisement-heading">NFTs Aren\'t Just a Bubble</div>' +
                'While some of the traditional art world view NFTs as one big bubble, the reality is a little different.' +
                ' In addition to the market volume created by NFTs, the efforts of important institutions, such as Christie\'s and Sotheby\'s,' +
                ' for the traditional art market to take place in the NFT world are important proofs that this change is not a bubble.<br>' +
                'The NFT concept brings together technology and art.' +
                ' There is often a period of faltering when major change occurs, both in technology and in the arts.' +
                ' The world of NFT opens up new playgrounds for artists, investors and entrepreneurs with the new possibilities it offers.' +
                ' Time will tell which concepts and which players can survive in these playgrounds.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">NFT Artwork Sales Reach $3.5 Billion</div>' +
                'The economy created by NFTs, seen as a bubble by conservative art circles, reached $3.5 billion in 2021.' +
                ' While sales of 6.8 billion dollars were made in the first half of the year,' +
                ' it is said that the total sales at the end of the year may increase even more in the second half, especially thanks to the boom in August.' +
                ' Total online artwork sales are also expected to reach $13.5 billion.<br>' +
                'For the art market, which is used to buying and selling physical artworks in the physical environment, these numbers are quite striking.' +
                ' The emerging trend indicates the entry of new investors into the art market and the formation of visibly large communities around NFT marketplaces.<br>' +
                'Behind the rapid growth of NFTs is that blockchain technology has overcome many of the problems in the art market.' +
                ' Thanks to the blockchain, it is very easy to guarantee the uniqueness of an artifact.' +
                ' Thanks to this technology, the owners of the works can continue to earn income from the works they own throughout their entire lives.' +
                ' On the other hand, the process of changing hands of artifacts is much faster than physical artifacts.' +
                ' In this way, the NFT economy has more mobility.' +
                '<div class="job-advertisement-heading">High Volatility</div>' +
                'In March 2021, the NFT market hit a peak with $356 million.' +
                ' However, a few months later, sales of $1.7 billion in August quickly turned that peak into a small hill.' +
                ' However, in September, the market returned to its old growth trend.' +
                ' This shows that the NFT market has intense volatility just like other crypto markets.<br>' +
                'If we look at the creative side, 2021 was the year when collectible creations started to come to the fore.' +
                ' While NFTs of artists who worked alone were on the agenda before, in 2021 collectible creations such as Crypto Punks and Bored Apes Yacht Club became the talk.' +
                '<div class="job-advertisement-heading">NFTs Aren\'t Just a Bubble</div>' +
                'While some of the traditional art world view NFTs as one big bubble, the reality is a little different.' +
                ' In addition to the market volume created by NFTs, the efforts of important institutions, such as Christie\'s and Sotheby\'s,' +
                ' for the traditional art market to take place in the NFT world are important proofs that this change is not a bubble.<br>' +
                'The NFT concept brings together technology and art.' +
                ' There is often a period of faltering when major change occurs, both in technology and in the arts.' +
                ' The world of NFT opens up new playgrounds for artists, investors and entrepreneurs with the new possibilities it offers.' +
                ' Time will tell which concepts and which players can survive in these playgrounds.',
            ROUTER_LINK: {
                TEXT: 'View Artworks',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 1640073600000,
        },
        {
            TOPIC: 'What is Impressionism?',
            IMAGE_SRC: '',
            TEXT_MOBILE: '<div class="job-advertisement-heading">What is Impressionism? The Most Expensive Impressionist Paintings</div>' +
                'In 1874, a group of painters whose artworks were not deemed worthy to be exhibited by the Paris Salon decided to organize their own exhibition.' +
                ' This exhibition, attended by Claude Monet, Berthe Morisot, Camille Pissarro, Pierre August Renoir and Edgar Degas, heralded the birth of impressionism.<br>' +
                'At the end of the 19th century, when religious and historical paintings were in demand, landscapes and still lifes were vilified,' +
                ' while portraits and paintings depicting scenes from the Bible were prized.' +
                ' Impressionist paintings also got their share from it. Both the art circles and the public did not like the impressionist artworks and did not hesitate to express it.' +
                ' These paintings marked the death of the old art systems and the birth of a new one. We now call this new system modern art.' +
                '<div class="job-advertisement-heading">Identifier Properties</div>' +
                'We have said that the subjects of Impressionist paintings are generally landscapes or scenes encountered in daily life.' +
                ' We can also say that thick brush strokes are one of the most distinguishing features of impressionist paintings.' +
                ' Impressionists used thick and distinct brushstrokes, in contrast to the efforts of other artists of the period to hide the traces of brushstrokes.' +
                ' These blows gave the painting the impression of a "volatile moment".' +
                ' Another characteristic feature of the paintings of Impressionist artists was the different color palette used by the artists.' +
                ' Impressionist painters combined different colors with separate brushstrokes, rather than mixing colors to achieve specific hues.' +
                ' They mostly used bright colors.<br>' +
                'Many Impressionist painters painted outdoors.' +
                ' This got them thinking about and experimenting with light and its effect on landscapes, structures, and other outdoor objects.' +
                ' For most Impressionist painters, we can say that painting light faithfully is more important than the subject of the painting.' +
                ' These artists painted the same landscape over and over, under different lighting and weather conditions.<br>' +
                'Impressionist painters created asymmetrical, unconventional compositions.' +
                ' Perhaps under the influence of the newly developing photography at that time, they produced pictures that captured a particular moment in time.' +
                '<div class="job-advertisement-heading">Pioneers</div>' +
                'Claude Monet is considered one of the founders of impressionism.' +
                ' Monet wanted to paint nature as he saw it.' +
                ' He was completing his paintings relatively quickly by applying another coat before one coat of paint dries.' +
                ' Using bold colors and rejecting traditional art rules, the painter mostly painted landscapes.<br>' +
                'Édouard Manet, who is associated with realism as well as Impressionism, was influenced by the Impressionists after influencing them.' +
                ' Manet used colors as a tool to create perspective.' +
                ' For example, it showed the proximity of an orange dress to light with different shades of orange.' +
                ' This method was quickly adopted by other Impressionists and became one of the most used methods of oil painting for generations to come.<br>' +
                'Edgar Degas, on the other hand, often painted Parisian life.' +
                ' We often see dancers, singers and women in Degas\'s paintings, especially depicting women.' +
                ' Degas depicts strange postures under artificial light at interesting angles; rejected historical and mythical themes and drew inspiration from modern everyday life.' +
                ' The painter was also widely criticized for his models being from the lower class.' +
                'Pierre August Renoir, who mostly depicted modern Paris and 19th century entertainment events, produced dynamic artworks by playing with light and shadow.<br>' +
                'Among the pioneers of Impressionism were two women painters.' +
                ' Berthe Morisot depicted the domestic life of women at that time in rich compositions.' +
                ' We often see naked women and her daughter Julie in Morisot\'s paintings.' +
                ' Mary Cassatt joined the Impressionists at the invitation of her friend Degas.' +
                ' Cassatt painted mostly everyday scenes from home life and mothers caring for their children.' +
                ' Unlike most painters before him, Cassatt portrayed women as they are, without embellishing or beautifying them.' +
                '<div class="job-advertisement-heading">The Most Expensive Impressionist Paintings</div>' +
                'Cezanne\'s painting The Card Players was sold to the Qatari Royal Family in 2011.' +
                ' At $250 million ($287.6 million today), the painting was the most expensive painting ever sold and held the record until 2017.' +
                ' The painter\'s painting, entitled Monte Sainte Victoire (Mount Sainte-Victoire), sold for $100 million ($111 million today) in 2013.' +
                ' Again, Cezanne\'s painting titled Rideau, Cruche et un Bol de Fruits (Curtain, Jug and Fruit Bowl) was sold for $ 60.5 million in 1999.' +
                ' The current value of the artwork is estimated at $120 million.' +
                'Renoir\'s painting Bal du Moulin de la Galette (Dancing in the Moulin de Galette) sold for $78.1 million ($154.7 million today) at Sotheby\'s New York in 1990.<br>' +
                'Monet\'s painting entitled Les Nymphéas (The Water Lilies) sold for $70.4 million this year.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">What is Impressionism? The Most Expensive Impressionist Paintings</div>' +
                'In 1874, a group of painters whose artworks were not deemed worthy to be exhibited by the Paris Salon decided to organize their own exhibition.' +
                ' This exhibition, attended by Claude Monet, Berthe Morisot, Camille Pissarro, Pierre August Renoir and Edgar Degas, heralded the birth of impressionism.<br>' +
                'At the end of the 19th century, when religious and historical paintings were in demand, landscapes and still lifes were vilified,' +
                ' while portraits and paintings depicting scenes from the Bible were prized.' +
                ' Impressionist paintings also got their share from it. Both the art circles and the public did not like the impressionist artworks and did not hesitate to express it.' +
                ' These paintings marked the death of the old art systems and the birth of a new one. We now call this new system modern art.' +
                '<div class="job-advertisement-heading">Identifier Properties</div>' +
                'We have said that the subjects of Impressionist paintings are generally landscapes or scenes encountered in daily life.' +
                ' We can also say that thick brush strokes are one of the most distinguishing features of impressionist paintings.' +
                ' Impressionists used thick and distinct brushstrokes, in contrast to the efforts of other artists of the period to hide the traces of brushstrokes.' +
                ' These blows gave the painting the impression of a "volatile moment".' +
                ' Another characteristic feature of the paintings of Impressionist artists was the different color palette used by the artists.' +
                ' Impressionist painters combined different colors with separate brushstrokes, rather than mixing colors to achieve specific hues.' +
                ' They mostly used bright colors.<br>' +
                'Many Impressionist painters painted outdoors.' +
                ' This got them thinking about and experimenting with light and its effect on landscapes, structures, and other outdoor objects.' +
                ' For most Impressionist painters, we can say that painting light faithfully is more important than the subject of the painting.' +
                ' These artists painted the same landscape over and over, under different lighting and weather conditions.<br>' +
                'Impressionist painters created asymmetrical, unconventional compositions.' +
                ' Perhaps under the influence of the newly developing photography at that time, they produced pictures that captured a particular moment in time.' +
                '<div class="job-advertisement-heading">Pioneers</div>' +
                'Claude Monet is considered one of the founders of impressionism.' +
                ' Monet wanted to paint nature as he saw it.' +
                ' He was completing his paintings relatively quickly by applying another coat before one coat of paint dries.' +
                ' Using bold colors and rejecting traditional art rules, the painter mostly painted landscapes.<br>' +
                'Édouard Manet, who is associated with realism as well as Impressionism, was influenced by the Impressionists after influencing them.' +
                ' Manet used colors as a tool to create perspective.' +
                ' For example, it showed the proximity of an orange dress to light with different shades of orange.' +
                ' This method was quickly adopted by other Impressionists and became one of the most used methods of oil painting for generations to come.<br>' +
                'Edgar Degas, on the other hand, often painted Parisian life.' +
                ' We often see dancers, singers and women in Degas\'s paintings, especially depicting women.' +
                ' Degas depicts strange postures under artificial light at interesting angles; rejected historical and mythical themes and drew inspiration from modern everyday life.' +
                ' The painter was also widely criticized for his models being from the lower class.' +
                'Pierre August Renoir, who mostly depicted modern Paris and 19th century entertainment events, produced dynamic artworks by playing with light and shadow.<br>' +
                'Among the pioneers of Impressionism were two women painters.' +
                ' Berthe Morisot depicted the domestic life of women at that time in rich compositions.' +
                ' We often see naked women and her daughter Julie in Morisot\'s paintings.' +
                ' Mary Cassatt joined the Impressionists at the invitation of her friend Degas.' +
                ' Cassatt painted mostly everyday scenes from home life and mothers caring for their children.' +
                ' Unlike most painters before him, Cassatt portrayed women as they are, without embellishing or beautifying them.' +
                '<div class="job-advertisement-heading">The Most Expensive Impressionist Paintings</div>' +
                'Cezanne\'s painting The Card Players was sold to the Qatari Royal Family in 2011.' +
                ' At $250 million ($287.6 million today), the painting was the most expensive painting ever sold and held the record until 2017.' +
                ' The painter\'s painting, entitled Monte Sainte Victoire (Mount Sainte-Victoire), sold for $100 million ($111 million today) in 2013.' +
                ' Again, Cezanne\'s painting titled Rideau, Cruche et un Bol de Fruits (Curtain, Jug and Fruit Bowl) was sold for $ 60.5 million in 1999.' +
                ' The current value of the artwork is estimated at $120 million.' +
                'Renoir\'s painting Bal du Moulin de la Galette (Dancing in the Moulin de Galette) sold for $78.1 million ($154.7 million today) at Sotheby\'s New York in 1990.<br>' +
                'Monet\'s painting entitled Les Nymphéas (The Water Lilies) sold for $70.4 million this year.',
            ROUTER_LINK: {
                TEXT: 'View Artworks',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 1639897200000,
        },
        {
            TOPIC: 'Highlights of the Art Market in 2021',
            IMAGE_SRC: '',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Highlights of the Art Market in 2021</div>' +
                '2021 has been a year in the COVID-19 game, just like 2020.' +
                ' Uncertainty prevailed in the art industry, just as in other industries, in this year when investors had difficulties in producing sound predictions.' +
                ' This uncertainty caused the market to shrink.' +
                ' It is not easy to say how long it will take for investors to see their way.<br>' +
                'Although the general picture is negative, positive developments are also experienced.' +
                ' The concept of “physical art” being stuck within four walls due to the closures has fueled the growth of online art.' +
                ' An unprecedented activity has begun to occur in the digital art market.<br>' +
                'We\'ve compiled the highlights of the art market for 2021 so you can see the overall picture more clearly.' +
                '<div class="job-advertisement-heading">The Art Market Has Begun to Recover</div>' +
                '2020 had been a year in which the art market had shrunk to a devastating extent.' +
                ' This year, the art market had shrunk by 22%.' +
                ' In 2022, auction sales of important institutions such as Christie\'s, Sotheby\'s and Phillips increased by 230% compared to 2021, reaching almost the level of 2019.' +
                ' Considering the impact of these institutions on the art market, we can say that 2021 is a year of recovery.' +
                '<div class="job-advertisement-heading">Explosion in Online Sales</div>' +
                'In 2021, revenue from online artwork sales rose to $12.4 billion.' +
                ' That this greatness is derived only from physical artworks and antiques; Let\'s say that this figure does not include digital artwork sales.' +
                '<div class="job-advertisement-heading">A New Collector Definition Has Been Revealed</div>' +
                'The year 2021, which we have come to an end, causes many concepts to be reinterpreted in the art market.' +
                ' At the beginning of these concepts is “collection”.<br>' +
                'The generation gap is the most important reason for behavioral changes among collectors.' +
                ' This difference between generations has become much more observable, thanks to the fact that digital channels' +
                ' have become more important in the world under the effect of the pandemic.<br>' +
                'A new audience of collectors who are inclined to invest in digital artworks, value artists and artworks that go beyond the conventional formats of art,' +
                ' and take higher risks in the commercial future of an artwork became more visible in 2021.' +
                '<div class="job-advertisement-heading">China Overtakes the USA</div>' +
                'In the auction economy, China\'s rise to leadership by replacing the United States was an important development.' +
                ' China holds 36% of this market. The USA has 29% and the UK 16%.' +
                '<div class="job-advertisement-heading">Online Sales on the Rise</div>' +
                'Although the overall picture of the art market was negative, there was a huge increase in online sales.' +
                ' Online sales channels, which have grown by 25% compared to 2020, have also enabled the emergence of new audiences who buy art.' +
                ' Digital concepts such as NFT, which had a very limited impact before, deeply affected this growth with the explosion they experienced in 2021.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Highlights of the Art Market in 2021</div>' +
                '2021 has been a year in the COVID-19 game, just like 2020.' +
                ' Uncertainty prevailed in the art industry, just as in other industries, in this year when investors had difficulties in producing sound predictions.' +
                ' This uncertainty caused the market to shrink.' +
                ' It is not easy to say how long it will take for investors to see their way.<br>' +
                'Although the general picture is negative, positive developments are also experienced.' +
                ' The concept of “physical art” being stuck within four walls due to the closures has fueled the growth of online art.' +
                ' An unprecedented activity has begun to occur in the digital art market.<br>' +
                'We\'ve compiled the highlights of the art market for 2021 so you can see the overall picture more clearly.' +
                '<div class="job-advertisement-heading">The Art Market Has Begun to Recover</div>' +
                '2020 had been a year in which the art market had shrunk to a devastating extent.' +
                ' This year, the art market had shrunk by 22%.' +
                ' In 2022, auction sales of important institutions such as Christie\'s, Sotheby\'s and Phillips increased by 230% compared to 2021, reaching almost the level of 2019.' +
                ' Considering the impact of these institutions on the art market, we can say that 2021 is a year of recovery.' +
                '<div class="job-advertisement-heading">Explosion in Online Sales</div>' +
                'In 2021, revenue from online artwork sales rose to $12.4 billion.' +
                ' That this greatness is derived only from physical artworks and antiques; Let\'s say that this figure does not include digital artwork sales.' +
                '<div class="job-advertisement-heading">A New Collector Definition Has Been Revealed</div>' +
                'The year 2021, which we have come to an end, causes many concepts to be reinterpreted in the art market.' +
                ' At the beginning of these concepts is “collection”.<br>' +
                'The generation gap is the most important reason for behavioral changes among collectors.' +
                ' This difference between generations has become much more observable, thanks to the fact that digital channels' +
                ' have become more important in the world under the effect of the pandemic.<br>' +
                'A new audience of collectors who are inclined to invest in digital artworks, value artists and artworks that go beyond the conventional formats of art,' +
                ' and take higher risks in the commercial future of an artwork became more visible in 2021.' +
                '<div class="job-advertisement-heading">China Overtakes the USA</div>' +
                'In the auction economy, China\'s rise to leadership by replacing the United States was an important development.' +
                ' China holds 36% of this market. The USA has 29% and the UK 16%.' +
                '<div class="job-advertisement-heading">Online Sales on the Rise</div>' +
                'Although the overall picture of the art market was negative, there was a huge increase in online sales.' +
                ' Online sales channels, which have grown by 25% compared to 2020, have also enabled the emergence of new audiences who buy art.' +
                ' Digital concepts such as NFT, which had a very limited impact before, deeply affected this growth with the explosion they experienced in 2021.',
            ROUTER_LINK: {
                TEXT: 'View Artworks',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Who Invested in Artiox?',
            IMAGE_SRC: './assets/announcements/operations/corporate.svg#corporate',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Who Invested in Artiox?</div>' +
                'We talked about Artiox\'s story in our previous articles.' +
                ' Now we will talk about the investors who made Artiox what it is today.' +
                '<div class="job-advertisement-heading">Mehmet Toprak</div>' +
                'Mehmet Toprak, one of our pre-seed investors, has been working as the CEO of Topraklar Imalat Export Limited Company,' +
                ' which was established in Ankara in 1995 and has been one of Turkey\'s leading companies in the Industrial Construction Market business line since its establishment.' +
                ' Mehmet Toprak, who is the Chairman of the 26th Hardware Sales Committee in Ankara Chamber of Commerce,' +
                ' also with his investments and consultancy in digital business ideas that capture the spirit of the time;' +
                ' is also the chairman of the board of the Strategic and Innovative Investors Association, which brought these ideas to life.' +
                '<div class="job-advertisement-heading">Murat Aksöz</div>' +
                'Murat Aksöz, another of our investors in the pre-seed period; is the founder of 2U INVESTMENT.' +
                ' After graduating from TED Ankara College in 1993, Aksöz completed his higher education at Gazi University, Department of Mechanical Engineering.' +
                ' Aksöz is a production management, engineering, strategic planning and business development specialist.' +
                ' He is also one of the partners of Bronz Automotive, which provides manufacturing support to defense, automotive, HES-RES-GES projects.' +
                ' With his high interest in R&D and innovation, he takes place as an angel investor in the entrepreneurship ecosystem.' +
                '<div class="job-advertisement-heading">Alesta Yatırım</div>' +
                'Investing in Artiox in the first round in April 2021, Alesta Elektronik Teknoloji Yatırım A.Ş. an Escort Teknoloji Yatırım A.Ş. was established in 2012 as a subsidiary.' +
                ' Supporting technology companies with growth potential,' +
                ' Alesta Investment tries to make these companies successful operations by transferring its capital and knowledge to the companies it invests in.' +
                '<div class="job-advertisement-heading">Sertaç Özinal</div>' +
                'Sertaç Özinal, one of the important names in the banking and finance sector, is a member of the board of directors of TurkishBank.' +
                ' Sertaç Özinal, who also served as the chairman of the Board of Directors of the Interbank Card Center in the past;' +
                ' is also a partner of True Global Ventures, which invests in startups working in the fields of technology, data, artificial intelligence and blockchain.  ' +
                '<div class="job-advertisement-heading">Ideal Financial Technologies and Consulting JSC</div>' +
                'Ideal Financial Technologies, which invested in Artiox in the second round, has been working in the field of data distribution for more than 25 years.' +
                ' Ideal Financial Technologies, which offers domestic and foreign market data to its users, also provides software development services in the field of finance.' +
                ' In addition, Ideal Financial Technologies Anonim Şirketi, traded on Borsa Istanbul, is a subsidiary of Hedef Holding.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Who Invested in Artiox?</div>' +
                'We talked about Artiox\'s story in our previous articles.' +
                ' Now we will talk about the investors who made Artiox what it is today.' +
                '<div class="job-advertisement-heading">Mehmet Toprak</div>' +
                'Mehmet Toprak, one of our pre-seed investors, has been working as the CEO of Topraklar Imalat Export Limited Company,' +
                ' which was established in Ankara in 1995 and has been one of Turkey\'s leading companies in the Industrial Construction Market business line since its establishment.' +
                ' Mehmet Toprak, who is the Chairman of the 26th Hardware Sales Committee in Ankara Chamber of Commerce,' +
                ' also with his investments and consultancy in digital business ideas that capture the spirit of the time;' +
                ' is also the chairman of the board of the Strategic and Innovative Investors Association, which brought these ideas to life.' +
                '<div class="job-advertisement-heading">Murat Aksöz</div>' +
                'Murat Aksöz, another of our investors in the pre-seed period; is the founder of 2U INVESTMENT.' +
                ' After graduating from TED Ankara College in 1993, Aksöz completed his higher education at Gazi University, Department of Mechanical Engineering.' +
                ' Aksöz is a production management, engineering, strategic planning and business development specialist.' +
                ' He is also one of the partners of Bronz Automotive, which provides manufacturing support to defense, automotive, HES-RES-GES projects.' +
                ' With his high interest in R&D and innovation, he takes place as an angel investor in the entrepreneurship ecosystem.' +
                '<div class="job-advertisement-heading">Alesta Yatırım</div>' +
                'Investing in Artiox in the first round in April 2021, Alesta Elektronik Teknoloji Yatırım A.Ş. an Escort Teknoloji Yatırım A.Ş. was established in 2012 as a subsidiary.' +
                ' Supporting technology companies with growth potential,' +
                ' Alesta Investment tries to make these companies successful operations by transferring its capital and knowledge to the companies it invests in.' +
                '<div class="job-advertisement-heading">Sertaç Özinal</div>' +
                'Sertaç Özinal, one of the important names in the banking and finance sector, is a member of the board of directors of TurkishBank.' +
                ' Sertaç Özinal, who also served as the chairman of the Board of Directors of the Interbank Card Center in the past;' +
                ' is also a partner of True Global Ventures, which invests in startups working in the fields of technology, data, artificial intelligence and blockchain.  ' +
                '<div class="job-advertisement-heading">Ideal Financial Technologies and Consulting JSC</div>' +
                'Ideal Financial Technologies, which invested in Artiox in the second round, has been working in the field of data distribution for more than 25 years.' +
                ' Ideal Financial Technologies, which offers domestic and foreign market data to its users, also provides software development services in the field of finance.' +
                ' In addition, Ideal Financial Technologies Anonim Şirketi, traded on Borsa Istanbul, is a subsidiary of Hedef Holding.',
            ROUTER_LINK: {
                TEXT: 'View Artworks',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: '5 Trends That Will Shape The Art Market',
            IMAGE_SRC: './assets/announcements/operations/corporate.svg#corporate',
            TEXT_MOBILE: '<div class="job-advertisement-heading">5 Trends That Will Shape The Art Market\'s Near Future</div>' +
                'It is not an easy task to predict about the future. But in the last two years, it has become even more difficult to make future predictions due to COVID-19.' +
                ' However, we cannot move forward in waters full of obscurity in the early days.' +
                ' While we don\'t yet know when the pandemic will end, plan to live yet.' +
                ' We want to share with you the 5 trends that we think they will shape the art market\'s near future.' +
                '<div class="job-advertisement-heading">The Online Art Market Will Continue to Grow</div>' +
                'The daily volume of the pandemic online art market was changing between $4.5 and $6 billion.' +
                ' After a big picture date of the training to be held within the scope of the 2020 pandemic, this number reached to $12.4 billion in 2020.' +
                ' On the other hand, about 30% of online sales is commercial data from people who did not purchase art from their previous conventional channels.' +
                ' Thanks to this online situation, it is notoriously speculated that a new audience could become an "art buyer".<br>' +
                'In the upcoming period, we can say that the changing consumer purchasing habits due to the pandemic will become permanent in the art market, just like in other sectors.' +
                ' We can predict that online designs for the great convenience of artworks will increase the share of the content of abstract artworks.' +
                '<div class="job-advertisement-heading">Investments in Art-Oriented Technology Initiatives Will Increase</div>' +
                'It is possible to say that as a natural consequence of the increase in the share of online sales channels in total sales, investments in art-oriented start-ups will increase.' +
                ' Art-oriented start-ups, which managed to collect only 640 million dollars of investment during the 20 years before the pandemic,' +
                ' managed to receive 380 million dollars of investment in 2020 alone.<br>' +
                'Pandemic effects are not the only reason for this trend in investment decisions.' +
                ' The fact that millennials will dominate the art market in the coming years increases the importance of technology in the art market.' +
                ' It is estimated that by 2030, the millennials will inherit between 20 and 70 trillion dollars.' +
                ' It is known that the millennial generation uses online channels to purchase artworks much more than previous generations.' +
                ' That\'s why it\'s not a bad idea for most entrepreneurs to get involved early on the tech side of the art.' +
                '<div class="job-advertisement-heading">The Share of Technology in Art Production Will Increase</div>' +
                'Since the Internet has become mainstream, the presence of technology in the production of artworks has been growing.' +
                ' However, the fact that the art market was built on extremely conservative foundations caused artworks using technology to not be valued financially enough.' +
                ' The increasing use of technologies such as NFT in the art market is changing this situation.' +
                ' This deviation in the demand for artworks also causes the art production processes to be reviewed.' +
                ' It is possible to say that digital canvases will be used more in the coming period.' +
                '<div class="job-advertisement-heading">More Online Art Events Will Be Held</div>' +
                'In 2020, with the effect of the pandemic, many museums and art galleries started to offer online experiences rapidly.' +
                ' It seems that this need, born out of necessity at the beginning, will become one of the normals of the art world in time.' +
                ' Online experiences, which were originally designed as the digitization of physical experiences such as museum tours, are gradually finding their own character.' +
                ' It is possible to foresee that art event organizers, who have begun to better understand the structure and dynamics of online media,' +
                ' will use the opportunities offered by these media more in the future to design art events that are difficult to do in the physical world.' +
                '<div class="job-advertisement-heading">The Voices of Those Who Have Been in the Background Will Be Heard More</div>' +
                'One of the consequences of the millennial generation\'s gradual domination of the art market is the change in the quality of the artworks in demand.' +
                ' We have said before that artists who produce artworks in digital media or with the help of technology will see more value.' +
                ' A similar situation applies to African modern artists or street artists.<br>' +
                'The biggest reason for this change: The internet has made the distribution of artworks very easy.' +
                ' In this way, artists who do not have traditional roots in the field of art have the chance to reach large masses in a more democratic way.' +
                ' The increase in the number of artworks in circulation also causes a change in the nature of the demand for artworks.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">5 Trends That Will Shape The Art Market\'s Near Future</div>' +
                'It is not an easy task to predict about the future. But in the last two years, it has become even more difficult to make future predictions due to COVID-19.' +
                ' However, we cannot move forward in waters full of obscurity in the early days.' +
                ' While we don\'t yet know when the pandemic will end, plan to live yet.' +
                ' We want to share with you the 5 trends that we think they will shape the art market\'s near future.' +
                '<div class="job-advertisement-heading">The Online Art Market Will Continue to Grow</div>' +
                'The daily volume of the pandemic online art market was changing between $4.5 and $6 billion.' +
                ' After a big picture date of the training to be held within the scope of the 2020 pandemic, this number reached to $12.4 billion in 2020.' +
                ' On the other hand, about 30% of online sales is commercial data from people who did not purchase art from their previous conventional channels.' +
                ' Thanks to this online situation, it is notoriously speculated that a new audience could become an "art buyer".<br>' +
                'In the upcoming period, we can say that the changing consumer purchasing habits due to the pandemic will become permanent in the art market, just like in other sectors.' +
                ' We can predict that online designs for the great convenience of artworks will increase the share of the content of abstract artworks.' +
                '<div class="job-advertisement-heading">Investments in Art-Oriented Technology Initiatives Will Increase</div>' +
                'It is possible to say that as a natural consequence of the increase in the share of online sales channels in total sales, investments in art-oriented start-ups will increase.' +
                ' Art-oriented start-ups, which managed to collect only 640 million dollars of investment during the 20 years before the pandemic,' +
                ' managed to receive 380 million dollars of investment in 2020 alone.<br>' +
                'Pandemic effects are not the only reason for this trend in investment decisions.' +
                ' The fact that millennials will dominate the art market in the coming years increases the importance of technology in the art market.' +
                ' It is estimated that by 2030, the millennials will inherit between 20 and 70 trillion dollars.' +
                ' It is known that the millennial generation uses online channels to purchase artworks much more than previous generations.' +
                ' That\'s why it\'s not a bad idea for most entrepreneurs to get involved early on the tech side of the art.' +
                '<div class="job-advertisement-heading">The Share of Technology in Art Production Will Increase</div>' +
                'Since the Internet has become mainstream, the presence of technology in the production of artworks has been growing.' +
                ' However, the fact that the art market was built on extremely conservative foundations caused artworks using technology to not be valued financially enough.' +
                ' The increasing use of technologies such as NFT in the art market is changing this situation.' +
                ' This deviation in the demand for artworks also causes the art production processes to be reviewed.' +
                ' It is possible to say that digital canvases will be used more in the coming period.' +
                '<div class="job-advertisement-heading">More Online Art Events Will Be Held</div>' +
                'In 2020, with the effect of the pandemic, many museums and art galleries started to offer online experiences rapidly.' +
                ' It seems that this need, born out of necessity at the beginning, will become one of the normals of the art world in time.' +
                ' Online experiences, which were originally designed as the digitization of physical experiences such as museum tours, are gradually finding their own character.' +
                ' It is possible to foresee that art event organizers, who have begun to better understand the structure and dynamics of online media,' +
                ' will use the opportunities offered by these media more in the future to design art events that are difficult to do in the physical world.' +
                '<div class="job-advertisement-heading">The Voices of Those Who Have Been in the Background Will Be Heard More</div>' +
                'One of the consequences of the millennial generation\'s gradual domination of the art market is the change in the quality of the artworks in demand.' +
                ' We have said before that artists who produce artworks in digital media or with the help of technology will see more value.' +
                ' A similar situation applies to African modern artists or street artists.<br>' +
                'The biggest reason for this change: The internet has made the distribution of artworks very easy.' +
                ' In this way, artists who do not have traditional roots in the field of art have the chance to reach large masses in a more democratic way.' +
                ' The increase in the number of artworks in circulation also causes a change in the nature of the demand for artworks.',
            ROUTER_LINK: {
                TEXT: 'View Artworks',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Technology, Art World and Democracy',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Does Technology Make the Art World More Democratic?</div>' +
                'Art exists for all of us. However, it is also true that a large part of the art world is in the hands of an economically powerful elite.' +
                ' Access to art by large masses means that the ownership of artworks is freed from the control of a certain group and art is democratized.' +
                ' We have seen that it is possible to democratize art thanks to technology, especially thanks to the developments experienced during the pandemic period.' +
                '<div class="job-advertisement-heading">The Art Market Had To Come Out Of Its Shell</div>' +
                'The art market has a very conservative structure.' +
                ' Therefore, while almost every institution of social life redefines itself after the digital revolution and the art world continued to exist with its old traditions for a long time, ignoring this revolution.' +
                ' Paintings on canvases were exhibited in walled galleries and were sold with the hammer that the auctioneer\'s bidder slammed on the table.<br>' +
                'But the global COVID-19 pandemic has forced the art world to come out of its shell.' +
                ' The art market, which had come to a standstill due to the closures, started to try different methods to regain some momentum.' +
                ' While developing these methods, it made great use of digital technology, which was ignored for many years.<br>' +
                'Online showrooms and auctions are not new practices.' +
                ' These applications were also used before the pandemic.' +
                ' However, the closures where physical activities could not be organized made these practices a necessity rather than an alternative.<br>' +
                'It is said that some of these practices in the art market, as in almost every sector, may be permanent after the pandemic.' +
                ' The conveniences and new possibilities provided by technology cause habits to change.<br>' +
                'However, partial digitization of long-standing institutions and ways of doing business in the art world is not enough for art to truly democratize.' +
                ' This digitalization enables artworks to reach wider audiences. However, this group still consists of wealthy people.' +
                '<div class="job-advertisement-heading">Is Digital Art Art?</div>' +
                'Artists who produce artworks in digital environments have been around for a long time. For a long time, these artists have been ostracized by traditional art circles.' +
                ' Some of these circles have negative reflexes, such as not seeing digital artworks as "art".<br>' +
                'The biggest arguments of those who think that digital art is "art" is that every tool used to create an artwork is actually a technology.' +
                ' This technology changes everything over time, from the production of the artwork to its distribution.<br>' +
                'This is a deep discussion.' +
                ' When we look at this discussion from the investment side of art, there is only one truth.' +
                ' There are people who produce digital artworks, and there is an audience that aspires to what these people produce.' +
                ' This mass is not in auction rooms. At home, they stare at their screens in living rooms.' +
                '<div class="job-advertisement-heading">Not Just Art, The Art Market Is Going Digitized</div>' +
                'The concept of NFT, which emerged thanks to the blockchain technology,' +
                ' has made it very easy for digital artworks to be purchased by large masses regardless of economic class.' +
                ' On the other hand, technological initiatives in the field of art, in which we, as Artiox,' +
                ' are involved, make the ownership of artworks more accessible to individuals with average income.' +
                ' Thanks to the changing nature of technology and art consumers, a strong alternative to the traditional art market is emerging.<br>' +
                'This development deeply affects not only the ownership of artworks, but also the production processes of artworks.' +
                ' Artists whose artworks are not accepted in traditional markets and therefore cannot find financial comfort now have a better chance.' +
                ' Although the brand value of the artist is still very decisive in determining the value of a artwork,' +
                ' the need for other institutions and individuals to create this brand value is much less.' +
                ' This situation offers artists a great freedom.<br>' +
                'We are certainly at the beginning of a revolution.' +
                ' The coming years will be times when the concept of art and the art market are redefined.' +
                ' We recommend that you take your place early in this revolutionary movement.' +
                ' At Artiox, you can get your share of the future by investing in artworks with small budgets.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Does Technology Make the Art World More Democratic?</div>' +
                'Art exists for all of us. However, it is also true that a large part of the art world is in the hands of an economically powerful elite.' +
                ' Access to art by large masses means that the ownership of artworks is freed from the control of a certain group and art is democratized.' +
                ' We have seen that it is possible to democratize art thanks to technology, especially thanks to the developments experienced during the pandemic period.' +
                '<div class="job-advertisement-heading">The Art Market Had To Come Out Of Its Shell</div>' +
                'The art market has a very conservative structure.' +
                ' Therefore, while almost every institution of social life redefines itself after the digital revolution and the art world continued to exist with its old traditions for a long time, ignoring this revolution.' +
                ' Paintings on canvases were exhibited in walled galleries and were sold with the hammer that the auctioneer\'s bidder slammed on the table.<br>' +
                'But the global COVID-19 pandemic has forced the art world to come out of its shell.' +
                ' The art market, which had come to a standstill due to the closures, started to try different methods to regain some momentum.' +
                ' While developing these methods, it made great use of digital technology, which was ignored for many years.<br>' +
                'Online showrooms and auctions are not new practices.' +
                ' These applications were also used before the pandemic.' +
                ' However, the closures where physical activities could not be organized made these practices a necessity rather than an alternative.<br>' +
                'It is said that some of these practices in the art market, as in almost every sector, may be permanent after the pandemic.' +
                ' The conveniences and new possibilities provided by technology cause habits to change.<br>' +
                'However, partial digitization of long-standing institutions and ways of doing business in the art world is not enough for art to truly democratize.' +
                ' This digitalization enables artworks to reach wider audiences. However, this group still consists of wealthy people.' +
                '<div class="job-advertisement-heading">Is Digital Art Art?</div>' +
                'Artists who produce artworks in digital environments have been around for a long time. For a long time, these artists have been ostracized by traditional art circles.' +
                ' Some of these circles have negative reflexes, such as not seeing digital artworks as "art".<br>' +
                'The biggest arguments of those who think that digital art is "art" is that every tool used to create an artwork is actually a technology.' +
                ' This technology changes everything over time, from the production of the artwork to its distribution.<br>' +
                'This is a deep discussion.' +
                ' When we look at this discussion from the investment side of art, there is only one truth.' +
                ' There are people who produce digital artworks, and there is an audience that aspires to what these people produce.' +
                ' This mass is not in auction rooms. At home, they stare at their screens in living rooms.' +
                '<div class="job-advertisement-heading">Not Just Art, The Art Market Is Going Digitized</div>' +
                'The concept of NFT, which emerged thanks to the blockchain technology,' +
                ' has made it very easy for digital artworks to be purchased by large masses regardless of economic class.' +
                ' On the other hand, technological initiatives in the field of art, in which we, as Artiox,' +
                ' are involved, make the ownership of artworks more accessible to individuals with average income.' +
                ' Thanks to the changing nature of technology and art consumers, a strong alternative to the traditional art market is emerging.<br>' +
                'This development deeply affects not only the ownership of artworks, but also the production processes of artworks.' +
                ' Artists whose artworks are not accepted in traditional markets and therefore cannot find financial comfort now have a better chance.' +
                ' Although the brand value of the artist is still very decisive in determining the value of a artwork,' +
                ' the need for other institutions and individuals to create this brand value is much less.' +
                ' This situation offers artists a great freedom.<br>' +
                'We are certainly at the beginning of a revolution.' +
                ' The coming years will be times when the concept of art and the art market are redefined.' +
                ' We recommend that you take your place early in this revolutionary movement.' +
                ' At Artiox, you can get your share of the future by investing in artworks with small budgets.',
            ROUTER_LINK: {
                TEXT: 'View Artworks',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'How Do We Choose Artworks to List?',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">How Do We Select Artworks to List on Artiox?</div>' +
                'As Artiox, we enable large audiences to easily invest in art, even with low budgets, thanks to technology.' +
                ' However, art is a multi-layered investment area.' +
                ' That\'s why we need to offer you, our users, solutions that consider different dimensions of art investment.' +
                ' In this way, it becomes possible to enter the field of art investing with less money and time capital.<br>' +
                'The most important condition for investing in any field is that the investment promises a future return.' +
                ' The same rule applies to art investments.' +
                ' For an investment in an artwork to be financially meaningful, it must have commercial potential.<br>' +
                'However, this potential is hard to predict for a non-art market expert.' +
                ' The artist who produced the artwork is very effective in determining the commercial potential of that.' +
                ' However, even two different artworks of the same artist can have different commercial lives.<br>' +
                'At this point, one of the most important conveniences we provide to our users as Artiox is the selection of artworks with a financial future.' +
                ' In this way, our users can quickly enter the field of art investing without wasting time to gain expertise in the field of art market.<br>' +
                'Artiox\'s journey of incorporating an artwork begins with collectors reaching Artiox or Artiox reaching out to collectors by identifying some artworks.' +
                ' After the artworks to be invested are determined, the originality and physical condition is reported by an expert in the field.' +
                ' Following the expert\'s approval of the determined artwork; it comes down to determining the price.' +
                ' The artwork is insured in line with the price determined under the guidance of an expert and is kept in a specially designed warehouse for the storage.' +
                ' After the process of incorporation of the artwork into Artiox is completed, the blockchain side comes into play.' +
                ' At this stage, as Artiox, we create a smart contract specific to the artifact and tokenize the artifact over the amount we set.' +
                ' In the last stage, we sell the tokens of the artwork, depending on the price and the number of tokens.<br>' +
                'As Artiox, one of our most important tasks is not just to facilitate art investment with low budgets.' +
                ' At the same time, to keep the risk to our users to a minimum by choosing the right artworks of art and keeping them in environments free from physical dangers.' +
                ' In this way, as Artiox, we offer a platform where even our users who have no experience in art investing can invest with confidence.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">How Do We Select Artworks to List on Artiox?</div>' +
                'As Artiox, we enable large audiences to easily invest in art, even with low budgets, thanks to technology.' +
                ' However, art is a multi-layered investment area.' +
                ' That\'s why we need to offer you, our users, solutions that consider different dimensions of art investment.' +
                ' In this way, it becomes possible to enter the field of art investing with less money and time capital.<br>' +
                'The most important condition for investing in any field is that the investment promises a future return.' +
                ' The same rule applies to art investments.' +
                ' For an investment in an artwork to be financially meaningful, it must have commercial potential.<br>' +
                'However, this potential is hard to predict for a non-art market expert.' +
                ' The artist who produced the artwork is very effective in determining the commercial potential of that.' +
                ' However, even two different artworks of the same artist can have different commercial lives.<br>' +
                'At this point, one of the most important conveniences we provide to our users as Artiox is the selection of artworks with a financial future.' +
                ' In this way, our users can quickly enter the field of art investing without wasting time to gain expertise in the field of art market.<br>' +
                'Artiox\'s journey of incorporating an artwork begins with collectors reaching Artiox or Artiox reaching out to collectors by identifying some artworks.' +
                ' After the artworks to be invested are determined, the originality and physical condition is reported by an expert in the field.' +
                ' Following the expert\'s approval of the determined artwork; it comes down to determining the price.' +
                ' The artwork is insured in line with the price determined under the guidance of an expert and is kept in a specially designed warehouse for the storage.' +
                ' After the process of incorporation of the artwork into Artiox is completed, the blockchain side comes into play.' +
                ' At this stage, as Artiox, we create a smart contract specific to the artifact and tokenize the artifact over the amount we set.' +
                ' In the last stage, we sell the tokens of the artwork, depending on the price and the number of tokens.<br>' +
                'As Artiox, one of our most important tasks is not just to facilitate art investment with low budgets.' +
                ' At the same time, to keep the risk to our users to a minimum by choosing the right artworks of art and keeping them in environments free from physical dangers.' +
                ' In this way, as Artiox, we offer a platform where even our users who have no experience in art investing can invest with confidence.',
            ROUTER_LINK: {
                TEXT: 'View Artworks',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'The Value of Frida Kahlo Self-Portrait',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Self-Portrait of Frida Kahlo Sold at Auction for $34.9M</div>' +
                'In September 1925, a bus in Mexico City crashed into a doctoral candidate who was going to school with her boyfriend.' +
                ' It was not yet known that this unfortunate accident would lead to the birth of one of the most important artists of the 20th century.<br>' +
                'The victim was the young woman, Frida Kahlo.' +
                ' Eighteen-year-old Kahlo, who started painting while she was tied to the bed,' +
                ' became one of the few female artists who achieved the position she deserved in art history.' +
                ' She could not have predicted that, nearly a century later, she would neither become a feminist icon nor would her self-portrait sell for a record price.<br>' +
                '<div class="job-advertisement-heading">Diego y Yo</div>' +
                'The title of the self-portrait in question is Diego y Yo (Diego and I).' +
                ' This painting, dated 1949, is Kahlo\'s last \'bust\' self-portrait.' +
                ' In the painting we see Kahlo in tears with a small image of her husband Diego on her forehead.<br>' +
                'When Kahlo painted this painting five years before her death, Diego was in a relationship with an actress who was also a friend of Kahlo\'s.' +
                ' While the Diego miniature on her forehead depicts Kahlo\'s passionate love for her husband,' +
                ' the use of Diego as the third eye points to Kahlo\'s admiration for Diego\'s wit and wisdom.' +
                ' Kahlo, who joked about Diego\'s infidelities for years, expresses her real pain with tears running down her cheeks in this picture.<br>' +
                'In many of her self-portraits, Kahlo portrays herself with her hair tightly pulled back.' +
                ' In this picture, her messy hair entwined around her neck; it brings to mind that the artist was suffocated without Diego.<br>' +
                '<div class="job-advertisement-heading">Record-Breaking Sale</div>' +
                'Diego y Yo sold for $1.4 million in 1990 and Kahlo became the first Latin American artist whose work was sold at auction for more than $1 million.' +
                ' The same work was put up for auction at Sotheby\'s New York on November 15, 2021.' +
                ' At the end of the auction, which lasted less than two minutes, the painting was sold for $34.9 million.' +
                ' The painting\'s new owner, Eduardo F. Costantini, is the founder of Malba, a museum of Latin American art in Buenos Aires.' +
                ' Costantini stated that the work will be exhibited in Malba in 2022.<br>' +
                'The painting broke a record at $34.9 million, making it the most expensive work by a Latin American artist ever sold at auction.' +
                ' The previous record was held by Rivera\'s painting The Rivals, which sold for $9.8 million in 2018.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Self-Portrait of Frida Kahlo Sold at Auction for $34.9M</div>' +
                'In September 1925, a bus in Mexico City crashed into a doctoral candidate who was going to school with her boyfriend.' +
                ' It was not yet known that this unfortunate accident would lead to the birth of one of the most important artists of the 20th century.<br>' +
                'The victim was the young woman, Frida Kahlo.' +
                ' Eighteen-year-old Kahlo, who started painting while she was tied to the bed,' +
                ' became one of the few female artists who achieved the position she deserved in art history.' +
                ' She could not have predicted that, nearly a century later, she would neither become a feminist icon nor would her self-portrait sell for a record price.<br>' +
                '<div class="job-advertisement-heading">Diego y Yo</div>' +
                'The title of the self-portrait in question is Diego y Yo (Diego and I).' +
                ' This painting, dated 1949, is Kahlo\'s last \'bust\' self-portrait.' +
                ' In the painting we see Kahlo in tears with a small image of her husband Diego on her forehead.<br>' +
                'When Kahlo painted this painting five years before her death, Diego was in a relationship with an actress who was also a friend of Kahlo\'s.' +
                ' While the Diego miniature on her forehead depicts Kahlo\'s passionate love for her husband,' +
                ' the use of Diego as the third eye points to Kahlo\'s admiration for Diego\'s wit and wisdom.' +
                ' Kahlo, who joked about Diego\'s infidelities for years, expresses her real pain with tears running down her cheeks in this picture.<br>' +
                'In many of her self-portraits, Kahlo portrays herself with her hair tightly pulled back.' +
                ' In this picture, her messy hair entwined around her neck; it brings to mind that the artist was suffocated without Diego.<br>' +
                '<div class="job-advertisement-heading">Record-Breaking Sale</div>' +
                'Diego y Yo sold for $1.4 million in 1990 and Kahlo became the first Latin American artist whose work was sold at auction for more than $1 million.' +
                ' The same work was put up for auction at Sotheby\'s New York on November 15, 2021.' +
                ' At the end of the auction, which lasted less than two minutes, the painting was sold for $34.9 million.' +
                ' The painting\'s new owner, Eduardo F. Costantini, is the founder of Malba, a museum of Latin American art in Buenos Aires.' +
                ' Costantini stated that the work will be exhibited in Malba in 2022.<br>' +
                'The painting broke a record at $34.9 million, making it the most expensive work by a Latin American artist ever sold at auction.' +
                ' The previous record was held by Rivera\'s painting The Rivals, which sold for $9.8 million in 2018.',
            ROUTER_LINK: {
                TEXT: 'View Artworks',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'The 8 Best Books for Art Lovers',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">The 8 Best Books to Buy as Christmas Gifts for Your Art-loving Friends</div>' +
                'The new year is approaching. You want to buy gifts for your loved ones, but you do not know what to buy.' +
                ' If the people you are considering to buy a gifts are art lovers, you don\'t have to worry.' +
                ' A book about art will make them happy.<br>' +
                'But there are many art books in bookstores. Which one should you get?' +
                '<div class="job-advertisement-heading">The Courage to Create - Rollo May</div>' +
                'In order to understand art and the artist, first of all, it is necessary to understand the concept of "creativity".' +
                ' But creativity is a rather misunderstood concept.' +
                ' Rollo May, one of the most important psychologists of the 20th century, thinks that creativity is a courageous journey.' +
                ' This journey is full of suffering, obstacles, anxiety and disappointments, and it takes great courage to overcome these difficulties.' +
                '<div class="job-advertisement-heading">The Short Story of Art - Susie Hodge</div>' +
                'Susie Hodge\'s "The Short Story of Art" is a great beginner\'s guide for people who are just beginning to take an interest in art.' +
                ' This book, which describes art movements and some artworks, is one of the ideal works for introduction to the world of art.' +
                '<div class="job-advertisement-heading">Studio Ghibli - Michelle Le Blanc</div>' +
                'Hayao Miyazaki, whose name is identified with Studio Ghibli, is a very popular animation artist.' +
                ' But most of us don\'t know about both Studio Ghibli and Hayao Miyazaki beyond DVD extras.' +
                ' Written with an academic approach, this book allows us to take a closer look at the films of Hayao Miyazaki and Isao Takahata.' +
                '<div class="job-advertisement-heading">How to Be an Artist - Jerry Saltz</div>' +
                'Jerry Saltz, a senior art critic at New York Magazine, has written an easy-to-understand book not only for those who want to be artists,' +
                ' but also for those who want to understand how an artist came to be.' +
                ' If you think that your loved one has artistic talents and needs to do something now, this book can be a good gift.' +
                '<div class="job-advertisement-heading">Sanat ve Arzu - Ulus Baker</div>' +
                'In this book, which is the book version of Ulus Baker\'s seminars given at METU Audio-Visual Systems Research and Production Center in 1998,' +
                ' Ulus Baker questions the relationship between the philosophies of Spinoza, Descartes, Leibniz, Kant and art.' +
                '<div class="job-advertisement-heading">A History of Art in 21 Cats - Nia Gould</div>' +
                'If your recipient loves both art and cats, you can\'t get a better gift than Nia Gould\'s "A History of Art in 21 Cats".' +
                ' Nia Gould describes 21 art movements with 21 cats in this entertaining book.' +
                '<div class="job-advertisement-heading">Osmanlı Tasvir Sanatları 1: Minyatür - Metin And</div>' +
                'Metin And is known for his researches on Turkish-Islamic depiction and decoration arts.' +
                ' We sadly lost him in 2008.' +
                ' Yapı Kredi Publications has been republishing Metin And\'s work since 2002 by making it a series.' +
                ' The miniature book in the series is a golden resource, especially for those interested in the depiction arts of the Ottoman period.' +
                '<div class="job-advertisement-heading">Sinan Çağı - Gülru Necipoğlu</div>' +
                'Gülru Necipoğlu, who has been the head of the Aga Khan Program for Islamic Architecture at Harvard University since 1993,' +
                ' sheds light on us in this book to better interpret the works of Mimar Sinan and to better understand her architectural genius.' +
                ' If your friends, whom you are considering to buy gifts, is influenced by the combination of analytical thinking and creativity, this book may be a treasure for them.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">The 8 Best Books to Buy as Christmas Gifts for Your Art-loving Friends</div>' +
                'The new year is approaching. You want to buy gifts for your loved ones, but you do not know what to buy.' +
                ' If the people you are considering to buy a gifts are art lovers, you don\'t have to worry.' +
                ' A book about art will make them happy.<br>' +
                'But there are many art books in bookstores. Which one should you get?' +
                '<div class="job-advertisement-heading">The Courage to Create - Rollo May</div>' +
                'In order to understand art and the artist, first of all, it is necessary to understand the concept of "creativity".' +
                ' But creativity is a rather misunderstood concept.' +
                ' Rollo May, one of the most important psychologists of the 20th century, thinks that creativity is a courageous journey.' +
                ' This journey is full of suffering, obstacles, anxiety and disappointments, and it takes great courage to overcome these difficulties.' +
                '<div class="job-advertisement-heading">The Short Story of Art - Susie Hodge</div>' +
                'Susie Hodge\'s "The Short Story of Art" is a great beginner\'s guide for people who are just beginning to take an interest in art.' +
                ' This book, which describes art movements and some artworks, is one of the ideal works for introduction to the world of art.' +
                '<div class="job-advertisement-heading">Studio Ghibli - Michelle Le Blanc</div>' +
                'Hayao Miyazaki, whose name is identified with Studio Ghibli, is a very popular animation artist.' +
                ' But most of us don\'t know about both Studio Ghibli and Hayao Miyazaki beyond DVD extras.' +
                ' Written with an academic approach, this book allows us to take a closer look at the films of Hayao Miyazaki and Isao Takahata.' +
                '<div class="job-advertisement-heading">How to Be an Artist - Jerry Saltz</div>' +
                'Jerry Saltz, a senior art critic at New York Magazine, has written an easy-to-understand book not only for those who want to be artists,' +
                ' but also for those who want to understand how an artist came to be.' +
                ' If you think that your loved one has artistic talents and needs to do something now, this book can be a good gift.' +
                '<div class="job-advertisement-heading">Sanat ve Arzu - Ulus Baker</div>' +
                'In this book, which is the book version of Ulus Baker\'s seminars given at METU Audio-Visual Systems Research and Production Center in 1998,' +
                ' Ulus Baker questions the relationship between the philosophies of Spinoza, Descartes, Leibniz, Kant and art.' +
                '<div class="job-advertisement-heading">A History of Art in 21 Cats - Nia Gould</div>' +
                'If your recipient loves both art and cats, you can\'t get a better gift than Nia Gould\'s "A History of Art in 21 Cats".' +
                ' Nia Gould describes 21 art movements with 21 cats in this entertaining book.' +
                '<div class="job-advertisement-heading">Osmanlı Tasvir Sanatları 1: Minyatür - Metin And</div>' +
                'Metin And is known for his researches on Turkish-Islamic depiction and decoration arts.' +
                ' We sadly lost him in 2008.' +
                ' Yapı Kredi Publications has been republishing Metin And\'s work since 2002 by making it a series.' +
                ' The miniature book in the series is a golden resource, especially for those interested in the depiction arts of the Ottoman period.' +
                '<div class="job-advertisement-heading">Sinan Çağı - Gülru Necipoğlu</div>' +
                'Gülru Necipoğlu, who has been the head of the Aga Khan Program for Islamic Architecture at Harvard University since 1993,' +
                ' sheds light on us in this book to better interpret the works of Mimar Sinan and to better understand her architectural genius.' +
                ' If your friends, whom you are considering to buy gifts, is influenced by the combination of analytical thinking and creativity, this book may be a treasure for them.',
            ROUTER_LINK: {
                TEXT: 'View Artworks',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'The Value of Pablo Picasso Artworks',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">\'Some painters transform the sun into a yellow spot. Others transform a yellow spot into the sun.\'</div>' +
                'said Pablo Picasso.' +
                ' According to many art historians, Picasso is the most important artist of the 20th century.' +
                ' Years after completing his first painting at the age of 7, he pioneered cubism with Georges Braque.' +
                ' In addition to painting, he also revolutionized music, literature and architecture.' +
                ' Many art historians claim that art changed completely after Picasso and see Picasso\'s art as a milestone.' +
                '<div class="job-advertisement-heading">Its Value Is Realized While Living</div>' +
                'Unlike many of his contemporaries, Picasso is one of the artists whose value is known while he is living.' +
                ' So much so that when Paul Klee called him "the painter of today", Dali wanted to see Picasso before the Louvre when he first went to Paris.' +
                ' Picasso also holds the title of being the first artist whose artwork was exhibited in the Louvre while he was alive.<br>' +
                'It is estimated that Picasso produced more than 50,000 artworks during his career, of which about 29,000 have been cataloged.' +
                ' The prolific artist who revolutionized art history was both world-famous and immensely wealthy when he died in 1973.' +
                ' Picasso did not leave a will. His heirs were in dispute, and his fortune was set by the court at $100 to $250 million.' +
                ' This figure currently corresponds to 530 million to 1.3 billion dollars.' +
                '<div class="job-advertisement-heading">To What Does He Owe His Financial Success?</div>' +
                'A visitor to his workshop asked Picasso after taking a long look at one of the paintings, “What does this represent?” he asked.' +
                ' Picasso replied: "Two hundred thousand dollars."<br>' +
                'Many art critics argue that Picasso owes his success to his financial genius as well as his artistic talent.' +
                ' Realizing the power of media and public relations, Picasso branded himself long before today\'s pop stars.' +
                ' There are many critics who comment that his private life, full of violence and turmoil,' +
                ' also played an important role in his reputation and increased the value of his artworks.' +
                '<div class="job-advertisement-heading">Record-Breaking Picasso Sales</div>' +
                'Picasso refused to produce bespoke artworks, painting only what he wanted.' +
                ' He was aware that buying his artworks from major collectors in Paris would increase the value of them.' +
                ' In 1932, she broke the record by selling La Coiffure (The Hairdresser\'s Girl) for 56,000 francs. This figure corresponds to approximately 172 million dollars today.<br>' +
                'Le Rêve (The Dream) from 1932 was the fourth most expensive painting when it sold for $48.4 million in 1997.' +
                ' The same painting was sold in 2013 for $155 million—$172 million today—almost double the expected price, after the six participants struggled for 19 minutes.' +
                ' Fillette à la corbeille fleurie (The Girl with a Flower Basket) sold for $115 million in 2018—$118 million today.<br>' +
                'We can extend this list as long as we want, but now let\'s talk about the most expensive painting ever sold at an auction.' +
                ' The last painting in Picasso\'s 15-painting Les Femmes d\'Alger (Women of Alger) series, Version O,' +
                ' was expected to break records when it was auctioned for the second time at Christie\'s New York in May 2015.' +
                ' The artwork, which was valued at 140 million dollars before the sale;' +
                ' was sold to former Qatari Prime Minister Hamad bin Khalifa Al Thani for $179.4 million, making it the most expensive painting ever sold at auction.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">\'Some painters transform the sun into a yellow spot. Others transform a yellow spot into the sun.\'</div>' +
                'said Pablo Picasso.' +
                ' According to many art historians, Picasso is the most important artist of the 20th century.' +
                ' Years after completing his first painting at the age of 7, he pioneered cubism with Georges Braque.' +
                ' In addition to painting, he also revolutionized music, literature and architecture.' +
                ' Many art historians claim that art changed completely after Picasso and see Picasso\'s art as a milestone.' +
                '<div class="job-advertisement-heading">Its Value Is Realized While Living</div>' +
                'Unlike many of his contemporaries, Picasso is one of the artists whose value is known while he is living.' +
                ' So much so that when Paul Klee called him "the painter of today", Dali wanted to see Picasso before the Louvre when he first went to Paris.' +
                ' Picasso also holds the title of being the first artist whose artwork was exhibited in the Louvre while he was alive.<br>' +
                'It is estimated that Picasso produced more than 50,000 artworks during his career, of which about 29,000 have been cataloged.' +
                ' The prolific artist who revolutionized art history was both world-famous and immensely wealthy when he died in 1973.' +
                ' Picasso did not leave a will. His heirs were in dispute, and his fortune was set by the court at $100 to $250 million.' +
                ' This figure currently corresponds to 530 million to 1.3 billion dollars.' +
                '<div class="job-advertisement-heading">To What Does He Owe His Financial Success?</div>' +
                'A visitor to his workshop asked Picasso after taking a long look at one of the paintings, “What does this represent?” he asked.' +
                ' Picasso replied: "Two hundred thousand dollars."<br>' +
                'Many art critics argue that Picasso owes his success to his financial genius as well as his artistic talent.' +
                ' Realizing the power of media and public relations, Picasso branded himself long before today\'s pop stars.' +
                ' There are many critics who comment that his private life, full of violence and turmoil,' +
                ' also played an important role in his reputation and increased the value of his artworks.' +
                '<div class="job-advertisement-heading">Record-Breaking Picasso Sales</div>' +
                'Picasso refused to produce bespoke artworks, painting only what he wanted.' +
                ' He was aware that buying his artworks from major collectors in Paris would increase the value of them.' +
                ' In 1932, she broke the record by selling La Coiffure (The Hairdresser\'s Girl) for 56,000 francs. This figure corresponds to approximately 172 million dollars today.<br>' +
                'Le Rêve (The Dream) from 1932 was the fourth most expensive painting when it sold for $48.4 million in 1997.' +
                ' The same painting was sold in 2013 for $155 million—$172 million today—almost double the expected price, after the six participants struggled for 19 minutes.' +
                ' Fillette à la corbeille fleurie (The Girl with a Flower Basket) sold for $115 million in 2018—$118 million today.<br>' +
                'We can extend this list as long as we want, but now let\'s talk about the most expensive painting ever sold at an auction.' +
                ' The last painting in Picasso\'s 15-painting Les Femmes d\'Alger (Women of Alger) series, Version O,' +
                ' was expected to break records when it was auctioned for the second time at Christie\'s New York in May 2015.' +
                ' The artwork, which was valued at 140 million dollars before the sale;' +
                ' was sold to former Qatari Prime Minister Hamad bin Khalifa Al Thani for $179.4 million, making it the most expensive painting ever sold at auction.',
            ROUTER_LINK: {
                TEXT: 'View Artworks',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Demand for Artworks',
            IMAGE_SRC: './assets/announcements/operations/corporate.svg#corporate',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Why Has Demand for Artworks Increased in Recent Years?</div>' +
                'The news about the astronomical figures paid for artworks interests us all.' +
                ' Competition against each other in auctions organized by world-famous auction houses,' +
                ' wealthy collectors invest billions of dollars in artworks every year.<br>' +
                'Art is an investment.' +
                ' Therefore, the nature of art investment is as much to lose as to win.' +
                ' For example, the news of the sale of Monet\'s painting "Dans la Prairie", which found a buyer for 10 million pounds at an auction in February 2009,' +
                ' occupied the agenda of the global press in those days.' +
                ' It was emphasized that the fact that the painting was sold for such a figure proved the commercial potential of the impressionism and modernism movements.' +
                ' However, the same painting was sold for £14.3 million in 1988 and for $15.4 million in 1999.<br>' +
                'Don\'t let this story create a negative image in your mind about art investing.' +
                ' This story proves that even just the top-tier artworks can fluctuate in value over time.' +
                ' Just like gold prices.<br>' +
                'Despite the 22% decline caused by the COVID-19 outbreak in 2020, the value of the art market was $50.1 billion.' +
                ' During this time, more than 30 million transactions were made.' +
                ' These numbers are not very high at a time when many industries are suffering huge losses.<br>' +
                'Research on the period between 1957 and 2007 shows that an artwork gains value by an average of 3.97% each year.' +
                ' This rate has been increasing rapidly in recent years.' +
                '<div class="job-advertisement-heading">The Number of Capital Owners Increases</div>' +
                'The main reason for the increase in the prices of artworks is the rapid increase in demand while supply remains constant.' +
                ' The biggest reason for the increase in demand is the increase in the number of wealthy people globally.' +
                ' In the past year alone, there has been a 7% increase in the number of people with a net worth of more than $1 billion.' +
                ' The fact that this audience turns to the field of art both to improve their image and to make a safe investment causes prices in the market to increase.' +
                '<div class="job-advertisement-heading">Returns on Conventional Investment Instruments Are Not Appetizing</div>' +
                'In the last few years, the return promises of conventional investment instruments in developed countries have been stagnant,' +
                ' causing capital owners to turn to alternative investment channels.' +
                ' The fact that art investments are less risky than many alternative investment channels' +
                ' makes these investments attractive to investors seeking to provide above-average returns.' +
                '<div class="job-advertisement-heading">New Markets</div>' +
                'The developed economies of Western countries are traditional markets for the art market.' +
                ' However, with the increase in the economic power of countries such as China, Russia and India in recent years,' +
                ' there has been an explosion in the demand for art investments from these countries.' +
                ' The demand created by these countries, which had little say in the art markets in the 20th century, increases the value of artworks.' +
                '<div class="job-advertisement-heading">Wider Service</div>' +
                'The reason why more people are entering the field of art investment is the increase in the variety of services provided in this field.' +
                ' The increase in individuals and institutions providing services in fields such as collection consultancy or art investment expertise' +
                ' not only makes it easier for new names to become art investors, but also increases the demand for artworks with high commercial potential.' +
                '<div class="job-advertisement-heading">Digitalization</div>' +
                'Alternative channels have emerged for the distribution of artworks, as the art market has started to digitize, albeit late, due to its conservative nature.' +
                ' Digitalization, which includes different applications from auctions using virtual reality to digital artworks,' +
                ' makes it easier for the buyer to access the artwork and for the artifacts to reach their true value.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Why Has Demand for Artworks Increased in Recent Years?</div>' +
                'The news about the astronomical figures paid for artworks interests us all.' +
                ' Competition against each other in auctions organized by world-famous auction houses,' +
                ' wealthy collectors invest billions of dollars in artworks every year.<br>' +
                'Art is an investment.' +
                ' Therefore, the nature of art investment is as much to lose as to win.' +
                ' For example, the news of the sale of Monet\'s painting "Dans la Prairie", which found a buyer for 10 million pounds at an auction in February 2009,' +
                ' occupied the agenda of the global press in those days.' +
                ' It was emphasized that the fact that the painting was sold for such a figure proved the commercial potential of the impressionism and modernism movements.' +
                ' However, the same painting was sold for £14.3 million in 1988 and for $15.4 million in 1999.<br>' +
                'Don\'t let this story create a negative image in your mind about art investing.' +
                ' This story proves that even just the top-tier artworks can fluctuate in value over time.' +
                ' Just like gold prices.<br>' +
                'Despite the 22% decline caused by the COVID-19 outbreak in 2020, the value of the art market was $50.1 billion.' +
                ' During this time, more than 30 million transactions were made.' +
                ' These numbers are not very high at a time when many industries are suffering huge losses.<br>' +
                'Research on the period between 1957 and 2007 shows that an artwork gains value by an average of 3.97% each year.' +
                ' This rate has been increasing rapidly in recent years.' +
                '<div class="job-advertisement-heading">The Number of Capital Owners Increases</div>' +
                'The main reason for the increase in the prices of artworks is the rapid increase in demand while supply remains constant.' +
                ' The biggest reason for the increase in demand is the increase in the number of wealthy people globally.' +
                ' In the past year alone, there has been a 7% increase in the number of people with a net worth of more than $1 billion.' +
                ' The fact that this audience turns to the field of art both to improve their image and to make a safe investment causes prices in the market to increase.' +
                '<div class="job-advertisement-heading">Returns on Conventional Investment Instruments Are Not Appetizing</div>' +
                'In the last few years, the return promises of conventional investment instruments in developed countries have been stagnant,' +
                ' causing capital owners to turn to alternative investment channels.' +
                ' The fact that art investments are less risky than many alternative investment channels' +
                ' makes these investments attractive to investors seeking to provide above-average returns.' +
                '<div class="job-advertisement-heading">New Markets</div>' +
                'The developed economies of Western countries are traditional markets for the art market.' +
                ' However, with the increase in the economic power of countries such as China, Russia and India in recent years,' +
                ' there has been an explosion in the demand for art investments from these countries.' +
                ' The demand created by these countries, which had little say in the art markets in the 20th century, increases the value of artworks.' +
                '<div class="job-advertisement-heading">Wider Service</div>' +
                'The reason why more people are entering the field of art investment is the increase in the variety of services provided in this field.' +
                ' The increase in individuals and institutions providing services in fields such as collection consultancy or art investment expertise' +
                ' not only makes it easier for new names to become art investors, but also increases the demand for artworks with high commercial potential.' +
                '<div class="job-advertisement-heading">Digitalization</div>' +
                'Alternative channels have emerged for the distribution of artworks, as the art market has started to digitize, albeit late, due to its conservative nature.' +
                ' Digitalization, which includes different applications from auctions using virtual reality to digital artworks,' +
                ' makes it easier for the buyer to access the artwork and for the artifacts to reach their true value.',
            ROUTER_LINK: {
                TEXT: 'View Artworks',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'NFT, Future of the Art World',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Could NFT Be the Future of the Art World?</div>' +
                'Throughout history, there has been an indispensable relationship between art and the concept of money.' +
                ' The wealthy, who embraced art and artists, financed the production of artworks and traded them,' +
                ' sometimes in order to strengthen their image and sometimes to make a profit from their interest in art.<br>' +
                'The art market, which emerged under the leadership of these "bosses" who took art under their protection,' +
                ' facilitated the emergence of many artworks that we all know today, but on the other hand, it caused many artists to not reach large masses.' +
                ' Therefore, it is impossible to know how many artists we cannot recognize and see their artworks due to the obstacles in the art market.<br>' +
                '<div class="job-advertisement-heading">Things Are Changing</div>' +
                'The concept of NFT makes art investment, which is in the hands of only a small group, more democratic and allows it to spread to large masses.' +
                ' In this way, the financial fate of art and the artist passes from the hands of a few people to the initiative of large masses.<br>' +
                'This development is especially important for artists working outside of the popular arts such as music or cinema.' +
                ' In this way, artists have the opportunity to reach wider audiences without intermediary institutions.<br>' +
                '<div class="job-advertisement-heading">NFT Doesn\'t Just Solve Financial Problems</div>' +
                'The concept of NFT has been embraced by artists who produce in digital environments that have been largely excluded by the traditional art world.' +
                ' Thanks to NFT, these artists have already started to find the place they deserve.<br>' +
                'One of the biggest problems of artists who produce artworks in digital environments is that their artworks can be easily copied.' +
                ' It is quite difficult to tell which of the two copies of an artwork is the original.<br>' +
                'NFTs provide a solution to this problem.' +
                ' An artwork in NFT does not allow copies to raise suspicion, as it can prove its originality by itself.<br>' +
                '<div class="job-advertisement-heading">So is NFT the Future?</div>' +
                'Although almost everyone in the innovative wing of the art world approaches the concept of NFT with great enthusiasm,' +
                ' it is not possible to predict what effect this technology will have on the art world in the future.' +
                ' Because history is full of examples where technological developments have created unexpected social effects.<br>' +
                'It is a fact that the perception of "artwork" must first change in order for NFTs to create an optimistic change.' +
                ' It will not be easy for the broad conservative audience, who thinks that a JPG file cannot be an artwork, to give up on these ideas.<br>' +
                'Another problem is that the traditional art market refuses to review the way it does business.' +
                ' In this regard, it is possible to say that it will be difficult to change habits.<br>' +
                'Another problem is that the large masses do not yet have the reflex to perceive artworks as an investment tool.' +
                ' Although this group, which has been in the habit of using more traditional investment tools such as real estate, foreign exchange markets or the stock market,' +
                ' has recently started to invest in crypto money markets, there is still a long way to go.' +
                ' The risk that NFTs carry is keeping the masses from getting involved in this market.<br>' +
                '<div class="job-advertisement-heading">Tokenization the Value of an Artwork</div>' +
                'Fixing the value of a token to a stable real asset reduces the risk of that token.' +
                ' Fixed tokens in the cryptocurrency markets are a good example of this.<br>' +
                'At Artiox, we reduce the risk factor by tokenizing the commercial value of physical artwork whose commercial potential has been analyzed.' +
                ' In this way, we create an environment that will make investors feel safe, who are hesitant to invest in this area due to the high risks of the cryptocurrency markets.' +
                ' At the same time, we are transforming the traditional art market, which is under the control of the wealthy minority, into a more democratic space.<br>' +
                'It is very difficult to predict how the NFT concept will guide the art markets in the future. But something is sure to change.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Could NFT Be the Future of the Art World?</div>' +
                'Throughout history, there has been an indispensable relationship between art and the concept of money.' +
                ' The wealthy, who embraced art and artists, financed the production of artworks and traded them,' +
                ' sometimes in order to strengthen their image and sometimes to make a profit from their interest in art.<br>' +
                'The art market, which emerged under the leadership of these "bosses" who took art under their protection,' +
                ' facilitated the emergence of many artworks that we all know today, but on the other hand, it caused many artists to not reach large masses.' +
                ' Therefore, it is impossible to know how many artists we cannot recognize and see their artworks due to the obstacles in the art market.<br>' +
                '<div class="job-advertisement-heading">Things Are Changing</div>' +
                'The concept of NFT makes art investment, which is in the hands of only a small group, more democratic and allows it to spread to large masses.' +
                ' In this way, the financial fate of art and the artist passes from the hands of a few people to the initiative of large masses.<br>' +
                'This development is especially important for artists working outside of the popular arts such as music or cinema.' +
                ' In this way, artists have the opportunity to reach wider audiences without intermediary institutions.<br>' +
                '<div class="job-advertisement-heading">NFT Doesn\'t Just Solve Financial Problems</div>' +
                'The concept of NFT has been embraced by artists who produce in digital environments that have been largely excluded by the traditional art world.' +
                ' Thanks to NFT, these artists have already started to find the place they deserve.<br>' +
                'One of the biggest problems of artists who produce artworks in digital environments is that their artworks can be easily copied.' +
                ' It is quite difficult to tell which of the two copies of an artwork is the original.<br>' +
                'NFTs provide a solution to this problem.' +
                ' An artwork in NFT does not allow copies to raise suspicion, as it can prove its originality by itself.<br>' +
                '<div class="job-advertisement-heading">So is NFT the Future?</div>' +
                'Although almost everyone in the innovative wing of the art world approaches the concept of NFT with great enthusiasm,' +
                ' it is not possible to predict what effect this technology will have on the art world in the future.' +
                ' Because history is full of examples where technological developments have created unexpected social effects.<br>' +
                'It is a fact that the perception of "artwork" must first change in order for NFTs to create an optimistic change.' +
                ' It will not be easy for the broad conservative audience, who thinks that a JPG file cannot be an artwork, to give up on these ideas.<br>' +
                'Another problem is that the traditional art market refuses to review the way it does business.' +
                ' In this regard, it is possible to say that it will be difficult to change habits.<br>' +
                'Another problem is that the large masses do not yet have the reflex to perceive artworks as an investment tool.' +
                ' Although this group, which has been in the habit of using more traditional investment tools such as real estate, foreign exchange markets or the stock market,' +
                ' has recently started to invest in crypto money markets, there is still a long way to go.' +
                ' The risk that NFTs carry is keeping the masses from getting involved in this market.<br>' +
                '<div class="job-advertisement-heading">Tokenization the Value of an Artwork</div>' +
                'Fixing the value of a token to a stable real asset reduces the risk of that token.' +
                ' Fixed tokens in the cryptocurrency markets are a good example of this.<br>' +
                'At Artiox, we reduce the risk factor by tokenizing the commercial value of physical artwork whose commercial potential has been analyzed.' +
                ' In this way, we create an environment that will make investors feel safe, who are hesitant to invest in this area due to the high risks of the cryptocurrency markets.' +
                ' At the same time, we are transforming the traditional art market, which is under the control of the wealthy minority, into a more democratic space.<br>' +
                'It is very difficult to predict how the NFT concept will guide the art markets in the future. But something is sure to change.',
            ROUTER_LINK: {
                TEXT: 'View Artworks',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Art Market, Pandemic and After',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Art Market, Pandemic and After</div>' +
                'The global art market, like any other industry, has been affected by the COVID-19 pandemic.' +
                ' In 2020, there was a decrease of approximately 30% in the total transaction volume in the market compared to 2019.' +
                ' The biggest reason for this is the changing priorities of those who invest in artworks.<br>' +
                'In 2020, the size of the art market reached approximately 50 billion dollars.' +
                ' This market, which shrank by 22% compared to 2019; It reached its lowest level since 2009, when another economic crisis experienced it.<br>' +
                'This was due to the decline in the US, UK and China, which hold 82% of the art market.' +
                ' The art market shrank 24% in the US, 22% in the UK and 12% in China.' +
                ' Closures and restrictions were effective in the emergence of this decline.' +
                ' Of the 365 global art fairs planned for 2020, 61% were cancelled.<br>' +
                'Despite all these decreases, online sales reached record levels.' +
                ' A volume of $12.4 billion in 2020 was generated through online sales.' +
                ' This figure is almost double the figure obtained in 2019.' +
                ' In 2020, 22% of sales in the field of fine arts came through online sales channels.' +
                ' In addition, during this period, art-oriented technology startups managed to collect 60% of the investments in the sector.' +
                ' Art startups, which could collect a total of $640 million in the 20-year period before the pandemic, managed to receive an investment of $380 million in 2020 alone.<br>' +
                '<div class="job-advertisement-heading">Reopening</div>' +
                'The fact that daily life has started to return to normal, albeit relatively, since March and art events have opened their doors has energized the art markets.' +
                ' This movement has direct and indirect effects on the art market.<br>' +
                'The art market is largely in the hands of wealthy people.' +
                ' The slowdown in the economy during the shutdown prompted wealthy people to turn to a more protectionist wealth management strategy.' +
                ' Therefore, art investments remained in the background for these people in this period.' +
                ' In the coming period, it can be expected that these people will return to the market with the mobilization of the economy.<br>' +
                '<div class="job-advertisement-heading">Interest Rates</div>' +
                'In order to support economic activity during the epidemic, interest rates were kept at low levels in many developed economies and support packages were announced.' +
                ' At first glance, it can be said that low loan rates will facilitate the purchase of works of art, and thus, there will be a dynamism in the art market.' +
                ' However, during the pandemic, the opposite happened. Sellers met their financing needs by taking advantage of low-interest loans instead of selling their works.' +
                ' The buyers, on the other hand, did not want to buy the works at the high prices created by the low supply, as they expected the prices to fall in the market.' +
                ' Considering that the interest rate policies will continue as they are for a while, we can say that this trend will have a negative effect on the growth of the market.<br>' +
                '<div class="job-advertisement-heading">Technology</div>' +
                'The record level of online sales during the pandemic and the unprecedented large investments of art-oriented technology startups' +
                ' show that technology will take a large share in the art economy in the coming period.' +
                ' Especially young art investors who have just entered the market are warmly attracted to online channels' +
                ' and that these channels make the art markets more accessible and systematic,' +
                ' is one of the most important signs that technology will not be an intermediate solution in the pandemic period, but a permanent value in the art economy.<br>' +
                'On the other hand, the volume created by NFT technology is not large enough to be ignored.' +
                'This technology, which creates a market that did not exist before, is not only growing the arts economy, but also the number of people investing in the arts.' +
                '<div class="job-advertisement-heading">As a Result</div>' +
                'Let\'s summarize the future expectations of the art market with a few items:' +
                '<ul>' +
                '  <li>The revival of the economy, thanks to the return to normal life, will allow wealth owners to return to their investments in art.</li>' +
                '  <li>As the interest rates are low and the continuation of the economic support packages meet the financing needs of the sellers,' +
                ' the supply problem in the market may continue.</li>' +
                '  <li>The boom in online sales and the increase in investments in art-oriented technology startups show that technology' +
                ' will take a much larger place in the art economy in the coming years.</li>' +
                '</ul>',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Art Market, Pandemic and After</div>' +
                'The global art market, like any other industry, has been affected by the COVID-19 pandemic.' +
                ' In 2020, there was a decrease of approximately 30% in the total transaction volume in the market compared to 2019.' +
                ' The biggest reason for this is the changing priorities of those who invest in artworks.<br>' +
                'In 2020, the size of the art market reached approximately 50 billion dollars.' +
                ' This market, which shrank by 22% compared to 2019; It reached its lowest level since 2009, when another economic crisis experienced it.<br>' +
                'This was due to the decline in the US, UK and China, which hold 82% of the art market.' +
                ' The art market shrank 24% in the US, 22% in the UK and 12% in China.' +
                ' Closures and restrictions were effective in the emergence of this decline.' +
                ' Of the 365 global art fairs planned for 2020, 61% were cancelled.<br>' +
                'Despite all these decreases, online sales reached record levels.' +
                ' A volume of $12.4 billion in 2020 was generated through online sales.' +
                ' This figure is almost double the figure obtained in 2019.' +
                ' In 2020, 22% of sales in the field of fine arts came through online sales channels.' +
                ' In addition, during this period, art-oriented technology startups managed to collect 60% of the investments in the sector.' +
                ' Art startups, which could collect a total of $640 million in the 20-year period before the pandemic, managed to receive an investment of $380 million in 2020 alone.<br>' +
                '<div class="job-advertisement-heading">Reopening</div>' +
                'The fact that daily life has started to return to normal, albeit relatively, since March and art events have opened their doors has energized the art markets.' +
                ' This movement has direct and indirect effects on the art market.<br>' +
                'The art market is largely in the hands of wealthy people.' +
                ' The slowdown in the economy during the shutdown prompted wealthy people to turn to a more protectionist wealth management strategy.' +
                ' Therefore, art investments remained in the background for these people in this period.' +
                ' In the coming period, it can be expected that these people will return to the market with the mobilization of the economy.<br>' +
                '<div class="job-advertisement-heading">Interest Rates</div>' +
                'In order to support economic activity during the epidemic, interest rates were kept at low levels in many developed economies and support packages were announced.' +
                ' At first glance, it can be said that low loan rates will facilitate the purchase of works of art, and thus, there will be a dynamism in the art market.' +
                ' However, during the pandemic, the opposite happened. Sellers met their financing needs by taking advantage of low-interest loans instead of selling their works.' +
                ' The buyers, on the other hand, did not want to buy the works at the high prices created by the low supply, as they expected the prices to fall in the market.' +
                ' Considering that the interest rate policies will continue as they are for a while, we can say that this trend will have a negative effect on the growth of the market.<br>' +
                '<div class="job-advertisement-heading">Technology</div>' +
                'The record level of online sales during the pandemic and the unprecedented large investments of art-oriented technology startups' +
                ' show that technology will take a large share in the art economy in the coming period.' +
                ' Especially young art investors who have just entered the market are warmly attracted to online channels' +
                ' and that these channels make the art markets more accessible and systematic,' +
                ' is one of the most important signs that technology will not be an intermediate solution in the pandemic period, but a permanent value in the art economy.<br>' +
                'On the other hand, the volume created by NFT technology is not large enough to be ignored.' +
                'This technology, which creates a market that did not exist before, is not only growing the arts economy, but also the number of people investing in the arts.' +
                '<div class="job-advertisement-heading">As a Result</div>' +
                'Let\'s summarize the future expectations of the art market with a few items:' +
                '<ul>' +
                '  <li>The revival of the economy, thanks to the return to normal life, will allow wealth owners to return to their investments in art.</li>' +
                '  <li>As the interest rates are low and the continuation of the economic support packages meet the financing needs of the sellers,' +
                ' the supply problem in the market may continue.</li>' +
                '  <li>The boom in online sales and the increase in investments in art-oriented technology startups show that technology' +
                ' will take a much larger place in the art economy in the coming years.</li>' +
                '</ul>',
            ROUTER_LINK: {
                TEXT: 'View Artworks',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'NFTs vs Artworks\' Tokens of Artiox',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">What Is NFT Actually? / What Is The Difference With Tokens Listed On Artiox?</div>' +
                'Even people who are not interested in cryptocurrencies or the art markets are talking about NFTs lately.' +
                ' The biggest reason for this is the rapid spread of sensational news about NFTs on social media.' +
                ' The fact that digital artworks, which are even debatable as artworks, find buyers for much higher amounts of money than many classical artworks,' +
                ' causes the public to perceive the concept of NFT as insanity.' +
                '<div class="job-advertisement-heading">Let\'s Define the Concept of NFT</div>' +
                'First, let\'s start by defining the concept of NFT.' +
                ' NFT is the abbreviation of "Non-fungile token". Was it descriptive? Probably not…<br>' +
                'NFT is a value created by registering a digital asset with cookies on a blockchain by means of smart contracts.' +
                ' This unique value cannot be changed or divided in any way.' +
                ' It is this uniqueness and immutability that makes NFTs a commercial commodity.' +
                '<div class="job-advertisement-heading">How are NFTs Used in the Arts?</div>' +
                'The NFT concept can be used in digital areas where uniqueness is valuable.' +
                ' The fields of game and art outshines.<br>' +
                'Digital artists, who have recently been almost ignored by the traditional art markets,' +
                ' have started to earn income from the artworks they produce thanks to the NFTs they have issued.' +
                ' There are cases where physical artworks are also converted into NFTs.<br>' +
                'It is possible to think of the concept of NFT as the reality document of a digital artwork.' +
                ' This immutable document ensures the uniqueness of digital artworks that can be easily copied under normal conditions.<br>' +
                '<div class="job-advertisement-heading">How Are Artiox\'s Tokens Different From NFT?</div>' +
                'The tokens we list on Artiox are designed to facilitate the buying and selling of artworks like cryptocurrencies, similar to NFTs.' +
                ' But we have to say that these tokens are not NFTs.<br>' +
                'First of all, for an artwork to be NFT, all or at least an integral part of the artwork must be digital.' +
                ' Although there are various methods for converting physical artworks to NFT,' +
                ' it is necessary to compromise the physical integrity or commercial potential of the artwork in order to create uniqueness in all of these methods.' +
                ' Dividing the physical artwork into parts or completely destroying the artwork are among these methods.<br>' +
                'As Artiox, we tokenize the economic value created by the physical artworks instead of converting them to NFT.' +
                ' In this way, it opens the way for artworks that were not created to be NFTs to become investment tools' +
                ' that large masses can participate without destroying their physical integrity.' +
                ' Instead of an irreversible destructive transformation such as destroying or dismantling the physical artifact,' +
                ' we ensure that large masses benefit from this commercial potential by breaking down the commercial benefit' +
                ' created only during the time the artwork is under our management.<br>' +
                'The fact that we tokenize the artworks we include in our system as a result of the evaluations made by experts in their fields' +
                ' also makes it easier for Artiox investors to invest in an environment with lower risk.' +
                ' While the value of an NFT artifact fluctuates greatly over time,' +
                ' the tokens we list on Artiox become a less risky investment option thanks to the value of physical artifacts.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">What Is NFT Actually? / What Is The Difference With Tokens Listed On Artiox?</div>' +
                'Even people who are not interested in cryptocurrencies or the art markets are talking about NFTs lately.' +
                ' The biggest reason for this is the rapid spread of sensational news about NFTs on social media.' +
                ' The fact that digital artworks, which are even debatable as artworks, find buyers for much higher amounts of money than many classical artworks,' +
                ' causes the public to perceive the concept of NFT as insanity.' +
                '<div class="job-advertisement-heading">Let\'s Define the Concept of NFT</div>' +
                'First, let\'s start by defining the concept of NFT.' +
                ' NFT is the abbreviation of "Non-fungile token". Was it descriptive? Probably not…<br>' +
                'NFT is a value created by registering a digital asset with cookies on a blockchain by means of smart contracts.' +
                ' This unique value cannot be changed or divided in any way.' +
                ' It is this uniqueness and immutability that makes NFTs a commercial commodity.' +
                '<div class="job-advertisement-heading">How are NFTs Used in the Arts?</div>' +
                'The NFT concept can be used in digital areas where uniqueness is valuable.' +
                ' The fields of game and art outshines.<br>' +
                'Digital artists, who have recently been almost ignored by the traditional art markets,' +
                ' have started to earn income from the artworks they produce thanks to the NFTs they have issued.' +
                ' There are cases where physical artworks are also converted into NFTs.<br>' +
                'It is possible to think of the concept of NFT as the reality document of a digital artwork.' +
                ' This immutable document ensures the uniqueness of digital artworks that can be easily copied under normal conditions.<br>' +
                '<div class="job-advertisement-heading">How Are Artiox\'s Tokens Different From NFT?</div>' +
                'The tokens we list on Artiox are designed to facilitate the buying and selling of artworks like cryptocurrencies, similar to NFTs.' +
                ' But we have to say that these tokens are not NFTs.<br>' +
                'First of all, for an artwork to be NFT, all or at least an integral part of the artwork must be digital.' +
                ' Although there are various methods for converting physical artworks to NFT,' +
                ' it is necessary to compromise the physical integrity or commercial potential of the artwork in order to create uniqueness in all of these methods.' +
                ' Dividing the physical artwork into parts or completely destroying the artwork are among these methods.<br>' +
                'As Artiox, we tokenize the economic value created by the physical artworks instead of converting them to NFT.' +
                ' In this way, it opens the way for artworks that were not created to be NFTs to become investment tools' +
                ' that large masses can participate without destroying their physical integrity.' +
                ' Instead of an irreversible destructive transformation such as destroying or dismantling the physical artifact,' +
                ' we ensure that large masses benefit from this commercial potential by breaking down the commercial benefit' +
                ' created only during the time the artwork is under our management.<br>' +
                'The fact that we tokenize the artworks we include in our system as a result of the evaluations made by experts in their fields' +
                ' also makes it easier for Artiox investors to invest in an environment with lower risk.' +
                ' While the value of an NFT artifact fluctuates greatly over time,' +
                ' the tokens we list on Artiox become a less risky investment option thanks to the value of physical artifacts.',
            ROUTER_LINK: {
                TEXT: 'View Artworks',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Art Investing for Novices',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Art Investing for Novices</div>' +
                'Almost all of us are familiar with conventional investment instruments such as the stock market or currency markets.' +
                ' Naturally, we invest a large part of our savings in these investment instruments.' +
                ' However, there are alternatives to these investment instruments. Art investments are among these alternatives.' +
                '<div class="job-advertisement-heading">Is Being an Art Investor a Dream for an Average Income?</div>' +
                'The biggest reason most of us have limited knowledge about art investing is that we think art investing is the business of wealthy people.' +
                ' When we consider the traditional art market, this opinion is not so wrong.' +
                ' It is not possible for everyone to spend thousands of dollars on artworks that are sold at auctions and have investment value.<br>' +
                'But things are changing in the art market.' +
                ' Artiox, who introduced the concept of art investment 3.0, provides people with average incomes the opportunity to become art investors with small sums.' +
                '<div class="job-advertisement-heading">What are the Advantages of Art Investment?</div>' +
                'If you think of art investors as art enthusiasts who just enjoy looking at the paintings on their walls, you are sorely mistaken.' +
                ' Although there is always the personal pleasure factor in art, art investors buy artworks considering their commercial value.' +
                ' The most important reason for them to make this choice in terms of investment is that correctly chosen artworks always bring profit in the long run.' +
                '<div class="job-advertisement-heading">How to Choose the Right Artwork?</div>' +
                'As with any investment instrument, the commercial return of artworks can vary.' +
                ' Considering that the investment to be made in traditional art market channels will be large amounts,' +
                ' it is possible to see how important it is to start with the right artwork.<br>' +
                'First of all, it is very important who the artist who signed the artwork is.' +
                ' The artworks of artists known in the art world usually do not lose value.' +
                ' The fact that these artifacts are low-risk investment instruments increases the demand for artifacts.<br>' +
                'It is possible to have the artworks of less known artists at more economical prices.' +
                ' However, this investment is risky.' +
                ' Before making such an investment, it is necessary to be sure of the future commercial potential of the artist.<br>' +
                'As a result, finding the right piece of art to invest in is not an easy task.' +
                ' That\'s why almost all serious collectors artwork with consultants.<br>' +
                'Artiox also offers a solution to this problem.' +
                ' Before an artwork is included in Artiox\'s portfolio, it is evaluated by an expert art consultant to determine its commercial potential.' +
                ' Thus, Artiox users experience the comfort of investing in the right artwork, even if they have no knowledge of the art market.<br>' +
                '<div class="job-advertisement-heading">You Have Bought the Artwork. Then what?</div>' +
                'When you buy dollars, you can deposit it in a bank, when you buy gold, you can put it in a safe.' +
                ' So how will you store your artwork?<br>' +
                'One of the most challenging aspects of art investing is the delicate task of preserving artworks.' +
                ' It is necessary to store it in the right temperature, light and humidity conditions according to the structure of these artworks.' +
                ' Of course, it is also essential to ensure the safety of the artwork first.<br>' +
                'Owning an artwork requires responsibility.' +
                ' It is essential not to forget that these artworks are not only an investment tool but also a common heritage of humanity' +
                ' and that\'s why it is essential to preserve the artifacts in the best conditions.<br>' +
                'Artiox also saves those who want to invest in artworks from this hassle.' +
                ' The insured artworks are kept in secure warehouses designed for the storage of them.<br>' +
                'Traditional art investing is a business that requires large capital and intense interest.' +
                ' Therefore, a very small part of the society can become art investors.' +
                ' However, Artiox democratizes art investing thanks to the possibilities provided by technology.' +
                ' Become a member of Artiox now and start investing in art.',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Art Investing for Novices</div>' +
                'Almost all of us are familiar with conventional investment instruments such as the stock market or currency markets.' +
                ' Naturally, we invest a large part of our savings in these investment instruments.' +
                ' However, there are alternatives to these investment instruments. Art investments are among these alternatives.' +
                '<div class="job-advertisement-heading">Is Being an Art Investor a Dream for an Average Income?</div>' +
                'The biggest reason most of us have limited knowledge about art investing is that we think art investing is the business of wealthy people.' +
                ' When we consider the traditional art market, this opinion is not so wrong.' +
                ' It is not possible for everyone to spend thousands of dollars on artworks that are sold at auctions and have investment value.<br>' +
                'But things are changing in the art market.' +
                ' Artiox, who introduced the concept of art investment 3.0, provides people with average incomes the opportunity to become art investors with small sums.' +
                '<div class="job-advertisement-heading">What are the Advantages of Art Investment?</div>' +
                'If you think of art investors as art enthusiasts who just enjoy looking at the paintings on their walls, you are sorely mistaken.' +
                ' Although there is always the personal pleasure factor in art, art investors buy artworks considering their commercial value.' +
                ' The most important reason for them to make this choice in terms of investment is that correctly chosen artworks always bring profit in the long run.' +
                '<div class="job-advertisement-heading">How to Choose the Right Artwork?</div>' +
                'As with any investment instrument, the commercial return of artworks can vary.' +
                ' Considering that the investment to be made in traditional art market channels will be large amounts,' +
                ' it is possible to see how important it is to start with the right artwork.<br>' +
                'First of all, it is very important who the artist who signed the artwork is.' +
                ' The artworks of artists known in the art world usually do not lose value.' +
                ' The fact that these artifacts are low-risk investment instruments increases the demand for artifacts.<br>' +
                'It is possible to have the artworks of less known artists at more economical prices.' +
                ' However, this investment is risky.' +
                ' Before making such an investment, it is necessary to be sure of the future commercial potential of the artist.<br>' +
                'As a result, finding the right piece of art to invest in is not an easy task.' +
                ' That\'s why almost all serious collectors artwork with consultants.<br>' +
                'Artiox also offers a solution to this problem.' +
                ' Before an artwork is included in Artiox\'s portfolio, it is evaluated by an expert art consultant to determine its commercial potential.' +
                ' Thus, Artiox users experience the comfort of investing in the right artwork, even if they have no knowledge of the art market.<br>' +
                '<div class="job-advertisement-heading">You Have Bought the Artwork. Then what?</div>' +
                'When you buy dollars, you can deposit it in a bank, when you buy gold, you can put it in a safe.' +
                ' So how will you store your artwork?<br>' +
                'One of the most challenging aspects of art investing is the delicate task of preserving artworks.' +
                ' It is necessary to store it in the right temperature, light and humidity conditions according to the structure of these artworks.' +
                ' Of course, it is also essential to ensure the safety of the artwork first.<br>' +
                'Owning an artwork requires responsibility.' +
                ' It is essential not to forget that these artworks are not only an investment tool but also a common heritage of humanity' +
                ' and that\'s why it is essential to preserve the artifacts in the best conditions.<br>' +
                'Artiox also saves those who want to invest in artworks from this hassle.' +
                ' The insured artworks are kept in secure warehouses designed for the storage of them.<br>' +
                'Traditional art investing is a business that requires large capital and intense interest.' +
                ' Therefore, a very small part of the society can become art investors.' +
                ' However, Artiox democratizes art investing thanks to the possibilities provided by technology.' +
                ' Become a member of Artiox now and start investing in art.',
            ROUTER_LINK: {
                TEXT: 'View Artworks',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Commercial Value of an Artwork',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">How is the Commercial Value of an Artwork Determined?</div>' +
                'On November 15, 2017, Christie\'s, one of the most important auction houses in the world, was hosting a very important auction in New York.' +
                ' Leonardo\'s "Salvator Mundi" painting was put up for sale at the auction.' +
                ' Bader bin Abdullah, who participated in the auction on behalf of Saudi Crown Prince Mohammed bin Salman, won the auction.' +
                ' With a price tag of $450.3 million, Salvator Mundi was the most expensive painting ever sold.' +
                ' However, the same painting was only bought for £58 at an auction in 1958.<br>' +
                'The subject of how the commercial valuation of artworks is done is one of the most curious subjects of those who are interested in art but are far from the art markets.' +
                ' The news about the paintings that were bought for millions of dollars at auctions make almost everyone interested in this subject.<br>' +
                'There are certain variables for pricing many trading commodities.' +
                ' When you buy a house, variables such as the location of the house,' +
                ' the age of the building and the size of the house give you important clues about how much you should pay for the house you are considering.<br>' +
                'But the same is not true for artworks.' +
                ' Although there are criteria for the valuation of an artwork, it is not easy to accurately predict the true commercial value of an artwork.' +
                'Let\'s take a look at the variables that are looked at when pricing an artwork.' +
                '<div class="job-advertisement-heading">Artist</div>' +
                'Undoubtedly, the main determinant of the value of an artwork is the artist who produced it.' +
                ' The importance of artists in the art world, the number of artworks they have created and the period in which they produced the artwork are important.' +
                ' While the artworks of well-known artists usually find buyers at high prices,' +
                ' sometimes the artworks of artists whose identity is a mystery or whose value was not known in their lifetime can also be sold at high prices.' +
                ' Therefore, although it is important for an artist to be marketed in the art market, at the end of the day, the real value is determined by the identity of the artist.' +
                '<div class="job-advertisement-heading">Reality</div>' +
                'The art market is full of imitations. Some of these imitations are not as innocent as the Venus painting hanging in your home.' +
                ' Art crimes, including counterfeit works, cost the American economy between $6 and $8 billion each year.' +
                ' The FBI even has a special desk for these crimes.<br>' +
                'The prevalence of counterfeit artworks makes the authenticity of the artworks critical.' +
                ' The reason why the Salvator Mundi painting, which we talked about at the beginning of our article,' +
                ' was sold for only 58 pounds in 1958, was that there were great doubts about the authenticity of the painting at that time.' +
                '<div class="job-advertisement-heading">Status of the Artworks</div>' +
                'The physical condition is also one of the factors that determine the value of the artwork.' +
                ' This variable is very important especially for artworks whose production date is not recent.' +
                ' Artworks that are worn out due to natural causes or that have undergone a bad restoration may lose their value.' +
                '<div class="job-advertisement-heading">State of the Art Market</div>' +
                'The state of the art market is also a very important variable in determining the value of an artwork.' +
                ' The size of the art market at the time of sale and the demand of the market for the artist, the artwork or the movement in which the artwork is also affect the price tag.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">How is the Commercial Value of an Artwork Determined?</div>' +
                'On November 15, 2017, Christie\'s, one of the most important auction houses in the world, was hosting a very important auction in New York.' +
                ' Leonardo\'s "Salvator Mundi" painting was put up for sale at the auction.' +
                ' Bader bin Abdullah, who participated in the auction on behalf of Saudi Crown Prince Mohammed bin Salman, won the auction.' +
                ' With a price tag of $450.3 million, Salvator Mundi was the most expensive painting ever sold.' +
                ' However, the same painting was only bought for £58 at an auction in 1958.<br>' +
                'The subject of how the commercial valuation of artworks is done is one of the most curious subjects of those who are interested in art but are far from the art markets.' +
                ' The news about the paintings that were bought for millions of dollars at auctions make almost everyone interested in this subject.<br>' +
                'There are certain variables for pricing many trading commodities.' +
                ' When you buy a house, variables such as the location of the house,' +
                ' the age of the building and the size of the house give you important clues about how much you should pay for the house you are considering.<br>' +
                'But the same is not true for artworks.' +
                ' Although there are criteria for the valuation of an artwork, it is not easy to accurately predict the true commercial value of an artwork.' +
                'Let\'s take a look at the variables that are looked at when pricing an artwork.' +
                '<div class="job-advertisement-heading">Artist</div>' +
                'Undoubtedly, the main determinant of the value of an artwork is the artist who produced it.' +
                ' The importance of artists in the art world, the number of artworks they have created and the period in which they produced the artwork are important.' +
                ' While the artworks of well-known artists usually find buyers at high prices,' +
                ' sometimes the artworks of artists whose identity is a mystery or whose value was not known in their lifetime can also be sold at high prices.' +
                ' Therefore, although it is important for an artist to be marketed in the art market, at the end of the day, the real value is determined by the identity of the artist.' +
                '<div class="job-advertisement-heading">Reality</div>' +
                'The art market is full of imitations. Some of these imitations are not as innocent as the Venus painting hanging in your home.' +
                ' Art crimes, including counterfeit works, cost the American economy between $6 and $8 billion each year.' +
                ' The FBI even has a special desk for these crimes.<br>' +
                'The prevalence of counterfeit artworks makes the authenticity of the artworks critical.' +
                ' The reason why the Salvator Mundi painting, which we talked about at the beginning of our article,' +
                ' was sold for only 58 pounds in 1958, was that there were great doubts about the authenticity of the painting at that time.' +
                '<div class="job-advertisement-heading">Status of the Artworks</div>' +
                'The physical condition is also one of the factors that determine the value of the artwork.' +
                ' This variable is very important especially for artworks whose production date is not recent.' +
                ' Artworks that are worn out due to natural causes or that have undergone a bad restoration may lose their value.' +
                '<div class="job-advertisement-heading">State of the Art Market</div>' +
                'The state of the art market is also a very important variable in determining the value of an artwork.' +
                ' The size of the art market at the time of sale and the demand of the market for the artist, the artwork or the movement in which the artwork is also affect the price tag.',
            ROUTER_LINK: {
                TEXT: 'View Artworks',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'The Concept of NFT for Artists',
            IMAGE_SRC: './assets/announcements/operations/corporate.svg#corporate',
            TEXT_MOBILE: '<div class="job-advertisement-heading">What Does the Concept of NFT Mean for Artists?</div>' +
                'In April 2011, an animation of a cat with a rainbow coming out of its tail was uploaded to YouTube.' +
                ' This video, with a Japanese pop song playing in the background, became inexplicably popular, making it the 5th most-watched video on YouTube of 2011.<br>' +
                'To celebrate the 10th Anniversary of the video, Chris Torres, the creator of this video called Nyan Cat,' +
                ' rearranged the GIF that created the video, turned it into NFT and offered it for sale.' +
                ' Entertaining people for just a few seconds, Nyan Cat sold for a sensational $590,000 valuation.<br>' +
                'While many recent stories like this have made the concept of NFT the agenda of even people who are not related to the subject,' +
                ' the sensational side of these stories has caused NFTs to be thought of as internet bubbles.<br>' +
                'However, a big change is taking place in this area beyond sensation.' +
                ' Artists are a big part of this change.<br>' +
                '<div class="job-advertisement-heading">Excludeds</div>' +
                'Artists using digital media as a canvas pioneered the concept of NFT.' +
                ' The main reason for this was that these artists were largely excluded from the traditional art markets.' +
                ' Although these artists have recently been involved in art formations with a more innovative line, ' +
                'they have had a hard time achieving commercial success as the traditional collection value of the artworks they produce has been questioned.<br>' +
                'The NFT concept embraced these marginalized artists.' +
                ' Thanks to NFT, artists, who were able to sell their artworks through digital platforms with much more democratic structures,' +
                ' had the opportunity to reach large masses and sell in a more comfortable way.<br>' +
                '<div class="job-advertisement-heading">Intermediaries</div>' +
                'One of the most important realities of the art market is that it is largely in the hands of middlemen.' +
                ' In the sale of an artwork, brokerage commissions can reach up to 50%, depending on the identity of the artist and the characteristics of the artwork.' +
                ' That\'s why the artists earn much less than thry deserve.<br>' +
                'The direct selling of NFT eliminates the exorbitant commission rates that artists have to pay.' +
                ' Artists can sell the artworks they have converted to NFT directly' +
                ' or they can sell their artworks through platforms that operate at much lower commission rates compared to traditional brokerage houses.<br>' +
                '<div class="job-advertisement-heading">Ownership</div>' +
                'In the traditional sense, with the sale of the work of art, the ownership of the artwork produced by the artist ends.' +
                ' Although the developments in the artists\' career increase the value of the artworks previously sold,' +
                ' the artists cannot benefit from this increased value.<br>' +
                'Thanks to NFT, it is possible for the artists to receive a certain percentage from each sale of their artwork.' +
                ' In this way, they can continue to earn income from an artwork they have sold throughout their life.<br>' +
                'With the opportunities provided by NFT technology, it is possible to say that the financing of art will change in favor of the artists.' +
                ' However, it will take a long time to see the real effects of technological innovations.' +
                ' At the end of this period, we will see to what extent NFTs will democratize arts finance.<br>',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">What Does the Concept of NFT Mean for Artists?</div>' +
                'In April 2011, an animation of a cat with a rainbow coming out of its tail was uploaded to YouTube.' +
                ' This video, with a Japanese pop song playing in the background, became inexplicably popular, making it the 5th most-watched video on YouTube of 2011.<br>' +
                'To celebrate the 10th Anniversary of the video, Chris Torres, the creator of this video called Nyan Cat,' +
                ' rearranged the GIF that created the video, turned it into NFT and offered it for sale.' +
                ' Entertaining people for just a few seconds, Nyan Cat sold for a sensational $590,000 valuation.<br>' +
                'While many recent stories like this have made the concept of NFT the agenda of even people who are not related to the subject,' +
                ' the sensational side of these stories has caused NFTs to be thought of as internet bubbles.<br>' +
                'However, a big change is taking place in this area beyond sensation.' +
                ' Artists are a big part of this change.<br>' +
                '<div class="job-advertisement-heading">Excludeds</div>' +
                'Artists using digital media as a canvas pioneered the concept of NFT.' +
                ' The main reason for this was that these artists were largely excluded from the traditional art markets.' +
                ' Although these artists have recently been involved in art formations with a more innovative line, ' +
                'they have had a hard time achieving commercial success as the traditional collection value of the artworks they produce has been questioned.<br>' +
                'The NFT concept embraced these marginalized artists.' +
                ' Thanks to NFT, artists, who were able to sell their artworks through digital platforms with much more democratic structures,' +
                ' had the opportunity to reach large masses and sell in a more comfortable way.<br>' +
                '<div class="job-advertisement-heading">Intermediaries</div>' +
                'One of the most important realities of the art market is that it is largely in the hands of middlemen.' +
                ' In the sale of an artwork, brokerage commissions can reach up to 50%, depending on the identity of the artist and the characteristics of the work.' +
                ' That\'s why the artists earn much less than thry deserve.<br>' +
                'The direct selling of NFT eliminates the exorbitant commission rates that artists have to pay.' +
                ' Artists can sell the artworks they have converted to NFT directly' +
                ' or they can sell their artworks through platforms that operate at much lower commission rates compared to traditional brokerage houses.<br>' +
                '<div class="job-advertisement-heading">Ownership</div>' +
                'In the traditional sense, with the sale of the artwork of art, the ownership of the artwork produced by the artist ends.' +
                ' Although the developments in the artists\' career increase the value of the artworks previously sold,' +
                ' the artists cannot benefit from this increased value.<br>' +
                'Thanks to NFT, it is possible for the artists to receive a certain percentage from each sale of their artwork.' +
                ' In this way, they can continue to earn income from an artwork they have sold throughout their life.<br>' +
                'With the opportunities provided by NFT technology, it is possible to say that the financing of art will change in favor of the artists.' +
                ' However, it will take a long time to see the real effects of technological innovations.' +
                ' At the end of this period, we will see to what extent NFTs will democratize arts finance.<br>',
            ROUTER_LINK: {
                TEXT: 'View Artworks',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'The Most Expensive Painting by a Turk',
            IMAGE_SRC: './assets/announcements/operations/corporate.svg#corporate',
            TEXT_MOBILE: '<div class="job-advertisement-heading">The Most Expensive Painting by a Turkish Artist: Girl Reciting Qur\'an</div>' +
                '“The Tortoise Trainer” is a painting we\'re all familiar with.' +
                ' The painting "Girl Reciting Qur\'an" by Osman Hamdi Bey, found a buyer for 6 million 315 thousand pounds in an auction held at Bonhams Auction House in 2019.' +
                ' This is the highest amount paid for the work of an artist from Turkey.' +
                '<div class="job-advertisement-heading">Who is Osman Hamdi Bey?</div>' +
                'One of the most important reasons why the painting "Girl Reciting Qur\'an" found buyers at such a high price is the importance of Osman Hamdi Bey.<br>' +
                'Osman Hamdi Bey is one of the pioneers of Turkish painting.' +
                ' Osman Hamdi Bey, who was born in Istanbul in 1842, became interested in painting at a young age.' +
                ' He proved his talent for painting with the charcoal drawings he made while he was studying at the Maarif-i Adliye during his secondary school years.' +
                ' He was sent to Paris by his father for his university education.' +
                ' He stayed in Paris for 12 years.' +
                ' During this time, while he was studying law,' +
                ' he also progressed in the field of painting by working in the workshops of important painters of the period such as Léon Gérôme and Boulanger.<br>' +
                'Osman Hamdi Bey was a versatile person.' +
                ' He was interested not only in painting, but also in archeology and politics.' +
                ' He carried out the excavation of the Sidon King Cemetery in Lebanon and provided the legal groundwork in the field of archeology,' +
                ' giving priority to laying the first foundations of modern archeology in the Ottoman Empire.<br>' +
                'Osman Hamdi Bey was appointed as the director of the Müze-i Humayun, which formed the basis of the Istanbul Archaeological Museums, in 1881.' +
                ' With this appointment, which marks the beginning of a new era in Turkish museology,' +
                ' Osman Hamdi Bey made the museum one of the most important archeology museums in the world in a short time.<br>' +
                'The painter, who is also interested in politics, is also the first mayor of Kadıköy.' +
                '<div class="job-advertisement-heading">A Bridge Between East and West</div>' +
                'As you can see from his life story, Osman Hamdi Bey is a versatile name who grew up in the Ottoman\'s westernization effort.' +
                ' Osman Hamdi Bey\'s fact that he was one of the first names in the art of painting, which was largely rejected in Islamic culture,' +
                ' not only with the artworks he produced, but also with the fact that this art was accepted in the Ottoman lands, makes the artist an important name.<br>' +
                'What makes Osman Hamdi Bey attractive to westerners is that he portrays an oriental lifestyle with western painting techniques.' +
                ' With this feature, it managed to attract attention with the orientalism movement that became popular in the west during the industrial revolution.' +
                '<div class="job-advertisement-heading">Questioning Orientalism</div>' +
                'However, while western orientalists described the east as an exotic but far-flung place in a fairy-tale manner,' +
                ' Osman Hamdi Bey took a counter-stance by choosing real topics from daily life.<br>' +
                'The female characters in Osman Hamdi Bey\'s paintings also did not fit the eastern woman image in the minds of westerners.' +
                ' Osman Hamdi Bey gave place to women who are in daily life and who are open to the outside in his paintings.<br>' +
                'It is possible to say that this stance against the orientalists, contemporary of Osman Hamdi Bey, is accepted today.' +
                ' It is quite natural that Osman Hamdi Bey\'s artworks gain popularity in a different dimension in these times when the West is questioning the view of the East.<br>' +
                'The painting "Girl Reciting Qur\'an" was included in the exhibition titled "Inspiration from the East: How the Islamic World Influenced Western Art"' +
                ' jointly organized by the British Museum and the Malaysian Museum of Islamic Arts.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">The Most Expensive Painting by a Turkish Artist: Girl Reciting Qur\'an</div>' +
                '“The Tortoise Trainer” is a painting we\'re all familiar with.' +
                ' The painting "Girl Reciting Qur\'an" by Osman Hamdi Bey, found a buyer for 6 million 315 thousand pounds in an auction held at Bonhams Auction House in 2019.' +
                ' This is the highest amount paid for the work of an artist from Turkey.' +
                '<div class="job-advertisement-heading">Who is Osman Hamdi Bey?</div>' +
                'One of the most important reasons why the painting "Girl Reciting Qur\'an" found buyers at such a high price is the importance of Osman Hamdi Bey.<br>' +
                'Osman Hamdi Bey is one of the pioneers of Turkish painting.' +
                ' Osman Hamdi Bey, who was born in Istanbul in 1842, became interested in painting at a young age.' +
                ' He proved his talent for painting with the charcoal drawings he made while he was studying at the Maarif-i Adliye during his secondary school years.' +
                ' He was sent to Paris by his father for his university education.' +
                ' He stayed in Paris for 12 years.' +
                ' During this time, while he was studying law,' +
                ' he also progressed in the field of painting by working in the workshops of important painters of the period such as Léon Gérôme and Boulanger.<br>' +
                'Osman Hamdi Bey was a versatile person.' +
                ' He was interested not only in painting, but also in archeology and politics.' +
                ' He carried out the excavation of the Sidon King Cemetery in Lebanon and provided the legal groundwork in the field of archeology,' +
                ' giving priority to laying the first foundations of modern archeology in the Ottoman Empire.<br>' +
                'Osman Hamdi Bey was appointed as the director of the Müze-i Humayun, which formed the basis of the Istanbul Archaeological Museums, in 1881.' +
                ' With this appointment, which marks the beginning of a new era in Turkish museology,' +
                ' Osman Hamdi Bey made the museum one of the most important archeology museums in the world in a short time.<br>' +
                'The painter, who is also interested in politics, is also the first mayor of Kadıköy.' +
                '<div class="job-advertisement-heading">A Bridge Between East and West</div>' +
                'As you can see from his life story, Osman Hamdi Bey is a versatile name who grew up in the Ottoman\'s westernization effort.' +
                ' Osman Hamdi Bey\'s fact that he was one of the first names in the art of painting, which was largely rejected in Islamic culture,' +
                ' not only with the artworks he produced, but also with the fact that this art was accepted in the Ottoman lands, makes the artist an important name.<br>' +
                'What makes Osman Hamdi Bey attractive to westerners is that he portrays an oriental lifestyle with western painting techniques.' +
                ' With this feature, it managed to attract attention with the orientalism movement that became popular in the west during the industrial revolution.' +
                '<div class="job-advertisement-heading">Questioning Orientalism</div>' +
                'However, while western orientalists described the east as an exotic but far-flung place in a fairy-tale manner,' +
                ' Osman Hamdi Bey took a counter-stance by choosing real topics from daily life.<br>' +
                'The female characters in Osman Hamdi Bey\'s paintings also did not fit the eastern woman image in the minds of westerners.' +
                ' Osman Hamdi Bey gave place to women who are in daily life and who are open to the outside in his paintings.<br>' +
                'It is possible to say that this stance against the orientalists, contemporary of Osman Hamdi Bey, is accepted today.' +
                ' It is quite natural that Osman Hamdi Bey\'s artworks gain popularity in a different dimension in these times when the West is questioning the view of the East.<br>' +
                'The painting "Girl Reciting Qur\'an" was included in the exhibition titled "Inspiration from the East: How the Islamic World Influenced Western Art"' +
                ' jointly organized by the British Museum and the Malaysian Museum of Islamic Arts.',
            ROUTER_LINK: {
                TEXT: 'View Artworks',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Become an Investor: Guide for Newbies',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">How to Become an Investor: A Little Beginner\'s Guide for Newbies in their Thirties</div>' +
                'Turkish Language Association defines the word "investment" as follows:' +
                ' <i>“Investing money in an income-generating, movable or immovable property, deposit, placement.”</i><br>' +
                'Investing is not saving money.' +
                ' Although the accumulated money seems to increase, it loses value in the face of inflation and economic crises.' +
                ' In addition, it makes it easier for you to reach your financial goals, such as buying a house or having a comfortable retirement with the right investment.' +
                '<div class="job-advertisement-heading">Before Starting Investing</div>' +
                'In order to become an investor, you need to think about some issues first.<br><br>' +
                '<strong>Can you manage your debts?</strong><br>' +
                'Unfortunately, debt is a fact of our lives and some of us are overwhelmed by debt.' +
                ' If you are having trouble managing your debts, you are not yet fit to be an investor.' +
                ' First, focus on getting your debts to a manageable level.' +
                ' In the meantime, think about the reasons why your debts have reached this level and try not to make the same mistake.<br><br>' +
                '<strong>Do you have any money set aside for emergencies?</strong><br>' +
                'An emergency, such as getting fired or having a health issue that isn\'t covered by your insurance, can put your financial flow in trouble.' +
                ' It\'s a good idea to have some cash in a corner to deal with such situations.' +
                ' If you do not have such money, you should postpone investing for a while.' +
                '<div class="job-advertisement-heading">First Steps to Invest</div>' +
                'The thirties are perhaps the age when we learn the most about life.' +
                ' We truly gain our economic freedom at these ages.' +
                ' Therefore, we start to think about the idea of investing at this age.' +
                '<div class="job-advertisement-heading">Don\'t Change Your Lifestyle Just Because You Have Money</div>' +
                'It\'s normal for your lifestyle to change as you get older.' +
                ' But if you\'re changing your lifestyle just because you can afford something new, question this.<br>' +
                'It\'s okay if you\'re spending money on taking up a new hobby or improving your skills.' +
                ' However, there is no need to leave a quarter of your salary to luxury restaurants or to move to one of the city\'s favorite districts to be closer to social life.<br>' +
                'Regularly evaluate expenses that you have in your life but have not made in the past.' +
                ' Which of these expenses really add value to your life?' +
                ' Which ones fall into the “not really necessary” category?' +
                ' You have the answers!' +
                '<div class="job-advertisement-heading">Save Even in Small Amounts and Turn Your Savings into Investments</div>' +
                'You don\'t need a huge amount of capital to be an investor.' +
                ' You can even become an investor by throwing your pocket coins in a box.<br>' +
                'Banks have many products for young investors.' +
                ' Talk to your bank for information on these products.<br>' +
                'Another alternative is to turn to alternative investment channels.' +
                ' Artiox is one of those channels.' +
                ' As Artiox, we enable you to make art investments that involve less risk compared to other alternative investment channels, even with very low budgets.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">How to Become an Investor: A Little Beginner\'s Guide for Newbies in their Thirties</div>' +
                'Turkish Language Association defines the word "investment" as follows:' +
                ' <i>“Investing money in an income-generating, movable or immovable property, deposit, placement.”</i><br>' +
                'Investing is not saving money.' +
                ' Although the accumulated money seems to increase, it loses value in the face of inflation and economic crises.' +
                ' In addition, it makes it easier for you to reach your financial goals, such as buying a house or having a comfortable retirement with the right investment.' +
                '<div class="job-advertisement-heading">Before Starting Investing</div>' +
                'In order to become an investor, you need to think about some issues first.<br><br>' +
                '<strong>Can you manage your debts?</strong><br>' +
                'Unfortunately, debt is a fact of our lives and some of us are overwhelmed by debt.' +
                ' If you are having trouble managing your debts, you are not yet fit to be an investor.' +
                ' First, focus on getting your debts to a manageable level.' +
                ' In the meantime, think about the reasons why your debts have reached this level and try not to make the same mistake.<br><br>' +
                '<strong>Do you have any money set aside for emergencies?</strong><br>' +
                'An emergency, such as getting fired or having a health issue that isn\'t covered by your insurance, can put your financial flow in trouble.' +
                ' It\'s a good idea to have some cash in a corner to deal with such situations.' +
                ' If you do not have such money, you should postpone investing for a while.' +
                '<div class="job-advertisement-heading">First Steps to Invest</div>' +
                'The thirties are perhaps the age when we learn the most about life.' +
                ' We truly gain our economic freedom at these ages.' +
                ' Therefore, we start to think about the idea of investing at this age.' +
                '<div class="job-advertisement-heading">Don\'t Change Your Lifestyle Just Because You Have Money</div>' +
                'It\'s normal for your lifestyle to change as you get older.' +
                ' But if you\'re changing your lifestyle just because you can afford something new, question this.<br>' +
                'It\'s okay if you\'re spending money on taking up a new hobby or improving your skills.' +
                ' However, there is no need to leave a quarter of your salary to luxury restaurants or to move to one of the city\'s favorite districts to be closer to social life.<br>' +
                'Regularly evaluate expenses that you have in your life but have not made in the past.' +
                ' Which of these expenses really add value to your life?' +
                ' Which ones fall into the “not really necessary” category?' +
                ' You have the answers!' +
                '<div class="job-advertisement-heading">Save Even in Small Amounts and Turn Your Savings into Investments</div>' +
                'You don\'t need a huge amount of capital to be an investor.' +
                ' You can even become an investor by throwing your pocket coins in a box.<br>' +
                'Banks have many products for young investors.' +
                ' Talk to your bank for information on these products.<br>' +
                'Another alternative is to turn to alternative investment channels.' +
                ' Artiox is one of those channels.' +
                ' As Artiox, we enable you to make art investments that involve less risk compared to other alternative investment channels, even with very low budgets.',
            ROUTER_LINK: {
                TEXT: 'View Artworks',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'How to Convert Artwork to NFT?',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">How to Convert Physical Artwork to NFT?</div>' +
                'With the popularity of NFTs recently, the interest of artists who have difficulty in selling through traditional channels or who want to try something new has increased.' +
                ' Especially digital artists, who could not find a place for themselves in the art market, started to receive the attention they deserve thanks to NFT.<br>' +
                'However, the concept of NFT is not just a concept that applies to digital artworks.' +
                ' It is also possible to convert a physical artwork into NFT.' +
                ' Naturally, this process is a little more complex than turning a digital artwork into an NFT and requires radical decisions.' +
                '<div class="job-advertisement-heading">First Step: Digitization</div>' +
                'In order for an artifact to be converted into NFT, it must first be digitized.' +
                ' The method of Digitization a physical artwork of art is to convert the artwork into a photo, video or any digital format.<br>' +
                'We need to underline the word "conversion".' +
                ' In NFT logic, the physical artwork has to be a tool used to produce the digital artwork.' +
                ' In other words, the result should be the artwork itself, not a photograph or video of an artwork.' +
                '<div class="job-advertisement-heading">Core Value: Uniqueness</div>' +
                'There are several ways to reinforce this perception.' +
                ' The most robust of these methods, but also the most radical, is to destroy the physical artwork after the digitization process.' +
                ' In this way, the only copy of the artwork is digital.' +
                ' Considering that one of the most important variables that determine the value of an artifact is the uniqueness of the artwork,' +
                ' we conclude that it is quite logical to follow this method.' +
                ' However, it is not easy for artists to destroy the physical artwork they produces with their hands.<br>' +
                'Another method is to offer the physical artwork together with the digital artwork for sale.' +
                ' Artists who follow this method give a piece of the artwork to the person who takes the NFT.' +
                ' Thus, the NFT itself becomes physical.' +
                ' Although the uniqueness is preserved with this method, this method is little more than experimental,' +
                ' as physical NFTs are impractical to trade and the artwork still loses its physical integrity.<br>' +
                'The last method is to sell the digital and physical versions of the artwork separately.' +
                ' In this method, the artists try to create the uniqueness of the digital version by guaranteeing that they will never sell the physical version of the work.' +
                ' However, the formation of this perception depends entirely on the credibility of the artist.' +
                '<div class="job-advertisement-heading">We Offer an Alternative Method at Artiox</div>' +
                'Apart from the alternatives we have mentioned, there are methods to transform a physical artwork into NFT.' +
                ' However, almost all of these methods damage the physical integrity of the artwork or make it unable to reach its commercial potential.' +
                ' As Artiox, we leave the artwork as it is and only digitize the economic value created by this artwork.' +
                ' We keep the artifacts in safe conditions and thus ensure that the physical integrity of the artifacts is not damaged.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">How to Convert Physical Artwork to NFT?</div>' +
                'With the popularity of NFTs recently, the interest of artists who have difficulty in selling through traditional channels or who want to try something new has increased.' +
                ' Especially digital artists, who could not find a place for themselves in the art market, started to receive the attention they deserve thanks to NFT.<br>' +
                'However, the concept of NFT is not just a concept that applies to digital artworks.' +
                ' It is also possible to convert a physical artwork into NFT.' +
                ' Naturally, this process is a little more complex than turning a digital artwork into an NFT and requires radical decisions.' +
                '<div class="job-advertisement-heading">First Step: Digitization</div>' +
                'In order for an artifact to be converted into NFT, it must first be digitized.' +
                ' The method of Digitization a physical artwork is to convert the artwork into a photo, video or any digital format.<br>' +
                'We need to underline the word "conversion".' +
                ' In NFT logic, the physical artwork has to be a tool used to produce the digital artwork.' +
                ' In other words, the result should be the artwork itself, not a photograph or video of an artwork.' +
                '<div class="job-advertisement-heading">Core Value: Uniqueness</div>' +
                'There are several ways to reinforce this perception.' +
                ' The most robust of these methods, but also the most radical, is to destroy the physical artwork after the digitization process.' +
                ' In this way, the only copy of the artwork is digital.' +
                ' Considering that one of the most important variables that determine the value of an artifact is the uniqueness of the artwork,' +
                ' we conclude that it is quite logical to follow this method.' +
                ' However, it is not easy for artists to destroy the physical artwork they produces with their hands.<br>' +
                'Another method is to offer the physical artwork together with the digital artwork for sale.' +
                ' Artists who follow this method give a piece of the artwork to the person who takes the NFT.' +
                ' Thus, the NFT itself becomes physical.' +
                ' Although the uniqueness is preserved with this method, this method is little more than experimental,' +
                ' as physical NFTs are impractical to trade and the artwork still loses its physical integrity.<br>' +
                'The last method is to sell the digital and physical versions of the artwork separately.' +
                ' In this method, the artists try to create the uniqueness of the digital version by guaranteeing that they will never sell the physical version of the artwork.' +
                ' However, the formation of this perception depends entirely on the credibility of the artist.' +
                '<div class="job-advertisement-heading">We Offer an Alternative Method at Artiox</div>' +
                'Apart from the alternatives we have mentioned, there are methods to transform a physical artwork into NFT.' +
                ' However, almost all of these methods damage the physical integrity of the artwork or make it unable to reach its commercial potential.' +
                ' As Artiox, we leave the artwork as it is and only digitize the economic value created by this artwork.' +
                ' We keep the artifacts in safe conditions and thus ensure that the physical integrity of the artifacts is not damaged.',
            ROUTER_LINK: {
                TEXT: 'View Artworks',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'What Is This Blockchain?',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">What Is This Blockchain?</div>' +
                'You may have doubts about the future of Bitcoin.' +
                ' You may have even greater doubts about the future of all alternative coins to Bitcoin.' +
                ' You might think that NFTs that have become popular lately are bubbles.' +
                ' These thoughts are all natural.' +
                ' There is no definite data on how and to what extent all these technological developments will create a change.<br>' +
                'The only thing certain is: The blockchain technology behind all this is our future.' +
                '<div class="job-advertisement-heading">Let\'s Define Blockchain Concept</div>' +
                'Blockchain is essentially a digital ledger of transactions that are replicated and distributed throughout a computer network.' +
                ' Each block on the chain contains a series of transactions, and each time a new transaction occurs on the blockchain,' +
                ' a record of that transaction is added to each participant\'s ledger.' +
                '<div class="job-advertisement-heading">What Does Blockchain Do?</div>' +
                'The main reason why we define blockchains as the technology of the future is that these systems are much more secure than traditional systems.<br>' +
                'Let\'s take a bank with a central management system.' +
                ' An attack on the data management center of this bank may cause the bank customers\' accounts to be completely drained.' +
                ' Banks take precautions with security systems that have more than one layer to prevent such a situation from happening.' +
                ' However, it is theoretically possible for all of these systems to be attacked simultaneously.' +
                ' Especially if there is a staff inside the bank that supports the hackers...<br>' +
                'In blockchain technology, it is impossible for such an attack to be successful.' +
                ' Since the data is not hosted in a central location,' +
                ' thousands of computers in different locations in the system must be attacked simultaneously in order for the attack to be successful.' +
                ' Another important feature of the blockchain is that it is programmable.' +
                ' By programming a number of functions such as smart contracts, blockchain can be used in different fields.<br>' +
                'Today, especially the information infrastructures that enable the management of commercial relations can survive' +
                ' thanks to the trust in the institutions that own the infrastructure and control these infrastructures.' +
                ' In blockchain technology, the system itself is an element of trust.' +
                ' However, we need to underline that this technology is still quite young and there are still problems that prevent its widespread use in daily life.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">What Is This Blockchain?</div>' +
                'You may have doubts about the future of Bitcoin.' +
                ' You may have even greater doubts about the future of all alternative coins to Bitcoin.' +
                ' You might think that NFTs that have become popular lately are bubbles.' +
                ' These thoughts are all natural.' +
                ' There is no definite data on how and to what extent all these technological developments will create a change.<br>' +
                'The only thing certain is: The blockchain technology behind all this is our future.' +
                '<div class="job-advertisement-heading">Let\'s Define Blockchain Concept</div>' +
                'Blockchain is essentially a digital ledger of transactions that are replicated and distributed throughout a computer network.' +
                ' Each block on the chain contains a series of transactions, and each time a new transaction occurs on the blockchain,' +
                ' a record of that transaction is added to each participant\'s ledger.' +
                '<div class="job-advertisement-heading">What Does Blockchain Do?</div>' +
                'The main reason why we define blockchains as the technology of the future is that these systems are much more secure than traditional systems.<br>' +
                'Let\'s take a bank with a central management system.' +
                ' An attack on the data management center of this bank may cause the bank customers\' accounts to be completely drained.' +
                ' Banks take precautions with security systems that have more than one layer to prevent such a situation from happening.' +
                ' However, it is theoretically possible for all of these systems to be attacked simultaneously.' +
                ' Especially if there is a staff inside the bank that supports the hackers...<br>' +
                'In blockchain technology, it is impossible for such an attack to be successful.' +
                ' Since the data is not hosted in a central location,' +
                ' thousands of computers in different locations in the system must be attacked simultaneously in order for the attack to be successful.' +
                ' Another important feature of the blockchain is that it is programmable.' +
                ' By programming a number of functions such as smart contracts, blockchain can be used in different fields.<br>' +
                'Today, especially the information infrastructures that enable the management of commercial relations can survive' +
                ' thanks to the trust in the institutions that own the infrastructure and control these infrastructures.' +
                ' In blockchain technology, the system itself is an element of trust.' +
                ' However, we need to underline that this technology is still quite young and there are still problems that prevent its widespread use in daily life.',
            ROUTER_LINK: {
                TEXT: 'View Artworks',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Traditional Investment and Cryptocurrency',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Traditional Investment Tools and Cryptocurrency Markets</div>' +
                'Regardless of the economic situation, there is a phrase that we all approve of: Money is not easily earned.<br>' +
                'Therefore, we want to use our money, which we spend a lot of time and effort to earn, in the investment tool that is best for us.' +
                ' The number of investment instruments is increasing day by day and the investment environment is getting more and more confusing as the number increases.' +
                ' The recent developments in the field of financial technology have attracted the attention of people who are far from this field, unfortunately it is not very understandable.<br>' +
                'If you are one of those people, we want to light your way with this little comparative guide.<br>' +
                '<div class="job-advertisement-heading">Traditional Investment Instruments</div>' +
                'Investments such as bank interest, stocks and foreign exchange markets are traditional investment instruments.' +
                ' An important part of the society makes all their investments in these investment instruments.' +
                ' The low risk of these investment vehicles makes them popular with the general audience.<br>' +
                'However, one of the basic principles of the investment concept is that the lower the risk of losing in an investment instrument, the lower the winning rate.' +
                ' Therefore, these investment instruments are ideal for those who want their money not to be beaten by inflation and who aim to earn even a little.<br>' +
                'However, the changing global balances in recent years and the COVID-19 pandemic that came on top of this change have increased the risk in these investment instruments.' +
                ' Especially the fluctuations in the economies of developing countries and the fact that the global economy is far from a stable image' +
                ' recently cause these investment instruments to not be as safe as before.<br>' +
                '<div class="job-advertisement-heading">Cryptocurrency Markets</div>' +
                'Cryptocurrencies, which were created by a few people who can be described as "computer enthusiasts" at the beginning of the 2010s,' +
                ' have created an economy that can be considered serious today.' +
                ' Today, the total size of crypto money markets is over $ 2.5 trillion.<br>' +
                'This figure is still small compared to traditional investment instruments.' +
                ' That\'s why even Bitcoin, which has about half of the crypto market, can fluctuate in value.<br><br>' +
                'Let\'s list the advantages/disadvantages of traditional investment tools and cryptocurrencies as a short list:' +
                '<div class="job-advertisement-heading">Traditional Investment</div>' +
                '<strong>Advantages</strong><br>' +
                '<ul>' +
                '  <li>Low risk</li>' +
                '  <li>Stable return</li>' +
                '  <li>State assurance</li>' +
                '</ul>' +
                '<strong>Disadvantages</strong><br>' +
                '<ul>' +
                '  <li>Low return</li>' +
                '  <li>Fluctuations due to recent crises</li>' +
                '</ul>' +
                '<div class="job-advertisement-heading">Cryptocurrencies</div>' +
                '<strong>Advantages</strong><br>' +
                '<ul>' +
                '  <li>High return</li>' +
                '  <li>Decentralized structure</li>' +
                '</ul>' +
                '<strong>Disadvantages</strong><br>' +
                '<ul>' +
                '  <li>High risk</li>' +
                '  <li>High fluctuations</li>' +
                '  <li>Influenced by government and company policies</li>' +
                '</ul>' +
                'You should decide on the structure of your investment portfolio according to the risk you want to take.' +
                ' If your risk appetite is low, you should focus on traditional investment instruments in your portfolio; If you have a large risk appetite, you should turn to cryptocurrencies.' +
                ' But let\'s underline a rule again: The higher the profits an investment tool promises, the more risk to lose.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Traditional Investment Tools and Cryptocurrency Markets</div>' +
                'Regardless of the economic situation, there is a phrase that we all approve of: Money is not easily earned.<br>' +
                'Therefore, we want to use our money, which we spend a lot of time and effort to earn, in the investment tool that is best for us.' +
                ' The number of investment instruments is increasing day by day and the investment environment is getting more and more confusing as the number increases.' +
                ' The recent developments in the field of financial technology have attracted the attention of people who are far from this field, unfortunately it is not very understandable.<br>' +
                'If you are one of those people, we want to light your way with this little comparative guide.<br>' +
                '<div class="job-advertisement-heading">Traditional Investment Instruments</div>' +
                'Investments such as bank interest, stocks and foreign exchange markets are traditional investment instruments.' +
                ' An important part of the society makes all their investments in these investment instruments.' +
                ' The low risk of these investment vehicles makes them popular with the general audience.<br>' +
                'However, one of the basic principles of the investment concept is that the lower the risk of losing in an investment instrument, the lower the winning rate.' +
                ' Therefore, these investment instruments are ideal for those who want their money not to be beaten by inflation and who aim to earn even a little.<br>' +
                'However, the changing global balances in recent years and the COVID-19 pandemic that came on top of this change have increased the risk in these investment instruments.' +
                ' Especially the fluctuations in the economies of developing countries and the fact that the global economy is far from a stable image' +
                ' recently cause these investment instruments to not be as safe as before.<br>' +
                '<div class="job-advertisement-heading">Cryptocurrency Markets</div>' +
                'Cryptocurrencies, which were created by a few people who can be described as "computer enthusiasts" at the beginning of the 2010s,' +
                ' have created an economy that can be considered serious today.' +
                ' Today, the total size of crypto money markets is over $ 2.5 trillion.<br>' +
                'This figure is still small compared to traditional investment instruments.' +
                ' That\'s why even Bitcoin, which has about half of the crypto market, can fluctuate in value.<br><br>' +
                'Let\'s list the advantages/disadvantages of traditional investment tools and cryptocurrencies as a short list:' +
                '<div class="job-advertisement-heading">Traditional Investment</div>' +
                '<strong>Advantages</strong><br>' +
                '<ul>' +
                '  <li>Low risk</li>' +
                '  <li>Stable return</li>' +
                '  <li>State assurance</li>' +
                '</ul>' +
                '<strong>Disadvantages</strong><br>' +
                '<ul>' +
                '  <li>Low return</li>' +
                '  <li>Fluctuations due to recent crises</li>' +
                '</ul>' +
                '<div class="job-advertisement-heading">Cryptocurrencies</div>' +
                '<strong>Advantages</strong><br>' +
                '<ul>' +
                '  <li>High return</li>' +
                '  <li>Decentralized structure</li>' +
                '</ul>' +
                '<strong>Disadvantages</strong><br>' +
                '<ul>' +
                '  <li>High risk</li>' +
                '  <li>High fluctuations</li>' +
                '  <li>Influenced by government and company policies</li>' +
                '</ul>' +
                'You should decide on the structure of your investment portfolio according to the risk you want to take.' +
                ' If your risk appetite is low, you should focus on traditional investment instruments in your portfolio; If you have a large risk appetite, you should turn to cryptocurrencies.' +
                ' But let\'s underline a rule again: The higher the profits an investment tool promises, the more risk to lose.',
            ROUTER_LINK: {
                TEXT: 'View Artworks',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Short Story of Artiox',
            IMAGE_SRC: './assets/announcements/operations/corporate.svg#corporate',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Short Story of Artiox</div>' +
                'Let\'s face it, it\'s not uncommon for art and technology to stand side by side.' +
                ' Especially in Turkey. As Artiox, we believed that these two concepts could go together and set off.<br><br>' +
                '<strong>First Times</strong><br>' +
                'Artiox was founded at METU by METU students. Tuncay Dakdevir, one of our co-founders, became interested in blockchain technology in early 2017.' +
                ' Tuncay believed that the use of this technology would not be limited to Bitcoin, and that the usage areas of blockchain technology would be much wider.<br>' +
                'After proving that smart contracts work on the blockchain in 2019, Tuncay saw that this prediction was correct.<br>' +
                'Cemal Doğan\'s interest in art, one of the co-founders of Artiox, also helped define Artiox\'s field of work.' +
                ' Being aware of the volume reached by the art economy, Cemal Doğan wanted to start an initiative in the field of art economy for a while.' +
                ' However, he was having difficulty in finding an enterprise idea that would differ from the others.<br>' +
                'Tuncay Dakdevir and Cemal Doğan\'s paths crossed at a meeting on the digitalization of traditional workspaces.' +
                ' While Cemal Doğan was talking about the art economy during the meeting, a question asked by Tuncay led to the idea of Artiox:' +
                ' “Can I buy only a small piece of a painting?”<br>' +
                'Thus, the first steps have been taken for Artiox, which will safely deliver art investment to large audiences through smart contracts on the blockchain.<br><br>' +
                '<strong>Initial Investment</strong><br>' +
                'Artiox, which was established in Ankara with the pre-seed investment made by Ankara businessmen Mehmet Toprak and Murat Aksöz in May 2019,' +
                ' started its activities in this region as a METU Technopolis company in 2020.' +
                ' Afterwards, Artiox started to draw attention with the launch of its website' +
                ' and received an investment of 7 million TL in April 2021 from Keiretsu Forum Turkey investors Alesta and Sertaç Özinal.' +
                ' In a short time, the infrastructure coding was further developed and Artiox\'s smartphone applications came into play.<br><br>' +
                '<strong>Investments Continue</strong><br>' +
                'In its second investment round, Artiox received an investment of 2.5 million dollars from Ideal Financial Technologies.' +
                ' Thus, it has been proven that a business model in which the concept of art and technology stand side by side can also emerge from Turkey.<br>' +
                'Artiox aims to bring the concept of art investment to large audiences with its solid technological and financial infrastructure.<br>',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Short Story of Artiox</div>' +
                'Let\'s face it, it\'s not uncommon for art and technology to stand side by side.' +
                ' Especially in Turkey. As Artiox, we believed that these two concepts could go together and set off.<br><br>' +
                '<strong>First Times</strong><br>' +
                'Artiox was founded at METU by METU students. Tuncay Dakdevir, one of our co-founders, became interested in blockchain technology in early 2017.' +
                ' Tuncay believed that the use of this technology would not be limited to Bitcoin, and that the usage areas of blockchain technology would be much wider.<br>' +
                'After proving that smart contracts work on the blockchain in 2019, Tuncay saw that this prediction was correct.<br>' +
                'Cemal Doğan\'s interest in art, one of the co-founders of Artiox, also helped define Artiox\'s field of work.' +
                ' Being aware of the volume reached by the art economy, Cemal Doğan wanted to start an initiative in the field of art economy for a while.' +
                ' However, he was having difficulty in finding an enterprise idea that would differ from the others.<br>' +
                'Tuncay Dakdevir and Cemal Doğan\'s paths crossed at a meeting on the digitalization of traditional workspaces.' +
                ' While Cemal Doğan was talking about the art economy during the meeting, a question asked by Tuncay led to the idea of Artiox:' +
                ' “Can I buy only a small piece of a painting?”<br>' +
                'Thus, the first steps have been taken for Artiox, which will safely deliver art investment to large audiences through smart contracts on the blockchain.<br><br>' +
                '<strong>Initial Investment</strong><br>' +
                'Artiox, which was established in Ankara with the pre-seed investment made by Ankara businessmen Mehmet Toprak and Murat Aksöz in May 2019,' +
                ' started its activities in this region as a METU Technopolis company in 2020.' +
                ' Afterwards, Artiox started to draw attention with the launch of its website' +
                ' and received an investment of 7 million TL in April 2021 from Keiretsu Forum Turkey investors Alesta and Sertaç Özinal.' +
                ' In a short time, the infrastructure coding was further developed and Artiox\'s smartphone applications came into play.<br><br>' +
                '<strong>Investments Continue</strong><br>' +
                'In its second investment round, Artiox received an investment of 2.5 million dollars from Ideal Financial Technologies.' +
                ' Thus, it has been proven that a business model in which the concept of art and technology stand side by side can also emerge from Turkey.<br>' +
                'Artiox aims to bring the concept of art investment to large audiences with its solid technological and financial infrastructure.<br>',
            ROUTER_LINK: {
                TEXT: 'View Artworks',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Difference Between Traditional Investments',
            IMAGE_SRC: './assets/announcements/operations/corporate.svg#corporate',
            TEXT_MOBILE: '<div class="job-advertisement-heading">What is the Difference Between Artiox Model and Traditional Art Investments?</div>' +
                'Artiox is a new generation art investment platform that aims to make everyone an art investor, regardless of capital size.' +
                ' Artiox has a different model from the working principles of traditional art markets to achieve this goal.<br>' +
                '<strong>Ownership:</strong> Artiox reinterprets artwork ownership.' +
                ' In traditional art investments, individuals, institutions or partnerships own the artwork and therefore the commercial return,' +
                ' while in Artiox, the owner of the commercial return of the artwork is Artiox users who hold the tokens of the artwork.' +
                ' In this way, Artiox eliminates the need for a large budget to invest in art.' +
                ' Thanks to Artiox, even investors with small budgets can invest in the field of art.<br>' +
                '<strong>Speed:</strong> Commercial revenue generation from artworks in traditional markets is a complex and therefore slow process.' +
                ' Creating this value requires many parties to work together.' +
                ' Artiox, on the other hand, has a fast structure like the crypto money markets.' +
                ' In this way, Artiox investors can buy and sell whenever they want and follow long or short-term strategies.<br>' +
                '<strong>Valuation:</strong> In traditional markets, the commercial value of artworks is determined by a small number of collectors and art authorities.' +
                ' On the contrary, In Artiox, the commercial values of the artworks are first offered for sale at the price determined and insured by the experts,' +
                ' and then determined by Artiox users according to supply and demand.<br>' +
                '<strong>Protection:</strong> In order to protect the commercial value of an artwork, it is very important to ensure the safety of the artwork and not to harm its physical condition.' +
                ' Owners of artwork in traditional art investments have to keep their artworks under appropriate conditions.' +
                ' Artiox, on the other hand, insures the artworks in its system with the values determined by the experts, and stores them in warehouses designed to store the artworks.' +
                ' Artiox users do not have to bear any extra costs for the safety and physical condition of the artworks.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">What is the Difference Between Artiox Model and Traditional Art Investments?</div>' +
                'Artiox is a new generation art investment platform that aims to make everyone an art investor, regardless of capital size.' +
                ' Artiox has a different model from the working principles of traditional art markets to achieve this goal.<br>' +
                '<strong>Ownership:</strong> Artiox reinterprets artwork ownership.' +
                ' In traditional art investments, individuals, institutions or partnerships own the artwork of art and therefore the commercial return,' +
                ' while in Artiox, the owner of the commercial return of the artwork is Artiox users who hold the tokens of the artwork.' +
                ' In this way, Artiox eliminates the need for a large budget to invest in art.' +
                ' Thanks to Artiox, even investors with small budgets can invest in the field of art.<br>' +
                '<strong>Speed:</strong> Commercial revenue generation from artworks in traditional markets is a complex and therefore slow process.' +
                ' Creating this value requires many parties to work together.' +
                ' Artiox, on the other hand, has a fast structure like the crypto money markets.' +
                ' In this way, Artiox investors can buy and sell whenever they want and follow long or short-term strategies.<br>' +
                '<strong>Valuation:</strong> In traditional markets, the commercial value of artworks is determined by a small number of collectors and art authorities.' +
                ' On the contrary, In Artiox, the commercial values of the artworks are first offered for sale at the price determined and insured by the experts,' +
                ' and then determined by Artiox users according to supply and demand.<br>' +
                '<strong>Protection:</strong> In order to protect the commercial value of an artwork, it is very important to ensure the safety of the artwork and not to harm its physical condition.' +
                ' Owners of artwork in traditional art investments have to keep their artworks under appropriate conditions.' +
                ' Artiox, on the other hand, insures the artworks in its system with the values determined by the experts, and stores them in warehouses designed to store the artworks.' +
                ' Artiox users do not have to bear any extra costs for the safety and physical condition of the artworks.',
            ROUTER_LINK: {
                TEXT: 'View Artworks',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'What is Artiox?',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">What is Artiox?</div>' +
                'Artiox is a new generation art investment platform where you can invest in valuable artworks at any time, in any amount you want, and where you can easily manage these investments.<br>' +
                'Artiox aims to democratize art investment, thanks to the possibilities offered by technology.' +
                ' In this way, investors who do not have a large capital to spare for art investments can also become a part of the ever-growing art market.<br>' +
                '<div class="job-advertisement-heading">Why Art Investment, Why Artiox?</div>' +
                'Artwork investing is one of the lowest risk investment types among alternative investment instruments.' +
                ' This market, where the demand has increased much faster than the supply, has managed to survive even during the economic crisis in the past.<br>' +
                'In recent years, traditional investment tools lead the capital owners to invest in art since they do not promise high returns.' +
                ' On the other hand, the increase in the number of people with capital causes an increase in the demand for artworks and thus the price of artworks .<br>' +
                'As Artiox, we aim to simplify the art investment processes with the developments in the field of financial technology.' +
                ' In this way, we enable investors to invest in art easily, quickly and safely, regardless of their capital size.<br>' +
                '<div class="job-advertisement-heading">How Does Artiox Work?</div>' +
                'Artiox ensures that the concept of art investing reaches wide audiences thanks to the possibilities offered by technology.' +
                ' To achieve this goal, Artiox simplifies all the processes of art investing.<br><br>' +
                '<strong>Choosing the Right Artwork</strong><br>' +
                'Before investing in an artwork, it is important to determine the commercial potential of the artwork and the artist who produced it.' +
                ' Artiox includes selected artworks to its portfolio in line with the decisions of experts in their fields.<br><br>' +
                '<strong>Insurance and Storage of Artworks</strong><br>' +
                'In order to ensure the safety of the artworks within Artiox and not to lose their commercial value,' +
                ' the artworks are insured at the value determined by the experts in their fields.' +
                ' In order to preserve the physical condition of the artworks, the artworks are stored in warehouses designed to preserve them.<br><br>' +
                '<strong>Tokenization of Artworks</strong><br>' +
                'The commercial values of the artworks within Artiox are determined by the experts.' +
                ' The determined commercial value is tokenized on the blockchain.' +
                ' Thanks to smart contracts on the blockchain, tokens whose supply is fixed are offered for sale.<br><br>' +
                '<strong>Determining Token Prices</strong><br>' +
                'After you become an Artiox user, you can get the tokens of the artworks offered on the Artiox platform at the insurance price during the pre-sale and public sale processes.' +
                ' It is possible to invest as much as you want in many artworks.' +
                ' The price of the tokens of the artworks listed for trading is determined according to the supply and demand of Artiox users.<br><br>' +
                '<strong>Portfolio Management</strong><br>' +
                'At Artiox, it is very easy to manage your portfolio according to your own strategy.' +
                ' You can easily place trading orders and monitor the instant value of your portfolio on the Artiox website and mobile applications.<br>',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">What is Artiox?</div>' +
                'Artiox is a new generation art investment platform where you can invest in valuable artworks at any time, in any amount you want, and where you can easily manage these investments.<br>' +
                'Artiox aims to democratize art investment, thanks to the possibilities offered by technology.' +
                ' In this way, investors who do not have a large capital to spare for art investments can also become a part of the ever-growing art market.<br>' +
                '<div class="job-advertisement-heading">Why Art Investment, Why Artiox?</div>' +
                'Artwork investing is one of the lowest risk investment types among alternative investment instruments.' +
                ' This market, where the demand has increased much faster than the supply, has managed to survive even during the economic crisis in the past.<br>' +
                'In recent years, traditional investment tools lead the capital owners to invest in art since they do not promise high returns.' +
                ' On the other hand, the increase in the number of people with capital causes an increase in the demand for artworks and thus the price of artworks .<br>' +
                'As Artiox, we aim to simplify the art investment processes with the developments in the field of financial technology.' +
                ' In this way, we enable investors to invest in art easily, quickly and safely, regardless of their capital size.<br>' +
                '<div class="job-advertisement-heading">How Does Artiox Work?</div>' +
                'Artiox ensures that the concept of art investing reaches wide audiences thanks to the possibilities offered by technology.' +
                ' To achieve this goal, Artiox simplifies all the processes of art investing.<br><br>' +
                '<strong>Choosing the Right Artwork</strong><br>' +
                'Before investing in an artwork, it is important to determine the commercial potential of the artwork and the artist who produced it.' +
                ' Artiox includes selected artworks to its portfolio in line with the decisions of experts in their fields.<br><br>' +
                '<strong>Insurance and Storage of Artworks</strong><br>' +
                'In order to ensure the safety of the artworks within Artiox and not to lose their commercial value,' +
                ' the artworks are insured at the value determined by the experts in their fields.' +
                ' In order to preserve the physical condition of the artworks, the artworks are stored in warehouses designed to preserve them.<br><br>' +
                '<strong>Tokenization of Artworks</strong><br>' +
                'The commercial values of the artworks within Artiox are determined by the experts.' +
                ' The determined commercial value is tokenized on the blockchain.' +
                ' Thanks to smart contracts on the blockchain, tokens whose supply is fixed are offered for sale.<br><br>' +
                '<strong>Determining Token Prices</strong><br>' +
                'After you become an Artiox user, you can get the tokens of the artworks offered on the Artiox platform at the insurance price during the pre-sale and public sale processes.' +
                ' It is possible to invest as much as you want in many artworks.' +
                ' The price of the tokens of the artworks listed for trading is determined according to the supply and demand of Artiox users.<br><br>' +
                '<strong>Portfolio Management</strong><br>' +
                'At Artiox, it is very easy to manage your portfolio according to your own strategy.' +
                ' You can easily place trading orders and monitor the instant value of your portfolio on the Artiox website and mobile applications.<br>',
            ROUTER_LINK: {
                TEXT: 'View Artworks',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Investment Round',
            IMAGE_SRC: './assets/announcements/operations/corporate.svg#corporate',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Valuation of $2.5 Million from Ideal Financial Technologies and Consulting JSC.</div>' +
                'After the investment we have received in last March at a valuation of TL 7 million,' +
                ' we have signed a brand new investment that has increased our value by 3 times in a short time with Ideal Financial Technologies and Consulting JSC.' +
                ' Alesta and Sertaç Özinal also continued to invest in the investment that we received from Ideal Financial Technologies and Consulting JSC with a valuation of <strong>$2.5 million.</strong><br><br>' +
                'First of all, we are pleased that we have received a quick and rising new investment.' +
                ' It is very flattering for us to be considered valuable by proven institutional investors.' +
                ' With the new investment, we will expand our team in the coming process and will offer you more alternatives for your portfolio by quickly increasing the number of works listed on our platform.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Valuation of $2.5 Million from Ideal Financial Technologies and Consulting JSC.</div>' +
                'After the investment we have received in last March at a valuation of TL 7 million,' +
                ' we have signed a brand new investment that has increased our value by 3 times in a short time with Ideal Financial Technologies and Consulting JSC.' +
                ' Alesta and Sertaç Özinal also continued to invest in the investment that we received from Ideal Financial Technologies and Consulting JSC with a valuation of <strong>$2.5 million.</strong><br><br>' +
                'First of all, we are pleased that we have received a quick and rising new investment.' +
                ' It is very flattering for us to be considered valuable by proven institutional investors.' +
                ' With the new investment, we will expand our team in the coming process and will offer you more alternatives for your portfolio by quickly increasing the number of works listed on our platform.',
            ROUTER_LINK: {
                TEXT: 'Read the news',
                LINK: {
                    url: 'https://egirisim.com/2021/09/21/sanat-yatirim-platformu-artiox-2-5-milyon-dolar-degerleme-uzerinden-yatirim-aldi/',
                    is_inner: false,
                    target: '_blank'
                },
                HREF: 'https://egirisim.com/2021/09/21/sanat-yatirim-platformu-artiox-2-5-milyon-dolar-degerleme-uzerinden-yatirim-aldi/',
                INNER: false,
                TARGET: '_blank'
            },
            TEXT_LIMIT_DESKTOP: 852,
            TEXT_LIMIT_MOBILE: 852,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Partnership with Art.Ist Sauna',
            IMAGE_SRC: './assets/announcements/operations/corporate.svg#corporate',
            TEXT_MOBILE:
                '<div class="job-advertisement-heading">Partnership with Art.Ist Sauna</div>' +
                'We are pleased to share the collaboration with <a class="clarification-text-black" href="https://www.artistsauna.com?utm_source=artiox" target="_blank">Art.Ist Sauna</a>,' +
                ' operating in UniqExpo, Turkey\'s largest art and event space on a single floor, which includes many disciplines of art such as painting,' +
                ' sculpture, photography, video art, caricature, illustration, street art, performance, cinema.' +
                ' We aim to bring a new breath to art investments by breaking new ground in this field with an art bundle containing the works of many artists,' +
                ' the details of which we will share soon as part of our cooperation.' +
                ' In addition, with this collaboration, young and independent artists who want to list their works on Artiox will have the opportunity to exhibit their works at Art.Ist Sauna.<br>' +
                '<div class="job-advertisement-heading">What is Art.Ist Sauna?</div>' +
                'Art.Ist Sauna; is an art initiative that includes many disciplines of art such as painting, sculpture, photography, video art, caricature,' +
                ' illustration, street art, performance and cinema, and was established at UniqExpo, Turkey\'s largest art and event space on a single floor.' +
                ' The biggest feature of this giant exhibition area is; It is open and free for 365 days to all visitors who are interested and curious about art.<br>' +
                'The biggest aim of this initiative is to offer the art audience a place where they can spend the whole day with their family and friends, work and feel fun, educational and comfortable.<br>' +
                'Established to change the perception that artwork is expensive and inaccessible, Art.Ist Sauna provides an area of 1500 m<sup>2</sup> and increases the interaction between young artists and art lovers,' +
                ' as well as providing freedom of time and place in terms of access.<br>' +
                'For the art collector, the most important privilege is to have the opportunity to get to know the artists closely and from A to Z and to have a pleasant and sincere purchasing experience. <br>' +
                'In order to serve the arts, Art.Ist Sauna provides young and independent artists from all disciplines with the opportunity to freely exhibit their works and express themselves, and to reach more art lovers.' +
                ' Thus, Art.Ist Sauna expands the art market in Turkey and turns its concept into a marketplace.<br>' +
                '<br>' +
                'Don\'t forget to follow our social media accounts to be informed about Art.Ist Sauna and the details of this collaboration, which we are very happy to announce!<br>',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Partnership with Art.Ist Sauna</div>' +
                'We are pleased to share the collaboration with <a class="clarification-text-black" href="https://www.artistsauna.com?utm_source=artiox" target="_blank">Art.Ist Sauna</a>,' +
                ' operating in UniqExpo, Turkey\'s largest art and event space on a single floor, which includes many disciplines of art such as painting,' +
                ' sculpture, photography, video art, caricature, illustration, street art, performance, cinema.' +
                ' We aim to bring a new breath to art investments by breaking new ground in this field with an art bundle containing the works of many artists,' +
                ' the details of which we will share soon as part of our cooperation.' +
                ' In addition, with this collaboration, young and independent artists who want to list their works on Artiox will have the opportunity to exhibit their works at Art.Ist Sauna.<br>' +
                '<div class="job-advertisement-heading">What is Art.Ist Sauna?</div>' +
                'Art.Ist Sauna; is an art initiative that includes many disciplines of art such as painting, sculpture, photography, video art, caricature,' +
                ' illustration, street art, performance and cinema, and was established at UniqExpo, Turkey\'s largest art and event space on a single floor.' +
                ' The biggest feature of this giant exhibition area is; It is open and free for 365 days to all visitors who are interested and curious about art.<br>' +
                'The biggest aim of this initiative is to offer the art audience a place where they can spend the whole day with their family and friends, work and feel fun, educational and comfortable.<br>' +
                'Established to change the perception that artwork is expensive and inaccessible, Art.Ist Sauna provides an area of 1500 m<sup>2</sup> and increases the interaction between young artists and art lovers,' +
                ' as well as providing freedom of time and place in terms of access.<br>' +
                'For the art collector, the most important privilege is to have the opportunity to get to know the artists closely and from A to Z and to have a pleasant and sincere purchasing experience. <br>' +
                'In order to serve the arts, Art.Ist Sauna provides young and independent artists from all disciplines with the opportunity to freely exhibit their works and express themselves, and to reach more art lovers.' +
                ' Thus, Art.Ist Sauna expands the art market in Turkey and turns its concept into a marketplace.<br>' +
                '<br>' +
                'Don\'t forget to follow our social media accounts to be informed about Art.Ist Sauna and the details of this collaboration, which we are very happy to announce!<br>',
            ROUTER_LINK: {
                TEXT: 'Visit Art.Ist Sauna',
                LINK: {url: 'https://www.artistsauna.com?utm_source=artiox', is_inner: false, target: '_blank'},
                HREF: 'https://www.artistsauna.com?utm_source=artiox',
                INNER: false,
                TARGET: '_blank'
            },
            TEXT_LIMIT_DESKTOP: 784,
            TEXT_LIMIT_MOBILE: 784,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Turkish Lira Deposit Process',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Turkish Lira Deposit Process</div>' +
                'In order to start the deposit process, you must first raise your account level to \'Level 1\'.' +
                ' You can examine in detail how authentication processes are done from the Help Center section at the bottom of the main page.' +
                ' In addition, you can deposit money only from your bank accounts serving in Turkey.' +
                ' After logging into your Artiox account to start the deposit process,' +
                ' you can go to the deposit page by clicking the deposit button from the list on the left side, then to the user panel.' +
                ' Reaching this point, select the bank you want to deposit money from and after reading the instructions,' +
                ' you can make your deposits with the account information opened below and the description code specially created for you. ' +
                ' You can follow these steps in more detail from the images below.<br>' +
                '<div class="job-advertisement-heading">Rules To Be Followed When Depositing Turkish Lira</div>' +
                '<ol>' +
                '  <li>The bank account you will use for depositing money must belong to you. Otherwise, your transfer will not be approved.</li>' +
                '  <li>Transfers from credit cards and ATMs will not be approved.</li>' +
                '  <li>Please do not forget to write \'Artiox Teknoloji Yazılım A.Ş.\' as the reciever </li>' +
                '  <li>Before transfer, make sure that you enter the correct IBAN number of our company.</li>' +
                '  <li>Please do not forget to enter the code we have created specifically for you in the description section when transferring. And do not add any comments other than this code.</li>' +
                '  <p style="font-style: italic">Note: Transfers made by money order are reflected to your account 24/7, while transfers made via EFT can only be reflected in your account during working hours.</p>' +
                '</ol>',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Turkish Lira Deposit Process</div>' +
                'In order to start the deposit process, you must first raise your account level to \'Level 1\'.<br>' +
                ' You can examine in detail how authentication processes are done from the Help Center section at the bottom of the main page.<br>' +
                ' In addition, you can deposit money only from your bank accounts serving in Turkey.<br>' +
                ' After logging into your Artiox account to start the deposit process,' +
                ' you can go to the deposit page by clicking the deposit button from the list on the left side, then to the user panel.<br>' +
                ' Reaching this point, select the bank you want to deposit money from and after reading the instructions,' +
                ' you can make your deposits with the account information opened below and the description code specially created for you.<br>' +
                ' You can follow these steps in more detail from the images below.<br>' +
                '<div class="job-advertisement-heading">Rules To Be Followed When Depositing Turkish Lira</div>' +
                '<ol>' +
                '  <li>The bank account you will use for depositing money must belong to you. Otherwise, your transfer will not be approved.</li>' +
                '  <li>Transfers from credit cards and ATMs will not be approved.</li>' +
                '  <li>Please do not forget to write \'Artiox Teknoloji Yazılım A.Ş.\' as the reciever </li>' +
                '  <li>Before transfer, make sure that you enter the correct IBAN number of our company.</li>' +
                '  <li>Please do not forget to enter the code we have created specifically for you in the description section when transferring. And do not add any comments other than this code.</li>' +
                '  <p style="font-style: italic">Note: Transfers made by money order are reflected to your account 24/7, while transfers made via EFT can only be reflected in your account during working hours.</p>' +
                '</ol>',
            ROUTER_LINK: {
                TEXT: 'Hemen Yatırın',
                LINK: {url: '/account/deposit', current_component_index: 0},
                HREF: '/account/deposit',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 308,
            TEXT_LIMIT_MOBILE: 301,
            TOGGLE: false,
            RELEASE_DATE: 0,
        }
    ]
};
