<!--<div class="w-80 mx-auto pt-4">-->
<!--  <div class="w-100 d-flex flex-row">-->
<!--    <div>-->
<!--      <svg style="height: 52px; width: 52px;">-->
<!--        <use [attr.xlink:href]='"./assets/images/career.svg#career"' fill="var(&#45;&#45;artiox-blue)"/>-->
<!--      </svg>-->
<!--    </div>-->
<!--    <div class="pl-3">-->
<!--      <div class="pb-1" style="font-family: Roboto, sans-serif; font-size: 20px; color: var(&#45;&#45;artiox-blue)">-->
<!--        {{managerService.config.CORPORATE.CAREER.HEADING | managerPipe}}-->
<!--      </div>-->
<!--      <div [innerHTML]="managerService.config.CORPORATE.CAREER.DESCRIPTION | managerPipe" class=""-->
<!--           style="font-family: Roboto, sans-serif; font-size: 13px; color: #707070;">-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="pb-3" style="width: 810px">-->
<!--    <div class="mt-4 mb-2 px-5 pt-3 pb-2"-->
<!--         style="font-family: Roboto, sans-serif; font-size: 18px; font-weight: 500; letter-spacing: 0.55px; border-radius: 4px; box-shadow: 1px 1px 2px 0 #dbdbdf; color: #595e7e;">-->
<!--      {{managerService.config.CORPORATE.CAREER.CURRENT_JOBS | managerPipe}}-->
<!--    </div>-->

<!--    <div *ngFor="let job of managerService.config.CORPORATE.CAREER.JOB_ADVERTISEMENTS | managerPipe" class="my-3">-->
<!--      <div (click)="details.classList.toggle('d-none')"-->
<!--           class="position-relative d-flex flex-row justify-content-between align-items-center whitish-hover"-->
<!--           style="font-family: Roboto, sans-serif; border-radius: 4px; box-shadow: 1px 1px 1px 1px #dbdbdf;">-->
<!--        <div class="px-5 py-4" style="font-size: 16px; color: #9da5b7;">{{job.HEADING| managerPipe}}</div>-->
<!--        <div class="px-5 py-4" style="font-size: 16px; color: var(&#45;&#45;artiox-blue); cursor: pointer">{{managerService.config.CORPORATE.CAREER.INSPECT| managerPipe}}</div>-->
<!--      </div>-->
<!--      <div #details-->
<!--           class="d-none px-5 pb-3" style="border-radius: 4px; box-shadow: 1px 1px 2px 0 #dbdbdf; margin-top: -72px; padding-top: 72px;">-->
<!--        <div class="job-advertisement-heading pt-2">{{job.GENERAL_HEADING | managerPipe}}</div>-->
<!--        <div class="job-advertisement-description">{{job.GENERAL | managerPipe}}</div>-->
<!--        <div class="job-advertisement-heading">{{job.RESPONSIBILITIES_HEADING | managerPipe}}</div>-->
<!--        <ul class="job-advertisement-description">-->
<!--          <li *ngFor="let qualification of job.RESPONSIBILITIES | managerPipe">{{qualification}}</li>-->
<!--        </ul>-->
<!--        <div class="job-advertisement-heading">{{job.QUALIFICATIONS_HEADING | managerPipe}}</div>-->
<!--        <ul class="job-advertisement-description">-->
<!--          <li *ngFor="let qualification of job.QUALIFICATIONS | managerPipe">{{qualification}}</li>-->
<!--        </ul>-->
<!--        <div class="job-advertisement-heading">{{job.ADVANTAGEOUS_HEADING | managerPipe}}</div>-->
<!--        <ul class="job-advertisement-description">-->
<!--          <li *ngFor="let advantageous of job.ADVANTAGEOUS | managerPipe">{{advantageous}}</li>-->
<!--        </ul>-->
<!--        <div class="job-advertisement-heading">{{job.ADVANTAGES_HEADING | managerPipe}}</div>-->
<!--        <ul class="job-advertisement-description">-->
<!--          <li *ngFor="let advantage of job.ADVANTAGES | managerPipe">{{advantage}}</li>-->
<!--        </ul>-->
<!--        <div class="job-advertisement-description">{{job.WORKING_HOURS | managerPipe}}</div>-->
<!--        <div class="job-advertisement-description">{{job.LUNCH | managerPipe}}</div>-->
<!--        <a class="apply-now-button mx-auto mt-4" href="mailto:info@artiox.com">-->
<!--          {{managerService.config.CORPORATE.CAREER.APPLY_NOW | managerPipe}}-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->


<div class="w-80 mx-auto pb-4" [style]="managerService.show_sign_up_or_login | managerPipe: managerService.mpa_template.GET_MIN_BODY_HEIGHT_OBSOLETE">
  <div *ngIf="managerService.config.ZENDESK_ACTIVE | managerPipe" class="px-4" style=" border-radius: 4px; box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22);  background-color: #fcfdff;">
    <div class="pt-5 pb-3" style="font-family: bebas-neue-bold, sans-serif; font-size: 39px; letter-spacing: 4px; color: #404352;">
      {{managerService.config.CORPORATE.CAREER.HEADING | managerPipe: managerService.mpa_template.TO_LOCAL_UPPER_CASE}}
    </div>
  </div>

  <div class="d-flex flex-row align-items-center py-4">
    <div>
      <svg style="height: 42px; width: 42px;">
        <use [attr.xlink:href]='"./assets/images/career.svg#career"' fill="var(--artiox-blue)"/>
      </svg>
    </div>
    <div [innerHTML]="managerService.config.CORPORATE.CAREER.DESCRIPTION | managerPipe" class="pl-3"
         style="font-family: Roboto, sans-serif; font-size: 13px; color: #707070;">
    </div>
  </div>

  <div class="d-flex flex-row pb-5">
    <div class="w-33">
      <div (click)="active_career_id = i;"
           *ngFor="let job of managerService.config.CORPORATE.CAREER.JOB_ADVERTISEMENTS | managerPipe; let i = index"
           [style]="{'background-color': (active_career_id === i ? '#eceef7' : '#fcfdff') | managerPipe}"
           class="my-3 whitish-hover"
           style="font-family: Roboto, sans-serif; border-radius: 4px; box-shadow: 1px 1px 1px 1px #dbdbdf;">
        <div class="ps-5 pe-3 py-4" style="font-size: 16px; color: #9da5b7;">{{job.HEADING| managerPipe}}</div>
      </div>
    </div>
    <div class="w-50">
      <div *ngFor="let job of managerService.config.CORPORATE.CAREER.JOB_ADVERTISEMENTS | managerPipe; let i = index"
           [class.d-none]="active_career_id !== i | managerPipe"
           class="px-5">
        <div class="pt-3" style="font-family: Roboto,sans-serif;font-size: 18px;font-weight: 500;letter-spacing: 0.9px;color: var(--artiox-blue)">
          {{job.HEADING | managerPipe}}
        </div>
        <div class="job-advertisement-heading pt-2">{{job.GENERAL_HEADING | managerPipe}}</div>
        <div class="job-advertisement-description">{{job.GENERAL | managerPipe}}</div>
        <div class="job-advertisement-heading">{{job.RESPONSIBILITIES_HEADING | managerPipe}}</div>
        <ul class="job-advertisement-description">
          <li *ngFor="let qualification of job.RESPONSIBILITIES | managerPipe">{{qualification}}</li>
        </ul>
        <div class="job-advertisement-heading">{{job.QUALIFICATIONS_HEADING | managerPipe}}</div>
        <ul class="job-advertisement-description">
          <li *ngFor="let qualification of job.QUALIFICATIONS | managerPipe">{{qualification}}</li>
        </ul>
        <div class="job-advertisement-heading">{{job.ADVANTAGEOUS_HEADING | managerPipe}}</div>
        <ul class="job-advertisement-description">
          <li *ngFor="let advantageous of job.ADVANTAGEOUS | managerPipe">{{advantageous}}</li>
        </ul>
        <div class="job-advertisement-heading">{{job.ADVANTAGES_HEADING | managerPipe}}</div>
        <ul class="job-advertisement-description">
          <li *ngFor="let advantage of job.ADVANTAGES | managerPipe">{{advantage}}</li>
        </ul>
        <div class="job-advertisement-description">{{job.WORKING_HOURS | managerPipe}}</div>
        <div class="job-advertisement-description">{{job.LUNCH | managerPipe}}</div>
        <a class="apply-now-button mx-auto mt-4" href="mailto:career@artiox.com">
          {{managerService.config.CORPORATE.CAREER.APPLY_NOW | managerPipe}}
        </a>
      </div>
    </div>
  </div>
</div>
