import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../services/manager.service';

export enum LegalRoutes {
    USER_AGREEMENT = 'user-agreement',
    PROTECTION_LAW_POLICY = 'protection-law-policy',
    INFORMATION_SECURITY_POLICY = 'information-security-policy',
    DATA_DELETION_POLICY = 'data-deletion-policy',
    KVKK_APPLICATION_FORM = 'kvkk-application-form',
    COOKIE_CLARIFICATION_TEXT = 'cookie-clarification-text',
    KVKK_CLARIFICATION = 'kvkk-clarification',
    MARKETING_CONSENT = 'marketing-consent',
    FOREIGN_CONSENT = 'foreign-consent',
    DISTANCE_SELLING = 'distance-selling-contract',
    AUCTION_CANCELLATION = 'auction-cancellation-and-refund-rules',
    AUCTION_USER_AGREEMENT = 'auction-user-agreement',
    AUCTION_RULES = 'auction-rules',
    COMMISSIONS = 'commissions',
    LIMITS = 'limits'
}
@Component({
    selector: 'app-legal',
    templateUrl: './legal.component.html',
    styleUrls: ['./legal.component.css']
})
export class LegalComponent implements OnInit {

    constructor(public managerService: ManagerService) {
    }

    ngOnInit(): void {
    }

}
