import {announcements_config_en} from './announcements_config_en';
import {announcements_titles} from './announcements_router';
import {art_products_config_en} from './art_products_config_en';
import {auction_config_en} from './auction_config_en';
import {artists_config_en} from './artists_config_en';

export const config_en = {
    ZENDESK_ACTIVE: announcements_titles.zendesk_active,
    COOKIE: {
        HEADER: 'Cookie Policy',
        BODY: 'To ensure that you can make the most benefit of our website, we use cookies according to Turkish Personal Data Protection Law no. 6698(KVKK)' +
            ' and General Data Protection Regulation(GDPR).' +
            ' It is only mandatory cookies for user safety and our website to work and be used properly.' +
            ' For detailed information, you can read <a class="cookie-clarification-text" href="./assets/pdfs/Artiox Çerez Aydınlatma Metni.pdf" target="_blank">Cookie Clarification Text</a>.' +
            ' If you don\'t accept cookies, you must end your visit.',
        BODY_SHORT: 'We only use mandatory cookies for user safety and our website to work and be used properly.' +
            ' For detailed information, you can read <a class="cookie-clarification-text" href="./assets/pdfs/Artiox Çerez Aydınlatma Metni.pdf" target="_blank">Cookie Clarification Text</a>.' +
            ' If you don\'t accept cookies, you must end your visit.',
        ACCEPT: 'Accept',
        DENY: 'Deny'
    },
    HEADER: {
        SIGNUP: 'Sign Up',
        LOGIN: 'Log In',
        LOGIN_SHORT: 'Login',
        EXCHANGE: 'Fractional Exchange',
        BASIC: 'Basic',
        ADVANCED: 'Advanced',
        BASIC_TEXT: 'Easily buy and sell any artwork you want',
        ADVANCED_TEXT: 'Trade in depth with the advanced exchange interface',
        CATALOG_TEXT: 'Bid on artworks in catalogs',
        DAILY_TEXT: 'Bid on artworks in daily auctions',
        ACADEMY: 'Academy',
        AUCTION: 'Auction',
        CATALOG: 'Catalog',
        DAILY: 'Daily',
        MENU: 'Menu',
        ADMIN: 'Admin',
        DASHBOARD: 'Dashboard',
        USER_INFORMATION: 'User Information',
        USER_INFORMATION_SHORT: 'User Info',
        WALLET: 'Balance',
        DEPOSIT: 'Deposit',
        WITHDRAW: 'Withdraw',
        SECURITY: 'Security',
        ARTIOX: 'Artiox',
        ARTIOX_LINK: {url: '/guidebook', current_component_index: 0},
        ANNOUNCEMENTS: 'Announcements',
        ANNOUNCEMENTS_LINK: {
            url: 'https://support.artiox.com/hc/tr/categories/4414300043025-Duyurular',
            is_inner: false,
            target: '_blank'
        },
        BLOG: 'Artiox Blog',
        BLOG_LINK: {
            url: 'https://support.artiox.com/hc/tr/categories/4414328818577-Artiox-Blog',
            is_inner: false,
            target: '_blank'
        },
        ABOUT_ARTISTS: 'Abt. Artists',
        ABOUT_ARTISTS_LINK: {url: '/about-artist', current_component_index: 0},
        SUPPORT: 'Support',
        SUPPORT_LINK: {url: 'https://support.artiox.com/hc/tr', is_inner: false, target: '_blank'},
        LOGOUT: 'Log Out',
        LANGUAGES: [
            {SHORT: 'TR', FULL_NAME: 'Turkish'},
            {SHORT: 'EN', FULL_NAME: 'English'}
        ],
        ACCOUNT_LEVEL: 'Account Level',
        GUIDEBOOK: 'Guidebook',
        ANNOUNCEMENT: {
            TEXT: 'Özdemir Altan’s  <span class="exo subtitle1 bold"> “Soyağacı” </span>  eseri 3 Ağustos’ta Genel Satışta. Dont misss  🖼️',
            LINK: {url: '/exchange/advanced', current_component_index: 0}
        },
        // CHANGE_LANGUAGE: 'Language',
        // VERSION: 'Version ',
        OR: ' or ',
        TO_INSTALL_APP: 'Continue with the application',
        LATER: 'Later',
        INSTALL: 'Open Application'
    },
    FOOTER: {
        // ADDRESS_HEADER: 'ARTIOX',
        ADDRESS: 'Artiox Kripto Varlık Alım Satım Platformu A.Ş. Mustafa Kemal Mahallesi Bilişim İnovasyon Merkezi ODTÜ Teknokent 280, D:G, 06510 Çankaya/Ankara',
        // MAIL: 'info@artiox.com',
        // ARTIOX: 'Artiox',
        // ARTIOX_LINK: {url: 'https://support.artiox.com/hc/tr/categories/4414391792401-Artiox-Rehberi', is_inner: false, target: '_blank'},
        // INFO_BANK: 'Info Bank',
        // INFO_BANK_LINK: {url: 'https://support.artiox.com/hc/tr', is_inner: false, target: '_blank'},
        LEGAL: {
            HEADING: 'Legal',
            LINKS : [
                {TEXT: 'User Agreement', URL: {url: 'https://www.artiox.com/legal/user-agreement', is_inner: false, target: '_blank'}},
                {TEXT: 'Protection Law Policy', URL: {url: 'https://www.artiox.com/legal/protection-law-policy', is_inner: false, target: '_blank'}},
                {TEXT: 'Information Security Policy', URL: {url: 'https://www.artiox.com/legal/information-security-policy', is_inner: false, target: '_blank'}},
                {TEXT: 'Data Deletion Policy', URL: {url: 'https://www.artiox.com/legal/data-deletion-policy', is_inner: false, target: '_blank'}},
                {TEXT: 'Kvkk Application Form', URL: {url: 'https://www.artiox.com/legal/kvkk-application-form', is_inner: false, target: '_blank'}},
                {TEXT: 'Cookie Clarification Text', URL: {url: 'https://www.artiox.com/legal/cookie-clarification-text', is_inner: false, target: '_blank'}},
                {TEXT: 'Kvkk Clarification', URL: {url: 'https://www.artiox.com/legal/kvkk-clarification', is_inner: false, target: '_blank'}},
                {TEXT: 'Marketing Consent', URL: {url: 'https://www.artiox.com/legal/marketing-consent', is_inner: false, target: '_blank'}},
                {TEXT: 'Foreign Consent', URL: {url: 'https://www.artiox.com/legal/foreign-consent', is_inner: false, target: '_blank'}},

                {TEXT: 'Commissions', URL: {url: 'https://www.artiox.com/legal/commissions', is_inner: false, target: '_blank'}},
                {TEXT: 'Limits', URL: {url: 'https://www.artiox.com/legal/limits', is_inner: false, target: '_blank'}},
            ],
        },
        ARTIOX_SECTION: {
            HEADER: 'Artiox',
            BASIC: 'Basic Exchange',
            ADVANCED: 'Advanced Exchange',
            CATALOG: 'Catalog Auction',
            DAILY: 'Daily Auction',
            SEND_ARTWORK: 'Send Artwork',
        },
        ARTISTS: {
            HEADER: 'Artists',
            ALL_ARTISTS: 'All artists...',
        },
        AUCTION: {
            HEADER: 'Auction',
            DISTANCE_SELLING: 'Distance Sales Agreement',
            AUCTION_CANCELLATION: 'Auction Cancellation and Refund Rules',
            AUCTION_USER_AGREEMENT: 'Auction User Agreement',
            AUCTION_RULES: 'Auction Rules',
        },
        // LEGAL_LINK: {url: 'https://support.artiox.com/hc/tr/categories/4414300039953-Yasal', is_inner: false, target: '_blank'},
        // ANNOUNCEMENTS: 'Announcements',
        // ANNOUNCEMENTS_LINK: {url: 'https://support.artiox.com/hc/tr/categories/4414300043025-Duyurular', is_inner: false, target: '_blank'},
        // ABOUT_ARTISTS: 'Abt. Artists',
        // ABOUT_ARTISTS_LINK: {url: '/about-artist', current_component_index: 0},
        // CAREER: 'Career',
        // CAREER_LINK: {url: '/career', current_component_index: 0},
        // CORPORATE_HEADER: 'Corporate',  // obsolete
        // CORPORATE_BODY: [
        //     {TEXT: 'About Us', LINK: {url: '/corporate/about', current_component_index: 0}, HREF: '/corporate/about', INNER: true, TARGET: '_blank'},
        //     {
        //         TEXT: 'Announcements',
        //         LINK: {url: '/corporate/announcements', current_component_index: 0},
        //         HREF: '/corporate/announcements',
        //         INNER: true,
        //         TARGET: '_blank'
        //     },
        //     {TEXT: 'Career', LINK: {url: '/corporate/career', current_component_index: 0}, HREF: '/corporate/career', INNER: true, TARGET: '_blank'},
        //     {TEXT: 'Contact Us', LINK: {url: '/corporate/contact', current_component_index: 0}, HREF: '/corporate/contact', INNER: true, TARGET: '_blank'},
        // ],
        // LEGAL_HEADER: 'Legal',
        // LEGAL_BODY: [
        //     '<a href="./assets/pdfs/Artiox Kullanıcı Sözleşmesi.pdf" target="_blank">User Agreement</a>',
        //     '<a href="./assets/pdfs/Artiox KVKK Politikası.pdf" target="_blank">Personal Data Protection Law Policy</a>',
        //     '<a href="./assets/pdfs/Artiox Bilgi Güvenliği Politikası.pdf" target="_blank">Information Security Policy</a>',
        //     '<a href="./assets/pdfs/Artiox Kişisel Veri Saklama ve İmha Politikası.pdf" target="_blank">Data Deletion Policy</a>',
        //     '<a href="./assets/pdfs/Artiox Kişisel Veri Sahibi Başvuru Formu.pdf" target="_blank">KVKK Application Form</a>',
        //     '<a href="./assets/pdfs/Artiox Çerez Aydınlatma Metni.pdf" target="_blank">Cookie Clarification Text</a>',
        // ],
        // SERVICE_HEADER: 'Service',
        // SERVICE_BODY: [
        //     {TEXT: 'Limits and Rules', LINK: {url: '/service/rules', current_component_index: 0}, HREF: '/service/rules', INNER: true, TARGET: '_blank'},
        //     {TEXT: 'Commissions', LINK: {url: '/service/commissions', current_component_index: 0}, HREF: '/service/commissions', INNER: true, TARGET: '_blank'},
        // ],
        // SUPPORT_HEADER: 'Help',
        // SUPPORT_BODY: [
        //     '<a href="https://support.artiox.com/hc/tr" target="_blank" rel="noreferrer">Help Center</a>',
        //     '<a href="https://support.artiox.com/hc/tr" target="_blank" rel="noreferrer">F.A.Q.</a>',
        // ],
        RIGHTS: '© current_year Artiox Technology Software Corporation. All Rights Reserved.',
        VERSION: 'Version: 2.0.2 Last Update: 11.11.2024',
        // RIGHTS_MOBILE: '© 2019 - current_year Artiox.com.<br> All Rights Reserved.<br><small>Version: 2.0.0<br>Last Update: 12.10.2023</div>',
    },
    ANNOUNCEMENTS_SLIDER: [
        // {
        //   HEADING: 'BLOG',
        //   TOPIC: 'Osman Hamdi Bey Hk.',
        //   TEXT: 'Osman Hamdi Bey Osman Hamdi Bey Osman Hamdi Bey',
        //   IMAGE_SRC: './assets/artists/test_artist_3.jpg',
        //   TEXT_LIMIT_DESKTOP: 30,
        //   TEXT_LIMIT_MOBILE: 30,
        //   LINK: {url: '/about-artist', artist_announcement_index: 12, current_component_index: 0},
        //   RELEASE_DATE: 0,
        // },
        // {
        //   HEADING: 'BLOG',
        //   TOPIC: 'Gustav Klimt Hk.',
        //   TEXT: 'Gustav Klimt Gustav Klimt Gustav Klimt Gustav Klimt',
        //   IMAGE_SRC: './assets/artists/test_artist_2.jpg',
        //   TEXT_LIMIT_DESKTOP: 30,
        //   TEXT_LIMIT_MOBILE: 30,
        //   LINK: {url: '/about-artist', artist_announcement_index: 11, current_component_index: 0},
        //   RELEASE_DATE: 0,
        // },
        // {
        //   HEADING: 'BLOG',
        //   TOPIC: 'Vincent Van Gogh Hk.',
        //   TEXT: 'Vincent Van Gogh Vincent Van Gogh Vincent Van Gogh',
        //   IMAGE_SRC: './assets/artists/test_artist_1.jpg',
        //   TEXT_LIMIT_DESKTOP: 30,
        //   TEXT_LIMIT_MOBILE: 30,
        //   LINK: {url: '/about-artist', artist_announcement_index: 10, current_component_index: 0},
        //   RELEASE_DATE: 0,
        // },
        {
            HEADING: 'BLOG',
            TOPIC: 'Abt. Ergin İnan',
            TEXT: 'Ergin İnan is a painter who has participated in many internationally recognized exhibitions and was awarded an award.' +
                ' The artist, who has an unconventional approach in contemporary Turkish art, has made conceptual studies using mixed media and printing techniques in his works.' +
                ' Abstracted human figures, writing images and insect depictions can be given as examples to the images that are common in Ergin İnan\'s works.',
            IMAGE_SRC: './assets/artists/ergin_inan.webp',
            TEXT_LIMIT_DESKTOP: 79,
            TEXT_LIMIT_MOBILE: 79,
            LINK: {url: '/about-artist', artist_announcement_index: 7, current_component_index: 0},
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Abt. Ahmet Yeşil',
            TEXT: 'Ahmet Yeşil was born in Mersin in 1954 and grew up in this city.' +
                ' He received his artistic education from talented artists such as Painter Nuri Abaç, İlhan Çevik, and Sculptor Ernür Tüzün.',
            IMAGE_SRC: './assets/artists/ahmet_yesil.webp',
            TEXT_LIMIT_DESKTOP: 89,
            TEXT_LIMIT_MOBILE: 89,
            LINK: {url: '/about-artist', artist_announcement_index: 17, current_component_index: 0},
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Abt. Özdemir Altan',
            TEXT: 'Özdemir Altan, born in Giresun in 1931, graduated from Istanbul State Academy of Fine Arts in 1956.' +
                ' Following his graduation in 1956, he began working as an academician at the same school and continued in this position until his retirement in 1988.' +
                ' As a prominent artist, he pioneered avant-garde thinking, Pop art, and postmodernism in Turkey.',
            IMAGE_SRC: './assets/artists/ozdemir_altan.webp',
            TEXT_LIMIT_DESKTOP: 77,
            TEXT_LIMIT_MOBILE: 77,
            LINK: {url: '/about-artist', artist_announcement_index: 16, current_component_index: 0},
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Abt. Devrim Erbil',
            TEXT: 'Devrim Erbil is a painter and academic who has participated in many national and international exhibitions and has many awards.' +
                'He has produced works with many mediums such as oil painting, printing, stained glass and mosaic.' +
                'Devrim Erbil\'s art can be evaluated within the local trends that want to place contemporary art on a foundation connected with tradition.',
            IMAGE_SRC: './assets/artists/devrim_erbil.webp',
            TEXT_LIMIT_DESKTOP: 67,
            TEXT_LIMIT_MOBILE: 67,
            LINK: {url: '/about-artist', artist_announcement_index: 4, current_component_index: 0},
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Abt. Mustafa Ata',
            TEXT: 'Mustafa Ata, born in Trabzon on March 18, 1945,' +
                ' studied at the Adnan Çoker Studio of the Painting Department of Mimar Sinan Fine Arts Faculty (then Istanbul State Academy of Fine Arts).',
            IMAGE_SRC: './assets/artists/mustafa_ata.webp',
            TEXT_LIMIT_DESKTOP: 81,
            TEXT_LIMIT_MOBILE: 81,
            LINK: {url: '/about-artist', artist_announcement_index: 15, current_component_index: 0},
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Could The Mona Lisa Owe It\'s World\'s Most Famous Painting To An Italian Criminal?',
            TEXT: 'Leonardo da Vinci\'s Mona Lisa, also known as La Gioconda, is the most famous painting in the world.',
            IMAGE_SRC: './assets/general_operations/en/34.webp',
            TEXT_LIMIT_DESKTOP: 19,
            TEXT_LIMIT_MOBILE: 19,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/8727098544157-Mona-Lisa-D%C3%BCnyan%C4%B1n-En-%C3%9Cnl%C3%BC-Tablosu-Olmas%C4%B1n%C4%B1-%C4%B0talyan-Bir-Su%C3%A7luya-Bor%C3%A7lu-Olabilir-mi-',
                    is_inner: false,
                    target: '_blank'
                }
                : {url: '/corporate/general-announcements', current_component_index: 0},
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Abt. Nuri İyem',
            TEXT: 'Inspired by the eyes and face of his older sister, whom he witnessed as a child,' +
                ' artist Nuri İyem is a painter of \'shy, beautiful and melancholic\' faces that have become an iconic and integral part of his art.',
            IMAGE_SRC: './assets/artists/nuri_iyem.webp',
            TEXT_LIMIT_DESKTOP: 79,
            TEXT_LIMIT_MOBILE: 79,
            LINK: {url: '/about-artist', artist_announcement_index: 14, current_component_index: 0},
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Abt. Ömer Uluç',
            TEXT: 'Born in 1931, Ömer Uluç is a world-renowned Turkish painter.' +
                ' He opened more than 30 personal exhibitions in different countries and impressed the whole world with the works he presented in the organizations he participated in.<br>' +
                'Uluç, who stands out with the value he added to Turkish art, studied engineering in the United States after graduating from Robert College in 1953.',
            IMAGE_SRC: './assets/artists/omer_uluc.webp',
            TEXT_LIMIT_DESKTOP: 80,
            TEXT_LIMIT_MOBILE: 80,
            LINK: {url: '/about-artist', artist_announcement_index: 13, current_component_index: 0},
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'ANNOUNCEMENT',
            TOPIC: 'Investment Round',
            TEXT: '“Artiox”, founded by Tuncay Dakdevir and Cemal Doğan in 2019, received an investment of 1 Million Dollars in an evaluation of 15 Million Dollars.',
            IMAGE_SRC: './assets/general_operations/en/33.webp',
            TEXT_LIMIT_DESKTOP: 60,
            TEXT_LIMIT_MOBILE: 60,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/7203761619613-Hedef-GSYO-ve-RePie',
                    is_inner: false,
                    target: '_blank'
                }
                : {url: '/corporate/general-announcements', current_component_index: 0},
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Abt. Haluk Akakçe',
            TEXT: 'Haluk Akakçe, one of the most prominent names in Turkish contemprary art. Extraordinary, creative and genius…',
            IMAGE_SRC: './assets/artists/haluk_akakce.webp',
            TEXT_LIMIT_DESKTOP: 73,
            TEXT_LIMIT_MOBILE: 73,
            LINK: {url: '/about-artist', artist_announcement_index: 12, current_component_index: 0},
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Abt. Fahrelnissa Zeid',
            TEXT: 'Born in Büyükada on January 7, 1901, painter Fahrelnissa Zeid is from the Kabaağaçlı family, which has trained many artists.' +
                ' Although the family has a military background, members of the Kabaağaçlı family have written their names in history in different branches of art.' +
                ' Famous painter Fahrelnissa Zeid, sister of Halikarnas Balıkçısı Cevat Şakir and painter Aliye Berger, mother of actress Şirin Devrim and painter Nejad Devrim,' +
                ' and aunt of Turkey’s first female ceramic artist Füreya Koral, is a talented Turkish woman who made her name known to the whole world with her art and character.',
            IMAGE_SRC: './assets/artists/fahrelnissa_zeid.webp',
            TEXT_LIMIT_DESKTOP: 84,
            TEXT_LIMIT_MOBILE: 84,
            LINK: {url: '/about-artist', artist_announcement_index: 11, current_component_index: 0},
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Story of Leda Atomica',
            TEXT: 'According to classical mythology, Zeus is in love with Leda, the daughter of the king of Aetolia, but Leda will marry Tyndareus, the king of Sparta.' +
                'Zeus is making plans to be with the beautiful Leda.',
            IMAGE_SRC: './assets/general_operations/en/31.webp',
            TEXT_LIMIT_DESKTOP: 76,
            TEXT_LIMIT_MOBILE: 76,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/5930360872209-Atomsal-Leda-n%C4%B1n-Hikayesi',
                    is_inner: false,
                    target: '_blank'
                }
                : {url: '/corporate/general-announcements', current_component_index: 0},
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Why Did Van Gogh Cut His Ear?',
            TEXT: 'Famous Dutch painter Vincent Van Gogh cut off his left ear with a sharp razor blade in Arles, France, in December 1888 at the age of 35.',
            IMAGE_SRC: './assets/general_operations/en/30.webp',
            TEXT_LIMIT_DESKTOP: 45,
            TEXT_LIMIT_MOBILE: 45,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/5461294836753-Van-Gogh-Kula%C4%9F%C4%B1n%C4%B1-Neden-Kesti-',
                    is_inner: false,
                    target: '_blank'
                }
                : {url: '/corporate/general-announcements', current_component_index: 0},
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'The Most Famous Antiwar Painting: Guernica',
            TEXT: 'On April 26, 1937, the Nazi and Italian forces started to bomb the Spanish town of Guernica with their new planes.' +
                ' This attack was a cold-blooded training mission designed to test a new bombing tactic and was the first airborne bombardment in history against a civilian population.',
            IMAGE_SRC: './assets/general_operations/en/29.webp',
            TEXT_LIMIT_DESKTOP: 46,
            TEXT_LIMIT_MOBILE: 46,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/5082128068753-En-%C3%BCnl%C3%BC-sava%C5%9F-kar%C5%9F%C4%B1t%C4%B1-tablo-olan-Guernica-nin-hikayesini-biliyor-musunuz-',
                    is_inner: false, target: '_blank'
                }
                : {url: '/corporate/general-announcements', current_component_index: 0},
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Abt. Pablo Picasso',
            TEXT: 'Pablo Picasso, one of the greatest and most influential artists of the 20th century, is born in Malaga, Spain.' +
                'Picasso’s father was a professor of drawing, and he bred his son for a career in academic art.' +
                ' Picasso had his first exhibit at age 13 and later quit art school so he could experiment full-time with modern art styles.',
            IMAGE_SRC: './assets/artists/pablo_picasso.webp',
            TEXT_LIMIT_DESKTOP: 75,
            TEXT_LIMIT_MOBILE: 75,
            LINK: {url: '/about-artist', artist_announcement_index: 9, current_component_index: 0},
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Artwork Increased Its Value 35 Times',
            TEXT: 'We all know Andy Warhol\'s "Four Marilyns" painting.' +
                ' This painting, which is one of the most well-known works of the pop art movement, has appeared many times throughout our lives.',
            IMAGE_SRC: './assets/general_operations/en/28.webp',
            TEXT_LIMIT_DESKTOP: 65,
            TEXT_LIMIT_MOBILE: 65,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4420356336529-De%C4%9Ferini-Zaman-%C4%B0%C3%A7inde-20-Kat-Art%C4%B1ran-Andy-Warhol-Eseri-Four-Marilyns',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('artwork_increased_its_value_20_times'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'NFT Artwork Sales Reach $3.5 Billion',
            TEXT: 'The economy created by NFTs, seen as a bubble by conservative art circles, reached $3.5 billion in 2021.',
            IMAGE_SRC: './assets/general_operations/en/27.webp',
            TEXT_LIMIT_DESKTOP: 73,
            TEXT_LIMIT_MOBILE: 73,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414481722513-NFT-Sanat-Eseri-Sat%C4%B1%C5%9Flar%C4%B1-3-5-Milyar-Dolara-Ula%C5%9Ft%C4%B1',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('nft_artwork_sales_reach_3.5_billion'),
                    current_component_index: 0
                },
            RELEASE_DATE: 1640073600000,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'What is Impressionism?',
            TEXT: 'In 1874, a group of painters whose artworks were not deemed worthy to be exhibited by the Paris Salon decided to organize their own exhibition.' +
                ' This exhibition, attended by Claude Monet, Berthe Morisot, Camille Pissarro, Pierre August Renoir and Edgar Degas, heralded the birth of impressionism.',
            IMAGE_SRC: './assets/general_operations/en/26.webp',
            TEXT_LIMIT_DESKTOP: 66,
            TEXT_LIMIT_MOBILE: 66,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414489445649-Empresyonizm-Nedir-En-Pahal%C4%B1-Empresyonist-Tablolar',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('what_is_impressionism'),
                    current_component_index: 0
                },
            RELEASE_DATE: 1639897200000,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Highlights of the Art Market in 2021',
            TEXT: '2021 has been a year in the COVID-19 game, just like 2020.' +
                ' Uncertainty prevailed in the art industry, just as in other industries, in this year when investors had difficulties in producing sound predictions.',
            IMAGE_SRC: './assets/general_operations/en/25.webp',
            TEXT_LIMIT_DESKTOP: 80,
            TEXT_LIMIT_MOBILE: 80,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414481625873-2021-Y%C4%B1l%C4%B1-Sanat-Pazar%C4%B1nda-%C3%96ne-%C3%87%C4%B1kan-Veriler',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('highlights_of_the_art_market_in_2021'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Who Invested in Artiox?',
            TEXT: 'We talked about Artiox\'s story in our previous articles.' +
                ' Now we will talk about the investors who made Artiox what it is today.',
            IMAGE_SRC: './assets/general_operations/en/24.webp',
            TEXT_LIMIT_DESKTOP: 93,
            TEXT_LIMIT_MOBILE: 93,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/sections/4414300225041-Yat%C4%B1r%C4%B1mc%C4%B1lar%C4%B1m%C4%B1z',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('who_invested_in_artiox'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: '5 Trends That Will Shape The Art Market',
            TEXT: 'It is not an easy task to predict about the future. But in the last two years, it has become even more difficult to make future predictions due to COVID-19.' +
                ' However, we cannot move forward in waters full of obscurity in the early days.',
            IMAGE_SRC: './assets/general_operations/en/23.webp',
            TEXT_LIMIT_DESKTOP: 77,
            TEXT_LIMIT_MOBILE: 77,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414496774417-Sanat-Piyasas%C4%B1na-Y%C3%B6n-Verecek-5-Trend',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('5_trends_that_will_shape_the_art_market'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Does Technology Make the Art World More Democratic?',
            TEXT: 'Art exists for all of us. However, it is also true that a large part of the art world is in the hands of an economically powerful elite.' +
                ' Access to art by large masses means that the ownership of artworks is freed from the control of a certain group and art is democratized.',
            IMAGE_SRC: './assets/general_operations/en/22.webp',
            TEXT_LIMIT_DESKTOP: 55,
            TEXT_LIMIT_MOBILE: 55,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414489313553-Teknoloji-Sanat-D%C3%BCnyas%C4%B1n%C4%B1-Daha-Demokratik-Hale-Getirir-mi-',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('technology_art_world_and_democracy'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'How Do We Choose Artworks to List?',
            TEXT: 'As Artiox, we enable large audiences to easily invest in art, even with low budgets, thanks to technology.' +
                ' However, art is a multi-layered investment area.',
            IMAGE_SRC: './assets/general_operations/en/21.webp',
            TEXT_LIMIT_DESKTOP: 83,
            TEXT_LIMIT_MOBILE: 83,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414488967441-Artiox-ta-Listelenecek-Sanat-Eserlerini-Nas%C4%B1l-Se%C3%A7iyoruz-',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('how_do_we_choose_artworks_to_list'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'The Value of Frida Kahlo Self-Portrait',
            TEXT: 'In September 1925, a bus in Mexico City crashed into a doctoral candidate who was going to school with her boyfriend.' +
                ' It was not yet known that this unfortunate accident would lead to the birth of one of the most important artists of the 20th century.',
            IMAGE_SRC: './assets/general_operations/en/20.webp',
            TEXT_LIMIT_DESKTOP: 73,
            TEXT_LIMIT_MOBILE: 73,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414481114001-Frida-Kahlo-nun-Otoportresinin-De%C4%9Feri',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('the_value_of_frida_kahlo_selfportrait'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'The 8 Best Books for Art Lovers',
            TEXT: 'The new year is approaching. You want to buy gifts for your loved ones, but you do not know what to buy.' +
                ' If the people you are considering to buy a gifts are art lovers, you don\'t have to worry.',
            IMAGE_SRC: './assets/general_operations/en/19.webp',
            TEXT_LIMIT_DESKTOP: 70,
            TEXT_LIMIT_MOBILE: 70,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414481072017-Sanatsever-Dostlar%C4%B1n%C4%B1za-Y%C4%B1lba%C5%9F%C4%B1-Hediyesi-Olarak-Alabilece%C4%9Finiz-En-%C4%B0yi-8-Kitap'
                    , is_inner: false, target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('the_8_best_books_for_art_lovers'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'The Value of Pablo Picasso Artworks',
            TEXT: '\'Some painters transform the sun into a yellow spot. Others transform a yellow spot into the sun.\'' +
                'said Pablo Picasso.',
            IMAGE_SRC: './assets/general_operations/en/18.webp',
            TEXT_LIMIT_DESKTOP: 69,
            TEXT_LIMIT_MOBILE: 69,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414488849681-Pablo-Picasso-Eserlerinin-De%C4%9Feri',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('the_value_of_pablo_picasso_artworks'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Demand for Artworks',
            TEXT: 'The news about the astronomical figures paid for artworks interests us all.' +
                ' Competition against each other in auctions organized by world-famous auction houses,' +
                ' wealthy collectors invest billions of dollars in artworks every year.',
            IMAGE_SRC: './assets/general_operations/en/17.webp',
            TEXT_LIMIT_DESKTOP: 67,
            TEXT_LIMIT_MOBILE: 67,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414477224337-Son-Y%C4%B1llarda-Sanat-Eserlerine-Olan-Talep-Neden-Artt%C4%B1-',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('demand_for_artworks'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Could NFT Be the Future of the Art World?',
            TEXT: 'Throughout history, there has been an indispensable relationship between art and the concept of money.' +
                ' The wealthy, who embraced art and artists, financed the production of artworks and traded them,' +
                ' sometimes in order to strengthen their image and sometimes to make a profit from their interest in art.',
            IMAGE_SRC: './assets/general_operations/en/16.webp',
            TEXT_LIMIT_DESKTOP: 64,
            TEXT_LIMIT_MOBILE: 64,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414480921489-NFT-Sanat-D%C3%BCnyas%C4%B1n%C4%B1n-Gelece%C4%9Fi-Olabilir-mi-',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('nft_future_of_the_art_world'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Art Market, Pandemic and After',
            TEXT: 'The global art market, like any other industry, has been affected by the COVID-19 pandemic.' +
                ' In 2020, there was a decrease of approximately 30% in the total transaction volume in the market compared to 2019.',
            IMAGE_SRC: './assets/general_operations/en/15.webp',
            TEXT_LIMIT_DESKTOP: 65,
            TEXT_LIMIT_MOBILE: 65,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414480898321-Sanat-Pazar%C4%B1-Pandemi-ve-Sonras%C4%B1',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('art_market_pandemic_and_after'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'NFTs vs Artworks\' Tokens of Artiox',
            TEXT: 'Even people who are not interested in cryptocurrencies or the art markets are talking about NFTs lately.' +
                ' The biggest reason for this is the rapid spread of sensational news about NFTs on social media.',
            IMAGE_SRC: './assets/general_operations/en/14.webp',
            TEXT_LIMIT_DESKTOP: 73,
            TEXT_LIMIT_MOBILE: 73,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414419408401-Artiox-taki-Tokenler-ile-NFT-lerin-Fark%C4%B1',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('nfts_vs_artworks_tokens_of_artiox'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Art Investing for Novices',
            TEXT: 'Almost all of us are familiar with conventional investment instruments such as the stock market or currency markets.' +
                ' Naturally, we invest a large part of our savings in these investment instruments.',
            IMAGE_SRC: './assets/general_operations/en/13.webp',
            TEXT_LIMIT_DESKTOP: 70,
            TEXT_LIMIT_MOBILE: 70,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414419269905-Acemiler-%C4%B0%C3%A7in-Sanat-Yat%C4%B1r%C4%B1mc%C4%B1l%C4%B1%C4%9F%C4%B1',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('art_investing_for_novices'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Commercial Value of an Artwork',
            TEXT: 'On November 15, 2017, Christie\'s, one of the most important auction houses in the world, was hosting a very important auction in New York.' +
                ' Leonardo\'s "Salvator Mundi" painting was put up for sale at the auction.',
            IMAGE_SRC: './assets/general_operations/en/12.webp',
            TEXT_LIMIT_DESKTOP: 67,
            TEXT_LIMIT_MOBILE: 67,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414418818705-Sanat-Eserinin-Ticari-De%C4%9Feri-Nas%C4%B1l-Belirlenir-',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('commercial_value_of_an_artwork'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'The Concept of NFT for Artists',
            TEXT: 'In April 2011, an animation of a cat with a rainbow coming out of its tail was uploaded to YouTube.' +
                ' This video, with a Japanese pop song playing in the background, became inexplicably popular, making it the 5th most-watched video on YouTube of 2011.',
            IMAGE_SRC: './assets/general_operations/en/11.webp',
            TEXT_LIMIT_DESKTOP: 74,
            TEXT_LIMIT_MOBILE: 74,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414431676817-Sanat%C3%A7%C4%B1lar-%C4%B0%C3%A7in-NFT-Kavram%C4%B1',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('the_concept_of_nft_for_artists'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'The Most Expensive Painting by a Turkish',
            TEXT: '“The Tortoise Trainer” is a painting we\'re all familiar with.' +
                ' The painting "Girl Reciting Qur\'an" by Osman Hamdi Bey, found a buyer for 6 million 315 thousand pounds in an auction held at Bonhams Auction House in 2019.',
            IMAGE_SRC: './assets/general_operations/en/10.webp',
            TEXT_LIMIT_DESKTOP: 74,
            TEXT_LIMIT_MOBILE: 74,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414418391057-Bir-T%C3%BCrk-Sanat%C3%A7%C4%B1ya-Ait-En-Pahal%C4%B1-Tablo',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('the_most_expensive_painting_by_a_turk'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Become an Investor: Guide for Newbies',
            TEXT: 'Turkish Language Association defines the word "investment" as follows:' +
                ' “Investing money in an income-generating, movable or immovable property, deposit, placement.”',
            IMAGE_SRC: './assets/general_operations/en/9.webp',
            TEXT_LIMIT_DESKTOP: 81,
            TEXT_LIMIT_MOBILE: 81,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414431498385-Nas%C4%B1l-Yat%C4%B1r%C4%B1mc%C4%B1-Olunur-Ba%C5%9Flang%C4%B1%C3%A7-Rehberi',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('become_an_investor_guide_for_newbies'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'How to Convert Artwork to NFT?',
            TEXT: 'With the popularity of NFTs recently, the interest of artists who have difficulty in selling through traditional channels or who want to try something new has increased.',
            IMAGE_SRC: './assets/general_operations/en/8.webp',
            TEXT_LIMIT_DESKTOP: 81,
            TEXT_LIMIT_MOBILE: 81,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414418213905-Fiziksel-Sanat-Eseri-Nas%C4%B1l-NFT-ye-D%C3%B6n%C3%BC%C5%9Ft%C3%BCr%C3%BCl%C3%BCr-',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('how_to_convert_artwork_to_nft'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'What Is This Blockchain?',
            TEXT: 'You may have doubts about the future of Bitcoin.' +
                ' You may have even greater doubts about the future of all alternative coins to Bitcoin.',
            IMAGE_SRC: './assets/general_operations/en/7.webp',
            TEXT_LIMIT_DESKTOP: 74,
            TEXT_LIMIT_MOBILE: 74,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414431297425-Nedir-Bu-Blockchain-',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('what_is_this_blockchain'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Traditional Investment and Cryptocurrency',
            TEXT: 'Regardless of the economic situation, there is a phrase that we all approve of: Money is not easily earned.' +
                'Therefore, we want to use our money, which we spend a lot of time and effort to earn, in the investment tool that is best for us.',
            IMAGE_SRC: './assets/general_operations/en/6.webp',
            TEXT_LIMIT_DESKTOP: 75,
            TEXT_LIMIT_MOBILE: 75,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414418048913-Geleneksel-Yat%C4%B1r%C4%B1m-ve-Kripto-Para',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('traditional_investment_and_cryptocurrency'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Short Story of Artiox',
            TEXT: 'Let\'s face it, it\'s not uncommon for art and technology to stand side by side.' +
                ' Especially in Turkey. As Artiox, we believed that these two concepts could go together and set off.',
            IMAGE_SRC: './assets/general_operations/en/5.webp',
            TEXT_LIMIT_DESKTOP: 77,
            TEXT_LIMIT_MOBILE: 77,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414417569937-Hikayemiz',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('short_story_of_artiox'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'F.A.Q.',
            TOPIC: 'Difference Between Traditional Investments',
            TEXT: 'Artiox is a new generation art investment platform that aims to make everyone an art investor, regardless of capital size.' +
                ' Artiox has a different model from the working principles of traditional art markets to achieve this goal.',
            IMAGE_SRC: './assets/general_operations/en/4.webp',
            TEXT_LIMIT_DESKTOP: 68,
            TEXT_LIMIT_MOBILE: 68,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414309587601-Geleneksel-Sanat-Yat%C4%B1r%C4%B1m%C4%B1-ile-Fark%C4%B1m%C4%B1z',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('difference_between_traditional_investments'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'F.A.Q.',
            TOPIC: 'What is Artiox?',
            TEXT: 'Artiox is a new generation art investment platform where you can invest in valuable works of art at any time, in any amount you want, and where you can easily manage these investments.' +
                'Artiox aims to democratize art investment, thanks to the possibilities offered by technology.',
            IMAGE_SRC: './assets/general_operations/en/3.webp',
            TEXT_LIMIT_DESKTOP: 71,
            TEXT_LIMIT_MOBILE: 71,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414430588433-Artiox-Nedir-',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('what_is_artiox'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Abt. Adnan Çoker',
            TEXT: 'Adnan Çoker, who has an important place in 20th century Turkish art, is best known for creating an original aesthetic style by transforming Byzantine, Seljuk and Ottoman architectural forms into pictorial contours.' +
                ' The artist, who produced works in this line after the 1970s, works in his paintings, which he describes as pattern-form, in an abstract spatial dimension on black.' +
                ' The harmony created by the motif of the pointed arched door and window opening the interior space of the monumental architecture to the outside world, is based on a molded form in the words of the artist.',
            IMAGE_SRC: './assets/artists/adnan_coker.webp',
            TEXT_LIMIT_DESKTOP: 76,
            TEXT_LIMIT_MOBILE: 76,
            LINK: {url: '/about-artist', artist_announcement_index: 8, current_component_index: 0},
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'ANNOUNCEMENT',
            TOPIC: 'Investment Round',
            TEXT: 'After the investment we have received in last March at a valuation of TL 7 million,' +
                ' we have signed a brand new investment that has increased our value by 3 times in a short time with Ideal Financial Technologies and Consulting JSC.',
            IMAGE_SRC: './assets/general_operations/en/2.webp',
            TEXT_LIMIT_DESKTOP: 66,
            TEXT_LIMIT_MOBILE: 66,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414430968081-%C4%B0deal-Finansal-Teknolojiler-A-%C5%9E',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('investment_round'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Abt. Sauna Emerging 1 Bundle',
            TEXT: 'The “Sauna Emerging 1” bundle, created within the framework of the collaboration of Artiox and Art.Ist Sauna, consists of the works of 11 young and independent artists.' +
                ' While investing in the artworks produced by promising and carefully selected artists, you will also support them with this bundle.' +
                ' As a result of a long and detailed study, the selection committee formed by Art.Ist Sauna determined the names to be included in the bundle' +
                ' and focused on selecting the artists based on their past performances and producing works consistently.',
            IMAGE_SRC: './assets/artists/Art_Ist_Sauna.webp',
            TEXT_LIMIT_DESKTOP: 80,
            TEXT_LIMIT_MOBILE: 80,
            LINK: {url: '/about-artist', artist_announcement_index: 6, current_component_index: 0},
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'ANNOUNCEMENT',
            TOPIC: 'New Partnership',
            TEXT: 'We are pleased to share the collaboration with Art.Ist Sauna, operating in UniqExpo,' +
                ' Turkey\'s largest art and event space on a single floor, which includes many disciplines of art such as painting, sculpture,' +
                ' photography, video art, caricature, illustration, street art, performance, cinema.',
            IMAGE_SRC: './assets/general_operations/en/1.webp',
            TEXT_LIMIT_DESKTOP: 74,
            TEXT_LIMIT_MOBILE: 74,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414417604241-Art-Ist-Sauna-ile-%C4%B0%C5%9F-Birli%C4%9Fi',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('partnership_with_art.ist_sauna'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'ANNOUNCEMENT',
            TOPIC: 'TL Deposit Process',
            TEXT: 'To start deposit process, you can use user panel located at the top right.' +
                ' Click the deposit button in the list that opens.' +
                ' Choose your preferred bank from the options to deposit.',
            IMAGE_SRC: './assets/general_operations/en/0.webp',
            TEXT_LIMIT_DESKTOP: 67,
            TEXT_LIMIT_MOBILE: 67,
            LINK: announcements_titles.zendesk_active
                ? {
                    url: 'https://support.artiox.com/hc/tr/articles/4414682372113-T%C3%BCrk-Liras%C4%B1-Yat%C4%B1rma-%C4%B0%C5%9Flemi',
                    is_inner: false,
                    target: '_blank'
                }
                : {
                    url: '/corporate/general-announcements', ...announcements_titles.getAnnouncementIndex('turkish_lira_deposit_process'),
                    current_component_index: 0
                },
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Abt. Nuri Abaç',
            TEXT: 'Nuri Abaç, who relates his paintings with traditions, daily life and Anatolian legends, and presents them with a unique decorative surrealism,' +
                ' became one of the important painters of 20th century Turkish art.' +
                ' The prominent feature of his style is that he adopts an approach with Karagöz-Hacivat aesthetic and often includes musical elements.',
            IMAGE_SRC: './assets/artists/nuri_abaç.webp',
            TEXT_LIMIT_DESKTOP: 68,
            TEXT_LIMIT_MOBILE: 68,
            LINK: {url: '/about-artist', artist_announcement_index: 5, current_component_index: 0},
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Abt. Adnan Turani',
            TEXT: 'Adnan Turani is a key figure of art and art academies of Turkey and one of the the most important representatives of abstract-oriented painting in Turkey.' +
                ' Turani showed an abstract understanding in his early works and used figures and nature recently,' +
                ' but his approach to paintings continued to be based on an abstract fiction and the expression of form.',
            IMAGE_SRC: './assets/artists/adnan_turani.webp',
            TEXT_LIMIT_DESKTOP: 71,
            TEXT_LIMIT_MOBILE: 71,
            LINK: {url: '/about-artist', artist_announcement_index: 3, current_component_index: 0},
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Abt. Ahmet Güneştekin',
            TEXT: 'Ahmet Güneştekin is known as one of the most important figures of the art in the 21st century in Turkey.' +
                ' He produces works in the fields of painting, conceptual art and sculpture.' +
                ' This self-taught artist uses geographical elements and Anatolian, Greek and Mesopotamian legends in his works.' +
                ' Standing out with traditional and contemporary references, Güneştekin abandoned figurative work in the early 2000s' +
                ' and adopted an abstract style that focuses on narrative.',
            IMAGE_SRC: './assets/artists/ahmet_güneştekin.webp',
            TEXT_LIMIT_DESKTOP: 69,
            TEXT_LIMIT_MOBILE: 69,
            LINK: {url: '/about-artist', artist_announcement_index: 2, current_component_index: 0},
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Abt. Nejad Melih Devrim',
            TEXT: 'Nejad Melih Devrim is among the important painters of his time with his works in the field of contemporary art.' +
                ' Influenced by geographical and cultural elements, the artist followed a path alternating between figurative and abstract.' +
                ' It has an important place in Turkish art, especially with its late period abstract paintings.' +
                ' He takes place in the lyrical abstract and partially geometric abstract,' +
                ' which is the European counterpart of the movement known as Abstract Expressionism in America.',
            IMAGE_SRC: './assets/artists/nejad_melih_devrim.webp',
            TEXT_LIMIT_DESKTOP: 67,
            TEXT_LIMIT_MOBILE: 67,
            LINK: {url: '/about-artist', artist_announcement_index: 1, current_component_index: 0},
            RELEASE_DATE: 0,
        },
        {
            HEADING: 'BLOG',
            TOPIC: 'Abt. Yalçın Gökçebağ',
            TEXT: 'Yalçın Gökçebağ  is known as “Anadolu Ressamı”, “Anadolu Düşlerinin Ressamı” and “Resmin Yaşar Kemal\'i”.' +
                ' He reflects Anatolia with its own attitude with its wide perspective, rhythmic layout, detail, use of brushes and colors.' +
                ' He is generally known for his landscape compositions that he fictionalized with wide viewpoints such as Hasat and Bodrum series.',
            IMAGE_SRC: './assets/artists/yalçın_gökçebağ.webp',
            TEXT_LIMIT_DESKTOP: 67,
            TEXT_LIMIT_MOBILE: 67,
            LINK: {url: '/about-artist', artist_announcement_index: 0, current_component_index: 0},
            RELEASE_DATE: 0,
        },
    ],
    ANNOUNCEMENT_POPUP: {
        HEADING: 'IMPORTANT ANNOUNCEMENT',
        BODY: 'All the tokens belonging to Devrim Erbil - "Süleymaniye\'den İstanbul\'a" ($IST) were quickly exhausted.' +
            ' The announced listing date before the sale was brought forward due to the intense interest shown.' +
            '<br><br>' +
            ' New listing date: <strong>15 April 2021, 14.00 </strong><br><br>' +
            ' Thank you for your interest. ',
        SRC: './assets/animations/move_to_amazon.webm',
        SRC_MP4: './assets/animations/move_to_amazon.mp4'
    },
    LOGIN: {
        WELCOME_TO_ARTIOX: 'Welcome to Artiox!',
        SUBTITLE: 'Log in to Artiox using your email address and password.',
        LOGIN: 'Sign In',
        E_MAIL: 'Email',
        E_MAIL_PLACEHOLDER: 'example@artiox.com',
        PASSWORD: 'Password',
        PASSWORD_PLACEHOLDER: '********',
        AUTH_TITLE: 'Two Factor Authentication',
        AUTH_PLACEHOLDER: '******',
        AUTH_SUBTITLE: 'Log in to Artiox by entering the authentication code.',
        AUTH: 'Authentication Code',
        PASTE: 'Paste',
        FORGOT_PASSWORD: 'Forgot My Password',
        SUBMIT: 'Submit',
        CAPTCHA_HEADER: 'Please complete the puzzle.',
        CAPTCHA_BODY: 'Drag the arrow.',
        NO_ACCOUNT: 'If you haven\'t already, ',
        CREATE_NEW_ACCOUNT: 'Create New Account!',
        ART_INVESTMENT: 'Easiest Way to Invest in Art',
    },
    FORGOT_PASSWORD: {
        HEADING: 'Password Reset',
        INFO: 'Please enter your email address. You’ll receive an email with instructions on how to reset your password.',
        E_MAIL: 'Email',
        E_MAIL_PLACEHOLDER: 'example@artiox.com',
        SEND: 'Send password reset email',
        SEND_SUCCESFULLY_BEGGINING: 'We’ve sent password reset instructions to ',
        SEND_SUCCESFULLY_END: '',
        RESEND_REQUEST: 'Send the email again',
        SECOND_ABBREVIATION: 'sec'
    },
    SIGN_UP: {
        SIGN_UP: 'Sign Up',
        SIGN_UP_TEXT: 'Enter your email address and choose a password to continue.',
        CREATE_ACCOUNT: 'Join Artiox!',
        E_MAIL: 'Email',
        E_MAIL_PLACEHOLDER: 'example@artiox.com',
        PASSWORD: 'Password',
        PASSWORD_PLACEHOLDER: '*********',
        PASSWORD_AGAIN: 'Password (Again)',
        PASSWORD_PLACEHOLDER_AGAIN: 'Please enter your password (again)',
        KVKK_ACCEPTED: '<p class="mb-0"> I have read <a class="sign-up-agreements-color" href="./assets/pdfs/Artiox KVKK Aydınlatma Metni.pdf" target="_blank">KVKK Clarification Text</a> prepared in accordance with Turkish Personal Data Protection Law(KVKK). I agree to <a class="sign-up-agreements-color" href="./assets/pdfs/Artiox Kullanıcı Sözleşmesi.pdf" target="_blank">User Agreement</a> </p>',
        CONSENT_TEXT_ACCEPTED: '<p class="mb-0"> I accept the transfer of my personal data to foreign individuals and organizations according to <a class="sign-up-agreements-color" href="./assets/pdfs/Artiox Yurtdışı Açık Rıza Metni.pdf" target="_blank">Explicit Consent Text</a>. </p>',
        AUCTION_RULES_ACCEPTED: '<p class="mb-0"> I have read and approved <a class="sign-up-agreements-color" href="./assets/pdfs/Müzayede Kuralları.pdf" target="_blank">Auction Rules</a>, <a class="sign-up-agreements-color" href="./assets/pdfs/Mesafeli Satış Sözleşmesi.pdf" target="_blank">Distance Selling Agreement</a> and <a class="sign-up-agreements-color" href="./assets/pdfs/Müzayede Kullanıcı Sözleşmesi.pdf" target="_blank">Auction User Agreement</a> for participation in the online auction organized by Artiox Kripto Varlık Alım Satım Platformu A.Ş.</p>',
        COMMERCIALS_ACCEPTED: 'I accept the sending of commercial e-mails for purposes such as information, promotion of goods and services, advertisement and promotion.',
        DATA_PROCESSING_ACCEPTED: 'I consent to the processing and transfer of my email address information for information, advertisement, promotion and marketing purposes.',
        HAVE_ACCOUNT: 'If you have an account, ',
        LOGIN: 'Log In!',
        PASSWORD_RULES: 'Min 8 characters, must include 1 uppercase letter, 1 lowercase letter, 1 digit and 1 special character.',
        SIGN_UP_LOADER: 'Creating Your Account. Please wait.',
        SIGN_UP_SUCCESSFUL: 'CONGRATULATIONS, YOU HAVE CREATED A NEW ACCOUNT.',
        ACTIVATION_MAIL: 'To activate your account, please provide confirmation from your e-mail address. ',
        ACTIVATION_MAIL_NOT_SENT: 'If You Have Not Received Your Activation Email',
        ACTIVATION_MAIL_NOT_SENT_CHECK_LIST: ['- Check your spam folder and trash.', '- Make sure you typed your e-mail address correctly.', '- Make sure your internet connection is working.', '- Make sure your e-mail is working correctly.'],
        CREATE_PASSWORD: 'Reset account password',
        CREATE_PASSWORD_TEXT: 'Enter a new password for your account.',
        COMPLETE: 'Submit',
        SIGN_UP_HEADER: 'Sign Up',
        SIGN_UP_HEADER_TEXT: 'Join Artiox and seize the opportunity to invest in valuable artworks by dozens of artistsş. The privileged world of art investment is just one step away.'

    },
    ACCOUNT_VERIFICATION: {
        HEADER: 'Account Verification',
        FIRST_INFO: { STRING: 'You can activate your Artiox account by entering the <span class="verification-info-bold"> 6-digit-code </span> sent to $EMAIL in the field below', TOKENS: ['$EMAIL']},
        SECOND_INFO: 'If you cannot see the email containing the verification code, <span class="verification-info-bold"> check your spam/junk box.</span> ',
        INPUT_HEADER: '6-digit verification code',
        INPUT_PLACEHOLDER: '___ - ___',
        RESEND_REQUEST: 'Send the code again',
        SECOND_ABBREVIATION: 'sec',
        BUTTON_TEXT: 'Verify Your Account',
        ART_INVESTMENT: 'Easiest Way to Invest in Art'
    },
    LANDING_PAGE: {
        GREETER: {
            HEADING: 'DISCOVER ART INVESTMENT',
            HEADING_INFO: 'The Easiest Way To Have Fractional Ownership Of Precious Artworks',
            PRE_SALE: 'Pre-Sale',
            PUBLIC_SALE: 'Public Sale',
            TRADE: 'Trade',
            ADVANTAGES: [
                {
                    HEADING: 'Art Market',
                    CONTENT: [
                        'Portfolio with Different Works',
                        'Partial Trading'
                    ]
                },
                {
                    HEADING: 'Safe Investment',
                    CONTENT: [
                        'Blockchain Technology',
                        'Expert Approval and Insurance'
                    ]
                },
                {
                    HEADING: 'Easy Trading',
                    CONTENT: [
                        '24/7 Online Operation',
                        'User Friendly Interface'
                    ]
                }
            ],
            INFO: [
                {
                    HEADING: 'Art Market 2020 Volume',
                    HIGHLIGHT: '$50.1 BILLION',
                },
                {
                    HEADING: 'Online Art Market 2020 Volume',
                    HIGHLIGHT: '$12.4 BILLION',
                },
                {
                    HEADING: 'Online Art Market 2020 Growth',
                    HIGHLIGHT: '110%',
                }
            ],
        }
    },
    HOME: {
        SLIDER: {
            PRE_SALE_STARTS_IN: 'PRESALE STARTS IN',
            PRE_SALE_ENDS_IN: 'PRESALE ENDS IN',
            PUBLIC_SALE_STARTS_IN: 'PUBLIC SALE STARTS IN',
            PUBLIC_SALE_ENDS_IN: 'PUBLIC SALE ENDS IN',
            LISTED: 'LISTED',
            YEAR: 'YEAR',
            MONTH: 'MONTH',
            DAY: 'DAY',
            HOUR: 'HOUR',
            MIN: 'MIN',
            SEC: 'SEC',
            TOKENMETRICS: {
                HEADING: 'TOKEN METRICS',
                SYMBOL: 'Symbol',
                TOTAL_SUPPLY: 'Total Supply',
                CIRCULATING_SUPPLY: 'Circulating Supply',
                PRE_SALE_CIRCULATING_SUPPLY: 'Pre-Sale Supply',
                PRE_SALE_PRICE: 'Pre-Sale Price',
                PUBLIC_SALE_CIRCULATING_SUPPLY: 'Public Sale Supply',
                PUBLIC_SALE_PRICE: 'Public Sale Price',
                PUBLIC_SALE_PRICE_UPPERCASE: 'PUBLIC SALE PRICE',
            },
            ART_PRODUCT_INFO: {
                HEADING: 'ARTWORK INFO',
                LAST_PRICE: 'Last Price',
                CHANGE_24_HOUR: '24H Change',
                VOLUME_24_HOUR: '24H Volume',
                LOW_24_HOUR: '24H Lowest',
                HIGH_24_HOUR: '24H Highest',
            }
        },
        PAIR_HEADER: {
            ART_PRODUCT: 'ARTWORK',
            LAST_PRICE: 'Last Price',
            ART_PRODUCT_MARKET_VALUE: 'Market Value',
            CHANGE_24_HOUR: '24H Change',
            MARKET: 'Market'
        },
        SUMMARY: {
            HEADING_MOBILE: 'Become A Co-Owner!',
            HEADING_DESKTOP: 'Become a Co-Owner to Artworks with ARTIOX!',
            TOPICS: [
                {
                    SRC: './assets/images/insured_art_pieces.svg#insured_art_pieces',
                    TEXT: 'The artworks listed on Artiox are expert approved and insured.'
                },
                {
                    SRC: './assets/images/high_security.svg#high_security',
                    TEXT: 'Your information and funds at Artiox are securely stored.'
                },
                {
                    SRC: './assets/images/easy_to_use.svg#easy_to_use',
                    TEXT: 'With its user-friendly interface, you can easily trade artworks tokens on Artiox.'
                },
                {
                    SRC: './assets/images/7_24_online.svg#7_24_online',
                    TEXT: 'You can perform your trading transactions at any time of the day.'
                }
            ]
        }
    },
    APP_ANNOUNCEMENT: {
        DESCRIPTION: 'With Artiox Mobile<br>art investment is<br>always in your pocket!',
        APP_STORE_LINK_DESKTOP: 'https://apps.apple.com/tr/app/artiox/id1579037676?l=tr',
        APP_STORE_LINK_MOBILE: 'itms-apps://apple.com/app/id1579037676?l=tr',
        GOOGLE_PLAY_LINK: 'https://play.google.com/store/apps/details?id=com.artiox.artiox',
        APP_STORE_BADGE: './assets/misc/app_announcement/app_store.svg#app_store',
        GOOGLE_PLAY_BADGE: './assets/misc/app_announcement/google_play.svg#google_play',
        DESKTOP_IMAGE_THUMBNAIL: './assets/misc/app_announcement/en.d.thumbnail.webp',
        MOBILE_IMAGE_THUMBNAIL: './assets/misc/app_announcement/en.m.thumbnail.webp',
    },
    ART_MARKET: {
        HEADING: 'OVERVIEW OF ART MARKET',
        TOPIC: 'ART MARKET',
        INFO: 'Apart from being a form of self-expression, art appears as a great market.' +
            ' That is; besides being illiquid art assets worth 3$ trillion on the world,' +
            ' Art market volume of $ 64 billion in 2019 clearly shows the size of the market.' +
            ' As can be observed in the chart, this market, which looks very profitable and safe compared to traditional investment instruments,' +
            ' continues to grow with a high momentum on the online side. The online art market, which reached a volume of $3.75 billion in 2016,' +
            ' is estimated to reach $ 9.1 billion in 2024.<span class="citation h6 text-info">1</span>',
        CARDS: [
            'Online Art Market<br> Growth Rate<br><strong class="d-inline-block h5 mt-1 mb-0">110% last year</strong>', 'Online Art Market<br>2020 Volume<br><strong class="h5"> $12.4 Billion<span class="citation h6 text-info">2</span> </strong>'
        ],
        SOURCE: [
            'The Art Basel and UBS Global Art Market Report 2020', 'The Art Basel and UBS Global Art Market Report 2021'
        ]
    },
    WHAT_IS_ARTIOX: {
        HEADING: 'WHAT IS ARTIOX?',
        TOPIC: 'EASY ART INVESTMENT PLATFORM DESIGNED FOR EVERYONE',
        INFO: 'On the Artiox platform, you can easily buy and sell \'tokens\' belonging to expert-approved and insured artworks, just like buying and selling stocks. Thus:',
        ADVANTAGES: [
            'Instead of investing a large fund to a whole work, you can become a partner of a part of it and diversify your portfolio with other works.',
            'You can instantly buy and sell as many as you want at the market price, and place buy or sell orders at any point.',
            'You can manage your art investments more effectively than traditional methods.'
        ]
    },
    HOW_IT_WORKS: {
        HEADING: 'HOW IT WORKS?',
        TOPICS: [
            {
                HEADING: 'SELECTING ARTWORK',
                INFO: 'Valuable artworks are selected as a result of the evaluation of experts.',
                SRC: './assets/animations/choose.d.webm',
                SRC_MP4: './assets/animations/choose.d.mp4'
            },
            {
                HEADING: 'TOKENIZATION',
                INFO: 'The insured work is tokenized with the smart contract created on the blockchain and announced to the users.',
                SRC: './assets/animations/tokenization.d.webm',
                SRC_MP4: './assets/animations/tokenization.d.mp4'
            },
            {
                HEADING: 'LISTING ON THE PLATFORM',
                INFO: 'After the pre-sale and sales process, the work is listed in the market and becomes ready for trade 24/7.',
                SRC: './assets/animations/trade.d.webm',
                SRC_MP4: './assets/animations/trade.d.mp4'
            }
        ]
    },
    DASHBOARD: {
        SUCCESS: 'Success',
        SUCCESSFUL_LOGIN: 'Successful Login',
        SUCCESSFUL_OPERATION: 'Successful Operation',
        WARNING: 'Warning',
        WELCOME: 'Welcome,',
        LAST_ACTIVITIES: 'LAST ACTIVITIES',
        PORTFOLIO: 'PORTFOLIO',
        TOTAL_BALANCE: 'Total Balance',
        TOTAL_BALANCE_HISTORY: 'Total Balance History',
        DAILY_LIMIT: 'Daily Limit',
        MONTHLY_LIMIT: 'Monthly Limit',
        LIMITS: 'Remaining Limits',
        LIMITS_MOBILE: 'Rmng Limits',
        DAILY: 'Daily',
        DAILY_REMAINING: 'Daily Remaining',
        MONTHLY: 'Monthly',
        MONTHLY_REMAINING: 'Monthly Remaining',
        DEPOSIT: 'Deposit',
        WITHDRAWAL: 'Withdrawal',
        BUY: 'Buy',
        SELL: 'Sell',
        LEVEL: 'Level',
        LEVEL_UP: 'Level Up Now',
        LEVEL_UP_MOBILE: 'Level Up',
        HIGHEST_LEVEL: 'Your account is at the highest level.',
    },
    USER_INFORMATION: {
        INVALID_PHONE: 'Invalid phone number',
        INVALID_ADDRESS: 'Invalid address',
        INVALID_CITY: 'Invalid city',
        NO_KYC_HEADING: 'Your account must be Level 1',
        NO_KYC_DESCRIPTION: 'For security reasons, you must make an "Identity Statement" to access account information.',
        HEADING: 'User Information',
        ACCOUNT_INFORMATION: 'Account Information',
        PROFILE: 'Profile',
        AUCTION_ADDRESS: 'Auction Address',
        MY_ADDRESSESS: 'My Addresses',
        DELIVERY_ADDRESS: 'Delivery Address',
        BILLING_ADDRESS: 'Billing Address',
        ADD_ADDRESS: 'Add Address',
        EDIT_ADDRESS: 'Edit Address',
        DELETE_ADDRESS: 'Delete Address',
        HOME: 'Home',
        WORK: 'Work',
        OTHER: 'Other',
        NAME: 'Name',
        SURNAME: 'Surname',
        TC_NUMBER: 'TC Number',
        CITY: 'City',
        ADDRESS: 'Address',
        EMAIL: 'E-Mail',
        PHONE_NUMBER: 'Phone Number',
        DISCLAIMER: '<p>Your <span class="underline">Name, Surname, ID Number and Email</span> information cannot be changed for your security. You can contact <a href="mailto:support@artiox.com">support@artiox.com</a> to delete your account or change this information.</p>',
        CANCEL: 'Cancel',
        CHANGE: 'Change',
        APPROVE: 'Approve'
    },
    BILLING_ADDRESS: {
        HEADER: 'Add Address',
        MODAL_INFO: 'My Billing Information',
        INDIVIDUAL_OPTION: 'Individual',
        CORPORATE_OPTION: 'Corporate',
        INDIVIDUAL_HEADERS: {
            ADDRESS_HEADER: 'Address Title',
            NAME: 'Name',
            SURNAME: 'Surname',
            ID_NUMBER: 'ID Number',
            PHONE_NUMBER: 'Phone Number',
            PROVINCE: 'Province',
            DISTRICT: 'District',
            ADDRESS: 'Address',
        },
        CORPORATE_HEADERS: {
            ADDRESS_HEADER: 'Address Title',
            CORPORATE_NAME: 'Corporate Name',
            TAX_ADMINISTRATION: 'Tax Administration',
            TAX_NUMBER: 'Tax Number',
            PHONE_NUMBER: 'Phone Number',
            PROVINCE: 'Province',
            DISTRICT: 'District',
            ADDRESS: 'Addresss',
            E_INVOICE: 'I am an e-ınvoice payer.',
        }

    },
    ACCOUNT_AUCTION: {
        HEADING: 'AUCTION',
        MY_BIDS: 'My Bids',
        MY_FOLLOWINGS: 'My Followings',
        MY_WONS: 'Won Auctions',
        PRODUCT_DETAIL: 'Product Detail',
        MAKE_PAYMENT: 'Make Payment',
        PAYMENT_DETAIL: 'Payment Detail',
        GIVEN_BID: 'Given Bid',
        CURRENT_BID: 'Highest Bid',
        TOTAL_PRICE: 'Payment Amount',
        BUYER_COST: 'Cost to Buyer',
        NO_USER_WON_HEADING: 'There are no artworks you have won',
        NO_USER_WON_DESCRIPTION: 'Click to see artworks',
        NO_USER_FOLLOWING_HEADING: 'There are no artworks you follow',
        NO_USER_FOLLOWING_DESCRIPTION: 'Click to see artworks',
        NO_USER_BID_HEADING: 'There are no artworks you are bidding on.',
        NO_USER_BID_DESCRIPTION: 'Click to see artworks',
        SEE_ARTWORKS: 'Inspect the artworks',
    },
    SUPPORT: {
        SUPPORT_HEADER: 'Destek Türünü Seç',
        QUESTIONS_HEADER: 'Popüler Sorular',
        QUESTIONS: [
            {
                TITLE: 'Artiox nedir?',
                BODY: 'Artiox, değerli sanat eserlerini eksper onaylı ve sigortalı bir şekilde blockchain teknolojisiyle tokenize ederek kullanıcılara arz eden ve bu sayede kullanıcıların tbu sanat eserlerine diledikleri miktarlarda yatırım yapabilmelerini sağlayarak sanat yatırımlarını daha erişilebilir ve demokratik hale getiren yeni nesil bir sanat yatırım platformdur.'
            },
            {
                TITLE: 'Artiox\'a nasıl üye olurum?',
                BODY: 'Artiox Web sitesine veya uygulamasına girdikten sonra üye ol / başla butonuna tıklayıp gerekli bilgileri doldurarak üye olabilirsiniz.'
            },
            {
                TITLE: 'Artiox\'a üyelik ücretli mi?',
                BODY: 'Artiox’a üye olmak tamamen ücretsizdir.'
            },
            {
                TITLE: 'Yatırım sınıfı sanat eseri nedir?',
                BODY: 'Yatırım sınıfı sanat eseri, orta/uzun vadede değer kazanması beklenen sanat eseridir. Bu eserler tanınmış sanatçılar tarafından yapılmış, iyi korunmuş ve nadir bulunan eserlerdir. Yatırım sınıfı sanat eserleri yüksek fiyatlı olsa da, orta/uzun vadede değer kazanarak yatırımcısına kar sağlama potansiyeline sahiptir.' +
                    '\n' +
                    'Yatırım sınıfı sanat eserleri, diğer sanat eserlerinden farklı olarak finansal yatırım amacı taşıyan özellikleriyle ayrılır ve yatırımcılar için alternatif bir varlık sınıfı sunar. Bunlar, finansal değer artışı potansiyeline sahip olan ve portföy çeşitlendirmek amacıyla kullanılan sanat eserleridir'
            },
            {
                TITLE: 'Varlığa dayalı token nedir?',
                BODY: 'Varlığa dayalı token(asset-backed tokens), fiziksel bir varlığın dijital temsilidir. Bu varlık, sanat eseri, gayrimenkul, hisse senedi, emtia veya başka herhangi bir fiziksel varlık olabilir. Varlığa dayalı tokenler, blok zinciri teknolojisi kullanılarak oluşturulur. Bu, tokenlerin güvenli ve şeffaf bir şekilde saklanmasına, transfer edilmesine veya temsil ettiği fiziksel varlığa paydaş olunabilmesine olanak tanır. ' +
                    '\n' +
                    'Varlığa dayalı tokenler (asset-backed tokens), gerçek dünya varlıklarını kripto para veya dijital varlık dünyasında entegre eden araçlardır. Bölünebilirlik ve şeffaflık gibi çeşitli faydalar sunmakla birlikte fiziksel varlıklara yatırım yapmayı sağladıkları için daha güvenilirlerdir.'
            },
            {
                TITLE: 'Online sanat marketinin büyüklüğü nedir?',
                BODY: 'Sanat dünyası ile ilgili analizler ve öngörülerde bulunan Art Basel’ın güncel raporuna göre 2023 yılında online sanat piyasasının tahmini değeri 10.8 milyar dolar olarak kabul edilmekte ve 2030 yılına kadar 23.79 milyar dolara kadar büyümesi öngörülmektedir.'
            },
            {
                TITLE: 'Artiox müzayede nedir?',
                BODY: 'Artiox Müzayede, açık artırmayla online eser satın almak için kullanabileceğiniz bir platformdur. Artiox Müzayede’de günlük ve katolog olmak üzere iki müzayede türü vardır. Katolog Müzayedeler toplu olarak açık artırmaya çıkarılan eserlerin tümü satılıncaya dek devam eder. Günlük Müzayede’de ise eserler tek tek açık artırmaya çıkarılır ve her gün bir müzayede olacak şekilde devam eder. Bu şekilde Artiox’ta her gün açık artımaya katılma fırsatınız olur.'
            },
            {
                TITLE: 'Artiox’ta 7/24 işlem yapabilir miyim?',
                BODY: 'Artiox\'ta alım-satım işlemlerinizi 7/24 gerçekleştirebilirsiniz.'
            },
            {
                TITLE: 'Artiox komisyon oranları nelerdir?',
                BODY: 'Artiox\'ta üyelik tamamen ücretsizdir. Alış ve satış komisyonları Türk Lirası ile yapılan işlemlerde %0,5(binde beş)\'tir. Tether (USDT) işlemlerinde ise %1 olarak belirlenmiştir.'
            },
            {
                TITLE: 'Eserler nasıl tokenize ediliyor?',
                BODY: 'Artiox bünyesinde yer alan eserlerin ticari değerleri eksperler tarafından belirlenir. Belirlenen ticari değer blok zincir üstünde tokenize edilir. Blok zincir üstündeki akıllı sözleşmeler (smart contracts) sayesinde arzı sabitlenen tokenler satışa sunulur.'
            },
        ],
        CATEGORIES: [
            {
                HEADER: 'Artiox Hakkında',
                BODY: 'Artiox nedir? Parçalı sanat yatırımı nedır',
                SVG_PATH: './assets/images/v2/ic_artiox.svg#ic_artiox',
                PATH: 'about-artiox',
                QUESTIONS: [
                    {
                        TITLE: 'Artiox nedir?',
                        BODY: 'Artiox, değerli sanat eserlerini eksper onaylı ve sigortalı bir şekilde blockchain teknolojisiyle tokenize ederek kullanıcılara arz eden ve bu sayede kullanıcıların tbu sanat eserlerine diledikleri miktarlarda yatırım yapabilmelerini sağlayarak sanat yatırımlarını daha erişilebilir ve demokratik hale getiren yeni nesil bir sanat yatırım platformdur.'
                    },
                    {
                        TITLE: 'Neden Artiox?',
                        BODY: 'Sanat eseri yatırımcılığı, alternatif yatırım seçenekleri arasında oldukça düşük risk taşıyan bir yatırım türü olarak öne çıkmaktadır. Diğer yatırım sınıflarıyla düşük korelasyona sahip olan sanat yatırımı, bu yönüyle ekonomik kriz dönemlerinde dahi istikrarını koruma potansiyeline sahiptir. Ek olarak son dönemde geleneksel yatırım araçlarının beklenen getirileri sunmaması da yatırımcıları sanat alanına yönlendiren önemli bir faktördür. Artiox, sanat yatırımı süreçlerini finans teknolojisi alanındaki yeniliklerle birleştirerek daha pratik ve erişilebilir hale getirmeyi hedefliyor. Bu yaklaşımla, giriş sermayesi bariyeri, saklama maliyeti ve orijinallik endişesi olmadan yatırımcıların hızlı ve güvenli bir şekilde sanat yatırımı yapabilmelerini sağlar.'
                    },
                    {
                        TITLE: 'Eserler nerede tutuluyor?',
                        BODY: 'Artiox’ta listelenen eserler fiziksel durumlarının korunması için sanat eserlerini muhafaza etmek üzere tasarlanmış güvenli depolarda saklanır.'
                    },

                    {
                        TITLE: 'Varlığa dayalı token nedir?',
                        BODY: 'Varlığa dayalı token(asset-backed tokens), fiziksel bir varlığın dijital temsilidir. Bu varlık, sanat eseri, gayrimenkul, hisse senedi, emtia veya başka herhangi bir fiziksel varlık olabilir. Varlığa dayalı tokenler, blok zinciri teknolojisi kullanılarak oluşturulur. Bu, tokenlerin güvenli ve şeffaf bir şekilde saklanmasına, transfer edilmesine veya temsil ettiği fiziksel varlığa paydaş olunabilmesine olanak tanır.' +
                            'Varlığa dayalı tokenler (asset-backed tokens), gerçek dünya varlıklarını kripto para veya dijital varlık dünyasında entegre eden araçlardır. Bölünebilirlik ve şeffaflık gibi çeşitli faydalar sunmakla birlikte fiziksel varlıklara yatırım yapmayı sağladıkları için daha güvenilirlerdir.'
                    },

                    {
                        TITLE: 'Artiox müzayededen aldığım eserin orijinalliğinden nasıl emin olabilirim?',
                        BODY: 'Artiox’ta arz edilen eserlerin orjinallikleri bağımsız eksperler tarafından onaylanmıştır. Eksper raporlarını kolay-al sat sayfasının solundaki panelde görebilirsiniz.'
                    },

                    {
                        TITLE: 'Eserler sigortalı mı?',
                        BODY: 'Artiox tarafından tokenize edilen eserlerin tamamı genel satışa çıkmadan önce  alanında uzman eksperler tarafından belirlenen değeri üzerinden sigortalanır.'
                    },

                    {
                        TITLE: 'Artiox’taki eserlerin fiyatları nasıl belirlenir?',
                        BODY: 'Artiox’ta listelenen ve müzayedeye çıkan eserlerin fiyatları bağımsız eksperler tarafından tür, sanatçının bilinirliği, eserin durumu, özgünlüğü ve piyasa rayici gibi faktörler göz önünde bulundurularak belirlenir.'
                    },

                    {
                        TITLE: 'Yatırım sınıfı sanat eseri nedir?',
                        BODY: 'Yatırım sınıfı sanat eseri, orta/uzun vadede değer kazanması beklenen sanat eseridir. Bu eserler tanınmış sanatçılar tarafından yapılmış, iyi korunmuş ve nadir bulunan eserlerdir. Yatırım sınıfı sanat eserleri yüksek fiyatlı olsa da, orta/uzun vadede değer kazanarak yatırımcısına kar sağlama potansiyeline sahiptir.' +
                            'Yatırım sınıfı sanat eserleri, diğer sanat eserlerinden farklı olarak finansal yatırım amacı taşıyan özellikleriyle ayrılır ve yatırımcılar için alternatif bir varlık sınıfı sunar. Bunlar, finansal değer artışı potansiyeline sahip olan ve portföy çeşitlendirmek amacıyla kullanılan sanat eserleridir.'
                    },

                    {
                        TITLE: 'Artiox tokenlerinin türü nedir?',
                        BODY: 'Artiox, eserleri tokenize ederken Ethereum blok zincirinde oldukça yaygın olan ERC-20 standardını kullanır.'
                    },

                    {
                        TITLE: 'ERC - 20 tokeni nedir?',
                        BODY: 'ERC-20 standardı kullanılarak oluşturulan ERC-20 tokenleri , Ethereum blok zincirinde yaygın olarak kullanılan bir token standardını temsil eder. ERC-20 tokenleri transfer edilebilen, bölünebilen, aynı akıllı kontrata tabi olan her birinin birbiri yerine geçtiği(fungible) ve Ethereum zincirinin merkeziyetsizlik, değiştirilemezlik ve şeffaflık özelliklerini taşıyan tokenlerdir.'
                    },

                    {
                        TITLE: 'Artiox ne zaman kuruldu?',
                        BODY: 'Mayıs 2019’da aldığı tohum öncesi yatırım ile Ankara’da kurulan Artiox, 2020 yılında ODTÜ Teknokent firması olarak faaliyetlerini sürdürmeye başladı.'
                    },

                    {
                        TITLE: 'Online sanat marketinin büyüklüğü nedir?',
                        BODY: 'Sanat dünyası ile ilgili analizler ve öngörülerde bulunan Art Basel’ın güncel raporuna göre 2023 yılında online sanat piyasasının tahmini değeri 10.8 milyar dolar olarak kabul edilmekte ve 2030 yılına kadar 23.79 milyar dolara kadar büyümesi öngörülmektedir.'
                    },

                    {
                        TITLE: 'Artiox tokenlerinin NFT\'den farkı nedir?',
                        BODY: 'Artiox’taki sanat eserlerini temsil eden tokenler varlığa dayalı tokenlerdir ve ERC-20(fungible) standardındadır. Diğer bir deyişle bir eseri temsil eden tokenlerin hepsi birbiri ile aynıdır ve eserin değerini eşit olarak temsil ederler. Bu standarttaki tokenler gerçek dünya varlıklarını, gayrimenkullerden emtialara ya da para birimlerine(USD, EUR) kadar farklı türlerdeki değerleri yansıtarak finansal piyasalarda kullanılır ve bölünebilirler. NFT\'ler ise benzersiz dijital veya fiziksel varlıkları temsil ederler ve temsil ettikleri varlığın tamamını ya da benzersiz bir bölümünü ifade ederler. Örneğin, Artiox’taki her bir eserin bir adet kontratı ve ona özel token sembolü olmakla beraber, eseri parçalara/hisselere ayırmakta kullanılan bu tokenlerin her biri birbirinin aynısıdır, bu şekilde ortak olunabilir ve aktarabilirler. NFT’ler ise benzersiz tokenlerdir. Yani her bir NFT ikamesi olmadan tek başına benzersiz bir token olarak blok zincirde kendine yer bulur.' +
                            'Artiox’taki tokenler varlığa dayalı tokenlerdir, bunlar gerçek dünya varlıklarını, gayrimenkullerden emtialara kadar farklı türlerdeki değerleri yansıtarak finansal piyasalarda kullanılır ve genellikle bölünebilirdir. NFT\'ler ise benzersiz dijital veya fiziksel varlıkları temsil ederler. Örneğin, Artiox’taki her bir eserin bir adet kontratı olmakla beraber, eseri hisselere ayırmakta kullanılan tokenlerin her biri birbirinin aynısıdır, bu şekilde takas edilebilirler. NFT’ler ise benzersiz tokenlerdir. Yani her bir NFT değiştirilemez, benzersiz bir token olarak blok zincirde kendine yer bulur.'
                    },

                    {
                        TITLE: 'Artiox\'ta sadece Türkiyeden sanatçıların eserleri mi var?',
                        BODY: 'Artiox’ta farklı ülkelerden sanatçıların eserleri Müzayedede satılır veya tokenize edilir.'
                    },

                    {
                        TITLE: 'Eserler nasıl tokenize ediliyor?',
                        BODY: 'Artiox bünyesinde yer alan eserlerin ticari değerleri eksperler tarafından belirlenir. Belirlenen ticari değer blok zincir üstünde tokenize edilir. Blok zincir üstündeki akıllı sözleşmeler (smart contracts) sayesinde arzı sabitlenen tokenler satışa sunulur.'
                    },

                    {
                        TITLE: 'Token fiyatları nasıl belirleniyor?',
                        BODY: 'Artiox’ta arz edilen eserlerin token fiyatları, genel satış sürecinde sigorta fiyatı üzerinden belirlenir. Bu da örneğin 500.000 TL’lik sigorta fiyatı olan bir eser için 500.000 adet özel token oluşturularak eseri temsil eden her bir tokeninin 1 TL’ye alınabileceği anlamına gelir. Alım satım için listelenmiş eserlere ait tokenlerin fiyatı ise Artiox kullanıcılarının arz ve talebine göre belirlenmektedir.'
                    },

                ]
            },
            {
                HEADER: 'Çekme/Yatırma Hakkında',
                BODY: 'Artiox\'a para nasıl yatırılır? Artiox\'tan para nasıl çekilir?',
                SVG_PATH: './assets/images/v2/ic_wallet.svg#ic_wallet',
                PATH: 'deposit-withdrawal',
                QUESTIONS: [
                    {
                        TITLE: 'Para yatırma/çekme limiti nedir?',
                        BODY: 'Artiox’ta para yatırma/çekme üst limitleri aylık ve günlük olarak hesap seviyenize göre belirlenmektedir. Para yatırma için alt limit bulunmamaktadır. Para çekme tarafında ise en az 100 TL alt limiti vardır.'
                    },


                    {
                        TITLE: 'Artiox’ta 7/24 işlem yapabilir miyim?',
                        BODY: 'Artiox\'ta alım-satım işlemlerinizi 7/24 gerçekleştirebilirsiniz.'
                    },


                    {
                        TITLE: 'Artiox komisyon oranları nelerdir?',
                        BODY: 'Artiox\'ta üyelik tamamen ücretsizdir. Alış ve satış komisyonları Türk Lirası ile yapılan işlemlerde %0,5(binde beş)\'tir. Tether (USDT) işlemlerinde ise %1 olarak belirlenmiştir.'
                    },


                    {
                        TITLE: 'Türk lirası yatırma kuralları nelerdir?',
                        BODY: 'Kredi kartı ve ATM üzerinden yapılan transferler kabul edilmemektedir. Transfer yaparken alıcı unvanını "Artiox Kripto Varlık Alım Satım Platformu A.Ş." olarak belirtmeyi unutmayınız. Şirketimize ait IBAN numarasını doğru bir şekilde girmeniz gerekmektedir. Ayrıca, size özel olarak verilen transfer kodunu açıklama bölümüne eklemeniz önemlidir ve bu kod dışında herhangi bir açıklama eklememeniz gerekmektedir. Eksik veya hatalı bilgiler nedeniyle onaylanamayan transferler, banka komisyonları düşülerek 5 iş günü içinde geri gönderilecektir. Havale ile gerçekleştirilen transferler 7/24 hesaplarınıza yansıtılırken, EFT ile gerçekleştirilen transferler sadece mesai saatleri içinde işlenmektedir.' +
                            'Papara veya diğer elektronik para şirketleri üzerinden yapılan yatırmalar geçerli değildir ve bu tür transferler, banka komisyonları düşülerek 5 iş günü içinde geri gönderilecektir. Bu şekilde yapılan transferlerle ilgili iade işlemleri için transferinize ilişkin dekont ile birlikte <a style="color: #8266f0; text-decoration: underline">support@artiox.com</a> adresine ulaşmanızı rica ederiz.'
                    },


                    {
                        TITLE: 'Türk lirası çekme kuralları nelerdir?',
                        BODY: 'Lütfen para çekme işlemleri için kullanacağınız banka hesabının, Artiox\'a verdiğiniz kimlik bilgilerinizle uyumlu ve sizin adınıza kayıtlı olmasına özen gösterin. Aksi takdirde çekme talebiniz onaylanmayabilir. Çekme talebinizi işleme koyabilmemiz için e-posta onayı vermeyi unutmamanız önemlidir. Onaylanmayan talepler 24 saat sonra zaman aşımına uğrar ve ilgili miktar kullanılabilir bakiyenize geri yatırılır. Çekmek istediğiniz tutar, kullanılabilir bakiyenizin miktarını aşmamalıdır. Minimum para çekme limiti 100 Türk Lirası olarak belirlenmiştir ve her çekme işlemi için 4 TL komisyon kesilir. Garanti Bankası\'na ait hesabınıza 7/24 çekme talimatı verebilirsiniz, ancak diğer banka hesapları için talepler EFT işlem saatleri içerisinde gerçekleştirilecektir.'
                    },


                    {
                        TITLE: 'Emir defteri nedir?',
                        BODY: 'Üye girişi yaptıktan sonra "Al-Sat" bölümüne erişerek "Gelişmiş" sekmesine tıklayın. Bu sekme altında, emir defteri adı verilen elektronik liste yer almaktadır. Emir defteri, işlem çiftine ilişkin verilen alım ve satım emirlerinin fiyat sırasına göre kaydedildiği bir sistemdir. İçerdiği bilgilere göre, alıcıların ve satıcıların ilgi seviyelerini yansıtır. Emir defteri, işlem çiftinin alım-satım işlemlerinin izlenmesi ve fiyat hareketlerinin takip edilmesi açısından önemlidir.'
                    },


                    {
                        TITLE: 'Emir defteri nasıl çalışır?',
                        BODY: 'Satış emirleri, sağ tarafta en düşük fiyattan en yükseğe doğru sıralanmıştır, ve alış emirleri sol tarafta en yüksek fiyattan en düşüğe doğru sıralanmıştır. Her bir fiyat seviyesindeki emirlerin yanında belirtilen miktar, o seviyede bulunan alım veya satım emirlerinin toplam adedini ifade eder. "Son fiyat" terimi, en son gerçekleşen işlemin fiyatını temsil eder ve bu fiyat, emir defterindeki satış ve alış emirlerinin son eşleşmesini yansıtır. Aynı fiyat seviyesinde, farklı zamanlarda verilen emirler kronolojik sıraya göre kaydedilir ve bu nedenle eşleşen emirlerin sıralaması, emirlerin veriliş sırasını izler.'
                    },


                    {
                        TITLE: 'Limit emri nedir?',
                        BODY: 'Limit emri vererek ticaret yapmak istediğiniz token için belirlediğiniz fiyat ve miktarı girerek açık emir oluşturabilirsiniz. Bu işlem, siz bilgisayar ya da mobil cihazınızın başında olmasanız bile emrinizdeki şartlar gerçekleştiğinde otomatik olarak gerçekleşir. Oluşturduğunuz alış veya satış emirlerini, fiyat ve miktar bilgilerini girerek açık emirler bölümünde detaylı olarak inceleyebilir ve istediğiniz zaman iptal edebilirsiniz.'
                    },


                    {
                        TITLE: 'Limit emriyle ilgili dikkat edilmesi gerekenler nelerdir?',
                        BODY: 'Piyasa fiyatının üçte birinden daha düşük bir fiyattan alış emri veya 3 katından fazla bir fiyattan satış emri giremezsiniz. Ayrıca, açık emrinizin bir kısmı gerçekleşmiş olsa bile, emri iptal etmek istediğinizde sadece gerçekleşmemiş miktar iptal edilir, gerçekleşen kısım geri alınamaz. Kullanılabilir bakiye, açık emirleriniz olduğunda, toplam bakiyenizden emirde yer alan miktar düşülerek hesaplanır.'
                    },


                    {
                        TITLE: 'Piyasa emri nedir?',
                        BODY: '"Piyasa" işlemlerinde; ticaretini yapmak istediğiniz tokenin miktarını girmeniz yeterlidir. Fiyat bilgisi piyasa olarak otomatik doldurulur. Adetini girdiğiniz token ardından “AL” butonuna bastığınızda emir defterinde bulunan en düşük fiyattan başlayarak girmiş olduğunuz adet miktarı doluncaya kadar alım gerçekleşecektir. Satış yaparken de aynı şekilde emir defterinde bulunan en yüksek fiyattan başlayarak girmiş olduğunuz adet miktarı doluncaya kadar satım gerçekleşir. Kısacası işlemleriniz en iyi fiyattan gerçekleşmiş olacaktır. İşlem geçmişinizden ticareti gerçekleşen ortalama işlem fiyatını inceleyebilirsiniz.'
                    },


                    {
                        TITLE: 'Piyasa emriyle ilgili dikkat edilmesi gerekenler nelerdir?',
                        BODY: 'Alış veya satış yapmak istediğiniz miktarı belirledikten ve “AL” ya da “SAT” butonuna tıkladıktan sonra bu işlemi geri almanız mümkün değildir. Gerçekleşen işlemler geri alınamaz ve bu nedenle dikkatle işlem yapmanız önemlidir. Yaklaşık ifadesi, emir defterine göre hesaplandığı için anlık fiyat değişikliklerinde küçük farklılıklar yaşanabilir. Kullanılabilir bakiye, alış ya da satış işlemleri için kullanabileceğiniz miktarı ifade eder. Ancak bu miktar, açık emirleriniz olduğu durumda, toplam bakiyenizden emirde yer alan miktar düşülerek hesaplanır. Bu nedenle hesabınızdaki bakiye ve açık emirleriniz arasındaki ilişkiyi göz önünde bulundurmalısınız.'
                    },

                ]
            },
            {
                HEADER: 'Müzayede Hakkında',
                BODY: 'Artiox müzayede nedir? Müzayededen nasıl eser alınır?',
                SVG_PATH: './assets/images/v2/ic_auction.svg#ic_auction',
                PATH: 'auction',
                QUESTIONS: [
                    {
                        TITLE: 'Artiox müzayede nedir?',
                        BODY: 'Artiox Müzayede, açık artırmayla online eser satın almak için kullanabileceğiniz bir platformdur. Artiox Müzayede’de günlük ve katolog olmak üzere iki müzayede türü vardır. Katolog Müzayedeler toplu olarak açık artırmaya çıkarılan eserlerin tümü satılıncaya dek devam eder. Günlük Müzayede’de ise eserler tek tek açık artırmaya çıkarılır ve her gün bir müzayede olacak şekilde devam eder. Bu şekilde Artiox’ta her gün açık artımaya katılma fırsatınız olur.'
                    },

                    {
                        TITLE: 'Artiox Müzayede’de nasıl eser satabilirim?',
                        BODY: 'Artiox Müzayede sayfasında bulunan Eser Kabul formunu doldurup eserin görseli, sertifikası, ebatları vb. bilgileri ilettikten sonra eseriniz değerlendirmeye alınır. Değerlendirme aşamasından geçen eserler Artiox tarafından müzayedede listelenir. Bu sayede eserlerinizi Artiox Müzayede’de satabilirsiniz.'
                    },

                    {
                        TITLE: 'Sanatçı olarak Artiox\'u nasıl kullanırım?',
                        BODY: 'Sanatçı olarak Artiox’u eserlerinizi müzayedeyle satmak için kullanabilirsiniz. Bunun için Eser Kabul formunu doldurmanız gerekmektedir. Bu sayede eseriniz Artiox tarafından değerlendirmeye alınacaktır. Değerlendirme aşamasını geçen eserler Artiox Müzayede’de listelenir.'
                    },

                    {
                        TITLE: 'Artiox\'ta resim/tablo dışında sanat eseri alıp satabilir miyim?',
                        BODY: 'Artiox’ta şu an yalnızca resim/tablo türündeki yatırım sınıfı sanat eserlerinin alım-satımı mümkündür.'
                    },

                    {
                        TITLE: 'Artiox Müzayede’de "alıcıya maliyet" nedir?',
                        BODY: 'Artiox Müzayede’de teklif verirken ve müzayede bittiğinde size bildirilen “alıcıya maaliyet” eserin son fiyatını, komisyonu ve bunların toplamı üzerinden hesaplanan KDV’yi içerir.'
                    },

                    {
                        TITLE: 'Koleksiyoner olarak Artiox Müzayede’de eser satabilir miyim?',
                        BODY: '\n' +
                            'Artiox Müzayede sayfasında yer alan Eser Kabul formunu doldurduğunuzda eserleriniz Artiox tarafından değerlendirmeye alınır. Müzayedede listelenmeye uygun görülen eserler kabul edilir. Bu şekilde sahip olduğunuz sanat eserlerini satabilirsiniz.'
                    },
                ]
            },
            {
                HEADER: 'Alım-Satım İşlemleri',
                BODY: 'Basit al-sat, gelişmiş al-sat, emir defteri, genel satış işlemleri nedir?',
                SVG_PATH: './assets/images/v2/ic_buy_sell.svg#ic_buy_sell',
                PATH: 'buy-sell',
                QUESTIONS: [
                    {
                        TITLE: 'Emir defteri nedir?',
                        BODY: 'Üye girişi yaptıktan sonra "Al-Sat" bölümüne erişerek "Gelişmiş" sekmesine tıklayın. Bu sekme altında, emir defteri adı verilen elektronik liste yer almaktadır. Emir defteri, işlem çiftine ilişkin verilen alım ve satım emirlerinin fiyat sırasına göre kaydedildiği bir sistemdir. İçerdiği bilgilere göre, alıcıların ve satıcıların ilgi seviyelerini yansıtır. Emir defteri, işlem çiftinin alım-satım işlemlerinin izlenmesi ve fiyat hareketlerinin takip edilmesi açısından önemlidir.'
                    },

                    {
                        TITLE: 'Emir defteri nasıl çalışır?',
                        BODY: 'Satış emirleri, sağ tarafta en düşük fiyattan en yükseğe doğru sıralanmıştır, ve alış emirleri sol tarafta en yüksek fiyattan en düşüğe doğru sıralanmıştır. Her bir fiyat seviyesindeki emirlerin yanında belirtilen miktar, o seviyede bulunan alım veya satım emirlerinin toplam adedini ifade eder. "Son fiyat" terimi, en son gerçekleşen işlemin fiyatını temsil eder ve bu fiyat, emir defterindeki satış ve alış emirlerinin son eşleşmesini yansıtır. Aynı fiyat seviyesinde, farklı zamanlarda verilen emirler kronolojik sıraya göre kaydedilir ve bu nedenle eşleşen emirlerin sıralaması, emirlerin veriliş sırasını izler.'
                    },

                    {
                        TITLE: 'Limit emri nedir?',
                        BODY: 'Limit emri vererek ticaret yapmak istediğiniz token için belirlediğiniz fiyat ve miktarı girerek açık emir oluşturabilirsiniz. Bu işlem, siz bilgisayar ya da mobil cihazınızın başında olmasanız bile emrinizdeki şartlar gerçekleştiğinde otomatik olarak gerçekleşir. Oluşturduğunuz alış veya satış emirlerini, fiyat ve miktar bilgilerini girerek açık emirler bölümünde detaylı olarak inceleyebilir ve istediğiniz zaman iptal edebilirsiniz.'
                    },

                    {
                        TITLE: 'Limit emriyle ilgili dikkat edilmesi gerekenler nelerdir?',
                        BODY: 'Piyasa fiyatının üçte birinden daha düşük bir fiyattan alış emri veya 3 katından fazla bir fiyattan satış emri giremezsiniz. Ayrıca, açık emrinizin bir kısmı gerçekleşmiş olsa bile, emri iptal etmek istediğinizde sadece gerçekleşmemiş miktar iptal edilir, gerçekleşen kısım geri alınamaz. Kullanılabilir bakiye, açık emirleriniz olduğunda, toplam bakiyenizden emirde yer alan miktar düşülerek hesaplanır.'
                    },

                    {
                        TITLE: 'Piyasa emri nedir?',
                        BODY: '"Piyasa" işlemlerinde; ticaretini yapmak istediğiniz tokenin miktarını girmeniz yeterlidir. Fiyat bilgisi piyasa olarak otomatik doldurulur. Adetini girdiğiniz token ardından “AL” butonuna bastığınızda emir defterinde bulunan en düşük fiyattan başlayarak girmiş olduğunuz adet miktarı doluncaya kadar alım gerçekleşecektir. Satış yaparken de aynı şekilde emir defterinde bulunan en yüksek fiyattan başlayarak girmiş olduğunuz adet miktarı doluncaya kadar satım gerçekleşir. Kısacası işlemleriniz en iyi fiyattan gerçekleşmiş olacaktır. İşlem geçmişinizden ticareti gerçekleşen ortalama işlem fiyatını inceleyebilirsiniz.'
                    },

                    {
                        TITLE: 'Piyasa emriyle ilgili dikkat edilmesi gerekenler nelerdir?',
                        BODY: 'Alış veya satış yapmak istediğiniz miktarı belirledikten ve “AL” ya da “SAT” butonuna tıkladıktan sonra bu işlemi geri almanız mümkün değildir. Gerçekleşen işlemler geri alınamaz ve bu nedenle dikkatle işlem yapmanız önemlidir. Yaklaşık ifadesi, emir defterine göre hesaplandığı için anlık fiyat değişikliklerinde küçük farklılıklar yaşanabilir. Kullanılabilir bakiye, alış ya da satış işlemleri için kullanabileceğiniz miktarı ifade eder. Ancak bu miktar, açık emirleriniz olduğu durumda, toplam bakiyenizden emirde yer alan miktar düşülerek hesaplanır. Bu nedenle hesabınızdaki bakiye ve açık emirleriniz arasındaki ilişkiyi göz önünde bulundurmalısınız.'
                    },
                ]
            },
            {
                HEADER: 'Üyelik işlemleri Hakkında',
                BODY: 'Üyelik nasıl açılır? Şifremi unuttum, şifre değiştirme. Artiox\'a üyelik ücretli mi?',
                SVG_PATH: './assets/images/v2/ic_user.svg#ic_user',
                PATH: 'membership-processes',
                QUESTIONS: [
                    {
                        TITLE: 'Artiox’a nasıl üye olurum?',
                        BODY: 'Artiox Web sitesine veya uygulamasına girdikten sonra üye ol / başla butonuna tıklayıp gerekli bilgileri doldurarak üye olabilirsiniz.'
                    },

                    {
                        TITLE: 'Artiox\'a üyelik ücretli mi?',
                        BODY: 'Artiox’a üye olmak tamamen ücretsizdir.'
                    },

                    {
                        TITLE: 'Şifremi nasıl değiştiririm?',
                        BODY: 'Üye girişi yaptıktan sonra profil ikonu altından "Güvenlik" kısmına girerek mevcut hesap şifrenizi girin. Daha sonra oluşturmak istediğiniz yeni şifrenizi belirleyin. Yeni şifreniz en az 8 karakter uzunluğunda olmalı ve içinde en az bir büyük harf, bir küçük harf, bir sayı ve bir özel karakter bulundurmalıdır. Yeni şifrenizi tekrar girerek doğrulayın ve değişiklikleri kaydetmek için "Değişiklikleri Kaydet" butonuna tıklayın. Böylece şifreniz güncellenmiş olacaktır.'
                    },

                    {
                        TITLE: 'Hesap seviyesi nedir?',
                        BODY: 'Artiox’a ilk defa üye olduğunuzda hesabınız otomatik olarak Seviye 0 şeklinde kaydedilir. Seviye 0 hesabınızı kullanarak Artiox’ta listelenen sanat eserlerini ve fiyat bilgilerini inceleyebilirsiniz. Artiox’ta yatırım yapabilmek için hesabınız en az Seviye 1 olmalıdır. Kimlik bilgilerinizi doğruladığınızda hesabınız Seviye 1’e yükseltilir. Seviye 1 hesap size günlük 50.000 TL ve ve aylık 1.000.000 TL yatırma/çekme limiti sağlar. KYC Doğrulama Ekranı’nı kullanarak hesabınızı Seviye 2’ye yükselttiğinizde limitleriniz günlük 1.000.000 TL ve aylık 10.000.000 TL olmak üzere artırılır. Ek olarak Seviye 2 hesapla kripto para kullanarak da yatırma/çekme işlemleri yapabilirsiniz.'
                    },

                    {
                        TITLE: 'Artiox e-mail listesinden nasıl çıkabilirim?',
                        BODY: 'Artiox tarafından size gönderilen emaillerde bulunan abonelikten çıkma bağlantısına tıklayarak Artiox e-mail listesinden çıkabilirsiniz.'
                    },
                ]
            },
            {
                HEADER: 'Güvenlik İşlemleri Hakkında',
                BODY: 'Artiox kullanıcı güvenliğini nasıl sağlar? Sözleşmelere nasıl ulaşırım? Blockchain nedir?',
                SVG_PATH: './assets/images/v2/ic_security.svg#ic_security',
                PATH: 'security-procedures',
                QUESTIONS: [
                    {
                        TITLE: 'İşlem yapmak için neden kişisel bilgileri vermek zorundayım?',
                        BODY: 'Dijital ortamda yapılan finansal  işlemler için yasal düzenlemelere uyum ve güvenlik nedeniyle işlem yapmak isteyen kullanıcıların kimlik bilgilerine ihtiyaç duyulur. Artiox’ta  yapılan işlemler de diğer finansal piyasalara benzer şekilde kimlik doğrulama ve müşteri tanıma (KYC) süreçlerine tabidir. Bu, hem yasal gereksinimleri yerine getirmek hem de finansal dolandırıcılık ve kötü niyetli faaliyetleri engellemek için önemlidir. Kimlik bilgileri, müşterilerin gerçek kişiler olduğunu ve yasal olarak işlem yapma yetkilerinin olduğunu doğrulamak için kullanılır. Aynı zamanda hesap güvenliği ve risk yönetimi için de kritik bir rol oynar.'
                    },

                    {
                        TITLE: 'Eserlerin eksper onaylı olması ne anlama geliyor?',
                        BODY: 'Sanat eserlerinin eksper onaylı olması, bağımsız bir sanat eksperinin, sanatçının kendisinin/ailesinin veya sanatçıyı temsil eden galerinin eseri inceleyerek orijinalliğini ve eserin kondisyon durumunun iyi olduğunu teyit ettiği anlamına gelir. Bu onay, eserin gerçek veya sahte olduğunu tespitten sanatçı kimliğini doğrulamaya kadar farklı bir çok konuyu kapsar. Eksper onayı, sahte eserlerin piyasaya sürülmesini engellerken aynı zamanda koleksiyonerler için önemli bir referans noktasıdır.'
                    },

                    {
                        TITLE: 'Yatırım yaptığım eserin eksper onayını nereden görebilirim?',
                        BODY: 'Yatırım yaptığınız eserin eksper onaylarını eserin Kolay Al-Sat sayfasının solundaki panelde görebilirsiniz.'
                    },

                    {
                        TITLE: 'Yatırım yaptığım eserin sigorta bilgilerini nereden görebilirim?',
                        BODY: 'Yatırım yaptığınız eserin sigorta raporunu eserin Kolay Al-Sat sayfasının solundaki panelde görebilirsiniz.'
                    },
                    ]
            },
        ],
        ALTERNATIVE_HEADER: 'Alternatif Destek Hattı',
        ALTERNATIVE_SUPPORT: [
            {
                HEADER: 'Artiox Mail',
                BODY: 'info@artiox.com',
                SVG_PATH: './assets/images/v2/ic_mail.svg#ic_mail',
            },

            {
                HEADER: 'Artiox Linkedin',
                BODY: '@artioxofficial',
                SVG_PATH: '/assets/images/v2/ic_linkedin.svg#ic_linkedin',
            },

            {
                HEADER: 'Artiox Instagram',
                BODY: '@artioxofficial',
                SVG_PATH: '/assets/images/v2/ic_instagram.svg#ic_instagram',
            },

            {
                HEADER: 'Artiox Twitter',
                BODY: '@artiox_official',
                SVG_PATH: '/assets/images/v2/ic_twitter.svg#ic_twitter',
            },
        ]

    },

    PAYMENT: {
        HEADER: 'Acquired Product Info',
        MANDATORY_FIELDS: '<span class="asterix">* Mandatory Fields</span>',
        EDIT: 'Edit',
        DELETE: 'Delete',
        DELIVERY_OPTIONS: {
            HEADER: 'Delivery Option <span class="asterix">*</span>',
            HEADER_WITHOUT_ASTERIX: 'Delivery Option',
            INFORMATION: 'Delivery Information',
            SHIPPING: 'Shipping',
            SHIPPING_TEXT: 'Shipping price will be paid by the recipient',
            PICK_UP: 'Pick-up',
            PICK_UP_TEXT: 'Pick-up is free of charge'
        },
        DELIVERY_ADDRESS: {
            HEADER: 'Delivery Address <span class="asterix" #F11658">*</span>',
            HEADER_RAW: 'Delivery Address',
            TEXT: 'Add Delivery Address',
            PICK_UP_HEADER: 'Delivery Address',
            PICK_UP_ADDRESS: 'Kızılırmak District YDA Center A/2 Floor:8 No:287 Artiox Çankaya/Ankara'
        },
        BILLING_ADDRESS: {
            HEADER: 'Billing Address <span class="asterix">*</span>',
            HEADER_RAW: 'Billing Address',
            TEXT: 'Add Billing Address'
        },
        PAYMENT_INFO: {
            HEADER: 'Payment Info',
            MONEY_ORDER: 'Money Order/EFT',
            CREDIT_CARD: 'Credit Card (Sometime Soon)',
            BANK_NAME: 'Garanti Bank',
            ACCOUNT_TITLE: '<span class="payment-info-subtitles">Account Title: </span> Artiox Kripto Varlık Alım Satım Platformu A.Ş.',
            IBAN: '<span class="payment-info-subtitles">IBAN: </span> TR750006200114500006899270',
            MESSAGE: '<span class="payment-info-subtitles">Message: </span> ',
            INFO: 'Please make sure to pay attention <span class="payment-info-underline" >transfer rules</span>.',
            COMPLETE: 'Complete Payment',
            ACCOUNT_TITLE_TEXT: 'Artiox Kripto Varlık Alım Satım Platformu A.Ş.',
            IBAN_NO: 'TR750006200114500006899270',
        },
        PAYMENT_STATUS: {
            HEADER: 'Payment Status',
            STATUS: {
                PENDING: {
                    HEADER: 'Waiting Payment',
                    TEXT: 'We are waiting for your payment to be approved in our systems. The delivery process will start after the payment is approved',
                    IMAGE: './assets/images/waiting_payment.svg#waiting_payment',
                },
                CONFIRMING: {
                    HEADER: 'Waiting Approval',
                    TEXT: 'We are waiting for your payment to be approved in our systems. The delivery process will start after the payment is approved',
                    IMAGE: './assets/images/waiting_approval.svg#waiting_approval',
                },
                APPROVED: {
                    HEADER: 'Approved',
                    TEXT: 'Your payment has been approved by our system. The process of shipping will begin',
                    IMAGE: './assets/images/approve_icon_colored.svg#approve_icon_colored',
                },
                PACKED: {
                    HEADER: 'In Transit',
                    TEXT: 'Art piece has been shipped. \n' +
                        '<br>Delivery Firm: $cargo_firm\n' +
                        '<br>Tracking Number: $cargo_number\n' +
                        '<br>Tracking Link: <a href="$cargo_link" target="_blank">$cargo_link</a>',
                    IMAGE: './assets/images/in_transit.svg#in_transit',
                },
                DELIVERED: {
                    HEADER: 'Delivered',
                    TEXT: 'We hope you enjoy your art piece. Thank you for choosing Artiox.',
                    IMAGE: './assets/images/delivered.svg#delivered',
                },
            }
        }
    },
    USER_AGREEMENT: {
        HEADING: 'The Artiox User Agreement has been updated.',
        TEXT: '<div>We\'ve updated the <a href="assets/pdfs/Artiox Kullanıcı Sözleşmesi.pdf" target="_blank" class="purple-highlight">Artiox User Agreement</a> to make it more secure for you. You can continue your transactions on Artiox by approving the new user agreement:</div>',
        LINK: 'Artiox User Agreement',
        AGREEMENT: 'I have read and accept the updated Artiox User Agreement.',
        ACCEPT: 'Confirm',
        REJECT: 'Cancel',
    },
    CITIES: {
        Adana: ['Aladağ', 'Ceyhan', 'Çukurova', 'Feke', 'İmamoğlu', 'Karaisalı', 'Karataş', 'Kozan', 'Pozantı', 'Saimbeyli', 'Sarıçam', 'Seyhan', 'Tufanbeyli', 'Yumurtalık', 'Yüreğir'],
        Adıyaman: ['Besni', 'Çelikhan', 'Gerger', 'Gölbaşı', 'Kahta', 'Merkez', 'Samsat', 'Sincik', 'Tut'],
        Afyonkarahisar: ['Başmakçı', 'Bayat', 'Bolvadin', 'Çay', 'Çobanlar', 'Dazkırı', 'Dinar', 'Emirdağ', 'Evciler', 'Hocalar', 'İhsaniye', 'İscehisar', 'Kızılören', 'Merkez', 'Sandıklı', 'Sinanpaşa', 'Sultandağı', 'Şuhut'],
        Ağrı: ['Diyadin', 'Doğubayazıt', 'Eleşkirt', 'Hamur', 'Merkez', 'Patnos', 'Taşlıçay', 'Tutak'],
        Aksaray: ['Ağaçören', 'Eskil', 'Gülağaç', 'Güzelyurt', 'Merkez', 'Ortaköy', 'Sarıyahşi'],
        Amasya: ['Göynücek', 'Gümüşhacıköy', 'Hamamözü', 'Merkez', 'Merzifon', 'Suluova', 'Taşova'],
        Ankara: ['Akyurt', 'Altındağ', 'Ayaş', 'Bala', 'Beypazarı', 'Çamlıdere', 'Çankaya', 'Çubuk', 'Elmadağ', 'Etimesgut', 'Evren', 'Gölbaşı', 'Güdül', 'Haymana', 'Kalecik', 'Kahramankazan', 'Keçiören', 'Kızılcahamam', 'Mamak', 'Nallıhan', 'Polatlı', 'Pursaklar', 'Sincan', 'Şereflikoçhisar', 'Yenimahalle'],
        Antalya: ['Akseki', 'Aksu', 'Alanya', 'Demre', 'Döşemealtı', 'Elmalı', 'Finike', 'Gazipaşa', 'Gündoğmuş', 'İbradı', 'Kaş', 'Kemer', 'Kepez', 'Konyaaltı', 'Korkuteli', 'Kumluca', 'Manavgat', 'Muratpaşa', 'Serik'],
        Ardahan: ['Çıldır', 'Damal', 'Göle', 'Hanak', 'Merkez', 'Posof'],
        Artvin: ['Ardanuç', 'Arhavi', 'Borçka', 'Hopa', 'Merkez', 'Murgul', 'Şavşat', 'Yusufeli'],
        Aydın: ['Bozdoğan', 'Buharkent', 'Çine', 'Didim', 'Efeler', 'Germencik', 'İncirliova', 'Karacasu', 'Karpuzlu', 'Koçarlı', 'Köşk', 'Kuşadası', 'Kuyucak', 'Merkez', 'Nazilli', 'Söke', 'Sultanhisar', 'Yenipazar'],
        Balıkesir: ['Altıeylül', 'Ayvalık', 'Balya', 'Bandırma', 'Bigadiç', 'Burhaniye', 'Dursunbey', 'Edremit', 'Erdek', 'Gömeç', 'Gönen', 'Havran', 'İvrindi', 'Karesi', 'Kepsut', 'Manyas', 'Marmara', 'Savaştepe', 'Sındırgı', 'Susurluk'],
        Bartın: ['Amasra', 'Kurucaşile', 'Merkez', 'Ulus'],
        Batman: ['Beşiri', 'Gercüş', 'Hasankeyf', 'Kozluk', 'Merkez', 'Sason'],
        Bayburt: ['Aydıntepe', 'Demirözü', 'Merkez'],
        Bilecik: ['Bozüyük', 'Gölpazarı', 'İnhisar', 'Merkez', 'Osmaneli', 'Pazaryeri', 'Söğüt', 'Yenipazar'],
        Bingöl: ['Adaklı', 'Genç', 'Karlıova', 'Kiğı', 'Merkez', 'Solhan', 'Yayladere', 'Yedisu'],
        Bitlis: ['Adilcevaz', 'Ahlat', 'Güroymak', 'Hizan', 'Merkez', 'Mutki', 'Tatvan'],
        Bolu: ['Dörtdivan', 'Gerede', 'Göynük', 'Kıbrıscık', 'Mengen', 'Merkez', 'Mudurnu', 'Seben', 'Yeniçağa'],
        Burdur: ['Ağlasun', 'Altınyayla', 'Bucak', 'Çavdır', 'Çeltikçi', 'Gölhisar', 'Karamanlı', 'Kemer', 'Merkez', 'Tefenni', 'Yeşilova'],
        Bursa: ['Büyükorhan', 'Gemlik', 'Gürsu', 'Harmancık', 'İnegöl', 'İznik', 'Karacabey', 'Keles', 'Kestel', 'Mudanya', 'Mustafakemalpaşa', 'Nilüfer', 'Orhaneli', 'Orhangazi', 'Osmangazi', 'Yenişehir', 'Yıldırım'],
        Çanakkale: ['Ayvacık', 'Bayramiç', 'Biga', 'Bozcaada', 'Çan', 'Eceabat', 'Ezine', 'Gelibolu', 'Gökçeada', 'Lapseki', 'Merkez', 'Yenice'],
        Çankırı: ['Atkaracalar', 'Bayramören', 'Çerkeş', 'Eldivan', 'Ilgaz', 'Kızılırmak', 'Korgun', 'Kurşunlu', 'Merkez', 'Orta', 'Şabanözü', 'Yapraklı'],
        Çorum: ['Alaca', 'Bayat', 'Boğazkale', 'Dodurga', 'İskilip', 'Kargı', 'Laçin', 'Mecitözü', 'Merkez', 'Oğuzlar', 'Ortaköy', 'Osmancık', 'Sungurlu', 'Uğurludağ'],
        Denizli: ['Acıpayam', 'Babadağ', 'Baklan', 'Bekilli', 'Beyağaç', 'Bozkurt', 'Buldan', 'Çal', 'Çameli', 'Çardak', 'Çivril', 'Güney', 'Honaz', 'Kale', 'Merkez', 'Pamukkale', 'Sarayköy', 'Serinhisar', 'Tavas'],
        Diyarbakır: ['Bağlar', 'Bismil', 'Çermik', 'Çınar', 'Çüngüş', 'Dicle', 'Eğil', 'Ergani', 'Hani', 'Hazro', 'Kayapınar', 'Kocaköy', 'Kulp', 'Lice', 'Silvan', 'Sur', 'Yenişehir'],
        Düzce: ['Akçakoca', 'Cumayeri', 'Çilimli', 'Gölyaka', 'Gümüşova', 'Kaynaşlı', 'Merkez', 'Yığılca'],
        Edirne: ['Enez', 'Havsa', 'İpsala', 'Keşan', 'Lalapaşa', 'Meriç', 'Merkez', 'Süloğlu', 'Uzunköprü'],
        Elazığ: ['Ağın', 'Alacakaya', 'Arıcak', 'Baskil', 'Karakoçan', 'Keban', 'Kovancılar', 'Maden', 'Merkez', 'Palu', 'Sivrice'],
        Erzincan: ['Çayırlı', 'İliç', 'Kemah', 'Kemaliye', 'Merkez', 'Otlukbeli', 'Refahiye', 'Tercan', 'Üzümlü'],
        Erzurum: ['Aşkale', 'Aziziye', 'Çat', 'Hınıs', 'Horasan', 'İspir', 'Karaçoban', 'Karayazı', 'Köprüköy', 'Narman', 'Oltu', 'Olur', 'Palandöken', 'Pasinler', 'Pazaryolu', 'Şenkaya', 'Tekman', 'Tortum', 'Uzundere', 'Yakutiye'],
        Eskişehir: ['Alpu', 'Beylikova', 'Çifteler', 'Günyüzü', 'Han', 'İnönü', 'Mahmudiye', 'Merkez', 'Mihalgazi', 'Mihalıççık', 'Odunpazarı', 'Sarıcakaya', 'Seyitgazi', 'Sivrihisar', 'Tepebaşı'],
        Gaziantep: ['Araban', 'İslahiye', 'Karkamış', 'Nizip', 'Nurdağı', 'Oğuzeli', 'Şahinbey', 'Şehitkamil', 'Yavuzeli'],
        Giresun: ['Alucra', 'Bulancak', 'Çamoluk', 'Çanakçı', 'Dereli', 'Doğankent', 'Espiye', 'Eynesil', 'Görele', 'Güce', 'Keşap', 'Merkez', 'Piraziz', 'Şebinkarahisar', 'Tirebolu', 'Yağlıdere'],
        Gümüşhane: ['Kelkit', 'Köse', 'Kürtün', 'Merkez', 'Şiran', 'Torul'],
        Hakkari: ['Çukurca', 'Merkez', 'Şemdinli', 'Yüksekova'],
        Hatay: ['Altınözü', 'Antakya', 'Arsuz', 'Belen', 'Defne', 'Dörtyol', 'Erzin', 'Hassa', 'İskenderun', 'Kırıkhan', 'Kumlu', 'Payas', 'Reyhanlı', 'Samandağ', 'Yayladağı'],
        Iğdır: ['Aralık', 'Karakoyunlu', 'Merkez', 'Tuzluca'],
        Isparta: ['Aksu', 'Atabey', 'Eğirdir', 'Gelendost', 'Gönen', 'Keçiborlu', 'Merkez', 'Senirkent', 'Sütçüler', 'Şarkikaraağaç', 'Uluborlu', 'Yalvaç', 'Yenişarbademli'],
        İstanbul: ['Adalar', 'Arnavutköy', 'Ataşehir', 'Avcılar', 'Bağcılar', 'Bahçelievler', 'Bakırköy', 'Başakşehir', 'Bayrampaşa', 'Beşiktaş', 'Beykoz', 'Beylikdüzü', 'Beyoğlu', 'Büyükçekmece', 'Çatalca', 'Çekmeköy', 'Esenler', 'Esenyurt', 'Eyüp', 'Fatih', 'Gaziosmanpaşa', 'Güngören', 'Kadıköy', 'Kağıthane', 'Kartal', 'Küçükçekmece', 'Maltepe', 'Pendik', 'Sancaktepe', 'Sarıyer', 'Silivri', 'Sultanbeyli', 'Sultangazi', 'Şile', 'Şişli', 'Tuzla', 'Ümraniye', 'Üsküdar', 'Zeytinburnu'],
        İzmir: ['Aliağa', 'Balçova', 'Bayındır', 'Bayraklı', 'Bergama', 'Beydağ', 'Bornova', 'Buca', 'Çeşme', 'Çiğli', 'Dikili', 'Foça', 'Gaziemir', 'Güzelbahçe', 'Karabağlar', 'Karaburun', 'Karşıyaka', 'Kemalpaşa', 'Kınık', 'Kiraz', 'Konak', 'Menderes', 'Menemen', 'Narlıdere', 'Ödemiş', 'Seferihisar', 'Selçuk', 'Tire', 'Torbalı', 'Urla'],
        Kahramanmaraş: ['Afşin', 'Andırın', 'Çağlayancerit', 'Dulkadiroğlu', 'Ekinözü', 'Elbistan', 'Göksun', 'Merkez', 'Nurhak', 'Onikişubat', 'Pazarcık', 'Türkoğlu'],
        Karabük: ['Eflani', 'Eskipazar', 'Karabük', 'Ovacık', 'Safranbolu', 'Yenice'],
        Karaman: ['Ayrancı', 'Başyayla', 'Ermenek', 'Karaman', 'Kazımkarabekir', 'Sarıveliler'],
        Kars: ['Akyaka', 'Arpaçay', 'Digor', 'Kağızman', 'Kars', 'Sarıkamış', 'Selim', 'Susuz'],
        Kastamonu: ['Abana', 'Ağlı', 'Araç', 'Azdavay', 'Bozkurt', 'Cide', 'Çatalzeytin', 'Daday', 'Devrekani', 'Doğanyurt', 'Hanönü', 'İhsangazi', 'İnebolu', 'Kastamonu', 'Küre', 'Pınarbaşı', 'Seydiler', 'Şenpazar', 'Taşköprü', 'Tosya'],
        Kayseri: ['Akkışla', 'Bünyan', 'Develi', 'Felahiye', 'Hacılar', 'İncesu', 'Kocasinan', 'Melikgazi', 'Özvatan', 'Pınarbaşı', 'Sarıoğlan', 'Sarız', 'Talas', 'Tomarza', 'Yahyalı', 'Yeşilhisar'],
        Kırıkkale: ['Bahşılı', 'Balışeyh', 'Çelebi', 'Delice', 'Karakeçili', 'Keskin', 'Kırıkkale', 'Sulakyurt', 'Yahşihan'],
        Kırklareli: ['Babaeski', 'Demirköy', 'Kırklareli', 'Kofçaz', 'Lüleburgaz', 'Pehlivanköy', 'Pınarhisar', 'Vize'],
        Kırşehir: ['Akçakent', 'Akpınar', 'Boztepe', 'Çiçekdağı', 'Kaman', 'Kırşehir', 'Mucur'],
        Kilis: ['Elbeyli', 'Merkez', 'Musabeyli', 'Polateli'],
        Kocaeli: ['Başiskele', 'Çayırova', 'Darıca', 'Derince', 'Dilovası', 'Gebze', 'Gölcük', 'İzmit', 'Kandıra', 'Karamürsel', 'Kartepe', 'Körfez'],
        Konya: ['Ahırlı', 'Akören', 'Akşehir', 'Altınekin', 'Beyşehir', 'Bozkır', 'Cihanbeyli', 'Çeltik', 'Çumra', 'Derbent', 'Derebucak', 'Doğanhisar', 'Emirgazi', 'Ereğli', 'Güneysınır', 'Hadim', 'Halkapınar', 'Hüyük', 'Ilgın', 'Kadınhanı', 'Karapınar', 'Karatay', 'Kulu', 'Meram', 'Sarayönü', 'Selçuklu', 'Seydişehir', 'Taşkent', 'Tuzlukçu', 'Yalıhüyük', 'Yunak'],
        Kütahya: ['Altıntaş', 'Aslanapa', 'Çavdarhisar', 'Domaniç', 'Dumlupınar', 'Emet', 'Gediz', 'Hisarcık', 'Kütahya', 'Pazarlar', 'Şaphane', 'Simav', 'Tavşanlı'],
        Malatya: ['Akçadağ', 'Arapgir', 'Arguvan', 'Battalgazi', 'Darende', 'Doğanşehir', 'Doğanyol', 'Hekimhan', 'Kale', 'Kuluncak', 'Merkez', 'Pütürge', 'Yazıhan', 'Yeşilyurt'],
        Manisa: ['Ahmetli', 'Akhisar', 'Alaşehir', 'Demirci', 'Gölmarmara', 'Gördes', 'Kırkağaç', 'Köprübaşı', 'Kula', 'Merkez', 'Salihli', 'Sarıgöl', 'Saruhanlı', 'Selendi', 'Soma', 'Turgutlu', 'Yunusemre'],
        Mardin: ['Artuklu', 'Dargeçit', 'Derik', 'Kızıltepe', 'Mazıdağı', 'Midyat', 'Nusaybin', 'Ömerli', 'Savur', 'Yeşilli'],
        Mersin: ['Akdeniz', 'Anamur', 'Aydıncık', 'Bozyazı', 'Çamlıyayla', 'Erdemli', 'Gülnar', 'Mezitli', 'Mut', 'Silifke', 'Tarsus', 'Toroslar', 'Yenişehir'],
        Muğla: ['Bodrum', 'Dalaman', 'Datça', 'Fethiye', 'Kavaklıdere', 'Köyceğiz', 'Marmaris', 'Milas', 'Ortaca', 'Seydikemer', 'Ula', 'Yatağan'],
        Muş: ['Bulanık', 'Hasköy', 'Korkut', 'Malazgirt', 'Merkez', 'Varto'],
        Nevşehir: ['Acıgöl', 'Avanos', 'Derinkuyu', 'Gülşehir', 'Hacıbektaş', 'Kozaklı', 'Merkez', 'Ürgüp'],
        Niğde: ['Altunhisar', 'Bor', 'Çamardı', 'Çiftlik', 'Merkez', 'Ulukışla'],
        Ordu: ['Akkuş', 'Altınordu', 'Aybastı', 'Çamaş', 'Çatalpınar', 'Çaybaşı', 'Fatsa', 'Gölköy', 'Gülyalı', 'Gürgentepe', 'İkizce', 'Kabadüz', 'Kabataş', 'Korgan', 'Kumru', 'Mesudiye', 'Perşembe', 'Ulubey', 'Ünye'],
        Osmaniye: ['Bahçe', 'Düziçi', 'Hasanbeyli', 'Kadirli', 'Merkez', 'Sumbas', 'Toprakkale'],
        Rize: ['Ardeşen', 'Çamlıhemşin', 'Çayeli', 'Derepazarı', 'Fındıklı', 'Güneysu', 'Hemşin', 'İkizdere', 'İyidere', 'Kalkandere', 'Merkez', 'Pazar'],
        Sakarya: ['Akyazı', 'Arifiye', 'Erenler', 'Ferizli', 'Geyve', 'Hendek', 'Karapürçek', 'Karasu', 'Kaynarca', 'Kocaali', 'Merkez', 'Pamukova', 'Sapanca', 'Serdivan', 'Söğütlü', 'Taraklı'],
        Samsun: ['Alaçam', 'Asarcık', 'Atakum', 'Ayvacık', 'Bafra', 'Canik', 'Çarşamba', 'Havza', 'İlkadım', 'Kavak', 'Ladik', 'Salıpazarı', 'Tekkeköy', 'Terme', 'Vezirköprü', 'Yakakent'],
        Siirt: ['Ayıpsa', 'Baykan', 'Eruh', 'Kurtalan', 'Merkez', 'Pervari', 'Şirvan'],
        Sinop: ['Ayancık', 'Boyabat', 'Dikmen', 'Durağan', 'Erfelek', 'Gerze', 'Merkez', 'Saraydüzü', 'Türkeli'],
        Sivas: ['Akıncılar', 'Altınyayla', 'Divriği', 'Doğanşar', 'Gemerek', 'Gölova', 'Hafik', 'İmranlı', 'Kangal', 'Koyulhisar', 'Merkez', 'Suşehri', 'Şarkışla', 'Ulaş', 'Yıldızeli', 'Zara'],
        Şanlıurfa: ['Akçakale', 'Birecik', 'Bozova', 'Ceylanpınar', 'Eyyübiye', 'Halfeti', 'Haliliye', 'Harran', 'Hilvan', 'Karaköprü', 'Siverek', 'Suruç', 'Viranşehir'],
        Şırnak: ['Beytüşşebap', 'Cizre', 'Güçlükonak', 'İdil', 'Merkez', 'Silopi', 'Uludere'],
        Tekirdağ: ['Çerkezköy', 'Çorlu', 'Ergene', 'Hayrabolu', 'Kapaklı', 'Malkara', 'Marmaraereğlisi', 'Muratlı', 'Saray', 'Şarköy'],
        Tokat: ['Almus', 'Artova', 'Başçiftlik', 'Erbaa', 'Merkez', 'Niksar', 'Pazar', 'Reşadiye', 'Sulusaray', 'Turhal', 'Yeşilyurt', 'Zile'],
        Trabzon: ['Akçaabat', 'Araklı', 'Arsin', 'Beşikdüzü', 'Çarşıbaşı', 'Çaykara', 'Dernekpazarı', 'Düzköy', 'Hayrat', 'Köprübaşı', 'Maçka', 'Merkez', 'Of', 'Sürmene', 'Tonya', 'Vakfıkebir', 'Yomra'],
        Tunceli: ['Çemişgezek', 'Hozat', 'Mazgirt', 'Merkez', 'Nazımiye', 'Ovacık', 'Pertek'],
        Uşak: ['Banaz', 'Eşme', 'Karahallı', 'Merkez', 'Sivaslı', 'Ulubey'],
        Van: ['Başkale', 'Çaldıran', 'Çatak', 'Edremit', 'Erciş', 'Gevaş', 'Gürpınar', 'İpekyolu', 'Muradiye', 'Özalp', 'Saray', 'Tuşba'],
        Yalova: ['Altınova', 'Armutlu', 'Çınarcık', 'Çiftlikköy', 'Merkez', 'Termal'],
        Yozgat: ['Akdağmadeni', 'Aydıncık', 'Boğazlıyan', 'Çandır', 'Çayıralan', 'Çekerek', 'Kadışehri', 'Merkez', 'Saraykent', 'Sarıkaya', 'Sorgun', 'Şefaatli', 'Yenifakılı', 'Yerköy'],
        Zonguldak: ['Alaplı', 'Çaycuma', 'Devrek', 'Ereğli', 'Gökçebey', 'Karadeniz Ereğli', 'Kilimli', 'Kozlu', 'Merkez']
    },
    BALANCE: {
        HEADING: 'Balance',
        SUMMARY: 'Account Summary',
        SUMMARY_MOBILE: 'Account<br> Summary',
        USER_TRADE_HISTORY: 'Transaction History',
        USER_TRADE_HISTORY_UPPERCASE: 'TRANSACTION HISTORY',
        USER_TRADE_HISTORY_MOBILE: 'Transaction<br> History',
        USER_OPERATION_HISTORY: 'Operation History',
        USER_OPERATION_HISTORY_UPPERCASE: 'OPERATION HISTORY',
        USER_OPERATION_HISTORY_MOBILE: 'Operation<br> History',
        TOTAL_BALANCE: 'Total Balance',
        TL: 'Turkish Lira',
        TL_BALANCE: 'TL Balance',
        CRYPTO_BALANCE: 'Tether(USDT)',
        ETHEREUM_BALANCE: 'Ethereum(ETH)',
        ART_PRODUCTS: 'Art Products',
        ART_PRODUCT: 'Art Product',
        PORTFOLIO: 'Portfolio',
        PORTFOLIO_UPPERCASE: 'PORTFOLIO',
        SYMBOL: 'Symbol',
        AVAILABLE_AMOUNT: 'Available',
        IN_ORDER: 'In order',
        TOTAL_AMOUNT: 'Total',
        VALUE: 'Value',
        NO_TOKEN: 'There is no token belonging to the user.',
        TRADE: 'Trade',
        DEPOSIT: 'Deposit',
        WITHDRAW: 'Withdraw',
        PAYING: 'Deposit',
        WITHDRAWAL: 'Withdrawal',
        USER_OPEN_ORDERS: 'Open Orders',
        USER_OPEN_ORDERS_UPPERCASE: 'OPEN ORDERS',
        ORDER_TYPE: 'Order Type',
        OPERATION_TYPE: 'Operation Type',
        OPERATION_TYPE_MOBILE: 'Operation',
        PENDING: 'Waiting for mail approval',
        ONGOING: 'Ongoing',
        QUEUED: 'Queued',
        WAITING: 'Executing',
        SIGNING: 'Signing',
        CANCELED: 'Canceled',
        EXECUTING: 'Executing',
        COMPLETED: 'Completed',
        EXPIRED: 'Expired',
        ERROR: 'Error',
        AMOUNT: 'Amount',
        KIND: 'Kind',
        PAIR: 'Pair',
        ALL: 'All',
        FILTER: 'Filter',
        BUY: 'Buy',
        SELL: 'Sell',
        DATE: 'Date',
        STATUS: 'Status',
        EXPLANATION: 'Info',
        READ_ACCEPTED: 'Read, accepted',
        NEXT: 'Next',
        INSPECT: 'Inspect',
    },
    SECURITY: {
        HEADING: 'SECURITY',
        HEADING_MOBILE: 'Security',
        CHANGE_PASSWORD: {
            HEADING: 'Change Password',
            HEADING_MOBILE: 'Change<br>Password',
            FORM_HEADING: 'PASSWORD CHANGE FORM',
            FORM_HEADING_MOBILE: 'Password Change Form',
            CURRENT_PASSWORD: 'Current Password',
            NEW_PASSWORD: 'New Password',
            NEW_PASSWORD_AGAIN: 'New Password (Again)',
            PASSWORD_RULES: '(It must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.)',
            SAVE_CHANGES: 'Save Changes'
        },
        TWO_FACTOR_AUTHENTICATOR: {
            HEADING: 'Two Factor Authentication',
            HEADING_REMOVE: 'REMOVE TWO FACTOR AUTHENTICATION (GOOGLE 2FA)',
            HEADING_MOBILE: 'Two Factor<br>Authentication',
            FORM_HEADING: 'TWO FACTOR AUTHENTICATION (GOOGLE 2FA)',
            FORM_HEADING_MOBILE: 'Two Factor Authentication (Google 2FA)',
            DOWNLOAD_2FA_APP: '1. Download Google Authenticator app.',
            INSERT_PASSWORD: '2. Please enter your password to see the QR code generated for Google Authenticator.',
            INSERT_AUTH_PASSWORD: '3. Enter the verification key generated by Google Authenticator.',
            QR_CODE: 'QR Code',
            SECRET_KEY: 'QR Code(Secret Key)',
            AUTHENTICATION_WARNING: 'If you lose your QR code, you won\'t be able to access your verification key.' +
                'So don\'t forget to back up your QR code! Otherwise, you will not be able to login to your account.',
            CURRENT_PASSWORD: 'Current password',
            APPLY: 'Apply',
            AUTH_PASSWORD: 'Authentication Key',
            ACTIVE: 'Two Factor Authentication activated. <strong>Kaldırmak istiyorsanız:</strong>',
            INSERT_PASSWORD_TO_REMOVE: '1. Hesabınızdan Çift Aşamalı Doğrulama (Google 2FA) kaldırmak için lütfen mevcut hesap şifrenizi girin.',
            INSERT_AUTH_PASSWORD_TO_REMOVE: '2. Kaldırma işlemini tamamlamak için Google Authenticator tarafından üretilen kodu girin.',
            COMPLETE: 'Complete',
            REMOVE: 'Remove',
        },
        IDENTITY_VERIFICATION: {
            HEADING: 'Identity Verification',
            HEADING_MOBILE: 'Identity<br>Verification',
            LIMIT_INFO: 'Limit<br>Info',  // obsolete
            LIMITS: {
                DEPOSIT: 'Deposit',
                WITHDRAW: 'Withdrawal',
                LEVEL: 'Level ',
                DAY: 'Day',
                MONTH: 'Month',
                TL: {
                    HEADING: 'Turkish Lira',
                    LEVEL_0_DESCRIPTION: 'Level 1 Required',
                    LEVEL_1_DAILY_LIMIT: '50,000.00₺',
                    LEVEL_1_MONTHLY_LIMIT: '1,000,000.00₺',
                    LEVEL_2_DAILY_LIMIT: '1,000,000.00₺',
                    LEVEL_2_MONTHLY_LIMIT: '10,000,000.00₺',
                },
                CRYPTO: {
                    HEADING: 'Cryptocurrency',
                    DESCRIPTION: 'Level 2 Required',
                    LEVEL_2_DAILY_LIMIT: '100,000.00₮',
                    LEVEL_2_MONTHLY_LIMIT: '1,000,000.00₮',
                },
                ETHEREUM: {
                    HEADING: 'Ethereum',
                    DESCRIPTION: 'Level 2 Required',
                    LEVEL_2_DAILY_LIMIT: '30 ETH',
                    LEVEL_2_MONTHLY_LIMIT: '300 ETH',
                }
            },
            KYC_LEVEL_ONE: {
                FORM_HEADING: 'LEVEL 1 IDENTITY VERIFICATION FORM',
                NAME: 'Name',
                SURNAME: 'Surname',
                BIRTH_YEAR: 'Year of birth',
                TC: 'Turkish Identity Number',
                TOWN: 'City',
                ADDRESS: 'Address',
                COMPLETE: 'Complete',
            },
            KYC_LEVEL_TWO: {
                FORM_HEADING: 'LEVEL 2 IDENTITY VERIFICATION FORM',
                UPLOAD_FILE: 'In the photo you uploaded',
                ID_CARD_EXPLANATION: 'Include a photo in which the entire front of your ID card is clearly visible.',
                ID_CARD_RULE: 'The information on your ID card must be the same as the information you declared for Level 1.',
                FACE_EXPLANATION: 'Add a photo of the front of your ID card, as shown.',
                FACE_RULE: 'Your ID card and face must be clearly visible. ',
                SELECT_FILE: 'Select File',
                COMPLETE: 'Complete',
                WAITING_FOR_APPROVAL: 'Your application for <strong>Level 2</strong> authentication is pending.',
                COMPLETED: 'Your account is at the top level.'
            }
        }
    },
    DEPOSIT: {
        NO_KYC_HEADING: 'Your account must be Level 1',
        NO_KYC_DESCRIPTION: 'For security reasons, you must make an "Identity Statement" in bank deposit and withdrawal transactions.',
        LEVEL_2_NEEDED_HEADING: 'Your account must be Level 2',
        LEVEL_2_NEEDED_DESCRIPTION: 'For security reasons, you must make an "Identity Verification" in bank deposit and withdrawal transactions.',
        LEVEL_UP: 'Level Up',
        HEADING: 'Deposit',
        BANK: 'Bank',
        TETHER: 'Tether(USDT)',
        ETHEREUM: 'Ethereum(ETH)',
        LIMITS: '1. Deposit Limits',
        RULES: '2. Deposit Rules',
        RULES_TITLE: 'Deposit Rules',
        TRANSACTION: 'Depositing Process',
        PREREQUIREMENTS: 'Deposit Prerequirements',
        TL_DEPOSIT_HISTORY: 'TL Deposit History',
        TL_DEPOSIT_REQUIRED: 'At least 1 TL deposit required',
        TL_DEPOSIT_ACCOMPLISHED: 'TL deposit operation completed',
        TL_DEPOSIT_HISTORY_INVALID: 'Deposit history not found',
        TL_DEPOSIT_HISTORY_VALID: 'Deposit history is valid',
        DEPOSIT_NOW: 'Deposit Now',
        DAILY_DEPOSIT_LIMIT: 'Daily Remaining Deposit Limit',
        DAILY_DEPOSIT_LIMIT_MOBILE: 'Daily Remaining<br>Deposit Limit',
        MONTHLY_DEPOSIT_LIMIT: 'Monthly Remaining Deposit Limit',
        MONTHLY_DEPOSIT_LIMIT_MOBILE: 'Monthly Remaining<br>Deposit Limit',
        CRYPTO_DEPOSIT_DESCRIPTION: 'Please select the cryptocurrency you want to deposit and make sure you follow the rules.',
        DEPOSIT_RULES: ['- The bank account you will use for depositing money must belong to you. Otherwise, your transfer will not be approved.',
            '- Transfers from credit cards and ATMs will not be approved.',
            '- Do not forget to write \'Artiox Kripto Varlık Alım Satım Platformu A.Ş.\' to the recipient title when transferring.',
            '- Make sure that you enter the correct IBAN number of our company while making the transfer.',
            '- Please do not forget to enter the code we have created specifically for you in the description section while transferring and do not add any explanation other than this code.',
            '- Transfers that reach us but cannot be confirmed due to incomplete or incorrect information will be returned within 5 work days, deducting bank commissions.',
            '- Transfers made by money order are reflected to your account 24/7, while transfers made via EFT can only be reflected in your account during working hours.',
            '- Deposits made through Papara are not valid. Transfers made through this channel will be sent back within 5 working days, deducting bank commissions.'],
        FIAT_DEPOSIT_RULES: ['- The bank account you will use for depositing money must belong to you. Otherwise, your transfer will not be approved.',
            '- Transfers from credit cards and ATMs will not be approved.',
            '- Do not forget to write \'Artiox Kripto Varlık Alım Satım Platformu A.Ş.\' to the recipient title when transferring.',
            '- Make sure that you enter the correct IBAN number of our company while making the transfer.',
            '- Please do not forget to enter the code we have created specifically for you in the description section while transferring and do not add any explanation other than this code.',
            '- Transfers that reach us but cannot be confirmed due to incomplete or incorrect information will be returned within 5 work days, deducting bank commissions.',
            '- Transfers made by money order are reflected to your account 24/7, while transfers made via EFT can only be reflected in your account during working hours.',
            '- Deposits made through Papara are not valid. Transfers made through this channel will be sent back within 5 working days, deducting bank commissions.'],
        CRYPTO_DEPOSIT_RULES: [
            '- Select the crypto currency you want to deposit.',
            '- Enter your wallet address in your Artiox account by copy-pasting the address to the party you want to send to.',
            '  Be sure to check that the address you copied is correct in the area you pasted.',
            '- You can only deposit ERC20-based Tether (USDT) on the Ethereum blockchain to the deposit address.',
            '- Sending coins or tokens other than USDT to this address will result in the loss of what you sent.',
            '- The minimum amount that can be deposited is 50 USDT. Deposits below 50 USDT are not valid and will not be refunded.',
            '- All valid transfers to this wallet address will be automatically credited to your Artiox balance.',
        ],
        ETHEREUM_DEPOSIT_RULES: [
            '- Select the type of cryptocurrency you want to deposit.',
            '- Enter the wallet address from your Artiox account to the recipient using copy-paste.',
            '- Always check the accuracy of the address you copied after you paste it.',
            '- You can only deposit ETH through the Ethereum blockchain to the deposit address.',
            '- Sending coins or tokens other than ETH to this address will result in the loss of what you send.',
            '- The minimum deposit amount is 0.005 ETH. Deposit transactions below 0.005 ETH are not valid and will not be refunded.',
            '- All valid transfers to this wallet address are automatically reflected in your Artiox balance.',
        ],
        CRYPTO_DEPOSIT_PREREQUIREMENTS: [
            'Make sure you follow the rules below to be able to deposit Tether(USDT).',
            '- Your account must be level 2',
            '- You must deposit Turkish Lira at least once.'
        ],
        ETHEREUM_DEPOSIT_PREREQUIREMENTS: [
            'Make sure you follow the rules below to be able to deposit Ethereum(ETH).',
            '- Your account must be level 2',
            '- You must deposit Turkish Lira at least once.'
        ],
        BANK_NAME: 'Bank Name',
        ACCOUNT_ADDRESS: 'Wallet Address',
        WALLET_OF: 'Wallet',
        COPY_ADDRESS: 'Copy address',
        ACCOUNT_NAME: 'Account Name',
        IBAN: 'IBAN',
        DEPOSIT_REF_CODE: 'References Code',
        OBEY_RULES: 'Please make sure you follow the deposit rules.',
        COPIED: 'Copied Successfully',
        COPY: 'Copy',
        DEPOSIT_WARNING: 'Please be sure that obeying the deposit rules.',
        DEPOSIT_HISTORY: 'DEPOSIT HISTORY',
        DEPOSIT_HISTORY_MOBILE: 'Deposit History',
        TL_SUSPENSION: {
            SUSPENDED: false,
            TITLE: '',
            DESCRIPTION: '',
        },
        TETHER_SUSPENSION: {
            SUSPENDED: false,
            TITLE: 'Suspended due to the Merge',
            DESCRIPTION: 'Due to the Merge on the Ethereum network, Tether deposits and withdrawals are suspended until <strong>September 20, 2022</strong>.<br>Thank you for your understanding.',
        },
    },
    WITHDRAWAL: {
        HEADING: 'Withdrawal',
        BANK: 'Bank',
        TETHER: 'Tether(USDT)',
        ETHEREUM: 'Ethereum(ETH)',
        LIMITS: '1. Withdrawal Limits',
        BANK_ACCOUNT: '2. Account Info',
        ACCOUNT_ADDRESS: 'Wallet Address',
        SAVED_BANK_ACCOUNTS_PLACEHOLDER: 'Registered Accounts',
        SAVED_CRYPTO_ACCOUNTS_PLACEHOLDER: 'Registered Wallets',
        NO_SAVED_BANK_ACCOUNT: 'You don\'t have any registered accounts.',
        NO_SAVED_CRYPTO_ACCOUNT: 'You don\'t have any registered wallets.',
        ADD_NEW_BANK_ACCOUNT: 'Add New Account',
        ADD_NEW_CRYPTO_ACCOUNT: 'Add New Wallet',
        PLEASE_SELECT_BANK: 'For withdrawal, please select the account to which the money will be deposited.',
        PLEASE_SELECT_WALLET: 'For withdrawal, please select the wallet to which the money will be deposited.',
        RULES_TITLE: 'Withdrawal Rules',
        TRANSACTION: '3. Amount To Withdraw:',
        TRANSACTION_MOBILE: 'Amount To Withdraw:',
        DAILY_WITHDRAWAL_LIMIT: 'Daily Remaining Withdrawal Limit',
        DAILY_WITHDRAWAL_LIMIT_MOBILE: 'Daily Remaining<br>Withdrawal Limit',
        MONTHLY_WITHDRAWAL_LIMIT: 'Monthly Remaining Withdrawal Limit',
        MONTHLY_WITHDRAWAL_LIMIT_MOBILE: 'Monthly Remaining<br>Withdrawal Limit',
        WITHDRAWAL_RULES: ['- You can only withdraw money to your personal accounts.',  // obsolete
            '- The amount you want to withdraw will be deducted from your available balance.',
            '- The minimum withdrawal amount is 100 Turkish Lira.',
            '- A commission of 4 liras is deducted for each withdrawal.',
            'Warning: In order for your withdrawal request to take place, you must provide an e-mail confirmation. Unconfirmed requests will expire after 24 hours and the relevant amount will be charged to your available balance.(Your e-mail may end up in the Spam Box in some cases.)'],  // obsolete
        FIAT_WITHDRAWAL_RULES: ['- You can only withdraw money to your personal accounts.',
            '- The amount you want to withdraw will be deducted from your available balance.',
            '- The minimum withdrawal amount is 100 Turkish Lira.',
            '- A commission of 4 liras is deducted for each withdrawal.',
            'Warning: In order for your withdrawal request to take place, you must provide an e-mail confirmation.  Unconfirmed requests will expire after 24 hours and the relevant amount will be charged to your available balance.  (Your e-mail may end up in the Spam Box in some cases.)'
        ],
        CRYPTO_WITHDRAWAL_RULES: [
            '- In order to withdraw crypto currency, you must first define the wallet address you want to withdraw to.',
            '- Make sure that the wallet address you have defined is a wallet address belonging to the Ethereum blockchain.',
            '- You can use the addresses you defined before or add a new withdrawal address.',
            '- The amount you want to withdraw will be deducted from your available USDT balance.',
            '- The minimum withdrawal amount is 50 USDT.',
            '- For each withdrawal, the commission amount calculated according to the instantaneous state of the Ethereum network will be deducted from the amount you want to withdraw.',
            '- The requested commission fee is the amount paid to the miners for the transfer transaction, and Artiox cannot interfere with this.',
            '- In order for your withdrawal request to be processed, you must approve your request via the e-mail we sent you.',
            '- Unconfirmed requests will expire after 24 hours, and the relevant amount will be returned in your available USDT balance. (The confirmation email may end up in the Spam Box in some cases.)',
            '- Your transfer will be initiated shortly after you approve your withdrawal request and it is technically not possible to stop or cancel the transfer.',
            '- Transfers to wrong or faulty wallet addresses are the responsibility of the users, and it is not possible to return the transferred funds.',
        ],
        ETHEREUM_WITHDRAWAL_RULES: [
            '- To withdraw cryptocurrency, you must first specify the wallet address you want to withdraw to.',
            '- Ensure that the wallet address you specify belongs to the Ethereum network.',
            '- You can use previously specified addresses or add a new withdrawal address.',
            '- The amount you wish to withdraw will be deducted from your available ETH balance.',
            '- The minimum withdrawal amount is 0.005 ETH.',
            '- A commission fee, calculated based on the current state of the Ethereum network, will be deducted from the amount you wish to withdraw.',
            '- The requested commission amount is paid to the miners for the transfer process, and Artiox cannot interfere with this.',
            '- For your withdrawal request to be processed, you must confirm this request through the email we sent you.',
            '- Unconfirmed requests will expire after 24 hours, and the related amount will be reflected in your available ETH balance. (The confirmation email may end up in the Spam Folder in some cases.)',
            '- Shortly after confirming your withdrawal request, your transfer will be initiated, and it is technically impossible to stop or cancel the transfer.',
            '- Transfers to incorrect or faulty wallet addresses are the responsibility of the users, and it is not possible to return the transferred funds.',
        ],
        BANK_NAME: 'Bank Name',
        ACCOUNT_NAME: 'Account Name',
        IBAN: 'IBAN',
        OBEY_RULES: 'Please make sure you follow the withdrawal rules.',
        COPIED: 'Copied Successfully',
        COPY: 'Copy',
        WITHDRAWAL_WARNING: 'Please make sure you follow the withdrawal rules.',
        APPROVE_MAIL_WARNING: 'Approve your withdrawal request with the e-mail we sent.',
        WITHDRAWAL_HISTORY: 'WITHDRAWAL HISTORY',
        WITHDRAWAL_HISTORY_MOBILE: 'Withdrawal History',
        REMOVE_BANK_ACCOUNT: 'Remove Account',
        REMOVE_CRYPTO_ACCOUNT: 'Remove Wallet',
        REMOVE_BANK_ACCOUNT_MOBILE: 'Remove',
        REMOVE_CRYPTO_ACCOUNT_MOBILE: 'Remove',
        MINIMUM_WITHDRAWAL_AMOUNT: 'The minimum withdrawal amount is 100 Turkish Lira.',  // obsolete
        MINIMUM_FIAT_WITHDRAWAL_AMOUNT: 'The minimum withdrawal amount is 100 Turkish Lira.',
        MINIMUM_CRYPTO_WITHDRAWAL_AMOUNT: 'The minimum withdrawal amount is 50 USDT.',
        MINIMUM_ETHEREUM_WITHDRAWAL_AMOUNT: 'The minimum withdrawal amount is 0.005 ETH.',
        ADD_BANK_ACCOUNT: {
            HEADING: 'ADD NEW BANK ACCOUNT',
            IBAN: 'IBAN',
            IBAN_ERROR_LIST: {
                IBAN_NOT_ALPHA_NUMERIC: 'IBAN Code Must Be Alphanumeric.',
                IBAN_COUNTRY_CODE: 'Country Code Must Be Written in Capital Letters and Must Consist of 2 Letters.',
                IBAN_NOT_TURKEY: 'Country Code Not Turkey.',
                IBAN_CONTROL_CODE: 'Control Code Must Consist of Digits.',
                IBAN_LONGER_THAN: 'IBAN Code Length is longer than Turkish Standard (26 characters)',
                IBAN_SHORTER_THAN: 'IBAN Code Length is shorter than Turkish Standard (26 characters)',
                IBAN_BANK_CODE: 'Bank Code Must Be Numbers Only.',
            },
            NAME_SURNAME: 'Name and Surname',
            ACCOUNT_NAME: 'Account Name',
            SAVE_ACCOUNT: 'Save Account',
            FILL_THE_FORM: 'Please fill out the form!'
        },
        ADD_CRYPTO_ACCOUNT: {
            HEADING: 'ADD NEW CRYPTO WALLET',
            BLOCK_CHAIN: 'Blockchain',
            ACCOUNT_ADDRESS: 'Wallet Address',
            CRYPTO_ACCOUNT_NAME: 'Wallet Name',
            SAVE_CRYPTO_ACCOUNT: 'Save Wallet',
            SELECT_BLOCK_CHAIN: 'Select Blockchain',
        },
        AVAILABLE_TRY: 'Available: ',
        AVAILABLE: 'Available',
        WITHDRAWAL_REQUEST: 'Request a Withdrawal',
        WITHDRAWAL_REQUEST_SUM_HEADING: 'Request Summary',
        PLEASE_SELECT_BANK_ACCOUNT: 'You must select your bank account',
        PLEASE_SELECT_CRYPTO_ACCOUNT: 'You must select your wallet',
        WITHDRAWAL_AMOUNT: 'Amount To Withdraw',
        WITHDRAWAL_FEE: 'Transfer Fee',
        WITHDRAWAL_SUM: 'The Amount To Be Deposited',
        WITHDRAWAL_ACCOUNT: 'Account info:',
        WITHDRAWAL_WALLET: 'Wallet info:',
        STATUS_ICONS: {
            PENDING: './assets/images/pending.svg#pending',
            QUEUED: './assets/images/queued.svg#queued',
            WAITING: './assets/images/executing.svg#executing',
            EXECUTING: './assets/images/executing.svg#executing',
            COMPLETED: './assets/images/blue_tick_icon_no_size.svg#blue_tick_icon_no_size',
            EXPIRED: './assets/images/withdrawal_expired.svg#withdrawal_expired',
            ERROR: './assets/images/error_icon.svg#error_icon',
            CANCELED: './assets/images/withdrawal_expired.svg#withdrawal_expired',
            SIGNING: './assets/images/blog.svg#blog',
        },
        TL_SUSPENSION: {
            SUSPENDED: false,
            TITLE: '',
            DESCRIPTION: '',
        },
        TETHER_SUSPENSION: {
            SUSPENDED: false,
            TITLE: 'Suspended due to the Merge',
            DESCRIPTION: 'Due to the Merge on the Ethereum network, Tether(USDT) deposits and withdrawals are suspended until <strong>September 20, 2022</strong>.<br>Thank you for your understanding.',
        },
    },
    WITHDRAWAL_CONFIRMED: {
        HEADING: 'YOUR WITHDRAWAL REQUEST IS APPROVED',
        DESCRIPTION: 'Your withdrawal is queued. Your request will be fulfilled as soon as possible.',
        IMG_SRC: './assets/images/withdrawal_approved.webp',
        IMG_ALT: 'Your withdrawal is queued. Your request will be fulfilled as soon as possible.',
        BACK_TO_HOME: 'Back to Homepage'
    },
    WITHDRAWAL_ALREADY_CONFIRMED: {
        HEADING: 'THIS REQUEST HAS BEEN CONFIRMED BEFORE',
        DESCRIPTION: 'If your request has not been fulfilled yet, you can contact us via support@artiox.com.',
        IMG_SRC: './assets/images/withdrawal_approved.webp',
        IMG_ALT: 'If your request has not been fulfilled yet, you can contact us via support@artiox.com.',
        BACK_TO_HOME: 'Back to Homepage'
    },
    WITHDRAWAL_EXPIRED: {
        HEADING: 'YOUR WITHDRAWAL REQUEST HAS EXPIRED',
        DESCRIPTION: 'Your withdrawal has expired because you didn\'t give an email confirmation within 24 hours of creating the request.' +
            ' The amount related to the cancellation of your request has been reflected back to your available balance.' +
            ' You can request again to perform the withdrawal.',
        IMG_SRC: './assets/images/withdrawal_expired.webp',
        IMG_ALT: 'Your withdrawal has expired because you didn\'t give an email confirmation within 24 hours of creating the request.',
        BACK_TO_HOME: 'Back to Homepage'
    },
    CURRENCY_CONVERTER: {
        BUY: 'BUY',
        SELL: 'SELL',
        LAST_PRICE: 'Last Price :',
        AMOUNT_TO_BUY: {STRING: 'Amount of $SYMBOL to Buy', TOKENS: ['$SYMBOL']},
        AMOUNT_TO_SELL: {STRING: 'Amount of $SYMBOL to Sell', TOKENS: ['$SYMBOL']},
        TL_AMOUNT: 'Approximate TRY Amount',
        SUMMARY_TITLE: 'Request Summary',
        FEE: 'Processing Fee',
        NET_AMOUNT: 'Amount to Transfer',
        TOTAL_BUY_PRICE: 'Total Price You Will Pay',
        TOTAL_SELL_PRICE: 'Total Price You Will Get',
    },
    BANK_ACCOUNT_IMG_SRCS: {
        'TC Merkez Bankası': './assets/images/banks/TC Merkez Bankası.webp',
        'İller Bankası': './assets/images/banks/İller Bankası.webp',
        'Ziraat Bankası': './assets/images/banks/Ziraat Bankası.webp',
        'Halkbank': './assets/images/banks/Halkbank.webp',
        'Sınai Kalkınma Bankası': './assets/images/banks/Sınai Kalkınma Bankası.webp',
        'Vakıflar Bankası': './assets/images/banks/Vakıflar Bankası.webp',
        'Eximbank': './assets/images/banks/Eximbank.webp',
        'Kalkınma Bankası': './assets/images/banks/Kalkınma Bankası.webp',
        'Birleşik Fon Bankası': './assets/images/banks/Birleşik Fon Bankası.webp',
        'Türk Ekonomi Bankası': './assets/images/banks/Türk Ekonomi Bankası.webp',
        'Akbank': './assets/images/banks/Akbank.webp',
        'Şekerbank': './assets/images/banks/Şekerbank.webp',
        'Garanti Bankası': './assets/images/banks/Garanti Bankası.webp',
        'İş Bankası': './assets/images/banks/İş Bankası.webp',
        'Yapı ve Kredi Bankası': './assets/images/banks/Yapı ve Kredi Bankası.webp',
        'Arap Türk Bankası': './assets/images/banks/Arap Türk Bankası.webp',
        'Citibank': './assets/images/banks/Citibank.webp',
        'Bank Mellat': './assets/images/banks/Bank Mellat.webp',
        'Turkish Bank': './assets/images/banks/Turkish Bank.webp',
        'JPMorgan Chase Bank': './assets/images/banks/JPMorgan Chase Bank.webp',
        'ING Bank': './assets/images/banks/ING Bank.webp',
        'Adabank': './assets/images/banks/Adabank.webp',
        'Fibabanka': './assets/images/banks/Fibabanka.webp',
        'Turkland Bank': './assets/images/banks/Turkland Bank.webp',
        'ICBC Turkey Bank': './assets/images/banks/ICBC Turkey Bank.webp',
        'Finansbank': './assets/images/banks/Finansbank.webp',
        'Deutsche Bank': './assets/images/banks/Deutsche Bank.webp',
        'Pasha Yatırım Bankası': './assets/images/banks/Pasha Yatırım Bankası.webp',
        'Standard Chartered Yatırım Bankası': './assets/images/banks/Standard Chartered Yatırım Bankası.webp',
        'Societe Generale': './assets/images/banks/Societe Generale.webp',
        'HSBC': './assets/images/banks/HSBC.webp',
        'Alternatif Bank': './assets/images/banks/Alternatif Bank.webp',
        'Burgan Bank': './assets/images/banks/Burgan Bank.webp',
        'Merrill Lynch Yatırım Bank': './assets/images/banks/Merrill Lynch Yatırım Bank.webp',
        'Takasbank': './assets/images/banks/Takasbank.webp',
        'Denizbank': './assets/images/banks/Denizbank.webp',
        'Anadolu Bank': './assets/images/banks/Anadolu Bank.webp',
        'Rabobank': './assets/images/banks/Rabobank.webp',
        'Dilerbank': './assets/images/banks/Dilerbank.webp',
        'GSD Bank': './assets/images/banks/GSD Bank.webp',
        'Nurol Yatırım Bankası': './assets/images/banks/Nurol Yatırım Bankası.webp',
        'Bankpozitif Kredi ve Kalkınma Bankası': './assets/images/banks/Bankpozitif Kredi ve Kalkınma Bankası.webp',
        'Aktif Yatırım Bankası': './assets/images/banks/Aktif Yatırım Bankası.webp',
        'Odea Bank': './assets/images/banks/Odea Bank.webp',
        'Bank of Tokyo-Mitsubishi UFJ Turkey': './assets/images/banks/Bank of Tokyo-Mitsubishi UFJ Turkey.webp',
        'Intesa Sanpaolo S.p.A': './assets/images/banks/Intesa Sanpaolo S.p.A.webp',
        'Albaraka Türk Katılım Bankası': './assets/images/banks/Albaraka Türk Katılım Bankası.webp',
        'Kuveyt Türk Katılım Bankası': './assets/images/banks/Kuveyt Türk Katılım Bankası.webp',
        'Türkiye Finans Katılım Bankası': './assets/images/banks/Türkiye Finans Katılım Bankası.webp',
        'Ziraat Katılım Bankası': './assets/images/banks/Ziraat Katılım Bankası.webp',
        'Vakıf Katılım Bankası': './assets/images/banks/Vakıf Katılım Bankası.webp',
        'Merkezi Kayıt Kuruluşu': './assets/images/banks/Merkezi Kayıt Kuruluşu.webp',
    },
    CRYPTO_TOKEN_IMG_SRCS: {
        'Tether': './assets/images/tokens/Tether.webp',
        'USD Coin': './assets/images/tokens/USD Coin.webp',
        'Ethereum': './assets/images/tokens/Ethereum.webp',
    },
    CURRENCY_NAMES: {
        0: 'Turkish Lira',
        1: 'Tether'
    },
    CRYPTO_ACCOUNT_IMG_SRCS: {
        'Ethereum Main Net': './assets/images/networks/Ethereum.png',
        // 'Ethereum Goerli-Prater Test Net': './assets/images/networks/Ethereum.png',
        'Ethereum Sepolia Test Net': './assets/images/networks/Ethereum.png',
    },
    SIGN_UP_OR_LOGIN: {
        HEADING_BALANCE: 'To inspect your assets:',
        HEADING_USER_OPERATIONS: 'To view your Open Orders and Operations:',
        SIGN_UP: 'Sign Up',
        OR: ' or ',
        LOGIN: 'Log In'
    },
    FAQ: {
        HEADING: 'FREQUENTLY ASKED QUESTIONS (F.A.Q.)',
        HEADING_2: 'ANNOUNCEMENTS',
        TOPICS: [
            {
                HEADING: 'What is Artiox?',
                INFO: 'Artiox is a new generation art investment platform where you can invest in precious works of art and manage your portfolio easily.',
            },
            {
                HEADING: 'How Does Artiox Work?',
                INFO: 'Valuable artworks selected in line with the decisions of art experts are insured at the prices determined by the experts and tokenized (ERC20) on the blockchain.' +
                    ' The artworks, whose supply is fixed with Smart Contract technology, are listed on the platform after the Pre-Sale and Public Sale process and become ready for 24/7 trade.'
            },
            {
                HEADING: 'What Are the Commissions and Fees?',
                INFO: 'It is completely free to become a member of Artiox. Buying and selling commissions are set at 0.5% per transaction.',
            },
        ]
    },
    BASIC: {
        ART_PRODUCTS: 'ARTWORKS',
        PAIR_HEADER: {
            LAST_PRICE: 'Last Price',
            ART_PRODUCT_MARKET_VALUE: 'Market Value of Artwork ',
            VOLUME_24_HOUR_DESKTOP: '24 Hour Volume(₺): ',
            CHANGE_24_HOUR_DESKTOP: '24 Hour Change: ',
            VOLUME_24_HOUR_MOBILE: '24H Volume: ',
            CHANGE_24_HOUR_MOBILE: '24H Change: '
        },
        TRADE: {
            AMOUNT_TO_BUY_MOBILE: {STRING: 'Amount of <br> $SYMBOL to Buy', TOKENS: ['$SYMBOL']},
            AMOUNT_TO_SELL_MOBILE: {STRING: 'Amount of <br> $SYMBOL to Sell', TOKENS: ['$SYMBOL']},
            AMOUNT_TO_BUY_DESKTOP: {STRING: 'Amount of $SYMBOL to Buy', TOKENS: ['$SYMBOL']},
            AMOUNT_TO_SELL_DESKTOP: {STRING: 'Amount of $SYMBOL to Sell', TOKENS: ['$SYMBOL']},
            AVAILABLE_AMOUNT: {STRING: 'Avbl. $SYMBOL: ', TOKENS: ['$SYMBOL']},
            AVAILABLE_AMOUNT_ADVANCE: {STRING: 'Avbl. $AMOUNT $SYMBOL ', TOKENS: ['$AMOUNT', '$SYMBOL']},
            SWAP: 'Swap',
            AVERAGE_COST_MOBILE: {STRING: 'Approximate<br> $SYMBOL Amount', TOKENS: ['$SYMBOL']},
            AVERAGE_COST_DESKTOP: {STRING: 'Approximate $SYMBOL Amount', TOKENS: ['$SYMBOL']},
            BUY: 'BUY',
            SELL: 'SELL',
        }
    },
    PRE_SALE: {
        PRE_SALE_TOKEN_LEFT: 'Pre-Sale Remaining Amount',
        PUBLIC_SALE_TOKEN_LEFT: 'Public Sale Remaining Amount',
        LOCKED: 'Locked',
        PRE_SALE: 'Pre-Sale',
        PUBLIC_SALE: 'Public Sale',
        PRE_SALE_NOT_STARTED: 'Sale Has Not Started Yet',
        BUY: 'BUY',
        BUY_SELL: 'TRADE',
        OVER: 'OVER',
        TOKENMETRICS: {
            HEADING: 'TOKEN METRICS',
            NAME: 'Name',
            SYMBOL: 'Symbol',
            TOTAL_SUPPLY: 'Total Supply',
            CIRCULATING_SUPPLY: 'Circulating Supply',
            PRE_SALE_CIRCULATING_SUPPLY: 'Pre-Sale Amount',
            PRE_SALE_PRICE: 'Pre-Sale Price',
            PUBLIC_SALE_CIRCULATING_SUPPLY: 'Public Sale Amount',
            PUBLIC_SALE_PRICE: 'Public Sale Price',
            LISTING_DATE: 'Listing Date',
            CONTRACT_ADDRESS: 'Contract Address',
        },
        PUBLIC_SALE_ARTWORKS: 'ARTWORKS ON PUBLIC SALE:',
    },
    ADVANCED: {
        PAIR: 'Pair',
        PRICE: 'Price',
        CHANGE: '24H Change',
        LIMIT: 'Limit',
        MARKET: 'Market',
        MARKET_ALTER: 'Market',
        LOW_24_HOUR: '24H Lowest',
        HIGH_24_HOUR: '24H Highest',
        BALANCE: 'Balance',
        TRADE: {
            HEADER_BUY: {STRING: 'BUY $SYMBOL', TOKENS: ['$SYMBOL']},
            HEADER_SELL: {STRING: 'SELL $SYMBOL', TOKENS: ['$SYMBOL']},
            PRICE: {STRING: 'Price($SYMBOL):', TOKENS: ['$SYMBOL']},
            AMOUNT: {STRING: 'Amt.($SYMBOL):', TOKENS: ['$SYMBOL']},
            APPROX_COST: {STRING: 'Approx.($SYMBOL):', TOKENS: ['$SYMBOL']},
            TOTAL: {STRING: 'Total($SYMBOL):', TOKENS: ['$SYMBOL']},
        },
        USER_BALANCE: {
            AVAILABLE: 'Available:',
            IN_ORDER: 'In Order:',
            TOTAL: 'Total:',
            TOTAL_BALANCE: 'Total Balance:'
        },
        TRADE_CHART: {
            ONE_MIN: '1Min',
            FIVE_MINS: '5Min',
            FIFTEEN_MINS: '15Min',
            THIRTY_MINS: '30Min',
            ONE_HOUR: '1H',
            FOUR_HOURS: '4H',
            TWELVE_HOURS: '12H',
            DAY: 'D',
            WEEK: 'W',
            MONTHLY: 'M',
            BID: 'Bid',
            ASK: 'Ask',
            PRICE: 'Price',
        },
        ORDER_BOOK: {
            HEADER: 'Order Book',
            PRICE: 'Price',
            AMOUNT: 'Amount',
            TOTAL: 'Total',
            LAST_PRICE: 'Last Price: ',
        },
        TRADE_HISTORY: {
            HEADER: 'Trade History',
            PRICE: 'Price',
            AMOUNT: 'Amount',
            TIME: 'Time'
        },
        USER_OPEN_ORDERS: {
            HEADING: 'Open Orders',
            PAIR: 'Pair',
            PRICE: 'Price',
            TYPE: 'Operation Type',
            AMOUNT: 'Amount',
            CANCEL: 'Cancel',
            MORE: 'Details',
            BUY: 'Buy',
            SELL: 'Sell',
            TIME: 'Date',
            FILLED_AMOUNT: 'Filled Amount',
            FILLED_VOLUME: 'Filled Volume',
            FILLED_PERCENTAGE: 'Filled(%)',
            TOTAL_AMOUNT: 'Total Amount',
            TOTAL_VOLUME: 'Total Volume',
        },
        USER_TRADE_HISTORY: {
            HEADING: 'Trade History',
            PAIR: 'Pair',
            PRICE: 'Price',
            TYPE: 'Operation Type',
            TYPE_MOBILE: 'Operation',
            AMOUNT: 'Amount',
            MORE: 'Details',
            BUY: 'Buy',
            SELL: 'Sell',
            TIME: 'Date',
            TOTAL: 'Total',
        }
    },
    AUCTION: auction_config_en,
    GUIDEBOOK: {
        // HEADING: 'Guidebook',
        HEADING: 'ARTIOX',
        DESCRIPTION: 'You can find all the detailed information about Artiox with the guidebook.',
        HEADER: {
            ARTIOX: 'Artiox Guidebook',
            LEGAL: 'Legal',
            CAREER: 'Career',
        },
        GUIDEBOOK: [
            {
                HEADING: 'About Us',
                LINKS: [
                    {
                        TEXT: 'Who are We?',
                        LINK: {
                            url: 'https://support.artiox.com/hc/tr/articles/4414402456465-K%C4%B1saca-Biz',
                            is_inner: false,
                            target: '_blank'
                        },
                    },
                    {
                        TEXT: 'Our Story',
                        LINK: {
                            url: 'https://support.artiox.com/hc/tr/articles/4414417569937-Hikayemiz',
                            is_inner: false,
                            target: '_blank'
                        },
                    },
                    {
                        TEXT: 'Contact',
                        LINK: {
                            url: 'https://support.artiox.com/hc/tr/articles/4423184358161-%C4%B0leti%C5%9Fim',
                            is_inner: false,
                            target: '_blank'
                        },
                    },
                ]
            },
            {
                HEADING: 'What is Artiox?',
                LINKS: [
                    {
                        TEXT: 'What is Artiox?',
                        LINK: {
                            url: 'https://support.artiox.com/hc/tr/articles/4414430588433-Artiox-Nedir-',
                            is_inner: false,
                            target: '_blank'
                        },
                    },
                    {
                        TEXT: 'Why Artiox?',
                        LINK: {
                            url: 'https://support.artiox.com/hc/tr/articles/4414417314193-Neden-Artiox-',
                            is_inner: false,
                            target: '_blank'
                        },
                    },
                ]
            },
            {
                HEADING: 'How It Works?',
                LINKS: [
                    {
                        TEXT: 'How It Works?',
                        LINK: {
                            url: 'https://support.artiox.com/hc/tr/articles/4414402601361-Nas%C4%B1l-%C3%87al%C4%B1%C5%9F%C4%B1r-',
                            is_inner: false,
                            target: '_blank'
                        },
                    },
                ]
            },
        ],
        LEGAL: [
            {
                HEADING: 'Limits and Rules',
                LINKS: [
                    {
                        TEXT: 'Limits and Rules',
                        LINK: {
                            url: 'https://support.artiox.com/hc/tr/articles/4414505009681-Limitler-ve-Kurallar',
                            is_inner: false,
                            target: '_blank'
                        },
                    }
                ]
            },
            {
                HEADING: 'Commissions',
                LINKS: [
                    {
                        TEXT: 'Commissions',
                        LINK: {
                            url: 'https://support.artiox.com/hc/tr/articles/4414483870865-Komisyonlar',
                            is_inner: false,
                            target: '_blank'
                        },
                    },
                ]
            },
            {
                HEADING: 'Legal Texts',
                LINKS: [
                    {
                        TEXT: 'User Agreement',
                        LINK: {
                            url: 'https://www.artiox.com/assets/pdfs/Artiox Kullanıcı Sözleşmesi.pdf',
                            is_inner: false,
                            target: '_blank'
                        },
                    },
                    {
                        TEXT: 'Personal Data Protection Law Policy',
                        LINK: {
                            url: 'https://www.artiox.com/assets/pdfs/Artiox%20KVKK%20Politikas%C4%B1.pdf',
                            is_inner: false,
                            target: '_blank'
                        },
                    },
                    {
                        TEXT: 'Information Security Policy',
                        LINK: {
                            url: 'https://www.artiox.com/assets/pdfs/Artiox%20Bilgi%20G%C3%BCvenli%C4%9Fi%20Politikas%C4%B1.pdf',
                            is_inner: false,
                            target: '_blank'
                        },
                    },
                    {
                        TEXT: 'Data Deletion Policy',
                        LINK: {
                            url: 'https://www.artiox.com/assets/pdfs/Artiox%20Ki%C5%9Fisel%20Veri%20Saklama%20ve%20%C4%B0mha%20Politikas%C4%B1.pdf',
                            is_inner: false,
                            target: '_blank'
                        },
                    },
                    {
                        TEXT: 'KVKK Application Form',
                        LINK: {
                            url: 'https://www.artiox.com/assets/pdfs/Artiox%20Ki%C5%9Fisel%20Veri%20Sahibi%20Ba%C5%9Fvuru%20Formu.pdf',
                            is_inner: false,
                            target: '_blank'
                        },
                    },
                    {
                        TEXT: 'Cookie Clarification Text',
                        LINK: {
                            url: 'https://www.artiox.com/assets/pdfs/Artiox%20%C3%87erez%20Ayd%C4%B1nlatma%20Metni.pdf',
                            is_inner: false,
                            target: '_blank'
                        },
                    },
                ]
            },
        ],
    },
    LEGAL: {
        HEADING: 'Yasal',
        INSPECT: 'İncele',
        // SHOW_AS_PDF: '.pdf olarak görüntüle',
        // TEXTS: [
        //   {
        //     HEADING : 'Kullanıcı Sözleşmesi',
        //     SRC: './assets/pdfs/Artiox Kullanıcı Sözleşmesi.pdf',
        //     LINK: {url: 'https://www.artiox.com/assets/pdfs/Artiox Kullanıcı Sözleşmesi.pdf', is_inner: false, target: '_blank'},
        //   },
        //   {
        //     HEADING : 'KVKK Politikası',
        //     SRC: './assets/pdfs/Artiox KVKK Politikası.pdf',
        //     LINK: {url: 'https://www.artiox.com/assets/pdfs/Artiox%20KVKK%20Politikas%C4%B1.pdf', is_inner: false, target: '_blank'},
        //   },
        //   {
        //     HEADING : 'Bilgi Güvenliği Politikası',
        //     SRC: './assets/pdfs/Artiox Bilgi Güvenliği Politikası.pdf',
        //     LINK: {url: 'https://www.artiox.com/assets/pdfs/Artiox%20Bilgi%20G%C3%BCvenli%C4%9Fi%20Politikas%C4%B1.pdf', is_inner: false, target: '_blank'},
        //   },
        //   {
        //     HEADING : 'Kişisel Veri Saklama ve İmha Politikası',
        //     SRC: './assets/pdfs/Artiox Kişisel Veri Saklama ve İmha Politikası.pdf',
        //     LINK: {url: 'https://www.artiox.com/assets/pdfs/Artiox%20Ki%C5%9Fisel%20Veri%20Saklama%20ve%20%C4%B0mha%20Politikas%C4%B1.pdf', is_inner: false, target: '_blank'},
        //   },
        //   {
        //     HEADING : 'Kişisel Veri Sahibi Başvuru Formu',
        //     SRC: './assets/pdfs/Artiox Kişisel Veri Sahibi Başvuru Formu.pdf',
        //     LINK: {url: 'https://www.artiox.com/assets/pdfs/Artiox%20Ki%C5%9Fisel%20Veri%20Sahibi%20Ba%C5%9Fvuru%20Formu.pdf', is_inner: false, target: '_blank'},
        //   },
        //   {
        //     HEADING : 'Çerez Aydınlatma Metni',
        //     SRC: './assets/pdfs/Artiox Çerez Aydınlatma Metni.pdf',
        //     LINK: {url: 'https://www.artiox.com/assets/pdfs/Artiox%20%C3%87erez%20Ayd%C4%B1nlatma%20Metni.pdf', is_inner: false, target: '_blank'},
        //   },
        //
        //   {
        //     HEADING : 'KVKK Aydınlatma Metni',
        //     SRC: './assets/pdfs/Artiox KVKK Aydınlatma Metni.pdf',
        //     LINK: {url: 'https://www.artiox.com/assets/pdfs/Artiox%20KVKK%20Ayd%C4%B1nlatma%20Metni.pdf', is_inner: false, target: '_blank'},
        //   },
        //   {
        //     HEADING : 'Pazarlama Açık Rıza Metni',
        //     SRC: './assets/pdfs/Artiox Pazarlama Açık Rıza Metni.pdf',
        //     LINK: {url: 'https://www.artiox.com/assets/pdfs/Artiox%20Pazarlama%20Ac%CC%A7%C4%B1k%20R%C4%B1za%20Metni.pdf', is_inner: false, target: '_blank'},
        //   },
        //   {
        //     HEADING : 'Yurtdışı Açık Rıza Metni',
        //     SRC: './assets/pdfs/Artiox Yurtdışı Açık Rıza Metni.pdf',
        //     LINK: {url: 'https://www.artiox.com/assets/pdfs/Artiox%20Yurtd%C4%B1s%CC%A7%C4%B1%20Ac%CC%A7%C4%B1k%20R%C4%B1za%20Metni.pdf', is_inner: false, target: '_blank'},
        //   },
        // ],
        TEXT_NAMES: [
            'Kullanıcı Sözleşmesi',
            'KVKK Politikası',
            'Bilgi Güvenliği Politikası',
            'Kişisel Veri Saklama ve İmha Politikası',
            'Kişisel Veri Sahibi Başvuru Formu',
            'Çerez Aydınlatma Metni',
            'KVKK Aydınlatma Metni',
            'Pazarlama Açık Rıza Metni',
            'Yurtdışı Açık Rıza Metni',
            'Mesafeli Satış Sözleşmesi',
            'Müzayede İptal ve İade Koşulları',
            'Müzayede Kullanıcı Sözleşmesi',
            'Müzayede Kuralları',
            'Komisyonlar',
            'Limitler'
        ],
    },
    ACCOUNT_LEVEL: {
        HEADING: 'Account Level',
        DESCRIPTION_INTRO: 'Your account level is ',
        DESCRIPTION_LEVEL0: '/2. With "Identity Statement", you can increase your account level and increase your limits.',
        DESCRIPTION_LEVEL1: '/2. With "Identity Verification" you can increase your account level and increase your limits.',
        DESCRIPTION_LEVEL2: '/2. Your account is at the top level.',
        LIMIT: 'Limit',
        REMAINING_LIMIT: 'Remaining Limit Information:',
        REMAINING_DAILY_LIMIT: 'Remaining Daily Limit Information:',
        REMAINING_MONTHLY_LIMIT: 'Remaining Monthly Limit Information:',
        REMAINING_LIMIT_MOBILE: 'Remaining Limits',
        YOUR_LEVEL_LIMIT: 'Limit Information of Your Account Level:',
        LEVEL_1_LIMIT: 'Level 1 Limit Information',
        LEVEL_2_LIMIT: 'Level 2 Limit Information',
        LEVEL_2_REQUIRED: 'Level 2 Verification Required',
        LEVEL_2_VALID: 'Level 2 Verification Completed',
        DAILY_LIMIT: 'Daily Limit',
        MONTHLY_LIMIT: 'Monthly Limit',
        DAILY_REMAINING: 'Daily Remaining',
        DAILY: 'Daily',
        MONTHLY_REMAINING: 'Monthly Remaining',
        DEPOSIT_WITHDRAWAL: 'Deposit/Withdrawal',
        MONTHLY: 'Monthly',
        LEVEL_UP: 'Upgrade Now',
        LEVEL_UP_MOBILE: 'Upgrade',
    },
    SERVICE: {
        HEADER: {
            HEADING: 'SERVICE',
            HEADING_MOBILE: 'Service',
            RULES: 'Limits and Rules',
            COMMISSIONS: 'Commissions',
        },
        RULES: {
            HEADING: 'Limits and Rules',
            SUMMARY: 'You can view the daily and monthly deposit/withdrawal limits in the table below.',
            LIMITS: 'Limits',
            DAILY: 'Daily',
            MONTHLY: 'Monthly',
            DEPOSIT: 'Deposit',
            WITHDRAWAL: 'Withdrawal',
            DAILY_DEPOSIT_WITHDRAWAL: 'Daily<br>Deposit/Withdrawal',
            MONTHLY_DEPOSIT_WITHDRAWAL: 'Monthly<br>Deposit/Withdrawal',
            SIGN_UP: 'Sign<br>Up',
            IDENTITY_DESCRIPTION: 'Identity<br>Statement',
            IDENTITY_VERIFICATION: 'Identity<br>Verification',
            LEVEL: 'Level',
            LEVEL_0_DESCRIPTION: 'It is an account level created only with e-mail address confirmation.',
            LEVEL_1_DESCRIPTION: 'It is an automatic verification process with NVI system with your NAME, SURNAME, TR ID NO, BIRTH YEAR information. Verification takes place instantly.',
            LEVEL_2_DESCRIPTION: 'It is the account level verified by our team with your identity document and selfie that includes the date details you uploaded to our system. Verification takes place within 24 hours at the latest.',
            TL_DEPOSIT_RULES_HEADING: 'Turkish Lira Deposit Rules',
            TL_DEPOSIT_RULES: [
                'Transfers from credit cards and ATMs will not be approved.',
                'Please do not forget to write the title of the recipient "Artiox Kripto Varlık Alım Satım Platformu A.Ş."',
                'Make sure you enter the IBAN number of our company correctly when making the transfer.',
                'Please do not forget to enter the code that we created specially for you in the description section when transferring. And do not add any explanation other than this code.',
                'Transfers that reach us but cannot be approved due to incomplete or incorrect information will be sent back within 5 working days, deducting bank commissions.',
                'Transfers made by wire transfer are reflected in your account 24/7, while transfers made via EFT can only be reflected in your account during working hours.',
                'Deposits made through Papara are not valid. Transfers made through this channel will be sent back within 5 working days, deducting bank commissions.',
            ],
            TL_WITHDRAWAL_RULES_HEADING: 'Turkish Lira Withdrawal Rules',
            TL_WITHDRAWAL_RULES: [
                'The bank account you will use for withdrawal must match the identity information you have declared to Artiox and belong to you. Otherwise, your withdrawal will not be approved.',
                'Please do not forget to provide email confirmation so that we can process your withdrawal request.',
                'Unconfirmed requests will expire after 24 hours and the relevant amount will be charged to your available balance.',
                'The amount you want to withdraw can be up to your available balance.',
                'The minimum withdrawal limit is set as 100 Turkish Lira.',
                'A commission of 4 liras is deducted for each withdrawal transaction.',
                'You can give a 24/7 withdrawal order to your Garanti Bank account.',
                'For your other bank accounts, your requests submitted during working hours will be processed on that day, otherwise the next business day.',
            ],
        },
        COMMISSIONS: {
            HEADING: 'Commissions',
            SUMMARY: 'Membership at Artiox is completely free. Buying and selling commissions are set at 0.5%.',
        },
    },
    CORPORATE: {
        HEADER: {
            HEADING: 'CORPORATE',
            ABOUT: 'About Us',
            ANNOUNCEMENTS: 'Announcements',
            CAREER: 'Career',
            CONTACT: 'Contact',
        },
        ABOUT: {
            HEADING: 'About Us',
            DESCRIPTION: 'Our company, which was established in 2019, continues its activities in the METU Teknokent Region.' +
                ' We will update this page and share with you detailed information about our team, which consists of young and dynamic METU students and business people experienced in their fields.<br> ' +
                ' <strong>Artiox Kripto Varlık Alım Satım Platformu A.Ş.</strong>',
        },
        ANNOUNCEMENTS: {},
        CAREER: {
            HEADING: 'Career',
            DESCRIPTION: 'You can send your CV to <strong>career@artiox.com</strong> so that we can contact you when suitable positions are opened.',
            CURRENT_JOBS: 'Current Job Advertisements',
            INSPECT: 'Inspect',
            JOB_ADVERTISEMENTS: [
                {
                    HEADING: 'Business Development Specialist',
                    GENERAL_HEADING: 'Job Description',
                    GENERAL: 'We are looking for a Business Development Specialist for our company Artiox which is offering users fractional ownership of investment-grade artworks via blockchain technology placed under METU Technopolis.',
                    RESPONSIBILITIES_HEADING: 'Responsibilities',
                    RESPONSIBILITIES: [
                        'Analyze business performance, organize the schedule',
                        'Design workflow processes to include the most effective ways to complete',
                        'Assist our business and improve our strategic positioning by establishing new partnerships, doing market research, and building new product initiatives',
                    ],
                    QUALIFICATIONS_HEADING: 'Qualifications',
                    QUALIFICATIONS: [
                        'Bachelor Degree, Business Administration, Industrial Engineering, Management Information Systems or relevant fields',
                        'Proficiency with Google Drive Tools and MS Office Tools, ability to analyze meetings and collect and review data',
                        'Excellent communication in English (both written and spoken)',
                        'The interest in blockchain technology and NFT’s is a BIG plus.',
                    ],
                    ADVANTAGEOUS_HEADING: '',
                    ADVANTAGEOUS: [],
                    ADVANTAGES_HEADING: 'Advantages',
                    ADVANTAGES: [
                        'We are committed to equal employment opportunities regardless of race, color, ancestry, religion, sex, national origin, sexual orientation, age, citizenship, marital status, disability, or gender identity.',
                        'Observation of salary against potential macroeconomic factors (subject to the discretion of the company)',
                        'Private Health Insurance',
                        'Access to various learning & development opportunities',
                        'When you need a break, there is Playstation & TV with Netflix and sofa for the quick nap!',
                        'Comfortable working environment (We do not have a solid hierarchical order in our company)',
                        'Flexible working hours (while preserving total working hours per day)',
                        'HPV vaccinations of female employees to be paid by Artiox',
                        'Exclusive time off policy; birthday leave',
                    ],
                    WORKING_HOURS: 'Working Hours: Monday - Friday, ~9.00 - ~18.00',
                    LUNCH: 'Lunch: Multinet',
                },
                {
                    HEADING: 'Performance Marketing Specialist ',
                    GENERAL_HEADING: 'Job Description',
                    GENERAL: 'We are looking for a Performance Marketing Specialist for our company Artiox which is offering users fractional ownership of investment-grade artworks via blockchain technology placed under METU Technopolis.',
                    RESPONSIBILITIES_HEADING: 'Responsibilities',
                    RESPONSIBILITIES: [
                        'Developing, monitoring, optimizing campaigns and budget across multiple advertising platforms for our apps',
                        'Managing creative asset production for campaigns',
                        'Planning, testing and delivering new approaches based on A/B or multivariate testings',
                        'Directing and overseeing ASO efforts',
                        'Optimizing various steps in user funnel to increase the effectiveness of user acquisition campaigns',
                        'Preparing detailed reports on our marketing campaign’s overall performance',
                        'Evaluating important metrics that affect our CPI, CAC, LTV, ROI.',
                        'Identifying the latest trends and technologies affecting app and digital marketing industries',
                    ],
                    QUALIFICATIONS_HEADING: 'Qualifications',
                    QUALIFICATIONS: [
                        'Min 2 year of performance marketing experience (app experience is a must)',
                        'Experience in digital marketing and platforms like Google Ads, Tiktok Ads, Apple Search Ads, Meta Ads, Appsflyer, Adjust…',
                        'Strong drive for A/B testing',
                        'Research and analyze competitors, industry trends, and markets',
                        'Provide reports, analyses, and recommendations for improving performance while making data-driven decisions',
                        'Being curious, creative, and relentless in pursuit of growth',
                        'Excellent level of written and spoken English',
                        'University degree from a reputable University preferably in Engineering, Management or Economics',
                        'Excellent communication in English (both written and spoken)',
                        'The interest in blockchain technology and NFT’s is a BIG plus',
                    ],
                    ADVANTAGEOUS_HEADING: '',
                    ADVANTAGEOUS: [],
                    ADVANTAGES_HEADING: 'Advantages',
                    ADVANTAGES: [
                        'We are committed to equal employment opportunities regardless of race, color, ancestry, religion, sex, national origin, sexual orientation, age, citizenship, marital status, disability, or gender identity.',
                        'Observation of salary against potential macroeconomic factors (subject to the discretion of the company)',
                        'Private Health Insurance',
                        'Access to various learning & development opportunities',
                        'When you need a break, there is Playstation & TV with Netflix and sofa for the quick nap!',
                        'Comfortable working environment (We do not have a solid hierarchical order in our company)',
                        'Flexible working hours (while preserving total working hours per day)',
                        'HPV vaccinations of female employees to be paid by Artiox',
                        'Exclusive time off policy; birthday leave',
                    ],
                    WORKING_HOURS: 'Working Hours: Monday - Friday, ~9.00 - ~18.00',
                    LUNCH: 'Lunch: Multinet',
                },
                {
                    HEADING: 'Digital Marketing Specialist',
                    GENERAL_HEADING: 'Job Description',
                    GENERAL: 'We are looking for a Digital Marketing Specialist for our company Artiox which is offering users fractional ownership of investment-grade artworks' +
                        ' via blockchain technology for the first time in the world and placed under ODTÜ Teknokent.',
                    RESPONSIBILITIES_HEADING: 'Responsibilities',
                    RESPONSIBILITIES: [
                        'Plan and execute a comprehensive digital marketing strategy in line with the goals',
                        'Support marketing activities broadly including content marketing, event marketing, social media marketing, email marketing',
                        'Set up & optimize digital campaigns within multiple platforms including Google, Facebook/Instagram, Twiter, LinkedIn',
                        'Provide reports, analyses, and recommendations for improving performance while making data-driven decisions',
                        'Tracking campaign performance daily, weekly and monthly',
                        'Research and analyze competitors, industry trends, and markets'
                    ],
                    QUALIFICATIONS_HEADING: 'Qualifications',
                    QUALIFICATIONS: [
                        'The desire to learn is a must-have!',
                        'Excellent communication in English (both written and spoken)',
                        'Min 2 years experience in digital media, content creation and/or agency management',
                        'Experience in performance marketing channels',
                        'B2C marketing experience is a BIG plus',
                        'The interest in blockchain technology and NFT’s is a BIG plus',
                    ],
                    ADVANTAGEOUS_HEADING: '',
                    ADVANTAGEOUS: [],
                    ADVANTAGES_HEADING: 'Advantages',
                    ADVANTAGES: [
                        'Opportunity to work by taking responsibility in a fast-growing startup.',
                        'Opportunity to work on a global scale soon.',
                        'Workplace at Co-Zone, easy access to ODTÜ Teknokent Bilişim ve İnovasyon Merkezi via Subway',
                        'Flexible working hours (while preserving total working hours per day)',
                        'Comfortable working environment (We do not have a solid hierarchical order in our company)',
                        'Co-Zone benefits',
                    ],
                    WORKING_HOURS: 'Working Hours: Monday - Friday, ~9.00 - ~18.00',
                    LUNCH: 'Lunch: Multinet',
                },
                {
                    HEADING: 'Graphic Designer',
                    GENERAL_HEADING: 'Job Description',
                    GENERAL: 'We are looking for a Graphic Designer for our company Artiox which is offering users fractional ownership of investment-grade artworks via blockchain technology placed under METU Technopolis.',
                    RESPONSIBILITIES_HEADING: 'Responsibilities',
                    RESPONSIBILITIES: [
                        'Following the latest design trends in the art & finance',
                        'Work with marketing team on defining the brand and product identities',
                        'Design creative content for blogs, video diaries, social media',
                        'Optimizing the visuals upon user reviews and activities',
                        'Creating engaging visuals for marketing campaigns',
                    ],
                    QUALIFICATIONS_HEADING: 'Qualifications',
                    QUALIFICATIONS: [
                        'Interest in video editing and motion graphics',
                        'The ability to create inspired, original designs',
                        'Proficiency in Adobe Creative Suite programs (After Effects, Premiere Pro, XD, Photoshop, Illustrator, InDesign)',
                        'Ability to adhere to deadlines and production schedules',
                        'Passion for spotting trends within design, marketing, and social media',
                        'Passion about art, crypto and blockchain technology',
                        'Portfolio attached to the CV',
                        'Bonus: Animation and 3D',
                    ],
                    ADVANTAGEOUS_HEADING: '',
                    ADVANTAGEOUS: [],
                    ADVANTAGES_HEADING: 'Advantages',
                    ADVANTAGES: [
                        'We are committed to equal employment opportunities regardless of race, color, ancestry, religion, sex, national origin, sexual orientation, age, citizenship, marital status, disability, or gender identity.',
                        'Observation of salary against potential macroeconomic factors (subject to the discretion of the company)',
                        'Private Health Insurance',
                        'Access to various learning & development opportunities',
                        'When you need a break, there is Playstation & TV with Netflix and sofa for the quick nap!',
                        'Comfortable working environment (We do not have a solid hierarchical order in our company)',
                        'Flexible working hours (while preserving total working hours per day)',
                        'HPV vaccinations of female employees to be paid by Artiox',
                        'Exclusive time off policy; birthday leave',
                    ],
                    WORKING_HOURS: 'Working Hours: Monday - Friday, ~9.00 - ~18.00',
                    LUNCH: 'Lunch: Multinet',
                },
                {
                    HEADING: 'Test Engineer',
                    GENERAL_HEADING: 'Job Description',
                    GENERAL: 'We are looking for a Test Engineer for our company Artiox which is offering users fractional ownership of investment-grade artworks via blockchain technology placed under METU Technopolis.',
                    RESPONSIBILITIES_HEADING: 'Responsibilities',
                    RESPONSIBILITIES: [
                        'Writing test plans and creating test cases for the product',
                        'Designing and implementing the tests that ensure the quality and the functionality of a product',
                        'Effectively applying general test knowledge',
                    ],
                    QUALIFICATIONS_HEADING: 'Qualifications',
                    QUALIFICATIONS: [
                        'Bachelor’s degree in engineering, computer science, or computer engineering',
                        'Previous work experience as a test engineer',
                        'Familiarity with testing tools',
                        'Knowledge of diagramming software',
                        'Extensive knowledge of engineering and testing procedures',
                        'Understanding test execution methods and tools',
                    ],
                    ADVANTAGEOUS_HEADING: '',
                    ADVANTAGEOUS: [],
                    ADVANTAGES_HEADING: 'Advantages',
                    ADVANTAGES: [
                        'We are committed to equal employment opportunities regardless of race, color, ancestry, religion, sex, national origin, sexual orientation, age, citizenship, marital status, disability, or gender identity.',
                        'Observation of salary against potential macroeconomic factors (subject to the discretion of the company)',
                        'Private Health Insurance',
                        'Access to various learning & development opportunities',
                        'When you need a break, there is Playstation & TV with Netflix and sofa for the quick nap!',
                        'Comfortable working environment (We do not have a solid hierarchical order in our company)',
                        'Flexible working hours (while preserving total working hours per day)',
                        'HPV vaccinations of female employees to be paid by Artiox',
                        'Exclusive time off policy; birthday leave',
                    ],
                    WORKING_HOURS: 'Working Hours: Monday - Friday, ~9.00 - ~18.00',
                    LUNCH: 'Lunch: Multinet',
                },
                {
                    HEADING: 'Flutter Developer',
                    GENERAL_HEADING: 'General Qualifications and Job Description',
                    GENERAL: 'We are looking for an Flutter(cross-platform) app developer for our company which is placed under ODTÜ Teknokent.',
                    RESPONSIBILITIES_HEADING: '',
                    RESPONSIBILITIES: [],
                    QUALIFICATIONS_HEADING: 'Qualifications',
                    QUALIFICATIONS: [
                        'Computer Engineering/ Computer Science degree',
                        'Linux/UNIX experience',
                        'Experience with Flutter',
                        'Experience with Dart',
                        'Will be working at least one year',
                        'JSON, REST',
                        'Data Structures, Algorithms',
                        'Strong analytical skills',
                        'Enjoys coding',
                        'Ability to write industrial level clean code',
                        'Being compatible with teamwork',
                        'Eager to learn',
                        'Responsible and disciplined',
                    ],
                    ADVANTAGEOUS_HEADING: 'Good-To-Have(optional)',
                    ADVANTAGEOUS: [
                        'Git',
                        'Have published at least one original cross-platform app with flutter',
                        'Design and build applications with the Flutter framework',
                        'App Design',
                    ],
                    ADVANTAGES_HEADING: 'Advantages',
                    ADVANTAGES: [
                        'Workplace at Co-Zone, easy access to ODTÜ Teknokent Bilişim ve İnovasyon Merkezi via Metro',
                        'Flexible working hours (while preserving total working hours per day)',
                        'Comfortable working environment (We do not have a solid hierarchical order in our company)',
                        'Co-Zone benefits',
                    ],
                    WORKING_HOURS: 'Working Hours: Monday - Friday, ~9.00 - ~18.00',
                    LUNCH: 'Lunch: Multinet',
                },
                {
                    HEADING: 'Android Developer',
                    GENERAL_HEADING: 'General Qualifications and Job Description',
                    GENERAL: 'We are looking for an Android developer for our company which is placed under ODTÜ Teknokent.',
                    RESPONSIBILITIES_HEADING: '',
                    RESPONSIBILITIES: [],
                    QUALIFICATIONS_HEADING: 'Qualifications',
                    QUALIFICATIONS: [
                        'Computer Engineering/ Computer Science degree',
                        'Linux/UNIX experience',
                        'Experience with Android Studio',
                        'Experience with Android SDK (26+)',
                        'Will be working at least one year',
                        'JSON, REST',
                        'Data Structures, Algorithms',
                        'Strong analytical skills',
                        'Enjoys coding',
                        'Ability to write industrial level clean code',
                        'Being compatible with teamwork',
                        'Eager to learn',
                        'Responsible and disciplined',
                    ],
                    ADVANTAGEOUS_HEADING: 'Good-To-Have(optional)',
                    ADVANTAGEOUS: [
                        'Git',
                        'Have published at least one original Android app',
                        'Design and build applications for the Android platform',
                        'App Design',
                    ],
                    ADVANTAGES_HEADING: 'Advantages',
                    ADVANTAGES: [
                        'Workplace at Co-Zone, easy access to ODTÜ Teknokent Bilişim ve İnovasyon Merkezi via Metro',
                        'Flexible working hours (while preserving total working hours per day)',
                        'Comfortable working environment (We do not have a solid hierarchical order in our company)',
                        'Co-Zone benefits',
                    ],
                    WORKING_HOURS: 'Working Hours: Monday - Friday, ~9.00 - ~18.00',
                    LUNCH: 'Lunch: Multinet',
                },
                {
                    HEADING: 'IOS Developer',
                    GENERAL_HEADING: 'General Qualifications and Job Description',
                    GENERAL: 'We are looking for an IOS developer for our company which is placed under ODTÜ Teknokent.',
                    RESPONSIBILITIES_HEADING: '',
                    RESPONSIBILITIES: [],
                    QUALIFICATIONS_HEADING: 'Qualifications',
                    QUALIFICATIONS: [
                        'Computer Engineering/ Computer Science degree',
                        'Linux/UNIX experience',
                        'Experience with IOS and Xcode',
                        'Experience with Objective-C, Swift, and Cocoa Touch',
                        'Will be working at least one year',
                        'JSON, REST',
                        'Data Structures, Algorithms',
                        'Strong analytical skills',
                        'Enjoys coding',
                        'Ability to write industrial level clean code',
                        'Being compatible with teamwork',
                        'Eager to learn',
                        'Responsible and disciplined',
                    ],
                    ADVANTAGEOUS_HEADING: 'Good-To-Have(optional)',
                    ADVANTAGEOUS: [
                        'Git',
                        'Have published at least one original IOS app',
                        'Design and build applications for the IOS platform',
                        'App Design',
                    ],
                    ADVANTAGES_HEADING: 'Advantages',
                    ADVANTAGES: [
                        'Workplace at Co-Zone, easy access to ODTÜ Teknokent Bilişim ve İnovasyon Merkezi via Metro',
                        'Flexible working hours (while preserving total working hours per day)',
                        'Comfortable working environment (We do not have a solid hierarchical order in our company)',
                        'Co-Zone benefits',
                    ],
                    WORKING_HOURS: 'Working Hours: Monday - Friday, ~9.00 - ~18.00',
                    LUNCH: 'Lunch: Multinet',
                },
                {
                    HEADING: 'Front-End Developer',
                    GENERAL_HEADING: 'General Qualifications and Job Description',
                    GENERAL: 'We are looking for a frontend web developer with Angular 2+ experience for our company which is placed under ODTÜ Teknokent.',
                    RESPONSIBILITIES_HEADING: '',
                    RESPONSIBILITIES: [],
                    QUALIFICATIONS_HEADING: 'Qualifications',
                    QUALIFICATIONS: [
                        'Computer Engineering/ Computer Science degree',
                        'Linux/UNIX experience',
                        'Angular 2+ experience',
                        'NodeJS',
                        'Will be working at least one year',
                        'JSON, REST',
                        'Data Structures, Algorithms',
                        'Strong analytical skills',
                        'Enjoys coding',
                        'Ability to write industrial level clean code',
                        'Being compatible with teamwork',
                        'Eager to learn',
                        'Responsible and disciplined',
                    ],
                    ADVANTAGEOUS_HEADING: 'Good-To-Have(optional)',
                    ADVANTAGEOUS: [
                        'Git',
                        'NOSQL ',
                        'Web development experience',
                        'Responsive Web Design',
                    ],
                    ADVANTAGES_HEADING: 'Advantages',
                    ADVANTAGES: [
                        'Workplace at Co-Zone, easy access to ODTÜ Teknokent Bilişim ve İnovasyon Merkezi via Metro',
                        'Flexible working hours (while preserving total working hours per day)',
                        'Comfortable working environment (We do not have a solid hierarchical order in our company)',
                        'Co-Zone benefits',
                    ],
                    WORKING_HOURS: 'Working Hours: Monday - Friday, ~9.00 - ~18.00',
                    LUNCH: 'Lunch: Multinet',
                },
            ],
            APPLY_NOW: 'Apply Now'
        },
        CONTACT: {
            HEADING: 'Contact Us',
            MAIL: 'career@artiox.com',
            ADDRESS: 'Artiox Kripto Varlık Alım Satım Platformu A.Ş.<br>' +
                ' Mustafa Kemal Mahallesi Bilişim İnovasyon Merkezi<br>' +
                ' ODTÜ Teknokent 280, D:G, 06510 <br>' +
                ' Çankaya/Ankara',
        },
    },
    ANNOUNCEMENTS: {
        HEADERS: {
            ANNOUNCEMENTS: 'Announcements',
            DESCRIPTION: 'You can access up-to-date news about the transactions made at Artiox and detailed information about the artists of the listed works.',
            OPERATION_ANNOUNCEMENTS: 'General Announcements',
            OPERATION_ANNOUNCEMENTS_EXPLANATION: 'Current news about the operations made in Artiox',
            OPERATION_ANNOUNCEMENTS_BG_IMG_SRC_DESKTOP: './assets/announcements/operation_announcements/0.d.webp',
            OPERATION_ANNOUNCEMENTS_BG_IMG_SRC_MOBILE: './assets/announcements/operation_announcements/0.m.webp',
            ARTISTS_ANNOUNCEMENTS: 'About Artists',
            ARTISTS_ANNOUNCEMENTS_EXPLANATION: 'Detailed information about the artists of the works listed on Artiox',
            ARTISTS_ANNOUNCEMENTS_BG_IMG_SRC_DESKTOP: './assets/announcements/artist_announcements/0.d.webp',
            ARTISTS_ANNOUNCEMENTS_BG_IMG_SRC_MOBILE: './assets/announcements/artist_announcements/0.m.webp',
        },
        ARTIST_ANNOUNCEMENTS: artists_config_en,
        OPERATION_ANNOUNCEMENTS: announcements_config_en,
    },
    ART_PRODUCTS: art_products_config_en,
    SNACK_BAR: {
        SUCCESS: 'Successful Operation',
        FAIL: 'Failed Operation'
    },
    TOOLTIP: {
        BASIC: {
            ACTIVE_ART_PRODUCT_SYMBOL_TOOLTIP: 'Symbol of the token',
            ACTIVE_PAIR_PRICE_TOOLTIP: 'Last transaction price',
            ACTIVE_PAIR_CHANGE_TOOLTIP: 'The percentage change of the last price and the price 24 hours ago',
            ACTIVE_PAIR_VOLUME_TOOLTIP: 'The total of buy and sell transactions in this trading pair in the last 24 hours',

            ACTIVE_ART_PRODUCT_NAME_TOOLTIP: 'Name of the artwork',
            ACTIVE_ART_PRODUCT_ARTIST_BORN_DEATH_TOOLTIP: 'Name of the artist',
            ART_PRODUCT_MARKET_VALUE_TOOLTIP: 'The current value of the entire artwork.' +
                ' It is obtained by multiplying the total number of tokens belonging to this item and the last transaction price.',
            ART_PRODUCT_REPORT_LIST_TOOLTIP: 'Important reports and information',

            ART_PRODUCTS_TOOLTIP: 'Other artworks listed on the market',

            SWITCH_BUTTON_TOOLTIP: 'Operation direction change button',
        },
        ADVANCED: {
            ACTIVE_PAIR_PRICE_TOOLTIP: 'Last transaction price',
            ACTIVE_PAIR_CHANGE_TOOLTIP: 'The difference of the last price and the price 24 hours ago',
            ACTIVE_PAIR_VOLUME_TOOLTIP: 'The total of buy and sell transactions in this trading pair in the last 24 hours',
            ACTIVE_PAIR_MIN_MAX_TOOLTIP: 'The highest and lowest price the token has been traded in the last 24 hours',

            BALANCE_TOOLTIP: 'List of tokens and currencies owned by the user.' +
                ' Equivalent ₺ refers to the current total value of tokens in the user\'s portfolio.',
            ORDER_BOOK_HEADER_TOOLTIP: 'The display of the buy and sell orders of this token.',

            DEPTH_CHART_TOOLTIP: 'You can observe the depth chart with this button. Depth chart is the display of buy and sell orders.',

            LIMIT_TOOLTIP: 'From this section, you can enter orders with price and quantity preferences for the token you want to buy or sell.' +
                ' The orders you have entered will be listed in the \'open orders\' section below, you can use the open orders section to cancel them.' +
                ' Also, your open orders will be deducted from your available token amount.',
            MARKET_TOOLTIP: 'In this section, when you enter the number of tokens you want to buy or sell,' +
                ' your transactions are carried out starting from the best price in the order book until the amount you entered is completed.' +
                ' As a result of the transactions performed, you can observe your average transaction price in the \'transaction history\' section below.',

            TRADE_HISTORY_HEADER_TOOLTIP: 'It shows the transactions taking place in this transaction pair instantly and sequentially.',

            USER_OPEN_ORDERS_HEADING_TOOLTIP: 'It shows the user\'s pending buy and sell orders.',
            USER_TRADE_HISTORY_HEADING_TOOLTIP: 'It shows the user\'s actual buy and sell orders.' +
                ' For more detailed observations, you can view the transaction history section from your "Balance".',
        },
    },
    PAGE_NOT_FOUND: {
        DESCRIPTION: 'The page you were looking for was not found.',
        GO_HOME: 'Back to Homepage'
    },
    CONNECTION_ERROR: {
        HEADING: 'Reconnection',
        DESCRIPTION: 'Please wait while connecting.',
    },
    UPDATE_APP: {
        HEADING: 'Update Available',
        DESCRIPTION: 'There is a new version of Artiox.<br>' +
            'Would you like to update now?',
        UPDATE: 'Update',
        CONTINUE: 'Continue',
    },
    GENERIC_MODAL: {
        OPEN: false,
        INNER_HTML: '    <div>' +
            '      <div class=" w-100 d-flex flex-flex justify-content-end" style="font-size: 12px;font-weight: 300;color: #9da5b7;">' +
            '        <div onclick="window.closeGenericModal();" >' +
            '          x' +
            '        </div>' +
            '      </div>' +
            '      <div class="d-flex flex-column align-items-center">' +
            '      <span style="margin-top: -8px; font-family: bebas-neue-bold, sans-serif; letter-spacing: 1.7px; text-align: center; color: #424554;">' +
            '        SÜRÜM GÜNCELLEMESİ' +
            '      </span>' +
            '        <div style="width: 160px; height: 160px; margin-top: 32px; padding: 50px; border-radius: 160px; background-color: rgba(80, 191, 210, 0.1)">' +
            '          <svg style="height: 60px; width: 60px;">' +
            '            <use fill="#f9f9f9" xlink:href="https://artiox.com/assets/images/update.svg#update"></use>' +
            '          </svg>' +
            '        </div>' +
            '        <span style="margin-top: 32px; font-family: Roboto, sans-serif; font-size: 11px; letter-spacing: 0.35px; color: #49566b;">' +
            '        Türk Lirası çekme işlemleri için <strong>uygulamanızı güncellemeniz gerekmektedir</strong>.' +
            '        Uygulamanızı daha sonra güncellemeyi tercih ediyorsanız web sitemizden çekme işlemlerinizi yapabilirsiniz.' +
            '      </span>' +
            '        <div class="w-100 d-flex flex-row justify-content-around align-items-center" style="margin-top: 32px; margin-bottom: 8px">' +
            '          <a href="https://artiox.com/" target="_blank" style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500; letter-spacing: 0.35px; color: #4fc0d2; text-decoration: none;">' +
            '            Web Sitesine Git' +
            '          </a>' +
            '          <button onclick="window.openAppMarket();" style="width: 120px; padding: 8px 16px; border: none; border-radius: 8px; box-shadow: 2px 2px 4px 0 rgba(213, 213, 213, 0.83); background-color: #4fc0d2;">' +
            '            <div style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500; letter-spacing: 0.35px; text-align: center; color: #fcfdff;">' +
            '              Güncelle' +
            '            </div>' +
            '          </button>' +
            '        </div>' +
            '      </div>' +
            '    </div>',
        TIME_THRESHOLD: 6000,
        CLOSE_DISABLED: false,
    },
    ONE_PAGER: {
        DESCRIPTION: [
            {TEXT: 'Do only the rich invest in art?', WEIGHT: 500},
            {TEXT: 'Does investment in art provide prestige as well as financial gain?', WEIGHT: 500},
            {
                TEXT: 'Is the way to get a prestigious place in the society you live in, is it the car you drive or the possession of a work of art, which is a cultural heritage?',
                WEIGHT: 500
            },
            {TEXT: 'What about being a partner in Picasso painting, how does it make you feel?', WEIGHT: 600},
            {
                TEXT: 'Click now to discover what art investment 3.0 is and take a step into the world of art investment!',
                WEIGHT: 500
            },
        ],
        BUTTON_TEXT: 'Discover',
        BUTTON_LINK: {url: 'https://artiox.onelink.me/wOYr/b7c26bd', is_inner: false, target: '_blank'},
    },
    AWARD: {
        TITLE: 'ARTIOX SURVEY AWARD ',
        INFO: 'Thank you for filling out the form.' +
            'You can find out your prize by spinning the wheel.' +
            'The amount you earn will be credited to your account within 24 hours when you complete the following steps.',
        IDIOM: 'Good Luck!',
        EMAIL_INFO: 'In order to receive your award, you must open an account with the email address you entered. <span class="text-decoration-underline">Please make sure you entered your email address correctly!</span>',
        EMAIL_PLACEHOLDER: 'Email you entered the survey',
        AWARD_DECLARATION: {STRING: 'You won $AWARD TL', TOKENS: ['$AWARD']},
        AWARD_INFO: 'We will upload your prize to your Artiox account, which you have opened with the email address you specified above, within 24 hours.<br/>n order to receive your reward, you need to raise your "Account Level" after opening your account. You can create and upgrade your account by following the instructions below.',
        STEP: 'Step',
        STEPS: [
            {
                INFO: 'After registering and logging in to Artiox, click on the security tab in the upper right panel.',
                IMAGE: './assets/screen_shots/desktop/frame_1.png'
            },
            {
                INFO: 'Go to authentication from the options above.',
                IMAGE: './assets/screen_shots/desktop/frame_2.png'
            },
            {
                INFO: 'Complete your identity verification process by filling out the identity verification form.',
                IMAGE: './assets/screen_shots/desktop/frame_3.png'
            },
        ]
    },
    TEST_ENVIRONMENT: {
        TITLE: 'Important Warning',
        DESCRIPTION: 'This site has been opened by Artiox for testing purposes in order to provide better service.' +
            ' Operations like authentication, two-factor authentication, password change are completely independent of <strong>www.artiox.com</strong> and take place in a test environment.' +
            ' Emails from <strong>test.artiox.com</strong> are not valid for <strong>www.artiox.com.</strong>' +
            ' You can send an e-mail to support@artiox.com to participate in the test environment.',
        RULES: [
            'All operations are for testing purposes and do not reflect reality.',
            'Membership and identity information is not permanent.',
            'Deposits and withdrawals are for testing purposes and completely invalid.',
        ],
    },
    // TURKEY_TOWN_LIST: ['İstanbul', 'Ankara', 'İzmir', 'Adana', 'Adıyaman', 'Afyon', 'Aksaray', 'Amasya', 'Antalya', 'Ardahan',
    //   'Artvin', 'Aydın', 'Ağrı', 'Balıkesir', 'Bartın', 'Batman', 'Bayburt', 'Bilecik', 'Bingöl', 'Bitlis', 'Bolu', 'Burdur', 'Bursa',
    //   'Çanakkale', 'Çankırı', 'Çorum', 'Denizli', 'Diyarbakır', 'Düzce', 'Edirne', 'Elazığ', 'Erzincan', 'Erzurum', 'Eskişehir', 'Gaziantep',
    //   'Giresun', 'Gümüşhane', 'Hakkâri', 'Hatay', 'Isparta', 'Iğdır', 'Kahramanmaraş', 'Karabük', 'Karaman', 'Kars', 'Kastamonu', 'Kayseri',
    //   'Kilis', 'Kocaeli', 'Konya', 'Kütahya', 'Kırklareli', 'Kırıkkale', 'Kırşehir', 'Malatya', 'Manisa', 'Mardin', 'Mersin', 'Muğla', 'Muş',
    //   'Nevşehir', 'Niğde', 'Ordu', 'Osmaniye', 'Rize', 'Sakarya', 'Samsun', 'Siirt', 'Sinop', 'Sivas', 'Şanlıurfa', 'Şırnak', 'Tekirdağ',
    //   'Tokat', 'Trabzon', 'Tunceli', 'Uşak', 'Van', 'Yalova', 'Yozgat', 'Zonguldak'],
    MISC: {
        // APP_VERSION: '2.0.0',
        // APP_ANDROID_VERSION: '1.0.5',
        // APP_IOS_VERSION: '1.0.5',
        ANNOUNCEMENT_ACTIVE: false,
        ZOOM_LEVEL: 'SevenDays',
        BUY: 'buy',
        SELL: 'sell',
        ONELINK: 'https://artiox.onelink.me/wOYr/b7c26bd',
        LOADING_ANIM_DESKTOP_WEBM: './assets/animations/loading.d.webm',
        LOADING_ANIM_DESKTOP_MP4: './assets/animations/loading.d.mp4',
        LOADING_ANIM_MOBILE_WEBM: './assets/animations/loading.m.webm',
        LOADING_ANIM_MOBILE_MP4: './assets/animations/loading.m.mp4',
        READ_ACCEPTED: 'Read, accepted',
        CONTINUE: 'Continue',
        CHANGELOG_HEADER: 'Changes',
        CHANGELOG_WEB: [
            {
                VERSION: '1.0.5',
                INFO: '<ul>' +
                    '  <li>New UI Design</li>' +
                    '  <li>Whole New Application Implementation</li>' +
                    '  <li>Native Framework Support</li>' +
                    '  <li>Native Performance Metrics</li>' +
                    '  <li>Smoother Animations</li>' +
                    '  <li>Hardware Accelerated Rendering</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.6.1',
                INFO: '<ul>' +
                    '  <li>Icons Refactor</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor Fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.6.0',
                INFO: '<ul>' +
                    '  <li>Crypto Currency Deposit Manager</li>' +
                    '  <li>Crypto Currency Withdrawal Manager</li>' +
                    '  <li>Currency Swap Manager</li>' +
                    '  <li>CountDown Utility Refactor</li>' +
                    '  <li>Modal Manager Refactor</li>' +
                    '  <li>Operation Service Refactor</li>' +
                    '  <li>Dashboard Refactor</li>' +
                    '  <li>User Currency Limits Refactor</li>' +
                    '  <li>Ethereum Network Added</li>' +
                    '  <li>Event Emitter BroadCast Channels Refactor</li>' +
                    '  <li>Icons Refactor</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor Fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.5.3',
                INFO: '<ul>' +
                    '  <li>Update Memory Manager</li>' +
                    '  <li>Login from Different Devices Fix</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor Fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.5.2',
                INFO: '<ul>' +
                    '  <li>Framework and Package Updates</li>' +
                    '  <li>Consecutive Press Limit on Buttons</li>' +
                    '  <li>Request Limit</li>' +
                    '  <li>Zendesk</li>' +
                    '  <li>Logout Error Fix</li>' +
                    '  <li>Connection Error Fix</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor Fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.5.1',
                INFO: '<ul>' +
                    '  <li>Transaction History Chart Local Date Correction</li>' +
                    '  <li>Sign Up Navigation</li>' +
                    '  <li>Announcement Cards Swipe</li>' +
                    '  <li>New Artwork Listing</li>' +
                    '  <li>Connection Error Page</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor Fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.4.8',
                INFO: '<ul>' +
                    '  <li>2FA Removal Feature</li>' +
                    '  <li>Add Bank Auto-Fill Feature</li>' +
                    '  <li>Sign-Up Form Improvements</li>' +
                    '  <li>New Rules For Deposit-Withdrawal Operations</li>' +
                    '  <li>Withdrawal Operation Timeout</li>' +
                    '  <li>Mail Feedback Improvements</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor Fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.4.7',
                INFO: '<ul>' +
                    '  <li>Navigation Service Improvement</li>' +
                    '  <li>New Password Rule for Security</li>' +
                    '  <li>Sign Up Notifications</li>' +
                    '  <li>Better Blog Experience</li>' +
                    '  <li>Advanced Exchange Chart Improvement</li>' +
                    '  <li>Security Precautions</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.4.6',
                INFO: '<ul>' +
                    '  <li>Navigation Service Performance Improvement</li>' +
                    '  <li>Navigation Service New Routing Rules</li>' +
                    '  <li>ExchangeResolver Improvement</li>' +
                    '  <li>User LoggedInResolver Improvement</li>' +
                    '  <li>Pre Sale Navigation Menu</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.4.5',
                INFO: '<ul>' +
                    '  <li>Android-IOS Deep Link Connection</li>' +
                    '  <li>Navigation Service Performance Improvement</li>' +
                    '  <li>Depth Chart Correction</li>' +
                    '  <li>Bugfix: Trading History Sorting Correction</li>' +
                    '  <li>Authentication Fix</li>' +
                    '  <li>Test Environment Setup</li>' +
                    '  <li>Test Environment Error Codes</li>' +
                    '  <li>Last Update Date and Version Number</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor fixes</li>' +
                    '</ul>',
            },
        ],
        CHANGELOG_ANDROID: [
            {
                VERSION: '1.0.5',
                INFO: '<ul>' +
                    '  <li>New UI Design</li>' +
                    '  <li>Whole New Application Implementation</li>' +
                    '  <li>Native Framework Support</li>' +
                    '  <li>Native Performance Metrics</li>' +
                    '  <li>Smoother Animations</li>' +
                    '  <li>Hardware Accelerated Rendering</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.6.1',
                INFO: '<ul>' +
                    '  <li>Icons Refactor</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor Fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.6.0',
                INFO: '<ul>' +
                    '  <li>Lower API Level Support</li>' +
                    '  <li>Crypto Currency Deposit Manager</li>' +
                    '  <li>Crypto Currency Withdrawal Manager</li>' +
                    '  <li>Currency Swap Manager</li>' +
                    '  <li>CountDown Utility Refactor</li>' +
                    '  <li>Modal Manager Refactor</li>' +
                    '  <li>Operation Service Refactor</li>' +
                    '  <li>Dashboard Refactor</li>' +
                    '  <li>User Currency Limits Refactor</li>' +
                    '  <li>Ethereum Network Added</li>' +
                    '  <li>Event Emitter BroadCast Channels Refactor</li>' +
                    '  <li>Icons Refactor</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor Fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.5.3',
                INFO: '<ul>' +
                    '  <li>Update Memory Manager</li>' +
                    '  <li>Login from Different Devices Fix</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor Fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.5.2',
                INFO: '<ul>' +
                    '  <li>Framework and Package Updates</li>' +
                    '  <li>Consecutive Press Limit on Buttons</li>' +
                    '  <li>Request Limit</li>' +
                    '  <li>Zendesk</li>' +
                    '  <li>Logout Error Fix</li>' +
                    '  <li>Connection Error Fix</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor Fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.5.1',
                INFO: '<ul>' +
                    '  <li>Transaction History Chart Local Date Correction</li>' +
                    '  <li>Sign Up Navigation</li>' +
                    '  <li>Announcement Cards Swipe</li>' +
                    '  <li>New Artwork Listing</li>' +
                    '  <li>Connection Error Page</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor Fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.4.8',
                INFO: '<ul>' +
                    '  <li>2FA Removal Feature</li>' +
                    '  <li>Add Bank Auto-Fill Feature</li>' +
                    '  <li>Sign-Up Form Improvements</li>' +
                    '  <li>New Rules For Deposit-Withdrawal Operations</li>' +
                    '  <li>Withdrawal Operation Timeout</li>' +
                    '  <li>Mail Feedback Improvements</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor Fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.4.7',
                INFO: '<ul>' +
                    '  <li>Navigation Service Improvement</li>' +
                    '  <li>New Password Rule for Security</li>' +
                    '  <li>Sign Up Notifications</li>' +
                    '  <li>Better Blog Experience</li>' +
                    '  <li>Advanced Exchange Chart Improvement</li>' +
                    '  <li>Security Precautions</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.4.6',
                INFO: '<ul>' +
                    '  <li>Navigation Service Performance Improvement</li>' +
                    '  <li>Navigation Service New Routing Rules</li>' +
                    '  <li>ExchangeResolver Improvement</li>' +
                    '  <li>User LoggedInResolver Improvement</li>' +
                    '  <li>Pre Sale Navigation Menu</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.4.2',
                INFO: '<ul>' +
                    '  <li>Android-IOS Deep Link Connection</li>' +
                    '  <li>Navigation Service Performance Improvement</li>' +
                    '  <li>Depth Chart Correction</li>' +
                    '  <li>Bugfix: Trading History Sorting Correction</li>' +
                    '  <li>Authentication Fix</li>' +
                    '  <li>Bugfix: Firebase Plugin Fix</li>' +
                    '  <li>Bugfix: Appsflyer Plugin Fix</li>' +
                    '  <li>Bugfix: Facebook Plugin Fix</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor fixes</li>' +
                    '</ul>',
            },
        ],
        CHANGELOG_IOS: [
            {
                VERSION: '1.0.5',
                INFO: '<ul>' +
                    '  <li>New UI Design</li>' +
                    '  <li>Whole New Application Implementation</li>' +
                    '  <li>Native Framework Support</li>' +
                    '  <li>Native Performance Metrics</li>' +
                    '  <li>Smoother Animations</li>' +
                    '  <li>Hardware Accelerated Rendering</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.6.1',
                INFO: '<ul>' +
                    '  <li>Icons Refactor</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor Fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.6.0',
                INFO: '<ul>' +
                    '  <li>Crypto Currency Deposit Manager</li>' +
                    '  <li>Crypto Currency Withdrawal Manager</li>' +
                    '  <li>Currency Swap Manager</li>' +
                    '  <li>CountDown Utility Refactor</li>' +
                    '  <li>Modal Manager Refactor</li>' +
                    '  <li>Operation Service Refactor</li>' +
                    '  <li>Dashboard Refactor</li>' +
                    '  <li>User Currency Limits Refactor</li>' +
                    '  <li>Ethereum Network Added</li>' +
                    '  <li>Event Emitter BroadCast Channels Refactor</li>' +
                    '  <li>Icons Refactor</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor Fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.5.3',
                INFO: '<ul>' +
                    '  <li>Update Memory Manager</li>' +
                    '  <li>Login from Different Devices Fix</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor Fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.5.2',
                INFO: '<ul>' +
                    '  <li>Framework and Package Updates</li>' +
                    '  <li>Consecutive Press Limit on Buttons</li>' +
                    '  <li>Request Limit</li>' +
                    '  <li>Zendesk</li>' +
                    '  <li>Logout Error Fix</li>' +
                    '  <li>Connection Error Fix</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor Fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.5.1',
                INFO: '<ul>' +
                    '  <li>Transaction History Chart Local Date Correction</li>' +
                    '  <li>Sign Up Navigation</li>' +
                    '  <li>Announcement Cards Swipe</li>' +
                    '  <li>New Artwork Listing</li>' +
                    '  <li>Connection Error Page</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor Fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.4.8',
                INFO: '<ul>' +
                    '  <li>2FA Removal Feature</li>' +
                    '  <li>Add Bank Auto-Fill Feature</li>' +
                    '  <li>Sign-Up Form Improvements</li>' +
                    '  <li>New Rules For Deposit-Withdrawal Operations</li>' +
                    '  <li>Withdrawal Operation Timeout</li>' +
                    '  <li>Mail Feedback Improvements</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor Fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.4.7',
                INFO: '<ul>' +
                    '  <li>Navigation Service Improvement</li>' +
                    '  <li>New Password Rule for Security</li>' +
                    '  <li>Sign Up Notifications</li>' +
                    '  <li>Better Blog Experience</li>' +
                    '  <li>Advanced Exchange Chart Improvement</li>' +
                    '  <li>Security Precautions</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor fixes</li>' +
                    '  <li>Bugfix: Login Autofill Error</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.4.6',
                INFO: '<ul>' +
                    '  <li>Navigation Service Performance Improvement</li>' +
                    '  <li>Navigation Service New Routing Rules</li>' +
                    '  <li>ExchangeResolver Improvement</li>' +
                    '  <li>User LoggedInResolver Improvement</li>' +
                    '  <li>Pre Sale Navigation Menu</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.4.5',
                INFO: '<ul>' +
                    '  <li>IOS User Anonymous Data Sharing Permission</li>' +
                    '  <li>Android-IOS Deep Link Connection</li>' +
                    '  <li>Navigation Service Performance Improvement</li>' +
                    '  <li>Depth Chart Correction</li>' +
                    '  <li>Bugfix: Trading History Sorting Correction</li>' +
                    '  <li>Authentication Fix</li>' +
                    '  <li>Bugfix: Firebase Plugin Fix</li>' +
                    '  <li>Bugfix: Appsflyer Plugin Fix</li>' +
                    '  <li>Bugfix: Facebook Plugin Fix</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor fixes</li>' +
                    '</ul>',
            },
        ],
        CHANGELOG: [  // obsolete
            {
                VERSION: '0.3.8',
                INFO: '<ul>' +
                    '  <li>Bugfix: Form Initial Value Adjustment</li>' +
                    '  <li>Bugfix: Navigasyon Service LoggedIn Guard Adjustment</li>' +
                    '  <li>Bugfix: Exchange Forms Precision Point Adjustment</li>' +
                    '  <li>Bugfix: IOS Snack Bar Animation Adjustment </li>' +
                    '  <li>Continue to the Desired Page After Login</li>' +
                    '  <li>iPhone Safe Area</li>' +
                    '  <li>Application Portrait Mode Restriction</li>' +
                    '  <li>Bugfix: Selected Announcements When Language Change</li>' +
                    '  <li>Bugfix: IPhone Photo Upload Error</li>' +
                    '  <li>Bugfix: Advanced Exchange Page Loading Error</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.3.7',
                INFO: '<ul>' +
                    '  <li>Continue to the Desired Page After Login</li>' +
                    '  <li>iPhone Safe Area</li>' +
                    '  <li>Application Portrait Mode Restriction</li>' +
                    '  <li>Bugfix: Selected Announcements When Language Change</li>' +
                    '  <li>Bugfix: IPhone Photo Upload Error</li>' +
                    '  <li>Bugfix: Advanced Exchange Page Loading Error</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Style Enhancements</li>' +
                    '  <li>Minor fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.3.6',
                INFO: '<ul>' +
                    '  <li>Bugfix: Navigasyon Service</li>' +
                    '  <li>Bugfix: IOS Platform Image Encodings</li>' +
                    '  <li>Announcement and Carrer Update</li>' +
                    '  <li>IOS Platform Status Bar Optimization</li>' +
                    '  <li>IOS Platform Overscroll Disabled</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Minor fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.3.5',
                INFO: '<ul>' +
                    '  <li>Bugfix: GuideBook Height</li>' +
                    '  <li>Bugfix: WEBP-JPG</li>' +
                    '  <li>Minor fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.3.4',
                INFO: '<ul>' +
                    '  <li>Build Optimizer Enhanced</li>' +
                    '  <li>Navigation Service Update</li>' +
                    '  <li>Image Encodings Improvement</li>' +
                    '  <li>Reconnection Attempt when No Connection</li>' +
                    '  <li>Bugfix: Currency In-Order Amount</li>' +
                    '  <li>Bugfix: Depth Chart Toggle</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Minor fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.3.3',
                INFO: '<ul>' +
                    '  <li>WebSocket Update</li>' +
                    '  <li>Navigation Service Update</li>' +
                    '  <li>More informative snack bar</li>' +
                    '  <li>2FA Password Detection</li>' +
                    '  <li>Bugfix: "Socket.io ID required" error</li>' +
                    '  <li>Performance Optimizations</li>' +
                    '  <li>Minor fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.3.2',
                INFO: '<ul>' +
                    '  <li>WebSocket Update</li>' +
                    '  <li>Stable Internet Connection</li>' +
                    '  <li>Bugfix: Exchange Forms Precision Point Adjustment</li>' +
                    '  <li>Bugfix: Global Time Adjustment</li>' +
                    '  <li>Minor fixes</li>' +
                    '</ul>',
            },
            {
                VERSION: '0.3.1',
                INFO: '<ul>' +
                    '  <li>Language Change Fix</li>' +
                    '  <li>Backend V2 Merge</li>' +
                    '  <li>Fix: User Trade History Fix</li>' +
                    '  <li>Minor fixes</li>' +
                    '</ul>',
            },
        ],
        ANNOUNCEMENTS_TITLES: announcements_titles,
    },
    DELISTING: {
        "BOD" : {
            TITLE: '"BOD" IS BEING AUCTIONED',
            TEXT: [
                '"BODRUM" by Yalçın Gökçebağ, which was listed on Artiox on 28.08.2020, is going up for auction.',
                'In accordance with our sales policy (User Agreement Article 6), the BOD/TRY trading pair will be closed for transactions on 19.02.2024 at 17:00, and the artwork will be put up for auction based on the last transaction price. ',
                '<strong><a class="delisting-text-black"  href="https://tr.artiox.com/blog/bodrum-muzayedeye-cikiyor" target="_blank">Click here for more detailed information.</a></strong>',
                'If you have any questions about this process, please send an email to <a class="delisting-text-black" href="mailto:support@artiox.com">support@artiox.com</a> or use our live support service on the platform.',
            ],
        },
        "MAR" : {
            TITLE: '"MAR" IS BEING AUCTIONED',
            TEXT: [
                '"ABSTRAIT MARRON" by Nejad Melih Devrim, which was listed on Artiox on 28.08.2020, is going up for auction.',
                'In accordance with our sales policy (User Agreement Article 6), the MAR/TRY trading pair will be closed for transactions on 26.04.2024 at 16:00, and the artwork will be put up for auction based on the last transaction price. ',
                '<strong><a class="delisting-text-black"  href="https://tr.artiox.com/blog/sanati-kazanca-donusturen-yolculuk" target="_blank">Click here to review previous artwork Exit.</a></strong>',
                '<strong><a class="delisting-text-black"  href="https://artioxmuzayede.com/muzayede/23839/mayis-muzayedesi" target="_blank">You can follow the auction here.</a></strong>',
                'If you have any questions about this process, please send an email to <a class="delisting-text-black" href="mailto:support@artiox.com">support@artiox.com</a> or use our live support service on the platform.',
            ],
        },
        "ORC" : {
            TITLE: '"ORC" IS BEING AUCTIONED',
            TEXT: [
                '"ORCHESTRA" by Adnan Turani, which was listed on Artiox on 22.01.2021, is going up for auction.',
                'In accordance with our sales policy (User Agreement Article 6), the ORC/TRY trading pair will be closed for transactions on 31.07.2024 at 15:00, and the artwork will be put up for auction based on the last transaction price.',
                '<strong><a class="delisting-text-black"  href="https://tr.artiox.com/blog/sanati-kazanca-donusturen-yolculuk" target="_blank">Click here to review previous artwork Exit.</a></strong>',
                '<strong><a class="delisting-text-black"  href="https://artioxmuzayede.com/muzayede/23839/mayis-muzayedesi" target="_blank">You can follow the auction here.</a></strong>',
                'If you have any questions about this process, please send an email to <a class="delisting-text-black" href="mailto:support@artiox.com">support@artiox.com</a> or use our live support service on the platform.',
            ],
        },
        "IST" : {
            TITLE: '"IST" IS BEING AUCTIONED',
            TEXT: [
                '"SÜLEYMANİYE\'DEN İSTANBUL\'A" by Devrim Erbil, which was listed on Artiox on 16.04.2021, is going up for auction.',
                'In accordance with our sales policy (User Agreement Article 6), the IST/TRY trading pair will be closed for transactions on 26.04.2024 at 16:00, and the artwork will be put up for auction based on the last transaction price. ',
                '<strong><a class="delisting-text-black"  href="https://tr.artiox.com/blog/sanati-kazanca-donusturen-yolculuk" target="_blank">Click here to review previous artwork Exit.</a></strong>',
                '<strong><a class="delisting-text-black"  href="https://artioxmuzayede.com/muzayede/23839/mayis-muzayedesi" target="_blank">You can follow the auction here.</a></strong>',
                'If you have any questions about this process, please send an email to <a class="delisting-text-black" href="mailto:support@artiox.com">support@artiox.com</a> or use our live support service on the platform.',
            ],
        },
        "APT" : {
            TITLE: '"APT" IS BEING AUCTIONED',
            TEXT: [
                '"APARTMAN" by Nuri Abaç, which was listed on Artiox on 07.06.2021, is going up for auction.',
                'In accordance with our sales policy (User Agreement Article 6), the APT/TRY trading pair will be closed for transactions on 31.07.2024 at 15:00, and the artwork will be put up for auction based on the last transaction price.',
                '<strong><a class="delisting-text-black"  href="https://tr.artiox.com/blog/sanati-kazanca-donusturen-yolculuk" target="_blank">Click here to review previous artwork Exit.</a></strong>',
                '<strong><a class="delisting-text-black"  href="https://artioxmuzayede.com/muzayede/23839/mayis-muzayedesi" target="_blank">You can follow the auction here.</a></strong>',
                'If you have any questions about this process, please send an email to <a class="delisting-text-black" href="mailto:support@artiox.com">support@artiox.com</a> or use our live support service on the platform.',
            ],
        },
        "ULC" : {
            TITLE: '"ULC" IS BEING AUCTIONED',
            TEXT: [
                'Untitled by Ömer Uluç, which was listed on Artiox on 15.04.2021, is going up for auction.',
                'In accordance with our sales policy (User Agreement Article 6), the ULC/TRY trading pair will be closed for transactions on 31.07.2024 at 15:00, and the artwork will be put up for auction based on the last transaction price.',
                '<strong><a class="delisting-text-black"  href="https://tr.artiox.com/blog/sanati-kazanca-donusturen-yolculuk" target="_blank">Click here to review previous artwork Exit.</a></strong>',
                '<strong><a class="delisting-text-black"  href="https://artioxmuzayede.com/muzayede/23839/mayis-muzayedesi" target="_blank">You can follow the auction here.</a></strong>',
                'If you have any questions about this process, please send an email to <a class="delisting-text-black" href="mailto:support@artiox.com">support@artiox.com</a> or use our live support service on the platform.',
            ],
        },
        "MATA" : {
            TITLE: '"MATA" IS BEING AUCTIONED',
            TEXT: [
                'Untitled by Mustafa Ata, which was listed on Artiox on 22.03.2023, is going up for auction.',
                'In accordance with our sales policy (User Agreement Article 6), the MATA/TRY trading pair will be closed for transactions on 31.07.2024 at 15:00, and the artwork will be put up for auction based on the last transaction price.',
                '<strong><a class="delisting-text-black"  href="https://tr.artiox.com/blog/sanati-kazanca-donusturen-yolculuk" target="_blank">Click here to review previous artwork Exit.</a></strong>',
                '<strong><a class="delisting-text-black"  href="https://artioxmuzayede.com/muzayede/23839/mayis-muzayedesi" target="_blank">You can follow the auction here.</a></strong>',
                'If you have any questions about this process, please send an email to <a class="delisting-text-black" href="mailto:support@artiox.com">support@artiox.com</a> or use our live support service on the platform.',
            ],
        },
        BUTTON_TEXT: 'Return to Artwork',
        ART_PRODUCTS: ['BOD/TRY', 'MAR/TRY', 'ORC/TRY', 'IST/TRY', 'APT/TRY', 'ULC/TRY', 'MATA/TRY',]
    },
    RESPONSE_TABLE: {
        101: {code: 101, is_okay: false, message: 'Inappropriate parameter error.'},
        102: {code: 102, is_okay: true, message: 'Currency $currency_id added.'},
        103: {code: 103, is_okay: true, message: 'Art Product $art_product_id added.'},
        104: {code: 104, is_okay: true, message: 'Auction Product $auction_product_id added.'},

        201: {code: 201, is_okay: false, message: 'This email address has already been used.'},
        202: {code: 202, is_okay: true, message: 'Email registration completed successfully.'},
        203: {code: 203, is_okay: true, message: 'Sign-up completed successfully.'},
        204: {code: 204, is_okay: false, message: 'Email or password incorrect.'},
        205: {code: 205, is_okay: false, message: 'Account is blocked/You must verify your e-mail.'},
        206: {code: 206, is_okay: false, message: 'This IP has been blacklisted.'},
        207: {code: 207, is_okay: false, message: 'You must verify your 2FA.'},
        208: {code: 208, is_okay: false, message: 'Invalid CODE.'},
        209: {code: 209, is_okay: true, message: 'Login successful.'},
        210: {code: 210, is_okay: false, message: 'You must allow new IP, please check your emails.'},
        211: {code: 211, is_okay: false, message: 'IP verification mail resent, please check your emails.'},
        212: {code: 212, is_okay: false, message: 'Session key required.'},
        213: {code: 213, is_okay: true, message: 'Logout successful.'},
        214: {code: 214, is_okay: false, message: 'Authentication failed. Invalid session key/Session key expired.'},
        215: {code: 215, is_okay: false, message: 'Authentication failed.'},
        216: {
            code: 216,
            is_okay: false,
            message: 'Admin authentication required. Invalid session key/Session key expired.'
        },
        217: {code: 217, is_okay: false, message: 'Admin authentication failed.'},
        218: {code: 218, is_okay: true, message: 'Admin Login successful.'},
        219: {code: 219, is_okay: true, message: 'KYC approved.'},
        220: {code: 220, is_okay: false, message: 'This KYC information has already been registered.'},
        221: {code: 221, is_okay: false, message: 'Incorrect credentials.'},
        222: {code: 222, is_okay: false, message: 'KYC has already been approved for this account.'},
        223: {code: 223, is_okay: false, message: 'Password incorrect.'},
        224: {code: 224, is_okay: false, message: '2FA already enabled for associated account.'},
        225: {
            code: 225,
            is_okay: true,
            message: '2FA setup completed successfully. Verification CODE is pending for activation.'
        },
        226: {code: 226, is_okay: true, message: '2FA verified successfully.'},
        227: {code: 227, is_okay: false, message: 'Incorrect CODE.'},
        228: {code: 228, is_okay: false, message: 'Password incorrect.'},
        229: {code: 229, is_okay: true, message: 'Password changed successfully.'},
        230: {code: 230, is_okay: false, message: 'Invalid email address.'},
        231: {code: 231, is_okay: true, message: 'Password change request submitted.'},
        232: {code: 232, is_okay: false, message: 'Invalid key.'},
        233: {code: 233, is_okay: true, message: 'Second level KYC request has been enqueued successfully.'},
        234: {code: 234, is_okay: false, message: 'Your account level must be at least 1.'},
        235: {code: 235, is_okay: false, message: 'KYC info mismatched with bank account info.'},
        236: {code: 236, is_okay: false, message: 'Bank account info error.'},
        237: {code: 237, is_okay: true, message: 'Bank account added successfully.'},
        238: {code: 238, is_okay: false, message: 'Max file upload limit(16MB) exceeded.'},
        239: {code: 239, is_okay: false, message: 'Your age year must be greater than 18.'},
        240: {code: 240, is_okay: false, message: 'Higher admin tier required.'},
        241: {code: 241, is_okay: false, message: '2FA already disabled for associated account.'},
        242: {code: 242, is_okay: true, message: '2FA removed successfully.'},
        243: {code: 243, is_okay: true, message: 'Password correct.'},
        244: {code: 244, is_okay: false, message: 'Invalid user id.'},
        245: {code: 245, is_okay: false, message: 'Invalid user info.'},
        246: {
            code: 246,
            is_okay: false,
            message: 'Your account must be level 1 for appealing second level KYC./You can not upload new image until current second level KYC is concluded.'
        },
        247: {code: 247, is_okay: false, message: 'KYC service error. Please try again later.'},
        248: {code: 248, is_okay: false, message: 'Your account level must be at least 2.'},
        249: {code: 249, is_okay: false, message: 'Invalid Block Chain ID.'},
        250: {code: 250, is_okay: true, message: 'Crypto wallet added successfully.'},
        251: {code: 251, is_okay: false, message: 'Invalid address'},
        252: {code: 252, is_okay: true, message: 'User e-mail changed successfully.'},
        253: {code: 253, is_okay: false, message: 'User e-mail incorrect.'},
        254: {code: 254, is_okay: false, message: 'Admin e-mails cannot be changed.'},
        255: {code: 255, is_okay: false, message: 'User deposit reference code not found.'},
        256: {code: 256, is_okay: true, message: 'Your account information updated.'},
        257: {code: 257, is_okay: true, message: 'User account information updated'},
        258: {code: 258, is_okay: false, message: 'Maximum 3 billing information can be registered.'},
        259: {code: 259, is_okay: false, message: `Billing info type error. Type must be 'individual' or 'corporate'`},
        260: {code: 260, is_okay: false, message: 'Billing info update error. Label not found'},
        261: {code: 261, is_okay: true, message: 'Billing info added successfully.'},
        262: {code: 262, is_okay: false, message: 'Maximum 3 delivery addresses can be registered.'},
        263: {code: 263, is_okay: false, message: 'Delivery address update error. Id not found'},
        264: {code: 264, is_okay: true, message: 'Delivery address added successfully.'},
        265: {code: 265, is_okay: false, message: 'Delivery update error.'},
        266: {code: 266, is_okay: true, message: 'Delivery updated successfully.'},
        267: {code: 267, is_okay: false, message: 'Maximum 10 bank accounts can be registered.'},
        268: {code: 268, is_okay: false, message: 'Delivery type error. Type must be \'hand_delivery\' or \'cargo\''},
        269: {
            code: 269,
            is_okay: false,
            message: 'Delivery tracking status error. Type must be \'preparing\', \'shipped\', \'en_route\', \'in_distribution\' or \'delivered\''
        },
        270: {
            code: 270,
            is_okay: false,
            message: 'Delivery status error. Type must be \'pending\', \'confirming\', \'approved\', \'packed\' or \'delivered\''
        },
        271: {code: 271, is_okay: true, message: 'Billing info updated successfully.'},
        272: {code: 272, is_okay: true, message: 'Delivery address updated successfully.'},
        273: {code: 273, is_okay: true, message: 'Account verification mail resent, please check your emails.'},
        274: {code: 274, is_okay: true, message: 'Account verified.'},
        275: {code: 275, is_okay: false, message: 'Account verification code is wrong.'},
        276: {code: 276, is_okay: false, message: 'Account verification code expired.'},

        301: {code: 301, is_okay: true, message: 'Art Product deposit completed successfully.'},
        302: {code: 302, is_okay: true, message: 'Art Product withdrawal completed successfully.'},
        303: {code: 303, is_okay: false, message: 'Not enough available balance.'},
        304: {code: 304, is_okay: true, message: 'Currency deposit completed successfully.'},
        305: {code: 305, is_okay: true, message: 'Currency withdrawal completed successfully.'},
        306: {code: 306, is_okay: true, message: 'Currency withdrawal request saved successfully.'},
        307: {code: 307, is_okay: false, message: 'Bank information mismatched.'},
        308: {
            code: 308,
            is_okay: false,
            message: 'Withdrawal amount you have been requested exceeded your withdrawal limit.'
        },
        309: {code: 309, is_okay: true, message: 'Currency withdrawal request executed successfully.'},
        310: {code: 310, is_okay: false, message: 'Currency withdrawal request not queued.'},
        311: {code: 311, is_okay: false, message: 'Currency withdrawal request not found.'},
        312: {code: 312, is_okay: true, message: 'Currency deposit executed successfully.'},
        313: {code: 313, is_okay: false, message: 'Currency deposit amount exceeded deposit limit.'},
        314: {code: 314, is_okay: false, message: 'Crypto wallet information mismatched.'},
        315: {code: 315, is_okay: false, message: 'Requested block chain mismatched with crypto wallet information.'},
        316: {code: 316, is_okay: true, message: 'Currency withdrawal request proposal details.'},
        317: {code: 317, is_okay: false, message: 'Currency withdrawal request proposal already signed.'},
        318: {code: 318, is_okay: false, message: 'Currency withdrawal request proposal not found.'},
        319: {code: 319, is_okay: false, message: 'Currency withdrawal request proposal canceled.'},
        320: {
            code: 320,
            is_okay: false,
            message: 'Invalid status for currency withdrawal request proposal. Try "approve" or "cancel"'
        },

        401: {code: 401, is_okay: false, message: 'Art Product not listed.'},
        402: {code: 402, is_okay: false, message: 'Amount must be greater than zero.'},
        403: {code: 403, is_okay: false, message: 'Price must be greater than zero.'},
        404: {code: 404, is_okay: false, message: 'Max price($max_price) limit reached.'},
        405: {code: 405, is_okay: false, message: 'Min price($min_price) limit reached.'},
        406: {
            code: 406,
            is_okay: true,
            message: 'Limit $type order for $amount $symbol for the price of $price saved successfully.'
        },
        407: {code: 407, is_okay: false, message: 'Not enough available balance.'},
        408: {code: 408, is_okay: false, message: 'Max amount($max_amount) limit reached.'},
        409: {code: 409, is_okay: true, message: 'Market $type order for $amount $symbol saved successfully.'},
        410: {code: 410, is_okay: false, message: 'Error. Pre Sale not started.'},
        411: {code: 411, is_okay: false, message: 'Error. Sale finished.'},
        412: {code: 412, is_okay: false, message: 'Error. Pre Sale supply stock finished.'},
        413: {code: 413, is_okay: false, message: 'Error. Public Sale supply stock finished.'},
        414: {
            code: 414,
            is_okay: true,
            message: 'Pre Sale order for $amount $symbol for the price of $price saved successfully.'
        },
        415: {code: 415, is_okay: true, message: 'Cancel order saved successfully.'},
        416: {code: 416, is_okay: false, message: 'Error. Auction not started.'},
        417: {code: 417, is_okay: false, message: 'Error. Auction finished.'},
        418: {
            code: 418,
            is_okay: false,
            message: 'Price must be be greater than or equal to opening price($opening_value)."'
        },
        419: {
            code: 419,
            is_okay: false,
            message: 'Price must be greater than current value($current_value) and must be multiple of value step($value_step).'
        },
        420: {
            code: 420,
            is_okay: true,
            message: 'Auction order for $symbol for the price of $price saved successfully.'
        },
        421: {code: 421, is_okay: true, message: "Delist order saved successfully."},
        422: {code: 422, is_okay: false, message: "Error. Sale state must be 'listing'."},
        423: {code: 423, is_okay: false, message: "Error. Delisting price must be greater than Art Product's current total value."},
        424: {code: 424, is_okay: false, message: "Error. Trade engine must be blocked."},

        501: {
            code: 501,
            is_okay: true,
            message: 'Your market buy order for $amount $symbol for the price of $price completed successfully.'
        },
        502: {
            code: 502,
            is_okay: true,
            message: 'Your limit buy order for $amount $symbol for the price of $price completed successfully.'
        },
        503: {
            code: 503,
            is_okay: true,
            message: 'Your market sell order for $amount $symbol for the price of $price completed successfully.'
        },
        504: {
            code: 504,
            is_okay: true,
            message: 'Your limit sell order for $amount $symbol for the price of $price completed successfully.'
        },
        505: {
            code: 505,
            is_okay: true,
            message: 'Your pre-sale buy order for $amount $symbol for the price of $price completed successfully.'
        },
        506: {code: 506, is_okay: true, message: 'Your $type order for $symbol cancelled successfully.'},
        507: {code: 507, is_okay: true, message: 'Your cancel order for $symbol completed successfully.'},
        508: {code: 508, is_okay: true, message: 'Your bid for the price of $price has been received.'},
        509: {code: 509, is_okay: false, message: 'Your bid for the price of $price has been exceeded.'},
        510: {code: 510, is_okay: true, message: 'Your bid for the price of $price has been completed.'},
        511: {code: 511, is_okay: true, message: "Your delist order of $symbol completed successfully."},

        602: {code: 602, is_okay: false, message: 'Not enough available stock.'},

        801: {code: 801, is_okay: true, message: 'Currency swapped successfully.'},
        802: {code: 802, is_okay: false, message: 'Currency swap failed. Not enough available stock.'},
        803: {code: 803, is_okay: true, message: 'Supply stock added successfully.'},
        804: {code: 804, is_okay: false, message: 'Trade Engine blocked.'},
        805: {code: 805, is_okay: false, message: 'Swap Engine blocked.'},
        806: {code: 806, is_okay: true, message: 'Trade Engine activated.'},
        807: {code: 807, is_okay: true, message: 'Swap Engine activated.'},
        808: {code: 808, is_okay: false, message: 'Auction Engine blocked.'},
        809: {code: 809, is_okay: true, message: 'Auction Engine activated.'},

        901: {code: 901, is_okay: true, message: 'Operation successful.'},
        902: {code: 902, is_okay: false, message: 'Operation fail.'},

        906: {code: 906, is_okay: false, message: 'User email not found.'},
        907: {code: 907, is_okay: false, message: 'User email already registered with award of $award.'},
        908: {code: 908, is_okay: true, message: '$user_email has won $award.'},

        1001: {cookies_accepted: ''},
        1002: {user_email: ''},
        1003: {auction_agreement_accepted: false},
        1004: {user_agreement_accepted: false},

        1103: {code: 1003, is_okay: false, message: 'This section will open when the works are listed on the market.'},
        1104: {code: 1004, is_okay: false, message: 'You must be logged in to be able to do this.'},

        1201: {code: 1201, is_okay: false, message: 'Art product could not be found.'},
        1202: {code: 1202, is_okay: false, message: 'Currency could not be found.'},
        1203: {code: 1203, is_okay: false, message: 'Pair could not be found.'},
        1204: {code: 1204, is_okay: false, message: 'Currency pair could not be found.'},
        1205: {code: 1205, is_okay: false, message: 'Zoom level could not be found.'},
        1206: {code: 1206, is_okay: false, message: 'Limit threshold reached.'},
        1207: {code: 1207, is_okay: false, message: 'Invalid IP address.'},
        1208: {code: 1208, is_okay: false, message: 'Invalid email address.'},
        1209: {code: 1209, is_okay: false, message: 'Invalid order type.'},
        1210: {code: 1210, is_okay: false, message: 'Invalid operation type.'},
        1211: {code: 1211, is_okay: false, message: 'Invalid amount.'},
        1212: {code: 1212, is_okay: false, message: 'Invalid price.'},
        1213: {code: 1213, is_okay: false, message: 'Socket.io ID required.'},
        1214: {code: 1214, is_okay: false, message: 'Erroneous parameter.'},
        1215: {
            code: 1215,
            is_okay: false,
            message: 'To register for the test environment, send an e-mail to support@artiox.com.'
        },
        1216: {code: 1216, is_okay: false, message: 'Password cannot include more than 40 characters.'},
        1217: {code: 1217, is_okay: false, message: 'Invalid TC ID.'},
        1218: {code: 1218, is_okay: false, message: 'Invalid name.'},
        1219: {code: 1219, is_okay: false, message: 'Invalid surname.'},
        1220: {code: 1220, is_okay: false, message: 'Invalid concatenated name field.'},
        1221: {code: 1221, is_okay: false, message: 'Request credit exceeded.'},
        1222: {code: 1222, is_okay: false, message: 'Missing argument.'},
        1223: {code: 1223, is_okay: false, message: 'Missing validator.'},
        1224: {code: 1224, is_okay: false, message: 'Backend error.'},
        1225: {code: 1225, is_okay: false, message: 'Block chain could not be found.'},
        1226: {code: 1226, is_okay: false, message: 'Auction product could not be found.'},

        2000: {code: 2000, is_okay: false, message: 'Captcha error, try again.'},
        2001: {code: 2001, is_okay: false, message: 'You need to login.'},
        2002: {code: 2002, is_okay: false, message: 'Passwords do not match.'},
        2003: {code: 2003, is_okay: false, message: 'Fields have to be filled properly.'},
        2004: {
            code: 2004,
            is_okay: false,
            message: 'Min 8 characters, must include 1 uppercase letter, 1 lowercase letter, 1 digit and 1 special character.',
            message_long: 'Min 8 characters, must include 1 uppercase letter, 1 lowercase letter, 1 digit and 1 special character.'
        },
        2005: {code: 2005, is_okay: false, message: 'File format issue.'},
        2006: {code: 2006, is_okay: false, message: 'Enter a valid email address.'},
        2007: {code: 2007, is_okay: false, message: 'The marked fields must be approved.'},
        2008: {code: 2008, is_okay: false, message: 'This field cannot be left empty.'},
        2009: {code: 2009, is_okay: false, message: 'Name could not be found in the database.'},
        2010: {code: 2010, is_okay: false, message: 'Please enter a valid phone number'},
        2011: {code: 2011, is_okay: false, message: 'Please enter a valid address'},
        2012: {code: 2012, is_okay: false, message: 'Please fill the necessary fields: '},
        2013: {code: 2013, is_okay: false, message: 'Please enter a valid tax number'},
        2014: {code: 2014, is_okay: false, message: 'Must contain at least 8 characters. '},
        2015: {code: 2015, is_okay: false, message: 'Must contain uppercase letter, lowercase letter and digit.'},
        2016: {code: 2016, is_okay: false, message: 'Your password must contain at least 1 uppercase letter and lowercase letter'},  // deprecated
        2017: {code: 2017, is_okay: false, message: 'Must contain special character'},
        2018: {code: 2018, is_okay: false, message: 'This field is required.'},
        2019: {code: 2019, is_okay: false, message: 'Service not allowed in dev mode.'},
        2020: {code: 2020, is_okay: false, message: 'Code should be composed of 6 digits.'},
    },
    ACTIVITY_TABLE: {
        701: {code: 701, reason: 'Password incorrect.', attempt: false},
        702: {code: 702, reason: 'Blacklisted IP login attempt.', attempt: false},
        703: {code: 703, reason: 'Successful login.', attempt: true},
        704: {code: 704, reason: 'You must allow new IP.', attempt: false},
        705: {code: 705, reason: 'IP verification mail resent.', attempt: false},
        706: {code: 706, reason: '2FA enabled successfully.', attempt: true},
        707: {code: 707, reason: 'Password changed successfully.', attempt: true},
        708: {code: 708, reason: 'Password change request submitted.', attempt: false},
        709: {code: 709, reason: 'Login from new IP detected.', attempt: false},
        710: {code: 710, reason: '2FA disabled successfully.', attempt: true},
        711: {code: 711, reason: 'Email changed successfully.', attempt: true},
    },
};
