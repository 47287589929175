import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ManagerService} from '../../../../services/manager.service';
import {FormControl, FormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {LoginStep} from '../login.component';
import {UserAccountVerificationCredentials} from "../../../../services/user.service";

@Component({
  selector: 'app-login-page-mobile',
  templateUrl: './login-page-mobile.component.html',
  styleUrls: ['./login-page-mobile.component.css']
})
export class LoginPageMobileComponent implements OnInit, AfterViewInit {

  protected isLogInClicked = false;
  @ViewChild('login_captcha') captcha: any;
  @ViewChild('main_page') main_page: any;
  @ViewChild('login_page') login_page: any;
  @ViewChild('email_error') emailErrorRef: any;
  @ViewChild('password_error') passwordErrorRef: any;
  // @ViewChild('auth_password_error') authPasswordErrorRef: any;
  protected loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    auth_password: new UntypedFormControl('', [Validators.pattern('^[0-9][0-9][0-9][0-9][0-9][0-9]$')]),
  });
  protected errorMap: any = {email: true, password: true, auth_password: true };
  protected viewMap: Map<string, ElementRef> = new Map<string, ElementRef>();
  protected subscriptionList: any[] = [];


  protected checkboxSelected = false;
  protected passwordPipe = false;
  public login_step: LoginStep = LoginStep.BASE;
  protected readonly LoginStep = LoginStep;

  constructor(protected managerService: ManagerService, protected httpClient: HttpClient) { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void{
    if (this.emailErrorRef instanceof ElementRef) {
      this.viewMap.set('email', this.emailErrorRef);
    }
    if (this.passwordErrorRef instanceof ElementRef) {
      this.viewMap.set('password', this.passwordErrorRef);
    }
    // if (this.authPasswordErrorRef instanceof ElementRef<any>) {
    //   this.viewMap.set('auth_password', this.authPasswordErrorRef);
    // }

    this.errorMap.email = false;
    this.errorMap.password = false;
    // this.errorMap.auth_password = false;
    this.subscriptionList.push(this.loginForm.get('auth_password')?.valueChanges.subscribe(() => {
      if (this.loginForm.get('auth_password')?.value.length === 6){
        setTimeout(() => {
          const submit_button = document.getElementById('submit-button');
          if (submit_button){
            submit_button.click();
            submit_button.classList.add('auto-click');
            setTimeout(() => {
              submit_button.classList.remove('auto-click');
            }, 300);
          }
        });
      }
    }));
  }
  loginStepResolver(e: boolean): void {
    if (e) {
      setTimeout(() => {
        this.login();
      }, 500);
    } else {
      this.login_step = LoginStep.BASE;
    }
  }
  login(): void {
    const data = {
      user_email: this.loginForm.value.email,
      password: this.loginForm.value.password,
      auth_password: this.loginForm.value.auth_password,
      client_info: this.managerService.client_info
    };
    this.subscriptionList.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'user_login_service', data).subscribe(
        response => {
          this.managerService.closeModal('loading-modal');
          if (!response.is_okay) {
            if (response.code === 1213) {
              this.managerService.openModal('loading-modal');
              setTimeout(() => {
                this.login();
              }, 500);
            }
            else if ([207, 208].includes(response.code)) {
              if (this.login_step === LoginStep.PUZZLE) {
                this.login_step = LoginStep.TWO_FACTOR;
                this.loginForm.get('auth_password')?.
                setValidators([Validators.required, Validators.pattern('^[0-9][0-9][0-9][0-9][0-9][0-9]$')]);
              }
              else if (this.login_step === LoginStep.TWO_FACTOR){
                const auth_error_div = document.getElementById('auth_password_error');
                auth_error_div?.classList.remove('d-none');
                // @ts-ignore
                auth_error_div.children[1].innerHTML = response.message;
              }
              else if (response.code === 205) {
                const credentialData = {
                  user_email: this.loginForm.value.email!,
                  password: this.loginForm.value.password!,
                };
                // this.managerService.userService.user_account_verification_credentials = new UserAccountVerificationCredentials({user_email: credentialData.user_email, verification_code: ''});
                this.managerService.userService.setUserAccountVerificationCredentials(credentialData.user_email, '');
                this.managerService.userService.createUserLoginData(credentialData);
                this.managerService.navigationManager({url: '/account-verification', current_component_index: 0});
              }
            } else {
              if (this.login_step === LoginStep.PUZZLE) {
                // @ts-ignore
                const content = this.managerService.config.RESPONSE_TABLE[response.code].message;
                this.emailErrorRef.nativeElement.children[1].innerHTML = content;
                // @ts-ignore
                this.passwordErrorRef.nativeElement.children[1].innerHTML = content;
                this.emailErrorRef.nativeElement.classList.remove('d-none');
                this.passwordErrorRef.nativeElement.classList.remove('d-none');
                // @ts-ignore
                document.getElementById('email').classList.add('error');
                // @ts-ignore
                document.getElementById('password').classList.add('error');
                this.login_step = LoginStep.BASE;

              }
              else if (this.login_step === LoginStep.TWO_FACTOR){
                // @ts-ignore
                const content = this.managerService.config.RESPONSE_TABLE[response.code].message;
                // this.authPasswordErrorRef.nativeElement.children[1].innerHTML = content;
                // this.authPasswordErrorRef.nativeElement.classList.remove('d-none');
                // @ts-ignore
                document.getElementById('auth_password').classList.add('error');
              }
            }
          } else {
            this.managerService.loggedInResolver(true, response.cookie);
            this.clearLoginForm();
            this.managerService.navigateIfLoggedIn({url: '/home', current_component_index: 0});
            this.login_step = LoginStep.BASE;
          }
        }));
  }
  clearLoginForm(): void {
    this.loginForm.reset({email: '', password: '', auth_password: ''});
    this.loginForm.get('auth_password')?.setErrors(null);
    this.login_step = LoginStep.BASE;
  }

  protected validateFormGroup(): void{
    if (this.loginForm.invalid){

      // tslint:disable-next-line:forin
      for (const controlKey in this.loginForm.controls) {
        const controlMember = this.loginForm.get(controlKey);
        let elementRef = this.viewMap.get(controlKey)?.nativeElement;
        if (elementRef === undefined){
          // @ts-ignore
          elementRef = document.getElementById(controlKey + '_error');
        }
        if (controlMember?.errors?.required){
          // @ts-ignore
          elementRef.children[1].innerHTML = this.managerService.config.RESPONSE_TABLE['2003'].message;
          // @ts-ignore
          elementRef.classList.remove('d-none');
          // @ts-ignore
          document.getElementById(controlKey).classList.add('error');
        }
        else if (controlMember?.errors?.email){
          // @ts-ignore
          elementRef.children[1].innerHTML = this.managerService.config.RESPONSE_TABLE['2006'].message;
          // @ts-ignore
          elementRef.classList.remove('d-none');
          // @ts-ignore
          document.getElementById(controlKey).classList.add('error');
        }
        else if (controlMember?.errors?.pattern){
          // 2 factor only
          // @ts-ignore
          elementRef.children[1].innerHTML = this.managerService.config.RESPONSE_TABLE['208'].message;
          // @ts-ignore
          elementRef.classList.remove('d-none');
          // @ts-ignore
          document.getElementById(controlKey).classList.add('error');

        }
        else{
          // @ts-ignore
          elementRef.classList.add('d-none');
          // @ts-ignore
          document.getElementById(controlKey)?.classList.remove('error');
        }
      }

    }
    else if (this.login_step === LoginStep.BASE){
      // this.captcha.el.nativeElement.classList.remove('d-none');
      // this.captcha.el.nativeElement.classList.add('d-flex');
      this.login_step = LoginStep.PUZZLE;
    }
    else if (this.login_step === LoginStep.TWO_FACTOR) {
      this.login();

    }
  }
  openLogin(): void{
    this.main_page.nativeElement.classList.add('d-none');
    this.login_page.nativeElement.classList.remove('d-none');
  }
  closeLogin(): void{
    this.main_page.nativeElement.classList.remove('d-none');
    this.login_page.nativeElement.classList.add('d-none');
  }


}
