<div class="grid">
  <app-pair-header-desktop></app-pair-header-desktop>
  <div class="position-absolute" style="top: 150px; right: 0;">
    <svg (click)="this.managerService.tooltipService.toggleAll()" style="height: 24px; width: 24px; padding: 4px; border-radius: 4px; box-shadow: 0 2px 4px #abafc7; user-select: none;">
      <use [attr.xlink:href]='"./assets/images/info.svg#info"' fill="#404352"/>
    </svg>
  </div>
  <app-pair-list-desktop></app-pair-list-desktop>
  <app-order-book-balance-desktop></app-order-book-balance-desktop>
  <app-trade-chart-desktop></app-trade-chart-desktop>
  <app-trade-history-desktop></app-trade-history-desktop>
  <app-trade-desktop></app-trade-desktop>
  <div class="user-operation-panel">
    <div class="w-100 d-flex flex-row align-items-center justify-content-start exo body2 bold" style="height: 30px;
      line-height: 1.25; color: #404352; cursor: pointer;">
      <div (click)="managerService.active_user_operation_panel=managerService.active_user_operation_template.USER_OPEN_ORDERS;"
           [style]="managerService.active_user_operation_panel !== managerService.active_user_operation_template.USER_OPEN_ORDERS ? {'box-shadow':  'inset -2px -2px 3px rgba(213, 216, 226, 1)', color: '#9da5b7'} : {}"
           class="h-100 d-flex flex-row align-items-center justify-content-center text-center" style="width: 300px;">
        <div class="d-flex flex-column align-items-center justify-content-center">
          <div>
            {{managerService.config.ADVANCED.USER_OPEN_ORDERS.HEADING}}
          </div>
          <div class="d-flex flex-row align-items-center justify-content-center">
            <app-tooltip [direction]="managerService.tooltipService.tooltip_direction_template.BOTTOM | managerPipe" class="d-none position-relative" id="user-open-orders-heading-tooltip">
              {{managerService.config.TOOLTIP.ADVANCED.USER_OPEN_ORDERS_HEADING_TOOLTIP | managerPipe}}
            </app-tooltip>
          </div>
        </div>
      </div>
      <div (click)="managerService.active_user_operation_panel=managerService.active_user_operation_template.USER_TRADE_HISTORY"
           [style]="managerService.active_user_operation_panel !== managerService.active_user_operation_template.USER_TRADE_HISTORY ? {'box-shadow':'inset +2px -2px 3px rgba(213, 216, 226, 1)', color: '#9da5b7'} : {}"
           class="h-100 d-flex flex-row align-items-center justify-content-center text-center" style="width: 300px;">
        <div class="d-flex flex-column align-items-center justify-content-center">
          <div>
            {{managerService.config.ADVANCED.USER_TRADE_HISTORY.HEADING}}
          </div>
          <div class="d-flex flex-row align-items-center justify-content-center">
            <app-tooltip [direction]="managerService.tooltipService.tooltip_direction_template.BOTTOM | managerPipe" class="d-none position-relative" id="user-trade-history-heading-tooltip">
              {{managerService.config.TOOLTIP.ADVANCED.USER_TRADE_HISTORY_HEADING_TOOLTIP | managerPipe}}
            </app-tooltip>
          </div>
        </div>
      </div>
      <div
        [style]="managerService.active_user_operation_panel !== managerService.active_user_operation_template.USER_OPEN_ORDERS ? {'box-shadow': 'inset +2px -2px 3px rgba(213, 216, 226, 1)'} : {'box-shadow': 'inset -2px -2px 3px rgba(213, 216, 226, 1)'}"
        class="h-100" style="width: calc(100% - 600px);"></div>
    </div>
    <div *ngIf="!managerService.logged_in | managerPipe" class="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
      <div class="font-weight-bold" style="font-size: 14px;">{{managerService.config.SIGN_UP_OR_LOGIN.HEADING_USER_OPERATIONS}}</div>
      <div><a (click)="managerService.navigationManager({url: '/sign-up', current_component_index: 0})" class="text-highlight text-info">{{managerService.config.SIGN_UP_OR_LOGIN.SIGN_UP}}</a>
        {{managerService.config.SIGN_UP_OR_LOGIN.OR}}
        <a (click)="managerService.openModal('login-modal')" class="text-highlight text-info">{{managerService.config.SIGN_UP_OR_LOGIN.LOGIN}}</a></div>
    </div>
    <div *ngIf="managerService.userService.user_ready | managerPipe">
      <app-user-open-orders-desktop [class.d-none]="managerService.active_user_operation_panel === managerService.active_user_operation_template.USER_TRADE_HISTORY"></app-user-open-orders-desktop>
      <app-user-trade-history-desktop [class.d-none]="managerService.active_user_operation_panel === managerService.active_user_operation_template.USER_OPEN_ORDERS"></app-user-trade-history-desktop>
    </div>
  </div>
</div>
