import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';

@Component({
  selector: 'app-user-trade-history-desktop',
  templateUrl: './user-trade-history-desktop.component.html',
  styleUrls: ['./user-trade-history-desktop.component.css']
})
export class UserTradeHistoryDesktopComponent implements OnInit {

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

}
