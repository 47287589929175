import {Component, OnDestroy, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';
import {Clipboard} from '@angular/cdk/clipboard';
import {CurrencyWallet} from '../../../../../../services/user.service';
import {Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-deposit-cryptocurrency-desktop',
  templateUrl: './deposit-cryptocurrency-desktop.component.html',
  styleUrls: ['./deposit-cryptocurrency-desktop.component.css']
})
export class DepositCryptocurrencyDesktopComponent implements OnInit, OnDestroy {
  // public subscription_list: Subscription[] = [];

  public crypto_wallet: CurrencyWallet = {} as CurrencyWallet;
  constructor(public managerService: ManagerService, private httpClient: HttpClient, private clipboard: Clipboard) {
    this.managerService.setUserCryptoWalletList();
  }

  ngOnInit(): void {
    this.crypto_wallet = this.managerService.operationsService.user_crypto_currency_wallet_list[0];
  }

  copyAddress(): void {
    this.clipboard.copy(this.managerService.operationsService.selected_user_currency_wallet.address);
    this.managerService.popSnackBar(true, this.managerService.config.DEPOSIT.COPIED);
  }

  selectCurrencyWallet(crypto_wallet: CurrencyWallet): void {
    if (!this.managerService.operationsService.crypto_deposit_rules_accepted_status) {
      this.managerService.openModal('crypto-deposit-rules-modal');
    }
    this.managerService.operationsService.selected_user_currency_wallet = crypto_wallet;
  }

  ngOnDestroy(): void {
    this.managerService.operationsService.crypto_deposit_rules_accepted_status = false;
    // this.subscription_list.forEach((subscription) => {
    //   subscription.unsubscribe();
    // });
  }

}
