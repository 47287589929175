<div class="w-100 d-flex flex-column align-items-start justify-content-start"
     style="box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff; height: 840px; border-radius: 4px;">
  <div class="w-100 mt-4 px-5 d-flex flex-row align-items-center justify-content-between"
       style="font-family: Roboto, sans-serif; font-size: 13px; font-weight: bold; line-height: 1.2; letter-spacing: 0.9px; color: #404352;">
    <div>{{managerService.config.BALANCE.USER_OPERATION_HISTORY | managerPipe}}</div>
    <div class="d-flex flex-row"
         style="font-family: Roboto, sans-serif; font-weight: normal; font-size: 12px; line-height: 2; letter-spacing: 1.2px; color: #595e7e;">
      <div class="mx-1">{{managerService.config.BALANCE.PAIR}}:</div>
      <div class="mx-1 mr-3">
        <button
          (click)="filter_menu.classList.toggle('d-none')"
          class="btn-native filter-dropdown-button px-2">
          {{currency_selected_placeholder | managerPipe}}
          <svg style="height: 20px; width: 20px;">
            <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#595e7e"/>
          </svg>
        </button>
        <div #filter_menu class="filter-dropdown-menu d-none">
          <div
            (click)="currency_selected = undefined; currency_selected_placeholder = managerService.config.BALANCE.ALL; filter_menu.classList.add('d-none');"
            [style.border]="((currency_selected_placeholder === managerService.config.BALANCE.ALL) | managerPipe) ? 'solid 1px #f4f5f9' : ''"
            class="btn-native filter-dropdown-item py-1 text-center" style=" cursor: default">
            {{managerService.config.BALANCE.ALL | managerPipe}}
          </div>
          <div
            (click)="currency_selected = currency.id; currency_selected_placeholder = currency.symbol; filter_menu.classList.add('d-none');"
            *ngFor="let currency of (managerService.currency_pipe | managerPipe: managerService.mpa_template.GET_CURRENCY_LIST)"
            [style.border]="((currency_selected_placeholder === currency.symbol) | managerPipe) ? 'solid 1px #f4f5f9' : ''"
            class="btn-native filter-dropdown-item py-1 text-center" style=" cursor: default">
            {{currency.symbol | managerPipe}}
          </div>
        </div>
      </div>
      <div class="mx-1">{{managerService.config.BALANCE.OPERATION_TYPE | managerPipe}}:</div>
      <div class="mx-1 text-left">
        <div
          (click)="filter_type_menu.classList.toggle('d-none')"
          [class.text-center]="operation_type_selected_placeholder === managerService.config.BALANCE.ALL"
          [style.width]="((managerService.language === 'TR') | managerPipe) ? '100px' : '120px'"
          class="btn-native filter-dropdown-button">
          <div *ngIf="! (operation_type_selected_placeholder === managerService.config.BALANCE.ALL)"
               [style]="operation_type_selected_placeholder | managerPipe: managerService.mpa_template.GET_USER_OPERATION_HISTORY_FILTER_BACKGROUND_COLOR"
               class="d-inline-block ml-2 mr-1"
               style="height: 8px; width: 8px;"></div>
          {{operation_type_selected_placeholder | managerPipe}}
          <svg style="margin-left: -2px; height: 20px; width: 20px;">
            <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#595e7e"/>
          </svg>
        </div>
        <div #filter_type_menu [style.width]="((managerService.language === 'TR') | managerPipe) ? '100px' : '120px'"
             class="filter-dropdown-menu d-none">
          <div
            (click)="operation_type_selected = undefined; operation_type_selected_placeholder = managerService.config.BALANCE.ALL; filter_type_menu.classList.add('d-none');"
            [style.border]="(( operation_type_selected_placeholder === managerService.config.BALANCE.ALL) | managerPipe) ? 'solid 1px #f4f5f9' : ''"
            [style.width]="((managerService.language === 'TR') | managerPipe) ? '100px' : '120px'"
            class="btn-native filter-dropdown-item py-1 text-center" style=" cursor: default">
            {{managerService.config.BALANCE.ALL | managerPipe}}
          </div>
          <div
            (click)="operation_type_selected = 'deposit'; operation_type_selected_placeholder = managerService.config.BALANCE.PAYING; filter_type_menu.classList.add('d-none');"
            [style.border]="(( operation_type_selected_placeholder === managerService.config.BALANCE.PAYING) | managerPipe) ? 'solid 1px #f4f5f9' : ''"
            [style.width]="((managerService.language === 'TR') | managerPipe) ? '100px' : '120px'"
            class="btn-native filter-dropdown-item py-1" style=" cursor: default">
            <div class="d-inline-block ml-2 mr-1" style="background-color: #2ad8a9; height: 8px; width: 8px;"></div>
            {{managerService.config.BALANCE.PAYING | managerPipe}}
          </div>
          <div
            (click)="operation_type_selected = 'withdrawal'; operation_type_selected_placeholder = managerService.config.BALANCE.WITHDRAWAL; filter_type_menu.classList.add('d-none');"
            [style.border]="(( operation_type_selected_placeholder === managerService.config.BALANCE.WITHDRAWAL) | managerPipe) ? 'solid 1px #f4f5f9' : ''"
            [style.width]="((managerService.language === 'TR') | managerPipe) ? '100px' : '120px'"
            class="btn-native filter-dropdown-item py-1" style=" cursor: default">
            <div class="d-inline-block ml-2 mr-1" style="background-color: #ff207d; height: 8px; width: 8px;"></div>
            {{managerService.config.BALANCE.WITHDRAWAL | managerPipe}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-100 d-flex flex-row align-items-center justify-content-around py-1 my-3"
       style="font-family: Roboto, sans-serif;font-size: 12px; font-weight: 500; line-height: 2.7; letter-spacing: 0.7px; color: #4a4e70;">
  <span class="pl-5 text-left"
        style="width: 14%">{{managerService.config.BALANCE.SYMBOL | managerPipe}}</span>
    <span class="text-center"
          style="width: 16%">{{managerService.config.BALANCE.OPERATION_TYPE | managerPipe}}</span>
    <span class="text-center"
          style="width: 14%">{{managerService.config.BALANCE.AMOUNT| managerPipe}}</span>
    <span class="text-center"
          style="width: 16%">{{managerService.config.BALANCE.DATE | managerPipe}}</span>
    <span class="text-center"
          style="width: 16%">{{managerService.config.BALANCE.KIND | managerPipe}}</span>
    <span class="text-center"
          style="width: 18%">{{managerService.config.BALANCE.EXPLANATION | managerPipe}}</span>
    <span class="pr-4 text-right"
          style="width: 16%">{{managerService.config.BALANCE.STATUS | managerPipe}}</span>
  </div>
  <div class="w-100 d-flex flex-column overflow-auto">
    <div
      *ngFor="let user_operation of ((managerService.user_currency_operation_history_pipe | managerPipe: managerService.mpa_template.GET_USER_OPERATION_HISTORY: currency_selected: operation_type_selected: undefined) | managerPipe: managerService.mpa_template.SORT_BY_CREATION_TIME)"
      class="w-100 d-flex flex-row align-items-center justify-content-around py-1 user-trade-history"
      style="font-family: Roboto, sans-serif;font-size: 10px; font-weight: 500; line-height: 2.7; letter-spacing: 0.7px; color: #4a4e70;">
      <span class="pl-5 text-left"
            style="width: 14%;">{{user_operation.currency_id | managerPipe: managerService.mpa_template.CURRENCY_SYMBOL: managerService.pair_pipe}}</span>
      <span [style]="user_operation | managerPipe: managerService.mpa_template.GET_USER_OPERATION_BACKGROUND_COLOR"
            class="text-center"
            style="width: 16%">{{user_operation.type | managerPipe: managerService.mpa_template.GET_USER_OPERATION_TYPE: managerService.language_pipe}}</span>
      <span class="text-center"
            style="width: 14%">{{user_operation | managerPipe: managerService.mpa_template.USER_OPERATION_HISTORY_AMOUNT}}</span>
      <span class="text-center"
            style="width: 16%">{{user_operation.creation_time | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</span>
      <span class="text-center"
            style="width: 16%">{{user_operation.kind | managerPipe}}</span>
      <span (click)="managerService.navigationManager(managerService.userOperationTransactionInfoLink(user_operation))"
            [innerHTML]="user_operation | managerPipe: managerService.mpa_template.GET_USER_OPERATION_INFO: managerService.language_pipe"
            [style.cursor]="(user_operation | managerPipe: managerService.mpa_template.GET_USER_OPERATION_INFO_STYLE)"
            class="text-center" style="width: 18%"></span>
      <span class="pr-4 text-right"
            style="width: 16%">{{managerService.config.BALANCE | managerPipe: managerService.mpa_template.RETURN_INDEX: user_operation.status.toUpperCase()}}</span>
    </div>
  </div>
</div>
