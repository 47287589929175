import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-order-history-mobile',
  templateUrl: './order-history-mobile.component.html',
  styleUrls: ['./order-history-mobile.component.css']
})
export class OrderHistoryMobileComponent implements OnInit, AfterViewInit, OnDestroy {

  public pair_selected: any = undefined;
  public trade_type_selected: any = undefined;
  public pair_selected_placeholder: string = this.managerService.config.BALANCE.ALL;
  public trade_type_selected_placeholder: string = this.managerService.config.BALANCE.ALL;

  public subscription_list: Subscription[] = [];

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.subscription_list.push(this.managerService.language_changed.subscribe(() => {
      this.pair_selected_placeholder = this.managerService.config.BALANCE.ALL;
      this.trade_type_selected_placeholder = this.managerService.config.BALANCE.ALL;
    }));
  }

  ngOnDestroy(): void {
    this.subscription_list.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

}
