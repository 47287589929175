<div class="w-100 min-vw-100 d-flex flex-column align-items-center justify-content-center px-3">
  <div class="w-100 d-flex flex-row align-self-center justify-content-center">
    <span class="pr-2" style="font-size: 18px; height: 14px;">
      <svg (click)="managerService.art_product_info_display_status=true"
           style="height: 14px; width: 14px;">
        <use [attr.xlink:href]='"./assets/images/info.svg#info"' fill="white"/>
      </svg>
    </span>
    <img *ngIf="managerService.pairService.active_featured_pair_ready | managerPipe"
         (click)="managerService.photoSwipeOpen(managerService.pairService.active_featured_pair)" alt="art_product"
         class="p-1" #art_product
         src="{{(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_IMAGE_MOBILE | managerPipe: managerService.mpa_template.LOCAL_IMAGE_MANAGER)}}"
         style="max-height: 240px; max-width: 86%;"
         (error)="art_product.src = art_product.src.replace('.webp', '.jpg');">
    <span class="pl-2" style="font-size: 18px; height: 14px;">
      <svg (click)="managerService.art_product_token_info_display_status=true"
           style="height: 14px; width: 14px;">
        <use [attr.xlink:href]='"./assets/images/token_info.svg#token_info"' fill="white"/>
      </svg>
    </span>
  </div>
  <span class="d-flex flex-row align-items-center justify-content-center pb-2">
    <p class="mx-1 mb-1"
       style="font-family: Roboto, sans-serif; font-size: 14px; color: white;">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_ARTIST_NAME}}</p>
    <p
      [class.d-none]="(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_ARTIST_BORN_DEATH) | managerPipe: managerService.mpa_template.CHECK_LENGTH_SHORTER_THAN : 4"
      class="mb-1" style="font-family: Roboto, sans-serif; font-size: 14px; color: white;">
      ({{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_ARTIST_BORN_DEATH}})
    </p>
  </span>
</div>
