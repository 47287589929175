<div class="d-flex flex-column w-100">
  <div class="w-100 d-flex flex-row align-items-center justify-content-start"
       style="box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff; max-height: 100px; min-height: 100px; border-radius: 4px; margin-bottom: 10px;">
    <div class="h-100 d-flex flex-column m-4">
      <span class="m-1"
            style="font-family: Roboto, sans-serif; font-size: 15px; font-weight: bold; line-height: 1.1; letter-spacing: 0.75px; color: #404352;">{{managerService.config.BALANCE.TOTAL_BALANCE | managerPipe}}</span>
      <span class="m-1"
            style="font-family: 'Roboto Condensed', sans-serif; font-size: 20px; line-height: 1.2; letter-spacing: 2.5px; color: #404352;">{{(managerService.user_pipe | managerPipe: managerService.mpa_template.GET_USER_TOTAL_ART_PRODUCT_TYPE_OF_CURRENCY: managerService.pair_pipe: managerService.currency_pair_pipe) + (managerService.user_pipe | managerPipe: managerService.mpa_template.GET_USER_TOTAL_CURRENCY_TYPE_OF_CURRENCY: managerService.pair_pipe: managerService.currency_pair_pipe) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}₺</span>
    </div>
    <span
      style="height: 60px; width: 1px; min-width: 2px; margin: auto 10px; border-radius:1px; background-color: #dddddd;"></span>
    <div class="h-100 d-flex flex-column m-4">
      <span class="m-1"
            style="font-family: Roboto, sans-serif; font-size: 15px; font-weight: normal; line-height: 1.1; letter-spacing: 0.75px; color: #404352;">{{managerService.config.BALANCE.TL | managerPipe}}</span>
      <span class="m-1"
            style="font-family: 'Roboto Condensed', sans-serif; font-size: 16px; line-height: 1.2; letter-spacing: 2.5px; color: #404352;">{{(managerService.user_pipe | managerPipe: managerService.mpa_template.GET_USER_TOTAL_FIAT_CURRENCY_TYPE_OF_CURRENCY: managerService.pair_pipe: managerService.currency_pair_pipe) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}₺</span>
    </div>
    <span
      style="height: 60px; width: 1px; min-width: 2px; margin: auto 10px; border-radius:1px; background-color: #dddddd;"></span>
    <div class="h-100 d-flex flex-column m-4">
      <span class="m-1"
            style="font-family: Roboto, sans-serif; font-size: 15px; font-weight: normal; line-height: 1.1; letter-spacing: 0.75px; color: #404352;">
        {{managerService.config.BALANCE.CRYPTO_BALANCE | managerPipe}}
      </span>
      <span class="m-1"
            style="font-family: 'Roboto Condensed', sans-serif; font-size: 16px; line-height: 1.2; letter-spacing: 2.5px; color: #404352;">{{( 1 | managerPipe: managerService.mpa_template.GET_USER_CRYPTO_CURRENCY_TYPE_OF_CURRENCY: managerService.pair_pipe: managerService.currency_pair_pipe: managerService.user_pipe) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}₺</span>
    </div>
    <span
        style="height: 60px; width: 1px; min-width: 2px; margin: auto 10px; border-radius:1px; background-color: #dddddd;"></span>
      <div class="h-100 d-flex flex-column m-4">
      <span class="m-1"
            style="font-family: Roboto, sans-serif; font-size: 15px; font-weight: normal; line-height: 1.1; letter-spacing: 0.75px; color: #404352;">
        {{managerService.config.BALANCE.ETHEREUM_BALANCE | managerPipe}}
      </span>
          <span class="m-1"
                style="font-family: 'Roboto Condensed', sans-serif; font-size: 16px; line-height: 1.2; letter-spacing: 2.5px; color: #404352;">{{( 2 | managerPipe: managerService.mpa_template.GET_USER_CRYPTO_CURRENCY_TYPE_OF_CURRENCY: managerService.pair_pipe: managerService.currency_pair_pipe: managerService.user_pipe) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}₺</span>
      </div>
      <span
              style="height: 60px; width: 1px; min-width: 2px; margin: auto 10px; border-radius:1px; background-color: #dddddd;"></span>
    <div class="h-100 d-flex flex-column m-4">
      <span class="m-1"
            style="font-family: Roboto, sans-serif; font-size: 15px; font-weight: normal; line-height: 1.1; letter-spacing: 0.75px; color: #404352;">{{managerService.config.BALANCE.ART_PRODUCT | managerPipe}}</span>
      <span class="m-1"
            style="font-family: 'Roboto Condensed', sans-serif; font-size: 16px; line-height: 1.2; letter-spacing: 2.5px; color: #404352;">~{{(managerService.user_pipe | managerPipe: managerService.mpa_template.GET_USER_TOTAL_ART_PRODUCT_TYPE_OF_CURRENCY: managerService.pair_pipe: managerService.currency_pair_pipe) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}₺</span>
    </div>
  </div>
  <div class="w-100 d-flex flex-column align-items-start justify-content-start"
       style="box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff; height: 360px; border-radius: 4px; margin-bottom: 10px;">
    <div class="mt-4 ml-4"
         style="font-family: bebas-neue-regular, sans-serif;font-size: 20px; font-weight: bold; line-height: 1.2; letter-spacing: 4px; color: #404352;">
      {{managerService.config.BALANCE.PORTFOLIO | managerPipe}}
    </div>
    <div class="w-100 d-flex flex-row align-items-center justify-content-center px-4 py-3"
         style="font-family: Roboto, sans-serif; font-size: 14px; font-weight: bold; line-height: 1; letter-spacing: 0.4px; color: #404352;">
      <div class="text-left" style="width: 10%">{{managerService.config.BALANCE.SYMBOL | managerPipe}}</div>
      <div class="text-right" style="width: 16%">{{managerService.config.BALANCE.AVAILABLE_AMOUNT | managerPipe}}</div>
      <div class="text-right" style="width: 16%">{{managerService.config.BALANCE.IN_ORDER | managerPipe}}</div>
      <div class="text-right" style="width: 16%">{{managerService.config.BALANCE.TOTAL_AMOUNT | managerPipe}}</div>
      <div class="text-right" style="width: 16%">{{managerService.config.BALANCE.VALUE | managerPipe}}</div>
      <div class="text-right" style="width: 26%; min-width: 340px"></div>
    </div>
    <div class="w-100 overflow-auto">
      <div
        *ngFor="let user_asset of (managerService.user_pipe | managerPipe: managerService.mpa_template.GET_USER_ASSETS: managerService.pair_pipe: managerService.currency_pair_pipe)"
        class="w-100 d-flex flex-row align-items-center justify-content-center px-4 py-1 user-asset"
        style="font-family: Roboto, sans-serif; font-size: 13px; line-height: 1.5; letter-spacing: 0.4px; color: #404352;">
        <div class="text-left" class="font-weight-bold"
             style="width: 10%">{{user_asset | managerPipe: managerService.mpa_template.GET_USER_ASSETS_SYMBOL}}</div>
        <div class="text-right"
             style="width: 16%">{{user_asset.available_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER_DECIMAL: (user_asset.currency_id === 2 ? 6 : 2)}}</div>
        <div class="text-right" style="width: 16%">
          {{user_asset | managerPipe: managerService.mpa_template.USER_INORDER_AMOUNT: managerService.user_open_orders_history_pipe | managerPipe: managerService.mpa_template.FANCY_NUMBER_DECIMAL: (user_asset.currency_id === 2 ? 6 : 2)}}
        </div>
        <div class="text-right"
             style="width: 16%">{{user_asset.total_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER_DECIMAL: (user_asset.currency_id === 2 ? 6 : 2)}}</div>
        <div class="text-right"
             style="width: 16%">{{user_asset | managerPipe: managerService.mpa_template.GET_USER_ASSETS_VALUE : managerService.pair_pipe: managerService.currency_pair_pipe}}</div>
        <div class="pl-3" style="width: 26%; min-width: 340px">
          <button
                  (click)="managerService.setActiveCurrencyPairByCurrencyId(user_asset.currency_id); managerService.openModal('currency-converter-modal');"
                  *ngIf="user_asset | managerPipe: managerService.mpa_template.IS_BASE_ASSET_IN_CURRENCY_PAIR : managerService.pair_pipe: managerService.currency_pair_pipe"
                  class="btn-native user-asset-button mx-2"
                  style=" height: 28px;">
            {{managerService.config.BALANCE.TRADE}}
          </button>
          <button
                  (click)="managerService.setActivePairByArtProductId(user_asset.art_product_id); managerService.navigationManager({url: '/exchange/advanced', current_component_index: 0});"
                  *ngIf="user_asset | managerPipe: managerService.mpa_template.IS_BASE_ASSET_IN_PAIR : managerService.pair_pipe: managerService.currency_pair_pipe"
                  class="btn-native user-asset-button mx-2"
                  style=" height: 28px;">
            {{managerService.config.BALANCE.TRADE}}
          </button>
          <button (click)="managerService.navigationManager({url: '/account/deposit', current_component_index: 0, active_deposit_page: managerService.navigationService.deposit_page_template.TURKISH_LIRA});"
                  *ngIf="(user_asset | managerPipe: managerService.mpa_template.IS_CURRENCY_ASSET_FIAT : managerService.pair_pipe: managerService.currency_pair_pipe)"
                  style=" height: 28px;"
                  class="btn-native user-asset-button mx-2">{{managerService.config.BALANCE.DEPOSIT}}</button>
          <button (click)="managerService.navigationManager({url: '/account/deposit', current_component_index: 0, active_deposit_page: managerService.navigationService.deposit_page_template.TETHER});"
                  *ngIf="((user_asset | managerPipe: managerService.mpa_template.IS_CURRENCY_ASSET_CRYPTO : managerService.pair_pipe: managerService.currency_pair_pipe) && user_asset.currency_id === 1)"
                  style=" height: 28px;"
                  class="btn-native user-asset-button mx-2">{{managerService.config.BALANCE.DEPOSIT}}</button>
            <button (click)="managerService.navigationManager({url: '/account/deposit', current_component_index: 0, active_deposit_page: managerService.navigationService.deposit_page_template.ETHEREUM});"
                  *ngIf="((user_asset | managerPipe: managerService.mpa_template.IS_CURRENCY_ASSET_CRYPTO : managerService.pair_pipe: managerService.currency_pair_pipe)  && user_asset.currency_id === 2)"
                  style=" height: 28px;"
                  class="btn-native user-asset-button mx-2">{{managerService.config.BALANCE.DEPOSIT}}</button>
          <button (click)="managerService.navigationManager({url: '/account/withdrawal', current_component_index: 0, active_withdrawal_page: managerService.navigationService.withdrawal_page_template.TURKISH_LIRA});"
                  style=" height: 28px;"
                  *ngIf="(user_asset | managerPipe: managerService.mpa_template.IS_CURRENCY_ASSET_FIAT : managerService.pair_pipe: managerService.currency_pair_pipe)"
                  class="btn-native user-asset-button mx-2">{{managerService.config.BALANCE.WITHDRAW}}</button>
          <button (click)="managerService.navigationManager({url: '/account/withdrawal', current_component_index: 0, active_withdrawal_page: managerService.navigationService.withdrawal_page_template.TETHER});"
                  style=" height: 28px;"
                  *ngIf="((user_asset | managerPipe: managerService.mpa_template.IS_CURRENCY_ASSET_CRYPTO : managerService.pair_pipe: managerService.currency_pair_pipe) && user_asset.currency_id === 1)"

                  class="btn-native user-asset-button mx-2">{{managerService.config.BALANCE.WITHDRAW}}</button>
          <button (click)="managerService.navigationManager({url: '/account/withdrawal', current_component_index: 0, active_withdrawal_page: managerService.navigationService.withdrawal_page_template.ETHEREUM});"
                  style=" height: 28px;"
                  *ngIf="((user_asset | managerPipe: managerService.mpa_template.IS_CURRENCY_ASSET_CRYPTO : managerService.pair_pipe: managerService.currency_pair_pipe)  && user_asset.currency_id === 2)"
                  class="btn-native user-asset-button mx-2">{{managerService.config.BALANCE.WITHDRAW}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="w-100 d-flex flex-column align-items-start justify-content-start"
       style="box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff; height: 360px; border-radius: 4px;">
    <div class="w-100 mt-4 px-4 d-flex flex-row align-items-center justify-content-between">
      <div style="font-family: bebas-neue-regular, sans-serif;font-size: 20px; font-weight: bold; line-height: 1.2; letter-spacing: 4px; color: #404352;">
        {{managerService.config.BALANCE.USER_OPEN_ORDERS_UPPERCASE | managerPipe}}
      </div>
      <div class="d-flex flex-row"
           style="font-family: Roboto, sans-serif; font-weight: normal; font-size: 12px; line-height: 2; letter-spacing: 1.2px; color: #595e7e;">
        <div class="mx-1">{{managerService.config.BALANCE.PAIR}}:</div>
        <div class="mx-1 mr-3">
          <button
            (click)="filter_menu.classList.toggle('d-none')"
            class="btn-native filter-dropdown-button px-2">
            {{pair_selected_placeholder | managerPipe}}
            <svg style="height: 20px; width: 20px;">
              <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#595e7e"/>
            </svg>
          </button>
          <div #filter_menu class="filter-dropdown-menu d-none">
            <div
              (click)="pair_selected = undefined; pair_selected_placeholder = managerService.config.BALANCE.ALL; filter_menu.classList.add('d-none');"
              class="btn-native filter-dropdown-item py-1" style=" cursor: default"
              [style.border]="(( pair_selected_placeholder === managerService.config.BALANCE.ALL) | managerPipe) ? 'solid 1px #f4f5f9' : ''">
              {{managerService.config.BALANCE.ALL | managerPipe}}
            </div>
            <div
              (click)="pair_selected = pair.id; pair_selected_placeholder = pair.symbol; filter_menu.classList.add('d-none');"
              *ngFor="let pair of (managerService.pair_pipe | managerPipe: managerService.mpa_template.GET_SORTED_LISTED_PAIR_LIST)"
              class="btn-native filter-dropdown-item py-1" style=" cursor: default"
              [style.border]="((pair_selected_placeholder === pair.symbol) | managerPipe) ? 'solid 1px #f4f5f9' : ''">
              {{pair.symbol | managerPipe}}
            </div>
          </div>
        </div>
        <div class="mx-1">{{managerService.config.BALANCE.ORDER_TYPE}}:</div>
        <div class="mx-1">
          <button
            (click)="filter_type_menu.classList.toggle('d-none')"
            class="btn-native filter-dropdown-button px-2">
            <div class="d-inline-block ml-2 mr-1" style="height: 8px; width: 8px;"
                 [style]="order_type_selected_placeholder | managerPipe: managerService.mpa_template.GET_USER_SUMMARY_FILTER_BACKGROUND_COLOR"></div>
            {{order_type_selected_placeholder | managerPipe}}
            <svg style="height: 20px; width: 20px;">
              <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#595e7e"/>
            </svg>
          </button>
          <div #filter_type_menu class="filter-dropdown-menu d-none">
            <div
              (click)="order_type_selected = undefined; order_type_selected_placeholder = managerService.config.BALANCE.ALL; filter_type_menu.classList.add('d-none');"
              class="btn-native filter-dropdown-item py-1" style=" cursor: default"
              [style.border]="((order_type_selected_placeholder === managerService.config.BALANCE.ALL) | managerPipe) ? 'solid 1px #f4f5f9' : ''">
              {{managerService.config.BALANCE.ALL | managerPipe}}
            </div>
            <div
              (click)="order_type_selected = 'buy'; order_type_selected_placeholder = managerService.config.BALANCE.BUY; filter_type_menu.classList.add('d-none');"
              class="btn-native filter-dropdown-item py-1 text-left" style=" cursor: default"
              [style.border]="((order_type_selected_placeholder === managerService.config.BALANCE.BUY) | managerPipe) ? 'solid 1px #f4f5f9' : ''">
              <div class="d-inline-block ml-2 mr-1" style="background-color: #2ad8a9; height: 8px; width: 8px;"></div>
              {{managerService.config.BALANCE.BUY | managerPipe}}
            </div>
            <div
              (click)="order_type_selected = 'sell'; order_type_selected_placeholder = managerService.config.BALANCE.SELL; filter_type_menu.classList.add('d-none');"
              class="btn-native filter-dropdown-item py-1 text-left" style=" cursor: default"
              [style.border]="((order_type_selected_placeholder === managerService.config.BALANCE.SELL) | managerPipe) ? 'solid 1px #f4f5f9' : ''">
              <div class="d-inline-block ml-2 mr-1" style="background-color: #ff207d; height: 8px; width: 8px;"></div>
              {{managerService.config.BALANCE.SELL | managerPipe}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-100 d-flex flex-row align-items-center justify-content-around py-1"
         style="font-family: Roboto, sans-serif;font-size: 11px; font-weight: 500; line-height: 2.7; letter-spacing: 0.7px; color: #4a4e70;">
      <span class="pl-4 text-left"
            style="width: 16%">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.PAIR | managerPipe}}</span>
      <span class="text-center"
            style="width: 16%">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.TYPE | managerPipe}}</span>
      <span class="text-center"
            style="width: 12%">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.PRICE | managerPipe}}</span>
      <span class="text-center"
            style="width: 16%">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.AMOUNT | managerPipe}}</span>
      <span class="text-center"
            style="width: 16%">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.TIME | managerPipe}}</span>
      <span class="text-center"
            style="width: 8%">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.CANCEL | managerPipe}}</span>
      <span class="pr-4 text-right" style="width: 12%"></span>
    </div>
    <div class="w-100 d-flex flex-column overflow-auto mb-3" style="min-height: 210px; max-height: 210px;">
      <div
        *ngFor="let user_open_order of (managerService.user_open_orders_history_pipe | managerPipe: managerService.mpa_template.GET_USER_OPEN_ORDERS: pair_selected: order_type_selected)"
        class="d-flex flex-row align-items-center justify-content-around flex-wrap py-1 user-open-order"
        style="font-family: Roboto, sans-serif;font-size: 11px; font-weight: 500; line-height: 2.7; letter-spacing: 0.7px; color: #4a4e70; flex-shrink: 0">
    <span class="pl-4 text-left"
          style="width: 16%">{{user_open_order.pair_id | managerPipe: managerService.mpa_template.PAIR_SYMBOL: managerService.pair_pipe}}</span>
        <span class="text-center"
              style="width: 16%">{{user_open_order.type | managerPipe: managerService.mpa_template.USER_OPEN_ORDER_TYPE: managerService.language_pipe}}</span>
        <span [style]="user_open_order | managerPipe: managerService.mpa_template.GET_USER_OPEN_ORDERS_BACKGROUND_COLOR"
              class="text-center"
              style="width: 12%">{{user_open_order.price | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
        <span class="text-center"
              style="width: 16%">{{user_open_order.amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
        <span class="text-center"
              style="width: 16%">{{user_open_order.creation_time | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</span>
        <span class="text-center" style="width: 8%">
      <svg (click)="managerService.cancelUserOrder(user_open_order);" style="height: 20px; width: 20px;" type="button">
        <use [attr.xlink:href]='"./assets/images/close.svg#close"' fill="#4fc0d2"/>
      </svg>
    </span>
        <span (click)="more_div.classList.toggle('d-none'); more_div.classList.toggle('d-flex')" class="pr-4 text-right"
              style="width: 12%"
              type="button">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.MORE | managerPipe}}</span>
        <div #more_div class="w-100 d-none flex-row flex-wrap align-items-center justify-content-around py-1"
             style="font-family: Roboto, sans-serif;font-size: 11px; font-weight: 500; line-height: 1.2; letter-spacing: 0.7px; color: #4a4e70;">
          <span
            class="w-50 pl-4 my-1 text-left">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.TIME | managerPipe}}</span>
          <span
            class="w-50 pr-4 my-1 text-right">{{user_open_order.creation_time | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</span>
          <span
            class="w-50 pl-4 my-1 text-left">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.FILLED_PERCENTAGE | managerPipe}}</span>
          <span
            class="w-50 pr-4 my-1 text-right">{{(1 - user_open_order.amount / user_open_order.init_amount) * 100 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
            %</span>
          <span
            class="w-50 pl-4 my-1 text-left">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.FILLED_AMOUNT | managerPipe}}</span>
          <span
            class="w-50 pr-4 my-1 text-right">{{user_open_order.init_amount - user_open_order.amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
          <span
            class="w-50 pl-4 my-1 text-left">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.FILLED_VOLUME | managerPipe}}</span>
          <span
            class="w-50 pr-4 my-1 text-right">{{user_open_order.price * (user_open_order.init_amount - user_open_order.amount) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
          <span
            class="w-50 pl-4 my-1 text-left">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.TOTAL_AMOUNT | managerPipe}}</span>
          <span
            class="w-50 pr-4 my-1 text-right">{{user_open_order.init_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
          <span
            class="w-50 pl-4 my-1 text-left">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.TOTAL_VOLUME | managerPipe}}</span>
          <span
            class="w-50 pr-4 my-1 text-right">{{user_open_order.price * user_open_order.init_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
