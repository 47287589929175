import {Component, OnDestroy, OnInit} from '@angular/core';
import {ManagerService} from '../../../services/manager.service';
import {Subscription} from 'rxjs';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-exchange',
  templateUrl: './exchange.component.html',
  styleUrls: ['./exchange.component.css']
})
export class ExchangeComponent implements OnInit, OnDestroy {

  public subscription_list: Subscription[] = [];

  constructor(public managerService: ManagerService, private titleService: Title) {
    if (this.managerService.pairService.active_pair_ready) {
      this.titleService.setTitle(this.managerService.fancyNumber(this.managerService.pairService.getActivePair()?.price ?? 0) + '  |  ' + this.managerService.pairService.getActivePair()?.symbol + ' | ARTIOX | art investment 3.0');
    }
    this.subscription_list.push(this.managerService.pairService.active_pair_changed.subscribe(() => {
      this.titleService.setTitle(this.managerService.fancyNumber(this.managerService.pairService.getActivePair()?.price ?? 0) + '  |  ' + this.managerService.pairService.getActivePair()?.symbol + ' | ARTIOX | art investment 3.0');
    }));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription_list.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.titleService.setTitle('ARTIOX | art investment 3.0');
  }

}
