<div class="w-100 d-flex flex-column align-items-start justify-content-start"
     style="background-color: #fcfdff;">
  <div class="w-100 py-2 px-3 d-flex flex-column">
    <div class="align-self-start py-2"
         style="font-family: babes-neue-normal, sans-serif; font-size: 14px; letter-spacing: 0.7px; color: #272a3b;">
      {{managerService.config.BALANCE.USER_OPERATION_HISTORY_UPPERCASE | managerPipe}}
    </div>
    <div class="d-flex flex-row align-self-end"
         style="font-family: Roboto, sans-serif; font-weight: normal; font-size: 12px; color: #595e7e;">
      <div class="mx-1">
        <button
          (click)="filter_menu.classList.toggle('d-none')"
          class="btn-native filter-dropdown-button"
          style="font-family: Roboto, sans-serif; font-weight: normal; font-size: 12px; color: #9da5b7; width: 81px; height: 20px;">
          {{currency_selected_placeholder | managerPipe}}
          <svg style="height: 14px; width: 14px;">
            <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#595e7e"/>
          </svg>
        </button>
        <div #filter_menu class="filter-dropdown-menu d-none">
          <div
            (click)="currency_selected = undefined; currency_selected_placeholder = managerService.config.BALANCE.ALL; filter_menu.classList.add('d-none');"
            [style.background-color]="((currency_selected_placeholder === managerService.config.BALANCE.ALL) | managerPipe) ? '#f4f5f9' : ''"
            class="btn-native filter-dropdown-item text-center py-1">
            {{managerService.config.BALANCE.ALL | managerPipe}}
          </div>

          <div
            (click)="currency_selected = currency.id; currency_selected_placeholder = currency.symbol; filter_menu.classList.add('d-none');"
            *ngFor="let currency of (managerService.currency_pipe | managerPipe: managerService.mpa_template.GET_CURRENCY_LIST)"
            [style.background-color]="((currency_selected_placeholder === currency.symbol) | managerPipe) ? '#f4f5f9' : ''"
            class="btn-native filter-dropdown-item text-center py-1">
            {{currency.symbol | managerPipe}}
          </div>
        </div>
      </div>
      <div class="mx-1">
        <button
          (click)="filter_type_menu.classList.toggle('d-none')"
          [class.text-left]="operation_type_selected_placeholder !== managerService.config.BALANCE.ALL && managerService.language === 'EN'"
          [style.width]="((managerService.language === 'TR') | managerPipe) ? '81px' : '99px'"
          class="btn-native filter-dropdown-button"
          style="font-family: Roboto, sans-serif; font-weight: normal; font-size: 12px; color: #9da5b7; height: 20px;">
          <div [class.d-none]="operation_type_selected_placeholder === managerService.config.BALANCE.ALL" [style]="operation_type_selected_placeholder | managerPipe: managerService.mpa_template.GET_USER_OPERATION_HISTORY_FILTER_BACKGROUND_COLOR"
               class="d-inline-block ml-1 mr-1"
               style="height: 8px; width: 8px;"></div>
          {{operation_type_selected_placeholder | managerPipe}}
          <svg style="height: 14px; width: 14px;">
            <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#595e7e"/>
          </svg>
        </button>
        <div #filter_type_menu [style.width]="((managerService.language === 'TR') | managerPipe) ? '81px' : '99px'"
             class="filter-dropdown-menu d-none">
          <div
            (click)="operation_type_selected = undefined; operation_type_selected_placeholder = managerService.config.BALANCE.ALL; filter_type_menu.classList.add('d-none');"
            [style.background-color]="((operation_type_selected_placeholder === managerService.config.BALANCE.ALL) | managerPipe) ? '#f4f5f9' : ''"
            [style.width]="((managerService.language === 'TR') | managerPipe) ? '81px' : '99px'"
            class="btn-native filter-dropdown-item text-center py-1">
            {{managerService.config.BALANCE.ALL | managerPipe}}
          </div>
          <div
            (click)="operation_type_selected = 'deposit'; operation_type_selected_placeholder = managerService.config.BALANCE.PAYING; filter_type_menu.classList.add('d-none');"
            [style.background-color]="((operation_type_selected_placeholder === managerService.config.BALANCE.PAYING) | managerPipe) ? '#f4f5f9' : ''"
            [style.width]="((managerService.language === 'TR') | managerPipe) ? '81px' : '99px'"
            class="btn-native filter-dropdown-item py-1">
            <div class="d-inline-block ml-2 mr-1" style="background-color: #2ad8a9; height: 8px; width: 8px;"></div>
            {{managerService.config.BALANCE.PAYING | managerPipe}}
          </div>
          <div
            (click)="operation_type_selected = 'withdrawal'; operation_type_selected_placeholder = managerService.config.BALANCE.WITHDRAWAL; filter_type_menu.classList.add('d-none');"
            [style.background-color]="((operation_type_selected_placeholder === managerService.config.BALANCE.WITHDRAWAL) | managerPipe) ? '#f4f5f9' : ''"
            [style.width]="((managerService.language === 'TR') | managerPipe) ? '81px' : '99px'"
            class="btn-native filter-dropdown-item py-1">
            <div class="d-inline-block ml-2 mr-1" style="background-color: #ff207d; height: 8px; width: 8px;"></div>
            {{managerService.config.BALANCE.WITHDRAWAL | managerPipe}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-100 d-flex flex-row align-items-center justify-content-around px-3 py-1"
       style="font-family: Roboto, sans-serif; font-size: 10px; color: #272a3b;">
  <span class="text-left"
        style="width: 15%">{{managerService.config.BALANCE.SYMBOL | managerPipe}}</span>
    <span class="text-center"
          style="width: 27%">{{managerService.config.BALANCE.OPERATION_TYPE | managerPipe}}</span>
    <span class="text-center"
          style="width: 20%">{{managerService.config.BALANCE.AMOUNT | managerPipe}}</span>
    <span class="text-center"
          style="width: 28%">{{managerService.config.BALANCE.KIND| managerPipe}}</span>
    <span class="text-right"
          style="width: 10%;"></span>
  </div>
  <div class="w-100 d-flex flex-column overflow-auto" style="max-height: 460px">
    <div
      *ngFor="let user_operation of ((managerService.user_currency_operation_history_pipe | managerPipe: managerService.mpa_template.GET_USER_OPERATION_HISTORY: currency_selected: operation_type_selected: undefined) | managerPipe: managerService.mpa_template.SORT_BY_CREATION_TIME)"
      class="d-flex flex-row align-items-center justify-content-around flex-wrap px-3 user-trade-history flex-shrink-0"
      style="font-family: Roboto, sans-serif;  font-weight: 500; letter-spacing: 0.7px; font-size: 10px; color: #646673;">
      <div class="w-100 d-flex flex-row align-items-center justify-content-around py-2">
        <span class="text-left"
              style="width: 15%; color: #4a4e70; font-weight: 500; letter-spacing: 0.7px;">{{user_operation.currency_id | managerPipe: managerService.mpa_template.CURRENCY_SYMBOL: managerService.pair_pipe}}</span>
        <span class="text-center"
              style="width: 27%;">{{user_operation.type | managerPipe: managerService.mpa_template.GET_USER_OPERATION_TYPE: managerService.language_pipe}}</span>
        <span class="text-center"
              style="width: 20%">{{user_operation | managerPipe: managerService.mpa_template.USER_OPERATION_HISTORY_AMOUNT}}</span>
        <span [style]="user_operation | managerPipe: managerService.mpa_template.GET_USER_OPERATION_BACKGROUND_COLOR"
              class="text-center"
              style="width: 28%">{{user_operation.kind | managerPipe}}</span>
        <span (click)="more_div.classList.toggle('d-none'); more_div.classList.toggle('d-flex')"
              class="text-right" style="width: 10%; font-size: 9px; color: #9da5b7; font-style: italic; font-weight: 500; letter-spacing: normal;"
              type="button">
            {{managerService.config.ADVANCED.USER_TRADE_HISTORY.MORE | managerPipe}}
        </span>
      </div>
      <div #more_div class="w-100 d-none flex-row flex-wrap align-items-center justify-content-around pb-1"
           style="font-family: Roboto, sans-serif;font-size: 10px; font-weight: 500; line-height: 1.2; letter-spacing: 0.7px; color: #4a4e70;">
        <span class="w-50  my-1 text-left">{{managerService.config.BALANCE.DATE | managerPipe}}</span>
        <span class="w-50  my-1 text-right">{{user_operation.creation_time | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</span>
        <span class="w-25  my-1 text-left">{{managerService.config.BALANCE.STATUS | managerPipe}}</span>
        <span class="w-75  my-1 text-right">{{managerService.config.BALANCE | managerPipe: managerService.mpa_template.RETURN_INDEX: user_operation.status.toUpperCase()}}</span>
        <span class="w-25  my-1 text-left">{{managerService.config.BALANCE.EXPLANATION | managerPipe}}</span>
        <span (click)="managerService.navigationManager(managerService.userOperationTransactionInfoLink(user_operation))"
              [innerHTML]="user_operation | managerPipe: managerService.mpa_template.GET_USER_OPERATION_INFO: managerService.language_pipe"
              class="w-75  my-1 text-right"></span>
      </div>
    </div>
  </div>
</div>
