import {Component, Input, OnInit} from '@angular/core';
import {ManagerService} from "../../../../../services/manager.service";

@Component({
  selector: 'app-account-level-mobile',
  templateUrl: './account-level-mobile.component.html',
  styleUrls: ['./account-level-mobile.component.css']
})
export class AccountLevelMobileComponent implements OnInit {

  @Input() modal_id: string = '';
  constructor(public managerService: ManagerService) { }

  ngOnInit(): void {
  }

}
