import {Component, OnInit, ViewChild} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-auction-bid-mobile',
    templateUrl: './auction-bid-mobile.component.html',
    styleUrls: ['./auction-bid-mobile.component.css']
})
export class AuctionBidMobileComponent implements OnInit {

    @ViewChild('gavel') gavel: any;

    constructor(public managerService: ManagerService) {
    }

    ngOnInit(): void {
    }

    scrollBids(): void {
        this.managerService.active_auction_info_panel = this.managerService.active_auction_info_panel_template.LAST_BIDS;
        this.gavel.nativeElement.scrollIntoView({behavior: 'smooth'});
    }
}
