import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';

@Component({
  selector: 'app-pair-header-desktop',
  templateUrl: './pair-header-desktop.component.html',
  styleUrls: ['./pair-header-desktop.component.css']
})
export class PairHeaderDesktopComponent implements OnInit {

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

}
