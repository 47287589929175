<div class="w-100 d-flex flex-row align-items-center justify-content-start subtitle2 exo bold" style="height: 30px; cursor: pointer;
   line-height: 1.25;  color: #404352;">
  <div (click)="managerService.setActiveTradeKindPanel(managerService.active_trade_kind_panel_template.LIMIT);"
       [style]="managerService.active_trade_kind_panel === managerService.active_trade_kind_panel_template.MARKET ? {'box-shadow':  'inset -2px -2px 3px rgba(213, 216, 226, 1)', color: '#9da5b7'} : {}"
       class="h-100 w-25 d-flex flex-row align-items-center justify-content-center">
    <div class="d-flex flex-column align-items-center justify-content-center">
      <div>
        {{managerService.config.ADVANCED.LIMIT | managerPipe}}
      </div>
      <div class="d-flex flex-row align-items-center justify-content-center">
        <app-tooltip [direction]="managerService.tooltipService.tooltip_direction_template.BOTTOM | managerPipe" class="d-none position-relative" id="limit-tooltip">
          {{managerService.config.TOOLTIP.ADVANCED.LIMIT_TOOLTIP | managerPipe}}
        </app-tooltip>
      </div>
    </div>
  </div>
  <div (click)="managerService.setActiveTradeKindPanel(managerService.active_trade_kind_panel_template.MARKET);"
       [style]="managerService.active_trade_kind_panel === managerService.active_trade_kind_panel_template.LIMIT ? {'box-shadow':'inset +2px -2px 3px rgba(213, 216, 226, 1)', color: '#9da5b7'} : {}"
       class="h-100 w-25 d-flex flex-row align-items-center justify-content-center">
    <div class="d-flex flex-column align-items-center justify-content-center">
      <div class="d-flex flex-row align-items-center justify-content-center">
        <app-tooltip [direction]="managerService.tooltipService.tooltip_direction_template.TOP | managerPipe" class="d-none position-relative" id="market-tooltip">
          {{managerService.config.TOOLTIP.ADVANCED.MARKET_TOOLTIP | managerPipe}}
        </app-tooltip>
      </div>
      <div>
        {{managerService.config.ADVANCED.MARKET | managerPipe}}
      </div>
    </div>
  </div>
  <div
    [style]="managerService.active_trade_kind_panel === managerService.active_trade_kind_panel_template.MARKET ? {'box-shadow': 'inset +2px -2px 3px rgba(213, 216, 226, 1)'} : {'box-shadow': 'inset -2px -2px 3px rgba(213, 216, 226, 1)'}"
    class="h-100 w-50"></div>
</div>
<app-limit-trade-desktop
  [class.d-none]="managerService.active_trade_kind_panel !== managerService.active_trade_kind_panel_template.LIMIT"></app-limit-trade-desktop>
<app-market-trade-desktop
  [class.d-none]="managerService.active_trade_kind_panel !== managerService.active_trade_kind_panel_template.MARKET"></app-market-trade-desktop>
