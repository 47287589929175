<div id="basic_pair_list" class="pb-2">
  <h1 class="vw-100 min-vw-100 m-auto pl-4 pt-3"
      style="color: #dbdde2; font-family: Raleway, sans-serif; font-size: 24px;">
    {{managerService.config.BASIC.ART_PRODUCTS | managerPipe}}
  </h1>
  <div
    *ngFor="let pair of (managerService.pair_pipe | managerPipe: managerService.mpa_template.GET_LISTED_PAIR_LIST)"
    class="vw-100 min-vw-100 d-flex flex-column align-items-center justify-content-center mb-3">
    <div #div
         [style]="{height: (div.offsetWidth | managerPipe: managerService.mpa_template.ASPECT_RATIO_16_9),
        'background-image': 'url(' + (pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_IMAGE_BACKGROUND_MOBILE | managerPipe: managerService.mpa_template.LOCAL_IMAGE_MANAGER_TO_BE) + ')',
         'background-size': 'contain'}"
         class="w-100 d-flex flex-column align-items-start justify-content-start">
      <div class="h-100 w-50 d-flex flex-column align-items-start justify-content-start"
           style="background: rgba(0, 0, 0, 0.5);">
        <p class="mx-auto my-2 text-center"
           style="font-family: Raleway, sans-serif; font-size: 18px; font-weight: normal; color: #fcfdff;">
          {{pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_NAME}}
        </p>
        <p class="mx-auto my-2 text-center"
           style="font-family: Raleway, sans-serif; font-size: 14px; font-weight: normal; color: #d5d8e2;">
          {{pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_ARTIST_NAME: managerService.language_pipe}}</p>
        <button (click)="managerService.navigationManager({url: '/exchange/basic', active_pair_id: pair.id, current_component_index: 1})" class="btn-native btn-white mt-auto mb-3 mx-auto">{{pair.sale_state | managerPipe: managerService.mpa_template.SALE_STATE_BUTTON: managerService.language_pipe}}</button>
      </div>
    </div>
  </div>
</div>
