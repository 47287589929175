

<!--  <div [style]="(managerService.inner_width >= 1100 | managerPipe) ? {width: '85%'} : {width: '100%'}" class="d-flex flex-row align-items-center justify-content-around m-auto"-->
<!--       style="height: 60px;">-->
<div  class="d-flex flex-row align-items-center justify-content-between m-auto"
     style="height: 60px; width: calc(45% + 550px); min-width: 1050px">
    <div class="h-100 d-flex flex-column align-items-center justify-content-center">
      <div class="d-inline-flex flex-row align-items-center justify-content-center">
        <svg class="mr-1" style="height:40px; width: 40px; min-width: 40px;">
          <use [attr.xlink:href]='"./assets/images/art_product_token.svg#art_product_token"' class="w-100" fill="#8266f0"/>
        </svg>
        <div class=" pr-3 text-center"
             style="font-family: 'Manrope', sans-serif; font-size: 50px; font-weight:bold; color: #8266f0; ">
          {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_SYMBOL}}
        </div>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-center">
        <app-tooltip [direction]="managerService.tooltipService.tooltip_direction_template.BOTTOM | managerPipe"
                     class="d-none position-relative" id="active-art-product-symbol-tooltip" style="margin-top: -40px">
          {{managerService.config.TOOLTIP.BASIC.ACTIVE_ART_PRODUCT_SYMBOL_TOOLTIP | managerPipe}}
        </app-tooltip>
      </div>
    </div>

    <div class="h-100 d-flex flex-column align-items-center justify-content-center">
      <div class="h-100 d-flex flex-column align-items-center justify-content-center pt-1">
      <span class="text-center exo body2 bold mb-1"
            style=" color: #37324D;">
        {{managerService.config.BASIC.PAIR_HEADER.LAST_PRICE | managerPipe}}
      </span>
        <div class="d-flex flex-row align-items-center justify-content-center">
    <span class="pr-1 text-center barlow body2 medium"
          style=" color: #6f6f6f; ">
    1 {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_SYMBOL}}:
    </span>
          <span
                  [style]="managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_CHANGE | managerPipe: managerService.mpa_template.PAIR_CHANGE_COLOR"
                  class="pr-1 text-center barlow body2 medium"
                  >
      {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_PRICE}}
    </span>
          <span class="text-center barlow body2 medium"
                style="color: #6f6f6f; ">
          {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_CURRENCY_SYMBOL}}
        </span>
        </div>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-center">
        <app-tooltip [direction]="managerService.tooltipService.tooltip_direction_template.BOTTOM  | managerPipe"
                     class="d-none position-relative" id="active-pair-price" max_width="150px" style="margin-top: -25px">
          {{managerService.config.TOOLTIP.BASIC.ACTIVE_PAIR_PRICE_TOOLTIP  | managerPipe}}
        </app-tooltip>
      </div>
    </div>

    <div class="h-100 d-flex flex-column align-items-center justify-content-center">
      <div class="h-100 d-flex flex-column align-items-center justify-content-center pt-1">
    <span class="text-center exo body2 bold"
          style=" color: #37324D; ">
      {{managerService.config.BASIC.PAIR_HEADER.CHANGE_24_HOUR_DESKTOP | managerPipe}}
    </span>
        <div class="d-flex flex-row align-items-center justify-content-center">
      <span
              [style]="managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_CHANGE | managerPipe: managerService.mpa_template.PAIR_CHANGE_COLOR"
              class="pr-1 text-center barlow body2 medium mt-1">
      {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_CHANGE}}%
      </span>
        </div>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-center">
        <app-tooltip [direction]="managerService.tooltipService.tooltip_direction_template.BOTTOM  | managerPipe"
                     class="d-none position-relative" id="active-pair-change" style="margin-top: -25px">
          {{managerService.config.TOOLTIP.BASIC.ACTIVE_PAIR_CHANGE_TOOLTIP | managerPipe}}
        </app-tooltip>
      </div>
    </div>

    <div class="h-100 d-flex flex-column align-items-center justify-content-center">
      <div class="h-100 d-flex flex-column align-items-center justify-content-center pt-1">
    <span class="text-center exo body2 bold mb-1"
          style=" color: #37324D;">
      {{managerService.config.BASIC.PAIR_HEADER.VOLUME_24_HOUR_DESKTOP | managerPipe}}
    </span>
        <div class="d-flex flex-row align-items-center justify-content-center">
      <span
              class="pr-1 text-center barlow body2 medium"
              style=" color: #6f6f6f;">
      {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_VOLUME}}
      </span>
          <span class="text-center barlow body2 medium"
                style=" color: #6f6f6f; ">
          {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_CURRENCY_SYMBOL}}
        </span>
        </div>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-center">
        <app-tooltip [direction]="managerService.tooltipService.tooltip_direction_template.BOTTOM  | managerPipe"
                     class="d-none position-relative" id="active-pair-volume" style="margin-top: -25px">
          {{managerService.config.TOOLTIP.BASIC.ACTIVE_PAIR_VOLUME_TOOLTIP  | managerPipe}}
        </app-tooltip>
      </div>
    </div>

    <div class="h-100 d-flex flex-row align-items-center justify-content-center" style="width: 200px;">
<!--          <span class="pr-1 text-center" style="font-family: 'Manrope', sans-serif; font-size: 20px; color: #6f6f6f;">-->
<!--                  {{[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_CURRENCY_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.BASIC.TRADE.AVAILABLE_AMOUNT}}-->
<!--          </span>-->
<!--          <span class="text-center" style="font-family: Roboto, sans-serif; font-size: 20px; font-weight:bold; color: #6f6f6f;">-->
<!--            {{managerService.pair_pipe | managerPipe: managerService.mpa_template.USER_ACTIVE_CURRENCY_AVAILABLE_AMOUNT: managerService.user_pipe}}₺-->
<!--          </span>-->
    </div>
  </div>


