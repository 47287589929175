<h1>
    Kullanıcı Sözleşmesi
</h1>

<p>
    Artiox Teknoloji Yazılım A.Ş. olarak; düzenlediğimiz online müzayedelere iştirak
    edecek (katılımcılar) alıcılar aşağıdaki şartları peşinen kabul ederler.
</p>

<ol>
    <li><p>Artiox Teknoloji Yazılım A.Ş. satıcı için bir aracı olup elektronik ortamda anında
        ifa ettiği aracılık hizmeti dolayısıyla satış anında komisyona hak kazanır.</p></li>
    <li><p>Satışa sunulan tüm eserler 2863 sayılı Kültür ve Tabiat Varlıkları Kanunu’na
        göre T.C. Kültür ve Turizm Bakanlığı Müze uzmanlarınca kontrol edilmekte, yasa
        kapsamına giren ve gerek görülenlerine belge verilmektedir.</p></li>
    <li><p>Online müzayedelere katılabilmek için www.artiox.com adresinden üye kaydı
        yapılması zorunludur.</p></li>
    <li><p>Online müzayedelerde yer alan eserler satış öncesinde online katalog
        yayınlandığı tarihten sonra Kızılırmak Mah. Dumlupınar Bul. No:9A D:287
        Çankaya/Ankara adresinde teşhir edilmektedir. Online müzayede kataloğunda yer
        alan eserlerin ve tanıtımı için verilen tüm bilgiler eksperlerin kanaatleridir.
        Eserlerin online kataloğa konulması online müzayedeye hazırlık sürecidir ve
        müzayede gününe kadar online katalog üzerinden eser satışı yapılmamaktadır.</p></li>
    <li><p>Online müzayedeye katılanlar eserleri Artiox Teknoloji Yazılım A.Ş.’nin 4.
        Maddede belirtilen adresinde önceden görmüş ve incelemiş olduklarını kabul ve
        beyan ederler. Tüm eserlerin haliyle satışa sunulduğunu, alıcı satılan eseri pey
        sürmeden evvel yeterince kontrol ettiğini ve Borçlar Kanunu’nun 223. Maddesinde
        belirtilen hükümlerden feragat ettiğini kabul ve taahhüt eder. Her eserin yanında
        haliyle yazma zorunluluğu yoktur. Eser hakkında verilen bilgiler ve her türlü ilan,
        taahhüt niteliğinde olmayıp genel bilgi niteliğindedir. Alıcı bu durumu peşinen
        kabul eder. Artiox Teknoloji Yazılım A.Ş. satılan eserin (malın) tekeffülü ile
        mükellef değildir. Bu konuda gelecekte Artiox Teknoloji Yazılım A.Ş. firmasından
        herhangi bir itiraz ve talepte bulunmayacaktır.</p></li>
    <li><p>Online müzayedelerde fiyat artırımı “TEKLİF VER” veya “HIZLI TEKLİF” yazan
        yere tıklanarak yapılır. Alıcı online müzayedede pey vermek suretiyle yapmış
        olduğu teklif ile bağlıdır. Müzayede konusu eşya, Artiox Teknoloji Yazılım A.Ş.
        tarafından önceden belirlenen müzayede bitiş saati itibariyle en yüksek teklifte
        bulunan alıcıya ihale edilir. Alıcı, her ne sebeple olursa olsun müzayedenin
        butlanını dermeyan edemez. Online müzayedede TEKLİF VER veya “HIZLI
        TEKLİF” bölümüne basmanın ne kadar değer artışını ifade ettiğini belirleme ve bu
        değerleri değiştirme yetkisi şirketimize aittir. Online Müzayedeye çıkarılan bir
        eserin bedelinin artırılmasına muhammen değerin yüzde yirmi veya daha eksiği ile
        başlanabilir. Online müzayedeye, siteye üye kaydı yaptırmayan konuklar
        katılamazlar. Üyeliğin, üyelik sahibi kişi dışında kullanılması yasaktır. Online
        müzayede katılımcısı, işbu kendisine özgülenmiş şifrenin başka kişilerce
        kullanımı hususunda şirketimizin hiçbir sorumluluk kabul etmeyeceğini kabul
        beyan ve taahhüt eder. Artırma ürün için verilen sürenin sonuna kadar pey vermeksuretiyle devam eder ve sürenin dolmasıyla ürüne en fazla peyi veren kişi
        artırmayı kazanmış olur.</p>
        <ol>
            <li><p>Artiox Teknoloji Yazılım A.Ş. internet sitesinde yapılan online müzayedelerde,
                müzayede devam ederken, müzayede konusu ürün için verilen en yüksek teklif
                güncel haliyle müzayede ekranında yer alacaktır. Bu bağlamda, online
                müzayedeye katılanlar, öne sürülen peyler ve kim tarafından hangi tutarda pey
                sürüldüğü, en yüksek peyi kimin sürdüğü vb. bilgiler şirketimiz nezdinde
                şifrelenerek güvenlik altına alınacak olup, katılımcılar bu kayıtlara herhangi bir
                şekilde itiraz hakkı bulunmadıklarını kabul beyan ve taahhüt ederler.</p></li>
            <li><p>
                Online müzayedeye çıkarılan ürünlerin bedellerine, KDV ile aracı hizmet
                komisyon bedeli (+KDV) eklenecek olup alıcı bütün bedellerden sorumludur.
            </p></li>
        </ol>

    </li>
    <li><p>Satın alınan tüm eserler için fatura hazırlanmaktadır. Faturalar üye numarasına,
        ad, soyad ve adrese göre tanzim edilir. Ancak müşteriye verilen fatura yasal
        zorunluluk nedeniyle Artiox Teknoloji Yazılım A.Ş. tarafından düzenlenmektedir.
        Fatura kesilmesiArtiox Teknoloji Yazılım A.Ş.’nin satıcı olduğunu göstermez.
        Artiox Teknoloji Yazılım A.Ş. satıcı değil; satıma aracılık eden aracıdır. Artiox
        Teknoloji Yazılım A.Ş.’nin işbu aracılık hizmeti nedeniyle hak kazanacağı %10
        komisyon + KDV alacağı, müzayedede eşya üzerine kalan kişi tarafından ayrıca
        ödenecektir. Artiox Teknoloji Yazılım A.Ş.’nin komisyon(+KDV) alacağı müzayede
        sona erdiği andan itibaren muaccel olacaktır. Alıcı ihale konusu eseri almaktan
        vazgeçse dahi cezai şart yanında %10 komisyon bedelini de ödemekle
        mükelleftir. Artiox Teknoloji Yazılım A.Ş.’nin bu kapsamda sunduğu hizmet;
        elektronik ortamda anında ifa edilen aracılık hizmeti olup cayma hakkı kapsamı
        dışındadır.</p></li>
    <li><p>Satın alınan eşyanın bedeli havale yoluyla peşin olarak, müzayede gününden
        itibaren en geç 7 gün içinde alıcı tarafından ödenir. Alıcı satış bedelinin tamamını,
        Katma Değer Vergisi'ni (KDV) ve %10 müzayede şirketi komisyonunu (+KDV)
        ödediği Artiox Teknoloji Yazılım A.Ş.'nin ürünü teslim yükümlülüğü doğacaktır.
        Ödemeden itibaren; şehir içi teslimlerde 7 işgünü, şehir dışı teslimlerde 14 işgünü
        içinde Artiox Teknoloji Yazılım A.Ş. eşyayı alıcıya kargo yoluyla
        gönderecektir/teslim edecektir veya alınan eşya alıcı tarafından Kızılırmak Mah.
        Dumlupınar Bul. No:9A D:287 Çankaya/Ankara adresinden teslim alınacaktır. Eşya
        kargoya verilirse eğer, eşyanın kargoya verildiği Artiox Teknoloji Yazılım
        A.Ş. tarafından alıcıya bildirilir. Kargo firmasından kaynaklanan
        gecikmelerden Artiox Teknoloji Yazılım A.Ş. sorumlu tutulamaz.</p></li>
    <li><p>Artiox doğrudan sanatçı, kişi veya kuruluşun veya koleksiyonerin sahipliğinde
        olan eserleri de Müzayedeye (Açık arttırmaya) koyabilir, bu eserleri teslim etme
        sorumluluğu eserin sahibi olan veya eserin sahibi olduğu iddiasında bulunan
        sanatçı, kişi veya kuruluşa veya koleksiyonere aittir. Müzayedeyi kazanan yani
        artırma üzerine kalan kişi eserin bedelini 7 (yedi) gün içinde tam olarak
        ödemediği takdirde Artiox Teknoloji Yazılım A.Ş. dilerse satış akdini feshe , %20
        cezai şartı ve uğradığı bilcümle zararlarını ticari faiz ile fatura tarihinden itibaren
        işletilecek aylık %15 faiz ile birlikte tahsile yetkilidir. Artiox Teknoloji YazılımA.Ş.’nin sair dava ve talep hakları saklıdır. Fatura bedeli tahsil edilinceye kadar
        geçecek süre içinde eserin muhafazası için Artiox Teknoloji Yazılım A.Ş.’nin
        yapacağı bilcümle masraf alıcı tarafından ödenmedikçe satılan eser alıcıya teslim
        edilmez.
        Açık arttırımı (müzayedeyi) kazanan kişi süresi içinde eserini bedelini ödediği
        halde, eser sahibi olan veya eser sahipliğini elinde bulundurduğu iddiasında olan
        sanatçı, kişi veya kuruluş veya koleksiyonerlerin eserlerini açık arttırımı
        (müzayedeyi) kazanan kişi veya kuruluşa 14 (ondört) işgünü içerisinde tam ve
        eksiksiz teslim etmemesi halinde, bu teslimat süresinin sonundan itibaren Artiox
        açık artırımı kazanan kişi veya kuruluşa teslim edilmeyen eser ile ilgili ödediği
        ilgili bedeli 15 (onbeş) işgünü içerisinde ödemekle yükümlüdür. Artiox’un bu
        ödeme yükümlülüğü dışında bir yükümlülüğü olmayacağını taraflar itirazsız kabul
        ve beyan ederler.</p></li>
    <li><p>Yapılacak müzayede TBK 274 ve davamı maddelerindeki düzenlemeye göre
        gerçekleştiğinden ve Tüketici Kanun'nun 48. maddesinde düzenlenen mesafeli
        satış sözleşmesi olarak kabul edilemeyeceğinden ihaleye katılan kişinin cayma
        hakkı yoktur. Artırma üzerine kalan kişi eserin bedelini 7 gün içinde tam olarak
        ödemediği takdirde Artiox Teknoloji Yazılım A.Ş. dilerse satış akdini feshe, %10
        cezai şartı ve uğradığı bilcümle zararlarını ticari faiz ile fatura tarihinden itibaren
        işletilecek aylık %15 faiz ile birlikte tahsile yetkilidir. Artiox Teknoloji Yazılım
        A.Ş.’nin sair dava ve talep hakları saklıdır. Fatura bedeli tahsil edilinceye kadar
        geçecek süre içinde eserin muhafazası için Artiox Teknoloji Yazılım A.Ş.’nin
        yapacağı bilcümle masraf alıcı tarafından ödenmedikçe satılan eser alıcıya teslim
        edilmez.</p></li>
    <li><p>Müzayede katılımcısının online üyelik formunda belirttiği açık posta adresine
        ve diğer iletişim bilgilerine posta, faks, e-posta veya telefonla yapılan bildirim,
        tebligatların ve ürünün , verilen bilgilerdeki yanlışlık, adres değişikliği, adresine
        geç ulaşma ve P.T.T./kargodaki gecikmeler nedeniyle Müzayede katılımcısına
        geç ulaşmasına Artiox Teknoloji Yazılım A.Ş. sorumlu değildir.</p></li>
    <li><p>Online Müzayede kapsamında satışa sunulan eserlerin beyan edilen vasıfları
        haiz olmaması, orijin itibariyle beyana uygun olmaması veya hak sahibinin rızası
        dışında elden çıkmış olması durumlarında, Artiox Teknoloji Yazılım A.Ş.’nin satıcı
        olmamasından dolayı hukuki sorumluluk, eserin satışa arzını talep eden kişiye
        aittir.</p></li>
    <li><p>Artiox Teknoloji Yazılım A.Ş., müzayedede yer alan herhangi bir eseri,
        müzayededen geri çekmek, başka eserle birlikte satmak, birbirinden ayırmak ve
        satışını reddetmek hakkına sahiptir. Online katalogda yer alan eserler hakkında
        müzayede gününe kadar ortaya çıkacak her türlü itiraz ve bildirim sonucu ilgili
        eseri müzayedeye sunmayıp yasal prosedürü uygulama hakkına sahiptir.</p></li>
    <li><p>Artiox Teknoloji Yazılım A.Ş. şüpheli gördüğü kişileri üye olarak kabul etmeme
        ve herhangi bir mazeret göstermeksizin online müzayedeye kabul etmeme
        yetkisine sahiptir. Ayrıca Artiox Teknoloji Yazılım A.Ş.hakkında, satıcı için aracıkonumunda bulunmasından dolayı BK 214 ve 218. maddelerinde işlenen zapttan
        sorumluluk hükümleri uygulanamaz. 18 yaşından küçükler üye olamaz.</p></li>
    <li><p>Artırmada bulunup online müzayedeye katılmak demek; yukarıda belirtilen
        şartların tümünü ve ayrıca bunlara ilişkin kurumumuza ait diğer hak ve vecibeleri
        peşinen kabullenmiş olmak anlamına gelir (Satış gerçekleştikten sonra herhangi
        bir kusur itirazında bulunulmaz).</p></li>
    <li><p>Online Katalogda yazım hataları olması halinde şirketimizin verdiği
        açıklamalar esas alınır.</p></li>
    <li><p>Online Katalogda yayınlanan her türlü yazı ve fotoğraf Artiox Teknoloji Yazılım
        A.Ş.’nin yazılı izni olmaksızın kısmen veya tümüyle yayınlanamaz.</p></li>
    <li><p>Online Müzayedede satışa sunulan eserlerin katalog değerleri eserlerin final
        değerleri değil müzayede başlangıç fiyatıdır.</p></li>
    <li><p>Online Katalog yayınlandıktan sonra çıkabilecek yeni yasalar, yasa
        değişiklikleri ve vergi oranlarındaki değişiklikler alıcılara yansıtılacak olup, Artiox
        Teknoloji Yazılım A.Ş. sorumlu tutulmayacaktır.</p></li>
    <li><p>Online müzayede eserleri sadece Türkiye adreslerine teslim edilir.</p></li>
    <li><p>5846 sayılı kanunun 45. Maddesi ve Bakanlar Kurulu’nun 27.09.2006 tarihli ve
        2006/10880 sayılı kararı uyarınca, bu kapsamda, belge düzenine uyulması koşulu
        ile sanatçılara veya hak sahiplerine ödenmesi gereken paylar ile ilgili olarak
        müzayedede eserleri satılacak olan eser sahiplerine Artiox Teknoloji Yazılım
        A.Ş.’nin rücu hakkı saklıdır.</p></li>
    <li><p>Üye kaydı yaptırarak üye numarası alan ve online müzayedeye katılan herkes
        yukarıdaki şartları peşinen okumuş ve kabul etmiş sayılır.</p></li>
    <li><p>Müzayede Katılım Şartlarından doğan uyuşmazlıklarda Ankara Mahkemeleri
        ve İcra Daireleri yetkilidir.</p></li>
</ol>