import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserBankAccount} from '../../../../../../services/user.service';
import {ManagerService} from '../../../../../../services/manager.service';
import {HttpClient} from '@angular/common/http';
import {Clipboard} from '@angular/cdk/clipboard';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-deposit-fiat-mobile',
  templateUrl: './deposit-fiat-mobile.component.html',
  styleUrls: ['./deposit-fiat-mobile.component.css']
})
export class DepositFiatMobileComponent implements OnInit, OnDestroy {

  // public subscription_list: Subscription[] = [];

  constructor(public managerService: ManagerService, private httpClient: HttpClient, private clipboard: Clipboard) {
    this.managerService.operationsService.refreshArtioxOfficialBankAccountList();
  }

  ngOnInit(): void {
  }

  copyIBAN(): void {
    this.clipboard.copy(this.managerService.operationsService.selected_artiox_bank_account.iban);
    this.managerService.popSnackBar(true, this.managerService.config.DEPOSIT.COPIED);
  }

  copyBank(): void {
    this.clipboard.copy(this.managerService.operationsService.selected_artiox_bank_account.bank);
    this.managerService.popSnackBar(true, this.managerService.config.DEPOSIT.COPIED);
  }

  copyName(): void {
    this.clipboard.copy(this.managerService.operationsService.selected_artiox_bank_account.name);
    this.managerService.popSnackBar(true, this.managerService.config.DEPOSIT.COPIED);
  }

  copyDepositRefCode(): void {
    if (this.managerService.userService.user !== undefined) {
      this.clipboard.copy(this.managerService.userService.user.deposit_reference_code);
      this.managerService.popSnackBar(true, this.managerService.config.DEPOSIT.COPIED);
    }
  }

  selectBankAccount(bank_account: UserBankAccount): void {
    if (!this.managerService.operationsService.fiat_deposit_rules_accepted_status) {
      this.managerService.openModal('fiat-deposit-rules-modal');
    }
    this.managerService.operationsService.selected_artiox_bank_account = bank_account;
  }

  ngOnDestroy(): void {
    this.managerService.operationsService.fiat_deposit_rules_accepted_status = false;
    // this.subscription_list.forEach((subscription) => {
    //   subscription.unsubscribe();
    // });
  }

}
