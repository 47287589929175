<div [class.invisible]="!managerService.art_product_token_info_display_status | managerPipe"
     [style]="(!managerService.art_product_token_info_display_status | managerPipe) ? {'background-color': 'transparent'} : {'background-color': 'rgba(0,0,0, 0.75)'}"
     class="position-absolute"
     id="art_product_token_info_container"
     style="z-index: 200; transition: 0.3s; right: 0; width: 100%; height: 100%;">
  <div [style]="(!managerService.art_product_token_info_display_status | managerPipe) ? {transform: 'translateX(100%)'} : {transform: 'translateX(0)'}"
       class="d-flex flex-column align-items-start justify-content-start"
       style="position: absolute; height: fit-content; transition: 0.3s; background-color: #1e1f25; margin-top: 120px; width: 320px; right: 0;">
    <div class="w-100 d-flex flex-row align-items-center justify-content-between mt-5" style="margin-top: 40px;">
    <span class="w-100 text-center"
          style="font-family: bebas-neue-regular, sans-serif; font-size: 24px; line-height: 1.2; letter-spacing: 1px; color: #ffffff;">
    {{managerService.config.HOME.SLIDER.TOKENMETRICS.HEADING | managerPipe}}</span>
    </div>
    <div class="w-100 d-flex flex-column align-items-center justify-content-center p-4 mb-4">
      <div class="w-100 d-flex flex-row mb-2"
           style="font-family: sans-serif-demibold-flf, sans-serif; font-size: 14px; line-height: 1.2; color: #ffffff;">
        <div class="w-50 text-right">{{managerService.config.PRE_SALE.TOKENMETRICS.NAME | managerPipe}}:</div>
        <div class="w-50 text-left pl-2"
             style="font-family: Roboto, sans-serif;">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_NAME}}</div>
      </div>
      <div class="w-100 d-flex flex-row mb-2"
           style="font-family: sans-serif-demibold-flf, sans-serif; font-size: 14px; line-height: 1.2; color: #ffffff;">
        <div class="w-50 text-right">{{managerService.config.PRE_SALE.TOKENMETRICS.SYMBOL | managerPipe}}:</div>
        <div class="w-50 text-left pl-2"
             style="font-family: Roboto, sans-serif;">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SYMBOL}}</div>
      </div>
      <div class="w-100 d-flex flex-row mb-2"
           style="font-family: sans-serif-demibold-flf, sans-serif; font-size: 14px; line-height: 1.2; color: #ffffff;">
        <div class="w-50 text-right">{{managerService.config.PRE_SALE.TOKENMETRICS.TOTAL_SUPPLY | managerPipe}}:</div>
        <div class="w-50 text-left pl-2"
             style="font-family: Roboto, sans-serif;">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_TOTAL_SUPPLY}}</div>
      </div>
      <div class="w-100 d-flex flex-row mb-2"
           style="font-family: sans-serif-demibold-flf, sans-serif; font-size: 14px; line-height: 1.2; color: #ffffff;">
        <div class="w-50 text-right">{{managerService.config.PRE_SALE.TOKENMETRICS.CIRCULATING_SUPPLY | managerPipe}}:
        </div>
        <div class="w-50 text-left pl-2"
             style="font-family: Roboto, sans-serif;">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_CIRCULATING_SUPPLY | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</div>
      </div>
      <div class="w-100 d-flex flex-row mb-2"
           style="font-family: sans-serif-demibold-flf, sans-serif; font-size: 14px; line-height: 1.2; color: #ffffff;">
        <div class="w-50 text-right">{{managerService.config.PRE_SALE.TOKENMETRICS.PRE_SALE_PRICE | managerPipe}}:</div>
        <div class="w-50 text-left pl-2"
             style="font-family: Roboto, sans-serif;">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_PRICE}}</div>
      </div>
      <div class="w-100 d-flex flex-row mb-2"
           style="font-family: sans-serif-demibold-flf, sans-serif; font-size: 14px; line-height: 1.2; color: #ffffff;">
        <div
          class="w-50 text-right">{{managerService.config.PRE_SALE.TOKENMETRICS.PRE_SALE_CIRCULATING_SUPPLY | managerPipe}}
          :
        </div>
        <div class="w-50 text-left pl-2"
             style="font-family: Roboto, sans-serif;">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_CIRCULATING_SUPPLY | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</div>
      </div>
      <div class="w-100 d-flex flex-row mb-2"
           style="font-family: sans-serif-demibold-flf, sans-serif; font-size: 14px; line-height: 1.2; color: #ffffff;">
        <div class="w-50 text-right">{{managerService.config.PRE_SALE.TOKENMETRICS.PUBLIC_SALE_PRICE | managerPipe}}:
        </div>
        <div class="w-50 text-left pl-2"
             style="font-family: Roboto, sans-serif;">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_PRICE}}
          ₺
        </div>
      </div>
      <div class="w-100 d-flex flex-row mb-2"
           style="font-family: sans-serif-demibold-flf, sans-serif; font-size: 14px; line-height: 1.2; color: #ffffff;">
        <div
          class="w-50 text-right">{{managerService.config.PRE_SALE.TOKENMETRICS.PUBLIC_SALE_CIRCULATING_SUPPLY | managerPipe}}
          :
        </div>
        <div class="w-50 text-left pl-2"
             style="font-family: Roboto, sans-serif;">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_CIRCULATING_SUPPLY | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</div>
      </div>
      <div class="w-100 d-flex flex-row mb-2"
           style="font-family: sans-serif-demibold-flf, sans-serif; font-size: 14px; line-height: 1.2; color: #ffffff;">
        <div class="w-50 text-right">{{managerService.config.PRE_SALE.TOKENMETRICS.LISTING_DATE | managerPipe}}:</div>
        <div class="w-50 text-left pl-2"
             style="font-family: Roboto, sans-serif;">{{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_LISTING_DATE: managerService.language_pipe}}</div>
      </div>
      <div class="w-100 d-flex flex-row mb-2"
           style="font-family: sans-serif-demibold-flf, sans-serif; font-size: 14px; line-height: 1.2; color: #ffffff;">
        <div class="w-50 text-right">{{managerService.config.PRE_SALE.TOKENMETRICS.CONTRACT_ADDRESS | managerPipe}}:</div>
        <div class="w-50 text-left pl-2" style="font-family: Roboto, sans-serif;">
          <a [href]="managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_CONTRACT_ADDRESS_LINK" class="text-info"
             target="_blank" rel="noreferrer"
             type="button">
            {{managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_CONTRACT_ADDRESS}}
          </a>
        </div>
      </div>
    </div>

  </div>
</div>
