import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';
import {ArtProduct} from '../../../../../services/pair.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-delisting-desktop',
  templateUrl: './delisting-desktop.component.html',
  styleUrl: './delisting-desktop.component.css',
  encapsulation: ViewEncapsulation.None
})
export class DelistingDesktopComponent implements OnInit, OnDestroy {
  @Input() modal_id: string = '';

  protected active_art_product_symbol: string = "BOD";

  protected subscription_list: Subscription[] = [];

  constructor(protected managerService: ManagerService) {
  }

  ngOnInit(): void {
    if (this.managerService.pairService.active_pair && this.managerService.config.DELISTING.ART_PRODUCTS.includes(this.managerService.pairService.active_pair.symbol)) {
      this.active_art_product_symbol = this.managerService.pairService.getArtProductById(this.managerService.pairService.active_pair.id[0])!.symbol;
    }
    this.subscription_list.push(this.managerService.pairService.active_pair_changed.subscribe(() => {
      if (this.managerService.pairService.active_pair && this.managerService.config.DELISTING.ART_PRODUCTS.includes(this.managerService.pairService.active_pair.symbol)) {
        this.active_art_product_symbol = this.managerService.pairService.getArtProductById(this.managerService.pairService.active_pair.id[0])!.symbol;
      }
    }));
  }

  closeModal(): void {
    this.managerService.closeModal(this.modal_id);
  }

  ngOnDestroy(): void {
    this.subscription_list.forEach((subs) => {
      subs.unsubscribe();
    });
  }
}

