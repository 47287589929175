import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../services/manager.service';
import {ServicePageTemplate} from '../../../services/navigation.service';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})
export class ServiceComponent implements OnInit{
  public service_page_template = ServicePageTemplate;

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }
}
