import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';
import {Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AuctionProduct} from "../../../../../services/auction.service";

@Component({
    selector: 'app-auction-bid-report-desktop',
    templateUrl: './auction-bid-report-desktop.component.html',
    styleUrls: ['./auction-bid-report-desktop.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AuctionBidReportDesktopComponent implements OnInit, OnDestroy {
    @Input() modal_id: string = '';

    public subscription_list: Subscription[] = [];
    public is_max_bidder: boolean = false;
    public approved: boolean = false;

    constructor(public managerService: ManagerService, private httpClient: HttpClient) {
    }

    ngOnInit(): void {
        this.subscription_list.push(this.managerService.report_bid_modal.subscribe(() => {
                this.is_max_bidder = this.checkMaxBidder();
                this.approved = false;
            }
        ));
    }

    checkMaxBidder(): boolean {
        const auction_product: any = this.managerService.auctionService.active_auction_product;
        const bids = this.managerService.userService.user_bids;
        const bid = bids.find(x => x.auction_product_id === auction_product.id);
        if (auction_product.auction_state === 'live' && bid?.price === auction_product.current_value) {
            return true;
        }
        return false;
    }

    approve(): void {
        this.approved = true;
    }

    bid(): void {
        if (!this.managerService.userService.user_ready) {
            // @ts-ignore
            const content = this.managerService.config.RESPONSE_TABLE['2001'];
            this.managerService.popSnackBar(content.is_okay, content.message);
            return;
        }

        const auction_product: any = this.managerService.auctionService.active_auction_product;
        const price: number = this.managerService.auctionService.getActiveAuctionProductBidPrice();

        const data = {
            auction_product_id: auction_product.id,
            bid_type: 'buy',
            price,
            anonymous: true,
        };

        if (!this.managerService.utilityService.requestCriticServicePermission('bid_buy_service', data)){
            this.managerService.popResponseSnackbar({code: 2019, is_okay: false, message: 'Service not allowed in dev mode.'});
            return;
        }

        this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'bid_buy_service', data).subscribe(
            response => {
                if (response.is_okay === undefined) {
                    return;
                }

                this.managerService.closeModal(this.modal_id);

                // @ts-ignore
                const content = this.managerService.config.RESPONSE_TABLE[response.code];
                if (response.is_okay) {
                    this.managerService.openModal('auction-bid-approved-modal');
                    this.logBidApproved();
                } else {
                    this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                    return;
                }
            }));
    }

    logBidApproved(): void {
        const auction_product: AuctionProduct = this.managerService.auctionService.active_auction_product;
        const value: number = this.managerService.auctionService.getActiveAuctionProductBidPrice();

        let view: string = 'unknown';
        if (this.managerService.navigationService.current_routing.url.includes('auction/auction_product')) {
            view = 'classic';
        } else if (this.managerService.navigationService.current_routing.url.includes('auction/catalog')) {
            view = 'quick';
        }

        this.managerService.marketingService.logEvent('bid_approved', {auction_product_id: auction_product.id, catalog: auction_product.catalog, value, view});
    }

    ngOnDestroy(): void {
        this.subscription_list.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    protected readonly ManagerService = ManagerService;
}
