<div style="max-width: 300px">
  <div class=" w-100 p-2 d-flex flex-flex justify-content-end" style="font-size: 12px;font-weight: 300;color: #9da5b7;">
    <div (click)="managerService.closeModal(modal_id)" style="cursor: pointer;">
      X
    </div>
  </div>
  <div class="mx-3 mt-2 mb-3" style="font-size: 12px">
    <div *ngFor="let item of managerService.config.ONE_PAGER.DESCRIPTION | managerPipe" class="mb-3" [style.font-weight]="item.WEIGHT | managerPipe">
      {{item.TEXT | managerPipe}}
    </div>
    <div class="w-100 d-flex justify-content-center mt-3">
      <a href="{{managerService.config.ONE_PAGER.BUTTON_LINK.url | managerPipe}}" class="btn-native sign-up-login-button mx-auto py-2"
         style="font-family: Roboto, sans-serif; font-size: 12px; color: #ffffff; border-radius: 6px; font-weight: 500; letter-spacing: 1.1px; text-decoration: none; padding: 30px"
         type="button">{{managerService.config.ONE_PAGER.BUTTON_TEXT | managerPipe}}</a>
    </div>
  </div>
</div>
