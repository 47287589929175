import {Component, OnDestroy, OnInit} from '@angular/core';
import {ManagerService} from '../../../services/manager.service';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.css']
})
export class AnnouncementComponent implements OnInit, OnDestroy {

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

}
