<div style="max-width: 100vw; background: #FFFFFF;height: 100vh">

    <div class="d-flex w-100 flex-column">

        <div class="d-flex w-100 justify-content-start">
            <div class="d-flex flex-column" style="gap: 16px;padding: 64px 0 32px 48px">
                <span class="header5 exo bold"
                      style="color: #8266F0">{{managerService.config.AUCTION.AGREEMENT.TITLE | managerPipe: managerService.mpa_template.IDENTITY: managerService.language}}</span>
                <!--                <span class="body2 barlow regular"-->
                <!--                      style="color: #6F6F6F">{{active_aggreement_config.SUBTITLE | managerPipe}}</span>-->
            </div>
        </div>

        <div class="d-flex  flex-column barlow body1 regular text-decoration-underline"
             style="color: #8266F0; padding: 32px 0 32px 48px; gap: 8px">
            <a href="assets/pdfs/Müzayede Kullanıcı Sözleşmesi.pdf"
               target="_blank">1-{{managerService.config.FOOTER.AUCTION.AUCTION_USER_AGREEMENT}}</a>
            <a href="assets/pdfs/Mesafeli Satış Sözleşmesi.pdf"
               target="_blank">2-{{managerService.config.FOOTER.AUCTION.DISTANCE_SELLING}}</a>
            <a href="assets/pdfs/Müzayede Kuralları.pdf"
               target="_blank">3-{{managerService.config.FOOTER.AUCTION.AUCTION_RULES}}</a>
        </div>


        <div class="d-flex w-100 justify-content-start flex-row"
             style="padding-bottom: 48px; padding-top: 32px; padding-left: 48px">
            <div (click)="checkBoxToggle()" [class.login-checkbox-selected]="checked"
                 class="d-flex login-checkbox align-items-center">
                <svg *ngIf="checked" style="height: 16px; width: 16px;">
                    <use [attr.xlink:href]='"./assets/images/checkbox.svg#checkbox"' fill="#8266F0"/>
                </svg>
            </div>
            <span class="d-flex body2 overline regular"
                  style="color: var(--text-color-artiox-light-black); padding-left: 8px">{{managerService.config.AUCTION.AGREEMENT.CHECKBOX| managerPipe}}</span>
        </div>

        <div class="d-flex w-100 flex-row justify-content-center pb-5">
            <button (click)="rejectAuctionAgreement()"
                    style="cursor: pointer; display: flex;flex-direction: row;justify-content: center;align-items: center;margin-right: 16px;gap: 8px;width: 140px;height: 44px;border: none; background: #FDEBEC;border-radius: 6px;color: #EA3943">
                {{managerService.config.AUCTION.AGREEMENT.CANCEL | managerPipe}}
            </button>
            <button #agreement_button (click)="acceptAuctionAgreement()" class="agreement-button" disabled style="display: flex;flex-direction: row;justify-content: center;align-items: center;margin-left: 16px;gap: 8px;width: 140px;height: 44px;border: none; border-radius: 6px; color: #FFFFFF"
                    type="button">
                {{managerService.config.AUCTION.AGREEMENT.ACCEPT | managerPipe}}
            </button>
        </div>
    </div>

    <div class="d-flex w-100 justify-content-center align-items-end" style="position:absolute; bottom: 32px">
        <svg style=" height: 32px; width: 138px; margin-top: 32px;">
            <use [attr.xlink:href]='"./assets/images/artiox_logo.svg#artiox_logo"' fill="#000"/>
        </svg>
    </div>
</div>
