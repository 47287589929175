import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TooltipDirectionTemplate, TooltipService} from '../tooltip.service';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.css']
})
export class TooltipComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() id: string = '';
  @Input() direction: TooltipDirectionTemplate = TooltipDirectionTemplate.BOTTOM;
  @Input() max_width: string = '';
  public element: any = undefined;
  @ViewChild('tooltip_container') tooltip_container: any = undefined;


  constructor(public tooltipService: TooltipService, public el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.tooltipService.add(this);
    if (this.max_width !== '') {
      this.tooltip_container.nativeElement.style.maxWidth = this.max_width;
    }
  }

  ngOnDestroy(): void {
    this.tooltipService.remove(this.id);
  }

  positionCalculator(): any {
    if (this.direction === TooltipDirectionTemplate.TOP || this.direction === TooltipDirectionTemplate.BOTTOM) {
      this.tooltip_container.nativeElement.style.left = ((this.tooltip_container.nativeElement.offsetWidth * -1) / 2).toString() + 'px';
    } else if (this.direction === TooltipDirectionTemplate.LEFT || this.direction === TooltipDirectionTemplate.RIGHT) {
      this.tooltip_container.nativeElement.style.top = ((this.tooltip_container.nativeElement.offsetHeight * -1) / 2).toString() + 'px';
    }
  }

  toggle(): void {
    this.element.classList.toggle('d-none');
    if (this.direction === TooltipDirectionTemplate.TOP) {
      this.tooltip_container.nativeElement.classList.toggle('tooltip-native-top');
    } else if (this.direction === TooltipDirectionTemplate.BOTTOM) {
      this.tooltip_container.nativeElement.classList.toggle('tooltip-native-bottom');
    } else if (this.direction === TooltipDirectionTemplate.LEFT) {
      this.tooltip_container.nativeElement.classList.toggle('tooltip-native-left');
    } else if (this.direction === TooltipDirectionTemplate.RIGHT) {
      this.tooltip_container.nativeElement.classList.toggle('tooltip-native-right');
    }
    this.positionCalculator();
  }

  open(): void {
    this.element.classList.remove('d-none');
    if (this.direction === TooltipDirectionTemplate.TOP) {
      this.tooltip_container.nativeElement.classList.add('tooltip-native-top');
    } else if (this.direction === TooltipDirectionTemplate.BOTTOM) {
      this.tooltip_container.nativeElement.classList.add('tooltip-native-bottom');
    } else if (this.direction === TooltipDirectionTemplate.LEFT) {
      this.tooltip_container.nativeElement.classList.add('tooltip-native-left');
    } else if (this.direction === TooltipDirectionTemplate.RIGHT) {
      this.tooltip_container.nativeElement.classList.add('tooltip-native-right');
    }
    this.positionCalculator();
  }

  close(): void {
    this.element.classList.add('d-none');
    if (this.direction === TooltipDirectionTemplate.TOP) {
      this.tooltip_container.nativeElement.classList.remove('tooltip-native-top');
    } else if (this.direction === TooltipDirectionTemplate.BOTTOM) {
      this.tooltip_container.nativeElement.classList.remove('tooltip-native-bottom');
    } else if (this.direction === TooltipDirectionTemplate.LEFT) {
      this.tooltip_container.nativeElement.classList.remove('tooltip-native-left');
    } else if (this.direction === TooltipDirectionTemplate.RIGHT) {
      this.tooltip_container.nativeElement.classList.remove('tooltip-native-right');
    }
  }

}
