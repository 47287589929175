<div *ngIf="((!managerService.logged_in) || (0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO)) | managerPipe"
     class="p-3">
  <div class="my-2" style="font-family: Roboto, sans-serif; font-size: 16px; font-weight: bold; line-height: 1.2; letter-spacing: 1px; color: #4a4e70;">
    {{managerService.config.DEPOSIT.NO_KYC_HEADING | managerPipe}}
  </div>
  <div class="my-2" style="font-family: Roboto, sans-serif; font-size: 12px; color: #4a4e70;">
    {{managerService.config.DEPOSIT.NO_KYC_DESCRIPTION | managerPipe}}
  </div>
  <div class="d-flex flex-row justify-content-center">
    <button *ngIf="!this.managerService.environment.app"
            (click)="managerService.navigationManager({url: '/account/security', active_security_panel: managerService.active_security_panel_template.IDENTITY_VERIFICATION, current_component_index: 0});"
            class="btn-native sign-up-login-button my-3"
            style="height: 30px; width:120px; border-radius: 16px;
              font-family: Roboto, sans-serif; font-size: 12px; line-height: 1.3; letter-spacing: 0.3px; color: #ffffff;">
      {{managerService.config.DASHBOARD.LEVEL_UP | managerPipe}}
    </button>
<!--    <button *ngIf="this.managerService.environment.app"-->
<!--            (click)="managerService.navigationManager({url: '/account-level', current_component_index: 0});"-->
<!--            class="btn-native sign-up-login-button my-3"-->
<!--            style="height: 30px; width:120px; border-radius: 16px;-->
<!--              font-family: Roboto, sans-serif; font-size: 12px; line-height: 1.3; letter-spacing: 0.3px; color: #ffffff;">-->
<!--      {{managerService.config.DASHBOARD.LEVEL_UP | managerPipe}}-->
<!--    </button>-->
  </div>

</div>

<div *ngIf="(managerService.logged_in && !(0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO)) | managerPipe"
     class="w-100 d-flex flex-column align-items-start justify-content-start" style="background-color: #fcfdff; ">
  <div class="w-100" *ngIf="!managerService.config.DEPOSIT.TL_SUSPENSION.SUSPENDED | managerPipe">
    <div class="w-100 d-flex flex-row align-items-center justify-content-around mt-1"
         style=" background-color: #272a3b; margin-bottom: 10px; font-family: Roboto,sans-serif; font-size: 10px; line-height: 0.9; letter-spacing: 0.3px; color: #f4f5f9;">
      <div class="d-flex flex-column m-3">
        <span [innerHTML]="managerService.config.DEPOSIT.DAILY_DEPOSIT_LIMIT_MOBILE | managerPipe" class="m-1 text-center" style="line-height: 1.2;"></span>
        <span class="m-1 text-center"
              style="font-family: 'Roboto Condensed', sans-serif;font-size: 15px;">{{managerService.userService.user?.daily_fiat_deposit_limit | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
          ₺</span>
      </div>
      <span
          style="height: 40px; width: 1px; margin: auto 0; border-radius:1px; background-color: #dddddd;"></span>
      <div class="h-100 d-flex flex-column m-3">
        <span [innerHTML]="managerService.config.DEPOSIT.MONTHLY_DEPOSIT_LIMIT_MOBILE | managerPipe" class="m-1 text-center" style="line-height: 1.2;"></span>
        <span class="m-1 text-center"
              style=" font-family: 'Roboto Condensed', sans-serif;font-size: 15px;">{{managerService.userService.user?.monthly_fiat_deposit_limit | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
          ₺</span>
      </div>
    </div>

    <div class="d-flex flex-row align-items-start justify-content-start mt-3 mb-2 mx-3">
      <div (click)="selectBankAccount(bank_account)"
           *ngFor="let bank_account of managerService.operationsService.artiox_bank_account_list | managerPipe;"
           [class.inactive-bank-account]="(!(managerService.operationsService.selected_artiox_bank_account.label === bank_account.label)) && (managerService.operationsService.selected_artiox_bank_account.iban !== '') | managerPipe"
           class="p-2"
           style="flex-basis: 50%; border-radius: 4px; box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22);">
        <img [src]="bank_account.bank | managerPipe: managerService.mpa_template.GET_BANK_ACCOUNT_IMG_SRC" alt="bank" width="100%" #bank
             (error)="bank.src = bank.src.replace('.webp', '.jpg')">
      </div>
    </div>

    <div [class]="managerService.operationsService.fiat_deposit_rules_accepted_status ? 'd-flex':'d-none'" class="w-100 flex-column"
         style="font-family: Roboto, sans-serif; font-size: 14px; line-height: 1.6; letter-spacing: 0.4px; color: #404352;">
      <div class="mx-2 my-1">
        <span class="font-weight-bold">{{managerService.config.DEPOSIT.BANK_NAME}}:</span><span class="mx-1">{{managerService.operationsService.selected_artiox_bank_account.bank}}</span>
        <button (click)="copyBank()" class="mx-1 btn-native">
          <svg style="height: 16px; width: 16px;">
            <use [attr.xlink:href]='"./assets/images/copy.svg#copy"' fill="#595e7e"/>
          </svg>
        </button>
      </div>
      <div class="mx-2 my-1">
        <span class="font-weight-bold">{{managerService.config.DEPOSIT.ACCOUNT_NAME}}:</span><span class="mx-1">{{managerService.operationsService.selected_artiox_bank_account.name}}</span>
        <button (click)="copyName()" class="mx-1 btn-native">
          <svg style="height: 16px; width: 16px;">
            <use [attr.xlink:href]='"./assets/images/copy.svg#copy"' fill="#595e7e"/>
          </svg>
        </button>
      </div>
      <div class="mx-2 my-1">
        <span class="font-weight-bold">{{managerService.config.DEPOSIT.IBAN}}:</span><span class="mx-1">{{managerService.operationsService.selected_artiox_bank_account.iban}}</span>
        <button (click)="copyIBAN()" class="mx-1 btn-native">
          <svg style="height: 16px; width: 16px;">
            <use [attr.xlink:href]='"./assets/images/copy.svg#copy"' fill="#595e7e"/>
          </svg>
        </button>
      </div>
      <div class="mx-2 my-1">
        <span class="font-weight-bold">{{managerService.config.DEPOSIT.DEPOSIT_REF_CODE}}:</span>
        <span class="mx-1 font-weight-bold" style="font-size: 16px; letter-spacing: 0.5px;">{{managerService.userService.user?.deposit_reference_code}}</span>
        <button (click)="copyDepositRefCode()" class="mx-1 btn-native">
          <svg style="height: 16px; width: 16px; margin-top: -2px;">
            <use [attr.xlink:href]='"./assets/images/copy.svg#copy"' fill="#595e7e"/>
          </svg>
        </button>
      </div>
      <div class="mx-2 my-1 text-info" style="font-size: 12px; ">
        <svg style="height: 14px; width: 14px; line-height: 0.75; margin-top: -2px;">
          <use [attr.xlink:href]='"./assets/images/info.svg#info"' fill="#17a2b8"/>
        </svg>
        {{managerService.config.DEPOSIT.DEPOSIT_WARNING}}
      </div>

      <div class="mx-2 my-2 d-flex flex-row" style="font-family: Roboto, sans-serif; font-size: 12px; color: #272a3b;">
        <div class="d-flex flex-column">
          <div [innerHTML]="managerService.config.DEPOSIT.DAILY_DEPOSIT_LIMIT | managerPipe" class="my-2 mr-2"
               style="">
          </div>
          <div [innerHTML]="managerService.config.DEPOSIT.MONTHLY_DEPOSIT_LIMIT | managerPipe" class="mr-2"
               style="">
          </div>
        </div>
        <div class="d-flex flex-column" style="flex-grow: 1; font-weight: normal;">
          <div class="mx-2 my-2 text-center" style="border-radius: 2px; background-color: #f7f7ff; box-shadow: 2px 2px 2px 0 rgba(230, 232, 248, 0.33);">
            {{managerService.userService.user?.daily_fiat_deposit_limit | managerPipe: managerService.mpa_template.FANCY_NUMBER}}₺
          </div>
          <div class="mx-2 text-center" style="border-radius: 2px; background-color: #f7f7ff; box-shadow: 2px 2px 2px 0 rgba(230, 232, 248, 0.33);">
            {{managerService.userService.user?.monthly_fiat_deposit_limit | managerPipe: managerService.mpa_template.FANCY_NUMBER}}₺
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="managerService.config.DEPOSIT.TL_SUSPENSION.SUSPENDED | managerPipe"
       class="pt-1 pb-3"
       style="min-height: 260px; box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff; max-height: 1160px; border-radius: 4px;">
    <div class="mt-2 ms-3"
         style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: bold; line-height: 2; letter-spacing: 1px; color: #4a4e70;"
         [innerHTML]="managerService.config.DEPOSIT.TL_SUSPENSION.TITLE | managerPipe">
    </div>

    <div class="mt-2 ms-3"
         style="font-family: Roboto, sans-serif; font-size: 11px; line-height: 1.7; color: #272a3b;"
         [innerHTML]="managerService.config.DEPOSIT.TL_SUSPENSION.DESCRIPTION | managerPipe">
    </div>
  </div>
</div>

<app-deposit-history-mobile [currency_type]="'fiat'" [currency_id]="0"></app-deposit-history-mobile>
