<div>
    <div class="d-flex mt-3 w-100 justify-content-between flex-row" style="padding: 0 32px">
        <div class="d-flex  dropdown-native-button"
             style="width: auto; border-radius: 4px; background: var(--secondary-colors-artiox-gray-300, #F6F6F6); height: 32px;">
            <div class="d-flex flex-row align-items-center justify-content-between w-100" style="">
                <div class="d-flex ml-2 barlow"
                     style="color: var(--text-color-artiox-grey, #6F6F6F);font-size: 14px;font-style: normal;font-weight: 600;line-height: normal;  white-space: nowrap">
                    LOT {{managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_LOT}}
                </div>
                <div class="d-flex mx-2">
                    <svg style=" height: 16px; width: 16px;">
                        <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#404352"/>
                    </svg>
                </div>
            </div>
            <div class="dropdown-native-menu  "
                 style="height: 120px; overflow: auto; width: 77px; border-radius: 4px; margin-top: 32px; background: var(--secondary-colors-artiox-gray-300, #F6F6F6);  box-shadow: 0px 8px 24px 0px #00000026; ">
                <div (click)="managerService.navigateIfLoggedIn({url: '/auction/auction_product', active_auction_product_hash: auction_product.hash, current_component_index: 3});"
                     *ngFor="let auction_product of (managerService.auctionService.auction_product_list_pipe| managerPipe: managerService.mpa_template.ACTIVE_CATALOG_STARTED_AUCTION_PRODUCT_ARRAY)"
                     class="dropdown-native-item-white  btn-native justify-content-center "
                     style=" height: 40px;" type="button">
                    <div style="color: var(--text-color-artiox-grey, #6F6F6F);font-size: 14px;font-style: normal;font-weight: 600;line-height: normal ">
                        LOT {{auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_LOT}}
                    </div>
                </div>
            </div>
        </div>
        <div (click)="managerService.setUserFavoriteAuctionProduct(managerService.auctionService.active_auction_product)" class="me-4" style="cursor: pointer">
            <svg [style.fill]="managerService.userService.user?.isAuctionProductFavorite(managerService.auctionService.active_auction_product) ? '#FFC300' : '#FFF'" style="width: 24px; height: 24px">
                <use [attr.xlink:href]='"./assets/images/fav_star.svg#fav_star"'/>
            </svg>
            {{ managerService.config.AUCTION.FOLLOW | managerPipe }}
        </div>

    </div>


    <div class="d-flex justify-content-center mt-2 mb-2">
        <div class="d-flex flex-column align-items-center justify-content-start">
            <div (dblclick)="managerService.photoSwipeOpen(managerService.auctionService.active_auction_product, current_slider_index)"
                 (swipeleft)="swipeLeft()" (swiperight)="swipeRight()" class="d-flex flex-row overflow-hidden" style="width: 90vw;">
                <div
                    *ngFor="let src of (managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_IMAGES)"
                    [style]="current_slider_index | managerPipe : managerService.mpa_template.SLIDER_BY_VW: 90"
                    class="d-flex flex-column align-items-center justify-content-center"
                    style="transition: 0.4s ease-in-out; width: 90vw; height: 90vw">
                    <img #auction_product
                         (error)="auction_product.src = auction_product.src.replace('.webp', '.jpg');"
                         alt="auction_product"
                         src="{{src}}"
                         style="width: 90vw; height: 90vw; pointer-events: none">
                </div>
            </div>

            <div *ngIf="slider_window > 1" class="d-flex flex-row align-items-center justify-content-center mb-4">
                <button (click)="current_slider_index = i"
                        *ngFor="let i = index; let pair of (managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_IMAGES)"
                        [style]="current_slider_index | managerPipe: managerService.mpa_template.SLIDER_BUTTON: i"
                        aria-label="Slider" class="btn-native"
                        style="width: 15px; height: 15px; margin: 7px; border-radius: 1000px; background-color: #E2E2E2; transition: 0.3s ease-in-out;"></button>
            </div>
        </div>
    </div>
</div>
