<div *ngIf="!isRequestSent" class="d-flex flex-column justify-content-center h-100 w-100 px-4">
    <div class="d-flex justify-content-start mt-3"  >
        <svg (click)="this.managerService.previousPageNavigation()" style=" height: 24px; width: 24px;">
            <use [attr.xlink:href]='"./assets/images/v2/ic_left.svg#ic_left"' stroke="#131515"/>
        </svg>
    </div>
    <div class="d-flex justify-content-center h-100  w-100">
        <div class="d-flex flex-column justify-content-center  ">
            <span class="mb-2 header3 medium exo" style="color: var(--text-color-artiox-black) ">{{managerService.config.FORGOT_PASSWORD.HEADING}}</span>
            <span class="caption regular barlow" style="color: var(--text-color-artiox-grey); margin-bottom: 32px; max-width: 320px">{{managerService.config.FORGOT_PASSWORD.INFO}}</span>

            <div class="d-flex form-group focused" style="margin-bottom: 32px" [formGroup]="forgotPasswordForm" >
                <label class=" d-flex form-label caption barlow medium" for="email">
                    {{managerService.config.FORGOT_PASSWORD.E_MAIL | managerPipe}}</label>
                <input #email_input class="w-100 form-input body2 barlow regular" id="email" formControlName="email" type="text" placeholder="{{managerService.config.FORGOT_PASSWORD.E_MAIL_PLACEHOLDER}}">
                <div  #email_error  class="d-flex d-none flex-row " style="gap: 8px; margin-top: 12px"  >
                    <svg style=" height: 16px; width: 16px; ">
                        <use [attr.xlink:href]='"./assets/images/error_icon.svg#error_icon"' fill="#EA3943"/>
                    </svg>
                    <div  class="caption barlow regular" style="color: var(--secondary-colors-artiox-red-base)" ></div>
                </div>
            </div>

            <div (click)="validateEmail()" class="w-100  d-flex justify-content-center py-2 login-button" style="max-width: 320px">
                <span  class="body2 exo " style="color:#FFFFFF; font-weight: 600">{{managerService.config.FORGOT_PASSWORD.SEND}}</span>
            </div>

        </div>
    </div>
</div>
<div *ngIf="isRequestSent" class="d-flex flex-column justify-content-center h-100 w-100 px-4">
    <div class="d-flex justify-content-start mt-3"  >
        <svg style=" height: 24px; width: 24px;">
            <use [attr.xlink:href]='"./assets/images/v2/ic_left.svg#ic_left"' stroke="#131515"/>
        </svg>
    </div>
    <div class="d-flex flex-column justify-content-center h-100" style="max-width: 364px">
        <span class="mb-2 header3 medium exo" style="color: var(--text-color-artiox-black) ">{{managerService.config.FORGOT_PASSWORD.HEADING}}</span>
        <span class="body2 regular barlow" style="color: var(--text-color-artiox-grey); max-width: 320px; margin-bottom: 32px"><span style="color: var(--main-color-artiox-purple-base); ">{{forgotPasswordForm.get('email')?.value}}</span> adresine mail başarılı bir şekilde gönderilmiştir</span>

        <div class="d-flex w-100 justify-content-center">
                <span (click)="resendEmail()" class="body2 regular barlow clickable_text" style="text-decoration: underline"
                      [ngStyle]="{color: isTextClickable ? 'var(--main-color-artiox-purple-base' : 'var(--secondary-colors-artiox-gray-500)', cursor: isTextClickable? 'pointer': 'default'}">
                    Iletiyi yeniden gonder <span *ngIf="!isTextClickable">({{countdown}}sn)</span>
                </span>
        </div>

    </div>
</div>