<div class="p-3">
    <div class="exo header6 medium"
         style="color: #8266F0; padding: 0 0 48px 0">{{managerService.config.AUCTION.LIVE_CATALOG | managerPipe}}
    </div>
    <div
        *ngIf="(managerService.auctionService.auction_product_list_pipe| managerPipe: managerService.mpa_template.AUCTION_CATALOG_ARRAY_LIVE).length === 0"
        class="d-flex m-5 p-5">
        {{managerService.config.AUCTION.NO_LIVE_CATALOG | managerPipe}}
    </div>
    <div (click)="managerService.navigateIfLoggedIn({url: '/auction/catalog', active_auction_catalog_hash: auction_catalog.hash, current_component_index: 2});"
         *ngFor="let auction_catalog of (managerService.auctionService.auction_product_list_pipe| managerPipe: managerService.mpa_template.AUCTION_CATALOG_ARRAY_LIVE)"
         class="w-100 d-flex flex-column  mb-4 "
         style="border-radius: 6px; box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15); cursor: pointer; position: relative">

        <div class="d-flex p-4 pb-3 justify-content-center align-items-center">
            <div class="d-flex justify-content-center align-items-center" style="max-width:270px;">
                <img #catalog_img (error)="catalog_img.src = catalog_img.src.replace('.webp', '.jpg');" alt="catalog_img"
                     height="140px"
                     src="{{auction_catalog | managerPipe: managerService.mpa_template.AUCTION_CATALOG_THUMBNAIL | managerPipe: managerService.mpa_template.LOCAL_IMAGE_MANAGER_TO_BE}}"
                     width="100%">
            </div>
        </div>
        <div class="d-flex mx-4 flex-column align-items-center">
            <div class="mb-2 exo subtitle1 bold"
                 style="color: #8266F0 ">{{auction_catalog | managerPipe: managerService.mpa_template.AUCTION_CATALOG_NAME}}</div>
            <div class="mb-2 barlow caption bold"
                 style=";color: #6F6F6F;">{{auction_catalog | managerPipe: managerService.mpa_template.AUCTION_CATALOG_TITLE}}</div>
            <div class="mb-3 barlow overline bold"
                 style="color: #6F6F6F;">{{auction_catalog | managerPipe: managerService.mpa_template.AUCTION_CATALOG_TEXT}}</div>
            <div class="d-flex flex-row" style="font-family: Roboto, sans-serif; text-align: center">
                <div class="d-flex flex-column">
                    <div class="mb-2 barlow caption medium"
                         style="color: #66BB6A;">
                        {{auction_catalog | managerPipe: managerService.mpa_template.AUCTION_CATALOG_START_DATE | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</div>
                    <div class="barlow caption regular" style="color: #B5B5B5;">
                        {{managerService.config.AUCTION.AUCTION_START | managerPipe}}
                    </div>
                </div>
                <div class="mx-2" style="width: 1px; height: 47px; background-color: #E5E6E7;"></div>
                <div class="d-flex flex-column">
                    <div class="mb-2 barlow caption medium"
                         style=";color: #EA3943;">
                        {{auction_catalog | managerPipe: managerService.mpa_template.AUCTION_CATALOG_END_DATE | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</div>
                    <div class="barlow caption regular" style="color: #B5B5B5;">
                        {{managerService.config.AUCTION.AUCTION_END | managerPipe}}
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex align-items-end justify-content-end ">
            <div class="d-flex justify-content-center align-items-center m-4" style="width: 107px; height: 40px; border-radius: 4px; background: #8266F0;text-align: center;
                    font-weight: 500; font-size: 14px; color: #FFFFFF;">
                {{managerService.config.AUCTION.ARTWORKS | managerPipe}}
                <svg style="width:25px; height: 30px; rotate: 270deg; ">
                    <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#FFFFFF"/>
                </svg>
            </div>
        </div>
    </div>

</div>

<div class="p-3">
    <div class="exo header6 medium "
         style="color: #EA3943; padding: 0 0 48px 0">{{managerService.config.AUCTION.PAST_CATALOG | managerPipe}}
    </div>
    <div
        *ngIf="(managerService.auctionService.auction_product_list_pipe| managerPipe: managerService.mpa_template.AUCTION_CATALOG_ARRAY_PAST).length === 0"
        class="d-flex m-5 p-5">
        {{managerService.config.AUCTION.NO_PAST_CATALOG | managerPipe}}
    </div>
    <div (click)="managerService.navigateIfLoggedIn({url: '/auction/catalog', active_auction_catalog_hash: auction_catalog.hash, current_component_index: 2});"
         *ngFor="let auction_catalog of (managerService.auctionService.auction_product_list_pipe| managerPipe: managerService.mpa_template.AUCTION_CATALOG_ARRAY_PAST)"
         class="w-100 d-flex flex-column  mb-4 "
         style="border-radius: 6px; box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15); cursor: pointer; position: relative">

        <div class="d-flex p-4 pb-3 justify-content-center align-items-center">
            <div class="d-flex justify-content-center align-items-center" style="max-width:270px;">
                <img #catalog_img (error)="catalog_img.src = catalog_img.src.replace('.webp', '.jpg');" alt="catalog_img"
                     height="140px"
                     src="{{auction_catalog | managerPipe: managerService.mpa_template.AUCTION_CATALOG_THUMBNAIL | managerPipe: managerService.mpa_template.LOCAL_IMAGE_MANAGER_TO_BE}}"
                     width="100%">
            </div>
        </div>
        <div class="d-flex mx-4 flex-column align-items-center" >
            <div class="mb-3 exo subtitle1 bold"
                 style="color: #EA3943 ">{{auction_catalog | managerPipe: managerService.mpa_template.AUCTION_CATALOG_NAME}}</div>
            <div class="mb-3 barlow caption regular"
                 style="color: #6F6F6F;">{{auction_catalog | managerPipe: managerService.mpa_template.AUCTION_CATALOG_TITLE}}</div>
            <div class="mb-4 barlow caption regular"
                 style="color: #6F6F6F;">{{auction_catalog | managerPipe: managerService.mpa_template.AUCTION_CATALOG_TEXT}}</div>
            <div class="d-flex flex-row" style="font-family: Roboto, sans-serif; text-align: center">
                <div class="d-flex flex-column">
                    <div class="mb-2 barlow caption medium"
                         style="color: #66BB6A;text-decoration: line-through;">
                        {{auction_catalog | managerPipe: managerService.mpa_template.AUCTION_CATALOG_START_DATE | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</div>
                    <div class="barlow caption regular" style="color: #B5B5B5;text-decoration: line-through;">
                        {{managerService.config.AUCTION.AUCTION_START | managerPipe}}
                    </div>
                </div>
                <div class="mx-2" style="width: 1px; height: 47px; background-color: #E5E6E7;"></div>
                <div class="d-flex flex-column">
                    <div class="mb-2 barlow caption medium"
                         style=";color: #EA3943;text-decoration: line-through;">
                        {{auction_catalog | managerPipe: managerService.mpa_template.AUCTION_CATALOG_END_DATE | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</div>
                    <div class="barlow caption regular" style="color: #B5B5B5;text-decoration: line-through;">
                        {{managerService.config.AUCTION.AUCTION_END | managerPipe}}
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex align-items-end justify-content-end ">
            <div class="d-flex justify-content-center align-items-center m-4" style="width: 107px; height: 40px; border-radius: 4px; background: #8266F0;text-align: center;
                    font-weight: 500; font-size: 14px; color: #FFFFFF;">
                {{managerService.config.AUCTION.ARTWORKS | managerPipe}}
                <svg style="width:25px; height: 30px; rotate: 270deg; ">
                    <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#FFFFFF"/>
                </svg>
            </div>
        </div>
    </div>
</div>
