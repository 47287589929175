<h1 style="padding-top: 4pt;padding-left: 116pt;text-indent: 0pt;text-align: center;">Artiox Limitler</h1>

<div class="d-flex flex-column justify-content-center align-items-center">
    <table cellspacing="0" style="border-collapse:collapse;margin-left:13.625pt; margin-bottom: 24px;">
        <tr style="height:48pt">
            <td style="width:127pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;">
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p class="s2" style="padding-left: 13pt;text-indent: 0pt;text-align: left;">Türk Lirası</p></td>
            <td style="width:127pt;border-top-style:solid;border-top-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;"></td>
            <td style="width:127pt;border-top-style:solid;border-top-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt"></td>
        </tr>
        <tr style="height:48pt">
            <td style="width:127pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p class="s2" style="padding-left: 2pt;padding-right: 1pt;text-indent: 0pt;line-height: 112%;text-align: left;">Seviyeler</p>
            <td style="width:135pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p class="s2" style="padding-left: 2pt;padding-right: 1pt;text-indent: 0pt;line-height: 112%;text-align: left;">Yatırma</p>
            <td style="width:158pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p class="s2" style="padding-top: 2pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">Çekme</p>
        </tr>
        <tr style="height:48pt">
            <td style="border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p class="s3" style="padding-left: 2pt;padding-right: 33pt;text-indent: 0pt;line-height: 112%;text-align: left;">Seviye 0</p></td>
            <td style="border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p class="s3" style="padding-top: 7pt;padding-left: 2pt;padding-right: 1pt;text-indent: 0pt;line-height: 112%;text-align: left;">Seviye 1 Doğrulama Gerekli</p>
            <td style="border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p class="s3" style="padding-left: 2pt;padding-right: 2pt;text-indent: 0pt;line-height: 112%;text-align: left;">Seviye 1 Doğrulama Gerekli</p></td>
        </tr>
        <tr style="height:48pt">
            <td style="border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p class="s3" style="padding-left: 2pt;padding-right: 22pt;text-indent: 0pt;line-height: 112%;text-align: left;">Seviye 1</p></td>
            <td style=";border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p style="padding-left: 2pt;text-indent: 0pt;text-align: left;">50.000,00₺ / Gün</p>
                <p style="padding-left: 2pt;text-indent: 0pt;text-align: left;">1.000.000,00₺ / Ay</p>
            </td>
            <td style="width:158pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p style="padding-left: 2pt;text-indent: 0pt;text-align: left;">50.000,00₺ / Gün</p>
                <p style="padding-left: 2pt;text-indent: 0pt;text-align: left;">1.000.000,00₺ / Ay</p>
        </tr>
        <tr style="height:48pt">
            <td style="width:127pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p class="s3" style="padding-left: 2pt;padding-right: 25pt;text-indent: 0pt;line-height: 112%;text-align: left;">Seviye 2</p></td>
            <td style="width:135pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p style="padding-left: 2pt;text-indent: 0pt;text-align: left;">1.000.000,00₺ / Gün</p>
                <p style="padding-left: 2pt;text-indent: 0pt;text-align: left;">10.000.000,00₺ / Ay</p>
            <td style="width:158pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p style="padding-left: 2pt;text-indent: 0pt;text-align: left;">1.000.000,00₺ / Gün</p>
                <p style="padding-left: 2pt;text-indent: 0pt;text-align: left;">10.000.000,00₺ / Ay</p>
        </tr>
    </table>

    <table cellspacing="0" style="border-collapse:collapse;margin-left:13.625pt; ">
        <tr style="height:48pt">
            <td style="width:127pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;">
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p class="s2" style="padding-left: 13pt;text-indent: 0pt;text-align: left;">Kripto Para</p></td>
            <td style="width:127pt;border-top-style:solid;border-top-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;"></td>
            <td style="width:127pt;border-top-style:solid;border-top-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt"></td>
        </tr>
        <tr style="height:48pt">
            <td style="width:127pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p class="s2" style="padding-left: 2pt;padding-right: 1pt;text-indent: 0pt;line-height: 112%;text-align: left;">Seviyeler</p>
            <td style="width:135pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p class="s2" style="padding-left: 2pt;padding-right: 1pt;text-indent: 0pt;line-height: 112%;text-align: left;">Yatırma</p>
            <td style="width:158pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p class="s2" style="padding-top: 2pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">Çekme</p>
        </tr>
        <tr style="height:48pt">
            <td style="border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p class="s3" style="padding-left: 2pt;padding-right: 33pt;text-indent: 0pt;line-height: 112%;text-align: left;">Seviye 0</p></td>
            <td style="border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p class="s3" style="padding-top: 7pt;padding-left: 2pt;padding-right: 1pt;text-indent: 0pt;line-height: 112%;text-align: left;">Seviye 2 Doğrulama Gerekli</p>
            <td style="border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p class="s3" style="padding-left: 2pt;padding-right: 2pt;text-indent: 0pt;line-height: 112%;text-align: left;">Seviye 2 Doğrulama Gerekli</p></td>
        </tr>
        <tr style="height:48pt">
            <td style="border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p class="s3" style="padding-left: 2pt;padding-right: 22pt;text-indent: 0pt;line-height: 112%;text-align: left;">Seviye 1</p></td>
            <td style=";border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p class="s3" style="padding-top: 7pt;padding-left: 2pt;padding-right: 1pt;text-indent: 0pt;line-height: 112%;text-align: left;">Seviye 2 Doğrulama Gerekli</p>
            </td>
            <td style="width:158pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p class="s3" style="padding-top: 7pt;padding-left: 2pt;padding-right: 1pt;text-indent: 0pt;line-height: 112%;text-align: left;">Seviye 2 Doğrulama Gerekli</p>
        </tr>
        <tr style="height:48pt">
            <td style="width:127pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p class="s3" style="padding-left: 2pt;padding-right: 25pt;text-indent: 0pt;line-height: 112%;text-align: left;">Seviye 2</p></td>
            <td style="width:135pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p style="padding-left: 2pt;text-indent: 0pt;text-align: left;">100.000,00₮ / Gün</p>
                <p style="padding-left: 2pt;text-indent: 0pt;text-align: left;">1.000.000,00₮ / Ay</p>
            <td style="width:158pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p style="padding-left: 2pt;text-indent: 0pt;text-align: left;">100.000,00₮ / Gün</p>
                <p style="padding-left: 2pt;text-indent: 0pt;text-align: left;">1.000.000,00₮ / Ay</p>
        </tr>
    </table>
</div>
