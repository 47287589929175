import {Injectable} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ManagerService} from './manager.service';
import {environment} from '../../environments/environment';


@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {

    constructor(private managerService: ManagerService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (environment.proxy && (request.url.startsWith(this.managerService.environment.serverAPI))) {
            request = request.clone({
                url: this.managerService.environment.serverURL + this.managerService.environment.serverAPI,
                setHeaders: {'Set-Cookie': 'SameSite=None;'}
            });
        }
        if (request.method === 'POST') {
            if (request.url.endsWith(this.managerService.environment.serverAPI + 'appeal_second_level_kyc_service')) {
                return next.handle(request);
            } else {
                request = request.clone({
                    setHeaders: {'Content-Type': 'application/json'},
                    withCredentials: true
                });
                request.body.sid = this.managerService.client_socket.id ?? '';
                request.body.client_ip = this.managerService.client_ip;
                // if (environment.platform === 'android' || environment.platform === 'ios') {
                //     request.body.cookie = this.managerService.cookie;
                // }
                return next.handle(request);
            }
        } else {
            return next.handle(request);
        }
    }
}

export const HttpClientInterceptorProvider = [
    {provide: HTTP_INTERCEPTORS, useClass: HttpClientInterceptor, multi: true, deps: [ManagerService]},
];
