import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {NgClass} from '@angular/common';

@Component({
  selector: 'app-support-card',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './support-card.component.html',
  styleUrl: './support-card.component.css'
})
export class SupportCardComponent {
  @Input({required: true}) cardTitle = ''
  @Input({required: true}) cardBody = ''
  @Input() clickable = true;

}


