<div class="w-100 d-flex flex-column align-items-start justify-content-start" style="background-color: #fcfdff;"
     [style]="managerService.show_sign_up_or_login | managerPipe: managerService.mpa_template.GET_MIN_BODY_HEIGHT_OBSOLETE">
  <div [class]="managerService.userService.user?.two_factor_auth ? 'd-none' : 'd-flex' | managerPipe"
       class="w-100 flex-column align-items-start justify-content-start p-3">
    <div class="w-100 d-flex flex-column" style="min-width: 240px;">
      <div class="w-100 mb-2"
           style="font-family: 'Roboto Condensed', sans-serif, sans-serif; font-size: 16px; letter-spacing: 0.75px; color: #404352;">
        {{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.FORM_HEADING_MOBILE | managerPipe}}
      </div>

      <div class="w-100 py-2"
           style="font-family: Roboto, sans-serif; font-size: 12px;line-height: 1.2; color: #404352;">
        {{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.DOWNLOAD_2FA_APP | managerPipe}}
      </div>
      <div class="w-100 py-2"
           style="font-family: Roboto, sans-serif; font-size: 12px;line-height: 1.2; color: #404352;">
        {{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.INSERT_PASSWORD | managerPipe}}
      </div>
    </div>

    <form [formGroup]="passwordForm" class="w-100 d-flex flex-column align-items-center justify-content-start">
      <div class="w-100 mb-2">
        <label class="position-relative mt-3 d-flex flex-row" style="height: 30px;">
          <input [type]="(current_password_visible | managerPipe) ? 'text' : 'password'"
                 class="w-100 input-field-underline password-input-field"
                 formControlName="password"
                 placeholder="{{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.CURRENT_PASSWORD | managerPipe}}"
                 required
                 style="padding: 0 40px 0 8px">
          <svg (click)="current_password_visible = !current_password_visible" class="password-eye" style="right: 0">
            <use *ngIf="(!current_password_visible | managerPipe)"
                 [attr.xlink:href]='"./assets/images/eye_hidden.svg#eye_hidden"' fill="#9DA5B7"/>
            <use *ngIf="(current_password_visible | managerPipe)"
                 [attr.xlink:href]='"./assets/images/eye_visible.svg#eye_visible"' fill="#9DA5B7"/>
          </svg>
        </label>
        <div #warning_password class="d-none warning" style="padding: 4px 12px 0 8px; font-size: 10px; letter-spacing: normal;"></div>
      </div>
      <button (click)="checkPassword()" [class.button-inactive]="(! two_factor_auth_div.classList.contains('d-none')) | managerPipe"
              [class.d-none]="(! two_factor_auth_div.classList.contains('d-none')) | managerPipe"
              class="btn-native authentication-button mb-4"
              style="width: 145px; height: 33px; border-radius: 19px;  font-family: Roboto, sans-serif; font-size: 13px; font-weight: 300; letter-spacing: normal;">
        {{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.APPLY | managerPipe}}
      </button>
    </form>

    <div #two_factor_auth_div class="w-100 d-none">
      <div class="w-100 position-relative d-flex flex-column align-items-start justify-content-start"
           style="height: fit-content;">
        <div class="w-100 position-relative d-flex flex-column align-items-center justify-content-center" style="">
          <div
            style="font-family: bebas-neue-regular, sans-serif; font-size: 25px; font-weight: bold; line-height: 1.25; letter-spacing: 2.5px; color: #404352;">{{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.QR_CODE}}</div>
          <svg class="position-absolute" style="height: 200px; width: 200px; transform: translateY(5px); margin-left: auto; margin-right: auto; left: 0; right: 0">
            <use [attr.xlink:href]='"./assets/images/qr_code.svg#qr_code"' fill="#231f20"/>
          </svg>
          <canvas #qr></canvas>
          <div style="height: 20px;"></div>
        </div>
        <div class="w-100 d-flex flex-column">
          <div class="font-weight-bold mt-1"
               style="font-family: Roboto, sans-serif; font-size: 14px; letter-spacing: 0.4px; color: #404352;">
            {{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.SECRET_KEY | managerPipe}}
            :
          </div>
          <div>
            <span #qr_code style="font-size: 13px; margin-right: 4px"></span>
            <span (click)="copySecretKey()" class=" btn-native">
              <svg style="height: 16px; width: 16px;">
                <use [attr.xlink:href]='"./assets/images/copy.svg#copy"' fill="#595e7e"/>
              </svg>
            </span>
          </div>

        </div>
      </div>
      <div class="w-75 d-flex flex-column" style="min-width: 240px;">
        <div class="w-100 py-2 pt-4"
             style="font-family: Roboto, sans-serif; font-size: 12px;line-height: 1.2; color: #404352;">
          {{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.INSERT_AUTH_PASSWORD}}
        </div>
      </div>
      <form [formGroup]="twoFactorAuthenticationForm"
            class="w-100 d-flex flex-column align-items-start justify-content-start" style="height: fit-content;">
        <div class="w-100">
          <label class="d-flex flex-row align-items-start justify-content-start checkbox-text-black">
            <input class="checkbox-field two-factor-authentication-input-field" formControlName="auth_accepted"
                   style="height: 16px; min-width: 16px; border-radius: 2px; box-shadow: 1px 1px 2px 0 #d8d8d8;" type="checkbox">
            <span class="px-2 my-1 two-factor-authentication-input-field"
                  style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 300; font-style: italic; line-height: 1.2; color: #272a3b;">
            {{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.AUTHENTICATION_WARNING | managerPipe}}
          </span>
          </label>
        </div>
        <div class="w-100 d-flex flex-column align-items-center justify-content-start">
          <div class="w-100">
            <label class="position-relative py-2 d-flex flex-row" style="height: 30px;">
              <input [type]="(auth_password_visible | managerPipe) ? 'text' : 'password'"
                     class="w-100 input-field-underline two-factor-authentication-input-field"
                     formControlName="auth_password" placeholder="{{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.AUTH_PASSWORD | managerPipe}}"
                     required
                     style="padding: 0 40px 0 8px">
              <svg (click)="auth_password_visible = !auth_password_visible" class="password-eye" style="right: 0">
                <use *ngIf="(!auth_password_visible | managerPipe)"
                     [attr.xlink:href]='"./assets/images/eye_hidden.svg#eye_hidden"' fill="#9DA5B7"/>
                <use *ngIf="(auth_password_visible | managerPipe)"
                     [attr.xlink:href]='"./assets/images/eye_visible.svg#eye_visible"' fill="#9DA5B7"/>
              </svg>
            </label>
            <div #warning_auth_password class="d-none warning" style="padding: 8px 8px 0; font-size: 10px; letter-spacing: normal;"></div>
          </div>
          <button (click)="checkAuthPassword()"
                  [class.button-inactive]="twoFactorAuthenticationForm.invalid"
                  [disabled]="twoFactorAuthenticationForm.invalid"
                  class="btn-native authentication-button m-3"
                  style="width: 145px; height: 33px; border-radius: 19px;  font-family: Roboto, sans-serif; font-size: 13px; font-weight: 300; letter-spacing: normal;">
            {{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.COMPLETE | managerPipe}}
          </button>
        </div>
      </form>
    </div>
  </div>

  <div [class]="managerService.userService.user?.two_factor_auth ? 'd-flex' : 'd-none' | managerPipe"
       class="w-100 flex-column p-3"
       style="font-family: Roboto, sans-serif; font-size: 14px; line-height: 1.5; letter-spacing: 1.4px; color: #404352;">
    <div class="w-100 mb-2"
    style="font-family: 'Roboto Condensed', sans-serif, sans-serif; font-size: 16px; letter-spacing: 0.75px; color: #404352;">
      {{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.HEADING_REMOVE | managerPipe}}
    </div>
    <div [innerHTML]="managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.ACTIVE | managerPipe"
         class="w-100 py-2"
         style="font-family: Roboto, sans-serif; font-size: 12px;line-height: 1.2; color: #404352;">
    </div>
    <div class="w-100 py-2"
         style="font-family: Roboto, sans-serif; font-size: 12px;line-height: 1.2; color: #404352;">
      {{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.INSERT_PASSWORD_TO_REMOVE | managerPipe}}
    </div>
    <form [formGroup]="remove2FAPasswordForm" class="w-100 d-flex flex-column align-items-center justify-content-start">
      <div class="w-100 mb-2">
        <label class="position-relative mt-3 d-flex flex-row" style="height: 30px;">
          <input [type]="(current_password_visible | managerPipe) ? 'text' : 'password'"
                 class="w-100 input-field-underline password-input-field"
                 formControlName="password" #remove_2fa_password_input
                 placeholder="{{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.CURRENT_PASSWORD | managerPipe}}"
                 required
                 style="padding: 0 40px 0 8px">
          <svg (click)="current_password_visible = !current_password_visible" class="password-eye" style="right: 0">
            <use *ngIf="(!current_password_visible | managerPipe)"
                 [attr.xlink:href]='"./assets/images/eye_hidden.svg#eye_hidden"' fill="#9DA5B7"/>
            <use *ngIf="(current_password_visible | managerPipe)"
                 [attr.xlink:href]='"./assets/images/eye_visible.svg#eye_visible"' fill="#9DA5B7"/>
          </svg>
        </label>
        <div #warning_password class="d-none warning" style="padding: 4px 12px 0 8px; font-size: 10px; letter-spacing: normal;"></div>
      </div>
      <button (click)="validatePassword()" [class.button-inactive]="(remove_2fa_password_input.value.length === 0) | managerPipe"
              [disabled]="(remove_2fa_password_input.value.length === 0) | managerPipe"
              [class.d-none]="password_validated | managerPipe"
              class="btn-native authentication-button mb-4"
              style="width: 145px; height: 33px; border-radius: 19px;  font-family: Roboto, sans-serif; font-size: 13px; font-weight: 300; letter-spacing: normal;">
        {{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.APPLY | managerPipe}}
      </button>
    </form>
    <div [class.d-none]="!password_validated | managerPipe">
      <div class="w-100 py-2"
           style="font-family: Roboto, sans-serif; font-size: 12px;line-height: 1.2; color: #404352;">
        {{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.INSERT_AUTH_PASSWORD_TO_REMOVE | managerPipe}}
      </div>
      <form [formGroup]="remove2FAKeyForm" class="w-100 d-flex flex-column align-items-center justify-content-start">
        <div class="w-100 mb-2">
          <label class="position-relative mt-3 d-flex flex-row" style="height: 30px;">
            <input [type]="(auth_password_visible | managerPipe) ? 'text' : 'password'"
                   class="w-100 input-field-underline password-input-field"
                   formControlName="auth_password" #remove_2fa_auth_password_input
                   placeholder="{{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.SECRET_KEY | managerPipe}}"
                   required
                   style="padding: 0 40px 0 8px">
            <svg (click)="auth_password_visible = !auth_password_visible" class="password-eye" style="right: 0">
              <use *ngIf="(!auth_password_visible | managerPipe)"
                   [attr.xlink:href]='"./assets/images/eye_hidden.svg#eye_hidden"' fill="#9DA5B7"/>
              <use *ngIf="(auth_password_visible | managerPipe)"
                   [attr.xlink:href]='"./assets/images/eye_visible.svg#eye_visible"' fill="#9DA5B7"/>
            </svg>
          </label>
          <div #warning_password class="d-none warning" style="padding: 4px 12px 0 8px; font-size: 10px; letter-spacing: normal;"></div>
        </div>
        <button (click)="removeTwoFactorAuth()" [class.button-inactive]="(remove_2fa_auth_password_input.value.length === 0) | managerPipe"
                [disabled]="(remove_2fa_auth_password_input.value.length === 0) | managerPipe"
                class="btn-native authentication-button mb-4"
                style="width: 145px; height: 33px; border-radius: 19px;  font-family: Roboto, sans-serif; font-size: 13px; font-weight: 300; letter-spacing: normal;">
          {{managerService.config.SECURITY.TWO_FACTOR_AUTHENTICATOR.APPLY | managerPipe}}
        </button>
      </form>
    </div>
  </div>
</div>


