import { Component, OnInit } from '@angular/core';
import {ManagerService} from '../../../services/manager.service';

@Component({
  selector: 'app-withdrawal-expired',
  templateUrl: './withdrawal-expired.component.html',
  styleUrls: ['./withdrawal-expired.component.css']
})
export class WithdrawalExpiredComponent implements OnInit {

  constructor(public managerService: ManagerService) { }

  ngOnInit(): void {
  }

}
