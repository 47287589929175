<div style="max-width: 90vw; background: #FFFFFF;height: 100vh">
    <div class="d-flex w-100 justify-content-center">
        <div class="d-flex flex-column" style="gap: 16px;padding-left: 16px;padding-right: 16px;padding-top: 24px">
            <div class="d-flex flex-row justify-content-between">
                <p class="header5 exo bold" style="color: #8266F0">{{managerService.config.AUCTION.PAST_PRODUCT_INFO.TITLE | managerPipe}}</p>
                <svg (click)="managerService.closeModal(modal_id)" style="width: 24px; height: 24px; cursor: pointer">
                    <use [attr.xlink:href]='"./assets/images/close_with_circle.svg#close_with_circle"' stroke="#B5B5B5" fill="none"/>
                </svg>
            </div>
            <p class="overline barlow regular" style="color: #6F6F6F">{{managerService.config.AUCTION.PAST_PRODUCT_INFO.DESCRIPTION | managerPipe}}</p>
        </div>
    </div>
    <div class="px-4 mb-3 w-100 d-flex flex-column align-items-start justify-content-center">
        <div class="d-flex w-100 align-items-stretch justify-content-between">
            <div class=" d-flex w-100 justify-content-between">
                 <span
                     style="font-family: Roboto, sans-serif;font-style: normal;font-weight: 500;font-size: 14px;line-height: 16px;display: flex;align-items: center;text-align: center;letter-spacing: 0.001em;color: #686C75;">
                     {{managerService.config.AUCTION.PAST_PRODUCT_INFO.OPEN | managerPipe}}
                 </span>
                <span class="barlow body2 medium"
                    style="color: #6F6F6F">
                    {{managerService.auctionService.active_auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_OPENING_VALUE: managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
                    {{managerService.auctionService.active_auction_product.value_type | managerPipe: managerService.mpa_template.VALUE_TYPE}}
                </span>
            </div>
        </div>
    </div>

    <div class="px-4 mb-3 w-100 d-flex flex-column align-items-start justify-content-center">
        <div class="d-flex w-100 align-items-stretch justify-content-between">
            <div class=" d-flex w-100 justify-content-between">
                 <span
                     style="font-family: Roboto, sans-serif;font-style: normal;font-weight: 500;font-size: 14px;line-height: 16px;display: flex;align-items: center;text-align: center;letter-spacing: 0.001em;color: #686C75;">
                     {{managerService.config.AUCTION.PAST_PRODUCT_INFO.CLOSE | managerPipe}}
                 </span>
                <span class="barlow body2 medium" style="color: #6F6F6F">
                    {{managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_CURRENT_PRICE}}
<!--                    {{managerService.auctionService.active_auction_product.value_type | managerPipe}}-->
                </span>
            </div>
        </div>
    </div>

<!--    <div class="px-4 mb-3 w-100 d-flex flex-column align-items-start justify-content-center">-->
<!--        <div class="d-flex w-100 align-items-stretch justify-content-between">-->
<!--            <div class=" d-flex w-100 justify-content-between">-->
<!--                <span-->
<!--                    style="font-family: Roboto, sans-serif;font-style: normal;font-weight: 500;font-size: 14px;line-height: 16px;display: flex;align-items: center;text-align: center;letter-spacing: 0.001em;color: #686C75;">-->
<!--                     {{[(managerService.auctionService.active_auction_product.tax)*100] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.AUCTION.BID_REPORT.TAX}}-->
<!--                 </span>-->
<!--                <span class="barlow body2 medium" style="color: #6F6F6F">-->
<!--                    {{managerService.auctionService.active_bid_price_pipe | managerPipe: managerService.mpa_template.ACTIVE_BID_TAX: managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.FANCY_NUMBER}}-->
<!--                    {{managerService.auctionService.active_auction_product.value_type | managerPipe}}-->
<!--                </span>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->

    <!--    <div style="width: 445px;height: 0px;border: 0.1px solid #D8D8D8; border-radius: 4px; margin-left: 40px; margin-right: 40px; margin-bottom: 10px"></div>-->

    <div class="px-4 mb-4 w-100 d-flex flex-column align-items-start justify-content-center">
        <div class="d-flex w-100 align-items-stretch justify-content-between">
            <div class=" d-flex w-100 justify-content-between" style="border-top: 0.4px solid #D8D8D8; padding-top: 10px; ">
                <span
                    style="font-family: Roboto, sans-serif;font-style: normal;font-weight: 500;font-size: 14px;line-height: 19px;display: flex;align-items: center;text-align: center;letter-spacing: 0.0015em;color: #6F6F6F; margin-top: 2px">
                     {{managerService.config.AUCTION.PAST_PRODUCT_INFO.TOTAL | managerPipe}}
                 </span>
                <span class="bold barlow body2"
                    style="color: #8266F0;">
                    {{managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_TOTAL_PRICE: managerService.auctionService.active_auction_product_pipe}}
<!--                    {{managerService.auctionService.active_auction_product.value_type | managerPipe}}-->
                </span>
            </div>
        </div>
    </div>

    <div class="d-flex w-100 justify-content-center align-items-end" style="position:absolute; bottom: 10px">
        <svg style=" height: 32px; width: 138px; margin-top: 32px; margin-bottom: 60px">
            <use [attr.xlink:href]='"./assets/images/artiox_logo.svg#artiox_logo"' fill="#000"/>
        </svg>
    </div>
</div>
