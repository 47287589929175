<div class="w-100 d-flex flex-row pt-3 px-3" style="background-color: #fcfdff;">
  <div>
    <svg style="height: 48px; width: 48px;">
      <use [attr.xlink:href]='"./assets/images/commissions.svg#commissions"' fill="var(--artiox-blue)"/>
    </svg>
  </div>
  <div class="pl-2">
    <div class="" style="font-family: Roboto, sans-serif; font-size: 12px; line-height: 1.35; color: #404352;">
      {{managerService.config.SERVICE.COMMISSIONS.SUMMARY | managerPipe}}
    </div>
  </div>
</div>

