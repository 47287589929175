import { Component, OnInit } from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';

@Component({
  selector: 'app-my-wons-mobile',
  templateUrl: './my-wons-mobile.component.html',
  styleUrls: ['./my-wons-mobile.component.css']
})
export class MyWonsMobileComponent implements OnInit {

  constructor(public managerService: ManagerService) { }
  ngOnInit(): void {
  }

}
