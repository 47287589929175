<div class="d-flex flex-column w-100 h-100" style="background: #fcfdff"
     [style]="managerService.show_sign_up_or_login | managerPipe: managerService.mpa_template.GET_MIN_BODY_HEIGHT_OBSOLETE" >
  <div class="w-100 d-flex flex-column position-relative"
       style="background-color: #fcfdff; max-height: 150px; box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);">
    <div class="mt-4 mb-2 ml-3"
         style="font-family: Roboto, sans-serif;font-size: 14px; letter-spacing: 0.7px; color: #272a3b;">
      <span>
        <svg style="height: 24px; width: 24px; margin-top: -4px;">
            <use [attr.xlink:href]='"./assets/images/wallet_full.svg#wallet_full"' fill="#272a3b"/>
          </svg>
      </span>
      {{managerService.config.BALANCE.HEADING | managerPipe: managerService.mpa_template.TO_LOCAL_UPPER_CASE}}
    </div>
    <div class="d-flex flex-row">
      <div class="text-center" style="width: 30%;">
        <button [class.btn-account-navigator-active]="managerService.active_balance_panel | managerPipe: managerService.mpa_template.ACTIVE_BALANCE_PANEL_SUMMARY"
                (click)="managerService.active_balance_panel = managerService.active_balance_panel_template.SUMMARY"
                class="btn-native btn-account-navigator" style=" font-size: 13px; white-space: normal;" type="button"
                [innerHTML]="managerService.config.BALANCE.SUMMARY_MOBILE | managerPipe">
        </button>
        <div style="margin: 0px 12px; border-bottom: 1px solid #4bc4d6"
             *ngIf="managerService.active_balance_panel | managerPipe: managerService.mpa_template.ACTIVE_BALANCE_PANEL_SUMMARY"></div>
      </div>
      <div class="text-center" style="width: 30%">
        <button [class.btn-account-navigator-active]="managerService.active_balance_panel | managerPipe: managerService.mpa_template.ACTIVE_BALANCE_PANEL_ORDER_HISTORY"
                (click)="managerService.active_balance_panel = managerService.active_balance_panel_template.USER_TRADE_HISTORY"
                class="btn-native btn-account-navigator" style=" font-size: 13px; white-space: normal;" type="button"
                [innerHTML]="managerService.config.BALANCE.USER_TRADE_HISTORY_MOBILE | managerPipe">
        </button>
        <div style="margin: 0px 12px; border-bottom: 1px solid #4bc4d6"
             *ngIf="managerService.active_balance_panel | managerPipe: managerService.mpa_template.ACTIVE_BALANCE_PANEL_ORDER_HISTORY"></div>
      </div>
      <div class="text-center" style="width: 40%">
        <button [class.btn-account-navigator-active]="managerService.active_balance_panel | managerPipe: managerService.mpa_template.ACTIVE_BALANCE_PANEL_OPERATION_HISTORY"
                (click)="managerService.active_balance_panel = managerService.active_balance_panel_template.USER_OPERATION_HISTORY"
                class="btn-native btn-account-navigator" style=" font-size: 13px; white-space: normal;" type="button"
                [innerHTML]="managerService.config.BALANCE.USER_OPERATION_HISTORY_MOBILE | managerPipe">
        </button>
        <div style=" margin: 0px 12px; border-bottom: 1px solid #4bc4d6"
             *ngIf="managerService.active_balance_panel | managerPipe: managerService.mpa_template.ACTIVE_BALANCE_PANEL_OPERATION_HISTORY"></div>
      </div>
    </div>
  </div>
  <app-summary-mobile [class.d-none]="!(managerService.active_balance_panel | managerPipe: managerService.mpa_template.ACTIVE_BALANCE_PANEL_SUMMARY)"></app-summary-mobile>
  <app-order-history-mobile [class.d-none]="!(managerService.active_balance_panel | managerPipe: managerService.mpa_template.ACTIVE_BALANCE_PANEL_ORDER_HISTORY)"></app-order-history-mobile>
  <app-operation-history-mobile
    [class.d-none]="!(managerService.active_balance_panel | managerPipe: managerService.mpa_template.ACTIVE_BALANCE_PANEL_OPERATION_HISTORY)"></app-operation-history-mobile>
</div>
