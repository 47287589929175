import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ManagerService} from '../../../../../services/manager.service';
import {HttpClient} from '@angular/common/http';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-pre-sale-trade-desktop',
  templateUrl: './pre-sale-trade-desktop.component.html',
  styleUrls: ['./pre-sale-trade-desktop.component.css']
})
export class PreSaleTradeDesktopComponent implements OnInit, AfterViewInit, OnDestroy {

  public pre_sale_hover: boolean = false;

  marketBuyForm = new UntypedFormGroup({
    amount: new UntypedFormControl(null, [Validators.required, Validators.min(0)]),
  });

  public market_buy_average_cost: number | null = null;

  public subscription_list: (Subscription | undefined)[] = [];

  constructor(public managerService: ManagerService, public httpClient: HttpClient) {
    this.subscription_list.push(this.managerService.pairService.active_featured_pair_selected.subscribe(() => {
      this.marketBuyForm.get('amount')?.setValue(null);
    }));
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.subscription_list.push(this.marketBuyForm.get('amount')?.valueChanges.subscribe(() => {
      if (this.managerService.userService.user_ready) {
        if (this.marketBuyForm.get('amount')?.value === null) {
          this.market_buy_average_cost = null;
          return;
        } else {
          this.market_buy_average_cost = this.managerService.preSaleBuyCost(this.marketBuyForm.get('amount')?.value);
        }
        const buy_market_average_cost_check = this.managerService.checkPreSaleBuyCost(this.marketBuyForm.get('amount')?.value);
        if (this.marketBuyForm.get('amount')?.value !== buy_market_average_cost_check) {
          this.marketBuyForm.get('amount')?.setValue(buy_market_average_cost_check);
        }
      }
    }));
  }

  preSaleBuy(): void {
    if (this.managerService.pairService.active_featured_pair === undefined) {
      return;
    } else if (this.managerService.pairService.active_featured_pair.sale_state === 'featuring') {
      return;
    } else if (this.managerService.pairService.active_featured_pair.sale_state === 'listing') {
      this.managerService.navigationManager({url: '/exchange/advanced', active_pair_id: this.managerService.pairService.active_featured_pair.id, current_component_index: 1});
    }

    if (!this.managerService.userService.user_ready) {
      // @ts-ignore
      const content = this.managerService.config.RESPONSE_TABLE['2001'];
      this.managerService.popSnackBar(content.is_okay, content.message);
      return;
    }

    if (this.marketBuyForm.invalid || this.market_buy_average_cost === null) {
      return;
    }

    const pair = this.managerService.pairService.active_featured_pair;
    const amount = this.marketBuyForm.get('amount')?.value || 0;

    const data = {
      pair_id: pair.id,
      order_type: 'buy',
      amount
    };

    this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'pre_sale_buy_service', data).subscribe(
      response => {
        // @ts-ignore
        const content = this.managerService.config.RESPONSE_TABLE[response.code];
        this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
        if (response.is_okay){
          const symbol = pair.symbol;
          const volume = (pair.sale_state  === 'pre_sale' ? pair.pre_sale_price : pair.public_sale_price) * amount;
          this.managerService.marketingService.logEvent('pre_sale', {type: 'buy', kind: 'pre_sale', view: 'pre_sale', symbol, amount, volume});  // untested
        }
      }));
    this.marketBuyForm.get('amount')?.setValue(null);
  }

  ngOnDestroy(): void {
    this.subscription_list.forEach((subscription) => {
      subscription?.unsubscribe();
    });
  }

}
