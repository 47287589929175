<div class="w-100" style="background-color: #fcfdff;padding: 24px">
    <!--    <div class="w-100 d-flex flex-row align-items-center justify-content-center" style="height: 7.5vw;-->
    <!--  font-family: Roboto, sans-serif; font-size: 4vw; line-height: 1.25; letter-spacing: 1px; color: #404352; cursor: pointer;">-->
    <!--        <div (click)="managerService.active_auction_info_panel = managerService.active_auction_info_panel_template.ARTIST;"-->
    <!--             [style]="managerService.active_auction_info_panel !== managerService.active_auction_info_panel_template.ARTIST ? {'box-shadow':  'inset -2px -2px 3px rgba(213, 216, 226, 1)', color: '#9da5b7'} : {}"-->
    <!--             class="h-100 d-flex flex-column align-items-center justify-content-center text-center" style="width: 30vw">-->
    <!--            <div>-->
    <!--                {{managerService.config.AUCTION.ARTIST | managerPipe}}-->
    <!--            </div>-->
    <!--        </div>-->
    <!--        <div (click)="managerService.active_auction_info_panel = managerService.active_auction_info_panel_template.LAST_BIDS;"-->
    <!--             [style]="managerService.active_auction_info_panel !== managerService.active_auction_info_panel_template.LAST_BIDS ? {'box-shadow':  'inset -2px -2px 3px rgba(213, 216, 226, 1)', color: '#9da5b7'} : {}"-->
    <!--             class="h-100 d-flex flex-column align-items-center justify-content-center text-center" style="width: 30vw">-->
    <!--            <div>-->
    <!--                {{[managerService.bidService.bid_list_pipe | managerPipe: managerService.mpa_template.GET_LAST_BID_COUNT] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.AUCTION.BIDS}}-->
    <!--            </div>-->
    <!--        </div>-->
    <!--    </div>-->
    <div class="w-100 d-flex flex-row align-items-center justify-content-center">
        <div
            [style]="managerService.active_auction_info_panel !== managerService.active_auction_info_panel_template.LAST_BIDS ? {'background': 'linear-gradient(to right, #8266F0, #fff)'} : {'background': 'linear-gradient(to left, #8266F0, #fff)'}"
            class="d-flex flex-row"
            style="padding: 1px; border-radius: 16px;cursor: pointer">
            <div (click)="managerService.active_auction_info_panel = managerService.active_auction_info_panel_template.ARTIST;"
                 [style]="managerService.active_auction_info_panel !== managerService.active_auction_info_panel_template.ARTIST ? { color: '#B5B5B5'} : { color: '#8266F0'}"
                 class="h-100 d-flex flex-column align-items-center justify-content-center text-center"
                 style="padding:8px 0 8px 0;width: 120px;background: #FFFFFF;border-top-left-radius: 16px;border-bottom-left-radius: 16px;">
                <div>
                    {{managerService.config.AUCTION.ARTIST | managerPipe}}
                </div>
            </div>

            <div (click)="managerService.active_auction_info_panel = managerService.active_auction_info_panel_template.LAST_BIDS;"
                 [style]="managerService.active_auction_info_panel !== managerService.active_auction_info_panel_template.LAST_BIDS ? { color: '#B5B5B5'} : { color: '#8266F0'}"
                 class="h-100 d-flex flex-column align-items-center justify-content-center text-center"
                 style="padding:8px 0 8px 0;width: 120px;background: #FFFFFF;border-bottom-right-radius: 16px;border-top-right-radius: 16px;">
                <div>
                    {{[managerService.bidService.bid_list_pipe | managerPipe: managerService.mpa_template.GET_LAST_BID_COUNT] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.AUCTION.BIDS}}
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="managerService.active_auction_info_panel | managerPipe: managerService.mpa_template.COMPARE: managerService.active_auction_info_panel_template.ARTIST"
         class="px-4" style="box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); border-radius: 4px; min-height: 360px;padding-bottom: 5vw">
        <div class="pt-4 pb-3 exo header5 bold" style=" color: #8266F0;">
            {{ managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_ARTIST_TITLE }}
        </div>
        <div class="barlow caption regular" style="color: #6F6F6F">
            {{ managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_ARTIST_TEXT : managerService.language_pipe}}
        </div>
    </div>

    <div *ngIf="managerService.active_auction_info_panel | managerPipe: managerService.mpa_template.COMPARE: managerService.active_auction_info_panel_template.LAST_BIDS"
         style="padding-left: 3vw;padding-right: 3vw;box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); border-radius: 4px; min-height: 360px;">
        <div class="d-flex flex-row align-items-center justify-content-around py-1 my-1"
             style="font-family: Roboto, sans-serif;font-size: 3vw; font-weight: 500; line-height: 2.7; letter-spacing: 0.7px; color: #4a4e70;">
            <span class="text-center" style="width: 25%">{{managerService.config.AUCTION.LAST_BIDS.BIDDER | managerPipe}}</span>
            <span class="text-center" style="width: 50%">{{managerService.config.AUCTION.LAST_BIDS.DATE | managerPipe}}</span>
            <span class="text-center" style="width: 25%">{{managerService.config.AUCTION.LAST_BIDS.PRICE | managerPipe}}</span>
        </div>
        <div class="d-flex flex-column overflow-auto" style="min-height: 200px; max-height: 300px;">
            <div
                *ngFor="let bid of (managerService.bidService.bid_list_pipe | managerPipe: managerService.mpa_template.GET_LAST_BIDS)"
                class="d-flex flex-row align-items-center justify-content-around flex-wrap user-trade-history flex-shrink-0 row-separator"
                style="font-family: Roboto, sans-serif;font-size: 3vw; font-weight: 500; line-height: 2.7; letter-spacing: 0.7px; color: #4a4e70; padding: 2px 0; height: fit-content;">
                <span class="text-center" style="width: 25%">********</span>
                <span class="text-center" style="width: 50%">{{bid.creation_time | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</span>
                <span class="text-center" style="width: 25%; color: #66BB6A">{{bid.price | managerPipe: managerService.mpa_template.FANCY_NUMBER}} TL</span>
            </div>
        </div>
    </div>
</div>
