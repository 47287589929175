import {EventEmitter, Injectable, Output} from '@angular/core';
import * as moment from 'moment';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';


export class Bid {
    public id: number = -1;
    public auction_product_id: number = -1;
    public user_id: number = -1;
    public type: string = '';
    public price: number = 0.0;
    public status: string = '';
    public bidder: string = '';
    public anonymous: boolean = false;
    public creation_time: number = 0.0;

    constructor(bid_primitive: any) {
        this.id = bid_primitive.id;
        this.auction_product_id = bid_primitive.auction_product_id;
        this.user_id = bid_primitive.user_id;
        this.type = bid_primitive.type;
        this.price = bid_primitive.price;
        this.status = bid_primitive.status;
        this.bidder = bid_primitive.bidder;
        this.anonymous = bid_primitive.anonymous;
        this.creation_time = bid_primitive.creation_time / 1000000;
    }
}

@Injectable({
    providedIn: 'root'
})
export class BidService {
    public bid_list_ready: boolean = false;
    public bid_list: Bid[] = [];
    public bid_list_pipe: boolean = false;

    public bid_tickers_ready: boolean = false;
    public bid_tickers: Bid[] = [];
    public bid_tickers_pipe: boolean = false;

    public bid_list_limit: number = 200;
    public ticker_limit: number = 12;

    public environment = environment;

    @Output() bid_list_changed: EventEmitter<any> = new EventEmitter();
    @Output() bid_tickers_changed: EventEmitter<any> = new EventEmitter();

    constructor(private httpClient: HttpClient) {
    }

    refreshBidList(auction_product_id: number, resolve?: any): void {
        const data = {auction_product_id, limit: this.bid_list_limit};
        this.httpClient.post<any>(this.environment.serverAPI + 'get_auction_product_bids_service', data).subscribe(
            response => {
                this.setBidList(response);
                if (resolve !== undefined) {
                    resolve();
                }
            });
    }


    refreshBidTickers(): void {
        this.httpClient.post<any>(this.environment.serverAPI + 'get_bid_tickers_service', {limit: this.ticker_limit}).subscribe(
            response => {
                this.setBidTickers(response);
            });

    }

    setBidList(bid_list_primitive: any[]): void {
        this.bid_list = bid_list_primitive.map(x => new Bid(x));
        this.bid_list_ready = true;
        this.bid_list_pipe = !this.bid_list_pipe;
        this.bid_list_changed.emit();
    }

    setBidTickers(bid_tickers_primitive: any[]): void {
        const temp = bid_tickers_primitive.map(x => new Bid(x));
        this.bid_tickers = temp;
        while (temp.length && this.bid_tickers.length < this.ticker_limit) {  // todo remove after bids populate in prod
            this.bid_tickers = this.bid_tickers.concat(temp);
        }
        this.bid_tickers_ready = true;
        this.bid_tickers_pipe = !this.bid_tickers_pipe;
        this.bid_tickers_changed.emit();
    }
}
