import {Component, Input, OnInit} from '@angular/core';
import {ManagerService} from "../../../../../services/manager.service";
import {Subscription} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {DeliveryType} from '../../../../../services/user.service';


@Component({
  selector: 'app-approve-payment-desktop',
  templateUrl: './approve-payment-desktop.component.html',
  styleUrls: ['./approve-payment-desktop.component.css']
})
export class ApprovePaymentDesktopComponent implements OnInit {

  public approved: boolean = false;
  public subscription_list: (Subscription | undefined)[] = [];
  constructor(public managerService: ManagerService, private httpClient: HttpClient) { }

  ngOnInit(): void {
  }

  approve(checked: any): void{
    this.approved = checked;
  }

  approvePayment(): void{
    if (this.approved){
      const data = {
        delivery_id: this.managerService.userService.selected_delivery_id,
        delivery_type: this.managerService.userService.selected_delivery_type === DeliveryType.Cargo ? 'cargo' : 'hand_delivery',
        billing_address_id: this.managerService.userService.selected_billing_address_id,
        delivery_address_id: this.managerService.userService.selected_delivery_address_id,
      };
      this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'update_delivery_service', data).subscribe(
          response => {
            if (!response.is_okay) {
              // @ts-ignore
              const content = this.managerService.config.RESPONSE_TABLE[response.code];
              this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
            } else {
              // @ts-ignore
              const content = this.managerService.config.RESPONSE_TABLE[response.code];
              this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
              this.managerService.navigateIfLoggedIn({url: '/account/auction', current_component_index: 0});
              this.managerService.marketingService.logEvent('payment_confirmed', {type: data.delivery_type});
            }
          }));
      this.managerService.closeModal('approve-payment-modal');
    }
  }
}
