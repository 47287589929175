<div class="w-100 d-flex flex-row align-items-center justify-content-center exo subtitle2 bold" style="height: 30px;
   line-height: 1.25;  color: #404352; cursor: pointer;">

  <div (click)="managerService.active_order_book_panel=managerService.active_order_book_panel_template.USER_BALANCE_CHART;"
       [style]="managerService.active_order_book_panel !== managerService.active_order_book_panel_template.USER_BALANCE_CHART ? {'box-shadow':  'inset -2px -2px 3px rgba(213, 216, 226, 1)', color: '#9da5b7'} : {}"
       class="h-100 w-50  d-flex flex-column align-items-center justify-content-center text-center">
    <div>
      {{managerService.config.ADVANCED.BALANCE}}
    </div>
    <div class="d-flex flex-row align-items-center justify-content-center">
      <app-tooltip [direction]="managerService.tooltipService.tooltip_direction_template.BOTTOM | managerPipe" class="d-none position-relative" id="balance-tooltip" max_width="125px">
        {{managerService.config.TOOLTIP.ADVANCED.BALANCE_TOOLTIP | managerPipe}}
      </app-tooltip>
    </div>
  </div>

  <div (click)="managerService.active_order_book_panel=managerService.active_order_book_panel_template.ORDER_BOOK"
       [style]="managerService.active_order_book_panel !== managerService.active_order_book_panel_template.ORDER_BOOK ? {'box-shadow':'inset +2px -2px 3px rgba(213, 216, 226, 1)', color: '#9da5b7'} : {}"
       class="h-100 w-50  d-flex flex-column align-items-center justify-content-center text-center">
    <div>
      {{managerService.config.ADVANCED.ORDER_BOOK.HEADER}}
    </div>
    <div class="d-flex flex-row align-items-center justify-content-center">
      <app-tooltip [direction]="managerService.tooltipService.tooltip_direction_template.BOTTOM | managerPipe" class="d-none position-relative" id="order-book-header-tooltip" max_width="125px">
        {{managerService.config.TOOLTIP.ADVANCED.ORDER_BOOK_HEADER_TOOLTIP | managerPipe}}
      </app-tooltip>
    </div>
  </div>

</div>
<app-order-book-desktop [class.d-none]="managerService.active_order_book_panel === managerService.active_order_book_panel_template.USER_BALANCE_CHART"></app-order-book-desktop>
<app-user-balance-desktop [class.d-none]="managerService.active_order_book_panel === managerService.active_order_book_panel_template.ORDER_BOOK"></app-user-balance-desktop>
