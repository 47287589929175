<div [style]="managerService.show_sign_up_or_login | managerPipe: managerService.mpa_template.GET_MIN_BODY_HEIGHT_OBSOLETE">
    <app-auction-catalog-type-desktop *ngIf="managerService.navigationService.current_component_index === 0 | managerPipe"></app-auction-catalog-type-desktop>

    <app-auction-catalog-list-desktop *ngIf="managerService.navigationService.current_component_index === 1 | managerPipe"></app-auction-catalog-list-desktop>

    <app-auction-catalog-desktop *ngIf="managerService.navigationService.current_component_index === 2 | managerPipe"></app-auction-catalog-desktop>

    <div *ngIf="managerService.navigationService.current_component_index === 3 | managerPipe">
        <div class="w-100 d-flex flex-row justify-content-center" style="padding: 48px 120px 64px 120px; gap: 120px">
            <app-auction-art-product-desktop></app-auction-art-product-desktop>
            <app-auction-bid-desktop></app-auction-bid-desktop>
        </div>
        <div class="d-flex justify-content-center" style="padding: 48px 120px 64px 120px;">
            <app-auction-artist-bid-history-desktop></app-auction-artist-bid-history-desktop>
        </div>
    </div>
</div>
