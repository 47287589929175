<div *ngIf="managerService.userService.user_ready | managerPipe" class="w-100 d-flex align-items-center overflow-auto">
  <div *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
       [style.width]="(managerService.inner_width >= 1200 | managerPipe) ? '100%':'80%'" class="grid">
    <app-navigation-side-bar></app-navigation-side-bar>
    <div class="router-outlet">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE" class="w-100">
    <router-outlet></router-outlet>
  </div>
</div>
