<div class="w-100 mx-auto px-2 position-relative" style="box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);  background-color: #ffffff;">
  <div class="pt-4 pb-2 pl-2" style="font-family: Roboto, sans-serif; font-size: 14px; letter-spacing: 0.7px; color: #272a3b;">
    {{managerService.config.SERVICE.HEADER.HEADING_MOBILE | managerPipe}}
  </div>
  <div class="w-100 d-flex flex-row">
    <div (click)="managerService.navigationManager(managerService.config.ZENDESK_ACTIVE
    ? {url: 'https://support.artiox.com/hc/tr/articles/4414505009681-Limitler-ve-Kurallar', is_inner: false, target: '_blank'}
    : {url: '/service/rules', current_component_index: 0})"
         [style.border-bottom]="(managerService.navigationService.active_service_page === managerService.navigationService.service_page_template.RULES ? 'solid 1px #4fc0d2' : '') | managerPipe"
         [style.color]="(managerService.navigationService.active_service_page === managerService.navigationService.service_page_template.RULES ? 'var(--artiox-blue)' : '#9da5b7') | managerPipe"
         class="btn-native px-2 mr-4"
         style="font-family: Roboto, sans-serif; font-size: 13px; cursor: pointer">
      {{managerService.config.SERVICE.HEADER.RULES | managerPipe}}
    </div>
    <div (click)="managerService.navigationManager(managerService.config.ZENDESK_ACTIVE
    ? {url: 'https://support.artiox.com/hc/tr/articles/4414483870865-Komisyonlar', is_inner: false, target: '_blank'}
    : {url: '/service/commissions', current_component_index: 0})"
         [style.border-bottom]="(managerService.navigationService.active_service_page === managerService.navigationService.service_page_template.COMMISSIONS ? 'solid 1px #4fc0d2' : '') | managerPipe"
         [style.color]="(managerService.navigationService.active_service_page === managerService.navigationService.service_page_template.COMMISSIONS ? 'var(--artiox-blue)' : '#9da5b7') | managerPipe"
         class="btn-native px-2 mx-4"
         style="font-family: Roboto, sans-serif; font-size: 13px; cursor: pointer">
      {{managerService.config.SERVICE.HEADER.COMMISSIONS | managerPipe}}
    </div>
  </div>
</div>
