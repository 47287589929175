import {Component, Input, OnInit} from '@angular/core';
import {ManagerService} from "../../../../../services/manager.service";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-auction-product-information-mobile',
  templateUrl: './auction-product-information-mobile.component.html',
  styleUrls: ['./auction-product-information-mobile.component.css']
})
export class AuctionProductInformationMobileComponent implements OnInit {

  @Input() modal_id: string = '';

  constructor(public managerService: ManagerService, private httpClient: HttpClient) {
  }

  ngOnInit(): void {
  }

}
