<h1 class="btn-native w-100 m-auto pl-4 py-3 font-weight-bold"
    style="font-family: Roboto, sans-serif; font-size: 14px; letter-spacing: 0.4px; color: #d9dbe4;">
  {{managerService.config.ADVANCED.USER_OPEN_ORDERS.HEADING | managerPipe}}
</h1>
<div class="d-flex flex-row align-items-center justify-content-around py-1"
     style="font-family: Roboto, sans-serif; font-size: 10px; color: #d9dbe4;">
  <span class="pl-4 text-left"
        style="width: 25%">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.PAIR | managerPipe}}</span>
  <span class="text-center"
        style="width: 15%">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.TYPE | managerPipe}}</span>
  <span class="text-center"
        style="width: 15%">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.PRICE | managerPipe}}</span>
  <span class="text-center"
        style="width: 15%">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.AMOUNT | managerPipe}}</span>
  <span class="text-center"
        style="width: 15%">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.CANCEL | managerPipe}}</span>
  <span class="pr-4 text-right" style="width: 15%"></span>
</div>
<div class="d-flex flex-column overflow-auto mb-3" style="min-height: 160px; max-height: 160px;">
  <div
    *ngFor="let user_open_order of (managerService.user_open_orders_history_pipe | managerPipe: managerService.mpa_template.GET_USER_OPEN_ORDERS)"
    class="d-flex flex-row align-items-center justify-content-around flex-wrap py-1 user-open-order flex-shrink-0"
    style="font-family: Roboto, sans-serif; font-size: 10px; color: #d9dbe4; line-height: 1.2;">
    <span class="pl-4 text-left"
          style="width: 25%">{{user_open_order.pair_id | managerPipe: managerService.mpa_template.PAIR_SYMBOL: managerService.pair_pipe}}</span>
    <span [style]="user_open_order | managerPipe: managerService.mpa_template.GET_USER_OPEN_ORDERS_BACKGROUND_COLOR"
          class="text-center"
          style="width: 15%">{{user_open_order.type | managerPipe: managerService.mpa_template.USER_OPEN_ORDER_TYPE}}</span>
    <span class="text-center"
          style="width: 15%">{{user_open_order.price | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
    <span class="text-center"
          style="width: 15%">{{user_open_order.amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
    <span class="text-center" style="width: 15%">
      <svg (click)="managerService.cancelUserOrder(user_open_order);" style="height: 20px; width: 20px;">
        <use [attr.xlink:href]='"./assets/images/close.svg#close"' fill="#4fc0d2"/>
      </svg>
    </span>
    <span (click)="more_div.classList.toggle('d-none'); more_div.classList.toggle('d-flex')" class="pr-4 text-right"
          style="width: 15%">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.MORE | managerPipe}}</span>
    <div #more_div class="w-100 d-none flex-row flex-wrap align-items-center justify-content-around py-1"
         style="font-family: Roboto, sans-serif; font-size: 10px; color: #d9dbe4; line-height: 1.2;">
      <span class="w-50 pl-4 my-1 text-left">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.TIME | managerPipe}}</span>
      <span class="w-50 pr-4 my-1 text-right">{{user_open_order.creation_time | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</span>
      <span class="w-50 pl-4 my-1 text-left">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.FILLED_PERCENTAGE | managerPipe}}</span>
      <span class="w-50 pr-4 my-1 text-right">{{(1 - user_open_order.amount / user_open_order.init_amount) * 100 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}%</span>
      <span class="w-50 pl-4 my-1 text-left">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.FILLED_AMOUNT | managerPipe}}</span>
      <span class="w-50 pr-4 my-1 text-right">{{user_open_order.init_amount - user_open_order.amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
      <span class="w-50 pl-4 my-1 text-left">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.FILLED_VOLUME | managerPipe}}</span>
      <span class="w-50 pr-4 my-1 text-right">{{user_open_order.price * (user_open_order.init_amount - user_open_order.amount) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
      <span class="w-50 pl-4 my-1 text-left">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.TOTAL_AMOUNT | managerPipe}}</span>
      <span class="w-50 pr-4 my-1 text-right">{{user_open_order.init_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
      <span class="w-50 pl-4 my-1 text-left">{{managerService.config.ADVANCED.USER_OPEN_ORDERS.TOTAL_VOLUME | managerPipe}}</span>
      <span class="w-50 pr-4 my-1 text-right">{{user_open_order.price * user_open_order.init_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
    </div>
  </div>
</div>
