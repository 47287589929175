import {AfterContentInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ManagerService} from '../../../../services/manager.service';
import {NavigationEnd} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-navigation-side-bar',
  templateUrl: './navigation-side-bar.component.html',
  styleUrls: ['./navigation-side-bar.component.css']
})
export class NavigationSideBarComponent implements OnInit, AfterContentInit, OnDestroy {

  public subscription_list: Subscription[] = [];

  constructor(public managerService: ManagerService) {
    this.subscription_list.push(this.managerService.router.events.subscribe((_event) => {
        if (_event instanceof NavigationEnd) {
          this.checkActiveNavigationBarPanel();
        }
      })
    );
  }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
    this.checkActiveNavigationBarPanel();
  }

  checkActiveNavigationBarPanel(): void {
    if (this.managerService.checkURLPrefix('/account/dashboard')) {
      this.managerService.active_navigation_bar_panel = this.managerService.active_navigation_bar_panel_template.DASHBOARD;
    } else if (this.managerService.checkURLPrefix('/account/user-info')) {
      this.managerService.active_navigation_bar_panel = this.managerService.active_navigation_bar_panel_template.USER_INFORMATION;
    } else if (this.managerService.checkURLPrefix('/account/balance')) {
      this.managerService.active_navigation_bar_panel = this.managerService.active_navigation_bar_panel_template.BALANCE;
    } else if (this.managerService.checkURLPrefix('/account/deposit')) {
      this.managerService.active_navigation_bar_panel = this.managerService.active_navigation_bar_panel_template.DEPOSIT;
    } else if (this.managerService.checkURLPrefix('/account/withdrawal')) {
      this.managerService.active_navigation_bar_panel = this.managerService.active_navigation_bar_panel_template.WITHDRAWAL;
    } else if (this.managerService.checkURLPrefix('/account/auction')) {
      this.managerService.active_navigation_bar_panel = this.managerService.active_navigation_bar_panel_template.AUCTION;
    } else if (this.managerService.checkURLPrefix('/account/security')) {
      this.managerService.active_navigation_bar_panel = this.managerService.active_navigation_bar_panel_template.SECURITY;
    }
  }

  ngOnDestroy(): void {
    this.subscription_list.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }


}
