import {EventEmitter, Injectable, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

export enum DepositPageTemplate {
    TURKISH_LIRA,
    TETHER,
    ETHEREUM,
}

export enum WithdrawalPageTemplate {
    TURKISH_LIRA,
    TETHER,
    ETHEREUM,
}

export enum CorporatePageTemplate {
    ABOUT,
    ANNOUNCEMENT,
    CAREER,
    CONTACT
}

export enum ServicePageTemplate {
    RULES,
    COMMISSIONS
}

export enum GuidebookPageTemplate {
    GUIDEBOOK,
    LEGAL,
    CAREER,
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    public raw_current_routing: any = '{}';
    public current_routing: any = {};
    public navigation_history: any[] = [];
    public prevent_navigation_history_push: boolean = false;
    public requested_routing: any = {};

    @Output() navigation_requested: EventEmitter<any> = new EventEmitter();

    public current_component_index: number = 0;
    @Output() current_component_index_changed: EventEmitter<any> = new EventEmitter();

    // exchange specific
    public scroll_top: number = 0;

    // announcement specific
    public artist_announcement_active: boolean = false;
    public artist_announcement_active_index: number = -1;
    public artist_announcement_gallery_active: boolean = false;
    public operation_announcement_active: boolean = false;
    public operation_announcement_active_index: number = -1;
    public corporate_page_template = CorporatePageTemplate;
    public active_corporate_page: CorporatePageTemplate = this.corporate_page_template.ABOUT;
    public service_page_template = ServicePageTemplate;
    public active_service_page: ServicePageTemplate = this.service_page_template.RULES;
    public deposit_page_template = DepositPageTemplate;
    public active_deposit_page: DepositPageTemplate = this.deposit_page_template.TURKISH_LIRA;
    public withdrawal_page_template = WithdrawalPageTemplate;
    public active_withdrawal_page: WithdrawalPageTemplate = this.withdrawal_page_template.TURKISH_LIRA;

    @Output() operation_announcement_index_changed: EventEmitter<number> = new EventEmitter();
    @Output() operation_artist_index_changed: EventEmitter<number> = new EventEmitter();

    constructor(private router: Router, private location: Location) {
    }

    navigate(object: any = {}): void {
        const prev_current_routing = this.current_routing;
        if (!this.prevent_navigation_history_push) {
            this.navigation_history.push(prev_current_routing);
        }
        this.prevent_navigation_history_push = false;
        this.current_routing = object;

        if (this.current_routing.pair) {
            if (this.current_routing.url.startsWith(prev_current_routing.url)) {
                this.location.replaceState(this.current_routing.url + this.current_routing.pair);
            } else {
                this.router.navigateByUrl(this.current_routing.url + this.current_routing.pair).then(() => {
                });
            }
        } else if (this.current_routing.auction_catalog) {
            if (this.current_routing.url.startsWith(prev_current_routing.url)) {
                this.location.replaceState(this.current_routing.url + this.current_routing.auction_catalog);
            } else {
                this.router.navigateByUrl(this.current_routing.url + this.current_routing.auction_catalog).then(() => {
                });
            }
        } else if (this.current_routing.auction_product) {
            if (this.current_routing.url.startsWith(prev_current_routing.url)) {
                this.location.replaceState(this.current_routing.url + this.current_routing.auction_product);
            } else {
                this.router.navigateByUrl(this.current_routing.url + this.current_routing.auction_product).then(() => {
                });
            }
        } else {
            this.router.navigateByUrl(this.current_routing.url).then(() => {
                this.location.replaceState(this.current_routing.url);
            });
        }
        this.changeCurrentComponentIndex(this.current_routing.current_component_index);
        window.scrollTo(0, 0);
        this.requested_routing = {};
    }

    // tools
    changeCurrentComponentIndex(index: number): void {
        if (index !== this.current_component_index) {
            this.current_component_index = index;
            this.current_component_index_changed.emit();
        }
    }

    navigateNewPage(is_app: boolean, object: any): void {
        if (!is_app) {
            window.open(object.url, object.target);
        } else {
            if (object.is_inner) {
                this.navigate(object);
            } else {
                window.open(object.url, object.target);
            }
        }
    }

    reverseNavigation(): void {
        const object = this.navigation_history.pop();
        if (object) {
            this.prevent_navigation_history_push = true;
            this.navigation_requested.emit(object);
        }
    }

    clearNavigationHistory(): void {
        this.navigation_history = [];
        this.prevent_navigation_history_push = false;
    }

    // announcement
    setActiveArtist(index: number): void {
        this.artist_announcement_active_index = index;
        this.artist_announcement_gallery_active = false;
        window.scrollTo(0, 0);
    }

    resetActiveArtist(): void {
        this.artist_announcement_active_index = -1;
        this.artist_announcement_gallery_active = false;
        window.scrollTo(0, 0);
    }

    setActiveOperation(index: number): void {
        this.operation_announcement_active = true;
        this.operation_announcement_active_index = index;
        this.operation_announcement_index_changed.emit(index);
        window.scrollTo(0, 0);
    }

    resetActiveOperation(): void {
        this.operation_announcement_active = false;
        this.operation_announcement_active_index = -1;
        window.scrollTo(0, 0);
    }

    seeArtworks(): void {
        this.artist_announcement_gallery_active = true;
        window.scrollTo(0, 0);
    }

    backToArtworks(): void {
        this.artist_announcement_gallery_active = false;
        window.scrollTo(0, 0);
    }

    resetAnnouncements(): void {
        this.artist_announcement_active = false;
        this.artist_announcement_active_index = -1;
        this.artist_announcement_gallery_active = false;

        this.operation_announcement_active = false;
        this.operation_announcement_active_index = -1;
    }

}
