<div class="vw-100 min-vw-100 d-flex flex-column align-items-center justify-content-center">
  <div class="w-100 d-flex flex-row align-items-center justify-content-around mx-5">
    <div>
      <button (click)="trade_chart_dropdown_menu_minutes.classList.remove('d-none');
      trade_chart_dropdown_menu_hours.classList.add('d-none');"
              [class.trade-chart-dropdown-button-active]="['OneMin', 'FiveMins', 'FifteenMins', 'ThirtyMins'].includes(managerService.tradeHistoryService.zoom_level)"
              class="trade-chart-dropdown-button btn-native">
        {{managerService.config.ADVANCED.TRADE_CHART | managerPipe: managerService.mpa_template.RETURN_INDEX: min_selected}}
      </button>
      <div #trade_chart_dropdown_menu_minutes class="trade-chart-dropdown-menu d-none">
        <button (click)="adjustZoomLevel('OneMin'); min_selected='ONE_MIN'; trade_chart_dropdown_menu_minutes.classList.add('d-none');" class="trade-chart-dropdown-item btn-native">
          {{managerService.config.ADVANCED.TRADE_CHART.ONE_MIN | managerPipe}}
        </button>
        <button (click)="adjustZoomLevel('FiveMins'); min_selected='FIVE_MINS'; trade_chart_dropdown_menu_minutes.classList.add('d-none');" class="trade-chart-dropdown-item btn-native">
          {{managerService.config.ADVANCED.TRADE_CHART.FIVE_MINS | managerPipe}}
        </button>
        <button (click)="adjustZoomLevel('FifteenMins'); min_selected='FIFTEEN_MINS'; trade_chart_dropdown_menu_minutes.classList.add('d-none');" class="trade-chart-dropdown-item btn-native">
          {{managerService.config.ADVANCED.TRADE_CHART.FIFTEEN_MINS | managerPipe}}
        </button>
        <button (click)="adjustZoomLevel('ThirtyMins'); min_selected='THIRTY_MINS'; trade_chart_dropdown_menu_minutes.classList.add('d-none');" class="trade-chart-dropdown-item btn-native">
          {{managerService.config.ADVANCED.TRADE_CHART.THIRTY_MINS | managerPipe}}
        </button>
      </div>
    </div>
    <div>
      <button
        (click)="trade_chart_dropdown_menu_minutes.classList.add('d-none');
        trade_chart_dropdown_menu_hours.classList.remove('d-none');"
        [class.trade-chart-dropdown-button-active]="['OneHour', 'FourHours', 'TwelveHours'].includes(managerService.tradeHistoryService.zoom_level)"
        class="trade-chart-dropdown-button btn-native">
        {{managerService.config.ADVANCED.TRADE_CHART | managerPipe: managerService.mpa_template.RETURN_INDEX: hour_selected}}
      </button>
      <div #trade_chart_dropdown_menu_hours class="trade-chart-dropdown-menu d-none">
        <button (click)="adjustZoomLevel('OneHour'); hour_selected='ONE_HOUR'; trade_chart_dropdown_menu_hours.classList.add('d-none');" class="trade-chart-dropdown-item btn-native">
          {{managerService.config.ADVANCED.TRADE_CHART.ONE_HOUR | managerPipe}}
        </button>
        <button (click)="adjustZoomLevel('FourHours'); hour_selected='FOUR_HOURS'; trade_chart_dropdown_menu_hours.classList.add('d-none');" class="trade-chart-dropdown-item btn-native">
          {{managerService.config.ADVANCED.TRADE_CHART.FOUR_HOURS | managerPipe}}
        </button>
        <button (click)="adjustZoomLevel('TwelveHours'); hour_selected='TWELVE_HOURS'; trade_chart_dropdown_menu_hours.classList.add('d-none');" class="trade-chart-dropdown-item btn-native">
          {{managerService.config.ADVANCED.TRADE_CHART.TWELVE_HOURS | managerPipe}}
        </button>
      </div>
    </div>
    <button (click)="adjustZoomLevel('OneDay');
    trade_chart_dropdown_menu_minutes.classList.add('d-none');
    trade_chart_dropdown_menu_hours.classList.add('d-none');"
            [class.trade-chart-dropdown-button-active]="['OneDay'].includes(managerService.tradeHistoryService.zoom_level)"
            class="trade-chart-dropdown-button btn-native">
      {{managerService.config.ADVANCED.TRADE_CHART.DAY | managerPipe}}
    </button>
    <button (click)="adjustZoomLevel('SevenDays');
      trade_chart_dropdown_menu_minutes.classList.add('d-none');
      trade_chart_dropdown_menu_hours.classList.add('d-none');"
            [class.trade-chart-dropdown-button-active]="['SevenDays'].includes(managerService.tradeHistoryService.zoom_level)"
            class="trade-chart-dropdown-button btn-native">
      {{managerService.config.ADVANCED.TRADE_CHART.WEEK | managerPipe}}
    </button>
    <button (click)="adjustZoomLevel('ThirtyDays');
      trade_chart_dropdown_menu_minutes.classList.add('d-none');
      trade_chart_dropdown_menu_hours.classList.add('d-none');"
            [class.trade-chart-dropdown-button-active]="['ThirtyDays'].includes(managerService.tradeHistoryService.zoom_level)"
            class="trade-chart-dropdown-button btn-native">
      {{managerService.config.ADVANCED.TRADE_CHART.MONTHLY | managerPipe}}
    </button>
    <svg (click)="resolveChart('Line')"
         [class.d-none]="trade_chart_type === 'Line'"
         [style]="active_chart_type === 'TradeChart' ? {border: 'solid 1px white'} : {border: 'solid 1px #9da5b7'}" class="p-1"
         style="height:25px; width: 30px; border-radius: 4px;">
      <use [attr.xlink:href]='"./assets/images/line_chart.svg#line_chart"' class="w-100" fill="white"/>
    </svg>
    <svg (click)="resolveChart('Candlestick')" [class.d-none]="trade_chart_type === 'Candlestick'"
         [style]="active_chart_type === 'TradeChart' ? {border: 'solid 1px white'} : {border: 'solid 1px #9da5b7'}"
         class="p-1"
         style="height:25px; width: 30px; border-radius: 4px;">
      <use [attr.xlink:href]='"./assets/images/candlestick_chart.svg#candlestick_chart"' class="w-100" fill="white"/>
    </svg>
    <svg (click)="resolveChart(toggleChartType())" [style]="active_chart_type === 'DepthChart' ? {border: 'solid 1px white'} : {border: 'solid 1px #9da5b7'}" class="p-1"
         style="height:25px; width: 30px; border-radius: 4px;">
      <use [attr.xlink:href]='"./assets/images/depth_chart.svg#depth_chart"' class="w-100" fill="white"/>
    </svg>
  </div>
  <div #trade_chart_container [class]="active_chart_type === 'DepthChart' ? 'd-none':'d-flex'"
       class="h-100 w-100 align-items-center justify-content-end position-relative">
    <div class="position-absolute" style="left: 25px; bottom: 30px; color: whitesmoke; font-size: 14px; z-index: 100;">artiox.com</div>
    <div #trade_chart_container_overlay
         class="position-absolute d-flex flex-column align-items-center justify-content-center">
      <svg class="invisible" style="height:40px; width:40px; margin-right: 27px; margin-bottom: 15px;">
        <use [attr.xlink:href]='"./assets/images/artiox_icon.svg#artiox_icon"' class="h-100 w-100" fill="white"/>
      </svg>
    </div>
  </div>
  <div #depth_chart_container [class]="active_chart_type === 'TradeChart'? 'd-none':'d-flex'" class="h-100 w-100 align-items-center justify-content-center">
    <div #depth_chart_container_overlay
         class="position-absolute d-flex flex-column align-items-center justify-content-center">
      <svg class="d-none" style="height:40px; width:40px; margin-right: 27px; margin-bottom: 15px;">
        <use [attr.xlink:href]='"./assets/images/artiox_icon.svg#artiox_icon"' class="h-100 w-100" fill="white"/>
      </svg>
    </div>
  </div>
</div>
