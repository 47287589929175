import {announcements_titles} from './announcements_router';

export const config_default = {
    ZENDESK_ACTIVE: announcements_titles.zendesk_active,
    COOKIE: {
        HEADER: '',
        BODY: '',
        BODY_SHORT: '',
        ACCEPT: '',
        DENY: ''
    },
    HEADER: {
        SIGNUP: '',
        LOGIN: '',
        LOGIN_SHORT: '',
        EXCHANGE: '',
        BASIC: '',
        ADVANCED: '',
        BASIC_TEXT: '',
        ADVANCED_TEXT: '',
        ACADEMY: '',
        AUCTION: '',
        CATALOG: '',
        DAILY: '',
        CATALOG_TEXT: '',
        DAILY_TEXT: '',
        MENU: '',
        ADMIN: '',
        DASHBOARD: '',
        USER_INFORMATION: '',
        USER_INFORMATION_SHORT: '',
        WALLET: '',
        DEPOSIT: '',
        WITHDRAW: '',
        SECURITY: '',
        ARTIOX: '',
        ARTIOX_LINK: {},
        ANNOUNCEMENTS: '',
        ANNOUNCEMENTS_LINK: {},
        BLOG: '',
        BLOG_LINK: {},
        ABOUT_ARTISTS: '',
        ABOUT_ARTISTS_LINK: {},
        SUPPORT: '',
        SUPPORT_LINK: {},
        LOGOUT: ' ',
        LANGUAGES: [{SHORT: '', FULL_NAME: ''}],
        ACCOUNT_LEVEL: '',
        GUIDEBOOK: '',
        ANNOUNCEMENT: {
            TEXT: '',
            LINK: {url: ''},
        },
        // CHANGE_LANGUAGE: '',
        // VERSION: '',
        OR: '',
        TO_INSTALL_APP: '',
        LATER: '',
        INSTALL: ''
    },
    FOOTER: {
        // ADDRESS_HEADER: '',
        ADDRESS: '',
        // MAIL: '',
        // ARTIOX: '',
        // ARTIOX_LINK: {url: ''},
        // INFO_BANK: '',
        // INFO_BANK_LINK: {url: ''},
        LEGAL: {
            HEADING: '',
            LINKS : [
                {TEXT: '', URL: {url: ''}},
            ],
        },
        ARTIOX_SECTION: {
            HEADER: '',
            BASIC: '',
            ADVANCED: '',
            CATALOG: '',
            DAILY: '',
            SEND_ARTWORK: '',
        },
        ARTISTS: {
            HEADER: '',
            ALL_ARTISTS: '',
        },
        AUCTION: {
            HEADER: '',
            DISTANCE_SELLING: '',
            AUCTION_CANCELLATION: '',
            AUCTION_USER_AGREEMENT: '',
            AUCTION_RULES: '',
        },
        // LEGAL_LINK: {url: ''},
        // ANNOUNCEMENTS: '',
        // ANNOUNCEMENTS_LINK: {url: ''},
        // ABOUT_ARTISTS: '',
        // ABOUT_ARTISTS_LINK: {url: ''},
        // CAREER: '',
        // CAREER_LINK: {url: ''},
        // CORPORATE_HEADER: '',
        // CORPORATE_BODY: [{TEXT: '', LINK: {url: ''}, HREF: '', INNER: false, TARGET: ''}],
        // LEGAL_HEADER: '',
        // LEGAL_BODY: [''],
        // SERVICE_HEADER: '',
        // SERVICE_BODY: [{TEXT: '', LINK: {url: ''}, HREF: '', INNER: false, TARGET: ''}],
        // SUPPORT_HEADER: '',
        // SUPPORT_BODY: [''],
        RIGHTS: '',
        VERSION: '',
        // RIGHTS_MOBILE: '',
    },
    ANNOUNCEMENTS_SLIDER: [
        {
            HEADING: '',
            TOPIC: '',
            TEXT: '',
            IMAGE_SRC: '',
            TEXT_LIMIT_DESKTOP: 0,
            TEXT_LIMIT_MOBILE: 0,
            LINK: {url: ''},
            RELEASE_DATE: 0,
        },
        {
            HEADING: '',
            TOPIC: '',
            TEXT: '',
            IMAGE_SRC: '',
            TEXT_LIMIT_DESKTOP: 0,
            TEXT_LIMIT_MOBILE: 0,
            LINK: {url: ''}
        },
    ],
    ANNOUNCEMENT_POPUP: {
        HEADING: '',
        BODY: '',
        SRC: '',
        SRC_MP4: ''
    },
    LOGIN: {
        WELCOME_TO_ARTIOX: '',
        SUBTITLE: '',
        LOGIN: '',
        E_MAIL: '',
        E_MAIL_PLACEHOLDER: '',
        PASSWORD: '',
        PASSWORD_PLACEHOLDER: '',
        AUTH_TITLE: '',
        AUTH: '',
        AUTH_PLACEHOLDER: '',
        AUTH_SUBTITLE: '',
        PASTE: '',
        FORGOT_PASSWORD: '',
        SUBMIT: '',
        CAPTCHA_HEADER: '',
        CAPTCHA_BODY: '',
        NO_ACCOUNT: '',
        CREATE_NEW_ACCOUNT: '',
        ART_INVESTMENT: '',
    },
    FORGOT_PASSWORD: {
        HEADING: '',
        INFO: '',
        E_MAIL: '',
        E_MAIL_PLACEHOLDER: '',
        SEND: '',
        SEND_SUCCESFULLY_BEGGINING: '',
        SEND_SUCCESFULLY_END: '',
        RESEND_REQUEST: '',
        SECOND_ABBREVIATION: '',
    },
    SIGN_UP: {
        SIGN_UP: '',
        SIGN_UP_TEXT: '',
        CREATE_ACCOUNT: '',
        E_MAIL: '',
        E_MAIL_PLACEHOLDER: '',
        PASSWORD: '',
        PASSWORD_PLACEHOLDER: '',
        PASSWORD_AGAIN: '',
        PASSWORD_PLACEHOLDER_AGAIN: '',
        KVKK_ACCEPTED: '',
        CONSENT_TEXT_ACCEPTED: '',
        AUCTION_RULES_ACCEPTED: '',
        COMMERCIALS_ACCEPTED: '',
        DATA_PROCESSING_ACCEPTED: '',
        HAVE_ACCOUNT: '',
        LOGIN: '',
        PASSWORD_RULES: '',
        SIGN_UP_LOADER: '',
        SIGN_UP_SUCCESSFUL: '',
        ACTIVATION_MAIL: '',
        ACTIVATION_MAIL_NOT_SENT: '',
        ACTIVATION_MAIL_NOT_SENT_CHECK_LIST: [''],
        CREATE_PASSWORD: '',
        CREATE_PASSWORD_TEXT: '',
        COMPLETE: '',
        SIGN_UP_HEADER: '',
        SIGN_UP_HEADER_TEXT: ''
    },
    ACCOUNT_VERIFICATION: {
        HEADER: '',
        FIRST_INFO: {STRING: '' , TOKENS: ['']},
        SECOND_INFO: '',
        INPUT_HEADER: '',
        INPUT_PLACEHOLDER: '',
        RESEND_REQUEST: '',
        SECOND_ABBREVIATION: '',
        BUTTON_TEXT: '',
        ART_INVESTMENT: ''
    },
    LANDING_PAGE: {
        GREETER: {
            HEADING: '',
            HEADING_INFO: '',
            PRE_SALE: '',
            PUBLIC_SALE: '',
            TRADE: '',
            ADVANTAGES: [{HEADING: '', CONTENT: ['']}],
            INFO: [{HEADING: '', HIGHLIGHT: ''}],
        }
    },
    HOME: {
        SLIDER: {
            PRE_SALE_STARTS_IN: '',
            PRE_SALE_ENDS_IN: '',
            PUBLIC_SALE_STARTS_IN: '',
            PUBLIC_SALE_ENDS_IN: '',
            LISTED: '',
            YEAR: '',
            MONTH: '',
            DAY: '',
            HOUR: '',
            MIN: '',
            SEC: '',
            TOKENMETRICS: {
                HEADING: '',
                SYMBOL: '',
                TOTAL_SUPPLY: '',
                CIRCULATING_SUPPLY: '',
                PRE_SALE_CIRCULATING_SUPPLY: '',
                PRE_SALE_PRICE: '',
                PUBLIC_SALE_CIRCULATING_SUPPLY: '',
                PUBLIC_SALE_PRICE: '',
                PUBLIC_SALE_PRICE_UPPERCASE: ''
            },
            ART_PRODUCT_INFO: {
                HEADING: '',
                LAST_PRICE: '',
                CHANGE_24_HOUR: '',
                VOLUME_24_HOUR: '',
                LOW_24_HOUR: '',
                HIGH_24_HOUR: ''
            }
        },
        PAIR_HEADER: {
            ART_PRODUCT: '',
            LAST_PRICE: '',
            ART_PRODUCT_MARKET_VALUE: '',
            CHANGE_24_HOUR: '',
            MARKET: ''
        },
        SUMMARY: {
            HEADING_MOBILE: '',
            HEADING_DESKTOP: '',
            TOPICS: [{SRC: '', TEXT: ''}]
        }
    },
    APP_ANNOUNCEMENT: {
        DESCRIPTION: '',
        APP_STORE_LINK_DESKTOP: '',
        APP_STORE_LINK_MOBILE: '',
        GOOGLE_PLAY_LINK: '',
        APP_STORE_BADGE: '',
        GOOGLE_PLAY_BADGE: '',
        DESKTOP_IMAGE_THUMBNAIL: '',
        MOBILE_IMAGE_THUMBNAIL: '',
    },
    ART_MARKET: {
        HEADING: '',
        TOPIC: '',
        INFO: '',
        CARDS: [''],
        SOURCE: ['']
    },
    WHAT_IS_ARTIOX: {
        HEADING: '',
        TOPIC: '',
        INFO: '',
        ADVANTAGES: ['']
    },
    HOW_IT_WORKS: {
        HEADING: '',
        TOPICS: [{HEADING: '', INFO: '', SRC: '', SRC_MP4: ''}]
    },
    DASHBOARD: {
        SUCCESS: '',
        SUCCESSFUL_LOGIN: '',
        SUCCESSFUL_OPERATION: '',
        WARNING: '',
        WELCOME: '',
        LAST_ACTIVITIES: '',
        PORTFOLIO: '',
        TOTAL_BALANCE: '',
        TOTAL_BALANCE_HISTORY: '',
        DAILY_LIMIT: '',
        MONTHLY_LIMIT: '',
        LIMITS: '',
        LIMITS_MOBILE: '',
        DAILY_REMAINING: '',
        DAILY: '',
        MONTHLY_REMAINING: '',
        MONTHLY: '',
        DEPOSIT: '',
        WITHDRAWAL: '',
        BUY: '',
        SELL: '',
        LEVEL: '',
        LEVEL_UP: '',
        LEVEL_UP_MOBILE: '',
        HIGHEST_LEVEL: '',
    },
    USER_INFORMATION: {
        INVALID_PHONE: '',
        INVALID_ADDRESS: '',
        INVALID_CITY: '',
        NO_KYC_HEADING: '',
        NO_KYC_DESCRIPTION: '',
        HEADING: '',
        ACCOUNT_INFORMATION: '',
        PROFILE: '',
        AUCTION_ADDRESS: '',
        MY_ADDRESSESS: '',
        DELIVERY_ADDRESS: '',
        BILLING_ADDRESS: '',
        ADD_ADDRESS: '',
        EDIT_ADDRESS: '',
        DELETE_ADDRESS: '',
        HOME: '',
        WORK: '',
        OTHER: '',
        NAME: '',
        SURNAME: '',
        TC_NUMBER: '',
        CITY: '',
        ADDRESS: '',
        EMAIL: '',
        PHONE_NUMBER: '',
        DISCLAIMER: '',
        CANCEL: '',
        CHANGE: '',
        APPROVE: ''
    },
    BILLING_ADDRESS: {
        HEADER: '',
        MODAL_INFO: '',
        INDIVIDUAL_OPTION: '',
        CORPORATE_OPTION: '',
        INDIVIDUAL_HEADERS: {
            ADDRESS_HEADER: '',
            NAME: '',
            SURNAME: '',
            ID_NUMBER: '',
            PHONE_NUMBER: '',
            PROVINCE: '',
            DISTRICT: '',
            ADDRESS: '',
        },
        CORPORATE_HEADERS: {
            ADDRESS_HEADER: '',
            CORPORATE_NAME: '',
            TAX_ADMINISTRATION: '',
            TAX_NUMBER: '',
            PHONE_NUMBER: '',
            PROVINCE: '',
            DISTRICT: '',
            ADDRESS: '',
            E_INVOICE: '',
        }

    },
    ACCOUNT_AUCTION: {
        HEADING: '',
        MY_BIDS: '',
        MY_FOLLOWINGS: '',
        MY_WONS: '',
        PRODUCT_DETAIL: '',
        MAKE_PAYMENT: '',
        PAYMENT_DETAIL: '',
        GIVEN_BID: '',
        CURRENT_BID: '',
        TOTAL_PRICE: '',
        BUYER_COST: '',
        NO_USER_WON_HEADING: '',
        NO_USER_WON_DESCRIPTION: '',
        NO_USER_FOLLOWING_HEADING: '',
        NO_USER_FOLLOWING_DESCRIPTION: '',
        NO_USER_BID_HEADING: '',
        NO_USER_BID_DESCRIPTION: '',
        SEE_ARTWORKS: '',
    },
    SUPPORT: {
        SUPPORT_HEADER: '',
        QUESTIONS_HEADER: '',
        QUESTIONS: [{TITLE: '', BODY: ''}],
        CATEGORIES: [
            {
                HEADER: '',
                BODY: '',
                SVG_PATH: '',
                PATH: '',
                QUESTIONS: [{TITLE: '', BODY: ''}]
            }

        ],
        ALTERNATIVE_HEADER: '',
        ALTERNATIVE_SUPPORT: [
            {
                HEADER: '',
                BODY: '',
                SVG_PATH: '',
            }
        ]

    },
    PAYMENT: {
        HEADER: '',
        MANDATORY_FIELDS: '',
        EDIT: '',
        DELETE: '',
        DELIVERY_OPTIONS: {
            HEADER: '',
            HEADER_WITHOUT_ASTERIX: '',
            INFORMATION: '',
            SHIPPING: '',
            SHIPPING_TEXT: '',
            PICK_UP: '',
            PICK_UP_TEXT: ''
        },
        DELIVERY_ADDRESS: {
            HEADER: '',
            HEADER_RAW: '',
            TEXT: '',
            PICK_UP_HEADER: '',
            PICK_UP_ADDRESS: ''
        },
        BILLING_ADDRESS: {
            HEADER: '',
            HEADER_RAW: '',
            TEXT: ''
        },
        PAYMENT_INFO: {
            HEADER: '',
            MONEY_ORDER: '',
            CREDIT_CARD: '',
            BANK_NAME: '',
            ACCOUNT_TITLE: '',
            IBAN: '',
            MESSAGE: '',
            INFO: '',
            COMPLETE: '',
            ACCOUNT_TITLE_TEXT: '',
            IBAN_NO: '',
        },
        PAYMENT_STATUS: {
            HEADER: '',
            STATUS: {
                PENDING: {
                    HEADER: '',
                    TEXT: '',
                    IMAGE: '',
                },
                CONFIRMING: {
                    HEADER: '',
                    TEXT: '',
                    IMAGE: '',
                },
                APPROVED: {
                    HEADER: '',
                    TEXT: '',
                    IMAGE: '',
                },
                PACKED: {
                    HEADER: '',
                    TEXT: '',
                    IMAGE: '',
                },
                DELIVERED: {
                    HEADER: '',
                    TEXT: '',
                    IMAGE: '',
                },
            }
        }
    },
    USER_AGREEMENT: {
        HEADING: '',
        TEXT: '',
        LINK: '',
        AGREEMENT: '',
        ACCEPT: '',
        REJECT: '',
    },
    CITIES: {},
    BALANCE: {
        HEADING: '',
        SUMMARY: '',
        SUMMARY_MOBILE: '',
        USER_TRADE_HISTORY: '',
        USER_TRADE_HISTORY_UPPERCASE: '',
        USER_TRADE_HISTORY_MOBILE: '',
        USER_OPERATION_HISTORY: '',
        USER_OPERATION_HISTORY_UPPERCASE: '',
        USER_OPERATION_HISTORY_MOBILE: '',
        TOTAL_BALANCE: '',
        TL_BALANCE: '',
        CRYPTO_BALANCE: '',
        ETHEREUM_BALANCE: '',
        ART_PRODUCTS: '',
        TL: '',
        ART_PRODUCT: '',
        PORTFOLIO: '',
        PORTFOLIO_UPPERCASE: '',
        SYMBOL: '',
        AVAILABLE_AMOUNT: '',
        IN_ORDER: '',
        TOTAL_AMOUNT: '',
        VALUE: '',
        NO_TOKEN: '',
        TRADE: '',
        DEPOSIT: '',
        WITHDRAW: '',
        PAYING: '',
        WITHDRAWAL: '',
        USER_OPEN_ORDERS: '',
        USER_OPEN_ORDERS_UPPERCASE: '',
        ORDER_TYPE: '',
        OPERATION_TYPE: '',
        OPERATION_TYPE_MOBILE: '',
        PENDING: '',
        ONGOING: '',
        QUEUED: '',
        WAITING: '',
        EXECUTING: '',
        COMPLETED: '',
        EXPIRED: '',
        ERROR: '',
        AMOUNT: '',
        KIND: '',
        PAIR: '',
        ALL: '',
        FILTER: '',
        BUY: '',
        SELL: '',
        DATE: '',
        STATUS: '',
        EXPLANATION: '',
        READ_ACCEPTED: '',
        NEXT: '',
        INSPECT: '',
    },
    SECURITY: {
        HEADING: '',
        HEADING_MOBILE: '',
        CHANGE_PASSWORD: {
            HEADING: '',
            HEADING_MOBILE: '',
            FORM_HEADING: '',
            FORM_HEADING_MOBILE: '',
            CURRENT_PASSWORD: '',
            NEW_PASSWORD: '',
            NEW_PASSWORD_AGAIN: '',
            PASSWORD_RULES: '',
            SAVE_CHANGES: ''
        },
        TWO_FACTOR_AUTHENTICATOR: {
            HEADING: '',
            HEADING_REMOVE: '',
            HEADING_MOBILE: '',
            FORM_HEADING: '',
            FORM_HEADING_MOBILE: '',
            DOWNLOAD_2FA_APP: '',
            INSERT_PASSWORD: '',
            INSERT_AUTH_PASSWORD: '',
            QR_CODE: '',
            SECRET_KEY: '',
            AUTHENTICATION_WARNING: '',
            CURRENT_PASSWORD: '',
            APPLY: '',
            AUTH_PASSWORD: '',
            ACTIVE: '',
            INSERT_PASSWORD_TO_REMOVE: '',
            INSERT_AUTH_PASSWORD_TO_REMOVE: '',
            COMPLETE: '',
            REMOVE: '',
        },
        IDENTITY_VERIFICATION: {
            HEADING: '',
            HEADING_MOBILE: '',
            LIMIT_INFO: '',  // obsolete
            LIMITS: {
                DEPOSIT: '',
                WITHDRAW: '',
                LEVEL: ' ',
                DAY: '',
                MONTH: '',
                TL: {
                    HEADING: '',
                    LEVEL_0_DESCRIPTION: '',
                    LEVEL_1_DAILY_LIMIT: '',
                    LEVEL_1_MONTHLY_LIMIT: '',
                    LEVEL_2_DAILY_LIMIT: '',
                    LEVEL_2_MONTHLY_LIMIT: '',
                },
                CRYPTO: {
                    HEADING: '',
                    DESCRIPTION: '',
                    LEVEL_2_DAILY_LIMIT: '',
                    LEVEL_2_MONTHLY_LIMIT: '',
                },
                ETHEREUM: {
                    HEADING: '',
                    DESCRIPTION: '',
                    LEVEL_2_DAILY_LIMIT: '',
                    LEVEL_2_MONTHLY_LIMIT: '',
                }
            },
            KYC_LEVEL_ONE: {
                FORM_HEADING: '',
                NAME: '',
                SURNAME: '',
                BIRTH_YEAR: '',
                TC: '',
                TOWN: '',
                ADDRESS: '',
                COMPLETE: ''
            },
            KYC_LEVEL_TWO: {
                FORM_HEADING: '',
                UPLOAD_FILE: '',
                ID_CARD_EXPLANATION: '',
                ID_CARD_RULE: '',
                FACE_EXPLANATION: '',
                FACE_RULE: '',
                SELECT_FILE: '',
                COMPLETE: '',
                WAITING_FOR_APPROVAL: '',
                COMPLETED: ''
            }
        }
    },
    DEPOSIT: {
        NO_KYC_HEADING: '',
        NO_KYC_DESCRIPTION: '',
        LEVEL_2_NEEDED_HEADING: '',
        LEVEL_2_NEEDED_DESCRIPTION: '',
        LEVEL_UP: '',
        HEADING: '',
        BANK: '',
        TETHER: '',
        ETHEREUM: '',
        LIMITS: '',
        RULES: '',
        RULES_TITLE: '',
        TRANSACTION: '',
        PREREQUIREMENTS: '',
        TL_DEPOSIT_HISTORY: '',
        TL_DEPOSIT_REQUIRED: '',
        TL_DEPOSIT_ACCOMPLISHED: '',
        TL_DEPOSIT_HISTORY_INVALID: '',
        TL_DEPOSIT_HISTORY_VALID: '',
        DEPOSIT_NOW: '',
        DAILY_DEPOSIT_LIMIT: '',
        DAILY_DEPOSIT_LIMIT_MOBILE: '',
        MONTHLY_DEPOSIT_LIMIT: '',
        MONTHLY_DEPOSIT_LIMIT_MOBILE: '',
        CRYPTO_DEPOSIT_DESCRIPTION: '',
        DEPOSIT_RULES: [''],
        FIAT_DEPOSIT_RULES: [''],
        CRYPTO_DEPOSIT_PREREQUIREMENTS: [''],
        CRYPTO_DEPOSIT_RULES: [''],
        ETHEREUM_DEPOSIT_RULES: [''],
        ETHEREUM_DEPOSIT_PREREQUIREMENTS: [''],
        BANK_NAME: '',
        ACCOUNT_ADDRESS: '',
        WALLET_OF: '',
        COPY_ADDRESS: '',
        ACCOUNT_NAME: '',
        IBAN: '',
        DEPOSIT_REF_CODE: '',
        OBEY_RULES: '',
        COPIED: '',
        COPY: '',
        DEPOSIT_WARNING: '',
        DEPOSIT_HISTORY: '',
        DEPOSIT_HISTORY_MOBILE: '',
        TL_SUSPENSION: {
            SUSPENDED: false,
            TITLE: '',
            DESCRIPTION: '',
        },
        TETHER_SUSPENSION: {
            SUSPENDED: false,
            TITLE: '',
            DESCRIPTION: '',
        },
    },
    WITHDRAWAL: {
        HEADING: '',
        BANK: '',
        TETHER: '',
        ETHEREUM: '',
        LIMITS: '',
        BANK_ACCOUNT: '',
        ACCOUNT_ADDRESS: '',
        SAVED_BANK_ACCOUNTS_PLACEHOLDER: '',
        SAVED_CRYPTO_ACCOUNTS_PLACEHOLDER: '',
        NO_SAVED_BANK_ACCOUNT: '',
        NO_SAVED_CRYPTO_ACCOUNT: '',
        ADD_NEW_BANK_ACCOUNT: '',
        ADD_NEW_CRYPTO_ACCOUNT: '',
        PLEASE_SELECT_BANK: '',
        PLEASE_SELECT_WALLET: '',
        RULES_TITLE: '',
        TRANSACTION: '',
        TRANSACTION_MOBILE: '',
        DAILY_WITHDRAWAL_LIMIT: '',
        DAILY_WITHDRAWAL_LIMIT_MOBILE: '',
        MONTHLY_WITHDRAWAL_LIMIT: '',
        MONTHLY_WITHDRAWAL_LIMIT_MOBILE: '',
        WITHDRAWAL_RULES: [''],  // obsolete
        FIAT_WITHDRAWAL_RULES: [''],
        CRYPTO_WITHDRAWAL_RULES: [''],
        ETHEREUM_WITHDRAWAL_RULES: [''],
        BANK_NAME: '',
        ACCOUNT_NAME: '',
        IBAN: '',
        OBEY_RULES: '',
        COPIED: '',
        COPY: '',
        WITHDRAWAL_WARNING: '',
        APPROVE_MAIL_WARNING: '',
        WITHDRAWAL_HISTORY: '',
        WITHDRAWAL_HISTORY_MOBILE: '',
        REMOVE_BANK_ACCOUNT: '',
        REMOVE_CRYPTO_ACCOUNT: '',
        REMOVE_BANK_ACCOUNT_MOBILE: '',
        REMOVE_CRYPTO_ACCOUNT_MOBILE: '',
        MINIMUM_WITHDRAWAL_AMOUNT: '',  // obsolete
        MINIMUM_FIAT_WITHDRAWAL_AMOUNT: '',
        MINIMUM_CRYPTO_WITHDRAWAL_AMOUNT: '',
        MINIMUM_ETHEREUM_WITHDRAWAL_AMOUNT: '',
        ADD_BANK_ACCOUNT: {
            HEADING: '',
            IBAN: '',
            IBAN_ERROR_LIST: {
                IBAN_NOT_ALPHA_NUMERIC: '',
                IBAN_COUNTRY_CODE: '',
                IBAN_NOT_TURKEY: '',
                IBAN_CONTROL_CODE: '',
                IBAN_LONGER_THAN: '',
                IBAN_SHORTER_THAN: '',
                IBAN_BANK_CODE: ''
            },
            NAME_SURNAME: '',
            ACCOUNT_NAME: '',
            SAVE_ACCOUNT: '',
            FILL_THE_FORM: ''
        },
        ADD_CRYPTO_ACCOUNT: {
            HEADING: '',
            BLOCK_CHAIN: '',
            ACCOUNT_ADDRESS: '',
            CRYPTO_ACCOUNT_NAME: '',
            SAVE_CRYPTO_ACCOUNT: '',
            SELECT_BLOCK_CHAIN: '',
        },
        AVAILABLE_TRY: '',
        AVAILABLE: '',
        WITHDRAWAL_REQUEST: '',
        WITHDRAWAL_REQUEST_SUM_HEADING: '',
        PLEASE_SELECT_BANK_ACCOUNT: '',
        PLEASE_SELECT_CRYPTO_ACCOUNT: '',
        WITHDRAWAL_AMOUNT: '',
        WITHDRAWAL_FEE: '',
        WITHDRAWAL_SUM: '',
        WITHDRAWAL_ACCOUNT: '',
        WITHDRAWAL_WALLET: '',
        STATUS_ICONS: {
            PENDING: '',
            QUEUED: '',
            WAITING: '',
            EXECUTING: '',
            COMPLETED: '',
            EXPIRED: '',
            ERROR: '',
            CANCELED: '',
            SIGNING: '',
        },
        TL_SUSPENSION: {
            SUSPENDED: false,
            TITLE: '',
            DESCRIPTION: '',
        },
        TETHER_SUSPENSION: {
            SUSPENDED: false,
            TITLE: '',
            DESCRIPTION: '',
        },
    },
    WITHDRAWAL_CONFIRMED: {
        HEADING: '',
        DESCRIPTION: '',
        IMG_SRC: '',
        IMG_ALT: '',
        BACK_TO_HOME: ''
    },
    WITHDRAWAL_ALREADY_CONFIRMED: {
        HEADING: '',
        DESCRIPTION: '',
        IMG_SRC: '',
        IMG_ALT: '',
        BACK_TO_HOME: ''
    },
    WITHDRAWAL_EXPIRED: {
        HEADING: '',
        DESCRIPTION: '',
        IMG_SRC: '',
        IMG_ALT: '',
        BACK_TO_HOME: ''
    },
    CURRENCY_CONVERTER: {
        BUY: '',
        SELL: '',
        LAST_PRICE: '',
        AMOUNT_TO_BUY: {STRING: '', TOKENS: ['']},
        AMOUNT_TO_SELL: {STRING: '', TOKENS: ['']},
        TL_AMOUNT: '',
        SUMMARY_TITLE: '',
        FEE: '',
        NET_AMOUNT: '',
        TOTAL_BUY_PRICE: '',
        TOTAL_SELL_PRICE: '',
    },
    BANK_ACCOUNT_IMG_SRCS: {},
    CRYPTO_TOKEN_IMG_SRCS: {},
    CURRENCY_NAMES: {},
    CRYPTO_ACCOUNT_IMG_SRCS: {},
    SIGN_UP_OR_LOGIN: {
        HEADING_BALANCE: '',
        HEADING_USER_OPERATIONS: '',
        SIGN_UP: '',
        OR: '',
        LOGIN: ''
    },
    FAQ: {
        HEADING: '',
        HEADING_2: '',
        TOPICS: [{HEADING: '', INFO: ''}]
    },
    BASIC: {
        ART_PRODUCTS: '',
        PAIR_HEADER: {
            LAST_PRICE: '',
            ART_PRODUCT_MARKET_VALUE: '',
            VOLUME_24_HOUR_DESKTOP: '',
            CHANGE_24_HOUR_DESKTOP: '',
            VOLUME_24_HOUR_MOBILE: '',
            CHANGE_24_HOUR_MOBILE: ''
        },
        TRADE: {
            AMOUNT_TO_BUY_MOBILE: {STRING: '', TOKENS: ['']},
            AMOUNT_TO_SELL_MOBILE: {STRING: '', TOKENS: ['']},
            AMOUNT_TO_BUY_DESKTOP: {STRING: '', TOKENS: ['']},
            AMOUNT_TO_SELL_DESKTOP: {STRING: '', TOKENS: ['']},
            AVAILABLE_AMOUNT: {STRING: '', TOKENS: ['']},
            AVAILABLE_AMOUNT_ADVANCE: {STRING: '', TOKENS: ['']},
            SWAP: '',
            AVERAGE_COST_MOBILE: {STRING: '', TOKENS: ['']},
            AVERAGE_COST_DESKTOP: {STRING: '', TOKENS: ['']},
            BUY: '',
            SELL: '',
        }
    },
    PRE_SALE: {
        PRE_SALE_TOKEN_LEFT: '',
        PUBLIC_SALE_TOKEN_LEFT: '',
        LOCKED: '',
        PRE_SALE: '',
        PUBLIC_SALE: '',
        PRE_SALE_NOT_STARTED: '',
        BUY: '',
        BUY_SELL: '',
        OVER: '',
        TOKENMETRICS: {
            HEADING: '',
            NAME: '',
            SYMBOL: '',
            TOTAL_SUPPLY: '',
            CIRCULATING_SUPPLY: '',
            PRE_SALE_CIRCULATING_SUPPLY: '',
            PRE_SALE_PRICE: '',
            PUBLIC_SALE_CIRCULATING_SUPPLY: '',
            PUBLIC_SALE_PRICE: '',
            LISTING_DATE: '',
            CONTRACT_ADDRESS: ''
        },
        PUBLIC_SALE_ARTWORKS: '',
    },
    ADVANCED: {
        PAIR: '',
        PRICE: '',
        CHANGE: '',
        LIMIT: '',
        MARKET: '',
        MARKET_ALTER: '',
        LOW_24_HOUR: '',
        HIGH_24_HOUR: '',
        BALANCE: '',
        TRADE: {
            HEADER_BUY: {STRING: '', TOKENS: ['']},
            HEADER_SELL: {STRING: '', TOKENS: ['']},
            PRICE: {STRING: '', TOKENS: ['']},
            AMOUNT: {STRING: '', TOKENS: ['']},
            APPROX_COST: {STRING: '', TOKENS: ['']},
            TOTAL: {STRING: '', TOKENS: ['']},
        },
        USER_BALANCE: {
            AVAILABLE: '',
            IN_ORDER: '',
            TOTAL: '',
            TOTAL_BALANCE: ''
        },
        TRADE_CHART: {
            ONE_MIN: '',
            FIVE_MINS: '',
            FIFTEEN_MINS: '',
            THIRTY_MINS: '',
            ONE_HOUR: '',
            FOUR_HOURS: '',
            TWELVE_HOURS: '',
            DAY: '',
            WEEK: '',
            MONTHLY: '',
            BID: '',
            ASK: '',
            PRICE: ''
        },
        ORDER_BOOK: {
            HEADER: '',
            PRICE: '',
            AMOUNT: '',
            TOTAL: '',
            LAST_PRICE: ''
        },
        TRADE_HISTORY: {
            HEADER: '',
            PRICE: '',
            AMOUNT: '',
            TIME: ''
        },
        USER_OPEN_ORDERS: {
            HEADING: '',
            PAIR: '',
            PRICE: '',
            TYPE: '',
            AMOUNT: '',
            CANCEL: '',
            MORE: '',
            BUY: '',
            SELL: '',
            TIME: '',
            FILLED_AMOUNT: '',
            FILLED_VOLUME: '',
            FILLED_PERCENTAGE: '',
            TOTAL_AMOUNT: '',
            TOTAL_VOLUME: ''
        },
        USER_TRADE_HISTORY: {
            HEADING: '',
            PAIR: '',
            PRICE: '',
            TYPE: '',
            TYPE_MOBILE: '',
            AMOUNT: '',
            MORE: '',
            BUY: '',
            SELL: '',
            TIME: '',
            TOTAL: ''
        }
    },
    AUCTION: {
        AUCTION_STATE_MAP: {
            AUCTION: '',
            LIVE: '',
            KNOCKDOWN: '',
        },
        CATALOG_LOT_IS_YOURS: '',
        LOT_IS_YOURS: '',
        REQUIRED_PARTS: '',
        NO_KYC_DESCRIPTION: '',
        BILLING: {
            SAVE_ADDRESS: '',
            CANCEL: '',
        },
        APPROVE_PAYMENT: {
            HEADER: '',
            BODY: '',
            CANCEL: '',
            APPROVE: '',
        },
        DELETE_ADDRESS: {
            HEADER: '',
            BODY: '',
            CANCEL: '',
            APPROVE: '',
        },
        LIVE_CATALOG: '',
        PAST_CATALOG: '',
        PRODUCT_INFO: '',
        CATALOG_TYPE: {
            CATALOG: '',
            DAILY: '',
            STATUS: {
                LIVE: '',
                UPCOMING: '',
                ENDED: '',
            },
            CATALOG_TEXT: '',
            CATALOG_IMG: '',
            DAILY_TEXT: '',
            DAILY_IMG: '',
        },
        FOLLOW: '',
        SHARE: {
            SHARE: '',
            HEADER: '',
            DESCRIPTION: '',
            OR: '',
            TWITTER: {
                TEXT: '',
                // URL: '',
                HASHTAGS: '',
                VIA: '',
                // RELATED: '',
            },
            LINKEDIN: {
                TITLE: '',
                SUMMARY: '',
                // URL: '',
                // SOURCE: '',
                // MINI: '',
            },
            WHATSAPP: {
                TEXT: '',
            },
        },
        AGREEMENT: {
            TITLE: '',
            SUBTITLE: '',
            RULES: [''],
            CHECKBOX: '',
            ACCEPT: '',
            CANCEL: '',
        },
        BIDS: {STRING: '', TOKENS: ['']},
        MY_BIDS: {
            ONGOING: '',
            EXCEEDED: '',
            WON: ''
        },
        AUCTION_START: '',
        AUCTION_END: '',
        AUCTION_FINALIZED: {
            HEADER: {STRING: '', TOKENS: ['']},
            CLOSE: '',
            COST: '',
            OPEN: {STRING: '', TOKENS: ['']},
        },
        OPENING_PRICE: '',
        CURRENT_PRICE: '',
        BID: '',
        QUICK_BID: '',
        BID_RULES: {
            TITLE: '',
            SUBTITLE: '',
            TEXT: [''],
        },
        DURATION_RULES: {
            TITLE: '',
            SUBTITLE: '',
            TEXT: [''],
        },
        PRICE_ESTIMATION_RULES: {
            TITLE: '',
            TEXT: [''],
        },
        PAST_PRODUCT_INFO: {
            TITLE: '',
            DESCRIPTION: '',
            OPEN: '',
            CLOSE: '',
            TOTAL: '',
        },
        BID_REPORT: {
            LOT_IS_YOURS: {
                TITLE: '',
                TEXT: '',
                BODY: '',
                CANCEL: '',
                BID: '',
            },
            TITLE: '',
            DESCRIPTION: {STRING: '', TOKENS: ['']},
            WARNING: '',
            LEGAL: '',
            BID_PRICE: '',
            COMMISSIONS: {STRING: '', TOKENS: ['']},
            TAX: {STRING: '', TOKENS: ['']},
            TOTAL: '',
            ACCEPT: '',
            REJECT: '',
        },
        ACCOUNT_LEVEL: {
            TITLE: '',
            TEXT: '',
            CANCEL: '',
            LEVELUP: '',
        },
        BID_APPROVED: {
            APPROVED: '',
            SUCCESS: '',
            HISTORY: '',
        },
        END_TEXT: '',
        ARTIST: '',
        LAST_BIDS: {
            TEXT: '',
            BIDDER: '',
            DATE: '',
            PRICE: '',
        },
        FILTER: {
            TITLE: '',
            ARTIST_PRODUCT: '',
            FILTERS: '',
            CLEAR: '',
            APPLY: '',
            NO_RESULT: '',
            PRICE: '',
            KEYWORD: '',
            PRICE_MIN: '',
            PRICE_MAX: '',
        },
        AUCTION_CATALOG_SORT_OPTIONS: {
            OPTIONS: ['']
        },
        NO_LIVE_CATALOG: '',
        NO_PAST_CATALOG: '',
        ARTWORKS: '',
        STATEMENT_HEADER: '',
        AUCTION_PRODUCTS: {},
        AUCTION_CATALOGS: {},
        AUCTION_ARTISTS: {},

    },
    GUIDEBOOK: {
        HEADING: '',
        DESCRIPTION: '',
        HEADER: {
            ARTIOX: '',
            LEGAL: '',
            CAREER: '',
        },
        GUIDEBOOK: [
            {
                HEADING: '',
                LINKS: [
                    {
                        TEXT: '',
                        LINK: {}
                    },
                ]
            }
        ],
        LEGAL: [
            {
                HEADING: '',
                LINKS: [
                    {
                        TEXT: '',
                        LINK: {}
                    },
                ]
            }
        ],
    },
    LEGAL: {
        HEADING: '',
        INSPECT: '',
        // SHOW_AS_PDF: '',
        // TEXTS: [
        //   {
        //     HEADING : '',
        //     SRC: '',
        //     LINK: {},
        //   }
        // ],
        TEXT_NAMES: [
            '',
        ],
    },
    ACCOUNT_LEVEL: {
        HEADING: '',
        DESCRIPTION_INTRO: '',
        DESCRIPTION_LEVEL0: '',
        DESCRIPTION_LEVEL1: '',
        DESCRIPTION_LEVEL2: '',
        LIMIT: '',
        REMAINING_LIMIT: '',
        REMAINING_DAILY_LIMIT: '',
        REMAINING_MONTHLY_LIMIT: '',
        REMAINING_LIMIT_MOBILE: '',
        YOUR_LEVEL_LIMIT: '',
        LEVEL_1_LIMIT: '',
        LEVEL_2_LIMIT: '',
        LEVEL_2_REQUIRED: '',
        LEVEL_2_VALID: '',
        DAILY_LIMIT: '',
        MONTHLY_LIMIT: '',
        DAILY_REMAINING: '',
        DAILY: '',
        MONTHLY_REMAINING: '',
        DEPOSIT_WITHDRAWAL: '',
        MONTHLY: '',
        LEVEL_UP: '',
        LEVEL_UP_MOBILE: ''
    },
    SERVICE: {
        HEADER: {
            HEADING: '',
            HEADING_MOBILE: '',
            RULES: '',
            COMMISSIONS: '',
        },
        RULES: {
            HEADING: '',
            SUMMARY: '',
            LIMITS: '',
            DAILY: '',
            MONTHLY: '',
            DEPOSIT: '',
            WITHDRAWAL: '',
            DAILY_DEPOSIT_WITHDRAWAL: '',
            MONTHLY_DEPOSIT_WITHDRAWAL: '',
            SIGN_UP: '',
            IDENTITY_DESCRIPTION: '',
            IDENTITY_VERIFICATION: '',
            LEVEL: '',
            LEVEL_0_DESCRIPTION: '',
            LEVEL_1_DESCRIPTION: '',
            LEVEL_2_DESCRIPTION: '',
            TL_DEPOSIT_RULES_HEADING: '',
            TL_DEPOSIT_RULES: [''],
            TL_WITHDRAWAL_RULES_HEADING: '',
            TL_WITHDRAWAL_RULES: [''],
        },
        COMMISSIONS: {HEADING: '', SUMMARY: ''}
    },
    CORPORATE: {
        HEADER: {
            HEADING: '',
            ABOUT: '',
            ANNOUNCEMENTS: '',
            CAREER: '',
            CONTACT: ''
        },
        ABOUT: {
            HEADING: '',
            DESCRIPTION: ''
        },
        ANNOUNCEMENTS: {},
        CAREER: {
            HEADING: '',
            DESCRIPTION: '',
            CURRENT_JOBS: '',
            INSPECT: '',
            JOB_ADVERTISEMENTS: [
                {
                    HEADING: '',
                    GENERAL_HEADING: '',
                    GENERAL: '',
                    RESPONSIBILITIES_HEADING: '',
                    RESPONSIBILITIES: [''],
                    QUALIFICATIONS_HEADING: '',
                    QUALIFICATIONS: [''],
                    ADVANTAGEOUS_HEADING: '',
                    ADVANTAGEOUS: [''],
                    ADVANTAGES_HEADING: '',
                    ADVANTAGES: [''],
                    WORKING_HOURS: '',
                    LUNCH: '',
                },
            ],
            APPLY_NOW: ''
        },
        CONTACT: {
            HEADING: '',
            MAIL: '',
            ADDRESS: ''
        },
    },
    ANNOUNCEMENTS: {
        HEADERS: {
            ANNOUNCEMENTS: '',
            DESCRIPTION: '',
            OPERATION_ANNOUNCEMENTS: '',
            OPERATION_ANNOUNCEMENTS_EXPLANATION: '',
            OPERATION_ANNOUNCEMENTS_BG_IMG_SRC_DESKTOP: '',
            OPERATION_ANNOUNCEMENTS_BG_IMG_SRC_MOBILE: '',
            ARTISTS_ANNOUNCEMENTS: '',
            ARTISTS_ANNOUNCEMENTS_EXPLANATION: '',
            ARTISTS_ANNOUNCEMENTS_BG_IMG_SRC_DESKTOP: '',
            ARTISTS_ANNOUNCEMENTS_BG_IMG_SRC_MOBILE: ''
        },
        ARTIST_ANNOUNCEMENTS: {
            MISC: {
                READ_MORE: '',
                READ_LESS: '',
                REFERENCES: ''
            },
            FOOTER: {
                ARTIST_ARCHIVE: '',
                PREVIOUS_ARTIST: '',
                NEXT_ARTIST: '',
                SEE_ARTWORKS: '',
                BACK_TO_ARTIST: ''
            },
            ARTIST_LIST: [
                {
                    ID: -1,
                    TEMPLATE: -1,
                    NAME: '',
                    NAME_UPPERCASE: '',
                    TOPIC: '',
                    TOPIC_UPPERCASE: '',
                    ARTWORKS: '',
                    ARTWORKS_UPPERCASE: '',
                    ARTIST_IMAGE_SRC: '',
                    IMAGE_BACKGROUND_DESKTOP: '',
                    IMAGE_BACKGROUND_MOBILE: '',
                    TEXT: '',
                    TEXT_LIMIT_DESKTOP: 0,
                    TEXT_LIMIT_MOBILE: 0,
                    REFERENCES: '',
                    ARTWORKS_LIST_DESKTOP: [{SRC: '', DESCRIPTION: ''}],
                    ARTWORKS_LIST_MOBILE: [{SRC: '', DESCRIPTION: ''}],
                    ARTWORK_REFERENCES: ['']
                },
                {
                    ID: -1,
                    TEMPLATE: -2,
                    NAME: '',
                    NAME_UPPERCASE: '',
                    TOPIC: '',
                    TOPIC_UPPERCASE: '',
                    ARTIST_LIST_HEADING: '',
                    ARTIST_LIST_HEADING_UPPERCASE: '',
                    ARTWORKS: '',
                    ARTIST_IMAGE_SRC: '',
                    IMAGE_BACKGROUND_DESKTOP: '',
                    IMAGE_BACKGROUND_MOBILE: '',
                    TEXT: '',
                    NESTED_ARTIST_LIST: [{PHOTO: '', HEADING: '', TEXT: ''}],
                    TEXT_LIMIT_DESKTOP: 0,
                    TEXT_LIMIT_MOBILE: 0,
                    REFERENCES: '',
                    ARTWORKS_LIST_DESKTOP: [{SRC: '', DESCRIPTION: ''}],
                    ARTWORKS_LIST_MOBILE: [{SRC: '', DESCRIPTION: ''}],
                    ARTWORK_REFERENCES: []
                },
            ]
        },
        OPERATION_ANNOUNCEMENTS: {
            OPERATION_LIST: [
                {
                    TOPIC: '',
                    IMAGE_SRC: '',
                    TEXT_MOBILE: '',
                    TEXT_DESKTOP: '',
                    TEXT_LIMIT_DESKTOP: 0,
                    TEXT_LIMIT_MOBILE: 0,
                    TOGGLE: false,
                    RELEASE_DATE: 0,
                }
            ]
        }
    },
    ART_PRODUCTS: {
        ABOUT_ART_PRODUCT: '',
        CONDITION: '',
        EXPERT_REPORT: '',
        EXPERT_REPORT_LINK: '',
        INSURANCE_REPORT: '',
        INSURANCE_REPORT_LINK: '',
        EXHIBITION_REPORT: '',
        EXHIBITION_REPORT_LINK: '',
        MORE: '',
    },
    SNACK_BAR: {
        SUCCESS: '',
        FAIL: ''
    },
    TOOLTIP: {
        BASIC: {
            ACTIVE_ART_PRODUCT_SYMBOL_TOOLTIP: '',
            ACTIVE_PAIR_PRICE_TOOLTIP: '',
            ACTIVE_PAIR_CHANGE_TOOLTIP: '',
            ACTIVE_PAIR_VOLUME_TOOLTIP: '',
            ACTIVE_ART_PRODUCT_NAME_TOOLTIP: '',
            ACTIVE_ART_PRODUCT_ARTIST_BORN_DEATH_TOOLTIP: '',
            ART_PRODUCT_MARKET_VALUE_TOOLTIP: '',
            ART_PRODUCT_REPORT_LIST_TOOLTIP: '',
            ART_PRODUCTS_TOOLTIP: '',
            SWITCH_BUTTON_TOOLTIP: ''
        },
        ADVANCED: {
            ACTIVE_PAIR_PRICE_TOOLTIP: '',
            ACTIVE_PAIR_CHANGE_TOOLTIP: '',
            ACTIVE_PAIR_VOLUME_TOOLTIP: '',
            ACTIVE_PAIR_MIN_MAX_TOOLTIP: '',
            BALANCE_TOOLTIP: '',
            ORDER_BOOK_HEADER_TOOLTIP: '',
            DEPTH_CHART_TOOLTIP: '',
            LIMIT_TOOLTIP: '',
            MARKET_TOOLTIP: '',
            TRADE_HISTORY_HEADER_TOOLTIP: '',
            USER_OPEN_ORDERS_HEADING_TOOLTIP: '',
            USER_TRADE_HISTORY_HEADING_TOOLTIP: ''
        },
    },
    PAGE_NOT_FOUND: {
        DESCRIPTION: '',
        GO_HOME: ''
    },
    CONNECTION_ERROR: {
        HEADING: '',
        DESCRIPTION: '',
    },
    UPDATE_APP: {
        HEADING: '',
        DESCRIPTION: '',
        UPDATE: '',
        CONTINUE: '',
    },
    GENERIC_MODAL: {
        OPEN: false,
        INNER_HTML: '',
        TIME_THRESHOLD: -1,
        CLOSE_DISABLED: false,
    },
    TEST_ENVIRONMENT: {
        TITLE: '',
        DESCRIPTION: '',
        RULES: [
            '',
        ],
    },
    // TURKEY_TOWN_LIST: [''],
    ONE_PAGER: {
        DESCRIPTION: [{TEXT: '', WEIGHT: 500}],
        BUTTON_TEXT: '',
        BUTTON_LINK: {url: '', is_inner: false, target: ''},
    },
    AWARD: {
        TITLE: '',
        INFO: '',
        IDIOM: '',
        EMAIL_INFO: '',
        EMAIL_PLACEHOLDER: '',
        AWARD_DECLARATION: {STRING: '', TOKENS: ['']},
        AWARD_INFO: '',
        STEP: '',
        STEPS: [{
            INFO: '',
            IMAGE: ''
        }]
    },
    MISC: {
        // APP_VERSION: '',
        // APP_ANDROID_VERSION: '',
        // APP_IOS_VERSION: '',
        ANNOUNCEMENT_ACTIVE: false,
        ZOOM_LEVEL: '',
        BUY: '',
        SELL: '',
        ONELINK: '',
        LOADING_ANIM_DESKTOP_WEBM: '',
        LOADING_ANIM_DESKTOP_MP4: '',
        LOADING_ANIM_MOBILE_WEBM: '',
        LOADING_ANIM_MOBILE_MP4: '',
        READ_ACCEPTED: '',
        CONTINUE: '',
        CHANGELOG_HEADER: '',
        CHANGELOG_WEB: [
            {
                VERSION: '',
                INFO: '',
            },
        ],
        CHANGELOG_ANDROID: [
            {
                VERSION: '',
                INFO: '',
            },
        ],
        CHANGELOG_IOS: [
            {
                VERSION: '',
                INFO: '',
            },
        ],
        CHANGELOG: [
            {
                VERSION: '',
                INFO: '',
            },
        ],
        ANNOUNCEMENTS_TITLES: {},
    },
    DELISTING: {
        // {
            // TITLE: '',
            // TEXT: [''],
        // }
        BUTTON_TEXT: '',
        ART_PRODUCTS: ['']
    },
    RESPONSE_TABLE: {},
    ACTIVITY_TABLE: {},
};
