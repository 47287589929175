import {Component, OnDestroy, OnInit} from '@angular/core';
import {ManagerService} from '../../../../services/manager.service';

@Component({
  selector: 'app-advanced',
  templateUrl: './advanced.component.html',
  styleUrls: ['./advanced.component.css']
})
export class AdvancedComponent implements OnInit, OnDestroy {

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
}
