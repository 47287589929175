<div *ngIf="((!managerService.logged_in) || (0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO)) | managerPipe"
     class="p-3">
  <div class="my-2" style="font-family: Roboto, sans-serif; font-size: 16px; font-weight: bold; line-height: 1.2; letter-spacing: 1px; color: #4a4e70;">
    {{managerService.config.DEPOSIT.NO_KYC_HEADING | managerPipe}}
  </div>
  <div class="my-2" style="font-family: Roboto, sans-serif; font-size: 12px; color: #4a4e70;">
    {{managerService.config.DEPOSIT.NO_KYC_DESCRIPTION | managerPipe}}
  </div>
  <div class="d-flex flex-row justify-content-center">
    <button *ngIf="!this.managerService.environment.app"
            (click)="managerService.navigationManager({url: '/account/security', active_security_panel: managerService.active_security_panel_template.IDENTITY_VERIFICATION, current_component_index: 0});"
            class="btn-native sign-up-login-button my-3"
            style="height: 30px; width:120px; border-radius: 16px;
              font-family: Roboto, sans-serif; font-size: 12px; line-height: 1.3; letter-spacing: 0.3px; color: #ffffff;">
      {{managerService.config.DASHBOARD.LEVEL_UP | managerPipe}}
    </button>
<!--    <button *ngIf="this.managerService.environment.app"-->
<!--            (click)="managerService.navigationManager({url: '/account-level', current_component_index: 0});"-->
<!--            class="btn-native sign-up-login-button my-3"-->
<!--            style="height: 30px; width:120px; border-radius: 16px;-->
<!--              font-family: Roboto, sans-serif; font-size: 12px; line-height: 1.3; letter-spacing: 0.3px; color: #ffffff;">-->
<!--      {{managerService.config.DASHBOARD.LEVEL_UP | managerPipe}}-->
<!--    </button>-->
  </div>

</div>

<div *ngIf="(managerService.logged_in && !(0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO)) | managerPipe"
     class="w-100 d-flex flex-column align-items-start justify-content-start" style=" background-color: #fcfdff; max-height: 1160px;">
  <div class="w-100" *ngIf="!managerService.config.WITHDRAWAL.TL_SUSPENSION.SUSPENDED | managerPipe">
    <div class="w-100 d-flex flex-row align-items-center justify-content-around mt-1"
         style=" background-color: #272a3b; margin-bottom: 10px; font-family: Roboto,sans-serif; font-size: 10px; line-height: 0.9; letter-spacing: 0.3px; color: #f4f5f9;">
      <div class="d-flex flex-column m-3">
        <span [innerHTML]="managerService.config.WITHDRAWAL.DAILY_WITHDRAWAL_LIMIT_MOBILE | managerPipe" class="m-1 text-center" style="line-height: 1.2;"></span>
        <span class="m-1 text-center"
              style=" font-family: 'Roboto Condensed', sans-serif;font-size: 15px;">{{managerService.userService.user?.daily_fiat_withdrawal_limit | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
          ₺</span>
      </div>
      <span
          style="height: 40px; width: 1px; margin: auto 0; border-radius:1px; background-color: #dddddd;"></span>
      <div class="h-100 d-flex flex-column m-3">
        <span [innerHTML]="managerService.config.WITHDRAWAL.MONTHLY_WITHDRAWAL_LIMIT_MOBILE | managerPipe" class="m-1 text-center" style="line-height: 1.2;"></span>
        <span class="m-1 text-center"
              style=" font-family: 'Roboto Condensed', sans-serif;font-size: 15px;">{{managerService.userService.user?.monthly_fiat_withdrawal_limit | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
          ₺</span>
      </div>
    </div>

    <div class="w-100">
      <div class="py-2 px-3" style="font-family: Roboto, sans-serif; font-size: 12px; line-height: 1.2; letter-spacing: 0.6px; color: #272a3b;">
        {{managerService.config.WITHDRAWAL.PLEASE_SELECT_BANK}}
      </div>

      <div class="d-flex flex-row mt-2 ml-3" style="height: 40px;">
        <div class="h-100 bank-account-dropdown-native-button">
          <button (click)="toggleBankAccountDropdownDisplay()" class="btn-native" style="width: 176px; padding: 0 4px 0 16px; font-family: Roboto, sans-serif; font-size: 14px; line-height: 1.2; letter-spacing: 0.4px; color: #4a4e70;
         border-radius: 4px; box-shadow: 1px 1px 2px 0 #dbdbdf; background-color: #ffffff;"
                  type="button">
            {{managerService.config.WITHDRAWAL.SAVED_BANK_ACCOUNTS_PLACEHOLDER | managerPipe}}
            <svg style="height: 40px; width: 20px;">
              <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#4a4e70"/>
            </svg>
          </button>
          <div #bank_account_dropdown_native_menu class="bank-account-dropdown-native-menu d-none" style="width: 240px; ">
            <button
                *ngIf="! (managerService.user_bank_accounts_pipe | managerPipe: managerService.mpa_template.GET_USER_BANK_ACCOUNT_LIST)?.length"
                class="position-relative bank-account-dropdown-native-item d-flex flex-column btn-native align-items-center justify-content-around p-2"
                style="width: 240px; font-size: 10.5px; font-weight: 300;font-style: italic;color: #595e7e; margin: 0; padding: 0">
              <div>
                {{managerService.config.WITHDRAWAL.NO_SAVED_BANK_ACCOUNT | managerPipe}}
              </div>
            </button>
            <button (click)="selectBankAccount(bank_account);"
                    *ngFor="let bank_account of (managerService.user_bank_accounts_pipe | managerPipe: managerService.mpa_template.GET_USER_BANK_ACCOUNT_LIST)"
                    class="position-relative bank-account-dropdown-native-item d-flex flex-column btn-native align-items-start justify-content-around px-2 py-3"
                    style="width: 240px; border-radius: 0; margin: 0; border-top: #f4f5f9 solid 1px" type="button">
            <span style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500; letter-spacing: 0.6px; color: #595e7e;">
              {{bank_account.label | managerPipe}}
            </span>
              <span style="font-size: 10px; letter-spacing: 0.5px; color: #abafc7;">
              {{bank_account.bank | managerPipe}}
            </span>
              <span style="font-family: Roboto, sans-serif; font-size: 12px; letter-spacing: 0.5px; color: #595e7e;">
              {{bank_account.iban | managerPipe}}
            </span>
              <span (click)="removeUserBankAccount(bank_account)" class="position-absolute" style="top:2px; right:3px; font-family: Roboto, sans-serif; font-size: 9px; line-height: 1.25; color: #abafc7;">
              {{this.managerService.config.WITHDRAWAL.REMOVE_BANK_ACCOUNT_MOBILE | managerPipe}}
            </span>
            </button>
          </div>
        </div>
        <button (click)="managerService.openModal('add-bank-account-modal')" class="mt-4 ml-3 btn-native"
                style="font-family: Roboto, sans-serif; font-size: 10px; font-weight: 500; line-height: 1; letter-spacing: 0.5px; color: #595e7e;"
                type="button">
          {{managerService.config.WITHDRAWAL.ADD_NEW_BANK_ACCOUNT | managerPipe}}
        </button>
      </div>
      <div *ngIf="managerService.operationsService.selected_user_bank_account_ready | managerPipe" class="mt-2">
        <div class="d-flex flex-row align-items-start justify-content-start pr-2 py-2 mx-3"
             style="width: fit-content; border-radius: 4px; box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22);">

          <div class="h-100 d-flex flex-row align-items-center mx-2" style="min-height: 68px">
            <img [src]="managerService.operationsService.selected_user_bank_account.bank | managerPipe: managerService.mpa_template.GET_BANK_ACCOUNT_IMG_SRC" alt="bank" width="80px" #bank
                 (error)="bank.src = bank.src.replace('.webp', '.jpg')">
          </div>
          <div class="d-flex flex-column">
            <div class="mb-1"
                 style="font-family: Roboto, sans-serif;font-size: 11px; font-weight: 500; line-height: 1.25; letter-spacing: 0.7px; color: #4a4e70;">
              {{managerService.operationsService.selected_user_bank_account.bank | managerPipe}}
            </div>
            <div class="mb-2"
                 style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500; line-height: 1.25; letter-spacing: 0.7px; color: #4a4e70;">
              {{managerService.operationsService.selected_user_bank_account.iban | managerPipe}}
            </div>
            <div class="mb-1"
                 style="font-family: Roboto, sans-serif; font-size: 11px; line-height: 1.25; color: #4a4e70;">
              {{managerService.operationsService.selected_user_bank_account.name | managerPipe}}
            </div>
            <div class=""
                 style="font-family: Roboto, sans-serif; font-size: 11px; line-height: 1.25; color: #4a4e70;">
              {{managerService.operationsService.selected_user_bank_account.label | managerPipe}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mt-4 pl-3"
         style="font-family: 'Roboto Condensed', sans-serif; font-size: 16px; line-height: 1.2; letter-spacing: 0.75px; color: #404352;">
      {{managerService.config.WITHDRAWAL.TRANSACTION_MOBILE}}
    </div>

    <form [formGroup]="bankWithdrawalRequestForm" class="w-100 mt-1 px-3">
      <div class="" style="font-family: Raleway, sans-serif; font-size: 10px; font-weight: 500; line-height: 1.1; color: #272a3b;">
        {{managerService.config.WITHDRAWAL.AVAILABLE_TRY | managerPipe}}
        {{managerService.pair_pipe | managerPipe: managerService.mpa_template.USER_TRY_CURRENCY_AVAILABLE_AMOUNT: managerService.user_pipe}}₺
      </div>
      <label class="d-flex flex-row" style="width: 250px; height: 30px;">
        <input class="w-100 input-field-underline text-right" formControlName="amount" min="100.00" placeholder="0" required type="number">
        <div class="h-100 d-flex flex-column align-content-center justify-content-end pb-1" style="font-family: 'Roboto Condensed', sans-serif;  font-size: 13px; line-height: 1.15; color: #abafc7;">
          (₺)
        </div>
      </label>

      <div class="mt-1" style="font-family: Raleway, sans-serif; font-size: 10px; line-height: 1.1; color: #9da5b7;">
        {{managerService.config.WITHDRAWAL.MINIMUM_FIAT_WITHDRAWAL_AMOUNT | managerPipe}}
      </div>


      <div class="my-2 d-flex flex-row" style="font-family: Roboto, sans-serif; font-size: 12px; color: #272a3b;">
        <div class="d-flex flex-column">
          <div class="my-2 mr-2" style="">
            {{managerService.config.WITHDRAWAL.DAILY_WITHDRAWAL_LIMIT | managerPipe}}
          </div>
          <div class="mr-2" style="">
            {{managerService.config.WITHDRAWAL.MONTHLY_WITHDRAWAL_LIMIT | managerPipe}}
          </div>
        </div>
        <div class="d-flex flex-column" style="flex-grow: 1; font-weight: normal;">
          <div class="mx-2 my-2 text-center" style="border-radius: 2px; background-color: #f7f7ff; box-shadow: 2px 2px 2px 0 rgba(230, 232, 248, 0.33);">
            {{managerService.userService.user?.daily_fiat_withdrawal_limit | managerPipe: managerService.mpa_template.FANCY_NUMBER}}₺
          </div>
          <div class="mx-2 text-center" style="border-radius: 2px; background-color: #f7f7ff; box-shadow: 2px 2px 2px 0 rgba(230, 232, 248, 0.33);">
            {{managerService.userService.user?.monthly_fiat_withdrawal_limit | managerPipe: managerService.mpa_template.FANCY_NUMBER}}₺
          </div>
        </div>
      </div>

      <div class="mt-3 text-info" style="font-size: 12px;">
        <svg style="margin-top: -2px; height: 12px; width: 12px;">
          <use [attr.xlink:href]='"./assets/images/info.svg#info"' fill="#17a2b8"/>
        </svg>
        {{managerService.config.WITHDRAWAL.WITHDRAWAL_WARNING | managerPipe}}
      </div>

      <button (click)="managerService.openFiatWithdrawalRequestModal(bankWithdrawalRequestForm.get('amount')?.value)" class="mt-3 btn-native"
              id="withdrawal-request-button"
              style="height: 34px; border-radius: 8px; width: 80%; margin-left: 10%; letter-spacing: 0.4px;">
        {{managerService.config.WITHDRAWAL.WITHDRAWAL_REQUEST | managerPipe}}
      </button>

      <div #mail_approval_info class="text-info d-none" style="font-size: 12px;">
        <svg style="height: 12px; width: 12px;">
          <use [attr.xlink:href]='"./assets/images/info.svg#info"' fill="#17a2b8"/>
        </svg>
        {{managerService.config.WITHDRAWAL.APPROVE_MAIL_WARNING | managerPipe}}
      </div>
    </form>
  </div>

  <div *ngIf="managerService.config.WITHDRAWAL.TL_SUSPENSION.SUSPENDED | managerPipe"
       class="pt-1 pb-3"
       style="min-height: 260px; box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff; max-height: 1160px; border-radius: 4px;">
    <div class="mt-2 ms-3"
         style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: bold; line-height: 2; letter-spacing: 1px; color: #4a4e70;"
         [innerHTML]="managerService.config.WITHDRAWAL.TL_SUSPENSION.TITLE | managerPipe">
    </div>

    <div class="mt-2 ms-3"
         style="font-family: Roboto, sans-serif; font-size: 11px; line-height: 1.7; color: #272a3b;"
         [innerHTML]="managerService.config.WITHDRAWAL.TL_SUSPENSION.DESCRIPTION | managerPipe">
    </div>
  </div>
</div>

<app-withdrawal-history-mobile [currency_type]="'fiat'" [currency_id]="0"></app-withdrawal-history-mobile>
