import {Component, ViewEncapsulation, ElementRef, OnInit} from '@angular/core';
import {trigger, state, style, animate, transition} from '@angular/animations';

export enum SnackBarStatus {
  POSITIVE,
  NEGATIVE,
}

@Component({
  selector: 'app-snack-bar',
  templateUrl: 'snack-bar.component.html',
  styleUrls: ['snack-bar.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('animationState', [
      state('open', style({
        right: '10px'
      })),
      state('close', style({
        right: '-250px'
      })),
      transition('*=>*', [
        animate('300ms')]),
    ]),
  ]
})
export class SnackBarComponent implements OnInit {

  public element: any;
  public animationState = '';
  public translateY = 0;
  public SnackBarStatusTemplate = SnackBarStatus;
  public animationStateActive: boolean = false;

  public data: { status: SnackBarStatus, heading: string, content: string } = {
    status: SnackBarStatus.POSITIVE,
    heading: '',
    content: ''
  };

  constructor(public el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
  }

  open(status: boolean, heading: string, content: string): void {
    this.data = {status: status ? SnackBarStatus.POSITIVE : SnackBarStatus.NEGATIVE, heading, content};
    this.element.style.visibility = 'visible';
    this.animationState = 'open';
    setTimeout(() => {
      this.close(false);
    }, 3000);
  }

  shift(): void {
    this.translateY += 120;
    this.element.getElementsByClassName('snack-bar')[0].style.transform = 'translateY(' + this.translateY.toString() + 'px)';
  }

  close(shutdown: boolean): void {
    this.animationState = 'close';
    if (shutdown) {
      this.element.style.visibility = 'hidden';
    } else {
      setTimeout(() => {
        this.element.style.visibility = 'hidden';
      }, 300);
    }
  }

  animate($event: any, $run?: any): void {
    if ($event.phaseName === 'start') {
      this.animationStateActive = true;
    }
    if (this.animationStateActive) {
      if ($event.phaseName === 'done') {
        this.animationStateActive = false;
      }
    }
  }

}
