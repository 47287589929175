import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../services/manager.service';

@Component({
    selector: 'app-footer-desktop',
    templateUrl: './footer-desktop.component.html',
    styleUrls: ['./footer-desktop.component.css']
})
export class FooterDesktopComponent implements OnInit {
    artist_list: { ID: number, NAME: string }[] = [];

    constructor(public managerService: ManagerService) {
    }

    ngOnInit(): void {
        this.artist_list = [...this.managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST].sort(() => Math.random() - 0.5).slice(0, 3);
    }

    instagram(): void {
        window.open('https://www.instagram.com/artioxofficial', '_blank');
    }

    twitter(): void {
        window.open('https://www.twitter.com/artiox_official', '_blank');

    }

    telegram(): void {
        window.open('https://t.me/joinchat/AAAAAEi3DvvSITYO1OJI0w', '_blank');
    }

    linkedin(): void {
        window.open('https://www.linkedin.com/company/artiox', '_blank');
    }
}
