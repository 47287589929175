import {Component, OnDestroy, OnInit} from '@angular/core';
import {ManagerService} from '../../../../services/manager.service';

@Component({
  selector: 'app-announcement-mobile',
  templateUrl: './announcement-mobile.component.html',
  styleUrls: ['./announcement-mobile.component.css']
})
export class AnnouncementMobileComponent implements OnInit, OnDestroy {

  public subscription: any;

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

}
