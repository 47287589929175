import {Component, Input, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
  selector: 'app-one-pager-desktop',
  templateUrl: './one-pager-desktop.component.html',
  styleUrls: ['./one-pager-desktop.component.css']
})
export class OnePagerDesktopComponent implements OnInit {

  @Input() modal_id: string = '';

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

}
