<div
    *ngIf="((!managerService.logged_in) || !(0 | managerPipe: managerService.mpa_template.CHECK_USER_DEPOSIT_HISTORY_LENGTH_GREATER_THAN: managerService.user_currency_operation_history_pipe) || !(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO)) | managerPipe"
    class="p-3">
  <div class="w-100 my-2" style="font-family: Roboto, sans-serif; font-size: 14px; font-weight: bold; line-height: 2; letter-spacing: 1px; color: #4a4e70;">
    {{managerService.config.DEPOSIT.PREREQUIREMENTS | managerPipe}}
  </div>
  <div class="w-100">
    <div class="mt-2 pl-2 py-2" style="background-color: #f4f5f9; font-family: Roboto, sans-serif; font-size: 12px; font-style: italic;
      line-height: 1.75; letter-spacing: 0.35px; color: #4a4e70;">
      <div *ngFor="let rule of managerService.config.DEPOSIT.CRYPTO_DEPOSIT_PREREQUIREMENTS | managerPipe" class="w-100">
        {{rule | managerPipe}}
      </div>
    </div>
  </div>

  <div *ngIf="!(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO)" class="w-100 mt-2 my-2 d-flex flex-column align-items-start justify-content-start"
       style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500; line-height: 2.5; letter-spacing: 0.84px; color: #595e7e;">
    <div class="mt-2">{{managerService.config.ACCOUNT_LEVEL.HEADING | managerPipe}}</div>
    <div class="w-100">{{managerService.user_pipe | managerPipe: managerService.mpa_template.GET_USER_ACCOUNT_LEVEL}} / 2</div>
    <div class="w-100 d-flex flex-row align-items-center justify-content-between mb-2">
      <div class="w-66 text-left"
           style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500; line-height: 2; letter-spacing: 0.84px; color: #9da5b7;">{{managerService.config.ACCOUNT_LEVEL.LEVEL_2_REQUIRED | managerPipe}}</div>
      <div class="w-33 text-center">
        <button
            (click)="managerService.navigationManager({url: '/account/security', active_security_panel: managerService.active_security_panel_template.IDENTITY_VERIFICATION, current_component_index: 0});"
            class="btn-native btn-account-navigator text-decoration-underline"
            style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500; line-height: 2.73; letter-spacing: normal; color: #59c8d9;" type="button">
          {{managerService.config.DASHBOARD.LEVEL_UP | managerPipe}}
        </button>
      </div>
    </div>
    <span class="w-100" style="border-top: solid 1px #d5d8e2;"></span>
  </div>

  <div *ngIf="(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO)" class="w-100 mt-2 my-2 d-flex flex-column align-items-start justify-content-start"
       style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500; line-height: 2.5; letter-spacing: 0.84px; color: #595e7e;">
    <div class="mt-2">{{managerService.config.ACCOUNT_LEVEL.HEADING | managerPipe}}</div>
    <div class="w-100">{{managerService.user_pipe | managerPipe: managerService.mpa_template.GET_USER_ACCOUNT_LEVEL}} / 2</div>
    <div class="w-100 d-flex flex-row align-items-center justify-content-between mb-2">
      <div class="w-66 text-left"
           style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500; line-height: 2; letter-spacing: 0.84px; color: #9da5b7;">{{managerService.config.ACCOUNT_LEVEL.LEVEL_2_VALID | managerPipe}}</div>
      <div class="w-33 text-center">
        <svg class="mx-4" style="height: 20px; width: 20px;">
          <use [attr.xlink:href]='"./assets/images/tick.svg#tick"' fill="#0ad5a0"/>
        </svg>
      </div>
    </div>
    <span class="w-100" style="border-top: solid 1px #d5d8e2;"></span>
  </div>

  <div *ngIf="!(0 | managerPipe: managerService.mpa_template.CHECK_USER_DEPOSIT_HISTORY_LENGTH_GREATER_THAN: managerService.user_currency_operation_history_pipe)"
       class="w-100 mt-2 my-2 d-flex flex-column align-items-start justify-content-start"
       style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500; line-height: 2.5; letter-spacing: 0.84px; color: #595e7e;">
    <div class="mt-2">{{managerService.config.DEPOSIT.TL_DEPOSIT_HISTORY | managerPipe}}</div>
    <div class="w-100"
         style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500; line-height: 2.73; letter-spacing: 0.84px; color: #9da5b7;">{{managerService.config.DEPOSIT.TL_DEPOSIT_HISTORY_INVALID | managerPipe}}</div>
    <div class="w-100 d-flex flex-row align-items-center justify-content-between mb-2">
      <div class="w-66 text-left"
           style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500; line-height: 2; letter-spacing: 0.84px; color: #9da5b7;">{{managerService.config.DEPOSIT.TL_DEPOSIT_REQUIRED | managerPipe}}</div>
      <div class="w-33 text-center">
        <button (click)="managerService.navigationService.active_deposit_page = managerService.navigationService.deposit_page_template.TURKISH_LIRA"
                class="btn-native btn-account-navigator text-decoration-underline"
                style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500; line-height: 2.73; letter-spacing: normal; color: #59c8d9;" type="button">
          {{managerService.config.DEPOSIT.DEPOSIT_NOW | managerPipe}}
        </button>
      </div>
    </div>
    <span class="w-100" style="border-top: solid 1px #d5d8e2;"></span>
  </div>

  <div *ngIf="(0 | managerPipe: managerService.mpa_template.CHECK_USER_DEPOSIT_HISTORY_LENGTH_GREATER_THAN: managerService.user_currency_operation_history_pipe)"
       class="w-100 mt-2 my-2 d-flex flex-column align-items-start justify-content-start"
       style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500; line-height: 2.5; letter-spacing: 0.84px; color: #595e7e;">
    <div class="mt-2">{{managerService.config.DEPOSIT.TL_DEPOSIT_HISTORY | managerPipe}}</div>
    <div class="w-100"
         style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500; line-height: 2.73; letter-spacing: 0.84px; color: #9da5b7;">{{managerService.config.DEPOSIT.TL_DEPOSIT_HISTORY_VALID | managerPipe}}</div>
    <div class="w-100 d-flex flex-row align-items-center justify-content-between mb-2">
      <div class="w-66 text-left"
           style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500; line-height: 2; letter-spacing: 0.84px; color: #9da5b7;">{{managerService.config.DEPOSIT.TL_DEPOSIT_ACCOMPLISHED | managerPipe}}</div>
      <div class="w-33 text-center">
        <svg class="mx-4" style="height: 20px; width: 20px;">
          <use [attr.xlink:href]='"./assets/images/tick.svg#tick"' fill="#0ad5a0"/>
        </svg>
      </div>
    </div>
    <span class="w-100" style="border-top: solid 1px #d5d8e2;"></span>
  </div>

</div>

<div
    *ngIf="(managerService.logged_in && (0 | managerPipe: managerService.mpa_template.CHECK_USER_DEPOSIT_HISTORY_LENGTH_GREATER_THAN: managerService.user_currency_operation_history_pipe) && (2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO)) | managerPipe"
    class="w-100 d-flex flex-column align-items-start justify-content-start" style="background-color: #fcfdff; ">
  <div class="w-100" *ngIf="!managerService.config.DEPOSIT.TETHER_SUSPENSION.SUSPENDED | managerPipe">
    <div class="w-100 d-flex flex-row align-items-center justify-content-around mt-1"
         style=" background-color: #272a3b; margin-bottom: 10px; font-family: Roboto,sans-serif; font-size: 10px; line-height: 0.9; letter-spacing: 0.3px; color: #f4f5f9;">
      <div class="d-flex flex-column m-3">
        <span [innerHTML]="managerService.config.DEPOSIT.DAILY_DEPOSIT_LIMIT_MOBILE | managerPipe" class="m-1 text-center" style="line-height: 1.2;"></span>
        <span class="m-1 text-center"
              style=" font-family: 'Roboto Condensed', sans-serif;font-size: 15px;">{{managerService.userService.user?.daily_crypto_deposit_limit | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
          ₮</span>
      </div>
      <span
          style="height: 40px; width: 1px; margin: auto 0; border-radius:1px; background-color: #dddddd;"></span>
      <div class="h-100 d-flex flex-column m-3">
        <span [innerHTML]="managerService.config.DEPOSIT.MONTHLY_DEPOSIT_LIMIT_MOBILE | managerPipe" class="m-1 text-center" style="line-height: 1.2;"></span>
        <span class="m-1 text-center"
              style=" font-family: 'Roboto Condensed', sans-serif;font-size: 15px;">{{managerService.userService.user?.monthly_crypto_deposit_limit | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
          ₮</span>
      </div>
    </div>

    <div class="px-3 py-3" style="font-family: Roboto, sans-serif; font-size: 11px; letter-spacing: 0.35px; color: #404352;">
      {{managerService.config.DEPOSIT.CRYPTO_DEPOSIT_DESCRIPTION | managerPipe}}
    </div>

    <div class="d-flex flex-row flex-wrap align-items-start justify-content-start px-3 my-2">
      <div (click)="selectCryptoAccount(crypto_wallet)"
           *ngFor="let crypto_wallet of [managerService.operationsService.user_crypto_currency_wallet_list[0]] | managerPipe;"
           [class.inactive-crypto-account]="((crypto_wallet !== managerService.operationsService.selected_user_currency_wallet)) && managerService.operationsService.crypto_deposit_rules_accepted_status | managerPipe"
           class="p-2"
           style="flex-basis: 46%; margin: 4px 2%; border-radius: 4px; box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22);">
        <img [src]="crypto_wallet | managerPipe: managerService.mpa_template.GET_CRYPTO_TOKEN_IMG_SRC" alt="crypto_account" width="100%" #crypto_account
             (error)="crypto_account.src = crypto_account.src.replace('.webp', '.jpg')">
      </div>
    </div>

    <div *ngIf="managerService.operationsService.crypto_deposit_rules_accepted_status | managerPipe" class="w-100 d-flex flex-column"
         style="font-family: Roboto, sans-serif; font-size: 14px; line-height: 1.6; letter-spacing: 0.4px; color: #404352;">
      <div class="mx-2 my-3">
        <div style="font-size: 11px; letter-spacing: 1.1px">
          {{managerService.pairService.currency_list[managerService.operationsService.selected_user_currency_wallet.currency_id].name| managerPipe}} {{managerService.config.DEPOSIT.WALLET_OF| managerPipe}}
        </div>
        <div class="font-weight-bold">{{managerService.config.DEPOSIT.ACCOUNT_ADDRESS}}:</div>
        <div class="my-1" style="font-size: 13px; letter-spacing: normal">{{managerService.operationsService.selected_user_currency_wallet.address}}</div>
        <div class="d-flex flex-row justify-content-end">
          <button (click)="copyAddress()" class="mx-1 btn-native" style="font-family: Roboto, sans-serif; font-size: 12px; color: #abafc7;">
            {{managerService.config.DEPOSIT.COPY_ADDRESS | managerPipe}}
            <svg style="height: 16px; width: 16px;">
              <use [attr.xlink:href]='"./assets/images/copy.svg#copy"' fill="#abafc7"/>
            </svg>
          </button>
        </div>

      </div>
      <div class="mx-2 my-1 text-info" style="font-size: 12px; ">
        <svg style="height: 14px; width: 14px; line-height: 0.75; margin-top: -2px;">
          <use [attr.xlink:href]='"./assets/images/info.svg#info"' fill="#17a2b8"/>
        </svg>
        {{managerService.config.DEPOSIT.DEPOSIT_WARNING}}
      </div>
    </div>
  </div>

  <div *ngIf="managerService.config.DEPOSIT.TETHER_SUSPENSION.SUSPENDED | managerPipe"
       class="pt-1 pb-3"
       style="min-height: 260px; box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff; max-height: 1160px; border-radius: 4px;">
    <div class="mt-2 ms-3"
         style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: bold; line-height: 2; letter-spacing: 1px; color: #4a4e70;"
         [innerHTML]="managerService.config.DEPOSIT.TETHER_SUSPENSION.TITLE | managerPipe">
    </div>

    <div class="mt-2 ms-3"
         style="font-family: Roboto, sans-serif; font-size: 11px; line-height: 1.7; color: #272a3b;"
         [innerHTML]="managerService.config.DEPOSIT.TETHER_SUSPENSION.DESCRIPTION | managerPipe">
    </div>
  </div>
</div>

<app-deposit-history-mobile [currency_type]="'crypto'" [currency_id]="1"></app-deposit-history-mobile>
