import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {ManagerService} from '../../../../services/manager.service';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-user-change-password-mobile',
  templateUrl: './user-change-password-mobile.component.html',
  styleUrls: ['./user-change-password-mobile.component.css']
})
export class UserChangePasswordMobileComponent implements OnInit, AfterViewInit, OnDestroy {

  public forgot_password_key: string = '';
  public router_sub: any;
  public input_fields: any = undefined;
  public passwordValidation: boolean = false;

  public password_visible: boolean = false;
  public password_again_visible: boolean = false;

  changePasswordForm = new UntypedFormGroup({
    new_password: new UntypedFormControl('', [Validators.required, Validators.minLength(8),
      this.regexValidator(new RegExp('.*[a-z]+.*$'), {lowercase: ''}), this.regexValidator(new RegExp('.*[0-9]+.*$'), {number: ''}), this.regexValidator(new RegExp('.*[A-Z]+.*$'), {uppercase: ''}),
      this.regexValidator(new RegExp('.*[!@#$%^&*(),.?":{}|<>_+=/;€£\\[\\]-]+.*$'), {special_char: ''}),  Validators.maxLength(50)]),
  });

  @ViewChild('password_input') password_input: any = undefined;
  @ViewChild('password_error') password_error: any = undefined;
  @ViewChild('submit_button') submit_button: any = undefined;
  @ViewChildren('password_pattern_error') password_pattern_error: any;

  public subscription_list: Subscription[] = [];

  constructor(public managerService: ManagerService, private httpClient: HttpClient, public elementRef: ElementRef, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.subscription_list.push(this.router_sub = this.route.params.subscribe(params => {
      this.forgot_password_key = params.id;
    }));
    // @ts-ignore
    this.subscription_list.push(this.changePasswordForm.get('new_password')?.valueChanges.subscribe(() => {
      this.validatePasswords();
    }));
    this.subscription_list.push(this.managerService.language_changed.subscribe(() => {
      this.validatePasswords();
    }));
  }

  ngAfterViewInit(): void {
  }


  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  validatePasswords(): void{

    if (this.password_input) {
      const controlMember = this.changePasswordForm.get('new_password');
      if (controlMember?.errors) {
        this.submit_button.nativeElement.disabled = true;
        this.password_input.nativeElement.classList.remove('input-success');
        this.password_input.nativeElement.classList.add('input-error');
        if (controlMember.errors.required){
          this.password_pattern_error.forEach( (el: ElementRef) => {
            el.nativeElement.classList.add('d-none');
          });
          // @ts-ignore
          this.password_error.nativeElement.classList.remove('d-none');
          // @ts-ignore
          this.password_error.nativeElement.children[1].innerHTML = this.managerService.config.RESPONSE_TABLE['2003'].message;
        }
        else{

          this.password_pattern_error.forEach( (el: ElementRef) => {
            el.nativeElement.classList.remove('d-none');
          });
          this.password_error.nativeElement.classList.add('d-none');
        }
        if (controlMember.errors.minlength === undefined){
          this.password_pattern_error.first.nativeElement.classList.add('pattern-success');
          this.password_pattern_error.first.nativeElement.classList.remove('pattern-error');
        }
        else{
          this.password_pattern_error.first.nativeElement.classList.remove('pattern-success');
          this.password_pattern_error.first.nativeElement.classList.add('pattern-error');
        }
        if (controlMember.errors.lowercase === undefined && controlMember.errors.number === undefined ){
          const pattern_error = this.password_pattern_error.get(1);
          pattern_error.nativeElement.classList.add('pattern-success');
          pattern_error.nativeElement.classList.remove('pattern-error');
        }
        else{
          const pattern_error = this.password_pattern_error.get(1);
          pattern_error.nativeElement.classList.remove('pattern-success');
          pattern_error.nativeElement.classList.add('pattern-error');
        }
        if (controlMember.errors.uppercase === undefined){
          const pattern_error = this.password_pattern_error.get(2);
          pattern_error.nativeElement.classList.add('pattern-success');
          pattern_error.nativeElement.classList.remove('pattern-error');
        }
        else{
          const pattern_error = this.password_pattern_error.get(2);
          pattern_error.nativeElement.classList.remove('pattern-success');
          pattern_error.nativeElement.classList.add('pattern-error');
        }
        if (controlMember.errors.special_char === undefined){
          this.password_pattern_error.last.nativeElement.classList.add('pattern-success');
          this.password_pattern_error.last.nativeElement.classList.remove('pattern-error');
        }
        else{
          this.password_pattern_error.last.nativeElement.classList.remove('pattern-success');
          this.password_pattern_error.last.nativeElement.classList.add('pattern-error');
        }
      }
      else{
        this.password_input.nativeElement.classList.add('input-success');
        this.password_input.nativeElement.classList.remove('input-error');
        this.password_pattern_error.forEach( (el: ElementRef) => {
          el.nativeElement.classList.add('pattern-success');
          el.nativeElement.classList.remove('pattern-error');
        });
        this.submit_button.nativeElement.disabled = false;

      }
    }
  }

  changePassword(): void {
    if (this.changePasswordForm.invalid){
      return;
    }
    const data = {
      new_password: this.changePasswordForm.value.new_password,
      forgot_password_key: this.forgot_password_key,
      client_info: this.managerService.client_info
    };

    this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'new_user_password_service', data).subscribe(
        response => {
          this.managerService.closeModal('loading-modal');
          // @ts-ignore
          const content = this.managerService.config.RESPONSE_TABLE[response.code];
          this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));

          if (response.is_okay){
            this.managerService.navigationManager({url: '/login', current_component_index: 0});
          }
        }));
  }

  ngOnDestroy(): void {
    this.subscription_list.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

}
