<div style="background-color: #fcfdff;" [style]="managerService.show_sign_up_or_login | managerPipe: managerService.mpa_template.GET_MIN_BODY_HEIGHT_OBSOLETE">
  <div *ngIf="managerService.config.ZENDESK_ACTIVE | managerPipe" class="w-100 mx-auto px-2 position-relative" style="box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);  background-color: #ffffff;">
    <div class="pt-4 pb-2 pl-2" style="font-family: Roboto, sans-serif; font-size: 14px; letter-spacing: 0.7px; color: #272a3b;">
      {{managerService.config.CORPORATE.CAREER.HEADING | managerPipe: managerService.mpa_template.TO_LOCAL_UPPER_CASE}}
    </div>
  </div>

  <div class="w-100 d-flex flex-row py-3 px-3">
    <div>
      <svg style="height: 48px; width: 48px;">
        <use [attr.xlink:href]='"./assets/images/career.svg#career"' fill="var(--artiox-blue)"/>
      </svg>
    </div>
    <div class="pl-2">
      <div [innerHTML]="managerService.config.CORPORATE.CAREER.DESCRIPTION | managerPipe" class=""
           style="font-family: Roboto, sans-serif; font-size: 12px; line-height: 1.6; color: #404352;">
      </div>
    </div>
  </div>
  <div class="px-3 pb-3">
    <div class="py-2"
         style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500; color: #4a4e70;">{{managerService.config.CORPORATE.CAREER.CURRENT_JOBS | managerPipe}}</div>

    <div *ngFor="let job of managerService.config.CORPORATE.CAREER.JOB_ADVERTISEMENTS | managerPipe" class="my-2">
      <div (click)="details.classList.toggle('d-none')"
           class="position-relative d-flex flex-row justify-content-between align-items-center"
           style="height: 41px; font-family: Roboto, sans-serif; border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; background-color: #ffffff;">
        <div class="px-2" style="font-size: 12px; color: #9da5b7;">{{job.HEADING| managerPipe}}</div>
        <div class="px-2" style="font-size: 11px; color: var(--artiox-blue)">{{managerService.config.CORPORATE.CAREER.INSPECT| managerPipe}}</div>
      </div>
      <div #details
           class="d-none px-2 pb-2" style="border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; margin-top: -20px; padding-top: 20px; background-color: #ffffff;">
        <div class="job-advertisement-heading">{{job.GENERAL_HEADING | managerPipe}}</div>
        <div class="job-advertisement-description">{{job.GENERAL | managerPipe}}</div>
        <div class="job-advertisement-heading">{{job.RESPONSIBILITIES_HEADING | managerPipe}}</div>
        <ul class="job-advertisement-description">
          <li *ngFor="let advantageous of job.RESPONSIBILITIES | managerPipe">{{advantageous}}</li>
        </ul>
        <div class="job-advertisement-heading">{{job.QUALIFICATIONS_HEADING | managerPipe}}</div>
        <ul class="job-advertisement-description">
          <li *ngFor="let qualification of job.QUALIFICATIONS | managerPipe">{{qualification}}</li>
        </ul>
        <div class="job-advertisement-heading">{{job.ADVANTAGEOUS_HEADING | managerPipe}}</div>
        <ul class="job-advertisement-description">
          <li *ngFor="let advantageous of job.ADVANTAGEOUS | managerPipe">{{advantageous}}</li>
        </ul>
        <div class="job-advertisement-heading">{{job.ADVANTAGES_HEADING | managerPipe}}</div>
        <ul class="job-advertisement-description">
          <li *ngFor="let advantage of job.ADVANTAGES | managerPipe">{{advantage}}</li>
        </ul>
        <div class="job-advertisement-description">{{job.WORKING_HOURS | managerPipe}}</div>
        <div class="job-advertisement-description">{{job.LUNCH | managerPipe}}</div>
        <a class="apply-now-button mx-auto mt-3 mb-2" href="mailto:career@artiox.com">
          {{managerService.config.CORPORATE.CAREER.APPLY_NOW | managerPipe}}
        </a>
      </div>
    </div>
  </div>
</div>
