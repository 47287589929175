import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-pair-list-desktop',
  templateUrl: './pair-list-desktop.component.html',
  styleUrls: ['./pair-list-desktop.component.css']
})
export class PairListDesktopComponent implements OnInit {

  constructor(public managerService: ManagerService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
  }

}
