import {Component, OnDestroy, OnInit} from '@angular/core';
import {ManagerService} from '../../../../services/manager.service';

@Component({
  selector: 'app-pre-sale-mobile',
  templateUrl: './pre-sale-mobile.component.html',
  styleUrls: ['./pre-sale-mobile.component.css']
})
export class PreSaleMobileComponent implements OnInit, OnDestroy {

  constructor(public managerService: ManagerService) {
    this.managerService.art_product_token_info_display_status = false;
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
}
