import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
    selector: 'app-user-info-desktop',
    templateUrl: './user-info-desktop.component.html',
    styleUrls: ['./user-info-desktop.component.css']
})
export class UserInfoDesktopComponent implements OnInit {

    constructor(public managerService: ManagerService) {
    }

    ngOnInit(): void {
    }

}
