import { Component } from '@angular/core';

@Component({
  selector: 'app-legal-limits-desktop',
  templateUrl: './legal-limits-desktop.component.html',
  styleUrl: './legal-limits-desktop.component.css'
})
export class LegalLimitsDesktopComponent {

}
