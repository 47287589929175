import {Component, Input, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';

@Component({
  selector: 'app-deposit-history-desktop',
  templateUrl: './deposit-history-desktop.component.html',
  styleUrls: ['./deposit-history-desktop.component.css']
})
export class DepositHistoryDesktopComponent implements OnInit {

  @Input() currency_type: string = '';
  @Input() currency_id: number = 0;

  constructor(public managerService: ManagerService) { }

  ngOnInit(): void {
  }

}
