<div class=" w-100 d-flex flex-column align-items-start justify-content-start"
     style="margin-top: 10px; box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff; border-radius: 4px;">
  <div class="w-100 mt-4 ml-5" style="font-family: bebas-neue-bold, sans-serif; font-size: 25px; line-height: 1.25; letter-spacing: 2.5px; color: #404352;">
    {{managerService.config.DEPOSIT.DEPOSIT_HISTORY | managerPipe}}
  </div>
  <div class="w-100 d-flex flex-row align-items-center justify-content-around py-1 my-3"
       style="font-family: Roboto, sans-serif;font-size: 12px; font-weight: 500; line-height: 2.7; letter-spacing: 0.7px; color: #4a4e70;">
    <span class="pl-4 text-center"
          style="width: 14%">{{managerService.config.BALANCE.SYMBOL | managerPipe}}</span>
    <span class="text-center"
          style="width: 16%">{{managerService.config.BALANCE.OPERATION_TYPE | managerPipe}}</span>
    <span class="text-center"
          style="width: 14%">{{managerService.config.BALANCE.AMOUNT| managerPipe}}</span>
    <span class="text-center"
          style="width: 16%">{{managerService.config.BALANCE.DATE | managerPipe}}</span>
    <span class="text-center"
          style="width: 16%">{{managerService.config.BALANCE.KIND | managerPipe}}</span>
    <span class="text-center"
          style="width: 18%">{{managerService.config.BALANCE.EXPLANATION | managerPipe}}</span>
    <span class="pr-4 text-right"
          style="width: 16%">{{managerService.config.BALANCE.STATUS | managerPipe}}</span>
  </div>
  <div class="w-100 d-flex flex-column overflow-auto" style="min-height: 360px; max-height: 360px">
    <div
        *ngFor="let user_operation of (managerService.user_currency_operation_history_pipe | managerPipe: managerService.mpa_template.GET_USER_OPERATION_HISTORY: currency_id: 'deposit': currency_type) | managerPipe: managerService.mpa_template.SORT_BY_CREATION_TIME"
        class="d-flex flex-row align-items-center justify-content-around py-1 user-trade-history"
        style="font-family: Roboto, sans-serif;font-size: 10px; font-weight: 500; line-height: 2.7; letter-spacing: 0.7px; color: #4a4e70;">
      <span class="pl-4 text-center"
            style="width: 14%;">{{user_operation.currency_id | managerPipe: managerService.mpa_template.CURRENCY_SYMBOL: managerService.pair_pipe}}</span>
      <span [style]="user_operation | managerPipe: managerService.mpa_template.GET_USER_OPERATION_BACKGROUND_COLOR"
            class="text-center"
            style="width: 16%">{{user_operation.type | managerPipe: managerService.mpa_template.GET_USER_OPERATION_TYPE: managerService.language_pipe}}</span>
      <span class="text-center"
            style="width: 14%">{{user_operation | managerPipe: managerService.mpa_template.USER_OPERATION_HISTORY_AMOUNT}}</span>
      <span class="text-center"
            style="width: 16%">{{user_operation.creation_time | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</span>
      <span class="text-center"
            style="width: 16%">{{user_operation.kind | managerPipe}}</span>
      <span (click)="managerService.navigationManager(managerService.userOperationTransactionInfoLink(user_operation))"
            [innerHTML]="user_operation | managerPipe: managerService.mpa_template.GET_USER_OPERATION_INFO: managerService.language_pipe"
            [style.cursor]="(user_operation | managerPipe: managerService.mpa_template.GET_USER_OPERATION_INFO_STYLE)"
            class="text-center" style="width: 18%;"></span>
      <span class="pr-4 text-right"
            style="width: 16%">{{managerService.config.BALANCE | managerPipe: managerService.mpa_template.RETURN_INDEX: user_operation.status.toUpperCase()}}</span>
    </div>
  </div>
</div>
