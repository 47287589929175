<div [style]="managerService.show_sign_up_or_login | managerPipe: managerService.mpa_template.GET_MIN_BODY_HEIGHT_OBSOLETE"
     class="d-flex flex-column w-100 h-100 mx-3">
  <div class="w-100 position-relative"
       style="padding: 0 10px; min-width: 850px;">
    <!--    R.I.P.-->
    <!--    <div class="w-100 d-flex flex-column"-->
    <!--         style="box-shadow: 2px 2px 9px 0 rgba(193, 196, 206, 0.5); background-color: #272a3b; max-height: 200px; min-height: 200px; border-radius: 4px;">-->
    <!--      <div class="my-3 ml-4"-->
    <!--           style="font-family: Roboto, sans-serif; font-size: 15px; font-weight: 300; line-height: 1.5; color: #59c8d9;">-->
    <!--        <span class="font-weight-bold">{{managerService.config.DASHBOARD.WELCOME | managerPipe}}</span>-->
    <!--        {{managerService.userService.user?.email | managerPipe}}-->
    <!--      </div>-->
    <!--      <div class="d-flex flex-row flex-grow-1 align-items-center justify-content-around m-2"-->
    <!--           style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 300; line-height: 0.9; letter-spacing: 0.1px; color: #ffffff;">-->
    <!--        <div *ngIf="!(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)" class="h-100 d-flex align-items-center justify-content-center"-->
    <!--             style="min-width: 225px;">-->
    <!--          <table *ngIf="!(0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_GREATER_THAN: managerService.user_pipe)" class="h-50 text-right"-->
    <!--                 style="width: 200px;">-->
    <!--            <tr>-->
    <!--              <td class="pr-2 py-1 text-left">{{managerService.config.DASHBOARD.LIMITS | managerPipe}}</td>-->
    <!--              <td class="px-1 py-1">{{managerService.config.DASHBOARD.DAILY | managerPipe}}</td>-->
    <!--              <td class="px-1 py-1">{{managerService.config.DASHBOARD.MONTHLY | managerPipe}}</td>-->
    <!--            </tr>-->
    <!--            <tr style="border-bottom: solid 1px rgba(240, 242, 247, 0.3);">-->
    <!--              <td class="pr-2 py-1 text-left">{{managerService.config.DASHBOARD.DEPOSIT | managerPipe}}</td>-->
    <!--              <td class="px-1 py-1">{{0 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
    <!--              <td class="px-1 py-1">{{0 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
    <!--            </tr>-->
    <!--            <tr>-->
    <!--              <td class="pr-2 py-1 text-left">{{managerService.config.DASHBOARD.WITHDRAWAL | managerPipe}}</td>-->
    <!--              <td class="px-1 py-1">{{0 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
    <!--              <td class="px-1 py-1">{{0 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
    <!--            </tr>-->
    <!--          </table>-->
    <!--          <svg class="position-absolute" style="height: 100px; width: 100px; transform: translate(-80px, -50px); z-index: 1;">-->
    <!--            <use [attr.xlink:href]='"./assets/images/level_zero.svg#level_zero"'/>-->
    <!--          </svg>-->
    <!--          <svg *ngIf="0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe" class="position-absolute"-->
    <!--               style="height: 100px; width: 225px;">-->
    <!--            <use [attr.xlink:href]='"./assets/images/level_box.svg#level_box"' fill="#ffffff"/>-->
    <!--          </svg>-->
    <!--          <span class="position-absolute" style="font-family: Roboto, sans-serif; font-size: 14px; z-index: 1;-->
    <!--              line-height: 1.3; letter-spacing: 0.65px; color: #272a3b; transform: translate(-82.5px, -45px)">{{managerService.config.DASHBOARD.LEVEL | managerPipe}}</span>-->
    <!--          <svg *ngIf="(0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_GREATER_THAN: managerService.user_pipe)" class="position-absolute" style="height: 190px; width: 200px;">-->
    <!--            <use [attr.xlink:href]='"./assets/images/level_passed.svg#level_passed"'/>-->
    <!--          </svg>-->
    <!--        </div>-->
    <!--        <div *ngIf="!(0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_GREATER_THAN: managerService.user_pipe)" class="h-100 d-flex align-items-center justify-content-center"-->
    <!--             style="width: 18px;">-->
    <!--          <svg style="height: 18px; width: 18px;">-->
    <!--            <use [attr.xlink:href]='"./assets/images/blue_arrow.svg#blue_arrow"' fill="#40b4c8"/>-->
    <!--          </svg>-->
    <!--        </div>-->
    <!--        <div (mouseenter)="level_one_hover=true;" (mouseleave)="level_one_hover=false;" *ngIf="!(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)" class="h-100 d-flex align-items-center justify-content-center"-->
    <!--             style="min-width: 225px;">-->
    <!--          <table *ngIf="!(1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_GREATER_THAN: managerService.user_pipe)" class="h-50 text-right"-->
    <!--                 style="min-width: 190px;">-->
    <!--            <tr>-->
    <!--              <td class="pr-2 py-1 text-left">{{managerService.config.DASHBOARD.LIMITS | managerPipe}}</td>-->
    <!--              <td class="px-2 py-1">{{managerService.config.DASHBOARD.DAILY | managerPipe}}</td>-->
    <!--              <td class="px-2 py-1">{{managerService.config.DASHBOARD.MONTHLY | managerPipe}}</td>-->
    <!--            </tr>-->
    <!--            <tr style="border-bottom: solid 1px rgba(240, 242, 247, 0.3);">-->
    <!--              <td class="pr-2 py-1 text-left">{{managerService.config.DASHBOARD.DEPOSIT | managerPipe}}</td>-->
    <!--              <td-->
    <!--                class="px-2 py-1">{{((1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe) ? managerService.userService.user?.daily_fiat_deposit_limit : 5000) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
    <!--              <td-->
    <!--                class="px-2 py-1">{{((1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe) ? managerService.userService.user?.monthly_fiat_deposit_limit : 100000) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
    <!--            </tr>-->
    <!--            <tr>-->
    <!--              <td class="pr-2 py-1 text-left">{{managerService.config.DASHBOARD.WITHDRAWAL | managerPipe}}</td>-->
    <!--              <td-->
    <!--                class="px-2 py-1">{{((1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe) ? managerService.userService.user?.daily_fiat_withdrawal_limit : 5000) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
    <!--              <td-->
    <!--                class="px-2 py-1">{{((1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe) ? managerService.userService.user?.monthly_fiat_withdrawal_limit : 100000) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
    <!--            </tr>-->
    <!--          </table>-->
    <!--          <svg class="position-absolute" style="height: 100px; width: 100px; transform: translate(-80px, -50px); z-index: 1;">-->
    <!--            <use [attr.xlink:href]='"./assets/images/level_one.svg#level_one"'/>-->
    <!--          </svg>-->
    <!--          <svg class="position-absolute" style="height: 100px; width: 255px;">-->
    <!--            <use [attr.fill]='(0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe) && level_one_hover ? "rgba(70, 70, 70, 0.7)" : "none"'-->
    <!--                 [attr.xlink:href]='"./assets/images/level_box_hover.svg#level_box_hover"'/>-->
    <!--          </svg>-->
    <!--          <svg *ngIf="1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe" class="position-absolute"-->
    <!--               style="height: 100px; width: 255px; color: #ffffff">-->
    <!--            <use [attr.xlink:href]='"./assets/images/level_box.svg#level_box"' fill="#ffffff"/>-->
    <!--          </svg>-->
    <!--          <button (click)="managerService.navigationManager({url: '/account/security', active_security_panel: managerService.active_security_panel_template.IDENTITY_VERIFICATION, current_component_index: 0});"-->
    <!--                  *ngIf="(0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe) && level_one_hover" class="position-absolute btn-native sign-up-login-button"-->
    <!--                  style="height: 30px; width:120px; border-radius: 16px;-->
    <!--              font-family: Roboto, sans-serif; font-size: 12px; line-height: 1.3; letter-spacing: 0.3px; color: #ffffff; z-index: 100;">-->
    <!--            {{managerService.config.DASHBOARD.LEVEL_UP | managerPipe}}-->
    <!--          </button>-->
    <!--          <span class="position-absolute" style="font-family: Roboto, sans-serif; font-size: 14px; z-index: 1;-->
    <!--              line-height: 1.3; letter-spacing: 0.65px; color: #272a3b; transform: translate(-82.5px, -45px)">{{managerService.config.DASHBOARD.LEVEL | managerPipe}}</span>-->
    <!--          <svg *ngIf="(1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_GREATER_THAN: managerService.user_pipe)" class="position-absolute" style="height: 190px; width: 200px;">-->
    <!--            <use [attr.xlink:href]='"./assets/images/level_passed.svg#level_passed"' fill="white"/>-->
    <!--          </svg>-->
    <!--        </div>-->
    <!--        <div *ngIf="!(1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_GREATER_THAN: managerService.user_pipe)" class="h-100 d-flex align-items-center justify-content-center"-->
    <!--             style="width: 18px;">-->
    <!--          <svg style="height: 18px; width: 18px;">-->
    <!--            <use [attr.xlink:href]='"./assets/images/blue_arrow.svg#blue_arrow"' fill="#40b4c8"/>-->
    <!--          </svg>-->
    <!--        </div>-->
    <!--        <div (mouseenter)="level_two_hover=true;" (mouseleave)="level_two_hover=false;" class=" h-100 d-flex align-items-center justify-content-center" style="min-width: 225px;">-->
    <!--          <table *ngIf="!(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_GREATER_THAN: managerService.user_pipe)" class="h-50 text-right"-->
    <!--                 style="min-width: 200px;">-->
    <!--            <tr>-->
    <!--              <td class="pr-1 py-1 text-left">{{managerService.config.DASHBOARD.LIMITS | managerPipe}}</td>-->
    <!--              <td class="pr-1 py-1">{{managerService.config.DASHBOARD.DAILY | managerPipe}}</td>-->
    <!--              <td class="px-1 py-1">{{managerService.config.DASHBOARD.MONTHLY | managerPipe}}</td>-->
    <!--            </tr>-->
    <!--            <tr style="border-bottom: solid 1px rgba(240, 242, 247, 0.3);">-->
    <!--              <td class="pr-1 py-1 text-left">{{managerService.config.DASHBOARD.DEPOSIT | managerPipe}}</td>-->
    <!--              <td [class.pl-4]="(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)"-->
    <!--                  class="px-1 py-1">-->
    <!--                {{((2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe) ? managerService.userService.user?.daily_fiat_deposit_limit : 500000) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
    <!--              <td [class.pl-4]="(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)"-->
    <!--                  class="px-1 py-1">-->
    <!--                {{((2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe) ? managerService.userService.user?.monthly_fiat_deposit_limit : 3000000) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
    <!--            </tr>-->
    <!--            <tr>-->
    <!--              <td class="pr-1 py-1 text-left">{{managerService.config.DASHBOARD.WITHDRAWAL | managerPipe}}</td>-->
    <!--              <td [class.pl-4]="(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)"-->
    <!--                  class="px-1 py-1">-->
    <!--                {{((2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe) ? managerService.userService.user?.daily_fiat_withdrawal_limit : 500000) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
    <!--              <td [class.pl-4]="(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)"-->
    <!--                  class="px-1 py-1">-->
    <!--                {{((2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe) ? managerService.userService.user?.monthly_fiat_withdrawal_limit : 3000000) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
    <!--            </tr>-->
    <!--          </table>-->
    <!--          <svg *ngIf="2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe"-->
    <!--               class="position-absolute"-->
    <!--               style="height: 140px; width: 300px;">-->
    <!--            <use [attr.xlink:href]='"./assets/images/level_box.svg#level_box"' fill="#ffffff"/>-->
    <!--          </svg>-->
    <!--          <svg class="position-absolute" style="height: 100px; width: 100px; transform: translate(-85px, -50px); z-index: 1;">-->
    <!--            <use [attr.xlink:href]='"./assets/images/level_two.svg#level_two"'/>-->
    <!--          </svg>-->
    <!--          <svg *ngIf="!(0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)" class="position-absolute" style="height: 100px; width: 300px;">-->
    <!--            <use [attr.fill]='(1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe) && level_two_hover ? "rgba(70, 70, 70, 0.7)" : "none"' [attr.xlink:href]='"./assets/images/level_box_hover.svg#level_box_hover"'-->
    <!--                 fill="#595e7e"></use>-->
    <!--          </svg>-->
    <!--          <button (click)="managerService.navigationManager({url: '/account/security', active_security_panel: managerService.active_security_panel_template.IDENTITY_VERIFICATION, current_component_index: 0});"-->
    <!--                  *ngIf="(1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe) && level_two_hover" class="position-absolute btn-native sign-up-login-button"-->
    <!--                  style="height: 30px; width:120px; border-radius: 16px;-->
    <!--              font-family: Roboto, sans-serif; font-size: 12px; line-height: 1.3; letter-spacing: 0.3px; color: #ffffff;">-->
    <!--            {{managerService.config.DASHBOARD.LEVEL_UP | managerPipe}}-->
    <!--          </button>-->
    <!--          <span class="position-absolute" style="font-family: Roboto, sans-serif; font-size: 14px; z-index: 1;-->
    <!--              line-height: 1.3; letter-spacing: 0.65px; color: #272a3b; transform: translate(-87.5px, -45px)">-->
    <!--                {{managerService.config.DASHBOARD.LEVEL | managerPipe}}-->
    <!--              </span>-->
    <!--          <svg *ngIf="(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_GREATER_THAN: managerService.user_pipe)" class="position-absolute" style="height: 190px; width: 200px;">-->
    <!--            <use [attr.xlink:href]='"./assets/images/level_passed.svg#level_passed"' fill="white"/>-->
    <!--          </svg>-->
    <!--        </div>-->
    <!--        <div *ngIf="(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)" style="width: 430px;"></div>-->
    <!--      </div>-->
    <!--      <svg *ngIf="(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)" class="position-absolute align-self-end" style="height: 200px;">-->
    <!--        <defs>-->
    <!--          <linearGradient id="level_two_texture_gradient" x1="0%" x2="50%" y1="0%" y2="100%">>-->
    <!--            <stop offset="0%" style="stop-color:rgb(255, 255, 255); stop-opacity: 0.4;"/>-->
    <!--            <stop offset="100%" style="stop-color:rgb(255, 255, 255); stop-opacity: 0.1;"/>-->
    <!--          </linearGradient>-->
    <!--        </defs>-->
    <!--        <use [attr.xlink:href]='"./assets/images/level_two_texture.svg#level_two_texture"' fill="url(#level_two_texture_gradient)"/>-->
    <!--      </svg>-->
    <!--    </div>-->

    <div class="w-100 d-flex flex-row justify-content-between align-items-center"
         style="font-family: Roboto, sans-serif; font-size: 14px; color: #fcfdff; background-color: #272a3b;
         box-shadow: 2px 2px 9px 0 rgba(193, 196, 206, 0.5); border-radius: 4px; margin-bottom: 10px;">
      <div class="px-3 pt-4 pb-2">
        <div style="font-family: bebas-neue-bold, sans-serif; font-size: 24px; letter-spacing: 2.45px;">
          {{managerService.config.DASHBOARD.WELCOME | managerPipe: managerService.mpa_template.TO_LOCAL_UPPER_CASE}}
        </div>
        <div>
          {{managerService.userService.user?.email | managerPipe}}
          <span [class.d-none]="(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)"
                class="ms-3" style="color: var(--artiox-blue)">
              {{managerService.config.DASHBOARD.LEVEL | managerPipe}}
            {{managerService.userService.user?.account_level | managerPipe}}
            </span>
        </div>
        <div [class.d-none]="(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)"
             class="position-relative">
          <div class="position-relative mt-3 d-flex flex-row justify-content-start align-items-center" style="z-index: 1">
            <div class="me-2 my-2 d-flex flex-column justify-content-start align-items-start" style="width: 110px; height: 100px;">
              <svg class="mb-2" style="height: 16px; width: 16px; margin-left: 18px">
                <use [attr.xlink:href]='"./assets/images/tick.svg#tick"' fill="var(--artiox-blue)"/>
              </svg>
              <div class="mb-3">
                {{managerService.config.DASHBOARD.LEVEL | managerPipe}} 0
              </div>
            </div>
            <div class="mx-2 my-2 d-flex flex-column justify-content-start align-items-center" style="width: 162px; height: 100px;">
              <svg [class.d-none]="(0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)"
                   class="mb-2" style="height: 16px; width: 16px;">
                <use [attr.xlink:href]='"./assets/images/tick.svg#tick"' fill="var(--artiox-blue)"/>
              </svg>
              <div [class.d-none]="(0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_GREATER_THAN: managerService.user_pipe)"
                   class="mb-2 d-flex flex-row justify-content-center align-items-center"
                   style="width: 16px; height: 16px; font-size: 11px; line-height: 1; border-radius: 16px; color: #272a3b; background-color: #fcfdff">
                1
              </div>
              <div class="mb-3">
                {{managerService.config.DASHBOARD.LEVEL | managerPipe}} 1
              </div>
              <button
                  (click)="managerService.navigationManager({url: '/account/security', active_security_panel: managerService.active_security_panel_template.IDENTITY_VERIFICATION, current_component_index: 0});"
                  *ngIf="(0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)"
                  class="btn-native sign-up-login-button"
                  style="height: 36px; width:162px; border-radius: 18px; font-family: Roboto, sans-serif; font-size: 15px; letter-spacing: 0.75px; color: #ffffff;">
                {{managerService.config.DASHBOARD.LEVEL_UP | managerPipe}}
              </button>
            </div>
            <div class="mx-2 my-2 d-flex flex-column justify-content-start align-items-center" style="width: 162px; height: 100px;">
              <div class="mb-2 d-flex flex-row justify-content-center align-items-center"
                   style="width: 16px; height: 16px; font-size: 11px; line-height: 1; border-radius: 16px; color: #272a3b; background-color: #fcfdff">
                2
              </div>
              <div class="mb-3">
                {{managerService.config.DASHBOARD.LEVEL | managerPipe}} 2
              </div>
              <button
                  (click)="managerService.navigationManager({url: '/account/security', active_security_panel: managerService.active_security_panel_template.IDENTITY_VERIFICATION, current_component_index: 0});"
                  *ngIf="(1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)"
                  class="btn-native sign-up-login-button"
                  style="height: 36px; width:162px; border-radius: 18px; font-family: Roboto, sans-serif; font-size: 15px; letter-spacing: 0.75px; color: #ffffff;">
                {{managerService.config.DASHBOARD.LEVEL_UP | managerPipe}}
              </button>
            </div>
          </div>
          <div class="position-absolute" style="left: 20px; top: 16px; width: 360px; border-top: var(--artiox-blue) dashed 1px; z-index: 0"></div>
        </div>
        <div [class.d-none]="!(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)"
             class="mt-5 mb-4">
          <div class="mb-2" style="font-size: 14px; color: var(--artiox-blue)">
            {{managerService.config.DASHBOARD.LEVEL | managerPipe}} 2
          </div>
          <div style="font-size: 11px;">
            {{managerService.config.DASHBOARD.HIGHEST_LEVEL | managerPipe}}
          </div>
        </div>
      </div>
      <div style="height: 220px; overflow: hidden;">
        <svg class="position-relative align-self-end" height="360px" style="margin-top: -60px" width="420px">
          <use [attr.xlink:href]='"./assets/images/level_two_texture.svg#level_two_texture"' fill="#404352"/>
        </svg>
      </div>
    </div>

    <div class="w-100 d-flex flex-row"
         style=" background-color: #fcfdff; max-height: 280px; min-height: 280px;
         box-shadow: 2px 2px 9px 0 rgba(193, 196, 206, 0.5); border-radius: 4px; margin-bottom: 10px;">
      <div class="w-100 pb-2">
        <canvas #user_balance_history_chart_canvas style="z-index: 1">
        </canvas>
      </div>
    </div>

    <div class="w-100 d-flex flex-row "
         style="background-color: #fcfdff; height: calc(100% - 520px); min-height: 300px; box-shadow: 2px 2px 9px 0 rgba(193, 196, 206, 0.5); border-radius: 4px;">
      <div class="d-flex flex-column justify-content-between pl-3">
        <div class="mt-4 mb-2"
             style="font-family: bebas-neue-regular, sans-serif; font-size: 22px; font-weight: bold; line-height: 1.1; letter-spacing: 1.8px; color: #404352;">
          {{managerService.config.DASHBOARD.PORTFOLIO | managerPipe}}
        </div>
        <div class="d-flex flex-column" style="height: 225px; width: 225px;">
          <canvas #user_balance_pie_chart_canvas style="z-index: 1"></canvas>
          <div *ngIf="managerService.userService.user_ready | managerPipe"
               class="position-absolute d-flex flex-column align-items-center justify-content-center"
               style="height: 225px; width: 225px;">
            <div>{{managerService.config.ADVANCED.USER_BALANCE.TOTAL_BALANCE | managerPipe}}</div>
            <div>{{(managerService.user_pipe | managerPipe: managerService.mpa_template.GET_USER_TOTAL_ART_PRODUCT_TYPE_OF_CURRENCY: managerService.pair_pipe: managerService.currency_pair_pipe) + (managerService.user_pipe | managerPipe: managerService.mpa_template.GET_USER_TOTAL_CURRENCY_TYPE_OF_CURRENCY: managerService.pair_pipe: managerService.currency_pair_pipe) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}₺</div>
          </div>
          <div *ngIf="!managerService.logged_in | managerPipe"
               class="position-absolute d-flex flex-column align-items-center justify-content-center"
               style="height: 225px; width: 225px;">
            <div class="font-weight-bold"
                 style="font-size: 14px;">{{managerService.config.SIGN_UP_OR_LOGIN.HEADING_BALANCE | managerPipe}}</div>
            <div><a (click)="managerService.navigationManager({url: '/sign-up/', current_component_index: 0})"
                    class="text-highlight text-info">{{managerService.config.SIGN_UP_OR_LOGIN.SIGN_UP | managerPipe}}</a>
              {{managerService.config.SIGN_UP_OR_LOGIN.OR | managerPipe}}
              <a (click)="managerService.openModal('login-modal')"
                 class="text-highlight text-info">{{managerService.config.SIGN_UP_OR_LOGIN.LOGIN | managerPipe}}</a>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <div class="w-100 d-flex flex-column align-items-center justify-content-center flex-grow-1 mx-3" style="min-width: 400px; max-height: 100%">
        <div *ngIf="managerService.userService.user_ready | managerPipe"
             class="w-100 d-flex flex-row align-items-center justify-content-center my-2">
          <span class="my-1 text-right"
                style="width: 16%; font-family: Roboto, sans-serif; font-size: 13px; font-weight: bold; line-height: 1; letter-spacing: 0.1px; color: #404352;">
          </span>
          <span class="my-1 text-right"
                style="width: 28%; font-family: Roboto, sans-serif; font-size: 13px; font-weight: bold; line-height: 1; letter-spacing: 0.1px; color: #404352;">
            {{managerService.config.ADVANCED.USER_BALANCE.AVAILABLE | managerPipe}}
          </span>
          <span class="my-1 text-right"
                style="width: 28%; font-family: Roboto, sans-serif; font-size: 13px; font-weight: bold; line-height: 1; letter-spacing: 0.1px; color: #404352;">
            {{managerService.config.ADVANCED.USER_BALANCE.IN_ORDER | managerPipe}}
          </span>
          <span class="pr-2 my-1 text-right"
                style="width: 28%; font-family: Roboto, sans-serif; font-size: 13px; font-weight: bold; line-height: 1; letter-spacing: 0.1px; color: #404352;">
            {{managerService.config.ADVANCED.USER_BALANCE.TOTAL | managerPipe}}
          </span>
        </div>
        <div class="w-100 overflow-auto">
          <div
              *ngFor="let user_asset of (managerService.user_pipe | managerPipe: managerService.mpa_template.GET_USER_ASSETS: managerService.pair_pipe)"
              class="w-100 d-flex flex-column align-items-center justify-content-center py-2 user-asset">
            <div class="w-100 d-flex flex-row align-items-start justify-content-end">
              <span [style]="user_asset | managerPipe: managerService.mpa_template.GET_USER_ASSETS_COLOR"
                    style="height: 18px; width: 18px; border-radius: 2px;">
              </span>
              <span class="my-1 text-right"
                    style="width: 42px; font-family: Roboto, sans-serif; font-size: 13px; font-weight: 400; line-height: 1; letter-spacing: 0.1px; color: #404352;">
                {{user_asset | managerPipe: managerService.mpa_template.GET_USER_ASSETS_SYMBOL}}
              </span>
              <span class="py-1 text-right"
                    style="width: 28%; font-family: Roboto, sans-serif; font-size: 13px; font-weight: 400; line-height: 1; letter-spacing: 0.1px; color: #404352;">
                {{user_asset.available_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER_DECIMAL: (user_asset.currency_id === 2 ? 6 : 2)}}
              </span>
              <span class="pr-2 my-1 text-right"
                    style="width: 28%; font-family: Roboto, sans-serif; font-size: 13px; font-weight: 400; line-height: 1; letter-spacing: 0.1px; color: #404352;">
                {{user_asset| managerPipe: managerService.mpa_template.USER_INORDER_AMOUNT: managerService.user_open_orders_history_pipe | managerPipe: managerService.mpa_template.FANCY_NUMBER_DECIMAL: (user_asset.currency_id === 2 ? 6 : 2)}}
              </span>
              <span class="pr-2 my-1 text-right"
                    style="width: 28%; font-family: Roboto, sans-serif; font-size: 13px; font-weight: 400; line-height: 1; letter-spacing: 0.1px; color: #404352;">
                {{user_asset.total_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER_DECIMAL: (user_asset.currency_id === 2 ? 6 : 2)}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="h-100"
       style="margin: 0 10px; box-shadow: 2px 2px 9px 0 rgba(193, 196, 206, 0.5); background-color: #fcfdff; min-width: 260px; border-radius: 4px;">
    <div class="w-100 mt-4 mb-2 pl-5"
         style="font-family: bebas-neue-regular, sans-serif; font-size: 22px; font-weight: bold; line-height: 1.1; letter-spacing: 1.8px; color: #404352;">
      {{managerService.config.DASHBOARD.LAST_ACTIVITIES | managerPipe}}</div>
    <div class="overflow-auto" style="max-height: calc(100% - 60px);">
      <div
          *ngFor="let user_activity of (managerService.user_activity_records_pipe | managerPipe : managerService.mpa_template.GET_USER_ACTIVITY_RECORDS);"
          class="w-100 d-flex flex-row pl-3 my-1" style="flex-shrink: 0">
        <div [class]="(user_activity.attempt | managerPipe) ? 'success-icon':'fail-icon'" style=""></div>
        <div style="width: 80%; font-size: 13px; font-weight: 300; letter-spacing: 0.65px; color: #404352;">
          <div [class]="(user_activity.attempt  | managerPipe ) ? 'color-success' : 'color-fail'"
               class="my-1" style="font-family: Roboto, sans-serif;font-size: 12px; font-weight: bold; letter-spacing: 0.84px;">
            {{user_activity | managerPipe: managerService.mpa_template.GET_USER_ACTIVITY_TITLE: managerService.language_pipe}}
          </div>
          <div class="w-100">{{user_activity.ip | managerPipe}}</div>
          <div class="w-100">{{(managerService.config.ACTIVITY_TABLE | managerPipe:managerService.mpa_template.RETURN_INDEX: user_activity.code).reason | managerPipe}}</div>
          <div class="w-100">{{user_activity.creation_time | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}, {{user_activity.city | managerPipe}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
