<div class="w-100 d-flex flex-column align-items-start justify-content-start"
     style="background-color: #fcfdff; ">
  <div class="w-100 py-2 px-3 d-flex flex-column">
    <div class="align-self-start py-2" style="font-family: babes-neue-normal, sans-serif; font-size: 14px; letter-spacing: 0.7px; color: #272a3b;">
      {{managerService.config.BALANCE.USER_TRADE_HISTORY_UPPERCASE | managerPipe}}
    </div>
    <div class="d-flex flex-row align-self-end"
         style="font-family: Roboto, sans-serif; font-weight: normal; font-size: 12px; color: #595e7e;">
      <div class="mx-1">
        <button
          (click)="filter_menu.classList.toggle('d-none')"
          class="btn-native filter-dropdown-button"
          style="font-family: Roboto, sans-serif; font-weight: normal; font-size: 12px; color: #9da5b7; width: 81px; height: 20px;">
          {{pair_selected_placeholder | managerPipe}}
          <svg style="height: 14px; width: 14px;">
            <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#595e7e"/>
          </svg>
        </button>
        <div #filter_menu class="filter-dropdown-menu d-none">
          <div
            (click)="pair_selected = undefined; pair_selected_placeholder = managerService.config.BALANCE.ALL; filter_menu.classList.add('d-none');"
            [style.background-color]="((pair_selected_placeholder === managerService.config.BALANCE.ALL) | managerPipe) ? '#f4f5f9' : ''"
            class="btn-native filter-dropdown-item text-center py-1">
            {{managerService.config.BALANCE.ALL | managerPipe}}
          </div>
          <div
            (click)="pair_selected = pair.id; pair_selected_placeholder = pair.symbol; filter_menu.classList.add('d-none');"
            *ngFor="let pair of (managerService.pair_pipe | managerPipe: managerService.mpa_template.GET_SORTED_LISTED_PAIR_LIST)"
            [style.background-color]="((pair_selected_placeholder === pair.symbol) | managerPipe) ? '#f4f5f9' : ''"
            class="btn-native filter-dropdown-item text-center py-1">
            {{pair.symbol | managerPipe}}
          </div>
          <div
              (click)="pair_selected = currency_pair.id; pair_selected_placeholder = currency_pair.symbol; filter_menu.classList.add('d-none');"
              *ngFor="let currency_pair of (managerService.currency_pair_pipe | managerPipe: managerService.mpa_template.GET_SORTED_LISTED_CURRENCY_PAIR_LIST)"
              [style.border]="((pair_selected_placeholder === currency_pair.symbol) | managerPipe) ? 'solid 1px #f4f5f9' : ''"
              class="btn-native filter-dropdown-item text-center py-1">
            {{currency_pair.symbol | managerPipe}}
          </div>
        </div>
      </div>
      <div class="mx-1">
        <button
          (click)="filter_type_menu.classList.toggle('d-none')"
          class="btn-native filter-dropdown-button text-left"
          style="font-family: Roboto, sans-serif; font-weight: normal; font-size: 12px; color: #9da5b7; width: 81px; height: 20px;">
          <div [style]="trade_type_selected_placeholder | managerPipe: managerService.mpa_template.GET_USER_ORDER_HISTORY_FILTER_BACKGROUND_COLOR" class="d-inline-block ml-2 mr-1"
               style="height: 8px; width: 8px;"></div>
          {{trade_type_selected_placeholder | managerPipe}}
          <svg style="height: 14px; width: 14px;">
            <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#595e7e"/>
          </svg>
        </button>
        <div #filter_type_menu class="filter-dropdown-menu d-none">
          <div
            (click)="trade_type_selected = undefined; trade_type_selected_placeholder = managerService.config.BALANCE.ALL; filter_type_menu.classList.add('d-none');"
            [style.background-color]="((trade_type_selected_placeholder === managerService.config.BALANCE.ALL) | managerPipe) ? '#f4f5f9' : ''"
            class="btn-native filter-dropdown-item  text-center py-1">
            {{managerService.config.BALANCE.ALL | managerPipe}}
          </div>
          <div
            (click)="trade_type_selected = 'buy'; trade_type_selected_placeholder = managerService.config.BALANCE.BUY; filter_type_menu.classList.add('d-none');"
            [style.background-color]="((trade_type_selected_placeholder === managerService.config.BALANCE.BUY) | managerPipe) ? '#f4f5f9' : ''"
            class="btn-native filter-dropdown-item py-1">
            <div class="d-inline-block ml-2 mr-1" style="background-color: #2ad8a9; height: 8px; width: 8px;"></div>
            {{managerService.config.BALANCE.BUY | managerPipe}}
          </div>
          <div
            (click)="trade_type_selected = 'sell'; trade_type_selected_placeholder = managerService.config.BALANCE.SELL; filter_type_menu.classList.add('d-none');"
            [style.background-color]="((trade_type_selected_placeholder === managerService.config.BALANCE.SELL) | managerPipe) ? '#f4f5f9' : ''"
            class="btn-native filter-dropdown-item py-1">
            <div class="d-inline-block ml-2 mr-1" style="background-color: #ff207d; height: 8px; width: 8px;"></div>
            {{managerService.config.BALANCE.SELL | managerPipe}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-100 d-flex flex-row align-items-center justify-content-around px-3 py-1"
       style="font-family: Roboto, sans-serif; font-size: 10px; color: #272a3b;">
    <span class="text-left"
          style="width: 26%">{{managerService.config.ADVANCED.USER_TRADE_HISTORY.PAIR | managerPipe}}</span>
    <span class="text-center"
          style="width: 18%">{{managerService.config.ADVANCED.USER_TRADE_HISTORY.TYPE | managerPipe}}</span>
    <span class="text-center"
          style="width: 18%">{{managerService.config.ADVANCED.USER_TRADE_HISTORY.PRICE | managerPipe}}</span>
    <span class="text-center"
          style="width: 18%">{{managerService.config.ADVANCED.USER_TRADE_HISTORY.AMOUNT | managerPipe}}</span>
    <span class="text-right" style="width: 20%"></span>
  </div>
  <div class="w-100 d-flex flex-column overflow-auto" style="max-height: 460px">
    <div
        *ngFor="let user_trade of (managerService.user_trade_history_pipe | managerPipe: managerService.mpa_template.GET_USER_TRANSACTION_HISTORY: pair_selected: trade_type_selected : managerService.user_swap_history_pipe) | managerPipe: managerService.mpa_template.SORT_BY_CREATION_TIME"
      class="d-flex flex-row align-items-center justify-content-around flex-wrap px-3 user-trade-history flex-shrink-0"
      style="font-family: Roboto, sans-serif;  font-weight: 500; letter-spacing: 0.7px; font-size: 10px; color: #272a3b;">
      <div class="w-100 d-flex flex-row align-items-center justify-content-around py-2">
         <span class="text-left"
               style="width: 26%; color: #4a4e70; font-weight: 500; letter-spacing: 0.7px;">{{user_trade | managerPipe: managerService.mpa_template.TRANSACTION_SYMBOL: managerService.pair_pipe}}</span>
        <span [style]="user_trade | managerPipe: managerService.mpa_template.GET_USER_TRADE_BACKGROUND_COLOR"
              class="text-center"
              style="width: 18%">{{user_trade.side | managerPipe: managerService.mpa_template.USER_TRADE_TYPE}}</span>
        <span [style]="user_trade | managerPipe: managerService.mpa_template.GET_USER_TRADE_BACKGROUND_COLOR"
              class="text-center"
              style="width: 18%">{{user_trade.price | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
        <span [style]="user_trade | managerPipe: managerService.mpa_template.GET_USER_TRADE_BACKGROUND_COLOR"
              class="text-center"
              style="width: 18%">{{user_trade | managerPipe: managerService.mpa_template.USER_ORDER_HISTORY_AMOUNT}}</span>
        <span (click)="more_div.classList.toggle('d-none'); more_div.classList.toggle('d-flex')"
              class="text-right"
              style="width: 20%; font-size: 9px; color: #9da5b7; font-style: italic; font-weight: 500; letter-spacing: normal;" type="button">
            {{managerService.config.ADVANCED.USER_TRADE_HISTORY.MORE | managerPipe}}</span>
      </div>
      <div #more_div class="w-100 d-none flex-row flex-wrap align-items-center justify-content-around pb-1"
           style="font-family: Roboto, sans-serif; font-size: 9px; font-weight: 500; line-height: 1.2; letter-spacing: 0.7px; color: #4a4e70; background-color: inherit">
        <span class="w-50 my-1 text-left">{{managerService.config.ADVANCED.USER_TRADE_HISTORY.TOTAL | managerPipe}}</span>
        <span class="w-50 my-1 text-right">{{user_trade.amount * user_trade.price | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
        <span class="w-50 my-1 text-left">{{managerService.config.ADVANCED.USER_TRADE_HISTORY.TIME | managerPipe}}</span>
        <span class="w-50 my-1 text-right">{{user_trade.creation_time | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</span>
      </div>
    </div>
  </div>
</div>
