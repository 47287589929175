<div class="w-100 mx-auto px-2 position-relative" style="box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);  background-color: #ffffff;">
  <div class="pt-4 pb-2 pl-2" style="font-family: Roboto, sans-serif; font-size: 14px; letter-spacing: 0.7px; color: #272a3b;">
    {{managerService.config.CORPORATE.HEADER.HEADING | managerPipe}}
  </div>
  <div class="w-100 d-flex flex-row">
    <div
      (click)="managerService.navigationManager({url: '/corporate/about', current_component_index: 0})"
      [style.border-bottom]="(managerService.navigationService.active_corporate_page === managerService.navigationService.corporate_page_template.ABOUT ? 'solid 1px #4fc0d2' : '') | managerPipe"
      [style.color]="(managerService.navigationService.active_corporate_page === managerService.navigationService.corporate_page_template.ABOUT ? 'var(--artiox-blue)' : '#595e7e') | managerPipe"
      class="btn-native px-1 ml-1 mr-2"
      style="font-family: Roboto, sans-serif; font-size: 13px; cursor: pointer">
      {{managerService.config.CORPORATE.HEADER.ABOUT | managerPipe}}
    </div>
    <div
      (click)="managerService.navigationManager({url: '/corporate/announcements', current_component_index: 0})"
      [style.border-bottom]="(managerService.navigationService.active_corporate_page === managerService.navigationService.corporate_page_template.ANNOUNCEMENT ? 'solid 1px #4fc0d2' : '') | managerPipe"
      [style.color]="(managerService.navigationService.active_corporate_page === managerService.navigationService.corporate_page_template.ANNOUNCEMENT ? 'var(--artiox-blue)' : '#595e7e') | managerPipe"
      class="btn-native mx-2"
      style="font-family: Roboto, sans-serif; font-size: 13px; cursor: pointer">
      {{managerService.config.CORPORATE.HEADER.ANNOUNCEMENTS | managerPipe}}
    </div>
    <div
      (click)="managerService.navigationManager({url: '/corporate/career', current_component_index: 0})"
      [style.border-bottom]="(managerService.navigationService.active_corporate_page === managerService.navigationService.corporate_page_template.CAREER ? 'solid 1px #4fc0d2' : '') | managerPipe"
      [style.color]="(managerService.navigationService.active_corporate_page === managerService.navigationService.corporate_page_template.CAREER ? 'var(--artiox-blue)' : '#595e7e') | managerPipe"
      class="btn-native px-1 mx-2"
      style="font-family: Roboto, sans-serif; font-size: 13px; cursor: pointer">
      {{managerService.config.CORPORATE.HEADER.CAREER | managerPipe}}
    </div>
    <div
      (click)="managerService.navigationManager({url: '/corporate/contact', current_component_index: 0})"
      [style.border-bottom]="(managerService.navigationService.active_corporate_page === managerService.navigationService.corporate_page_template.CONTACT ? 'solid 1px #4fc0d2' : '') | managerPipe"
      [style.color]="(managerService.navigationService.active_corporate_page === managerService.navigationService.corporate_page_template.CONTACT ? 'var(--artiox-blue)' : '#595e7e') | managerPipe"
      class="btn-native px-1 mx-2"
      style="font-family: Roboto, sans-serif; font-size: 13px; cursor: pointer">
      {{managerService.config.CORPORATE.HEADER.CONTACT | managerPipe}}
    </div>
  </div>
</div>
