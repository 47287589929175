import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
  selector: 'app-auction-bid-approved-desktop',
  templateUrl: './auction-bid-approved-desktop.component.html',
  styleUrls: ['./auction-bid-approved-desktop.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AuctionBidApprovedDesktopComponent implements OnInit {
  @Input() modal_id: string = '';

  constructor(public managerService: ManagerService) { }

  ngOnInit(): void {
  }

}
