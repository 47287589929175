<div style="width: 557px; background: #FFFFFF;height: 100vh">

    <div *ngIf="managerService.auctionService.active_auction_product.auction_status === 'sold'" class="d-flex w-100 flex-column">
        <div class="d-flex w-100 justify-content-center">
            <div class="d-flex flex-column" style="gap: 16px;padding-left: 48px;padding-right: 48px;padding-top: 64px">
                <div class="d-flex flex-row justify-content-between">
                    <p class="header6 exo bold" style="color: #8266F0">{{managerService.config.AUCTION.PAST_PRODUCT_INFO.TITLE | managerPipe}}</p>
                    <svg (click)="managerService.closeModal(modal_id)" style="width: 24px; height: 24px; cursor: pointer">
                        <use [attr.xlink:href]='"./assets/images/close_with_circle.svg#close_with_circle"' stroke="#B5B5B5" fill="none"/>
                    </svg>
                </div>
                <p class="body2 barlow regular" style="color: #6F6F6F">{{managerService.config.AUCTION.PAST_PRODUCT_INFO.DESCRIPTION | managerPipe}}</p>
            </div>
        </div>
        <div class="pl-5 pr-5 mb-3 w-100 d-flex flex-column align-items-start justify-content-center">
            <div class="d-flex w-100 align-items-stretch justify-content-between" style="border-bottom: 1px solid #E2E2E2; padding-bottom: 16px">
                <div class=" d-flex w-100 justify-content-between">
                 <span
                     class="barlow body1 medium" style="color: #B5B5B5">
                     {{managerService.config.AUCTION.PAST_PRODUCT_INFO.OPEN | managerPipe}}
                 </span>
                    <span
                        class="barlow body1 medium" style="color: #6F6F6F">
                    {{managerService.auctionService.active_auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_OPENING_VALUE: managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
                        {{managerService.auctionService.active_auction_product.value_type | managerPipe: managerService.mpa_template.VALUE_TYPE}}
                </span>
                </div>
            </div>
        </div>

        <div class="pl-5 pr-5 mb-3 w-100 d-flex flex-column align-items-start justify-content-center">
            <div class="d-flex w-100 align-items-stretch justify-content-between" style="border-bottom: 1px solid #E2E2E2; padding-bottom: 16px">
                <div class=" d-flex w-100 justify-content-between">
                 <span
                     class="barlow body1 medium" style="color: #B5B5B5">
                     {{managerService.config.AUCTION.PAST_PRODUCT_INFO.CLOSE | managerPipe}}
                 </span>
                    <span style="color: #6F6F6F">
                    {{managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_CURRENT_PRICE}}
<!--                        {{managerService.auctionService.active_auction_product.value_type | managerPipe}}-->
                </span>
                </div>
            </div>
        </div>

        <div class="pl-5 pr-5 mb-3 w-100 d-flex flex-column align-items-start justify-content-center">
            <div class="d-flex w-100 align-items-stretch justify-content-between">
                <div class=" d-flex w-100 justify-content-between">
                 <span
                     class="barlow body1 medium" style="color: #6F6F6F">
                     {{managerService.config.AUCTION.PAST_PRODUCT_INFO.TOTAL | managerPipe}}
                 </span>
                    <span
                        class="barlow body1 medium" style="color: #6F6F6F">
                    {{managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_TOTAL_PRICE: managerService.auctionService.active_auction_product_pipe}}
<!--                        {{managerService.auctionService.active_auction_product.value_type | managerPipe}}-->
                </span>
                </div>
            </div>
        </div>

        <!--    <div style="width: 445px;height: 0px;border: 0.1px solid #D8D8D8; border-radius: 4px; margin-left: 40px; margin-right: 40px; margin-bottom: 10px"></div>-->
    </div>

    <div *ngIf="managerService.auctionService.active_auction_product.auction_status === 'unsold'" class="d-flex w-100 flex-column">
        <div class="d-flex w-100 justify-content-center">
            <div class="d-flex flex-column" style="gap: 16px;padding-left: 48px;padding-right: 48px;padding-top: 64px">
                <div class="d-flex flex-row justify-content-between">
                    <p class="header6 exo bold" style="color: #8266F0">{{managerService.config.AUCTION.PAST_PRODUCT_INFO.TITLE | managerPipe}}</p>
                    <svg (click)="managerService.closeModal(modal_id)" style="width: 24px; height: 24px; cursor: pointer">
                        <use [attr.xlink:href]='"./assets/images/close_with_circle.svg#close_with_circle"' stroke="#B5B5B5" fill="none"/>
                    </svg>
                </div>
                <p class="body2 barlow regular" style="color: #6F6F6F">{{managerService.config.AUCTION.PAST_PRODUCT_INFO.DESCRIPTION | managerPipe}}</p>
            </div>
        </div>
        <div class="pl-5 pr-5 mb-3 w-100 d-flex flex-column align-items-start justify-content-center">
            <div class="d-flex w-100 align-items-stretch justify-content-between" style="border-bottom: 1px solid #E2E2E2; padding-bottom: 16px">
                <div class=" d-flex w-100 justify-content-between">
                 <span
                     class="barlow body1 medium" style="color: #B5B5B5">
                     {{managerService.config.AUCTION.PAST_PRODUCT_INFO.OPEN | managerPipe}}
                 </span>
                    <span
                        class="barlow body1 medium" style="color: #6F6F6F">
                    {{managerService.auctionService.active_auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_OPENING_VALUE: managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
                        {{managerService.auctionService.active_auction_product.value_type | managerPipe: managerService.mpa_template.VALUE_TYPE}}
                </span>
                </div>
            </div>
        </div>

        <div class="pl-5 pr-5 mb-3 w-100 d-flex flex-column align-items-start justify-content-center">
            <div class="d-flex w-100 align-items-stretch justify-content-between" style="border-bottom: 1px solid #E2E2E2; padding-bottom: 16px">
                <div class=" d-flex w-100 justify-content-between">
                 <span
                     class="barlow body1 medium" style="color: #B5B5B5">
                     {{managerService.config.AUCTION.PAST_PRODUCT_INFO.CLOSE | managerPipe}}
                 </span>
                    <span
                        class="barlow body1 medium" style="color: #6F6F6F">
                    -
                </span>
                </div>
            </div>
        </div>

        <div class="pl-5 pr-5 mb-3 w-100 d-flex flex-column align-items-start justify-content-center">
            <div class="d-flex w-100 align-items-stretch justify-content-between" >
                <div class=" d-flex w-100 justify-content-between">
                 <span
                     style="font-family: Roboto, sans-serif;font-style: normal;font-weight: 500;font-size: 14px;line-height: 16px;display: flex;align-items: center;text-align: center;letter-spacing: 0.001em;color: #686C75;">
                     {{managerService.config.AUCTION.PAST_PRODUCT_INFO.TOTAL | managerPipe}}
                 </span>
                    <span style="color: #6F6F6F">
                    -
                </span>
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex w-100 justify-content-center align-items-end" style="position:absolute; bottom: 10px">
        <svg style=" height: 32px; width: 138px; margin-top: 32px; margin-bottom: 60px">
            <use [attr.xlink:href]='"./assets/images/artiox_logo.svg#artiox_logo"' fill="#000"/>
        </svg>
    </div>
</div>
