import {AfterViewInit, Component} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
  selector: 'app-slider-mobile',
  templateUrl: './slider-mobile.component.html',
  styleUrls: ['./slider-mobile.component.css']
})
export class SliderMobileComponent implements AfterViewInit {

  public current_slider_index: number = 0;
  public slider_window = 0;
  public interval: any;

  constructor(public managerService: ManagerService) {
    // if (this.managerService.environment.app){
    //   this.slider_window = 3;
    // }
    // else {
      this.slider_window = 4;
    // }
  }

  ngAfterViewInit(): void {
    this.interval = setInterval(() => {
      this.swipeLeft();
    }, 10000);
  }

  swipeLeft(): void {
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.swipeLeft();
    }, 10000);
    this.current_slider_index = this.current_slider_index + 1;
    this.current_slider_index = this.current_slider_index + this.slider_window;
    this.current_slider_index = this.current_slider_index % this.slider_window;
  }

  swipeRight(): void {
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.swipeLeft();
    }, 10000);
    this.current_slider_index = this.current_slider_index - 1;
    this.current_slider_index = this.current_slider_index + this.slider_window;
    this.current_slider_index = this.current_slider_index % this.slider_window;
  }
}
