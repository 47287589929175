import {Component, OnInit, ViewEncapsulation, ElementRef, ViewChildren, QueryList, OnDestroy} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';
import {Subscription} from 'rxjs';
import {BillingAddress, DeliveryAddress} from '../../../../../../services/user.service';

@Component({
    selector: 'app-user-info-auction-address-desktop',
    templateUrl: './user-info-auction-address-desktop.component.html',
    styleUrls: ['./user-info-auction-address-desktop.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class UserInfoAuctionAddressDesktopComponent implements OnInit, OnDestroy {
    @ViewChildren('delivery_dropdown') delivery_dropdowns: QueryList<ElementRef> | undefined;
    @ViewChildren('billing_dropdown') billing_dropdowns: QueryList<ElementRef> | undefined;

    delivery_address_count: number = 0;
    billing_address_count: number = 0;

    subscription_list: (Subscription | undefined)[] = [];

    constructor(protected managerService: ManagerService) {
        this.delivery_address_count = this.managerService.userService.user_info?.delivery_addresses?.length || 0;
        this.billing_address_count = this.managerService.userService.user_info?.billing_information?.length || 0;
        this.subscription_list.push(this.managerService.userService.user_info_changed.subscribe(() => {
            this.delivery_address_count = this.managerService.userService.user_info?.delivery_addresses?.length || 0;
            this.billing_address_count = this.managerService.userService.user_info?.billing_information?.length || 0;
        }));
    }

    ngOnInit(): void {
    }

    toggleDropdown(div: HTMLDivElement): void {
        if (div.style.display === 'block') {
            div.style.display = 'none';
        } else {
            div.style.display = 'block';
        }
    }

    closeAllDropdown(): void {
        this.delivery_dropdowns?.forEach(item => {
            item.nativeElement.style.display = 'none';
        });
        this.billing_dropdowns?.forEach(item => {
            item.nativeElement.style.display = 'none';
        });
    }

    // addDeliveryAddress(): void {
    //     this.managerService.userService.update_selected_user_address = false;
    //     this.managerService.userService.selected_user_delivery_address_changed.emit(null);
    //     this.managerService.openModal('add-delivery-address-modal');
    // }
    //
    // addBillingAddress(): void {
    //     this.managerService.userService.update_selected_user_address = false;
    //     this.managerService.userService.selected_user_billing_address_changed.emit(null);
    //     this.managerService.openModal('add-billing-address-modal');
    // }
    //
    // updateDeliveryAddress(address: DeliveryAddress): void {
    //     this.managerService.userService.update_selected_user_address = true;
    //     this.managerService.userService.selected_user_delivery_address_changed.emit(address);
    //     this.managerService.openModal('add-delivery-address-modal');
    // }
    //
    // updateBillingAddress(address: BillingAddress): void {
    //     this.managerService.userService.update_selected_user_address = true;
    //     this.managerService.userService.selected_user_billing_address_changed.emit(address);
    //     this.managerService.openModal('add-billing-address-modal');
    // }

    // deleteAddress(address_id: any, type: 'delivery' | 'billing'): void {
    //     this.managerService.userService.selected_user_address_id = address_id;
    //     this.managerService.userService.selected_user_address_type = type;
    //     // this.managerService.userService.selected_user_address_ready = true;
    //     this.managerService.openModal('delete-address-modal');
    // }

    ngOnDestroy(): void {
        this.subscription_list.forEach((subscription) => {
            subscription?.unsubscribe();
        });
    }
}
