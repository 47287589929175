<div class="w-100 d-flex flex-column align-items-center justify-content-center">
    <div class="p-2"
         style="font-family: bebas-neue-regular, sans-serif;font-size: 17px; font-weight: bold; line-height: 1.2; letter-spacing: 2.5px;  color: #404352;">
        {{managerService.config.DEPOSIT.RULES_TITLE | managerPipe}}
    </div>
    <div class="p-1" style="font-family: Roboto, sans-serif; font-size: 12px; font-style: italic; line-height: 1.1; letter-spacing: 0.4px; color: #4a4e70;">
        <div *ngFor="let rule of managerService.config.DEPOSIT.ETHEREUM_DEPOSIT_RULES | managerPipe" class="mb-2">
            {{rule | managerPipe}}
        </div>
    </div>
    <label class="p-1 align-self-start d-flex flex-row align-items-start justify-content-start checkbox-text-black">
        <input #checkbox_field_mobile class="checkbox-field" style="height: 20px; min-width: 20px;" type="checkbox">
        <span class="mx-1 my-1">{{ managerService.config.BALANCE.READ_ACCEPTED | managerPipe}}</span>
    </label>
    <button (click)="onAccept()"
            [class.button-inactive]="!checkbox_field_mobile.checked"
            [disabled]="!checkbox_field_mobile.checked"
            class="btn-native checkbox-button">
        {{managerService.config.BALANCE.NEXT | managerPipe}}
    </button>
</div>
