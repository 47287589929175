import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren, ViewEncapsulation} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
  selector: 'app-operation-announcement-list-mobile',
  templateUrl: './operation-announcement-list-mobile.component.html',
  styleUrls: ['./operation-announcement-list-mobile.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class OperationAnnouncementListMobileComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren('operation_announcement') operation_announcements: QueryList<ElementRef> = new QueryList();

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const i = this.managerService.navigationService.operation_announcement_active_index;
    if (i !== -1) {
      setTimeout(() => {
        // this.operation_announcements.get(i)?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        let offsetTop: number = this.operation_announcements.get(i)?.nativeElement.offsetTop;
        // if (this.managerService.environment.app) {
        //   const app_header_height: number = document.getElementById('app-header')?.offsetHeight ?? 0;
        //   offsetTop -= app_header_height;
        // }
        window.scrollTo({top: offsetTop, left: 0, behavior: 'smooth'});
      });
    }
  }

  ngOnDestroy(): void {
    for (let i = 0; i < this.managerService.config.ANNOUNCEMENTS.OPERATION_ANNOUNCEMENTS.OPERATION_LIST.length; i++){
      this.managerService.resetToggleText(i);
    }
  }
}
