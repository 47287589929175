<div #announcement_card_container (swipeleft)="swipeLeft()" (swiperight)="swipeRight()" class="d-flex flex-row align-items-center justify-content-start overflow-hidden m-auto"
     style="height: 200px; width: 90%">
    <svg (click)="swipeRight()" class="position-absolute"
         style="height:16px; width: 16px; min-width: 16px; margin-right: 20px; z-index: 1; left: 2.5%;">
        <use [attr.xlink:href]='"./assets/images/left_arrow.svg#left_arrow"' class="w-100" fill="black"/>
    </svg>
    <svg (click)="swipeLeft()" class="position-absolute"
         style="height:16px; width: 16px; min-width: 16px; margin-left: 20px; z-index: 1; right: 2.5%;">
        <use [attr.xlink:href]='"./assets/images/right_arrow.svg#right_arrow"' class="w-100" fill="black"/>
    </svg>
    <div *ngFor="let announcement of managerService.config.ANNOUNCEMENTS_SLIDER; let i=index;">
        <div *ngIf="announcement.RELEASE_DATE | managerPipe: managerService.mpa_template.CHECK_TIME_ARRIVED | async"
             (click)="managerService.navigationManager(announcement.LINK)"
             [style]="{transform: 'translateX(' + ((current_slider_index | managerPipe) * -332).toString() + 'px)'}"
             class="d-flex flex-row mx-3 announcement-card"
             style="max-width: 300px; min-width: 300px; height: 150px;
         border-radius: 4px; box-shadow: 1px 2px 4px 0 rgba(45, 52, 104, 0.3); background-color: #ffffff;"
             type="button">
            <div>
                <h4 class="px-2 py-2"
                    style="font-family: Roboto, sans-serif; font-size: 20px; letter-spacing: 1px; color: #404352; margin-bottom: 0 ">{{announcement.HEADING | managerPipe}}</h4>
                <h5 class="pt-1 px-2 text-info-arrow"
                    style="font-family: Roboto, sans-serif; font-size: 16px; font-weight: 450; letter-spacing: 0.7px;">{{announcement.TOPIC | managerPipe}}</h5>
                <h6 [innerHTML]="announcement.TEXT | managerPipe: managerService.mpa_template.TEXT_LIMIT: announcement.TEXT_LIMIT_DESKTOP"
                    class="px-2"
                    style="font-family: Roboto, sans-serif; font-size: 13px; font-weight: 400; letter-spacing: 0.2px; line-height: 1.4; color: #9da5b7;"></h6>
            </div>
            <img [src]="announcement.IMAGE_SRC" alt="announcement" class="announcement-img" height="150px" #announce
                 (error)="announce.src = announce.src.replace('.webp', '.jpg')">
        </div>
    </div>

</div>
