<div class="w-100 d-flex flex-row align-items-start justify-content-around">
    <form [formGroup]="limitBuyForm" class="d-flex flex-column align-items-center justify-content-center"
          style="width: 45%">
        <div class="w-100 d-flex flex-row align-items-center justify-content-center my-3 exo subtitle1 bold"
             style="   line-height: 1.2;  color: #404352;">
            {{[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.ADVANCED.TRADE.HEADER_BUY}}
        </div>
        <div class="w-100 d-flex flex-row align-items-center justify-content-end mb-1 barlow caption medium"
             style="line-height: 1.4; text-align: left; color: #404352;">
            <div class="w-66 d-flex flex-row align-items-center justify-content-center">
        <span
            class="w-33 text-left">{{[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_CURRENCY_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.BASIC.TRADE.AVAILABLE_AMOUNT}}</span>
                <span class="w-66 text-right font-weight-bold"
                      style="padding-right:35px;">{{managerService.pair_pipe | managerPipe: managerService.mpa_template.USER_ACTIVE_CURRENCY_AVAILABLE_AMOUNT: managerService.user_pipe}}</span>
            </div>
        </div>
        <div class="w-100 d-flex flex-row align-items-center justify-content-center barlow caption bold"
             style=" line-height: 1.6;  color: #404352;">
            <label
                [innerHTML]="[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_CURRENCY_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.ADVANCED.TRADE.PRICE"
                class="w-33 d-flex flex-row align-items-center justify-content-start
">
            </label>
            <label class="w-66 d-flex flex-row align-items-center justify-content-start">
                <input class="w-100 input-field-trade text-right" formControlName="price" min="0.00" placeholder="0" required step="0.01"
                       style="height: 30px;" type="number">
            </label>
        </div>
        <div class="w-100 d-flex flex-row align-items-center justify-content-center barlow caption bold"
             style="line-height: 1.6;  color: #404352;">
            <label
                [innerHTML]="[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.ADVANCED.TRADE.AMOUNT"
                class="w-33 d-flex flex-row align-items-center justify-content-start
">
            </label>
            <label class="w-66 d-flex flex-row align-items-center justify-content-start">
                <input class="w-100 input-field-trade text-right" formControlName="amount" min="0.00" placeholder="0" required
                       type="number">
            </label>
        </div>
        <div class="w-100 d-flex flex-row align-items-center justify-content-center barlow caption bold"
             style=" line-height: 1.6; color: #404352;">
            <label
                [innerHTML]="[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_CURRENCY_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.ADVANCED.TRADE.TOTAL"
                class="w-33 d-flex flex-row align-items-center justify-content-start
">
            </label>
            <label class="w-66 d-flex flex-row align-items-center justify-content-start">
                <input class="w-100 input-field-trade text-right" disabled min="0.00" placeholder="0" readonly
                       type="number"
                       value="{{limit_buy_total | managerPipe}}">
            </label>
        </div>
        <button (click)="managerService.navigationManager({url: '/sign-up', current_component_index: 0})" *ngIf="!managerService.logged_in | managerPipe"
                class="w-100 btn-native sign-up-login-button mx-auto py-2"
                style="border-radius: 4px;">
            {{managerService.config.SIGN_UP_OR_LOGIN.SIGN_UP | managerPipe}}
        </button>
        <button (click)="limitBuy()" *ngIf="managerService.userService.user_ready | managerPipe"
                class="w-100 btn-native barlow buy-button mx-auto py-2 exo subtitle1 bold"
                style="border-radius: 4px;">
            {{managerService.config.BASIC.TRADE.BUY | managerPipe}}
        </button>
    </form>
    <form [formGroup]="limitSellForm" class="d-flex flex-column align-items-center justify-content-center"
          style="width: 45%">
        <div class="w-100 d-flex flex-row align-items-center justify-content-center my-3 exo subtitle1 bold"
             style="   line-height: 1.2; color: #404352;">
            {{[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.ADVANCED.TRADE.HEADER_SELL}}
        </div>
        <div class="w-100 d-flex flex-row align-items-center justify-content-end mb-1 barlow caption medium"
             style=" line-height: 1.4; text-align: left; color: #404352;">
            <div class="w-66 d-flex flex-row align-items-center justify-content-center">
        <span
            class="w-33 text-left">{{[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.BASIC.TRADE.AVAILABLE_AMOUNT}}</span>
                <span class="w-66 text-right font-weight-bold "
                      style="padding-right:35px;">{{managerService.pair_pipe | managerPipe: managerService.mpa_template.USER_ACTIVE_ART_PRODUCT_AVAILABLE_AMOUNT: managerService.user_pipe}}</span>
            </div>
        </div>
        <div class="w-100 d-flex flex-row align-items-center justify-content-center barlow caption bold"
             style=" line-height: 1.6; color: #404352;">
            <label
                [innerHTML]="[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_CURRENCY_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.ADVANCED.TRADE.PRICE"
                class="w-33 d-flex flex-row align-items-center justify-content-start ">
            </label>
            <label class="w-66 d-flex flex-row align-items-center justify-content-start">
                <input class="w-100 input-field-trade text-right" formControlName="price" min="0.00" placeholder="0" required step="0.01"
                       type="number">
            </label>
        </div>
        <div class="w-100 d-flex flex-row align-items-center justify-content-center barlow caption bold"
             style="line-height: 1.6;  color: #404352;">
            <label
                [innerHTML]="[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.ADVANCED.TRADE.AMOUNT"
                class="w-33 d-flex flex-row align-items-center justify-content-start ">
            </label>
            <label class="w-66 d-flex flex-row align-items-center justify-content-start">
                <input class="w-100 input-field-trade text-right" formControlName="amount" min="0.00" placeholder="0" required
                       type="number">
            </label>
        </div>
        <div class="w-100 d-flex flex-row align-items-center justify-content-center barlow caption bold"
             style=" line-height: 1.6;  color: #404352;">
            <label
                [innerHTML]="[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_CURRENCY_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.ADVANCED.TRADE.TOTAL"
                class="w-33 d-flex flex-row align-items-center justify-content-start ">
            </label>
            <label class="w-66 d-flex flex-row align-items-center justify-content-start">
                <input class="w-100 input-field-trade text-right" disabled min="0.00" placeholder="0" readonly
                       type="number"
                       value="{{limit_sell_total | managerPipe}}">
            </label>
        </div>
        <button (click)="managerService.openModal('login-modal')" *ngIf="!managerService.logged_in | managerPipe"
                class="w-100 btn-native sign-up-login-button mx-auto py-2"
                style="border-radius: 4px;">
            {{managerService.config.SIGN_UP_OR_LOGIN.LOGIN | managerPipe}}
        </button>
        <button (click)="limitSell()" *ngIf="managerService.userService.user_ready | managerPipe"
                class="w-100 btn-native barlow sell-button mx-auto py-2 exo subtitle1 bold"
                style="border-radius: 4px;">
            {{managerService.config.BASIC.TRADE.SELL | managerPipe}}
        </button>
    </form>
</div>
