import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../../../services/manager.service';

@Component({
  selector: 'app-order-book-desktop',
  templateUrl: './order-book-desktop.component.html',
  styleUrls: ['./order-book-desktop.component.css']
})
export class OrderBookDesktopComponent implements OnInit {

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }
}
