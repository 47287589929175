<div #window_one class="d-flex flex-column align-items-center justify-content-around">
  <app-pair-header-mobile></app-pair-header-mobile>
  <app-trade-chart-mobile></app-trade-chart-mobile>
  <div class="w-100 d-flex flex-row align-items-center justify-content-center mb-5">
    <button (click)="managerService.active_trade_type_panel = managerService.active_trade_type_panel_template.BUY; scrollDown()"
            class="btn-native buy-button m-2 p-2" style="width: 40%; border-radius: 20px;">
      {{managerService.config.BASIC.TRADE.BUY | managerPipe}}
    </button>
    <button (click)="managerService.active_trade_type_panel = managerService.active_trade_type_panel_template.SELL; scrollDown()"
            class="btn-native sell-button m-2 p-2" style="width: 40%; border-radius: 20px;">
      {{managerService.config.BASIC.TRADE.SELL | managerPipe}}
    </button>
  </div>
</div>
<div #window_two>
  <div class="w-100 d-flex flex-row align-items-start justify-content-center">
    <div class="w-50 dropdown-native-button">
      <button (click)="dropdown_native_menu.classList.toggle('d-none')" class="btn-native m-auto pl-4 py-3 font-weight-bold"
              style="font-family: Roboto, sans-serif; font-size: 14px; letter-spacing: 0.4px; color: #d9dbe4;" type="button">
        <span
          [class.d-none]="managerService.active_order_book_panel !== managerService.active_order_book_panel_template.ORDER_BOOK">{{managerService.config.ADVANCED.ORDER_BOOK.HEADER | managerPipe}}</span>
        <span
          [class.d-none]="managerService.active_order_book_panel !== managerService.active_order_book_panel_template.TRADE_HISTORY">{{managerService.config.ADVANCED.TRADE_HISTORY.HEADER | managerPipe}}</span>
        <svg style="height: 20px; width: 20px;">
          <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="white"/>
        </svg>
      </button>
      <div #dropdown_native_menu class="dropdown-native-menu-black d-none w-50" style="height: 385px; background: rgba(30, 31, 37, 0.6); margin-top: -10px;">
        <div class="pl-4" style="background-color: #2e2f34; box-shadow:1px 3px 4px #0e111f">
          <button (click)="managerService.active_order_book_panel=managerService.active_order_book_panel_template.ORDER_BOOK; dropdown_native_menu.classList.toggle('d-none')" class="dropdown-native-item-black btn-native"
                  style="font-family: Roboto, sans-serif; font-size: 14px; letter-spacing: 0.4px; color: #d9dbe4; height: 40px; margin: 0;"
                  type="button">
            {{managerService.config.ADVANCED.ORDER_BOOK.HEADER | managerPipe}}
          </button>
          <button (click)="managerService.active_order_book_panel=managerService.active_order_book_panel_template.TRADE_HISTORY; dropdown_native_menu.classList.toggle('d-none')" class="dropdown-native-item-black btn-native"
                  style="font-family: Roboto, sans-serif; font-size: 14px; letter-spacing: 0.4px; color: #d9dbe4; height: 40px; margin: 0;"
                  type="button">
            {{managerService.config.ADVANCED.TRADE_HISTORY.HEADER | managerPipe}}
          </button>
        </div>
      </div>
      <app-order-book-mobile [class.d-none]="managerService.active_order_book_panel !== managerService.active_order_book_panel_template.ORDER_BOOK"></app-order-book-mobile>
      <app-trade-history-mobile [class.d-none]="managerService.active_order_book_panel !== managerService.active_order_book_panel_template.TRADE_HISTORY"></app-trade-history-mobile>
    </div>
    <div class="w-50 d-flex flex-column align-items-center justify-content-start">
      <div class="w-75 my-3" style="background-color: rgba(255, 255, 255, 0.2); border-radius: 4px;">
        <button
          (click)="managerService.active_trade_type_panel = managerService.active_trade_type_panel_template.BUY"
          [style]="managerService.active_trade_type_panel === managerService.active_trade_type_panel_template.BUY ? {'background-color': 'rgba(255, 255, 255, 0.2)',  'border-radius': '4px'}:{}"
          class="w-50 btn-native text-uppercase p-1"
          style="font-family: Roboto, sans-serif; font-size: 14px; letter-spacing: 0.4px; color: #d9dbe4;">
          {{managerService.config.ADVANCED.TRADE_CHART.BID | managerPipe}}
        </button>
        <button
          (click)="managerService.active_trade_type_panel = managerService.active_trade_type_panel_template.SELL"
          [style]="managerService.active_trade_type_panel === managerService.active_trade_type_panel_template.SELL ? {'background-color': 'rgba(255, 255, 255, 0.2)',  'border-radius': '4px'}:{}"
          class="w-50 btn-native text-uppercase p-1"
          style="font-family: Roboto, sans-serif; font-size: 14px; letter-spacing: 0.4px; color: #d9dbe4;">
          {{managerService.config.ADVANCED.TRADE_CHART.ASK | managerPipe}}
        </button>
      </div>
      <div class="w-75">
        <button
          (click)="managerService.setActiveTradeKindPanel(managerService.active_trade_kind_panel_template.LIMIT);"
          [style]="managerService.active_trade_kind_panel === managerService.active_trade_kind_panel_template.LIMIT ? {color: '#4fc0d2', 'border-bottom': '1px solid #4fc0d2'}:{}"
          class="btn-native text-left mr-4 mb-2"
          style="font-family: Roboto, sans-serif; font-size: 12px; letter-spacing: 0.6px; color: #d5d8e2;">
          {{managerService.config.ADVANCED.LIMIT | managerPipe}}
        </button>
        <button
          (click)="managerService.setActiveTradeKindPanel(managerService.active_trade_kind_panel_template.MARKET);"
          [style]="managerService.active_trade_kind_panel === managerService.active_trade_kind_panel_template.MARKET ? {color: '#4fc0d2', 'border-bottom': '1px solid #4fc0d2'}:{}"
          class="btn-native text-left mr-4 mb-2"
          style="font-family: Roboto, sans-serif; font-size: 12px; letter-spacing: 0.6px; color: #d5d8e2;">
          {{managerService.config.ADVANCED.MARKET | managerPipe}}
        </button>
      </div>
      <div class="w-75">
        <app-limit-trade-mobile [class.d-none]="managerService.active_trade_kind_panel === managerService.active_trade_kind_panel_template.MARKET"></app-limit-trade-mobile>
        <app-market-trade-mobile [class.d-none]="managerService.active_trade_kind_panel === managerService.active_trade_kind_panel_template.LIMIT"></app-market-trade-mobile>
      </div>
    </div>
  </div>
  <app-user-open-orders-mobile *ngIf="managerService.userService.user_ready | managerPipe"></app-user-open-orders-mobile>
  <app-user-trade-history-mobile *ngIf="managerService.userService.user_ready | managerPipe"></app-user-trade-history-mobile>
</div>
