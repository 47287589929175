export const announcements_config_tr = {
    OPERATION_LIST: [
        {
            TOPIC: 'Değerini 35 Kat Artıran Eser: Four Marilyns',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Değerini Zaman İçinde 35 Kat Artıran Andy Warhol Eseri: Four Marilyns</div>' +
                'Andy Warhol’un “Four Marilyns” resmini hepimiz biliriz.' +
                ' Pop art akımının en bilinen çalışmalarından biri olan bu resim, hayatımız boyunca defalarca karşımıza çıkmıştır.' +
                ' Çağdaş sanatlarla pek ilgisi olmayanlarımız bile bu resmin Andy Warhol’a ait olduğunu bilir.<br/>' +
                'Tahmin edebileceğiniz gibi Four Marilyns, bol sıfırlı bir fiyat etiketine sahip.' +
                ' Resmin Andy Warhol’un en bilindik çalışmalarından biri olması ve 20. yüzyılın bir kültür ikonuna dönüşmesi,' +
                ' resmin piyasa değerinin bu kadar yüksek olmasında etkili.<br/>' +
                'Resmin piyasa değeri konusunda daha derinlere inmeden resmin hikâyesinden kısaca bahsedelim.<br/>' +
                'Andy Warhol’un çalışmalarında, yaşadığı dönemdeki pop kültüründen beslendiğini hepimiz biliyoruz.' +
                ' Sinema da bu kültürün önemli bileşenlerinden. Popüler sinemada da Marilyn Monroe, dönemin en ikonik aktrislerinden.<br/>' +
                '6 Ağustos 1962, Andy Warhol’un 32. doğum günüydü.' +
                ' Doğum gününün sabahında New York Times’ın manşetten verdiği haber, Andy Warhol’un bu doğum gününü daima hatırlayacağının bir habercisiydi.' +
                ' Dünyaca ünlü sinema yıldızı Marilyn Monroe ölü bulunmuştu. Gazetenin manşetinde “Yıldızın bedeni yatak odasında bulundu.' +
                ' Yakınında haplar var. Polis, hiçbir not bırakılmadığını söyledi.' +
                ' Resmî açıklama daha sonra yapılacak.” yazıyordu.<br/>' +
                'Bu ölüm Andy Warhol için sarsıcı olmuştu.' +
                ' Sanat yaşamında Marilyn Monroe sıklıkla ziyaret edeceği konulardan birine dönüşecekti.<br/>' +
                'Andy Warhol, Marilyn Monroe serisi için ihtiyacı olan ilhamı New York’taki bir tatlıcı dükkânı olan Serendipity 3’de buldu.' +
                ' Marilyn Monroe da bu mekânın müdavimlerindendi.' +
                ' Serendipity 3’ün şekerleri, şeker renklerinin hâkim olduğu Marilyn serisine ilham verdi.<br/>' +
                'Andy Warhol’un 1962 yılında yarattığı Four Marilyns, sanat piyasasının dinamiklerini açıklamak için en ideal eserlerden.' +
                ' Four Marilyns, 1992 yılında Londra’daki Sotheby\'s tarafından 955.433 dolar fiyat etiketiyle satılmıştı.' +
                ' 2015 yılının ilk aylarında ise eser, bir Türk koleksiyoner olan Kemal Has Çıngıllıoğlu tarafından 44 milyon dolara alındı.' +
                ' Enflasyon düzeltmesinin ardından eserin fiyatı 23 yılda tam 27,5 kat artmıştı.<br/>' +
                '2015 yılının sonlarına doğru Christie\'s tarafından düzenlenen müzayedede, Four Marilyns tekrar satışa çıkarıldı.' +
                ' Eserin yeni fiyatının 40 ila 60 milyon dolar arasında olacağı tahmin ediliyordu.' +
                ' Ancak bu tahmin gerçekleşmedi ve eser 36.005.000 dolar fiyat etiketiyle satıldı.' +
                ' Uzmanlar eserin tahmin edilen değerine ulaşmamasını Çinli sanat yatırımcılarının durgunluğuna bağladı.<br/>' +
                'Özellikle 2010 yılından sonra Çinli sanat yatırımcılarının sayısının artmasıyla birlikte sanat piyasalarında yeni bir rekabetin ortaya çıktığını söyleyebiliriz.' +
                ' Bu zamana kadar genellikle Batılı koleksiyonerlerin kontrolünde olan pazar,' +
                ' Çin’den gelen yatırımcıların katılımıyla fiyatların yukarıya doğru gittiği rekabetçi bir ortama sahne oldu.' +
                ' Ancak Çin’in dönem dönem yaşadığı ekonomik zorluklar, Çinli yatırımcıların sanat eserlerine yaptıkları yatırımları etkiledi.' +
                ' Bu yüzden bazı sanat eserleri, tahmin edilenin altında fiyatlara satıldı.<br/>' +
                'Daha önceki makalelerimizde belirttiğimiz gibi bir sanat eserinin değeri;' +
                ' eseri üreten sanatçıya, eserin sanat dünyası içindeki yerine ve sanat yatırımcılarının finansal durumlarına göre belirleniyor.' +
                ' Four Marilyns örneğinde de giderek ününü artıran bir sanatçı olan Andy Warhol’u,' +
                ' kültür ikonu haline gelen bir resmi ve genel ekonomik durumdan etkilenen Çinli yatırımcıları görüyoruz.<br/>' +
                'Her ne kadar son açık artırmada tahmin edilen satış rakamının altında kalmış olsa da Four Marilyns; yıllar içinde değerini 20 kattan daha fazla artırdı.' +
                ' Bunun en büyük nedeni Andy Warhol isminin daima hatırlanacak olması.<br/>' +
                'Sanat dünyası için Andy Warhol gibi önemli isimlerden biri de Pablo Picasso.' +
                ' Tek başınıza bir Pablo Picasso eserine sahip olmanız imkânsız olabilir.' +
                ' Ancak Artiox’la bu mümkün.' +
                ' Bugün Artiox’a üye olup elinizde çok kısıtlı bir yatırım sermayesi olsa bile Artiox’un portföyündeki Pablo Picasso eseri olan Tete d’Histrion’a yatırım yapabilirsiniz.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Değerini Zaman İçinde 35 Kat Artıran Andy Warhol Eseri: Four Marilyns</div>' +
                'Andy Warhol’un “Four Marilyns” resmini hepimiz biliriz.' +
                ' Pop art akımının en bilinen çalışmalarından biri olan bu resim, hayatımız boyunca defalarca karşımıza çıkmıştır.' +
                ' Çağdaş sanatlarla pek ilgisi olmayanlarımız bile bu resmin Andy Warhol’a ait olduğunu bilir.<br/>' +
                'Tahmin edebileceğiniz gibi Four Marilyns, bol sıfırlı bir fiyat etiketine sahip.' +
                ' Resmin Andy Warhol’un en bilindik çalışmalarından biri olması ve 20. yüzyılın bir kültür ikonuna dönüşmesi,' +
                ' resmin piyasa değerinin bu kadar yüksek olmasında etkili.<br/>' +
                'Resmin piyasa değeri konusunda daha derinlere inmeden resmin hikâyesinden kısaca bahsedelim.<br/>' +
                'Andy Warhol’un çalışmalarında, yaşadığı dönemdeki pop kültüründen beslendiğini hepimiz biliyoruz.' +
                ' Sinema da bu kültürün önemli bileşenlerinden. Popüler sinemada da Marilyn Monroe, dönemin en ikonik aktrislerinden.<br/>' +
                '6 Ağustos 1962, Andy Warhol’un 32. doğum günüydü.' +
                ' Doğum gününün sabahında New York Times’ın manşetten verdiği haber, Andy Warhol’un bu doğum gününü daima hatırlayacağının bir habercisiydi.' +
                ' Dünyaca ünlü sinema yıldızı Marilyn Monroe ölü bulunmuştu. Gazetenin manşetinde “Yıldızın bedeni yatak odasında bulundu.' +
                ' Yakınında haplar var. Polis, hiçbir not bırakılmadığını söyledi.' +
                ' Resmî açıklama daha sonra yapılacak.” yazıyordu.<br/>' +
                'Bu ölüm Andy Warhol için sarsıcı olmuştu.' +
                ' Sanat yaşamında Marilyn Monroe sıklıkla ziyaret edeceği konulardan birine dönüşecekti.<br/>' +
                'Andy Warhol, Marilyn Monroe serisi için ihtiyacı olan ilhamı New York’taki bir tatlıcı dükkânı olan Serendipity 3’de buldu.' +
                ' Marilyn Monroe da bu mekânın müdavimlerindendi.' +
                ' Serendipity 3’ün şekerleri, şeker renklerinin hâkim olduğu Marilyn serisine ilham verdi.<br/>' +
                'Andy Warhol’un 1962 yılında yarattığı Four Marilyns, sanat piyasasının dinamiklerini açıklamak için en ideal eserlerden.' +
                ' Four Marilyns, 1992 yılında Londra’daki Sotheby\'s tarafından 955.433 dolar fiyat etiketiyle satılmıştı.' +
                ' 2015 yılının ilk aylarında ise eser, bir Türk koleksiyoner olan Kemal Has Çıngıllıoğlu tarafından 44 milyon dolara alındı.' +
                ' Enflasyon düzeltmesinin ardından eserin fiyatı 23 yılda tam 27,5 kat artmıştı.<br/>' +
                '2015 yılının sonlarına doğru Christie\'s tarafından düzenlenen müzayedede, Four Marilyns tekrar satışa çıkarıldı.' +
                ' Eserin yeni fiyatının 40 ila 60 milyon dolar arasında olacağı tahmin ediliyordu.' +
                ' Ancak bu tahmin gerçekleşmedi ve eser 36.005.000 dolar fiyat etiketiyle satıldı.' +
                ' Uzmanlar eserin tahmin edilen değerine ulaşmamasını Çinli sanat yatırımcılarının durgunluğuna bağladı.<br/>' +
                'Özellikle 2010 yılından sonra Çinli sanat yatırımcılarının sayısının artmasıyla birlikte sanat piyasalarında yeni bir rekabetin ortaya çıktığını söyleyebiliriz.' +
                ' Bu zamana kadar genellikle Batılı koleksiyonerlerin kontrolünde olan pazar,' +
                ' Çin’den gelen yatırımcıların katılımıyla fiyatların yukarıya doğru gittiği rekabetçi bir ortama sahne oldu.' +
                ' Ancak Çin’in dönem dönem yaşadığı ekonomik zorluklar, Çinli yatırımcıların sanat eserlerine yaptıkları yatırımları etkiledi.' +
                ' Bu yüzden bazı sanat eserleri, tahmin edilenin altında fiyatlara satıldı.<br/>' +
                'Daha önceki makalelerimizde belirttiğimiz gibi bir sanat eserinin değeri;' +
                ' eseri üreten sanatçıya, eserin sanat dünyası içindeki yerine ve sanat yatırımcılarının finansal durumlarına göre belirleniyor.' +
                ' Four Marilyns örneğinde de giderek ününü artıran bir sanatçı olan Andy Warhol’u,' +
                ' kültür ikonu haline gelen bir resmi ve genel ekonomik durumdan etkilenen Çinli yatırımcıları görüyoruz.<br/>' +
                'Her ne kadar son açık artırmada tahmin edilen satış rakamının altında kalmış olsa da Four Marilyns; yıllar içinde değerini 20 kattan daha fazla artırdı.' +
                ' Bunun en büyük nedeni Andy Warhol isminin daima hatırlanacak olması.<br/>' +
                'Sanat dünyası için Andy Warhol gibi önemli isimlerden biri de Pablo Picasso.' +
                ' Tek başınıza bir Pablo Picasso eserine sahip olmanız imkânsız olabilir.' +
                ' Ancak Artiox’la bu mümkün.' +
                ' Bugün Artiox’a üye olup elinizde çok kısıtlı bir yatırım sermayesi olsa bile Artiox’un portföyündeki Pablo Picasso eseri olan Tete d’Histrion’a yatırım yapabilirsiniz.',
            ROUTER_LINK: {
                TEXT: 'Eserleri İncele',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'NFT Satışları 3.5 Milyar Dolara Ulaştı',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">NFT Sanat Eseri Satışları 3.5 Milyar Dolara Ulaştı</div>' +
                'Muhafazakâr sanat çevreleri tarafından bir balon olarak görülen NFT’lerin, 2021 yılında yarattığı ekonomi 3,5 milyar dolar seviyesine ulaştı.' +
                ' Yılın ilk yarısında 6,8 milyar dolarlık satış yapılırken' +
                ' ikinci yarısında özellikle ağustos ayında yaşanan patlama sayesinde yıl sonundaki toplam satışın daha da artabileceği konuşuluyor.' +
                ' Toplam online sanat eseri satışlarının da 13,5 milyar dolar seviyesine çıkması bekleniyor.<br>' +
                'Fiziksel ortamda fiziksel sanat eserlerini alıp satmaya alışkın sanat pazarı için bu rakamlar oldukça çarpıcı.' +
                ' Yükselişte olan trend sanat pazarına yeni yatırımcıların girdiğini ve' +
                ' NFT pazar yerleri etrafında gözle görülür büyüklüklere sahip toplulukların oluştuğunu gösteriyor.<br>' +
                'NFT’lerin bu kadar hızlı bir şekilde büyümesinin ardında, blok zinciri teknolojisinin sanat pazarındaki birçok sorunun üstesinden gelmesi bulunuyor.' +
                ' Blok zinciri sayesinde bir eserin benzersizliğinin garantilenmesi oldukça kolay.' +
                ' Bu teknoloji sayesinde eser sahipleri, tüm yaşamları boyunca sahip oldukları eserlerden gelir elde etmeye devam edebiliyorlar.' +
                ' Diğer bir yandan da eserlerin el değiştirmesi süreci, fiziksel eserlere göre çok daha hızlı.' +
                ' Bu sayede NFT ekonomisi daha fazla hareketliliğe sahip oluyor.' +
                '<div class="job-advertisement-heading">Yüksek Volatilite</div>' +
                '2021 yılının mart ayında 356 milyon dolarla NFT pazarı bir zirve yaşadı.' +
                ' Ancak birkaç ay sonra ağustos ayında elde edilen 1,7 milyar dolarlık satış rakamı bu zirveyi hızlı bir şekilde küçük bir tepeye dönüştürdü.' +
                ' Ancak eylül ayında pazar eski büyüme trendine geri döndü.' +
                ' Bu durum NFT pazarının tıpkı diğer kripto pazarlarında olduğu gibi yoğun bir volatiliteye sahip olduğunu gösteriyor.<br>' +
                'İşin yaratıcı tarafından bakacak olursak, 2021 yılı koleksiyonu yapılabilir yaratımların ön plana çıkmaya başladığı yıl oldu.' +
                ' Daha önce tek başına çalışan sanatçıların NFT’leri gündeme gelirken 2021’de Crypto Punks ve Bored Apes Yacht Club gibi koleksiyonluk yaratımlar konuşulur oldu.' +
                '<div class="job-advertisement-heading">NFT’ler Sadece Bir Balon Değil</div>' +
                'Geleneksel sanat dünyasının bir kısmı NFT’lere büyük bir balon gözüyle baksa da gerçekler biraz farklı.' +
                ' NFT’lerin yarattığı pazar hacimin yanında Christie’s ve Sotheby’s gibi geleneksel sanat pazarı için önemli kurumların NFT dünyasında yer alma çabaları' +
                ' bu değişimin bir balon olmadığının önemli kanıtları.<br>' +
                'NFT kavramı teknolojiyle sanatı bir araya getiriyor.' +
                ' Hem teknolojide hem de sanatta büyük bir değişim meydana geldiğinde genellikle bir bocalama dönemi yaşanıyor.' +
                ' NFT dünyası, sunduğu yeni olanaklarla hem sanatçılar hem yatırımcılar hem de girişimciler için yeni oyun alanları açıyor.' +
                ' Bu oyun alanlarında hangi kavramların ve hangi oyuncuların ayakta kalabileceğini ise zaman gösterecek.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">NFT Sanat Eseri Satışları 3.5 Milyar Dolara Ulaştı</div>' +
                'Muhafazakâr sanat çevreleri tarafından bir balon olarak görülen NFT’lerin, 2021 yılında yarattığı ekonomi 3,5 milyar dolar seviyesine ulaştı.' +
                ' Yılın ilk yarısında 6,8 milyar dolarlık satış yapılırken' +
                ' ikinci yarısında özellikle ağustos ayında yaşanan patlama sayesinde yıl sonundaki toplam satışın daha da artabileceği konuşuluyor.' +
                ' Toplam online sanat eseri satışlarının da 13,5 milyar dolar seviyesine çıkması bekleniyor.<br>' +
                'Fiziksel ortamda fiziksel sanat eserlerini alıp satmaya alışkın sanat pazarı için bu rakamlar oldukça çarpıcı.' +
                ' Yükselişte olan trend sanat pazarına yeni yatırımcıların girdiğini ve' +
                ' NFT pazar yerleri etrafında gözle görülür büyüklüklere sahip toplulukların oluştuğunu gösteriyor.<br>' +
                'NFT’lerin bu kadar hızlı bir şekilde büyümesinin ardında, blok zinciri teknolojisinin sanat pazarındaki birçok sorunun üstesinden gelmesi bulunuyor.' +
                ' Blok zinciri sayesinde bir eserin benzersizliğinin garantilenmesi oldukça kolay.' +
                ' Bu teknoloji sayesinde eser sahipleri, tüm yaşamları boyunca sahip oldukları eserlerden gelir elde etmeye devam edebiliyorlar.' +
                ' Diğer bir yandan da eserlerin el değiştirmesi süreci, fiziksel eserlere göre çok daha hızlı.' +
                ' Bu sayede NFT ekonomisi daha fazla hareketliliğe sahip oluyor.' +
                '<div class="job-advertisement-heading">Yüksek Volatilite</div>' +
                '2021 yılının mart ayında 356 milyon dolarla NFT pazarı bir zirve yaşadı.' +
                ' Ancak birkaç ay sonra ağustos ayında elde edilen 1,7 milyar dolarlık satış rakamı bu zirveyi hızlı bir şekilde küçük bir tepeye dönüştürdü.' +
                ' Ancak eylül ayında pazar eski büyüme trendine geri döndü.' +
                ' Bu durum NFT pazarının tıpkı diğer kripto pazarlarında olduğu gibi yoğun bir volatiliteye sahip olduğunu gösteriyor.<br>' +
                'İşin yaratıcı tarafından bakacak olursak, 2021 yılı koleksiyonu yapılabilir yaratımların ön plana çıkmaya başladığı yıl oldu.' +
                ' Daha önce tek başına çalışan sanatçıların NFT’leri gündeme gelirken 2021’de Crypto Punks ve Bored Apes Yacht Club gibi koleksiyonluk yaratımlar konuşulur oldu.' +
                '<div class="job-advertisement-heading">NFT’ler Sadece Bir Balon Değil</div>' +
                'Geleneksel sanat dünyasının bir kısmı NFT’lere büyük bir balon gözüyle baksa da gerçekler biraz farklı.' +
                ' NFT’lerin yarattığı pazar hacimin yanında Christie’s ve Sotheby’s gibi geleneksel sanat pazarı için önemli kurumların NFT dünyasında yer alma çabaları' +
                ' bu değişimin bir balon olmadığının önemli kanıtları.<br>' +
                'NFT kavramı teknolojiyle sanatı bir araya getiriyor.' +
                ' Hem teknolojide hem de sanatta büyük bir değişim meydana geldiğinde genellikle bir bocalama dönemi yaşanıyor.' +
                ' NFT dünyası, sunduğu yeni olanaklarla hem sanatçılar hem yatırımcılar hem de girişimciler için yeni oyun alanları açıyor.' +
                ' Bu oyun alanlarında hangi kavramların ve hangi oyuncuların ayakta kalabileceğini ise zaman gösterecek.',
            ROUTER_LINK: {
                TEXT: 'Eserleri İncele',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 1640073600000,
        },
        {
            TOPIC: 'Empresyonizm Nedir?',
            IMAGE_SRC: '',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Empresyonizm Nedir? En Pahalı Empresyonist Tablolar </div>' +
                '1874 yılında, eserleri Paris Salonu tarafından sergilenmeye layık görülmeyen bir grup ressam kendi sergilerini düzenlemeye karar verdiler.' +
                ' Claude Monet, Berthe Morisot, Camille Pissarro, Pierre August Renoir ve Edgar Degas’ın katıldığı bu sergi, empresyonizmin doğuşunu müjdeliyordu.<br>' +
                '19. yüzyılın sonlarında, dinsel ve tarihsel temalı resimlerin rağbet gördüğü bu dönemde,' +
                ' manzaralar ve natürmortlar yerilirken portreler ve İncil’den sahneleri betimleyen tablolar el üstünde tutuluyordu.' +
                ' Empresyonist tablolar da bundan nasibini aldı. Sanat çevreleri de halk da empresyonist eserlerden hiç hoşlanmadı ve bunu dile getirmekten çekinmedi.' +
                ' Bu tablolar eski sanat sistemlerinin ölümünün ve yeni bir sistemin doğuşunun işaretini veriyordu. Bu yeni sisteme şu anda modern sanat diyoruz.' +
                '<div class="job-advertisement-heading">Tanımlayıcı Özellikleri </div>' +
                'Empresyonist tabloların konularının genellikle gündelik hayatta karşılaşılan manzaralar ya da sahneler olduğunu söylemiştik.' +
                ' Ayrıca kalın fırça darbelerinin empresyonist tabloların en ayırt edici özelliklerinden biri olduğunu söyleyebiliriz.' +
                ' Empresyonistler, dönemin diğer sanatçılarının fırça darbelerinin izini belli etmeme çabasının aksine, kalın ve belirgin fırça darbeleri kullandı.' +
                ' Bu darbeler, resme “uçucu bir an” izlenimi veriyordu.' +
                ' Empresyonist sanatçıların tablolarının diğer karakteristik özelliği ise, sanatçıların kullandığı farklı renk paleti idi.' +
                ' Empresyonist ressamlar, belirli tonları elde etmek için renkleri karıştırmak yerine, farklı renkleri ayrı fırça darbeleriyle bir araya getirdiler.' +
                ' Çoğunlukla parlak renkler kullandılar.<br>' +
                'Birçok empresyonist ressam, resimlerini açık havada yapmıştı.' +
                ' Bu onların ışığı ve ışığın manzaralar, yapılar ve dış mekândaki diğer nesneler üzerindeki etkisini düşünmelerine ve bu konuda denemeler yapmalarına neden oldu.' +
                ' Empresyonist ressamların çoğu için, ışığı aslına sadık bir şekilde resmetmenin tablonun konusundan daha önemli olduğunu söyleyebiliriz.' +
                ' Bu sanatçılar aynı manzarayı defalarca, farklı ışıklar ve hava koşullarında resmettiler.<br>' +
                'Empresyonist ressamlar asimetrik, alışılmadık kompozisyonlar oluşturdu.' +
                ' Belki de o dönemde yeni yeni gelişen fotoğrafın etkisiyle, zaman içindeki belirli bir anı yakalayan resimler ürettiler.' +
                '<div class="job-advertisement-heading">Öncüleri</div>' +
                'Claude Monet, empresyonizmin kurucularından biri olarak kabul edilir.' +
                ' Monet, doğayı gördüğü haliyle resmetmek istiyordu.' +
                ' Bir kat boya kurumadan üzerine diğer katı sürerek resimlerini görece hızlı bir şekilde tamamlıyordu.' +
                ' Cesur renkler kullanan ve geleneksel sanat kurallarını reddeden ressam, çoğunlukla manzaralar resmetmiştir.<br>' +
                'Empresyonizm kadar realizmle de ilişkilendirilen Édouard Manet ise, empresyonistleri etkiledikten sonra onlardan etkilendi.' +
                ' Manet, renkleri perspektif yaratan bir araç olarak kullandı.' +
                ' Mesela turuncu bir elbisenin ışığa yakınlığını turuncunun farklı tonlarıyla gösteriyordu.' +
                ' Bu yöntem, diğer empresyonistler tarafından hızla benimsendi ve sonraki nesiller boyunca yağlıboya resimde en çok kullanılan yöntemlerden biri haline geldi.<br>' +
                'Edgar Degas ise, genellikle Parizyen hayatı resmetmişti.' +
                ' Özellikle kadınları resmeden Degas’ın resimlerinde dansçılar, şarkıcılar ve kadınları sıklıkla görüyoruz.' +
                ' İlginç açılarda, yapay ışık altında tuhaf postürleri resmeden Degas; tarihsel ve mitsel temaları reddetmiş ve modern gündelik hayattan ilham almıştı.' +
                ' Ressam, modellerinin alt sınıftan olması nedeniyle de çokça eleştirildi.' +
                'Çoğunlukla modern Paris’i ve 19. yüzyılın eğlence-dinlence etkinliklerini resmeden Pierre August Renoir, ışık ve gölgeyle oynayarak dinamik sanat eserleri üretti.<br>' +
                'Empresyonizmin öncüleri arasında iki kadın ressam da vardı.' +
                ' Berthe Morisot, kadınların o dönemdeki ev hayatını zengin kompozisyonlarla resmetti.' +
                ' Morisot’nun tablolarında sıklıkla çıplak kadınlar ve kızı Julie’yi görüyoruz.' +
                ' Mary Cassatt ise arkadaşı Degas’ın davetiyle empresyonistlere katıldı.' +
                ' Cassatt, çoğunlukla çocuklarıyla ilgilenen anneler ve ev hayatından gündelik sahneleri resmetti.' +
                ' Cassatt, kendinden önceki çoğu ressamın aksine kadınları süslemeden ya da güzelleştirmeden, oldukları gibi betimledi.' +
                '<div class="job-advertisement-heading">En Pahalı Empresyonist Tablolar</div>' +
                'Cezanne’ın The Card Players (Kâğıt Oynayanlar) başlıklı tablosu, 2011 yılında Katar Kraliyet Ailesi’ne satıldı.' +
                ' Tablo, 250 milyon dolarlık (bugünkü 287,6 milyon dolar) fiyatıyla o zamana dek satılan en pahalı tablo idi ve 2017 yılına kadar bu rekoru elinde tuttu.' +
                ' Ressamın Monte Sainte Victoire (Sainte-Victoire Dağı) başlıklı tablosu ise, 2013 yılında 100 milyon dolara (bugünkü 111 milyon dolar) alıcı buldu.' +
                ' Yine Cezanne’ın Rideau, Cruche et un Bol de Fruits (Perde, Sürahi ve Meyve Kâsesi) başlıklı tablosu ise, 1999 yılında 60,5 milyon dolara satıldı.' +
                ' Eserin şu anki değerinin 120 milyon dolar olduğu tahmin ediliyor.' +
                'Renoir’ın Bal du Moulin de la Galette (Moulin de Galette’te Dans) başlıklı tablosu ise' +
                ' 1990’da Sotheby’s New York’ta 78,1 milyon dolara (bugünkü 154,7 milyon dolar) satıldı.<br>' +
                'Monet’nin Les Nymphéas (Nilüferler) başlıklı tablosu, bu yıl 70,4 milyon dolara satıldı.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Empresyonizm Nedir? En Pahalı Empresyonist Tablolar </div>' +
                '1874 yılında, eserleri Paris Salonu tarafından sergilenmeye layık görülmeyen bir grup ressam kendi sergilerini düzenlemeye karar verdiler.' +
                ' Claude Monet, Berthe Morisot, Camille Pissarro, Pierre August Renoir ve Edgar Degas’ın katıldığı bu sergi, empresyonizmin doğuşunu müjdeliyordu.<br>' +
                '19. yüzyılın sonlarında, dinsel ve tarihsel temalı resimlerin rağbet gördüğü bu dönemde,' +
                ' manzaralar ve natürmortlar yerilirken portreler ve İncil’den sahneleri betimleyen tablolar el üstünde tutuluyordu.' +
                ' Empresyonist tablolar da bundan nasibini aldı. Sanat çevreleri de halk da empresyonist eserlerden hiç hoşlanmadı ve bunu dile getirmekten çekinmedi.' +
                ' Bu tablolar eski sanat sistemlerinin ölümünün ve yeni bir sistemin doğuşunun işaretini veriyordu. Bu yeni sisteme şu anda modern sanat diyoruz.' +
                '<div class="job-advertisement-heading">Tanımlayıcı Özellikleri </div>' +
                'Empresyonist tabloların konularının genellikle gündelik hayatta karşılaşılan manzaralar ya da sahneler olduğunu söylemiştik.' +
                ' Ayrıca kalın fırça darbelerinin empresyonist tabloların en ayırt edici özelliklerinden biri olduğunu söyleyebiliriz.' +
                ' Empresyonistler, dönemin diğer sanatçılarının fırça darbelerinin izini belli etmeme çabasının aksine, kalın ve belirgin fırça darbeleri kullandı.' +
                ' Bu darbeler, resme “uçucu bir an” izlenimi veriyordu.' +
                ' Empresyonist sanatçıların tablolarının diğer karakteristik özelliği ise, sanatçıların kullandığı farklı renk paleti idi.' +
                ' Empresyonist ressamlar, belirli tonları elde etmek için renkleri karıştırmak yerine, farklı renkleri ayrı fırça darbeleriyle bir araya getirdiler.' +
                ' Çoğunlukla parlak renkler kullandılar.<br>' +
                'Birçok empresyonist ressam, resimlerini açık havada yapmıştı.' +
                ' Bu onların ışığı ve ışığın manzaralar, yapılar ve dış mekândaki diğer nesneler üzerindeki etkisini düşünmelerine ve bu konuda denemeler yapmalarına neden oldu.' +
                ' Empresyonist ressamların çoğu için, ışığı aslına sadık bir şekilde resmetmenin tablonun konusundan daha önemli olduğunu söyleyebiliriz.' +
                ' Bu sanatçılar aynı manzarayı defalarca, farklı ışıklar ve hava koşullarında resmettiler.<br>' +
                'Empresyonist ressamlar asimetrik, alışılmadık kompozisyonlar oluşturdu.' +
                ' Belki de o dönemde yeni yeni gelişen fotoğrafın etkisiyle, zaman içindeki belirli bir anı yakalayan resimler ürettiler.' +
                '<div class="job-advertisement-heading">Öncüleri</div>' +
                'Claude Monet, empresyonizmin kurucularından biri olarak kabul edilir.' +
                ' Monet, doğayı gördüğü haliyle resmetmek istiyordu.' +
                ' Bir kat boya kurumadan üzerine diğer katı sürerek resimlerini görece hızlı bir şekilde tamamlıyordu.' +
                ' Cesur renkler kullanan ve geleneksel sanat kurallarını reddeden ressam, çoğunlukla manzaralar resmetmiştir.<br>' +
                'Empresyonizm kadar realizmle de ilişkilendirilen Édouard Manet ise, empresyonistleri etkiledikten sonra onlardan etkilendi.' +
                ' Manet, renkleri perspektif yaratan bir araç olarak kullandı.' +
                ' Mesela turuncu bir elbisenin ışığa yakınlığını turuncunun farklı tonlarıyla gösteriyordu.' +
                ' Bu yöntem, diğer empresyonistler tarafından hızla benimsendi ve sonraki nesiller boyunca yağlıboya resimde en çok kullanılan yöntemlerden biri haline geldi.<br>' +
                'Edgar Degas ise, genellikle Parizyen hayatı resmetmişti.' +
                ' Özellikle kadınları resmeden Degas’ın resimlerinde dansçılar, şarkıcılar ve kadınları sıklıkla görüyoruz.' +
                ' İlginç açılarda, yapay ışık altında tuhaf postürleri resmeden Degas; tarihsel ve mitsel temaları reddetmiş ve modern gündelik hayattan ilham almıştı.' +
                ' Ressam, modellerinin alt sınıftan olması nedeniyle de çokça eleştirildi.' +
                'Çoğunlukla modern Paris’i ve 19. yüzyılın eğlence-dinlence etkinliklerini resmeden Pierre August Renoir, ışık ve gölgeyle oynayarak dinamik sanat eserleri üretti.<br>' +
                'Empresyonizmin öncüleri arasında iki kadın ressam da vardı.' +
                ' Berthe Morisot, kadınların o dönemdeki ev hayatını zengin kompozisyonlarla resmetti.' +
                ' Morisot’nun tablolarında sıklıkla çıplak kadınlar ve kızı Julie’yi görüyoruz.' +
                ' Mary Cassatt ise arkadaşı Degas’ın davetiyle empresyonistlere katıldı.' +
                ' Cassatt, çoğunlukla çocuklarıyla ilgilenen anneler ve ev hayatından gündelik sahneleri resmetti.' +
                ' Cassatt, kendinden önceki çoğu ressamın aksine kadınları süslemeden ya da güzelleştirmeden, oldukları gibi betimledi.' +
                '<div class="job-advertisement-heading">En Pahalı Empresyonist Tablolar</div>' +
                'Cezanne’ın The Card Players (Kâğıt Oynayanlar) başlıklı tablosu, 2011 yılında Katar Kraliyet Ailesi’ne satıldı.' +
                ' Tablo, 250 milyon dolarlık (bugünkü 287,6 milyon dolar) fiyatıyla o zamana dek satılan en pahalı tablo idi ve 2017 yılına kadar bu rekoru elinde tuttu.' +
                ' Ressamın Monte Sainte Victoire (Sainte-Victoire Dağı) başlıklı tablosu ise, 2013 yılında 100 milyon dolara (bugünkü 111 milyon dolar) alıcı buldu.' +
                ' Yine Cezanne’ın Rideau, Cruche et un Bol de Fruits (Perde, Sürahi ve Meyve Kâsesi) başlıklı tablosu ise, 1999 yılında 60,5 milyon dolara satıldı.' +
                ' Eserin şu anki değerinin 120 milyon dolar olduğu tahmin ediliyor.' +
                'Renoir’ın Bal du Moulin de la Galette (Moulin de Galette’te Dans) başlıklı tablosu ise' +
                ' 1990’da Sotheby’s New York’ta 78,1 milyon dolara (bugünkü 154,7 milyon dolar) satıldı.<br>' +
                'Monet’nin Les Nymphéas (Nilüferler) başlıklı tablosu, bu yıl 70,4 milyon dolara satıldı.',
            ROUTER_LINK: {
                TEXT: 'Eserleri İncele',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 1639897200000,
        },
        {
            TOPIC: '2021 Yılı Sanat Pazarında Öne Çıkan Veriler',
            IMAGE_SRC: './assets/announcements/operations/corporate.svg#corporate',
            TEXT_MOBILE: '<div class="job-advertisement-heading">2021 Yılı Sanat Pazarında Öne Çıkan Veriler</div>' +
                '2021 yılı, tıpkı 2020 yılı gibi COVID-19 salgınının etkisinde bir yıl oldu.' +
                ' Yatırımcıların sağlıklı öngörüler üretmede güçlük çektiği bu yılda tıpkı diğer endüstrilerde olduğu gibi sanat endüstrisinde de belirsizlik hâkimdi.' +
                ' Bu belirsizlik pazarın küçülmesine neden oldu.' +
                ' Yatırımcıların önlerini görebilmeleri için ne kadar süre gerektiğini söylemek de pek kolay değil.<br>' +
                'Genel tablonun negatif olmasına karşın pozitif gelişmeler de yaşanmıyor değil.' +
                ' “Fiziksel sanat” kavramının kapanmalar nedeniyle dört duvar arasında sıkışması, çevrimiçi sanatın büyümesini hızlandırdı.' +
                ' Dijital sanat pazarında bugüne kadar yaşanmamış bir hareketlilik yaşanmaya başladı.<br>' +
                'Genel resmi daha net bir şekilde görebilmeniz için 2021 yılı sanat pazarının öne çıkan verilerini derledik.' +
                '<div class="job-advertisement-heading">Sanat Pazarı Toparlanmaya Başladı</div>' +
                '2020 yılı, sanat pazarının yıkıcı derecede küçüldüğü bir yıl olmuştu.' +
                ' Bu yılda sanat pazarı %22 oranında küçülmüştü.' +
                ' 2022 yılında Christie’s, Sotheby’s ve Phillips gibi önemli kurumların müzayede satışları, 2021 yılına göre %230 oranında artarak neredeyse 2019 yılındaki seviyeye geldi.' +
                ' Bu kurumların sanat pazarı üstündeki etkisi göz önüne alındığında, 2021 yılının bir toparlanma yılı olduğunu söylememiz mümkün.' +
                '<div class="job-advertisement-heading">Çevrimiçi Satışlarda Patlama Yaşandı</div>' +
                '2021 yılında, çevrimiçi sanat eseri satışlarından elde edilen gelir 12,4 milyar dolara yükseldi.' +
                ' Bu büyüklüğün sadece fiziksel sanat eserleri ve antikalardan elde edildiğini; bu rakama dijital sanat eseri satışlarının dâhil olmadığını söyleyelim.' +
                '<div class="job-advertisement-heading">Yeni Bir Koleksiyoner Tanımı Ortaya Çıktı</div>' +
                'Sonuna geldiğimiz 2021 yılı, sanat pazarında birçok kavramın yeniden yorumlanmasına neden oluyor. Bu kavramların başında da “koleksiyonerlik” geliyor.<br>' +
                'Jenerasyon farkı, koleksiyonerler arasındaki davranış değişikliklerinin en önemli nedeni.' +
                ' Pandemi etkisindeki dünyada dijital kanalların daha da önemli hale gelmesi sayesinde nesiller arasındaki bu fark çok daha gözlemlenebilir hale geldi.<br>' +
                'Dijital sanat eserlerine yatırım yapmaya meyilli, sanatın konvansiyonel formatları dışına çıkan sanatçılara ve eserlere daha fazla değer veren,' +
                ' bir sanat eserinin ticari geleceği konusunda daha yüksek riskler alabilen yeni bir koleksiyoner kitlesi; 2021 yılında daha görünür hale geldi.' +
                '<div class="job-advertisement-heading">Çin, ABD’yi Geçti</div>' +
                'Müzayede ekonomisinde Çin’in ABD’nin yerini alarak liderliğe yükselmesi önemli bir gelişme oldu.' +
                ' Çin, bu pazarın %36’sını elinde tutuyor. ABD %29, Birleşik Krallık ise %16’lık bir paya sahip.' +
                '<div class="job-advertisement-heading">Online Satışlar Yükselişte</div>' +
                'Sanat pazarının genel görünümü negatif olsa da çevrimiçi satışlarda büyük artış yaşandı.' +
                ' 2020 yılına oranla %25’lik büyüme kaydeden online satış kanalları, sanat satın alan yeni kitlelerin ortaya çıkmasını da sağladı.' +
                ' NFT gibi daha önce etkisi son derece kısıtlı olan dijital kavramlar, 2021 yılında yaşadıkları patlamayla bu büyümeyi derinden etkilediler.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">2021 Yılı Sanat Pazarında Öne Çıkan Veriler</div>' +
                '2021 yılı, tıpkı 2020 yılı gibi COVID-19 salgınının etkisinde bir yıl oldu.' +
                ' Yatırımcıların sağlıklı öngörüler üretmede güçlük çektiği bu yılda tıpkı diğer endüstrilerde olduğu gibi sanat endüstrisinde de belirsizlik hâkimdi.' +
                ' Bu belirsizlik pazarın küçülmesine neden oldu.' +
                ' Yatırımcıların önlerini görebilmeleri için ne kadar süre gerektiğini söylemek de pek kolay değil.<br>' +
                'Genel tablonun negatif olmasına karşın pozitif gelişmeler de yaşanmıyor değil.' +
                ' “Fiziksel sanat” kavramının kapanmalar nedeniyle dört duvar arasında sıkışması, çevrimiçi sanatın büyümesini hızlandırdı.' +
                ' Dijital sanat pazarında bugüne kadar yaşanmamış bir hareketlilik yaşanmaya başladı.<br>' +
                'Genel resmi daha net bir şekilde görebilmeniz için 2021 yılı sanat pazarının öne çıkan verilerini derledik.' +
                '<div class="job-advertisement-heading">Sanat Pazarı Toparlanmaya Başladı</div>' +
                '2020 yılı, sanat pazarının yıkıcı derecede küçüldüğü bir yıl olmuştu.' +
                ' Bu yılda sanat pazarı %22 oranında küçülmüştü.' +
                ' 2022 yılında Christie’s, Sotheby’s ve Phillips gibi önemli kurumların müzayede satışları, 2021 yılına göre %230 oranında artarak neredeyse 2019 yılındaki seviyeye geldi.' +
                ' Bu kurumların sanat pazarı üstündeki etkisi göz önüne alındığında, 2021 yılının bir toparlanma yılı olduğunu söylememiz mümkün.' +
                '<div class="job-advertisement-heading">Çevrimiçi Satışlarda Patlama Yaşandı</div>' +
                '2021 yılında, çevrimiçi sanat eseri satışlarından elde edilen gelir 12,4 milyar dolara yükseldi.' +
                ' Bu büyüklüğün sadece fiziksel sanat eserleri ve antikalardan elde edildiğini; bu rakama dijital sanat eseri satışlarının dâhil olmadığını söyleyelim.' +
                '<div class="job-advertisement-heading">Yeni Bir Koleksiyoner Tanımı Ortaya Çıktı</div>' +
                'Sonuna geldiğimiz 2021 yılı, sanat pazarında birçok kavramın yeniden yorumlanmasına neden oluyor. Bu kavramların başında da “koleksiyonerlik” geliyor.<br>' +
                'Jenerasyon farkı, koleksiyonerler arasındaki davranış değişikliklerinin en önemli nedeni.' +
                ' Pandemi etkisindeki dünyada dijital kanalların daha da önemli hale gelmesi sayesinde nesiller arasındaki bu fark çok daha gözlemlenebilir hale geldi.<br>' +
                'Dijital sanat eserlerine yatırım yapmaya meyilli, sanatın konvansiyonel formatları dışına çıkan sanatçılara ve eserlere daha fazla değer veren,' +
                ' bir sanat eserinin ticari geleceği konusunda daha yüksek riskler alabilen yeni bir koleksiyoner kitlesi; 2021 yılında daha görünür hale geldi.' +
                '<div class="job-advertisement-heading">Çin, ABD’yi Geçti</div>' +
                'Müzayede ekonomisinde Çin’in ABD’nin yerini alarak liderliğe yükselmesi önemli bir gelişme oldu.' +
                ' Çin, bu pazarın %36’sını elinde tutuyor. ABD %29, Birleşik Krallık ise %16’lık bir paya sahip.' +
                '<div class="job-advertisement-heading">Online Satışlar Yükselişte</div>' +
                'Sanat pazarının genel görünümü negatif olsa da çevrimiçi satışlarda büyük artış yaşandı.' +
                ' 2020 yılına oranla %25’lik büyüme kaydeden online satış kanalları, sanat satın alan yeni kitlelerin ortaya çıkmasını da sağladı.' +
                ' NFT gibi daha önce etkisi son derece kısıtlı olan dijital kavramlar, 2021 yılında yaşadıkları patlamayla bu büyümeyi derinden etkilediler.',
            ROUTER_LINK: {
                TEXT: 'Eserleri İncele',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Artiox’a Kimler Yatırım Yaptı?',
            IMAGE_SRC: './assets/announcements/operations/corporate.svg#corporate',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Artiox’a Kimler Yatırım Yaptı?</div>' +
                'Daha önceki yazılarımızda Artiox’un hikâyesinden bahsetmiştik.' +
                ' Şimdi de Artiox’un bugünlere gelmesini sağlayan yatırımcılardan bahsedeceğiz.' +
                '<div class="job-advertisement-heading">Mehmet Toprak</div>' +
                'Tohum öncesi yatırımcılarımızdan Mehmet Toprak, 1995 yılında Ankara’da kurulan ve kurulduğu günden bu yana' +
                ' Endüstriyel Yapı Market iş kolunda Türkiye’nin öncü şirketlerinden biri olan Topraklar İmalat İhracat Limited Şirketi’nde CEO olarak görev yapıyor.' +
                ' Ankara Ticaret Odası’nda 26. Hırdavat Satıcıları Komitesi Başkanı olan Mehmet Toprak aynı zamanda zamanın ruhunu yakalayan dijital iş fikirlerine yaptığı yatırım ve danışmanlıklarla;' +
                ' bu fikirlerin hayata geçmesini sağlayan Stratejik ve İnovatif Yatırımcılar Derneği’nin de yönetim kurulu başkanıdır. ' +
                '<div class="job-advertisement-heading">Murat Aksöz</div>' +
                'Tohum öncesi dönemdeki bir diğer yatırımcımız olan Murat Aksöz; 2U INVESTMENT\'ın kurucusudur.' +
                ' 1993\'te TED Ankara Koleji\'nden mezun olan Aksöz, yüksek öğrenimini Gazi Üniversitesi Makine Mühendisliği Bölümü’nde tamamlamıştır.' +
                ' Üretim yönetimi, mühendislik, stratejik planlama ve iş geliştirme uzmanı olan Aksöz;' +
                ' aynı zamanda savunma, otomotiv, HES-RES-GES projelerine imalat desteği veren Bronz Otomotiv\'in ortaklarındandır.' +
                ' AR-GE ve inovasyona olan yüksek ilgisi ile girişimcilik ekosisteminde melek yatırımcı olarak yer almaktadır.' +
                '<div class="job-advertisement-heading">Alesta Yatırım</div>' +
                'Nisan 2021 tarihindeki birinci turda Artiox’a yatırım yapan Alesta Elektronik Teknoloji Yatırım A.Ş. bir Escort Teknoloji Yatırım A.Ş. iştiraki olarak 2012 yılında kuruldu.' +
                ' Büyüme potansiyeline sahip teknoloji şirketlerini destekleyen Alesta Yatırım,' +
                ' yatırım yaptığı şirketlere sermaye ve bilgi birikimini aktararak bu şirketlerin başarılı operasyonlar haline gelmesine çalışıyor.' +
                '<div class="job-advertisement-heading">Sertaç Özinal</div>' +
                'Bankacılık ve finans sektörünün önemli isimlerinden olan Sertaç Özinal, TurkishBank’ın yönetim kurulu üyesi.' +
                ' Geçmişte Bankalararası Kart Merkezi yönetim kurulu başkanlığı görevini de üstlenen Sertaç Özinal;' +
                ' teknoloji, veri, yapay zekâ ve blok zinciri alanlarında çalışan girişimlere yatırım yapan True Global Ventures’ın da ortaklarından.  ' +
                '<div class="job-advertisement-heading">İdeal Finansal Teknolojiler A.Ş</div>' +
                'Artiox’a ikinci turda yatırım yapan İdeal Finansal Teknolojiler, 25 yılı aşkın bir süredir veri dağıtımı alanında çalışıyor.' +
                ' Yerli ve yabancı piyasa verilerini kullanıcılarına sunan İdeal Finansal Teknolojiler aynı zamanda finans alanında yazılım geliştirme hizmetleri de veriyor.' +
                ' Ek olarak Borsa İstanbul\'da işlem gören İdeal Finansal Teknolojiler Anonim Şirketi, Hedef Holding\'in iştiraklerindendir.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Artiox’a Kimler Yatırım Yaptı?</div>' +
                'Daha önceki yazılarımızda Artiox’un hikâyesinden bahsetmiştik.' +
                ' Şimdi de Artiox’un bugünlere gelmesini sağlayan yatırımcılardan bahsedeceğiz.' +
                '<div class="job-advertisement-heading">Mehmet Toprak</div>' +
                'Tohum öncesi yatırımcılarımızdan Mehmet Toprak, 1995 yılında Ankara’da kurulan ve kurulduğu günden bu yana' +
                ' Endüstriyel Yapı Market iş kolunda Türkiye’nin öncü şirketlerinden biri olan Topraklar İmalat İhracat Limited Şirketi’nde CEO olarak görev yapıyor.' +
                ' Ankara Ticaret Odası’nda 26. Hırdavat Satıcıları Komitesi Başkanı olan Mehmet Toprak aynı zamanda zamanın ruhunu yakalayan dijital iş fikirlerine yaptığı yatırım ve danışmanlıklarla;' +
                ' bu fikirlerin hayata geçmesini sağlayan Stratejik ve İnovatif Yatırımcılar Derneği’nin de yönetim kurulu başkanıdır. ' +
                '<div class="job-advertisement-heading">Murat Aksöz</div>' +
                'Tohum öncesi dönemdeki bir diğer yatırımcımız olan Murat Aksöz; 2U INVESTMENT\'ın kurucusudur.' +
                ' 1993\'te TED Ankara Koleji\'nden mezun olan Aksöz, yüksek öğrenimini Gazi Üniversitesi Makine Mühendisliği Bölümü’nde tamamlamıştır.' +
                ' Üretim yönetimi, mühendislik, stratejik planlama ve iş geliştirme uzmanı olan Aksöz;' +
                ' aynı zamanda savunma, otomotiv, HES-RES-GES projelerine imalat desteği veren Bronz Otomotiv\'in ortaklarındandır.' +
                ' AR-GE ve inovasyona olan yüksek ilgisi ile girişimcilik ekosisteminde melek yatırımcı olarak yer almaktadır.' +
                '<div class="job-advertisement-heading">Alesta Yatırım</div>' +
                'Nisan 2021 tarihindeki birinci turda Artiox’a yatırım yapan Alesta Elektronik Teknoloji Yatırım A.Ş. bir Escort Teknoloji Yatırım A.Ş. iştiraki olarak 2012 yılında kuruldu.' +
                ' Büyüme potansiyeline sahip teknoloji şirketlerini destekleyen Alesta Yatırım,' +
                ' yatırım yaptığı şirketlere sermaye ve bilgi birikimini aktararak bu şirketlerin başarılı operasyonlar haline gelmesine çalışıyor.' +
                '<div class="job-advertisement-heading">Sertaç Özinal</div>' +
                'Bankacılık ve finans sektörünün önemli isimlerinden olan Sertaç Özinal, TurkishBank’ın yönetim kurulu üyesi.' +
                ' Geçmişte Bankalararası Kart Merkezi yönetim kurulu başkanlığı görevini de üstlenen Sertaç Özinal;' +
                ' teknoloji, veri, yapay zekâ ve blok zinciri alanlarında çalışan girişimlere yatırım yapan True Global Ventures’ın da ortaklarından.  ' +
                '<div class="job-advertisement-heading">İdeal Finansal Teknolojiler A.Ş</div>' +
                'Artiox’a ikinci turda yatırım yapan İdeal Finansal Teknolojiler, 25 yılı aşkın bir süredir veri dağıtımı alanında çalışıyor.' +
                ' Yerli ve yabancı piyasa verilerini kullanıcılarına sunan İdeal Finansal Teknolojiler aynı zamanda finans alanında yazılım geliştirme hizmetleri de veriyor.' +
                ' Ek olarak Borsa İstanbul\'da işlem gören İdeal Finansal Teknolojiler Anonim Şirketi, Hedef Holding\'in iştiraklerindendir.',
            ROUTER_LINK: {
                TEXT: 'Eserleri İncele',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Sanat Piyasasına Yön Verecek 5 Trend',
            IMAGE_SRC: './assets/announcements/operations/corporate.svg#corporate',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Yakın Gelecekte Sanat Piyasasına Yön Verecek 5 Trend</div>' +
                'Gelecek hakkında öngörülerde bulunmak hiçbir zaman kolay bir iş olmadı.' +
                ' Ancak son iki yıldır, COVID-19 salgını nedeniyle gelecek tahminleri yapmak daha da güç hale geldi.' +
                ' Ancak salgının ilk zamanlarında olduğu kadar bilinmezliklerle dolu sularda ilerleyemiyoruz.' +
                ' Salgının küresel etkilerinin ne zaman sona ereceğini henüz bilmesek de salgınla yaşamayı bir derece öğrendik.' +
                ' Sizinle de sanat dünyasına yakın gelecekte yön vereceğini düşündüğümüz bazı trendleri paylaşmak istiyoruz.' +
                '<div class="job-advertisement-heading">Online Sanat Piyasası Büyümeye Devam Edecek</div>' +
                'Pandemi öncesinde online sanat pazarının yıllık hacmi 4,5 ile 6 milyar dolar arasında değişiyordu.' +
                ' 2020 yılında pandemi nedeniyle fiziksel sanat aktivitelerinin büyük bir bölümünün iptal edilmesinin ardından bu hacim 2020 yılında 12.4 milyar dolara ulaştı.' +
                ' Diğer bir yandan online satışların yaklaşık %30’unun, daha önce konvansiyonel satış kanallarından sanat eseri satın almamış kişilerden oluşması da ilginç bir veri.' +
                ' Bu durum online kanallar sayesinde hatırı sayılır büyüklükte yeni bir kitlenin “sanat eseri alıcısı” haline geldiğini gösteriyor.<br>' +
                'Önümüzdeki dönemde tıpkı diğer sektörlerde olduğu gibi sanat pazarında da' +
                ' pandemi nedeniyle değişen tüketici satın alma alışkanlıklarının kalıcı hale geleceğini söyleyebiliriz.' +
                ' Sanat yatırımcılarına büyük kolaylıklar sağlayan online kanalların, toplam sanat eseri satışları içindeki payını artıracağını öngörmemiz mümkün.<br>' +
                '<div class="job-advertisement-heading">Sanat Odaklı Teknoloji Girişimlerine Yapılan Yatırımlar Artacak</div>' +
                'Online satış kanallarının toplam satışlar içindeki payının artmasının doğal bir sonucu olarak sanat odaklı start-up’lara olan yatırımların artacağını söylemek mümkün.' +
                ' Pandemi öncesindeki 20 yıl boyunca sadece 640 milyon dolar yatırım toplamayı başaran sanat odaklı start-up’lar,' +
                ' sadece 2020 yılında 380 milyon dolar yatırım almayı başardılar.<br>' +
                'Yatırım kararlarındaki bu trendin tek nedeni pandemi etkileri değil.' +
                ' Önümüzdeki yıllarda milenyum jenerasyonunun sanat pazarına hâkim olacağı gerçeği, sanat pazarında teknolojinin önemini artırıyor.' +
                ' 2030 yılına kadar miras yoluyla milenyum jenerasyonun eline 20 ile 70 trilyon dolar para geçeceği tahmin ediliyor.' +
                ' Milenyum jenerasyonun sanat eseri satın alma konusunda online kanalları, önceki jenerasyonlara göre çok daha fazla kullandığı biliniyor.' +
                ' Bu yüzden çoğu girişimci için sanatın teknoloji tarafında erkenden yer almak kötü bir fikir değil.' +
                '<div class="job-advertisement-heading">Sanat Üretiminde Teknolojinin Payı Artacak</div>' +
                'İnternetin ana akım haline gelmesinden bu yana teknolojinin, sanat eserlerinin üretimi alanındaki varlığı büyüyor.' +
                ' Ancak sanat pazarının son derece muhafazakâr temeller üzerine inşa edilmiş olması,' +
                ' teknolojinin kullanıldığı sanat eserlerinin finansal anlamda yeteri kadar değer görmemesine neden oluyordu.' +
                ' Özellikle NFT gibi teknolojilerin sanat pazarında kullanımının artması bu durumu değiştiriyor.' +
                ' Sanat eserlerine olan talepteki bu sapma, sanat üretim süreçlerinin de gözden geçirilmesine neden oluyor.' +
                ' Önümüzdeki dönemde dijital tuvallerin daha fazla kullanılacağını söylememiz mümkün.' +
                '<div class="job-advertisement-heading">Daha Fazla Online Sanat Etkinliği Düzenlenecek</div>' +
                '2020 yılında pandeminin etkisiyle birlikte birçok müze ve sanat galerisi hızla online deneyimler sunmaya başladı.' +
                ' Başlangıçta mecburiyetten doğan bu ihtiyaç, zamanla sanat dünyasının normallerinden biri olacak gibi.' +
                ' İlk zamanlarda müze gezileri gibi fiziksel deneyimlerin dijitalleşmesi olarak tasarlanan online deneyimler, yavaş yavaş kendi karakterlerini buluyor.' +
                ' Online mecraların yapısını ve dinamiklerini daha iyi anlamaya başlayan sanat etkinliği organizatörlerinin,' +
                ' ilerleyen dönemlerde bu mecraların sunduğu olanakları daha fazla kullanarak fiziksel dünyada yapılması güç sanat etkinliklerini tasarlayacaklarını öngörmek mümkün.' +
                '<div class="job-advertisement-heading">Arka Planda Kalmış Olanların Sesi Daha Fazla Duyulacak</div>' +
                'Milenyum jenerasyonun sanat pazarına yavaş yavaş hâkim olmasının sonuçlarından biri de talep gören sanat eserlerinin niteliğinin değişmesi.' +
                ' Daha önce dijital mecralarda ya da teknoloji yardımıyla eserler üreten sanatçıların daha fazla değer göreceğini söylemiştik.' +
                ' Benzer bir durum Afrikalı modern sanatçılar ya da sokak sanatçıları için de geçerli.<br>' +
                'Bu değişimin en büyük nedeni: İnternetin sanat eserlerinin dağıtımını oldukça kolay hale getirmesidir.' +
                ' Bu sayede sanat alanında geleneksel köklere sahip olmayan sanatçılar, daha demokratik bir şekilde geniş kitlelere ulaşma şansı elde ediyorlar.' +
                ' Dolaşımdaki sanat eseri sayısının artması da sanat eserlerine olan talebin niteliğinin değişmesine neden oluyor.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Yakın Gelecekte Sanat Piyasasına Yön Verecek 5 Trend</div>' +
                'Gelecek hakkında öngörülerde bulunmak hiçbir zaman kolay bir iş olmadı.' +
                ' Ancak son iki yıldır, COVID-19 salgını nedeniyle gelecek tahminleri yapmak daha da güç hale geldi.' +
                ' Ancak salgının ilk zamanlarında olduğu kadar bilinmezliklerle dolu sularda ilerleyemiyoruz.' +
                ' Salgının küresel etkilerinin ne zaman sona ereceğini henüz bilmesek de salgınla yaşamayı bir derece öğrendik.' +
                ' Sizinle de sanat dünyasına yakın gelecekte yön vereceğini düşündüğümüz bazı trendleri paylaşmak istiyoruz.' +
                '<div class="job-advertisement-heading">Online Sanat Piyasası Büyümeye Devam Edecek</div>' +
                'Pandemi öncesinde online sanat pazarının yıllık hacmi 4,5 ile 6 milyar dolar arasında değişiyordu.' +
                ' 2020 yılında pandemi nedeniyle fiziksel sanat aktivitelerinin büyük bir bölümünün iptal edilmesinin ardından bu hacim 2020 yılında 12.4 milyar dolara ulaştı.' +
                ' Diğer bir yandan online satışların yaklaşık %30’unun, daha önce konvansiyonel satış kanallarından sanat eseri satın almamış kişilerden oluşması da ilginç bir veri.' +
                ' Bu durum online kanallar sayesinde hatırı sayılır büyüklükte yeni bir kitlenin “sanat eseri alıcısı” haline geldiğini gösteriyor.<br>' +
                'Önümüzdeki dönemde tıpkı diğer sektörlerde olduğu gibi sanat pazarında da' +
                ' pandemi nedeniyle değişen tüketici satın alma alışkanlıklarının kalıcı hale geleceğini söyleyebiliriz.' +
                ' Sanat yatırımcılarına büyük kolaylıklar sağlayan online kanalların, toplam sanat eseri satışları içindeki payını artıracağını öngörmemiz mümkün.<br>' +
                '<div class="job-advertisement-heading">Sanat Odaklı Teknoloji Girişimlerine Yapılan Yatırımlar Artacak</div>' +
                'Online satış kanallarının toplam satışlar içindeki payının artmasının doğal bir sonucu olarak sanat odaklı start-up’lara olan yatırımların artacağını söylemek mümkün.' +
                ' Pandemi öncesindeki 20 yıl boyunca sadece 640 milyon dolar yatırım toplamayı başaran sanat odaklı start-up’lar,' +
                ' sadece 2020 yılında 380 milyon dolar yatırım almayı başardılar.<br>' +
                'Yatırım kararlarındaki bu trendin tek nedeni pandemi etkileri değil.' +
                ' Önümüzdeki yıllarda milenyum jenerasyonunun sanat pazarına hâkim olacağı gerçeği, sanat pazarında teknolojinin önemini artırıyor.' +
                ' 2030 yılına kadar miras yoluyla milenyum jenerasyonun eline 20 ile 70 trilyon dolar para geçeceği tahmin ediliyor.' +
                ' Milenyum jenerasyonun sanat eseri satın alma konusunda online kanalları, önceki jenerasyonlara göre çok daha fazla kullandığı biliniyor.' +
                ' Bu yüzden çoğu girişimci için sanatın teknoloji tarafında erkenden yer almak kötü bir fikir değil.' +
                '<div class="job-advertisement-heading">Sanat Üretiminde Teknolojinin Payı Artacak</div>' +
                'İnternetin ana akım haline gelmesinden bu yana teknolojinin, sanat eserlerinin üretimi alanındaki varlığı büyüyor.' +
                ' Ancak sanat pazarının son derece muhafazakâr temeller üzerine inşa edilmiş olması,' +
                ' teknolojinin kullanıldığı sanat eserlerinin finansal anlamda yeteri kadar değer görmemesine neden oluyordu.' +
                ' Özellikle NFT gibi teknolojilerin sanat pazarında kullanımının artması bu durumu değiştiriyor.' +
                ' Sanat eserlerine olan talepteki bu sapma, sanat üretim süreçlerinin de gözden geçirilmesine neden oluyor.' +
                ' Önümüzdeki dönemde dijital tuvallerin daha fazla kullanılacağını söylememiz mümkün.' +
                '<div class="job-advertisement-heading">Daha Fazla Online Sanat Etkinliği Düzenlenecek</div>' +
                '2020 yılında pandeminin etkisiyle birlikte birçok müze ve sanat galerisi hızla online deneyimler sunmaya başladı.' +
                ' Başlangıçta mecburiyetten doğan bu ihtiyaç, zamanla sanat dünyasının normallerinden biri olacak gibi.' +
                ' İlk zamanlarda müze gezileri gibi fiziksel deneyimlerin dijitalleşmesi olarak tasarlanan online deneyimler, yavaş yavaş kendi karakterlerini buluyor.' +
                ' Online mecraların yapısını ve dinamiklerini daha iyi anlamaya başlayan sanat etkinliği organizatörlerinin,' +
                ' ilerleyen dönemlerde bu mecraların sunduğu olanakları daha fazla kullanarak fiziksel dünyada yapılması güç sanat etkinliklerini tasarlayacaklarını öngörmek mümkün.' +
                '<div class="job-advertisement-heading">Arka Planda Kalmış Olanların Sesi Daha Fazla Duyulacak</div>' +
                'Milenyum jenerasyonun sanat pazarına yavaş yavaş hâkim olmasının sonuçlarından biri de talep gören sanat eserlerinin niteliğinin değişmesi.' +
                ' Daha önce dijital mecralarda ya da teknoloji yardımıyla eserler üreten sanatçıların daha fazla değer göreceğini söylemiştik.' +
                ' Benzer bir durum Afrikalı modern sanatçılar ya da sokak sanatçıları için de geçerli.<br>' +
                'Bu değişimin en büyük nedeni: İnternetin sanat eserlerinin dağıtımını oldukça kolay hale getirmesidir.' +
                ' Bu sayede sanat alanında geleneksel köklere sahip olmayan sanatçılar, daha demokratik bir şekilde geniş kitlelere ulaşma şansı elde ediyorlar.' +
                ' Dolaşımdaki sanat eseri sayısının artması da sanat eserlerine olan talebin niteliğinin değişmesine neden oluyor.',
            ROUTER_LINK: {
                TEXT: 'Eserleri İncele',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Teknoloji, Sanat Dünyası ve Demokrasi',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Teknoloji Sanat Dünyasını Daha Demokratik Hale Getirir mi?</div>' +
                'Sanat, hepimiz için var. Ancak sanat dünyasının büyük bir bölümünün ekonomik bakımdan güçlü, elit bir tabakanın elinde olduğu da gerçek.' +
                ' Geniş kitlelerin sanata ulaşabilmesi, sanat eseri sahipliğinin belirli bir zümrenin kontrolünden kurtulması ve sanatın demokratikleşmesi anlamına geliyor.' +
                ' Teknoloji sayesinde sanatın demokratikleşmesinin mümkün olabileceğini de özellikle pandemi döneminde yaşanan gelişmeler sayesinde gördük.' +
                '<div class="job-advertisement-heading">Sanat Pazarı Kabuğundan Çıkmak Zorunda Kaldı</div>' +
                'Sanat pazarı oldukça muhafazakâr bir yapıya sahip.' +
                ' Bu yüzden sosyal hayatın hemen hemen her kurumu, dijital devrim sonrasında kendini yeniden tanımlarken sanat dünyası;' +
                ' uzun bir süre boyunca bu devrimi yok sayarak eski gelenekleriyle var olmaya devam etti.' +
                ' Tuvallere yapılan resimler, duvarları olan galerilerde sergilendi ve müzayedeyi yöneten münadinin masaya vurduğu çekiçle satıldı.<br>' +
                'Ancak küresel COVID-19 salgını sanat dünyasını kabuğundan çıkması için zorladı.' +
                ' Kapanmalar nedeniyle durma noktasına gelen sanat piyasası, bir ölçüde de olsa yeniden hareket kazanabilmek için farklı yöntemler denemeye başladı.' +
                ' Bu yöntemleri geliştirirken de uzun yıllar boyunca yok saydığı dijital teknolojiden bol bol yararlandı.<br>' +
                'Online sergi salonları ve müzayedeler yeni uygulamalar değil.' +
                ' Bu uygulamalar pandemi öncesinde de kullanılıyordu.' +
                ' Ancak fiziksel etkinliklerin düzenlenemediği kapanmalar bu uygulamaları birer alternatif olmaktan çıkarıp zorunluluk haline getirdi.<br>' +
                'Hemen hemen her sektörde olduğu gibi sanat pazarında da bu uygulamaların bir bölümünün pandemi sonrasında da kalıcı olabileceği konuşuluyor.' +
                ' Teknolojinin sağladığı kolaylıklar ve yeni olanaklar, alışkanlıkların değişmesine neden oluyor.<br>' +
                'Ancak sanatın gerçekten demokratikleşmesi için sanat dünyasında uzun süredir var olan kurumların ve iş yapış şekillerinin kısmi bir şekilde dijitalleşmesi yeterli değil.' +
                ' Bu dijitalleşme sanat eserlerinin daha geniş kitlelere ulaşmasını sağlıyor. Ancak bu kitle yine varlıklı insanlardan oluşuyor.' +
                '<div class="job-advertisement-heading">Dijital Sanat, Sanat mıdır?</div>' +
                'Dijital ortamlarda eserler üreten sanatçılar uzun süredir var. Uzun süredir de bu sanatçılar geleneksel sanat çevreleri tarafından dışlanıyorlar.' +
                ' Bu çevrelerin bir bölümü, dijital sanat eserlerini “sanat” olarak görmemek gibi olumsuz reflekslere sahipler.<br>' +
                'Dijital sanatın “sanat” olduğunu düşünenlerin en büyük argümanları ise, sanat eseri yaratmakta kullanılan her aracın aslında bir teknoloji olduğu.' +
                ' Bu teknoloji, sanat eserinin üretiminden dağıtımına kadar her şeyini zamanla değiştiriyor.<br>' +
                'Bu, derin bir tartışma.' +
                ' Sanatın yatırım tarafından bu tartışmaya baktığımızda ortada olan tek bir gerçek var.' +
                ' Dijital işler üreten insanlar var ve bu insanların ürettiklerine talip olan bir kitle var.' +
                ' Ve bu kitle müzayede salonlarında değil. Evlerde, oturma odalarında ekranlarına bakıyorlar.' +
                '<div class="job-advertisement-heading">Sadece Sanat Değil, Sanat Pazarı da Dijitalleşiyor</div>' +
                'Blok zinciri teknolojisi sayesinde ortaya çıkan NFT kavramı,' +
                ' dijital sanat eserlerinin ekonomik sınıftan bağımsız olarak geniş kitleler tarafından satın alınabilmesini oldukça kolaylaştırdı.' +
                ' Diğer bir yandan Artiox olarak bizim de içinde bulunduğumuz sanat alanındaki teknolojik girişimler,' +
                ' sanat eseri sahipliğini ortalama gelire sahip bireyler için daha ulaşılabilir hale getiriyor.' +
                ' Teknoloji ve sanat tüketicilerinin değişen yapısı sayesinde geleneksel sanat pazarına güçlü bir alternatif ortaya çıkıyor.<br>' +
                'Bu gelişme sadece sanat eseri sahipliğini değil, aynı zamanda sanat eseri üretim süreçlerini de derinden etkiliyor.' +
                ' Ürettiği işler geleneksel pazarlarda kabul görmeyen ve bu yüzden finansal rahatlığa kavuşamayan sanatçıların artık daha fazla şansı var.' +
                ' Bir sanat eserinin değerinin belirlenmesinde, sanatçının marka değeri hâlâ oldukça belirleyici olsa da' +
                ' bu marka değerini oluşturmak için sanatçıların başka kurumlara ve kişilere olan ihtiyacı artık çok daha az.' +
                ' Bu durum da sanatçılara müthiş bir özgürlük alanı sunuyor.<br>' +
                'Bir devrimin henüz başlangıcında olduğumuz kesin.' +
                ' Önümüzdeki yıllar sanat kavramının ve sanat piyasasının yeniden tanımlandığı zamanlar olacak.' +
                ' Bu devrim hareketinde yerinizi erkenden almanızı öneririz. Artiox’ta küçük bütçelerle sanat eserlerine yatırım yaparak gelecekten payınızı alabilirsiniz.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Teknoloji Sanat Dünyasını Daha Demokratik Hale Getirir mi?</div>' +
                'Sanat, hepimiz için var. Ancak sanat dünyasının büyük bir bölümünün ekonomik bakımdan güçlü, elit bir tabakanın elinde olduğu da gerçek.' +
                ' Geniş kitlelerin sanata ulaşabilmesi, sanat eseri sahipliğinin belirli bir zümrenin kontrolünden kurtulması ve sanatın demokratikleşmesi anlamına geliyor.' +
                ' Teknoloji sayesinde sanatın demokratikleşmesinin mümkün olabileceğini de özellikle pandemi döneminde yaşanan gelişmeler sayesinde gördük.' +
                '<div class="job-advertisement-heading">Sanat Pazarı Kabuğundan Çıkmak Zorunda Kaldı</div>' +
                'Sanat pazarı oldukça muhafazakâr bir yapıya sahip.' +
                ' Bu yüzden sosyal hayatın hemen hemen her kurumu, dijital devrim sonrasında kendini yeniden tanımlarken sanat dünyası;' +
                ' uzun bir süre boyunca bu devrimi yok sayarak eski gelenekleriyle var olmaya devam etti.' +
                ' Tuvallere yapılan resimler, duvarları olan galerilerde sergilendi ve müzayedeyi yöneten münadinin masaya vurduğu çekiçle satıldı.<br>' +
                'Ancak küresel COVID-19 salgını sanat dünyasını kabuğundan çıkması için zorladı.' +
                ' Kapanmalar nedeniyle durma noktasına gelen sanat piyasası, bir ölçüde de olsa yeniden hareket kazanabilmek için farklı yöntemler denemeye başladı.' +
                ' Bu yöntemleri geliştirirken de uzun yıllar boyunca yok saydığı dijital teknolojiden bol bol yararlandı.<br>' +
                'Online sergi salonları ve müzayedeler yeni uygulamalar değil.' +
                ' Bu uygulamalar pandemi öncesinde de kullanılıyordu.' +
                ' Ancak fiziksel etkinliklerin düzenlenemediği kapanmalar bu uygulamaları birer alternatif olmaktan çıkarıp zorunluluk haline getirdi.<br>' +
                'Hemen hemen her sektörde olduğu gibi sanat pazarında da bu uygulamaların bir bölümünün pandemi sonrasında da kalıcı olabileceği konuşuluyor.' +
                ' Teknolojinin sağladığı kolaylıklar ve yeni olanaklar, alışkanlıkların değişmesine neden oluyor.<br>' +
                'Ancak sanatın gerçekten demokratikleşmesi için sanat dünyasında uzun süredir var olan kurumların ve iş yapış şekillerinin kısmi bir şekilde dijitalleşmesi yeterli değil.' +
                ' Bu dijitalleşme sanat eserlerinin daha geniş kitlelere ulaşmasını sağlıyor. Ancak bu kitle yine varlıklı insanlardan oluşuyor.' +
                '<div class="job-advertisement-heading">Dijital Sanat, Sanat mıdır?</div>' +
                'Dijital ortamlarda eserler üreten sanatçılar uzun süredir var. Uzun süredir de bu sanatçılar geleneksel sanat çevreleri tarafından dışlanıyorlar.' +
                ' Bu çevrelerin bir bölümü, dijital sanat eserlerini “sanat” olarak görmemek gibi olumsuz reflekslere sahipler.<br>' +
                'Dijital sanatın “sanat” olduğunu düşünenlerin en büyük argümanları ise, sanat eseri yaratmakta kullanılan her aracın aslında bir teknoloji olduğu.' +
                ' Bu teknoloji, sanat eserinin üretiminden dağıtımına kadar her şeyini zamanla değiştiriyor.<br>' +
                'Bu, derin bir tartışma.' +
                ' Sanatın yatırım tarafından bu tartışmaya baktığımızda ortada olan tek bir gerçek var.' +
                ' Dijital işler üreten insanlar var ve bu insanların ürettiklerine talip olan bir kitle var.' +
                ' Ve bu kitle müzayede salonlarında değil. Evlerde, oturma odalarında ekranlarına bakıyorlar.' +
                '<div class="job-advertisement-heading">Sadece Sanat Değil, Sanat Pazarı da Dijitalleşiyor</div>' +
                'Blok zinciri teknolojisi sayesinde ortaya çıkan NFT kavramı,' +
                ' dijital sanat eserlerinin ekonomik sınıftan bağımsız olarak geniş kitleler tarafından satın alınabilmesini oldukça kolaylaştırdı.' +
                ' Diğer bir yandan Artiox olarak bizim de içinde bulunduğumuz sanat alanındaki teknolojik girişimler,' +
                ' sanat eseri sahipliğini ortalama gelire sahip bireyler için daha ulaşılabilir hale getiriyor.' +
                ' Teknoloji ve sanat tüketicilerinin değişen yapısı sayesinde geleneksel sanat pazarına güçlü bir alternatif ortaya çıkıyor.<br>' +
                'Bu gelişme sadece sanat eseri sahipliğini değil, aynı zamanda sanat eseri üretim süreçlerini de derinden etkiliyor.' +
                ' Ürettiği işler geleneksel pazarlarda kabul görmeyen ve bu yüzden finansal rahatlığa kavuşamayan sanatçıların artık daha fazla şansı var.' +
                ' Bir sanat eserinin değerinin belirlenmesinde, sanatçının marka değeri hâlâ oldukça belirleyici olsa da' +
                ' bu marka değerini oluşturmak için sanatçıların başka kurumlara ve kişilere olan ihtiyacı artık çok daha az.' +
                ' Bu durum da sanatçılara müthiş bir özgürlük alanı sunuyor.<br>' +
                'Bir devrimin henüz başlangıcında olduğumuz kesin.' +
                ' Önümüzdeki yıllar sanat kavramının ve sanat piyasasının yeniden tanımlandığı zamanlar olacak.' +
                ' Bu devrim hareketinde yerinizi erkenden almanızı öneririz. Artiox’ta küçük bütçelerle sanat eserlerine yatırım yaparak gelecekten payınızı alabilirsiniz.',
            ROUTER_LINK: {
                TEXT: 'Eserleri İncele',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Sanat Eserlerini Nasıl Seçiyoruz?',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Artiox\'ta Listelenecek Sanat Eserlerini Nasıl Seçiyoruz?</div>' +
                'Artiox olarak teknoloji sayesinde, düşük bütçelerle bile geniş kitlelerin sanata kolaylıkla yatırım yapmasını sağlıyoruz.' +
                ' Ancak sanat, çok katmanlı bir yatırım alanı.' +
                ' Bu yüzden siz kullanıcılarımıza sanat yatırımcılığının farklı boyutlarının düşünüldüğü çözümler sunmamız gerekiyor.' +
                ' Bu sayede sanat yatırımcılığı alanına daha az para ve zaman sermayesiyle giriş yapmanız mümkün hale geliyor.<br>' +
                'Herhangi bir alana yatırım yapmanın en önemli koşulu, yatırımın gelecekte bir getiri vadetmesidir.' +
                ' Sanat yatırımları için de aynı kural geçerli.' +
                ' Bir sanat eserine yapılan yatırımın finansal açıdan anlamı olabilmesi için söz konusu sanat eserinin ticari bir potansiyelinin olması gerek.<br>' +
                'Ancak bu potansiyeli sanat pazarı alanında uzman olmayan birinin öngörmesi oldukça güç.' +
                ' Söz konusu eseri üreten sanatçı, o eserin ticari potansiyelinin belirlenmesinde oldukça etkili.' +
                ' Ancak aynı sanatçının iki farklı eseri bile farklı ticari hayatlara sahip olabiliyor.<br>' +
                'Bu noktada Artiox olarak kullanıcılarımıza sağladığımız en önemli kolaylıklardan biri, finansal açıdan geleceği olan eserlerin seçimi.' +
                ' Bu sayede kullanıcılarımız, sanat pazarı alanında uzmanlık kazanmak için zaman harcamadan sanat yatırımcılığı alanına hızlı bir giriş yapabiliyorlar.<br>' +
                'Artiox’un bir sanat eserini bünyesine katma yolculuğu,' +
                ' koleksiyonerlerin Artiox’a ulaşması ya da Artiox’un bazı eserler belirleyerek koleksiyonerlere ulaşmasıyla başlıyor.' +
                ' Yatırım yapılacak eserler belirlendikten sonra alanında uzman bir eksper tarafından eserin orijinalliği ve fiziksel durumu raporlanıyor.' +
                ' Belirlenen eserin eksper onayından geçmesinin ardından sıra; eserin fiyatının belirlenmesine geliyor.' +
                ' Bir uzmanın rehberliğinde belirlenen fiyat doğrultusunda eser sigortalanıyor ve sanat eserlerinin saklanması için özel tasarlanmış bir depoda muhafaza ediliyor.' +
                ' Eserin Artiox bünyesine girme süreci tamamlandıktan sonra blok zinciri tarafı devreye giriyor.' +
                ' Bu aşamada Artiox olarak esere özel bir akıllı kontrat oluşturuyoruz ve belirlediğimiz miktar üzerinden eseri tokenize ediyoruz.' +
                ' Son aşamada da eserin fiyatına ve token sayısına bağlı olarak eserin tokenlerini satışa çıkarıyoruz.<br>' +
                'Artiox olarak en önemli görevlerimizden biri sadece düşük bütçelerle sanat yatırımcılığını kolaylaştırmak değil.' +
                ' Aynı zamanda doğru sanat eserlerini seçerek ve bu eserleri fiziksel tehlikelerden uzak ortamlarda saklayarak kullanıcılarımızın alacağı riski minimum düzeyde tutmak.' +
                ' Bu sayede Artiox olarak sanat yatırımcılığı konusunda hiçbir tecrübesi olmayan kullanıcılarımıza bile güvenle yatırım yapabilecekleri bir platform sunuyoruz.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Artiox\'ta Listelenecek Sanat Eserlerini Nasıl Seçiyoruz?</div>' +
                'Artiox olarak teknoloji sayesinde, düşük bütçelerle bile geniş kitlelerin sanata kolaylıkla yatırım yapmasını sağlıyoruz.' +
                ' Ancak sanat, çok katmanlı bir yatırım alanı.' +
                ' Bu yüzden siz kullanıcılarımıza sanat yatırımcılığının farklı boyutlarının düşünüldüğü çözümler sunmamız gerekiyor.' +
                ' Bu sayede sanat yatırımcılığı alanına daha az para ve zaman sermayesiyle giriş yapmanız mümkün hale geliyor.<br>' +
                'Herhangi bir alana yatırım yapmanın en önemli koşulu, yatırımın gelecekte bir getiri vadetmesidir.' +
                ' Sanat yatırımları için de aynı kural geçerli.' +
                ' Bir sanat eserine yapılan yatırımın finansal açıdan anlamı olabilmesi için söz konusu sanat eserinin ticari bir potansiyelinin olması gerek.<br>' +
                'Ancak bu potansiyeli sanat pazarı alanında uzman olmayan birinin öngörmesi oldukça güç.' +
                ' Söz konusu eseri üreten sanatçı, o eserin ticari potansiyelinin belirlenmesinde oldukça etkili.' +
                ' Ancak aynı sanatçının iki farklı eseri bile farklı ticari hayatlara sahip olabiliyor.<br>' +
                'Bu noktada Artiox olarak kullanıcılarımıza sağladığımız en önemli kolaylıklardan biri, finansal açıdan geleceği olan eserlerin seçimi.' +
                ' Bu sayede kullanıcılarımız, sanat pazarı alanında uzmanlık kazanmak için zaman harcamadan sanat yatırımcılığı alanına hızlı bir giriş yapabiliyorlar.<br>' +
                'Artiox’un bir sanat eserini bünyesine katma yolculuğu,' +
                ' koleksiyonerlerin Artiox’a ulaşması ya da Artiox’un bazı eserler belirleyerek koleksiyonerlere ulaşmasıyla başlıyor.' +
                ' Yatırım yapılacak eserler belirlendikten sonra alanında uzman bir eksper tarafından eserin orijinalliği ve fiziksel durumu raporlanıyor.' +
                ' Belirlenen eserin eksper onayından geçmesinin ardından sıra; eserin fiyatının belirlenmesine geliyor.' +
                ' Bir uzmanın rehberliğinde belirlenen fiyat doğrultusunda eser sigortalanıyor ve sanat eserlerinin saklanması için özel tasarlanmış bir depoda muhafaza ediliyor.' +
                ' Eserin Artiox bünyesine girme süreci tamamlandıktan sonra blok zinciri tarafı devreye giriyor.' +
                ' Bu aşamada Artiox olarak esere özel bir akıllı kontrat oluşturuyoruz ve belirlediğimiz miktar üzerinden eseri tokenize ediyoruz.' +
                ' Son aşamada da eserin fiyatına ve token sayısına bağlı olarak eserin tokenlerini satışa çıkarıyoruz.<br>' +
                'Artiox olarak en önemli görevlerimizden biri sadece düşük bütçelerle sanat yatırımcılığını kolaylaştırmak değil.' +
                ' Aynı zamanda doğru sanat eserlerini seçerek ve bu eserleri fiziksel tehlikelerden uzak ortamlarda saklayarak kullanıcılarımızın alacağı riski minimum düzeyde tutmak.' +
                ' Bu sayede Artiox olarak sanat yatırımcılığı konusunda hiçbir tecrübesi olmayan kullanıcılarımıza bile güvenle yatırım yapabilecekleri bir platform sunuyoruz.',
            ROUTER_LINK: {
                TEXT: 'Eserleri İncele',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Frida Kahlo’nun Otoportresinin Değeri',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Frida Kahlo’nun Otoportresi Müzayedede 34,9 Milyon Dolara Satıldı</div>' +
                'Eylül 1925’te Mexico City’de bir otobüs, erkek arkadaşıyla birlikte okula giden doktor adayı bir kadına çarptı.' +
                ' Bu talihsiz kazanın 20. yüzyılın en önemli sanatçılarından birinin doğuşuna vesile olacağı henüz bilinmiyordu.<br>' +
                'Kazazede genç kadın, Frida Kahlo idi.' +
                ' Yatağa bağlı kaldığı sırada resme başlayan on sekiz yaşındaki Kahlo, sanat tarihinde hak ettiği konuma ulaşan az sayıda kadın sanatçıdan biri oldu.' +
                ' Aradan yaklaşık bir asır geçtikten sonra ne feminist bir ikona dönüşeceğini ne de otoportresinin rekor bir fiyatla satılacağını tahmin edebilirdi.<br>' +
                '<div class="job-advertisement-heading">Diego y Yo</div>' +
                'Söz konusu otoportrenin başlığı Diego y Yo (Diego ve Ben).' +
                ' 1949 tarihli bu tablo, Kahlo’nun son ‘büst’ otoportresi olma özelliğini taşıyor.' +
                ' Tabloda Kahlo’yu, alnında kocası Diego’nun küçük bir imgesiyle gözyaşları içinde görüyoruz.<br>' +
                'Kahlo, ölümünden beş yıl önce bu resmi yaptığı sırada, Diego; Kahlo’nun da arkadaşı olan bir aktrisle ilişki yaşıyordu.' +
                ' Alnındaki Diego minyatürü, Kahlo’nun eşine duyduğu tutkulu aşkı betimlerken Diego imgesinin üçüncü göz olarak kullanımı ise;' +
                ' Kahlo’nun Diego’nun zekâsı ve bilgeliğine duyduğu hayranlığa işaret ediyor.' +
                ' Diego’nun sadakatsizliklerini yıllarca şakaya vuran Kahlo, gerçekte acı çektiğini bu resimde yanaklarından süzülen gözyaşlarıyla ifade ediyor.<br>' +
                'Kahlo, otoportrelerinin çoğunda kendini saçları sıkıca toplanmış olarak resmeder.' +
                ' Bu resimde boynuna dolanan dağınık saçları; sanatçının Diego olmadan soluksuz kaldığını, boğulduğunu akla getiriyor.<br>' +
                '<div class="job-advertisement-heading">Rekor Kıran Satış</div>' +
                'Diego y Yo, 1990 yılında 1,4 milyon dolara satılmış ve Kahlo, eseri müzayedede 1 milyon dolardan yüksek fiyatla satılan ilk Latin Amerikalı sanatçı olmuştu.' +
                ' Aynı eser, 15 Kasım 2021’de Sotheby’s New York’ta müzayedeye çıktı.' +
                ' İki dakikadan kısa süren açık arttırmanın sonunda tablo, 34,9 milyon dolara satıldı.' +
                ' Eserin yeni sahibi Eduardo F. Costantini, Buenos Aires’teki Latin Amerikan sanatı müzesi Malba’nın kurucusu.' +
                ' Costantini, 2022’de eserin Malba’da sergileneceğini belirtti.<br>' +
                'Tablo, 34,9 milyon dolarlık fiyatıyla rekor kırarak Latin Amerikalı bir sanatçının müzayedede satılan en pahalı eseri oldu.' +
                ' Bundan önceki rekor ise, Rivera’nın 2018’de 9,8 milyon dolara satılan The Rivals (Rakipler) başlıklı tablosuna aitti.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Frida Kahlo’nun Otoportresi Müzayedede 34,9 Milyon Dolara Satıldı</div>' +
                'Eylül 1925’te Mexico City’de bir otobüs, erkek arkadaşıyla birlikte okula giden doktor adayı bir kadına çarptı.' +
                ' Bu talihsiz kazanın 20. yüzyılın en önemli sanatçılarından birinin doğuşuna vesile olacağı henüz bilinmiyordu.<br>' +
                'Kazazede genç kadın, Frida Kahlo idi.' +
                ' Yatağa bağlı kaldığı sırada resme başlayan on sekiz yaşındaki Kahlo, sanat tarihinde hak ettiği konuma ulaşan az sayıda kadın sanatçıdan biri oldu.' +
                ' Aradan yaklaşık bir asır geçtikten sonra ne feminist bir ikona dönüşeceğini ne de otoportresinin rekor bir fiyatla satılacağını tahmin edebilirdi.<br>' +
                '<div class="job-advertisement-heading">Diego y Yo</div>' +
                'Söz konusu otoportrenin başlığı Diego y Yo (Diego ve Ben).' +
                ' 1949 tarihli bu tablo, Kahlo’nun son ‘büst’ otoportresi olma özelliğini taşıyor.' +
                ' Tabloda Kahlo’yu, alnında kocası Diego’nun küçük bir imgesiyle gözyaşları içinde görüyoruz.<br>' +
                'Kahlo, ölümünden beş yıl önce bu resmi yaptığı sırada, Diego; Kahlo’nun da arkadaşı olan bir aktrisle ilişki yaşıyordu.' +
                ' Alnındaki Diego minyatürü, Kahlo’nun eşine duyduğu tutkulu aşkı betimlerken Diego imgesinin üçüncü göz olarak kullanımı ise;' +
                ' Kahlo’nun Diego’nun zekâsı ve bilgeliğine duyduğu hayranlığa işaret ediyor.' +
                ' Diego’nun sadakatsizliklerini yıllarca şakaya vuran Kahlo, gerçekte acı çektiğini bu resimde yanaklarından süzülen gözyaşlarıyla ifade ediyor.<br>' +
                'Kahlo, otoportrelerinin çoğunda kendini saçları sıkıca toplanmış olarak resmeder.' +
                ' Bu resimde boynuna dolanan dağınık saçları; sanatçının Diego olmadan soluksuz kaldığını, boğulduğunu akla getiriyor.<br>' +
                '<div class="job-advertisement-heading">Rekor Kıran Satış</div>' +
                'Diego y Yo, 1990 yılında 1,4 milyon dolara satılmış ve Kahlo, eseri müzayedede 1 milyon dolardan yüksek fiyatla satılan ilk Latin Amerikalı sanatçı olmuştu.' +
                ' Aynı eser, 15 Kasım 2021’de Sotheby’s New York’ta müzayedeye çıktı.' +
                ' İki dakikadan kısa süren açık arttırmanın sonunda tablo, 34,9 milyon dolara satıldı.' +
                ' Eserin yeni sahibi Eduardo F. Costantini, Buenos Aires’teki Latin Amerikan sanatı müzesi Malba’nın kurucusu.' +
                ' Costantini, 2022’de eserin Malba’da sergileneceğini belirtti.<br>' +
                'Tablo, 34,9 milyon dolarlık fiyatıyla rekor kırarak Latin Amerikalı bir sanatçının müzayedede satılan en pahalı eseri oldu.' +
                ' Bundan önceki rekor ise, Rivera’nın 2018’de 9,8 milyon dolara satılan The Rivals (Rakipler) başlıklı tablosuna aitti.',
            ROUTER_LINK: {
                TEXT: 'Eserleri İncele',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Sanatseverlere Alabileceğiniz 8 Kitap',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Sanatsever Dostlarınıza Yılbaşı Hediyesi Olarak Alabileceğiniz En İyi 8 Kitap</div>' +
                'Yeni yıl yaklaşıyor. Sevdiklerinize hediyeler alacaksınız ancak ne almanız gerektiğini bilmiyorsunuz.' +
                ' Eğer hediye almayı düşündüğünüz kişi bir sanatseverse endişelenmenize gerek yok.' +
                ' Sanatla ilgili bir kitap onu mutlu edecektir.<br>' +
                'Ancak kitapçılarda birçok sanat kitabı var. Siz hangisini almalısınız?' +
                '<div class="job-advertisement-heading">Yaratma Cesareti - Rollo May</div>' +
                'Sanatı ve sanatçıyı anlamak için öncelikle “yaratıcılık” kavramını anlamak gerekiyor.' +
                ' Ancak yaratıcılık oldukça yanlış anlaşılmış bir kavram.' +
                ' 20. yüzyılın en önemli psikologlarından Rollo May, yaratıcılığın cesaret isteyen bir yolculuk olduğunu düşünüyor.' +
                ' Bu yol; ıstırap, engeller, endişe ve hayal kırıklıklarıyla dolu ve bu zorlukların üstesinden gelmek için büyük cesaret gerekiyor.' +
                '<div class="job-advertisement-heading">Sanatın Kısa Öyküsü - Susie Hodge</div>' +
                'Susie Hodge’un “Sanatın Kısa Öyküsü” kitabı sanata yeni ilgi duymaya başlamış kişiler için harika bir başlangıç rehberi.' +
                ' Sanat akımlarının ve bazı eserlerin anlatıldığı bu kitap, sanat dünyasına giriş için ideal eserlerden.' +
                '<div class="job-advertisement-heading">Stüdyo Ghibli - Michelle Le Blanc</div>' +
                'Adı Stüdyo Ghibli ile özdeşleşen Hayao Miyazaki, ülkemizde de çok sevilen bir animasyon sanatçısı.' +
                ' Ancak çoğumuzun hem Stüdyo Ghibli hem de Hayao Miyazaki hakkındaki bilgisi DVD ekstralarının ötesine geçmiyor.' +
                ' Akademik bir yaklaşımla yazılmış bu kitap Hayao Miyazaki ve İsao Takahata filmlerine daha yakından bakmamızı sağlıyor.' +
                '<div class="job-advertisement-heading">Nasıl Sanatçı Olunur - Jerry Saltz</div>' +
                'New York Magazine’in kıdemli sanat eleştirmenlerinden Jerry Saltz,' +
                ' sadece sanatçı olmak isteyenler için değil bir sanatçının nasıl ortaya çıktığını anlamak isteyenler için de oldukça kolay anlaşılır bir kitap yazmış.' +
                ' Eğer hediye aldığınız yakınınızın sanatsal yetenekleri olduğunu ve artık bir şeyler yapması gerektiğini düşünüyorsanız bu kitap güzel bir hediye olabilir.' +
                '<div class="job-advertisement-heading">Sanat ve Arzu - Ulus Baker</div>' +
                'Ulus Baker’in ODTÜ Görsel-İşitsel Sistemler Araştırma ve Üretim Merkezi’nde 1998 yılında verdiği seminerlerin kitaplaştırılmış hali olan bu eserde Ulus Baker;' +
                ' Spinoza, Descartes, Leibniz, Kant felsefelerinin sanatla olan ilişkisini sorguluyor.' +
                '<div class="job-advertisement-heading">21 Kedide Sanat Tarihi - Nia Gould</div>' +
                'Hediye alacağınız kişi hem sanatı hem de kedileri seviyorsa, ona Nia Gould’un “21 Kedide Sanat Tarihi” kitabından daha iyi bir hediye alamazsınız.' +
                ' Nia Gould, bu eğlenceli kitapta 21 sanat akımını 21 kediyle anlatıyor.' +
                '<div class="job-advertisement-heading">Osmanlı Tasvir Sanatları 1: Minyatür - Metin And</div>' +
                'Metin And, Türk-İslâm tasvir ve süsleme sanatları hakkındaki araştırmalarıyla tanınıyor.' +
                ' Kendisini ne yazık ki 2008 yılında kaybettik.' +
                ' Yapı Kredi Yayınları Metin And’ın çalışmalarını bir dizi haline getirerek 2002 yılından bu yana tekrar yayınlıyor.' +
                ' Dizinin içindeki minyatür kitabı ise özellikle Osmanlı dönemindeki tasvir sanatlarıyla ilgilenenler için altın değerinde bir kaynak.' +
                '<div class="job-advertisement-heading">Sinan Çağı - Gülru Necipoğlu</div>' +
                '1993 yılından bu yana Harvard Üniversitesi’nde Aga Khan İslami Mimari programının başında olan Gülru Necipoğlu bu kitabında,' +
                ' Mimar Sinan’ın eserlerini daha iyi yorumlayabilme ve mimari alandaki dehasını daha iyi anlayabilmek için bize ışık tutuyor.' +
                ' Hediye almayı düşündüğünüz dostunuz analitik düşünceyle yaratıcılığın birleşiminden etkileniyorsa bu kitap onun için bir hazine olabilir.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Sanatsever Dostlarınıza Yılbaşı Hediyesi Olarak Alabileceğiniz En İyi 8 Kitap</div>' +
                'Yeni yıl yaklaşıyor. Sevdiklerinize hediyeler alacaksınız ancak ne almanız gerektiğini bilmiyorsunuz.' +
                ' Eğer hediye almayı düşündüğünüz kişi bir sanatseverse endişelenmenize gerek yok.' +
                ' Sanatla ilgili bir kitap onu mutlu edecektir.<br>' +
                'Ancak kitapçılarda birçok sanat kitabı var. Siz hangisini almalısınız?' +
                '<div class="job-advertisement-heading">Yaratma Cesareti - Rollo May</div>' +
                'Sanatı ve sanatçıyı anlamak için öncelikle “yaratıcılık” kavramını anlamak gerekiyor.' +
                ' Ancak yaratıcılık oldukça yanlış anlaşılmış bir kavram.' +
                ' 20. yüzyılın en önemli psikologlarından Rollo May, yaratıcılığın cesaret isteyen bir yolculuk olduğunu düşünüyor.' +
                ' Bu yol; ıstırap, engeller, endişe ve hayal kırıklıklarıyla dolu ve bu zorlukların üstesinden gelmek için büyük cesaret gerekiyor.' +
                '<div class="job-advertisement-heading">Sanatın Kısa Öyküsü - Susie Hodge</div>' +
                'Susie Hodge’un “Sanatın Kısa Öyküsü” kitabı sanata yeni ilgi duymaya başlamış kişiler için harika bir başlangıç rehberi.' +
                ' Sanat akımlarının ve bazı eserlerin anlatıldığı bu kitap, sanat dünyasına giriş için ideal eserlerden.' +
                '<div class="job-advertisement-heading">Stüdyo Ghibli - Michelle Le Blanc</div>' +
                'Adı Stüdyo Ghibli ile özdeşleşen Hayao Miyazaki, ülkemizde de çok sevilen bir animasyon sanatçısı.' +
                ' Ancak çoğumuzun hem Stüdyo Ghibli hem de Hayao Miyazaki hakkındaki bilgisi DVD ekstralarının ötesine geçmiyor.' +
                ' Akademik bir yaklaşımla yazılmış bu kitap Hayao Miyazaki ve İsao Takahata filmlerine daha yakından bakmamızı sağlıyor.' +
                '<div class="job-advertisement-heading">Nasıl Sanatçı Olunur - Jerry Saltz</div>' +
                'New York Magazine’in kıdemli sanat eleştirmenlerinden Jerry Saltz,' +
                ' sadece sanatçı olmak isteyenler için değil bir sanatçının nasıl ortaya çıktığını anlamak isteyenler için de oldukça kolay anlaşılır bir kitap yazmış.' +
                ' Eğer hediye aldığınız yakınınızın sanatsal yetenekleri olduğunu ve artık bir şeyler yapması gerektiğini düşünüyorsanız bu kitap güzel bir hediye olabilir.' +
                '<div class="job-advertisement-heading">Sanat ve Arzu - Ulus Baker</div>' +
                'Ulus Baker’in ODTÜ Görsel-İşitsel Sistemler Araştırma ve Üretim Merkezi’nde 1998 yılında verdiği seminerlerin kitaplaştırılmış hali olan bu eserde Ulus Baker;' +
                ' Spinoza, Descartes, Leibniz, Kant felsefelerinin sanatla olan ilişkisini sorguluyor.' +
                '<div class="job-advertisement-heading">21 Kedide Sanat Tarihi - Nia Gould</div>' +
                'Hediye alacağınız kişi hem sanatı hem de kedileri seviyorsa, ona Nia Gould’un “21 Kedide Sanat Tarihi” kitabından daha iyi bir hediye alamazsınız.' +
                ' Nia Gould, bu eğlenceli kitapta 21 sanat akımını 21 kediyle anlatıyor.' +
                '<div class="job-advertisement-heading">Osmanlı Tasvir Sanatları 1: Minyatür - Metin And</div>' +
                'Metin And, Türk-İslâm tasvir ve süsleme sanatları hakkındaki araştırmalarıyla tanınıyor.' +
                ' Kendisini ne yazık ki 2008 yılında kaybettik.' +
                ' Yapı Kredi Yayınları Metin And’ın çalışmalarını bir dizi haline getirerek 2002 yılından bu yana tekrar yayınlıyor.' +
                ' Dizinin içindeki minyatür kitabı ise özellikle Osmanlı dönemindeki tasvir sanatlarıyla ilgilenenler için altın değerinde bir kaynak.' +
                '<div class="job-advertisement-heading">Sinan Çağı - Gülru Necipoğlu</div>' +
                '1993 yılından bu yana Harvard Üniversitesi’nde Aga Khan İslami Mimari programının başında olan Gülru Necipoğlu bu kitabında,' +
                ' Mimar Sinan’ın eserlerini daha iyi yorumlayabilme ve mimari alandaki dehasını daha iyi anlayabilmek için bize ışık tutuyor.' +
                ' Hediye almayı düşündüğünüz dostunuz analitik düşünceyle yaratıcılığın birleşiminden etkileniyorsa bu kitap onun için bir hazine olabilir.',
            ROUTER_LINK: {
                TEXT: 'Eserleri İncele',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Pablo Picasso Eserlerinin Değeri',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Bazı Ressamlar Güneşi Bir Sarı Noktaya Dönüştürürler, Bazıları İse Bir Sarı Noktayı Güneşe...</div>' +
                'Böyle diyordu Pablo Picasso.' +
                ' Birçok sanat tarihçisine göre Picasso, 20. yüzyılın en önemli sanatçısı.' +
                ' İlk resmini 7 yaşında tamamladıktan yıllar sonra Georges Braque ile kübizmin öncülüğünü yaptı.' +
                ' Resmin yanında müzik, edebiyat ve mimaride de çığır açtı.' +
                ' Öyle ki birçok sanat tarihçisi sanatın Picasso’dan sonra tamamen değiştiğini iddia ediyor ve Picasso’nun sanatını milat olarak görüyor. ' +
                '<div class="job-advertisement-heading">Değeri Yaşarken Anlaşıldı</div>' +
                'Birçok çağdaşının aksine Picasso, değeri yaşarken de bilinen sanatçılardan.' +
                ' Öyle ki Paul Klee onun için “bugünün ressamı” derken Dali ise Paris’e ilk gittiğinde Louvre’dan önce Picasso’yu görmek istemiş.' +
                ' Yaşarken eseri Louvre’da sergilenen ilk sanatçı olma unvanı da Picasso’ya ait.<br>' +
                'Picasso’nun kariyeri boyunca yaklaşık 29.000’i kataloglanmış olan 50.000’den fazla eser ürettiği tahmin ediliyor.' +
                ' Sanat tarihinde çığır açan üretken sanatçı, 1973 yılında öldüğünde hem dünya çapında ünlü hem de son derece zengindi.' +
                ' Picasso vasiyet bırakmamıştı. Varisleri anlaşmazlığa düştü ve servetinin değeri mahkeme tarafından 100 ila 250 milyon dolar olarak belirlendi.' +
                ' Bu rakam, şu anda 530 milyon ila 1,3 milyar dolara karşılık geliyor.' +
                '<div class="job-advertisement-heading">Finansal Başarısını Neye Borçlu?</div>' +
                'Atölyesini ziyaret eden biri, resimlerden birine uzun uzun baktıktan sonra Picasso’ya “Bu neyi temsil ediyor?” diye sormuş.' +
                ' Picasso’nun cevabı şöyle: “İki yüz bin doları.”<br>' +
                'Birçok sanat eleştirmeni, Picasso’nun başarısını sanatsal yeteneğinin yanında finansal dehasına da borçlu olduğu görüşünde.' +
                ' Picasso, medyanın ve halkla ilişkilerin gücünü fark ederek günümüzün pop yıldızlarından çok önce kendisini markalaştırmıştı.' +
                ' Şiddet ve çalkantılarla dolu özel hayatının da kazandığı ünde önemli bir payı olduğu ve eserlerinin değerini artırdığı yorumunu yapan çok sayıda eleştirmen var.' +
                '<div class="job-advertisement-heading">Rekor Kıran Picasso Satışları</div>' +
                'Picasso, sipariş üzerine eserler üretmeyi reddediyor, yalnızca kendi istediğini resmediyordu.' +
                ' Paris’teki büyük koleksiyonerlerin eserlerini satın almasının eserlerinin değerini artıracağının farkındaydı.' +
                ' 1932’de La Coiffure’yi (Kuaför Kız) 56.000 franka satarak rekor kırmıştı. Bu rakam, bugün yaklaşık 172 milyon dolara karşılık geliyor.<br>' +
                '1932 tarihli Le Rêve (Rüya), 1997’de 48,4 milyon dolara satıldığında en pahalı dördüncü tablo idi.' +
                ' Aynı tablo, 2013’te altı katılımcının 19 dakikalık mücadelesinin sonunda, beklenen fiyatın neredeyse iki katına, 155 milyon dolara -bugünkü 172 milyon dolar- satıldı.' +
                ' Fillette à la corbeille fleurie (Çiçek Sepetli Genç Kız), 2018’de 115 milyon dolara -bugünkü 118 milyon dolar- satıldı.<br>' +
                'Bu listeyi istediğimiz kadar uzatabiliriz ama şimdi bir müzayedede satılan en pahalı tablodan bahsedelim.' +
                ' Picasso’nun 15 tablodan oluşan Les Femmes d\'Alger (Cezayirli Kadınlar) serisinin son tablosu Version O (Versiyon O),' +
                ' Mayıs 2015’te Christie’s New York’ta ikinci kez müzayedeye çıktığında rekor kırması bekleniyordu.' +
                ' Satıştan önce 140 milyon dolar olarak değerlenen eser; 179,4 milyon dolara eski Katar Başbakanı Hamad bin Halife Al Thani’ye satılarak müzayedede satılan en pahalı tablo oldu.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Bazı Ressamlar Güneşi Bir Sarı Noktaya Dönüştürürler, Bazıları İse Bir Sarı Noktayı Güneşe...</div>' +
                'Böyle diyordu Pablo Picasso.' +
                ' Birçok sanat tarihçisine göre Picasso, 20. yüzyılın en önemli sanatçısı.' +
                ' İlk resmini 7 yaşında tamamladıktan yıllar sonra Georges Braque ile kübizmin öncülüğünü yaptı.' +
                ' Resmin yanında müzik, edebiyat ve mimaride de çığır açtı.' +
                ' Öyle ki birçok sanat tarihçisi sanatın Picasso’dan sonra tamamen değiştiğini iddia ediyor ve Picasso’nun sanatını milat olarak görüyor. ' +
                '<div class="job-advertisement-heading">Değeri Yaşarken Anlaşıldı</div>' +
                'Birçok çağdaşının aksine Picasso, değeri yaşarken de bilinen sanatçılardan.' +
                ' Öyle ki Paul Klee onun için “bugünün ressamı” derken Dali ise Paris’e ilk gittiğinde Louvre’dan önce Picasso’yu görmek istemiş.' +
                ' Yaşarken eseri Louvre’da sergilenen ilk sanatçı olma unvanı da Picasso’ya ait.<br>' +
                'Picasso’nun kariyeri boyunca yaklaşık 29.000’i kataloglanmış olan 50.000’den fazla eser ürettiği tahmin ediliyor.' +
                ' Sanat tarihinde çığır açan üretken sanatçı, 1973 yılında öldüğünde hem dünya çapında ünlü hem de son derece zengindi.' +
                ' Picasso vasiyet bırakmamıştı. Varisleri anlaşmazlığa düştü ve servetinin değeri mahkeme tarafından 100 ila 250 milyon dolar olarak belirlendi.' +
                ' Bu rakam, şu anda 530 milyon ila 1,3 milyar dolara karşılık geliyor.' +
                '<div class="job-advertisement-heading">Finansal Başarısını Neye Borçlu?</div>' +
                'Atölyesini ziyaret eden biri, resimlerden birine uzun uzun baktıktan sonra Picasso’ya “Bu neyi temsil ediyor?” diye sormuş.' +
                ' Picasso’nun cevabı şöyle: “İki yüz bin doları.”<br>' +
                'Birçok sanat eleştirmeni, Picasso’nun başarısını sanatsal yeteneğinin yanında finansal dehasına da borçlu olduğu görüşünde.' +
                ' Picasso, medyanın ve halkla ilişkilerin gücünü fark ederek günümüzün pop yıldızlarından çok önce kendisini markalaştırmıştı.' +
                ' Şiddet ve çalkantılarla dolu özel hayatının da kazandığı ünde önemli bir payı olduğu ve eserlerinin değerini artırdığı yorumunu yapan çok sayıda eleştirmen var.' +
                '<div class="job-advertisement-heading">Rekor Kıran Picasso Satışları</div>' +
                'Picasso, sipariş üzerine eserler üretmeyi reddediyor, yalnızca kendi istediğini resmediyordu.' +
                ' Paris’teki büyük koleksiyonerlerin eserlerini satın almasının eserlerinin değerini artıracağının farkındaydı.' +
                ' 1932’de La Coiffure’yi (Kuaför Kız) 56.000 franka satarak rekor kırmıştı. Bu rakam, bugün yaklaşık 172 milyon dolara karşılık geliyor.<br>' +
                '1932 tarihli Le Rêve (Rüya), 1997’de 48,4 milyon dolara satıldığında en pahalı dördüncü tablo idi.' +
                ' Aynı tablo, 2013’te altı katılımcının 19 dakikalık mücadelesinin sonunda, beklenen fiyatın neredeyse iki katına, 155 milyon dolara -bugünkü 172 milyon dolar- satıldı.' +
                ' Fillette à la corbeille fleurie (Çiçek Sepetli Genç Kız), 2018’de 115 milyon dolara -bugünkü 118 milyon dolar- satıldı.<br>' +
                'Bu listeyi istediğimiz kadar uzatabiliriz ama şimdi bir müzayedede satılan en pahalı tablodan bahsedelim.' +
                ' Picasso’nun 15 tablodan oluşan Les Femmes d\'Alger (Cezayirli Kadınlar) serisinin son tablosu Version O (Versiyon O),' +
                ' Mayıs 2015’te Christie’s New York’ta ikinci kez müzayedeye çıktığında rekor kırması bekleniyordu.' +
                ' Satıştan önce 140 milyon dolar olarak değerlenen eser; 179,4 milyon dolara eski Katar Başbakanı Hamad bin Halife Al Thani’ye satılarak müzayedede satılan en pahalı tablo oldu.',
            ROUTER_LINK: {
                TEXT: 'Eserleri İncele',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Sanat Eserlerine Talep',
            IMAGE_SRC: './assets/announcements/operations/corporate.svg#corporate',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Son Yıllarda Sanat Eserlerine Olan Talep Neden Arttı?</div>' +
                'Sanat eserleri için ödenen astronomik rakamlar hakkındaki haberler hepimizin ilgisini çekiyor.' +
                ' Dünyaca ünlü müzayede evlerinin düzenlediği açık artırmalarda birbirleriyle kıyasıya yarışan varlıklı koleksiyonerler' +
                ' her yıl milyarlarca doları sanat eserlerine yatırıyor.<br>' +
                'Sanat, bir yatırım.' +
                ' Dolayısıyla sanat yatırımının doğasında kazanmak kadar kaybetmek de var.' +
                ' Örneğin 2009 yılının şubat ayında bir açık arttırmada 10 milyon pounda alıcı bulan Monet’nin “Dans la Prairie” tablosunun satış haberi' +
                ' o günlerde global basında fazlasıyla gündemi meşgul etmişti.' +
                ' Tablonun böyle bir rakama satılmış olmasının empresyonizm ve modernizm akımlarının ticari potansiyelini kanıtladığına vurgu yapılmıştı.' +
                ' Ancak aynı tablo; 1988 yılında 14.3 milyon pounda, 1999 yılında ise 15.4 milyon dolara satılmıştı.<br>' +
                'Bu hikâye sanat yatırımcılığı konusunda kafanızda negatif bir görüntü yaratmasın.' +
                ' Bu hikâye sadece en üst katmanda olan sanat eserlerinin bile zaman içinde değerinin dalgalanabileceğini kanıtlıyor.' +
                ' Tıpkı altın fiyatları gibi.<br>' +
                '2020 yılında COVID-19 salgınının yarattığı %22’lik düşüşe rağmen sanat pazarının değeri 50.1 milyar dolar oldu.' +
                ' Bu süre içinde 30 milyondan fazla alım-satım işlemi gerçekleşti.' +
                ' Bu rakamlar, birçok endüstrinin büyük kayıplar verdiği bir dönemde çok da fazla sayılmaz.<br>' +
                '1957 - 2007 arasındaki dönem hakkında yapılan araştırmalar bir sanat eserinin her yıl ortalama %3.97 oranında değer kazandığını gösteriyor.' +
                ' Bu oran son yıllarda hızla artıyor.' +
                '<div class="job-advertisement-heading">Sermaye Sahiplerinin Sayısı Artıyor</div>' +
                'Sanat eserlerinin fiyatlarının artmasındaki en büyük neden arz sabit kalırken talebin hızla artması.' +
                ' Talebin artmasının en büyük nedeni ise, global olarak varlıklı insan sayısının artması.' +
                ' Sadece geçtiğimiz yıl içinde serveti 1 milyar dolardan fazla olan kişi sayısında %7’lik bir artış yaşandı.' +
                ' Bu kitlenin hem imajlarını iyileştirmek hem de güvenli bir yatırım yapmak için sanat alanına yönelmesi, pazardaki fiyatların artmasına neden oluyor.' +
                '<div class="job-advertisement-heading">Konvansiyonel Yatırım Araçlarındaki Getiriler İştah Açıcı Değil</div>' +
                'Son birkaç yıldır gelişmiş ülkelerde konvansiyonel yatırım araçlarının getiri vaatlerinin durağan seviyelerde olması,' +
                ' sermaye sahiplerinin alternatif yatırım kanallarına yönelmesine neden oluyor.' +
                ' Sanat yatırımlarının birçok alternatif yatırım kanalına göre daha az riskli olması' +
                ' bu yatırımları ortalamanın üstünde getiri sağlamaya çalışan yatırımcılar için çekici hale getiriyor.' +
                '<div class="job-advertisement-heading">Yeni Pazarlar</div>' +
                'Batılı ülkelerin gelişmiş ekonomileri, sanat piyasası için geleneksel pazarlardır.' +
                ' Ancak son yıllarda Çin, Rusya ve Hindistan gibi ülkelerin ekonomik gücünün artması ile birlikte bu ülkelerden sanat yatırımlarına gelen talepte patlama yaşanıyor.' +
                ' 20. yüzyılda sanat piyasalarında pek de söz sahibi olmayan bu ülkelerin yarattığı talep, sanat eserlerinin değerini artırıyor.' +
                '<div class="job-advertisement-heading">Daha Geniş Hizmet</div>' +
                'Sanat yatırımı alanına daha fazla insanın girmesinin nedeni, bu alanda verilen hizmetlerin çeşitliliğinin artması.' +
                ' Koleksiyon danışmanlığı ya da sanat yatırımı uzmanlığı gibi alanlarda hizmet veren kişi ve kurumların artması' +
                ' bir yandan yeni isimlerin sanat yatırımcısı olmasını kolaylaştırıyor diğer bir yandan da ticari potansiyeli yüksek olan sanat eserlerine olan talebi artırıyor.' +
                '<div class="job-advertisement-heading">Dijitalleşme</div>' +
                'Sanat pazarının muhafazakâr yapısı nedeniyle geç de olsa dijitalleşmeye başlamasıyla sanat eserlerinin dağıtımı için alternatif kanallar oluşmuş durumda.' +
                ' Sanal gerçeklik kullanılan müzayedelerden dijital sanat eserlerine kadar farklı uygulamalar barındıran dijitalleşme,' +
                ' alıcının sanat eserine daha kolay ulaşmasını sağlayarak eserlerin gerçek değerlerine ulaşmasını kolaylaştırıyor.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Son Yıllarda Sanat Eserlerine Olan Talep Neden Arttı?</div>' +
                'Sanat eserleri için ödenen astronomik rakamlar hakkındaki haberler hepimizin ilgisini çekiyor.' +
                ' Dünyaca ünlü müzayede evlerinin düzenlediği açık artırmalarda birbirleriyle kıyasıya yarışan varlıklı koleksiyonerler' +
                ' her yıl milyarlarca doları sanat eserlerine yatırıyor.<br>' +
                'Sanat, bir yatırım.' +
                ' Dolayısıyla sanat yatırımının doğasında kazanmak kadar kaybetmek de var.' +
                ' Örneğin 2009 yılının şubat ayında bir açık arttırmada 10 milyon pounda alıcı bulan Monet’nin “Dans la Prairie” tablosunun satış haberi' +
                ' o günlerde global basında fazlasıyla gündemi meşgul etmişti.' +
                ' Tablonun böyle bir rakama satılmış olmasının empresyonizm ve modernizm akımlarının ticari potansiyelini kanıtladığına vurgu yapılmıştı.' +
                ' Ancak aynı tablo; 1988 yılında 14.3 milyon pounda, 1999 yılında ise 15.4 milyon dolara satılmıştı.<br>' +
                'Bu hikâye sanat yatırımcılığı konusunda kafanızda negatif bir görüntü yaratmasın.' +
                ' Bu hikâye sadece en üst katmanda olan sanat eserlerinin bile zaman içinde değerinin dalgalanabileceğini kanıtlıyor.' +
                ' Tıpkı altın fiyatları gibi.<br>' +
                '2020 yılında COVID-19 salgınının yarattığı %22’lik düşüşe rağmen sanat pazarının değeri 50.1 milyar dolar oldu.' +
                ' Bu süre içinde 30 milyondan fazla alım-satım işlemi gerçekleşti.' +
                ' Bu rakamlar, birçok endüstrinin büyük kayıplar verdiği bir dönemde çok da fazla sayılmaz.<br>' +
                '1957 - 2007 arasındaki dönem hakkında yapılan araştırmalar bir sanat eserinin her yıl ortalama %3.97 oranında değer kazandığını gösteriyor.' +
                ' Bu oran son yıllarda hızla artıyor.' +
                '<div class="job-advertisement-heading">Sermaye Sahiplerinin Sayısı Artıyor</div>' +
                'Sanat eserlerinin fiyatlarının artmasındaki en büyük neden arz sabit kalırken talebin hızla artması.' +
                ' Talebin artmasının en büyük nedeni ise, global olarak varlıklı insan sayısının artması.' +
                ' Sadece geçtiğimiz yıl içinde serveti 1 milyar dolardan fazla olan kişi sayısında %7’lik bir artış yaşandı.' +
                ' Bu kitlenin hem imajlarını iyileştirmek hem de güvenli bir yatırım yapmak için sanat alanına yönelmesi, pazardaki fiyatların artmasına neden oluyor.' +
                '<div class="job-advertisement-heading">Konvansiyonel Yatırım Araçlarındaki Getiriler İştah Açıcı Değil</div>' +
                'Son birkaç yıldır gelişmiş ülkelerde konvansiyonel yatırım araçlarının getiri vaatlerinin durağan seviyelerde olması,' +
                ' sermaye sahiplerinin alternatif yatırım kanallarına yönelmesine neden oluyor.' +
                ' Sanat yatırımlarının birçok alternatif yatırım kanalına göre daha az riskli olması' +
                ' bu yatırımları ortalamanın üstünde getiri sağlamaya çalışan yatırımcılar için çekici hale getiriyor.' +
                '<div class="job-advertisement-heading">Yeni Pazarlar</div>' +
                'Batılı ülkelerin gelişmiş ekonomileri, sanat piyasası için geleneksel pazarlardır.' +
                ' Ancak son yıllarda Çin, Rusya ve Hindistan gibi ülkelerin ekonomik gücünün artması ile birlikte bu ülkelerden sanat yatırımlarına gelen talepte patlama yaşanıyor.' +
                ' 20. yüzyılda sanat piyasalarında pek de söz sahibi olmayan bu ülkelerin yarattığı talep, sanat eserlerinin değerini artırıyor.' +
                '<div class="job-advertisement-heading">Daha Geniş Hizmet</div>' +
                'Sanat yatırımı alanına daha fazla insanın girmesinin nedeni, bu alanda verilen hizmetlerin çeşitliliğinin artması.' +
                ' Koleksiyon danışmanlığı ya da sanat yatırımı uzmanlığı gibi alanlarda hizmet veren kişi ve kurumların artması' +
                ' bir yandan yeni isimlerin sanat yatırımcısı olmasını kolaylaştırıyor diğer bir yandan da ticari potansiyeli yüksek olan sanat eserlerine olan talebi artırıyor.' +
                '<div class="job-advertisement-heading">Dijitalleşme</div>' +
                'Sanat pazarının muhafazakâr yapısı nedeniyle geç de olsa dijitalleşmeye başlamasıyla sanat eserlerinin dağıtımı için alternatif kanallar oluşmuş durumda.' +
                ' Sanal gerçeklik kullanılan müzayedelerden dijital sanat eserlerine kadar farklı uygulamalar barındıran dijitalleşme,' +
                ' alıcının sanat eserine daha kolay ulaşmasını sağlayarak eserlerin gerçek değerlerine ulaşmasını kolaylaştırıyor.',
            ROUTER_LINK: {
                TEXT: 'Eserleri İncele',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'NFT, Sanat Dünyasının Geleceği',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">NFT, Sanat Dünyasının Geleceği Olabilir mi?</div>' +
                'Tarih boyunca sanatla para kavramı arasında vazgeçilemez bir ilişki olmuştur.' +
                ' Sanatı ve sanatçıları sahiplenen varlıklı kesim kimi zaman sanata olan ilgilerinden kimi zaman imajlarını güçlendirmek için' +
                ' kimi zaman da kâr elde etmek için sanat eserlerinin üretimini finanse etmiş ve bu eserlerin ticaretini yapmıştır.<br>' +
                'Sanatı himayesi altına alan bu “patron”lar öncülüğünde oluşan sanat piyasası,' +
                ' bugün hepimizin bildiği birçok sanat eserinin ortaya çıkmasını kolaylaştırsalar da öte yandan birçok sanatçının geniş kitlelere ulaşamamasına neden olmuştur.' +
                ' Bu yüzden sanat piyasasının engellemeleri nedeniyle tanıyamadığımız, eserlerini göremediğimiz kaç sanatçının olduğunu bilmemiz imkânsız.<br>' +
                '<div class="job-advertisement-heading">İşler Değişiyor</div>' +
                'NFT kavramı, sadece küçük bir zümrenin elinde olan sanat eseri yatırımcılığını daha demokratik hale getirerek geniş kitlelere yayılmasını sağlıyor.' +
                ' Bu sayede sanatın ve sanatçının finansal kaderi de birkaç insanın elinden çıkıp geniş kitlelerin inisiyatifine geçiyor.<br>' +
                'Bu gelişme özellikle müzik ya da sinema gibi popüler sanat dalları dışında çalışan sanatçılar için oldukça önemli.' +
                ' Bu sayede sanatçılar, aracı kurumlar olmadan daha geniş kitlelere ulaşma fırsatını elde ediyorlar.<br>' +
                '<div class="job-advertisement-heading">NFT, Sadece Finansal Sorunlara Çözüm Getirmiyor</div>' +
                'NFT kavramı bugüne kadar geleneksel sanat dünyası tarafından büyük ölçüde dışlanmış dijital ortamlarda üretim yapan sanatçılar tarafından sahiplenildi.' +
                ' NFT sayesinde bu sanatçılar bugüne kadar çoktan hak ettikleri yeri bulmaya başladılar.<br>' +
                'Dijital ortamlarda eserler üreten sanatçıların en büyük sorunlarından biri, eserlerinin kolaylıkla kopyalanabilmesi.' +
                ' Bir eserin iki kopyasından hangisinin orijinal olduğunu söylemek oldukça zor bir iş.<br>' +
                'NFT’ler bu soruna çözüm getiriyor.' +
                ' NFT halinde bir eser, kendi orijinalliğini kendisi kanıtlayabildiğinden kopyaların şüphe yaratmasına imkan tanımıyor.<br>' +
                '<div class="job-advertisement-heading">Peki NFT Gelecek mi?</div>' +
                'Sanat dünyasının yenilikçi kanadında yer alan hemen hemen herkes NFT kavramına büyük bir heyecanla yaklaşsa da' +
                ' bu teknolojinin ilerleyen zamanlarda sanat dünyasına nasıl bir etkisi olacağını öngörmek pek de mümkün değil.' +
                ' Zira tarih, teknolojik gelişmelerin beklenmedik sosyal etkiler yarattığı örneklerle dolu.<br>' +
                'NFT’lerin iyimser bir değişim yaratması için öncelikle “sanat eseri” algısının değişmesi gerektiği bir gerçek.' +
                ' Bir JPG dosyasının sanat eseri olamayacağını düşünen geniş muhafazakâr kitlenin bu fikirlerinden vazgeçmesi kolay olmayacak.<br>' +
                'Diğer bir sorun ise, geleneklerine sıkı sıkıya bağlı sanat piyasasının iş yapma şeklini gözden geçirmeyi reddetmesi.' +
                ' Bu konuda da alışkanlıkların değişmesinin zor olacağını söylemek mümkün.<br>' +
                'Geniş kitlelerin sanat eserlerini yatırım aracı olarak algılama refleksinin henüz olmaması ise bir diğer sorun.' +
                ' Bugüne kadar emlak, döviz piyasaları ya da borsa gibi daha geleneksel yatırım araçlarını kullanma alışkanlığı olan bu kitle' +
                ' son zamanlarda kripto para piyasalarına yatırım yapmaya başlamış olsa da daha gidilecek uzun bir yol var.' +
                ' NFT’lerin taşıdığı risk, kitleleri bu piyasaya dahil olmaktan alıkoyuyor.<br>' +
                '<div class="job-advertisement-heading">Sanat Eserinin Değerinin Tokenize Edilmesi</div>' +
                'Bir tokenin değerinin stabil bir gerçek varlığa sabitlenmesi, söz konusu tokenin riskini azaltır.' +
                ' Kripto para piyasalarındaki sabit tokenler bu duruma iyi bir örnek.<br>' +
                'Artiox’ta, ticari potansiyeli analiz edilmiş fiziksel sanat eserinin ticari değerini tokenize ederek risk faktörünü azaltıyoruz.' +
                ' Bu sayede kriptopara piyasalarının yüksek riskleri nedeniyle bu alanda yatırım yapmaya çekinen yatırımcıları güvende hissettirecek bir ortam yaratıyoruz.' +
                ' Aynı zamanda varlıklı azınlığın kontrolünde olan geleneksel sanat piyasasını daha demokratik bir alana dönüştürmüş oluyoruz.<br>' +
                'NFT kavramının gelecekte sanat piyasalarına nasıl yön vereceğini bugünden öngörmek çok zor. Ancak bir şeylerin değişeceği kesin.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">NFT, Sanat Dünyasının Geleceği Olabilir mi?</div>' +
                'Tarih boyunca sanatla para kavramı arasında vazgeçilemez bir ilişki olmuştur.' +
                ' Sanatı ve sanatçıları sahiplenen varlıklı kesim kimi zaman sanata olan ilgilerinden kimi zaman imajlarını güçlendirmek için' +
                ' kimi zaman da kâr elde etmek için sanat eserlerinin üretimini finanse etmiş ve bu eserlerin ticaretini yapmıştır.<br>' +
                'Sanatı himayesi altına alan bu “patron”lar öncülüğünde oluşan sanat piyasası,' +
                ' bugün hepimizin bildiği birçok sanat eserinin ortaya çıkmasını kolaylaştırsalar da öte yandan birçok sanatçının geniş kitlelere ulaşamamasına neden olmuştur.' +
                ' Bu yüzden sanat piyasasının engellemeleri nedeniyle tanıyamadığımız, eserlerini göremediğimiz kaç sanatçının olduğunu bilmemiz imkânsız.<br>' +
                '<div class="job-advertisement-heading">İşler Değişiyor</div>' +
                'NFT kavramı, sadece küçük bir zümrenin elinde olan sanat eseri yatırımcılığını daha demokratik hale getirerek geniş kitlelere yayılmasını sağlıyor.' +
                ' Bu sayede sanatın ve sanatçının finansal kaderi de birkaç insanın elinden çıkıp geniş kitlelerin inisiyatifine geçiyor.<br>' +
                'Bu gelişme özellikle müzik ya da sinema gibi popüler sanat dalları dışında çalışan sanatçılar için oldukça önemli.' +
                ' Bu sayede sanatçılar, aracı kurumlar olmadan daha geniş kitlelere ulaşma fırsatını elde ediyorlar.<br>' +
                '<div class="job-advertisement-heading">NFT, Sadece Finansal Sorunlara Çözüm Getirmiyor</div>' +
                'NFT kavramı bugüne kadar geleneksel sanat dünyası tarafından büyük ölçüde dışlanmış dijital ortamlarda üretim yapan sanatçılar tarafından sahiplenildi.' +
                ' NFT sayesinde bu sanatçılar bugüne kadar çoktan hak ettikleri yeri bulmaya başladılar.<br>' +
                'Dijital ortamlarda eserler üreten sanatçıların en büyük sorunlarından biri, eserlerinin kolaylıkla kopyalanabilmesi.' +
                ' Bir eserin iki kopyasından hangisinin orijinal olduğunu söylemek oldukça zor bir iş.<br>' +
                'NFT’ler bu soruna çözüm getiriyor.' +
                ' NFT halinde bir eser, kendi orijinalliğini kendisi kanıtlayabildiğinden kopyaların şüphe yaratmasına imkan tanımıyor.<br>' +
                '<div class="job-advertisement-heading">Peki NFT Gelecek mi?</div>' +
                'Sanat dünyasının yenilikçi kanadında yer alan hemen hemen herkes NFT kavramına büyük bir heyecanla yaklaşsa da' +
                ' bu teknolojinin ilerleyen zamanlarda sanat dünyasına nasıl bir etkisi olacağını öngörmek pek de mümkün değil.' +
                ' Zira tarih, teknolojik gelişmelerin beklenmedik sosyal etkiler yarattığı örneklerle dolu.<br>' +
                'NFT’lerin iyimser bir değişim yaratması için öncelikle “sanat eseri” algısının değişmesi gerektiği bir gerçek.' +
                ' Bir JPG dosyasının sanat eseri olamayacağını düşünen geniş muhafazakâr kitlenin bu fikirlerinden vazgeçmesi kolay olmayacak.<br>' +
                'Diğer bir sorun ise, geleneklerine sıkı sıkıya bağlı sanat piyasasının iş yapma şeklini gözden geçirmeyi reddetmesi.' +
                ' Bu konuda da alışkanlıkların değişmesinin zor olacağını söylemek mümkün.<br>' +
                'Geniş kitlelerin sanat eserlerini yatırım aracı olarak algılama refleksinin henüz olmaması ise bir diğer sorun.' +
                ' Bugüne kadar emlak, döviz piyasaları ya da borsa gibi daha geleneksel yatırım araçlarını kullanma alışkanlığı olan bu kitle' +
                ' son zamanlarda kripto para piyasalarına yatırım yapmaya başlamış olsa da daha gidilecek uzun bir yol var.' +
                ' NFT’lerin taşıdığı risk, kitleleri bu piyasaya dahil olmaktan alıkoyuyor.<br>' +
                '<div class="job-advertisement-heading">Sanat Eserinin Değerinin Tokenize Edilmesi</div>' +
                'Bir tokenin değerinin stabil bir gerçek varlığa sabitlenmesi, söz konusu tokenin riskini azaltır.' +
                ' Kripto para piyasalarındaki sabit tokenler bu duruma iyi bir örnek.<br>' +
                'Artiox’ta, ticari potansiyeli analiz edilmiş fiziksel sanat eserinin ticari değerini tokenize ederek risk faktörünü azaltıyoruz.' +
                ' Bu sayede kriptopara piyasalarının yüksek riskleri nedeniyle bu alanda yatırım yapmaya çekinen yatırımcıları güvende hissettirecek bir ortam yaratıyoruz.' +
                ' Aynı zamanda varlıklı azınlığın kontrolünde olan geleneksel sanat piyasasını daha demokratik bir alana dönüştürmüş oluyoruz.<br>' +
                'NFT kavramının gelecekte sanat piyasalarına nasıl yön vereceğini bugünden öngörmek çok zor. Ancak bir şeylerin değişeceği kesin.',
            ROUTER_LINK: {
                TEXT: 'Eserleri İncele',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Sanat Pazarı, Pandemi ve Sonrası',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Sanat Pazarı, Pandemi ve Sonrası</div>' +
                'Global sanat pazarı, diğer tüm endüstriler gibi COVID-19 salgınından etkilendi.' +
                ' 2020 yılında, 2019 yılına göre pazardaki toplam işlem hacminde yaklaşık %30’luk bir değer kaybı yaşandı.' +
                ' Bunun en büyük nedeni sanat eserlerine yatırım yapanların önceliklerinin değişmesi.<br>' +
                '2020 yılında sanat piyasasının büyüklüğü yaklaşık 50 milyar dolara ulaştı.' +
                ' 2019 yılına göre %22 oranında küçülen bu pazar; başka bir ekonomik kriz yaşanan 2009 yılından bu yana en düşük seviyesine geldi.<br>' +
                'Bunun ardında sanat piyasasının %82’sini elinde tutan ABD, Birleşik Krallık ve Çin’deki düşüş vardı.' +
                ' Sanat pazarı ABD’de %24, Birleşik Krallık’ta %22 ve Çin’de %12 küçülme yaşadı.' +
                ' Kapanmalar ve kısıtlamalar bu düşüşün ortaya çıkmasında etkili oldu.' +
                ' 2020 yılında planlanmış 365 küresel sanat fuarının %61’i iptal edildi.<br>' +
                'Tüm bu düşüşlere karşı online satışlar rekor düzeye ulaştı.' +
                ' 2020 yılında 12.4 milyar dolarlık bir hacim online satışlar aracılığıyla elde edildi.' +
                ' Bu rakam 2019 yılında elde edilen rakamın neredeyse iki katı.' +
                ' 2020 yılında güzel sanatlar alanındaki satışların %22’si online satış kanalları üzerinden geldi.' +
                ' Ayrıca bu dönem içinde sanat odaklı teknoloji startup’ları sektör içindeki yatırımların %60’ını toplamayı başardılar.' +
                ' Pandemiden önceki 20 yıllık dönem içinde toplamda 640 milyon dolar toplayabilen sanat startupları, sadece 2020 yılında 380 milyon dolar yatırım almayı başardılar.<br>' +
                '<div class="job-advertisement-heading">Yeniden Açılma</div>' +
                'Mart ayından bu yana günlük hayatın göreceli de olsa normale dönmeye başlaması ve sanat etkinliklerinin kapılarını açması sanat piyasalarını hareketlendirdi.' +
                ' Bu hareketlenmenin sanat pazarı üstünde doğrudan ve dolaylı etkileri var.<br>' +
                'Sanat pazarı büyük ölçüde varlıklı insanların elinde olan bir pazar.' +
                ' Kapanma döneminde ekonominin yavaşlaması varlıklı insanların daha korumacı bir servet yönetim stratejisine yönelmelerine neden oldu.' +
                ' Bu yüzden sanat yatırımları, bu dönemde bu insanlar için daha geri planda kaldı.' +
                ' İlerleyen dönemde ekonominin hareketlenmesiyle birlikte bu insanların pazara geri dönmesi beklenebilir.<br>' +
                '<div class="job-advertisement-heading">Faizler</div>' +
                'Salgın boyunca ekonomik hareketliliği desteklemek adına birçok gelişmiş ekonomide faizler düşük seviyelerde tutuldu ve destek paketleri açıklandı.' +
                ' İlk bakışta düşük kredi faizlerinin sanat eseri satın almayı kolaylaştıracağı ve bu sayede sanat pazarında bir hareketlilik yaşanacağı söylenebilir.' +
                ' Ancak pandemi boyunca bu durumun tam tersi yaşandı. Satıcılar ellerindeki eserleri satmak yerine düşük faizli kredilerden faydalanarak finansman ihtiyaçlarını giderdiler.' +
                ' Alıcılar ise piyasadaki fiyatların düşmesi beklentisi içinde olduklarından düşük arzın yarattığı yüksek fiyatlardan eserleri satın almak istemediler.' +
                ' Faiz politikalarının bir süre daha olduğu gibi devam edeceğini düşünürsek bu trendin pazarın büyümesine negatif yönde bir etkisi olacağını söyleyebiliriz.<br>' +
                '<div class="job-advertisement-heading">Teknoloji</div>' +
                'Online satışların pandemi boyunca rekor düzeye ulaşması ve sanat odaklı teknoloji startup’larının geçmişte görülmemiş ölçüde büyük yatırımlar almaları' +
                ' önümüzdeki dönemde sanat ekonomisinde teknolojinin büyük bir pay alacağını gösteriyor.' +
                ' Özellikle pazara yeni giren genç sanat yatırımcılarının online kanallara sıcak bakması ve bu kanalların sanat piyasalarını daha ulaşılabilir ve sistematik hale getirmesi,' +
                ' sanat ekonomisi içinde teknolojinin pandemi döneminde ara bir çözüm değil, kalıcı değer olacağının en önemli işaretlerinden.<br>' +
                'Diğer bir yandan NFT teknolojisinin yarattığı hacim de göz ardı edilecek bir büyüklükte değil.' +
                ' Daha önce var olmayan bir pazar yaratan bu teknoloji sadece sanat ekonomisini değil, aynı zamanda sanata yatırım yapan insan sayısını da büyütüyor. ' +
                '<div class="job-advertisement-heading">Sonuç Olarak</div>' +
                'Sanat piyasasının önümüzdeki dönemdeki beklentilerini birkaç maddeyle özetleyelim:' +
                '<ul>' +
                '  <li>Normal hayata dönüş sayesinde ekonominin canlanması, servet sahiplerinin sanat yatırımlarına geri dönmesini sağlayacaktır.</li>' +
                '  <li>Faizlerin düşük olması ve ekonomik destek paketlerinin devam etmesi satıcıların finansman ihtiyaçlarını karşıladığı için piyasadaki arz sorunu devam edebilir.</li>' +
                '  <li>Online satışlarda yaşanan patlama ve sanat odaklı teknoloji startup’larına yapılan yatırımların artması,' +
                ' önümüzdeki yıllarda sanat ekonomisinde teknolojinin çok daha fazla yer alacağını göstermektedir.</li>' +
                '</ul>',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Sanat Pazarı, Pandemi ve Sonrası</div>' +
                'Global sanat pazarı, diğer tüm endüstriler gibi COVID-19 salgınından etkilendi.' +
                ' 2020 yılında, 2019 yılına göre pazardaki toplam işlem hacminde yaklaşık %30’luk bir değer kaybı yaşandı.' +
                ' Bunun en büyük nedeni sanat eserlerine yatırım yapanların önceliklerinin değişmesi.<br>' +
                '2020 yılında sanat piyasasının büyüklüğü yaklaşık 50 milyar dolara ulaştı.' +
                ' 2019 yılına göre %22 oranında küçülen bu pazar; başka bir ekonomik kriz yaşanan 2009 yılından bu yana en düşük seviyesine geldi.<br>' +
                'Bunun ardında sanat piyasasının %82’sini elinde tutan ABD, Birleşik Krallık ve Çin’deki düşüş vardı.' +
                ' Sanat pazarı ABD’de %24, Birleşik Krallık’ta %22 ve Çin’de %12 küçülme yaşadı.' +
                ' Kapanmalar ve kısıtlamalar bu düşüşün ortaya çıkmasında etkili oldu.' +
                ' 2020 yılında planlanmış 365 küresel sanat fuarının %61’i iptal edildi.<br>' +
                'Tüm bu düşüşlere karşı online satışlar rekor düzeye ulaştı.' +
                ' 2020 yılında 12.4 milyar dolarlık bir hacim online satışlar aracılığıyla elde edildi.' +
                ' Bu rakam 2019 yılında elde edilen rakamın neredeyse iki katı.' +
                ' 2020 yılında güzel sanatlar alanındaki satışların %22’si online satış kanalları üzerinden geldi.' +
                ' Ayrıca bu dönem içinde sanat odaklı teknoloji startup’ları sektör içindeki yatırımların %60’ını toplamayı başardılar.' +
                ' Pandemiden önceki 20 yıllık dönem içinde toplamda 640 milyon dolar toplayabilen sanat startupları, sadece 2020 yılında 380 milyon dolar yatırım almayı başardılar.<br>' +
                '<div class="job-advertisement-heading">Yeniden Açılma</div>' +
                'Mart ayından bu yana günlük hayatın göreceli de olsa normale dönmeye başlaması ve sanat etkinliklerinin kapılarını açması sanat piyasalarını hareketlendirdi.' +
                ' Bu hareketlenmenin sanat pazarı üstünde doğrudan ve dolaylı etkileri var.<br>' +
                'Sanat pazarı büyük ölçüde varlıklı insanların elinde olan bir pazar.' +
                ' Kapanma döneminde ekonominin yavaşlaması varlıklı insanların daha korumacı bir servet yönetim stratejisine yönelmelerine neden oldu.' +
                ' Bu yüzden sanat yatırımları, bu dönemde bu insanlar için daha geri planda kaldı.' +
                ' İlerleyen dönemde ekonominin hareketlenmesiyle birlikte bu insanların pazara geri dönmesi beklenebilir.<br>' +
                '<div class="job-advertisement-heading">Faizler</div>' +
                'Salgın boyunca ekonomik hareketliliği desteklemek adına birçok gelişmiş ekonomide faizler düşük seviyelerde tutuldu ve destek paketleri açıklandı.' +
                ' İlk bakışta düşük kredi faizlerinin sanat eseri satın almayı kolaylaştıracağı ve bu sayede sanat pazarında bir hareketlilik yaşanacağı söylenebilir.' +
                ' Ancak pandemi boyunca bu durumun tam tersi yaşandı. Satıcılar ellerindeki eserleri satmak yerine düşük faizli kredilerden faydalanarak finansman ihtiyaçlarını giderdiler.' +
                ' Alıcılar ise piyasadaki fiyatların düşmesi beklentisi içinde olduklarından düşük arzın yarattığı yüksek fiyatlardan eserleri satın almak istemediler.' +
                ' Faiz politikalarının bir süre daha olduğu gibi devam edeceğini düşünürsek bu trendin pazarın büyümesine negatif yönde bir etkisi olacağını söyleyebiliriz.<br>' +
                '<div class="job-advertisement-heading">Teknoloji</div>' +
                'Online satışların pandemi boyunca rekor düzeye ulaşması ve sanat odaklı teknoloji startup’larının geçmişte görülmemiş ölçüde büyük yatırımlar almaları' +
                ' önümüzdeki dönemde sanat ekonomisinde teknolojinin büyük bir pay alacağını gösteriyor.' +
                ' Özellikle pazara yeni giren genç sanat yatırımcılarının online kanallara sıcak bakması ve bu kanalların sanat piyasalarını daha ulaşılabilir ve sistematik hale getirmesi,' +
                ' sanat ekonomisi içinde teknolojinin pandemi döneminde ara bir çözüm değil, kalıcı değer olacağının en önemli işaretlerinden.<br>' +
                'Diğer bir yandan NFT teknolojisinin yarattığı hacim de göz ardı edilecek bir büyüklükte değil.' +
                ' Daha önce var olmayan bir pazar yaratan bu teknoloji sadece sanat ekonomisini değil, aynı zamanda sanata yatırım yapan insan sayısını da büyütüyor. ' +
                '<div class="job-advertisement-heading">Sonuç Olarak</div>' +
                'Sanat piyasasının önümüzdeki dönemdeki beklentilerini birkaç maddeyle özetleyelim:' +
                '<ul>' +
                '  <li>Normal hayata dönüş sayesinde ekonominin canlanması, servet sahiplerinin sanat yatırımlarına geri dönmesini sağlayacaktır.</li>' +
                '  <li>Faizlerin düşük olması ve ekonomik destek paketlerinin devam etmesi satıcıların finansman ihtiyaçlarını karşıladığı için piyasadaki arz sorunu devam edebilir.</li>' +
                '  <li>Online satışlarda yaşanan patlama ve sanat odaklı teknoloji startup’larına yapılan yatırımların artması,' +
                ' önümüzdeki yıllarda sanat ekonomisinde teknolojinin çok daha fazla yer alacağını göstermektedir.</li>' +
                '</ul>',
            ROUTER_LINK: {
                TEXT: 'Eserleri İncele',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Artiox\'taki Tokenler ile NFT\'lerin Farkı',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">NFT Aslında Nedir? / Artiox\'ta Listelenen Tokenler ile Farkı Nedir?</div>' +
                'Son zamanlarda kripto paralarla ya da sanat piyasalarıyla ilgilenmeyen insanlar bile NFT’lerden bahsediyor.' +
                ' Bunun en büyük nedeni de NFT’ler hakkında çıkan sansasyonel haberlerin sosyal medyada hızla yayılması.' +
                ' Sanat eseri olup olmadığı bile tartışılır olan dijital işlerin,' +
                ' birçok klasik sanat eserinden çok daha yüksek miktarda paralara alıcı bulması kamuoyunun NFT kavramını bir delilik olarak algılamasına neden oluyor.' +
                '<div class="job-advertisement-heading">NFT Kavramını Tanımlayalım</div>' +
                'Öncelikle NFT kavramını tanımlayarak yola çıkalım.' +
                ' NFT, “Non-fungible token” kelimelerinin kısaltılmış hali. Açıklayıcı oldu mu? Muhtemelen hayır…<br>' +
                'NFT, bir dijital varlığın tanımlama bilgileri ile birlikte akıllı sözleşmeler sayesinde bir blok zinciri üzerine kaydedilmesi yoluyla ortaya çıkan bir değer.' +
                ' Bu benzersiz değer hiçbir şekilde değiştirilemiyor ya da bölünemiyor.' +
                ' NFT’lerin ticari bir meta olmasını da bu benzersizlik ve değiştirilemezlik sağlıyor.' +
                '<div class="job-advertisement-heading">Sanat Alanında NFT’ler Nasıl Kullanılıyor?</div>' +
                'NFT kavramı benzersizliğin değerli olduğu dijital alanlarda kullanılabiliyor.' +
                ' Oyun ve sanat alanları öne çıkıyor.<br>' +
                'Son zamanlarda geleneksel sanat piyasası tarafından neredeyse yok sayılan dijital sanatçılar,' +
                ' çıkardıkları NFT’ler sayesinde ürettikleri sanat eserlerinden gelir elde etmeye başladılar.' +
                ' Fiziksel sanat eserlerinin de NFT’lere dönüştürüldüğü durumlar söz konusu.<br>' +
                'NFT kavramını dijital bir sanat eserinin gerçeklik belgesi olarak düşünmek mümkün.' +
                ' Bu değiştirilemez belge sayesinde normal koşullarda kolaylıkla kopyalanabilen dijital sanat eserlerinin benzersizliği sağlanıyor.<br>' +
                '<div class="job-advertisement-heading">Artiox’un Tokenlerinin NFT’den Farkı Ne?</div>' +
                'Artiox’ta listelediğimiz tokenler, NFT’lere benzer şekilde sanat eserlerinin birer kripto para birimi gibi alım satımını kolaylaştırmak amacıyla tasarlandı.' +
                ' Ama bu tokenlerin birer NFT olmadığını söylememiz gerekiyor.<br>' +
                'Öncelikle bir sanat eserinin NFT olması için eserin tamamının ya da en azından ayrılmaz bir bölümünün dijital olması gerekiyor.' +
                ' Fiziksel sanat eserlerinin NFT’ye dönüştürülmesi için çeşitli yöntemler olsa da' +
                ' bu yöntemlerin hepsinde benzersizliğin yaratılması için eserin fiziksel bütünlüğünden ya da ticari potansiyelinden ödün vermek gerekiyor.' +
                ' Fiziksel eseri parçalara bölmek ya da eseri tamamen yok etmek bu yöntemlerden.<br>' +
                'Artiox olarak fiziksel sanat eserlerini NFT’ye dönüştürmek yerine, eserlerin yarattığı ekonomik değeri tokenize ediyoruz.' +
                ' Bu sayede NFT olmak için yaratılmamış eserlerin fiziksel bütünlüğünü bozmadan, geniş kitlelerin katılabileceği birer yatırım aracı olmalarının yolu açılıyor.' +
                ' Fiziksel eseri yok etmek ya da parçalara ayırmak gibi geri dönülemez yıkıcı bir dönüşüm yerine sadece eserin yönetimimiz altında olduğu süre' +
                ' içinde yaratılan ticari faydayı küçük parçalara ayırarak bu ticari potansiyelden geniş kitlelerin faydalanmasını sağlıyoruz.<br>' +
                'Sistemimize dâhil ettiğimiz eserleri alanlarında uzman eksperlerin yaptığı değerlendirmeler sonucunda tokenize etmemiz de' +
                ' Artiox yatırımcılarının daha düşük riske sahip bir ortamda yatırım yapmalarını kolaylaştırıyor.' +
                ' Bir NFT eserin değerinde zaman içinde büyük dalgalanmalar olurken,' +
                ' Artiox’ta listelediğimiz tokenler, fiziksel eserlerin değeri sayesinde daha az riskli bir yatırım seçeneği haline geliyor.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">NFT Aslında Nedir? / Artiox\'ta Listelenen Tokenler ile Farkı Nedir?</div>' +
                'Son zamanlarda kripto paralarla ya da sanat piyasalarıyla ilgilenmeyen insanlar bile NFT’lerden bahsediyor.' +
                ' Bunun en büyük nedeni de NFT’ler hakkında çıkan sansasyonel haberlerin sosyal medyada hızla yayılması.' +
                ' Sanat eseri olup olmadığı bile tartışılır olan dijital işlerin,' +
                ' birçok klasik sanat eserinden çok daha yüksek miktarda paralara alıcı bulması kamuoyunun NFT kavramını bir delilik olarak algılamasına neden oluyor.' +
                '<div class="job-advertisement-heading">NFT Kavramını Tanımlayalım</div>' +
                'Öncelikle NFT kavramını tanımlayarak yola çıkalım.' +
                ' NFT, “Non-fungible token” kelimelerinin kısaltılmış hali. Açıklayıcı oldu mu? Muhtemelen hayır…<br>' +
                'NFT, bir dijital varlığın tanımlama bilgileri ile birlikte akıllı sözleşmeler sayesinde bir blok zinciri üzerine kaydedilmesi yoluyla ortaya çıkan bir değer.' +
                ' Bu benzersiz değer hiçbir şekilde değiştirilemiyor ya da bölünemiyor.' +
                ' NFT’lerin ticari bir meta olmasını da bu benzersizlik ve değiştirilemezlik sağlıyor.' +
                '<div class="job-advertisement-heading">Sanat Alanında NFT’ler Nasıl Kullanılıyor?</div>' +
                'NFT kavramı benzersizliğin değerli olduğu dijital alanlarda kullanılabiliyor.' +
                ' Oyun ve sanat alanları öne çıkıyor.<br>' +
                'Son zamanlarda geleneksel sanat piyasası tarafından neredeyse yok sayılan dijital sanatçılar,' +
                ' çıkardıkları NFT’ler sayesinde ürettikleri sanat eserlerinden gelir elde etmeye başladılar.' +
                ' Fiziksel sanat eserlerinin de NFT’lere dönüştürüldüğü durumlar söz konusu.<br>' +
                'NFT kavramını dijital bir sanat eserinin gerçeklik belgesi olarak düşünmek mümkün.' +
                ' Bu değiştirilemez belge sayesinde normal koşullarda kolaylıkla kopyalanabilen dijital sanat eserlerinin benzersizliği sağlanıyor.<br>' +
                '<div class="job-advertisement-heading">Artiox’un Tokenlerinin NFT’den Farkı Ne?</div>' +
                'Artiox’ta listelediğimiz tokenler, NFT’lere benzer şekilde sanat eserlerinin birer kripto para birimi gibi alım satımını kolaylaştırmak amacıyla tasarlandı.' +
                ' Ama bu tokenlerin birer NFT olmadığını söylememiz gerekiyor.<br>' +
                'Öncelikle bir sanat eserinin NFT olması için eserin tamamının ya da en azından ayrılmaz bir bölümünün dijital olması gerekiyor.' +
                ' Fiziksel sanat eserlerinin NFT’ye dönüştürülmesi için çeşitli yöntemler olsa da' +
                ' bu yöntemlerin hepsinde benzersizliğin yaratılması için eserin fiziksel bütünlüğünden ya da ticari potansiyelinden ödün vermek gerekiyor.' +
                ' Fiziksel eseri parçalara bölmek ya da eseri tamamen yok etmek bu yöntemlerden.<br>' +
                'Artiox olarak fiziksel sanat eserlerini NFT’ye dönüştürmek yerine, eserlerin yarattığı ekonomik değeri tokenize ediyoruz.' +
                ' Bu sayede NFT olmak için yaratılmamış eserlerin fiziksel bütünlüğünü bozmadan, geniş kitlelerin katılabileceği birer yatırım aracı olmalarının yolu açılıyor.' +
                ' Fiziksel eseri yok etmek ya da parçalara ayırmak gibi geri dönülemez yıkıcı bir dönüşüm yerine sadece eserin yönetimimiz altında olduğu süre' +
                ' içinde yaratılan ticari faydayı küçük parçalara ayırarak bu ticari potansiyelden geniş kitlelerin faydalanmasını sağlıyoruz.<br>' +
                'Sistemimize dâhil ettiğimiz eserleri alanlarında uzman eksperlerin yaptığı değerlendirmeler sonucunda tokenize etmemiz de' +
                ' Artiox yatırımcılarının daha düşük riske sahip bir ortamda yatırım yapmalarını kolaylaştırıyor.' +
                ' Bir NFT eserin değerinde zaman içinde büyük dalgalanmalar olurken,' +
                ' Artiox’ta listelediğimiz tokenler, fiziksel eserlerin değeri sayesinde daha az riskli bir yatırım seçeneği haline geliyor.',
            ROUTER_LINK: {
                TEXT: 'Eserleri İncele',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Acemiler İçin Sanat Yatırımcılığı',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Acemiler İçin Sanat Yatırımcılığı</div>' +
                'Neredeyse hepimiz borsa ya da döviz piyasaları gibi alışılmış yatırım araçlarına aşinayız.' +
                ' Doğal olarak birikimlerimizin de büyük bir bölümünü bu yatırım araçlarında değerlendiriyoruz.' +
                ' Ancak bu yatırım araçlarına alternatifler de mevcut. Sanat yatırımları da bu alternatifler arasında.' +
                '<div class="job-advertisement-heading">Sanat Yatırımcısı Olmak Ortalama Gelire Sahip Biri İçin Hayal mi?</div>' +
                'Sanat yatırımcılığı konusunda çoğumuzun sınırlı bilgi sahibi olmasının en büyük nedeni, sanat yatırımcılığının zengin insanların işi olduğunu düşünmemiz.' +
                ' Geleneksel sanat pazarını düşündüğümüzde bu kanı çok da isabetsiz değil.' +
                ' Müzayedelerde satılan ve yatırım değeri olan sanat eserlerine binlerce dolar harcayarak sahip olmak herkes için mümkün değil.<br>' +
                'Ancak sanat pazarında işler değişiyor.' +
                ' Sanat yatırımcılığı 3.0 kavramını ortaya atan Artiox; ortalama gelire sahip kişilere küçük meblağlarla sanat yatırımcısı olabilme fırsatı sağlıyor.' +
                '<div class="job-advertisement-heading">Sanat Yatırımının Avantajları Neler?</div>' +
                'Sanat yatırımcılarını sadece duvarlarındaki tablolara bakmaktan haz duyan sanat meraklıları olarak görüyorsanız fena halde yanılıyorsunuz.' +
                ' Sanata duyulan kişisel haz faktörü her zaman olsa da sanat yatırımcıları, sanat eserlerini ticari değerini de göz önünde bulundurarak satın alıyorlar.' +
                ' Yatırım konusunda bu tercihi yapmalarının en önemli nedeni ise, doğru seçilmiş sanat eserlerinin uzun vadede daima kazandırması.' +
                '<div class="job-advertisement-heading">Doğru Sanat Eseri Nasıl Seçilir?</div>' +
                'Her yatırım aracında olduğu gibi sanat eserlerinin de ticari getirisi değişkenlik gösterebiliyor.' +
                ' Geleneksel sanat pazarı kanallarında yapılacak yatırımın büyük miktarlarda olacağını düşünürsek' +
                ' doğru sanat eseriyle yola çıkmanın ne kadar önemli olduğunu görmemiz mümkün.<br>' +
                'Öncelikle sanat eserinde imzası olan sanatçının kim olduğu oldukça önemli.' +
                ' Sanat dünyasında bilinen sanatçıların eserleri genellikle değer kaybetmiyor.' +
                ' Bu eserlerin düşük riskli birer yatırım aracı olmaları eserlere olan talebi artırıyor.<br>' +
                'Daha ekonomik fiyatlarla daha az duyulmuş sanatçıların eserlerine sahip olmak mümkün.' +
                ' Ancak bu, riskli bir yatırım.' +
                ' Böyle bir yatırım yapmadan önce, sanatçının gelecekteki ticari potansiyelinden emin olmak gerekiyor.<br>' +
                'Sonuç olarak yatırım yapmak için doğru sanat eserini tespit etmek o kadar da kolay bir iş değil.' +
                ' Bu yüzden ciddi koleksiyonerlerin neredeyse tamamı danışmanlarla çalışıyor.<br>' +
                'Artiox, bu soruna da bir çözüm getiriyor.' +
                ' Bir sanat eseri Artiox’un portföyüne dahil edilmeden önce alanında uzman bir sanat danışmanı tarafından değerlendirilerek ticari potansiyeli belirleniyor.' +
                ' Böylece Artiox kullanıcıları, sanat piyasası konusunda hiçbir bilgi sahibi olmasalar bile doğru esere yatırım yapmanın rahatlığını yaşıyorlar.<br>' +
                '<div class="job-advertisement-heading">Sanat Eserini Satın Aldınız. Peki ya Sonra?</div>' +
                'Dolar satın aldığınızda bankaya yatırabilirsiniz, altın aldığınızda kasaya koyabilirsiniz.' +
                ' Peki sanat eserinizi nasıl saklayacaksınız?<br>' +
                'Sanat eseri yatırımcılığının en zorlayıcı yanlarından biri de sanat eserlerinin muhafazasının oldukça hassas bir iş olması.' +
                ' Bu eserleri; eserin yapısına göre doğru ısı, ışık ve nem koşullarında saklamak gerekiyor.' +
                ' Tabii ki öncelikle eserin güvenliğini sağlamak da şart.<br>' +
                'Sanat eseri sahibi olmak sorumluluk gerektiriyor.' +
                ' Bu eserlerin sadece birer yatırım aracı değil' +
                ' aynı zamanda insanlığın ortak mirası olduğunu unutmamak ve bu yüzden sahip olunan eserleri en iyi koşullarda muhafaza etmek şart.<br>' +
                'Artiox, sanat eserlerine yatırım yapmak isteyenleri bu dertten de kurtarıyor.' +
                ' Sigortalanan sanat eserleri, bu eserlerin saklanması için tasarlanmış güvenli depolarda muhafaza ediliyor.<br>' +
                'Geleneksel sanat yatırımcılığı büyük sermaye ve yoğun ilgi gerektiren bir iş.' +
                ' Bu yüzden toplumun çok küçük bir bölümü sanat yatırımcısı olabiliyor.' +
                ' Ancak Artiox, sanat yatırımcılığını teknolojinin sağladığı imkânlar sayesinde demokratikleştiriyor.' +
                ' Siz de Artiox’a hemen üye olun ve sanata yatırım yapmaya başlayın.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Acemiler İçin Sanat Yatırımcılığı</div>' +
                'Neredeyse hepimiz borsa ya da döviz piyasaları gibi alışılmış yatırım araçlarına aşinayız.' +
                ' Doğal olarak birikimlerimizin de büyük bir bölümünü bu yatırım araçlarında değerlendiriyoruz.' +
                ' Ancak bu yatırım araçlarına alternatifler de mevcut. Sanat yatırımları da bu alternatifler arasında.' +
                '<div class="job-advertisement-heading">Sanat Yatırımcısı Olmak Ortalama Gelire Sahip Biri İçin Hayal mi?</div>' +
                'Sanat yatırımcılığı konusunda çoğumuzun sınırlı bilgi sahibi olmasının en büyük nedeni, sanat yatırımcılığının zengin insanların işi olduğunu düşünmemiz.' +
                ' Geleneksel sanat pazarını düşündüğümüzde bu kanı çok da isabetsiz değil.' +
                ' Müzayedelerde satılan ve yatırım değeri olan sanat eserlerine binlerce dolar harcayarak sahip olmak herkes için mümkün değil.<br>' +
                'Ancak sanat pazarında işler değişiyor.' +
                ' Sanat yatırımcılığı 3.0 kavramını ortaya atan Artiox; ortalama gelire sahip kişilere küçük meblağlarla sanat yatırımcısı olabilme fırsatı sağlıyor.' +
                '<div class="job-advertisement-heading">Sanat Yatırımının Avantajları Neler?</div>' +
                'Sanat yatırımcılarını sadece duvarlarındaki tablolara bakmaktan haz duyan sanat meraklıları olarak görüyorsanız fena halde yanılıyorsunuz.' +
                ' Sanata duyulan kişisel haz faktörü her zaman olsa da sanat yatırımcıları, sanat eserlerini ticari değerini de göz önünde bulundurarak satın alıyorlar.' +
                ' Yatırım konusunda bu tercihi yapmalarının en önemli nedeni ise, doğru seçilmiş sanat eserlerinin uzun vadede daima kazandırması.' +
                '<div class="job-advertisement-heading">Doğru Sanat Eseri Nasıl Seçilir?</div>' +
                'Her yatırım aracında olduğu gibi sanat eserlerinin de ticari getirisi değişkenlik gösterebiliyor.' +
                ' Geleneksel sanat pazarı kanallarında yapılacak yatırımın büyük miktarlarda olacağını düşünürsek' +
                ' doğru sanat eseriyle yola çıkmanın ne kadar önemli olduğunu görmemiz mümkün.<br>' +
                'Öncelikle sanat eserinde imzası olan sanatçının kim olduğu oldukça önemli.' +
                ' Sanat dünyasında bilinen sanatçıların eserleri genellikle değer kaybetmiyor.' +
                ' Bu eserlerin düşük riskli birer yatırım aracı olmaları eserlere olan talebi artırıyor.<br>' +
                'Daha ekonomik fiyatlarla daha az duyulmuş sanatçıların eserlerine sahip olmak mümkün.' +
                ' Ancak bu, riskli bir yatırım.' +
                ' Böyle bir yatırım yapmadan önce, sanatçının gelecekteki ticari potansiyelinden emin olmak gerekiyor.<br>' +
                'Sonuç olarak yatırım yapmak için doğru sanat eserini tespit etmek o kadar da kolay bir iş değil.' +
                ' Bu yüzden ciddi koleksiyonerlerin neredeyse tamamı danışmanlarla çalışıyor.<br>' +
                'Artiox, bu soruna da bir çözüm getiriyor.' +
                ' Bir sanat eseri Artiox’un portföyüne dahil edilmeden önce alanında uzman bir sanat danışmanı tarafından değerlendirilerek ticari potansiyeli belirleniyor.' +
                ' Böylece Artiox kullanıcıları, sanat piyasası konusunda hiçbir bilgi sahibi olmasalar bile doğru esere yatırım yapmanın rahatlığını yaşıyorlar.<br>' +
                '<div class="job-advertisement-heading">Sanat Eserini Satın Aldınız. Peki ya Sonra?</div>' +
                'Dolar satın aldığınızda bankaya yatırabilirsiniz, altın aldığınızda kasaya koyabilirsiniz.' +
                ' Peki sanat eserinizi nasıl saklayacaksınız?<br>' +
                'Sanat eseri yatırımcılığının en zorlayıcı yanlarından biri de sanat eserlerinin muhafazasının oldukça hassas bir iş olması.' +
                ' Bu eserleri; eserin yapısına göre doğru ısı, ışık ve nem koşullarında saklamak gerekiyor.' +
                ' Tabii ki öncelikle eserin güvenliğini sağlamak da şart.<br>' +
                'Sanat eseri sahibi olmak sorumluluk gerektiriyor.' +
                ' Bu eserlerin sadece birer yatırım aracı değil' +
                ' aynı zamanda insanlığın ortak mirası olduğunu unutmamak ve bu yüzden sahip olunan eserleri en iyi koşullarda muhafaza etmek şart.<br>' +
                'Artiox, sanat eserlerine yatırım yapmak isteyenleri bu dertten de kurtarıyor.' +
                ' Sigortalanan sanat eserleri, bu eserlerin saklanması için tasarlanmış güvenli depolarda muhafaza ediliyor.<br>' +
                'Geleneksel sanat yatırımcılığı büyük sermaye ve yoğun ilgi gerektiren bir iş.' +
                ' Bu yüzden toplumun çok küçük bir bölümü sanat yatırımcısı olabiliyor.' +
                ' Ancak Artiox, sanat yatırımcılığını teknolojinin sağladığı imkânlar sayesinde demokratikleştiriyor.' +
                ' Siz de Artiox’a hemen üye olun ve sanata yatırım yapmaya başlayın.',
            ROUTER_LINK: {
                TEXT: 'Eserleri İncele',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Sanat Eserinin Ticari Değeri',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Bir Sanat Eserinin Ticari Değeri Nasıl Belirlenir?</div>' +
                '15 Kasım 2017 günü New York’ta, dünyanın en önemli müzayede evlerinden biri olan Christie\'s oldukça önemli bir müzayedeye ev sahipliği yapıyordu.' +
                ' Müzayedede Leonardo’nun “Salvator Mundi” tablosu satışa çıkarılmıştı.' +
                ' Açık arttırmayı Suudi veliaht prens Muhammed bin Selman adına müzayedeye katılan Bader bin Abdullah kazandı.' +
                ' 450.3 milyon dolarlık fiyat etiketiyle Salvator Mundi, o güne kadar satılan en pahalı tablo olmuştu.' +
                ' Ancak aynı tablo 1958 yılındaki bir açık arttırmada sadece 58 Pounda alıcı bulabilmişti.<br>' +
                'Sanat eserlerinin ticari değerlemesinin nasıl yapıldığı konusu, sanata ilgi duyan ancak sanat piyasalarına uzak olanların en çok merak ettikleri konulardan biri.' +
                ' Müzayedelerde milyonlarca dolara alıcı bulan tablolar hakkında çıkan haberler hemen hemen herkesin bu konuyla ilgilenmesini sağlıyor.<br>' +
                'Birçok ticari metanın fiyatlandırılması için belirli değişkenler var.' +
                ' Bir ev satın aldığınızda evin konumu, binanın yaşı ve evin büyüklüğü gibi değişkenler,' +
                ' satın almayı düşündüğünüz ev için ne kadar ödemeniz gerektiği konusunda size önemli ipuçları veriyor.<br>' +
                'Ancak aynı durum sanat eserleri için geçerli değil.' +
                ' Sanat eserinin değerlemesi konusunda kriterler olsa da bir sanat eserinin gerçek ticari değerini tam olarak öngörmek kolay değil.' +
                'Gelin, bir sanat eserinin fiyatlandırılmasını yaparken bakılan değişkenlere göz atalım.' +
                '<div class="job-advertisement-heading">Sanatçı</div>' +
                'Şüphesiz ki bir sanat eserinin değerinin temel belirleyicisi, sanat eserini üreten sanatçı.' +
                ' Sanatçının, sanat dünyasındaki önemi, ortaya çıkardığı eser sayısı ve söz konusu eseri sanat hayatının hangi döneminde ürettiği önemli.' +
                ' Genellikle tanınmış sanatçıların eserleri yüksek fiyatlardan alıcı bulurken' +
                ' bazen de kimliği bir gizem olan ya da yaşadığı dönemlerde kıymeti bilinmemiş sanatçıların eserleri de yüksek fiyatlardan satılabiliyor.' +
                ' Dolayısıyla bir sanatçının, sanat piyasasında pazarlanması önemli olsa da günün sonunda gerçek değer sanatçının kimliği üzerinden belirleniyor.' +
                '<div class="job-advertisement-heading">Gerçeklik</div>' +
                'Sanat pazarı taklitlerle dolu. Bu taklitlerin bir bölümü evinizde asılı Venüs tablosu kadar masum değil.' +
                ' Sahte eserlerin de içinde bulunduğu sanat suçları her yıl Amerikan ekonomisine 6 ile 8 milyar dolar arasında zarar veriyor.' +
                ' Bu suçlar için FBI’ın özel bir masası bile var.<br>' +
                'Sahte sanat eserlerinin bu kadar yaygın olması, eserlerin gerçekliğinin kritik öneme sahip olmasına neden oluyor.' +
                ' Yazımızın başında anlattığımız Salvator Mundi tablosunun 1958 yılında sadece 58 pounda satılmasının nedeni,' +
                ' o dönemde tablonun gerçekliği konusunda büyük şüphelerin olmasıydı.' +
                '<div class="job-advertisement-heading">Eserin Durumu</div>' +
                'Sanat eserinin fiziksel durumu da eserin değerini belirleyen faktörlerden.' +
                ' Özellikle üretim tarihi üzerinden zaman geçmiş sanat eserleri için bu değişken oldukça önemli.' +
                ' Doğal nedenler yüzünden yıpranmış ya da kötü bir restorasyona maruz kalmış eserlerin değeri düşebiliyor.' +
                '<div class="job-advertisement-heading">Sanat Pazarının Durumu</div>' +
                'Bir sanat eserinin değerinin belirlenmesinde sanat pazarının durumu da oldukça önemli bir değişken.' +
                ' Sanat pazarının satış zamanındaki büyüklüğü ve pazarın sanatçıya, esere ya da eserin içinde olduğu akıma olan talebi de eserin fiyat etiketini etkiliyor.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Bir Sanat Eserinin Ticari Değeri Nasıl Belirlenir?</div>' +
                '15 Kasım 2017 günü New York’ta, dünyanın en önemli müzayede evlerinden biri olan Christie\'s oldukça önemli bir müzayedeye ev sahipliği yapıyordu.' +
                ' Müzayedede Leonardo’nun “Salvator Mundi” tablosu satışa çıkarılmıştı.' +
                ' Açık arttırmayı Suudi veliaht prens Muhammed bin Selman adına müzayedeye katılan Bader bin Abdullah kazandı.' +
                ' 450.3 milyon dolarlık fiyat etiketiyle Salvator Mundi, o güne kadar satılan en pahalı tablo olmuştu.' +
                ' Ancak aynı tablo 1958 yılındaki bir açık arttırmada sadece 58 Pounda alıcı bulabilmişti.<br>' +
                'Sanat eserlerinin ticari değerlemesinin nasıl yapıldığı konusu, sanata ilgi duyan ancak sanat piyasalarına uzak olanların en çok merak ettikleri konulardan biri.' +
                ' Müzayedelerde milyonlarca dolara alıcı bulan tablolar hakkında çıkan haberler hemen hemen herkesin bu konuyla ilgilenmesini sağlıyor.<br>' +
                'Birçok ticari metanın fiyatlandırılması için belirli değişkenler var.' +
                ' Bir ev satın aldığınızda evin konumu, binanın yaşı ve evin büyüklüğü gibi değişkenler,' +
                ' satın almayı düşündüğünüz ev için ne kadar ödemeniz gerektiği konusunda size önemli ipuçları veriyor.<br>' +
                'Ancak aynı durum sanat eserleri için geçerli değil.' +
                ' Sanat eserinin değerlemesi konusunda kriterler olsa da bir sanat eserinin gerçek ticari değerini tam olarak öngörmek kolay değil.' +
                'Gelin, bir sanat eserinin fiyatlandırılmasını yaparken bakılan değişkenlere göz atalım.' +
                '<div class="job-advertisement-heading">Sanatçı</div>' +
                'Şüphesiz ki bir sanat eserinin değerinin temel belirleyicisi, sanat eserini üreten sanatçı.' +
                ' Sanatçının, sanat dünyasındaki önemi, ortaya çıkardığı eser sayısı ve söz konusu eseri sanat hayatının hangi döneminde ürettiği önemli.' +
                ' Genellikle tanınmış sanatçıların eserleri yüksek fiyatlardan alıcı bulurken' +
                ' bazen de kimliği bir gizem olan ya da yaşadığı dönemlerde kıymeti bilinmemiş sanatçıların eserleri de yüksek fiyatlardan satılabiliyor.' +
                ' Dolayısıyla bir sanatçının, sanat piyasasında pazarlanması önemli olsa da günün sonunda gerçek değer sanatçının kimliği üzerinden belirleniyor.' +
                '<div class="job-advertisement-heading">Gerçeklik</div>' +
                'Sanat pazarı taklitlerle dolu. Bu taklitlerin bir bölümü evinizde asılı Venüs tablosu kadar masum değil.' +
                ' Sahte eserlerin de içinde bulunduğu sanat suçları her yıl Amerikan ekonomisine 6 ile 8 milyar dolar arasında zarar veriyor.' +
                ' Bu suçlar için FBI’ın özel bir masası bile var.<br>' +
                'Sahte sanat eserlerinin bu kadar yaygın olması, eserlerin gerçekliğinin kritik öneme sahip olmasına neden oluyor.' +
                ' Yazımızın başında anlattığımız Salvator Mundi tablosunun 1958 yılında sadece 58 pounda satılmasının nedeni,' +
                ' o dönemde tablonun gerçekliği konusunda büyük şüphelerin olmasıydı.' +
                '<div class="job-advertisement-heading">Eserin Durumu</div>' +
                'Sanat eserinin fiziksel durumu da eserin değerini belirleyen faktörlerden.' +
                ' Özellikle üretim tarihi üzerinden zaman geçmiş sanat eserleri için bu değişken oldukça önemli.' +
                ' Doğal nedenler yüzünden yıpranmış ya da kötü bir restorasyona maruz kalmış eserlerin değeri düşebiliyor.' +
                '<div class="job-advertisement-heading">Sanat Pazarının Durumu</div>' +
                'Bir sanat eserinin değerinin belirlenmesinde sanat pazarının durumu da oldukça önemli bir değişken.' +
                ' Sanat pazarının satış zamanındaki büyüklüğü ve pazarın sanatçıya, esere ya da eserin içinde olduğu akıma olan talebi de eserin fiyat etiketini etkiliyor.',
            ROUTER_LINK: {
                TEXT: 'Eserleri İncele',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Sanatçılar İçin NFT Kavramı',
            IMAGE_SRC: './assets/announcements/operations/corporate.svg#corporate',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Sanatçılar İçin NFT Kavramı Ne İfade Ediyor?</div>' +
                '2011 yılının Nisan ayında kuyruğundan gökkuşağı çıkan bir kedi animasyonu YouTube’a yüklendi.' +
                ' Arka planda bir Japon pop şarkısının çalındığı bu video açıklanamaz bir şekilde popüler hale geldi ve 2011 yılının YouTube’da en çok izlenen 5. videosu oldu.<br>' +
                'Nyan Cat adlı bu videonun yaratıcısı Chris Torres,' +
                ' videonun 10. Yılını kutlamak için videonun ortaya çıkmasını sağlayan GIF’i yeniden düzenleyerek NFT haline getirdi ve satışa sundu.' +
                ' İnsanları sadece birkaç saniye eğlendiren Nyan Cat, sansasyonel bir şekilde 590.000$ değeriyle satıldı.<br>' +
                'Bunun gibi son zamanlarda ortaya çıkan birçok hikâye,' +
                ' NFT kavramını konuyla alakası olmayan insanların bile gündemi haline getirirken' +
                ' bu hikâyelerin sansasyonel tarafı NFT’lerin birer internet balonu olduğunun düşünülmesine neden oldu.<br>' +
                'Ancak bu alanda sansasyonun ötesinde büyük bir değişim yaşanıyor.' +
                ' Sanatçılar ise bu değişimin büyük bir parçası.<br>' +
                '<div class="job-advertisement-heading">Dışlanmışlar</div>' +
                'Dijital mecraları tuval olarak kullanan sanatçılar, NFT kavramına öncülük ettiler.' +
                ' Bunun en büyük nedeni bu sanatçıların geleneksel sanat piyasalarından büyük ölçüde dışlanmalarıydı.' +
                ' Bu sanatçılar son zamanlarda daha yenilikçi bir çizgiye sahip sanat oluşumları içinde yer alsalar da' +
                ' ürettikleri eserlerin geleneksel anlamda koleksiyon değeri sorgulandığı için ticari başarılar elde etme konusunda oldukça zorlandılar.<br>' +
                'NFT kavramı bu dışlanmış sanatçılara kucak açtı.' +
                ' NFT sayesinde çok daha demokratik yapılara sahip dijital platformlar üstünden eserlerini satışa sunabilen sanatçılar' +
                ' daha rahat bir şekilde geniş kitlelere ulaşıp satış yapma fırsatını ele geçirmiş oldular.<br>' +
                '<div class="job-advertisement-heading">Aracılar</div>' +
                'Sanat piyasasının en önemli gerçekliklerinden biri, bu piyasanın büyük ölçüde aracıların elinde olduğu.' +
                ' Bir sanat eserinin satışında sanatçının kimliği ve eserin özelliklerine bağlı olarak aracı komisyonları %50’ye kadar ulaşabiliyor.' +
                ' Bu yüzden asıl üretimi yapan sanatçı, hak ettiğinden çok daha azını kazanıyor.<br>' +
                'NFT’nin getirdiği direkt satış, sanatçıların ödemek zorunda oldukları fahiş komisyon oranlarının ortadan kalkmasını sağlıyor.' +
                ' Sanatçılar NFT’ye dönüştürdükleri eserleri doğrudan satabiliyor ya da geleneksel aracı kurumlarla karşılaştırıldığında' +
                ' çok daha düşük komisyon oranlarıyla çalışan platformlar üzerinden eserlerin satışını gerçekleştirebiliyorlar.<br>' +
                '<div class="job-advertisement-heading">Sahiplik</div>' +
                'Geleneksel anlamda sanat eserinin satışı ile sanatçının ürettiği eser üstündeki sahipliği sona eriyor.' +
                ' Sanatçının kariyerindeki gelişmeler sayesinde daha önce satmış olduğu bir eserin değeri artarken sanatçı bu artan değerden faydalanamıyor.<br>' +
                'NFT sayesinde sanatçının üretmiş olduğu eserin her satışından belirli bir yüzde alması mümkün.' +
                ' Böylelikle satmış olduğu bir eserden tüm hayatı boyunca gelir elde etmeye devam edebiliyor.<br>' +
                'NFT teknolojisinin sağladığı olanaklar sayesinde sanatın finansmanının sanatçılar lehine değişeceğini söylemek mümkün.' +
                ' Ancak teknolojik yeniliklerin gerçek etkilerini görmek için uzunca bir süre geçmesi gerekiyor.' +
                ' Bu süre sonunda NFT’lerin sanat finansmanını ne ölçüde demokratikleştireceğini göreceğiz.<br>',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Sanatçılar İçin NFT Kavramı Ne İfade Ediyor?</div>' +
                '2011 yılının Nisan ayında kuyruğundan gökkuşağı çıkan bir kedi animasyonu YouTube’a yüklendi.' +
                ' Arka planda bir Japon pop şarkısının çalındığı bu video açıklanamaz bir şekilde popüler hale geldi ve 2011 yılının YouTube’da en çok izlenen 5. videosu oldu.<br>' +
                'Nyan Cat adlı bu videonun yaratıcısı Chris Torres,' +
                ' videonun 10. Yılını kutlamak için videonun ortaya çıkmasını sağlayan GIF’i yeniden düzenleyerek NFT haline getirdi ve satışa sundu.' +
                ' İnsanları sadece birkaç saniye eğlendiren Nyan Cat, sansasyonel bir şekilde 590.000$ değeriyle satıldı.<br>' +
                'Bunun gibi son zamanlarda ortaya çıkan birçok hikâye,' +
                ' NFT kavramını konuyla alakası olmayan insanların bile gündemi haline getirirken' +
                ' bu hikâyelerin sansasyonel tarafı NFT’lerin birer internet balonu olduğunun düşünülmesine neden oldu.<br>' +
                'Ancak bu alanda sansasyonun ötesinde büyük bir değişim yaşanıyor.' +
                ' Sanatçılar ise bu değişimin büyük bir parçası.<br>' +
                '<div class="job-advertisement-heading">Dışlanmışlar</div>' +
                'Dijital mecraları tuval olarak kullanan sanatçılar, NFT kavramına öncülük ettiler.' +
                ' Bunun en büyük nedeni bu sanatçıların geleneksel sanat piyasalarından büyük ölçüde dışlanmalarıydı.' +
                ' Bu sanatçılar son zamanlarda daha yenilikçi bir çizgiye sahip sanat oluşumları içinde yer alsalar da' +
                ' ürettikleri eserlerin geleneksel anlamda koleksiyon değeri sorgulandığı için ticari başarılar elde etme konusunda oldukça zorlandılar.<br>' +
                'NFT kavramı bu dışlanmış sanatçılara kucak açtı.' +
                ' NFT sayesinde çok daha demokratik yapılara sahip dijital platformlar üstünden eserlerini satışa sunabilen sanatçılar' +
                ' daha rahat bir şekilde geniş kitlelere ulaşıp satış yapma fırsatını ele geçirmiş oldular.<br>' +
                '<div class="job-advertisement-heading">Aracılar</div>' +
                'Sanat piyasasının en önemli gerçekliklerinden biri, bu piyasanın büyük ölçüde aracıların elinde olduğu.' +
                ' Bir sanat eserinin satışında sanatçının kimliği ve eserin özelliklerine bağlı olarak aracı komisyonları %50’ye kadar ulaşabiliyor.' +
                ' Bu yüzden asıl üretimi yapan sanatçı, hak ettiğinden çok daha azını kazanıyor.<br>' +
                'NFT’nin getirdiği direkt satış, sanatçıların ödemek zorunda oldukları fahiş komisyon oranlarının ortadan kalkmasını sağlıyor.' +
                ' Sanatçılar NFT’ye dönüştürdükleri eserleri doğrudan satabiliyor ya da geleneksel aracı kurumlarla karşılaştırıldığında' +
                ' çok daha düşük komisyon oranlarıyla çalışan platformlar üzerinden eserlerin satışını gerçekleştirebiliyorlar.<br>' +
                '<div class="job-advertisement-heading">Sahiplik</div>' +
                'Geleneksel anlamda sanat eserinin satışı ile sanatçının ürettiği eser üstündeki sahipliği sona eriyor.' +
                ' Sanatçının kariyerindeki gelişmeler sayesinde daha önce satmış olduğu bir eserin değeri artarken sanatçı bu artan değerden faydalanamıyor.<br>' +
                'NFT sayesinde sanatçının üretmiş olduğu eserin her satışından belirli bir yüzde alması mümkün.' +
                ' Böylelikle satmış olduğu bir eserden tüm hayatı boyunca gelir elde etmeye devam edebiliyor.<br>' +
                'NFT teknolojisinin sağladığı olanaklar sayesinde sanatın finansmanının sanatçılar lehine değişeceğini söylemek mümkün.' +
                ' Ancak teknolojik yeniliklerin gerçek etkilerini görmek için uzunca bir süre geçmesi gerekiyor.' +
                ' Bu süre sonunda NFT’lerin sanat finansmanını ne ölçüde demokratikleştireceğini göreceğiz.<br>',
            ROUTER_LINK: {
                TEXT: 'Eserleri İncele',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Bir Türk Sanatçıya Ait En Pahalı Tablo',
            IMAGE_SRC: './assets/announcements/operations/corporate.svg#corporate',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Bir Türk Sanatçıya Ait En Pahalı Tablo: Kur’an Okuyan Kız</div>' +
                '“Kaplumbağa Terbiyecisi” hemen hemen hepimizin aşina olduğu bir tablo.' +
                ' Bu tablonun ressamı Osman Hamdi Bey’in “Kur’an Okuyan Kız” tablosu,' +
                ' 2019 yılında Bonhams Müzayede Evi’nde gerçekleştirilen bir açık artırmada 6 milyon 315 bin sterline alıcı buldu.' +
                ' Bu rakam, Türkiye’den bir sanatçının eserine ödenen en yüksek rakam.' +
                '<div class="job-advertisement-heading">Osman Hamdi Bey Kimdir?</div>' +
                '“Kur’an Okuyan Kız” resminin bu kadar yüksek bir fiyattan alıcı bulmasının en önemli nedenlerinden biri, Osman Hamdi Bey’in önemi.<br>' +
                'Osman Hamdi Bey, Türk resim sanatının öncülerinden.' +
                ' 1842 yılında İstanbul’da dünyaya gelen Osman Hamdi Bey küçük yaşlarda resim sanatına ilgi duymaya başladı.' +
                ' Ortaokul yıllarında Maarif-i Adliye’de eğitim görürken yaptığı karakalem çizimleriyle resim sanatına olan yeteneğini çevresine kanıtladı.' +
                ' Üniversite eğitimi için babası tarafından Paris’e gönderildi.' +
                ' Paris’te 12 yıl boyunca kaldı.' +
                ' Bu süre içinde bir yandan hukuk alanında eğitim alırken,' +
                ' bir yandan da Léon Gérôme ve Boulanger gibi dönemin önemli ressamlarının atölyelerinde çalışarak resim alanında da ilerledi.<br>' +
                'Osman Hamdi Bey çok yönlü bir insandı.' +
                ' Sadece resim sanatıyla değil arkeoloji ve politikayla da ilgilendi.' +
                ' Lübnan’daki Sayda Kral Mezarlığı kazısını gerçekleştirdi ve' +
                ' arkeoloji alanındaki yasal zeminin hazırlanmasını sağlayarak modern arkeoloji biliminin Osmanlı’daki ilk temellerinin atılmasına öncelik etti.<br>' +
                'Osman Hamdi Bey, 1881 yılında İstanbul Arkeoloji Müzeleri’nin temelini oluşturan Müze-i Humayun’a müdür olarak atanır.' +
                ' Türk müzeciliğinde yeni bir çağın başlangıcı olan bu atama ile Osman Hamdi Bey,' +
                ' müzeyi kısa süre içinde dünyanın en önemli arkeoloji müzelerinden biri haline getirir.<br>' +
                'Politikayla da ilgilenen ressam aynı zamanda Kadıköy’ün ilk belediye başkanıdır.' +
                '<div class="job-advertisement-heading">Doğu ile Batı Arasında Bir Köprü</div>' +
                'Hayat hikâyesinden de anlayacağınız gibi Osman Hamdi Bey, Osmanlı’nın batılılaşma çabası içinde yetişmiş çok yönlü bir isim.' +
                ' Osman Hamdi Bey’in İslam kültürü içinde büyük ölçüde reddedilen resim sanatında sadece ürettiği eserlerle değil,' +
                ' bu sanatın Osmanlı topraklarında kabul görmesini sağlayan ilk isimlerden biri olması sanatçıyı önemli bir isim haline getiriyor.<br>' +
                'Osman Hamdi Bey’i Batılılar için çekici kılan özelliği, batılı resim teknikleriyle oryantal bir yaşam tarzını resmetmesi.' +
                ' Bu özelliğiyle sanayi devrimi sırasında batıda popüler hale gelen oryantalizm akımıyla ilgi toplamayı başarmış.' +
                '<div class="job-advertisement-heading">Oryantalizmin Sorgulanması</div>' +
                'Ancak batılı oryantalistler doğuyu egzotik ancak geride kalmış bir yer olarak masalsı halde anlatırken,' +
                ' Osman Hamdi Bey günlük hayattan gerçek konular seçerek karşı bir duruş sergiledi.<br>' +
                'Osman Hamdi Bey’in tablolarındaki kadın karakterleri de Batılıların zihnindeki doğulu kadın imajına uymuyordu.' +
                ' Osman Hamdi Bey tablolarında günlük hayatın içinde olan, dışarıya açılmış kadınlara yer verdi.<br>' +
                'Osman Hamdi Bey’in çağdaşı olan oryantalistlere karşı bu duruşu günümüzde kabul gördüğünü söylemek mümkün.' +
                ' Batı’nın Doğu’ya bakışını sorguladığı bu zamanlarda Osman Hamdi Bey’in eserlerinin farklı bir boyutta popülerlik kazanması da oldukça doğal.<br>' +
                '“Kur’an Okuyan Kız” tablosu British Museum ve Malezya İslam Sanatları Müzesi’nin ortaklaşa düzenledikleri ' +
                '“Doğu’dan İlhamla: İslam Dünyası Batı Sanatını Nasıl Etkiledi” isimli sergide yer aldı.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Bir Türk Sanatçıya Ait En Pahalı Tablo: Kur’an Okuyan Kız</div>' +
                '“Kaplumbağa Terbiyecisi” hemen hemen hepimizin aşina olduğu bir tablo.' +
                ' Bu tablonun ressamı Osman Hamdi Bey’in “Kur’an Okuyan Kız” tablosu,' +
                ' 2019 yılında Bonhams Müzayede Evi’nde gerçekleştirilen bir açık artırmada 6 milyon 315 bin sterline alıcı buldu.' +
                ' Bu rakam, Türkiye’den bir sanatçının eserine ödenen en yüksek rakam.' +
                '<div class="job-advertisement-heading">Osman Hamdi Bey Kimdir?</div>' +
                '“Kur’an Okuyan Kız” resminin bu kadar yüksek bir fiyattan alıcı bulmasının en önemli nedenlerinden biri, Osman Hamdi Bey’in önemi.<br>' +
                'Osman Hamdi Bey, Türk resim sanatının öncülerinden.' +
                ' 1842 yılında İstanbul’da dünyaya gelen Osman Hamdi Bey küçük yaşlarda resim sanatına ilgi duymaya başladı.' +
                ' Ortaokul yıllarında Maarif-i Adliye’de eğitim görürken yaptığı karakalem çizimleriyle resim sanatına olan yeteneğini çevresine kanıtladı.' +
                ' Üniversite eğitimi için babası tarafından Paris’e gönderildi. Paris’te 12 yıl boyunca kaldı.' +
                ' Bu süre içinde bir yandan hukuk alanında eğitim alırken,' +
                ' bir yandan da Léon Gérôme ve Boulanger gibi dönemin önemli ressamlarının atölyelerinde çalışarak resim alanında da ilerledi.<br>' +
                'Osman Hamdi Bey çok yönlü bir insandı.' +
                ' Sadece resim sanatıyla değil arkeoloji ve politikayla da ilgilendi.' +
                ' Lübnan’daki Sayda Kral Mezarlığı kazısını gerçekleştirdi ve' +
                ' arkeoloji alanındaki yasal zeminin hazırlanmasını sağlayarak modern arkeoloji biliminin Osmanlı’daki ilk temellerinin atılmasına öncelik etti.<br>' +
                'Osman Hamdi Bey, 1881 yılında İstanbul Arkeoloji Müzeleri’nin temelini oluşturan Müze-i Humayun’a müdür olarak atanır.' +
                ' Türk müzeciliğinde yeni bir çağın başlangıcı olan bu atama ile Osman Hamdi Bey,' +
                ' müzeyi kısa süre içinde dünyanın en önemli arkeoloji müzelerinden biri haline getirir.<br>' +
                'Politikayla da ilgilenen ressam aynı zamanda Kadıköy’ün ilk belediye başkanıdır.' +
                '<div class="job-advertisement-heading">Doğu ile Batı Arasında Bir Köprü</div>' +
                'Hayat hikâyesinden de anlayacağınız gibi Osman Hamdi Bey, Osmanlı’nın batılılaşma çabası içinde yetişmiş çok yönlü bir isim.' +
                ' Osman Hamdi Bey’in İslam kültürü içinde büyük ölçüde reddedilen resim sanatında sadece ürettiği eserlerle değil,' +
                ' bu sanatın Osmanlı topraklarında kabul görmesini sağlayan ilk isimlerden biri olması sanatçıyı önemli bir isim haline getiriyor.<br>' +
                'Osman Hamdi Bey’i Batılılar için çekici kılan özelliği, batılı resim teknikleriyle oryantal bir yaşam tarzını resmetmesi.' +
                ' Bu özelliğiyle sanayi devrimi sırasında batıda popüler hale gelen oryantalizm akımıyla ilgi toplamayı başarmış.' +
                '<div class="job-advertisement-heading">Oryantalizmin Sorgulanması</div>' +
                'Ancak batılı oryantalistler doğuyu egzotik ancak geride kalmış bir yer olarak masalsı halde anlatırken,' +
                ' Osman Hamdi Bey günlük hayattan gerçek konular seçerek karşı bir duruş sergiledi.<br>' +
                'Osman Hamdi Bey’in tablolarındaki kadın karakterleri de Batılıların zihnindeki doğulu kadın imajına uymuyordu.' +
                ' Osman Hamdi Bey tablolarında günlük hayatın içinde olan, dışarıya açılmış kadınlara yer verdi.<br>' +
                'Osman Hamdi Bey’in çağdaşı olan oryantalistlere karşı bu duruşu günümüzde kabul gördüğünü söylemek mümkün.' +
                ' Batı’nın Doğu’ya bakışını sorguladığı bu zamanlarda Osman Hamdi Bey’in eserlerinin farklı bir boyutta popülerlik kazanması da oldukça doğal.<br>' +
                '“Kur’an Okuyan Kız” tablosu British Museum ve Malezya İslam Sanatları Müzesi’nin ortaklaşa düzenledikleri ' +
                '“Doğu’dan İlhamla: İslam Dünyası Batı Sanatını Nasıl Etkiledi” isimli sergide yer aldı.',
            ROUTER_LINK: {
                TEXT: 'Eserleri İncele',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Nasıl Yatırımcı Olunur: Başlangıç Rehberi',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Nasıl Yatırımcı Olunur: Otuzlu Yaşlarına Yeni Girenler İçin Küçük Bir Başlangıç Rehberi</div>' +
                'Türk Dil Kurumu “yatırım” kelimesini şöyle tanımlıyor: <i>“Parayı, gelir getirici taşınır ya da taşınmaz bir mala yatırma, mevduat, plasman.”</i><br>' +
                'Yatırım, para biriktirmek değildir.' +
                ' Biriken para çoğalıyor gibi gözükse de enflasyon ve ekonomik krizler karşısında değer kaybeder.' +
                ' Ayrıca doğru bir şekilde yapılmış yatırım; bir ev satın almak ya da rahat bir emeklilik geçirmek gibi finansal hedeflerinize ulaşmanızı kolaylaştırır.' +
                '<div class="job-advertisement-heading">Yatırım Yapmaya Başlamadan Önce</div>' +
                'Yatırımcı olmak için öncelikle bazı konular üstünde düşünmeniz gerekiyor.<br><br>' +
                '<strong>Borçlarınızı yönetebiliyor musunuz?</strong><br>' +
                'Ne yazık ki borç, hayatımızın bir gerçeği ve bazılarımız borçları altında eziliyorlar.' +
                ' Eğer borçlarınızı yönetmekte zorlanıyorsanız, henüz yatırımcı olmaya uygun değilsinizdir.' +
                ' Öncelikle borçlarınızı yönetilebilir bir seviyeye getirmeye odaklanın.' +
                ' Bu sırada da borçlarınızın bu seviyeye gelmesinin nedenlerini düşünüp aynı hataya düşmemeye çalışın.<br><br>' +
                '<strong>Acil durumlar için ayırdığınız bir para var mı?</strong><br>' +
                'İşten çıkarılmanız ya da sigortanız tarafından karşılanmayan bir sağlık sorunu yaşamanız gibi acil bir durum, hayatınızdaki finansal akışı zora sokabilir.' +
                ' Bu tür durumlarla başa çıkabilmek için bir köşede bir miktar paranızın bulunması iyi bir fikir.' +
                ' Böyle bir paranız yoksa yatırım yapmayı bir süre ertelemelisiniz.' +
                '<div class="job-advertisement-heading">Yatırım Yapmak İçin İlk Adımlar</div>' +
                'Otuzlu yaşlar belki de hayat hakkında en fazla şeyi öğrendiğimiz yaşlar.' +
                ' Ekonomik özgürlüğümüzü gerçek anlamda bu yaşlarda kazanıyoruz.' +
                ' Dolayısıyla yatırım yapma fikrini de bu yaşlarda düşünmeye başlıyoruz.' +
                '<div class="job-advertisement-heading">Sadece Paranız Olduğu İçin Hayat Tarzınızı Değiştirmeyin</div>' +
                'Yaşınız ilerledikçe hayat tarzınızın değişmesi normal.' +
                ' Ancak hayat tarzınızı sadece yeni bir şeyler için paranız yetiyor diye değiştiriyorsanız, bu durumu sorgulayın.<br>' +
                'Yeni bir hobi edinmek ya da yeteneklerinizi geliştirmek için para harcıyorsanız sorun yok.' +
                ' Ancak lüks restoranlara maaşınızın dörtte birini bırakmanın ya da sosyal hayata daha yakın olmak için şehrin gözde semtlerinden birine taşınmanın gereği yok.<br>' +
                'Düzenli bir şekilde hayatınızda olan fakat eskiden yapmadığınız harcamaları değerlendirin.' +
                ' Bu harcamaların hangileri gerçekten hayatınıza bir değer katıyor?' +
                ' Hangileri “aslında çok da gerekli değil” kategorisine giriyor?' +
                ' Cevaplar sizde!' +
                '<div class="job-advertisement-heading">Küçük Miktarlarda da Olsa Biriktirin ve Birikimlerinizi Yatırıma Dönüştürün</div>' +
                'Yatırımcı olmak için çok büyük bir sermayeye ihtiyacınız yok.' +
                ' Cebinizdeki bozuklukları bir kutuya atarak bile yatırımcı olabilirsiniz.<br>' +
                'Bankaların genç yatırımcılar için birçok ürünü bulunuyor.' +
                ' Bu ürünler hakkında bilgi almak için bankanızla konuşun.<br>' +
                'Diğer bir seçenek de alternatif yatırım kanallarına yönelmek.' +
                ' Artiox, bu kanallardan biri.' +
                ' Artiox olarak diğer alternatif yatırım kanallarına oranla daha az risk içeren sanat yatırımlarını çok düşük bütçelerle bile yapmanıza olanak sağlıyoruz.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Nasıl Yatırımcı Olunur: Otuzlu Yaşlarına Yeni Girenler İçin Küçük Bir Başlangıç Rehberi</div>' +
                'Türk Dil Kurumu “yatırım” kelimesini şöyle tanımlıyor: <i>“Parayı, gelir getirici taşınır ya da taşınmaz bir mala yatırma, mevduat, plasman.”</i><br>' +
                'Yatırım, para biriktirmek değildir.' +
                ' Biriken para çoğalıyor gibi gözükse de enflasyon ve ekonomik krizler karşısında değer kaybeder.' +
                ' Ayrıca doğru bir şekilde yapılmış yatırım; bir ev satın almak ya da rahat bir emeklilik geçirmek gibi finansal hedeflerinize ulaşmanızı kolaylaştırır.' +
                '<div class="job-advertisement-heading">Yatırım Yapmaya Başlamadan Önce</div>' +
                'Yatırımcı olmak için öncelikle bazı konular üstünde düşünmeniz gerekiyor.<br><br>' +
                '<strong>Borçlarınızı yönetebiliyor musunuz?</strong><br>' +
                'Ne yazık ki borç, hayatımızın bir gerçeği ve bazılarımız borçları altında eziliyorlar.' +
                ' Eğer borçlarınızı yönetmekte zorlanıyorsanız, henüz yatırımcı olmaya uygun değilsinizdir.' +
                ' Öncelikle borçlarınızı yönetilebilir bir seviyeye getirmeye odaklanın.' +
                ' Bu sırada da borçlarınızın bu seviyeye gelmesinin nedenlerini düşünüp aynı hataya düşmemeye çalışın.<br><br>' +
                '<strong>Acil durumlar için ayırdığınız bir para var mı?</strong><br>' +
                'İşten çıkarılmanız ya da sigortanız tarafından karşılanmayan bir sağlık sorunu yaşamanız gibi acil bir durum, hayatınızdaki finansal akışı zora sokabilir.' +
                ' Bu tür durumlarla başa çıkabilmek için bir köşede bir miktar paranızın bulunması iyi bir fikir.' +
                ' Böyle bir paranız yoksa yatırım yapmayı bir süre ertelemelisiniz.' +
                '<div class="job-advertisement-heading">Yatırım Yapmak İçin İlk Adımlar</div>' +
                'Otuzlu yaşlar belki de hayat hakkında en fazla şeyi öğrendiğimiz yaşlar.' +
                ' Ekonomik özgürlüğümüzü gerçek anlamda bu yaşlarda kazanıyoruz.' +
                ' Dolayısıyla yatırım yapma fikrini de bu yaşlarda düşünmeye başlıyoruz.' +
                '<div class="job-advertisement-heading">Sadece Paranız Olduğu İçin Hayat Tarzınızı Değiştirmeyin</div>' +
                'Yaşınız ilerledikçe hayat tarzınızın değişmesi normal.' +
                ' Ancak hayat tarzınızı sadece yeni bir şeyler için paranız yetiyor diye değiştiriyorsanız, bu durumu sorgulayın.<br>' +
                'Yeni bir hobi edinmek ya da yeteneklerinizi geliştirmek için para harcıyorsanız sorun yok.' +
                ' Ancak lüks restoranlara maaşınızın dörtte birini bırakmanın ya da sosyal hayata daha yakın olmak için şehrin gözde semtlerinden birine taşınmanın gereği yok.<br>' +
                'Düzenli bir şekilde hayatınızda olan fakat eskiden yapmadığınız harcamaları değerlendirin.' +
                ' Bu harcamaların hangileri gerçekten hayatınıza bir değer katıyor?' +
                ' Hangileri “aslında çok da gerekli değil” kategorisine giriyor?' +
                ' Cevaplar sizde!' +
                '<div class="job-advertisement-heading">Küçük Miktarlarda da Olsa Biriktirin ve Birikimlerinizi Yatırıma Dönüştürün</div>' +
                'Yatırımcı olmak için çok büyük bir sermayeye ihtiyacınız yok.' +
                ' Cebinizdeki bozuklukları bir kutuya atarak bile yatırımcı olabilirsiniz.<br>' +
                'Bankaların genç yatırımcılar için birçok ürünü bulunuyor.' +
                ' Bu ürünler hakkında bilgi almak için bankanızla konuşun.<br>' +
                'Diğer bir seçenek de alternatif yatırım kanallarına yönelmek.' +
                ' Artiox, bu kanallardan biri.' +
                ' Artiox olarak diğer alternatif yatırım kanallarına oranla daha az risk içeren sanat yatırımlarını çok düşük bütçelerle bile yapmanıza olanak sağlıyoruz.',
            ROUTER_LINK: {
                TEXT: 'Eserleri İncele',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Sanat Eseri Nasıl NFT’ye Dönüştürülür?',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Fiziksel Sanat Eseri Nasıl NFT’ye Dönüştürülür?</div>' +
                'Son zamanlarda NFT’lerin popüler hale gelmesiyle birlikte geleneksel kanallardan satış yapmakta zorlanan' +
                ' ya da yeni bir şeyler denemek isteyen sanatçıların bu alana ilgisi arttı.' +
                ' Özellikle sanat pazarı alanında kendine yer bulamayan dijital sanatçılar, hak ettikleri ilgiyi NFT sayesinde görmeye başladı.<br>' +
                'Ancak NFT kavramı, sadece dijital sanat eserleri için geçerli olan bir kavram değil.' +
                ' Fiziksel bir sanat eserini de NFT’ye dönüştürmek mümkün.' +
                ' Doğal olarak bu süreç, bir dijital sanat eserini NFT’ye dönüştürmekten biraz daha karmaşık ve radikal kararların alınması gereken bir süreç.' +
                '<div class="job-advertisement-heading">İlk Adım: Dijital Hale Getirme</div>' +
                'Bir eserin NFT’ye dönüşebilmesi için öncelikle eserin dijital hale getirilmesi gerekiyor.' +
                ' Bir fiziksel sanat eserini dijital hale getirmenin yöntemi ise eseri fotoğraf, video ya da herhangi bir dijital formata dönüştürmek.<br>' +
                '“Dönüştürme” kelimesinin altını çizmemiz lazım.' +
                ' NFT mantığında fiziksel sanat eserinin, dijital sanat eserini üretmek için kullanılan bir araç olması gerekiyor.' +
                ' Yani ortaya çıkan sonuç, bir eserin fotoğrafı ya da videosu değil, eserin ta kendisi olması gerek.' +
                '<div class="job-advertisement-heading">Temel Değer: Benzersizlik</div>' +
                'Bu algıyı sağlamlaştırmak için birkaç yöntem var.' +
                ' Bu yöntemlerden en sağlam olanı, ama aynı zamanda en radikal olanı fiziksel sanat eserini dijitalleştirme sürecinden sonra yok etmek.' +
                ' Bu sayede eserin tek kopyası dijital oluyor.' +
                ' Bir eserin değerini belirleyen en önemli değişkenlerden birinin eserin benzersizliği olduğunu düşündüğümüzde bu yöntemi izlemenin oldukça mantıklı olduğu sonucuna varıyoruz.' +
                ' Ancak hiçbir sanatçı için elleriyle ürettiği fiziksel eserin yok edilmesi kolay değil.<br>' +
                'Diğer bir yöntem ise fiziksel sanat eserini dijital sanat eseriyle birlikte satışa sunmak.' +
                ' Bu yöntemi izleyen sanatçılar eserin bir parçasını eserin NFT’sini alan kişiye veriyor.' +
                ' Böylelikle NFT’nin kendisi de fiziksel hale geliyor.' +
                ' Benzersizlik özelliği bu yöntemle korunsa da fiziksel NFT’lerin alım satımı pratik olmadığından ve sanat eseri fiziksel bütünlüğünü yine kaybettiğinden dolayı bu yöntem,' +
                ' deneysel olmanın pek ötesine geçemiyor.<br>' +
                'Son yöntem ise eserin dijital ve fiziksel versiyonlarının ayrı ayrı satılması.' +
                ' Bu yöntemde sanatçılar, eserin fiziksel versiyonu asla satmayacaklarına dair garanti vererek dijital versiyonun benzersizliğini yaratmaya çalışıyorlar.' +
                ' Ancak bu algının oluşması tamamen sanatçının kredibilitesine bağlı.' +
                '<div class="job-advertisement-heading">Artiox’ta Alternatif Bir Yöntem Sunuyoruz</div>' +
                'Saydığımız alternatifler dışında fiziksel bir sanat eserini NFT’ye dönüştürmenin yöntemleri var.' +
                ' Ancak bu yöntemlerin neredeyse tamamı sanat eserinin fiziksel bütünlüğüne zarar veriyor ya da ticari potansiyeline ulaşamamasını sağlıyor.' +
                ' Artiox olarak ise sanat eserini olduğu gibi bırakıp sadece bu eserin yarattığı ekonomik değeri dijital hale getiriyoruz.' +
                ' Eserleri güvenli koşullarda saklıyor ve bu sayede eserlerin fiziksel bütünlüğüne zarar gelmemesini sağlıyoruz.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Fiziksel Sanat Eseri Nasıl NFT’ye Dönüştürülür?</div>' +
                'Son zamanlarda NFT’lerin popüler hale gelmesiyle birlikte geleneksel kanallardan satış yapmakta zorlanan' +
                ' ya da yeni bir şeyler denemek isteyen sanatçıların bu alana ilgisi arttı.' +
                ' Özellikle sanat pazarı alanında kendine yer bulamayan dijital sanatçılar, hak ettikleri ilgiyi NFT sayesinde görmeye başladı.<br>' +
                'Ancak NFT kavramı, sadece dijital sanat eserleri için geçerli olan bir kavram değil.' +
                ' Fiziksel bir sanat eserini de NFT’ye dönüştürmek mümkün.' +
                ' Doğal olarak bu süreç, bir dijital sanat eserini NFT’ye dönüştürmekten biraz daha karmaşık ve radikal kararların alınması gereken bir süreç.' +
                '<div class="job-advertisement-heading">İlk Adım: Dijital Hale Getirme</div>' +
                'Bir eserin NFT’ye dönüşebilmesi için öncelikle eserin dijital hale getirilmesi gerekiyor.' +
                ' Bir fiziksel sanat eserini dijital hale getirmenin yöntemi ise eseri fotoğraf, video ya da herhangi bir dijital formata dönüştürmek.<br>' +
                '“Dönüştürme” kelimesinin altını çizmemiz lazım.' +
                ' NFT mantığında fiziksel sanat eserinin, dijital sanat eserini üretmek için kullanılan bir araç olması gerekiyor.' +
                ' Yani ortaya çıkan sonuç, bir eserin fotoğrafı ya da videosu değil, eserin ta kendisi olması gerek.' +
                '<div class="job-advertisement-heading">Temel Değer: Benzersizlik</div>' +
                'Bu algıyı sağlamlaştırmak için birkaç yöntem var.' +
                ' Bu yöntemlerden en sağlam olanı, ama aynı zamanda en radikal olanı fiziksel sanat eserini dijitalleştirme sürecinden sonra yok etmek.' +
                ' Bu sayede eserin tek kopyası dijital oluyor.' +
                ' Bir eserin değerini belirleyen en önemli değişkenlerden birinin eserin benzersizliği olduğunu düşündüğümüzde bu yöntemi izlemenin oldukça mantıklı olduğu sonucuna varıyoruz.' +
                ' Ancak hiçbir sanatçı için elleriyle ürettiği fiziksel eserin yok edilmesi kolay değil.<br>' +
                'Diğer bir yöntem ise fiziksel sanat eserini dijital sanat eseriyle birlikte satışa sunmak.' +
                ' Bu yöntemi izleyen sanatçılar eserin bir parçasını eserin NFT’sini alan kişiye veriyor.' +
                ' Böylelikle NFT’nin kendisi de fiziksel hale geliyor.' +
                ' Benzersizlik özelliği bu yöntemle korunsa da fiziksel NFT’lerin alım satımı pratik olmadığından ve sanat eseri fiziksel bütünlüğünü yine kaybettiğinden dolayı bu yöntem,' +
                ' deneysel olmanın pek ötesine geçemiyor.<br>' +
                'Son yöntem ise eserin dijital ve fiziksel versiyonlarının ayrı ayrı satılması.' +
                ' Bu yöntemde sanatçılar, eserin fiziksel versiyonu asla satmayacaklarına dair garanti vererek dijital versiyonun benzersizliğini yaratmaya çalışıyorlar.' +
                ' Ancak bu algının oluşması tamamen sanatçının kredibilitesine bağlı.' +
                '<div class="job-advertisement-heading">Artiox’ta Alternatif Bir Yöntem Sunuyoruz</div>' +
                'Saydığımız alternatifler dışında fiziksel bir sanat eserini NFT’ye dönüştürmenin yöntemleri var.' +
                ' Ancak bu yöntemlerin neredeyse tamamı sanat eserinin fiziksel bütünlüğüne zarar veriyor ya da ticari potansiyeline ulaşamamasını sağlıyor.' +
                ' Artiox olarak ise sanat eserini olduğu gibi bırakıp sadece bu eserin yarattığı ekonomik değeri dijital hale getiriyoruz.' +
                ' Eserleri güvenli koşullarda saklıyor ve bu sayede eserlerin fiziksel bütünlüğüne zarar gelmemesini sağlıyoruz.',
            ROUTER_LINK: {
                TEXT: 'Eserleri İncele',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Nedir Bu Blockchain?',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Nedir Bu Blockchain?</div>' +
                'Bitcoin’in geleceği hakkında şüpheleriniz olabilir.' +
                ' Bitcoin’e alternatif tüm coin’lerin geleceği hakkında daha da büyük şüpheleriniz olabilir.' +
                ' Son zamanlarda popülerleşen NFT’lerin birer balon olduğunu düşünebilirsiniz.' +
                ' Bu düşüncelerinizin hepsi doğal.' +
                ' Tüm bu teknolojik gelişmelerin nasıl ve ne ölçüde bir değişim yaratacağı konusunda kesin bir veri yok.<br>' +
                'Kesin olan tek şey şu; tüm bu saydıklarımızın ardındaki blockchain teknolojisinin geleceğimiz olduğu.' +
                '<div class="job-advertisement-heading">Blockchain Kavramını Tanımlayalım</div>' +
                'Blockchain (Bundan sonra bu kavramdan “blok zinciri” olarak bahsedelim) özünde bir bilgisayar ağı boyunca çoğaltılan ve dağıtılan işlemlerin dijital bir defteridir.' +
                ' Zincirdeki her blok bir dizi işlem içerir ve blok zincirinde her yeni işlem gerçekleştiğinde, her katılımcının defterine bu işlemin bir kaydı eklenir.' +
                '<div class="job-advertisement-heading">Blok Zinciri Ne İşe Yarıyor?</div>' +
                'Blok zincirlerini geleceğin teknolojisi olarak tanımlamamızın temel nedeni, bu sistemlerin geleneksel sistemlere göre çok daha güvenli olmaları.<br>' +
                'Merkezi yönetim sistemine sahip bir bankayı ele alalım.' +
                ' Bu bankanın veri yönetim merkezine yapılacak olan bir saldırı banka müşterilerinin hesaplarının tamamen boşaltılmasına neden olabilir.' +
                ' Bankalar böyle bir durumun gerçekleşmemesi için birden fazla katmanı olan güvenlik sistemleriyle önlem alırlar.' +
                ' Ancak teorik olarak bu sistemlerin tamamının aynı anda saldırıya uğraması mümkün.' +
                ' Özellikle banka içinden korsanlara destek veren bir personel varsa...<br>' +
                'Blok zinciri teknolojisinde ise böyle saldırının başarıya uğraması imkânsız.' +
                ' Veri, merkezi bir konumda barındırılmadığından saldırının başarıya ulaşması için sistemdeki farklı lokasyonlardaki binlerce bilgisayara aynı anda saldırı düzenlenmesi gerekir.' +
                ' Blok zincirin bir diğer önemli özelliği ise programlanabilir olması.' +
                ' Akıllı kontratlar gibi bir dizi fonksiyonun programlanmasıyla blok zincir, farklı alanlarda kullanılabiliyor.<br>' +
                'Günümüzde özellikle ticari ilişkilerin yönetilmesini sağlayan bilişim altyapıları,' +
                ' altyapının sahibi olan ve bu altyapıları denetleyen kurumlara olan güven sayesinde ayakta kalabiliyor.' +
                ' Blok zinciri teknolojisinde ise sistemin kendisi bir güven unsuru.' +
                ' Ancak bu teknolojinin henüz oldukça yeni olduğunu ve günlük hayatta yaygın bir şekilde kullanılmasına engel olan sorunların hâlâ var olduğunun da altını çizmemiz gerekiyor.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Nedir Bu Blockchain?</div>' +
                'Bitcoin’in geleceği hakkında şüpheleriniz olabilir.' +
                ' Bitcoin’e alternatif tüm coin’lerin geleceği hakkında daha da büyük şüpheleriniz olabilir.' +
                ' Son zamanlarda popülerleşen NFT’lerin birer balon olduğunu düşünebilirsiniz.' +
                ' Bu düşüncelerinizin hepsi doğal.' +
                ' Tüm bu teknolojik gelişmelerin nasıl ve ne ölçüde bir değişim yaratacağı konusunda kesin bir veri yok.<br>' +
                'Kesin olan tek şey şu; tüm bu saydıklarımızın ardındaki blockchain teknolojisinin geleceğimiz olduğu.' +
                '<div class="job-advertisement-heading">Blockchain Kavramını Tanımlayalım</div>' +
                'Blockchain (Bundan sonra bu kavramdan “blok zinciri” olarak bahsedelim) özünde bir bilgisayar ağı boyunca çoğaltılan ve dağıtılan işlemlerin dijital bir defteridir.' +
                ' Zincirdeki her blok bir dizi işlem içerir ve blok zincirinde her yeni işlem gerçekleştiğinde, her katılımcının defterine bu işlemin bir kaydı eklenir.' +
                '<div class="job-advertisement-heading">Blok Zinciri Ne İşe Yarıyor?</div>' +
                'Blok zincirlerini geleceğin teknolojisi olarak tanımlamamızın temel nedeni, bu sistemlerin geleneksel sistemlere göre çok daha güvenli olmaları.<br>' +
                'Merkezi yönetim sistemine sahip bir bankayı ele alalım.' +
                ' Bu bankanın veri yönetim merkezine yapılacak olan bir saldırı banka müşterilerinin hesaplarının tamamen boşaltılmasına neden olabilir.' +
                ' Bankalar böyle bir durumun gerçekleşmemesi için birden fazla katmanı olan güvenlik sistemleriyle önlem alırlar.' +
                ' Ancak teorik olarak bu sistemlerin tamamının aynı anda saldırıya uğraması mümkün.' +
                ' Özellikle banka içinden korsanlara destek veren bir personel varsa...<br>' +
                'Blok zinciri teknolojisinde ise böyle saldırının başarıya uğraması imkânsız.' +
                ' Veri, merkezi bir konumda barındırılmadığından saldırının başarıya ulaşması için sistemdeki farklı lokasyonlardaki binlerce bilgisayara aynı anda saldırı düzenlenmesi gerekir.' +
                ' Blok zincirin bir diğer önemli özelliği ise programlanabilir olması.' +
                ' Akıllı kontratlar gibi bir dizi fonksiyonun programlanmasıyla blok zincir, farklı alanlarda kullanılabiliyor.<br>' +
                'Günümüzde özellikle ticari ilişkilerin yönetilmesini sağlayan bilişim altyapıları,' +
                ' altyapının sahibi olan ve bu altyapıları denetleyen kurumlara olan güven sayesinde ayakta kalabiliyor.' +
                ' Blok zinciri teknolojisinde ise sistemin kendisi bir güven unsuru.' +
                ' Ancak bu teknolojinin henüz oldukça yeni olduğunu ve günlük hayatta yaygın bir şekilde kullanılmasına engel olan sorunların hâlâ var olduğunun da altını çizmemiz gerekiyor.',
            ROUTER_LINK: {
                TEXT: 'Eserleri İncele',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Geleneksel Yatırım ve Kripto Para',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Geleneksel Yatırım Araçları ve Kripto Para Piyasaları</div>' +
                'Ekonomik durum gözetmeksizin hepimizin altına imzamızı atacağımız bir cümle var: Para kolay kazanılmıyor.<br>' +
                'Dolayısıyla kazanmak için bolca zaman ve emek harcadığımız paramızı, bizim için en iyi olan yatırım aracında değerlendirmek istiyoruz.' +
                ' Yatırım araçlarının sayısı her geçen gün artıyor ve sayı arttıkça yatırım ortamı gitgide daha kafa karıştırıcı hale geliyor.' +
                ' Son zamanlarda finans teknolojisi alanında yaşanan gelişmeler bu alana uzak olan insanların dikkatini çekse de ne yazık ki pek anlaşılabilir değil.<br>' +
                'Eğer bu insanlardan biriyseniz, küçük bir karşılaştırmalı rehberle yolunuzu aydınlatmak istiyoruz.<br>' +
                '<div class="job-advertisement-heading">Geleneksel Yatırım Araçları</div>' +
                'Banka faizleri, hisse senetleri ve döviz piyasaları gibi yatırımlar geleneksel yatırım araçlarını oluşturuyor.' +
                ' Toplumun büyük bir kısmı yatırımlarının tamamını bu yatırım araçlarına yapıyor.' +
                ' Bu yatırım araçlarının düşük riskli olması genel kitle için popüler olmasını sağlıyor.<br>' +
                'Ancak yatırım kavramının temel prensiplerinden biri, bir yatırım aracında kaybetme riski ne kadar düşükse aynı şekilde kazanma oranı da o kadar düşük oluyor.' +
                ' Dolayısıyla bu yatırım enstrümanları elindeki paranın enflasyona karşı yenilmemesini isteyen ve üstüne az da olsa kazanmayı hedefleyen kitle için ideal.<br>' +
                'Ancak son yıllarda değişen global dengeler ve bu değişimin üstüne gelen COVID-19 salgını bu yatırım araçlarındaki riski artırmış durumda.' +
                ' Özellikle gelişmekte olan ülkelerin ekonomilerindeki dalgalanmalar ve son zamanlarda global ekonominin stabil bir görüntüden uzak olması' +
                ' bu yatırım araçlarının eskisi kadar güvenli bir liman olamamalarına neden oluyor.<br>' +
                '<div class="job-advertisement-heading">Kripto Para Piyasaları</div>' +
                '2010’lu yılların başlangıcında birkaç “bilgisayar meraklısı” olarak tanımlanabilecek insan tarafından ortaya çıkarılan kripto paralar' +
                ' bugün ciddi sayılabilecek bir ekonomi oluşturmuş durumda.' +
                ' Günümüzde kripto para piyasalarının toplam büyüklüğü 2.5 trilyon doların üstünde.<br>' +
                'Bu rakam, geleneksel yatırım araçlarıyla karşılaştırıldığında hâlâ küçük bir rakam.' +
                ' Bu yüzden kripto pazarının yaklaşık yarısına sahip olan Bitcoin’in değerinde bile büyük dalgalanmalar olabiliyor.<br><br>' +
                'Kısa birer liste halinde geleneksel yatırım araçlarının ve kripto paraların avantaj/dezavantajlarını sıralayalım:' +
                '<div class="job-advertisement-heading">Geleneksel Yatırım</div>' +
                '<strong>Avantajlar</strong><br>' +
                '<ul>' +
                '  <li>Düşük risk</li>' +
                '  <li>Stabil getiri</li>' +
                '  <li>Devlet güvencesi</li>' +
                '</ul>' +
                '<strong>Dezavantajlar</strong><br>' +
                '<ul>' +
                '  <li>Düşük getiri</li>' +
                '  <li>Son zamanlarda yaşanan krizler nedeniyle yaşanan dalgalanmalar</li>' +
                '</ul>' +
                '<div class="job-advertisement-heading">Kripto Paralar</div>' +
                '<strong>Avantajlar</strong><br>' +
                '<ul>' +
                '  <li>Yüksek getiri</li>' +
                '  <li>Merkezi olmayan yapı</li>' +
                '</ul>' +
                '<strong>Dezavantajlar</strong><br>' +
                '<ul>' +
                '  <li>Yüksek risk</li>' +
                '  <li>Yüksek dalgalanma</li>' +
                '  <li>Devlet ve şirket politikalarından etkilenme</li>' +
                '</ul>' +
                'Yatırım portföyünüzün yapısına almak istediğiniz riske göre karar vermelisiniz.' +
                ' Eğer risk iştahınız düşükse, portföyünüzde geleneksel yatırım araçlarına ağırlık vermeli; risk iştahınız büyükse kripto paralara yönelmelisiniz.' +
                ' Ancak bir kuralın altını tekrar çizelim: Bir yatırım aracı ne kadar yüksek miktarda kâr vadediyorsa aynı zamanda o kadar fazla kaybetme riskine de sahiptir.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Geleneksel Yatırım Araçları ve Kripto Para Piyasaları</div>' +
                'Ekonomik durum gözetmeksizin hepimizin altına imzamızı atacağımız bir cümle var: Para kolay kazanılmıyor.<br>' +
                'Dolayısıyla kazanmak için bolca zaman ve emek harcadığımız paramızı, bizim için en iyi olan yatırım aracında değerlendirmek istiyoruz.' +
                ' Yatırım araçlarının sayısı her geçen gün artıyor ve sayı arttıkça yatırım ortamı gitgide daha kafa karıştırıcı hale geliyor.' +
                ' Son zamanlarda finans teknolojisi alanında yaşanan gelişmeler bu alana uzak olan insanların dikkatini çekse de ne yazık ki pek anlaşılabilir değil.<br>' +
                'Eğer bu insanlardan biriyseniz, küçük bir karşılaştırmalı rehberle yolunuzu aydınlatmak istiyoruz.<br>' +
                '<div class="job-advertisement-heading">Geleneksel Yatırım Araçları</div>' +
                'Banka faizleri, hisse senetleri ve döviz piyasaları gibi yatırımlar geleneksel yatırım araçlarını oluşturuyor.' +
                ' Toplumun büyük bir kısmı yatırımlarının tamamını bu yatırım araçlarına yapıyor.' +
                ' Bu yatırım araçlarının düşük riskli olması genel kitle için popüler olmasını sağlıyor.<br>' +
                'Ancak yatırım kavramının temel prensiplerinden biri, bir yatırım aracında kaybetme riski ne kadar düşükse aynı şekilde kazanma oranı da o kadar düşük oluyor.' +
                ' Dolayısıyla bu yatırım enstrümanları elindeki paranın enflasyona karşı yenilmemesini isteyen ve üstüne az da olsa kazanmayı hedefleyen kitle için ideal.<br>' +
                'Ancak son yıllarda değişen global dengeler ve bu değişimin üstüne gelen COVID-19 salgını bu yatırım araçlarındaki riski artırmış durumda.' +
                ' Özellikle gelişmekte olan ülkelerin ekonomilerindeki dalgalanmalar ve son zamanlarda global ekonominin stabil bir görüntüden uzak olması' +
                ' bu yatırım araçlarının eskisi kadar güvenli bir liman olamamalarına neden oluyor.<br>' +
                '<div class="job-advertisement-heading">Kripto Para Piyasaları</div>' +
                '2010’lu yılların başlangıcında birkaç “bilgisayar meraklısı” olarak tanımlanabilecek insan tarafından ortaya çıkarılan kripto paralar' +
                ' bugün ciddi sayılabilecek bir ekonomi oluşturmuş durumda.' +
                ' Günümüzde kripto para piyasalarının toplam büyüklüğü 2.5 trilyon doların üstünde.<br>' +
                'Bu rakam, geleneksel yatırım araçlarıyla karşılaştırıldığında hâlâ küçük bir rakam.' +
                ' Bu yüzden kripto pazarının yaklaşık yarısına sahip olan Bitcoin’in değerinde bile büyük dalgalanmalar olabiliyor.<br><br>' +
                'Kısa birer liste halinde geleneksel yatırım araçlarının ve kripto paraların avantaj/dezavantajlarını sıralayalım:' +
                '<div class="job-advertisement-heading">Geleneksel Yatırım</div>' +
                '<strong>Avantajlar</strong><br>' +
                '<ul>' +
                '  <li>Düşük risk</li>' +
                '  <li>Stabil getiri</li>' +
                '  <li>Devlet güvencesi</li>' +
                '</ul>' +
                '<strong>Dezavantajlar</strong><br>' +
                '<ul>' +
                '  <li>Düşük getiri</li>' +
                '  <li>Son zamanlarda yaşanan krizler nedeniyle yaşanan dalgalanmalar</li>' +
                '</ul>' +
                '<div class="job-advertisement-heading">Kripto Paralar</div>' +
                '<strong>Avantajlar</strong><br>' +
                '<ul>' +
                '  <li>Yüksek getiri</li>' +
                '  <li>Merkezi olmayan yapı</li>' +
                '</ul>' +
                '<strong>Dezavantajlar</strong><br>' +
                '<ul>' +
                '  <li>Yüksek risk</li>' +
                '  <li>Yüksek dalgalanma</li>' +
                '  <li>Devlet ve şirket politikalarından etkilenme</li>' +
                '</ul>' +
                'Yatırım portföyünüzün yapısına almak istediğiniz riske göre karar vermelisiniz.' +
                ' Eğer risk iştahınız düşükse, portföyünüzde geleneksel yatırım araçlarına ağırlık vermeli; risk iştahınız büyükse kripto paralara yönelmelisiniz.' +
                ' Ancak bir kuralın altını tekrar çizelim: Bir yatırım aracı ne kadar yüksek miktarda kâr vadediyorsa aynı zamanda o kadar fazla kaybetme riskine de sahiptir.',
            ROUTER_LINK: {
                TEXT: 'Eserleri İncele',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Artiox’un Kısa Hikâyesi',
            IMAGE_SRC: './assets/announcements/operations/corporate.svg#corporate',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Artiox’un Kısa Hikâyesi</div>' +
                'Kabul etmek gerekirse sanat ve teknolojinin yan yana durması pek de alışık olduğumuz bir durum değil.' +
                ' Özellikle Türkiye’de. Artiox olarak bu iki kavramın bir arada olabileceğine inandık ve yola çıktık.<br><br>' +
                '<strong>İlk Zamanlar</strong><br>' +
                'Artiox, ODTÜ’lüler tarafından ODTÜ’de kuruldu. Kurucu ortaklarımızdan Tuncay Dakdevir, 2017 başlarında blockchain teknolojisine ilgi duymaya başladı.' +
                ' Tuncay, bu teknolojinin kullanımının Bitcoin’le sınırlı kalmayacağını, blok zinciri teknolojisinin kullanım alanlarının çok daha geniş olacağına inanıyordu.<br>' +
                '2019 yılında blok zinciri üstünde smart contract’ların çalıştığının kanıtlanması ardından Tuncay, bu öngörüsünün isabetli olduğunu gördü.<br>' +
                'Artiox kurucu ortaklarından Cemal Doğan’ın sanata olan ilgisi de Artiox’un çalışma alanının belirlenmesini sağladı.' +
                ' Sanat ekonomisinin ulaştığı hacmin farkında olan Cemal Doğan bir süredir sanat ekonomisi alanında bir girişime başlamak istiyordu.' +
                ' Ancak diğerlerinden farklılaşacak bir girişim fikri bulmakta güçlük çekiyordu.<br>' +
                'Tuncay Dakdevir ve Cemal Doğan’ın yolları, geleneksel çalışma alanlarının dijitalleşmesi konulu bir toplantıda kesişti.' +
                ' Cemal Doğan toplantı sırasında sanat ekonomisi hakkında konuşurken Tuncay’ın sorduğu bir soru Artiox fikrinin ortaya çıkmasını sağladı:' +
                ' “Bir tablonun sadece küçük bir parçasını satın alabiliyor muyum?”<br>' +
                'Böylelikle sanat yatırımcılığını blok zincir üstündeki akıllı sözleşmeler aracılığıyla güvenli bir şekilde geniş kitlelere ulaştıracak Artiox’un ilk adımları atılmış oldu.<br><br>' +
                '<strong>İlk Yatırım</strong><br>' +
                'Mayıs 2019’da Ankara’lı iş insanları Mehmet Toprak ve Murat Aksöz’ün yapmış olduğu tohum öncesi yatırım ile Ankara’da kurulan Artiox,' +
                ' 2020 yılında ODTÜ Teknokent firması olarak faaliyetlerini bu bölgede sürdürmeye başladı.' +
                ' Ardından web sitesinin de yayına alınmasıyla dikkat çekmeye başlayan Artiox,' +
                ' Nisan 2021’de Keiretsu Forum Türkiye yatırımcılarından olan Alesta ve Sertaç Özinal’dan 7 Milyon TL değerleme ile yatırım aldı.' +
                ' Kısa süre içinde altyapı kodlaması daha da geliştirildi ve bir süre sonra da Artiox’un akıllı telefon uygulamaları devreye girdi.<br><br>' +
                '<strong>Yatırımlar Devam Ediyor</strong><br>' +
                'Artiox, ikinci yatırım turunda ise İdeal Finansal Teknolojiler’den 2.5 milyon dolar değerleme ile yatırım aldı.' +
                ' Böylelikle sanat kavramı ile teknolojinin yan yana durduğu bir iş modelinin Türkiye’den de çıkabileceği kanıtlanmış oldu.<br>' +
                'Artiox sahip olduğu sağlam teknolojik ve finansal altyapısıyla sanat yatırımcılığı kavramını geniş kitlelere ulaştırmayı hedefliyor.<br>',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Artiox’un Kısa Hikâyesi</div>' +
                'Kabul etmek gerekirse sanat ve teknolojinin yan yana durması pek de alışık olduğumuz bir durum değil.' +
                ' Özellikle Türkiye’de. Artiox olarak bu iki kavramın bir arada olabileceğine inandık ve yola çıktık.<br><br>' +
                '<strong>İlk Zamanlar</strong><br>' +
                'Artiox, ODTÜ’lüler tarafından ODTÜ’de kuruldu. Kurucu ortaklarımızdan Tuncay Dakdevir, 2017 başlarında blockchain teknolojisine ilgi duymaya başladı.' +
                ' Tuncay, bu teknolojinin kullanımının Bitcoin’le sınırlı kalmayacağını, blok zinciri teknolojisinin kullanım alanlarının çok daha geniş olacağına inanıyordu.<br>' +
                '2019 yılında blok zinciri üstünde smart contract’ların çalıştığının kanıtlanması ardından Tuncay, bu öngörüsünün isabetli olduğunu gördü.<br>' +
                'Artiox kurucu ortaklarından Cemal Doğan’ın sanata olan ilgisi de Artiox’un çalışma alanının belirlenmesini sağladı.' +
                ' Sanat ekonomisinin ulaştığı hacmin farkında olan Cemal Doğan bir süredir sanat ekonomisi alanında bir girişime başlamak istiyordu.' +
                ' Ancak diğerlerinden farklılaşacak bir girişim fikri bulmakta güçlük çekiyordu.<br>' +
                'Tuncay Dakdevir ve Cemal Doğan’ın yolları, geleneksel çalışma alanlarının dijitalleşmesi konulu bir toplantıda kesişti.' +
                ' Cemal Doğan toplantı sırasında sanat ekonomisi hakkında konuşurken Tuncay’ın sorduğu bir soru Artiox fikrinin ortaya çıkmasını sağladı:' +
                ' “Bir tablonun sadece küçük bir parçasını satın alabiliyor muyum?”<br>' +
                'Böylelikle sanat yatırımcılığını blok zincir üstündeki akıllı sözleşmeler aracılığıyla güvenli bir şekilde geniş kitlelere ulaştıracak Artiox’un ilk adımları atılmış oldu.<br><br>' +
                '<strong>İlk Yatırım</strong><br>' +
                'Mayıs 2019’da Ankara’lı iş insanları Mehmet Toprak ve Murat Aksöz’ün yapmış olduğu tohum öncesi yatırım ile Ankara’da kurulan Artiox,' +
                ' 2020 yılında ODTÜ Teknokent firması olarak faaliyetlerini bu bölgede sürdürmeye başladı.' +
                ' Ardından web sitesinin de yayına alınmasıyla dikkat çekmeye başlayan Artiox,' +
                ' Nisan 2021’de Keiretsu Forum Türkiye yatırımcılarından olan Alesta ve Sertaç Özinal’dan 7 Milyon TL değerleme ile yatırım aldı.' +
                ' Kısa süre içinde altyapı kodlaması daha da geliştirildi ve bir süre sonra da Artiox’un akıllı telefon uygulamaları devreye girdi.<br><br>' +
                '<strong>Yatırımlar Devam Ediyor</strong><br>' +
                'Artiox, ikinci yatırım turunda ise İdeal Finansal Teknolojiler’den 2.5 milyon dolar değerleme ile yatırım aldı.' +
                ' Böylelikle sanat kavramı ile teknolojinin yan yana durduğu bir iş modelinin Türkiye’den de çıkabileceği kanıtlanmış oldu.<br>' +
                'Artiox sahip olduğu sağlam teknolojik ve finansal altyapısıyla sanat yatırımcılığı kavramını geniş kitlelere ulaştırmayı hedefliyor.<br>',
            ROUTER_LINK: {
                TEXT: 'Eserleri İncele',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Geleneksel Sanat Yatırımı ile Farkımız',
            IMAGE_SRC: './assets/announcements/operations/corporate.svg#corporate',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Artiox Modeli ile Geleneksel Sanat Yatırımları Arasındaki Fark Nedir?</div>' +
                'Artiox; sermaye büyüklüğü gözetmeksizin herkesin sanat yatırımcısı olabilmesini amaçlayan yeni nesil bir sanat yatırım platformdur.' +
                ' Artiox’un bu amaca ulaşmak için geleneksel sanat piyasalarının çalışma prensiplerinden farklı bir modeli vardır.<br>' +
                '<strong>Sahiplik:</strong> Artiox sanat eseri sahipliğini yeniden yorumlar.' +
                ' Geleneksel sanat yatırımlarında sanat eserine ve dolayısıyla söz konusu eserin ticari getirisine kişiler,' +
                ' kurumlar ya da ortaklıklar sahip olurken Artiox’ta eserin ticari getirisinin sahibi eserin tokenlerini elinde bulunduran Artiox kullanıcılarıdır.' +
                ' Bu sayede Artiox, sanat yatırımı yapmak için yüksek bir bütçeye olan ihtiyacı ortadan kaldırır.' +
                ' Artiox sayesinde küçük bütçelere sahip olan yatırımcılar bile sanat alanına yatırım yapabilirler.<br>' +
                '<strong>Hız:</strong> Geleneksel pazarlarda sanat eserlerinden ticari gelir elde edilmesi karmaşık ve dolayısıyla yavaş bir süreçtir.' +
                ' Bu değerin yaratılması için birçok tarafın birlikte çalışması gerekir.' +
                ' Artiox ise kripto para piyasaları gibi hızlı bir yapıya sahiptir.' +
                ' Bu sayede Artiox yatırımcıları diledikleri zaman alım – satım yapabilir; uzun ya da kısa vadeli stratejiler izleyebilirler.<br>' +
                '<strong>Değerleme:</strong> Geleneksel piyasalarda eserlerin ticari değeri az sayıda koleksiyoner ve sanat otoritesi tarafından belirlenir.' +
                ' Artiox’ta ise eserlerin ticari değerleri ilk olarak eksperlerin biçtiği ve sigortalanan fiyatı üzerinden satışa sunulur' +
                ' ve ardından Artiox kullanıcıları tarafından arz ve talebe göre belirlenir.<br>' +
                '<strong>Koruma:</strong> Bir eserin ticari değerinin korunması için eserin güvenliğinin sağlanması ve fiziksel kondisyonunun zarar görmemesi oldukça önemlidir.' +
                ' Geleneksel sanat yatırımlarında sanat eseri sahipleri, sahibi oldukları eserleri uygun koşullarda saklamak zorundadırlar.' +
                ' Artiox ise sistemindeki eserleri eksperler tarafından belirlenen değerlerle sigortalayarak, sanat eserlerini saklamak için tasarlanan depolarda saklar.' +
                ' Artiox kullanıcıların eserlerin güvenliği ve fiziksel durumunun korunması konusunda hiçbir ekstra maliyete katlanmasına gerek yoktur.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Artiox Modeli ile Geleneksel Sanat Yatırımları Arasındaki Fark Nedir?</div>' +
                'Artiox; sermaye büyüklüğü gözetmeksizin herkesin sanat yatırımcısı olabilmesini amaçlayan yeni nesil bir sanat yatırım platformdur.' +
                ' Artiox’un bu amaca ulaşmak için geleneksel sanat piyasalarının çalışma prensiplerinden farklı bir modeli vardır.<br>' +
                '<strong>Sahiplik:</strong> Artiox sanat eseri sahipliğini yeniden yorumlar.' +
                ' Geleneksel sanat yatırımlarında sanat eserine ve dolayısıyla söz konusu eserin ticari getirisine kişiler,' +
                ' kurumlar ya da ortaklıklar sahip olurken Artiox’ta eserin ticari getirisinin sahibi eserin tokenlerini elinde bulunduran Artiox kullanıcılarıdır.' +
                ' Bu sayede Artiox, sanat yatırımı yapmak için yüksek bir bütçeye olan ihtiyacı ortadan kaldırır.' +
                ' Artiox sayesinde küçük bütçelere sahip olan yatırımcılar bile sanat alanına yatırım yapabilirler.<br>' +
                '<strong>Hız:</strong> Geleneksel pazarlarda sanat eserlerinden ticari gelir elde edilmesi karmaşık ve dolayısıyla yavaş bir süreçtir.' +
                ' Bu değerin yaratılması için birçok tarafın birlikte çalışması gerekir.' +
                ' Artiox ise kripto para piyasaları gibi hızlı bir yapıya sahiptir.' +
                ' Bu sayede Artiox yatırımcıları diledikleri zaman alım – satım yapabilir; uzun ya da kısa vadeli stratejiler izleyebilirler.<br>' +
                '<strong>Değerleme:</strong> Geleneksel piyasalarda eserlerin ticari değeri az sayıda koleksiyoner ve sanat otoritesi tarafından belirlenir.' +
                ' Artiox’ta ise eserlerin ticari değerleri ilk olarak eksperlerin biçtiği ve sigortalanan fiyatı üzerinden satışa sunulur' +
                ' ve ardından Artiox kullanıcıları tarafından arz ve talebe göre belirlenir.<br>' +
                '<strong>Koruma:</strong> Bir eserin ticari değerinin korunması için eserin güvenliğinin sağlanması ve fiziksel kondisyonunun zarar görmemesi oldukça önemlidir.' +
                ' Geleneksel sanat yatırımlarında sanat eseri sahipleri, sahibi oldukları eserleri uygun koşullarda saklamak zorundadırlar.' +
                ' Artiox ise sistemindeki eserleri eksperler tarafından belirlenen değerlerle sigortalayarak, sanat eserlerini saklamak için tasarlanan depolarda saklar.' +
                ' Artiox kullanıcıların eserlerin güvenliği ve fiziksel durumunun korunması konusunda hiçbir ekstra maliyete katlanmasına gerek yoktur.',
            ROUTER_LINK: {
                TEXT: 'Eserleri İncele',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Artiox Nedir?',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Artiox Nedir?</div>' +
                'Artiox; değerli sanat eserlerine istediğiniz zaman, dilediğiniz miktarda yatırım yapabileceğiniz' +
                ' ve bu yatırımlarınızı kolayca yönetebileceğiniz yeni nesil sanat yatırım platformudur.<br>' +
                'Artiox; sanat yatırımcılığını, teknolojinin sunduğu imkânlar sayesinde demokratikleştirmeyi amaçlar.' +
                ' Bu sayede sanat yatırımları için ayırabileceği büyük bir sermayesi olmayan yatırımcılar da her geçen gün büyüyen sanat pazarının bir parçası haline gelebilirler.<br>' +
                '<div class="job-advertisement-heading">Neden Sanat Yatırımcılığı, Neden Artiox?</div>' +
                'Sanat eseri yatırımcılığı, alternatif yatırım araçları arasında en düşük riske sahip yatırım türlerinden biridir.' +
                ' Talebin arza oranla çok daha büyük bir hızda arttığı bu piyasa, geçmişteki ekonomik kriz dönemlerinde bile ayakta kalmayı başarmıştır.<br>' +
                'Son yıllarda geleneksel yatırım araçlarının yüksek getiriler vadetmemesi, sermaye sahiplerini sanat yatırımı yapmaya yönlendiriyor.' +
                ' Diğer bir yandan sermaye sahibi insan sayısının artması sanat eserlerine olan talebin, dolayısıyla sanat eserlerinin fiyatının artmasına neden oluyor.<br>' +
                'Artiox olarak sanat yatırımı süreçlerini finans teknolojisi alanında yaşanan gelişmelerle birlikte sadeleştirmeyi hedefliyoruz.' +
                ' Bu sayede yatırımcıların sermaye büyülüklerinden bağımsız bir şekilde kolay, hızlı ve güvenli bir şekilde sanat yatırımı yapabilmelerini sağlıyoruz.<br>' +
                '<div class="job-advertisement-heading">Artiox Nasıl Çalışır?</div>' +
                'Artiox, sanat yatırımcılığı kavramının, teknolojinin sunduğu olanaklar sayesinde geniş kitlelere ulaşmasını sağlar.' +
                ' Bu amaca ulaşmak için Artiox, sanat yatırımcılığının tüm süreçlerini sadeleştirir.<br><br>' +
                '<strong>Doğru Sanat Eserinin Seçimi</strong><br>' +
                'Bir sanat eserine yatırım yapmadan önce eserin ve eseri üreten sanatçının ticari potansiyelinin belirlenmesi önemlidir.' +
                ' Artiox alanlarında uzman eksperlerin kararları doğrultusunda seçilen sanat eserlerini portföyüne katar.<br><br>' +
                '<strong>Eserlerin Sigortalanması ve Saklanması</strong><br>' +
                'Artiox bünyesinde yer alan sanat eserlerinin güvenliğinin sağlanması ve ticari değerini kaybetmemesi için eserler,' +
                ' alanında uzman eksperler tarafından belirlenen değer üzerinden sigortalanır.' +
                ' Eserlerin fiziksel durumunun korunması için de sanat eserlerini muhafaza etmek için tasarlanmış depolarda eserler saklanır.<br>' +
                '<strong>Eserlerin Tokenize Edilmesi</strong><br><br>' +
                'Artiox bünyesinde yer alan eserlerin ticari değerleri eksperler tarafından belirlenir.' +
                ' Belirlenen ticari değer blok zincir üstünde tokenize edilir.' +
                ' Blok zincir üstündeki akıllı sözleşmeler (smart contracts) sayesinde arzı sabitlenen tokenler satışa sunulur.<br><br>' +
                '<strong>Token Fiyatlarının Belirlenmesi</strong><br>' +
                'Bir Artiox kullanıcısı olduktan sonra Artiox platformu üzerinde arz edilen eserlerin tokenlerini ön satış ve genel satış süreçlerinde sigorta fiyatından alabilirsiniz.' +
                ' Birçok sayıda sanat eserine, dilediğiniz miktarda yatırım yapmanız mümkün.' +
                ' Alım satım için listelenmiş eserlere ait tokenlerin fiyatı ise Artiox kullanıcılarının arz ve talebine göre belirlenmektedir.<br><br>' +
                '<strong>Portföy Yönetimi</strong><br>' +
                'Artiox’ta kendi belirlediğiniz stratejiye göre portföyünüzü yönetmeniz oldukça kolay.' +
                ' Artiox web sitesi ve mobil uygulamalar üzerinden kolaylıkla token alım satım emirleri verebilir, portföyünüzün anlık değerini izleyebilirsiniz.<br>',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Artiox Nedir?</div>' +
                'Artiox; değerli sanat eserlerine istediğiniz zaman, dilediğiniz miktarda yatırım yapabileceğiniz' +
                ' ve bu yatırımlarınızı kolayca yönetebileceğiniz yeni nesil sanat yatırım platformudur.<br>' +
                'Artiox; sanat yatırımcılığını, teknolojinin sunduğu imkânlar sayesinde demokratikleştirmeyi amaçlar.' +
                ' Bu sayede sanat yatırımları için ayırabileceği büyük bir sermayesi olmayan yatırımcılar da her geçen gün büyüyen sanat pazarının bir parçası haline gelebilirler.<br>' +
                '<div class="job-advertisement-heading">Neden Sanat Yatırımcılığı, Neden Artiox?</div>' +
                'Sanat eseri yatırımcılığı, alternatif yatırım araçları arasında en düşük riske sahip yatırım türlerinden biridir.' +
                ' Talebin arza oranla çok daha büyük bir hızda arttığı bu piyasa, geçmişteki ekonomik kriz dönemlerinde bile ayakta kalmayı başarmıştır.<br>' +
                'Son yıllarda geleneksel yatırım araçlarının yüksek getiriler vadetmemesi, sermaye sahiplerini sanat yatırımı yapmaya yönlendiriyor.' +
                ' Diğer bir yandan sermaye sahibi insan sayısının artması sanat eserlerine olan talebin, dolayısıyla sanat eserlerinin fiyatının artmasına neden oluyor.<br>' +
                'Artiox olarak sanat yatırımı süreçlerini finans teknolojisi alanında yaşanan gelişmelerle birlikte sadeleştirmeyi hedefliyoruz.' +
                ' Bu sayede yatırımcıların sermaye büyülüklerinden bağımsız bir şekilde kolay, hızlı ve güvenli bir şekilde sanat yatırımı yapabilmelerini sağlıyoruz.<br>' +
                '<div class="job-advertisement-heading">Artiox Nasıl Çalışır?</div>' +
                'Artiox, sanat yatırımcılığı kavramının, teknolojinin sunduğu olanaklar sayesinde geniş kitlelere ulaşmasını sağlar.' +
                ' Bu amaca ulaşmak için Artiox, sanat yatırımcılığının tüm süreçlerini sadeleştirir.<br><br>' +
                '<strong>Doğru Sanat Eserinin Seçimi</strong><br>' +
                'Bir sanat eserine yatırım yapmadan önce eserin ve eseri üreten sanatçının ticari potansiyelinin belirlenmesi önemlidir.' +
                ' Artiox alanlarında uzman eksperlerin kararları doğrultusunda seçilen sanat eserlerini portföyüne katar.<br><br>' +
                '<strong>Eserlerin Sigortalanması ve Saklanması</strong><br>' +
                'Artiox bünyesinde yer alan sanat eserlerinin güvenliğinin sağlanması ve ticari değerini kaybetmemesi için eserler,' +
                ' alanında uzman eksperler tarafından belirlenen değer üzerinden sigortalanır.' +
                ' Eserlerin fiziksel durumunun korunması için de sanat eserlerini muhafaza etmek için tasarlanmış depolarda eserler saklanır.<br><br>' +
                '<strong>Eserlerin Tokenize Edilmesi</strong><br>' +
                'Artiox bünyesinde yer alan eserlerin ticari değerleri eksperler tarafından belirlenir.' +
                ' Belirlenen ticari değer blok zincir üstünde tokenize edilir.' +
                ' Blok zincir üstündeki akıllı sözleşmeler (smart contracts) sayesinde arzı sabitlenen tokenler satışa sunulur.<br><br>' +
                '<strong>Token Fiyatlarının Belirlenmesi</strong><br>' +
                'Bir Artiox kullanıcısı olduktan sonra Artiox platformu üzerinde arz edilen eserlerin tokenlerini ön satış ve genel satış süreçlerinde sigorta fiyatından alabilirsiniz.' +
                ' Birçok sayıda sanat eserine, dilediğiniz miktarda yatırım yapmanız mümkün.' +
                ' Alım satım için listelenmiş eserlere ait tokenlerin fiyatı ise Artiox kullanıcılarının arz ve talebine göre belirlenmektedir.<br><br>' +
                '<strong>Portföy Yönetimi</strong><br>' +
                'Artiox’ta kendi belirlediğiniz stratejiye göre portföyünüzü yönetmeniz oldukça kolay.' +
                ' Artiox web sitesi ve mobil uygulamalar üzerinden kolaylıkla token alım satım emirleri verebilir, portföyünüzün anlık değerini izleyebilirsiniz.<br>',
            ROUTER_LINK: {
                TEXT: 'Eserleri İncele',
                LINK: {url: '/exchange/basic', current_component_index: 0},
                HREF: '',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 1,
            TEXT_LIMIT_MOBILE: 1,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Yatırım Turu',
            IMAGE_SRC: './assets/announcements/operations/corporate.svg#corporate',
            TEXT_MOBILE: '<div class="job-advertisement-heading">İdeal Finansal Teknolojiler ve Danışmanlık A.Ş.\'den 2.5 Milyon Dolar Değerleme</div>' +
                'Geçtiğimiz Mart ayında 7 milyon TL değerleme üzerinden aldığımız yatırım ardından kısa sürede değerimizi 3 katına yükselten yepyeni bir yatırıma imza atmış bulunmaktayız.' +
                ' İdeal Finansal Teknolojiler ve Danışmanlık A.Ş.\'den <strong>2.5 milyon dolar</strong> değerleme ile almış olduğumuz yatırıma Alesta ve Sertaç Özinal da devam yatırımında bulundular.<br><br>' +
                'Öncelikle hızlı bir şekilde ve yükselerek yeni bir yatırım aldığımız için çok mutluyuz.' +
                ' Kendini kanıtlamış kurumsal yatırımcılar tarafından değerli görülmek bizler için gurur verici.' +
                ' Yeni yatırım ile önümüzdeki süreçte ekibimizi genişleteceğiz ve platformumuzda listelenen eser sayısını hızlıca artırarak sizlere portföy oluştururken daha fazla alternatif sunacağız.',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">İdeal Finansal Teknolojiler ve Danışmanlık A.Ş.\'den 2.5 Milyon Dolar Değerleme</div>' +
                'Geçtiğimiz Mart ayında 7 milyon TL değerleme üzerinden aldığımız yatırım ardından kısa sürede değerimizi 3 katına yükselten yepyeni bir yatırıma imza atmış bulunmaktayız.' +
                ' İdeal Finansal Teknolojiler ve Danışmanlık A.Ş.\'den <strong>2.5 milyon dolar</strong> değerleme ile almış olduğumuz yatırıma Alesta ve Sertaç Özinal da devam yatırımında bulundular.<br><br>' +
                'Öncelikle hızlı bir şekilde ve yükselerek yeni bir yatırım aldığımız için çok mutluyuz.' +
                ' Kendini kanıtlamış kurumsal yatırımcılar tarafından değerli görülmek bizler için gurur verici.' +
                ' Yeni yatırım ile önümüzdeki süreçte ekibimizi genişleteceğiz ve platformumuzda listelenen eser sayısını hızlıca artırarak sizlere portföy oluştururken daha fazla alternatif sunacağız.',
            ROUTER_LINK: {
                TEXT: 'Haberi inceleyin',
                LINK: {
                    url: 'https://egirisim.com/2021/09/21/sanat-yatirim-platformu-artiox-2-5-milyon-dolar-degerleme-uzerinden-yatirim-aldi/',
                    is_inner: false,
                    target: '_blank'
                },
                HREF: 'https://egirisim.com/2021/09/21/sanat-yatirim-platformu-artiox-2-5-milyon-dolar-degerleme-uzerinden-yatirim-aldi/',
            },
            TEXT_LIMIT_DESKTOP: 852,
            TEXT_LIMIT_MOBILE: 852,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Art.Ist Sauna ile İş Birliği',
            IMAGE_SRC: './assets/announcements/operations/corporate.svg#corporate',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Art.Ist Sauna ile İş Birliği</div>' +
                'Resim, heykel, fotoğraf, video sanatı, karikatür, illüstrasyon, sokak sanatı, performans, sinema gibi ' +
                'sanatın birçok disiplinlerini ihtiva eden ve Türkiye’nin tek kat üzerindeki en büyük sanat ve etkinlik' +
                ' alanı UniqExpo’da faaliyet gösteren <a class="clarification-text-black" href="https://www.artistsauna.com?utm_source=artiox" target="_blank">Art.Ist Sauna</a> ile gerçekleştirdiğimiz iş birliğini tüm kullanıcılarımız' +
                ' ve sanatseverler ile paylaşmaktan mutluluk duyuyoruz. İş birliği kapsamında yakında detaylarını paylaşacağımız' +
                ' çok sayıda sanatçının eserinin bulunduğu bir sanat sepeti ile bu alanda bir ilke imza atarak sanat yatırımlarına' +
                ' yeni bir soluk getirmeyi hedefliyoruz. Ayrıca bu iş birliği ile birlikte Artiox’ta eserlerini listelemek isteyen' +
                ' genç ve bağımsız sanatçılar, Art.Ist Sauna’da eserlerini sergileme imkanına sahip olabilecek.<br>' +
                '<div class="job-advertisement-heading">Art.Ist Sauna Nedir?</div>' +
                'Art.Ist Sauna; resim, heykel, fotoğraf, video sanatı, karikatür, illüstrasyon, sokak sanatı, performans, sinema gibi ' +
                'sanatın birçok disiplinlerini ihtiva eden ve Türkiye’nin tek kat üzerindeki en büyük sanat ve etkinlik alanı' +
                ' UniqExpo’da kurulmuş olan bir sanat inisiyatifidir. Bu dev sergi alanının en büyük özelliği; sanatla ilgilenen' +
                ' ve merak duyan tüm ziyaretçilere 365 gün boyunca açık ve ücretsiz olmasıdır.<br>' +
                'Bu inisiyatifteki en büyük amaç; sanat izleyicisine ailesiyle ve arkadaşlarıyla bütün gününü geçirebileceği, hatta' +
                ' çalışabileceği, eğlenceli, eğitici ve rahat hissedeceği bir mekan sunmaktır.<br>' +
                'Sanat eserinin pahalı ve erişilemez olduğu algısını değiştirmek amacıyla kurulan Art.Ist Sauna; 1500 m<sup>2</sup> büyüklüğünde' +
                ' bir alan sunmasının ve genç sanatçılar ile sanatseverler arasındaki etkileşimi artırmasının yanında, erişim konusunda' +
                ' zaman ve mekan özgürlüğü sağlar.<br>' +
                'Sanat koleksiyoncusu açısından ise en önemli ayrıcalık; sanatçıları yakından ve A’dan Z’ye tanıma fırsatı elde etmesi,' +
                ' keyifli ve samimi bir satın alma deneyimine sahip olmasıdır. <br>' +
                'Art.Ist Sauna; sanata hizmet etmek amacıyla, tüm disiplinlerden genç ve bağımsız sanatçılara eserlerini özgürce sergileme' +
                ' ve kendini ifade etme fırsatını sağlayıp, daha çok sanatsevere ulaşma imkanı sunar. Böylelikle Art.Ist Sauna Türkiye’deki' +
                ' sanat pazarını da büyüterek, konseptini bir pazaryeri anlayışına çevirir.<br>' +
                '<br>' +
                'Art.Ist Sauna ile ilgili güncel gelişmelerden ve duyurusunu yapmaktan büyük mutluluk duyduğumuz bu iş birliği ile ilgili detaylardan' +
                ' haberdar olmak için bizi sosyal medya hesaplarımızdan takip etmeyi unutmayın!<br>',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Art.Ist Sauna ile İş Birliği</div>' +
                'Resim, heykel, fotoğraf, video sanatı, karikatür, illüstrasyon, sokak sanatı, performans, sinema gibi' +
                ' sanatın birçok disiplinlerini ihtiva eden ve Türkiye’nin tek kat üzerindeki en büyük sanat ve etkinlik' +
                ' alanı UniqExpo’da faaliyet gösteren <a class="clarification-text-black" href="https://www.artistsauna.com?utm_source=artiox" target="_blank">Art.Ist Sauna</a> ile gerçekleştirdiğimiz iş birliğini tüm kullanıcılarımız' +
                ' ve sanatseverler ile paylaşmaktan mutluluk duyuyoruz. İş birliği kapsamında yakında detaylarını paylaşacağımız' +
                ' çok sayıda sanatçının eserinin bulunduğu bir sanat sepeti ile bu alanda bir ilke imza atarak sanat yatırımlarına' +
                ' yeni bir soluk getirmeyi hedefliyoruz. Ayrıca bu iş birliği ile birlikte Artiox’ta eserlerini listelemek isteyen' +
                ' genç ve bağımsız sanatçılar, Art.Ist Sauna’da eserlerini sergileme imkanına sahip olabilecek.<br>' +
                '<div class="job-advertisement-heading">Art.Ist Sauna Nedir?</div>' +
                'Art.Ist Sauna; resim, heykel, fotoğraf, video sanatı, karikatür, illüstrasyon, sokak sanatı, performans, sinema gibi ' +
                'sanatın birçok disiplinlerini ihtiva eden ve Türkiye’nin tek kat üzerindeki en büyük sanat ve etkinlik alanı' +
                ' UniqExpo’da kurulmuş olan bir sanat inisiyatifidir. Bu dev sergi alanının en büyük özelliği; sanatla ilgilenen' +
                ' ve merak duyan tüm ziyaretçilere 365 gün boyunca açık ve ücretsiz olmasıdır.<br>' +
                'Bu inisiyatifteki en büyük amaç; sanat izleyicisine ailesiyle ve arkadaşlarıyla bütün gününü geçirebileceği, hatta' +
                ' çalışabileceği, eğlenceli, eğitici ve rahat hissedeceği bir mekan sunmaktır.<br>' +
                'Sanat eserinin pahalı ve erişilemez olduğu algısını değiştirmek amacıyla kurulan Art.Ist Sauna; 1500 m<sup>2</sup> büyüklüğünde' +
                ' bir alan sunmasının ve genç sanatçılar ile sanatseverler arasındaki etkileşimi artırmasının yanında, erişim konusunda' +
                ' zaman ve mekan özgürlüğü sağlar.<br>' +
                'Sanat Koleksiyoncusu açısından ise en önemli ayrıcalık; sanatçıları yakından ve A’dan Z’ye tanıma fırsatı elde etmesi,' +
                ' keyifli ve samimi bir satın alma deneyimine sahip olmasıdır. <br>' +
                'Art.Ist Sauna; sanata hizmet etmek amacıyla, tüm disiplinlerden genç ve bağımsız sanatçılara eserlerini özgürce sergileme' +
                ' ve kendini ifade etme fırsatını sağlayıp, daha çok sanatsevere ulaşma imkanı sunar. Böylelikle Art.Ist Sauna Türkiye’deki' +
                ' sanat pazarını da büyüterek, konseptini bir pazaryeri anlayışına çevirir.<br>' +
                '<br>' +
                'Art.Ist Sauna ile ilgili güncel gelişmelerden ve duyurusunu yapmaktan büyük mutluluk duyduğumuz bu iş birliği ile ilgili detaylardan' +
                ' haberdar olmak için bizi sosyal medya hesaplarımızdan takip etmeyi unutmayın!<br>',
            ROUTER_LINK: {
                TEXT: 'Art.Ist Sauna\'yı ziyaret edin',
                LINK: {url: 'https://www.artistsauna.com?utm_source=artiox', is_inner: false, target: '_blank'},
                HREF: 'https://www.artistsauna.com?utm_source=artiox',
            },
            TEXT_LIMIT_DESKTOP: 852,
            TEXT_LIMIT_MOBILE: 852,
            TOGGLE: false,
            RELEASE_DATE: 0,
        },
        {
            TOPIC: 'Türk Lirası Yatırma İşlemi',
            IMAGE_SRC: './assets/announcements/operations/tl_yatırma.svg#tl_yatırma',
            TEXT_MOBILE: '<div class="job-advertisement-heading">Türk Lirası Yatırma İşlemi</div>' +
                'Yatırma işlemine başlayabilmeniz için öncelikle hesap seviyenizi ‘Seviye 1’e yükseltmeniz gerekmektedir.' +
                ' Ana sayfanın alt bölümünde bulunan Yardım Merkezi bölümünden kimlik doğrulama işlemlerinin nasıl yapıldığını' +
                ' detaylıca inceleyebilirsiniz. Ayrıca sadece Türkiye’de hizmet veren banka hesaplarınızdan para yatırma' +
                ' işlemi gerçekleştirebilirsiniz. Para yatırma işlemine başlamak için Artiox hesabınıza girdikten sonra,' +
                ' kullanıcı paneline ardından sol bölümde bulunan listeden yatırma butonuna tıklayarak yatırma sayfasına gidiniz.' +
                ' Bu noktaya geldiğinizde para yatırmak istediğiniz bankayı seçerek ve talimatları okuduktan sonra aşağıda açılan' +
                ' hesap bilgileri ve size özel üretilmiş açıklama kodu ile para yatırma işlemlerinizi gerçekleştirebilirsiniz.' +
                ' Aşağıdaki görsellerden bu adımları daha detaylı şekilde takip edebilirsiniz.<br>' +
                '<div class="job-advertisement-heading">Türk Lirası Yatırılırken Uyulması Gereken Kurallar</div>' +
                '<ol>' +
                '  <li>Para yatırma işlemi için kullanacağınız banka hesabı şahsınıza ait olmalıdır. Aksi durumda transferiniz onaylanmayacaktır.</li>' +
                '  <li>Kredi kartı ve ATM’lerden yapılan transferler onaylanmayacaktır.</li>' +
                '  <li>Lütfen transfer yaparken alıcı unvanına Artiox Teknoloji Yazılım A.Ş. yazmayı unutmayınız.</li>' +
                '  <li>Transfer yaparken şirketimize ait IBAN numarasını doğru girdiğinizden emin olunuz.</li>' +
                '  <li>Lütfen transfer yaparken açıklama bölümüne size özel oluşturduğumuz kodu girmeyi unutmayınız. Ve bu kod dışında herhangi bir açıklama eklemeyiniz.</li>' +
                '  <p style="font-style: italic">Not: Havale ile gerçekleştirdiğiniz transferler 7/24 hesabınıza yansıtılırken, EFT yolu ile gerçekleşen transferler sadece mesai saatleri içinde hesabınıza yansıtılabilmektedir.</p>' +
                '</ol>',
            TEXT_DESKTOP: '<div class="job-advertisement-heading">Türk Lirası Yatırma İşlemi</div>' +
                'Yatırma işlemine başlayabilmeniz için öncelikle hesap seviyenizi ‘Seviye 1’e yükseltmeniz gerekmektedir.<br>' +
                ' Ana sayfanın alt bölümünde bulunan Yardım Merkezi bölümünden kimlik doğrulama işlemlerinin nasıl yapıldığını' +
                ' detaylıca inceleyebilirsiniz.<br> Ayrıca sadece Türkiye’de hizmet veren banka hesaplarınızdan para yatırma' +
                ' işlemi gerçekleştirebilirsiniz.<br> Para yatırma işlemine başlamak için Artiox hesabınıza girdikten sonra,' +
                ' kullanıcı paneline ardından sol bölümde bulunan listeden yatırma butonuna tıklayarak yatırma sayfasına gidiniz.<br>' +
                ' Bu noktaya geldiğinizde para yatırmak istediğiniz bankayı seçerek ve talimatları okuduktan sonra aşağıda açılan' +
                ' hesap bilgileri ve size özel üretilmiş açıklama kodu ile para yatırma işlemlerinizi gerçekleştirebilirsiniz.<br>' +
                ' Aşağıdaki görsellerden bu adımları daha detaylı şekilde takip edebilirsiniz.<br>' +
                '<div class="job-advertisement-heading">Türk Lirası Yatırılırken Uyulması Gereken Kurallar:</div>' +
                '<ol>' +
                '  <li>Para yatırma işlemi için kullanacağınız banka hesabı şahsınıza ait olmalıdır. Aksi durumda transferiniz onaylanmayacaktır.</li>' +
                '  <li>Kredi kartı ve ATM’lerden yapılan transferler onaylanmayacaktır.</li>' +
                '  <li>Lütfen transfer yaparken alıcı unvanına Artiox Teknoloji Yazılım A.Ş. yazmayı unutmayınız.</li>' +
                '  <li>Transfer yaparken şirketimize ait IBAN numarasını doğru girdiğinizden emin olunuz.</li>' +
                '  <li>Lütfen transfer yaparken açıklama bölümüne size özel oluşturduğumuz kodu girmeyi unutmayınız. Ve bu kod dışında herhangi bir açıklama eklemeyiniz.</li>' +
                '  <p style="font-style: italic">Not: Havale ile gerçekleştirdiğiniz transferler 7/24 hesabınıza yansıtılırken, EFT yolu ile gerçekleşen transferler sadece mesai saatleri içinde hesabınıza yansıtılabilmektedir.</p>' +
                '</ol>',
            ROUTER_LINK: {
                TEXT: 'Hemen Yatırın',
                LINK: {url: '/account/deposit', current_component_index: 0},
                HREF: '/account/deposit',
                INNER: true,
                TARGET: '_self'
            },
            TEXT_LIMIT_DESKTOP: 356,
            TEXT_LIMIT_MOBILE: 349,
            TOGGLE: false,
            RELEASE_DATE: 0,
        }
    ]
};
