import {Pipe, PipeTransform} from '@angular/core';
import {ManagerPipeArgumentTemplate, ManagerService} from './manager.service';
import {DynamicStyleService} from './dynamic-style.service';
import {Time, UtilityService} from './utility.service';
import {ActivityRecord, ArtProductWallet, CurrencyOperation, CurrencyWallet, Delivery, DeliveryAddress, UserCryptoAccount} from './user.service';
import {Pair} from './pair.service';
import {DomSanitizer} from '@angular/platform-browser';
import {Swap, Trade} from './trade-history.service';
import {AuctionCatalog, AuctionProduct} from './auction.service';
import * as moment from 'moment';
import {Bid} from './bid.service';

@Pipe({
    name: 'managerPipe',
})
export class ManagerPipe implements PipeTransform {

    constructor(public managerService: ManagerService, public dynamicStyleService: DynamicStyleService, public sanitizer: DomSanitizer) {
    }

    RENDER_MOBILE(value: any): boolean {
        return value === 'mobile';
    }

    RENDER_DESKTOP(value: any): boolean {
        return value === 'desktop';
    }

    ACTIVE_MOBILE_NAVBAR_PANEL_HOME(value: any): boolean {
        return value === this.managerService.active_mobile_navbar_panel_template.HOME;
    }

    ACTIVE_MOBILE_NAVBAR_PANEL_BASIC_TRADE(value: any): boolean {
        return value === this.managerService.active_mobile_navbar_panel_template.BASIC_TRADE;
    }

    ACTIVE_MOBILE_NAVBAR_PANEL_ADVANCED_TRADE(value: any): boolean {
        return value === this.managerService.active_mobile_navbar_panel_template.ADVANCED_TRADE;
    }

    ACTIVE_MOBILE_NAVBAR_PANEL_AUCTION(value: any): boolean {
        return value === this.managerService.active_mobile_navbar_panel_template.AUCTION;
    }

    ACTIVE_MOBILE_NAVBAR_PANEL_ACCOUNT(value: any): boolean {
        return value === this.managerService.active_mobile_navbar_panel_template.ACCOUNT;
    }

    ACTIVE_NAVIGATION_BAR_PANEL_ADMIN(value: any): boolean {
        return value === this.managerService.active_navigation_bar_panel_template.ADMIN;
    }

    ACTIVE_NAVIGATION_BAR_PANEL_DASHBOARD(value: any): boolean {
        return value === this.managerService.active_navigation_bar_panel_template.DASHBOARD;
    }

    ACTIVE_NAVIGATION_BAR_PANEL_USER_INFORMATION(value: any): boolean {
        return value === this.managerService.active_navigation_bar_panel_template.USER_INFORMATION;
    }

    ACTIVE_NAVIGATION_BAR_PANEL_BALANCE(value: any): boolean {
        return value === this.managerService.active_navigation_bar_panel_template.BALANCE;
    }

    ACTIVE_NAVIGATION_BAR_PANEL_DEPOSIT(value: any): boolean {
        return value === this.managerService.active_navigation_bar_panel_template.DEPOSIT;
    }

    ACTIVE_NAVIGATION_BAR_PANEL_WITHDRAWAL(value: any): boolean {
        return value === this.managerService.active_navigation_bar_panel_template.WITHDRAWAL;
    }

    ACTIVE_NAVIGATION_BAR_PANEL_AUCTION(value: any): boolean {
        return value === this.managerService.active_navigation_bar_panel_template.AUCTION;
    }

    ACTIVE_NAVIGATION_BAR_PANEL_SECURITY(value: any): boolean {
        return value === this.managerService.active_navigation_bar_panel_template.SECURITY;
    }

    ACTIVE_BALANCE_PANEL_SUMMARY(value: any): boolean {
        return value === this.managerService.active_balance_panel_template.SUMMARY;

    }

    ACTIVE_BALANCE_PANEL_OPERATION_HISTORY(value: any): boolean {
        return value === this.managerService.active_balance_panel_template.USER_OPERATION_HISTORY;
    }

    ACTIVE_BALANCE_PANEL_ORDER_HISTORY(value: any): boolean {
        return value === this.managerService.active_balance_panel_template.USER_TRADE_HISTORY;
    }

    ACTIVE_ACCOUNT_AUCTION_PANEL_MY_BIDS(value: any): boolean {
        return value === this.managerService.active_account_auction_panel_template.MY_BIDS;
    }

    ACTIVE_ACCOUNT_AUCTION_PANEL_MY_FOLLOWINGS(value: any): boolean {
        return value === this.managerService.active_account_auction_panel_template.MY_FOLLOWINGS;
    }

    ACTIVE_ACCOUNT_AUCTION_PANEL_MY_WONS(value: any): boolean {
        return value === this.managerService.active_account_auction_panel_template.MY_WONS;
    }

    ACTIVE_USER_INFORMATION_PANEL_PROFILE(value: any): boolean {
        return value === this.managerService.active_user_information_panel_template.PROFILE;
    }

    ACTIVE_USER_INFORMATION_PANEL_AUCTION_ADDRESS(value: any): boolean {
        return value === this.managerService.active_user_information_panel_template.AUCTION_ADDRESS;
    }

    ACTIVE_SECURITY_PANEL_CHANGE_PASSWORD(value: any): boolean {
        return value === this.managerService.active_security_panel_template.CHANGE_PASSWORD;
    }

    ACTIVE_SECURITY_PANEL_OPERATION_TWO_FACTOR_AUTHENTICATOR(value: any): boolean {
        return value === this.managerService.active_security_panel_template.TWO_FACTOR_AUTHENTICATOR;
    }

    ACTIVE_SECURITY_PANEL_IDENTITY_VERIFICATION(value: any): boolean {
        return value === this.managerService.active_security_panel_template.IDENTITY_VERIFICATION;
    }

    GET_BIRTH_YEAR_LIST(value: any): number[] {
        const max_year = moment().year() - 18;
        const year_list = new Array<number>(120);
        for (let i = 0; i < 120; i++) {
            year_list[i] = max_year - i;
        }
        return year_list;
    }


    GET_FEATURED_PAIR_LIST(value: any, args: any[]): any {
        if (this.managerService.pairService.pair_list_ready) {
            if (args.length > 1) {
                return this.managerService.pairService.getFeaturedPairList().slice(0, args[1]);
            }
            return this.managerService.pairService.getFeaturedPairList();
        }
    }

    GET_LISTED_PAIR_LIST(value: any): any {
        if (this.managerService.pairService.pair_list_ready) {
            return this.managerService.pairService.getListedPairList();
        }
    }

    GET_NOT_LISTED_PAIR_LIST(value: any): any {
        if (this.managerService.pairService.pair_list_ready) {
            return this.managerService.pairService.getFeaturedPairList().filter(x => x.sale_state !== 'listing' && x.sale_state !== 'announcement' && x.sale_state !== 'delisted');
        }
    }

    GET_SORTED_LISTED_PAIR_LIST(value: any): any {
        if (this.managerService.pairService.pair_list_ready) {
            return this.managerService.sortPairList();
        }
    }


    GET_SORTED_LISTED_CURRENCY_PAIR_LIST(value: any): any {
        if (this.managerService.pairService.currency_pair_list_ready) {
            return this.managerService.sortCurrencyPairList();
        }
    }

    ART_PRODUCT_IMAGE_DESKTOP(value: any): any {
        if (this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(value)?.symbol].IMAGE_DESKTOP_THUMBNAIL;
        }
    }

    ART_PRODUCT_IMAGE_MOBILE(value: any): any {
        if (this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(value)?.symbol].IMAGE_MOBILE_THUMBNAIL;
        }
    }

    ART_PRODUCT_IMAGE_BACKGROUND_DESKTOP(value: any): any {  // obsolete
        if (this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(value)?.symbol].IMAGE_BACKGROUND_DESKTOP;
        }
    }

    ART_PRODUCT_IMAGE_BACKGROUND_MOBILE(value: any): any {
        if (this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(value)?.symbol].IMAGE_BACKGROUND_MOBILE;
        }
    }

    ART_PRODUCT_NAME(value: any): any {
        if (this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(value)?.symbol].NAME;
        }
    }

    ART_PRODUCT_NAME_UPPERCASE(value: any): any {
        if (this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(value)?.symbol].NAME_UPPERCASE;
        }
    }

    ART_PRODUCT_ARTIST_NAME(value: any): any {
        if (this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(value)?.symbol].ARTIST_NAME;
        }
    }

    ART_PRODUCT_ARTIST_BORN_DEATH(value: any): any {
        if (this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(value)?.symbol].ARTIST_BORN_DEATH;
        }
    }

    ART_PRODUCT_SIGNATURE(value: any): any {
        if (this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(value)?.symbol].SIGNATURE;
        }
    }

    ART_PRODUCT_MEDIUM(value: any): any {
        if (this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(value)?.symbol].MEDIUM;
        }
    }

    ART_PRODUCT_SIZE(value: any): any {
        if (this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(value)?.symbol].SIZE;
        }
    }

    ART_PRODUCT_YEAR(value: any): any {
        if (this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(value)?.symbol].YEAR;
        }
    }

    ART_PRODUCT_SYMBOL(value: any): any {
        if (this.managerService.pairService.art_product_list_ready) {
            return this.managerService.pairService.getArtProductById(value)?.symbol;
        }
    }

    CURRENCY_SYMBOL(value: any): any {
        if (this.managerService.pairService.currency_list_ready) {
            return this.managerService.pairService.getCurrencyById(value)?.symbol;
        }
    }

    PAIR_VALUE(value: any): any {
        if (this.managerService.pairService.active_pair_ready) {
            // @ts-ignore
            return this.managerService.fancyNumber(value.price * this.managerService.pairService.getArtProductById(value.id[0])?.total_supply);
        }
    }

    ACTIVE_PAIR_LOW(value: any): any {
        if (this.managerService.pairService.active_pair_ready) {
            // @ts-ignore
            return this.managerService.fancyNumber(this.managerService.pairService.getActivePair()?.low);
        }
    }

    ACTIVE_PAIR_HIGH(value: any): any {
        if (this.managerService.pairService.active_pair_ready) {
            // @ts-ignore
            return this.managerService.fancyNumber(this.managerService.pairService.getActivePair()?.high);
        }
    }

    ART_PRODUCT_TOTAL_SUPPLY(value: any): any {
        if (this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.fancyNumber(this.managerService.pairService.getArtProductById(value)?.total_supply);
        }
    }

    ART_PRODUCT_CIRCULATING_SUPPLY(value: any): any {
        if (this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.fancyNumber(this.managerService.pairService.getArtProductById(value)?.circulating_supply);
        }
    }

    ART_PRODUCT_PRE_SALE_CIRCULATING_SUPPLY(value: any): any {
        if (this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.fancyNumber(this.managerService.pairService.getArtProductById(value)?.pre_sale_circulating_supply);
        }
    }

    ART_PRODUCT_PUBLIC_SALE_CIRCULATING_SUPPLY(value: any): any {
        if (this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.fancyNumber(this.managerService.pairService.getArtProductById(value)?.public_sale_circulating_supply);
        }
    }

    ACTIVE_ART_PRODUCT_NAME(): any {
        if (this.managerService.pairService.active_pair_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActivePair()?.id[0])?.symbol].NAME;
        }
    }

    ACTIVE_ART_PRODUCT_SYMBOL(): any {
        if (this.managerService.pairService.active_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.pairService.getArtProductById(this.managerService.pairService.getActivePair()?.id[0])?.symbol;
        }
    }

    GET_CURRENCY_NAME_BY_ID(value: any): string {
        // @ts-ignore
        return this.managerService.config.CURRENCY_NAMES[value] || this.managerService.pairService.currency_list[value].name || '';
    }

    GET_CURRENCY_SYMBOL_BY_ID(value: any): string {
        return this.managerService.pairService.currency_list[value].symbol ?? '';
    }

    ROOT_CURRENCY_SYMBOL(): string {
        return this.managerService.pairService.root_currency?.symbol ?? '';
    }

    ACTIVE_CURRENCY_SYMBOL(): any {
        if (this.managerService.pairService.active_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.pairService.getCurrencyById(this.managerService.pairService.getActivePair()?.id[1])?.symbol;
        }
    }

    ACTIVE_CURRENCY_NAME(): any {
        if (this.managerService.pairService.active_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.pairService.getCurrencyById(this.managerService.pairService.getActivePair()?.id[1])?.name;
        }
    }

    ACTIVE_BASE_CURRENCY_SYMBOL(): any {
        if (this.managerService.pairService.active_currency_pair_ready && this.managerService.pairService.currency_list_ready) {
            // @ts-ignore
            return this.managerService.pairService.getCurrencyById(this.managerService.pairService.getActiveCurrencyPair()?.id[0])?.symbol;
        }
    }

    ACTIVE_BASE_CURRENCY_NAME(): any {
        if (this.managerService.pairService.active_currency_pair_ready && this.managerService.pairService.currency_list_ready) {
            // @ts-ignore
            return this.managerService.pairService.getCurrencyById(this.managerService.pairService.getActiveCurrencyPair()?.id[0])?.name;
        }
    }

    ACTIVE_BASE_CURRENCY_DECIMAL(): any {
        if (this.managerService.pairService.active_currency_pair_ready && this.managerService.pairService.currency_list_ready) {
            if (this.managerService.pairService.getActiveCurrencyPair()?.id[0] === 2) {
                return 6;
            }
            return 2;
        }
        return 2;
    }

    ACTIVE_QUOTE_CURRENCY_SYMBOL(): any {
        if (this.managerService.pairService.active_currency_pair_ready && this.managerService.pairService.currency_list_ready) {
            // @ts-ignore
            return this.managerService.pairService.getCurrencyById(this.managerService.pairService.getActiveCurrencyPair()?.id[1])?.symbol;
        }
    }

    ACTIVE_QUOTE_CURRENCY_NAME(): any {
        if (this.managerService.pairService.active_currency_pair_ready && this.managerService.pairService.currency_list_ready) {
            // @ts-ignore
            return this.managerService.pairService.getCurrencyById(this.managerService.pairService.getActiveCurrencyPair()?.id[1])?.name;
        }
    }


    ACTIVE_CURRENCY_PAIR_PRICE(): number {
        if (this.managerService.pairService.currency_pair_list_ready) {
            // @ts-ignore
            return this.managerService.pairService.active_currency_pair?.price;
        }
        return 0;
    }

    CHECK_ACTIVE_PAIR(value: Pair): boolean {
        return this.managerService.pairService.checkActivePair(value);
    }

    CHECK_ACTIVE_FEATURED_PAIR(value: Pair): boolean {
        return this.managerService.pairService.checkActiveFeaturedPair(value);
    }

    ACTIVE_PAIR_SYMBOL(): any {
        if (this.managerService.pairService.active_pair_ready) {
            // @ts-ignore
            return this.managerService.pairService.getActivePair()?.symbol;
        }
    }

    ACTIVE_FEATURED_ART_PRODUCT_NAME(): any {
        if (this.managerService.pairService.active_featured_pair_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.symbol].NAME;
        }
    }

    ACTIVE_FEATURED_ART_PRODUCT_SYMBOL(): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.symbol;
        }
    }

    ACTIVE_FEATURED_CURRENCY_SYMBOL(): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.pairService.getCurrencyById(this.managerService.pairService.getActiveFeaturedPair()?.id[1])?.symbol;
        }
    }

    ACTIVE_FEATURED_PAIR_SYMBOL(): any {
        if (this.managerService.pairService.active_featured_pair_ready) {
            // @ts-ignore
            return this.managerService.pairService.getActiveFeaturedPair()?.symbol;
        }
    }

    PAIR_SYMBOL(value: any): any {
        if (this.managerService.pairService.pair_list_ready) {
            // @ts-ignore
            return this.managerService.pairService.getPairById(value)?.symbol;
        }
    }

    TRANSACTION_SYMBOL(value: any): any {
        if (this.managerService.pairService.pair_list_ready && this.managerService.pairService.currency_pair_list_ready) {
            if (value instanceof Swap) {
                // @ts-ignore
                return this.managerService.pairService.getCurrencyPairById(value.currency_pair_id)?.symbol;
            } else if (value instanceof Trade) {
                // @ts-ignore
                return this.managerService.pairService.getPairById(value.pair_id)?.symbol;
            }
        }
    }

    USER_OPEN_ORDER_TYPE(value: any): any {
        if (value === 'buy') {
            // @ts-ignore
            return this.managerService.config.ADVANCED.USER_OPEN_ORDERS.BUY;
        } else {
            // @ts-ignore
            return this.managerService.config.ADVANCED.USER_OPEN_ORDERS.SELL;
        }
    }

    USER_TRADE_TYPE(value: any): any {
        if (value === 'buy') {
            // @ts-ignore
            return this.managerService.config.ADVANCED.USER_OPEN_ORDERS.BUY;
        } else {
            // @ts-ignore
            return this.managerService.config.ADVANCED.USER_OPEN_ORDERS.SELL;
        }
    }

    ACTIVE_PAIR_PRICE(): any {
        if (this.managerService.pairService.active_pair_ready) {
            // @ts-ignore
            return this.managerService.fancyNumber(this.managerService.pairService.getActivePair()?.price);
        }
    }

    ACTIVE_PAIR_VALUE(): any {
        if (this.managerService.pairService.active_pair_ready) {
            // @ts-ignore
            return this.managerService.fancyNumber(this.managerService.pairService.getActivePair()?.price * this.managerService.pairService.getArtProductById(this.managerService.pairService.getActivePair()?.id[0])?.total_supply);
        }
    }

    ACTIVE_PAIR_VOLUME(): any {
        if (this.managerService.pairService.active_pair_ready) {
            // @ts-ignore
            return this.managerService.fancyNumber(this.managerService.pairService.getActivePair()?.volume);
        }
    }

    ACTIVE_PAIR_CHANGE(): any {
        if (this.managerService.pairService.active_pair_ready) {
            // @ts-ignore
            return this.managerService.fancyNumber(this.managerService.pairService.getActivePair()?.change);
        }
    }

    ACTIVE_ART_PRODUCT_IMAGE_DESKTOP(value: any): any {
        if (this.managerService.pairService.active_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActivePair()?.id[0])?.symbol].IMAGE_DESKTOP_THUMBNAIL[0];
        }
    }

    ACTIVE_ART_PRODUCT_IMAGE_MOBILE(value: any): any {
        if (this.managerService.pairService.active_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActivePair()?.id[0])?.symbol].IMAGE_MOBILE_THUMBNAIL[0];
        }
    }

    ACTIVE_ART_PRODUCT_ARTIST_NAME(value: any): any {
        if (this.managerService.pairService.active_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActivePair()?.id[0])?.symbol].ARTIST_NAME;
        }
    }

    ACTIVE_ART_PRODUCT_ARTIST_BORN_DEATH(value: any): any {
        if (this.managerService.pairService.active_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActivePair()?.id[0])?.symbol].ARTIST_BORN_DEATH;
        }
    }

    ACTIVE_ART_PRODUCT_ARTIST_ABOUT(value: any): any {
        if (this.managerService.pairService.active_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActivePair()?.id[0])?.symbol].ARTIST_ABOUT;
        }
    }

    ACTIVE_PAIR_INFO(value: any): any {
        if (this.managerService.pairService.active_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActivePair()?.id[0])?.symbol].INFO;
        }
    }

    ACTIVE_PAIR_ROUTER_LINK(value: any): any {
        if (this.managerService.pairService.active_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActivePair()?.id[0])?.symbol].ROUTER_LINK;
        }
    }


    ACTIVE_ART_PRODUCT_SIGNATURE(value: any): any {
        if (this.managerService.pairService.active_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActivePair()?.id[0])?.symbol].SIGNATURE;
        }
    }

    ACTIVE_ART_PRODUCT_MEDIUM(value: any): any {
        if (this.managerService.pairService.active_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActivePair()?.id[0])?.symbol].MEDIUM;
        }
    }

    ACTIVE_ART_PRODUCT_SIZE(value: any): any {
        if (this.managerService.pairService.active_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActivePair()?.id[0])?.symbol].SIZE;
        }
    }

    ACTIVE_PAIR_YEAR(value: any): any {
        if (this.managerService.pairService.active_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActivePair()?.id[0])?.symbol].YEAR;
        }
    }

    ACTIVE_PAIR_CONDITION(value: any): any {
        if (this.managerService.pairService.active_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            const star_count = this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActivePair()?.id[0])?.symbol].CONDITION;
            const star_array = [];
            for (let x = 0; x < Math.floor(star_count); x++) {
                star_array.push(1);
            }
            if (Math.ceil(star_count) > Math.floor(star_count)) {
                star_array.push(0.5);
            }
            for (let x = Math.ceil(star_count); x < 5; x++) {
                star_array.push(0);
            }
            return star_array;
        }
    }

    ACTIVE_PAIR_EXPERT_REPORT(value: any): any {
        if (this.managerService.pairService.active_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActivePair()?.id[0])?.symbol].EXPERT_REPORT;
        }
    }

    ACTIVE_PAIR_INSURANCE_REPORT(value: any): any {
        if (this.managerService.pairService.active_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActivePair()?.id[0])?.symbol].INSURANCE_REPORT;
        }
    }

    ACTIVE_PAIR_EXHIBITION_REPORT(value: any): any {
        if (this.managerService.pairService.active_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActivePair()?.id[0])?.symbol].EXHIBITION_REPORT;
        }
    }

    ACTIVE_FEATURED_PAIR_PRICE(): any {
        if (this.managerService.pairService.active_featured_pair_ready) {
            // @ts-ignore
            return this.managerService.fancyNumber(this.managerService.pairService.getActiveFeaturedPair()?.price);
        }
    }

    ACTIVE_FEATURED_PAIR_VALUE(): any {
        if (this.managerService.pairService.active_featured_pair_ready) {
            // @ts-ignore
            return this.managerService.fancyNumber(this.managerService.pairService.getActiveFeaturedPair()?.price * this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.total_supply);
        }
    }

    ACTIVE_FEATURED_PAIR_VOLUME(): any {
        if (this.managerService.pairService.active_featured_pair_ready) {
            // @ts-ignore
            return this.managerService.fancyNumber(this.managerService.pairService.getActiveFeaturedPair()?.volume);
        }
    }

    ACTIVE_FEATURED_PAIR_CHANGE(): any {
        if (this.managerService.pairService.active_featured_pair_ready) {
            // @ts-ignore
            return this.managerService.fancyNumber(this.managerService.pairService.getActiveFeaturedPair()?.change);
        }
    }

    ACTIVE_FEATURED_ART_PRODUCT_IMAGE_DESKTOP(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.symbol].IMAGE_DESKTOP_THUMBNAIL[0];
        }
    }

    ACTIVE_FEATURED_ART_PRODUCT_IMAGE_MOBILE(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.symbol].IMAGE_MOBILE_THUMBNAIL[0];
        }
    }

    ACTIVE_FEATURED_ART_PRODUCT_ARTIST_NAME(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.symbol].ARTIST_NAME;
        }
    }

    ACTIVE_FEATURED_ART_PRODUCT_ARTIST_BORN_DEATH(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.symbol].ARTIST_BORN_DEATH;
        }
    }

    ACTIVE_FEATURED_ART_PRODUCT_ARTIST_ABOUT(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.symbol].ARTIST_ABOUT;
        }
    }


    ACTIVE_FEATURED_ART_PRODUCT_ARTIST_ABOUT_UPPERCASE(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.symbol].ARTIST_ABOUT_UPPERCASE;
        }
    }


    ACTIVE_FEATURED_PAIR_INFO(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.symbol].INFO;
        }
    }

    ACTIVE_FEATURED_PAIR_ROUTER_LINK(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.symbol].ROUTER_LINK;
        }
    }

    ACTIVE_FEATURED_ART_PRODUCT_SIGNATURE(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.symbol].SIGNATURE;
        }
    }

    ACTIVE_FEATURED_ART_PRODUCT_MEDIUM(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.symbol].MEDIUM;
        }
    }

    ACTIVE_FEATURED_ART_PRODUCT_SIZE(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.symbol].SIZE;
        }
    }

    ACTIVE_FEATURED_PAIR_YEAR(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.symbol].YEAR;
        }
    }

    ACTIVE_FEATURED_PAIR_CONDITION(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            const star_count = this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.symbol].CONDITION;
            const star_array = [];
            for (let x = 0; x < Math.floor(star_count); x++) {
                star_array.push(1);
            }
            if (Math.ceil(star_count) > Math.floor(star_count)) {
                star_array.push(0.5);
            }
            for (let x = Math.ceil(star_count); x < 5; x++) {
                star_array.push(0);
            }
            return star_array;
        }
    }

    ACTIVE_FEATURED_PAIR_EXPERT_REPORT(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.symbol].EXPERT_REPORT;
        }
    }

    ACTIVE_FEATURED_PAIR_INSURANCE_REPORT(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.symbol].INSURANCE_REPORT;
        }
    }

    ACTIVE_FEATURED_PAIR_EXHIBITION_REPORT(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.config.ART_PRODUCTS[this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.symbol].EXHIBITION_REPORT;
        }
    }

    ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_TOKEN_LEFT(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.pre_sale_supply_stock;
        }
        return 0;
    }

    ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_TOKEN_LEFT(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.public_sale_supply_stock;
        }
        return 0;
    }

    ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_SUPPLY_RATIO(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.pre_sale_supply_ratio * 100;
        }
        return 0;
    }

    ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_SUPPLY_RATIO(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.public_sale_supply_ratio * 100;
        }
        return 0;
    }

    ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_CIRCULATING_SUPPLY(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.pre_sale_circulating_supply;
        }
        return 0;
    }

    ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_CIRCULATING_SUPPLY(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.public_sale_circulating_supply;
        }
        return 0;
    }

    ACTIVE_FEATURED_ART_PRODUCT_LOCKED_SUPPLY_RATIO(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return 100 - this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.circulating_supply_ratio * 100;
        }
        return 0;
    }

    ACTIVE_FEATURED_ART_PRODUCT_CIRCULATING_SUPPLY_RATIO(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.circulating_supply_ratio * 100;
        }
        return 0;
    }

    ACTIVE_FEATURED_ART_PRODUCT_CIRCULATING_SUPPLY(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.circulating_supply;
        }
        return 0;
    }

    ACTIVE_FEATURED_ART_PRODUCT_TOKEN_LEFT(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            if ((this.managerService.pairService.getActiveFeaturedPair()?.sale_state === 'featuring' && this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.pre_sale_supply_ratio !== 0) || this.managerService.pairService.getActiveFeaturedPair()?.sale_state === 'pre_sale') {
                return this.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_TOKEN_LEFT(value);
            } else {
                return this.ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_TOKEN_LEFT(value);
            }
        }
        return 0;
    }

    ACTIVE_FEATURED_ART_PRODUCT_TOTAL_SUPPLY(): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.fancyNumber(this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.total_supply);
        }
    }

    TOKEN_LEFT_TITLE(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            if ((this.managerService.pairService.getActiveFeaturedPair()?.sale_state === 'featuring' && this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.pre_sale_supply_ratio !== 0) || this.managerService.pairService.getActiveFeaturedPair()?.sale_state === 'pre_sale') {
                return this.managerService.config.PRE_SALE.PRE_SALE_TOKEN_LEFT;
            } else {
                return this.managerService.config.PRE_SALE.PUBLIC_SALE_TOKEN_LEFT;
            }
        }
    }

    LOCKED_SUPPLY_BAR(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return {width: ((100.0 - (this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.circulating_supply_ratio * 100)) * 3).toString() + 'px'};
        }
    }

    PRE_SALE_SUPPLY_BAR(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            return {
                // @ts-ignore
                'width': (this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.pre_sale_supply_ratio * 300).toString() + 'px',
                // @ts-ignore
                'background-image': 'linear-gradient(90deg, #62e8ff 0%, #7ab7ff ' + (100 - (this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.pre_sale_supply_stock / this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.pre_sale_circulating_supply) * 100) + '%, #e2e3e7 ' + (100 - (this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.pre_sale_supply_stock / this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.pre_sale_circulating_supply) * 100) + '%)'
            };
        }
    }

    PUBLIC_SALE_SUPPLY_BAR(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            if (this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.pre_sale_supply_ratio !== 0) {
                return {
                    // @ts-ignore
                    'width': (this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.public_sale_supply_ratio * 300).toString() + 'px',
                    // @ts-ignore
                    'background-image': 'linear-gradient(90deg, #62e8ff 0%, #7ab7ff ' + (100 - (this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.public_sale_supply_stock / this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.public_sale_circulating_supply) * 100) + '%, #e2e3e7 ' + (100 - (this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.public_sale_supply_stock / this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.public_sale_circulating_supply) * 100) + '%)'
                };
            } else {
                return {
                    // @ts-ignore
                    'width': (this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.public_sale_supply_ratio * 300).toString() + 'px',
                    // @ts-ignore
                    'background-image': 'linear-gradient(90deg, #62e8ff 0%, #7ab7ff ' + (100 - (this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.public_sale_supply_stock / this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.public_sale_circulating_supply) * 100) + '%, #e2e3e7 ' + (100 - (this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.public_sale_supply_stock / this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.public_sale_circulating_supply) * 100) + '%)',
                    'border-radius': '8px'
                };
            }
        }
    }

    PAIR_CHANGE_COLOR(value: any): any {
        value = parseFloat(value);
        if (value > 0) {
            return {color: '#66BB6A'};
        } else if (value === 0) {
            return {};
        } else {
            return {color: '#EA3943'};
        }
    }

    LAST_PRICE_COLOR(value: any): any {
        if (this.managerService.tradeHistoryService.trade_history_ready && this.managerService.tradeHistoryService.trade_history.length > 0) {
            if (this.managerService.tradeHistoryService.trade_history[0].type === 'buy') {
                return {color: '#2ad8a9'};
            } else {
                return {color: '#ff207d'};
            }
        }
    }

    SLIDER_BY_VW(value: any, args: any[]): any {
        const multiplier = (args[1] === undefined ? 100 : args[1]);
        return {transform: 'translateX(' + (value * -multiplier).toString() + 'vw)'};
    }

    SLIDER_BY_PIXEL(value: any, ...args: any[]): any {
        return {transform: 'translateX(' + (value * -args[0][1]).toString() + 'px)'};
    }

    SLIDER_BUTTON(value: any, args: any[]): any {
        if (value === args[1]) {
            return {
                'width': '55px',
                'height': '9px',
                'background-color': '#E2E2E2'
            };
        } else {
            return {};
        }
    }

    SALE_STATE_BUTTON(value: any): any {
        if (value === 'featuring' || value === 'pre_sale') {
            // @ts-ignore
            if (this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.pre_sale_supply_ratio !== 0) {
                return this.managerService.config.LANDING_PAGE.GREETER.PRE_SALE;
            } else {
                return this.managerService.config.LANDING_PAGE.GREETER.PUBLIC_SALE;
            }
        } else if (value === 'public_sale') {
            return this.managerService.config.LANDING_PAGE.GREETER.PUBLIC_SALE;
        } else {
            return this.managerService.config.LANDING_PAGE.GREETER.TRADE;
        }
    }

    SALE_STATE_TIME_HEADING(pair: any): any {
        const art_prod = this.managerService.pairService.getArtProductById(pair.id[0]);
        if(!art_prod){
            return '';
        }

        if (pair.sale_state === 'featuring') {
            if (art_prod.pre_sale_supply_ratio) {
                return this.managerService.config.HOME.SLIDER.PRE_SALE_STARTS_IN;
            } else {
                return this.managerService.config.HOME.SLIDER.PUBLIC_SALE_STARTS_IN;
            }
        } else if (pair.sale_state === 'pre_sale') {
            if (art_prod.pre_sale_supply_stock > 0.01) {
                return this.managerService.config.HOME.SLIDER.PRE_SALE_ENDS_IN;
            } else {
                return this.managerService.config.HOME.SLIDER.PUBLIC_SALE_STARTS_IN;
            }
        } else if (pair.sale_state === 'public_sale') {
            return this.managerService.config.HOME.SLIDER.PUBLIC_SALE_ENDS_IN;
        } else if (pair.sale_state === 'listing') {
            return this.managerService.config.HOME.SLIDER.LISTED;
        }
        return '';
    }

    ACTIVE_FEATURED_ART_PRODUCT_SALE_STATE_TIME_HEADING(value: any): any {
        return this.SALE_STATE_TIME_HEADING(this.managerService.pairService.getActiveFeaturedPair());
    }

    ACTIVE_FEATURED_ART_PRODUCT_SALE_FEATURING(value: any): any {
        return this.managerService.pairService.active_featured_pair?.sale_state === 'featuring';
    }

    ACTIVE_FEATURED_ART_PRODUCT_SALE_PRE_SALE(value: any): any {
        return this.managerService.pairService.active_featured_pair?.sale_state === 'pre_sale';
    }

    ACTIVE_FEATURED_ART_PRODUCT_SALE_PUBLIC_SALE(value: any): any {
        return this.managerService.pairService.active_featured_pair?.sale_state === 'public_sale';
    }

    ACTIVE_FEATURED_ART_PRODUCT_SALE_LISTING(value: any): any {
        return this.managerService.pairService.active_featured_pair?.sale_state === 'listing';
    }


    ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_PRICE(value: any): any {
        // @ts-ignore
        if ((this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.pre_sale_supply_ratio === 0)) {
            return '-';
        }
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.fancyNumber(this.managerService.pairService.getActiveFeaturedPair()?.pre_sale_price) + '₺';
        }
        return 0;
    }

    ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_PRICE(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.fancyNumber(this.managerService.pairService.getActiveFeaturedPair()?.public_sale_price);
        }
        return 0;
    }

    ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_DATE(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.FORMAT_EUROPEAN_ABBREVIATED_DATE_TIME(this.managerService.pairService.getActiveFeaturedPair()?.pre_sale_start_date) + '-' + this.FORMAT_EUROPEAN_ABBREVIATED_DATE_TIME(this.managerService.pairService.getActiveFeaturedPair()?.pre_sale_end_date);
        }
        return 0;
    }

    ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_DATE_INTERVAL(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.FORMAT_EUROPEAN_ABBREVIATED_DATE_TIME(this.managerService.pairService.getActiveFeaturedPair()?.pre_sale_start_date) + '-' + this.FORMAT_EUROPEAN_ABBREVIATED_DATE_TIME(this.managerService.pairService.getActiveFeaturedPair()?.pre_sale_end_date);
        }
        return 0;
    }

    ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_DATE_INTERVAL(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.FORMAT_EUROPEAN_ABBREVIATED_DATE_TIME(this.managerService.pairService.getActiveFeaturedPair()?.public_sale_start_date) + '-' + this.FORMAT_EUROPEAN_ABBREVIATED_DATE_TIME(this.managerService.pairService.getActiveFeaturedPair()?.public_sale_end_date);
        }
        return 0;
    }

    ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_DATE(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.FORMAT_EUROPEAN_ABBREVIATED_DATE_TIME(this.managerService.pairService.getActiveFeaturedPair()?.public_sale_start_date) + '-' + this.FORMAT_EUROPEAN_ABBREVIATED_DATE_TIME(this.managerService.pairService.getActiveFeaturedPair()?.public_sale_end_date);
        }
        return 0;
    }

    ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_DATE_MOBILE(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.FORMAT_EUROPEAN_ABBREVIATED_DATE_TIME(this.managerService.pairService.getActiveFeaturedPair()?.public_sale_start_date) + ' - ' + this.FORMAT_EUROPEAN_ABBREVIATED_DATE_TIME(this.managerService.pairService.getActiveFeaturedPair()?.public_sale_end_date);
        }
        return 0;
    }

    ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_START_DATE(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.FORMAT_EUROPEAN_ABBREVIATED_DATE_TIME(this.managerService.pairService.getActiveFeaturedPair()?.public_sale_start_date);
        }
        return 0;
    }

    ACTIVE_FEATURED_ART_PRODUCT_LISTING_DATE(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.FORMAT_EUROPEAN_ABBREVIATED_DATE_TIME(this.managerService.pairService.getActiveFeaturedPair()?.listing_date);
        }
        return 0;
    }

    ACTIVE_FEATURED_ART_PRODUCT_CONTRACT_ADDRESS(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.contract_address.substr(0, 16) + '...';
        }
        return 0;
    }

    ACTIVE_FEATURED_ART_PRODUCT_CONTRACT_ADDRESS_LINK(value: any): any {
        if (this.managerService.pairService.active_featured_pair_ready && this.managerService.pairService.art_product_list_ready) {
            // @ts-ignore
            return 'https://etherscan.io/token/' + this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.contract_address;
        }
        return 0;
    }

    SALE_STATE_TIME_COUNTDOWN(value: any, args: any[]): any {
        const _value: any = this.managerService.pairService.getPairById(value);
        if (args[1] === 'featuring') {
            // @ts-ignore
            if (this.managerService.pairService.getArtProductById(this.managerService.pairService.getActiveFeaturedPair()?.id[0])?.pre_sale_supply_ratio !== 0) {
                return this.managerService.utilityService.getCountdown(_value.pre_sale_start_date);  // todo  + this.managerService.utilityService.global_timezone_offset
            } else {
                return this.managerService.utilityService.getCountdown(_value.public_sale_start_date);  // todo  + this.managerService.utilityService.global_timezone_offset
            }
        } else if (args[1] === 'pre_sale') {
            return this.managerService.utilityService.getCountdown(_value.pre_sale_end_date);  // todo  + this.managerService.utilityService.global_timezone_offset
        } else if (args[1] === 'public_sale') {
            return this.managerService.utilityService.getCountdown(_value.public_sale_end_date);  // todo  + this.managerService.utilityService.global_timezone_offset
        } else {
            return new Date(_value.listing_date);
        }
    }

    COUNTDOWN(value: any): any {
        return this.managerService.utilityService.timer(value);
    }

    CLOSE_MODAL(value: any, args: any[]): any {
        if (typeof value === 'number' && value <= 0) {
            this.managerService.closeModal(args[1]);
            this.managerService.utilityService.closeTimer();
        }
        return value;
    }

    TEXT_LIMIT(value: string, args: any[]): string {
        if (args[1] === -1) {
            return value + '<span class="text-info-arrow">[<<]</span>';
        } else {
            return value.substr(0, args[1]) + '<span class="text-info-arrow">[>>]</span>';
        }
    }

    ASPECT_RATIO_16_9(value: number): string {
        return value * 9 / 16 + 'px';
    }

    EVALUATE_STRING(value: any, args: any[]): string {
        return this.managerService.evaluateString(args[1].STRING, args[1].TOKENS, value);
    }

    ACTIVE_TRADE_PANEL_BUY(value: any): boolean {
        return value === this.managerService.active_trade_type_panel_template.BUY;
    }

    ACTIVE_TRADE_PANEL_SELL(value: any): boolean {
        return value === this.managerService.active_trade_type_panel_template.SELL;
    }

    USER_ACTIVE_ART_PRODUCT_AVAILABLE_AMOUNT(): any {
        if (this.managerService.pairService.active_pair_ready) {
            // @ts-ignore
            return this.managerService.fancyNumber(this.managerService.getUserActiveArtProductAvailableAmount());
        }
    }

    USER_ACTIVE_CURRENCY_AVAILABLE_AMOUNT(): any {
        if (this.managerService.pairService.active_pair_ready) {
            // @ts-ignore
            return this.managerService.fancyNumber(this.managerService.getUserActiveCurrencyAvailableAmount());
        }
    }

    USER_INORDER_AMOUNT(value: any): any {
        if (value instanceof CurrencyWallet) {
            return this.managerService.userService.user_open_buy_orders.filter(x => x.pair_id[1] === value.currency_id).map(x => x.amount * x.price)
                .reduce(((previous_value, current_value) => previous_value + current_value), 0);
        } else {
            return value.total_amount - value.available_amount;
        }
    }

    // AUCTION_PRODUCT_ARRAY(): AuctionProduct[] {
    //     return this.managerService.auctionService.auction_product_list.getAuctionProductArray();
    // }

    ACTIVE_CATALOG_AUCTION_PRODUCT_ARRAY(): AuctionProduct[] {
        return this.managerService.auctionService.active_auction_catalog.getAuctionProductArray();
    }

    ACTIVE_CATALOG_STARTED_AUCTION_PRODUCT_ARRAY(): AuctionProduct[] {
        return this.managerService.auctionService.active_auction_catalog.getAuctionProductArray().filter((e: AuctionProduct) => e.auction_state === 'live');
    }

    AUCTION_CATALOG_ARRAY_LIVE(): AuctionCatalog[] {
        return this.managerService.auctionService.getAuctionCatalogArray().filter(x => x.isLive() && !x.is_daily).sort((x, y) => {
            return y.auction_start_date - x.auction_start_date;
        });
    }

    AUCTION_CATALOG_ARRAY_PAST(): AuctionCatalog[] {
        return this.managerService.auctionService.getAuctionCatalogArray().filter(x => x.isFinished() && !x.is_daily).sort((x, y) => {
            return y.auction_start_date - x.auction_start_date;
        });
    }

    // GET_USER_BIDS(): any {
    //     return this.managerService.userService.user_bids;
    // }

    GET_USER_DELIVERY_ADDRESS(value: any, args: any[]): any {
        const delivery_option = args[1];
        if (delivery_option === 0) {
            return this.managerService.userService.user_info?.delivery_addresses;
        } else if (delivery_option === 1) {
            const data: DeliveryAddress = {
                address_id: -1,
                label: this.managerService.config.PAYMENT.DELIVERY_ADDRESS.PICK_UP_HEADER,
                province: 'Ankara',
                district: 'Çankaya',
                address: this.managerService.config.PAYMENT.DELIVERY_ADDRESS.PICK_UP_ADDRESS,
                phone_number: '5555555555' // todo add real number
            };
            return [data];
        }
    }

    GET_USER_BILLING_ADDRESS(): any {
        return this.managerService.userService.user_info?.billing_information;
    }

    GET_USER_WON(): any {
        const products: AuctionProduct[] = this.managerService.auctionService.auction_product_list.getAuctionProductArray();
        const deliveryIDs = this.managerService.userService.user_deliveries.map(delivery => delivery.auction_product_id);
        return products.filter(product => deliveryIDs.includes(product.id));
    }
    GET_USER_WON_DELIVERY_STATUS(id : number, args: any[]): any {
        const info = this.managerService.userService.user_deliveries.find(deliveryInfo => deliveryInfo.auction_product_id === id);
        return info?.status ?? ''
    }

    GET_USER_WON_DELIVERY_INFO(product: any): any {
        const delivery_info = this.managerService.userService.user_deliveries.find(x => x.auction_product_id === product.id);
        if (delivery_info) {
            return delivery_info;
        } else {
            return {
                auction_product_id: product.id,
                address_id: -1,
                label: '',
                province: '',
                district: '',
                address: '',
                phone_number: ''
            };
        }
    }

    GET_USER_WON_DELIVERY_HEADER(value: any, args: any[]): any {
        // @ts-ignore
        const delivery_info: Delivery = this.managerService.userService.user_deliveries.find(x => x.auction_product_id === value);
        if (delivery_info) {
            const delivery_status: string = delivery_info.status.toUpperCase();
            return this.managerService.config.PAYMENT.PAYMENT_STATUS.STATUS[delivery_status as keyof typeof this.managerService.config.PAYMENT.PAYMENT_STATUS.STATUS].HEADER;
        }
    }

    GET_USER_WON_DELIVERY_HEADER_COLOR(value: any, args: any[]): any {
        // @ts-ignore
        const delivery_info: Delivery = this.managerService.userService.user_deliveries.find(x => x.auction_product_id === value);
        // if (delivery_info) {
            const delivery_status: string = delivery_info?.status;
            switch (delivery_status) {
                case 'pending':
                    return '#F11658';
                case 'confirming':
                    return '#FFBC04';
                case 'approved':
                case 'packed':
                case 'delivered':
                default:
                    return '#4BC4D6';
            }
        // }
    }

    GET_USER_WON_DELIVERY_TEXT(value: any, args: any[]): any {
        // @ts-ignore
        const delivery_info: Delivery = this.managerService.userService.user_deliveries.find(x => x.auction_product_id === value);
        if (delivery_info) {
            const delivery_status: string = delivery_info.status.toUpperCase();
            if (delivery_info.status === 'packed') {
                const html = this.managerService.config.PAYMENT.PAYMENT_STATUS.STATUS[delivery_status as keyof typeof this.managerService.config.PAYMENT.PAYMENT_STATUS.STATUS].TEXT.replace('$cargo_firm', delivery_info.company).replace('$cargo_number', delivery_info.tracking_number).replaceAll('$cargo_link', delivery_info.tracking_link);
                // this.managerService.evaluateString(this.managerService.config.PAYMENT.PAYMENT_STATUS.STATUS[delivery_status as keyof typeof this.managerService.config.PAYMENT.PAYMENT_STATUS.STATUS].TEXT, ['$cargo_firm', '$cargo_number', '$cargo_link', '$cargo_link'], [delivery_info.company, delivery_info.tracking_number, delivery_info.tracking_link, delivery_info.tracking_link]);
                return this.sanitizer.bypassSecurityTrustHtml(html);
            }
            // tslint:disable:max-line-length
            return this.sanitizer.bypassSecurityTrustHtml(this.managerService.config.PAYMENT.PAYMENT_STATUS.STATUS[delivery_status as keyof typeof this.managerService.config.PAYMENT.PAYMENT_STATUS.STATUS].TEXT);
        }
    }

    GET_USER_WON_DELIVERY_IMAGE(value: any, args: any[]): any {
        // @ts-ignore
        const delivery_info: Delivery = this.managerService.userService.user_deliveries.find(x => x.auction_product_id === value);
        if (delivery_info) {
            const delivery_status: string = delivery_info.status.toUpperCase();
            return this.managerService.config.PAYMENT.PAYMENT_STATUS.STATUS[delivery_status as keyof typeof this.managerService.config.PAYMENT.PAYMENT_STATUS.STATUS].IMAGE;
        }
    }

    GET_USER_LATEST_BIDS(): any {
        const products: AuctionProduct[] = this.managerService.auctionService.auction_product_list.getAuctionProductArray();
        const bids = this.managerService.userService.user_bids;
        const latest = bids.filter(bid => {
            const product = products.find(x => x.id === bid.auction_product_id);
            if (product?.isFinished()) {
                return false;
            }
            for (const i of bids) {
                if (bid.auction_product_id === i.auction_product_id && bid.price < i.price) {
                    return false;
                }
            }
            return true;
        });
        return latest;
    }

    GET_FILTERED_SORTED_AUCTION_PRODUCT_LIST(value: any, args: any[]): any {
        let auction_products: AuctionProduct[];
        if (this.managerService.auctionService.active_auction_catalog.is_daily){
            auction_products = this.managerService.auctionService.cropFinishedAuctionProducts(this.managerService.auctionService.active_auction_catalog).getAuctionProductArray();
        } else {
            auction_products = this.managerService.auctionService.active_auction_catalog.getAuctionProductArray().filter(e => e.isStarted());
        }

        auction_products = auction_products.filter(product => {
            if (this.managerService.auctionService.auction_catalog_filter.min !== -1) {
                if (product.getValue() < this.managerService.auctionService.auction_catalog_filter.min) {
                    return false;
                }
            }
            if (this.managerService.auctionService.auction_catalog_filter.max !== -1) {
                if (product.getValue() > this.managerService.auctionService.auction_catalog_filter.max) {
                    return false;
                }
            }
            const keyword = this.managerService.auctionService.auction_catalog_filter.keyword.toLocaleUpperCase(this.managerService.language);
            if (keyword !== '') {
                // @ts-ignore
                const artist_name = this.managerService.getAuctionProductConfig(product.hash).ARTIST_NAME;
                if (product.name.toLocaleUpperCase(this.managerService.language).includes(keyword) ||
                    artist_name.toLocaleUpperCase(this.managerService.language).includes(keyword)) {
                    // continue
                } else {
                    return false;
                }
            }
            return true;
        });

        const catalog_sorting_type_template = this.managerService.auctionService.catalog_sorting_type_template;
        auction_products = auction_products.sort((a, b) => {
            switch (this.managerService.auctionService.active_catalog_sorting_type) {
                case catalog_sorting_type_template.DEFAULT: {
                    if (a.auction_state === 'knockdown' && b.auction_state === 'knockdown') {
                        return b.lot - a.lot;
                    } else if (a.auction_state === 'knockdown' && b.auction_state !== 'knockdown') {
                        return 1;
                    } else if (a.auction_state !== 'knockdown' && b.auction_state === 'knockdown') {
                        return -1;
                    } else if (a.auction_state !== 'knockdown' && b.auction_state !== 'knockdown') {
                        return a.lot - b.lot;
                    }

                    return 0;
                }
                case catalog_sorting_type_template.PRICE_INCREASING: {
                    return a.opening_value - b.opening_value;
                }
                case catalog_sorting_type_template.PRICE_DECREASING: {
                    return b.opening_value - a.opening_value;
                }
                case catalog_sorting_type_template.ACTIVE_PRICE_INCREASING: {
                    return a.getValue() - b.getValue();
                }
                case catalog_sorting_type_template.ACTIVE_PRICE_DECREASING: {
                    return b.getValue() - a.getValue();
                }
                case catalog_sorting_type_template.LOT_NUMBER_INCREASING: {
                    return a.lot - b.lot;
                }
                case catalog_sorting_type_template.LOT_NUMBER_DECREASING: {
                    return b.lot - a.lot;
                }
            }
        });

        return auction_products;
    }

    // GET_FOLLOWING_PRODUCT_LIST(value: any, args: any[]): any {
    //     return this.managerService.getUserFollowingAuctionProducts();
    // }

    AUCTION_PRODUCT_ARTIST_NAME(value: AuctionProduct): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return '';
        }
        return this.managerService.getAuctionProductConfig(value.hash).ARTIST_NAME;
    }

    AUCTION_PAYMENT_DESCRIPTION(value: AuctionProduct): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return '';
        }
        return this.managerService.auctionPaymentDescription(value);
    }

    AUCTION_PRODUCT_BY_ID(id: number): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return '';
        }
        return this.managerService.auctionService.getAuctionProductById(id);
    }

    AUCTION_PRODUCT_NAME(value: AuctionProduct): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return '';
        }
        return this.managerService.getAuctionProductConfig(value.hash).NAME;
    }

    AUCTION_CATALOG_THUMBNAIL(value: AuctionCatalog): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return '';
        }
        return this.managerService.getAuctionCatalogConfig(value.hash).IMAGE_THUMBNAIL;
    }

    AUCTION_CATALOG_NAME(value: AuctionCatalog): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return '';
        }
        return this.managerService.getAuctionCatalogConfig(value.hash).NAME;
    }

    AUCTION_CATALOG_TITLE(value: AuctionCatalog): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return '';
        }
        return this.managerService.getAuctionCatalogConfig(value.hash).TITLE;
    }

    AUCTION_CATALOG_TEXT(value: AuctionCatalog): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return '';
        }
        return this.managerService.getAuctionCatalogConfig(value.hash).TEXT;
    }

    AUCTION_CATALOG_START_DATE(value: AuctionCatalog): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return '';
        }
        return value.auction_start_date;
    }

    AUCTION_CATALOG_END_DATE(value: AuctionCatalog): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return '';
        }
        return value.auction_end_date;
    }

    AUCTION_PRODUCT_THUMBNAIL(value: AuctionProduct): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return '';
        }
        return this.managerService.getAuctionProductConfig(value.hash).IMAGE_DESKTOP[0];  // todo use thumbnail
    }

    AUCTION_PRODUCT_OPENING_VALUE(value: AuctionProduct): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return 0;
        }
        return value.opening_value;
    }

    AUCTION_PRODUCT_ACTIVE_BID(value: AuctionProduct, args: any[]): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return 0;
        }

        return value.active_bid_price;
    }

    AUCTION_PRODUCT_CURRENT_VALUE(value: AuctionProduct, args: any[]): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return 0;
        } else if (!value.hasBids()) {
            return '-';
        }
        return this.FANCY_NUMBER_NO_DECIMAL(value.current_value);
    }

    AUCTION_PRODUCT_NEXT_BIDS(value: AuctionProduct): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return [];
        }

        const min_bid: number = value.getMinBid();
        const value_step: number = value.value_step;
        return [
            min_bid,
            min_bid + value_step,
            min_bid + 2 * value_step,
            min_bid + 3 * value_step,
            min_bid + 4 * value_step,
            min_bid + 5 * value_step,
            min_bid + 6 * value_step,
            min_bid + 7 * value_step,
            min_bid + 8 * value_step,
            min_bid + 9 * value_step,
        ];
    }

    AUCTION_PRODUCT_LOT(value: AuctionProduct): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return '';
        }
        return value.lot;
    }

    AUCTION_PRODUCT_TEXT(value: AuctionProduct): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return '';
        }
        return this.managerService.getAuctionProductConfig(value.hash).TEXT;
    }

    AUCTION_CATALOG_SORT_OPTIONS(value: any): any {
        return this.managerService.config.AUCTION.AUCTION_CATALOG_SORT_OPTIONS.OPTIONS;
    }

    AUCTION_CATALOG_ACTIVE_SORT_OPTION(value: any, args: any[]): string {
        const optionIndex: number = this.managerService.auctionService.active_catalog_sorting_type;
        return this.managerService.config.AUCTION.AUCTION_CATALOG_SORT_OPTIONS.OPTIONS[optionIndex];
    }

    ACTIVE_AUCTION_PRODUCT_ARTIST_NAME(value: any): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return '';
        }
        return this.managerService.auctionService.active_auction_product_config.ARTIST_NAME;
    }

    ACTIVE_AUCTION_CATALOG_NAME(value: any): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return '';
        }
        return this.managerService.auctionService.active_auction_catalog_config.NAME;
    }

    ACTIVE_AUCTION_LOT(value: any): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return '';
        }
        return this.managerService.auctionService.active_auction_product.lot;
    }

    // ACTIVE_AUCTION_LOT_NAME(value: any): any {
    //     if (!this.managerService.auctionService.auction_product_list_ready) {
    //         return '';
    //     }
    //     return this.managerService.auctionService.active_auction_product_config.LOT_NAME;
    // }

    ACTIVE_AUCTION_PRODUCT_STATE(value: any): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return '';
        }
        // @ts-ignore
        return this.managerService.config.AUCTION.AUCTION_STATE_MAP[this.managerService.auctionService.active_auction_product.auction_state.toUpperCase()];
    }


    ACTIVE_AUCTION_PRODUCT_NAME(value: any): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return '';
        }
        return this.managerService.auctionService.active_auction_product_config.NAME;
    }

    ACTIVE_AUCTION_PRODUCT_TEXT(value: any, ...args: any[]): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return '';
        }
        const text = this.managerService.auctionService.active_auction_product_config.TEXT;
        const breaks = /<br>/gm;
        const result = text.replace(breaks, ', ');
        return result;
    }

    ACTIVE_AUCTION_PRODUCT_STATEMENT(value: any): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return '';
        }
        let text = this.managerService.auctionService.active_auction_product_config.STATEMENT;
        if (text === '') {
            text = '-';
        }
        return text;
    }

    ACTIVE_AUCTION_PRODUCT_END_DATE(value: any): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return 0;
        }
        return this.managerService.auctionService.active_auction_product.auction_end_date;
    }

    AUCTION_PRODUCT_END_DATE(value: AuctionProduct): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return 0;
        }
        return value.auction_end_date;
    }

    ACTIVE_AUCTION_PRODUCT_TIME_INCREMENT_TEXT(value: any): any {
        const increment = this.managerService.auctionService.active_auction_product.end_date_increment / (60 * 1000 * 1000 * 1000);
        return '+' + increment.toString() + ' Dk.';
    }

    AUCTION_PRODUCT_TIME_INCREMENT_TEXT(value: AuctionProduct): any {
        const increment = value.end_date_increment / (60 * 1000 * 1000 * 1000);
        return '+' + increment.toString() + ' Dk.';
    }

    ACTIVE_AUCTION_PRODUCT_IMAGES(value: any): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return [];
        }
        return this.managerService.auctionService.active_auction_product_config.IMAGE_DESKTOP;
    }

    ACTIVE_AUCTION_PRODUCT_OPENING_PRICE(value: any): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return 0;
        }
        return this.managerService.auctionService.active_auction_product.opening_value;
    }

    AUCTION_PRODUCT_OPENING_PRICE(value: any): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return 0;
        }
        return value.opening_value + ' TL';  // + value.value_type;
    }

    ACTIVE_AUCTION_PRODUCT_CURRENT_PRICE(value: any, ...args: any[]): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return 0;
        }
        // const current_value = this.managerService.auctionService.active_auction_product.current_value;
        const auc_prod = this.managerService.auctionService.active_auction_product;

        if (auc_prod.hasBids()) {
            // if args[0][1], decimals
            if(args[0]){
                return this.managerService.fancyNumber(auc_prod.current_value, args[0][1]) + ' TL';  // + value.value_type;
            } else {
                return this.managerService.fancyNumber(auc_prod.current_value) + ' TL';  // + value.value_type;
            }
        } else {
            return '-';
        }
    }

    ACTIVE_AUCTION_PRODUCT_TOTAL_PRICE(value: any): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return 0;
        }
        // const current_value = this.managerService.auctionService.active_auction_product.getTotalPrice();
        const auc_prod = this.managerService.auctionService.active_auction_product;

        if (auc_prod.hasBids()) {
            return this.managerService.fancyNumber(auc_prod.getTotalPrice()) + ' TL';  // + value.value_type;
        } else {
            return '-';
        }
    }

    AUCTION_PRODUCT_CURRENT_PRICE(value: any): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return 0;
        }
        const current_value = value.current_value;

        if (current_value) {
            return this.managerService.fancyNumber(current_value) + ' TL';  // + value.value_type;
        } else {
            return '-';
        }
    }

    AUCTION_PRODUCT_TOTAL_PRICE(value: any): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return 0;
        }
        // const current_value = value.getTotalPrice();

        if (value.hasBids()) {
            return this.managerService.fancyNumber(value.getTotalPrice(), 0) + ' TL';  // + value.value_type;
        } else {
            return '-';
        }
    }

    ACTIVE_AUCTION_PRODUCT_MIN_BID(value: any): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return 0;
        }
        return this.managerService.auctionService.active_auction_product.getMinBid();
    }

    ACTIVE_AUCTION_PRODUCT_NEXT_BIDS(value: any): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return [];
        }
        const min_bid: number = this.managerService.auctionService.active_auction_product.getMinBid();
        const value_step: number = this.managerService.auctionService.active_auction_product.value_step;

        return [
            min_bid,
            min_bid + value_step,
            min_bid + 2 * value_step,
            min_bid + 3 * value_step,
            min_bid + 4 * value_step,
            min_bid + 5 * value_step,
            min_bid + 6 * value_step,
            min_bid + 7 * value_step,
            min_bid + 8 * value_step,
            min_bid + 9 * value_step,
        ];
    }

    ACTIVE_AUCTION_PRODUCT_PRICE_ESTIMATION(value: any): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return '';
        }
        return this.managerService.auctionService.active_auction_product_config.PRICE_ESTIMATION;
    }

    ACTIVE_AUCTION_PRODUCT_ARTIST_TITLE(value: any): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return '';
        }
        return this.managerService.auctionService.active_auction_product_artist_config.TITLE;
    }

    ACTIVE_AUCTION_PRODUCT_ARTIST_TEXT(value: any): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return '';
        }
        return this.managerService.auctionService.active_auction_product_artist_config.TEXT;
    }

    AUCTION_PRODUCT_COUNTDOWN(value: AuctionProduct): any {
        // const now = UtilityService.currentTimestamp();
        const auction_start_date = value.auction_start_date + this.managerService.utilityService.global_timezone_offset;
        const auction_end_date = value.auction_end_date + this.managerService.utilityService.global_timezone_offset;

        // if (now < auction_start_date){
        //     return new Promise((resolve, reject) => 0);
        // } else if (now > auction_end_date){
        //     return new Promise((resolve, reject) => 100);
        // } else {
        // const ratio = (auction_end_date - now) / (auction_end_date - auction_start_date) * 100;
        return this.managerService.utilityService.timer(auction_end_date - auction_start_date);
        // }
    }

    AUCTION_PRODUCT_COUNTDOWN_BAR(value: any, ...args: any[]): any {
        const product: AuctionProduct = args[0][1];
        const now = UtilityService.currentTimestamp();
        const auction_start_date = product.auction_start_date + this.managerService.utilityService.global_timezone_offset;
        const auction_end_date = product.auction_end_date + this.managerService.utilityService.global_timezone_offset;

        if (now < auction_start_date) {
            return {'background-color': '#404352'};
        } else if (now > auction_end_date) {
            return {'background-color': '#FF207D'};
        } else {
            if (now + product.end_date_increment / 1000000 > auction_end_date) {
                const ratio = (auction_end_date - now) / (auction_end_date - auction_start_date) * 100;
                return {'background-image': `linear-gradient(to left, #E2E2E2, #E2E2E2 ${ratio}%, #FFC300 ${ratio}%, #FFC300 100%)`};
            } else {
                const ratio = (auction_end_date - now) / (auction_end_date - auction_start_date) * 100;
                return {'background-image': `linear-gradient(to left, #E2E2E2, #E2E2E2 ${ratio}%, #8266F0 ${ratio}%, #8266F0 100%)`};
            }
        }
    }

    AUCTION_PRODUCT_REMAIN_TIME(value: any, ...args: any[]): any {
        const product: AuctionProduct = args[0][1];
        const now = UtilityService.currentTimestamp();
        const auction_start_date = product.auction_start_date + this.managerService.utilityService.global_timezone_offset;
        const auction_end_date = product.auction_end_date + this.managerService.utilityService.global_timezone_offset;
        if (now < auction_start_date) {
            return '--:--:--';
        } else if (now > auction_end_date) {
            return '0g 00:00:00';
        } else {
            let time = (auction_end_date - now) / 1000;
            const day = Math.floor(time / (24 * 3600));

            time %= (24 * 3600);
            const hour = Math.floor(time / 3600);

            time %= 3600;
            const minutes = Math.floor(time / 60);

            const seconds = Math.floor(time % 60);

            const day_string = day.toString() + 'g ';  // todo hardcode
            return `${day_string}${this.managerService.toFixedInt(hour)}:${this.managerService.toFixedInt(minutes)}:${this.managerService.toFixedInt(seconds)}`;
        }
    }

    AUCTION_PRODUCT_TIME_STYLE(value: any, ...args: any[]): any {
        const product: AuctionProduct = args[0][1];
        const now = UtilityService.currentTimestamp();
        const auction_start_date = product.auction_start_date + this.managerService.utilityService.global_timezone_offset;
        const auction_end_date = product.auction_end_date + this.managerService.utilityService.global_timezone_offset;

        if (now < auction_start_date) {
            return {color: '#404352'};
        } else if (now > auction_end_date) {
            return {color: '#EA3943'};
        } else {
            if (now + product.end_date_increment / 1000000 > auction_end_date) {
                return {color: '#FFBC04'};
            } else {
                return {color: '#8266F0'};
            }
        }
    }

    ACTIVE_AUCTION_PRODUCT_COUNTDOWN(value: any): any {
        return this.AUCTION_PRODUCT_COUNTDOWN(this.managerService.auctionService.active_auction_product);
    }

    ACTIVE_AUCTION_PRODUCT_COUNTDOWN_BAR(value: any): any {
        return this.AUCTION_PRODUCT_COUNTDOWN_BAR(false, [0, this.managerService.auctionService.active_auction_product]);
    }

    ACTIVE_AUCTION_PRODUCT_REMAIN_TIME(value: any): any {
        return this.AUCTION_PRODUCT_REMAIN_TIME(false, [0, this.managerService.auctionService.active_auction_product]);
    }

    ACTIVE_AUCTION_END_TEXT(value: any): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return '';
        }
        if (this.managerService.auctionService.active_auction_product.auction_state === 'knockdown') {
            return this.managerService.config.AUCTION.AUCTION_END;
        }
    }

    ACTIVE_AUCTION_TIME_STYLE(value: any): any {
        // const now = UtilityService.currentTimestamp();
        // const auction_end_date = this.managerService.auctionService.active_auction_product.auction_end_date + this.managerService.utilityService.global_timezone_offset;
        // const remain = auction_end_date - now;
        // if (remain <= 0) {
        //     return {'color': '#EA3943'};
        // } else if (remain > this.managerService.auctionService.active_auction_product.end_date_increment / 1000000) {
        //     return {'color': '#66BB6A'};
        // } else {
        //     return {'color': '#FFC300'};
        // }
        return this.AUCTION_PRODUCT_TIME_STYLE(false, [0, this.managerService.auctionService.active_auction_product]);
    }

    AUCTION_PRODUCT_OWNERSHIP_STATUS(value: AuctionProduct): boolean {
        const bids = this.managerService.userService.user_bids;
        const bid = bids.find(x => x.auction_product_id === value.id);
        if (value.auction_state === 'live' && bid?.price === value.current_value) {
            return true;
        }
        return false;
    }

    AUCTION_PRODUCT_STATUS_STYLE(value: AuctionProduct): any {
        const now = UtilityService.currentTimestamp();
        if (value.auction_start_date + this.managerService.utilityService.global_timezone_offset > now) {
            return 'auction_not_started';
        } else if (value.auction_end_date + this.managerService.utilityService.global_timezone_offset < now) {
            return 'auction_finished';
        } else {
            return 'auction_active';
        }
    }

    ACTIVE_AUCTION_CATALOG_PRODUCTS(value: any): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return [];
        }
        return this.managerService.auctionService.active_auction_catalog.getAuctionProductArray();
    }

    GET_LAST_BIDS(value: any): Bid[] {
        if (!this.managerService.bidService.bid_list_ready) {
            return [];
        }
        return this.managerService.bidService.bid_list;
    }

    GET_LAST_BID_COUNT(value: any): string {
        if (!this.managerService.bidService.bid_list_ready) {
            return '';
        }
        const len = this.managerService.bidService.bid_list.length;
        if (len === this.managerService.bidService.bid_list_limit) {
            return len.toString() + '+';
        } else {
            return len.toString();
        }
    }

    GET_BID_TICKERS(value: any): Bid[] {
        if (!this.managerService.bidService.bid_tickers_ready) {
            return [];
        }
        return this.managerService.bidService.bid_tickers;
    }

    ACTIVE_BID_PRICE(value: any): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return 0;
        }
        return (this.managerService.auctionService.active_auction_product.active_bid_price > this.managerService.auctionService.active_auction_product.opening_value) ? this.managerService.auctionService.active_auction_product.active_bid_price : this.managerService.auctionService.active_auction_product.opening_value;
    }

    ACTIVE_BID_COMMISSION(value: any): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return 0;
        }
        return this.managerService.auctionService.getActiveCommission();
    }

    ACTIVE_BID_TAX(value: any): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return 0;
        }
        return this.managerService.auctionService.getActiveTax();
    }

    ACTIVE_BID_TOTAL(value: any): any {
        if (!this.managerService.auctionService.auction_product_list_ready) {
            return 0;
        }
        return this.managerService.auctionService.getActiveTotalPrice();
    }


    FANCY_NUMBER(value: any, args: any[]): any {
        const num: number = +value;
        return this.managerService.fancyNumber(num);
    }

    FANCY_NUMBER_NO_DECIMAL(value: any): any {
        return this.managerService.fancyNumber(value, 0);
    }

    FANCY_NUMBER_DECIMAL(value: any, args: any[]): any {
        return this.managerService.fancyNumber(value, args[1]);
    }

    TIME_FORMAT(value: any): any {
        if (value !== null && value instanceof Time) {
            return '<span class="w-25 text-right">' + value.days.toString() + '</span>' +
                '<span class="w-25 text-right">' + value.hours.toString() + '</span>' +
                '<span class="w-25 text-right">' + value.minutes.toString() + '</span>' +
                '<span class="w-25 text-right">' + value.seconds.toString() + '</span>';
        } else if (value !== null && value instanceof Date) {
            return '<span class="w-25 text-right">' + value.getFullYear().toString() + '</span>' +
                '<span class="w-25 text-right">' + (value.getMonth() + 1).toString() + '</span>' +
                '<span class="w-25 text-right">' + value.getDate().toString() + '</span>' +
                '<span class="w-25 text-right">' + value.getHours().toString() + '</span>';
        }
    }

    GET_DAYS(value: Time): string {
        if (value !== null) {
            return value.days.toString();
        }
        // else if (value !== null && value instanceof Date) {
        //   return value.getDate().toString() ;
        // }
        return '';
    }

    GET_HOURS(value: Time): string {
        if (value !== null) {
            return value.hours.toString();
        }
        return '';
    }

    GET_MINUTES(value: Time): string {
        if (value !== null) {
            return value.minutes.toString();
        }
        return '';
    }

    GET_SECONDS(value: Time): string {
        if (value !== null) {
            return value.seconds.toString();
        }
        return '';
    }

    IS_PAIR_FAVORITE(value: any): any {
        if (this.managerService.userService.user_ready) {
            return this.managerService.userService.getUser()?.isPairFavorite(value);
        }
        return false;
    }

    IS_AUCTION_PRODUCT_FAVOURITE(value: any): any {
        if (this.managerService.userService.user_ready) {
            return this.managerService.userService.getUser()?.isAuctionProductFavorite(value);
        }
        return false;
    }

    GET_TRADE_CHART_DATA_SVG(value: any): any {
        return this.managerService.tradeHistoryService.getTradeChartDataSVG(value);
    }

    GET_BUY_ORDER_BOOK(value: any): any {
        return this.managerService.orderBookService.getBuyOrderBook().slice(0, 10);
    }

    GET_SELL_ORDER_BOOK(value: any): any {
        return this.managerService.orderBookService.getSellOrderBook().slice(0, 10);
    }

    GET_BUY_ORDER_BOOK_BACKGROUND_COLOR_MOBILE(value: any): any {
        return {
            'background-image': 'linear-gradient(90deg, transparent ' + ((100 - value.amount / 1000 * 100) < 0 ? 0 : 100 - value.amount / 1000 * 100) + '%, rgba(41, 216, 169, 0.4) ' + ((100 - value.amount / 1000 * 100) < 0 ? 0 : 100 - value.amount / 1000 * 100) + '%)'
        };
    }

    GET_SELL_ORDER_BOOK_BACKGROUND_COLOR_MOBILE(value: any): any {
        return {
            'background-image': 'linear-gradient(90deg, transparent ' + ((100 - value.amount / 1000 * 100) < 0 ? 0 : 100 - value.amount / 1000 * 100) + '%, rgba(255, 32, 125, 0.4) ' + ((100 - value.amount / 1000 * 100) < 0 ? 0 : 100 - value.amount / 1000 * 100) + '%)'
        };
    }

    GET_BUY_ORDER_BOOK_BACKGROUND_COLOR(value: any): any {
        return {
            'background-image': 'linear-gradient(90deg, transparent ' + ((100 - value.amount / 1000 * 100) < 0 ? 0 : 100 - value.amount / 1000 * 100) + '%, #D0EAD1 ' + ((100 - value.amount / 1000 * 100) < 0 ? 0 : 100 - value.amount / 1000 * 100) + '%)'
        };
    }

    GET_SELL_ORDER_BOOK_BACKGROUND_COLOR(value: any): any {
        return {
            'background-image': 'linear-gradient(90deg, transparent ' + ((100 - value.amount / 1000 * 100) < 0 ? 0 : 100 - value.amount / 1000 * 100) + '%, #F8C2C5 ' + ((100 - value.amount / 1000 * 100) < 0 ? 0 : 100 - value.amount / 1000 * 100) + '%)'
        };
    }

    GET_TRADE_HISTORY(value: any): any {
        return this.managerService.tradeHistoryService.getTradeHistory();
    }

    GET_SWAP_HISTORY(value: any): any {
        return this.managerService.tradeHistoryService.getSwapHistory();
    }

    GET_TRADE_HISTORY_BACKGROUND_COLOR(value: any): any {
        if (value.type === 'buy') {
            return {
                color: '#66BB6A'
            };
        } else {
            return {
                color: '#EA3943'
            };
        }
    }

    GET_USER_OPEN_ORDERS_BACKGROUND_COLOR(value: any): any {
        if (value.type === 'buy') {
            return {
                color: '#var(--secondary-colors-artiox-green-base)'
            };
        } else {
            return {
                color: '#var(--secondary-colors-artiox-red-base)'
            };
        }
    }

    GET_USER_TRADE_BACKGROUND_COLOR(value: any): any {
        if (value.side === 'buy') {
            return {
                color: 'var(--secondary-colors-artiox-green-base)'
            };
        } else {
            return {
                color: 'var(--secondary-colors-artiox-red-base)'
            };
        }
    }

    // TRADE_TIME_FORMAT(value: any): any {
    //     return this.managerService.moment(value).format('HH:mm:ss');
    // }

    // USER_OPEN_ORDER_TIME(value: any): any {
    //     return this.managerService.moment(value).format('DD.MM.YY HH:mm:ss');
    // }

    GET_USER_OPEN_ORDERS(value: any, args: any[]): any {
        if (this.managerService.userService.user_open_orders_ready) {
            if (args.length === 3) {
                if (args[1] !== undefined && args[2] !== undefined) {
                    return this.managerService.userService.getUserOpenOrders().filter(x => x.pair_id[0] === args[1][0] && x.pair_id[1] === args[1][1]).filter(x => x.type === args[2]);
                } else if (args[1] !== undefined) {
                    return this.managerService.userService.getUserOpenOrders().filter(x => x.pair_id[0] === args[1][0] && x.pair_id[1] === args[1][1]);
                } else if (args[2] !== undefined) {
                    return this.managerService.userService.getUserOpenOrders().filter(x => x.type === args[2]);
                } else {
                    return this.managerService.userService.getUserOpenOrders();
                }
            } else {
                return this.managerService.userService.getUserOpenOrders();
            }
        }
    }

    GET_USER_TRADE_HISTORY(value: any, args: any[]): any {
        if (this.managerService.userService.user_trade_history_ready) {
            if (args.length === 3) {
                if (args[1] !== undefined && args[2] !== undefined) {
                    return this.managerService.userService.getUserTradeHistory().filter(x => x.pair_id[0] === args[1][0] && x.pair_id[1] === args[1][1]).filter(x => x.side === args[2]);
                } else if (args[1] !== undefined) {
                    return this.managerService.userService.getUserTradeHistory().filter(x => x.pair_id[0] === args[1][0] && x.pair_id[1] === args[1][1]);
                } else if (args[2] !== undefined) {
                    return this.managerService.userService.getUserTradeHistory().filter(x => x.side === args[2]);
                } else {
                    return this.managerService.userService.getUserTradeHistory();
                }
            } else {
                return this.managerService.userService.getUserTradeHistory();
            }
        }
    }

    GET_USER_TRANSACTION_HISTORY(value: any, args: any[]): any {
        if (this.managerService.userService.user_trade_history_ready && this.managerService.userService.user_swap_history_ready) {
            if (args.length === 4) {
                if (args[1] !== undefined && args[2] !== undefined) {
                    return [...this.managerService.userService.getUserTradeHistory().filter(x => x.pair_id[0] === args[1][0] && x.pair_id[1] === args[1][1]).filter(x => x.side === args[2]), ...this.managerService.userService.getUserSwapHistory().filter(x => x.currency_pair_id[0] === args[1][0] && x.currency_pair_id[1] === args[1][1]).filter(x => x.side === args[2])];
                } else if (args[1] !== undefined) {
                    return [...this.managerService.userService.getUserTradeHistory().filter(x => x.pair_id[0] === args[1][0] && x.pair_id[1] === args[1][1]), ...this.managerService.userService.getUserSwapHistory().filter(x => x.currency_pair_id[0] === args[1][0] && x.currency_pair_id[1] === args[1][1])];
                } else if (args[2] !== undefined) {
                    return [...this.managerService.userService.getUserTradeHistory().filter(x => x.side === args[2]), ...this.managerService.userService.getUserSwapHistory().filter(x => x.side === args[2])];
                } else {
                    return [...this.managerService.userService.getUserTradeHistory(), ...this.managerService.userService.getUserSwapHistory()];
                }
            } else {
                return [...this.managerService.userService.getUserTradeHistory(), ...this.managerService.userService.getUserSwapHistory()];
            }
        }
    }

    GET_USER_ASSETS(value: any): any {
        if (this.managerService.userService.user_ready && this.managerService.userService.user !== undefined &&
            this.managerService.pairService.art_product_list_ready && this.managerService.pairService.currency_list_ready) {
            const activeArtProducts = this.managerService.pairService.art_product_list.filter(artProduct => artProduct.status === 'active');
            const activeUserArtProducts = this.managerService.userService.user.art_product_list.filter( artProductWalletElement => activeArtProducts.find(value => value.id === artProductWalletElement.art_product_id) !== undefined)
            return [...this.managerService.userService.user.currency_list, ...activeUserArtProducts];
        }
    }

    GET_USER_OWNED_ASSETS(value: any): any {
        if (this.managerService.userService.user_ready && this.managerService.userService.user !== undefined &&
            this.managerService.pairService.art_product_list_ready && this.managerService.pairService.currency_list_ready) {
            return [...this.managerService.userService.user.currency_list, ...this.managerService.userService.user.art_product_list.filter(x => x.total_amount)];
        }
    }

    GET_USER_CURRENCY_LIST(value: any): any {
        if (this.managerService.userService.user_ready && this.managerService.userService.user !== undefined &&
            this.managerService.pairService.art_product_list_ready && this.managerService.pairService.currency_list_ready) {
            return this.managerService.userService.user.currency_list;
        }
    }

    GET_USER_FIAT_CURRENCY_LIST(value: any): any {
        if (this.managerService.userService.user_ready && this.managerService.userService.user !== undefined &&
            this.managerService.pairService.art_product_list_ready && this.managerService.pairService.currency_list_ready) {
            return this.managerService.userService.user.currency_list.filter(e => this.managerService.pairService.currency_list[e.currency_id].type === 'fiat');
        }
    }

    GET_USER_CRYPTO_CURRENCY_LIST(value: any): any {
        if (this.managerService.userService.user_ready && this.managerService.userService.user !== undefined &&
            this.managerService.pairService.art_product_list_ready && this.managerService.pairService.currency_list_ready) {
            return this.managerService.userService.user.currency_list.filter(e => this.managerService.pairService.currency_list[e.currency_id].type === 'crypto');
        }
    }

    GET_USER_ART_PRODUCT_LIST(value: any): any {
        if (this.managerService.userService.user_ready && this.managerService.userService.user !== undefined &&
            this.managerService.pairService.art_product_list_ready && this.managerService.pairService.currency_list_ready) {
            return this.managerService.userService.user.art_product_list;
        }
    }

    GET_USER_OWNED_ART_PRODUCT_LIST(value: any): any {
        if (this.managerService.userService.user_ready && this.managerService.userService.user !== undefined &&
            this.managerService.pairService.art_product_list_ready && this.managerService.pairService.currency_list_ready) {
            return this.managerService.userService.user.art_product_list.filter(x => this.managerService.preciseRound(x.total_amount));
        }
    }

    GET_USER_ASSETS_SYMBOL(value: any, args: any[]): any {
        if (this.managerService.userService.user_ready && this.managerService.userService.user !== undefined) {
            if (value instanceof ArtProductWallet) {
                return this.ART_PRODUCT_SYMBOL(value.art_product_id);
            } else if (value instanceof CurrencyWallet) {
                return this.CURRENCY_SYMBOL(value.currency_id);
            }
        }
    }

    GET_USER_ASSETS_VALUE(value: any, args: any[]): any {
        if (this.managerService.userService.user_ready && this.managerService.userService.user !== undefined &&
            this.managerService.pairService.pair_list_ready && this.managerService.pairService.currency_pair_list_ready) {
            if (value instanceof ArtProductWallet) {
                return this.managerService.fancyNumber(value.total_amount * this.managerService.artProductToRootCurrencyMultiplier(value.art_product_id));
            } else if (value instanceof CurrencyWallet) {
                return this.managerService.fancyNumber(value.total_amount * this.managerService.currencyToRootCurrencyMultiplier(value.currency_id));
            }
        }
        return 0;
    }

    IS_USER_ASSETS_ART_PRODUCT(value: any, args: any[]): any {
        if (this.managerService.userService.user_ready && this.managerService.userService.user !== undefined &&
            this.managerService.pairService.pair_list_ready && this.managerService.pairService.currency_pair_list_ready) {
            if (value instanceof ArtProductWallet) {
                return true;
            } else if (value instanceof CurrencyWallet) {
                return false;
            }
        }
        return false;
    }

    IS_USER_ASSETS_CURRENCY(value: any, args: any[]): any {
        if (this.managerService.userService.user_ready && this.managerService.userService.user !== undefined &&
            this.managerService.pairService.pair_list_ready && this.managerService.pairService.currency_pair_list_ready) {
            if (value instanceof CurrencyWallet) {
                return true;
            } else if (value instanceof ArtProductWallet) {
                return false;
            }
        }
        return false;
    }


    IS_BASE_ASSET_IN_PAIR(value: any, args: any[]): any {
        if (this.managerService.userService.user_ready && this.managerService.userService.user !== undefined &&
            this.managerService.pairService.pair_list_ready && this.managerService.pairService.currency_pair_list_ready) {
            if (value instanceof ArtProductWallet) {
                for (const item of this.managerService.pairService.pair_list) {
                    if (item.id[0] === value.art_product_id) {
                        return true;
                    }
                }
                return false;
            } else if (value instanceof CurrencyWallet) {
                return false;
            }
        }
        return false;
    }

    IS_BASE_ASSET_IN_CURRENCY_PAIR(value: any, args: any[]): any {
        if (this.managerService.userService.user_ready && this.managerService.userService.user !== undefined &&
            this.managerService.pairService.pair_list_ready && this.managerService.pairService.currency_pair_list_ready) {
            if (value instanceof CurrencyWallet) {
                for (const item of this.managerService.pairService.currency_pair_list) {
                    if (item.id[0] === value.currency_id) {
                        return true;
                    }
                }
                return false;
            } else if (value instanceof ArtProductWallet) {
                return false;
            }
        }
        return false;
    }

    IS_CURRENCY_ASSET_FIAT(value: any, args: any[]): any {
        if (this.managerService.userService.user_ready && this.managerService.userService.user !== undefined && this.managerService.pairService.currency_list_ready) {
            if (value instanceof CurrencyWallet) {
                for (const item of this.managerService.pairService.currency_list) {
                    if (item.id === value.currency_id && item.type === 'fiat') {
                        return true;
                    }
                }
                return false;
            } else if (value instanceof ArtProductWallet) {
                return false;
            }
        }
        return false;
    }

    IS_CURRENCY_ASSET_CRYPTO(value: any, args: any[]): any {

        if (this.managerService.userService.user_ready && this.managerService.userService.user !== undefined && this.managerService.pairService.currency_list_ready) {
            if (value instanceof CurrencyWallet) {
                for (const item of this.managerService.pairService.currency_list) {
                    if (item.id === value.currency_id && item.type === 'crypto') {
                        return true;
                    }
                }
                return false;
            } else if (value instanceof ArtProductWallet) {
                return false;
            }
        }
        return false;
    }

    GET_USER_ASSETS_COLOR(value: ArtProductWallet | CurrencyWallet): any {
        if (this.managerService.userService.user_ready && this.managerService.userService.user !== undefined &&
            this.managerService.pairService.art_product_list_ready && this.managerService.pairService.currency_list_ready) {
            if (value instanceof ArtProductWallet) {
                return {'background-image': 'linear-gradient(' + (this.managerService.pairService.getArtProductById(value.art_product_id)?.color) + ')'};
            } else {
                return {'background-image': 'linear-gradient(' + (this.managerService.pairService.getCurrencyById(value.currency_id)?.color) + ')'};
            }
        }
    }

    GET_USER_ACTIVITY_RECORDS(): any {
        if (this.managerService.userService.user_ready && this.managerService.userService.user_activity_records_ready) {
            return this.managerService.userService.getUserActivityRecords();
        }
    }

    CHECK_ACCOUNT_LEVEL_EQUALS_TO(value: number): any {
        return this.managerService.userService.user !== undefined && this.managerService.userService.user.account_level === value;
    }

    CHECK_ACCOUNT_LEVEL_GREATER_THAN(value: number): any {
        return this.managerService.userService.user !== undefined && this.managerService.userService.user.account_level > value;
    }

    GET_USER_ACCOUNT_LEVEL(): any {
        if (this.managerService.userService.user !== undefined) {
            return this.managerService.userService.user.account_level;
        } else {
            return 0;
        }
    }

    CHECK_USER_DEPOSIT_HISTORY_LENGTH_GREATER_THAN(value: number): any {
        return this.managerService.userService.getUserCurrencyDeposits().length > value;
    }

    GET_USER_TOTAL_ART_PRODUCT_TYPE_OF_CURRENCY(value: number): any {
        if (this.managerService.userService.user_ready && this.managerService.userService.user !== undefined &&
            this.managerService.pairService.pair_list_ready && this.managerService.pairService.currency_pair_list_ready) {
            return this.managerService.getUserTotalArtProductTypeOfRootCurrency();
        }
        return 0;
    }

    GET_USER_TOTAL_CURRENCY_TYPE_OF_CURRENCY(value: number): any {
        if (this.managerService.userService.user_ready && this.managerService.userService.user !== undefined &&
            this.managerService.pairService.pair_list_ready && this.managerService.pairService.currency_pair_list_ready) {
            return this.managerService.getUserTotalCurrencyTypeOfRootCurrency();
        }
        return 0;
    }

    GET_USER_TOTAL_FIAT_CURRENCY_TYPE_OF_CURRENCY(value: number): any {
        if (this.managerService.userService.user_ready && this.managerService.userService.user !== undefined &&
            this.managerService.pairService.pair_list_ready && this.managerService.pairService.currency_pair_list_ready) {
            return this.managerService.getUserTotalFiatCurrencyTypeOfRootCurrency();
        }
        return 0;
    }

    GET_USER_TOTAL_CRYPTO_CURRENCY_TYPE_OF_CURRENCY(value: number): any {
        if (this.managerService.userService.user_ready && this.managerService.userService.user !== undefined &&
            this.managerService.pairService.pair_list_ready && this.managerService.pairService.currency_pair_list_ready) {
            return this.managerService.getUserTotalCryptoCurrencyTypeOfRootCurrency();
        }
        return 0;
    }

    GET_USER_CRYPTO_CURRENCY_TYPE_OF_CURRENCY(value: number): any {
        if (this.managerService.userService.user_ready && this.managerService.userService.user !== undefined &&
          this.managerService.pairService.pair_list_ready && this.managerService.pairService.currency_pair_list_ready) {
            return this.managerService.getUserCryptoCurrencyTypeOfRootCurrency(value);
        }
        return 0;
    }

    SET_CURRENCY_CONVERTER_DECIMAL(value: any, args: any[]): any {
        console.log(value);
        return args;
    }

    GET_USER_OPERATION_HISTORY(value: number, args: any[]): any {
        const currency_id = args[1];
        const type = args[2];
        const currency_type = args[3];

        let result = [];

        if (this.managerService.userService.user_ready && this.managerService.userService.user !== undefined && this.managerService.userService.user_currency_operations_ready) {
            if (args.length === 4) {
                if (type === 'deposit') {
                    result = this.managerService.userService.user_currency_deposits;
                } else if (type === 'withdrawal') {
                    result = this.managerService.userService.user_currency_withdrawals;
                } else {
                    result = [...this.managerService.userService.user_currency_deposits, ...this.managerService.userService.user_currency_withdrawals];
                }

                if (currency_id !== undefined) {
                    result = result.filter(x => x.currency_id === currency_id);
                }

                if (currency_type !== undefined) {
                    result = result.filter(x => this.managerService.pairService.getCurrencyById(x.currency_id)?.type === currency_type);
                }

                return result;
            } else {
                return [...this.managerService.userService.user_currency_deposits, ...this.managerService.userService.user_currency_withdrawals];
            }
        }

        return [];
    }

    GET_CURRENCY_LIST(value: number, args: any[]): any {
        if (this.managerService.pairService.currency_list_ready) {
            return this.managerService.pairService.getCurrencyList();
        }
    }

    GET_CITIES_LIST(value: any): any {

        return Object.keys(this.managerService.config.CITIES);
    }

    GET_DISTRICT_LIST(value: string): any {
        // @ts-ignore
        return this.managerService.config.CITIES[value];
    }

    GET_USER_OPERATION_BACKGROUND_COLOR(value: any): any {
        if (value.type === 'deposit') {
            return {
                color: '#17dd81'
            };
        } else {
            return {
                color: '#f11658'
            };
        }
    }

    GET_USER_OPERATION_TYPE(value: any): any {
        if (value === 'deposit') {
            // @ts-ignore
            return this.managerService.config.BALANCE.PAYING;
        } else {
            // @ts-ignore
            return this.managerService.config.BALANCE.WITHDRAWAL;
        }
    }

    GET_USER_OPERATION_INFO(value: any): string {
        // if (value.type === 'deposit'){
        //   if (value.transaction_hash !== '-'){
        //     return '<span class="text-decoration-underline">' + this.managerService.config.BALANCE.INSPECT + '</span>';
        //   } else{
        //     return value.transaction_hash;
        //   }
        // } else if (value.type === 'withdrawal'){
        //   return value.iban;
        // } else {
        //   return '';
        // }
        if (value.transaction_hash !== '-') {
            return '<span class="text-decoration-underline">' + this.managerService.config.BALANCE.INSPECT + '</span>';
        } else {
            if (value.type === 'withdrawal') {
                return value.iban;
            } else {
                return '-';
            }
        }
    }

    GET_USER_OPERATION_INFO_STYLE(value: CurrencyOperation): string {
        return this.managerService.getCursorStyleFromUserOperation(value);
    }

    GET_USER_OPERATION_ICON(value: CurrencyOperation): string {
        // @ts-ignore
        return this.managerService.config.WITHDRAWAL.STATUS_ICONS[value.status.toUpperCase()];
    }

    GET_BANK_ACCOUNT_IMG_SRC(value: string): any {
        // @ts-ignore
        return this.managerService.config.BANK_ACCOUNT_IMG_SRCS[value];
    }

    GET_CRYPTO_ACCOUNT_IMG_SRC(value: UserCryptoAccount): any {
        // @ts-ignore
        return this.managerService.config.CRYPTO_ACCOUNT_IMG_SRCS[value.block_chain];
    }

    GET_CRYPTO_TOKEN_IMG_SRC(currency_wallet: CurrencyWallet): any {
        // @ts-ignore
        return this.managerService.config.CRYPTO_TOKEN_IMG_SRCS[this.managerService.pairService.getCurrencyById(currency_wallet.currency_id).name];
    }

    GET_WITHDRAWAL_RULES_STYLE(is_last: boolean): any {
        return (is_last) ? {'font-weight': 'bold', 'font-style': 'normal'} : {
            'font-weight': 'normal',
            'font-style': 'italic'
        };
    }

    GET_WITHDRAWAL_ACCOUNT_INFO(value: any): string {
        if (this.managerService.operationsService.active_withdrawal_type === 'fiat') {
            return this.managerService.operationsService.selected_user_bank_account.bank + ' - ' + this.managerService.operationsService.selected_user_bank_account.iban;
        } else if (this.managerService.operationsService.active_withdrawal_type === 'crypto') {
            return this.managerService.operationsService.selected_user_crypto_account.block_chain + ' - ' + this.managerService.operationsService.selected_user_crypto_account.address;
        } else {
            return '';
        }
    }

    PRE_SALE_BUY_BUTTON(value: string, args: any[]): any {
        if (this.managerService.pairService.active_featured_pair_ready) {
            if (this.managerService.pairService.active_featured_pair?.sale_state === 'featuring') {
                return args[1] === true ? this.managerService.config.PRE_SALE.PRE_SALE_NOT_STARTED : this.managerService.config.PRE_SALE.BUY;
            } else if (this.managerService.pairService.active_featured_pair?.sale_state === 'pre_sale' || this.managerService.pairService.active_featured_pair?.sale_state === 'public_sale') {
                return this.managerService.config.PRE_SALE.BUY;
            } else if (this.managerService.pairService.active_featured_pair?.sale_state === 'listing') {
                return this.managerService.config.PRE_SALE.BUY_SELL;
            }
        }
    }

    RETURN_INDEX(value: string, args: any[]): any {
        return value[args[1]];
    }

    GET_USER_BANK_ACCOUNT_LIST(value: string, args: any[]): any {
        if (this.managerService.userService.user_bank_accounts_ready) {
            return this.managerService.userService.getUserBankAccounts();
        }
    }

    GET_USER_CRYPTO_ACCOUNT_LIST(value: string, args: any[]): any {
        if (this.managerService.userService.user_crypto_accounts_ready) {
            return this.managerService.userService.getCryptoAccounts();
        }
    }

    USER_TRY_CURRENCY_AVAILABLE_AMOUNT(): any {
        if (this.managerService.userService.user_ready) {
            // @ts-ignore
            return this.managerService.fancyNumber(this.managerService.userService.user.getTRYAvailableAmount());
        }
    }

    USER_ACTIVE_BASE_CURRENCY_AVAILABLE_AMOUNT(): any {
        if (this.managerService.userService.user_ready && this.managerService.pairService.active_currency_pair_ready) {
            const available_amount = this.managerService.userService.getUserCurrencyWalletByID(this.managerService.pairService.active_currency_pair!.id[0])!.available_amount;
            const decimals = this.managerService.pairService.active_currency_pair!.id[0] === 2 ? 6 : 2;
            return this.managerService.fancyNumber(available_amount, decimals);
        }
        return '';
    }

    USER_ACTIVE_QUOTE_CURRENCY_AVAILABLE_AMOUNT(): any {
        if (this.managerService.userService.user_ready && this.managerService.pairService.active_currency_pair_ready) {
            const available_amount = this.managerService.userService.getUserCurrencyWalletByID(this.managerService.pairService.active_currency_pair!.id[1])!.available_amount;
            const decimals = this.managerService.pairService.active_currency_pair!.id[1] === 2 ? 6 : 2;
            return this.managerService.fancyNumber(available_amount, decimals);
        }
        return '';
    }

    USER_OPERATION_HISTORY_AMOUNT(value: any): string {
        let decimal = 2;
        if (value.currency_id > 1)
        {
            decimal = 6;
        }
        return this.managerService.fancyNumber(value.amount, decimal);
    }

    USER_ORDER_HISTORY_AMOUNT(value: any): string {
        let decimal = 2;
        if (value.currency_pair_id && value.currency_pair_id[0] > 1)
        {
            decimal = 6;
        }
        return this.managerService.fancyNumber(value.amount, decimal);
    }


    USER_BALANCE_SUMMARY_AMOUNT(value: any, ...args: any[]): string {
        // check if value is number
        let decimal = 2;
        if (typeof value === 'number') {
            if (args[0].currency_id >  1){
                decimal = 6;
            }
            return this.managerService.fancyNumber(value, decimal);
        }
        if (value.currency_id > 1)
        {
            decimal = 6;
        }
        return this.managerService.fancyNumber(value.available_amount, decimal);
    }

    USER_BALANCE_SUMMARY_TOTAL_AMOUNT(value: any): string {
        let decimal = 2;
        if (value.currency_id > 1)
        {
            decimal = 6;
        }
        return this.managerService.fancyNumber(value.total_amount, decimal);
    }

    USER_CRYPTO_CURRENCY_AVAILABLE_AMOUNT(value: any, ...args: any[]): any {
        if (this.managerService.userService.user_ready) {

            let decimal = 2;

            if (value > 1){
                decimal = 6;
            }
            // @ts-ignore
            return this.managerService.fancyNumber(this.managerService.userService.user?.getCryptocurrencyAvailableAmount(value), decimal);
        }
    }

    SORT_BY_CREATION_TIME(value: any[] | undefined): any {
        return value?.sort((x, y) => y.creation_time - x.creation_time);
    }

    FILE_INPUT_TEXT_TRANSFORM(value: string): any {
        if (value === null) {
            return this.managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_TWO.SELECT_FILE;
        } else {
            const value_placeholder: string | undefined = value.split(/(\\|\/)/g).pop();
            if (value_placeholder === undefined) {
                return this.managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_TWO.SELECT_FILE;
            } else {
                return value_placeholder.slice(0, 18) + '...';
            }
        }
    }

    GET_USER_SUMMARY_FILTER_BACKGROUND_COLOR(value: string): any {
        if (value === this.managerService.config.BALANCE.BUY) {
            return {
                'background-color': '#2ad8a9'
            };
        }
        if (value === this.managerService.config.BALANCE.SELL) {
            return {
                'background-color': '#ff207d'
            };
        }
    }

    GET_USER_ORDER_HISTORY_FILTER_BACKGROUND_COLOR(value: string): any {
        if (value === this.managerService.config.BALANCE.BUY) {
            return {
                'background-color': '#2ad8a9'
            };
        }
        if (value === this.managerService.config.BALANCE.SELL) {
            return {
                'background-color': '#ff207d'
            };
        }
    }

    GET_USER_OPERATION_HISTORY_FILTER_BACKGROUND_COLOR(value: string): any {
        if (value === this.managerService.config.BALANCE.PAYING) {
            return {
                'background-color': '#2ad8a9'
            };
        }
        if (value === this.managerService.config.BALANCE.WITHDRAWAL) {
            return {
                'background-color': '#ff207d'
            };
        }
    }

    GET_MIN_BODY_HEIGHT(value: any, ...args: any[]): any {
        if (this.managerService.view_port === 'desktop') {
            return {'min-height': 'calc(100vh - 180px)'};
        } else if (this.managerService.view_port === 'mobile') {
            // if (this.managerService.environment.app) {
            //     const tab_bar_height: number = document.getElementById('tab-bar')?.offsetHeight ?? 0;
            //     const app_header_height: number = document.getElementById('app-header')?.offsetHeight ?? 0;
            //     return {'min-height': 'calc(100vh - ' + (tab_bar_height + app_header_height) + 'px)'};
            // } else {
            //     const mobile_header_height: number = document.getElementById('mobile-header')?.offsetHeight ?? 0;
            return {'min-height': 'calc(100vh - 112px)'};
            // }
        } else {
            return {'min-height': '100vh'};
        }
    }

    GET_FILLING_BODY_HEIGHT(value: ActivityRecord, ...args: any[]): any {
        return this.GET_MIN_BODY_HEIGHT(value, ...args);
    }

    GET_MIN_BODY_HEIGHT_OBSOLETE(value: ActivityRecord, ...args: any[]): any {
        return {};
    }

    // GET_BODY_HEIGHT(value: ActivityRecord, ...args: any[]): any {
    //     if (this.managerService.view_port === 'desktop') {
    //         return {height: 'calc(100vh - 128px)'};
    //     } else if (this.managerService.view_port === 'mobile') {
    //         if (this.managerService.environment.app) {
    //             const tab_bar_height: number = document.getElementById('tab-bar')?.offsetHeight ?? 0;
    //             const app_header_height: number = document.getElementById('app-header')?.offsetHeight ?? 0;
    //             return {height: 'calc(100vh - ' + (tab_bar_height + app_header_height) + 'px)'};
    //         } else {
    //             const mobile_header_height: number = document.getElementById('mobile-header')?.offsetHeight ?? 0;
    //             return {height: 'calc(100vh - ' + (mobile_header_height) + 'px)'};
    //         }
    //     } else {
    //         return {height: '100vh'};
    //     }
    // }

    SET_HEIGHT(value: number, ...args: any[]): any {
        return {height: value + 'px'};
    }

    GET_USER_ACTIVITY_TITLE(value: ActivityRecord, ...args: any[]): string {
        if (!value.attempt) {
            return this.managerService.config.DASHBOARD.WARNING;
        } else {
            if (value.code === 704) {
                return this.managerService.config.DASHBOARD.SUCCESSFUL_LOGIN;
            } else {
                return this.managerService.config.DASHBOARD.SUCCESSFUL_OPERATION;
            }
        }
    }

    GET_LANGUAGE_NAME(value: any, ...args: any[]): string {
        if (this.managerService.language === 'TR') {
            return 'Türkçe';
        } else if (this.managerService.language === 'EN') {
            return 'English';
        }
        return '';
    }

    GET_USER_EMAIL_SHORTENED(value: any, ...args: any[]): string {
        if (this.managerService.userService.user_ready && this.managerService.userService.user !== undefined) {
            let [domain, host] = this.managerService.userService.user.email.split('@');
            let consideration = host.split('.')[1];
            domain = domain.substr(0, 2) + '***';
            host = host.substr(0, 2) + '***';
            consideration = consideration.substr(0, 1) + '**';
            return domain + '@' + host + '.' + consideration;
        }
        return '';
    }

    SET_HREF_CLASS(value: string): any {
        // if (this.managerService.environment.app) {
        //     return value;
        // } else {
        return value.slice(0, 2) + ' class="a-color" ' + value.slice(3);
        // }
    }

    SET_HREF_SOURCE(value: string): string {  // obsolete
        // if (this.managerService.environment.app && value.includes(' href') && value.includes('.pdf')) {
        //     const href_index = value.indexOf(' href');
        //     const link_start = value.indexOf('"', href_index);
        //     const link_end = value.indexOf('"', link_start + 1);
        //     return value.substring(0, link_start + 1) + 'https://www.artiox.com/' + value.substring(link_start + 3, link_end) + this.SET_HREF_SOURCE(value.substring(link_end));
        // }
        return value;
    }

    SET_ASSET_SOURCE(value: string): string {  // obsolete
        // if (this.managerService.environment.app) {
        //     return 'https://www.artiox.com/' + value.substring(2);
        // }
        return value;
    }

    LOCAL_IMAGE_MANAGER(value: any): string {
        return this.managerService.localImageStoreManager(value);
    }

    LOCAL_IMAGE_MANAGER_TO_BE(value: any): any {
        return value;  // todo remove this pipe after implement thumbnails
        // return this.managerService.localImageStoreManager(value); // Todo
    }

    // LOCAL_ASSET_MANAGER(value: any): string {  // obsolete
    //     // if (this.managerService.environment.app && value) {
    //     //     let src = this.managerService.environment.assetsURL + this.managerService.environment.assetsAPI + value.substr(2, value.length);
    //     //     if (this.managerService.runningOnIPhone()) {
    //     //         src = src.replace('.webp', '.jpg');
    //     //     }
    //     //     return src;
    //     // } else {
    //         return value;
    //     // }
    // }

    SET_CURRENT_YEAR(value: any): string {
        return this.managerService.evaluateString(value, ['current_year'], [moment().year().toString()]);
    }

    TO_LOCAL_UPPER_CASE(value: string | undefined): string {
        if (value === undefined) {
            return '';
        }
        return value.toLocaleUpperCase(this.managerService.language);
    }

    CHECK_LENGTH_SHORTER_THAN(value: any, args: any): any {
        return value?.length < args[1];
    }

    CHECK_TIME_ARRIVED(value: number, args: any): any {
        return this.managerService.utilityService.checkTimeArrived(value);
    }

    ENTRIES(value: any): any[] {
        return Object.entries(value).map((e) => ({key: e[0], value: e[1]}));
    }

    VALUES(value: any): any[] {
        return Object.values(value);
    }

    CALLBACK(value: any, ...args: any[]): any {
        return args[0][1](...args[0].slice(2));
    }

    CALL_METHOD(value: any, ...args: any[]): any {
        // args[0][1] is the name of method (string)
        return value[args[0][1]](...args[0].slice(2));
    }

    COMPARE(value: any, ...args: any[]): any {
        return value === args[0][1];
    }

    IDENTITY(value: any, ...args: any[]): any {
        return value;
    }

    RANGE(value: any, ...args: any[]): any {
        return [...Array(value).keys()];
    }

    // BYPASS_SECURITY_TRUST_HTML(value: string, ...args: any[]): any{
    //   return this.sanitizer.bypassSecurityTrustHtml(value);
    // }
    //
    // BYPASS_SECURITY_TRUST_RESOURCE_URL(value: string, ...args: any[]): any{
    //   return this.sanitizer.bypassSecurityTrustResourceUrl(value);
    // }

    ADMIN_GET_CACHED_USER(value: any, ...args: any[]): any {
        // first arg must be admin service
        return args[0][1].getCachedUser(value);
    }

    ADMIN_GET_CACHED_USER_INFO(value: any, ...args: any[]): any {
        // first arg must be admin service
        return args[0][1].getCachedUserInfo(value);
    }

    ADMIN_GET_USER_PLATFORM(value: any, ...args: any[]): any {
        // first arg must be admin service
        return args[0][1].getUserPlatform(value);
    }

    FORMAT_EUROPEAN_TIME(value: any): any {
        // return this.managerService.moment(value).format('HH:mm:ss');
        return this.managerService.utilityService.toEuropeanTime(value + this.managerService.utilityService.global_timezone_offset);
    }

    FORMAT_EUROPEAN_DATE(value: any): any {
        // return this.managerService.moment(value).format('DD.MM.YY');
        return this.managerService.utilityService.toEuropeanDate(value + this.managerService.utilityService.global_timezone_offset);
    }

    FORMAT_EUROPEAN_DATE_TIME(value: any): any {
        // return this.managerService.moment(value).format('DD.MM.YY HH:mm:ss');
        return this.managerService.utilityService.toEuropeanDateTime(value + this.managerService.utilityService.global_timezone_offset);
    }

    FORMAT_EUROPEAN_ABBREVIATED_DATE_TIME(value: any): string {
        // return this.managerService.moment(value).format('DD.MM.YY HH:mm:ss');
        return this.managerService.utilityService.toEuropeanAbbreviatedDateTime(value + this.managerService.utilityService.global_timezone_offset, this.managerService.language);
    }

    // AWARD_PRIZE_DECLARATION(value: any, ...args: any[]): any {
    //     return this.managerService.language === 'TR' ? value + 'TL' + this.managerService.config.AWARD.AWARD_DECLARATION : this.managerService.config.AWARD.AWARD_DECLARATION + value + 'TL';
    // }

    SIGN_UP_AGREEMENTS(value: any): any {
        const agreements: any[] = [this.managerService.config.SIGN_UP.KVKK_ACCEPTED,
            this.managerService.config.SIGN_UP.CONSENT_TEXT_ACCEPTED,
            this.managerService.config.SIGN_UP.AUCTION_RULES_ACCEPTED,
            this.managerService.config.SIGN_UP.COMMERCIALS_ACCEPTED,
            this.managerService.config.SIGN_UP.DATA_PROCESSING_ACCEPTED];
        return agreements[value];
    }

    // HEADER_CONFIG_EVALUATOR(value: any): any {
    //         const pair = this.managerService.pairService.getLastNotListedPair();
    //         const lastPairName = pair.symbol.split('/')[0];
    //         // @ts-ignore
    //         const artProductConfig = this.managerService.config.ART_PRODUCTS[lastPairName];
    //         if (artProductConfig === undefined){
    //             return '';
    //         }
    //         // console.log(artProductConfig);
    //         const turkishMonths = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık' ];
    //         const englishMonths = ['January', 'February', 'March', 'Aprıl', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
    //         const artName = artProductConfig.NAME;
    //         const artistName = artProductConfig.ARTIST_NAME;
    //         let announcementText = '';
    //
    //         if (pair.sale_state === 'announcement'){
    //             const startDate = new Date(pair.public_sale_start_date);
    //             if (this.managerService.language === 'TR') {
    //                  announcementText = `${artProductConfig.ARTIST_NAME} &nbsp - &nbsp <span class="exo subtitle1 bold">  "${artProductConfig.NAME}" </span> &nbsp  ${startDate.getDay()} &nbsp  ${turkishMonths[startDate.getMonth()]} tarihinde Genel Satışta! Bu fırsatı kaçırma  🖼️`;
    //             }else {
    //                  announcementText = `${artProductConfig.ARTIST_NAME} &nbsp - &nbsp <span class="exo subtitle1 bold">  "${artProductConfig.NAME}" </span> &nbsp is on Public Sale on ${englishMonths[startDate.getMonth()]}  ${startDate.getDay()}th! Do not miss the oppurtunity  🖼️`;
    //
    //             }
    //         } else if (pair.sale_state === 'public-sale'){
    //
    //             const artProduct =  this.managerService.pairService.getArtProductList().filter(value => value.symbol === pair.symbol)[0];
    //             const endDate = new Date(pair.public_sale_end_date);
    //             if (artProduct.pre_sale_supply_stock !== 0){
    //
    //                 if (this.managerService.language === 'TR') {
    //                      announcementText = `${artProductConfig.ARTIST_NAME} &nbsp - &nbsp <span class="exo subtitle1 bold">  "${artProductConfig.NAME}" </span> &nbsp  Genel Satışta! Bu fırsatı kaçırma  🖼️`;
    //                 }else {
    //                      announcementText = `${artProductConfig.ARTIST_NAME} &nbsp - &nbsp <span class="exo subtitle1 bold">  "${artProductConfig.NAME}" </span> &nbsp is on Public Sale! Do not miss the oppurtunity  🖼️`;
    //
    //                 }
    //             }
    //             else{
    //                 if (this.managerService.language === 'TR') {
    //                      announcementText = `${artProductConfig.ARTIST_NAME} &nbsp - &nbsp <span class="exo subtitle1 bold">  "${artProductConfig.NAME}" </span> &nbsp  ${endDate.getDay()} &nbsp  ${turkishMonths[endDate.getMonth()]} tarihinde listeleniyor! Bu fırsatı kaçırma  🖼️`;
    //                 }else {
    //                      announcementText = `${artProductConfig.ARTIST_NAME} &nbsp - &nbsp <span class="exo subtitle1 bold">  "${artProductConfig.NAME}" </span> &nbsp wıll be listed on ${englishMonths[endDate.getMonth()]}  ${endDate.getDay()}th! Do not miss the oppurtunity  🖼️`;
    //
    //
    //                 }
    //             }
    //         }
    //         return announcementText;
    // }

    FORMAT_PHONE_NUMBER(value: any): any {
        const input = value.replace(/\D/g, '').substring(0, 10);
        const zip = input.substring(0, 3);
        const middle = input.substring(3, 6);
        const lastFirst = input.substring(6, 8);
        const lastSecond = input.substring(8, 10);


        if (value > 6) {
            return `(${zip}) ${middle} ${lastFirst} ${lastSecond}`;
        } else if (value > 3) {
            return `(${zip}) ${middle}`;
        } else if (value > 0) {
            return `(${zip}`;
        }
    }

    VALUE_TYPE(value: any): string {
        if (value === 'TRY') {
            return 'TL';
        }
        return 'TL';  // todo
    }

    transform(value: any, ...args: any[]): any {
        if (!args.length) {
            return value;
        } else {
            // @ts-ignore
            return this[ManagerPipeArgumentTemplate[args[0]]](value, args);
        }
    }

}
