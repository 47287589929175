<div [@.disabled]="!animationStateActive" [@animationState]="animationState" (@animationState.start)="animate($event)" (@animationState.done)="animate($event)" class="snack-bar">
  <div class="snack-bar-body">
    <div [class.fail-bg]="data.status === SnackBarStatusTemplate.NEGATIVE"
         [class.success-bg]="data.status === SnackBarStatusTemplate.POSITIVE"
         class="snack-bar-color"></div>
    <div [class.fail-icon]="data.status === SnackBarStatusTemplate.NEGATIVE"
         [class.success-icon]="data.status === SnackBarStatusTemplate.POSITIVE"></div>
    <div class="snack-bar-content">
      <div class="snack-bar-info">
        <div class="snack-bar-info-heading">
          {{data.heading}}
        </div>
        <div class="snack-bar-info-content">
          {{data.content}}
        </div>
      </div>
    </div>
    <button (click)="close(true)" class="btn-native btn-close">
      <svg style="height: 20px; width: 20px;">
        <use [attr.xlink:href]='"./assets/images/close.svg#close"' fill="white"/>
      </svg>
    </button>
  </div>
</div>
