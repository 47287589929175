import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../services/manager.service';

@Component({
  selector: 'app-service-header',
  templateUrl: './service-header.component.html',
  styleUrls: ['./service-header.component.css']
})
export class ServiceHeaderComponent implements OnInit {


  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {

  }

}
