import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';

@Component({
  selector: 'app-trade-mobile',
  templateUrl: './trade-mobile.component.html',
  styleUrls: ['./trade-mobile.component.css']
})
export class TradeMobileComponent implements OnInit, AfterViewInit {

  @ViewChild('window_one') window_one: any = undefined;
  @ViewChild('window_two') window_two: any = undefined;

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.window_one.nativeElement.style.minHeight = (window.innerHeight - 120).toString() + 'px';
    this.window_two.nativeElement.style.minHeight = (window.innerHeight - 120).toString() + 'px';
  }

  scrollDown(): void {
    window.scroll({top: this.window_two.nativeElement.offsetTop, behavior: 'smooth'});
  }

}
