<div class="position-relative px-5 pt-5 d-flex flex-column align-items-center justify-content-center" style="width: 500px;
 background-image: radial-gradient(circle 1000px at 72.3% 1991.3%, #1e2028 0%, #21232d 100%);">
  <div class="d-flex w-100 justify-content-end">
    <svg style="margin-top: -16px; margin-right: -8px; height: 18px; width: 18px;" (click)="managerService.closeModal(modal_id)">
      <use [attr.xlink:href]='"./assets/images/exit_icon.svg#exit_icon"' fill="white"/>
    </svg>
  </div>
  <div class="pt-3 w-100 d-flex flex-row align-items-center justify-content-center"
       style="font-family: bebas-neue-regular, sans-serif; font-size: 20px; font-weight: bold; line-height: 1.2; letter-spacing: 2.5px; color: #f4f5f9;">
    {{managerService.config.WITHDRAWAL.ADD_BANK_ACCOUNT.HEADING | managerPipe}}
  </div>
  <form [formGroup]="addBankAccountForm" class="d-flex flex-column align-items-center justify-content-center my-4" style="width: 100%; height: fit-content; z-index: 2">
    <div class="d-flex flex-column" style="width: 100%; min-width: 240px;">
      <div class="w-100 d-flex flex-column">
        <span class='input-heading' style="color: #f4f5f9">{{managerService.config.WITHDRAWAL.ADD_BANK_ACCOUNT.IBAN | managerPipe}}</span>
        <label>
          <input class="input-field" formControlName="iban" required
                 style="border-radius: 4px; border: solid 1px #ffffff; background-color: #ffffff; box-shadow: none;" type="text">
        </label>
        <div #warning_iban class="d-none warning" style="margin: 0px 20px 0"></div>
        <span class='input-heading' style="color: #f4f5f9">{{managerService.config.WITHDRAWAL.ADD_BANK_ACCOUNT.NAME_SURNAME | managerPipe}}</span>
        <label>
          <input class="input-field" formControlName="concatenated_name_field" required
                 style="border-radius: 4px; border: solid 1px #ffffff; background-color: #ffffff; box-shadow: none;" type="text">
        </label>
        <div #warning_name_surname class="d-none warning" style="margin: 0px 20px 0"></div>
        <span class='input-heading' style="color: #f4f5f9">{{managerService.config.WITHDRAWAL.ADD_BANK_ACCOUNT.ACCOUNT_NAME | managerPipe}}</span>
        <label>
          <input class="input-field" formControlName="label" required
                 style="border-radius: 4px; border: solid 1px #ffffff; background-color: #ffffff; box-shadow: none;" type="text">
        </label>
        <div #warning_account_name class="d-none warning" style="margin: 0px 20px 0"></div>
        <button (click)="addBankAccount()" [class.button-inactive]="addBankAccountForm.invalid" class="mt-4 btn-native" id="save-account-button">
          {{managerService.config.WITHDRAWAL.ADD_BANK_ACCOUNT.SAVE_ACCOUNT | managerPipe}}
        </button>
      </div>
    </div>
  </form>
</div>
