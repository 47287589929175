<div class="w-100 d-flex flex-column align-items-start justify-content-start"
     style="background-color: #fcfdff; max-height: 1160px;">

  <form [formGroup]="changePasswordForm"
        class="w-100 d-flex flex-column align-items-start justify-content-start p-3" style="height: fit-content;">
    <div class="d-flex flex-column" style="width: 100%; min-width: 240px;">
      <div class="w-100"
           style="font-family:'Roboto Condensed', sans-serif; font-size: 16px; letter-spacing: 0.75px; color: #404352;">
        {{managerService.config.SECURITY.CHANGE_PASSWORD.FORM_HEADING_MOBILE | managerPipe}}
      </div>
      <div class="w-100 d-flex flex-column">
        <label class="position-relative mt-3 d-flex flex-row" style="height: 30px;">
          <input [type]="(old_password_again_visible | managerPipe) ? 'text' : 'password'"
                 class="w-100 input-field-underline" formControlName="old_password"
                 placeholder="{{managerService.config.SECURITY.CHANGE_PASSWORD.CURRENT_PASSWORD | managerPipe}}"
                 required
                 style="padding: 0 12px">
          <svg (click)="old_password_again_visible = !old_password_again_visible" class="password-eye" style="right: 0">
            <use *ngIf="(!old_password_again_visible | managerPipe)"
                 [attr.xlink:href]='"./assets/images/eye_hidden.svg#eye_hidden"' fill="#9DA5B7"/>
            <use *ngIf="(old_password_again_visible | managerPipe)"
                 [attr.xlink:href]='"./assets/images/eye_visible.svg#eye_visible"' fill="#9DA5B7"/>
          </svg>
        </label>
        <div #success_text class="d-none mx-2" style="font-size: 10px; color: var(--artiox-blue);"></div>
        <div #warning_old_password class="d-none warning" style="margin: 4px 12px 0; font-size: 10px; letter-spacing: normal;"></div>

        <label class="position-relative mt-4 d-flex flex-row" style="height: 30px;">
          <input [type]="(new_password_visible | managerPipe) ? 'text' : 'password'"
                 class="w-100 input-field-underline" formControlName="new_password"
                 placeholder="{{managerService.config.SECURITY.CHANGE_PASSWORD.NEW_PASSWORD | managerPipe}}"
                 required style="padding: 0 12px">
          <svg (click)="new_password_visible = !new_password_visible" class="password-eye" style="right: 0">
            <use *ngIf="(!new_password_visible | managerPipe)"
                 [attr.xlink:href]='"./assets/images/eye_hidden.svg#eye_hidden"' fill="#9DA5B7"/>
            <use *ngIf="(new_password_visible | managerPipe)"
                 [attr.xlink:href]='"./assets/images/eye_visible.svg#eye_visible"' fill="#9DA5B7"/>
          </svg>
        </label>
        <div #info_text class="mx-2" style="font-size: 10px; color: var(--artiox-blue);">
          {{managerService.config.SECURITY.CHANGE_PASSWORD.PASSWORD_RULES | managerPipe}}
        </div>
        <div #warning_new_password class="d-none warning" style="margin: 4px 12px 0; font-size: 10px; letter-spacing: normal;"></div>

        <label class="position-relative mt-4 d-flex flex-row" style="height: 30px;">
          <input [type]="(new_password_control_visible | managerPipe) ? 'text' : 'password'"
                 class="w-100 input-field-underline" formControlName="new_password_control"
                 placeholder="{{managerService.config.SECURITY.CHANGE_PASSWORD.NEW_PASSWORD_AGAIN | managerPipe}}"
                 required
                 style="padding: 0 12px">
          <svg (click)="new_password_control_visible = !new_password_control_visible" class="password-eye"
               style="right: 0">
            <use *ngIf="(!new_password_control_visible | managerPipe)"
                 [attr.xlink:href]='"./assets/images/eye_hidden.svg#eye_hidden"' fill="#9DA5B7"/>
            <use *ngIf="(new_password_control_visible | managerPipe)"
                 [attr.xlink:href]='"./assets/images/eye_visible.svg#eye_visible"' fill="#9DA5B7"/>
          </svg>
        </label>
        <div #warning_new_password_control class="d-none warning" style="margin: 4px 12px 0; font-size: 10px; letter-spacing: normal;"></div>
      </div>

      <button (click)="changePasswordValidator()" class="btn-native" id="change-password-button"
              style="width: 145px; height: 33px; font-family: Roboto, sans-serif; font-size: 13px; font-weight: 300; letter-spacing: normal;">
        {{managerService.config.SECURITY.CHANGE_PASSWORD.SAVE_CHANGES | managerPipe}}
      </button>
    </div>
  </form>

</div>


