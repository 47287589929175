import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ManagerService} from "../../../../../services/manager.service";

@Component({
  selector: 'app-auction-bid-rules-mobile',
  templateUrl: './auction-bid-rules-mobile.component.html',
  styleUrls: ['./auction-bid-rules-mobile.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AuctionBidRulesMobileComponent implements OnInit {
  @Input() modal_id: string = '';
  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

}
