import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';

@Component({
  selector: 'app-basic-pair-list-desktop',
  templateUrl: './basic-pair-list-desktop.component.html',
  styleUrls: ['./basic-pair-list-desktop.component.css']
})
export class BasicPairListDesktopComponent implements OnInit {

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }
}
