import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legal-auction-cancellation-and-refund-agreement-desktop',
  templateUrl: './legal-auction-cancellation-and-refund-agreement-desktop.component.html',
  styleUrls: ['./legal-auction-cancellation-and-refund-agreement-desktop.component.css']
})
export class LegalAuctionCancellationAndRefundAgreementDesktopComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
