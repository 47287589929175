<div *ngIf="!managerService.maintenance | managerPipe"
     class="d-flex flex-column justify-content-center align-items-center text-center"
     style="height: 100vh; font-family: Roboto, sans-serif; font-size: 12px; background-color: #1e1f25; color: #d5d8e2;">
    <div *ngIf="loading_animation">
        <div class="d-flex flex-row align-items-center justify-content-center p-3">
            <div class="position-absolute d-flex flex-column align-items-center justify-content-center">
                <svg class="loader" style="height:100px; width:100px;">
                    <use [attr.xlink:href]='"./assets/images/artiox_icon.svg#artiox_icon"' class="h-100 w-100" fill="#f4f5f9"/>
                </svg>
            </div>
        </div>
    </div>
    <div *ngIf="!loading_animation" class="d-flex flex-column justify-content-center align-items-center text-center">
        <img src="./assets/images/no_connection.svg" style="width: 100px; height: 100px; margin-bottom: 60px">
        Connection failed, please check<br>your network settings.
        <div style="color: var(--artiox-blue); margin-top: 40px">
            <svg #rotate_arrow (click)="rotate()"
                 style="height: 14px; width: 14px; margin-top: -4px; transition: 0.4s">
                <use [attr.xlink:href]='"./assets/images/refresh_icon.svg#refresh_icon"' fill="var(--artiox-blue)"/>
            </svg>
            Retry
        </div>
    </div>

</div>

<div *ngIf="managerService.maintenance | managerPipe" style="height: 80px"></div>
<div *ngIf="managerService.maintenance | managerPipe"
     style="width: 80vw; min-height:calc(100vh - 216px); margin: 5vh 0 5vh 10vw;padding: 5vh 5vw 0 5vw;border-radius: 5vw;box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.13);background-color: #ffffff;overflow: auto">
    <div style="margin-bottom: 8px"><img src="./assets/images/artiox_logo.svg" alt="artiox" height="12px"/></div>
    <div style="width:100%; height: 90%; display: flex; flex-direction: column; justify-content: center; align-items: center">
        <div style="font-family: Roboto,sans-serif;font-size: 18px;font-weight: bold;letter-spacing: 1px;color: #404352; margin-bottom: 36px">
            PLANLI SİSTEM BAKIMI
        </div>
        <div style="width: 100%"><img src="./assets/images/maintenance.svg" alt="coming soon"/></div>
        <div style="font-family: Roboto,sans-serif;font-size: 11px;line-height: 1.2;letter-spacing: 1.4px;color: #404352; margin-bottom: 9px">
            Değerli Kullanıcılarımız,<br/><br/>
            Planlı bir şekilde altyapı ve sistem güncellemesi gerçekleştirmekteyiz.<br/>
            Sistemimiz kısa sürede yeniden aktif olacaktır.<br/>
            Anlayışınız için teşekkür ederiz.<br/>
        </div>
    </div>
</div>
