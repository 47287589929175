import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
  selector: 'app-left-info-desktop',
  templateUrl: './left-info-desktop.component.html',
  styleUrls: ['./left-info-desktop.component.css']
})
export class LeftInfoDesktopComponent implements OnInit {

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

}
