import {Component, OnInit, ViewChild} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-auction-bid-desktop',
    templateUrl: './auction-bid-desktop.component.html',
    styleUrls: ['./auction-bid-desktop.component.css']
})
export class AuctionBidDesktopComponent implements OnInit {

    @ViewChild('dropdown') dropdown: any;
    dropdownExtended: boolean = false;
    constructor(public managerService: ManagerService) {
    }

    ngOnInit(): void {
    }

    toggleDropdown(): void{
        if (!this.dropdownExtended) {
            this.dropdown.nativeElement.classList.remove('d-none');
            this.dropdown.nativeElement.classList.add('d-flex');
        }
        else{
            this.dropdown.nativeElement.classList.remove('d-flex');
            this.dropdown.nativeElement.classList.add('d-none');
        }
        this.dropdownExtended = !this.dropdownExtended;
    }
}
