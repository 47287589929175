import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ManagerService} from '../../../../services/manager.service';
import {AdminService} from '../../../../services/admin.service';
import {Delivery, UserInfo} from '../../../../services/user.service';
import {UtilityService} from '../../../../services/utility.service';

@Component({
    selector: 'app-admin-panel-mobile',
    templateUrl: './admin-panel-mobile.component.html',
    styleUrls: ['./admin-panel-mobile.component.css']
})
export class AdminPanelMobileComponent implements OnInit, AfterViewInit, OnDestroy {

    searchForm = new UntypedFormGroup({
        id: new UntypedFormControl(null),
        tc_no: new UntypedFormControl(null),
        concatenated_name_field: new UntypedFormControl(null),
    });

    queryForm = new UntypedFormGroup({
        user_id: new UntypedFormControl(null),
        concatenated_name_field: new UntypedFormControl(null),
        deposit_reference_code: new UntypedFormControl(null),
    });

    changeDeliveryForm = new UntypedFormGroup({
        delivery_id: new UntypedFormControl(null, [Validators.required]),
        user_id: new FormControl<number | null>(null, [Validators.required]),
        status: new FormControl<string | null>(null, [Validators.required]),
        company: new FormControl<string | null>(null, [Validators.required]),
        tracking_number: new FormControl<string | null>(null, [Validators.required]),
        tracking_link: new FormControl<string | null>(null, [Validators.required]),
        tracking_status: new FormControl<string | null>(null, [Validators.required]),
        estimated_arrival_time: new FormControl<string | null>(null, [Validators.required]),
    });

    executeCurrencyDepositForm = new UntypedFormGroup({
        user_id: new UntypedFormControl(null, [Validators.required]),
        currency_id: new UntypedFormControl(null, [Validators.required]),
        amount: new UntypedFormControl(null, [Validators.required])
    });

    adminDepositForm = new UntypedFormGroup({
        user_id: new UntypedFormControl(null, [Validators.required]),
        operation_class: new UntypedFormControl('Currency', [Validators.required]),
        collection_id: new UntypedFormControl(null, [Validators.required]),
        amount: new UntypedFormControl(null, [Validators.required])
    });

    adminWithdrawalForm = new UntypedFormGroup({
        user_id: new UntypedFormControl(null, [Validators.required]),
        operation_class: new UntypedFormControl('Currency', [Validators.required]),
        collection_id: new UntypedFormControl(null, [Validators.required]),
        amount: new UntypedFormControl(null, [Validators.required])
    });

    adminAddArtProductForm = new UntypedFormGroup({
        name: new UntypedFormControl(null, [Validators.required]),
        symbol: new UntypedFormControl(null, [Validators.required]),
        value: new UntypedFormControl(null, [Validators.required]),
        contract_address: new UntypedFormControl('-', [Validators.required]),
        circulating_supply_ratio: new UntypedFormControl(0, [Validators.required]),
        pre_sale_supply_ratio: new UntypedFormControl(0, [Validators.required]),
        total_supply: new UntypedFormControl(0, [Validators.required]),
        right_to_own_ratio: new UntypedFormControl(0.92, [Validators.required]),
        color: new UntypedFormControl('["#000000", "#000000"]', [Validators.required]),
        pre_sale_start_date: new UntypedFormControl(0, [Validators.required]),
        pre_sale_end_date: new UntypedFormControl(0, [Validators.required]),
        public_sale_start_date: new UntypedFormControl(0, [Validators.required]),
        public_sale_end_date: new UntypedFormControl(0, [Validators.required]),
        listing_date: new UntypedFormControl(0, [Validators.required]),
        featured: new UntypedFormControl(true, [Validators.required])
    });

    adminAddCurrencyForm = new UntypedFormGroup({
        name: new UntypedFormControl(null, [Validators.required]),
        symbol: new UntypedFormControl(null, [Validators.required]),
        value: new UntypedFormControl(null, [Validators.required]),
        color: new UntypedFormControl('["#000000", "#000000"]', [Validators.required]),
    });

    adminAddAuctionProductForm: FormGroup = new FormGroup({
        name: new FormControl<string | null>(null, [Validators.required]),
        year: new FormControl<number | null>(null, [Validators.required]),
        catalog: new FormControl<string>('Daily', [Validators.required]),
        lot: new FormControl<number | null>(null, [Validators.required, Validators.min(0)]),
        opening_value: new FormControl<number | null>(null, [Validators.required, Validators.min(0.001)]),
        current_value: new FormControl<number | null>(0.0, [Validators.required, Validators.min(0)]),
        value_step: new FormControl<number | null>(null, [Validators.required, Validators.min(0.001)]),
        value_type: new FormControl<string>('TRY', [Validators.required]),
        auction_start_date: new FormControl<number | null>(null, [Validators.required, Validators.min(0.001)]),
        auction_end_date: new FormControl<number | null>(null, [Validators.required, Validators.min(0.001)]),
        auction_init_end_date: new FormControl<number | null>(null, [Validators.required, Validators.min(0.001)]),
        end_date_increment: new FormControl<number | null>(null, [Validators.required, Validators.min(0.001)]),
        featured: new FormControl<boolean>(true, [Validators.required]),
        assurance: new FormControl<number>(0.0, [Validators.required, Validators.min(0)]),
        buyer_fee: new FormControl<number>(0.1, [Validators.required, Validators.min(0)]),
        seller_fee: new FormControl<number>(0.1, [Validators.required, Validators.min(0)]),
        tax: new FormControl<number>(0.2, [Validators.required, Validators.min(0)]),
    });

    adminDelistForm = new UntypedFormGroup({
        pair: new UntypedFormControl(null, [Validators.required]),
        price: new FormControl<number>(0, [Validators.required]),
    });

    changeUserEmailForm = new UntypedFormGroup({
        user_id: new UntypedFormControl(null),
        old_email: new UntypedFormControl(null, [Validators.required, Validators.email]),
        new_email: new UntypedFormControl(null, [Validators.required, Validators.email]),
    });

    changeUserInfoForm = new UntypedFormGroup({
        user_id: new UntypedFormControl(null, [Validators.required]),
        tc_no: new FormControl<number | null>(null, [Validators.required, UtilityService.tcNoFormValidator]),
        name: new FormControl<string | null>(null, [Validators.required]),
        surname: new FormControl<string | null>(null, [Validators.required]),
        birth_year: new FormControl<number | null>(null, [Validators.required, UtilityService.adultBirthYearFormValidator]),
        town: new FormControl<string | null>(null, [Validators.required]),
        address: new FormControl<string | null>(null, [Validators.required]),
        phone_number: new FormControl<string | null>(null, [UtilityService.phoneNoFormValidator]),  // not required
    });

    public getUsersForm = new UntypedFormGroup({
        start_index: new UntypedFormControl(0, [Validators.required]),
        end_index: new UntypedFormControl(0, [Validators.required]),
    });

    @ViewChild('user_search_result') user_search_result: any = undefined;
    @ViewChild('user_info_search_result') user_info_search_result: any = undefined;
    @ViewChild('concatenated_name_search_result') concatenated_name_search_result: any = undefined;

    constructor(public managerService: ManagerService, public adminService: AdminService) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.adminService.user_search_result = this.user_search_result;
        this.adminService.user_info_search_result = this.user_info_search_result;
        this.adminService.concatenated_name_search_result = this.concatenated_name_search_result;

        this.adminService.getUserCount();
        this.adminService.getUsersAndInfo(this.getUsersForm);
        this.adminService.getOnlineUsers();
        this.adminService.getCurrencies();
        this.adminService.getArtProducts();
        this.adminService.getWithdrawalRequests();
        this.adminService.getSecondLevelKYCAppeals();
        this.adminService.getPairs();
        this.adminService.getCurrencyPairs();
        this.adminService.getBidEngineStatus();
        this.adminService.getDeliveries();

        this.changeUserInfoForm.get('user_id')?.valueChanges.subscribe(() => {
            const user_id = this.changeUserInfoForm.get('user_id')?.value;
            const user_info: UserInfo = this.adminService.getCachedUserInfo(user_id);

            this.changeUserInfoForm.get('tc_no')?.setValue(user_info.tc_no);
            this.changeUserInfoForm.get('name')?.setValue(user_info.name);
            this.changeUserInfoForm.get('surname')?.setValue(user_info.surname);
            this.changeUserInfoForm.get('birth_year')?.setValue(user_info.birth_year);
            this.changeUserInfoForm.get('town')?.setValue(user_info.town);
            this.changeUserInfoForm.get('address')?.setValue(user_info.address);
            this.changeUserInfoForm.get('phone_number')?.setValue(user_info.phone_number);
        });

        this.changeDeliveryForm.get('delivery_id')?.valueChanges.subscribe(() => {
            const delivery_id = this.changeDeliveryForm.get('delivery_id')?.value;
            const delivery: Delivery | undefined = this.adminService.delivery_list.find((x: Delivery) => x.delivery_id === delivery_id);
            if (delivery === undefined) {
                return;
            }
            this.changeDeliveryForm.get('user_id')?.setValue(delivery.user_id);
            this.changeDeliveryForm.get('status')?.setValue(delivery.status);
            this.changeDeliveryForm.get('company')?.setValue(delivery.company);
            this.changeDeliveryForm.get('tracking_number')?.setValue(delivery.tracking_number);
            this.changeDeliveryForm.get('tracking_link')?.setValue(delivery.tracking_link);
            this.changeDeliveryForm.get('tracking_status')?.setValue(delivery.tracking_status);
            this.changeDeliveryForm.get('estimated_arrival_time')?.setValue(delivery.estimated_arrival_time);
        });
    }

    ngOnDestroy(): void {
        if (this.adminService.time_interval !== undefined) {
            clearInterval(this.adminService.time_interval);
        }
    }

}
