<h1>
    İptal ve İade Koşulları
</h1>

<h1>
    TÜKETİCİ HAKLARI – CAYMA – İPTAL İADE
    KOŞULLARI
</h1>

<h2>
    GENEL:
</h2>
<ol>
    <li><p>www.artiox.com web sitesi üzerinden elektronik ortamda sipariş verdiğiniz
        takdirde, size sunulan ön bilgilendirme formunu ve mesafeli satış sözleşmesini
        kabul etmiş sayılırsınız.</p></li>
    <li><p>Alıcılar, satın aldıkları ürünün satış ve teslimi ile ilgili olarak 6502 sayılı
        Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği
        (RG:27.11.2014/29188) hükümleri ile yürürlükteki diğer yasalara tabidir.</p></li>
    <li><p>Ürün sevkiyat masrafı olan kargo ücretleri alıcılar tarafından ödenecektir.</p></li>
    <li><p>Satın alınan her bir ürün, 30 günlük yasal süreyi aşmamak kaydı ile alıcının
        gösterdiği adresteki kişi ve/veya kuruluşa teslim edilir. Bu süre içinde ürün teslim
        edilmez ise, Alıcılar sözleşmeyi sona erdirebilir.</p></li>
    <li><p>Satın alınan ürünler, eksiksiz ve müzayedelerimiz de belirtilen niteliklere uygun
        ve varsa sertifikaları ile birlikte teslim edilmek zorundadır.</p></li>
    <li><p>Satın alınan ürünün satılmasının imkansızlaşması durumunda, satıcı bu
        durumu öğrendiğinden itibaren 3 gün içinde yazılı olarak alıcıya bu durumu
        bildirmek zorundadır. 14 gün içinde de toplam bedel Alıcı’ya iade edilmek
        zorundadır.</p></li>

    <h2>SATIN ALINAN ÜRÜN BEDELİ ÖDENMEZ İSE:</h2>

    <li><p>Alıcı, satın aldığı ürün bedelini ödemez veya banka kayıtlarında iptal ederse,
        Satıcının ürünü teslim yükümlülüğü sona erer.</p></li>
    <li><p>Alıcının üyeliği, yeni satınalma ve pey verme işlemlerine, site yöneticisi
        tarafından kapatılır.</p></li>
    <h2>KREDİ KARTININ YETKİSİZ KULLANIMI İLE YAPILAN ALIŞVERİŞLER:</h2>

    <li><p>Ürün teslim edildikten sonra, alıcının ödeme yaptığı kredi kartının yetkisiz
        kişiler tarafından haksız olarak kullanıldığı tespit edilirse ve satılan ürün bedeli
        ilgili banka veya finans kuruluşu tarafından Satıcı'ya ödenmez ise, Alıcı, sözleşme
        konusu ürünü 3 gün içerisinde nakliye gideri SATICI’ya ait olacak şekilde
        SATICI’ya iade etmek zorundadır.</p></li>

    <h2>ÖNGÖRÜLEMEYEN SEBEPLERLE ÜRÜN SÜRESİNDE TESLİM EDİLEMEZ İSE:</h2>

    <li><p>Satıcı’nın öngöremeyeceği mücbir sebepler oluşursa ve ürün süresinde teslim
        edilemez ise, durum Alıcı’ya bildirilir. Alıcı, siparişin iptalini, ürünün benzeri ile
        değiştirilmesini veya engel ortadan kalkana dek teslimatın ertelenmesini talep
        edebilir. Alıcı siparişi iptal ederse; ödemeyi nakit ile yapmış ise iptalinden itibaren
        14 gün içinde kendisine nakden bu ücret ödenir. Alıcı, ödemeyi kredi kartı ile
        yapmış ise ve iptal ederse, bu iptalden itibaren yine 14 gün içinde ürün bedeli
        bankaya iade edilir, ancak bankanın alıcının hesabına 2-3 hafta içerisinde
        aktarması olasıdır.</p></li>
    <h2>
        ALICININ ÜRÜNÜ KONTROL ETME YÜKÜMLÜLÜĞÜ:
    </h2>
    <li><p>Alıcı, sözleşme konusu mal/hizmeti teslim almadan önce muayene edecek;
        ezik, kırık, ambalajı yırtılmış vb. hasarlı ve ayıplı mal/hizmeti kargo şirketinden
        teslim almayacaktır. Teslim alınan mal/hizmetin hasarsız ve sağlam olduğu kabul
        edilecektir. ALICI, Teslimden sonra mal/hizmeti özenle korumak zorundadır.</p></li>

    <h2>
        CAYMA HAKKI:
    </h2>

    <li><p>ALICI; cayma hakkında Online Müzayede Katılım Şartlarında belirtilen tüm
        şartları peşinen kabul etmiş sayılır.</p></li>
    <li><p>Yapılacak müzayede TBK 274 ve davamı maddelerindeki düzenlemeye göre
        gerçekleştiğinden ve Tüketici Kanun'nun 48. maddesinde düzenlenen mesafeli
        satış sözleşmesi olarak kabul edilemeyeceğinden ihaleye katılan kişinin cayma
        hakkı yoktur. Artırma üzerine kalan kişi eserin bedelini 7 gün içinde tam olarak
        ödemediği takdirde Artiox Teknoloji Yazılım A.Ş.. dilerse satış akdini feshe,
        %20 cezai şartı ve uğradığı bilcümle zararlarını ticari faiz ile fatura tarihinden
        itibaren işletilecek aylık %15 faiz ile birlikte tahsile yetkilidir. Artiox Teknoloji
        Yazılım A.Ş.’nin sair dava ve talep hakları saklıdır. Fatura bedeli tahsil edilinceye
        kadar geçecek süre içinde eserin muhafazası için Artiox Teknoloji Yazılım
        A.Ş..’nin yapacağı bilcümle masraf alıcı tarafından ödenmedikçe satılan eser
        alıcıya teslim edilmez.</p></li>

    <h2>TEMERRÜT HALİ VE HUKUKİ SONUÇLARI</h2>

    <li><p>ALICI, ödeme işlemlerini kredi kartı ile yaptığı durumda temerrüde düştüğü
        takdirde, kart sahibi banka ile arasındaki kredi kartı sözleşmesi çerçevesinde faiz
        ödeyeceğini ve bankaya karşı sorumlu olacağını kabul, beyan ve taahhüt eder. Bu
        durumda ilgili banka hukuki yollara başvurabilir; doğacak masrafları ve vekâlet
        ücretini ALICI’dan talep edebilir ve her koşulda ALICI’nın borcundan dolayı
        temerrüde düşmesi halinde, ALICI, borcun gecikmeli ifasından dolayı SATICI’nın
        uğradığı zarar ve ziyanını ödeyeceğini kabul eder.</p></li>

    <h2>ÖDEME VE TESLİMAT</h2>
    <li><p>
        Banka Havalesi veya EFT (Elektronik Fon Transferi) yaparak,Alıcı: ARTIOX TEKNOLOJİ YAZILIM ANONİM ŞİRKETİ
        Banka: GARANTİ BANKASI
        HESAP NUMARASI: 1145- 6899270
        IBAN: TR75 0006 2001 1450 0006 8992 70
        Türk Lirası olarak ödemenizi yapabilirsiniz.
    </p></li>

    <li><p>Ödeme işleminizi yaptıktan, 7-14 iş günü içerisinde sitemizde belirtmiş
        olduğunuz açık adresinize ürünleriniz Kargo ile alıcı ödemeli olarak
        gönderilecektir.</p></li>
</ol>