<div class="w-100 d-flex flex-column overflow-hidden" style="background-color: #fcfdff;">
  <app-basic-pair-header-desktop>
  </app-basic-pair-header-desktop>
  <div class="w-100" style="height: 1px; background: #E2E2E2"></div>
  <div class="position-absolute" style="top: 150px; right: 0;">
    <svg (click)="this.managerService.tooltipService.toggleAll()" style="height: 24px; width: 24px; padding: 4px; border-radius: 4px; box-shadow: 0 2px 4px #abafc7; user-select: none;">
      <use [attr.xlink:href]='"./assets/images/info.svg#info"' fill="#404352"/>
    </svg>
  </div>
  <div [class]="(managerService.inner_width >= 1100 | managerPipe) ? 'justify-content-center':'justify-content-start'" class="d-flex flex-row align-items-start overflow-auto"
       style="min-height: 750px; margin-top: 16px">

    <div style="width: 300px; min-width: 300px;">

      <app-art-product-repost-list-desktop>
      </app-art-product-repost-list-desktop>
    </div>
    <div class="d-flex flex-column align-items-center justify-content-center" style="width: 45%; min-width: 500px;">
      <app-basic-art-product-canvas-desktop class="w-100"></app-basic-art-product-canvas-desktop>
      <app-basic-trade-desktop class="w-100" style="max-width: 750px;"></app-basic-trade-desktop>
      <app-art-product-info-desktop class="w-100" style="max-width: 750px;"></app-art-product-info-desktop>
    </div>
    <div style="width: 300px; min-width: 300px;">
      <app-basic-pair-list-desktop></app-basic-pair-list-desktop>
    </div>
  </div>
</div>

