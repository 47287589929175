<div>
  <div [style]="height | managerPipe : managerService.mpa_template.SET_HEIGHT"
       class="w-100 grid overflow-hidden">
    <app-basic-pair-list-mobile [style]="managerService.navigationService.current_component_index | managerPipe : managerService.mpa_template.SLIDER_BY_VW"
                                style="transition: 0.4s ease-in-out; background-color: #1e1f25;">
    </app-basic-pair-list-mobile>
    <app-basic-trade-mobile [class.no-height]="managerService.navigationService.current_component_index === 0"
                            [style]="managerService.navigationService.current_component_index | managerPipe : managerService.mpa_template.SLIDER_BY_VW"
                            style="transition: 0.4s ease-in-out;">
    </app-basic-trade-mobile>
  </div>
</div>


