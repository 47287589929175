<div (click)="closeAllDropdown()" *ngIf="(managerService.logged_in && !(0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO)) | managerPipe"
     class="d-flex flex-column mx-3">

    <div class="d-flex flex-column mx-1">
        <span [innerHTML]="managerService.config.PAYMENT.DELIVERY_ADDRESS.HEADER_RAW | managerPipe" class="title my-4"></span>
        <div (click)="managerService.openAddDeliveryAddressModal(null)"
             *ngIf=" delivery_address_count < 3" class="d-flex flex-row "
             style="cursor: pointer">
            <img alt="add address" class="mr-2" src="./assets/images/add_address.svg"
                 style="width: 16px; height: 16px">
            <div class="add-address-text text-center">{{managerService.config.PAYMENT.DELIVERY_ADDRESS.TEXT | managerPipe}}</div>
        </div>

        <div class="d-flex flex-row" style="gap: 48px; margin-top: 16px">
            <div
                *ngFor="let delivery_address of (managerService.user_info_pipe | managerPipe: managerService.mpa_template.GET_USER_DELIVERY_ADDRESS: 0: managerService.language_pipe)">
                <div
                     class="d-flex flex-column"
                     style="width: 200px; height: 140px;border-radius: 4px;border: 1px solid #9DA5B7;">
                    <div class="d-flex w-100 flex-row justify-content-between"
                         style="background-color: #F5F6F7;border-radius: 4px; padding: 8px 16px;">
                            <span
                                style="overflow: hidden;color: #404352;text-overflow: ellipsis;font-family: Roboto, sans-serif;font-size: 16px;font-style: normal;font-weight: 500;line-height: 19px;">
                                {{delivery_address.label}}
                            </span>
                        <div (click)="managerService.stopProp($event);toggleDropdown(delivery_dropdown)"
                             class="edit-button">
                            <svg style="width:16px; height: 17px; cursor: pointer">
                                <use [attr.xlink:href]='"./assets/images/edit_icon.svg#edit_icon"'/>
                            </svg>
                            <div #delivery_dropdown class="edit-dropdown" style="user-select: none">
                                <div (click)="managerService.openAddDeliveryAddressModal(delivery_address)"
                                     class="d-flex justify-content-center edit-select"
                                     style="padding: 16px 48px;">
                                    <svg style="width:16px; height: 16px">
                                        <use [attr.xlink:href]='"./assets/images/pencil.svg#pencil"'/>
                                    </svg>
                                    <div
                                        style="color: #404352;font-family: Roboto, sans-serif;font-size: 10px;font-style: normal;font-weight: 500;line-height: 19px;margin-left: 8px">
                                        {{managerService.config.PAYMENT.EDIT | managerPipe}}
                                    </div>
                                </div>
                                <div (click)="managerService.openDeleteAddressModal(delivery_address.address_id, 'delivery')"
                                     class="d-flex justify-content-center edit-select"
                                     style="padding: 16px 48px;">
                                    <svg style="width:16px; height: 16px">
                                        <use [attr.xlink:href]='"./assets/images/trash.svg#trash"'/>
                                    </svg>
                                    <div
                                        style="color: #404352;font-family: Roboto, sans-serif;font-size: 10px;font-style: normal;font-weight: 500;line-height: 19px;margin-left: 8px">
                                        {{managerService.config.PAYMENT.DELETE | managerPipe}}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="d-flex w-100 flex-row" style="padding: 8px; align-items: center;">
                            <span
                                style="overflow: hidden;color: #404352;text-overflow: ellipsis;white-space: nowrap;font-family: Roboto, sans-serif;font-size: 12px;font-style: normal;font-weight: 400;line-height: 19px;">{{delivery_address.address}} </span>
                    </div>
                </div>
            </div>
        </div>
        <div [innerHTML]="managerService.config.PAYMENT.BILLING_ADDRESS.HEADER_RAW | managerPipe"
             class=" mb-4 title " style="margin-top: 32px"></div>
        <div (click)="managerService.openAddBillingAddressModal(null)" *ngIf="billing_address_count < 3" class="d-flex flex-row "
             style="cursor: pointer">
            <img alt="add address" class="mr-2" src="./assets/images/add_address.svg"
                 style="width: 16px; height: 16px">

            <div
                class="add-address-text text-center">{{managerService.config.PAYMENT.BILLING_ADDRESS.TEXT | managerPipe}}</div>
        </div>

        <div class="d-flex flex-row" style="gap: 48px; margin-top: 16px">
            <div
                *ngFor="let billingaddress of (managerService.user_info_pipe | managerPipe: managerService.mpa_template.GET_USER_BILLING_ADDRESS)">
                <div
                     class="d-flex flex-column"
                     style="width: 200px; height: 140px;border-radius: 4px; border: 1px solid #9DA5B7;">
                    <div class="d-flex w-100 flex-row justify-content-between"
                         style="background-color: #F5F6F7;border-radius: 4px; padding: 8px 16px;">
                            <span
                                style="overflow: hidden;color: #404352;text-overflow: ellipsis;font-family: Roboto, sans-serif;font-size: 16px;font-style: normal;font-weight: 500;line-height: 19px;">
                                {{billingaddress.label}}
                            </span>

                        <div (click)="managerService.stopProp($event);toggleDropdown(billing_dropdown)"
                             class="edit-button">
                            <svg style="width:16px; height: 17px; cursor: pointer">
                                <use [attr.xlink:href]='"./assets/images/edit_icon.svg#edit_icon"'/>
                            </svg>
                            <div #billing_dropdown class="edit-dropdown" style="user-select: none">
                                <div (click)="managerService.openAddBillingAddressModal(billingaddress)"
                                     class="d-flex justify-content-center edit-select"
                                     style="padding: 16px 48px;">
                                    <svg style="width:16px; height: 16px">
                                        <use [attr.xlink:href]='"./assets/images/pencil.svg#pencil"'/>
                                    </svg>
                                    <div
                                        style="color: #404352;font-family: Roboto, sans-serif;font-size: 10px;font-style: normal;font-weight: 500;line-height: 19px;margin-left: 8px">
                                        {{managerService.config.PAYMENT.EDIT | managerPipe}}
                                    </div>
                                </div>
                                <div (click)="managerService.openDeleteAddressModal(billingaddress.address_id, 'billing')"
                                     class="d-flex justify-content-center edit-select"
                                     style="padding: 16px 48px;">
                                    <svg style="width:16px; height: 16px">
                                        <use [attr.xlink:href]='"./assets/images/trash.svg#trash"'/>
                                    </svg>
                                    <div
                                        style="color: #404352;font-family: Roboto, sans-serif;font-size: 10px;font-style: normal;font-weight: 500;line-height: 19px;margin-left: 8px">
                                        {{managerService.config.PAYMENT.DELETE | managerPipe}}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="d-flex w-100 flex-row" style="padding: 8px; align-items: center;">
                            <span
                                style="overflow: hidden;color: #404352;text-overflow: ellipsis;white-space: nowrap;font-family: Roboto, sans-serif;font-size: 12px;font-style: normal;font-weight: 400;line-height: 19px;">{{billingaddress.address}} </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
