import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
    selector: 'app-auction-catalog-type-desktop',
    templateUrl: './auction-catalog-type-desktop.component.html',
    styleUrls: ['./auction-catalog-type-desktop.component.css']
})
export class AuctionCatalogTypeDesktopComponent implements OnInit {

    constructor(public managerService: ManagerService) {
    }

    ngOnInit(): void {
    }

}
