<div [style]="managerService.show_sign_up_or_login | managerPipe: managerService.mpa_template.GET_MIN_BODY_HEIGHT_OBSOLETE"
     class="d-flex flex-column w-100 h-100">
    <div class="w-100 d-flex flex-column" style="background-color: #fcfdff;box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);">

        <div class="mx-3 mt-4 mb-3" style="font-family: Roboto, sans-serif; font-size: 14px; letter-spacing: 0.7px; color: #272a3b;">
      <span>
        <svg style="height: 20px; width: 20px;">
          <use [attr.xlink:href]='"./assets/images/gavel.svg#gavel"' fill="#272a3b" stroke="#272a3b"/>
        </svg>
      </span>{{managerService.config.ACCOUNT_AUCTION.HEADING | managerPipe}} </div>

        <div class="d-flex flex-row justify-content-around" style="color: #9da5b7">
            <button
                (click)="managerService.active_account_auction_panel = managerService.active_account_auction_panel_template.MY_BIDS"
                [class.btn-account-navigator-active]="managerService.active_account_auction_panel === managerService.active_account_auction_panel_template.MY_BIDS | managerPipe"
                class="w-50 btn-native btn-account-navigator" style="font-size: 13px; letter-spacing: normal;"
                type="button">
                {{managerService.config.ACCOUNT_AUCTION.MY_BIDS | managerPipe}}
                <div
                    [class.d-none]="managerService.active_account_auction_panel !== managerService.active_account_auction_panel_template.MY_BIDS | managerPipe"
                    class="mx-auto" style="width: 90px; border-bottom: 1px solid #4fc0d2; margin-top: 2px"></div>
            </button>
            <button
                (click)="managerService.active_account_auction_panel = managerService.active_account_auction_panel_template.MY_FOLLOWINGS"
                [class.btn-account-navigator-active]="managerService.active_account_auction_panel === managerService.active_account_auction_panel_template.MY_FOLLOWINGS | managerPipe"
                class="w-50 btn-native btn-account-navigator" style="font-size: 13px; letter-spacing: normal;"
                type="button">
                {{managerService.config.ACCOUNT_AUCTION.MY_FOLLOWINGS | managerPipe}}
                <div
                    [class.d-none]="managerService.active_account_auction_panel !== managerService.active_account_auction_panel_template.MY_FOLLOWINGS | managerPipe"
                    class="mx-auto" style="width: 90px; border-bottom: 1px solid #4fc0d2; margin-top: 2px"></div>
            </button>
            <button
                (click)="managerService.active_account_auction_panel = managerService.active_account_auction_panel_template.MY_WONS"
                [class.btn-account-navigator-active]="managerService.active_account_auction_panel === managerService.active_account_auction_panel_template.MY_WONS | managerPipe"
                class="w-50 btn-native btn-account-navigator" style="font-size: 13px; letter-spacing: normal;"
                type="button">
                {{managerService.config.ACCOUNT_AUCTION.MY_WONS | managerPipe}}
                <div
                    [class.d-none]="managerService.active_account_auction_panel !== managerService.active_account_auction_panel_template.MY_WONS | managerPipe"
                    class="mx-auto" style="width: 90px; border-bottom: 1px solid #4fc0d2; margin-top: 2px"></div>
            </button>
        </div>
    </div>

    <div *ngIf="((!managerService.logged_in) || (0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO)) | managerPipe"
         class="px-4" style="box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22);  background-color: #fcfdff; min-height: 650px">
        <div class="pt-3 mb-2" style="font-family: Roboto, sans-serif; font-size: 20px; font-weight: bold; line-height: 2; letter-spacing: 1px; color: #4a4e70;">
            {{managerService.config.DEPOSIT.NO_KYC_HEADING | managerPipe}}
        </div>
        <div class="my-2" style="font-family: Roboto, sans-serif; font-size: 14px; color: #4a4e70;">
            {{managerService.config.AUCTION.NO_KYC_DESCRIPTION | managerPipe}}
        </div>

        <button (click)="managerService.navigationManager({url: '/account/security', active_security_panel: managerService.active_security_panel_template.IDENTITY_VERIFICATION, current_component_index: 0});"
                class="btn-native sign-up-login-button mx-4 my-3"
                style="height: 40px; width:160px; border-radius: 20px;
              font-family: Roboto, sans-serif; font-size: 16px; line-height: 1.3; letter-spacing: 0.3px; color: #ffffff;">
            {{managerService.config.DASHBOARD.LEVEL_UP | managerPipe}}
        </button>
    </div>

    <app-my-bids-mobile
        [class.d-none]="!(managerService.active_account_auction_panel | managerPipe: managerService.mpa_template.ACTIVE_ACCOUNT_AUCTION_PANEL_MY_BIDS)"></app-my-bids-mobile>
    <app-my-followings-mobile
        [class.d-none]="!(managerService.active_account_auction_panel | managerPipe: managerService.mpa_template.ACTIVE_ACCOUNT_AUCTION_PANEL_MY_FOLLOWINGS)"></app-my-followings-mobile>
    <app-my-wons-mobile
        [class.d-none]="!(managerService.active_account_auction_panel | managerPipe: managerService.mpa_template.ACTIVE_ACCOUNT_AUCTION_PANEL_MY_WONS)"></app-my-wons-mobile>
</div>
