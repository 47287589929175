<div [class.invisible]="!managerService.art_product_info_display_status | managerPipe"
     [style]="(!managerService.art_product_info_display_status | managerPipe) ? {'background-color': 'transparent'} : {'background-color': 'rgba(0,0,0, 0.75)'}"
     class="position-absolute"
     id="art_product_info_container"
     style="z-index: 200; transition: background-color 0.3s ease-in-out; left: 0;  width: 100%; height: 100%;">
  <div [style]="(!managerService.art_product_info_display_status | managerPipe) ? {transform: 'translateX(-100%)'} : {transform: 'translateX(0)'}"
       class="d-flex flex-column align-items-start justify-content-start"
       style="position: absolute; height: fit-content; transition: 0.3s; background-color: #1e1f25; margin-top: 120px; width: 320px; left: 0;">
    <p
      class="w-100 d-flex flex-row align-items-center justify-content-center my-3 mx-auto art-product-info-text"
      style="font-size: 18px;">
      <svg class="mr-2" style="height: 14px; width: 14px;">
        <use [attr.xlink:href]='"./assets/images/info.svg#info"' fill="white"/>
      </svg>
      {{managerService.config.ART_PRODUCTS.ABOUT_ART_PRODUCT | managerPipe}}
    </p>
    <p [innerHTML]="managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SIGNATURE: managerService.language_pipe"
       class="my-1 px-3 art-product-info-text"></p>
    <p [innerHTML]="managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_MEDIUM : managerService.language_pipe"
       class="my-1 px-3 art-product-info-text"></p>
    <p [innerHTML]="managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SIZE"
       class="my-1 px-3 art-product-info-text"></p>
    <p [innerHTML]="managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_PAIR_YEAR"
       class="my-1 px-3 art-product-info-text"></p>
    <div #condition
         class="w-100 my-1 px-3 art-product-info-text toggle-span">
      <p (click)="condition.classList.toggle('toggle-span')"
         class="py-1 my-1 d-flex flex-row align-items-center justify-content-between"
         type="button">
        {{managerService.config.ART_PRODUCTS.CONDITION | managerPipe}}
        <svg style="height: 20px; width: 20px;">
          <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="white"/>
        </svg>
      </p>
      <span
        *ngFor="let star of managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_PAIR_CONDITION"
        class="m-1">
      <svg id="star" style="height: 20px; width: 20px;" viewBox="0 0 353.5 352.89" xmlns="http://www.w3.org/2000/svg">
      <path [style]="(star===1 | managerPipe) ? {fill: '#ffbc04'} : {fill: '#404352'}"
            d="M66.26,352.77,110.71,210,0,133H133.33L176.24,0c2.27,6.71,4.41,12.72,6.43,18.83q18.06,54.9,36,109.89a5,5,0,0,0,5.69,4q62.76,0,125.54,0h3.6v.69l-34.79,23.8L243.6,208.64c-1.94,1.29-1.62,2.55-1,4.33q10,31.11,19.92,62.26l24.84,77.66L176.2,263.75Z"/>
      <path [style]="(star===0.5 || star===1 | managerPipe) ? {fill: '#ffbc04'} : {fill: '#404352'}"
            d="M176.24,0,133.33,133H0L110.71,210,66.26,352.77l109.94-89,.55.44V1.49Z"/>
      </svg>
    </span>
    </div>
    <div #expert_report
         class="w-100 my-1 px-3 art-product-info-text toggle-a">
      <p (click)="expert_report.classList.toggle('toggle-a')"
         class="py-1 my-1 d-flex flex-row align-items-center justify-content-between"
         type="button">
        {{managerService.config.ART_PRODUCTS.EXPERT_REPORT | managerPipe}}
        <svg style="height: 20px; width: 20px;">
          <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="white"/>
        </svg>
      </p>
      <a [href]="managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_PAIR_EXPERT_REPORT | managerPipe: managerService.mpa_template.SET_ASSET_SOURCE"
         [innerHTML]="managerService.config.ART_PRODUCTS.EXPERT_REPORT_LINK | managerPipe"
         class="art-product-info-text" style="color: #9da5b7; font-size: 12px; text-decoration: underline!important;"
         target="_blank">
      </a>
    </div>
    <div #insurance_report
         class="w-100 my-1 px-3 art-product-info-text toggle-a">
      <p (click)="insurance_report.classList.toggle('toggle-a')"
         class="py-1 my-1 d-flex flex-row align-items-center justify-content-between"
         type="button">
        {{managerService.config.ART_PRODUCTS.INSURANCE_REPORT | managerPipe}}
        <svg style="height: 20px; width: 20px;">
          <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="white"/>
        </svg>
      </p>
      <a [href]="managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_PAIR_INSURANCE_REPORT | managerPipe: managerService.mpa_template.SET_ASSET_SOURCE"
         [innerHTML]="managerService.config.ART_PRODUCTS.INSURANCE_REPORT_LINK | managerPipe"
         class="art-product-info-text"
         style="color: #9da5b7; font-size: 12px; text-decoration: underline!important;"
         target="_blank">
      </a>
    </div>
    <div #exhibition_report
         class="w-100 my-1 px-3 art-product-info-text toggle-span">
      <p (click)="exhibition_report.classList.toggle('toggle-span')"
         class="py-1 my-1 d-flex flex-row align-items-center justify-content-between"
         type="button">
        {{managerService.config.ART_PRODUCTS.EXHIBITION_REPORT | managerPipe}}
        <svg style="height: 20px; width: 20px;">
          <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="white"/>
        </svg>
      </p>
      <span [innerHTML]="managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_PAIR_EXHIBITION_REPORT: managerService.language_pipe"
            class="art-product-info-text" style="font-size: 12px">
    </span>
    </div>

  </div>
</div>
