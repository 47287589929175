import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    AbstractControl,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {DeliveryAddress} from '../../../../../services/user.service';
import {ManagerService} from '../../../../../services/manager.service';
import {HttpClient} from '@angular/common/http';
import {UtilityService} from '../../../../../services/utility.service';


// interface DeliveryAddressData {
//     delivery_address_id?: number;
//     label: string;
//     province: string;
//     district: string;
//     address: string;
//     phone_number: string;
// }

@Component({
    selector: 'app-add-delivery-address-mobile',
    templateUrl: './add-delivery-address-mobile.component.html',
    styleUrls: ['./add-delivery-address-mobile.component.css']
})
export class AddDeliveryAddressMobileComponent implements OnInit, OnDestroy {
    @Input() modal_id: string = '';

    @ViewChild('phoneNumber') phoneNumber: any = undefined;

    deliveryAddressForm = new UntypedFormGroup({
        label: new UntypedFormControl(''),
        phone_number: new UntypedFormControl(this.managerService.userService.user_info?.phone_number, [Validators.required, this.phoneNumberValidator()]),
        province: new UntypedFormControl('', [Validators.required]),
        district: new UntypedFormControl('', [Validators.required]),
        address: new UntypedFormControl('', [Validators.required, Validators.minLength(10)]),
    });
    errorMap: { [key: string]: string } = {
        phone_number: this.managerService.config.USER_INFORMATION.PHONE_NUMBER,
        province: this.managerService.config.USER_INFORMATION.CITY,
        district: this.managerService.config.BILLING_ADDRESS.INDIVIDUAL_HEADERS.DISTRICT,
        address: this.managerService.config.USER_INFORMATION.ADDRESS
    };

    tc_number_visible: boolean = false;
    deliveryInfo: DeliveryAddress | undefined;

    subscription_list: (Subscription | undefined)[] = [];

    constructor(public managerService: ManagerService, private httpClient: HttpClient) {
    }

    ngOnInit(): void {
        this.subscription_list.push(this.managerService.userService.selected_user_delivery_address_changed.subscribe(deliveryAddress => {
            if (deliveryAddress === null) {
                this.deliveryAddressForm.reset();
            } else {
                this.updateFormGroup(deliveryAddress);
            }
        }));
        this.subscription_list.push(this.deliveryAddressForm.get('phone_number')?.valueChanges.subscribe(() => {
            this.formatPhoneNumber();
        }));
    }

    phoneNumberValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const phoneNumber: string = control.value?.replace(/\D/g, '').substring(0, 10);
            if (UtilityService.validatePhoneNumber(phoneNumber)) {
                return null;
            }
            return {wrongNumber: control.value};
        };
    }

    formatPhoneNumber(): void {
        if (this.deliveryAddressForm.get('phone_number')?.value) {
            const input = this.deliveryAddressForm.get('phone_number')?.value.replace(/\D/g, '').substring(0, 10);
            const zip = input.substring(0, 3);
            const middle = input.substring(3, 6);
            const lastFirst = input.substring(6, 8);
            const lastSecond = input.substring(8, 10);
            let phone = '';
            if (input.length > 8) {
                phone = `(${zip}) ${middle} ${lastFirst} ${lastSecond}`;
            } else if (input.length > 6) {
                phone = `(${zip}) ${middle} ${lastFirst}`;
            } else if (input.length > 3) {
                phone = `(${zip}) ${middle}`;
            } else if (input.length > 0) {
                phone = `(${zip}`;
            }
            if (this.deliveryAddressForm.get('phone_number')?.value !== phone && phone.length > 0) {
                this.deliveryAddressForm.get('phone_number')?.setValue(phone);
            }
        }
    }

    saveDeliveryAddress(): void {
        if (!this.validateFormGroups()) {
            return;
        }

        const data: any = {
            delivery_address_id: this.deliveryInfo?.address_id,
            label: this.deliveryAddressForm.get('label')?.value,
            province: this.deliveryAddressForm.get('province')?.value,
            district: this.deliveryAddressForm.get('district')?.value,
            address: this.deliveryAddressForm.get('address')?.value,
            phone_number: this.deliveryAddressForm.get('phone_number')?.value.replace(/\D/g, '').substring(0, 10),
        };
        const url = this.managerService.userService.selected_user_address_operation === 'update' ? 'update_user_delivery_address_service' : 'add_user_delivery_address_service';
        this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + url, data).subscribe(
            response => {
                // @ts-ignore
                const content = this.managerService.config.RESPONSE_TABLE[response.code];
                this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                if (response.is_okay) {
                    this.closeModal();
                    if (url === 'add_user_delivery_address_service') {
                        this.managerService.marketingService.logEvent('address_added', {});
                    }
                }
            }));
    }

    updateFormGroup(deliveryAddress: DeliveryAddress): void {
        if (this.managerService.userService.selected_user_address_operation === 'update') {
            this.deliveryInfo = deliveryAddress;
            this.deliveryAddressForm.setValue({
                label: deliveryAddress.label,
                phone_number: deliveryAddress.phone_number,
                province: deliveryAddress.province,
                district: deliveryAddress.district,
                address: deliveryAddress.address
            });
        }
    }

    validateFormGroups(): boolean {
        let requiredKeys = '';
        if (!this.deliveryAddressForm.valid) {

            // tslint:disable-next-line:forin
            for (const controlsKey in this.deliveryAddressForm.controls) {
                const controlMember = this.deliveryAddressForm.get(controlsKey);
                if (controlMember?.errors?.required) {
                    requiredKeys += this.errorMap[controlsKey] + ' ';
                }
                if (controlMember?.errors?.wrongNumber) {
                    // @ts-ignore
                    const content = this.managerService.config.RESPONSE_TABLE[2010];
                    this.managerService.popSnackBar(content.is_okay, content.message);

                }
                if (controlMember?.errors?.minlength) {
                    // @ts-ignore
                    const content = this.managerService.config.RESPONSE_TABLE[2011];
                    this.managerService.popSnackBar(content.is_okay, content.message);
                }
            }
            if (requiredKeys !== '') {
                // @ts-ignore
                const content = this.managerService.config.RESPONSE_TABLE[2012];
                this.managerService.popSnackBar(content.is_okay, content.message + ' ' + requiredKeys);
            }
            return false;
        }
        return true;
    }

    closeModal(): void {
        // this.deliveryAddressForm.reset();
        this.managerService.closeModal(this.modal_id);
    }

    ngOnDestroy(): void {
        this.subscription_list.forEach((subscription) => {
            subscription?.unsubscribe();
        });
    }

}
