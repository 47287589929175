<div class="w-100 d-flex flex-column align-items-start justify-content-start" style="height: 600px;">
  <div class="d-flex flex-row align-items-center justify-content-center">
    <div>
      <p class="mt-3 exo header3 regular"
         style=" color: #404352;">
        "{{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_NAME}}"
      </p>
    </div>
    <div class="d-flex flex-column align-items-center justify-content-center">
      <app-tooltip [direction]="managerService.tooltipService.tooltip_direction_template.RIGHT | managerPipe" class="d-none position-relative"
                   id="active-art-product-name-tooltip">
        {{managerService.config.TOOLTIP.BASIC.ACTIVE_ART_PRODUCT_NAME_TOOLTIP | managerPipe}}
      </app-tooltip>
    </div>
  </div>

  <div class="d-flex flex-row align-items-center justify-content-center mb-4">
    <div>
      <span class="mr-1 exo subtitle1 bold" style="color: #404352;">{{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_ARTIST_NAME: managerService.language_pipe}}</span>
      <span [class.d-none]="managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_ARTIST_BORN_DEATH | managerPipe: managerService.mpa_template.CHECK_LENGTH_SHORTER_THAN : 4"
            class="exo subtitle1 regular" style="color: #404352;">
        ({{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_ARTIST_BORN_DEATH}})
      </span>
    </div>
    <div class="d-flex flex-column align-items-center justify-content-center">
      <app-tooltip [direction]="managerService.tooltipService.tooltip_direction_template.RIGHT | managerPipe" class="d-none position-relative"
                   id="active-art-product-artist-born-death-tooltip">
        {{managerService.config.TOOLTIP.BASIC.ACTIVE_ART_PRODUCT_ARTIST_BORN_DEATH_TOOLTIP | managerPipe}}
      </app-tooltip>
    </div>
  </div>

  <p [innerHTML]="managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_SIGNATURE: managerService.language_pipe"
     class="mb-1 art-product-info-text mt-2 barlow body2 regular " style="font-style: italic;"></p>
  <p [innerHTML]="managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_MEDIUM: managerService.language_pipe"
     class="mb-1 art-product-info-text barlow body2 regular" style="font-style: italic;"></p>
  <p [innerHTML]="managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_SIZE: managerService.language_pipe"
     class="mb-1 art-product-info-text barlow  body2 regular" style="font-style: italic;"></p>
  <p [innerHTML]="managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_YEAR: managerService.language_pipe"
     class="mb-1 art-product-info-text mb-2 barlow body2 regular" style="font-style: italic;"></p>

  <div class="d-flex flex-row align-items-center justify-content-center mt-4 exo subtitle1 bold"
       style=" color: #404352;">
    <div class="d-flex mr-2">
      {{managerService.config.BASIC.PAIR_HEADER.ART_PRODUCT_MARKET_VALUE | managerPipe}}
    </div>
    <div class="d-flex flex-column align-items-center justify-content-center" style="height: 14px;">
      <app-tooltip [direction]="managerService.tooltipService.tooltip_direction_template.TOP | managerPipe" class="d-none position-relative"
                   id="art-product-market-value-tooltip">
        {{managerService.config.TOOLTIP.BASIC.ART_PRODUCT_MARKET_VALUE_TOOLTIP | managerPipe}}
      </app-tooltip>
      <svg (click)="managerService.toggleTooltip('art-product-market-value-tooltip')"
           style="height: 14px; width: 14px;">
        <use [attr.xlink:href]='"./assets/images/info.svg#info"' fill="#404352"/>
      </svg>
    </div>

  </div>
  <span class="w-100 p-1 mt-2 mb-4 text-center barlow subtitle1 regular" style=" color: #595e7e; background-color: #eceef7;">
    {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_VALUE}}₺
  </span>

  <div class="d-flex flex-column align-items-center justify-content-center">

  </div>

  <div class="w-100 d-flex flex-column align-items-center justify-content-center mt-4">
    <div class="d-flex flex-row align-items-center justify-content-center " style="margin-bottom: -20px">
      <app-tooltip [direction]="managerService.tooltipService.tooltip_direction_template.TOP | managerPipe" class="d-none position-relative"
                   id="art-product-report-list-tooltip">
        {{managerService.config.TOOLTIP.BASIC.ART_PRODUCT_REPORT_LIST_TOOLTIP | managerPipe}}
      </app-tooltip>
    </div>
    <div #condition class="w-100 mt-2 mb-0 art-product-info-text toggle-span barlow body2 regular">
      <p (click)="condition.classList.toggle('toggle-span')"
         class="py-1 my-1 d-flex flex-row align-items-center justify-content-between"
         type="button">
        {{managerService.config.ART_PRODUCTS.CONDITION | managerPipe}}
        <svg style="height: 20px; width: 20px;">
          <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="black"/>
        </svg>
      </p>
      <span *ngFor="let star of managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_CONDITION"
            class="m-1">
      <svg id="star" style="height: 20px; width: 20px;" viewBox="0 0 353.5 352.89" xmlns="http://www.w3.org/2000/svg">
        <path [style]="(star===1 | managerPipe) ? {fill: '#ffbc04'} : {fill: '#404352'}"
              d="M66.26,352.77,110.71,210,0,133H133.33L176.24,0c2.27,6.71,4.41,12.72,6.43,18.83q18.06,54.9,36,109.89a5,5,0,0,0,5.69,4q62.76,0,125.54,0h3.6v.69l-34.79,23.8L243.6,208.64c-1.94,1.29-1.62,2.55-1,4.33q10,31.11,19.92,62.26l24.84,77.66L176.2,263.75Z"/>
        <path [style]="(star===0.5 || star===1 | managerPipe) ? {fill: '#ffbc04'} : {fill: '#404352'}"
              d="M176.24,0,133.33,133H0L110.71,210,66.26,352.77l109.94-89,.55.44V1.49Z"/>
      </svg>
    </span>
    </div>
    <div #expert_report class="w-100 my-0 art-product-info-text toggle-a barlow body2 regular">
      <p (click)="expert_report.classList.toggle('toggle-a')"
         class="py-1 my-1 d-flex flex-row align-items-center justify-content-between"
         type="button">
        {{managerService.config.ART_PRODUCTS.EXPERT_REPORT | managerPipe}}
        <svg style="height: 20px; width: 20px;">
          <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="black"/>
        </svg>
      </p>
      <a [href]="managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_EXPERT_REPORT"
         [innerHTML]="managerService.config.ART_PRODUCTS.EXPERT_REPORT_LINK | managerPipe"
         class="art-product-info-text" style="color: #9da5b7; font-size: 12px; text-decoration: underline!important;"
         target="_blank">
      </a>
    </div>
    <div #insurance_report class="w-100 my-0 art-product-info-text toggle-a barlow body2 regular">
      <p (click)="insurance_report.classList.toggle('toggle-a')"
         class="py-1 my-1 d-flex flex-row align-items-center justify-content-between"
         type="button">
        {{managerService.config.ART_PRODUCTS.INSURANCE_REPORT | managerPipe}}
        <svg style="height: 20px; width: 20px;">
          <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="black"/>
        </svg>
      </p>
      <a [href]="managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_INSURANCE_REPORT"
         [innerHTML]="managerService.config.ART_PRODUCTS.INSURANCE_REPORT_LINK | managerPipe"
         class="art-product-info-text"
         style="color: #9da5b7; font-size: 12px; text-decoration: underline!important;"
         target="_blank">
      </a>
    </div>
    <div #exhibition_report class="w-100 my-0 art-product-info-text toggle-span barlow body2 regular">
      <p (click)="exhibition_report.classList.toggle('toggle-span')"
         class="py-1 my-1 d-flex flex-row align-items-center justify-content-between"
         type="button">
        {{managerService.config.ART_PRODUCTS.EXHIBITION_REPORT | managerPipe}}
        <svg style="height: 20px; width: 20px;">
          <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="black"/>
        </svg>
      </p>
      <span [innerHTML]="managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_EXHIBITION_REPORT: managerService.language_pipe"
            class="art-product-info-text" style="font-size: 12px">
    </span>
    </div>
    <div class="w-100 my-0 art-product-info-text toggle-span barlow body2 regular">
      <p (click)="managerService.moreInfo()"
         class="py-1 my-1 d-flex flex-row align-items-center justify-content-between"
         type="button">
        {{managerService.config.ART_PRODUCTS.MORE | managerPipe}}
      </p>
    </div>
  </div>

</div>
