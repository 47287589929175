import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../services/manager.service';

@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.css']
})
export class DepositComponent implements OnInit {

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
    // this.managerService.marketingService.setInsiderEvent('Confirmation');  // maybe move navManager ??
  }

}
