import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewChildren, ViewEncapsulation} from '@angular/core';
import {ManagerService} from '../../../../services/manager.service';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UserAccountVerificationCredentials} from "../../../../services/user.service";

@Component({
  selector: 'app-sign-up-desktop',
  templateUrl: './sign-up-desktop.component.html',
  styleUrls: ['./sign-up-desktop.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SignUpDesktopComponent implements OnInit, OnDestroy {
  protected passwordPipe: boolean = false;
  protected buttonDisabled: boolean = false;
  protected checkBoxes = ['kvkk_accepted', 'consent_text_accepted', 'auction_rules_accepted', 'commercials_accepted', 'data_processing_accepted'];
  userSignUpForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('',
[Validators.required, Validators.minLength(8),
      this.regexValidator(new RegExp('.*[a-z]+.*$'), {lowercase: ''}), this.regexValidator(new RegExp('.*[0-9]+.*$'), {number: ''}), this.regexValidator(new RegExp('.*[A-Z]+.*$'), {uppercase: ''}),
      this.regexValidator(new RegExp('.*[!@#$%^&*(),.?":{}|<>_+=/;€£\\[\\]-]+.*$'), {special_char: ''}),  Validators.maxLength(50)]),
    kvkk_accepted: new UntypedFormControl(false, [ Validators.requiredTrue]),
    consent_text_accepted: new UntypedFormControl(false, [Validators.requiredTrue]),
    auction_rules_accepted: new UntypedFormControl(false, []),
    commercials_accepted: new UntypedFormControl(false, []),
    data_processing_accepted: new UntypedFormControl(false, [])
  });
  @ViewChild('email_input') email_input: any = undefined;
  @ViewChild('password_input') password_input: any = undefined;
  @ViewChild('email_error') email_error: any;
  @ViewChild('password_error') password_error: any;
  @ViewChildren('password_pattern_error') password_pattern_error: any;
  @ViewChildren('checkbox_error') checkbox_error: any;
  constructor(protected managerService: ManagerService, protected httpClient: HttpClient) { }
  public subscription_list: (Subscription | undefined)[] = [];
  ngOnInit(): void {
    this.subscription_list.push(this.userSignUpForm.get('email')?.valueChanges.subscribe(() => {
      const new_value = this.userSignUpForm.get('email')?.value.toLowerCase();
      if (new_value !== this.userSignUpForm.get('email')?.value) {
        this.userSignUpForm.get('email')?.setValue(new_value);
      }
      this.validateEmail();
    }));

    this.subscription_list.push(this.userSignUpForm.get('password')?.valueChanges.subscribe(() => {
      this.validatePasswords();
    }));

    this.subscription_list.push(this.userSignUpForm.get('kvkk_accepted')?.valueChanges.subscribe(() => {
      this.validateKVKK();
    }));

    this.subscription_list.push(this.userSignUpForm.get('consent_text_accepted')?.valueChanges.subscribe(() => {
      this.validateConsent();
    }));

    this.subscription_list.push(this.managerService.language_changed.subscribe(() => {
      this.validateEmail();
      this.validatePasswords();
      this.validateKVKK();
      this.validateConsent();
    }));
  }

  validateEmail(): void {
    if (this.email_input) {
      const controlMember = this.userSignUpForm.get('email');
      if (controlMember?.errors) {
        this.email_input.nativeElement.classList.remove('input-success');
        this.email_input.nativeElement.classList.add('input-error');
        // @ts-ignore
        if (controlMember.errors.required){
          // @ts-ignore
          this.email_error.nativeElement.children[1].innerHTML = this.managerService.config.RESPONSE_TABLE['2018'].message;
        }
        else if (controlMember.errors.email){
          // @ts-ignore
          this.email_error.nativeElement.children[1].innerHTML = this.managerService.config.RESPONSE_TABLE['2006'].message;
        }
        this.email_error.nativeElement.classList.remove('d-none');
      } else {
        this.email_input.nativeElement.classList.remove('input-error');
        this.email_input.nativeElement.classList.add('input-success');
        this.email_error.nativeElement.classList.add('d-none');
      }
    }
  }
  validateKVKK(): void{
    const controlMember = this.userSignUpForm.get('kvkk_accepted');
    const kvkk_checkbox = this.checkbox_error.first;

    if (!controlMember?.value){
      // @ts-ignore
      kvkk_checkbox.nativeElement.children[1].innerHTML = this.managerService.config.RESPONSE_TABLE['2018'].message;
      kvkk_checkbox.nativeElement.classList.remove('d-none');
    }
    else{
      kvkk_checkbox.nativeElement.classList.add('d-none');
    }
  }
  validateConsent(): void{
    const controlMember = this.userSignUpForm.get('consent_text_accepted');
    const consent_checkbox = this.checkbox_error.filter( (_: any, index: number) => index === 1 )[0];
    if (!controlMember?.value){
      // @ts-ignore
      consent_checkbox.nativeElement.children[1].innerHTML = this.managerService.config.RESPONSE_TABLE['2018'].message;
      consent_checkbox.nativeElement.classList.remove('d-none');
    }
    else{
      consent_checkbox.nativeElement.classList.add('d-none');
    }     }
  validatePasswords(): void{

    if (this.password_input) {
      const controlMember = this.userSignUpForm.get('password');
      if (controlMember?.errors) {
        this.password_input.nativeElement.classList.remove('input-success');
        this.password_input.nativeElement.classList.add('input-error');
        if (controlMember.errors.required){
          this.password_pattern_error.forEach( (el: ElementRef) => {
            el.nativeElement.classList.add('d-none');
          });
          // @ts-ignore
          this.password_error.nativeElement.classList.remove('d-none');
          // @ts-ignore
          this.password_error.nativeElement.children[1].innerHTML = this.managerService.config.RESPONSE_TABLE['2018'].message;
        }
        else{
          this.password_pattern_error.forEach( (el: ElementRef) => {
            el.nativeElement.classList.remove('d-none');
          });
          this.password_error.nativeElement.classList.add('d-none');
        }
        if (controlMember.errors.minlength === undefined){
          this.password_pattern_error.first.nativeElement.classList.add('pattern-success');
          this.password_pattern_error.first.nativeElement.classList.remove('pattern-error');
        }
        else{
          this.password_pattern_error.first.nativeElement.classList.remove('pattern-success');
          this.password_pattern_error.first.nativeElement.classList.add('pattern-error');
        }
        if (controlMember.errors.lowercase === undefined && controlMember.errors.number === undefined && controlMember.errors.uppercase === undefined){
          const pattern_error = this.password_pattern_error.filter( (_: any, index: number) => index === 1 )[0];
          pattern_error.nativeElement.classList.add('pattern-success');
          pattern_error.nativeElement.classList.remove('pattern-error');
        }
        else{
          const pattern_error = this.password_pattern_error.filter( (_: any, index: number) => index === 1 )[0];
          pattern_error.nativeElement.classList.remove('pattern-success');
          pattern_error.nativeElement.classList.add('pattern-error');
        }
        // if (controlMember.errors.uppercase === undefined){
        //   const pattern_error = this.password_pattern_error.filter( (_: any, index: number) => index === 2 )[0];
        //   pattern_error.nativeElement.classList.add('pattern-success');
        //   pattern_error.nativeElement.classList.remove('pattern-error');
        // }
        // else{
        //   const pattern_error = this.password_pattern_error.filter( (_: any, index: number) => index === 2 )[0];
        //   pattern_error.nativeElement.classList.remove('pattern-success');
        //   pattern_error.nativeElement.classList.add('pattern-error');
        // }
        if (controlMember.errors.special_char === undefined){
          this.password_pattern_error.last.nativeElement.classList.add('pattern-success');
          this.password_pattern_error.last.nativeElement.classList.remove('pattern-error');
        }
        else{
          this.password_pattern_error.last.nativeElement.classList.remove('pattern-success');
          this.password_pattern_error.last.nativeElement.classList.add('pattern-error');
        }
      }
      else{
        this.password_input.nativeElement.classList.add('input-success');
        this.password_input.nativeElement.classList.remove('input-error');
        this.password_pattern_error.forEach( (el: ElementRef) => {
          el.nativeElement.classList.add('pattern-success');
          el.nativeElement.classList.remove('pattern-error');
        });
      }
    }
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }
  signUpFormValidator(): void{
    this.validateEmail();
    this.validateKVKK();
    this.validateConsent();
    this.validatePasswords();
    if (this.userSignUpForm.valid){
      this.signUp();
    }
  }
  signUp(): void{
    if (this.buttonDisabled){
      return;
    }

    this.buttonDisabled = true;
    const data = {
      user_email: this.userSignUpForm.value.email,
      password: this.userSignUpForm.value.password,
      kvkk_accepted: this.userSignUpForm.value.kvkk_accepted,
      user_agreement_accepted: this.userSignUpForm.value.kvkk_accepted,
      consent_text_accepted: this.userSignUpForm.value.consent_text_accepted,
      auction_rules_accepted: this.userSignUpForm.value.auction_rules_accepted,
      commercials_accepted: this.userSignUpForm.value.commercials_accepted,
      data_processing_accepted: this.userSignUpForm.value.data_processing_accepted
    };

    this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'user_sign_up_service', data).subscribe(
       response => {
         setTimeout(() => {
           this.buttonDisabled = false;
         });
         // @ts-ignore
         const content = this.managerService.config.RESPONSE_TABLE[response.code];
         if (response.is_okay){
           const credentialData = {
             user_email: this.userSignUpForm.value.email,
             password: this.userSignUpForm.value.password,
           };
           this.userSignUpForm.reset({
             email: '', password: '', password_again: '',
             kvkk_accepted: false, consent_text_accepted: false, commercials_accepted: false, data_processing_accepted: false
           });
           // this.managerService.userService.user_account_verification_credentials = new UserAccountVerificationCredentials({user_email: credentialData.user_email, verification_code: ''});
           this.managerService.userService.setUserAccountVerificationCredentials(credentialData.user_email, '');
           this.managerService.userService.createUserLoginData(credentialData);
           this.managerService.navigationManager({url: '/account-verification', current_component_index: 0});
           this.managerService.marketingService.logEvent('sign_up', {});
         }
         else{
           this.email_input.nativeElement.classList.remove('input-success');
           this.email_input.nativeElement.classList.add('input-error');
           this.email_error.nativeElement.classList.remove('d-none');
           this.email_error.nativeElement.children[1].innerHTML = content.message;
         }
       }
    ));
  }

  ngOnDestroy(): void {
    this.subscription_list.forEach((subscription) => {
      subscription?.unsubscribe();
    });
  }

}
