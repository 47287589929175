import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UtilityService} from '../../../../../../services/utility.service';
import {first} from 'rxjs/operators';

@Component({
    selector: 'app-user-info-profile-desktop',
    templateUrl: './user-info-profile-desktop.component.html',
    styleUrls: ['./user-info-profile-desktop.component.css']
})
export class UserInfoProfileDesktopComponent implements OnInit, OnDestroy {
    tc_number_visible: boolean = false;
    form_enabled: boolean = false;

    @ViewChild('phone') phoneInput: any = undefined;
    @ViewChild('invalidPhone') invalidPhone: any = undefined;
    @ViewChild('city') cityInput: any = undefined;
    @ViewChild('invalidCity') invalidCity: any = undefined;
    @ViewChild('address') addressInput: any = undefined;
    @ViewChild('invalidAddress') invalidAddress: any = undefined;

    userInformationForm = new UntypedFormGroup({
        phone_number: new UntypedFormControl(this.managerService.userService.user_info?.phone_number, [Validators.required]),
        city: new UntypedFormControl(this.managerService.userService.user_info?.town, [Validators.required]),
        address: new UntypedFormControl(this.managerService.userService.user_info?.address, [Validators.required]),
    });

    subscription_list: (Subscription | undefined)[] = [];

    constructor(public managerService: ManagerService, private httpClient: HttpClient) {
    }

    ngOnInit(): void {
        this.subscription_list.push(this.managerService.userService.user_info_changed.subscribe(() => {
            this.userInformationForm.get('city')?.setValue(this.managerService.userService.user_info?.town);
            this.userInformationForm.get('address')?.setValue(this.managerService.logAndReturn(this.managerService.userService.user_info?.address));
            this.userInformationForm.get('phone_number')?.setValue(this.managerService.userService.user_info?.phone_number);
        }));

        this.subscription_list.push(this.managerService.userService.user_info_changed.pipe(first()).subscribe(() => {
            this.formatPhoneNumber();
        }));
        this.subscription_list.push(this.userInformationForm.get('phone_number')?.valueChanges.subscribe(() => {
            this.formatPhoneNumber();
        }));
    }

    formatPhoneNumber(): void {
        const input = this.userInformationForm.get('phone_number')?.value.replace(/\D/g, '').substring(0, 10);
        const zip = input.substring(0, 3);
        const middle = input.substring(3, 6);
        const lastFirst = input.substring(6, 8);
        const lastSecond = input.substring(8, 10);
        let phone = '';
        if (input.length > 8) {
            phone = `(${zip}) ${middle} ${lastFirst} ${lastSecond}`;
        } else if (input.length > 6) {
            phone = `(${zip}) ${middle} ${lastFirst}`;
        } else if (input.length > 3) {
            phone = `(${zip}) ${middle}`;
        } else if (input.length > 0) {
            phone = `(${zip}`;
        }
        if (this.userInformationForm.get('phone_number')?.value !== phone && phone.length > 0) {
            this.userInformationForm.get('phone_number')?.setValue(phone);
        }
    }

    // focus(div: HTMLDivElement): void {
    //     div.classList.add('focused');
    // }
    //
    // outFocus(div: HTMLDivElement, input: any): void {
    //     if (input === '') {
    //         div.classList.remove('focused');
    //     }
    // }

    // setCity(city: string): void {
    //     this.userInformationForm.get('city')?.setValue(city);
    // }

    // changeInformation(cancel: boolean = false): void {
    //     if (!this.form_enabled) {
    //         this.enableForm();
    //     } else {
    //         if (cancel) {
    //             this.disableForm();
    //         } else {
    //             this.validateForm();
    //         }
    //     }
    // }


    // for sake of single responsibility

    enableForm(): void {
        this.form_enabled = true;
    }

    disableForm(): void {
        this.userInformationForm.get('city')?.setValue(this.managerService.userService.user_info?.town);
        this.userInformationForm.get('address')?.setValue(this.managerService.userService.user_info?.address);
        this.userInformationForm.get('phone_number')?.setValue(this.managerService.userService.user_info?.phone_number);
        this.removeAlerts();
        this.form_enabled = false;
    }

    validateForm(): void {
        // empty fields
        let empty_fields = '';
        if (this.userInformationForm.get('city')?.value === '') {
            empty_fields += `${this.managerService.config.USER_INFORMATION.CITY}, `;
            this.alertCity();
        }
        if (this.userInformationForm.get('address')?.value === '') {
            empty_fields += `${this.managerService.config.USER_INFORMATION.ADDRESS}, `;
            this.alertAddress();
        }
        if (this.userInformationForm.get('phone_number')?.value === '') {
            empty_fields += `${this.managerService.config.USER_INFORMATION.PHONE_NUMBER}, `;
            this.alertPhone();
        }
        if (empty_fields !== '') {
            empty_fields = empty_fields.substring(0, empty_fields.length - 2);
            // @ts-ignore
            const content = this.managerService.config.RESPONSE_TABLE[2012];
            this.managerService.popSnackBar(content.is_okay, content.message + empty_fields);
            return;
        }

        // invalid address
        if (this.userInformationForm.get('address')?.value.length <= 10) {
            // @ts-ignore
            const content = this.managerService.config.RESPONSE_TABLE[2011];
            this.managerService.popSnackBar(content.is_okay, content.message);
            this.alertAddress();
            return;
        }

        // invalid phone number
        if (!UtilityService.validatePhoneNumber(this.userInformationForm.get('phone_number')?.value.replace(/\D/g, ''))) {
            // @ts-ignore
            const content = this.managerService.config.RESPONSE_TABLE[2010];
            this.managerService.popSnackBar(content.is_okay, content.message);
            this.alertPhone();
            return;
        }
        this.removeAlerts();

        this.form_enabled = false;
        this.updateUserInfo();
    }

    alertPhone(): void {
        this.phoneInput.nativeElement.classList.add('invalid-form-input');
        this.invalidPhone.nativeElement.style.display = 'block';
    }

    alertCity(): void {
        this.cityInput.nativeElement.classList.add('invalid-form-input');
        this.invalidCity.nativeElement.style.display = 'block';
    }

    alertAddress(): void {
        this.addressInput.nativeElement.classList.add('invalid-form-input');
        this.invalidAddress.nativeElement.style.display = 'block';
    }

    removeAlerts(): void {
        this.cityInput.nativeElement.classList.remove('invalid-form-input');
        this.invalidCity.nativeElement.style.display = 'none';
        this.addressInput.nativeElement.classList.remove('invalid-form-input');
        this.invalidAddress.nativeElement.style.display = 'none';
        this.phoneInput.nativeElement.classList.remove('invalid-form-input');
        this.invalidPhone.nativeElement.style.display = 'none';
    }

    updateUserInfo(): void {
        const data = {
            town: this.userInformationForm.get('city')?.value,
            address: this.userInformationForm.get('address')?.value,
            phone_number: this.userInformationForm.get('phone_number')?.value.replace(/\D/g, '').substring(0, 10),
        };
        this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'update_user_information_service', data).subscribe(
            response => {
                if (!response.is_okay) {
                    // @ts-ignore
                    const content = this.managerService.config.RESPONSE_TABLE[response.code];
                    this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                } else {
                    // @ts-ignore
                    const content = this.managerService.config.RESPONSE_TABLE[response.code];
                    this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                    this.managerService.marketingService.logEvent('user_info_added', {});
                }
            }));
    }

    ngOnDestroy(): void {
        this.subscription_list.forEach((subscription) => {
            subscription?.unsubscribe();
        });
    }

}
