<h1>
    Mesafeli Satış Sözleşmesi
</h1>

<p>
    www.artiox.com web sitesini ziyaret ederek veya www.artiox.com üyesi olarak,
    www.artiox.com web sitesinin kullanım koşullarının tamamını okuduğunuzu,
    içeriğini tamamen anladığınızı; bu sayfada belirtilen ve www.artiox.com web
    sitesinde belirtilecek olan tüm hususları kayıtsız ve şartsız olarak kabul ettiğinizi
    ve onayladığınızı kabul ederek, belirtilen bu hususlar ile ilgili olarak herhangi bir
    itiraz ve defi ileri sürmeyeceğinizi açıkça beyan ve taahhüt etmiş oluyorsunuz.
    www.artiox.com kullanıcıları ve üyeleri, güncellenen sözleşme maddelerini uygun
    bulmamaları durumunda, dilediklerinde www.artiox.com üyeliğini bitirebilirler
    ve/veya www.artiox.com web sitesini kullanmaktan vazgeçebilirler.
    www.artiox.com kullanıcıları ve üyeleri, bu konuda Artiox Teknoloji Yazılım
    A.Ş.’den herhangi bir hak talep edemezler.
</p>

<ol>
    <li> <p>
        www.artiox.com hizmeti, hiçbir şekilde kamu düzenini bozucu, genel ahlaka
        aykırı, başkalarını rahatsız ve taciz edecek şekilde, yasalara aykırı bir amaç için,
        başkalarının fikri ve telif haklarına tecavüz edecek şekilde kullanılamaz. Ayrıca
        www.artiox.com altyapısı üzerine makul olmayan veya orantısız büyüklükte yük
        getiren herhangi bir işlem de yapılamaz.
    </p>
    </li>
    <li><p>
        www.artiox.com web sitesinin içeriği ve yazılımı, her türlü fikri ve mali hakkı
        Artiox Teknoloji Yazılım A.Ş. ye aittir. www.artiox.com kullanıcıları ve üyeleri,
        www.artiox.com web sayfasını veya içindekileri izlemek veya kopyalamak,
        çoğaltmak, değiştirmek amacıyla ya da izin ve yetki verilmeyen başka amaçlarla
        ve önceden Artiox Teknoloji Yazılım A.Ş. ’nin açık ve yazılı iznini almadan
        herhangi bir robot, örümcek veya başka otomatik aletler ya da manuel süreçler
        kullanmayacaklarını; bu içerikten türev eserler yaratmayacaklarını ya da bunları
        kamuya teşhir etmeyeceklerini kabul ederler.
    </p>
    </li>

    <li><p>
        www.artiox.com kullanıcıları ve üyeleri, www.artiox.com web sitesinin
        çalışmasına müdahale etmek veya müdahaleye teşebbüs etmek amacıyla
        herhangi bir alet, yazılım veya araç kullanmayacaklarını, siteye yetkisiz olarak
        bağlanmayacaklarını ve işlem yapmayacaklarını, diğer internet kullanıcılarının
        yazılımlarına ve verilerine izinsiz olarak ulaşmayacaklarını veya bunları
        kullanmayacaklarını kabul ederler. Aksi takdirde Artiox Teknoloji Yazılım A.Ş..’nin
        bu yüzden kamu kurumlarına ve/veya üçüncü şahıslara ödemek zorunda kaldığı
        her türlü tazminat ve/veya idari/adli para cezaları için kullanıcıdan aynen rücu
        edilir.
    </p>
    </li>

    <li><p>
        www.artiox.com kullanıcıları ve üyeleri, telif hakkına konu olan bilgi ve
        materyalleri açık bir izin almadan, geçerli bir savunması veya haklı bir sebebi
        olmadan yasadışı yollarla iletmek amacıyla www.artiox.com web sitesini
        kullanamaz. Siteye makaleler, görüntüler, öyküler, yazılım veya telif hakkına konu
        olan veya benzeri içerik koyan kullanıcılar, siteye yükledikleri içeriğin üçüncü
        şahısların telif haklarına veya başka haklarına (özel hayatın gizliliği veya ifşayı
        men etme hakları gibi) tecavüz etmediğinden veya bu hakları ihlâl etmediğindenemin olmalıdırlar. www.artiox.com kullanıcıları ve üyeleri, Fikir ve Sanat Eserleri
        Yasası, Markaların Korunması Hakkında Kanun Hükmünde Kararname, Türk
        Ticaret Kanunu, Patent Haklarının Korunması Hakkında Kanun, Türk Ceza Kanunu
        ve diğer mevzuat hükümlerine riayet etmeyi ve bunları ihlal etmemeyi kabul ve
        taahhüt ederler. Aksi takdirde Artiox Teknoloji Yazılım A.Ş.’nin bu yüzden kamu
        kurumlarına ve/veya üçüncü şahıslara ödemek zorunda kaldığı her türlü tazminat
        ve/veya idari/adli para cezaları için kullanıcıya rücu edilir.
    </p>
    </li>

    <li><p>
        www.artiox.com kullanıcıları ve üyelerinin, www.artiox.com web sitesini
        yasadışı bir hak tecavüzünün veya hak ihlâlinin bir aracı olarak kullandığı
        konusunda ilgili telif hakkı sahibinin veya onun temsilcisinin Artiox Teknoloji
        Yazılım A.Ş.’ye bildirimde bulunması ya da bunun bir mahkeme kararıyla veya ilgili
        kullanıcının ikrarıyla sabit olması halinde, ihlâlde bulunan kullanıcının siteye
        erişim ve/veya siteyi kullanım hakları iptal edilir. Artiox Teknoloji Yazılım A.Ş.’nin
        ileri sürülen hak tecavüzü veya hak ihlâlinin gerçekten yapıldığını kendi teknik
        olanakları ile şüphelenmesi halinde, kendi mutlak takdirine göre, o kullanıcının
        siteye erişim veya siteyi kullanım haklarını böyle bir bildirim, mahkeme kararı veya
        ikrardan önce iptal etme hakkı saklıdır.
    </p>
    </li>
    <li><p>
        Artiox Teknoloji Yazılım A.Ş., www.artiox.com web sitesinin kesintisiz veya
        hatasız olacağını ya da sitenin veya içeriğinin kullanılmasıyla ya da siteye
        bağlantı yapılmasıyla belirli sonuçların elde edileceğini garanti etmemektedir.
        Artiox Teknoloji Yazılım A.Ş., www.artiox.com kullanıcılarının ve üyelerinin,
        www.artiox.com web sitesinden yükledikleri dosyalarda virüs ya da kirletici veya
        bozucu özellikler bulunmadığını da garanti etmemektedir. Artiox Teknoloji Yazılım
        A.Ş., doğrudan, dolaylı veya arızi zararlar, netice kabilinden doğan zararlar ve
        cezai tazminatlar da dahil olmak üzere, www.artiox.com web sitesinin
        kullanılmasından kaynaklanabilecek zararlar için sorumlu tutulamaz.
    </p></li>
    <li><p>
        www.artiox.com üyeleri, kendilerine tarafından belirlenmiş kullanıcı adı ve
        şifrelerini başka kişi ya da kuruluşlara veremezler. Aksi halde Artiox Teknoloji
        Yazılım A.Ş.’nin söz konusu kullanımdan doğan tazmin hakkı saklıdır.
        www.artiox.com üyelerinin www.artiox.com üyeliğiyle sahip oldukları kullanım
        hakkı sadece kendilerine aittir, başkalarına devredilemez.
    </p></li>
    <li><p>
        Artiox Teknoloji Yazılım A.Ş.’nin her zaman, tek taraflı olarak www.artiox.com
        web sitesini sürekli veya geçici olarak durdurma hakkı vardır.
    </p></li>
    <li><p>
        Artiox Teknoloji Yazılım A.Ş.., kullanıcı ve üyelerinin bilgilerinin yetkisiz
        kişilerce okunmasından, kullanıcı ve üyelerinin yazılım ve verilerine bu yolla gelebilecek herhangi bir zarardan dolayı sorumlu olmayacaktır. Kullanıcı ve
        üyeler, bu hizmetin kullanılmasından dolayı uğrayabilecekleri zararlarla ilgili
        olarak Artiox Teknoloji Yazılım A.Ş.’den herhangi bir tazminat talep etmemeyi
        peşinen kabul etmişlerdir.
    </p></li>
    <li><p>www.artiox.com web sitesine üyelik başvurusunda bulunmak,
        www.artiox.com üyesi olmanın ön koşulu olmakla beraber yeterli koşulu değildir.
        www.artiox.com üyesi olmak için başvuru işlemlerini tamamlayan kullanıcıların
        başvuruları Artiox Teknoloji Yazılım A.Ş. tarafından değerlendirilir; sadece uygun
        görülen üyeler www.artiox.com sitesi üyesi olarak kabul edilir. www.artiox.com
        web sitesine gönderilen her tür içerik, editör onayından geçtikten sonra
        www.artiox.com sitesinde yayınlanmaya başlar.</p></li>
    <li><p>
        Artiox Teknoloji Yazılım A.Ş. kayıtlarını eksik giren üyelerin ve/veya üyeliğinin
        www.artiox.com web sitesinin kullanım koşullarına uygun olmadığını tespit ettiği
        üyelerinin hesapları hiçbir açıklama yapmaksızın sistemden çıkarma hakkına
        sahiptir. Ayrıca Artiox Teknoloji Yazılım A.Ş., belirli zaman dilimi boyunca
        güncellenmediğini tespit ettiği üyelerin hesaplarını da hiçbir açıklama
        yapmaksızın sistemden çıkarma hakkına sahiptir.
    </p></li>
    <li><p>
        www.artiox.com kullanıcıları ve üyeleri, www.artiox.com web sitesini değer
        yaratacak bir içerik üretmeksizin, sadece ve doğrudan ticari tanıtım alanı olarak
        kullanamaz, bu site aracılığıyla ürün/hizmet tanıtımı/satışı yapma veya satış
        kanallarına yönlendirme girişiminde bulunamaz. Artiox Teknoloji Yazılım A.Ş..,
        kendi kişisel takdirine göre, www.artiox.com web sitesini bu amaçlarla
        kullandığını tespit ettiği üyelerin üyeliklere son verme ve/veya sayfalarını
        yayından kaldırma hakkına sahiptir.
    </p></li>
    <li><p>
        Artiox Teknoloji Yazılım A.Ş.., www.artiox.com üye sayfalarında çeşitli
        formatlarda tanıtım veya reklam görüntüleri, yazıları veya bağlantıları yayınlama
        hakkına sahiptir. Artiox Teknoloji Yazılım A.Ş. dilediği üye veya üye grupları için
        reklam uygulaması yapabilir veya yapmayabilir.
    </p></li>
    <li><p>
        Artiox Teknoloji Yazılım A.Ş., www.artiox.com kullanıcıları ve üyelerinin
        sağladıkları içerikleri, www.artiox.com bünyesinde çeşitli sayfalarda ön plana
        çıkarılabilir veya çıkarılmayabilir.
    </p></li>
    <li><p>
        www.artiox.com üyeleri, kişisel üyelik sayfasını kapatırsa, o güne kadar
        ürettiği içeriğin Artiox Teknoloji Yazılım A.Ş.. tarafından kullanım hakkı saklı
        kalacaktır.
    </p></li>
    <li><p>
        Artiox Teknoloji Yazılım A.Ş., www.artiox.com kullanıcıları ve üyelerinin,
        www.artiox.com web sitesini ve altyapısını yukarıda belirtilen şartlara ve yasalara
        aykırı olarak kullandığını tespit ederse, kullanıcıyı yetkili makamlara bildirmek
        hakkına sahiptir.
    </p></li>
    <li><p>
        Yukarıda belirtilen maddelerden bir ya da birkaçını ihlal eden www.artiox.com
        kullanıcıları ve üyeleri, bu ihlal nedeniyle cezai ve hukuki olarak şahsensorumludurlar. Ayrıca bu ihlal nedeniyle, olayın hukuk alanına intikal ettirilmesi
        halinde Artiox Teknoloji Yazılım A.Ş. ’nin kullanıcıya karşı bu koşullara
        uyulmamasından dolayı tazminat hakkı saklı olacaktır.
    </p></li>
    <li><p>
        Mesafeli satış sözleşmesine konu ürün/ürünler, şehir içi teslimlerde 7 (yedi)
        işgünü, şehir dışı teslimlerde 14 (ondört) işgünü içinde, her halde yasa ve
        yönetmeliklerle belirlenen yasal 30 (otuz) günlük süreyi aşmamak koşulu ile her
        bir ürün için alıcının yerleşim yerinin uzaklığına bağlı olarak alıcı veya gösterdiği
        adresteki kişi/kuruluşa teslim edilir. İşbu teslim süresi ile ilgili ürüne/ürünlere
        ilişkin ödemenin yapılması tarihinden itibaren başlayacaktır.
    </p></li>
    <li><p>
        Sözleşme konusu ürün, Alıcı'dan başka bir kişi/kuruluşa teslim edilecek ise,
        teslim edilecek kişi/kuruluşun teslimatı kabul etmemesinden Artiox Teknoloji
        Yazılım A.Ş. sorumlu tutulamaz.
    </p></li>
    <li><p>
        Kargo firmasının, ürünü Alıcı’ya teslimi aşamasında karşılaşacağı her türlü
        sorun nedeniyle, siparişi verilen ürünün Alıcı'ya teslim edilememesinden veya geç
        teslim edilmesinden dolayı Artiox Teknoloji Yazılım A.Ş. sorumlu tutulamaz.
    </p></li>
    <li><p>
        Alıcı, ürünü/ürünleri teslim aldığı anda kontrol etmekle ve üründe/ürünlerde
        kargodan kaynaklanan bir sorun gördüğünde, ürünü/ürünleri kabul etmemekle ve
        kargo firması yetkilisine tutanak tutturmakla sorumludur.
    </p></li>
    <li><p>
        Alıcı satış bedelinin tamamını, Katma Değer Vergisi'ni (KDV) ve %10 aracı
        şirket komisyonunu (+KDV) ödediği anda Artiox Teknoloji Yazılım A.Ş.'nin ürünü
        teslim yükümlülüğü doğacaktır. Herhangi bir nedenle ürünün/ürünlerin bedeli
        ödenmez veya banka kayıtlarında iptal edilir ise, Artiox Teknoloji Yazılım A.Ş.
        ürünün/ürünlerin teslimi yükümlülüğünden kurtulmuş kabul edilir.
    </p></li>
    <li><p>
        Mesafeli satış sözleşmesine konu ürüne/ürünlere ilişkin ödemenin Alıcı
        tarafından kredi kartı ile yapılması durumunda, Alıcı ile kredi kartı sahibinin ya da
        ürünün/ürünlerin teslim edileceği kişinin farklı olmasından kaynaklanabilecek
        olan, kredi kartının yetkisiz kişilerce haksız ve hukuka aykırı olarak kullanılması da
        dahil olmak üzere türlü hukuki risk, Alıcı'ya aittir. Alıcı, bahsi geçen durumlarda
        herhangi bir şekilde zarara uğraması halinde Artiox Teknoloji Yazılım A.Ş.'den
        hiçbir talepte bulunmayacağını kabul ve taahhüt eder.
    </p></li>
    <li><p>
        Alıcı, Sözleşme konusu ürünün Alıcı veya Alıcı’nın gösterdiği adresteki
        kişi/kuruluşa tesliminden sonra Alıcı'ya ait kredi kartının yetkisiz kişilerce haksız
        kullanılması sonucunda sözleşme konusu ürün bedelinin ilgili banka veya finans
        kuruluşu tarafından Artiox Teknoloji Yazılım A.Ş.'ye ödenmemesi halinde, Alıcı
        Sözleşme konusu ürünü 3 gün içerisinde nakliye gideri Alıcı’ya ait olacak şekilde
        Artiox Teknoloji Yazılım A.Ş..’ye iade edeceğini kabul, beyan ve taahhüt eder.
    </p></li>
    <li><p>
        Alıcı ve Artiox Teknoloji Yazılım A.Ş. işbu mesafeli satış sözleşmesinin
        başında bahsedilen yazışma adreslerinin geçerli tebligat adresi olduğunu ve bu
        adrese yöneltilecek tüm tebligatların geçerli addolunacağını kabul, beyan ve
        taahhüt eder.
    </p></li>
    <li><p>
        Taraflar işbu Sözleşme şartlarının yanı sıra Tüketicilerin Korunması
        Hakkındaki Kanun ve Mesafeli Sözleşmeler Yönetmeliği hükümlerini kabul
        ettiklerini ve bu hükümlere uygun hareket edeceklerini kabul, beyan ve taahhüt
        ederler.
    </p></li>
    <li><p>
        18 yaşından küçük kişiler ile ayırt etme gücünden yoksun veya kısıtlı erginler
        Artiox Teknoloji Yazılım A.Ş.’den alışveriş yapamaz.
    </p></li>
    <li><p>
        www.artiox.com kullanıcıları ve üyeleri, www.artiox.com web sitesini
        kullanmakla, site hakkında veya site ile ilgili bir ihtilâfın çıkması halinde, bu
        ihtilâfın kanunlar ihtilâfı kurallarına bakılmaksızın Türkiye Cumhuriyeti
        kanunlarına tâbi olacağını ve bu kanunlara göre çözümleneceğini kabul etmiş
        sayılırlar. Ayrıca, Ankara Mahkemelerinin görev ve yer olarak yetkili olacağını da
        kabul etmiş olurlar.
    </p></li>
    <li><p>
        www.artiox.com sitenize üyelik/alışveriş işlemleri sırasında verdiğim ve
        vereceğim iletişim adreslerime (cep telefonu, mail adresim ve diğerlerine) gerek
        her türlü üyelik/alışveriş işlemleri ve uygulamaları ile sözleşmesel ve kanuni
        hususlardan bilgilendirilmem, gerek çeşitli ürün-hizmetlerin ve genel/özel
        imkanların duyurulması, tanıtım ve reklamının yapılması için Şirketiniz Artiox
        Teknoloji Yazılım A.Ş. ile tarafıma ilgili kanunlara uygun olarak ticari elektronik
        iletiler ve diğer iletiler gönderileceği, iletişim adres bilgilerimin (isim, cep telefonu,
        mail adresi vd.) bu amaçlar ile alındığı-alınacağı, ayrıca (üyeliğim varsa) istediğim
        her zaman sitenizindeki üyelik sayfasında iletişim tercihlerimi değiştirebileceğim
        (bilahare iletişimi açtığımda, varsa önceki red bildirimim dikkate alınmaksızın ve
        ayrıca bir işleme gerek olmaksızın aynı kanaldan devam edeceği) veya hiçbir
        gerekçe göstermeksizin tarafıma gelen SMS/e-mail mesajlarında belirtilen işlemi
        (red) yaparak iletişimi durdurabileceğim (kanunlar gereği mümkün/gerekli
        iletişimlerin her durumda devam edeceği) ve diğer tüm ilgili hususlarda buradaki
        açıklamalar ve/veya Kullanım ve Satış Koşulları ile Gizlilik ve Kişisel Verilerin
        Korunması Politikası ile bilgilenmiş/bilgilendirilmiş olarak ve iletişim bilgilerim de
        dahil her türlü kişisel verilerime ilişkin bütün izinlerim-haklarım her halükarda ve
        ayrıca geçerli kalmak üzere; her türlü ürün ve hizmetlere ilişkin çeşitli
        bilgilendirme, tanıtım, reklam, promosyon, satış, pazarlama, anket yapılması
        amacıyla, kredi kartı ve üyelik-alışveriş bilgilendirmeleri, işlem ve uygulamaları
        için Şirketiniz Artiox Teknoloji Yazılım A.Ş.’nin kanunlara uygun şekilde SMS/kısa
        mesaj, anlık bildirim, otomatik arama, telefonla arama, bilgisayar, telefon,
        internet, sosyal medya ile online reklam ağları, bluetooth-beacon dahil kablosuz
        bağlantılar, faks, e-posta/mail ve diğer elektronik iletişim araçları/kanalları ile
        tarafıma ticari elektronik iletiler ve diğer iletiler göndermesine onayım vardır.
    </p></li>
</ol>
