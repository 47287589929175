<div class="vw-100 min-vw-100 d-flex flex-column align-items-center justify-content-start pb-3"
     style="min-width: 300px; height: fit-content;">
  <p [innerHTML]="managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_ARTIST_ABOUT: managerService.language_pipe"
     class="my-3"
     style="font-family: bebas-neue-regular, sans-serif; font-size: 24px; font-weight: bold; color: #404352;"></p>
  <p *ngIf="managerService.pairService.active_pair_ready | managerPipe"
     class="mt-1 mb-4 mx-3" style="font-family: Roboto, sans-serif; font-size: 14px; color: #2a2a2a;">
    <span [innerHTML]="managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_INFO: managerService.language_pipe"></span>
    <span class="clarification-text" *ngIf="(managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_ROUTER_LINK: managerService.language_pipe); let item;"
          (click)="managerService.navigationManager(item.LINK)">{{item.TEXT | managerPipe}}</span>
  </p>
</div>

