<div style="margin: auto;">
  <div class="d-flex flex-column align-items-center">
    <div class="w-100 d-flex flex-row">
      <div>
        <svg style="height: 44px; width: 44px;">
          <use [attr.xlink:href]='"./assets/images/announcement.svg#announcement"' fill="var(--artiox-blue)"/>
        </svg>
      </div>
      <div class="pl-2">
        <div class="pb-1" style="font-family: Roboto, sans-serif; font-size: 20px; color: var(--artiox-blue)">
          {{managerService.config.ANNOUNCEMENTS.HEADERS.ANNOUNCEMENTS | managerPipe}}
        </div>
        <div [innerHTML]="managerService.config.ANNOUNCEMENTS.HEADERS.DESCRIPTION | managerPipe" class=""
             style="font-family: Roboto, sans-serif; font-size: 13px; color: #707070;">
          [innerHTML]="managerService.config.ANNOUNCEMENTS.HEADERS.DESCRIPTION | managerPipe">
        </div>
      </div>
    </div>
    <div class="w-100 d-flex flex-row align-items-start justify-content-between mt-2">
      <div (click)="managerService.navigationManager({url: '/corporate/general-announcements', current_component_index: 0})"
           class="category position-relative">
        <img src="{{managerService.config.ANNOUNCEMENTS.HEADERS.OPERATION_ANNOUNCEMENTS_BG_IMG_SRC_DESKTOP}}"
             class=" w-100" style="height: 100%; object-fit: cover; z-index: 0; border-radius: 8px;" alt="operations" #operations
             (error)="operations.src = operations.src.replace('.webp', '.jpg');">
        <div class="d-flex flex-column align-items-center justify-content-start position-absolute w-100" style="background-color: rgba(244, 245, 249, 0.3); z-index: 1; top: 66%">
          <div style="font-family: Raleway, sans-serif; font-size: 1.2vw; font-weight: 600; letter-spacing: 1.7px; text-align: center; color: #fcfdff; margin: 12px 0 4px 0;">
            {{managerService.config.ANNOUNCEMENTS.HEADERS.OPERATION_ANNOUNCEMENTS | managerPipe: managerService.mpa_template.TO_LOCAL_UPPER_CASE}}
          </div>
          <div style="font-family: Raleway, sans-serif; font-size: 0.9vw; font-weight: 600; letter-spacing: 0.4px; text-align: center; color: #fcfdff; margin: 4px 0 12px 0;">
            {{managerService.config.ANNOUNCEMENTS.HEADERS.OPERATION_ANNOUNCEMENTS_EXPLANATION | managerPipe}}
          </div>
        </div>
      </div>
      <div (click)="managerService.navigationManager({url: '/corporate/about-artist', current_component_index: 0})"
           class="category position-relative">
          <img src="{{managerService.config.ANNOUNCEMENTS.HEADERS.ARTISTS_ANNOUNCEMENTS_BG_IMG_SRC_DESKTOP}}"
               class=" w-100" style="height: 100%; object-fit: cover; z-index: 0; border-radius: 8px;" alt="artists" #artists
               (error)="artists.src = artists.src.replace('.webp', '.jpg');">
          <div class="d-flex flex-column align-items-center justify-content-start position-absolute w-100" style="background-color: rgba(244, 245, 249, 0.3); z-index: 1; top: 66%">
            <div style="font-family: Raleway, sans-serif; font-size: 1.2vw; font-weight: 600; letter-spacing: 1.7px; text-align: center; color: #fcfdff; margin: 12px 0 4px 0;">
              {{managerService.config.ANNOUNCEMENTS.HEADERS.ARTISTS_ANNOUNCEMENTS | managerPipe: managerService.mpa_template.TO_LOCAL_UPPER_CASE}}
            </div>
            <div style="font-family: Raleway, sans-serif; font-size: 0.9vw; font-weight: 600; letter-spacing: 0.4px; text-align: center; color: #fcfdff; margin: 4px 0 12px 0;">
              {{managerService.config.ANNOUNCEMENTS.HEADERS.ARTISTS_ANNOUNCEMENTS_EXPLANATION | managerPipe}}
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
