import {EventEmitter, Injectable, Output} from '@angular/core';
import {CurrencyWallet, UserBankAccount, UserCryptoAccount} from './user.service';
import {HttpClient} from '@angular/common/http';
import {Currency} from './pair.service';
import {environment} from '../../environments/environment';
import {UtilityService} from "./utility.service";

export class BlockChain {
  static empty_blockchain: BlockChain = new BlockChain({id: -1, name: '', token: '', symbol: '', value: 0, value_type: ''});

  id: -1;
  name: string;
  token: string;
  symbol: string;
  value: number;
  value_type: string;

  constructor(blockchain_primitive: any) {
    this.id = blockchain_primitive.id;
    this.name = blockchain_primitive.name;
    this.token = blockchain_primitive.token;
    this.symbol = blockchain_primitive.symbol;
    this.value = blockchain_primitive.value;
    this.value_type = blockchain_primitive.value_type;
  }
}


@Injectable({
  providedIn: 'root'
})
export class OperationsService {
  static empty_withdrawal_request_data = {currency_id: -1, amount: 0, operation_type: 'withdrawal', bank: '-', iban: '-', block_chain_id: -1, address: '-', label: '-'};
  static empty_sign_withdrawal_request_data = {currency_id: -1, operation_id: -1, status: ''};

  public block_chain_list: BlockChain[] = [];
  @Output() block_chain_list_changed: EventEmitter<any> = new EventEmitter();

  public artiox_bank_account_list: UserBankAccount[] = [];
  public selected_artiox_bank_account: UserBankAccount = UserBankAccount.empty_bank_account;

  public user_fiat_currency_wallet_list: CurrencyWallet[] = [];
  public user_crypto_currency_wallet_list: CurrencyWallet[] = [];
  public selected_user_currency_wallet: CurrencyWallet = CurrencyWallet.empty_currency_wallet;

  public active_currency: Currency = Currency.empty_currency;
  public active_user_currency_wallet: CurrencyWallet = CurrencyWallet.empty_currency_wallet;

  public selected_user_bank_account: UserBankAccount = UserBankAccount.empty_bank_account;
  public selected_user_bank_account_ready: boolean = false;
  public selected_user_bank_account_pipe: boolean = false;

  public selected_user_crypto_account: UserCryptoAccount = UserCryptoAccount.empty_crypto_account;
  public selected_user_crypto_account_ready: boolean = false;
  public selected_user_crypto_account_pipe: boolean = false;

  public active_withdrawal_type: 'fiat' | 'crypto' = 'fiat';
  public withdrawal_request_data = OperationsService.empty_withdrawal_request_data;
  public sign_withdrawal_request_data = OperationsService.empty_sign_withdrawal_request_data;
  public requested_amount: number = 0;
  public transfer_fee: number = 0;

  public fiat_deposit_rules_accepted_status: boolean = false;
  public crypto_deposit_rules_accepted_status: boolean = false;
  public ethereum_deposit_rules_accepted_status: boolean = false;
  public fiat_withdrawal_rules_accepted_status: boolean = false;
  public crypto_withdrawal_rules_accepted_status: boolean = false;

  @Output() bank_withdrawal_request_form_submitted: EventEmitter<any> = new EventEmitter();
  @Output() cryptocurrency_withdrawal_request_form_submitted: EventEmitter<any> = new EventEmitter();
  @Output() currency_withdrawal_request_completed: EventEmitter<any> = new EventEmitter();

  public environment = environment;

  constructor(private httpClient: HttpClient, private utilityService: UtilityService) {
    setTimeout(() => {
      this.refreshBlockchains();
    }, 1000);
  }

  refreshArtioxOfficialBankAccountList(): void {
    this.httpClient.post<any>(this.environment.serverAPI + 'get_artiox_bank_account_list_service', {}).subscribe(
      response => {
        this.artiox_bank_account_list = response.map((x: any) => new UserBankAccount(x));
      });
  }

  removeUserBankAccount(bank_account: UserBankAccount, callback: any): void {
    if (!(bank_account.iban && bank_account.label)) {
      return;
    }

    const data = {
      iban: bank_account.iban,
      label: bank_account.label
    };

    this.httpClient.post<any>(this.environment.serverAPI + 'remove_user_bank_account_service', data).subscribe(
      response => {
        if (response.is_okay === 'true') {
          this.selected_user_bank_account = UserBankAccount.empty_bank_account;
          this.selected_user_bank_account_ready = false;
        }

        callback(response);
      });
  }

  removeUserCryptoAccount(crypto_account: UserCryptoAccount, callback: any): void {
    if (!(crypto_account.address && crypto_account.label)) {
      return;
    }

    const data = {
      address: crypto_account.address,
      label: crypto_account.label
    };

    this.httpClient.post<any>(this.environment.serverAPI + 'remove_user_crypto_account_service', data).subscribe(
      response => {
        if (response.is_okay) {
          this.selected_user_crypto_account = UserCryptoAccount.empty_crypto_account;
          this.selected_user_crypto_account_ready = false;
        }
        callback(response);
      });
  }

  selectWithdrawBankAccount(bank_account: UserBankAccount): void {
    this.active_withdrawal_type = 'fiat';
    this.selected_user_bank_account = bank_account;
    this.selected_user_bank_account_ready = true;
    this.selected_user_bank_account_pipe = !this.selected_user_bank_account_pipe;
  }

  selectWithdrawCryptoAccount(crypto_account: UserCryptoAccount): void {
    this.active_withdrawal_type = 'crypto';
    this.selected_user_crypto_account = crypto_account;
    this.selected_user_crypto_account_ready = true;
    this.selected_user_crypto_account_pipe = !this.selected_user_crypto_account_pipe;
  }

  refreshBlockchains(): void {
    this.httpClient.get<any>(this.environment.serverAPI + 'get_block_chains_service').subscribe(response => {
      this.block_chain_list = response.map((e: any) => new BlockChain(e));
      this.block_chain_list_changed.emit();
    });
  }

  refreshBlockchainById(block_chain_id: number): void {
    const data = {
      block_chain_id
    };
    this.httpClient.post<any>(this.environment.serverAPI + 'get_block_chain_service', data).subscribe(response => {
      this.block_chain_list[block_chain_id] = new BlockChain(response);
      this.block_chain_list_changed.emit();
    });
  }

  prepareWithdrawalRequestData(account: UserBankAccount | UserCryptoAccount): void {
    if (this.requested_amount <= 0) {
      this.withdrawal_request_data = OperationsService.empty_withdrawal_request_data;
      return;
    }

    if (this.active_currency === undefined) {
      this.withdrawal_request_data = OperationsService.empty_withdrawal_request_data;
      return;
    }

    if (account instanceof UserBankAccount) {
      if (this.active_currency.type !== 'fiat') {
        this.withdrawal_request_data = OperationsService.empty_withdrawal_request_data;
        return;
      }
      if (this.requested_amount < 100) {
        this.withdrawal_request_data = OperationsService.empty_withdrawal_request_data;
        return;
      }
      this.withdrawal_request_data = {
        currency_id: this.active_currency.id,
        amount: this.requested_amount,
        operation_type: 'withdrawal',
        bank: account.bank,
        iban: account.iban,
        block_chain_id: -1,
        address: '-',
        label: account.label,
      };
      return;
    } else if (account instanceof UserCryptoAccount) {
      if (this.active_currency.type !== 'crypto') {
        this.withdrawal_request_data = OperationsService.empty_withdrawal_request_data;
        return;
      }

      if (this.active_currency.id === 1 && this.requested_amount < 50) {
        this.withdrawal_request_data = OperationsService.empty_withdrawal_request_data;
        return;
      }

      if (this.active_currency.id === 2 && this.requested_amount < 0.005) {
        this.withdrawal_request_data = OperationsService.empty_withdrawal_request_data;
        return;
      }

      const block_chain_id: number | undefined = this.block_chain_list.find(e => e.name === account.block_chain)?.id;
      if (block_chain_id === undefined) {
        this.withdrawal_request_data = OperationsService.empty_withdrawal_request_data;
        return;
      }

      this.withdrawal_request_data = {
        currency_id: this.active_currency.id,
        amount: this.requested_amount,
        operation_type: 'withdrawal',
        bank: '-',
        iban: '-',
        block_chain_id,
        address: account.address,
        label: account.label,
      };
      return;
    }

    this.withdrawal_request_data = OperationsService.empty_withdrawal_request_data;
    return;
  }

  currencyWithdrawalRequest(): void {
    if (this.active_currency === Currency.empty_currency && this.selected_user_bank_account === UserBankAccount.empty_bank_account) {
      return;
    }

    if (this.withdrawal_request_data === OperationsService.empty_withdrawal_request_data) {
      return;
    }

    console.log('currency_withdrawal_request_service', this.withdrawal_request_data);

    this.httpClient.post<any>(this.environment.serverAPI + 'currency_withdrawal_request_service', this.withdrawal_request_data).subscribe(
      response => {
        if (response.is_okay) {
          this.transfer_fee = response.$transfer_fee;
          this.sign_withdrawal_request_data = {
            currency_id: response.$currency_id,
            operation_id: response.$operation_id,
            status: ''
          };
        }

        this.currency_withdrawal_request_completed.emit(response);
      });
  }

  signCurrencyWithdrawalRequest(popResponseSnackbarFn: any): void {
    if (this.active_currency === Currency.empty_currency && this.selected_user_bank_account === UserBankAccount.empty_bank_account) {
      return;
    }

    if (this.sign_withdrawal_request_data === OperationsService.empty_sign_withdrawal_request_data) {
      return;
    }

    if (!this.utilityService.requestCriticServicePermission('sign_currency_withdrawal_request_service', this.sign_withdrawal_request_data)){
      popResponseSnackbarFn({code: 2019, is_okay: false, message: 'Service not allowed in dev mode.'});
      return;
    }

    console.log('sign_currency_withdrawal_request_service', this.sign_withdrawal_request_data);

    this.httpClient.post<any>(this.environment.serverAPI + 'sign_currency_withdrawal_request_service', this.sign_withdrawal_request_data).subscribe(
      response => {
        popResponseSnackbarFn(response);
        this.sign_withdrawal_request_data = OperationsService.empty_sign_withdrawal_request_data;
      });
  }

}
