<div class="d-flex flex-column" style=" width: calc(80vw - 740px); min-width: 360px;max-width: 580px">

    <div class="d-flex w-100 justify-content-between flex-row">


        <div class="d-flex w-100 flex-column" style="gap: 16px">

            <div class="exo header6 medium" style="color: var(--text-color-artiox-black, #131515);">
                {{ managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_ARTIST_NAME }}
            </div>


            <div class="exo header6 medium" style="color: var(--text-color-artiox-grey, #6F6F6F);">
                {{ managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_NAME : managerService.language_pipe}}
            </div>
            <div
                    class="body1 regular barlow"
                    style="color: var(--text-color-artiox-grey, #6F6F6F);"> {{managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_TEXT: managerService.language_pipe}}
            </div>
            <!--        <div>-->
            <!--            {{ managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT }}-->
            <!--        </div>-->

            <div class="barlow body1 regular"  style="color: var(--text-color-artiox-grey, #6F6F6F);">
                <span class="">{{managerService.config.AUCTION.STATEMENT_HEADER}}</span>
                <span class=""></span>
                <span class="">{{managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_STATEMENT: managerService.language_pipe}}</span>
            </div>
        </div>

        <div (click)="toggleDropdown();" class=" d-flex"
             style="width: auto; border-radius: 4px; background: var(--secondary-colors-artiox-gray-300, #f6f6f6); height: 40px;
                    font-family: barlow-regular, sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: normal; color: #6F6F6F; user-select: none">
            <div class="d-flex flex-row align-items-center justify-content-between w-100" style="">
                <div class="d-flex ml-3 barlow"
                     style="color: var(--text-color-artiox-grey, #6F6F6F);font-size: 14px;font-style: normal;font-weight: 600;line-height: normal;  white-space: nowrap">
                    Lot {{managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_LOT}}
                </div>
                <div class="d-flex mx-2">
                    <svg style=" height: 16px; width: 16px;">
                        <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#404352"/>
                    </svg>
                </div>
            </div>
            <div #dropdown class="dropdown-native-menu-lot d-none"
                 style="height: 120px; overflow: auto; width: 77px; border-radius: 4px; margin-top: 48px; background: var(--secondary-colors-artiox-gray-300, #F6F6F6);
                    font-family: barlow-regular, sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: normal; color: #6F6F6F"
            >
                <div
                        (click)="managerService.navigateIfLoggedIn({url: '/auction/auction_product', active_auction_product_hash: auction_product.hash, current_component_index: 3});"
                        *ngFor="let auction_product of (managerService.auctionService.auction_product_list_pipe| managerPipe: managerService.mpa_template.ACTIVE_CATALOG_STARTED_AUCTION_PRODUCT_ARRAY)"
                        class="dropdown-native-item-white  btn-native justify-content-center "
                        style=" height: 40px;" type="button">
                    <div
                            style="font-family: barlow-regular,sans-serif; font-size: 14px;font-style: normal;font-weight: 600;line-height: normal ">
                        Lot {{auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_LOT}}
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="d-flex flex-column " style="height: 60px;margin-top: 48px">
        <div (click)="managerService.openModal('auction-duration-rules-modal');"
             class="w-100 d-flex flex-row justify-content-end  align-items-center" style="cursor: pointer; height: 20px;">
            <svg style="height: 16px; width: 16px; margin-right: 10px; ">
                <use [attr.xlink:href]='"./assets/images/info.svg#info"' fill="#B5B5B5"/>
            </svg>
            <span class="barlow body1 medium"
                    [style]="(managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_COUNTDOWN) | async | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_TIME_STYLE">
                {{(managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_COUNTDOWN) | async | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_REMAIN_TIME}}</span>
        </div>
        <div
                [style]="(managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_COUNTDOWN) | async | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_COUNTDOWN_BAR"
                style="height: 10px;border-radius: 100px;margin: 5px 0px 5px 0px">
        </div>
        <div class="w-100 d-flex flex-row justify-content-end">
      <span [style.display]="managerService.auctionService.active_auction_product.auction_state !== 'knockdown'? 'flex' : 'none'" class="barlow body1 medium" class="barlow body1 medium" style="color: var(--secondary-colors-artiox-gray-500, #B5B5B5);">
        {{managerService.config.AUCTION.AUCTION_END | managerPipe}}
          : {{managerService.auctionService.active_auction_product_pipe | managerPipe:managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_END_DATE | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME }}

      </span >

            <span  [style.display]="managerService.auctionService.active_auction_product.auction_state !== 'knockdown'? 'flex' : 'none'" class="barlow body1 medium" style="color: var(--secondary-colors-artiox-yellow-base, #FFC300);">
        / {{managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_TIME_INCREMENT_TEXT}}
      </span>
            <div class="end-text barlow body1 medium" style="color: #EA3943;" [style.display]="managerService.auctionService.active_auction_product.auction_state === 'knockdown'? 'flex' : 'none'">
            <span >
                        {{managerService.config.AUCTION.END_TEXT | managerPipe}}. &nbsp;
                    </span>
                <span>
                    {{managerService.auctionService.active_auction_product_pipe | managerPipe:managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_END_DATE | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME }}
                </span>
            </div>
        </div>
    </div>

    <div *ngIf=" this.managerService.auctionService.active_auction_product.auction_state  === 'live'"
         [style.padding-top]="(managerService.auctionService.active_auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_OWNERSHIP_STATUS: managerService.user_bids_pipe) ? '0px': '28px'"
         class="d-flex flex-column justify-content-center align-items-center"
         style="border-radius: 8px;border: 1px solid var(--secondary-colors-artiox-gray-base, #E2E2E2);margin-top: 48px">

        <div class="d-flex flex-row w-100 position-relative">
            <div
                    *ngIf="(managerService.auctionService.active_auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_OWNERSHIP_STATUS: managerService.user_bids_pipe) "
                    class="d-flex w-100 justify-content-center align-items-center exo subtitle1 bold mb-2"
                    style="height: 32px; padding: 4px 8px;background: var(--main-color-artiox-purple-200, #ECE8FD);color: #8266F0">
                {{managerService.config.AUCTION.LOT_IS_YOURS | managerPipe}}
            </div>
            <div (click)="managerService.openModal('auction-bid-rules-modal')"
                 [style.top]="(managerService.auctionService.active_auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_OWNERSHIP_STATUS: managerService.user_bids_pipe) ? '0px': '-24px'"
                 class=" position-absolute"
                 style="right: 8px">
                <svg style="cursor: pointer; height: 24px; width: 24px;">
                    <use [attr.xlink:href]='"./assets/images/v2/ic_warning.svg#ic_warning"'
                         fill="none" stroke="var(--secondary-colors-artiox-gray-500, #B5B5B5)"/>
                </svg>
            </div>
        </div>


        <div class="w-100 px-5 pt-3 mb-4 d-flex flex-row justify-content-center">
            <div class="my-auto text-center">
                <div class="barlow body0 medium"
                     style="color: var(--text-color-artiox-grey, #6F6F6F);margin-bottom: 8px">{{managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_OPENING_PRICE | managerPipe: managerService.mpa_template.FANCY_NUMBER_NO_DECIMAL}}
                    TL
                </div>
                <div style="font-weight: 300;">{{managerService.config.AUCTION.OPENING_PRICE | managerPipe}}</div>
            </div>
            <span class="mx-4" style="width: 1px; height: 60px; background-color: #d8d8d8;"></span>
            <div class="my-auto text-center">
                <div class="barlow body0 medium"
                     style="color: var(--text-color-artiox-grey, #6F6F6F);margin-bottom: 8px">
                    {{managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_CURRENT_PRICE}}
<!--                    {{managerService.auctionService.active_auction_product.value_type | managerPipe}}-->
                </div>
                <div style="font-weight: 300;">{{managerService.config.AUCTION.CURRENT_PRICE | managerPipe}}</div>
            </div>
        </div>
        <div class="d-flex justify-content-between"
             style="margin-bottom: 24px;padding:  6px 6px  6px 6px;border-radius: 6px;border: 1px solid var(--secondary-colors-artiox-gray-base, #E2E2E2);background: var(--background-color-artiox-background, #FFF);width: 170px;">
            <div class="d-flex" style="width: 24px">
                <div
                    (click)="managerService.auctionService.setActiveAuctionProductBidPrice(managerService.auctionService.active_auction_product.active_bid_price - managerService.auctionService.active_auction_product.value_step)"
                    *ngIf="managerService.auctionService.active_auction_product.active_bid_price > (managerService.auctionService.active_auction_product.current_value + managerService.auctionService.active_auction_product.value_step) && managerService.auctionService.active_auction_product.active_bid_price > managerService.auctionService.active_auction_product.opening_value"
                    style="cursor: pointer; user-select: none">
                    <svg style="height: 24px; width: 24px;">
                        <use [attr.xlink:href]='"./assets/images/decrease.svg#decrease"' fill="none" stroke="#6f6f6f"/>
                    </svg>
                </div>
            </div>
            <div class="barlow body0 medium" style="color: #6f6f6f">
                {{managerService.auctionService.active_bid_price_pipe | managerPipe: managerService.mpa_template.ACTIVE_BID_PRICE}}
                TL
            </div>
            <div
                    (click)="managerService.auctionService.setActiveAuctionProductBidPrice(managerService.auctionService.active_auction_product.active_bid_price + managerService.auctionService.active_auction_product.value_step)"
                    style="cursor: pointer; user-select: none">
                <svg style="height: 24px; width: 24px;">
                    <use [attr.xlink:href]='"./assets/images/increase.svg#increase"' fill="none" stroke="#6f6f6f"/>
                </svg>
            </div>

        </div>

        <div
                (click)="managerService.auctionService.setActiveBidPrice(managerService.auctionService.active_auction_product.active_bid_price);managerService.openModalWithKyc('auction-bid-report-modal', 1);managerService.report_bid_modal.emit()"
                class=" barlow text-center"
                style="user-select:none;cursor: pointer; width: 148px; background: #8266F0; color: #FFFFFF; border-radius: 8px;padding: 8px 16px 8px 16px;margin-bottom: 32px">
            {{managerService.config.AUCTION.BID | managerPipe}}
            <svg class="ms-2" style="height: 20px; width: 20px; margin-top: -4px">
                <use [attr.xlink:href]='"./assets/images/gavel_white.svg#gavel_white"'/>
            </svg>
        </div>

        <!--        <div (click)="managerService.openModal('app-auction-price-estimation-modal')" class="barlow body2 regular"-->
        <!--             style="color: #6F6F6F; padding-bottom: 28px">-->
        <!--            <svg-->
        <!--                style="user-select:none;cursor: pointer; height: 14px; width: 14px; margin-top: -2px; margin-right: 4px;">-->
        <!--                <use [attr.xlink:href]='"./assets/images/info.svg#info"' fill="#6F6F6F"/>-->
        <!--            </svg>-->
        <!--            {{managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_PRICE_ESTIMATION : managerService.language}}-->
        <!--        </div>-->
    </div>
    <div *ngIf="this.managerService.auctionService.active_auction_product.auction_state  === 'knockdown'"
         class="d-flex flex-column justify-content-center align-items-center"
         style="border-radius: 8px;border: 1px solid var(--secondary-colors-artiox-gray-base, #E2E2E2);margin-top: 48px;">

        <div (click)="managerService.openModal('auction-bid-rules-modal')" class="d-flex align-self-end"
             style="padding-right: 8px; padding-top: 4px; padding-bottom: 12px">
            <svg style="cursor: pointer; height: 24px; width: 24px;">
                <use [attr.xlink:href]='"./assets/images/v2/ic_warning.svg#ic_warning"'
                     fill="none" stroke="var(--secondary-colors-artiox-gray-500, #B5B5B5)"/>
            </svg>
        </div>
        <div class="d-flex w-100 flex-column justify-content-center align-items-center text-center"
             style="padding: 0 8px">
            <span class="barlow body1 regular" style="color: var(--text-color-artiox-black); margin-bottom: 40px">
                {{[managerService.auctionService.active_auction_product.auction_end_date | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.AUCTION.AUCTION_FINALIZED.HEADER}}
            </span>

            <div class="d-flex flex-row justify-content-between w-100" style="margin-bottom: 16px; padding: 0 8px">
                <span class="barlow body1 medium" style="color: var(--secondary-colors-artiox-gray-500)">
                    {{managerService.config.AUCTION.AUCTION_FINALIZED.CLOSE}}
                </span>
                <span class="barlow body1 bold" style="color:  var(--text-color-artiox-grey, #6F6F6F);">
                    {{managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_CURRENT_PRICE}}
<!--                    {{managerService.auctionService.active_auction_product.value_type | managerPipe}}-->
                </span>
            </div>
            <span
                    style="width: 90%; height: 1px; background-color:var(--secondary-colors-artiox-gray-base, #E2E2E2); margin-bottom: 16px"></span>

            <div class="d-flex flex-row justify-content-between w-100" style="margin-bottom: 16px; padding: 0 8px">
                <span class="barlow body1 medium" style="color: var(--secondary-colors-artiox-gray-500)">
                    {{managerService.config.AUCTION.BID_REPORT.TOTAL}}
                </span>
                <span class="barlow body1 bold" style=" color: var(--main-color-artiox-purple-base, #8266F0);">
                    {{managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_TOTAL_PRICE}}
<!--                    {{managerService.auctionService.active_auction_product.value_type | managerPipe}}-->
                </span>
            </div>

            <span class="mb-5"
                  style="width: 90%; height: 1px; background-color:var(--secondary-colors-artiox-gray-base, #E2E2E2);"></span>

            <span class="barlow body2 regular"
                  style="color: var(--text-color-artiox-grey); margin-bottom: 32px">
                {{[managerService.auctionService.active_auction_product.opening_value | managerPipe: managerService.mpa_template.FANCY_NUMBER ] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.AUCTION.AUCTION_FINALIZED.OPEN}}
                {{managerService.auctionService.active_auction_product.value_type | managerPipe: managerService.mpa_template.VALUE_TYPE}}</span>
        </div>
    </div>
</div>


