<div class="w-100 d-flex flex-row align-items-start justify-content-center">
  <form
    [class]="(managerService.active_trade_type_panel | managerPipe: managerService.mpa_template.ACTIVE_TRADE_PANEL_SELL) ? 'd-flex' : 'd-none'"
    [formGroup]="limitSellForm" class="w-100 flex-column">
    <span class="my-2"
          style="font-family: Raleway, sans-serif; font-size: 12px; text-align: left; color: #afb7ca;">
      {{[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.BASIC.TRADE.AVAILABLE_AMOUNT}}
      {{managerService.pair_pipe | managerPipe: managerService.mpa_template.USER_ACTIVE_ART_PRODUCT_AVAILABLE_AMOUNT: managerService.user_pipe}}
    </span>
    <h2
      [innerHTML]="[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_CURRENCY_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.ADVANCED.TRADE.PRICE"
      class="my-2"
      style="font-family: Raleway, sans-serif; font-size: 16px; color: #afb7ca;">
    </h2>
    <label class="mb-4" style="border-bottom: solid 1px #9da5b7;">
      <input class="w-100 text-left" formControlName="price" min="0.00"
             placeholder="0" required step="0.01"
             style="margin:0; padding: 0; border: none; background: transparent;
             font-family: Raleway, sans-serif; font-size: 16px; color: #afb7ca;"
             type="number">
    </label>
    <h2
      [innerHTML]="[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.ADVANCED.TRADE.AMOUNT"
      class="my-2"
      style="font-family: Raleway, sans-serif; font-size: 16px; color: #afb7ca;">
    </h2>
    <label class="mb-4" style="border-bottom: solid 1px #9da5b7;">
      <input class="w-100 text-left" formControlName="amount" min="0.00" placeholder="0"
             required
             style="margin:0; padding: 0; border: none; background: transparent;
             font-family: Raleway, sans-serif; font-size: 16px; color: #afb7ca;" type="number">
    </label>
    <h2
      [innerHTML]="[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_CURRENCY_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.ADVANCED.TRADE.TOTAL"
      class="my-2"
      style="font-family: Raleway, sans-serif; font-size: 16px; color: #afb7ca;">
    </h2>
    <label class="mb-4" style="border-bottom: solid 1px #9da5b7;">
      <input class="w-100 text-left" disabled min="0.00" placeholder="0" readonly
             style="margin:0; padding: 0; border: none; background: transparent;
             font-family: Raleway, sans-serif; font-size: 16px; color: #afb7ca;"
             type="number"
             value="{{limit_sell_total | managerPipe}}">
    </label>
  </form>

  <form
    [class]="(managerService.active_trade_type_panel | managerPipe: managerService.mpa_template.ACTIVE_TRADE_PANEL_BUY) ? 'd-flex' : 'd-none'"
    [formGroup]="limitBuyForm" class="w-100 flex-column">
    <span class="my-2"
          style="font-family: Raleway, sans-serif; font-size: 12px; text-align: left; color: #afb7ca;">
      {{[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_CURRENCY_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.BASIC.TRADE.AVAILABLE_AMOUNT}}
      {{managerService.pair_pipe | managerPipe: managerService.mpa_template.USER_ACTIVE_CURRENCY_AVAILABLE_AMOUNT: managerService.user_pipe}}
    </span>
    <h2
      [innerHTML]="[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_CURRENCY_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.ADVANCED.TRADE.PRICE"
      class="my-2"
      style="font-family: Raleway, sans-serif; font-size: 16px; color: #afb7ca;">
    </h2>
    <label class="mb-4" style="border-bottom: solid 1px #9da5b7;">
      <input class="w-100 text-left"
             formControlName="price" min="0.00" placeholder="0" required step="0.01"
             style="margin:0; padding: 0; border: none; background: transparent;
             font-family: Raleway, sans-serif; font-size: 16px; color: #afb7ca;"
             type="number">
    </label>
    <h2
      [innerHTML]="[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.ADVANCED.TRADE.AMOUNT"
      class="my-2"
      style="font-family: Raleway, sans-serif; font-size: 16px; color: #afb7ca;">
    </h2>
    <label class="mb-4" style="border-bottom: solid 1px #9da5b7;">
      <input class="w-100 text-left" formControlName="amount" min="0.00" placeholder="0"
             required
             style="margin:0; padding: 0; border: none; background: transparent;
             font-family: Raleway, sans-serif; font-size: 16px; color: #afb7ca;" type="number">
    </label>
    <h2
      [innerHTML]="[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_CURRENCY_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.ADVANCED.TRADE.TOTAL"
      class="my-2"
      style="font-family: Raleway, sans-serif; font-size: 16px; color: #afb7ca;">
    </h2>
    <label class="mb-4" style="border-bottom: solid 1px #9da5b7;">
      <input class="w-100 text-left" disabled min="0.00" placeholder="0" readonly
             style="margin:0; padding: 0; border: none; background: transparent;
             font-family: Raleway, sans-serif; font-size: 16px; color: #afb7ca;"
             type="number"
             value="{{limit_buy_total | managerPipe}}">
    </label>
  </form>
</div>

<button (click)="limitBuy()"
        [class]="(managerService.active_trade_type_panel | managerPipe: managerService.mpa_template.ACTIVE_TRADE_PANEL_BUY) ? 'd-block' : 'd-none'"
        class="w-100 btn-native buy-button my-2 mx-auto py-2"
        style="border-radius: 20px;">
  {{managerService.config.BASIC.TRADE.BUY | managerPipe}}
</button>

<button (click)="limitSell()"
        [class]="(managerService.active_trade_type_panel | managerPipe: managerService.mpa_template.ACTIVE_TRADE_PANEL_SELL) ? 'd-block' : 'd-none'"
        class="w-100 btn-native sell-button my-2 mx-auto py-2"
        style="border-radius: 20px;">
  {{managerService.config.BASIC.TRADE.SELL | managerPipe}}
</button>
