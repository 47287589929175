<div class="w-100 d-flex flex-column align-items-start justify-content-start"
     style="background-color: #ffffff; border-radius: 4px;">

<!--  R.I.P. -->
<!--  <div class="d-flex flex-column"-->
<!--       style="width: 1020px; box-shadow: 2px 2px 9px 0 rgba(193, 196, 206, 0.5); background-color: #272a3b; max-height: 200px; min-height: 200px; border-radius: 4px;">-->
<!--    <div class="my-3 ml-4"-->
<!--         style="font-family: Roboto, sans-serif; font-size: 15px; font-weight: 300; line-height: 1.5; color: #59c8d9;">-->
<!--      <span class="font-weight-bold">{{managerService.config.DASHBOARD.WELCOME | managerPipe}}</span>-->
<!--      {{managerService.userService.user?.email | managerPipe}}-->
<!--    </div>-->
<!--    <div class="d-flex flex-row flex-grow-1 align-items-center justify-content-around m-2"-->
<!--         style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 300; line-height: 0.9; letter-spacing: 0.1px; color: #ffffff;">-->
<!--      <div *ngIf="!(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)" class="h-100 d-flex align-items-center justify-content-center"-->
<!--           style="min-width: 225px;">-->
<!--        <table *ngIf="!(0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_GREATER_THAN: managerService.user_pipe)" class="h-50 text-right"-->
<!--               style="width: 200px;">-->
<!--          <tr>-->
<!--            <td class="pl-1 py-2"></td>-->
<!--            <td class="pl-1 py-2">{{managerService.config.DASHBOARD.DAILY_LIMIT | managerPipe}}</td>-->
<!--            <td class="pl-1 py-2">{{managerService.config.DASHBOARD.MONTHLY_LIMIT | managerPipe}}</td>-->
<!--          </tr>-->
<!--          <tr style="border-bottom: solid 1px rgba(240, 242, 247, 0.3);">-->
<!--            <td class="pl-4 py-2">{{managerService.config.DASHBOARD.DEPOSIT | managerPipe}}</td>-->
<!--            <td class="pl-4 py-2">{{0 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
<!--            <td class="pl-4 py-2">{{0 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td class="pl-4 py-2">{{managerService.config.DASHBOARD.WITHDRAWAL | managerPipe}}</td>-->
<!--            <td class="pl-4 py-2">{{0 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
<!--            <td class="pl-4 py-2">{{0 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
<!--          </tr>-->
<!--        </table>-->
<!--        <svg class="position-absolute" style="height: 100px; width: 100px; transform: translate(-95px, -50px); z-index: 1;">-->
<!--          <use [attr.xlink:href]='"./assets/images/level_zero.svg#level_zero"'/>-->
<!--        </svg>-->
<!--        <svg *ngIf="0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe" class="position-absolute" style="height: 140px; width: 265px;">-->
<!--          <use [attr.xlink:href]='"./assets/images/level_box.svg#level_box"' fill="#ffffff"/>-->
<!--        </svg>-->
<!--        <span class="position-absolute" style="font-family: Roboto, sans-serif; font-size: 14px; z-index: 1;-->
<!--              line-height: 1.3; letter-spacing: 0.65px; color: #272a3b; transform: translate(-97.5px, -45px)">{{managerService.config.DASHBOARD.LEVEL | managerPipe}}</span>-->
<!--        <svg *ngIf="(0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_GREATER_THAN: managerService.user_pipe)" class="position-absolute" style="height: 190px; width: 200px;">-->
<!--          <use [attr.xlink:href]='"./assets/images/level_passed.svg#level_passed"'/>-->
<!--        </svg>-->
<!--      </div>-->
<!--      <div *ngIf="!(0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_GREATER_THAN: managerService.user_pipe)" class="h-100 d-flex align-items-center justify-content-center"-->
<!--           style="width: 18px;">-->
<!--        <svg style="height: 18px; width: 18px;">-->
<!--          <use [attr.xlink:href]='"./assets/images/blue_arrow.svg#blue_arrow"' fill="#40b4c8"/>-->
<!--        </svg>-->
<!--      </div>-->
<!--      <div (mouseenter)="level_one_hover=true;" (mouseleave)="level_one_hover=false;" *ngIf="!(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)"-->
<!--           class="h-100 d-flex align-items-center justify-content-center"-->
<!--           style="min-width: 225px;">-->
<!--        <table *ngIf="!(1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_GREATER_THAN: managerService.user_pipe)" class="h-50 text-right"-->
<!--               style="width: 200px;">-->
<!--          <tr>-->
<!--            <td class="pl-1 py-2"></td>-->
<!--            <td class=" py-2">{{managerService.config.DASHBOARD.DAILY_LIMIT | managerPipe}}</td>-->
<!--            <td class="pl-1 py-2">{{managerService.config.DASHBOARD.MONTHLY_LIMIT | managerPipe}}</td>-->
<!--          </tr>-->
<!--          <tr style="border-bottom: solid 1px rgba(240, 242, 247, 0.3);">-->
<!--            <td class="pl-2 py-2">{{managerService.config.DASHBOARD.DEPOSIT | managerPipe}}</td>-->
<!--            <td-->
<!--              class="pl-4 py-2">{{((1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe) ? managerService.userService.user?.daily_fiat_deposit_limit : 5000) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
<!--            <td-->
<!--              class="pl-4 py-2">{{((1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe) ? managerService.userService.user?.monthly_fiat_deposit_limit : 100000) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td class="pl-2 py-2">{{managerService.config.DASHBOARD.WITHDRAWAL | managerPipe}}</td>-->
<!--            <td-->
<!--              class="pl-4 py-2">{{((1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe) ? managerService.userService.user?.daily_fiat_withdrawal_limit : 5000) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
<!--            <td-->
<!--              class="pl-4 py-2">{{((1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe) ? managerService.userService.user?.monthly_fiat_withdrawal_limit : 100000) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
<!--          </tr>-->
<!--        </table>-->
<!--        <svg class="position-absolute" style="height: 100px; width: 100px; transform: translate(-90px, -50px); z-index: 1;">-->
<!--          <use [attr.xlink:href]='"./assets/images/level_one.svg#level_one"'/>-->
<!--        </svg>-->
<!--        <svg *ngIf="1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe" class="position-absolute" style="height: 140px; width: 265px;">-->
<!--          <use [attr.xlink:href]='"./assets/images/level_box.svg#level_box"' fill="#ffffff"/>-->
<!--        </svg>-->
<!--        <span class="position-absolute" style="font-family: Roboto, sans-serif; font-size: 14px; z-index: 1;-->
<!--              line-height: 1.3; letter-spacing: 0.65px; color: #272a3b; transform: translate(-92.5px, -45px)">{{managerService.config.DASHBOARD.LEVEL | managerPipe}}</span>-->
<!--        <svg *ngIf="(1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_GREATER_THAN: managerService.user_pipe)" class="position-absolute" style="height: 190px; width: 200px;">-->
<!--          <use [attr.xlink:href]='"./assets/images/level_passed.svg#level_passed"' fill="white"/>-->
<!--        </svg>-->
<!--      </div>-->
<!--      <div *ngIf="!(1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_GREATER_THAN: managerService.user_pipe)" class="h-100 d-flex align-items-center justify-content-center"-->
<!--           style="width: 18px;">-->
<!--        <svg style="height: 18px; width: 18px;">-->
<!--          <use [attr.xlink:href]='"./assets/images/blue_arrow.svg#blue_arrow"' fill="#40b4c8"/>-->
<!--        </svg>-->
<!--      </div>-->
<!--      <div (mouseenter)="level_two_hover=true;" (mouseleave)="level_two_hover=false;" class="h-100 d-flex align-items-center justify-content-center" style="min-width: 225px;">-->
<!--        <table *ngIf="!(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_GREATER_THAN: managerService.user_pipe)" class="h-50 text-right"-->
<!--               style="width: 200px;">-->
<!--          <tr>-->
<!--            <td class="pl-1 py-2"></td>-->
<!--            <td class="pl-1 py-2">{{managerService.config.DASHBOARD.DAILY_LIMIT | managerPipe}}</td>-->
<!--            <td class="pl-1 py-2">{{managerService.config.DASHBOARD.MONTHLY_LIMIT | managerPipe}}</td>-->
<!--          </tr>-->
<!--          <tr style="border-bottom: solid 1px rgba(240, 242, 247, 0.3);">-->
<!--            <td class="pl-2 py-2">{{managerService.config.DASHBOARD.DEPOSIT | managerPipe}}</td>-->
<!--            <td-->
<!--              class="pl-5 py-2">{{((2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe) ? managerService.userService.user?.daily_fiat_deposit_limit : 500000) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
<!--            <td-->
<!--              class="pl-5 py-2">{{((2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe) ? managerService.userService.user?.monthly_fiat_deposit_limit : 3000000) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td class="pl-2 py-2">{{managerService.config.DASHBOARD.WITHDRAWAL | managerPipe}}</td>-->
<!--            <td-->
<!--              class="pl-5 py-2">{{((2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe) ? managerService.userService.user?.daily_fiat_withdrawal_limit : 500000) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
<!--            <td-->
<!--              class="pl-5 py-2">{{((2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe) ? managerService.userService.user?.monthly_fiat_withdrawal_limit : 3000000) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>-->
<!--          </tr>-->
<!--        </table>-->
<!--        <svg class="position-absolute" style="height: 100px; width: 100px; transform: translate(-95px, -50px); z-index: 1;">-->
<!--          <use [attr.xlink:href]='"./assets/images/level_two.svg#level_two"'/>-->
<!--        </svg>-->
<!--        <span class="position-absolute" style="font-family: Roboto, sans-serif; font-size: 14px; z-index: 1;-->
<!--              line-height: 1.3; letter-spacing: 0.65px; color: #272a3b; transform: translate(-97.5px, -45px)">-->
<!--                {{managerService.config.DASHBOARD.LEVEL | managerPipe}}-->
<!--        </span>-->
<!--        <svg *ngIf="2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe" class="position-absolute" style="height: 140px; width: 300px;">-->
<!--          <use [attr.xlink:href]='"./assets/images/level_box.svg#level_box"' fill="#ffffff"/>-->
<!--        </svg>-->
<!--        <svg *ngIf="(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_GREATER_THAN: managerService.user_pipe)" class="position-absolute" style="height: 190px; width: 200px;">-->
<!--          <use [attr.xlink:href]='"./assets/images/level_passed.svg#level_passed"' fill="white"/>-->
<!--        </svg>-->
<!--      </div>-->
<!--      <div *ngIf="(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)" style="width: 630px;"></div>-->
<!--    </div>-->
<!--    <svg *ngIf="(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)" class="position-absolute align-self-end" style="height: 200px;">-->
<!--      <defs>-->
<!--        <linearGradient id="level_two_texture_gradient" x1="0%" x2="50%" y1="0%" y2="100%">>-->
<!--          <stop offset="0%" style="stop-color:rgb(255, 255, 255); stop-opacity: 0.4;"/>-->
<!--          <stop offset="100%" style="stop-color:rgb(255, 255, 255); stop-opacity: 0.1;"/>-->
<!--        </linearGradient>-->
<!--      </defs>-->
<!--      <use [attr.xlink:href]='"./assets/images/level_two_texture.svg#level_two_texture"' fill="url(#level_two_texture_gradient)"/>-->
<!--    </svg>-->
<!--  </div>-->

  <div class="w-100 mb-5 d-flex flex-row justify-content-between" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500; letter-spacing: 0.85px; color: #595e7e; line-height: 2">
    <div class="py-3" style="width: 49%; border-radius: 4px; box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff;">
      <div class="px-4" style="font-size: 18px; font-weight: bold; letter-spacing: 1.25px;">
        {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.TL.HEADING | managerPipe}}
      </div>
      <div class="w-100 px-1 py-2 d-flex flex-row justify-content-between" style="font-size: 14px">
        <div style="width: 30%;">
        </div>
        <div style="width: 35%;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.DEPOSIT | managerPipe}}
        </div>
        <div style="width: 35%;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.WITHDRAW | managerPipe}}
        </div>
      </div>
      <div [class.active-level-box]="0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe"
           class="w-100 px-1 py-2 d-flex flex-row justify-content-between">
        <div [class.active-level-text]="0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe"
             style="width: 30%;" class="d-flex align-items-center light-text">
          <svg class="mx-1" style="height: 16px; width: 16px; transform: rotate(180deg);"
               [class.invisible]="!(0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)">
            <use [attr.xlink:href]='"./assets/images/level_cursor.svg#level_cursor"' fill="var(--artiox-blue)"/>
          </svg>
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.LEVEL | managerPipe}} 0
        </div>
        <div class="d-flex align-items-center" style="width: 35%; min-height: 48px;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.TL.LEVEL_0_DESCRIPTION | managerPipe}}
        </div>
        <div class="d-flex align-items-center" style="width: 35%; min-height: 48px;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.TL.LEVEL_0_DESCRIPTION | managerPipe}}
        </div>
      </div>
      <div [class.active-level-box]="1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe"
           class="w-100 px-1 py-2 d-flex flex-row justify-content-between">
        <div [class.active-level-text]="1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe"
             style="width: 30%;" class="d-flex align-items-center light-text">
          <svg class="mx-1" style="height: 16px; width: 16px; transform: rotate(180deg);"
               [class.invisible]="!(1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)">
            <use [attr.xlink:href]='"./assets/images/level_cursor.svg#level_cursor"' fill="var(--artiox-blue)"/>
          </svg>
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.LEVEL | managerPipe}} 1
        </div>
        <div style="width: 35%;">
            {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.TL.LEVEL_1_DAILY_LIMIT | managerPipe}}
          <span class="light-text">
            / {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.DAY | managerPipe}}
          </span>
          <br>
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.TL.LEVEL_1_MONTHLY_LIMIT | managerPipe}}
          <span class="light-text">
            / {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.MONTH | managerPipe}}
          </span>
        </div>
        <div style="width: 35%;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.TL.LEVEL_1_DAILY_LIMIT | managerPipe}}
          <span class="light-text">
            / {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.DAY | managerPipe}}
          </span>
          <br>
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.TL.LEVEL_1_MONTHLY_LIMIT | managerPipe}}
          <span class="light-text">
            / {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.MONTH | managerPipe}}
          </span>
        </div>
      </div>
      <div [class.active-level-box]="2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe"
           class="w-100 px-1 py-2 d-flex flex-row justify-content-between">
        <div [class.active-level-text]="2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe"
             style="width: 30%;" class="d-flex align-items-center light-text">
          <svg class="mx-1" style="height: 16px; width: 16px; transform: rotate(180deg);"
               [class.invisible]="!(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)">
            <use [attr.xlink:href]='"./assets/images/level_cursor.svg#level_cursor"' fill="var(--artiox-blue)"/>
          </svg>
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.LEVEL | managerPipe}} 2
        </div>
        <div style="width: 35%;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.TL.LEVEL_2_DAILY_LIMIT | managerPipe}}
          <span class="light-text">
            / {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.DAY | managerPipe}}
          </span>
          <br>
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.TL.LEVEL_2_MONTHLY_LIMIT | managerPipe}}
          <span class="light-text">
            / {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.MONTH | managerPipe}}
          </span>
        </div>
        <div style="width: 35%;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.TL.LEVEL_2_DAILY_LIMIT | managerPipe}}
          <span class="light-text">
            / {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.DAY | managerPipe}}
          </span>
          <br>
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.TL.LEVEL_2_MONTHLY_LIMIT | managerPipe}}
          <span class="light-text">
            / {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.MONTH | managerPipe}}
          </span>
        </div>
      </div>
    </div>
    <div class="py-3" style="width: 49%; border-radius: 4px; box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff;">
      <div class="px-4" style="font-size: 18px; font-weight: bold; letter-spacing: 1.25px;">
        {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.CRYPTO.HEADING | managerPipe}}
      </div>
      <div class="w-100 px-1 py-2 d-flex flex-row justify-content-between" style="font-size: 14px">
        <div style="width: 30%;">
        </div>
        <div style="width: 35%;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.DEPOSIT | managerPipe}}
        </div>
        <div style="width: 35%;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.WITHDRAW | managerPipe}}
        </div>
      </div>
      <div [class.active-level-box]="0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe"
           class="w-100 px-1 py-2 d-flex flex-row justify-content-between">
        <div [class.active-level-text]="0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe"
             style="width: 30%;" class="d-flex align-items-center light-text">
          <svg class="mx-1" style="height: 16px; width: 16px; transform: rotate(180deg);"
               [class.invisible]="!(0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)">
            <use [attr.xlink:href]='"./assets/images/level_cursor.svg#level_cursor"' fill="var(--artiox-blue)"/>
          </svg>
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.LEVEL | managerPipe}} 0
        </div>
        <div class="d-flex align-items-center" style="width: 35%; min-height: 48px;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.CRYPTO.DESCRIPTION | managerPipe}}
        </div>
        <div class="d-flex align-items-center" style="width: 35%; min-height: 48px;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.CRYPTO.DESCRIPTION | managerPipe}}
        </div>
      </div>
      <div [class.active-level-box]="1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe"
           class="w-100 px-1 py-2 d-flex flex-row justify-content-between">
        <div [class.active-level-text]="1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe"
             style="width: 30%;" class="d-flex align-items-center light-text">
          <svg class="mx-1" style="height: 16px; width: 16px; transform: rotate(180deg);"
               [class.invisible]="!(1 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)">
            <use [attr.xlink:href]='"./assets/images/level_cursor.svg#level_cursor"' fill="var(--artiox-blue)"/>
          </svg>
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.LEVEL | managerPipe}} 1
        </div>
        <div class="d-flex align-items-center" style="width: 35%; min-height: 48px;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.CRYPTO.DESCRIPTION | managerPipe}}
        </div>
        <div class="d-flex align-items-center" style="width: 35%; min-height: 48px;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.CRYPTO.DESCRIPTION | managerPipe}}
        </div>
      </div>
      <div [class.active-level-box]="2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe"
           class="w-100 px-1 py-2 d-flex flex-row justify-content-between">
        <div [class.active-level-text]="2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe"
             style="width: 30%;" class="d-flex align-items-center light-text">
          <svg class="mx-1" style="height: 16px; width: 16px; transform: rotate(180deg);"
               [class.invisible]="!(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO: managerService.user_pipe)">
            <use [attr.xlink:href]='"./assets/images/level_cursor.svg#level_cursor"' fill="var(--artiox-blue)"/>
          </svg>
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.LEVEL | managerPipe}} 2
        </div>
        <div style="width: 35%;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.CRYPTO.LEVEL_2_DAILY_LIMIT | managerPipe}}
          <span class="light-text">
            / {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.DAY | managerPipe}}
          </span>
          <br>
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.CRYPTO.LEVEL_2_MONTHLY_LIMIT | managerPipe}}
          <span class="light-text">
            / {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.MONTH | managerPipe}}
          </span>
        </div>
        <div style="width: 35%;">
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.CRYPTO.LEVEL_2_DAILY_LIMIT | managerPipe}}
          <span class="light-text">
            / {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.DAY | managerPipe}}
          </span>
          <br>
          {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.CRYPTO.LEVEL_2_MONTHLY_LIMIT | managerPipe}}
          <span class="light-text">
            / {{managerService.config.SECURITY.IDENTITY_VERIFICATION.LIMITS.MONTH | managerPipe}}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div [class.d-none]="!(managerService.userService.user?.account_level ===0) | managerPipe">
    <form [formGroup]="kycLevelOneForm" class="d-flex flex-column align-items-start justify-content-start m-4 p-4" style="width: 800px; height: fit-content;">
      <div class="w-100" style="font-family: bebas-neue-regular, sans-serif; font-size: 25px; font-weight: bold; line-height: 1.25; letter-spacing: 2.5px; color: #404352;">
        {{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_ONE.FORM_HEADING | managerPipe}}
      </div>
      <div class="w-100 d-flex flex-row align-items-center justify-content-between">
        <div class="d-flex flex-column" style="width: 45%; min-width: 240px;">
          <div class="w-100 d-flex flex-column">
            <label class="position-relative my-4 d-flex flex-row" style="height: 30px;">
              <input class="w-100 input-field-underline kyc-level-one-input-field" formControlName="name"
                     placeholder="{{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_ONE.NAME | managerPipe}}" required
                     style="font-weight: normal" type="text">
            </label>
            <label class="position-relative my-4 d-flex flex-row" style="height: 30px;">
              <input class="w-100 input-field-underline kyc-level-one-input-field" formControlName="surname"
                     placeholder="{{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_ONE.SURNAME | managerPipe}}" required
                     style="font-weight: normal" type="text">
            </label>

            <div class="position-relative my-4 dropdown-native-button" style="height: 30px;">
              <label (click)="year_dropdown_menu.classList.remove('d-none')" class="position-relative d-flex flex-row" style="height: 30px; margin: 0;">
                <input class="w-100 input-field-underline kyc-level-one-input-field" formControlName="birth_year"
                       placeholder="{{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_ONE.BIRTH_YEAR | managerPipe}}" readonly required
                       style="cursor: pointer; font-weight: normal" type="number">
                <svg class="dropdown-icon">
                  <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#9DA5B7"/>
                </svg>
              </label>
              <div #year_dropdown_menu class="dropdown-native-menu-white d-none">
                <button (click)="year_dropdown_menu.classList.add('d-none'); kycLevelOneForm.get('birth_year')?.setValue(year);"
                        *ngFor="let year of (false | managerPipe: managerService.mpa_template.GET_BIRTH_YEAR_LIST)"
                        class="dropdown-native-item-white btn-native justify-content-center" type="button">
                  {{year | managerPipe}}
                </button>
              </div>
            </div>

          </div>
        </div>
        <div class="d-flex flex-column" style="width: 45%; min-width: 240px;">
          <div class="w-100 d-flex flex-column">
            <label class="position-relative my-4 d-flex flex-row" style="height: 30px;">
              <input [maxLength]="11" class="w-100 input-field-underline kyc-level-one-input-field" formControlName="tc_no"
                     placeholder="{{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_ONE.TC | managerPipe}}" required
                     style="font-weight: normal" type="number"
                     oninput="this.value = this.value.slice(0,this.maxLength)">
            </label>
            <div class="position-relative my-4 dropdown-native-button" style="height: 30px;">
              <label (click)="town_dropdown_menu.classList.remove('d-none')" class="position-relative d-flex flex-row" style="height: 30px; margin: 0;">
                <input class="w-100 input-field-underline kyc-level-one-input-field" formControlName="town"
                       placeholder="{{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_ONE.TOWN | managerPipe}}" readonly required
                       style="cursor: pointer; font-weight: normal" type="text">
                <svg class="dropdown-icon">
                  <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#9DA5B7"/>
                </svg>
              </label>
              <div #town_dropdown_menu class="dropdown-native-menu-white d-none">
                <button (click)="town_dropdown_menu.classList.add('d-none'); kycLevelOneForm.get('town')?.setValue(town);" *ngFor="let town of (null | managerPipe: managerService.mpa_template.GET_CITIES_LIST)"
                        class="dropdown-native-item-white btn-native justify-content-center" type="button">
                  {{town | managerPipe}}
                </button>
              </div>
            </div>
            <label class="position-relative my-4 d-flex flex-row" style="height: 30px;">
              <input [maxLength]="10"
                     class="w-100 input-field-underline kyc-level-one-input-field" formControlName="phone_number" id="phone_number"
                     placeholder="{{managerService.config.USER_INFORMATION.PHONE_NUMBER | managerPipe}}"
                     style="font-weight: normal"
                     oninput="this.value = this.value.slice(0,this.maxLength)"
                     type="number">
            </label>
          </div>
        </div>

      </div>
      <div class="d-flex w-100">
        <label class="position-relative my-4 d-flex flex-row w-100" style="height: 30px;">
          <input class="w-100 input-field-underline kyc-level-one-input-field" formControlName="address"
                 placeholder="{{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_ONE.ADDRESS | managerPipe}}" required
                 style="font-weight: normal" type="text">
        </label>
      </div>

      <div #warning_kyc_level_one class="invisible warning"></div>
      <button (click)="verifyKYCLevelOne()" class="btn-native my-4 kyc-button"
              [disabled]="(kycLevelOneForm.invalid || level_one_button_lock) | managerPipe"
              [class.button-inactive]="(kycLevelOneForm.invalid || level_one_button_lock) | managerPipe">
        {{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_ONE.COMPLETE | managerPipe}}
      </button>
    </form>
  </div>

  <div [class.d-none]="!(managerService.userService.user?.account_level ===1 && managerService.userService.user?.second_level_kyc_status !== 'applied') | managerPipe">
    <form [formGroup]="kycLevelTwoForm" class="d-flex flex-column align-items-start justify-content-start m-4 p-4" style="width: 1000px; height: fit-content;">
      <div class="w-100" style="font-family: bebas-neue-regular, sans-serif; font-size: 25px; font-weight: bold; line-height: 1.25; letter-spacing: 2.5px; color: #404352;">
        {{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_TWO.FORM_HEADING | managerPipe}}
      </div>
      <div class="w-100 d-flex flex-row align-items-center justify-content-between">
        <div class="d-flex flex-column" style="width: 45%; min-width: 240px;">
          <div class="w-100 d-flex flex-row align-items-center justify-content-center p-1 my-3"
               style="width: fit-content; border-radius: 4px; box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #f4f5f9;">
            <div class="mx-3">
              <svg style="width: 125px;">
                <use [attr.xlink:href]='"./assets/images/id_card.svg#id_card"' fill="#9DA5B7"/>
              </svg>
            </div>
            <span style="height: 90px; width: 1px; min-width: 1px; margin: auto 10px; border-radius:1px; background-color: #9da5b7;"></span>
            <div class="h-100 d-flex flex-column align-items-center justify-content-center mx-3"
                 style="font-family: Roboto, sans-serif; font-size: 14px; line-height: 1.20; letter-spacing: 1.4px; color: #404352;">
              {{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_TWO.ID_CARD_EXPLANATION | managerPipe}}
            </div>
          </div>
          <div class="m-2" style="font-family: Roboto, sans-serif; font-size: 13px; line-height: 2.25; letter-spacing: 1.35px; color: #404352;">
            {{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_TWO.UPLOAD_FILE | managerPipe}}
          </div>
          <div class="m-2" style="font-family: Roboto, sans-serif; font-size: 13px; font-style: italic; line-height: 1.15; letter-spacing: 1.35px; color: #595e7e;">
            -{{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_TWO.ID_CARD_RULE | managerPipe}}
          </div>
          <label class="position-relative my-4 d-flex flex-row" style="height: 30px; width: 240px;">
            <input (change)="handleIDCardFileInput($event.target)" accept="image/*;capture=camera" class="position-absolute input-field-white" formControlName="id_card_photo_file" required
                   style="opacity: 0; cursor: pointer;" type='file'>
            <label class="d-inline-block input-field-white kyc-level-two-input-field text-center py-1" style="font-weight: normal; height: fit-content; ">
              {{kycLevelTwoForm.get('id_card_photo_file')?.value | managerPipe: managerService.mpa_template.FILE_INPUT_TEXT_TRANSFORM: managerService.language_pipe}}
            </label>
            <button *ngIf="(id_card_photo_file) | managerPipe" [disabled]="(id_card_photo_file) | managerPipe" class="btn-native"
                    type="button">
              <svg (click)="resetIDCardFile()" class="cancel-icon" style="margin-top:14px; height: 18px; width: 18px;">
                <use [attr.xlink:href]='"./assets/images/close.svg#close"' fill="#9DA5B7"/>
              </svg>
            </button>
          </label>
        </div>
        <div class="d-flex flex-column" style="width: 45%; min-width: 240px;">
          <div class="w-100 d-flex flex-row align-items-center justify-content-center p-1 my-3"
               style="width: fit-content; border-radius: 4px; box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #f4f5f9;">
            <div class="mx-3">
              <svg style="width: 125px;">
                <use [attr.xlink:href]='"./assets/images/face_and_id_card.svg#face_and_id_card"' fill="#9DA5B7"/>
              </svg>
            </div>
            <span style="height: 90px; width: 1px; min-width: 1px; margin: auto 10px; border-radius:1px; background-color: #9da5b7;"></span>
            <div class="h-100 d-flex flex-column align-items-center justify-content-center mx-3"
                 style="font-family: Roboto, sans-serif; font-size: 14px; line-height: 1.20; letter-spacing: 1.4px; color: #404352;">
              {{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_TWO.FACE_EXPLANATION | managerPipe}}
            </div>
          </div>
          <div class="m-2" style="font-family: Roboto, sans-serif; font-size: 13px; line-height: 2.25; letter-spacing: 1.35px; color: #404352;">
            {{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_TWO.UPLOAD_FILE | managerPipe}}
          </div>
          <div class="m-2" style="min-height: 28px; font-family: Roboto, sans-serif; font-size: 13px; font-style: italic; line-height: 1.15; letter-spacing: 1.35px; color: #595e7e;">
            -{{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_TWO.FACE_RULE | managerPipe}}
          </div>
          <label class="position-relative my-4 d-flex flex-row " style="height: 30px; width: 240px;">
            <input (change)="handleFaceFileInput($event.target)" accept="image/*;capture=camera" class="position-absolute input-field-white" formControlName="face_photo_file" required
                   style="opacity: 0; cursor: pointer;" type='file'>
            <label class="d-inline-block input-field-white kyc-level-two-input-field text-center py-1" style="height: fit-content; font-weight: normal">
              {{kycLevelTwoForm.get('face_photo_file')?.value | managerPipe: managerService.mpa_template.FILE_INPUT_TEXT_TRANSFORM: managerService.language_pipe}}
            </label>
            <button *ngIf="(face_photo_file) | managerPipe" class="btn-native" type="button">
              <svg (click)="resetFaceFile()" class="cancel-icon" style="margin-top:14px; height: 18px; width: 18px;">
                <use [attr.xlink:href]='"./assets/images/close.svg#close"' fill="#9DA5B7"/>
              </svg>
            </button>
          </label>
        </div>
      </div>
      <div #warning_kyc_level_two class="invisible warning" style="margin: 10px 20px 0"></div>
      <button (click)="verifyKYCLevelTwo()" [class.button-inactive]="!(id_card_photo_file && face_photo_file) || level_two_button_lock | managerPipe"
              [disabled]="!(id_card_photo_file && face_photo_file) || level_two_button_lock | managerPipe"
              class="btn-native my-4 kyc-button">
        {{managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_TWO.COMPLETE | managerPipe}}
      </button>
    </form>
  </div>

  <div [class.d-none]="!(managerService.userService.user?.account_level === 1 && managerService.userService.user?.second_level_kyc_status === 'applied')  | managerPipe"
       class="w-100 flex-row align-items-center justify-content-start p-4"
       style="font-family: Roboto, sans-serif; font-size: 14px; line-height: 1.5; letter-spacing: 1.4px; color: #404352;">
    <svg class="mr-2" style="height: 30px; width: 30px;">
      <use [attr.xlink:href]='"./assets/images/second_level_kyc_pending.svg#second_level_kyc_pending"' fill="white"/>
    </svg>
    <span [innerHTML]="managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_TWO.WAITING_FOR_APPROVAL | managerPipe"></span>
  </div>

  <div [class.d-none]="!(managerService.userService.user?.account_level===2) | managerPipe" class="w-100 flex-row align-items-center justify-content-start  p-4"
       style="font-family: Roboto, sans-serif; font-size: 14px; line-height: 1.5; letter-spacing: 1px; color: #404352;">
    <svg class="mr-2" style="height: 30px; width: 30px;">
      <use [attr.xlink:href]='"./assets/images/second_level_kyc_verified.svg#second_level_kyc_verified"' fill="white"/>
    </svg>
    <span [innerHTML]="managerService.config.SECURITY.IDENTITY_VERIFICATION.KYC_LEVEL_TWO.COMPLETED | managerPipe"></span>
  </div>

</div>


