import { Component } from '@angular/core';
import {ManagerService} from '../../../../services/manager.service';

@Component({
  selector: 'app-support-category',
  templateUrl: './support-category.component.html',
  styleUrl: './support-category.component.css'
})
export class SupportCategoryComponent {
  constructor(protected managerService: ManagerService) {

  }


}
