<div style="max-width: 650px;">
  <div class="p-5 w-100 d-flex flex-column align-items-start justify-content-center">
    <div class="d-flex w-100 justify-content-end">
      <svg style="margin-top: -16px; margin-right: -8px; height: 18px; width: 18px; cursor: pointer" (click)="managerService.closeModal(modal_id)">
        <use [attr.xlink:href]='"./assets/images/exit_icon.svg#exit_icon"' fill="#404352"/>
      </svg>
    </div>
    <div class="p-3"
         style="font-family: bebas-neue-regular, sans-serif;font-size: 24px; font-weight: bold; line-height: 1.2; letter-spacing: 2.5px;  color: #404352;">
      {{ (this.managerService.config.DELISTING | managerPipe: this.managerService.mpa_template.RETURN_INDEX: active_art_product_symbol).TITLE }}
    </div>
    <div style="font-family: Roboto, sans-serif; font-size: 17px; line-height: 1.1; letter-spacing: 0.4px; color: #4a4e70; padding: 12px 24px"
         *ngFor="let rule of (this.managerService.config.DELISTING | managerPipe: this.managerService.mpa_template.RETURN_INDEX: active_art_product_symbol).TEXT">
      <span style=" color: #404352;" [innerHTML]="rule"></span>
    </div>

    <button (click)="closeModal()"
            class="btn-native checkbox-button mb-0" style="margin-top: 36px">
      {{ managerService.config.DELISTING.BUTTON_TEXT | managerPipe }}
    </button>
  </div>
</div>

