import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ManagerService} from '../../../services/manager.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-header-desktop',
    templateUrl: './header-desktop.component.html',
    styleUrls: ['./header-desktop.component.css']
})
export class HeaderDesktopComponent implements OnInit, OnDestroy {
    // @ViewChild('header') header: any;
    // @ViewChild('announcement') announcement: any;

    // announcement_text: string = '';
    // announcement_link: any;

    public auctionHover: boolean = false;
    public exchangeHover: boolean = false;

    subscription_list: Subscription[] = [];

    constructor(public managerService: ManagerService) {
    }

    ngOnInit(): void {
        // this.subscription_list.push(this.managerService.language_changed.subscribe(() => {
        //     this.setAnnouncement();
        // }));
        // this.managerService.pairService.pair_list_changed.subscribe(() => {
        //    this.initAnnouncement();
        // });
    }

    // initAnnouncement(): void {
    //     this.setAnnouncement();
    //     if (this.announcement_text) {
    //         setTimeout(() => {
    //             this.openAnnouncement();
    //         }, 1200);
    //     }
    // }

    // setAnnouncement(): void {
    //     let notListedPairList;
    //     /* if (this.managerService.config.HEADER.ANNOUNCEMENT.TEXT) {
    //          this.announcement_text = this.managerService.config.HEADER.ANNOUNCEMENT.TEXT;
    //         this.announcement_link = this.managerService.config.HEADER.ANNOUNCEMENT.LINK;
    //          } else */
    //     if (( notListedPairList = this.managerService.pairService.getNotListedPairList()).length) {
    //         const lastPair = notListedPairList[notListedPairList.length - 1];
    //         const lastPairName = lastPair.symbol.split('/')[0];
    //         // @ts-ignore
    //         this.announcement_text = lastPair.symbol;
    //         this.announcement_link = {url: '/presale', active_featured_pair_id: lastPair.id, current_component_index: 0};
    //     }
    // }

    headerIconClick(): void {
        if (this.managerService.logged_in || !this.managerService.utilityService.checkReleaseUrl()) {
            this.managerService.navigationManager({url: '/home', current_component_index: 0});
        } else {
            window.open('https://tr.artiox.com/', '_self');
        }
    }

    // openAnnouncement(): void {
    //     this.announcement.nativeElement.classList.remove('d-none');
    //     this.announcement.nativeElement.classList.add('d-flex');
    //     this.header.nativeElement.style.animation = '0.8s ease transform-bottom';
    // }

    // closeAnnouncement(): void {
    //     this.announcement.nativeElement.classList.remove('d-flex');
    //     this.announcement.nativeElement.classList.add('d-none');
    //     this.header.nativeElement.style.animation = '0.6s ease transform-top';
    // }

    ngOnDestroy(): void {
        this.subscription_list.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

}
