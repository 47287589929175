import { Component, OnInit } from '@angular/core';
import {ManagerService} from "../../../../../../services/manager.service";

@Component({
  selector: 'app-my-bids-desktop',
  templateUrl: './my-bids-desktop.component.html',
  styleUrls: ['./my-bids-desktop.component.css']
})
export class MyBidsDesktopComponent implements OnInit {

  constructor(public managerService: ManagerService) { }

  ngOnInit(): void {
  }

}
