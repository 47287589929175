import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../services/manager.service';

@Component({
  selector: 'app-withdrawal-confirmed',
  templateUrl: './withdrawal-confirmed.component.html',
  styleUrls: ['./withdrawal-confirmed.component.css']
})
export class WithdrawalConfirmedComponent implements OnInit {

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

}
