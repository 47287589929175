import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {BillingAddress} from '../../../../../services/user.service';
import {HttpClient} from '@angular/common/http';
import {UtilityService} from '../../../../../services/utility.service';
import {ManagerService} from '../../../../../services/manager.service';

enum BillingOption {
    INDIVIDUAL,
    CORPORATE
}

// interface BillingAddressData {
//     billing_address_id?: number;
//     address_type: string;
//     label: string;
//     company_name: string;
//     tax_administration: string;
//     tax_number: string;
//     province: string;
//     district: string;
//     address: string;
//     phone_number: string;
// }

@Component({
    selector: 'app-add-billing-address-mobile',
    templateUrl: './add-billing-address-mobile.component.html',
    styleUrls: ['./add-billing-address-mobile.component.css']
})
export class AddBillingAddressMobileComponent implements OnInit, OnDestroy {
    @Input() modal_id: string = '';

    individualFormGroup: FormGroup = new FormGroup({
            label: new FormControl(''),
            phone_number: new FormControl(this.managerService.userService.user_info?.phone_number, [Validators.required, this.phoneNumberValidator()]),
            province: new FormControl('', [Validators.required]),
            district: new FormControl('', [Validators.required]),
            address: new FormControl('', [Validators.required, Validators.minLength(10)]),
        }
    );
    corporateFormGroup: FormGroup = new FormGroup({
            label: new FormControl(''),
            phone_number: new FormControl(this.managerService.userService.user_info?.phone_number, [Validators.required, this.phoneNumberValidator()]),
            corporateName: new FormControl('', [Validators.required]),
            taxAdministration: new FormControl('', [Validators.required]),
            taxNumber: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}$/), Validators.maxLength(10)]),
            province: new FormControl('', [Validators.required]),
            district: new FormControl('', [Validators.required]),
            address: new FormControl('', [Validators.required, Validators.minLength(10)]),
        }
    );
    activeFormGroup: FormGroup = this.individualFormGroup;


    billingOption: BillingOption = BillingOption.INDIVIDUAL;
    readonly BillingOption = BillingOption;

    checkboxSelected: boolean = false;
    updateInfo: BillingAddress | undefined;
    tc_number_visible: boolean = false;

    subscription_list: (Subscription | undefined)[] = [];

    constructor(public managerService: ManagerService, public http: HttpClient) {
    }

    ngOnInit(): void {
        this.activeFormGroup = this.individualFormGroup;
        this.subscription_list.push(this.managerService.userService.selected_user_billing_address_changed.subscribe(billingAddress => {
            if (billingAddress === null) {
                this.corporateFormGroup.reset();
                this.individualFormGroup.reset();
            } else {
                this.updateFormGrups(billingAddress);
            }
        }));
        this.subscription_list.push(this.corporateFormGroup.get('phone_number')?.valueChanges.subscribe(() => {
            this.formatPhoneNumber();
        }));
        this.subscription_list.push(this.individualFormGroup.get('phone_number')?.valueChanges.subscribe(() => {
            this.formatPhoneNumber();
        }));
    }

    formatPhoneNumber(): void {
        if (this.activeFormGroup.get('phone_number')?.value) {
            const input = this.activeFormGroup.get('phone_number')?.value.replace(/\D/g, '').substring(0, 10);
            const zip = input.substring(0, 3);
            const middle = input.substring(3, 6);
            const lastFirst = input.substring(6, 8);
            const lastSecond = input.substring(8, 10);
            let phone = '';
            if (input.length > 8) {
                phone = `(${zip}) ${middle} ${lastFirst} ${lastSecond}`;
            } else if (input.length > 6) {
                phone = `(${zip}) ${middle} ${lastFirst}`;
            } else if (input.length > 3) {
                phone = `(${zip}) ${middle}`;
            } else if (input.length > 0) {
                phone = `(${zip}`;
            }
            if (this.activeFormGroup.get('phone_number')?.value !== phone && phone.length > 0) {
                this.activeFormGroup.get('phone_number')?.setValue(phone);
            }
        }
    }

    phoneNumberValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const phoneNumber: string = control.value?.replace(/\D/g, '').substring(0, 10);
            if (UtilityService.validatePhoneNumber(phoneNumber)) {
                return null;
            }
            return {wrongNumber: control.value};
        };
    }

    updateFormGrups(billingAddress: BillingAddress): void {
        if (this.managerService.userService.selected_user_address_operation === 'update') {
            this.updateInfo = billingAddress;
            if (billingAddress.address_type === 'individual') {
                this.activeFormGroup = this.individualFormGroup;
                this.billingOption = this.BillingOption.INDIVIDUAL;
                this.individualFormGroup.setValue({
                    label: billingAddress.label,
                    phone_number: billingAddress.phone_number,
                    province: billingAddress.province,
                    district: billingAddress.district,
                    address: billingAddress.address
                });
            } else {
                this.activeFormGroup = this.corporateFormGroup;
                this.billingOption = this.BillingOption.CORPORATE;
                this.corporateFormGroup.setValue({
                    label: billingAddress.label,
                    phone_number: billingAddress.phone_number,
                    corporateName: billingAddress.company_name,
                    taxAdministration: billingAddress.tax_administration,
                    taxNumber: billingAddress.tax_number,
                    province: billingAddress.province,
                    district: billingAddress.district,
                    address: billingAddress.address,
                });
            }
        }
    }

    saveBillingAddress(): void {
        if (!this.validateFormGroup()) {
            return;
        }
        const data = this.getPayload();
        const url = this.managerService.userService.selected_user_address_operation === 'update' ? 'update_user_billing_information_service' : 'add_user_billing_information_service';
        this.subscription_list.push(this.http.post<any>(this.managerService.environment.serverAPI + url, data).subscribe(
            response => {
                // @ts-ignore
                const content = this.managerService.config.RESPONSE_TABLE[response.code];
                this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                this.managerService.closeModal(this.modal_id);
                if (url === 'add_user_billing_information_service'){
                    this.managerService.marketingService.logEvent('address_added', {});
                }
            }
        ));
    }

    // addBillingAddress(): void {
    //     if (!this.validateFormGroup()) {
    //         return;
    //     }
    //     const data = this.getPayload();
    //     this.subscription_list.push(this.http.post<any>(this.managerService.environment.serverAPI + 'add_user_billing_information_service', data).subscribe(
    //         response => {
    //             // if (!response.is_okay) {
    //             //     // @ts-ignore
    //             //     const content = this.managerService.config.RESPONSE_TABLE[response.code];
    //             //     this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
    //             // } else {
    //             //     // @ts-ignore
    //             //     const content = this.managerService.config.RESPONSE_TABLE[response.code];
    //             //     this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
    //             // }
    //             // @ts-ignore
    //             const content = this.managerService.config.RESPONSE_TABLE[response.code];
    //             this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
    //             this.managerService.closeModal(this.modal_id);
    //         }
    //     ));
    // }
    //
    // updateBillingAddress(): void {
    //     if (!this.validateFormGroup()) {
    //         return;
    //     }
    //     const data = this.getPayload(this.updateInfo?.address_id);
    //     this.subscription_list.push(this.http.post<any>(this.managerService.environment.serverAPI + 'update_user_billing_information_service', data).subscribe(
    //         response => {
    //             // if (!response.is_okay) {
    //             //     // @ts-ignore
    //             //     const content = this.managerService.config.RESPONSE_TABLE[response.code];
    //             //     this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
    //             // } else {
    //             //     // @ts-ignore
    //             //     const content = this.managerService.config.RESPONSE_TABLE[response.code];
    //             //     this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
    //             // }
    //             // @ts-ignore
    //             const content = this.managerService.config.RESPONSE_TABLE[response.code];
    //             this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
    //             this.closeModal();
    //         }
    //     ));
    // }

    getPayload(addressID?: number): any {
        if (this.billingOption === BillingOption.INDIVIDUAL) {
            return {
                billing_address_id: addressID,
                address_type: 'individual',
                label: this.activeFormGroup.get('label')?.value,
                company_name: '-',
                tax_administration: '-',
                tax_number: '-',
                province: this.activeFormGroup.get('province')?.value,
                district: this.activeFormGroup.get('district')?.value,
                address: this.activeFormGroup.get('address')?.value,
                phone_number: this.activeFormGroup.get('phone_number')?.value.replace(/\D/g, '').substring(0, 10)
            };
        } else {
            return {
                billing_address_id: addressID,
                address_type: 'corporate',
                label: this.activeFormGroup.get('label')?.value,
                company_name: this.activeFormGroup.get('corporateName')?.value,
                tax_administration: this.activeFormGroup.get('taxAdministration')?.value,
                tax_number: this.activeFormGroup.get('taxNumber')?.value,
                province: this.activeFormGroup.get('province')?.value,
                district: this.activeFormGroup.get('district')?.value,
                address: this.activeFormGroup.get('address')?.value,
                phone_number: this.activeFormGroup.get('phone_number')?.value.replace(/\D/g, '').substring(0, 10)
            };
        }
    }

    validateFormGroup(): boolean {
        if (!this.activeFormGroup.valid) {
            let requiredKeys = '';
            // tslint:disable-next-line:forin
            for (const activeFormGroupKey in this.activeFormGroup.controls) {
                const controlMember = this.activeFormGroup.get(activeFormGroupKey);
                if (controlMember?.errors?.required) {
                    requiredKeys += activeFormGroupKey + ' ';
                }
                if (controlMember?.errors?.wrongNumber) {
                    // @ts-ignore
                    const content = this.managerService.config.RESPONSE_TABLE[2010];
                    this.managerService.popSnackBar(content.is_okay, content.message);

                }
                if (controlMember?.errors?.minlength) {
                    // @ts-ignore
                    const content = this.managerService.config.RESPONSE_TABLE[2011];
                    this.managerService.popSnackBar(content.is_okay, content.message);
                }
                if (controlMember?.errors?.pattern) {
                    // @ts-ignore
                    const content = this.managerService.config.RESPONSE_TABLE[2013];
                    this.managerService.popSnackBar(content.is_okay, content.message);

                }
            }
            if (requiredKeys !== '') {
                // @ts-ignore
                const content = this.managerService.config.RESPONSE_TABLE[2012];
                this.managerService.popSnackBar(content.is_okay, content.message + ' ' + requiredKeys);
            }

            return false;
        }
        return true;
    }

    closeModal(): void {
        // this.corporateFormGroup.reset();
        // this.individualFormGroup.reset();
        this.managerService.closeModal(this.modal_id);
    }

    ngOnDestroy(): void {
        this.subscription_list.forEach((subscription) => {
            subscription?.unsubscribe();
        });
    }

}
