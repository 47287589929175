<div *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE" style="max-width: 300px; max-height: 95vh;">
    <div class="py-1 px-2 w-100 d-flex flex-column align-items-center justify-content-center">
        <div class="p-2 w-100 d-flex flex-row align-items-start justify-content-between"
             style="height: 40px; font-family: bebas-neue-regular, sans-serif;font-size: 17px; font-weight: bold; line-height: 1.2; letter-spacing: 2.5px; color: #404352;">
            <div>{{managerService.config.WITHDRAWAL.RULES_TITLE | managerPipe}}</div>
            <div *ngIf="managerService.utilityService.timer_ready && managerService.modalService.isActive(modal_id) | managerPipe">
                {{120 | managerPipe: managerService.mpa_template.COUNTDOWN | async | managerPipe: managerService.mpa_template.CLOSE_MODAL: modal_id}}
            </div>
        </div>
        <div class="px-3 py-1 overflow-auto"
             style="max-height: calc(95vh - 240px); font-family: Roboto, sans-serif; font-size: 11px; line-height: 1; letter-spacing: 0.4px; color: #4a4e70; background-color: #f4f5f9; border: 1px solid #e4e4e4;">
            <div *ngFor="let rule of managerService.config.WITHDRAWAL.ETHEREUM_WITHDRAWAL_RULES | managerPipe"
                 class="mb-1" style="font-weight: normal; font-style: italic;">
                {{rule | managerPipe}}
            </div>
        </div>
        <label class="align-self-start d-flex flex-row align-items-start justify-content-start checkbox-text-black">
            <input #checkbox_field_desktop class="checkbox-field" style="height: 16px; min-width: 16px;" type="checkbox">
            <span class="mx-2 my-1" style="font-size: 12px;">{{ managerService.config.BALANCE.READ_ACCEPTED | managerPipe}}</span>
        </label>
        <div class="w-100 d-flex flex-column px-1 py-1">
            <div class="w-100 d-flex flex-column px-1"
                 style="font-family: Roboto, sans-serif; font-size: 10px; font-weight: bold; line-height: 1.9; letter-spacing: 0.85px; color: #404352;">
                <div class="my-1" style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: bold; line-height: 1; letter-spacing: 1.4px; color: #404352;">
                    {{managerService.config.WITHDRAWAL.WITHDRAWAL_REQUEST_SUM_HEADING | managerPipe}}
                </div>
                <div class="d-flex flex-row align-content-center justify-content-between" style="line-height: 1.3">
                    <div>
                        {{managerService.config.WITHDRAWAL.WITHDRAWAL_AMOUNT | managerPipe}}
                    </div>
                    <div>
                        {{this.managerService.operationsService.requested_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER_DECIMAL: 6}}ETH
                    </div>
                </div>
                <div class="d-flex flex-row align-content-center justify-content-between" style="line-height: 1.3">
                    <div>
                        {{managerService.config.WITHDRAWAL.WITHDRAWAL_FEE | managerPipe}}
                    </div>
                    <div>
                        {{managerService.operationsService.transfer_fee | managerPipe: managerService.mpa_template.FANCY_NUMBER_DECIMAL: 6}}ETH
                    </div>
                </div>
                <div class="d-flex flex-row align-content-center justify-content-between" style="line-height: 1.3">
                    <div>
                        {{managerService.config.WITHDRAWAL.WITHDRAWAL_SUM | managerPipe}}
                    </div>
                    <div class="request-summary-content">
                        {{managerService.operationsService.requested_amount - managerService.operationsService.transfer_fee | managerPipe: managerService.mpa_template.FANCY_NUMBER_DECIMAL: 6}}ETH
                    </div>
                </div>
                <div class="d-flex flex-row align-content-center justify-content-between" style="line-height: 1.3">
                    <div>
                        {{managerService.config.WITHDRAWAL.WITHDRAWAL_WALLET | managerPipe}}
                    </div>
                    <div style="width: 75%; overflow-wrap: break-word;">
                        {{this.managerService.operationsService.selected_user_crypto_account_pipe | managerPipe: managerService.mpa_template.GET_WITHDRAWAL_ACCOUNT_INFO}}
                    </div>
                </div>
            </div>
            <div class="w-100 d-flex flex-row">
                <button
                        (click)="managerService.operationsService.sign_withdrawal_request_data.status='cancel'; managerService.closeEthereumWithdrawalRequestModal(); checkbox_field_desktop.checked = false; managerService.operationsService.bank_withdrawal_request_form_submitted.emit();"
                        class="btn-native checkbox-reject-button">
                    {{managerService.config.ADVANCED.USER_OPEN_ORDERS.CANCEL | managerPipe}}
                </button>
                <button
                        (click)="onAccept()"
                        [class.button-inactive]="!checkbox_field_desktop.checked" [disabled]="!checkbox_field_desktop.checked"
                        class="btn-native checkbox-button">
                    {{managerService.config.BALANCE.NEXT | managerPipe}}
                </button>
            </div>
        </div>
    </div>
</div>
