import {Injectable} from '@angular/core';

export enum TooltipDirectionTemplate {
  TOP,
  BOTTOM,
  LEFT,
  RIGHT,
}

@Injectable({
  providedIn: 'root'
})
export class TooltipService {
  public active: boolean = false;
  public tooltips: any[] = [];
  public tooltip_direction_template = TooltipDirectionTemplate;

  constructor() {
  }

  add(tooltip: any): void {
    this.tooltips.push(tooltip);
  }

  remove(id: string): void {
    this.tooltips = this.tooltips.filter(x => x.id !== id);
  }

  toggle(id: string): void {
    const tooltip = this.tooltips.find(x => x.id === id);
    tooltip.toggle();
  }

  toggleAll(): void {
    if (this.active) {
      this.tooltips.forEach(t => t.close());
    } else {
      this.tooltips.forEach(t => t.open());
    }
    this.active = !this.active;

  }
}
