
<div class="d-flex w-100 justify-content-end">
  <svg style="margin-top: -16px; margin-right: -8px; height: 18px; width: 18px;" (click)="managerService.closeModal(modal_id)">
    <use [attr.xlink:href]='"./assets/images/exit_icon.svg#exit_icon"' fill="#404352"/>
  </svg>
</div>

<div *ngIf="login_step !== 1 | managerPipe" class=" d-flex align-items-center justify-content-between" style="padding: 0 35px; font-size: 24px; color: #211f25">
  {{managerService.config.LOGIN.LOGIN | managerPipe}}

</div>

<app-captcha-desktop (response)="loginStepResolver($event)" *ngIf="login_step === 1 | managerPipe"></app-captcha-desktop>

<form #login_form [formGroup]="loginForm" class="d-flex flex-column align-items-start" style="width: 360px; min-width: 360px; height: fit-content; margin: 20px;">
  <div *ngIf="login_step === 0 | managerPipe" class="w-100 d-flex flex-column">
    <span class='input-heading' style="height: 30px!important;">{{managerService.config.LOGIN.E_MAIL | managerPipe}}</span>
    <label class="mb-3">
      <input class="input-field" formControlName="email"
             placeholder="{{managerService.config.LOGIN.E_MAIL_PLACEHOLDER | managerPipe}}" required
             type="email">
    </label>
    <span class='input-heading' style="height: 30px!important;">{{managerService.config.LOGIN.PASSWORD | managerPipe}}</span>
    <label class="position-relative">
      <svg (click)="password_visible = !password_visible" class="password-eye">
        <use *ngIf="(!password_visible | managerPipe)" [attr.xlink:href]='"./assets/images/eye_hidden.svg#eye_hidden"' fill="#9DA5B7"/>
        <use *ngIf="(password_visible | managerPipe)" [attr.xlink:href]='"./assets/images/eye_visible.svg#eye_visible"' fill="#9DA5B7"/>
      </svg>
      <input [type]="(password_visible | managerPipe) ? 'text' : 'password'" class="input-field" formControlName="password"
             placeholder="{{managerService.config.LOGIN.PASSWORD_PLACEHOLDER | managerPipe}}"
             required style="padding-right: 40px;">
    </label>
  </div>
  <div *ngIf="login_step == 2 | managerPipe" class="w-100 d-flex flex-column">
    <span class='input-heading' style="height: 30px!important;">{{managerService.config.LOGIN.AUTH | managerPipe}}</span>
    <label class="position-relative">
      <input class="input-field" formControlName="auth_password"
             placeholder="{{managerService.config.LOGIN.AUTH_PLACEHOLDER | managerPipe}}"
             required type="text">

      <div (click)="pasteAuthPassword()" class="position-absolute"
           style="top: 10px; right: 24px; font-family: Roboto, sans-serif; font-size: 16px; font-weight: 500; letter-spacing: 0.7px; color: #9DA5B7">
        {{managerService.config.LOGIN.PASTE | managerPipe}}
      </div>
    </label>
  </div>
  <button (click)="this.managerService.openModal('forgot-password-modal'); managerService.closeModal(modal_id);" *ngIf="login_step !== 2 | managerPipe" class="btn-native mb-1"
          style="color: #4bc4d6; padding: 0 20px;"
          type="button">
    {{managerService.config.LOGIN.FORGOT_PASSWORD | managerPipe}}
  </button>
  <button (click)="loginFormValidator()" class="btn-native" id="submit-button">
    {{managerService.config.LOGIN.SUBMIT | managerPipe}}
  </button>
  <div *ngIf="login_step !== 2 | managerPipe" class="w-100 d-flex flex-row align-items-center justify-content-center" type="button">
    <span>{{managerService.config.LOGIN.NO_ACCOUNT}}</span>
    <span (click)="managerService.navigationManager({url: '/sign-up', current_component_index: 0}); managerService.closeModal('login-modal')" class="mx-1" style="color: var(--artiox-blue)">{{managerService.config.LOGIN.CREATE_NEW_ACCOUNT}}</span>
  </div>
  <div #warning class="d-none warning" style="margin: 10px 20px 0"></div>
</form>
