import {Component, computed, OnDestroy, OnInit, Signal, signal, ViewChild, ViewEncapsulation, WritableSignal} from '@angular/core';
import {ManagerService} from '../../../../services/manager.service';
import {Subscription, timer} from 'rxjs';
import {FormControl, Validators} from '@angular/forms';
import {map, takeWhile} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-account-verification-mobile',
  templateUrl: './account-verification-mobile.component.html',
  styleUrl: './account-verification-mobile.component.css',
  encapsulation: ViewEncapsulation.None
})
export class AccountVerificationMobileComponent implements OnInit, OnDestroy {
  @ViewChild('verification_error') verification_error: any;
  @ViewChild('verification_input') verification_input: any;

  protected verificationControl = new FormControl('', [Validators.required, Validators.minLength(6)]);

  protected email: WritableSignal<string> = signal('');
  protected emailBold: Signal<string> = computed(() => '<span class="verification-info-bold email">' + this.email() + '</span>');

  protected countdown: number = 0;
  protected timeRemaining = 60;
  protected sendTimer = timer(0, 1000).pipe(
    map(n => this.timeRemaining - n),
    takeWhile(n => n >= 0),
  );

  protected isTextClickable = false;
  protected loading_animation = false;

  protected subscription_list: Subscription[] = [];

  constructor(protected managerService: ManagerService, protected httpClient: HttpClient) {
  }

  ngOnInit(): void {
    this.subscription_list.push(this.sendTimer.subscribe(x => {
      this.countdown = x;
      if (x <= 0) {
        this.isTextClickable = true;
      }
    }));
    this.subscription_list.push(this.verificationControl.valueChanges.subscribe(() => this.formatVerificationCode()));


    const credentials = this.managerService.userService.user_account_verification_credentials;
    if (credentials) {
      this.email.set(credentials?.getEmail);
      this.verificationControl.setValue(credentials?.getVerificationCode);
      this.formatVerificationCode();
    }
    this.subscription_list.push(this.managerService.userService.user_account_verification_credentials_changed.subscribe(() => {
      const credentials = this.managerService.userService.user_account_verification_credentials;
      if (credentials) {
        this.email.set(credentials?.getEmail);
        this.verificationControl.setValue(credentials?.getVerificationCode);
        this.formatVerificationCode();
      }
    }));

    this.managerService.addBeforeUnloadListener();
  }

  formatVerificationCode(): void {
    if (this.verificationControl?.value) {
      const input = this.verificationControl.value.replace(/\D/g, '').substring(0, 6);
      const firstPart = input.substring(0, 3);
      const secondPart = input.substring(3, 6);
      let code = '';
      if (input.length > 3) {
        code = firstPart + ' - ' + secondPart;
      } else {
        code = firstPart;
      }
      if (this.verificationControl.value !== code && code.length > 0) {
        this.verificationControl.setValue(code);
      }
    }
  }

  resendEmail(): void {
    if (this.isTextClickable) {
      // const user_login_data = this.managerService.userService.getUserLoginData();
      if (this.email()) {
        const body = {email: this.email(), client_ip: this.managerService.client_ip};
        this.httpClient.post(this.managerService.environment.serverAPI + 'resend_account_verification_code_service', body).subscribe(response => {
          this.subscription_list.pop()?.unsubscribe();
          this.subscription_list.push(this.sendTimer.subscribe(x => {
            this.countdown = x;
            if (x <= 0) {
              this.isTextClickable = true;
            }
          }));
          this.isTextClickable = false;
        });
      }
    }
  }

  sendVerification(): void {
    if (!this.verificationControl.valid) {
      if (this.verificationControl.errors?.required) {
        // @ts-ignore
        this.verification_error.nativeElement.children[1].innerHTML = this.managerService.config.RESPONSE_TABLE['2018'].message;
      } else {
        // @ts-ignore
        this.verification_error.nativeElement.children[1].innerHTML = this.managerService.config.RESPONSE_TABLE['2020'].message;
      }
      this.verification_error.nativeElement.classList.remove('d-none');
      this.verification_input.nativeElement.classList.add('error');
    } else {
      // if (this.email() !== '') {
      // const user_account_verification_credentials = this.managerService.userService.user_account_verification_credentials;
      if (this.email() !== '') {
        const account_verification_body = {
          email: this.email(),
          account_verification_code: this.verificationControl.value?.replace(/\D/g, '').substring(0, 6)
        };
        this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'verify_account_service', account_verification_body).subscribe(response => {
          if (response.code === 274 ) {  // verified
            const user_login_data = this.managerService.userService.getUserLoginData();
            if (user_login_data && user_login_data.getEmail === this.email()) {
              const login_body = {
                user_email: user_login_data.getEmail,
                password: user_login_data.getPassword,
                auth_password: '',
                client_info: this.managerService.client_info
              };
              this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'user_login_service', login_body).subscribe(
                response => {
                  if (response.is_okay) {
                    this.managerService.loggedInResolver(true, response.cookie);
                    this.loading_animation = true;
                    setTimeout(() => {
                      this.managerService.navigateIfLoggedIn({url: '/home', current_component_index: 0});
                      this.loading_animation = false;
                    }, 400);
                  } else {
                    this.loading_animation = true;
                    setTimeout(() => {
                      this.managerService.navigationManager({url: '/login', current_component_index: 0});
                      this.loading_animation = false;
                    }, 400);
                  }
                }));
            } else {
              this.loading_animation = true;
              setTimeout(() => {
                this.managerService.navigationManager({url: '/login', current_component_index: 0});
                this.loading_animation = false;
              }, 400);
            }
          } else {
            // @ts-ignore
            this.verification_error.nativeElement.children[1].innerHTML = this.managerService.config.RESPONSE_TABLE[response.code].message;
            this.verification_error.nativeElement.classList.remove('d-none');
            this.verification_input.nativeElement.classList.add('error');
          }
        }));
      } else {
        this.verification_error.nativeElement.children[1].innerHTML = 'Error fetching user credentials';
        this.verification_error.nativeElement.classList.remove('d-none');
        this.verification_input.nativeElement.classList.add('error');
      }
    }
  }

  ngOnDestroy() {
    this.managerService.removeBeforeUnloadListener();
  }
}
