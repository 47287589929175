import {ManagerService} from '../../../../../services/manager.service';
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {Chart} from 'chart.js';
import 'chartjs-plugin-zoom';

@Component({
  selector: 'app-dashboard-mobile',
  templateUrl: './dashboard-mobile.component.html',
  styleUrls: ['./dashboard-mobile.component.css']
})
export class DashboardMobileComponent implements OnInit, AfterViewInit, OnDestroy {

  public balance_visible: boolean = true;

  @ViewChild('user_balance_history_chart_canvas') user_balance_history_chart_canvas: any;
  public user_balance_history_chart: any = undefined;
  public user_balance_history_chart_init: boolean = false;

  public start: any = '';
  public end: any = '';
  public range_min: any = '';
  public range_max: any = '';
  public user_balance_history_chart_color: any = '';
  public user_balance_history_chart_values: any = [];
  public user_balance_history_chart_labels: any = [];

  public subscription_list: Subscription[] = [];

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.constructUserBalanceChart();
    this.resolveHistoryChart();
    this.subscription_list.push(this.managerService.userService.user_balance_history_chart_changed.subscribe(() => {
      this.resolveHistoryChart();
    }));
  }

  resolveHistoryChart(): void {
    return; // todo rm this
    if (!this.user_balance_history_chart_init) {
      if (this.managerService.prepareUserBalanceHistoryChartData(this.user_balance_history_chart_labels, this.user_balance_history_chart_values)) {

        const ctx = this.user_balance_history_chart_canvas.nativeElement.getContext('2d');
        this.user_balance_history_chart_color = ctx.createLinearGradient(0, 290, 0, 0);
        this.user_balance_history_chart_color.addColorStop(0, 'rgba(89,200,217,1)');
        this.user_balance_history_chart_color.addColorStop(0.7, 'rgba(89,200,217,0.8)');
        this.user_balance_history_chart_color.addColorStop(0.3, 'rgba(89,200,217,0.1)');
        this.user_balance_history_chart_color.addColorStop(1, 'rgba(89,200,217,0)');

        const user_balance_history_length = this.managerService.userService.getUserBalanceHistory().length;

        this.start = this.managerService.moment(this.managerService.userService.getUserBalanceHistory()[0].creation_time);
        this.end = this.managerService.moment(this.managerService.userService.getUserBalanceHistory()[user_balance_history_length - 1].creation_time);

        // start date
        this.range_min = new Date(this.managerService.userService.getUserBalanceHistory()[0].creation_time);
        this.range_min.setDate(this.range_min.getDate());

        this.range_max = new Date(this.managerService.userService.getUserBalanceHistory()[user_balance_history_length - 1].creation_time);
        this.range_max.setDate(this.range_max.getDate() + 1);

        this.user_balance_history_chart.options.pan.rangeMin.x = this.range_min;
        this.user_balance_history_chart.options.pan.rangeMax.x = this.range_max;

        this.user_balance_history_chart.options.zoom.rangeMin.x = this.range_min;
        this.user_balance_history_chart.options.zoom.rangeMax.x = this.range_max;

        this.user_balance_history_chart.options.scales.xAxes[0].ticks.start = this.start;
        this.user_balance_history_chart.options.scales.xAxes[0].ticks.end = this.end;

        this.user_balance_history_chart.data.labels = this.user_balance_history_chart_labels;
        this.user_balance_history_chart.data.datasets[0].data = this.user_balance_history_chart_values;
        this.user_balance_history_chart.data.datasets[0].borderColor = this.user_balance_history_chart_color;
        this.user_balance_history_chart.data.datasets[0].pointBorderColor = this.user_balance_history_chart_color;
        this.user_balance_history_chart.data.datasets[0].pointBackgroundColor = this.user_balance_history_chart_color;
        this.user_balance_history_chart.data.datasets[0].pointHoverBackgroundColor = this.user_balance_history_chart_color;
        this.user_balance_history_chart.data.datasets[0].pointHoverBorderColor = this.user_balance_history_chart_color;
        this.user_balance_history_chart.data.datasets[0].hoverBorderColor = this.user_balance_history_chart_color;
        this.user_balance_history_chart.data.datasets[0].hoverBorderWidt = this.user_balance_history_chart_color;
        this.user_balance_history_chart.data.datasets[0].backgroundColor = this.user_balance_history_chart_color;
        this.user_balance_history_chart.update();
        this.user_balance_history_chart_init = true;
      }
    } else {
      this.updateHistoryChart();
    }
  }

  updateHistoryChart(): void {
    if (this.managerService.prepareUserBalanceHistoryChartValues(this.user_balance_history_chart_labels, this.user_balance_history_chart_values)) {
      this.user_balance_history_chart.data.labels = this.user_balance_history_chart_labels;
      this.user_balance_history_chart.data.datasets[0].data = this.user_balance_history_chart_values;
      this.user_balance_history_chart.update(0);
    }
  }

  constructUserBalanceChart(): void {
    if (this.user_balance_history_chart === undefined) {
      this.user_balance_history_chart = new Chart(this.user_balance_history_chart_canvas.nativeElement.getContext('2d'), {
        type: 'line',
        data: {
          labels: [],
          datasets: [{
            label: this.managerService.config.DASHBOARD.TOTAL_BALANCE_HISTORY,
            borderColor: '',
            pointBorderColor: '',
            pointBackgroundColor: '',
            pointHoverBackgroundColor: '',
            pointHoverBorderColor: '',
            hoverBorderColor: '',
            hoverBorderWidth: 1,
            backgroundColor: '',
            data: [],
          }]
        },
        options: {
          maintainAspectRatio: false,
          elements: {
            rectangle: {
              borderWidth: 1,
              borderSkipped: 'bottom'
            },
            point: {
              radius: 1,
            }
          },
          layout: {
            padding: {
              left: 12,
              right: 16,
              top: 0,
              bottom: 0
            }
          },
          legend: {
            fullWidth: false,
            labels: {
              boxWidth: 0,
              fontFamily: 'Roboto',
              fontSize: 12,
              fontColor: '#9da5b7',
              fontStyle: 'bold'

            }
          },
          tooltips: {
            enabled: true,
            mode: 'x-axis',
            axis: 'x',
            callbacks: {
              title: (tooltipItem, data) => {
                // @ts-ignore
                return this.managerService.config.DASHBOARD.TOTAL_BALANCE;
              },
              label: (tooltipItem, data) => {
                // @ts-ignore
                return this.managerService.fancyNumber(data.datasets[0].data[tooltipItem.index]).toString();
              },

            },
            backgroundColor: '#141414',
            titleFontFamily: 'Roboto',
            titleFontStyle: 'bold',
            titleFontSize: 12,
            titleFontColor: '#ffffff',
            bodyFontFamily: 'Roboto',
            bodyFontStyle: 'normal',
            bodyFontSize: 11,
            bodyFontColor: '#ffffff',
            displayColors: false,
          },
          scales: {
            yAxes: [{
              type: 'linear',
              gridLines: {
                drawTicks: false,
                drawBorder: false,
                color: 'rgba(128, 128, 128, 0.1)',
                lineWidth: 1,
              },
              ticks: {
                callback: (value, index, values) => {
                  // @ts-ignore
                  return this.managerService.fancyNumber(value);
                },
                padding: 3,
                fontFamily: 'Roboto',
                fontSize: 9,
                fontColor: '#9da5b7',
                lineHeight: 1,
                maxTicksLimit: 6,
                beginAtZero: true,
              }

            }],
            xAxes: [{
              distribution: 'linear',
              stacked: false,
              type: 'time',
              time: {
                unit: 'day',
                stepSize: 1,
                tooltipFormat: 'll'
              },
              gridLines: {
                drawTicks: false,
                display: false,
                color: 'rgba(128, 128, 128, 0.5)',
                lineWidth: 1,
              },
              ticks: {
                min: this.start,
                max: this.end,
                padding: 6,
                fontFamily: 'Roboto',
                fontSize: 9,
                fontColor: '#9da5b7',
                lineHeight: 1,
                maxTicksLimit: 4,
                beginAtZero: true,
                maxRotation: 0
              }
            }],
          },
          // @ts-ignore
          pan: {
            enabled: true,
            mode: 'x',
            rangeMin: {x: this.start},
            rangeMax: {x: this.end}
          },
          zoom: {
            enabled: true,
            mode: 'x',
            threshold: 10,
            rangeMin: {x: ''},
            rangeMax: {x: ''}
          }
        },
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription_list.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

}
