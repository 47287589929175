<script src="../../../../footer/footer-desktop/footer-desktop.component.ts"></script>
<div class="w-100 d-flex flex-column" style="width: 100%;">
  <div class="mx-auto" style="width: 100%;">
    <div (click)="managerService.navigationManager({url: '/corporate/announcements', current_component_index: 0});" class=" blue-hover"
         style="width: fit-content; padding-left: 8px; margin-bottom: 8px; font-family: Roboto, sans-serif; font-size: 13px; letter-spacing: 1px;" type="button">
      <svg id="back-icon" style="height: 12px; width: 12px; margin-right: 4px; margin-bottom: 2px;">
        <use [attr.xlink:href]='"./assets/images/announcement_left_arrow.svg#announcement_left_arrow"' class="blue-hover" fill="in"/>
      </svg>
      {{managerService.config.ANNOUNCEMENTS.HEADERS.ANNOUNCEMENTS | managerPipe}}
    </div>
    <div class="d-flex flex-column" style="">
      <div class="pb-3" style="width: 810px">
        <div class="mt-4 mb-2 px-5 pt-3 pb-2"
             style="font-family: Roboto, sans-serif; font-size: 18px; font-weight: 500; letter-spacing: 0.55px; border-radius: 4px; box-shadow: 1px 1px 2px 0 #dbdbdf; color: #595e7e;">
          {{managerService.config.ANNOUNCEMENTS.HEADERS.OPERATION_ANNOUNCEMENTS | managerPipe}}
        </div>
        <div *ngFor="let operation_announcement of (managerService.config.ANNOUNCEMENTS.OPERATION_ANNOUNCEMENTS.OPERATION_LIST | managerPipe); let i=index;"
             class="my-2" #operation_announcement>
          <div *ngIf="operation_announcement.RELEASE_DATE | managerPipe: managerService.mpa_template.CHECK_TIME_ARRIVED | async">
            <div (click)="this.managerService.toggleText(i);"
                 class="position-relative d-flex flex-row justify-content-between align-items-center whitish-hover"
                 style="font-family: Roboto, sans-serif; border-radius: 4px; box-shadow: 1px 1px 1px 1px #dbdbdf;">
              <div class="px-5 py-4" style="font-size: 16px; color: #9da5b7;">{{operation_announcement.TOPIC | managerPipe}}</div>
              <div class="px-5 py-4" style="font-size: 16px; color: var(--artiox-blue); cursor: pointer">{{managerService.config.CORPORATE.CAREER.INSPECT| managerPipe}}</div>
            </div>
            <div [class.d-none]="(!operation_announcement.TOGGLE) | managerPipe" class="job-advertisement-description px-5 pb-3"
                 style="border-radius: 4px; box-shadow: 1px 1px 2px 0 #dbdbdf; margin-top: -72px; padding-top: 78px;">
              <div [innerHTML]="operation_announcement.TEXT_DESKTOP | managerPipe"></div>
              <a *ngIf="operation_announcement.ROUTER_LINK.LINK.url | managerPipe"
                 class="link-button" (click)="managerService.navigateIfLoggedIn(operation_announcement.ROUTER_LINK.LINK)">
                {{operation_announcement.ROUTER_LINK.TEXT | managerPipe}}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
