import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {interval, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import * as moment from 'moment';
import {environment} from '../../environments/environment';
import {AbstractControl, ValidationErrors} from '@angular/forms';
import * as CryptoJS from 'crypto-js';

export class Time {
    public days: number;
    public hours: number;
    public minutes: number;
    public seconds: number;

    constructor(_days: number, _hours: number, _minutes: number, _seconds: number) {
        this.days = _days;
        this.hours = _hours;
        this.minutes = _minutes;
        this.seconds = _seconds;
    }

    static getCountdown(local_time: number, time: number): Observable<Time> {
        return interval(1000).pipe(
            map(() => {
                return this.getIntervalTime(new Date().getTime() - local_time, time);
            })
        );
    }

    static getTime(time: number): Time {
        const interval_time = new Time(0, 0, 0, 0);
        interval_time.days = Math.floor(time / 86400);
        time -= interval_time.days * 86400;
        interval_time.hours = Math.floor(time / 3600) % 24;
        time -= interval_time.hours * 3600;
        interval_time.minutes = Math.floor(time / 60) % 60;
        time -= interval_time.minutes * 60;
        interval_time.seconds = time % 60;
        return interval_time;
    }

    static getIntervalTime(time_start: number, time_end: number): Time {
        const interval_time = Math.floor((time_end - time_start) / 1000);
        return this.getTime(interval_time);
    }
}

@Injectable({
    providedIn: 'root'
})
export class UtilityService {

    public environment = environment;
    public global_time_offset: number = 0;
    public global_timezone_offset: number = 0;
    public timer_counter: number = 0;
    public timer_ready: boolean = false;
    @Output() time_arrived: EventEmitter<void> = new EventEmitter();

    private aes_base_key = 'KVKK Politikası';
    constructor(private httpClient: HttpClient) {
    }

    static currentTimestamp(): number {  // in ms
        return moment().unix() * 1000;
    }

    static bubbleSortByID(list: any[]): void {
        for (let i = 0; i < list.length - 1; i++) {
            if (list[i].id < list[i + 1].id) {
                const temp = list[i];
                list[i] = list[i + 1];
                list[i + 1] = temp;
                return UtilityService.bubbleSortByID(list);
            }
        }
    }

    static validateTcNo(tc_no: number): boolean {
        if (tc_no < 10000000000 || tc_no > 99999999999) {
            return false;
        }
        const numberArray = Array.from(String(tc_no), Number);
        const odd = numberArray[0] + numberArray[2] + numberArray[4] + numberArray[6] + numberArray[8];
        const even = numberArray[1] + numberArray[3] + numberArray[5] + numberArray[7];
        const sum = odd + even + numberArray[9];
        const control = (((odd * 7 + even * 9) % 10) === numberArray[9]);
        const control2 = (((odd * 8) % 10) === numberArray[10]);
        const control3 = ((sum % 10) === numberArray[10]);
        return control && control2 && control3;
    }

    static validateAdultBirthYear(birth_year: number): boolean {
        return birth_year > 1900 && birth_year <= moment().year() - 18;
    }

    static validatePhoneNumber(phone_number: string): boolean {
        const turkish_phone_number_regex = /^(\+90|0090|0)?[1-9][0-9]{9}$/;
        const international_phone_number_regex = /^(\+|00)[0-9]{1,3}[0-9]{10}$/;
        return turkish_phone_number_regex.test(phone_number) || international_phone_number_regex.test(phone_number);
    }


    static tcNoFormValidator(control: AbstractControl): ValidationErrors | null {
        if (control.value === null || UtilityService.validateTcNo(control.value)) {
            return null;
        }

        return {tcNoInvalid: true};
    }

    static adultBirthYearFormValidator(control: AbstractControl): ValidationErrors | null {
        if (control.value === null || UtilityService.validateAdultBirthYear(control.value)) {
            return null;
        }

        return {adultBirthYearInvalid: true};
    }

    static phoneNoFormValidator(control: AbstractControl): ValidationErrors | null {
        if (control.value === null || UtilityService.validatePhoneNumber(control.value)) {
            return null;
        }

        return {phoneNoInvalid: true};
    }

    static last<T>(list: T[]): T | undefined {  // returns undefined if empty
        return list[list.length - 1];
    }

    static dropDuplicate<T>(list: T[]): T[] {
        const result: T[] = [];
        list.forEach((item) => {
            if (!result.includes(item)) {
                result.push(item);
            }
        });
        return result;
    }

    public parseInt = (x: string) => parseInt(x, 10);

    public clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

    setLocalTime(): void {
        this.httpClient.get<any>(this.environment.serverAPI + 'get_current_time_service').subscribe(response => {
            this.global_time_offset = UtilityService.currentTimestamp() - response.time / 1000000;
            // const delayFix = (this.global_time_offset % this.delayEpoch >= this.delayEpoch / 2) ? (this.delayEpoch - this.global_time_offset % this.delayEpoch) : -(this.global_time_offset % this.delayEpoch);
            // this.global_time_offset += delayFix;
            this.global_timezone_offset = Math.round(this.global_time_offset / 1800000) * 1800000;

        });
    }

    getCountdown(time: number): Observable<Time> {
        return Time.getCountdown(this.global_time_offset, time);
    }

    timer(value: number): Observable<number> {
        return interval(1000).pipe(
            map(() => {
                this.timer_counter += 1;
                return value - this.timer_counter;
            })
        );
    }

    openTimer(): void {
        this.timer_counter = 0;
        this.timer_ready = true;
    }

    closeTimer(): void {
        this.timer_counter = 0;
        this.timer_ready = false;
    }

    checkTimeArrived(time: number): Promise<boolean> | boolean {
        return new Promise<boolean>((resolve, reject) => {
            setTimeout(() => {
                resolve(true);
                this.time_arrived.emit();
            }, Math.max(time - new Date().getTime(), 0));
        });
    }

    passwordGenerator(): string {

        const passwordLength = 12;
        const addUpper = true;
        const addNumbers = true;
        const addSymbols = true;

        const lowerCharacters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
        const upperCharacters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
        const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        const symbols = ['!', '?', '@'];

        function getRandom(array: any[]): any {
            return array[Math.floor(Math.random() * array.length)];
        }

        let finalCharacters = '';

        if (addUpper) {
            finalCharacters = finalCharacters.concat(getRandom(upperCharacters));
        }

        if (addNumbers) {
            finalCharacters = finalCharacters.concat(getRandom(numbers));
        }

        if (addSymbols) {
            finalCharacters = finalCharacters.concat(getRandom(symbols));
        }

        for (let i = 1; i < passwordLength - 3; i++) {
            finalCharacters = finalCharacters.concat(getRandom(lowerCharacters));
        }

        return finalCharacters.split('').sort(() => 0.5 - Math.random()).join('');
    }

    preciseRound(num: number | null | undefined, dec: number = 2): number {
        if (typeof num === 'number') {
            return Math.floor((num + Math.pow(0.1, 2 * dec + 1)) * Math.pow(10, dec)) / Math.pow(10, dec);
        } else {
            return 0;
        }
    }

    fancyNumber(num: number, decimals: number = 2, dec_point: string = '.', thousands_point: string = ','): string {

        if (num === undefined || num === null || !isFinite(num)) {
            // throw new TypeError('number is not valid');
            return '';
        }

        if (!decimals) {
            const len = num.toString().split('.').length;
            decimals = len > 1 ? len : 0;
        }

        let fancy_number = this.preciseRound(num, decimals).toFixed(decimals);
        fancy_number = fancy_number.replace('.', dec_point);

        const splitNum = fancy_number.split(dec_point);
        splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_point);
        fancy_number = splitNum.join(dec_point);

        return fancy_number;
    }

    toFixedInt(num: number, digits: number = 2): string {
        if (num === undefined || num === null || !isFinite(num)) {
            // throw new TypeError('number is not valid');
            return '';
        }

        let fixed = num.toString();

        if (fixed.length < digits) {
            fixed = '0'.repeat(digits - fixed.length) + fixed;
        }
        return fixed;
    }

    toEuropeanTime(timestamp: number): string {
        const date = new Date(timestamp);
        return `${this.toFixedInt(date.getHours())}:${this.toFixedInt(date.getMinutes())}:${this.toFixedInt(date.getSeconds())}`;

    }

    toEuropeanDate(timestamp: number): string {
        const date = new Date(timestamp);
        return `${this.toFixedInt(date.getDate())}.${this.toFixedInt(date.getMonth() + 1)}.${this.toFixedInt(date.getFullYear())}`;
    }

    toEuropeanDateTime(timestamp: number): string {
        const date = new Date(timestamp);
        return `${this.toFixedInt(date.getDate())}.${this.toFixedInt(date.getMonth() + 1)}.${this.toFixedInt(date.getFullYear())} ${this.toFixedInt(date.getHours())}:${this.toFixedInt(date.getMinutes())}:${this.toFixedInt(date.getSeconds())}`;
    }

    toEuropeanAbbreviatedDateTime(timestamp: number, language: string): string {
        const date = new Date(timestamp);
        return `${this.toFixedInt(date.getDate())} ${this.getMonthAbbreviation(date.getMonth(), language)} ${this.toFixedInt(date.getFullYear() % 100)} ${this.toFixedInt(date.getHours())}:${this.toFixedInt(date.getMinutes())}`;
    }

    getMonthAbbreviation(month: number, language: string): string {
        if (language === 'TR') {
            switch (month) {
                case 0:
                    return 'Oca';
                case 1:
                    return 'Şub';
                case 2:
                    return 'Mar';
                case 3:
                    return 'Nis';
                case 4:
                    return 'May';
                case 5:
                    return 'Haz';
                case 6:
                    return 'Tem';
                case 7:
                    return 'Ağu';
                case 8:
                    return 'Eyl';
                case 9:
                    return 'Eki';
                case 10:
                    return 'Kas';
                case 11:
                    return 'Ara';
                default:
                    break;
            }
        } else if (language === 'EN') {
            switch (month) {
                case 0:
                    return 'Jan';
                case 1:
                    return 'Feb';
                case 2:
                    return 'Mar';
                case 3:
                    return 'Apr';
                case 4:
                    return 'May';
                case 5:
                    return 'Jun';
                case 6:
                    return 'Jul';
                case 7:
                    return 'Aug';
                case 8:
                    return 'Sep';
                case 9:
                    return 'Oct';
                case 10:
                    return 'Nov';
                case 11:
                    return 'Dec';
                default:
                    break;
            }
        }

        return this.toFixedInt(month + 1, 2);
    }

    requestCriticServicePermission(name: string, data: any): boolean {
        if (!this.environment.production && (this.environment.serverURL === 'https://www.artiox.com/' || this.environment.serverURL === 'https://artiox.com/')) {
            console.log('Service not allowed in dev mode', name, data);
            return false;
        } else {
            return true;
        }
    }

    /**
     * Checks for the given url and returns true if it is in release
     * @returns {boolean} True if url is https://www.artiox.com/ or https://artiox.com/
     */
    checkReleaseUrl(): boolean {
        return this.environment.production && ((this.environment.serverURL === 'https://www.artiox.com/' || this.environment.serverURL === 'https://artiox.com/'));
    }

    public encryptAES(txt: string, key: string): string {
        return CryptoJS.AES.encrypt(txt, this.aes_base_key + key).toString();
    }

    public decryptAES(txtToDecrypt: string, key: string): string {
        return CryptoJS.AES.decrypt(txtToDecrypt, this.aes_base_key + key).toString(CryptoJS.enc.Utf8);
    }

}
