import { Component } from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';

@Component({
  selector: 'app-legal-commissions-desktop',
  templateUrl: './legal-commissions-desktop.component.html',
  styleUrl: './legal-commissions-desktop.component.css'
})
export class LegalCommissionsDesktopComponent {

  constructor(protected managerService: ManagerService) {

  }
}
