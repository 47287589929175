import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ManagerService} from '../../../../../services/manager.service';
import {HttpClient} from '@angular/common/http';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-add-bank-account-mobile',
  templateUrl: './add-bank-account-mobile.component.html',
  styleUrls: ['./add-bank-account-mobile.component.css']
})
export class AddBankAccountMobileComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() modal_id: string = '';
  @ViewChild('warning_iban') warning_iban: any = undefined;
  @ViewChild('warning_name_surname') warning_name_surname: any = undefined;
  @ViewChild('warning_account_name') warning_account_name: any = undefined;
  public iban_error: string = '';
  public bank_code: string = '';

  addBankAccountForm = new UntypedFormGroup({
    iban: new UntypedFormControl('TR', [Validators.required]),
    concatenated_name_field: new UntypedFormControl('', [Validators.required]),
    label: new UntypedFormControl('', [Validators.required]),
  });

  public subscription_list: (Subscription | undefined)[] = [];

  constructor(public managerService: ManagerService, private httpClient: HttpClient, public elementRef: ElementRef) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.subscription_list.push(this.addBankAccountForm.get('iban')?.valueChanges.subscribe(() => {
      const control_iban = this.addBankAccountForm.get('iban')?.value.replace(/\s/g, '');
      if (control_iban.length > 3 && control_iban.substring(0, 2) === control_iban.substring(2, 4)){
        this.addBankAccountForm.get('iban')?.setValue(control_iban.substring(2));
      }

      this.warning_iban.nativeElement.classList.add('d-none');

      if (this.managerService.userService.user_ready) {
        this.controlIBAN();
      }
    }));
  }

  isAlphaNumeric(input: string): boolean {
    return input.search(/[^\w\s]/) === -1;
  }

  isLetter(input: string): boolean {
    const strValidChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let is_letter = true;
    let strChar;

    for (let i = 0; i < input.length && is_letter; i++) {
      strChar = input.charAt(i);
      if (strValidChars.indexOf(strChar) === -1) {
        is_letter = false;
      }
    }
    return is_letter;
  }

  isDigit(input: string): boolean {
    const strValidChars = '0123456789';
    let is_digit = true;
    let strChar;

    for (let i = 0; i < input.length && is_digit; i++) {
      strChar = input.charAt(i);
      if (strValidChars.indexOf(strChar) === -1) {
        is_digit = false;
      }
    }
    return is_digit;
  }

  controlIBAN(): boolean {
    if (!this.isAlphaNumeric(this.addBankAccountForm.get('iban')?.value)) {
      this.iban_error = this.managerService.config.WITHDRAWAL.ADD_BANK_ACCOUNT.IBAN_ERROR_LIST.IBAN_NOT_ALPHA_NUMERIC;
      return true;
    }

    if (this.addBankAccountForm.get('iban')?.value.length > 4) {
      const country_code = this.addBankAccountForm.get('iban')?.value.substr(0, 2);
      const control_string = this.addBankAccountForm.get('iban')?.value.substr(2, 2);
      if (country_code !== 'TR') {
        if (!this.isLetter(country_code)) {
          this.iban_error = this.managerService.config.WITHDRAWAL.ADD_BANK_ACCOUNT.IBAN_ERROR_LIST.IBAN_COUNTRY_CODE;
          return true;
        }
        this.iban_error = this.managerService.config.WITHDRAWAL.ADD_BANK_ACCOUNT.IBAN_ERROR_LIST.IBAN_NOT_TURKEY;
      } else {
        if (!this.isDigit(control_string)) {
          this.iban_error = this.managerService.config.WITHDRAWAL.ADD_BANK_ACCOUNT.IBAN_ERROR_LIST.IBAN_CONTROL_CODE;
          return true;
        }
      }
    }

    if (this.addBankAccountForm.get('iban')?.value.length > 26) {
      this.iban_error = this.managerService.config.WITHDRAWAL.ADD_BANK_ACCOUNT.IBAN_ERROR_LIST.IBAN_LONGER_THAN;
      return true;
    }

    if (this.addBankAccountForm.get('iban')?.value.length < 26) {
      this.iban_error = this.managerService.config.WITHDRAWAL.ADD_BANK_ACCOUNT.IBAN_ERROR_LIST.IBAN_SHORTER_THAN;
      return true;
    }

    this.bank_code = this.addBankAccountForm.get('iban')?.value.substr(4, 5);
    if (!this.isDigit(this.bank_code)) {
      this.iban_error = this.managerService.config.WITHDRAWAL.ADD_BANK_ACCOUNT.IBAN_ERROR_LIST.IBAN_BANK_CODE;
      return true;
    }

    return false;
  }

  clearAddBankAccountForm(): void {
    this.addBankAccountForm.reset({
      iban: 'TR', concatenated_name_field: '', label: ''
    });
  }

  addBankAccount(): void {

    if (this.addBankAccountForm.invalid) {
      this.warning_account_name.nativeElement.innerHTML = this.managerService.config.WITHDRAWAL.ADD_BANK_ACCOUNT.FILL_THE_FORM;
      this.warning_account_name.nativeElement.classList.remove('d-none');
      return;
    } else {
      this.warning_account_name.nativeElement.classList.add('d-none');
    }

    if (this.controlIBAN()) {
      this.warning_iban.nativeElement.innerHTML = this.iban_error;
      this.warning_iban.nativeElement.classList.remove('d-none');
      return;
    } else {
      this.warning_iban.nativeElement.classList.add('d-none');
    }

    const data = {
      iban: this.addBankAccountForm.value.iban,
      concatenated_name_field: this.addBankAccountForm.value.concatenated_name_field,
      label: this.addBankAccountForm.value.label,
    };

    this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'add_user_bank_account_service', data).subscribe(
      response => {
        if (!response.is_okay) {
          if (response.code === 235) {
            this.warning_name_surname.nativeElement.innerHTML = this.managerService.codeHandler(response);
            this.warning_iban.nativeElement.classList.add('d-none');
            this.warning_name_surname.nativeElement.classList.remove('d-none');
          } else if (response.code === 236) {
            this.warning_iban.nativeElement.innerHTML = this.managerService.codeHandler(response);
            this.warning_name_surname.nativeElement.classList.add('d-none');
            this.warning_iban.nativeElement.classList.remove('d-none');
          }
          // @ts-ignore
          const content = this.managerService.config.RESPONSE_TABLE[response.code];
          this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
        } else {
          // @ts-ignore
          const content = this.managerService.config.RESPONSE_TABLE[response.code];
          this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
          this.clearAddBankAccountForm();
          this.managerService.closeModal(this.modal_id);
          this.warning_iban.nativeElement.classList.add('d-none');
          this.warning_name_surname.nativeElement.classList.add('d-none');
          this.warning_account_name.nativeElement.classList.add('d-none');
          this.managerService.userService.user_bank_account_added.emit();
        }
      }));
  }

  ngOnDestroy(): void {
    this.subscription_list.forEach((subscription) => {
      subscription?.unsubscribe();
    });
  }

}
