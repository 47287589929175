
<div class="p-5" style="margin: 0 auto; min-width: 600px; max-width: 1200px">

    <div class="exo header6 medium" style="color: #8266F0; padding: 0 0 48px 0">{{managerService.config.AUCTION.LIVE_CATALOG | managerPipe}}</div>

    <div
        *ngIf="(managerService.auctionService.auction_product_list_pipe| managerPipe: managerService.mpa_template.AUCTION_CATALOG_ARRAY_LIVE).length === 0"
        class="d-flex m-5" style="min-width: 600px; max-width: 1200px">
<!--        todo text style -->
        {{managerService.config.AUCTION.NO_LIVE_CATALOG | managerPipe}}
    </div>

    <div (click)="managerService.navigateIfLoggedIn({url: '/auction/catalog', active_auction_catalog_hash: auction_catalog.hash, current_component_index: 2});"
         *ngFor="let auction_catalog of (managerService.auctionService.auction_product_list_pipe| managerPipe: managerService.mpa_template.AUCTION_CATALOG_ARRAY_LIVE)"
         class="w-100 d-flex flex-row justify-content-around mb-5 "
         style="border-radius: 16px;border: 2px solid #E2E2E2; cursor: pointer;padding: 32px 64px;">

        <div style="width: 300px; height: 218px;">
            <img #catalog_img (error)="catalog_img.src = catalog_img.src.replace('.webp', '.jpg');" alt="catalog_img"
                 height="200px"
                 src="{{auction_catalog | managerPipe: managerService.mpa_template.AUCTION_CATALOG_THUMBNAIL | managerPipe: managerService.mpa_template.LOCAL_IMAGE_MANAGER_TO_BE}}"
                 width="300px">
        </div>

        <div class="d-flex mx-3 flex-column" style="font-family: Roboto,sans-serif; width: 33%; gap: 16px">
            <div class="d-flex exo header6 medium" style="color: #8266F0">{{auction_catalog | managerPipe: managerService.mpa_template.AUCTION_CATALOG_NAME}}</div>
            <div class="d-flex barlow body1 medium"
                 style="color: #6F6F6F;">{{auction_catalog | managerPipe: managerService.mpa_template.AUCTION_CATALOG_TITLE}}</div>
            <div class="d-flex barlow body1 regular"
                 style="color: #6F6F6F;">{{auction_catalog | managerPipe: managerService.mpa_template.AUCTION_CATALOG_TEXT}}</div>
            <div class="d-flex flex-row" style="margin-top: 16px">
                <div class="d-flex flex-column barlow body1 medium">
                    <div class="mb-2 barlow body1 medium"
                         style="color: #66BB6A;">
                        {{auction_catalog | managerPipe: managerService.mpa_template.AUCTION_CATALOG_START_DATE | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</div>
                    <div class=" barlow body2 regular" style="color: #B5B5B5;">
                        {{managerService.config.AUCTION.AUCTION_START | managerPipe}}
                    </div>
                </div>
                <div class="mx-4" style="width: 1px; height: 53px; background-color: #747D8D;"></div>
                <div class="d-flex flex-column">
                    <div class="mb-2 barlow body1 medium"
                         style="color: #EA3943;">
                        {{auction_catalog | managerPipe: managerService.mpa_template.AUCTION_CATALOG_END_DATE | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</div>
                    <div class=" barlow body2 regular" style="color: #B5B5B5;">
                        {{managerService.config.AUCTION.AUCTION_END | managerPipe}}
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex align-items-end justify-content-center">
            <div class="d-flex justify-content-center align-items-center" style="width: 136px; height: 40px; border-radius: 8px; background: #8266F0;text-align: center;
                    font-weight: 700; font-size: 14px; color: #F5F6F7;">
                {{managerService.config.AUCTION.ARTWORKS | managerPipe}}
                <svg style="width:25px; height: 30px; rotate: 270deg; ">
                    <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#FFFFFF"/>
                </svg>
            </div>
        </div>
    </div>
</div>

<div class="p-5" style="margin: 0 auto;min-width: 600px; max-width: 1200px">

    <div class="exo header6 medium "
         style="color: #EA3943; padding: 0 0 48px 0">{{managerService.config.AUCTION.PAST_CATALOG | managerPipe}}</div>

    <div
        *ngIf="(managerService.auctionService.auction_product_list_pipe| managerPipe: managerService.mpa_template.AUCTION_CATALOG_ARRAY_PAST).length === 0"
        class="d-flex m-5" style="min-width: 600px; max-width: 1200px">
        {{managerService.config.AUCTION.NO_PAST_CATALOG | managerPipe}}
    </div>

    <div (click)="managerService.navigateIfLoggedIn({url: '/auction/catalog', active_auction_catalog_hash: auction_catalog.hash, current_component_index: 2});"
         *ngFor="let auction_catalog of (managerService.auctionService.auction_product_list_pipe| managerPipe: managerService.mpa_template.AUCTION_CATALOG_ARRAY_PAST)"
         class="w-100 d-flex flex-row justify-content-between mb-5 "
         style="border-radius: 16px;border: 2px solid #EA3943; cursor: pointer;padding: 32px 64px;">

        <div style="width: 300px; height: 218px;">
            <img #catalog_img (error)="catalog_img.src = catalog_img.src.replace('.webp', '.jpg');" alt="catalog_img"
                 height="200px"
                 src="{{auction_catalog | managerPipe: managerService.mpa_template.AUCTION_CATALOG_THUMBNAIL | managerPipe: managerService.mpa_template.LOCAL_IMAGE_MANAGER_TO_BE}}"
                 width="300px">
        </div>

        <div class="d-flex mx-1 flex-column" style="gap: 16px; width: 33%">
            <div class="d-flex exo header6 medium" style="color: #EA3943">{{auction_catalog | managerPipe: managerService.mpa_template.AUCTION_CATALOG_NAME}}</div>
            <div class="d-flex barlow body1 medium"
                 style="color: #6F6F6F;">{{auction_catalog | managerPipe: managerService.mpa_template.AUCTION_CATALOG_TITLE}}</div>
            <div class="d-flex barlow body1 regular"
                 style="color: #6F6F6F;">{{auction_catalog | managerPipe: managerService.mpa_template.AUCTION_CATALOG_TEXT}}</div>
            <div class="d-flex w-100 flex-row" style="margin-top: 16px">
                <div class="d-flex w-100 flex-column barlow body1 medium">
                    <div class="mb-2 barlow body1 medium"
                         style="color: #66BB6A;text-decoration: line-through">
                        {{auction_catalog | managerPipe: managerService.mpa_template.AUCTION_CATALOG_START_DATE | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</div>
                    <div class=" barlow body2 regular" style="color: #B5B5B5;text-decoration: line-through">
                        {{managerService.config.AUCTION.AUCTION_START | managerPipe}}
                    </div>
                </div>
                <div class="mx-4" style="width: 1px; height: 53px; background-color: #747D8D;"></div>
                <div class="d-flex w-100 flex-column">
                    <div class="mb-2 barlow body1 medium"
                         style="color: #EA3943;text-decoration: line-through">
                        {{auction_catalog | managerPipe: managerService.mpa_template.AUCTION_CATALOG_END_DATE | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</div>
                    <div class=" barlow body2 regular" style="color: #B5B5B5;text-decoration: line-through">
                        {{managerService.config.AUCTION.AUCTION_END | managerPipe}}
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex align-items-end justify-content-center">
            <div class="d-flex justify-content-center align-items-center" style="width: 136px; height: 40px; border-radius: 8px; background: #8266F0;text-align: center;
                    font-weight: 700; font-size: 14px; color: #F5F6F7;">
                {{managerService.config.AUCTION.ARTWORKS | managerPipe}}
                <svg style="width:25px; height: 30px; rotate: 270deg; ">
                    <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#FFFFFF"/>
                </svg>
            </div>
        </div>
    </div>
</div>

