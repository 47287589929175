<div style="margin: auto; min-height: 420px;">
  <div class="w-100 d-flex flex-column align-items-center justify-content-between">
    <div (click)="managerService.navigationManager({url: '/corporate/general-announcements', current_component_index: 0})"
         class="my-2 d-flex flex-column align-items-center justify-content-start category position-relative" style="cursor: pointer;">
      <img src="{{managerService.config.ANNOUNCEMENTS.HEADERS.OPERATION_ANNOUNCEMENTS_BG_IMG_SRC_MOBILE}}"
           class=" w-100" style="height: 100%; object-fit: cover; z-index: 0; border-radius: 4px;" alt="operations" #operations
           (error)="operations.src = operations.src.replace('.webp', '.jpg');">
      <div class="w-100 d-flex flex-column align-items-center justify-content-start position-absolute"
           style="top: 66%; background-color: rgba(244, 245, 249, 0.3);">
        <div class="pt-2" style="font-family: Raleway, sans-serif; font-size: 3.3vw; font-weight: 600; letter-spacing: 0.25px; text-align: center; color: #fcfdff;">
          {{managerService.config.ANNOUNCEMENTS.HEADERS.OPERATION_ANNOUNCEMENTS | managerPipe: managerService.mpa_template.TO_LOCAL_UPPER_CASE}}
        </div>
        <div class="py-1" style="font-family: Raleway, sans-serif; font-size: 2.7vw; font-weight: 500; line-height: 1.1; letter-spacing: 0.1px; text-align: center; color: #fcfdff;">
          {{managerService.config.ANNOUNCEMENTS.HEADERS.OPERATION_ANNOUNCEMENTS_EXPLANATION | managerPipe}}
        </div>
      </div>
    </div>
    <div (click)="managerService.navigationManager({url: '/corporate/about-artist', current_component_index: 0})"
         class="mb-2 d-flex flex-column align-items-center justify-content-start category position-relative" style="cursor: pointer;">
      <img src="{{managerService.config.ANNOUNCEMENTS.HEADERS.ARTISTS_ANNOUNCEMENTS_BG_IMG_SRC_MOBILE}}"
           class=" w-100" style="height: 100%; object-fit: cover; z-index: 0; border-radius: 4px;" alt="operations" #artists
           (error)="artists.src = artists.src.replace('.webp', '.jpg');">
      <div class="w-100 d-flex flex-column align-items-center justify-content-start position-absolute"
           style="top: 66%; background-color: rgba(244, 245, 249, 0.3);">
        <div class="pt-2" style="font-family: Raleway, sans-serif; font-size: 3.3vw; font-weight: 600; letter-spacing: 0.25px; text-align: center; color: #fcfdff;">
          {{managerService.config.ANNOUNCEMENTS.HEADERS.ARTISTS_ANNOUNCEMENTS | managerPipe: managerService.mpa_template.TO_LOCAL_UPPER_CASE}}
        </div>
        <div class="py-1" style="font-family: Raleway, sans-serif; font-size: 2.7vw; font-weight: 500; line-height: 1.1; letter-spacing: 0.1px; text-align: center; color: #fcfdff;">
          {{managerService.config.ANNOUNCEMENTS.HEADERS.ARTISTS_ANNOUNCEMENTS_EXPLANATION | managerPipe}}
        </div>
      </div>
    </div>
  </div>
</div>
