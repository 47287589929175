<div class="d-flex flex-row " style="min-height: 100%; margin-bottom: 0 ">
    <!--    Login elements half-->
    <div class="d-flex position-relative w-100" >
        <div class="position-absolute" style="left: 120px; top: 64px; cursor: pointer"  (click)="this.managerService.backToHome()">
            <img src="./assets/images/artiox_logo.svg#artiox_logo" alt="artiox_logo" width="130px" height="30px" style="fill: black; cursor: pointer"  >
        </div>
        <div class="d-flex justify-content-center h-100 w-100" style="padding-top: 170px">
            <div class="d-flex flex-column justify-content-center" [formGroup]="userSignUpForm" >
                <span class="mb-2 header5 medium exo" style="color: var(--text-color-artiox-black) ">{{managerService.config.SIGN_UP.CREATE_ACCOUNT | managerPipe}}</span>
                <span class="body2 regular barlow mb-4" style="color: var(--text-color-artiox-grey); max-width: 364px">{{managerService.config.SIGN_UP.SIGN_UP_TEXT | managerPipe}}</span>

                <form class="d-flex form-group focused mb-4" autocomplete="off" [formGroup]="userSignUpForm">
                    <label class=" d-flex form-label body2 barlow medium" for="email">
                        {{managerService.config.SIGN_UP.E_MAIL | managerPipe}}</label>
                    <input #email_input  autocomplete="off"    class="w-100 form-input body1 barlow regular" id="email"  (keydown.enter)="signUpFormValidator()" formControlName="email" type="text" placeholder="{{managerService.config.SIGN_UP.E_MAIL_PLACEHOLDER}}">
                    <div  #email_error  class="d-flex d-none flex-row " style="gap: 8px; margin-top: 12px" >
                        <svg style=" height: 16px; width: 16px; ">
                            <use [attr.xlink:href]='"./assets/images/error_icon.svg#error_icon"' fill="#EA3943"/>
                        </svg>
                        <div  class="body2 barlow regular" style="color: var(--secondary-colors-artiox-red-base)"></div>
                    </div>
                </form>
                <div class="d-flex flex-row  " style="margin-bottom: 32px">
                    <form class="d-flex form-group focused " [formGroup]="userSignUpForm" autocomplete="off">
                        <label class=" d-flex form-label body2 barlow medium position-relative" for="password">
                            {{managerService.config.SIGN_UP.PASSWORD | managerPipe}}
                            <svg (click)="passwordPipe = !passwordPipe" class="password-eye" style="height: 24px; width: 24px; cursor: pointer" >
                                <use *ngIf="(!passwordPipe | managerPipe)"  [attr.xlink:href]='"./assets/images/v2/ic_eye_close.svg#ic_eye_close"' />
                                <use *ngIf="(passwordPipe | managerPipe)" [attr.xlink:href]='"./assets/images/v2/ic_eye_open.svg#ic_eye_open"'/>
                            </svg>
                        </label>

                        <input #password_input autocomplete="off"  class="w-100 form-input body1 barlow regular" (keydown.enter)="signUpFormValidator()" formControlName="password" id="password"  [type]="(passwordPipe | managerPipe) ? 'text': 'password'" placeholder="{{managerService.config.SIGN_UP.PASSWORD_PLACEHOLDER}}">
                        <div  #password_error  class="d-flex d-none flex-row " style="gap: 8px; margin-top: 12px" >
                            <svg style=" height: 16px; width: 16px; ">
                                <use [attr.xlink:href]='"./assets/images/error_icon.svg#error_icon"' fill="#EA3943"/>
                            </svg>
                            <div  class="body2 barlow regular"  style="color: var(--secondary-colors-artiox-red-base)"></div>
                        </div>
<!--                        rezil bi implementation -->
                        <div *ngFor="let n of [2014,2015,2017]; index as i " >
                            <div  #password_pattern_error class="d-flex d-none flex-row" style="gap: 8px;margin-top: 16px"  >
                                <svg class="warning-checkbox d-flex justify-content-center align-items-center " style=" height: 16px; width: 16px; ">
                                    <use *ngIf="password_pattern_error.classList.contains('pattern-error')" [attr.xlink:href]='"./assets/images/checkbox_error.svg#checkbox_error"' stroke="#EA3943"/>
                                    <use *ngIf="password_pattern_error.classList.contains('pattern-success')" [attr.xlink:href]='"./assets/images/checkbox.svg#checkbox"' fill="#66BB6A"/>
                                </svg>
                                <div  class="body2 barlow regular text">{{(managerService.config.RESPONSE_TABLE | managerPipe :managerService.mpa_template.RETURN_INDEX: n).message}}</div>
                            </div>
                        </div>

                    </form>


                </div>
                <div *ngFor="let string of checkBoxes; index as i " class="d-flex flex-column" style="margin-bottom: 16px">
                    <div class="d-flex flex-row" style="gap: 8px;">
                        <div class="d-flex login-checkbox align-items-center " (click)="userSignUpForm.get(string)?.setValue(!userSignUpForm.get(string)?.value)" [class.login-checkbox-selected]="checkBoxes[i]">
                            <svg *ngIf="userSignUpForm.get(string)?.value" style="height: 16px; width: 16px;">
                                <use [attr.xlink:href]='"./assets/images/checkbox.svg#checkbox"' fill="#8266F0"/>
                            </svg>
                        </div>
                        <span  [innerHTML]="i | managerPipe: managerService.mpa_template.SIGN_UP_AGREEMENTS" class="d-flex body2 barlow regular mb-0 " style="color: var(--text-color-artiox-light-black); max-width: 339px "></span>

                    </div>
                    <div  #checkbox_error  class="d-flex d-none flex-row ml-4"  >
                        <span class="body2 barlow regular" style="color: var(--secondary-colors-artiox-red-base)">*</span>
                        <span  class="body2 barlow regular"  style="color: var(--secondary-colors-artiox-red-base)"></span>
                    </div>
                </div>
                <div (click)="signUpFormValidator()" class="w-100  d-flex justify-content-center py-2 login-button mt-3">
                    <span  class="body1 exo " style="color:#FFFFFF; font-weight: 600"> {{managerService.config.SIGN_UP.SIGN_UP | managerPipe}} </span>
                </div>
                <div class="d-flex w-100 justify-content-center mt-2" style="margin-bottom: 36px">
                    <span  class="body2 regular exo" style="color: var(--text-color-artiox-light-black)">{{managerService.config.SIGN_UP.HAVE_ACCOUNT | managerPipe}}</span>
                    <span (click)="managerService.navigationManager({url: '/login', current_component_index: 0});"  class="body2 regular exo clickable_text" > &nbsp; {{managerService.config.SIGN_UP.LOGIN | managerPipe}}</span>
                </div>

            </div>
        </div>
    </div >
    <!--    Artiox Purple background and image -->
    <div class="d-flex flex-column align-items-center justify-content-end w-100" style="background: linear-gradient(180deg, var(--main-color-artiox-purple-500) 0%, var(--main-color-artiox-purple-base) 100%); ">
        <div class="h-100 d-flex justify-content-center align-items-center flex-column">
        <span class="header2 medium exo text-center" style="color: var(--text-color-artiox-white, #FFFFFF); margin-top: 120px; margin-bottom: 48px; width: 480px">{{managerService.config.SIGN_UP.SIGN_UP_HEADER | managerPipe}}</span>
        <span class="header4 medium barlow text-center" [innerHTML]="managerService.config.SIGN_UP.SIGN_UP_HEADER_TEXT | managerPipe" style="color: var(--text-color-artiox-white, #FFFFFF);  margin-bottom: 70px;  width: 25dvw"></span>
        </div>
            <div  >
            <img src="./assets/screen_shots/desktop/sign_up_page_phone_mock_up.png" alt="market" style= "width: auto; min-height: 500px; height: 50dvh;">
        </div>
    </div>

</div>
