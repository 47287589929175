import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-art-product-info-mobile',
  templateUrl: './art-product-info-mobile.component.html',
  styleUrls: ['./art-product-info-mobile.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ArtProductInfoMobileComponent implements OnInit {

  constructor(public managerService: ManagerService, public router: Router) {
  }

  ngOnInit(): void {
  }

}
