import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../services/manager.service';

// import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-footer-mobile',
    templateUrl: './footer-mobile.component.html',
    styleUrls: ['./footer-mobile.component.css'],
    // animations: [
    //     trigger('addressDropdownState', [
    //         state('open', style({
    //             display: 'block'
    //         })),
    //         state('close', style({
    //             display: 'none'
    //         })),
    //         transition('*=>*', [
    //             animate('100ms')]),
    //     ]),
    //     trigger('corporateDropdownState', [
    //         state('open', style({
    //             display: 'block',
    //         })),
    //         state('close', style({
    //             display: 'none',
    //         })),
    //         transition('*=>*', [
    //             animate('100ms')]),
    //     ]),
    //     trigger('legalDropdownState', [
    //         state('open', style({
    //             display: 'block',
    //         })),
    //         state('close', style({
    //             display: 'none',
    //         })),
    //         transition('*=>*', [
    //             animate('100ms')]),
    //     ]),
    //     trigger('serviceDropdownState', [
    //         state('open', style({
    //             display: 'block',
    //         })),
    //         state('close', style({
    //             display: 'none',
    //         })),
    //         transition('*=>*', [
    //             animate('100ms')]),
    //     ]),
    //     trigger('supportDropdownState', [
    //         state('open', style({
    //             display: 'block',
    //         })),
    //         state('close', style({
    //             display: 'none',
    //         })),
    //         transition('*=>*', [
    //             animate('100ms')]),
    //     ])
    // ]
})
export class FooterMobileComponent implements OnInit {
    //
    // public address_dropdown_state: string = 'close';
    // public address_dropdown_state_reverse: string = 'open';
    // public corporate_dropdown_state: string = 'close';
    // public corporate_dropdown_state_reverse: string = 'open';
    // public legal_dropdown_state: string = 'close';
    // public legal_dropdown_state_reverse: string = 'open';
    // public service_dropdown_state: string = 'close';
    // public service_dropdown_state_reverse: string = 'open';
    // public support_dropdown_state: string = 'close';
    // public support_dropdown_state_reverse: string = 'open';
    //
    // constructor(public managerService: ManagerService) {
    // }
    //
    // ngOnInit(): void {
    // }
    //
    // instagram(): void {
    //     window.open('https://www.instagram.com/artioxofficial', '_blank');
    // }
    //
    // twitter(): void {
    //     window.open('https://www.twitter.com/artiox_official', '_blank');
    //
    // }
    //
    // telegram(): void {
    //     window.open('https://t.me/joinchat/AAAAAEi3DvvSITYO1OJI0w', '_blank');
    // }
    //
    // linkedin(): void {
    //     window.open('https://www.linkedin.com/company/artiox', '_blank');
    // }
    //
    // addressButtonClick(): void {
    //     if (this.address_dropdown_state === 'open') {
    //         this.address_dropdown_state = 'close';
    //         this.address_dropdown_state_reverse = 'open';
    //     } else {
    //         this.address_dropdown_state = 'open';
    //         this.address_dropdown_state_reverse = 'close';
    //     }
    // }
    //
    // corporateButtonClick(): void {
    //     if (this.corporate_dropdown_state === 'open') {
    //         this.corporate_dropdown_state = 'close';
    //         this.corporate_dropdown_state_reverse = 'open';
    //     } else {
    //         this.corporate_dropdown_state = 'open';
    //         this.corporate_dropdown_state_reverse = 'close';
    //     }
    // }
    //
    // legalButtonClick(): void {
    //     if (this.legal_dropdown_state === 'open') {
    //         this.legal_dropdown_state = 'close';
    //         this.legal_dropdown_state_reverse = 'open';
    //     } else {
    //         this.legal_dropdown_state = 'open';
    //         this.legal_dropdown_state_reverse = 'close';
    //     }
    // }
    //
    // serviceButtonClick(): void {
    //     if (this.service_dropdown_state === 'open') {
    //         this.service_dropdown_state = 'close';
    //         this.service_dropdown_state_reverse = 'open';
    //     } else {
    //         this.service_dropdown_state = 'open';
    //         this.service_dropdown_state_reverse = 'close';
    //     }
    // }
    //
    // supportButtonClick(): void {
    //     if (this.support_dropdown_state === 'open') {
    //         this.support_dropdown_state = 'close';
    //         this.support_dropdown_state_reverse = 'open';
    //     } else {
    //         this.support_dropdown_state = 'open';
    //         this.support_dropdown_state_reverse = 'close';
    //     }
    // }

    // number_array = Array.from({length: this.managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST.length}, (value, index) => index);
    // artist_list: any[] = [];
    //
    // constructor(public managerService: ManagerService) {
    // }
    //
    // ngOnInit(): void {
    //     this.artist_list = this.number_array.sort(() => Math.random() - 0.5).slice(0, 3);
    // }
    //
    // instagram(): void {
    //     window.open('https://www.instagram.com/artioxofficial', '_blank');
    // }
    //
    // twitter(): void {
    //     window.open('https://www.twitter.com/artiox_official', '_blank');
    //
    // }
    //
    // telegram(): void {
    //     window.open('https://t.me/joinchat/AAAAAEi3DvvSITYO1OJI0w', '_blank');
    // }
    //
    // linkedin(): void {
    //     window.open('https://www.linkedin.com/company/artiox', '_blank');
    // }
    //
    // openGooglePlay(): void {
    //     window.open('https://play.google.com/store/apps/details?id=com.artiox.artiox', '_blank');
    // }
    //
    // openAppStore(): void {
    //     window.open('itms-apps://apple.com/app/id1579037676?l=tr', '_blank');
    // }

    // addressButtonClick(): void {
    //     if (this.address_dropdown_state === 'open') {
    //         this.address_dropdown_state = 'close';
    //         this.address_dropdown_state_reverse = 'open';
    //     } else {
    //         this.address_dropdown_state = 'open';
    //         this.address_dropdown_state_reverse = 'close';
    //     }
    // }
    //
    // corporateButtonClick(): void {
    //     if (this.corporate_dropdown_state === 'open') {
    //         this.corporate_dropdown_state = 'close';
    //         this.corporate_dropdown_state_reverse = 'open';
    //     } else {
    //         this.corporate_dropdown_state = 'open';
    //         this.corporate_dropdown_state_reverse = 'close';
    //     }
    // }
    //
    // legalButtonClick(): void {
    //     if (this.legal_dropdown_state === 'open') {
    //         this.legal_dropdown_state = 'close';
    //         this.legal_dropdown_state_reverse = 'open';
    //     } else {
    //         this.legal_dropdown_state = 'open';
    //         this.legal_dropdown_state_reverse = 'close';
    //     }
    // }
    //
    // serviceButtonClick(): void {
    //     if (this.service_dropdown_state === 'open') {
    //         this.service_dropdown_state = 'close';
    //         this.service_dropdown_state_reverse = 'open';
    //     } else {
    //         this.service_dropdown_state = 'open';
    //         this.service_dropdown_state_reverse = 'close';
    //     }
    // }
    //
    // supportButtonClick(): void {
    //     if (this.support_dropdown_state === 'open') {
    //         this.support_dropdown_state = 'close';
    //         this.support_dropdown_state_reverse = 'open';
    //     } else {
    //         this.support_dropdown_state = 'open';
    //         this.support_dropdown_state_reverse = 'close';
    //     }
    // }
    artist_list: { ID: number, NAME: string }[] = [];

    constructor(public managerService: ManagerService) {
    }

    ngOnInit(): void {
        this.artist_list = [...this.managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST].sort(() => Math.random() - 0.5).slice(0, 3);
    }

    instagram(): void {
        window.open('https://www.instagram.com/artioxofficial', '_blank');
    }

    twitter(): void {
        window.open('https://www.twitter.com/artiox_official', '_blank');

    }

    telegram(): void {
        window.open('https://t.me/joinchat/AAAAAEi3DvvSITYO1OJI0w', '_blank');
    }

    linkedin(): void {
        window.open('https://www.linkedin.com/company/artiox', '_blank');
    }


}
