<h1 style="padding-top: 3pt;padding-left: 101pt;text-indent: 0pt;text-align: center;">ARTIOX KULLANICI</h1>
<h1 style="padding-top: 9pt;padding-left: 101pt;text-indent: 0pt;text-align: center;">KİŞİSEL VERİLERİN KORUNMASI
    AYDINLATMA METNİ</h1>
<p style="text-indent: 0pt;text-align: left;"><br/></p>
<ol>
    <li><p style="padding-left: 30pt;text-indent: -9pt;text-align: justify;">Artiox internet sitesi kullanıcıları bu
        site aracılığıyla verdikleri kişisel verilerinin aşağıda
        açıklanan amaçlar doğrultusunda işlenmesi hakkında <b>veri sorumlusu sıfatıyla </b>Artiox Teknoloji Yazılım
        Anonim Şirketi Şirketi tarafından 6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun
        10.maddesinde düzenlenen Veri sorumlusunun aydınlatma yükümlülüğü hükümleri kapsamında aydınlatılmaktadır.</p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
    <li><h2 style="padding-left: 30pt;text-indent: -9pt;text-align: justify;">Artiox internet sitesinde kullanıcı adı ve
        şifre oluşturan Kullanıcılarımız</h2> <span>, verdikleri e-posta adresi bilgisi üye kayıt işlemlerinin yapılabilmesi, üyelikle ilgili bilgi ve güncellemelerin gönderilebilmesi, ticari ileti tercihlerinize göre sizlere reklam,tanıtım ve pazarlama yapılabilmesi amacıyla işlenmektedir.</span>
        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
    <li><h2 style="padding-left: 30pt;text-indent: -9pt;text-align: justify;">Artiox internet sitesini kullanan
        kullanıcılarımızın </h2> <span>işlem ve trafik kayıtlarına ilişkin verileri, 5651 sayılı kanunda öngörülmesi hukuki sebebine dayalı olarak ve işlem güvenliğini sağlamak, hukuki yükümlülüğümüzü yerine getirmek amacıyla işlenmektedir.</span>
        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
    <li><h2 style="padding-left: 30pt;text-indent: -10pt;text-align: justify;">Kripto para yatırma-çekme işlemleri
        yapacak Kullanıcılarımız;</h2> <span>kendileri istemedikçe kimlik doğrulaması yapmalarına ihtiyaç yoktur, bu husus Kullanıcının kendi tercihine bağlıdır. Elindeki Kripto paralarını Türk Lirasına çevirip banka hesabına  göndermek  isteyen  kullanıcılarımızın  ise  kimlik  doğrulaması gerçekleştirmeleri gerekmektedir. Kimlik doğrulama sırasında, kullanıcılarımızın işlem güvenliğini sağlamak amacıyla aşağıdaki kişisel verileri işlenecektir:</span>

        <ul>
            <li><p style="padding-top: 9pt;padding-left: 66pt;text-indent: -18pt;text-align: justify;">Artiox’un
                belirlemiş olduğu limitler dahilinde yatırma ve çekme işlemleri
                gerçekleştirebilmek TC Kimlik numarası, İsim, Soyisim, Doğum yılı, Banka hesap numarası bilgileri
                işlenmektedir. Bu süreçte verdiğiniz bilgilerin doğruluğunu kontrol etmek amacıyla
                Nüfus Müdürlüğü API kullanıyoruz.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p style="padding-top: 9pt;padding-left: 66pt;text-indent: -18pt;text-align: justify;">Limitlerini
                artırmak isteyen kullanıcılarımızın kimlik ( Ad soyad, Anne - baba
                adı, Doğum tarihi, Cinsiyeti, Medeni hali, Nüfus cüzdanı seri sıra no, Uyruğu, TC kimlik
                no), Fotoğraf, Adres ve Fatura bilgileri, Banka hesap numarası
                bilgileri işlenmektedir.</p></li>
        </ul>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <p>Kimlikle işlem yapması gereken kullanıcılarımız
            kimliklerinin sadece ön yüzünü bizlerle paylaşmalıdırlar.</p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
    <li><h1 style="padding-left: 30pt;text-indent: -9pt;text-align: justify;">İtibari para (fiat currency) yatırma-çekme
        işlemleri yapacak kullanıcılarımız;</h1> <span>ancak ve ancak kendi banka hesaplarından havale/eft yapabilecekleri için kimlik bilgilerini   beyan   etmek   zorundadırlar.   Kimlik  doğrulama  sırasında, kullanıcılarımızın ve işlem güvenliğini sağlamak amacıyla aşağıdaki kişisel verileri işlenecektir:</span>
        <ul id="l3">
            <li><p style="padding-top: 10pt;padding-left: 66pt;text-indent: -18pt;text-align: justify;">Artiox’un
                belirlemiş olduğu limitler dahilinde yatırma ve çekme işlemleri
                gerçekleştirebilmek isteyen bu kullanıcılarımızın TC Kimlik numarası, İsim,</p>
                <p style="padding-top: 3pt;padding-left: 66pt;text-indent: 0pt;text-align: justify;">Soyisim, Doğum
                    yılı, Banka hesap numarası bilgileri işlenmektedir. Bu süreçte verdiğiniz bilgilerin
                    doğruluğunu kontrol etmek amacıyla Nüfus Müdürlüğü API kullanıyoruz.</p></li>
            <li><p style="padding-left: 66pt;text-indent: -18pt;text-align: justify;">Limitlerini arttırmak isteyen
                kullanıcılarımızın kimlik ( Ad soyad, Anne - baba adı, Doğum
                tarihi, Cinsiyeti, Medeni hali, Nüfus cüzdanı seri sıra no, Uyruğu, TC kimlik no<span>)</span>,
                Fotoğraf, Adres ve Müşteri İşlem(Fatura bilgileri,Banka hesap numarası
                bilgileri) işlenmektedir.</p></li>
        </ul>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <p style="padding-left: 66pt;text-indent: 0pt;text-align: justify;">Kimlikle işlem yapması gereken
            kullanıcılarımız kimliklerinin sadece ön yüzünü bizlerle paylaşmalıdırlar.</p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
    <li><h1 style="padding-left: 30pt;text-indent: -8pt;text-align: justify;">Kişisel Verilerinizin Toplama Yöntemi
        : </h1> <span
    >Kişisel verileriniz www.artiox.com İnternet sitesinde doldurduğunuz formla, yaptığınız işlemler ve ilettiğiniz bilgiler ile elde edildikten sonra Nüfus Müdürlüğü API ile kontrol edilmekte, kişisel verileri veri tabanına işleyen Müşteri İlişkileri Yönetimi yazılımı aracılığıyla otomatik yollarla işlenmektedir.</span>

        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
    <li><h1 style="padding-left: 30pt;text-indent: -8pt;text-align: justify;">
        Kişisel Veri İşleme Amaçları: </h1>
        <span>; Bu kapsamda, yukarıdaki kişisel verileriniz, kripto para ve/veya itibari para (fiat currency) yatırma-çekme işlemlerinizi yürütebilmek için ve aşağıda belirttiğimiz amaçlar doğrultusunda işlenmektedir.</span>

        <ol>
            <li><p style="padding-top: 9pt;padding-left: 29pt;text-indent: -16pt;text-align: left;">Bilgi Güvenliği
                Süreçlerinin Yürütülmesi</p></li>
            <li><p style="padding-top: 9pt;padding-left: 29pt;text-indent: -18pt;text-align: left;">İş Faaliyetlerinin
                Yürütülmesi / Denetimi</p></li>
            <li><p style="padding-top: 9pt;padding-left: 29pt;text-indent: -18pt;text-align: left;">İş Sürekliliğinin
                Sağlanması Faaliyetlerinin Yürütülmesi</p></li>
            <li><p style="padding-top: 9pt;padding-left: 29pt;text-indent: -20pt;text-align: left;">Yetkili Kişi, Kurum
                Ve Kuruluşlara Bilgi Verilmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p style="padding-left: 29pt;text-indent: -18pt;text-align: left;">Şirket’in ana faaliyet konusundaki
                hizmetleri yönetilmesi ve yürütülmesi,</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p style="padding-left: 32pt;text-indent: -22pt;text-align: left;">Finans ve Muhasebe işlerinin
                yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p style="padding-left: 29pt;text-indent: -19pt;text-align: left;">Müşteri ilişkileri yönetimi
                süreçlerinin yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p style="padding-left: 29pt;text-indent: -19pt;text-align: left;">Mal/Hizmet satış ve satın alım
                süreçlerinin yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p style="padding-left: 29pt;text-indent: -19pt;text-align: left;">Risk yönetimi süreçlerinin
                yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p style="padding-left: 29pt;text-indent: -24pt;text-align: left;">Sözleşme süreçlerinin yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p style="padding-left: 29pt;text-indent: -21pt;text-align: left;">Veri sorumlusu operasyonlarının
                güvenliğinin temini</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p style="padding-left: 29pt;text-indent: -23pt;text-align: left;">Reklam/ Tanıtım ve Pazarlama
                yapılması</p></li>
        </ol>
    </li>
    <li><h1 style="padding-top: 9pt;padding-left: 30pt;text-indent: -10pt;text-align: justify;">Kişisel Verilerinizin
        İşlenmesinin Hukuki Sebepleri: </h1><span>Yukarıda bahsedilen kişisel verileriniz aşağıdaki hukuki sebeplerle işlenmektedir:</span>

        <ol id="l5">
            <li><p style="padding-top: 9pt;padding-left: 29pt;text-indent: -17pt;text-align: left;">Sözleşmenin
                taraflarına ilişkin kişisel verilerin işlenmesinin gerekmesi</p>
            </li>
            <li><p style="padding-top: 9pt;padding-left: 29pt;text-indent: -19pt;text-align: left;">Bir hakkın tesisi,
                kullanılması veya korunması için veri işlemenin zorunlu
                olması</p></li>
            <li><p style="padding-top: 3pt;padding-left: 30pt;text-indent: -19pt;text-align: left;">İlgili kişinin temel
                hak ve özgürlüklerine zarar vermemek kaydıyla, veri
                sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması</p></li>
            <li><p style="padding-top: 9pt;padding-left: 29pt;text-indent: -20pt;text-align: left;">Kanunlarda
                Öngörülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
        </ol>
    </li>
    <li><h1 style="padding-left: 30pt;text-indent: -15pt;text-align: left;">Kişisel verilerinizin yurt içine
        aktarılması </h1>
        <span>:Yukarıda bahsedilen kişisel verileriniz aşağıdaki kişi gruplarına aktarılmaktadır.</span>

        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <table cellspacing="0" style="border-collapse:collapse;margin-left:30.4355pt">
            <tr style="height:63pt">
                <td style="width:141pt;border-top-style:solid;border-top-width:1pt;border-top-color:#D1D1D1;border-left-style:solid;border-left-width:1pt;border-left-color:#D1D1D1;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#D1D1D1;border-right-style:solid;border-right-width:1pt;border-right-color:#D1D1D1">
                    <p style="padding-top: 5pt;padding-left: 4pt;padding-right: 43pt;text-indent: 0pt;line-height: 111%;text-align: left;">
                        Veri Aktarımı Yapılabilecek Kişi Grupları</p></td>
                <td style="width:154pt;border-top-style:solid;border-top-width:1pt;border-top-color:#D1D1D1;border-left-style:solid;border-left-width:1pt;border-left-color:#D1D1D1;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#D1D1D1;border-right-style:solid;border-right-width:1pt;border-right-color:#D1D1D1">
                    <p style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">Kişi Gruplarının
                        Tanımı</p></td>
                <td style="width:176pt;border-top-style:solid;border-top-width:1pt;border-top-color:#D1D1D1;border-left-style:solid;border-left-width:1pt;border-left-color:#D1D1D1;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#D1D1D1;border-right-style:solid;border-right-width:1pt;border-right-color:#D1D1D1">
                    <p style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">Veri Aktarımının
                        Amacı</p></td>
            </tr>
            <tr style="height:144pt">
                <td style="width:141pt;border-top-style:solid;border-top-width:1pt;border-top-color:#D1D1D1;border-left-style:solid;border-left-width:1pt;border-left-color:#D1D1D1;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#D1D1D1;border-right-style:solid;border-right-width:1pt;border-right-color:#D1D1D1">
                    <p style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">Tedarikçi</p></td>
                <td style="width:154pt;border-top-style:solid;border-top-width:1pt;border-top-color:#D1D1D1;border-left-style:solid;border-left-width:1pt;border-left-color:#D1D1D1;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#D1D1D1;border-right-style:solid;border-right-width:1pt;border-right-color:#D1D1D1">
                    <p style="padding-top: 5pt;padding-left: 4pt;padding-right: 7pt;text-indent: 0pt;line-height: 111%;text-align: left;">
                        Veri sorumlusunun ticari faaliyetleri kapsamında
                        hizmet alımına yönelik hizmet sözleşmesine ve/veya vekalet sözleşmesine dayanarak Veri sorumlusu
                        ile iş ilişkisi içinde olan taraflardır.</p></td>
                <td style="width:176pt;border-top-style:solid;border-top-width:1pt;border-top-color:#D1D1D1;border-left-style:solid;border-left-width:1pt;border-left-color:#D1D1D1;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#D1D1D1;border-right-style:solid;border-right-width:1pt;border-right-color:#D1D1D1">
                    <p style="padding-top: 5pt;padding-left: 4pt;padding-right: 4pt;text-indent: 0pt;line-height: 111%;text-align: left;">
                        Veri sorumlusunun ticari faaliyetlerine ilişkin
                        olarak gerekli hizmetlerin alınmasına yönelik yapılan sözleşmelerin ifası için ve ancak bu
                        amaçla sınırlı olarak veri aktarımı sağlanacaktır.</p>
                    <p style="padding-left: 4pt;padding-right: 6pt;text-indent: 0pt;line-height: 111%;text-align: left;">
                        Örneğin müşteri ilişkileri yönetim şirketi, mali müşavirler,
                        avukatlar.</p></td>
            </tr>
            <tr style="height:184pt">
                <td style="width:141pt;border-top-style:solid;border-top-width:1pt;border-top-color:#D1D1D1;border-left-style:solid;border-left-width:1pt;border-left-color:#D1D1D1;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#D1D1D1;border-right-style:solid;border-right-width:1pt;border-right-color:#D1D1D1">
                    <p style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">Hissedarlar</p>
                </td>
                <td style="width:154pt;border-top-style:solid;border-top-width:1pt;border-top-color:#D1D1D1;border-left-style:solid;border-left-width:1pt;border-left-color:#D1D1D1;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#D1D1D1;border-right-style:solid;border-right-width:1pt;border-right-color:#D1D1D1">
                    <p style="padding-top: 5pt;padding-left: 4pt;padding-right: 7pt;text-indent: 0pt;line-height: 111%;text-align: left;">
                        Veri sorumlusunun Şirket&#39;inde hisselere
                        (paylara) sahip olan gerçek veya tüzel kişilerdir.</p></td>
                <td style="width:176pt;border-top-style:solid;border-top-width:1pt;border-top-color:#D1D1D1;border-left-style:solid;border-left-width:1pt;border-left-color:#D1D1D1;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#D1D1D1;border-right-style:solid;border-right-width:1pt;border-right-color:#D1D1D1">
                    <p style="padding-top: 5pt;padding-left: 4pt;padding-right: 4pt;text-indent: 0pt;line-height: 111%;text-align: left;">
                        Türk Ticaret Kanununun Ticaret Şirketleri başlıklı
                        bölümünde her şirket türüne özgü hükümler başta olmak üzere Ticaret Kanunundan , Borçlar
                        kanununun Adi Şirket&#39;e ilişkin hükümleri ve ilgili mevzuat hükümleri uyarınca doğan
                        bilgi verme ve benzeri yükümlülüklerimiz kapsamında hissedarlarımıza veri aktarımı
                        yapılacaktır.</p></td>
            </tr>
            <tr style="height:144pt">
                <td style="width:141pt;border-top-style:solid;border-top-width:1pt;border-top-color:#D1D1D1;border-left-style:solid;border-left-width:1pt;border-left-color:#D1D1D1;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#D1D1D1;border-right-style:solid;border-right-width:1pt;border-right-color:#D1D1D1">
                    <p style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;line-height: 111%;text-align: left;">
                        Yetkili Kamu Kurum ve Kuruluşları</p></td>
                <td style="width:154pt;border-top-style:solid;border-top-width:1pt;border-top-color:#D1D1D1;border-left-style:solid;border-left-width:1pt;border-left-color:#D1D1D1;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#D1D1D1;border-right-style:solid;border-right-width:1pt;border-right-color:#D1D1D1">
                    <p style="padding-top: 5pt;padding-left: 4pt;padding-right: 7pt;text-indent: 0pt;line-height: 111%;text-align: left;">
                        Veri sorumlusundan bilgi ve belge talep etmeye
                        ilgili mevzuatlarıyla yetkilendirilmiş bulunan kamu kurum ve kuruluşlarıdır.</p></td>
                <td style="width:176pt;border-top-style:solid;border-top-width:1pt;border-top-color:#D1D1D1;border-left-style:solid;border-left-width:1pt;border-left-color:#D1D1D1;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#D1D1D1;border-right-style:solid;border-right-width:1pt;border-right-color:#D1D1D1">
                    <p style="padding-top: 5pt;padding-left: 4pt;padding-right: 4pt;text-indent: 0pt;line-height: 111%;text-align: left;">
                        Veri Sorumlusu, ilgili mevzuattan doğan bilgi ve
                        belge paylaşımına ilişkin hukuki yükümlülüklerini yerine getirmek üzere Yetkili kamu Kurum ve
                        Kuruluşlarına veri aktarımı sağlayacaktır.</p>
                    <p style="padding-left: 4pt;text-indent: 0pt;line-height: 111%;text-align: left;">Ayrıca, kimlik
                        doğrulaması amacıyla Nüfus Müdürlüğüne aktarım yapılacaktır.</p></td>
            </tr>
        </table>
    </li>
    <li><h1>Kişisel verilerinizin yurt dışına aktarılması: </h1><span
    > Yukarıda bahsedilen kişisel verileriniz aşağıdaki şekilde yurt dışına aktarılabilmektedir:</span>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <ol id="l6">
            <li><p style="padding-left: 102pt;text-indent: -42pt;text-align: left;">Tedarikçilerimizin sunucularının
                yurt dışında bulunması sebebiyle bu hizmeti kullanabilmek
                amacıyla yurtdışına aktarılmaktadır.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p style="padding-left: 102pt;text-indent: -42pt;text-align: left;">Teknik sunucularımızın ve E-posta
                sunucularımızın yurt dışında olması sebebiyle kişisel
                verileriniz yurtdışına aktarılabilmektedir.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
        </ol>
    </li>
    <li><h1>Haklarınız </h1><span>- Şirket tarafından verilerinizin işlendiği ve Şirket’in verilerinizi veri sorumlusu sıfatı ile işlediği ölçüde kişisel verileriniz bakımından aşağıda bulunan haklara sahipsiniz:</span>

        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <ol id="l7">
            <li><p style="padding-left: 9pt;text-indent: 2pt;line-height: 213%;text-align: left;">Herhangi bir kişisel
                verinizin işlenip işlenmediğini öğrenme;</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p style="padding-left: 9pt;text-indent: 2pt;line-height: 213%;text-align: left;">Kişisel verilerinizin işlenme faaliyetlerine ilişkin olarak bilgi talep etme;
            </p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p style="padding-left: 9pt;text-indent: 2pt;line-height: 213%;text-align: left;">Kişisel verilerinizin
                işlenme amaçlarını öğrenme;</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p style="padding-left: 30pt;text-indent: -22pt;text-align: justify;">Kişisel verilerin yurt
                içinde veya yurt dışında üçüncü kişilere aktarılmış
                olması durumunda bu kişileri öğrenme;</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p style="padding-left: 30pt;text-indent: -20pt;text-align: justify;">Kişisel verilerin
                eksik veya yanlış işlenmiş olması halinde bunların
                düzeltilmesini isteme;</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p style="padding-left: 30pt;text-indent: -21pt;text-align: justify;">Kişisel verilerin
                işlenmesini gerektiren sebeplerin ortadan kalkması veya
                Şirket’in söz konusu verileri işleyebilmek için hukuki dayanağı veya meşru menfaatinin
                bulunmaması halinde kişisel verilerin silinmesini veya yok edilmesini isteme;</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p style="padding-left: 30pt;text-indent: -21pt;text-align: justify;">Şirket’ten, yine
                Şirket tarafından yetkilendirilen ve kişisel verileri işleyen
                üçüncü kişilerin bu bölüm kapsamındaki haklarınıza saygı duymasını sağlamasını talep
                etme;</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p style="padding-left: 30pt;text-indent: -21pt;text-align: justify;">Kişisel verilerin
                otomatik sistemler vasıtasıyla işlenmesi sonucu ortaya
                çıkabilecek aleyhte sonuçlara itiraz etme</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p style="padding-left: 30pt;text-indent: -21pt;text-align: justify;">Kişisel verilerinizin
                kanuna aykırı bir şekilde işlenmesi sebebiyle zarara
                uğramanız halinde bu zararın tazmin edilmesini isteme.</p></li>

            <p style="text-indent: 0pt;text-align: left;"><br/></p>
        </ol>
    </li>
    <li><h1>Veri sorumlusuna Başvuru- </h1><span>Kanunun ilgili kişinin haklarını düzenleyen 11. maddesi kapsamındaki taleplerinizi, “Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğe” göre Şirketimizin fiziki adresine  adresine yazılı olarak veya üyeliğinizin teyit edildiği elektronik posta üzerinden şirketimiz elektronik posta adresine iletebilirsiniz.</span>
        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
    <li><h1 >Veri Sorumlusu Bilgilendirmesi </h1><span>- Aşağıda detaylı kurumsal bilgileri yayınlanan ŞİRKET olarak, 6698 sayılı Kişisel Verilerin Korunması Kanunu uyarınca Veri Sorumlusu sıfatıyla kişisel verilerinizi ilgili mevzuata uygun olarak kullanacağız.</span>
    </li>
</ol>
<h1 >Veri Sorumlusu Unvan: Artiox Teknoloji Yazılım Anonim Şirketi</h1>
<p style="text-indent: 0pt;text-align: left;"><br/></p>
<h1> Mersis no:       0085093196500001</h1>
<p style="text-indent: 0pt;text-align: left;"><br/></p>
<h1 >E-posta adresi: info&#64;artiox.com</h1>
<p style="text-indent: 0pt;text-align: left;"><br/></p>
<h1>Kayıtlı Elektronik Posta Adresi:</h1>
<p style="text-indent: 0pt;text-align: left;"><br/></p>
<h1 >Fiziki Posta adresi : Mustafa Kemal Mah. Dumlıpınar Blv. No:280 G İç Kapı No: 1233 Çankaya/Ankara</h1>
