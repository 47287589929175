import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';

@Component({
  selector: 'app-basic-art-product-canvas-desktop',
  templateUrl: './basic-art-product-canvas-desktop.component.html',
  styleUrls: ['./basic-art-product-canvas-desktop.component.css']
})
export class BasicArtProductCanvasDesktopComponent implements OnInit {

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

}
