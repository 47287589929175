import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-operation-history-desktop',
  templateUrl: './operation-history-desktop.component.html',
  styleUrls: ['./operation-history-desktop.component.css']
})
export class OperationHistoryDesktopComponent implements OnInit, AfterViewInit, OnDestroy {

  public currency_selected: any = undefined;
  public operation_type_selected: any = undefined;
  public currency_selected_placeholder: string = this.managerService.config.BALANCE.ALL;
  public operation_type_selected_placeholder: string = this.managerService.config.BALANCE.ALL;

  public subscription_list: Subscription[] = [];

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.subscription_list.push(this.managerService.language_changed.subscribe(() => {
      if (this.currency_selected === undefined) {
        this.currency_selected_placeholder = this.managerService.config.BALANCE.ALL;
      }


      if (this.operation_type_selected === 'deposit') {
        this.operation_type_selected_placeholder = this.managerService.config.BALANCE.PAYING;
      } else if (this.operation_type_selected === 'withdrawal') {
        this.operation_type_selected_placeholder = this.managerService.config.BALANCE.WITHDRAWAL;
      } else {
        this.operation_type_selected_placeholder = this.managerService.config.BALANCE.ALL;
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscription_list.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

}
