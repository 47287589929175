import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as LightweightCharts from 'lightweight-charts';
import * as Highcharts from 'highcharts';
import {ManagerService} from '../../../../../../services/manager.service';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-trade-chart-mobile',
  templateUrl: './trade-chart-mobile.component.html',
  styleUrls: ['./trade-chart-mobile.component.css']
})
export class TradeChartMobileComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('trade_chart_container') trade_chart_container: any = undefined;
  @ViewChild('trade_chart_container_overlay') trade_chart_container_overlay: any = undefined;
  @ViewChild('depth_chart_container') depth_chart_container: any = undefined;
  @ViewChild('depth_chart_container_overlay') depth_chart_container_overlay: any = undefined;
  public trade_chart: any = undefined;
  public depth_chart: any = undefined;
  public trade_chart_line_series: any = undefined;
  public trade_chart_candlestick_series: any = undefined;
  public trade_chart_volume_series: any = undefined;
  public depth_chart_series: any = [
    {name: this.managerService.config.ADVANCED.TRADE_CHART.BID, data: [], color: '#29D8A9'},
    {name: this.managerService.config.ADVANCED.TRADE_CHART.ASK, data: [], color: '#FF207D'}
  ];
  public min_selected: string = 'FIVE_MINS';
  public hour_selected: string = 'FOUR_HOURS';

  public active_chart_type: string = 'TradeChart';
  public trade_chart_type: string = 'Line';
  public depth_chart_type: string = 'Depth';

  public subscription_list: Subscription[] = [];

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

  resolveDepthBookData(): void {
    this.depth_chart_series[0].data = this.managerService.tradeHistoryService.getBidsDepthBookData().map(x => [x.price, x.amount]).reverse();
    this.depth_chart_series[1].data = this.managerService.tradeHistoryService.getAsksDepthBookData().map(x => [x.price, x.amount]);
    this.depth_chart.update({
      series: this.depth_chart_series
    });
  }


  ngAfterViewInit(): void {
    this.constructCharts();
    this.subscription_list.push(this.managerService.language_changed.subscribe(() => {
      this.depth_chart_series[0].name = this.managerService.config.ADVANCED.TRADE_CHART.BID;
      this.depth_chart_series[1].name = this.managerService.config.ADVANCED.TRADE_CHART.ASK;
      this.depth_chart.update({
        series: this.depth_chart_series
      });
      this.depth_chart.update({
        tooltip: {
          headerFormat: '<span style="font-size=10px;">' + this.managerService.config.ADVANCED.TRADE_CHART.PRICE + ': {point.key}</span><br/>',
          valueDecimals: 2,
          borderRadius: 4
        },
      });
    }));
    this.subscription_list.push(this.managerService.tradeHistoryService.trade_chart_line_data_changed.subscribe(() => {
      if (this.active_chart_type === 'TradeChart' && this.trade_chart_type === 'Line') {
        this.trade_chart_line_series.setData(this.managerService.tradeHistoryService.getTradeChartData('Line'));
        this.trade_chart_candlestick_series.setData([]);
        this.trade_chart_volume_series.setData(this.managerService.tradeHistoryService.getTradeChartData('Candlestick'));
      }
    }));
    this.subscription_list.push(this.managerService.tradeHistoryService.trade_chart_candlestick_data_changed.subscribe(() => {
      if (this.active_chart_type === 'TradeChart' && this.trade_chart_type === 'Candlestick') {
        this.trade_chart_line_series.setData([]);
        this.trade_chart_candlestick_series.setData(this.managerService.tradeHistoryService.getTradeChartData('Candlestick'));
        this.trade_chart_volume_series.setData(this.managerService.tradeHistoryService.getTradeChartData('Candlestick'));
      }
    }));
    this.subscription_list.push(this.managerService.tradeHistoryService.trade_chart_depth_book_data_changed.subscribe(() => {
      if (this.active_chart_type === 'DepthChart' && this.depth_chart_type === 'Depth') {
        this.resolveDepthBookData();
      }
    }));
    this.subscription_list.push( this.managerService.pairService.active_pair_changed.subscribe(() => {
        this.trade_chart.priceScale().applyOptions({autoScale : true});
        this.trade_chart.timeScale().resetTimeScale();
        this.depth_chart.zoomOut();
      })
    );
    if (this.active_chart_type === 'TradeChart') {
      this.resolveChart(this.trade_chart_type);
    } else if (this.active_chart_type === 'DepthChart') {
      this.resolveChart(this.depth_chart_type);
    }

  }

  resolveChart(_chart_type: string): void {
    if (_chart_type === 'Line' || _chart_type === 'Candlestick') {
      this.trade_chart_type = _chart_type;
      this.active_chart_type = 'TradeChart';
      this.managerService.tradeHistoryService.trade_chart_line_data_changed.emit();
      this.managerService.tradeHistoryService.trade_chart_candlestick_data_changed.emit();
    } else if (_chart_type === 'Depth') {
      this.depth_chart_type = _chart_type;
      this.active_chart_type = 'DepthChart';
      this.managerService.tradeHistoryService.trade_chart_depth_book_data_changed.emit();
    }
  }

  constructCharts(): void {
    if (this.trade_chart === undefined) {
      this.trade_chart = LightweightCharts.createChart(this.trade_chart_container.nativeElement, {
        width: this.managerService.inner_width * 0.96,
        height: this.managerService.inner_width * 0.72,
        watermark: {
          color: 'rgba(64, 67, 82, 1)',
          visible: false,
          text: 'artiox.com',
          fontSize: 14,
          fontFamily: 'Roboto',
          horzAlign: 'left',
          vertAlign: 'bottom'
        },
        layout: {
          backgroundColor: 'transparent',
          textColor: 'rgba(171,175,199,0.4)',
          fontFamily: 'Roboto',
          fontSize: 14,
        },
        rightPriceScale: {
          autoScale: true,
          mode: LightweightCharts.PriceScaleMode.Normal,
          alignLabels: true,
          borderVisible: false,
          visible: true,
          drawTicks: false,
        },
        overlayPriceScales: {
          mode: LightweightCharts.PriceScaleMode.Normal,
          alignLabels: true,
          borderVisible: false,
          drawTicks: false,
          scaleMargins: {top: 0.8, bottom: 0},
        },
        timeScale: {
          rightOffset: 10,
          barSpacing: 6,
          fixLeftEdge: false,
          lockVisibleTimeRangeOnResize: false,
          rightBarStaysOnScroll: false,
          borderVisible: true,
          borderColor: 'rgba(64, 67, 82, 1)',
          visible: true,
          timeVisible: true,
          secondsVisible: false
        },
        crosshair: {
          mode: LightweightCharts.CrosshairMode.Normal,
          vertLine: {
            color: 'rgba(75,196,214,1)',
            width: 1,
            style: LightweightCharts.LineStyle.SparseDotted,
            visible: true,
            labelVisible: true,
            labelBackgroundColor: 'rgba(64,67,82,1)',
          },
          horzLine: {
            color: 'rgba(75,196,214,1)',
            width: 1,
            style: LightweightCharts.LineStyle.SparseDotted,
            visible: true,
            labelVisible: true,
            labelBackgroundColor: 'rgba(64,67,82,1)',
          }
        },
        grid: {
          vertLines: {
            visible: true,
            color: 'rgba(171,175,199,0.2)',
            style: LightweightCharts.LineStyle.Solid
          },
          horzLines: {
            visible: true,
            color: 'rgba(171,175,199,0.2)',
            style: LightweightCharts.LineStyle.Solid,
          }
        }
      });

      this.trade_chart_line_series = this.trade_chart.addAreaSeries({
        topColor: 'rgba(83, 162, 218, 0.2)',
        bottomColor: 'rgba(83, 162, 218, 0)',
        lineColor: 'rgba(83, 162, 218, 1)',
        lineStyle: LightweightCharts.LineStyle.Solid,
        lineWidth: 2,
        lineType: LightweightCharts.LineType.Simple,
        lastValueVisible: true,
        priceLineVisible: true,
        priceLineSource: LightweightCharts.PriceLineSource.LastVisible,
        priceLineWidth: 1,
        priceFormat: {
          type: 'price',
          precision: 2,
        }
      });

      this.trade_chart_volume_series = this.trade_chart.addHistogramSeries({
        priceScaleId: 'overlay',
        priceFormat: {
          type: 'volume',
          precision: 2,
        }
      });

      this.trade_chart_candlestick_series = this.trade_chart.addCandlestickSeries({
        upColor: 'rgba(41, 216, 169, 1)',
        downColor: 'rgba(255, 32, 125, 1)',
        wickVisible: true,
        borderVisible: true,
        borderUpColor: 'rgba(41, 216, 169, 1)',
        borderDownColor: 'rgba(255, 32, 125, 1)',
        wickUpColor: 'rgba(41, 216, 169, 1)',
        wickDownColor: 'rgba(255, 32, 125, 1)',
        lastValueVisible: true,
        priceLineVisible: true,
        priceLineSource: LightweightCharts.PriceLineSource.LastVisible,
        priceLineWidth: 1
      });
    }
    if (this.depth_chart === undefined) {
      this.depth_chart = Highcharts.chart(this.depth_chart_container.nativeElement, {
        chart: {
          type: 'area',
          width: this.managerService.inner_width,
          height: this.managerService.inner_width * 0.72,
          zoomType: 'xy',
          backgroundColor: 'transparent',
          borderColor: 'rgba(171,175,199,0.4)',
          style: {
            margin: '0',
            padding: '0',
            fontFamily: 'Roboto',
            fontSize: '14px',
            color: 'rgba(171,175,199,0.4)'
          }
        },
        title: {
          text: undefined
        },
        legend: {
          enabled: false
        },
        xAxis: {
          minPadding: 0,
          maxPadding: 0,
          title: {
            text: undefined
          },
          lineColor: 'rgba(171,175,199,0.2)',
          gridLineColor: 'rgba(171,175,199,0.2)',
          tickColor: 'rgba(171,175,199,0.2)',
        },
        yAxis: [
          {
            title: {
              text: undefined
            },
            lineWidth: 1,
            lineColor: 'rgba(171,175,199,0.2)',
            gridLineWidth: 1,
            gridLineColor: 'rgba(171,175,199,0.2)',
            tickWidth: 1,
            tickLength: 5,
            tickColor: 'rgba(171,175,199,0.2)',
            tickPosition: 'inside',
            labels: {
              align: 'right',
              x: -8
            }
          },
          {
            title: {
              text: undefined
            },
            opposite: true,
            linkedTo: 0,
            lineWidth: 1,
            lineColor: 'rgba(171,175,199,0.2)',
            gridLineWidth: 1,
            gridLineColor: 'rgba(171,175,199,0.2)',
            tickWidth: 1,
            tickLength: 5,
            tickColor: 'rgba(171,175,199,0.2)',
            tickPosition: 'inside',
            labels: {
              align: 'left',
              x: 8
            }
          }
        ],
        plotOptions: {
          area: {
            fillOpacity: 0.4,
            lineWidth: 1.5,
            step: 'center'
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size=10px;">' + this.managerService.config.ADVANCED.TRADE_CHART.PRICE + ': {point.key}</span><br/>',
          valueDecimals: 2,
          borderRadius: 4
        },
        series: this.depth_chart_series
      });
    }
  }

  adjustZoomLevel(zoom_level: string): void {
    if (this.active_chart_type === 'DepthChart'){
      this.active_chart_type = 'TradeChart';
    }
    new Promise((resolve, reject) => {
      this.trade_chart_container_overlay.nativeElement.firstChild.classList.add('loader');
      this.managerService.tradeHistoryService.setZoomLevel(zoom_level);
      this.managerService.tradeHistoryService.refreshTradeChartData(this.managerService.pairService.getActivePair()?.id, resolve);
    }).then(() => {
      setTimeout(() => {
        this.trade_chart_container_overlay.nativeElement.firstChild.classList.remove('loader');
      }, 100);
      this.managerService.trade_chart_pipe = !this.managerService.trade_chart_pipe;
    });
  }

  toggleChartType(): string{
    if (this.active_chart_type === 'TradeChart'){
      return 'Depth';
    }
    else {
      return 'Line';
    }
  }

  ngOnDestroy(): void {
    this.subscription_list.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
