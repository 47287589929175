import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
  selector: 'app-pre-sale-header-desktop',
  templateUrl: './pre-sale-header-desktop.component.html',
  styleUrls: ['./pre-sale-header-desktop.component.css']
})
export class PreSaleHeaderDesktopComponent implements OnInit {

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }
}
