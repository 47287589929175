<!--    <div #header class="justify-content-center d-flex w-100" style="background-color: #FFFFFF;border-bottom: 1px solid var(&#45;&#45;secondary-colors-artiox-gray-base, #E2E2E2); height: 84px">-->
<div class="w-100 d-flex justify-content-center" id="artiox-header" style="background-color: #FFFFFF;">
    <!--        <div-->
    <!--            #announcement (click)="managerService.navigationManager(announcement_link)"-->
    <!--            class="d-none w-100 flex-row align-items-center justify-content-center"-->
    <!--            style="color: #FFFFFF; background: #8266F0; height: 56px!important;cursor: pointer">-->
    <!--            <span *ngIf="managerService.pairService.getLastNotListedPair() !== undefined && managerService.pairService.getLastNotListedPair().symbol !== 'TEST'" [innerHTML]=" null | managerPipe: managerService.mpa_template.HEADER_CONFIG_EVALUATOR" class="d-flex exo subtitle1 medium" style="color: #FFFFFF"></span>-->
    <!--            &lt;!&ndash;        <div (click)="managerService.stopProp($event); closeAnnouncement();" class="px-4 py-3"&ndash;&gt;-->
    <!--            &lt;!&ndash;             style="right: 64px;cursor: pointer; position: absolute">&ndash;&gt;-->
    <!--            &lt;!&ndash;            <svg style="height:24px; width: 24px;">&ndash;&gt;-->
    <!--            &lt;!&ndash;                <use [attr.xlink:href]='"./assets/images/close.svg#close"' class="w-100" fill="white"/>&ndash;&gt;-->
    <!--            &lt;!&ndash;            </svg>&ndash;&gt;-->
    <!--            &lt;!&ndash;        </div>&ndash;&gt;-->
    <!--        </div>-->
    <div class="w-100 d-flex flex-row align-items-center"
         style="max-width: 1200px; height: 84px!important; background-color: #FFFFFF;">
        <div class="d-flex w-33" style="">
            <svg (click)="headerIconClick()" style="height:32px; width: 130px;cursor: pointer">
                <use [attr.xlink:href]='"./assets/images/artiox_logo.svg#artiox_logo"' class="w-100" fill="black"/>
            </svg>
        </div>

        <div class="d-flex w-100 h-100 flex-row justify-content-center align-items-center" style="gap: 24px; ">

            <!--        TRADE DROPDOWN-->
            <div
                    (mouseenter)="exchangeHover = true"
                    (mouseleave)="exchangeHover = false"
                    class="d-flex h-100 flex-row justify-content-center align-items-center dropdown-native-button"
                    style="padding: 8px; position: relative; cursor: pointer;">
                <div class="d-flex flex-row align-items-center justify-content-end w-100" style="gap: 8px; ">
                    <div class="d-flex exo subtitle1 medium"
                         style="color: #131515;">
                        {{managerService.config.HEADER.EXCHANGE | managerPipe}}
                    </div>
                    <div class="d-flex">
                        <svg style=" height: 10px; width: 10px;">
                            <use [attr.xlink:href]=' exchangeHover ? "./assets/images/up_arrow.svg#up_arrow"  : "./assets/images/down_arrow.svg#down_arrow"'
                                 fill="#131515"/>
                        </svg>
                    </div>
                </div>

                <div class="dropdown-native-menu overflow-visible "
                     style="cursor:pointer;width: 400px; background-color: #FFFFFF;top: 82px;left: 0px;border-radius: 0px 0px 4px 4px;position: absolute; padding-bottom: 8px">
                    <div class="d-flex" style="padding: 0 8px;width: 400px;">
                        <div
                                (click)="managerService.navigateIfLoggedIn({url: '/exchange/basic', current_component_index: 0});"
                                class="dropdown-native-item-white  btn-native justify-content-start "
                                style=" width: 384px; height: 70px;padding: 8px 16px">
                            <div class="d-flex">
                                <svg style=" height: 40px; width: 40px;">
                                    <use [attr.xlink:href]='"./assets/images/swap.svg#swap"'/>
                                </svg>
                            </div>
                            <div class="d-flex flex-column"
                                 style=" color: var(--text-color-artiox-black, #131515); margin-left: 16px; gap: 4px; ">
                                <div class="d-flex exo subtitle1 medium" style="color: #8266F0">
                                    {{managerService.config.HEADER.BASIC | managerPipe}}
                                </div>
                                <div class="d-flex exo subtitle2 regular" style="color: #6F6F6F">
                                    {{managerService.config.HEADER.BASIC_TEXT | managerPipe}}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="d-flex" style="padding: 0 8px;width: 400px;">
                        <div
                                (click)="managerService.navigateIfLoggedIn({url: '/exchange/advanced', current_component_index: 0});"
                                class="dropdown-native-item-white  btn-native justify-content-center justify-content-between"
                                style="width: 384px;  height: 70px;padding: 8px 16px">
                            <div class="d-flex">
                                <svg style=" height: 36px; width: 36px;">
                                    <use [attr.xlink:href]='"./assets/images/advance_trade.svg#advance_trade"'/>
                                </svg>
                            </div>
                            <div class="d-flex flex-column"
                                 style="color: var(--text-color-artiox-black, #131515); margin-left: 16px; gap: 4px">
                                <div class="d-flex exo subtitle1 medium" style="color: #8266F0">
                                    {{managerService.config.HEADER.ADVANCED | managerPipe}}
                                </div>
                                <div class="d-flex exo subtitle2 regular" style="color: #6F6F6F">
                                    {{managerService.config.HEADER.ADVANCED_TEXT | managerPipe}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--        AUCTION DROPDOWN-->
            <div
                    (mouseenter)="auctionHover = true"
                    (mouseleave)="auctionHover = false"
                    class="d-flex h-100 flex-row justify-content-center align-items-center dropdown-native-button"
                    style="padding: 8px; position: relative; cursor: pointer;">
                <div class="d-flex flex-row align-items-center justify-content-between w-100" style="gap: 8px;">
                    <div class="d-flex exo subtitle1 medium"
                         style="color: #131515; ">
                        {{managerService.config.HEADER.AUCTION | managerPipe}}
                    </div>
                    <div class="d-flex">
                        <svg style=" height: 10px; width: 10px;">
                            <use [attr.xlink:href]=' auctionHover ? "./assets/images/up_arrow.svg#up_arrow"   : "./assets/images/down_arrow.svg#down_arrow"'/>
                        </svg>
                    </div>
                </div>

                <div class="dropdown-native-menu overflow-visible justify-content-start"
                     style="cursor:pointer;width: 400px;padding-bottom: 8px; background-color: #FFFFFF;top: 82px;left: 0px;border-radius: 0px 0px 4px 4px;position: absolute;">

                    <div class="d-flex" style="padding: 0 8px;width: 400px;">
                        <div
                                (click)="managerService.navigateIfLoggedIn({url: '/auction/catalog', active_auction_catalog_hash: '2024_DAILY', current_component_index: 2});"
                                class="dropdown-native-item-white  btn-native justify-content-start"
                                style="width: 384px;  height: 70px;padding: 8px 16px">
                            <div class="d-flex">
                                <svg style=" height: 32px; width: 32px;">
                                    <use [attr.xlink:href]='"./assets/images/daily.svg#daily"'/>
                                </svg>
                            </div>
                            <div class="d-flex flex-column" style="color: #131515; margin-left: 16px;  gap: 4px">
                                <div class="d-flex exo subtitle1 medium" style="color: #8266F0">
                                    {{managerService.config.HEADER.DAILY | managerPipe}}
                                </div>
                                <div class="d-flex exo subtitle2 regular" style="color: #6F6F6F">
                                    {{managerService.config.HEADER.DAILY_TEXT | managerPipe}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex" style="padding: 0 8px;width: 400px;">
                        <div
                                (click)="managerService.navigateIfLoggedIn({url: '/auction', current_component_index: 1});"
                                class="dropdown-native-item-white  btn-native justify-content-start "
                                style="width: 384px;  height: 70px;padding: 8px 16px">
                            <div class="d-flex">
                                <svg style=" height: 32px; width: 32px;">
                                    <use [attr.xlink:href]='"./assets/images/catalogue.svg#catalogue"'/>
                                </svg>
                            </div>
                            <div class="d-flex flex-column" style="color: #131515; margin-left: 16px;gap: 4px">
                                <div class="d-flex exo subtitle1 medium" style="color: #8266F0">
                                    {{managerService.config.HEADER.CATALOG | managerPipe}}
                                </div>
                                <div class="d-flex exo subtitle2 regular" style="color: #6F6F6F">
                                    {{managerService.config.HEADER.CATALOG_TEXT | managerPipe}}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </div>
            <div class="d-flex exo subtitle1 medium"
                 style="padding: 8px; color: #131515; cursor: pointer;"
                (click)="managerService.navigationManager({url: '/sss', current_component_index: 0})">
                {{managerService.config.HEADER.SUPPORT| managerPipe}}
            </div>

            <!--            <div (click)="managerService.navigationManager(managerService.config.HEADER.SUPPORT_LINK)" class=" d-flex h-100 exo subtitle1 regular align-items-center"-->
            <!--                 style="color: #131515;padding: 8px 16px;cursor: pointer">-->
            <!--                <div class="d-flex">-->
            <!--                    {{managerService.config.HEADER.ACADEMY | managerPipe}}-->
            <!--                </div>-->
            <!--            </div>-->
            <!--            <div (click)="managerService.navigationManager(managerService.config.HEADER.SUPPORT_LINK)" class=" d-flex h-100 exo subtitle1 regular align-items-center"-->
            <!--                 style="color: #131515;padding: 8px 16px;cursor: pointer">-->
            <!--                <div class="d-flex">-->
            <!--                    {{managerService.config.HEADER.SUPPORT | managerPipe}}-->
            <!--                </div>-->
            <!--            </div>-->
        </div>
        <div class="d-flex  h-100 flex-row justify-content-end align-items-center w-33" style="">
            <!--            <div *ngIf=" managerService.pairService.active_featured_pair_ready && ((managerService.pair_pipe | managerPipe: managerService.mpa_template.GET_NOT_LISTED_PAIR_LIST).length) | managerPipe"-->
            <!--                    class="h-100 d-flex align-items-center">-->
            <!--                <div (click)="managerService.exchangeNavigate(managerService.pairService.getLastNotListedPair())"-->
            <!--                     class=" h-100  d-flex justify-content-center align-items-center public-sale-button" >-->
            <!--                    <div class="d-flex exo"-->
            <!--                         style="height: 32px;cursor: pointer;  padding: 8px 16px; font-size: 14px;font-style: normal;font-weight: 600;line-height: normal;color: var(&#45;&#45;main-color-artiox-purple-base, #FFF);">-->
            <!--                        {{managerService.pairService.getLastNotListedPair().sale_state | managerPipe: managerService.mpa_template.SALE_STATE_BUTTON: managerService.language_pipe}}-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
            <div (click)="managerService.exchangeNavigate(managerService.pairService.getLastNotListedPair())"
                 *ngIf=" managerService.pairService.active_featured_pair_ready && ((managerService.pair_pipe | managerPipe: managerService.mpa_template.GET_NOT_LISTED_PAIR_LIST).length) | managerPipe"
                 class=" public-sale-button"
                 style="cursor: pointer; min-width: 120px">
                <span class="exo subtitle1 regular"
                      style="color: #FFFFFF; user-select: none; min-width: 87px">  {{managerService.pairService.getLastNotListedPair().sale_state | managerPipe: managerService.mpa_template.SALE_STATE_BUTTON: managerService.language_pipe}}</span>
                <i></i>
            </div>
            <div *ngIf="!managerService.logged_in | managerPipe" class="d-flex flex-row align-items-center"
                 style="gap: 16px;margin-right: 16px">
                <div (click)="managerService.navigationManager({url: '/login', current_component_index: 0})"
                     [innerHTML]="managerService.config.HEADER.LOGIN_SHORT | managerPipe"
                     class="d-flex exo subtitle1 regular"
                     style="color: #131515; padding: 8px 16px;cursor: pointer"></div>
                <div (click)="managerService.navigationManager({url: '/sign-up', current_component_index: 0})"
                     [innerHTML]="managerService.config.HEADER.SIGNUP | managerPipe"
                     class="d-flex exo"
                     style="min-width: 77px;height: 32px;cursor: pointer; border-radius: 6px; padding: 8px 16px;background: #8266F0;font-size: 14px;font-style: normal;font-weight: 600;line-height: normal;color: var(--text-color-artiox-white, #FFF);"></div>
            </div>
            <!--        ACCOUNT DROPDOWN -->
            <div *ngIf="managerService.logged_in | managerPipe"
                 class="d-flex h-100 flex-row justify-content-center align-items-center dropdown-native-button"
                 style=" position: relative;margin-right: 16px; user-select: none">
                <div
                        style="color: #131515;cursor: pointer">
                    <svg style="height: 28px; width: 28px;">
                        <use [attr.xlink:href]='"./assets/images/profile.svg#profile"'/>
                    </svg>
                </div>
                <div class="dropdown-native-menu overflow-hidden "
                     style="cursor:pointer;width: 180px; background-color: #FFFFFF;top: 82px;border-radius: 0px 0px 4px 4px;position: absolute;border: 1px solid #E2E2E2; border-top: none">
                    <div
                            (click)="this.managerService.navigationManager({url: '/admin-panel', current_component_index: 0});"
                            *ngIf="managerService.userService.is_admin | managerPipe"
                            class="dropdown-native-item-white exo subtitle2 medium  btn-native justify-content-start"
                            style="width: 180px; height: 40px">
                        <svg style="height: 24px; width: 24px; margin: 0 8px;">
                            <use [attr.xlink:href]='"./assets/images/admin.svg#admin"'
                                 fill="var(--text-color-artiox-black, #131515)"/>
                        </svg>
                        {{managerService.config.HEADER.ADMIN | managerPipe}}
                    </div>
                    <div
                            (click)="this.managerService.navigationManager({url: '/account/dashboard', current_component_index: 0});"
                            class="dropdown-native-item-white exo subtitle2 medium  btn-native justify-content-start"
                            style="width: 180px; height: 40px">
                        <svg style="height: 24px; width: 24px; margin: 0 9px;">
                            <use [attr.xlink:href]='"./assets/images/panel.svg#panel"'
                                 fill="var(--text-color-artiox-black, #131515)"/>
                        </svg>
                        {{managerService.config.HEADER.DASHBOARD | managerPipe}}
                    </div>
                    <div
                            (click)="this.managerService.navigationManager({url: '/account/user-info', current_component_index: 0});"
                            class="dropdown-native-item-white exo subtitle2 medium  btn-native justify-content-start"
                            style="width: 180px; height: 40px">
                        <svg style="height: 24px; width: 24px; margin: 0 8px;">
                            <use [attr.xlink:href]='"./assets/images/user_info.svg#user_info"'
                                 fill="var(--text-color-artiox-black, #131515)"/>
                        </svg>
                        {{managerService.config.HEADER.USER_INFORMATION_SHORT | managerPipe}}
                    </div>
                    <div
                            (click)="this.managerService.navigationManager({url: '/account/balance', current_component_index: 0});"
                            class="dropdown-native-item-white exo subtitle2 medium  btn-native justify-content-start"
                            style="width: 180px; height: 40px">
                        <svg style="height: 24px; width: 24px; margin: 0 8px;">
                            <use [attr.xlink:href]='"./assets/images/wallet_full.svg#wallet_full"'
                                 fill="var(--text-color-artiox-black, #131515)"/>
                        </svg>
                        {{managerService.config.HEADER.WALLET | managerPipe}}
                    </div>
                    <div
                            (click)="this.managerService.navigationManager({url: '/account/deposit', current_component_index: 0});"
                            class="dropdown-native-item-white exo subtitle2 medium  btn-native justify-content-start"
                            style="width: 180px; height: 40px">
                        <svg style="height: 24px; width: 24px; margin: 0 8px;">
                            <use [attr.xlink:href]='"./assets/images/deposit.svg#deposit"'
                                 fill="var(--text-color-artiox-black, #131515)"/>
                        </svg>
                        {{managerService.config.HEADER.DEPOSIT | managerPipe}}
                    </div>
                    <div
                            (click)="this.managerService.navigationManager({url: '/account/withdrawal', current_component_index: 0});"
                            class="dropdown-native-item-white exo subtitle2 medium  btn-native justify-content-start"
                            style="width: 180px; height: 40px">
                        <svg style="height: 24px; width: 24px; margin: 0 8px;">
                            <use [attr.xlink:href]='"./assets/images/withdrawal.svg#withdrawal"'
                                 fill="var(--text-color-artiox-black, #131515)"/>
                        </svg>
                        {{managerService.config.HEADER.WITHDRAW | managerPipe}}
                    </div>
                    <div
                            (click)="this.managerService.navigationManager({url: '/account/auction', current_component_index: 0});"
                            class="dropdown-native-item-white exo subtitle2 medium  btn-native justify-content-start"
                            style="width: 180px; height: 40px">
                        <svg style="height: 24px; width: 24px; margin: 0 8px;">
                            <use [attr.xlink:href]='"./assets/images/gavel.svg#gavel"' fill="none"
                                 stroke="var(--text-color-artiox-black, #131515)"/>
                        </svg>
                        {{managerService.config.HEADER.AUCTION | managerPipe}}
                    </div>
                    <div
                            (click)="this.managerService.navigationManager({url: '/account/security', active_security_panel: managerService.active_security_panel_template.CHANGE_PASSWORD, current_component_index: 0});"
                            class="dropdown-native-item-white exo subtitle2 medium  btn-native justify-content-start"
                            style="width: 180px; height: 40px">
                        <svg style="height: 24px; width: 24px; margin: 0 8px;">
                            <use [attr.xlink:href]='"./assets/images/security.svg#security"'
                                 fill="var(--text-color-artiox-black, #131515)"/>
                        </svg>
                        {{managerService.config.HEADER.SECURITY | managerPipe}}
                    </div>
                    <div (click)="managerService.logout()"
                         class="dropdown-native-item-white exo subtitle2 medium  btn-native justify-content-start"
                         style="width: 180px; height: 40px">
                        <svg style="height: 24px; width: 24px; margin: 0 8px;">
                            <use [attr.xlink:href]='"./assets/images/logout.svg#logout"'
                                 fill="var(--text-color-artiox-black, #131515)"/>
                        </svg>
                        {{managerService.config.HEADER.LOGOUT | managerPipe}}
                    </div>
                </div>
            </div>
            <button (click)="managerService.switchLanguage()"
                    class="d-flex justify-content-center align-items-center"
                    id="language-button-desktop" aria-label="language-button-desktop"
                    style="height: 32px; padding: 0"
                    type="button">
                <svg style="height: 24px; width: 24px; color:#f3f5f9">
                    <use [attr.xlink:href]='"./assets/images/world_location.svg#world_location"'
                         stroke="var(--text-color-artiox-black, #131515)"/>
                </svg>
            </button>
        </div>
    </div>
</div>

