<div class="card">
  <div class="card-header">{{managerService.config.LOGIN.CAPTCHA_HEADER}}</div>
  <div class="card-body m-auto">
    <canvas #canvas id="canvas"></canvas>
    <canvas #block id="block" style="position: fixed;"></canvas>
    <div #slider_container class="slider-container">
      <span class="slider-text">{{managerService.config.LOGIN.CAPTCHA_BODY}}</span>
      <div class="slider-bg">
        <div #slider_mask class="slider-mask">
          <div #slider class="slider">
            <span class="slider-icon">
                <svg style="height: 24px; width: 24px;">
                  <use [attr.xlink:href]="slider_icon"/>
                </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
