<div id="basic_trade">
  <app-art-product-report-list-mobile></app-art-product-report-list-mobile>
  <app-basic-pair-header-mobile></app-basic-pair-header-mobile>
  <app-basic-art-product-canvas-mobile></app-basic-art-product-canvas-mobile>
  <div class="vw-100 min-vw-100 d-flex flex-row align-items-start justify-content-center"
       style="width: 80vw; min-width: 240px; height: fit-content;">
    <form
      [class]="(managerService.active_trade_type_panel | managerPipe: managerService.mpa_template.ACTIVE_TRADE_PANEL_SELL) ? 'd-flex' : 'd-none'"
      [formGroup]="marketSellForm" class="flex-column"
      style="width: 35%">
      <h2
        [innerHTML]="[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.BASIC.TRADE.AMOUNT_TO_SELL_MOBILE"
        style="font-family: Raleway, sans-serif; font-size: 12px; text-align: center; color: #4a4e70;">
      </h2>
      <label>
        <input class="w-100 input-field-white" formControlName="amount" min="0.00" placeholder="0"
               required type="number">
        <span
          style="font-family: Raleway, sans-serif; font-size: 10px; font-weight: normal; text-align: center; color: #9d9fa6;">
        {{[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.BASIC.TRADE.AVAILABLE_AMOUNT}}
          {{managerService.pair_pipe | managerPipe: managerService.mpa_template.USER_ACTIVE_ART_PRODUCT_AVAILABLE_AMOUNT: managerService.user_pipe}}
      </span>
      </label>
    </form>
    <form
      [class]="(managerService.active_trade_type_panel | managerPipe: managerService.mpa_template.ACTIVE_TRADE_PANEL_BUY) ? 'd-flex' : 'd-none'"
      [formGroup]="marketBuyForm" class="flex-column"
      style="width: 35%">
      <h2
        [innerHTML]="[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.BASIC.TRADE.AMOUNT_TO_BUY_MOBILE"
        style=" font-family: Raleway, sans-serif; font-size: 12px; text-align: center; color: #4a4e70;">
      </h2>
      <label>
        <input class="w-100 input-field-white" formControlName="amount" min="0.00" placeholder="0"
               required type="number">
        <span
          style="font-family: Raleway, sans-serif; font-size: 10px; font-weight: normal; text-align: center; color: #9d9fa6;">
        {{[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_CURRENCY_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.BASIC.TRADE.AVAILABLE_AMOUNT}}
          {{managerService.pair_pipe | managerPipe: managerService.mpa_template.USER_ACTIVE_CURRENCY_AVAILABLE_AMOUNT: managerService.user_pipe}}
      </span>
      </label>
    </form>
    <div class="d-flex flex-column align-items-center justify-content-end mt-3" style="width: 20%;">
      <h2 style="font-family: Raleway, sans-serif; font-size: 12px; text-align: center; color: #4a4e70;">
        {{managerService.config.BASIC.TRADE.SWAP | managerPipe}}
      </h2>
      <svg (click)="switch()"
           [style]="(managerService.active_trade_type_panel | managerPipe: managerService.mpa_template.ACTIVE_TRADE_PANEL_SELL) ? {transform: 'rotateZ(180deg)'} : {transform: 'rotateZ(0deg)'}"
           style="height: 24px; width: 24px; transition: 0.4s">
        <use [attr.xlink:href]='"./assets/images/basic_icon.svg#basic_icon"' fill="#cfd6d8"/>
      </svg>
    </div>
    <div
      [class]="(managerService.active_trade_type_panel | managerPipe: managerService.mpa_template.ACTIVE_TRADE_PANEL_SELL) ? 'd-flex' : 'd-none'"
      class="flex-column"
      style="width: 35%">
      <h2
        [innerHTML]="[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_CURRENCY_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.BASIC.TRADE.AVERAGE_COST_MOBILE"
        style=" font-family: Raleway, sans-serif; font-size: 12px; text-align: center; color: #4a4e70;">
      </h2>
      <label>
        <input class="w-100 input-field-white" disabled min="0.00" placeholder="0" readonly
               type="number"
               value="{{market_sell_average_cost | managerPipe}}">
      </label>
    </div>
    <div
      [class]="(managerService.active_trade_type_panel | managerPipe: managerService.mpa_template.ACTIVE_TRADE_PANEL_BUY) ? 'd-flex' : 'd-none'"
      class="flex-column"
      style="width: 35%">
      <h2
        [innerHTML]="[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_CURRENCY_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.BASIC.TRADE.AVERAGE_COST_MOBILE"
        style=" font-family: Raleway, sans-serif; font-size: 12px; text-align: center; color: #4a4e70;">
      </h2>
      <label>
        <input class="w-100 input-field-white" disabled min="0.00" placeholder="0" readonly
               type="number"
               value="{{market_buy_average_cost | managerPipe}}">
      </label>
    </div>
  </div>

  <button (click)="marketBuy()"
          [class]="(managerService.active_trade_type_panel | managerPipe: managerService.mpa_template.ACTIVE_TRADE_PANEL_BUY) ? 'd-block' : 'd-none'"
          class="w-75 btn-native buy-button my-2 mx-auto p-2">
    {{managerService.config.BASIC.TRADE.BUY | managerPipe}}
  </button>

  <button (click)="marketSell()"
          [class]="(managerService.active_trade_type_panel | managerPipe: managerService.mpa_template.ACTIVE_TRADE_PANEL_SELL) ? 'd-block' : 'd-none'"
          class="w-75 btn-native sell-button my-2 mx-auto p-2">
    {{managerService.config.BASIC.TRADE.SELL | managerPipe}}
  </button>

  <app-art-product-info-mobile></app-art-product-info-mobile>
</div>



