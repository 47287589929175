<div [style]="managerService.show_sign_up_or_login | managerPipe: managerService.mpa_template.GET_MIN_BODY_HEIGHT_OBSOLETE"
     class="d-flex flex-column w-100 h-100 mx-3">
    <div class="w-100 d-flex flex-column"
         style="box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff; max-height: 150px; min-height: 150px; border-radius: 4px; margin-bottom: 10px;">
        <div class="my-3 ml-4"
             style="font-family: Roboto, sans-serif; font-size: 15px; line-height: 0.7; color: #404352;">
            {{managerService.userService.user?.email | managerPipe}}
        </div>
        <div class="my-2 ml-4"
             style="font-family: bebas-neue-regular, sans-serif;font-size: 40px; font-weight: bold; line-height: 1.2; letter-spacing: 4px; color: #404352;">
            {{managerService.config.BALANCE.HEADING | managerPipe}}
        </div>
        <div class="my-1 ml-4">
            <button (click)="managerService.active_balance_panel = managerService.active_balance_panel_template.SUMMARY"
                    [class.btn-account-navigator-active]="managerService.active_balance_panel | managerPipe: managerService.mpa_template.ACTIVE_BALANCE_PANEL_SUMMARY"
                    class="btn-native btn-account-navigator mr-5" type="button">{{managerService.config.BALANCE.SUMMARY | managerPipe}}</button>
            <button (click)="managerService.active_balance_panel = managerService.active_balance_panel_template.USER_TRADE_HISTORY"
                    [class.btn-account-navigator-active]="managerService.active_balance_panel | managerPipe: managerService.mpa_template.ACTIVE_BALANCE_PANEL_ORDER_HISTORY"
                    class="btn-native btn-account-navigator mr-5" type="button">{{managerService.config.BALANCE.USER_TRADE_HISTORY | managerPipe}}</button>
            <button (click)="managerService.active_balance_panel = managerService.active_balance_panel_template.USER_OPERATION_HISTORY"
                    [class.btn-account-navigator-active]="managerService.active_balance_panel | managerPipe: managerService.mpa_template.ACTIVE_BALANCE_PANEL_OPERATION_HISTORY"
                    class="btn-native btn-account-navigator mr-5" type="button">{{managerService.config.BALANCE.USER_OPERATION_HISTORY | managerPipe}}</button>
        </div>
    </div>
    <app-summary-desktop [class.d-none]="!(managerService.active_balance_panel | managerPipe: managerService.mpa_template.ACTIVE_BALANCE_PANEL_SUMMARY)"></app-summary-desktop>
    <app-order-history-desktop [class.d-none]="!(managerService.active_balance_panel | managerPipe: managerService.mpa_template.ACTIVE_BALANCE_PANEL_ORDER_HISTORY)"></app-order-history-desktop>
    <app-operation-history-desktop
        [class.d-none]="!(managerService.active_balance_panel | managerPipe: managerService.mpa_template.ACTIVE_BALANCE_PANEL_OPERATION_HISTORY)"></app-operation-history-desktop>
</div>
