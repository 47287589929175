<div class="position-relative px-2 pt-5 d-flex flex-column align-items-center justify-content-center"
     style="background-image: radial-gradient(circle 1000px at 72.3% 1991.3%, #1e2028 0%, #21232d 100%); width: 300px;">
  <svg (click)="managerService.closeModal(modal_id);" class="position-absolute" style="top:10px; right: 10px; height: 24px; width: 24px;">
    <use [attr.xlink:href]='"./assets/images/close.svg#close"' fill="#9da5b7"/>
  </svg>
  <div class="w-100 d-flex flex-row align-items-center justify-content-center"
       style="font-family: bebas-neue-regular, sans-serif; font-size: 20px; font-weight: bold; line-height: 1.2; letter-spacing: 2.5px; color: #f4f5f9;">
    {{managerService.config.WITHDRAWAL.ADD_CRYPTO_ACCOUNT.HEADING | managerPipe}}
  </div>
  <form [formGroup]="addCryptoAccountForm" class="d-flex flex-column align-items-center justify-content-center my-4" style="width: 100%; height: fit-content; z-index: 2">
    <div class="d-flex flex-column" style="width: 100%; min-width: 240px;">
      <div class="w-100 d-flex flex-column">
        <div *ngIf="(managerService.operationsService.block_chain_list.length > 1) | managerPipe"
             class="position-relative w-100 mb-3 dropdown-native-button" style="width: 90%; margin: 2px auto; padding: 0 20px;">
          <label (click)="blockchain_dropdown.classList.remove('d-none')" class="position-relative d-flex flex-row" style="height: 40px; border-radius: 4px; margin: 0;">
            <input #blockchain_warning class="w-100 input-field-underline" readonly required style="height: 40px; border-radius: 4px; cursor: pointer" type="text"
                   [value]="selected_blockchain.name | managerPipe">
            <svg class="dropdown-icon" style="height: 24px; margin: 8px 2px;">
              <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#9DA5B7"/>
            </svg>
          </label>
          <div #blockchain_dropdown class="dropdown-native-menu-white d-none" style="width: 244px; height: fit-content;">
            <button (click)="blockchain_dropdown.classList.add('d-none'); selectBlockchain(item.id);"
                    *ngFor="let item of managerService.operationsService.block_chain_list | managerPipe"
                    class="dropdown-native-item-white btn-native justify-content-center" type="button">
              {{item.name | managerPipe}}
            </button>
          </div>
        </div>
        <div *ngIf="(managerService.operationsService.block_chain_list.length === 1) | managerPipe"
             class="w-100 mb-3 dropdown-native-button" style="width: 90%; margin: 2px auto; padding: 0 20px;">
          <label class="position-relative d-flex flex-row" style="height: 40px; border-radius: 4px; margin: 0;">
            <input #blockchain_warning class="w-100 input-field-underline" readonly required style="height: 40px; border-radius: 4px; cursor: pointer" type="text"
                   [value]="selected_blockchain.name | managerPipe">
          </label>
        </div>
        <label class="position-relative my-3">
          <span class='input-heading' style="color: #f4f5f9">{{managerService.config.WITHDRAWAL.ADD_CRYPTO_ACCOUNT.ACCOUNT_ADDRESS | managerPipe}}</span>
          <input #address_warning class="input-field" formControlName="address" placeholder="0x" required style="border-radius: 4px; border: solid 1px #ffffff; background-color: #ffffff; box-shadow: none;" type="text">
          <svg (click)="pasteAddress()" class="position-absolute"
               style="top: 52px; right: 32px; width: 20px; height: 20px;">
            <use [attr.xlink:href]='"./assets/images/paste.svg#paste"' fill="#9DA5B7"/>
          </svg>
        </label>
        <span class='input-heading' style="color: #f4f5f9">{{managerService.config.WITHDRAWAL.ADD_CRYPTO_ACCOUNT.CRYPTO_ACCOUNT_NAME | managerPipe}}</span>
        <label>
          <input #label_warning class="input-field" formControlName="label" required
                 style="border-radius: 4px; border: solid 1px #ffffff; background-color: #ffffff; width: 92%; box-shadow: none; padding: 0 10px;" type="text">
        </label>
        <div class="d-none warning mx-3" style="margin: 0"></div>
        <button (click)="addCryptoAccount()" [class.button-inactive]="addCryptoAccountForm.invalid" class="mt-4 btn-native" id="save-account-button-crypto">
          {{managerService.config.WITHDRAWAL.ADD_CRYPTO_ACCOUNT.SAVE_CRYPTO_ACCOUNT | managerPipe}}
        </button>
      </div>
    </div>
  </form>
</div>
