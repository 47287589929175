import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {LegalRoutes} from '../legal.component';

@Component({
    selector: 'app-legal-desktop',
    templateUrl: './legal-desktop.component.html',
    styleUrls: ['./legal-desktop.component.css']
})


export class LegalDesktopComponent implements OnInit {
    protected readonly LegalRoutes = LegalRoutes;
    active_path: LegalRoutes = LegalRoutes.USER_AGREEMENT;
    legalRoutes = [
        LegalRoutes.USER_AGREEMENT,
        LegalRoutes.PROTECTION_LAW_POLICY,
        LegalRoutes.INFORMATION_SECURITY_POLICY,
        LegalRoutes.DATA_DELETION_POLICY,
        LegalRoutes.KVKK_APPLICATION_FORM,
        LegalRoutes.COOKIE_CLARIFICATION_TEXT,
        LegalRoutes.KVKK_CLARIFICATION,
        LegalRoutes.MARKETING_CONSENT,
        LegalRoutes.FOREIGN_CONSENT,
        LegalRoutes.DISTANCE_SELLING,
        LegalRoutes.AUCTION_CANCELLATION,
        LegalRoutes.AUCTION_USER_AGREEMENT,
        LegalRoutes.AUCTION_RULES,
        LegalRoutes.COMMISSIONS,
        LegalRoutes.LIMITS
    ];

    constructor(public managerService: ManagerService, public route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.active_path = LegalRoutes.USER_AGREEMENT;
        this.route.paramMap.subscribe((params: ParamMap) => {
            if(params.get('path') != null) {
                // @ts-ignore
                const path = params.get('path');
                // @ts-ignore
                this.active_path = path
                this.managerService.navigationManager({url:'/legal/' + path, current_component_index: 0});

            }
        });
    }



}
