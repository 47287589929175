import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../services/manager.service';
import {AuctionService} from '../../../../services/auction.service';

@Component({
    selector: 'app-auction-desktop',
    templateUrl: './auction-desktop.component.html',
    styleUrls: ['./auction-desktop.component.css']
})
export class AuctionDesktopComponent implements OnInit {
    constructor(public managerService: ManagerService) {
    }

    ngOnInit(): void {
    }
}
