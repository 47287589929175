import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
    selector: 'app-crypto-withdrawal-request-desktop',
    templateUrl: './crypto-withdrawal-request-desktop.component.html',
    styleUrls: ['./crypto-withdrawal-request-desktop.component.css']
})
export class CryptoWithdrawalRequestDesktopComponent implements OnInit {
    @ViewChild('checkbox_field_desktop') checkbox_field_desktop: any = undefined;

    @Input() modal_id: string = '';

    constructor(public managerService: ManagerService) {
    }

    ngOnInit(): void {
    }

    onAccept(): void {
        this.managerService.operationsService.sign_withdrawal_request_data.status = 'approve';
        this.managerService.operationsService.crypto_withdrawal_rules_accepted_status = true;
        this.managerService.closeCryptocurrencyWithdrawalRequestModal();
        this.checkbox_field_desktop.checked = false;
        this.managerService.operationsService.cryptocurrency_withdrawal_request_form_submitted.emit();
        this.managerService.marketingService.logEvent('withdrawal_rules_approved', {symbol: 'USDT'});
    }

}
