import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ManagerService} from '../../../../../services/manager.service';
import {HttpClient} from '@angular/common/http';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-login-mobile',
  templateUrl: './login-mobile.component.html',
  styleUrls: ['./login-mobile.component.css']
})
export class LoginMobileComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() modal_id: string = '';
  @ViewChild('warning') warning: any = undefined;
  @ViewChild('login_form') login_form: any = undefined;
  public input_fields: any = undefined;
  public login_step: number = 0;

  public password_visible: boolean = false;

  loginForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', [Validators.required]),
    auth_password: new UntypedFormControl('', [Validators.pattern('^[0-9][0-9][0-9][0-9][0-9][0-9]$')]),
  });

  public subscription_list: (Subscription | undefined)[] = [];

  constructor(public managerService: ManagerService, private httpClient: HttpClient, public elementRef: ElementRef) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.input_fields = this.elementRef.nativeElement.getElementsByClassName('input-field');

    this.subscription_list.push(this.loginForm.get('auth_password')?.valueChanges.subscribe(() => {
      if (this.loginForm.get('auth_password')?.value.length === 6) {
        setTimeout(() => {
          this.login();
          const submit_button = document.getElementById('submit-button');
          if (submit_button){
            submit_button.classList.add('submit-button-active');
            setTimeout(() => {
              submit_button.classList.remove('submit-button-active');
            }, 200);
          }
        });
      }
    }));
  }

  loginFormValidator(): void {
    if (this.loginForm.invalid) {
      for (const input_field of this.input_fields) {
        input_field.classList.add('input-field-required');
      }
    } else {
      for (const input_field of this.input_fields) {
        input_field.classList.remove('input-field-required');
      }
      if (this.login_step === 0) {
        // if (this.managerService.environment.app) {
        //   this.login();
        // } else {
          this.login_step = 1;
          this.toggleLoginForm();
        // }
      } else if (this.login_step === 2) {
        this.login();
      }
    }
  }

  loginStepResolver(event: boolean): void {
    if (event) {
      setTimeout(() => {
        this.login();
      }, 500);
    } else {
      this.login_step = 0;
      // @ts-ignore
      this.warning.nativeElement.innerHTML = this.managerService.config.RESPONSE_TABLE['2000'].message;
      this.warning.nativeElement.classList.remove('d-none');
      this.toggleLoginForm();
    }
  }

  toggleLoginForm(): void {
    this.login_form.nativeElement.classList.toggle('d-flex');
    this.login_form.nativeElement.classList.toggle('d-none');
  }

  clearLoginForm(): void {
    for (const input_field of this.input_fields) {
      input_field.classList.remove('input-field-required');
    }
    this.loginForm.reset({email: '', password: '', auth_password: ''});
    this.loginForm.get('auth_password')?.setErrors(null);
    this.login_step = 0;
    this.login_form.nativeElement.classList.add('d-flex');
    this.login_form.nativeElement.classList.remove('d-none');
    this.warning.nativeElement.classList.add('d-none');
  }

  login(): void {
    const data = {
      user_email: this.loginForm.value.email,
      password: this.loginForm.value.password,
      auth_password: this.loginForm.value.auth_password,
      client_info: this.managerService.client_info
    };

    this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'user_login_service', data).subscribe(
      response => {
        this.managerService.closeModal('loading-modal');
        if (!response.is_okay) {
          if (response.code === 1213) {
            this.managerService.openModal('loading-modal');
            setTimeout(() => {
              this.login();
            }, 500);
          }
          else if ([207, 208].includes(response.code)) {
            if (this.login_step === 1) {
              this.login_step = 2;
              this.toggleLoginForm();
            }
            // if (this.managerService.environment.app) {
            //   this.login_step = 2;
            // }
            this.warning.nativeElement.innerHTML = this.managerService.codeHandler(response);
            this.warning.nativeElement.classList.remove('d-none');
          } else {
            if (this.login_step === 1) {
              this.login_step = 0;
              this.toggleLoginForm();
            }
            this.warning.nativeElement.innerHTML = this.managerService.codeHandler(response);
            this.warning.nativeElement.classList.remove('d-none');
          }
        } else {
          this.managerService.loggedInResolver(true, response.cookie);
          this.managerService.closeModal(this.modal_id);
          this.clearLoginForm();
        }
      }));
  }

  autofillPassword(event: any): void{
    this.loginForm.get('password')?.setValue(event.target.value ?? '');
  }

  pasteAuthPassword(): void{
    // if (this.managerService.environment.app) {
    //   this.managerService.cordova.plugins.clipboard.paste(
    //     (text: any) => { this.loginForm.get('auth_password')?.setValue(text); }
    //   );
    // }
    // else {
      navigator.clipboard.readText().then((text) => {
        this.loginForm.get('auth_password')?.setValue(text);
      });
    // }
  }

  ngOnDestroy(): void {
    this.subscription_list.forEach((subscription) => {
      subscription?.unsubscribe();
    });
  }
}
