<div class="w-75 d-flex flex-column align-items-center justify-content-center m-auto m-3"
     style="height: fit-content; margin: 16px!important;">
  <p
    [innerHTML]="managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_ARTIST_ABOUT_UPPERCASE: managerService.language_pipe"
    class="my-3"
    style="font-family: bebas-neue-bold, sans-serif; font-size: 24px; color: #eceef7; letter-spacing: 1.5px; text-align: center!important; margin-bottom: 4px!important;"></p>
  <p *ngIf="managerService.pairService.active_pair_ready | managerPipe"
     class="mt-1 mb-4 mx-3" style="font-family: Roboto, sans-serif; font-size: 14px; color: #eceef7; margin-top: 4px!important;">
    <span
      [innerHTML]="managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_PAIR_INFO: managerService.language_pipe"></span>
    <a class="clarification-text"
       *ngIf="(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_PAIR_ROUTER_LINK: managerService.language_pipe); let item;"
       (click)="managerService.navigationManager(item.LINK);" rel="noreferrer">{{item.TEXT | managerPipe}}</a>
  </p>
</div>

