<div class="w-80 mx-auto px-4" style=" border-radius: 4px; box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22);  background-color: #fcfdff;">
  <div class="pt-5 pb-3" style="font-family: bebas-neue-bold, sans-serif; font-size: 39px; letter-spacing: 4px; color: #404352;">
    {{managerService.config.CORPORATE.HEADER.HEADING | managerPipe}}
  </div>
  <div class="w-100 d-flex flex-row">
    <div (click)="managerService.navigationManager({url: '/corporate/about', current_component_index: 0})"
         [style.color]="(managerService.navigationService.active_corporate_page === managerService.navigationService.corporate_page_template.ABOUT ? 'var(--artiox-blue)' : '#595e7e') | managerPipe"
         class="btn-native pr-5"
         style="font-family: Roboto, sans-serif; font-size: 16px; font-weight: 500; letter-spacing: 1.6px; cursor: pointer">
      {{managerService.config.CORPORATE.HEADER.ABOUT | managerPipe}}
    </div>
    <div (click)="managerService.navigationManager({url: '/corporate/announcements', current_component_index: 0})"
      [style.color]="(managerService.navigationService.active_corporate_page === managerService.navigationService.corporate_page_template.ANNOUNCEMENT ? 'var(--artiox-blue)' : '#595e7e') | managerPipe"
      class="btn-native px-5"
      style="font-family: Roboto, sans-serif; font-size: 16px; font-weight: 500; letter-spacing: 1.6px; cursor: pointer">
      {{managerService.config.CORPORATE.HEADER.ANNOUNCEMENTS | managerPipe}}
    </div>
    <div (click)="managerService.navigationManager({url: '/corporate/career', current_component_index: 0})"
         [style.color]="(managerService.navigationService.active_corporate_page === managerService.navigationService.corporate_page_template.CAREER ? 'var(--artiox-blue)' : '#595e7e') | managerPipe"
         class="btn-native px-5"
         style="font-family: Roboto, sans-serif; font-size: 16px; font-weight: 500; letter-spacing: 1.6px; cursor: pointer">
      {{managerService.config.CORPORATE.HEADER.CAREER | managerPipe}}
    </div>
    <div (click)="managerService.navigationManager({url: '/corporate/contact', current_component_index: 0})"
         [style.color]="(managerService.navigationService.active_corporate_page === managerService.navigationService.corporate_page_template.CONTACT ? 'var(--artiox-blue)' : '#595e7e') | managerPipe"
         class="btn-native px-5"
         style="font-family: Roboto, sans-serif; font-size: 16px; font-weight: 500; letter-spacing: 1.6px; cursor: pointer">
      {{managerService.config.CORPORATE.HEADER.CONTACT | managerPipe}}
    </div>
  </div>
</div>
