<div class="w-100" style="background-color: #ffffff;"
     [style]="managerService.show_sign_up_or_login | managerPipe: managerService.mpa_template.GET_MIN_BODY_HEIGHT_OBSOLETE">
  <div class="w-100 pt-4 text-center" style="font-family: bebas-neue-bold, sans-serif; font-size: 20px; letter-spacing: 1.2px; color: #404352;">
    {{managerService.config.WITHDRAWAL_ALREADY_CONFIRMED.HEADING | managerPipe}}
  </div>
  <div class="w-100 px-3 pt-3 text-center" style="font-family: Roboto, sans-serif; font-size: 12px; line-height: 1.25; letter-spacing: 0.32px; color: #404352;">
    {{managerService.config.WITHDRAWAL_ALREADY_CONFIRMED.DESCRIPTION | managerPipe}}
  </div>
  <div class="w-100 pt-3 text-center">
    <img [alt]="managerService.config.WITHDRAWAL_ALREADY_CONFIRMED.IMG_ALT | managerPipe"
         [src]="managerService.config.WITHDRAWAL_ALREADY_CONFIRMED.IMG_SRC | managerPipe" width="200px">
  </div>
  <div class="w-100 pt-3 text-center">
    <button (click)="managerService.backToHome()" class="btn-native" id="back-to-home-button">
      {{managerService.config.WITHDRAWAL_ALREADY_CONFIRMED.BACK_TO_HOME | managerPipe}}
    </button>
  </div>
</div>


