import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
  selector: 'app-deposit-desktop',
  templateUrl: './deposit-desktop.component.html',
  styleUrls: ['./deposit-desktop.component.css']
})
export class DepositDesktopComponent implements OnInit {

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

  changeDepositCurrencySection(section: number): void {

    this.managerService.operationsService.selected_user_currency_wallet = this.managerService.operationsService.user_crypto_currency_wallet_list[section];
  }

}
