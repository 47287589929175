import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
    selector: 'app-connection-fail-mobile',
    templateUrl: './connection-fail-mobile.component.html',
    styleUrls: ['./connection-fail-mobile.component.css']
})
export class ConnectionFailMobileComponent implements OnInit, OnDestroy {

    public rotate_arrow_degree: number = 0;
    @ViewChild('rotate_arrow') rotate_arrow: any = undefined;
    public loading_animation: boolean = true;

    public timeout_list: any[] = [];

    constructor(public managerService: ManagerService) {
        this.loading_animation = true;
        this.timeout_list = [];

        this.timeout_list.push(setTimeout(() => {
            this.loading_animation = false;
        }, 5000));

        // if (this.managerService.environment.platform === 'web') {
        this.timeout_list.push(setTimeout(() => location.reload(), 5000));
        // }

        this.managerService.maintenance = false;

        this.timeout_list.push(setTimeout(() => {
            fetch(managerService.environment.serverURL).then((response) => {
                response.body?.getReader().read().then(value => {
                    if (new TextDecoder().decode(value.value).includes('<meta name="description" content="Artiox is coming soon">')) {
                        this.managerService.maintenance = true;
                    }

                    if (this.managerService.config_ready && !this.managerService.maintenance) {
                        this.managerService.showConnectionFailModal();
                    }
                });
            });

        }, 1000));
    }


    ngOnInit(): void {
    }

    rotate(): void {
        this.rotate_arrow_degree += 360;
        this.rotate_arrow.nativeElement.style.transform = 'rotateZ(' + this.rotate_arrow_degree.toString() + 'deg)';
    }

    ngOnDestroy(): void {
        this.timeout_list.forEach((e) => clearTimeout(e));
        this.loading_animation = false;
    }

}
