import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../services/manager.service';

@Component({
  selector: 'app-pre-sale',
  templateUrl: './pre-sale.component.html',
  styleUrls: ['./pre-sale.component.css']
})
export class PreSaleComponent implements OnInit{

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }
}
