<div style="width: 557px; background: #FFFFFF;height: 100vh">
    <div class="p-5 w-100 d-flex flex-column align-items-start justify-content-center" style="gap: 16px">
        <div class="d-flex w-100 align-items-stretch justify-content-between">
            <div class="header5 exo bold"
                 style="color: #8266F0">
                {{managerService.config.AUCTION.BID_RULES.TITLE | managerPipe}}
            </div>

            <div class="justify-content-end">
                <svg (click)="managerService.closeModal(modal_id)" style=" height: 24px; width: 24px;cursor: pointer">
                    <use [attr.xlink:href]='"./assets/images/close.svg#close"' fill="#black"/>
                </svg>
            </div>
        </div>
        <div class="barlow overline regular"
             [innerHTML]="managerService.config.AUCTION.BID_RULES.SUBTITLE | managerPipe">
        </div>
        <div class="barlow overline regular">
            <div *ngFor="let rule of managerService.config.AUCTION.BID_RULES.TEXT | managerPipe" [innerHTML]="rule"
                 class="mb-4">
            </div>
        </div>
    </div>
    <div class="d-flex w-100 justify-content-center align-items-end" style="position:absolute; bottom: 10px">
        <svg style=" height: 32px; width: 138px; margin-top: 32px; margin-bottom: 60px">
            <use [attr.xlink:href]='"./assets/images/artiox_logo.svg#artiox_logo"' fill="#000"/>
        </svg>
    </div>
</div>
