<div class="w-80 mx-auto pt-4">
  <div class="w-100 d-flex flex-row">
    <div>
      <svg style="height: 56px; width: 44px;">
        <use [attr.xlink:href]='"./assets/images/commissions.svg#commissions"' fill="var(--artiox-blue)"/>
      </svg>
    </div>
    <div class="pl-2">
      <div class="pb-1" style="font-family: Roboto, sans-serif; font-size: 20px; color: var(--artiox-blue)">
        {{managerService.config.SERVICE.COMMISSIONS.HEADING | managerPipe}}
      </div>
      <div class="" style="font-family: Roboto, sans-serif; font-size: 13px; color: #707070;">
        {{managerService.config.SERVICE.COMMISSIONS.SUMMARY | managerPipe}}
      </div>
    </div>
  </div>
</div>
