import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../services/manager.service';

@Component({
  selector: 'app-sign-up-successful',
  templateUrl: './sign-up-successful.component.html',
  styleUrls: ['./sign-up-successful.component.css']
})
export class SignUpSuccessfulComponent implements OnInit {

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

}
