<div class="w-100 d-flex flex-column" style="min-height: 800px; background-color: #fcfdff;">
  <img src="./assets/images/404.svg" alt="404" width="404" height="404" style="margin-top: 120px" class="mx-auto">

  <div class="text-center" style="font-family: Roboto, sans-serif; font-size: 13px; color: #404352; margin-top: 60px">
    {{managerService.config.PAGE_NOT_FOUND.DESCRIPTION | managerPipe}}
  </div>

  <div class="text-center" style="margin: 80px 0 40px 0">
    <button (click)="backToHome()" class="btn-native" id="back-to-home-button">
      {{managerService.config.WITHDRAWAL_CONFIRMED.BACK_TO_HOME | managerPipe}}
    </button>
  </div>

</div>
