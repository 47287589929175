<div class="d-inline-flex flex-column align-items-start justify-content-center">
  <div class="d-flex flex-row align-self-center justify-content-center position-absolute mb-4">
    <app-tooltip [direction]="managerService.tooltipService.tooltip_direction_template.TOP | managerPipe" class="d-none position-relative"
                 id="art-products-tooltip">
      {{managerService.config.TOOLTIP.BASIC.ART_PRODUCTS_TOOLTIP  | managerPipe}}
    </app-tooltip>
  </div>
  <div>
    <h1 class=" pl-4 pr-4 pt-3 exo header5 bold" style="color: #404352;  height: 50px;">
      {{managerService.config.BASIC.ART_PRODUCTS | managerPipe}}
    </h1>
  </div>
</div>

<div class="w-100 d-flex flex-column align-items-center justify-content-start overflow-auto" style="height: 600px; border-radius: 4px;
box-shadow: 1px 2px 4px 0 rgba(45, 52, 104, 0.32); background-color: #ffffff;">
  <div (click)="managerService.navigationManager({url: '/exchange/basic', active_pair_id: pair.id, current_component_index: 1})"
       *ngFor="let pair of (managerService.pair_pipe | managerPipe: managerService.mpa_template.GET_LISTED_PAIR_LIST)"
       [class.pair-box-shadow]="pair | managerPipe: managerService.mpa_template.CHECK_ACTIVE_PAIR: managerService.pair_pipe"
       class="m-2 card pair-card"
       style="height: 150px; width: 270px; border-radius: 4px; border: solid 1px #d5d8e2; background-color: #fcfdff;"
       type="button">
    <div class="d-flex flex-row align-items-center justify-content-start card-body">
      <div class="d-flex flex-row align-items-center justify-content-center" style="width: 100px!important;">
        <img
            [src]="(pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_IMAGE_DESKTOP)[0] | managerPipe: managerService.mpa_template.LOCAL_IMAGE_MANAGER"
            alt="art_product" #art_product
            style="max-width: 100px; max-height: 120px"
            (error)="art_product.src = art_product.src.replace('.webp', '.jpg');">
      </div>
      <span class="ml-2 exo header6 bold"
            style="color: #404352;">
        {{pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_SYMBOL}}</span>
      <span
        style="height: 28px; width: 1px; min-width: 1px; margin: auto 5px; border-radius:1px; background-color: #9da5b7;"></span>
      <div class="d-flex flex-column">
        <span [style]="pair.change | managerPipe: managerService.mpa_template.PAIR_CHANGE_COLOR">
          {{pair.price | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
        <span class="barlow overline regular"
          style=" color: #9da5b7;">{{pair.symbol | managerPipe}}</span>
      </div>
    </div>
  </div>
</div>
