import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
  selector: 'app-pre-sale-art-product-info-mobile',
  templateUrl: './pre-sale-art-product-info-mobile.component.html',
  styleUrls: ['./pre-sale-art-product-info-mobile.component.css'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class PreSaleArtProductInfoMobileComponent implements OnInit {

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

}
