import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../services/manager.service';

@Component({
  selector: 'app-withdrawal-confirmed-mobile',
  templateUrl: './withdrawal-confirmed-mobile.component.html',
  styleUrls: ['./withdrawal-confirmed-mobile.component.css']
})
export class WithdrawalConfirmedMobileComponent implements OnInit {

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

}
