<div [style]="managerService.show_sign_up_or_login | managerPipe: managerService.mpa_template.GET_MIN_BODY_HEIGHT_OBSOLETE"
     class="d-flex flex-column w-100 h-100 mx-3">
    <div class="w-100 d-flex flex-column"
         style="box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff; max-height: 150px; min-height: 150px; border-radius: 4px; margin-bottom: 10px;">
        <div class="my-3 ml-4"
             style="font-family: Roboto, sans-serif; font-size: 15px; line-height: 0.7; color: #404352;">
            {{managerService.userService.user?.email | managerPipe}}
        </div>
        <div class="my-2 ml-4"
             style="font-family: bebas-neue-regular, sans-serif;font-size: 40px; font-weight: bold; line-height: 1.2; letter-spacing: 4px; color: #404352;">
            {{managerService.config.ACCOUNT_AUCTION.HEADING | managerPipe}}
        </div>
        <div class="my-1 ml-4">
            <button (click)="managerService.active_account_auction_panel = managerService.active_account_auction_panel_template.MY_BIDS"
                    [class.btn-account-navigator-active]="managerService.active_account_auction_panel | managerPipe: managerService.mpa_template.ACTIVE_ACCOUNT_AUCTION_PANEL_MY_BIDS"
                    class="btn-native btn-account-navigator mr-5" type="button">{{managerService.config.ACCOUNT_AUCTION.MY_BIDS | managerPipe}}</button>
            <button (click)="managerService.active_account_auction_panel = managerService.active_account_auction_panel_template.MY_FOLLOWINGS"
                    [class.btn-account-navigator-active]="managerService.active_account_auction_panel | managerPipe: managerService.mpa_template.ACTIVE_ACCOUNT_AUCTION_PANEL_MY_FOLLOWINGS"
                    class="btn-native btn-account-navigator mr-5" type="button">{{managerService.config.ACCOUNT_AUCTION.MY_FOLLOWINGS | managerPipe}}</button>
            <button (click)="managerService.active_account_auction_panel = managerService.active_account_auction_panel_template.MY_WONS"
                    [class.btn-account-navigator-active]="managerService.active_account_auction_panel | managerPipe: managerService.mpa_template.ACTIVE_ACCOUNT_AUCTION_PANEL_MY_WONS"
                    class="btn-native btn-account-navigator mr-5" type="button">{{managerService.config.ACCOUNT_AUCTION.MY_WONS | managerPipe}}</button>
        </div>
    </div>
    <!--    Account kyc level check and show increase account level if account level is 0.-->
    <div *ngIf="((!managerService.logged_in) || (0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO)) | managerPipe"
         class="px-4" style="box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22);  background-color: #fcfdff; min-height: 650px">
        <div class="pt-3 mb-2" style="font-family: Roboto, sans-serif; font-size: 20px; font-weight: bold; line-height: 2; letter-spacing: 1px; color: #4a4e70;">
            {{managerService.config.DEPOSIT.NO_KYC_HEADING | managerPipe}}
        </div>
        <div class="my-2" style="font-family: Roboto, sans-serif; font-size: 14px; color: #4a4e70;">
            {{managerService.config.AUCTION.NO_KYC_DESCRIPTION | managerPipe}}
        </div>

        <button
            (click)="managerService.navigationManager({url: '/account/security', active_security_panel: managerService.active_security_panel_template.IDENTITY_VERIFICATION, current_component_index: 0});"
            class="btn-native sign-up-login-button mx-4 my-3"
            style="height: 40px; width:160px; border-radius: 20px;
              font-family: Roboto, sans-serif; font-size: 16px; line-height: 1.3; letter-spacing: 0.3px; color: #ffffff;">
            {{managerService.config.DASHBOARD.LEVEL_UP | managerPipe}}
        </button>
    </div>
    <div *ngIf="((managerService.logged_in) && !(0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO)) | managerPipe">
        <app-my-bids-desktop [class.d-none]="!(managerService.active_account_auction_panel | managerPipe: managerService.mpa_template.ACTIVE_ACCOUNT_AUCTION_PANEL_MY_BIDS)"></app-my-bids-desktop>
        <app-my-followings-desktop
            [class.d-none]="!(managerService.active_account_auction_panel | managerPipe: managerService.mpa_template.ACTIVE_ACCOUNT_AUCTION_PANEL_MY_FOLLOWINGS)"></app-my-followings-desktop>
        <app-my-wons-desktop [class.d-none]="!(managerService.active_account_auction_panel | managerPipe: managerService.mpa_template.ACTIVE_ACCOUNT_AUCTION_PANEL_MY_WONS)"></app-my-wons-desktop>
    </div>
</div>
