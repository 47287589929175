<div *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE" style="max-width: 300px;">
  <div class="pt-1 pb-2 px-2 w-100 d-flex flex-column align-items-center justify-content-center">
    <div class="px-2 pb-2 w-100 d-flex flex-row align-items-start justify-content-between"
         style="font-family: bebas-neue-regular, sans-serif;font-size: 17px; font-weight: bold; line-height: 1.2; letter-spacing: 2.5px;  color: #404352;">
      <div>{{managerService.config.WITHDRAWAL.RULES_TITLE | managerPipe}}</div>
      <div *ngIf="managerService.utilityService.timer_ready && managerService.modalService.isActive(modal_id) | managerPipe">
        {{120 | managerPipe: managerService.mpa_template.COUNTDOWN | async | managerPipe: managerService.mpa_template.CLOSE_MODAL: modal_id}}
      </div>
    </div>
    <div class="p-1" style="font-family: Roboto, sans-serif; font-size: 12px; line-height: 1.1; letter-spacing: 0.4px; color: #4a4e70;">
      <div *ngFor="let rule of managerService.config.WITHDRAWAL.FIAT_WITHDRAWAL_RULES | managerPipe; let last = last" class="mb-1"
           [style]="last | managerPipe: managerService.mpa_template.GET_WITHDRAWAL_RULES_STYLE">
        {{rule | managerPipe}}
      </div>
    </div>
    <label class="p-1 align-self-start  d-flex flex-row align-items-start justify-content-start checkbox-text-black">
      <input #checkbox_field_desktop class="checkbox-field" style="height: 20px; min-width: 20px;" type="checkbox">
      <span class="mx-2 my-1">{{ managerService.config.BALANCE.READ_ACCEPTED | managerPipe}}</span>
    </label>
    <div class="w-100 d-flex flex-column px-1 py-3" style="background-color: #f4f5f9">
      <div class="w-100 d-flex flex-column px-1"
           style="background-color: #f4f5f9; font-family: Roboto, sans-serif; font-size: 10px; font-weight: bold; line-height: 1.9; letter-spacing: 0.85px; color: #404352;">
        <div style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: bold; line-height: 1.8; letter-spacing: 1.4px; color: #404352;">
          {{managerService.config.WITHDRAWAL.WITHDRAWAL_REQUEST_SUM_HEADING | managerPipe}}
        </div>
        <div class="m-1 d-flex flex-row align-content-center justify-content-between">
          <div>
            {{managerService.config.WITHDRAWAL.WITHDRAWAL_AMOUNT | managerPipe}}
          </div>
          <div>
            {{this.managerService.operationsService.requested_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}₺
          </div>
        </div>
        <div class="m-1 d-flex flex-row align-content-center justify-content-between">
          <div>
            {{managerService.config.WITHDRAWAL.WITHDRAWAL_FEE | managerPipe}}
          </div>
          <div>
            {{managerService.operationsService.transfer_fee | managerPipe: managerService.mpa_template.FANCY_NUMBER}}₺
          </div>
        </div>
        <div class="m-1 d-flex flex-row align-content-center justify-content-between">
          <div>
            {{managerService.config.WITHDRAWAL.WITHDRAWAL_SUM | managerPipe}}
          </div>
          <div class="request-summary-content">
            {{this.managerService.operationsService.requested_amount - managerService.operationsService.transfer_fee | managerPipe: managerService.mpa_template.FANCY_NUMBER}}₺
          </div>
        </div>
        <div class="m-1 d-flex flex-row align-content-center justify-content-between">
          <div>
            {{managerService.config.WITHDRAWAL.WITHDRAWAL_ACCOUNT | managerPipe}}
          </div>
          <div>
            {{this.managerService.operationsService.selected_user_bank_account_pipe | managerPipe: managerService.mpa_template.GET_WITHDRAWAL_ACCOUNT_INFO}}
          </div>
        </div>
      </div>
      <div class="w-100 d-flex flex-row">
        <button
                (click)="managerService.operationsService.sign_withdrawal_request_data.status='cancel'; managerService.closeFiatWithdrawalRequestModal(); checkbox_field_desktop.checked = false; managerService.operationsService.bank_withdrawal_request_form_submitted.emit();"
                class="btn-native checkbox-reject-button">
          {{managerService.config.ADVANCED.USER_OPEN_ORDERS.CANCEL | managerPipe}}
        </button>
        <button
                (click)="onAccept()"
                [class.button-inactive]="!checkbox_field_desktop.checked"
                [disabled]="!checkbox_field_desktop.checked"
                class="btn-native checkbox-button">
          {{managerService.config.BALANCE.NEXT | managerPipe}}
        </button>
      </div>
    </div>
  </div>
</div>
