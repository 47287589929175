import {Component, OnDestroy, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';
import {AuctionProduct} from '../../../../../../services/auction.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-my-followings-mobile',
    templateUrl: './my-followings-mobile.component.html',
    styleUrls: ['./my-followings-mobile.component.css']
})
export class MyFollowingsMobileComponent implements OnInit, OnDestroy {
    favorite_auction_product_id_list: number[] = [];
    user_following_auction_products: AuctionProduct[] = [];

    subscription_list: Subscription[] = [];

    constructor(public managerService: ManagerService) {
    }

    ngOnInit(): void {
        if (!this.managerService.userService.user) {
            return;
        }
        this.favorite_auction_product_id_list = this.managerService.userService.user?.auction_product_list;
        this.user_following_auction_products = this.getUserFollowingAuctionProducts();
        this.subscription_list.push(this.managerService.auctionService.auction_product_list_changed.subscribe(() => {
            this.user_following_auction_products = this.getUserFollowingAuctionProducts();
        }));
    }

    getUserFollowingAuctionProducts(): AuctionProduct[] {
        if (!this.managerService.userService.user_ready) {
            return [];
        }
        return this.managerService.auctionService.auction_product_list.getAuctionProductArray().filter(product => {
            return !product.isFinished() && this.favorite_auction_product_id_list.includes(product.id);
        }).sort((a, b) => {
            return a.id - b.id;
        });
    }

    toggleFollowedProduct(product: any, open: HTMLDivElement, close: HTMLDivElement): void {
        this.managerService.setUserFavoriteAuctionProduct(product);
        open.style.display = 'flex';
        close.style.display = 'none';
    }

    ngOnDestroy(): void {
        this.subscription_list.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

}
