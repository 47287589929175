<div *ngIf="((!managerService.logged_in) || !(0 | managerPipe: managerService.mpa_template.CHECK_USER_DEPOSIT_HISTORY_LENGTH_GREATER_THAN: managerService.user_currency_operation_history_pipe) || !(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO)) | managerPipe"
     class="w-100 pe-5 d-flex flex-column align-items-center justify-content-start pb-3" style="box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22);  background-color: #fcfdff; min-height: 650px">

  <div class="w-100 mt-4 pl-5" style="font-family: Roboto, sans-serif; font-size: 14px; font-weight: bold; line-height: 2; letter-spacing: 1px; color: #4a4e70;">
    {{managerService.config.DEPOSIT.PREREQUIREMENTS | managerPipe}}
  </div>
  <div class="w-100 pl-5">
    <div class="mt-3 pl-3 py-2" style="background-color: #f4f5f9; font-family: Roboto, sans-serif; font-size: 12px; font-style: italic;
      line-height: 1.75; letter-spacing: 0.35px; color: #4a4e70;">
      <div *ngFor="let rule of managerService.config.DEPOSIT.CRYPTO_DEPOSIT_PREREQUIREMENTS | managerPipe" class="w-80">
        {{rule | managerPipe}}
      </div>
    </div>
  </div>

  <div *ngIf="!(2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO)"
       class="w-100 mt-5 pl-5 d-flex flex-column align-items-start justify-content-start" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500; line-height: 2.5; letter-spacing: 0.84px; color: #595e7e;">
    <span class="w-100" style="border-top: solid 1px #d5d8e2;"></span>
    <div class="mt-4">{{managerService.config.ACCOUNT_LEVEL.HEADING | managerPipe}}</div>
    <div  class="w-100 d-flex flex-row align-items-center justify-content-between mb-4">
      <div class="w-33 text-left">{{managerService.user_pipe | managerPipe: managerService.mpa_template.GET_USER_ACCOUNT_LEVEL}} / 2</div>
      <div class="w-33 text-center">{{managerService.config.ACCOUNT_LEVEL.LEVEL_2_REQUIRED | managerPipe}}</div>
      <div class="w-33 text-right">
        <button (click)="managerService.navigationManager({url: '/account/security', active_security_panel: managerService.active_security_panel_template.IDENTITY_VERIFICATION, current_component_index: 0});"
                class="btn-native sign-up-login-button mx-4"
                style="height: 40px; width:160px; border-radius: 6px;
              font-family: Roboto, sans-serif; font-size: 16px; line-height: 1.3; letter-spacing: 0.3px; color: #ffffff;">
          {{managerService.config.DASHBOARD.LEVEL_UP | managerPipe}}
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO"
       class="w-100 mt-5 pl-5 d-flex flex-column align-items-start justify-content-start" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500; line-height: 2.5; letter-spacing: 0.84px; color: #595e7e;">
    <span class="w-100" style="border-top: solid 1px #d5d8e2;"></span>
    <div class="mt-4">{{managerService.config.ACCOUNT_LEVEL.HEADING | managerPipe}}</div>
    <div class="w-100 d-flex flex-row align-items-center justify-content-between mb-4">
      <div class="w-33 text-left">{{managerService.user_pipe | managerPipe: managerService.mpa_template.GET_USER_ACCOUNT_LEVEL}} / 2</div>
      <div class="w-33 text-center">{{managerService.config.ACCOUNT_LEVEL.LEVEL_2_VALID | managerPipe}}</div>
      <div class="w-33 text-right">
        <svg class="mx-4" style="height: 20px; width: 160px;">
          <use [attr.xlink:href]='"./assets/images/tick.svg#tick"' fill="#0ad5a0"/>
        </svg>
      </div>
    </div>
  </div>

  <div *ngIf="!(0 | managerPipe: managerService.mpa_template.CHECK_USER_DEPOSIT_HISTORY_LENGTH_GREATER_THAN: managerService.user_currency_operation_history_pipe)"
       class="w-100 pl-5 d-flex flex-column align-items-start justify-content-start" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500; line-height: 2.5; letter-spacing: 0.84px; color: #595e7e;">
    <span class="w-100" style="border-top: solid 1px #d5d8e2;"></span>
    <div class="mt-4">{{managerService.config.DEPOSIT.TL_DEPOSIT_HISTORY | managerPipe}}</div>
    <div class="w-100 d-flex flex-row align-items-center justify-content-between mb-4">
      <div class="w-33 text-left">{{managerService.config.DEPOSIT.TL_DEPOSIT_HISTORY_INVALID | managerPipe}}</div>
      <div class="w-33 text-center">{{managerService.config.DEPOSIT.TL_DEPOSIT_REQUIRED | managerPipe}}</div>
      <div class="w-33 text-right">
        <button (click)="managerService.navigationService.active_deposit_page = managerService.navigationService.deposit_page_template.TURKISH_LIRA"
                class="btn-native sign-up-login-button mx-4"
                style="height: 40px; width:160px; border-radius: 6px;
              font-family: Roboto, sans-serif; font-size: 16px; line-height: 1.3; letter-spacing: 0.3px; color: #ffffff;">
          {{managerService.config.DEPOSIT.DEPOSIT_NOW | managerPipe}}
        </button>
      </div>
    </div>
    <span class="w-100" style="border-top: solid 1px #d5d8e2;"></span>
  </div>

  <div *ngIf="0 | managerPipe: managerService.mpa_template.CHECK_USER_DEPOSIT_HISTORY_LENGTH_GREATER_THAN: managerService.user_currency_operation_history_pipe"
       class="w-100 pl-5 d-flex flex-column align-items-start justify-content-start" style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500; line-height: 2.5; letter-spacing: 0.84px; color: #595e7e;">
    <span class="w-100" style="border-top: solid 1px #d5d8e2;"></span>
    <div class="mt-4">{{managerService.config.DEPOSIT.TL_DEPOSIT_HISTORY | managerPipe}}</div>
    <div  class="w-100 d-flex flex-row align-items-center justify-content-between mb-4">
      <div class="w-33 text-left">{{managerService.config.DEPOSIT.TL_DEPOSIT_HISTORY_VALID | managerPipe}}</div>
      <div class="w-33 text-center">{{managerService.config.DEPOSIT.TL_DEPOSIT_ACCOMPLISHED | managerPipe}}</div>
      <div class="w-33 text-right">
        <svg class="mx-4" style="height: 20px; width: 160px;">
          <use [attr.xlink:href]='"./assets/images/tick.svg#tick"' fill="#0ad5a0"/>
        </svg>
      </div>
    </div>
    <span class="w-100" style="border-top: solid 1px #d5d8e2;"></span>
  </div>

</div>

<div *ngIf="(managerService.logged_in && (0 | managerPipe: managerService.mpa_template.CHECK_USER_DEPOSIT_HISTORY_LENGTH_GREATER_THAN: managerService.user_currency_operation_history_pipe) && (2 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO)) | managerPipe">
  <div *ngIf="!managerService.config.DEPOSIT.TETHER_SUSPENSION.SUSPENDED | managerPipe"
       class="w-100 d-flex flex-column align-items-start justify-content-start pb-3"
       style="box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff; max-height: 1160px; border-radius: 4px;">
    <div class="w-100 mt-4 ml-5"
         style="font-family: Roboto, sans-serif; font-size: 14px; font-weight: bold; line-height: 2; letter-spacing: 1px; color: #4a4e70;">
      {{managerService.config.DEPOSIT.LIMITS | managerPipe}}
    </div>
    <div class="d-flex flex-row align-items-start justify-content-start mt-3 ml-5"
         style="box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff; border-radius: 4px; margin-bottom: 10px;">
      <div class="d-flex flex-column m-3">
        <span class="my-1 text-center"
              style="font-family: 'Roboto Condensed', sans-serif; font-size: 13px; line-height: 1.25; color: #272a3b; letter-spacing: 1px">
          {{managerService.config.DEPOSIT.DAILY_DEPOSIT_LIMIT | managerPipe}}
        </span>
        <span class="m-1 text-center"
              style="font-family: 'Roboto Condensed', sans-serif; font-size: 14px; line-height: 1.25; color: #272a3b; letter-spacing: 1px">
          {{managerService.userService.user?.daily_crypto_deposit_limit | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
          USDT
        </span>
      </div>
      <span
          style="height: 40px; width: 1px; min-width: 2px; margin: auto 10px; border-radius:1px; background-color: #dddddd;"></span>
      <div class="h-100 d-flex flex-column m-3">
        <span class="my-1 text-center"
              style="font-family: 'Roboto Condensed', sans-serif; font-size: 13px; line-height: 1.25; color: #272a3b; letter-spacing: 1px">
          {{managerService.config.DEPOSIT.MONTHLY_DEPOSIT_LIMIT | managerPipe}}
        </span>
        <span class="m-1 text-center"
              style="font-family: 'Roboto Condensed', sans-serif; font-size: 13px; line-height: 1.25; color: #272a3b; letter-spacing: 1px">
          {{managerService.userService.user?.monthly_crypto_deposit_limit | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
          USDT
        </span>
      </div>
    </div>

    <div class="w-100 mt-4 ml-5" style="font-family: Roboto, sans-serif; font-size: 14px; font-weight: bold; line-height: 2; letter-spacing: 1px; color: #4a4e70;">
      2. {{managerService.config.DEPOSIT.RULES_TITLE}}
    </div>
    <div class="w-100 pl-5" >
      <div class="mt-3 pl-3 py-2" style="background-color: #f4f5f9; font-family: Roboto, sans-serif; font-size: 12px; font-style: italic;
        line-height: 1.75; letter-spacing: 0.35px; color: #4a4e70;">
        <div *ngFor="let rule of managerService.config.DEPOSIT.CRYPTO_DEPOSIT_RULES | managerPipe" class="w-80">
          {{rule | managerPipe}}
        </div>
      </div>
    </div>

    <div class="w-100 mt-4 ml-5" style="font-family: Roboto, sans-serif; font-size: 14px; font-weight: bold; line-height: 2; letter-spacing: 1px; color: #4a4e70;">
      3. {{managerService.config.DEPOSIT.TRANSACTION}}
    </div>
    <div class="d-flex flex-row align-items-start justify-content-start mt-3 ml-5">
      <div (click)="selectCurrencyWallet(crypto_wallet)"
           *ngIf="crypto_wallet"
           [class.inactive-crypto-account]="((crypto_wallet !== managerService.operationsService.selected_user_currency_wallet)) && managerService.operationsService.crypto_deposit_rules_accepted_status | managerPipe"
           class="d-flex flex-column p-3 mx-3"
           style="border-radius: 4px; box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22);">
        <img [src]="crypto_wallet | managerPipe: managerService.mpa_template.GET_CRYPTO_TOKEN_IMG_SRC" alt="crypto_account" width="150px" #crypto_account
             (error)="crypto_account.src = crypto_account.src.replace('.webp', '.jpg')">
      </div>
    </div>

    <div *ngIf="managerService.operationsService.crypto_deposit_rules_accepted_status | managerPipe" class="w-100 mt-5 ml-5 d-flex flex-column"
         style="font-family: Roboto, sans-serif; font-size: 14px; line-height: 1.6; letter-spacing: 0.4px; color: #404352;">
      <div class="m-1" style="font-size: 13px; letter-spacing: 1.3px">
        {{managerService.pairService.currency_list[managerService.operationsService.selected_user_currency_wallet.currency_id].name| managerPipe}} {{managerService.config.DEPOSIT.WALLET_OF| managerPipe}}
      </div>
      <div class="m-1">
        <span class="font-weight-bold">{{managerService.config.DEPOSIT.ACCOUNT_ADDRESS}}:</span><span class="mx-1">{{managerService.operationsService.selected_user_currency_wallet.address}}</span>
        <button (click)="copyAddress()" class="mx-2 btn-native">
          <svg style="height: 20px; width: 20px;">
            <use [attr.xlink:href]='"./assets/images/copy.svg#copy"' fill="#595e7e"/>
          </svg>
        </button>
      </div>
      <div class="m-1 text-info">
        <svg style="height: 14px; width: 14px;">
          <use [attr.xlink:href]='"./assets/images/info.svg#info"' fill="#17a2b8"/>
        </svg>
        {{managerService.config.DEPOSIT.DEPOSIT_WARNING}}
      </div>
    </div>
  </div>

  <div *ngIf="managerService.config.DEPOSIT.TETHER_SUSPENSION.SUSPENDED | managerPipe"
       class="w-100 pt-1 pb-3"
       style="height: 400px; box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff; max-height: 1160px; border-radius: 4px;">
    <div class="w-100 mt-4 ml-5"
         style="font-family: Roboto, sans-serif; font-size: 14px; font-weight: bold; line-height: 2; letter-spacing: 1px; color: #4a4e70;"
         [innerHTML]="managerService.config.DEPOSIT.TETHER_SUSPENSION.TITLE | managerPipe">
    </div>

    <div class="w-100 mt-3 ml-5"
         style="font-family: Roboto, sans-serif; font-size: 12px; line-height: 1.7; color: #272a3b;"
         [innerHTML]="managerService.config.DEPOSIT.TETHER_SUSPENSION.DESCRIPTION | managerPipe">
    </div>
  </div>
</div>

<app-deposit-history-desktop [currency_type]="'crypto'" [currency_id]="1"></app-deposit-history-desktop>
