<h1 style="padding-top: 4pt;padding-left: 117pt;text-indent: 0pt;text-align: center;">KİŞİSEL VERİ SAKLAMA VE İMHA POLİTİKASI</h1>
<p style="text-indent: 0pt;text-align: left;"><br/></p>
<ol id="l1">
    <li data-list-text="1."><p style="padding-left: 41pt;text-indent: -25pt;line-height: 112%;text-align: justify;">Bu politikanın amacı, tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla işlenen kişisel verilerin silinmesi, yok edilmesi veya anonim hale getirilmesine ilişkin usul ve esasları belirlemektir.</p>
    </li>
    <li data-list-text="2."><p style="padding-top: 10pt;padding-left: 41pt;text-indent: -27pt;line-height: 112%;text-align: justify;">Bu politika; 6698 sayılı Kanunun 7 nci maddesinin üçüncü fıkrası ile 22 nci
        maddesinin birinci fıkrasının (e) bendine dayanılarak hazırlanmış Kişisel Verilerin Silinmesi, Yok edilmesi veya Anonim hale Yönetmeliğine uygun
        olarak hazırlanmıştır.</p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
    <li data-list-text="3."><p style="padding-left: 41pt;text-indent: -27pt;line-height: 112%;text-align: left;">Şirket; Kişisel veri işleme envanterine uygun olarak bu kişisel veri saklama ve imha
        politikasını hazırlamıştır.</p></li>
    <li data-list-text="4."><h1 style="padding-top: 10pt;padding-left: 41pt;text-indent: -28pt;text-align: left;">Tanımlar</h1>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <ol id="l2">
            <li data-list-text="4.1."><h1 style="padding-left: 77pt;text-indent: -35pt;line-height: 112%;text-align: justify;">Alıcı grubu: <span class="p">Veri sorumlusu tarafından kişisel verilerin aktarıldığı gerçek veya tüzel kişi kategorisidir.</span>
            </h1></li>
            <li data-list-text="4.2."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -37pt;line-height: 112%;text-align: justify;">İlgili kullanıcı: <span class="p">Verilerin  teknik  olarak  depolanması,  korunması  ve yedeklenmesinden sorumlu olan kişi ya da birim hariç olmak üzere veri sorumlusu organizasyonu içerisinde veya veri sorumlusundan aldığı yetki ve talimat doğrultusunda kişisel verileri işleyen kişileridir</span>
            </h1></li>
            <li data-list-text="4.3."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -37pt;line-height: 112%;text-align: justify;">İmha: <span class="p">Kişisel verilerin silinmesi, yok edilmesi veya anonim hale getirilmesi işlemidir.</span>
            </h1></li>
            <li data-list-text="4.4."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -39pt;line-height: 112%;text-align: justify;">Kayıt ortamı: <span class="p">Tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla işlenen kişisel verilerin bulunduğu her türlü ortamı ifade eder.</span>
            </h1></li>
            <li data-list-text="4.5."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -37pt;line-height: 112%;text-align: justify;">Kişisel veri işleme envanteri: <span class="p">Veri sorumlularının iş süreçlerine bağlı olarak gerçekleştirmekte oldukları kişisel verileri işleme faaliyetlerini; kişisel verileri işleme amaçları, veri kategorisi, aktarılan alıcı grubu ve veri konusu kişi grubuyla ilişkilendirerek oluşturdukları ve kişisel verilerin işlendikleri amaçlar için gerekli olan azami süreyi, yabancı ülkelere aktarımı  öngörülen  kişisel  verileri ve veri güvenliğine ilişkin alınan tedbirleri açıklayarak detaylandırdıkları envanterdir.</span>
            </h1></li>
            <li data-list-text="4.6."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -38pt;line-height: 112%;text-align: justify;">Kişisel veri saklama ve imha politikası: <span
                class="p">Veri sorumlularının, kişisel verilerin işlendikleri amaç için gerekli olan azami süreyi belirleme işlemi ile silme, yok etme ve anonim hale getirme işlemi için dayanak yaptıkları politikadır.</span>
            </h1></li>
            <li data-list-text="4.7."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -38pt;line-height: 112%;text-align: justify;">Periyodik imha: <span class="p">Kanunda yer alan kişisel verilerin işlenme şartlarının tamamının ortadan kalkması durumunda kişisel verileri saklama ve imha</span>
            </h1>
                <p style="padding-top: 3pt;padding-left: 77pt;text-indent: 0pt;line-height: 112%;text-align: left;">politikasında belirtilen ve tekrar eden aralıklarla resen gerçekleştirilecek silme,
                    yok etme veya anonim hale getirme işlemini ifade eder.</p></li>
            <li data-list-text="4.8."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -38pt;line-height: 112%;text-align: justify;">Sicil: <span class="p">Kişisel Verileri Koruma Kurumu Başkanlığı tarafından tutulan veri sorumluları sicilini ifade eder.</span>
            </h1></li>
            <li data-list-text="4.9."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -38pt;line-height: 112%;text-align: justify;">Veri kayıt sistemi: <span class="p">Kişisel verilerin belirli kriterlere göre yapılandırılarak işlendiği kayıt sistemini ifade eder.</span>
            </h1></li>
            <li data-list-text="4.10."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -43pt;line-height: 112%;text-align: justify;">Veri sorumlusu: <span class="p">Kişisel  verilerin  işleme  amaçlarını ve vasıtalarını belirleyen,  veri  kayıt  sisteminin  kurulmasından  ve  yönetilmesinden sorumlu olan gerçek veya tüzel kişiyi ifade eder.</span>
            </h1></li>
            <li data-list-text="4.11."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -40pt;line-height: 112%;text-align: justify;">Kişisel verilerin silinmesi <span class="p">Kişisel verilerin silinmesi, kişisel verilerin ilgili kullanıcılar için hiçbir şekilde erişilemez ve tekrar kullanılamaz hale getirilmesi işlemidir.</span>
            </h1></li>
            <li data-list-text="4.12."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -42pt;line-height: 112%;text-align: justify;">Kişisel verilerin yok edilmesi <span class="p">Kişisel verilerin yok edilmesi, kişisel verilerin hiç kimse tarafından hiçbir şekilde erişilemez, geri getirilemez ve tekrar kullanılamaz hale getirilmesi işlemidir.</span>
            </h1></li>
            <li data-list-text="4.13."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -42pt;line-height: 112%;text-align: justify;">Kişisel verilerin anonim hale getirilmesi <span
                class="p">Kişisel verilerin başka verilerle eşleştirilse dahi hiçbir surette kimliği belirli veya belirlenebilir bir gerçek kişiyle ilişkilendirilemeyecek hale getirilmesidir. Kişisel verilerin anonim hale getirilmiş olması için; kişisel verilerin, veri sorumlusu, alıcı veya alıcı grupları tarafından geri döndürme ve verilerin başka verilerle eşleştirilmesi gibi kayıt ortamı ve ilgili faaliyet alanı açısından uygun tekniklerin kullanılması yoluyla dahi kimliği belirli veya belirlenebilir bir gerçek kişiyle ilişkilendirilemez hale getirilmesi gerekir.</span>
            </h1></li>
        </ol>
    </li>
    <li data-list-text="5."><h1 style="padding-top: 10pt;padding-left: 41pt;text-indent: -27pt;text-align: left;">Kişisel veri saklama ve imha politikası ile düzenlenen kayıt ortamları :</h1>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <ol id="l3">
            <li data-list-text="5.1."><p style="padding-left: 77pt;text-indent: -34pt;text-align: left;">Kağıt ortamlar</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <ol id="l4">
                    <li data-list-text="5.1.1."><p style="padding-left: 113pt;text-indent: -41pt;text-align: left;">Kağıt</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="5.1.2."><p style="padding-left: 113pt;text-indent: -43pt;text-align: left;">Manuel veri kayıt sistemleri (formlar ziyaretçi giriş defteri)</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="5.1.3."><p style="padding-left: 116pt;text-indent: -46pt;text-align: left;">Yazılı, basılı, görsel ortamlar</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                </ol>
            </li>
            <li data-list-text="5.2."><p style="padding-left: 77pt;text-indent: -36pt;text-align: left;">Elektronik ortamlar</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <ol id="l5">
                    <li data-list-text="5.2.1."><p style="padding-left: 113pt;text-indent: -43pt;text-align: left;">Sunucular (Etki alanı, yedekleme, e-posta,</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="5.2.2."><p style="padding-left: 113pt;text-indent: -45pt;text-align: left;">veritabanı, web, dosya paylaşım, vb.)</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="5.2.3."><p style="padding-left: 113pt;text-indent: -45pt;text-align: left;">Yazılımlar</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="5.2.4."><p style="padding-left: 113pt;text-indent: -47pt;line-height: 112%;text-align: left;">Bilgi güvenliği cihazları (güvenlik duvarı, saldırı tespit ve
                        engelleme, günlük kayıt dosyası, antivirüs vb. )</p></li>
                    <li data-list-text="5.2.5."><p style="padding-top: 3pt;padding-left: 116pt;text-indent: -48pt;text-align: left;">Kişisel bilgisayarlar (Masaüstü, dizüstü)</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="5.2.6."><p style="padding-left: 113pt;text-indent: -46pt;text-align: left;">Mobil cihazlar (telefon, tablet vb.)</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="5.2.7."><p style="padding-left: 116pt;text-indent: -49pt;text-align: left;">Optik diskler (CD, DVD vb.)</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="5.2.8."><p style="padding-left: 116pt;text-indent: -49pt;text-align: left;">Çıkartılabilir bellekler (USB, Hafıza Kart vb.)</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="5.2.9."><p style="padding-left: 113pt;text-indent: -46pt;text-align: left;">Yazıcı, tarayıcı, fotokopi makinesi</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                </ol>
            </li>
        </ol>
    </li>
    <li data-list-text="6."><h1 style="padding-left: 41pt;text-indent: -27pt;text-align: left;">Saklamayı Gerektiren Hukuki Sebepler</h1>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <ol id="l6">
            <li data-list-text="6.1."><p style="padding-left: 80pt;text-indent: -36pt;text-align: left;">6698 sayılı Kişisel Verilerin Korunması Kanunu,</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="6.2."><p style="padding-left: 77pt;text-indent: -35pt;text-align: left;">6098 sayılı Türk Borçlar Kanunu,</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="6.3."><p style="padding-left: 77pt;text-indent: -35pt;text-align: left;">4734 sayılı Kamu İhale Kanunu,</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="6.4."><p style="padding-left: 77pt;text-indent: -36pt;text-align: left;">657 sayılı Devlet Memurları Kanunu,</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="6.5."><p style="padding-left: 77pt;text-indent: -35pt;text-align: left;">5510 sayılı Sosyal Sigortalar ve Genel Sağlık Sigortası Kanunu,</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="6.6."><p style="padding-left: 77pt;text-indent: -36pt;line-height: 112%;text-align: left;">5651 sayılı İnternet Ortamında Yapılan Yayınların Düzenlenmesi ve Bu
                Yayınlar</p></li>
            <li data-list-text="6.7."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -35pt;text-align: left;">Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkında Kanun,</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="6.8."><p style="padding-left: 77pt;text-indent: -36pt;text-align: left;">5018 sayılı Kamu Mali Yönetimi Kanunu,</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="6.9."><p style="padding-left: 77pt;text-indent: -35pt;text-align: left;">6331 sayılı İş Sağlığı ve Güvenliği Kanunu,</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="6.10."><p style="padding-left: 77pt;text-indent: -40pt;text-align: left;">4982 Sayılı Bilgi Edinme Kanunu,</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="6.11."><p style="padding-left: 77pt;text-indent: -37pt;text-align: left;">3071 sayılı Dilekçe Hakkının Kullanılmasına Dair Kanun</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="6.12."><p style="padding-left: 80pt;text-indent: -42pt;text-align: left;">4857 sayılı İş Kanunu,</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="6.13."><p style="padding-left: 80pt;text-indent: -42pt;text-align: left;">2547 sayılı Yükseköğretim Kanunu,</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="6.14."><p style="padding-left: 77pt;text-indent: -40pt;text-align: left;">5434 sayılı Emekli Sağlığı Kanunu,</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="6.15."><p style="padding-left: 77pt;text-indent: -39pt;text-align: left;">2828 sayılı Sosyal Hizmetler Kanunu</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="6.16."><p class="s1" style="padding-left: 77pt;text-indent: -40pt;line-height: 112%;text-align: left;"><span class="p">İşyeri Bina ve Eklentilerinde Alınacak Sağlık ve Güvenlik Önlemlerine İlişkin Yönetmelik,</span>
            </p></li>
            <li data-list-text="6.17."><p style="padding-top: 10pt;padding-left: 80pt;text-indent: -42pt;text-align: left;">Arşiv Hizmetleri Hakkında Yönetmelik</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="6.18."><p style="padding-left: 41pt;text-indent: -4pt;line-height: 190%;text-align: left;">Bu kanunlar uyarınca yürürlükte olan diğer ikincil düzenlemeler çerçevesinde
                öngörülen saklama süreleri kadar saklanmaktadır.</p></li>
        </ol>
    </li>
    <li data-list-text="7."><h1 style="padding-left: 41pt;text-indent: -27pt;text-align: left;">Saklamayı Gerektiren İşleme Amaçları</h1>
        <ol id="l7">
            <li data-list-text="7.1."><p style="padding-top: 3pt;padding-left: 77pt;text-indent: -34pt;text-align: left;">Acil Durum Yönetimi Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.2."><p style="padding-left: 77pt;text-indent: -36pt;text-align: left;">Bilgi Güvenliği Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.3."><p style="padding-left: 77pt;text-indent: -37pt;line-height: 112%;text-align: left;">Çalışan Adayı / Stajyer / Öğrenci Seçme Ve Yerleştirme Süreçlerinin
                Yürütülmesi</p></li>
            <li data-list-text="7.4."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -37pt;text-align: left;">Çalışan Adaylarının Başvuru Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.5."><p style="padding-left: 77pt;text-indent: -36pt;text-align: left;">Çalışan Memnuniyeti Ve Bağlılığı Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.6."><p style="padding-left: 77pt;text-indent: -37pt;line-height: 112%;text-align: left;">Çalışanlar İçin İş Akdi Ve Mevzuattan Kaynaklı Yükümlülüklerin Yerine
                Getirilmesi</p></li>
            <li data-list-text="7.7."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -37pt;text-align: left;">Çalışanlar İçin Yan Haklar Ve Menfaatleri Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.8."><p style="padding-left: 77pt;text-indent: -37pt;text-align: left;">Denetim / Etik Faaliyetlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.9."><p style="padding-left: 77pt;text-indent: -37pt;text-align: left;">Eğitim Faaliyetlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.10."><p style="padding-left: 77pt;text-indent: -42pt;text-align: left;">Erişim Yetkilerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.11."><p style="padding-left: 77pt;text-indent: -38pt;text-align: left;">Faaliyetlerin Mevzuata Uygun Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.12."><p style="padding-left: 77pt;text-indent: -41pt;text-align: left;">Finans Ve Muhasebe İşlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.13."><p style="padding-left: 77pt;text-indent: -41pt;text-align: left;">Firma / Ürün / Hizmetlere Bağlılık Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.14."><p style="padding-left: 77pt;text-indent: -42pt;text-align: left;">Fiziksel Mekan Güvenliğinin Temini</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.15."><p style="padding-left: 77pt;text-indent: -41pt;text-align: left;">Görevlendirme Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.16."><p style="padding-left: 77pt;text-indent: -41pt;text-align: left;">Hukuk İşlerinin Takibi Ve Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.17."><p style="padding-left: 77pt;text-indent: -41pt;text-align: left;">İç Denetim/ Soruşturma / İstihbarat Faaliyetlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.18."><p style="padding-left: 77pt;text-indent: -41pt;text-align: left;">İletişim Faaliyetlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.19."><p style="padding-left: 77pt;text-indent: -41pt;text-align: left;">İnsan Kaynakları Süreçlerinin Planlanması</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.20."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">İş Faaliyetlerinin Yürütülmesi / Denetimi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.21."><p style="padding-left: 77pt;text-indent: -41pt;text-align: left;">İş Sağlığı / Güvenliği Faaliyetlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.22."><p style="padding-left: 77pt;text-indent: -43pt;line-height: 112%;text-align: left;">İş Süreçlerinin İyileştirilmesine Yönelik Önerilerin Alınması Ve
                Değerlendirilmesi</p></li>
            <li data-list-text="7.23."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -43pt;text-align: left;">İş Sürekliliğinin Sağlanması Faaliyetlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.24."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">Lojistik Faaliyetlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.25."><p style="padding-left: 77pt;text-indent: -43pt;text-align: left;">Mal / Hizmet Satın Alım Süreçlerinin Yürütülmesi</p></li>
            <li data-list-text="7.26."><p style="padding-top: 3pt;padding-left: 77pt;text-indent: -43pt;text-align: left;">Mal / Hizmet Satış Sonrası Destek Hizmetlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.27."><p style="padding-left: 77pt;text-indent: -43pt;text-align: left;">Mal / Hizmet Satış Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.28."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">Mal / Hizmet Üretim Ve Operasyon Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.29."><p style="padding-left: 77pt;text-indent: -43pt;text-align: left;">Müşteri İlişkileri Yönetimi Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.30."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">Müşteri Memnuniyetine Yönelik Aktivitelerin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.31."><p style="padding-left: 77pt;text-indent: -41pt;text-align: left;">Organizasyon Ve Etkinlik Yönetimi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.32."><p style="padding-left: 77pt;text-indent: -43pt;text-align: left;">Pazarlama Analiz Çalışmalarının Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.33."><p style="padding-left: 77pt;text-indent: -43pt;text-align: left;">Performans Değerlendirme Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.34."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">Reklam / Kampanya / Promosyon Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.35."><p style="padding-left: 77pt;text-indent: -43pt;text-align: left;">Risk Yönetimi Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.36."><p style="padding-left: 77pt;text-indent: -43pt;text-align: left;">Saklama Ve Arşiv Faaliyetlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.37."><p style="padding-left: 77pt;text-indent: -43pt;text-align: left;">Sosyal Sorumluluk Ve Sivil Toplum Aktivitelerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.38."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">Sözleşme Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.39."><p style="padding-left: 77pt;text-indent: -43pt;text-align: left;">Sponsorluk Faaliyetlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.40."><p style="padding-left: 77pt;text-indent: -45pt;text-align: left;">Stratejik Planlama Faaliyetlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.41."><p style="padding-left: 77pt;text-indent: -42pt;text-align: left;">Talep / Şikayetlerin Takibi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.42."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">Taşınır Mal Ve Kaynakların Güvenliğinin Temini</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.43."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">Tedarik Zinciri Yönetimi Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.44."><p style="padding-left: 77pt;text-indent: -45pt;text-align: left;">Ücret Politikasının Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.45."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">Ürün / Hizmetlerin Pazarlama Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.46."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">Veri Sorumlusu Operasyonlarının Güvenliğinin Temini</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.47."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">Yabancı Personel Çalışma Ve Oturma İzni İşlemleri</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.48."><p style="padding-left: 77pt;text-indent: -45pt;text-align: left;">Yatırım Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.49."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">Yetenek / Kariyer Gelişimi Faaliyetlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.50."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">Yetkili Kişi, Kurum Ve Kuruluşlara Bilgi Verilmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.51."><p style="padding-left: 77pt;text-indent: -41pt;text-align: left;">Yönetim Faaliyetlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.52."><p style="padding-left: 77pt;text-indent: -43pt;text-align: left;">Ziyaretçi Kayıtlarının Oluşturulması Ve Takibi</p></li>
        </ol>
    </li>
    <li data-list-text="8."><h1 style="padding-top: 3pt;padding-left: 41pt;text-indent: -28pt;text-align: left;">İmhayı Gerektiren Sebepler</h1>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <ol id="l8">
            <li data-list-text="8.1."><p style="padding-left: 77pt;text-indent: -35pt;line-height: 112%;text-align: justify;">Kişisel verilerin işlenme şartlarının tamamının ortadan kalkması halinde,
                kişisel verilerin veri sorumlusu tarafından resen veya ilgili kişinin talebi üzerine silinmesi, yok edilmesi veya anonim hâle getirilmesi gerekir.</p></li>
            <li data-list-text="8.2."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -37pt;line-height: 112%;text-align: justify;">Türk Ceza Kanunu’nun 138. maddesinde ve KVK Kanunu’nun
                7. maddesinde düzenlendiği üzere ilgili kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması halinde Şirket kendi kararına
                istinaden veya kişisel veri sahibinin talebi üzerine kişisel veriler silinir, yok edilir veya anonim hale getirilir.</p></li>
            <li data-list-text="8.3."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -37pt;line-height: 112%;text-align: justify;">İlgili kişi, Şirkete başvurarak kendisine ait kişisel
                verilerin silinmesini veya yok edilmesini talep ettiğinde bu talebi yerine getirilmek üzere hemen değerlendirmeye alınır.</p></li>
            <li data-list-text="8.4."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -38pt;line-height: 112%;text-align: justify;">Kişisel verileri işleme şartlarının tamamı ortadan
                kalkmışsa; Şirket talebe konu kişisel verileri siler, yok eder veya anonim hale getirir. Şirket, ilgili kişinin talebini en geç otuz gün içinde sonuçlandırır ve ilgili kişiye bilgi
                verir.</p></li>
            <li data-list-text="8.5."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -37pt;line-height: 112%;text-align: justify;">Kişisel verileri işleme şartlarının tamamı ortadan
                kalkmış ve talebe konu olan kişisel veriler üçüncü kişilere aktarılmışsa Şirket bu durumu üçüncü kişiye bildirir; üçüncü kişi nezdinde bu politika kapsamında gerekli işlemlerin
                yapılmasını temin eder.</p></li>
            <li data-list-text="8.6."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -38pt;line-height: 112%;text-align: justify;">Kişisel verileri işleme şartlarının tamamı ortadan
                kalkmamışsa, bu talep Şirket tarafından gerekçesi açıklanarak reddedilebilir ve ret cevabı ilgili kişiye en geç otuz gün içinde yazılı olarak ya da elektronik ortamda bildirilir.</p>
            </li>
        </ol>
    </li>
    <li data-list-text="9."><h1 style="padding-top: 10pt;padding-left: 41pt;text-indent: -27pt;line-height: 112%;text-align: left;">Kişisel verilerin güvenli bir şekilde saklanması ile hukuka aykırı
        olarak işlenmesi ve erişilmesinin önlenmesi için alınmış teknik ve idari tedbirler</h1>
        <ol id="l9">

                <ol id="l11">
                    <li data-list-text="9.1.1."><p class="s2" style="padding-top: 10pt;padding-left: 113pt;text-indent: -42pt;text-align: left;">Ağ güvenliği ve uygulama güvenliği sağlanmaktadır.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.2."><p class="s2" style="padding-left: 113pt;text-indent: -44pt;text-align: left;">Ağ yoluyla veri akarımlarında kapalı sistem ağ kullanılmaktadır.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.3."><p class="s2" style="padding-left: 113pt;text-indent: -44pt;text-align: left;">Anahtar yönetimi uygulanmaktadır.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.4."><p class="s2" style="padding-left: 113pt;text-indent: -45pt;line-height: 108%;text-align: left;">Bilgi teknolojileri sistemleri tedarik, geliştirme ve
                        bakımı kapsamındaki güvenlik önlemleri alınmaktadır.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.5."><p class="s2" style="padding-left: 113pt;text-indent: -44pt;text-align: left;">Çalışanlar için yetki matrisi oluşturulmuştur.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.6."><p class="s2" style="padding-left: 113pt;text-indent: -44pt;text-align: left;">Erişim logları düzenli olarak tutulmaktadır.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.7."><p class="s2" style="padding-left: 113pt;text-indent: -44pt;line-height: 108%;text-align: left;">Erişim, bilgi güvenliği, kullanım, saklama ve imha
                        konularında kurumsal politikalar hazırlanmış ve uygulanmaya başlanmıştır.</p></li>
                    <li data-list-text="9.1.8."><p class="s2" style="padding-top: 3pt;padding-left: 113pt;text-indent: -44pt;text-align: left;">Gerektiğinde veri maskeleme yöntemi uygulanmaktadır.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.9."><p class="s2" style="padding-left: 113pt;text-indent: -44pt;text-align: left;">Kişisel veri güvenliği sorunları hızlı bir şekilde raporlanmaktadır.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.10."><p class="s2" style="padding-left: 113pt;text-indent: -49pt;text-align: left;">Kişisel veri güvenliğinin takibi yapılmaktadır.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.11."><p class="s2" style="padding-left: 113pt;text-indent: -46pt;line-height: 108%;text-align: justify;">Kişisel veri içeren fiziksel ortamlara giriş
                        çıkışlarla ilgili gerekli güvenlik önlemleri alınmaktadır.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.12."><p class="s2" style="padding-left: 113pt;text-indent: -48pt;line-height: 108%;text-align: justify;">Kişisel veri içeren fiziksel ortamların dış
                        risklere (yangın, sel vb.) karşı güvenliği sağlanmaktadır.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.13."><p class="s2" style="padding-left: 113pt;text-indent: -48pt;text-align: left;">Kişisel veri içeren ortamların güvenliği sağlanmaktadır.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.14."><p class="s2" style="padding-left: 113pt;text-indent: -49pt;line-height: 108%;text-align: justify;">Kişisel veriler yedeklenmekte ve yedeklenen kişisel
                        verilerin güvenliği de sağlanmaktadır.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.15."><p class="s2" style="padding-left: 113pt;text-indent: -48pt;line-height: 108%;text-align: justify;">Kullanıcı hesap yönetimi ve yetki kontrol sistemi
                        uygulanmakta olup bunların takibi de yapılmaktadır.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.16."><p class="s2" style="padding-left: 113pt;text-indent: -49pt;line-height: 108%;text-align: justify;">Kurum içi periyodik ve/veya rastgele denetimler
                        yapılmakta ve yaptırılmaktadır.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.17."><p class="s2" style="padding-left: 113pt;text-indent: -48pt;text-align: left;">Log kayıtları kullanıcı müdahalesi olmayacak şekilde tutulmaktadır.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.18."><p class="s2" style="padding-left: 113pt;text-indent: -49pt;text-align: left;">Mevcut risk ve tehditler belirlenmiştir.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.19."><p class="s2" style="padding-left: 113pt;text-indent: -49pt;line-height: 109%;text-align: justify;">Özel nitelikli kişisel veriler elektronik posta
                        yoluyla gönderilecekse mutlaka şifreli olarak ve KEP veya kurumsal posta hesabı kullanılarak gönderilmektedir.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.20."><p class="s2" style="padding-left: 113pt;text-indent: -51pt;line-height: 108%;text-align: justify;">Özel nitelikli kişisel veriler için güvenli
                        şifreleme / kriptografik anahtarlar kullanılmakta ve farklı birimlerce yönetilmektedir.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.21."><p class="s2" style="padding-left: 113pt;text-indent: -48pt;text-align: left;">Saldırı tespit ve önleme sistemleri kullanılmaktadır.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.22."><p class="s2" style="padding-left: 113pt;text-indent: -50pt;text-align: left;">Sızma testi uygulanmaktadır.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.23."><p class="s2" style="padding-left: 113pt;text-indent: -51pt;line-height: 108%;text-align: justify;">Siber güvenlik önlemleri alınmış olup uygulanması
                        sürekli takip edilmektedir.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.24."><p class="s2" style="padding-left: 113pt;text-indent: -51pt;text-align: left;">Şifreleme yapılmaktadır.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.25."><p class="s2" style="padding-left: 113pt;text-indent: -51pt;line-height: 108%;text-align: justify;">Veri işleyen hizmet sağlayıcılarının veri güvenliği
                        konusunda belli aralıklara denetimi sağlanmaktadır.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.26."><p class="s2" style="padding-left: 113pt;text-indent: -51pt;line-height: 108%;text-align: justify;">Veri işleyen hizmet sağlayıcılarının, veri
                        güvenliği konusunda farkındalığı sağlanmaktadır.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.27."><p class="s2" style="padding-left: 113pt;text-indent: -50pt;text-align: left;">Veri kaybı önleme yazılımları kullanılmaktadır.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.28."><p class="s2" style="padding-left: 113pt;text-indent: -51pt;line-height: 108%;text-align: justify;">Çalışanlar için veri güvenliği hükümleri içeren
                        disiplin düzenlemeleri mevcuttur.</p></li>
                    <li data-list-text="9.1.29."><p class="s2" style="padding-top: 4pt;padding-left: 113pt;text-indent: -48pt;line-height: 111%;text-align: justify;">Çalışanlar için veri güvenliği
                        konusunda belirli aralıklarla eğitim ve farkındalık çalışmaları yapılmaktadır.</p></li>
                    <li data-list-text="9.1.30."><p class="s2" style="padding-top: 10pt;padding-left: 113pt;text-indent: -48pt;line-height: 111%;text-align: justify;">Erişim, bilgi güvenliği,
                        kullanım, saklama ve imha konularında kurumsal politikalar hazırlanmış ve uygulanmaya başlanmıştır.</p></li>
                    <li data-list-text="9.1.31."><p class="s2" style="padding-top: 10pt;padding-left: 113pt;text-indent: -45pt;text-align: left;">Gizlilik taahhütnameleri yapılmaktadır.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.32."><p class="s2" style="padding-left: 113pt;text-indent: -47pt;text-align: left;">İmzalanan sözleşmeler veri güvenliği hükümleri içermektedir.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.33."><p class="s2" style="padding-left: 113pt;text-indent: -48pt;line-height: 111%;text-align: justify;">Kağıt yoluyla aktarılan kişisel veriler için ekstra
                        güvenlik tedbirleri alınmakta ve ilgili evrak gizlilik dereceli belge formatında gönderilmektedir.</p></li>
                    <li data-list-text="9.1.34."><p class="s2" style="padding-top: 10pt;padding-left: 113pt;text-indent: -48pt;text-align: left;">Kişisel veri güvenliği politika ve prosedürleri
                        belirlenmiştir.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.35."><p class="s2" style="padding-left: 113pt;text-indent: -47pt;text-align: left;">Kişisel veri içeren ortamların güvenliği sağlanmaktadır.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.36."><p class="s2" style="padding-left: 113pt;text-indent: -48pt;text-align: left;">Kişisel veriler mümkün olduğunca azaltılmaktadır.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="9.1.37."><p class="s2" style="padding-left: 113pt;text-indent: -48pt;line-height: 111%;text-align: justify;">Kurum içi periyodik ve/veya rastgele denetimler
                        yapılmakta ve yaptırılmaktadır.</p></li>
                    <li data-list-text="9.1.38."><p class="s2" style="padding-top: 10pt;padding-left: 113pt;text-indent: -48pt;line-height: 111%;text-align: justify;">Özel nitelikli kişisel veri
                        güvenliğine yönelik protokol ve prosedürler mevcuttur.</p></li>
                </ol>

        </ol>
        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
    <li data-list-text="10."><h1 style="padding-left: 41pt;text-indent: -32pt;line-height: 112%;text-align: left;">Kişisel verilerin hukuka uygun olarak imha edilmesi için alınmış teknik ve idari
        tedbirler</h1>
        <ol id="l12">
            <li data-list-text="10.1."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -39pt;line-height: 112%;text-align: justify;">Kişisel verilerin silinmesi, yok edilmesi ve anonim
                hale getirilmesiyle ilgili bütün işlemler yetkili kişiler tarafından politika ve prosedürlere uygun olarak yapılır ve kayıt altına alınır.</p></li>
            <li data-list-text="10.2."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -42pt;line-height: 112%;text-align: justify;">Söz konusu kayıtlar, diğer hukuki yükümlülükler hariç
                olmak üzere en az üç yıl süreyle saklanır.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
        </ol>
    </li>
    <li data-list-text="11."><h1 style="padding-top: 5pt;padding-left: 41pt;text-indent: -29pt;text-align: left;">Kişisel verilerin silinmesi, Yok edilmesi ve Anonimleştirilmesi Teknikleri</h1>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <ol id="l13">
            <li data-list-text="11.1."><h1 style="padding-top: 5pt;padding-left: 77pt;text-indent: -36pt;line-height: 112%;text-align: justify;">Fiziksel Olarak Yok Etme <span class="p">Kişisel veriler herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla da işlenebilmektedir. Bu tür   veriler   silinirken/yok   edilirken   kişisel   verinin   sonradan kullanılamayacak  biçimde  fiziksel  olarak  yok  edilmesi  sistemi uygulanmaktadır. Örnek: İlgili dosyanın, belgenin parçalanarak çöpe atılması.</span>
            </h1></li>
            <li data-list-text="11.2."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -38pt;line-height: 112%;text-align: justify;">Yazılımdan Güvenli Olarak Silme <span class="p">Tamamen veya kısmen otomatik olan yollarla işlenen ve dijital ortamlarda muhafaza edilen veriler silinirken/yok edilirken; çok yüksek ihtimalle bir daha kurtarılamayacak biçimde verinin ilgili yazılımdan silinmesine ilişkin yöntemler kullanılır.</span>
            </h1></li>
            <li data-list-text="11.3."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -38pt;line-height: 112%;text-align: justify;">Uzman Tarafından Güvenli Olarak Silme <span class="p">Şirket bazı durumlarda kendisi adına kişisel verileri silmesi için bir uzman ile anlaşabilir. Bu durumda,</span>
            </h1>
                <p style="padding-top: 3pt;padding-left: 77pt;text-indent: 0pt;line-height: 112%;text-align: left;">kişisel veriler bu konuda uzman olan kişi tarafından bir daha kurtarılamayacak
                    biçimde güvenli olarak silinir/yok edilir.</p></li>
            <li data-list-text="11.4."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -39pt;text-align: left;">Kişisel Verileri Anonim Hale Getirme Teknikleri</h1>
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <ol id="l14">
                    <li data-list-text="11.4.1."><p style="padding-left: 113pt;text-indent: -47pt;line-height: 112%;text-align: justify;">Kişisel verilerin anonimleştirilmesi, kişisel verilerin başka
                        verilerle eşleştirilerek dahi kimliği belirli veya belirlenebilir bir gerçek kişiyle ilişkilendirilemeyecek hale getirilmesini ifade eder. Şirket, hukuka uygun olarak işlenen
                        kişisel verilerin işlenmesini gerektiren sebepler ortadan kalktığında kişisel verileri anonimleştirebilmektedir.</p></li>
                    <li data-list-text="11.4.2."><p style="padding-top: 10pt;padding-left: 113pt;text-indent: -49pt;line-height: 112%;text-align: justify;">KVK Kanunu’nun 28. maddesine uygun olarak;
                        anonim hale getirilmiş olan kişisel veriler araştırma, planlama ve istatistik gibi amaçlarla işlenebilir. Bu tür işlemeler KVK Kanunu kapsamı dışındadır. Anonim hale
                        getirilerek işlenen kişisel veriler KVK Kanunu kapsamı dışında olacağından politikanın 10. bölümünde düzenlenen haklar bu veriler için geçerli olmayacaktır.</p></li>
                    <li data-list-text="11.4.3."><h1 style="padding-top: 10pt;padding-left: 113pt;text-indent: -49pt;line-height: 112%;text-align: justify;">Maskeleme (Masking) <span class="p">Veri  maskeleme,  kişisel  verinin  temel belirleyici bilgisini veri seti içerisinden çıkartılarak kişisel verinin anonim hale getirilmesi yöntemidir.  Örnek: Kişisel veri sahibinin tanımlanmasını sağlayan isim, TC Kimlik No, ad, soyad vb. bilginin çıkartılması yoluyla kişisel veri sahibinin tanımlanmasının imkansız hale geldiği bir veri setine dönüştürülmesi.</span>
                    </h1></li>
                    <li data-list-text="11.4.4."><h1 style="padding-top: 10pt;padding-left: 113pt;text-indent: -50pt;line-height: 112%;text-align: justify;">Toplulaştırma (Aggregation) <span
                        class="p">Veri toplulaştırma yöntemi ile birçok veri  toplulaştırılmakta  ve  kişisel  veriler  herhangi  bir  kişiyle ilişkilendirilemeyecek hale getirilmektedir.  Örnek: Müşterilerin doğum yıllarını tek tek göstermeksizin 1975 yılında doğan 100 müşteri bulunduğunun ortaya konulması.</span>
                    </h1></li>
                    <li data-list-text="11.4.5."><h1 style="padding-top: 10pt;padding-left: 113pt;text-indent: -49pt;line-height: 112%;text-align: justify;">Veri Türetme (Data Derivation) <span
                        class="p">Veri türetme yöntemi ile kişisel verinin içeriğinden daha genel bir içerik oluşturulmakta ve kişisel verinin herhangi bir kişiyle ilişkilendirilemeyecek hale getirilmesi sağlanmaktadır.     Örnek:  Doğum  tarihleri  yerine  yaşların belirtilmesi; açık adres yerine ikamet edilen ilçenin veya şehirin belirtilmesi.</span>
                    </h1></li>
                    <li data-list-text="11.4.6."><h1 style="padding-top: 10pt;padding-left: 113pt;text-indent: -49pt;line-height: 112%;text-align: justify;">Veri Karma (Data Shuffling, Permutation)
                        <span class="p">Veri karma yöntemi ile kişisel veri seti içindeki değerlerinin karıştırılarak değerler ile kişiler arasındaki  bağın  kopartılması  sağlanmaktadır.    Örnek:  Ses kayıtlarının niteliğinin değiştirilerek sesler ile veri sahibi kişinin ilişkilendirilemeyecek veya tanınamayacak hale getirilmesi.</span>
                    </h1>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                </ol>
            </li>
        </ol>
    </li>
    <li data-list-text="12."><h1 style="padding-top: 9pt;padding-left: 41pt;text-indent: -31pt;line-height: 112%;text-align: left;">Kişisel verileri saklama ve imha süreçlerinde yer alanların
        unvanlarına, birimleri ve görev tanımları:</h1>
        <ol id="l15">
            <li data-list-text="12.1."><p class="s3" style="padding-top: 3pt;padding-left: 77pt;text-indent: -38pt;text-align: left;">Bilgi İşlem Birimi Yöneticisi; <span class="p">Şirketin tüm Bilgi İşlem süreçlerini yönetir.</span>
            </p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="12.2."><p class="s3" style="padding-left: 77pt;text-indent: -41pt;line-height: 112%;text-align: left;">İnsan Kaynakları Yöneticisi <span class="s4">(Personel ile ilgili konularda)</span><span
                class="p">, Şirketin tüm personel süreçlerini yönetir.</span></p></li>
            <li data-list-text="12.3."><p class="s3" style="padding-top: 10pt;padding-left: 77pt;text-indent: -41pt;line-height: 112%;text-align: left;">Satış ve Pazarlama Yöneticisi <span class="p">(Müşteri bilgileri ile ilgili konularda); Şirketin tüm satış pazarlama süreçlerini yönetir.</span>
            </p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
        </ol>
    </li>
    <li data-list-text="13."><h1 style="padding-left: 41pt;text-indent: -31pt;text-align: left;">Saklama ve imha sürelerini gösteren tablo</h1>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <table cellspacing="0" style="border-collapse:collapse;margin-left:42.4531pt">
            <tr style="height:36pt">
                <td style="width:29pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt">
                    <p class="s5" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">NO</p></td>
                <td style="width:203pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt">
                    <p class="s5" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">VERİ KATEGORİSİ</p></td>
                <td style="width:212pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s5" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">VERİ SAKLAMA SÜRESİ</p></td>
            </tr>
            <tr style="height:36pt">
                <td style="width:29pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">1</p></td>
                <td style="width:203pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">Kimlik</p></td>
                <td style="width:212pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s5" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">15 YIL</p></td>
            </tr>
            <tr style="height:36pt">
                <td style="width:29pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">2</p></td>
                <td style="width:203pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">İletişim</p></td>
                <td style="width:212pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s5" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">15 YIL</p></td>
            </tr>
            <tr style="height:36pt">
                <td style="width:29pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">3</p></td>
                <td style="width:203pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">Lokasyon</p></td>
                <td style="width:212pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s5" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">2 YIL</p></td>
            </tr>
            <tr style="height:36pt">
                <td style="width:29pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">4</p></td>
                <td style="width:203pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">Özlük</p></td>
                <td style="width:212pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s5" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">15 YIL</p></td>
            </tr>
            <tr style="height:36pt">
                <td style="width:29pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">5</p></td>
                <td style="width:203pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">Hukuki İşlem</p></td>
                <td style="width:212pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s5" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">10 YIL</p></td>
            </tr>
            <tr style="height:36pt">
                <td style="width:29pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">6</p></td>
                <td style="width:203pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">Müşteri İşlem</p></td>
                <td style="width:212pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s5" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">5 YIL</p></td>
            </tr>
            <tr style="height:36pt">
                <td style="width:29pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">7</p></td>
                <td style="width:203pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">Fiziksel Mekân Güvenliği</p></td>
                <td style="width:212pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s5" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">2 YIL</p></td>
            </tr>
            <tr style="height:36pt">
                <td style="width:29pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">8</p></td>
                <td style="width:203pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">İşlem Güvenliği</p></td>
                <td style="width:212pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s5" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">2 YIL</p></td>
            </tr>
            <tr style="height:36pt">
                <td style="width:29pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">9</p></td>
                <td style="width:203pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">Risk Yönetimi</p></td>
                <td style="width:212pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s5" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">10 YIL</p></td>
            </tr>
            <tr style="height:36pt">
                <td style="width:29pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">10</p></td>
                <td style="width:203pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">Finans</p></td>
                <td style="width:212pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s5" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">10 YIL</p></td>
            </tr>
            <tr style="height:36pt">
                <td style="width:29pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">11</p></td>
                <td style="width:203pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">Mesleki Deneyim</p></td>
                <td style="width:212pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s5" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">15 YIL</p></td>
            </tr>
            <tr style="height:36pt">
                <td style="width:29pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">12</p></td>
                <td style="width:203pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">Pazarlama</p></td>
                <td style="width:212pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s5" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">5 YIL</p></td>
            </tr>
            <tr style="height:36pt">
                <td style="width:29pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">13</p></td>
                <td style="width:203pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">Görsel ve İşitsel Kayıtlar</p></td>
                <td style="width:212pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt">
                    <p class="s5" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">15 YIL</p></td>
            </tr>
            <tr style="height:30pt">
                <td style="width:29pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 2pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">18</p></td>
                <td style="width:203pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 2pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">Sağlık Bilgileri</p></td>
                <td style="width:212pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 2pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">15 YIL</p></td>
            </tr>
        </table>
        <table cellspacing="0" style="border-collapse:collapse;margin-left:42.4531pt">
            <tr style="height:45pt">
                <td style="width:29pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p style="text-indent: 0pt;text-align: left;"><br/></p>
                    <p class="s5" style="padding-left: 1pt;text-indent: 0pt;text-align: left;">19</p></td>
                <td style="width:203pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p class="s5" style="padding-top: 2pt;padding-left: 1pt;text-indent: 0pt;line-height: 112%;text-align: left;">Ceza Mahkûmiyeti Ve Güvenlik Tedbirleri</p></td>
                <td style="width:212pt;border-top-style:solid;border-top-width:1pt;border-top-color:#CCCCCC;border-left-style:solid;border-left-width:1pt;border-left-color:#CCCCCC;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#CCCCCC;border-right-style:solid;border-right-width:1pt;border-right-color:#CCCCCC">
                    <p style="text-indent: 0pt;text-align: left;"><br/></p>
                    <p class="s5" style="padding-left: 1pt;text-indent: 0pt;text-align: left;">15 YIL</p></td>
            </tr>
        </table>
        <h1 style="padding-left: 41pt;text-indent: 0pt;line-height: 112%;text-align: justify;">*Yukarıdaki süreler, çalışanlar için iş sözleşmesinin feshi tarihinden, tedarikçi ve müşteriler için
            sözleşmenin sona erme tarihinden veya sözleşme yoksa son işlemin yapıldığı tarihten, diğer ilgili kişiler için kişisel verilerin elde edilme tarihinden itibaren başlar.</h1></li>
    <li data-list-text="14."><h1 style="padding-top: 10pt;padding-left: 41pt;text-indent: -32pt;text-align: left;">Periyodik imha süreleri,</h1>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <ol id="l16">
            <li data-list-text="14.1."><p style="padding-left: 77pt;text-indent: -40pt;line-height: 112%;text-align: justify;">Şirket saklama süresi dolan kişisel verileri saklama süresinin dolduğu
                tarihten itibaren en geç 180 gün içerisinde imha eder.</p></li>
            <li data-list-text="14.2."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -42pt;line-height: 112%;text-align: justify;">Şirket; kişisel verileri silme, yok etme veya anonim
                hale getirme yükümlülüğünün ortaya çıktığı tarihi takip eden ilk periyodik imha işleminde, kişisel verileri siler, yok eder veya anonim hale getirir.</p></li>
            <li data-list-text="14.3."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -42pt;line-height: 112%;text-align: justify;">Periyodik imhanın gerçekleştirileceği zaman aralığı
                veri sorumlusu tarafından kişisel veri saklama ve imha politikasına, prosedürlere ve şirketin iş akışına uygun olarak belirlenir. Bu süre her halde altı ayı geçemez.</p></li>
        </ol>
    </li>
    <li data-list-text="15."><h1 style="padding-top: 10pt;padding-left: 41pt;text-indent: -31pt;text-align: left;">Politikanın Yayınlanması ve Saklanması</h1>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <p style="padding-left: 41pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Politika, ıslak imzalı (basılı kâğıt) ve elektronik ortamda olmak üzere iki farklı ortamda yayımlanır,
            internet sayfasında kamuya açıklanır.</p></li>
    <li data-list-text="16."><h1 style="padding-top: 10pt;padding-left: 41pt;text-indent: -32pt;text-align: left;">Güncelleme Periyodu</h1>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <p style="padding-left: 41pt;text-indent: 0pt;text-align: justify;">Politika, ihtiyaç duyuldukça gözden geçirilir ve gerekli olan bölümler güncellenir.</p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
    <li data-list-text="17."><h1 style="padding-left: 41pt;text-indent: -31pt;text-align: left;">Yürürlük</h1></li>
</ol>
<p style="text-indent: 0pt;text-align: left;"><br/></p>
<p style="padding-left: 41pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Politika, Şirketin internet sitesinde yayınlanmasının ardından yürürlüğe girmiş kabul edilir.</p>
<p style="text-indent: 0pt;text-align: left;"><br/></p>
<h1 style="padding-left: 5pt;text-indent: 0pt;line-height: 224%;text-align: left;">Veri Sorumlusu Unvan: Artiox Teknoloji Yazılım Anonim Şirketi</h1><h1> Mersis no: <span class="s1">   </span>0085093196500001
</h1>
<h1 style="padding-left: 5pt;text-indent: 0pt;line-height: 224%;text-align: left;"><a class="a" href="mailto:info@artiox.com" target="_blank">E-posta adresi : </a>info&#64;artiox.com Kayıtlı Elektronik
    Posta Adresi:</h1>
<h1 style="padding-left: 5pt;text-indent: 0pt;line-height: 185%;text-align: left;">Fiziki Posta adresi : Mustafa Kemal Mah. Dumlıpınar Blv. No:280 G İç Kapı No: 1233 Çankaya/Ankara</h1>
