<div class="w-100 mt-3 px-3 pt-1 pb-2"
     style="font-family: Roboto, sans-serif; font-size: 14px; letter-spacing: 0.7px; color: #272a3b;">
  {{managerService.config.WITHDRAWAL.WITHDRAWAL_HISTORY_MOBILE | managerPipe}}
</div>

<div class="w-100 d-flex flex-row align-items-center justify-content-start px-3 py-1"
     style="font-family: Roboto, sans-serif; font-size: 10px; color: #272a3b;">
  <span class="text-left"
        style="width: 15%">{{managerService.config.BALANCE.SYMBOL | managerPipe}}</span>
  <span class="text-center"
        style="width: 30%">{{managerService.config.BALANCE.OPERATION_TYPE | managerPipe}}</span>
  <span class="text-center"
        style="width: 20%">{{managerService.config.BALANCE.AMOUNT | managerPipe}}</span>
  <span class="text-center"
        style="width: 25%">{{managerService.config.BALANCE.STATUS | managerPipe}}</span>
  <span class="text-right"
        style="width: 10%"></span>
</div>

<div class="w-100 d-flex flex-column overflow-auto " style="min-height: 360px; max-height: 360px;">
  <div
      *ngFor="let user_operation of (managerService.user_currency_operation_history_pipe | managerPipe: managerService.mpa_template.GET_USER_OPERATION_HISTORY: currency_id: 'withdrawal': currency_type) | managerPipe : managerService.mpa_template.SORT_BY_CREATION_TIME"
      class="d-flex flex-row align-items-center justify-content-start flex-wrap user-trade-history px-3"
      style="font-family: Roboto, sans-serif;font-size: 10px; letter-spacing: 0.7px; color: #565865;">
    <div class="w-100 d-flex flex-row align-items-center justify-content-start py-2">
        <span class="text-left"
              style="width: 15%; font-weight: 500;">{{user_operation.currency_id | managerPipe: managerService.mpa_template.CURRENCY_SYMBOL: managerService.pair_pipe}}</span>
      <span class="text-center"
            style="width: 30%">{{user_operation.type | managerPipe: managerService.mpa_template.GET_USER_OPERATION_TYPE: managerService.language_pipe}}</span>
      <span class="text-center"
            style="width: 20%">{{user_operation.amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
      <span class="text-center"
            style="width: 25%">
        <svg style="height: 18px; width: 18px;">
          <use [attr.xlink:href]='user_operation | managerPipe: managerService.mpa_template.GET_USER_OPERATION_ICON' fill="#47b3c5"/>
        </svg>
      </span>
      <span (click)="more_div.classList.toggle('d-none'); more_div.classList.toggle('d-flex')" class="text-right"
            style="width: 10%; font-size: 9px; font-weight: 500; font-style: italic; color: #9da5b7; letter-spacing: normal" type="button">
          {{managerService.config.ADVANCED.USER_OPEN_ORDERS.MORE | managerPipe}}
        </span>
    </div>
    <div #more_div class="w-100 d-none flex-row flex-wrap align-items-center justify-content-around pb-1"
         style="font-family: Roboto, sans-serif;font-size: 10px; font-weight: 500; line-height: 1.2; letter-spacing: 0.7px; color: #4a4e70; background-color: inherit">
      <span class="w-50 my-1 text-left">{{managerService.config.BALANCE.DATE| managerPipe}}</span>
      <span class="w-50 my-1 text-right">{{user_operation.creation_time | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</span>
      <span class="w-50 my-1 text-left">{{managerService.config.BALANCE.KIND | managerPipe}}</span>
      <span class="w-50 my-1 text-right">{{user_operation.kind | managerPipe}}</span>
      <span class="w-25 my-1 text-left">{{managerService.config.BALANCE.EXPLANATION | managerPipe}}</span>
      <span (click)="managerService.navigationManager(managerService.userOperationTransactionInfoLink(user_operation))"
            [innerHTML]="user_operation | managerPipe: managerService.mpa_template.GET_USER_OPERATION_INFO: managerService.language_pipe"
            class="w-75 my-1 text-right"></span>
    </div>
  </div>
</div>
