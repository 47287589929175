<div class="position-relative" style="height: 100vh">
    <div *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP" style="height: 84px;">
        <app-header></app-header>
    </div>

    <div *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE">
        <app-header></app-header>
    </div>

<!--    <div *ngIf="managerService.environment.platform === 'ios'" style="height: env(safe-area-inset-top); background-color: #1e1f25;"></div>-->
    <div [style]="managerService.show_sign_up_or_login | managerPipe: managerService.mpa_template.GET_MIN_BODY_HEIGHT">
        <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>


    <div *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
         class="fixed-bottom" id="tab-bar"
         style="background-color: #1e1f25; z-index: 300; max-width: 100%; box-shadow: 0 -3px 4px 0 rgba(0, 0, 0, 0.5);">

        <div
            class="d-flex flex-row align-items-center justify-content-around"
            style="height: 56px;">
            <svg (click)="activateMobilNavbar(managerService.active_mobile_navbar_panel_template.HOME)"
                 [class]="(managerService.active_mobile_navbar_panel | managerPipe: managerService.mpa_template.ACTIVE_MOBILE_NAVBAR_PANEL_HOME) ? 'active':'passive'"
                 style="height:23px; width: 23px;" type="button">
                <use [attr.xlink:href]="'./assets/images/home_icon.svg#home_icon'" class="w-100"/>
            </svg>
            <svg (click)="activateMobilNavbar(managerService.active_mobile_navbar_panel_template.BASIC_TRADE)"
                 [class]="(managerService.active_mobile_navbar_panel | managerPipe: managerService.mpa_template.ACTIVE_MOBILE_NAVBAR_PANEL_BASIC_TRADE) ? 'active':'passive'"
                 style="height:24px; width: 24px;" type="button">
                <use [attr.xlink:href]="'./assets/images/monalisa.svg#monalisa'" class="w-100"/>
            </svg>
            <svg (click)="activateMobilNavbar(managerService.active_mobile_navbar_panel_template.ADVANCED_TRADE)"
                 [class]="(managerService.active_mobile_navbar_panel | managerPipe: managerService.mpa_template.ACTIVE_MOBILE_NAVBAR_PANEL_ADVANCED_TRADE) ? 'active':'passive'"
                 style="height:26px; width: 26px;" type="button">
                <use [attr.xlink:href]="'./assets/images/advanced_icon.svg#advanced_icon'" class="w-100"/>
            </svg>
            <svg (click)="activateMobilNavbar(managerService.active_mobile_navbar_panel_template.AUCTION)"
                 [class]="(managerService.active_mobile_navbar_panel | managerPipe: managerService.mpa_template.ACTIVE_MOBILE_NAVBAR_PANEL_AUCTION) ? 'active':'passive'"
                 style="height:32px; width: 32px;" type="button">
                <use [attr.xlink:href]="'./assets/images/gavel_mobile.svg#gavel_mobile'" class="w-100"/>
            </svg>
            <svg (click)="activateMobilNavbar(managerService.active_mobile_navbar_panel_template.ACCOUNT)"
                 [class]="(managerService.active_mobile_navbar_panel | managerPipe: managerService.mpa_template.ACTIVE_MOBILE_NAVBAR_PANEL_ACCOUNT) ? 'active':'passive'"
                 style="height:24px; width: 24px;" type="button">
                <use [attr.xlink:href]="'./assets/images/wallet.svg#wallet'" class="w-100"/>
            </svg>

        </div>
<!--        <div *ngIf="managerService.environment.platform === 'ios'"-->
<!--             style="height: env(safe-area-inset-bottom); background-color: inherit;"></div>-->
    </div>

    <!--<div *ngIf="(!managerService.environment.app) && (managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE)" class="position-fixed"-->
    <!--     style="top: 56px; right: 20px; width: 40px; z-index: 100;">-->
    <!--  <svg #scroll_button class="position-relative" style="height: 40px; width: 40px; opacity: 0.75;">-->
    <!--    <use [attr.xlink:href]='"assets/images/scroll.svg#scroll"' class="w-100"/>-->
    <!--  </svg>-->
    <!--</div>-->
</div>
