<div class="w-100 d-flex flex-column align-items-start justify-content-start"
     style="box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff; max-height: 1160px; border-radius: 4px;">
  <form [formGroup]="changePasswordForm" class="w-100 d-flex flex-column align-items-start justify-content-start m-4 p-4" style="height: fit-content;">
    <div class="d-flex flex-column" style="width: 40%; min-width: 240px;">
      <div class="w-100" style="font-family: bebas-neue-bold, sans-serif; font-size: 25px; line-height: 1.25; letter-spacing: 2.5px; color: #404352;">
        {{managerService.config.SECURITY.CHANGE_PASSWORD.FORM_HEADING | managerPipe}}
      </div>
      <div class="w-100 d-flex flex-column">
        <label class="position-relative mt-4 mb-2 d-flex flex-row" style="height: 30px;">
          <input [type]="(old_password_again_visible | managerPipe) ? 'text' : 'password'" class="w-100 input-field-underline" formControlName="old_password"
                 placeholder="{{managerService.config.SECURITY.CHANGE_PASSWORD.CURRENT_PASSWORD | managerPipe}}" required>
          <svg (click)="old_password_again_visible = !old_password_again_visible" class="password-eye" style="width: 20px; height: 20px">
            <use *ngIf="(!old_password_again_visible | managerPipe)" [attr.xlink:href]='"./assets/images/eye_hidden.svg#eye_hidden"' fill="#9DA5B7"/>
            <use *ngIf="(old_password_again_visible | managerPipe)" [attr.xlink:href]='"./assets/images/eye_visible.svg#eye_visible"' fill="#9DA5B7"/>
          </svg>
        </label>
        <div #info_old_password class="mx-2" style="font-size: 11px; color: #4fc0d2;"></div>
        <div #warning_old_password class="d-none warning"></div>
        <label class="position-relative mt-4 mb-2 d-flex flex-row" style="height: 30px;">
          <input [type]="(new_password_visible | managerPipe) ? 'text' : 'password'" class="w-100 input-field-underline" formControlName="new_password"
                 placeholder="{{managerService.config.SECURITY.CHANGE_PASSWORD.NEW_PASSWORD | managerPipe}}" required>
          <svg (click)="new_password_visible = !new_password_visible" class="password-eye" style="width: 20px; height: 20px">
            <use *ngIf="(!new_password_visible | managerPipe)" [attr.xlink:href]='"./assets/images/eye_hidden.svg#eye_hidden"' fill="#9DA5B7"/>
            <use *ngIf="(new_password_visible | managerPipe)" [attr.xlink:href]='"./assets/images/eye_visible.svg#eye_visible"' fill="#9DA5B7"/>
          </svg>
        </label>
        <div #info_new_password class="mx-2" style="font-size: 11px; color: #4fc0d2;">
          {{managerService.config.SECURITY.CHANGE_PASSWORD.PASSWORD_RULES | managerPipe}}
        </div>
        <div #warning_new_password class="d-none warning"></div>
        <label class="position-relative mt-4 mb-2 d-flex flex-row" style="height: 30px;">
          <input [type]="(new_password_control_visible | managerPipe) ? 'text' : 'password'" class="w-100 input-field-underline" formControlName="new_password_control"
                 placeholder="{{managerService.config.SECURITY.CHANGE_PASSWORD.NEW_PASSWORD_AGAIN | managerPipe}}" required>
          <svg (click)="new_password_control_visible = !new_password_control_visible" class="password-eye" style="width: 20px; height: 20px">
            <use *ngIf="(!new_password_control_visible | managerPipe)" [attr.xlink:href]='"./assets/images/eye_hidden.svg#eye_hidden"' fill="#9DA5B7"/>
            <use *ngIf="(new_password_control_visible | managerPipe)" [attr.xlink:href]='"./assets/images/eye_visible.svg#eye_visible"' fill="#9DA5B7"/>
          </svg>
        </label>
      </div>
      <div #info_new_password_control class="mx-2" style="font-size: 11px; color: #4fc0d2;"></div>
      <div #warning_new_password_control class="d-none warning"></div>
      <button (click)="changePasswordValidator()" class="btn-native" id="change-password-button">
        {{managerService.config.SECURITY.CHANGE_PASSWORD.SAVE_CHANGES | managerPipe}}
      </button>
    </div>
  </form>
</div>


