import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
    selector: 'app-auction-bid-rules-desktop',
    templateUrl: './auction-bid-rules-desktop.component.html',
    styleUrls: ['./auction-bid-rules-desktop.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AuctionBidRulesDesktopComponent implements OnInit {
    @Input() modal_id: string = '';

    constructor(public managerService: ManagerService) {
    }

    ngOnInit(): void {
    }
}
