import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-currency-converter-desktop',
  templateUrl: './currency-converter-desktop.component.html',
  styleUrls: ['./currency-converter-desktop.component.css']
})
export class CurrencyConverterDesktopComponent implements OnInit, OnDestroy {

  public active_currency_converter_panel_template = this.managerService.active_currency_converter_panel_template;

  @Input() modal_id: string = '';

  currencyConverterBuyForm = new UntypedFormGroup({
    amount: new UntypedFormControl(null, [Validators.required, Validators.min(0)])
  });

  currencyConverterSellForm = new UntypedFormGroup({
    amount: new UntypedFormControl(null, [Validators.required, Validators.min(0)])
  });

  public swap_amount: number = 0;
  public swap_average_cost: number = 0;
  public swap_fee: number = 0;
  public net_amount: number = 0;

  public current_active_pair_id = [-1, -1];

  public subscription_list: (Subscription | undefined)[] = [];

  constructor(public managerService: ManagerService, private httpClient: HttpClient) {
    this.subscription_list.push(
      this.currencyConverterBuyForm.get('amount')?.valueChanges.subscribe(() => {
        this.setBuyAmount();
      })
    );

    this.subscription_list.push(
      this.currencyConverterSellForm.get('amount')?.valueChanges.subscribe(() => {
        this.setSellAmount();
      })
    );

    this.subscription_list.push(
      this.managerService.pairService.currency_pair_list_changed.subscribe(() => {
        if (this.managerService.active_currency_converter_panel === this.active_currency_converter_panel_template.BUY) {
          this.setBuyAmount();
        } else if (this.managerService.active_currency_converter_panel === this.active_currency_converter_panel_template.SELL) {
          this.setSellAmount();
        }
      })
    );

    this.subscription_list.push(
      this.managerService.active_currency_converter_panel_changed.subscribe(() => {
        this.clearForms();
      })
    );

    this.subscription_list.push(
      this.managerService.pairService.active_currency_pair_changed.subscribe(() => {
        if (this.current_active_pair_id[0] !== this.managerService.pairService.active_currency_pair?.id[0]
          || this.current_active_pair_id[1] !== this.managerService.pairService.active_currency_pair?.id[1]) {
          this.clearForms();
        }
        if (this.managerService.active_currency_converter_panel === this.managerService.active_currency_converter_panel_template.BUY){
          this.setBuyAmount();
        } else if (this.managerService.active_currency_converter_panel === this.managerService.active_currency_converter_panel_template.SELL){
          this.setSellAmount();
        }
        this.current_active_pair_id = this.managerService.pairService.active_currency_pair?.id ?? [-1, -1];
      })
    );
  }

  ngOnInit(): void {
  }

  switch(): void {
    if (this.managerService.active_currency_converter_panel === this.managerService.active_currency_converter_panel_template.BUY) {
      this.managerService.setActiveCurrencyConverterPanel(this.managerService.active_currency_converter_panel_template.SELL);
    } else if (this.managerService.active_currency_converter_panel === this.managerService.active_currency_converter_panel_template.SELL) {
      this.managerService.setActiveCurrencyConverterPanel(this.managerService.active_currency_converter_panel_template.BUY);
    }
  }

  clearForms(): void {
    this.currencyConverterBuyForm.get('amount')?.setValue(null);
    this.currencyConverterSellForm.get('amount')?.setValue(null);
  }

  setBuyAmount(): void{
    if (this.managerService.active_currency_converter_panel !== this.managerService.active_currency_converter_panel_template.BUY){
      return;
    }
    if (!this.managerService.pairService.active_currency_pair || !this.managerService.userService.user) {
      return;
    }

    const value: number = this.currencyConverterBuyForm.get('amount')?.value;
    if (value === null) {
      this.swap_average_cost = 0;
      this.swap_amount = 0;
      this.swap_fee = 0;
      this.net_amount = 0;
      return;
    }

    const decimals = this.managerService.pairService.active_currency_pair!.id[0] === 2 ? 6 : 2;

    const max_price: number = this.managerService.userService.user.currency_list[this.managerService.pairService.active_currency_pair.id[1]].available_amount / this.managerService.pairService.active_currency_pair.price;
    const amount: number = this.managerService.preciseRound(this.managerService.utilityService.clamp(value, 0, max_price), decimals);
    if (value !== amount) {
      this.currencyConverterBuyForm.get('amount')?.setValue(amount);
      return;
    }

    this.swap_average_cost = this.managerService.preciseRound(amount * this.managerService.pairService.active_currency_pair.price, decimals);
    this.swap_amount = amount;
    this.swap_fee = amount * 0.01;
    this.net_amount = this.swap_amount - this.swap_fee;
  }

  setSellAmount(): void{
    if (this.managerService.active_currency_converter_panel !== this.managerService.active_currency_converter_panel_template.SELL){
      return;
    }

    if (!this.managerService.pairService.active_currency_pair || !this.managerService.userService.user) {
      return;
    }

    const value: number = this.currencyConverterSellForm.get('amount')?.value;
    if (value === null) {
      this.swap_average_cost = 0;
      this.swap_amount = 0;
      this.swap_fee = 0;
      this.net_amount = 0;
      return;
    }

    const decimals = this.managerService.pairService.active_currency_pair!.id[0] === 2 ? 6 : 2;

    const available: number = this.managerService.userService.user.currency_list[this.managerService.pairService.active_currency_pair.id[0]].available_amount;
    const amount: number = this.managerService.preciseRound(this.managerService.utilityService.clamp(value, 0, available), decimals);
    if (value !== amount) {
      this.currencyConverterSellForm.get('amount')?.setValue(amount);
      return;
    }

    this.swap_average_cost = this.managerService.preciseRound(amount * this.managerService.pairService.active_currency_pair.price, decimals);
    this.swap_amount = amount;
    this.swap_fee = amount * 0.01 * this.managerService.pairService.active_currency_pair.price;
    this.net_amount = this.swap_amount  * this.managerService.pairService.active_currency_pair.price - this.swap_fee;
  }

  buyCurrency(): void {
    if (this.currencyConverterBuyForm.invalid) {
      // @ts-ignore
      const content = this.managerService.config.RESPONSE_TABLE['2003'];
      this.managerService.popSnackBar(content.is_okay, content.message);
      return;
    }

    const currency_pair = this.managerService.pairService.active_currency_pair;
    const amount = this.currencyConverterBuyForm.get('amount')?.value;

    const data = {
      currency_pair_id: currency_pair?.id,
      order_type: 'buy',
      amount
    };

    this.currencyConverterBuyForm.get('amount')?.setValue(null);

    this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'swap_buy_service', data).subscribe(
      response => {
        // @ts-ignore
        const content = this.managerService.config.RESPONSE_TABLE[response.code];
        this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
        if (response.is_okay){
          const symbol = currency_pair?.symbol;
          const volume = (currency_pair?.price || 0) * amount;
          this.managerService.marketingService.logEvent('currency_swap', {type: 'buy', view: 'swap', symbol, amount, volume});
        }
      }));
  }

  sellCurrency(): void {
    if (this.currencyConverterSellForm.invalid) {
      // @ts-ignore
      const content = this.managerService.config.RESPONSE_TABLE['2003'];
      this.managerService.popSnackBar(content.is_okay, content.message);
      return;
    }

    const currency_pair = this.managerService.pairService.active_currency_pair;
    const amount = this.currencyConverterSellForm.get('amount')?.value;

    const data = {
      currency_pair_id: currency_pair?.id,
      order_type: 'sell',
      amount
    };

    this.currencyConverterSellForm.get('amount')?.setValue(null);

    this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'swap_sell_service', data).subscribe(
      response => {
        // @ts-ignore
        const content = this.managerService.config.RESPONSE_TABLE[response.code];
        this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
        if (response.is_okay){
          const symbol = currency_pair?.symbol;
          const volume = (currency_pair?.price || 0) * amount;
          this.managerService.marketingService.logEvent('currency_swap', {type: 'sell', view: 'swap', symbol, amount, volume});
        }
      }));
  }

  ngOnDestroy(): void {
    this.subscription_list.forEach((subscription) => {
      subscription?.unsubscribe();
    });
  }

}
