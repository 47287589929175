<div *ngIf="!managerService.environment.app | managerPipe" id="mobile-header" style="height: 56px;">
    <div class="h-100 w-100 d-flex flex-row position-relative"
         style="background-color: #1e1f25; box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.5); z-index: 1;">
        <div class="w-50 d-flex flex-row align-self-center justify-content-start">
            <svg (click)="headerIconClick()" [class.d-none]="managerService.navigationService.navigation_history.length !== 0 | managerPipe"
                 class="mx-4"
                 style="height:56px; width: 100px; min-width: 100px;">
                <use [attr.xlink:href]="'./assets/images/artiox_logo.svg#artiox_logo'" class="w-100" fill="white"/>
            </svg>
            <svg (click)="headerIconClick()" [class.d-none]="managerService.navigationService.navigation_history.length === 0 | managerPipe"
                 class="mx-4"
                 style="height:56px; width: 24px; min-width: 24px;">
                <use [attr.xlink:href]="'./assets/images/back_arrow.svg#back_arrow'" class="w-100" fill="white"/>
            </svg>
            <div
                (click)="managerService.exchangeNavigate(managerService.pairService.getLastNotListedPair())"
                *ngIf="managerService.navigationService.current_component_index === 0 && managerService.pairService.active_featured_pair_ready && ((managerService.pair_pipe | managerPipe: managerService.mpa_template.GET_NOT_LISTED_PAIR_LIST).length) | managerPipe"
                class="d-flex flex-column align-items-center justify-content-center"
                style="height: 56px;">
                <svg id="pre_sale_mobile" style="height: 24px; width: 24px;" viewBox="0 0 141.73 141.73" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <linearGradient gradientUnits="userSpaceOnUse" id="pre-sale-mobile" x1="-28.77" x2="197.41" y1="82.54" y2="56.4">
                            <stop offset="0.08" stop-color="#d7035f"/>
                            <stop offset="0.36" stop-color="#ba5196"/>
                            <stop offset="0.7" stop-color="#98add7"/>
                            <stop offset="0.85" stop-color="#8bd2f1"/>
                        </linearGradient>
                    </defs>
                    <path
                        d="M141,35.57c-.27,6.12-.49,12.25-.83,18.37a32,32,0,0,1-9.8,22C112,93.56,93.48,111,75,128.54c-3.28,3.11-6.53,6.28-9.86,9.33a14,14,0,0,1-20.34-1Q24.51,114.09,4.3,91.28c-6.09-6.88-5.67-15.68,1-22Q37.62,38.65,70,8.09A28.58,28.58,0,0,1,90.13,0c10.82.08,21.63.77,32.44,1.23a59.8,59.8,0,0,1,7.46.52,14.36,14.36,0,0,1,11.7,14.71c-.08,3.18-.31,6.36-.38,9.55M135,32.35c.12-3.33.38-9.46.55-15.59.14-5.2-3.19-8.84-8.24-9.1-2-.1-3.92-.11-5.88-.19-10.1-.44-20.2-1.06-30.31-1.26a24.7,24.7,0,0,0-17.68,7.06c-6.7,6.26-13.33,12.6-20,18.9q-22.14,21-44.28,42c-3.81,3.61-4.08,8.76-.67,12.59q20.07,22.58,40.18,45.12c4,4.54,8.85,4.76,13.27.57q32.19-30.53,64.31-61.09A25.45,25.45,0,0,0,134,54.8C134.61,48.27,134.64,41.7,135,32.35Z"
                        fill="url(#pre-sale-mobile)"/>
                    <path d="M114.82,35a6.37,6.37,0,0,1-6.52-6.52A6.51,6.51,0,1,1,114.82,35Z" fill="#a4bce3"/>
                </svg>
            </div>
        </div>

        <div class="w-50 d-flex justify-content-end py-2 mr-4">
            <button (click)="managerService.navigationManager({url: '/login', current_component_index: 0})"
                    *ngIf="!managerService.logged_in | managerPipe"
                    [innerHTML]="managerService.config.HEADER.LOGIN_SHORT | managerPipe"
                    class="btn-native btn-simple mr-2"
                    type="button"></button>
            <button (click)="managerService.switchLanguage()"
                    *ngIf="!(managerService.active_mobile_navbar_panel | managerPipe: managerService.mpa_template.ACTIVE_MOBILE_NAVBAR_PANEL_ACCOUNT)"
                    class="btn-native btn-simple"
                    id="language-button-mobile"
                    type="button">{{managerService.language}}</button>
            <div *ngIf="managerService.logged_in | managerPipe">
                <!--        <button (click)="notificationButtonClick()"-->
                <!--                *ngIf="!(managerService.active_mobile_navbar_panel | managerPipe: managerService.mpa_template.ACTIVE_MOBILE_NAVBAR_PANEL_ACCOUNT)"-->
                <!--                class="btn-native btn-simple navbar-native-button"-->
                <!--                style="padding: 0;"-->
                <!--                type="button">-->
                <!--          <svg style="height: 28px; width: 28px;">-->
                <!--            <svg id="notification_icon" viewBox="0 0 2873 3382" xmlns="http://www.w3.org/2000/svg">-->
                <!--              <path-->
                <!--                d="M2835.25,2654.6l-208.9-348.4a915.73,915.73,0,0,1-135.06-482.71c.75-106.93,1.35-214,0-318.16a1025.3,1025.3,0,0,0-49.68-321c-91.84-293.77-309.9-532.31-591.29-651.47q.67-67.08-1.2-135C1842.37,203.53,1704.3,42.8,1513.26,7,1289.8-34.57,1074,112.66,1030,348.72a1557.52,1557.52,0,0,0-7.95,185.34c-394.69,167.78-648.92,557.15-639.46,988.23l-.15,97.55c-.3,66-.45,131.84.15,197.8,1.5,174.38-46.07,344.94-138.37,494.41q-103.32,170.64-204.85,342.1c-31.06,44.49-44.87,99.79-38.12,153.29,13.66,111.43,108.81,193.3,218.36,193.3,5,0,9.9-.15,15-.53l649.5-.34q5.22,37,27.18,76.36a599,599,0,0,0,113.75,145.12,622.48,622.48,0,0,0,319.36,158.17l158.33,2.48,46.22-7.13c170.18-32.26,318-136.41,401.74-279.06,25.73-34.6,36.67-67.49,39.45-96.53l636.93-.34c58.23,6.45,116.46-9.83,162.68-46.75,46.07-36.69,75.19-89.22,82.39-154.35C2876.22,2742.09,2860.17,2686.79,2835.25,2654.6ZM1025.67,622.75a136.31,136.31,0,0,1-1.05-17.4C1025.07,611.12,1025.37,616.9,1025.67,622.75ZM373.9,2692.86q66.19-110.74,132.52-220.45A1228.25,1228.25,0,0,0,690,1814.86c-.6-64.76-.45-129.36-.15-194l.15-101.9C682.91,1195.36,882.5,907,1187.15,799.54c95-27.16,157.13-121.18,144.07-208.08-4.2-69.63-2.7-140.69,2.7-198.54,10.66-57,65.89-94.25,122.76-83.82,48.48,9.08,83.59,50,85.24,98.15,1.81,63.26,1.5,126.81.15,190.29A191.06,191.06,0,0,0,1683.74,798c221.21,76.23,394.55,254.52,465.08,479.55,23.71,73.31,35.42,149.48,35.12,229,1.35,104.9.75,209.87,0,315.15a1221.48,1221.48,0,0,0,179.63,643.81L2500,2692.26Zm2199.18,120.59,6.9,11.7A70.48,70.48,0,0,1,2573.08,2813.45Z"-->
                <!--                fill="white"/>-->
                <!--              <circle [class]="managerService.notification_status | managerPipe"-->
                <!--                      cx="2195.78" cy="1427.23" r="742.88"/>-->
                <!--            </svg>-->
                <!--          </svg>-->
                <!--        </button>-->
                <button (click)="accountButtonClick()"
                        *ngIf="(managerService.active_mobile_navbar_panel | managerPipe: managerService.mpa_template.ACTIVE_MOBILE_NAVBAR_PANEL_ACCOUNT)"
                        class="btn-native btn-simple navbar-native-button"
                        style="padding: 0;"
                        type="button">
                    <svg style="height: 28px; width: 28px;">
                        <use [attr.xlink:href]='"./assets/images/hamburger_icon.svg#hamburger_icon"' fill="#f3f5f9"/>
                    </svg>
                </button>
            </div>
            <div [class.invisible]="!managerService.account_navbar_menu_display_status | managerPipe"
                 [style]="(!managerService.account_navbar_menu_display_status | managerPipe) ? {transform: 'translateX(100%)'} : {transform: 'translateX(0)'}"
                 class="h-100 navbar-native-menu position-fixed vw-100" id="account-navbar-menu"
                 style="background-color: rgba(0,0,0, 0.75); z-index: 200; transition: 0.3s; right: 0;">
                <div class="navbar-native-menu">
                    <button (click)="this.managerService.account_navbar_menu_display_status = !this.managerService.account_navbar_menu_display_status;"
                            class="navbar-native-item btn-native"
                            type="button">
                        <svg style="height: 28px; width: 28px; margin: 0 8px">
                            <use [attr.xlink:href]='"./assets/images/hamburger_icon.svg#hamburger_icon"' fill="#f3f5f9"/>
                        </svg>
                        {{managerService.config.HEADER.MENU | managerPipe}}
                    </button>
                    <span style="height: 1px; width: 90%; background-color: rgba(50,53,72, 1); margin: 0 auto"></span>
                    <button
                        (click)="this.managerService.navigationManager({url: '/admin-panel', current_component_index: 0});  this.managerService.account_navbar_menu_display_status = !this.managerService.account_navbar_menu_display_status;"
                        *ngIf="managerService.userService.is_admin | managerPipe" class="navbar-native-item btn-native"
                        style="width: 120px" type="button">
                        <svg style="height: 20px; width: 20px; margin: 0 10px;">
                            <use [attr.xlink:href]='"./assets/images/admin.svg#admin"' fill="#f3f5f9"/>
                        </svg>
                        {{managerService.config.HEADER.ADMIN | managerPipe}}
                    </button>
                    <button
                        (click)="this.managerService.navigationManager({url: '/account/dashboard', current_component_index: 0}); this.managerService.account_navbar_menu_display_status = !this.managerService.account_navbar_menu_display_status;"
                        class="navbar-native-item btn-native"
                        type="button">
                        <svg style="height: 20px; width: 20px; margin: 0 10px;">
                            <use [attr.xlink:href]='"./assets/images/dashboard.svg#dashboard"' fill="#f3f5f9"/>
                        </svg>
                        {{managerService.config.HEADER.DASHBOARD | managerPipe}}
                    </button>
                    <button
                        (click)="managerService.navigationManager({url: '/account/user-info', current_component_index: 0}); this.managerService.account_navbar_menu_display_status = !this.managerService.account_navbar_menu_display_status;"
                        class="navbar-native-item btn-native"
                        type="button">
                        <svg style="height: 20px; width: 20px; margin: 0 10px;">
                            <use [attr.xlink:href]='"./assets/images/user_information.svg#user_information"' fill="#f3f5f9"/>
                        </svg>
                        {{managerService.config.HEADER.USER_INFORMATION | managerPipe}}
                    </button>
                    <button
                        (click)="this.managerService.navigationManager({url: '/account/balance', current_component_index: 0}); this.managerService.account_navbar_menu_display_status = !this.managerService.account_navbar_menu_display_status;"
                        class="navbar-native-item btn-native"
                        type="button">
                        <svg style="height: 20px; width: 20px; margin: 0 10px;">
                            <use [attr.xlink:href]='"./assets/images/wallet_white.svg#wallet_white"' fill="#f3f5f9"/>
                        </svg>
                        {{managerService.config.HEADER.WALLET | managerPipe}}
                    </button>
                    <button
                        (click)="this.managerService.navigationManager({url: '/account/deposit', current_component_index: 0}); this.managerService.account_navbar_menu_display_status = !this.managerService.account_navbar_menu_display_status;"
                        class="navbar-native-item btn-native"
                        type="button">
                        <svg style="height: 20px; width: 20px; margin: 0 10px;">
                            <use [attr.xlink:href]='"./assets/images/deposit_white.svg#deposit_white"' fill="#f3f5f9"/>
                        </svg>
                        {{managerService.config.HEADER.DEPOSIT | managerPipe}}
                    </button>
                    <button
                        (click)="this.managerService.navigationManager({url: '/account/withdrawal', current_component_index: 0}); this.managerService.account_navbar_menu_display_status = !this.managerService.account_navbar_menu_display_status;"
                        class="navbar-native-item btn-native"
                        type="button">
                        <svg style="height: 20px; width: 20px; margin: 0 10px;">
                            <use [attr.xlink:href]='"./assets/images/withdrawal_white.svg#withdrawal_white"' fill="#f3f5f9"/>
                        </svg>
                        {{managerService.config.HEADER.WITHDRAW | managerPipe}}
                    </button>
                    <button
                        (click)="managerService.navigationManager({url: '/account/auction', current_component_index: 0});  this.managerService.account_navbar_menu_display_status = !this.managerService.account_navbar_menu_display_status;"
                        class="navbar-native-item btn-native"
                        type="button">
                        <svg style="height: 20px; width: 20px; margin: 0 10px;">
                            <use [attr.xlink:href]='"./assets/images/gavel_white.svg#gavel_white"' fill="none"
                                 stroke="var(--text-color-artiox-white, #FFFFFF)"/>
                        </svg>
                        {{managerService.config.HEADER.AUCTION | managerPipe}}
                    </button>
                    <button
                        (click)="this.managerService.navigationManager({url: '/account/security', active_security_panel: managerService.active_security_panel_template.CHANGE_PASSWORD, current_component_index: 0}); this.managerService.account_navbar_menu_display_status = !this.managerService.account_navbar_menu_display_status;"
                        class="navbar-native-item btn-native"
                        type="button">
                        <svg style="height: 20px; width: 20px; margin: 0 10px;">
                            <use [attr.xlink:href]='"./assets/images/security_white.svg#security_white"' fill="#f3f5f9"/>
                        </svg>
                        {{managerService.config.HEADER.SECURITY | managerPipe}}
                    </button>
                    <button
                        (click)="managerService.logout(); this.managerService.account_navbar_menu_display_status = !this.managerService.account_navbar_menu_display_status;"
                        class="navbar-native-item btn-native"
                        type="button">
                        <svg style="height: 20px; width: 20px; margin: 0 10px;">
                            <use [attr.xlink:href]='"./assets/images/logout_white.svg#logout_white"' fill="#f3f5f9"/>
                        </svg>
                        {{managerService.config.HEADER.LOGOUT | managerPipe}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>


<!--<div *ngIf="managerService.environment.app | managerPipe" id="app-header" class="fixed-top" style="z-index: 300;">-->
<!--  <div *ngIf="managerService.environment.platform === 'ios'" style="height: env(safe-area-inset-top); background-color: #1e1f25;"></div>-->
<!--  <div style="height: 56px;">-->
<!--    <div class="h-100 w-100 d-flex flex-row position-relative"-->
<!--         style="background-color: #1e1f25; box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.5); z-index: 1;">-->
<!--      <div class="w-50 d-flex flex-row align-self-center justify-content-start">-->
<!--        <svg (click)="headerIconClick()" [class.d-none]="(!managerService.environment.app || managerService.navigationService.navigation_history.length !== 0) | managerPipe"-->
<!--             class="mx-4"-->
<!--             style="height:56px; width: 100px; min-width: 100px;">-->
<!--          <use [attr.xlink:href]="'./assets/images/artiox_logo.svg#artiox_logo'" class="w-100" fill="white"/>-->
<!--        </svg>-->
<!--        <svg (click)="headerIconClick()" [class.d-none]="(managerService.environment.app && managerService.navigationService.navigation_history.length === 0) | managerPipe"-->
<!--             class="mx-4"-->
<!--             style="height:56px; width: 24px; min-width: 24px;">-->
<!--          <use [attr.xlink:href]="'./assets/images/back_arrow.svg#back_arrow'" class="w-100" fill="white"/>-->
<!--        </svg>-->
<!--        <div-->
<!--            (click)="managerService.exchangeNavigate(managerService.pairService.getLastNotListedPair())"-->
<!--          *ngIf="managerService.navigationService.current_component_index === 0 && managerService.pairService.active_featured_pair_ready && ((managerService.pair_pipe | managerPipe: managerService.mpa_template.GET_NOT_LISTED_PAIR_LIST).length) | managerPipe"-->
<!--          class="d-flex flex-column align-items-center justify-content-center"-->
<!--          style="height: 56px;">-->
<!--          <svg id="pre_sale_mobile" style="height: 24px; width: 24px;" viewBox="0 0 141.73 141.73" xmlns="http://www.w3.org/2000/svg">-->
<!--            <defs>-->
<!--              <linearGradient gradientUnits="userSpaceOnUse" id="pre-sale-mobile" x1="-28.77" x2="197.41" y1="82.54" y2="56.4">-->
<!--                <stop offset="0.08" stop-color="#d7035f"/>-->
<!--                <stop offset="0.36" stop-color="#ba5196"/>-->
<!--                <stop offset="0.7" stop-color="#98add7"/>-->
<!--                <stop offset="0.85" stop-color="#8bd2f1"/>-->
<!--              </linearGradient>-->
<!--            </defs>-->
<!--            <path-->
<!--              d="M141,35.57c-.27,6.12-.49,12.25-.83,18.37a32,32,0,0,1-9.8,22C112,93.56,93.48,111,75,128.54c-3.28,3.11-6.53,6.28-9.86,9.33a14,14,0,0,1-20.34-1Q24.51,114.09,4.3,91.28c-6.09-6.88-5.67-15.68,1-22Q37.62,38.65,70,8.09A28.58,28.58,0,0,1,90.13,0c10.82.08,21.63.77,32.44,1.23a59.8,59.8,0,0,1,7.46.52,14.36,14.36,0,0,1,11.7,14.71c-.08,3.18-.31,6.36-.38,9.55M135,32.35c.12-3.33.38-9.46.55-15.59.14-5.2-3.19-8.84-8.24-9.1-2-.1-3.92-.11-5.88-.19-10.1-.44-20.2-1.06-30.31-1.26a24.7,24.7,0,0,0-17.68,7.06c-6.7,6.26-13.33,12.6-20,18.9q-22.14,21-44.28,42c-3.81,3.61-4.08,8.76-.67,12.59q20.07,22.58,40.18,45.12c4,4.54,8.85,4.76,13.27.57q32.19-30.53,64.31-61.09A25.45,25.45,0,0,0,134,54.8C134.61,48.27,134.64,41.7,135,32.35Z"-->
<!--              fill="url(#pre-sale-mobile)"/>-->
<!--            <path d="M114.82,35a6.37,6.37,0,0,1-6.52-6.52A6.51,6.51,0,1,1,114.82,35Z" fill="#a4bce3"/>-->
<!--          </svg>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="w-50 d-flex justify-content-end py-2 mr-4">-->
<!--        <button (click)="accountButtonClick(); languages.classList.add('d-none')"-->
<!--                class="btn-native btn-simple navbar-native-button"-->
<!--                style="padding: 0;" type="button">-->
<!--          <svg style="height: 28px; width: 28px;">-->
<!--            <use [attr.xlink:href]='"./assets/images/account.svg#account"' fill="#f3f5f9"/>-->
<!--          </svg>-->
<!--        </button>-->
<!--        &lt;!&ndash;        <div *ngIf="managerService.logged_in | managerPipe">&ndash;&gt;-->
<!--        &lt;!&ndash;          <button (click)="notificationButtonClick()"&ndash;&gt;-->
<!--        &lt;!&ndash;                  *ngIf="!(managerService.active_mobile_navbar_panel | managerPipe: managerService.mpa_template.ACTIVE_MOBILE_NAVBAR_PANEL_ACCOUNT)"&ndash;&gt;-->
<!--        &lt;!&ndash;                  class="btn-native btn-simple navbar-native-button"&ndash;&gt;-->
<!--        &lt;!&ndash;                  style="padding: 0;"&ndash;&gt;-->
<!--        &lt;!&ndash;                  type="button">&ndash;&gt;-->
<!--        &lt;!&ndash;            <svg style="height: 28px; width: 28px;">&ndash;&gt;-->
<!--        &lt;!&ndash;              <svg id="notification_icon" viewBox="0 0 2873 3382" xmlns="http://www.w3.org/2000/svg">&ndash;&gt;-->
<!--        &lt;!&ndash;                <path&ndash;&gt;-->
<!--        &lt;!&ndash;                  d="M2835.25,2654.6l-208.9-348.4a915.73,915.73,0,0,1-135.06-482.71c.75-106.93,1.35-214,0-318.16a1025.3,1025.3,0,0,0-49.68-321c-91.84-293.77-309.9-532.31-591.29-651.47q.67-67.08-1.2-135C1842.37,203.53,1704.3,42.8,1513.26,7,1289.8-34.57,1074,112.66,1030,348.72a1557.52,1557.52,0,0,0-7.95,185.34c-394.69,167.78-648.92,557.15-639.46,988.23l-.15,97.55c-.3,66-.45,131.84.15,197.8,1.5,174.38-46.07,344.94-138.37,494.41q-103.32,170.64-204.85,342.1c-31.06,44.49-44.87,99.79-38.12,153.29,13.66,111.43,108.81,193.3,218.36,193.3,5,0,9.9-.15,15-.53l649.5-.34q5.22,37,27.18,76.36a599,599,0,0,0,113.75,145.12,622.48,622.48,0,0,0,319.36,158.17l158.33,2.48,46.22-7.13c170.18-32.26,318-136.41,401.74-279.06,25.73-34.6,36.67-67.49,39.45-96.53l636.93-.34c58.23,6.45,116.46-9.83,162.68-46.75,46.07-36.69,75.19-89.22,82.39-154.35C2876.22,2742.09,2860.17,2686.79,2835.25,2654.6ZM1025.67,622.75a136.31,136.31,0,0,1-1.05-17.4C1025.07,611.12,1025.37,616.9,1025.67,622.75ZM373.9,2692.86q66.19-110.74,132.52-220.45A1228.25,1228.25,0,0,0,690,1814.86c-.6-64.76-.45-129.36-.15-194l.15-101.9C682.91,1195.36,882.5,907,1187.15,799.54c95-27.16,157.13-121.18,144.07-208.08-4.2-69.63-2.7-140.69,2.7-198.54,10.66-57,65.89-94.25,122.76-83.82,48.48,9.08,83.59,50,85.24,98.15,1.81,63.26,1.5,126.81.15,190.29A191.06,191.06,0,0,0,1683.74,798c221.21,76.23,394.55,254.52,465.08,479.55,23.71,73.31,35.42,149.48,35.12,229,1.35,104.9.75,209.87,0,315.15a1221.48,1221.48,0,0,0,179.63,643.81L2500,2692.26Zm2199.18,120.59,6.9,11.7A70.48,70.48,0,0,1,2573.08,2813.45Z"&ndash;&gt;-->
<!--        &lt;!&ndash;                  fill="white"/>&ndash;&gt;-->
<!--        &lt;!&ndash;                <circle [class]="managerService.notification_status | managerPipe"&ndash;&gt;-->
<!--        &lt;!&ndash;                        cx="2195.78" cy="1427.23" r="742.88"/>&ndash;&gt;-->
<!--        &lt;!&ndash;              </svg>&ndash;&gt;-->
<!--        &lt;!&ndash;            </svg>&ndash;&gt;-->
<!--        &lt;!&ndash;          </button>&ndash;&gt;-->
<!--        &lt;!&ndash;          <button (click)="accountButtonClick()"&ndash;&gt;-->
<!--        &lt;!&ndash;                  *ngIf="(managerService.active_mobile_navbar_panel | managerPipe: managerService.mpa_template.ACTIVE_MOBILE_NAVBAR_PANEL_ACCOUNT)"&ndash;&gt;-->
<!--        &lt;!&ndash;                  class="btn-native btn-simple navbar-native-button"&ndash;&gt;-->
<!--        &lt;!&ndash;                  style="padding: 0;"&ndash;&gt;-->
<!--        &lt;!&ndash;                  type="button">&ndash;&gt;-->
<!--        &lt;!&ndash;            <svg style="height: 28px; width: 28px;">&ndash;&gt;-->
<!--        &lt;!&ndash;              <use [attr.xlink:href]='"assets/images/hamburger_icon.svg#hamburger_icon"' fill="#f3f5f9"/>&ndash;&gt;-->
<!--        &lt;!&ndash;            </svg>&ndash;&gt;-->
<!--        &lt;!&ndash;          </button>&ndash;&gt;-->
<!--        &lt;!&ndash;        </div>&ndash;&gt;-->
<!--        <div [class.invisible]="!managerService.account_navbar_menu_display_status | managerPipe"-->
<!--             [style]="(!managerService.account_navbar_menu_display_status | managerPipe) ? {'background-color': 'transparent'} : {'background-color': 'rgba(0,0,0, 0.75)'}"-->
<!--             class=" h-100 navbar-native-menu position-fixed vw-100" id="account-navbar-menu"-->
<!--             style="z-index: 200; transition: background-color 0.3s ease-in-out; right: 0;">-->
<!--          <div [style]="(!managerService.account_navbar_menu_display_status | managerPipe) ? {transform: 'translateX(100%)'} : {transform: 'translateX(0)'}"-->
<!--               class="navbar-native-menu overflow-auto"-->
<!--               style="transition: 0.3s;">-->
<!--            <div *ngIf="managerService.environment.platform === 'ios'" style="height: env(safe-area-inset-top); background-color: inherit;"></div>-->
<!--            <button (click)="this.managerService.account_navbar_menu_display_status = !this.managerService.account_navbar_menu_display_status;"-->
<!--                    *ngIf="managerService.logged_in | managerPipe" class="navbar-native-item btn-native" -->
<!--                    type="button" style="min-height: 42px">-->
<!--              <svg style="height: 24px; width: 24px; margin: 0 8px">-->
<!--                <use [attr.xlink:href]='"./assets/images/account.svg#account"' fill="#f3f5f9"/>-->
<!--              </svg>-->
<!--              {{managerService.user_pipe | managerPipe : managerService.mpa_template.GET_USER_EMAIL_SHORTENED}}-->
<!--            </button>-->
<!--            <button-->
<!--              (click)="managerService.openModal('login-modal'); this.managerService.account_navbar_menu_display_status = !this.managerService.account_navbar_menu_display_status;"-->
<!--              *ngIf="! managerService.logged_in | managerPipe" class="navbar-native-item btn-native"  style="min-height: 42px"-->
<!--              type="button">-->
<!--              <svg style="height: 24px; width: 24px; margin: 0 8px">-->
<!--                <use [attr.xlink:href]='"./assets/images/account.svg#account"' fill="#f3f5f9"/>-->
<!--              </svg>-->
<!--              {{managerService.config.HEADER.LOGIN | managerPipe}}-->
<!--            </button>-->
<!--            <span style="height: 1px; width: 90%; background-color: rgba(50,53,72, 1); margin: 0 auto"></span>-->
<!--            <button (click)="this.managerService.navigationManager({url: '/admin-panel', current_component_index: 0}); this.managerService.account_navbar_menu_display_status = !this.managerService.account_navbar_menu_display_status;"-->
<!--                    *ngIf="managerService.userService.is_admin | managerPipe" class="navbar-native-item btn-native" style="min-height: 42px; width: 120px;"-->
<!--                    type="button">-->
<!--              <svg style="height: 20px; width: 20px; margin: 0 10px">-->
<!--                <use [attr.xlink:href]='"./assets/images/admin.svg#admin"' fill="#f3f5f9"/>-->
<!--              </svg>-->
<!--              {{managerService.config.HEADER.ADMIN | managerPipe}}-->
<!--            </button>-->
<!--            <button (click)="managerService.navigateIfLoggedIn({url: '/account/dashboard', current_component_index: 0});"-->
<!--                    class="navbar-native-item btn-native"  style="min-height: 42px"-->
<!--                    type="button">-->
<!--              <svg style="height: 24px; width: 24px; margin: 0 8px">-->
<!--                <use [attr.xlink:href]='"./assets/images/account.svg#account"' stroke="#f3f5f9"/>-->
<!--              </svg>-->
<!--              {{managerService.config.HEADER.DASHBOARD | managerPipe}}-->
<!--            </button>-->
<!--            <button (click)="managerService.navigateIfLoggedIn({url: '/account-level', current_component_index: 0});"-->
<!--                    class="navbar-native-item btn-native" style="min-height: 42px"-->
<!--                    type="button">-->
<!--              <svg style="height: 24px; width: 24px; margin: 0 8px">-->
<!--                <use [attr.xlink:href]='"./assets/images/account_level.svg#account_level"' fill="#f3f5f9"/>-->
<!--              </svg>-->
<!--              {{managerService.config.HEADER.ACCOUNT_LEVEL | managerPipe}}-->
<!--            </button>-->
<!--            <button (click)="managerService.navigateIfLoggedIn({url: '/account/deposit', current_component_index: 0});"-->
<!--                    class="navbar-native-item btn-native"  style="min-height: 42px"-->
<!--                    type="button">-->
<!--              <svg style="height: 20px; width: 20px; margin: 0 10px">-->
<!--                <use [attr.xlink:href]='"./assets/images/deposit.svg#deposit"' fill="#f3f5f9"/>-->
<!--              </svg>-->
<!--              {{managerService.config.HEADER.DEPOSIT | managerPipe}}-->
<!--            </button>-->
<!--            <button (click)="managerService.navigateIfLoggedIn({url: '/account/withdrawal', current_component_index: 0});"-->
<!--                    class="navbar-native-item btn-native"  style="min-height: 42px"-->
<!--                    type="button">-->
<!--              <svg style="height: 20px; width: 20px; margin: 0 10px">-->
<!--                <use [attr.xlink:href]='"./assets/images/withdrawal_white.svg#withdrawal_white"' fill="#f3f5f9"/>-->
<!--              </svg>-->
<!--              {{managerService.config.HEADER.WITHDRAW | managerPipe}}-->
<!--            </button>-->
<!--            <button (click)="managerService.navigateIfLoggedIn({url: '/account/security', active_security_panel: managerService.active_security_panel_template.CHANGE_PASSWORD, current_component_index: 0});"-->
<!--                    class="navbar-native-item btn-native" style="min-height: 42px"-->
<!--                    type="button">-->
<!--              <svg style="height: 24px; width: 24px; margin: 0 8px">-->
<!--                <use [attr.xlink:href]='"./assets/images/security.svg#security"' fill="#f3f5f9"/>-->
<!--              </svg>-->
<!--              {{managerService.config.HEADER.SECURITY | managerPipe}}-->
<!--            </button>-->
<!--            <div *ngIf="!managerService.config.ZENDESK_ACTIVE | managerPipe">-->
<!--              <button-->
<!--                (click)="this.managerService.navigationManager({url: '/guidebook', current_component_index: 0}); this.managerService.account_navbar_menu_display_status = !this.managerService.account_navbar_menu_display_status;"-->
<!--                class="navbar-native-item btn-native" type="button">-->
<!--                <svg style="height: 20px; width: 20px; margin: 0 10px">-->
<!--                  <use [attr.xlink:href]='"./assets/images/guidebook.svg#guidebook"' fill="#f3f5f9"/>-->
<!--                </svg>-->
<!--                {{managerService.config.HEADER.GUIDEBOOK | managerPipe}}-->
<!--              </button>-->
<!--            </div>-->
<!--            <div *ngIf="managerService.config.ZENDESK_ACTIVE | managerPipe">-->
<!--              <button (click)="managerService.navigationManager(managerService.config.HEADER.ARTIOX_LINK); this.managerService.account_navbar_menu_display_status = !this.managerService.account_navbar_menu_display_status;"-->
<!--                      class="navbar-native-item btn-native" type="button"  style="min-height: 42px">-->
<!--                <svg style="height: 20px; width: 20px; margin: 0 10px">-->
<!--                  <use [attr.xlink:href]='"./assets/images/artiox_icon.svg#artiox_icon"' fill="#f3f5f9"/>-->
<!--                </svg>-->
<!--                {{managerService.config.HEADER.ARTIOX | managerPipe}}-->
<!--              </button>-->
<!--              <button (click)="managerService.navigationManager(managerService.config.HEADER.ANNOUNCEMENTS_LINK); this.managerService.account_navbar_menu_display_status = !this.managerService.account_navbar_menu_display_status;"-->
<!--                      class="navbar-native-item btn-native" type="button" style="min-height: 42px">-->
<!--                <svg style="height: 20px; width: 20px; margin: 0 10px">-->
<!--                  <use [attr.xlink:href]='"./assets/images/announcement.svg#announcement"' fill="#f3f5f9"/>-->
<!--                </svg>-->
<!--                {{managerService.config.HEADER.ANNOUNCEMENTS | managerPipe}}-->
<!--              </button>-->
<!--              <button (click)="managerService.navigationManager(managerService.config.HEADER.BLOG_LINK); this.managerService.account_navbar_menu_display_status = !this.managerService.account_navbar_menu_display_status;"-->
<!--                      class="navbar-native-item btn-native" type="button" style="min-height: 42px">-->
<!--                <svg style="height: 20px; width: 20px; margin: 0 10px">-->
<!--                  <use [attr.xlink:href]='"./assets/images/blog.svg#blog"' fill="#f3f5f9"/>-->
<!--                </svg>-->
<!--                {{managerService.config.HEADER.BLOG | managerPipe}}-->
<!--              </button>-->
<!--              <button (click)="managerService.navigationManager(managerService.config.HEADER.ABOUT_ARTISTS_LINK); this.managerService.account_navbar_menu_display_status = !this.managerService.account_navbar_menu_display_status;"-->
<!--                      class="navbar-native-item btn-native" type="button" style="min-height: 42px">-->
<!--                <svg style="height: 20px; width: 20px; margin: 0 10px">-->
<!--                  <use [attr.xlink:href]='"./assets/images/about_artists.svg#about_artists"' fill="#f3f5f9"/>-->
<!--                </svg>-->
<!--                {{managerService.config.HEADER.ABOUT_ARTISTS | managerPipe}}-->
<!--              </button>-->
<!--              <button (click)="managerService.navigationManager(managerService.config.HEADER.SUPPORT_LINK); this.managerService.account_navbar_menu_display_status = !this.managerService.account_navbar_menu_display_status;"-->
<!--                      class="navbar-native-item btn-native" type="button" style="min-height: 42px">-->
<!--                <svg style="height: 20px; width: 20px; margin: 0 10px">-->
<!--                  <use [attr.xlink:href]='"./assets/images/support.svg#support"' fill="#f3f5f9"/>-->
<!--                </svg>-->
<!--                {{managerService.config.HEADER.SUPPORT | managerPipe}}-->
<!--              </button>-->
<!--            </div>-->
<!--            <button (click)="languages.classList.toggle('d-none')" class="navbar-native-item btn-native" type="button"  style="min-height: 42px">-->
<!--              <svg style="height: 20px; width: 20px; margin: 0 10px">-->
<!--                <use [attr.xlink:href]='"./assets/images/language.svg#language"' fill="#f3f5f9"/>-->
<!--              </svg>-->
<!--              {{managerService.config.HEADER.CHANGE_LANGUAGE | managerPipe}}-->
<!--            </button>-->
<!--            <div #languages class="d-none">-->
<!--              <button-->
<!--                (click)="managerService.switchLanguage(); this.managerService.account_navbar_menu_display_status = !this.managerService.account_navbar_menu_display_status;"-->
<!--                *ngFor="let language of managerService.config.HEADER.LANGUAGES | managerPipe"-->
<!--                [disabled]="managerService.language === language.SHORT | managerPipe"-->
<!--                [style.background-color]="((managerService.language === language.SHORT) | managerPipe) ? '#2a2b32' : ''"-->
<!--                class="navbar-native-item btn-native" style="height: 38px; font-size: 15px" type="button">-->
<!--                <span class="ml-4">{{language.SHORT}}</span>-->
<!--                <span class="ml-2">{{language.FULL_NAME}}</span>-->
<!--              </button>-->
<!--            </div>-->
<!--            <span *ngIf="managerService.logged_in | managerPipe" style="height: 1px; width: 90%; background-color: rgba(50,53,72, 1); margin: 0 auto"></span>-->
<!--            <button-->
<!--              (click)="managerService.logout(); this.managerService.account_navbar_menu_display_status = !this.managerService.account_navbar_menu_display_status;"-->
<!--              *ngIf="managerService.logged_in | managerPipe" class="navbar-native-item btn-native" -->
<!--              type="button" style="min-height: 42px">-->
<!--              <svg style="height: 20px; width: 20px; margin: 0 8px 0 12px; color:#f3f5f9 ">-->
<!--                <use [attr.xlink:href]='"./assets/images/logout.svg#logout"' fill="#f3f5f9"/>-->
<!--              </svg>-->
<!--              {{managerService.config.HEADER.LOGOUT | managerPipe}}-->
<!--            </button>-->
<!--            <div class="d-flex flex-grow-1"></div>-->
<!--            <div style="font-size: 9px;font-weight: 300;color: #9da5b7; margin: 0 0 2px 14px;">{{managerService.config.HEADER.VERSION | managerPipe}}{{managerService.environment.version | managerPipe}}</div>-->
<!--            <div style="height: calc(env(safe-area-inset-bottom));"></div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div *ngIf="(!managerService.logged_in && managerService.show_sign_up_or_login) | managerPipe"-->
<!--       class="text-center"-->
<!--       style="padding: 12px 0; color: var(&#45;&#45;artiox-blue); background-color: #2f3449">-->
<!--    <span (click)="managerService.openModal('login-modal');" style="font-size: 16px;">{{managerService.config.HEADER.LOGIN | managerPipe}}</span>-->
<!--    <span style="font-size: 12px; color: #f4f5f9;">{{managerService.config.HEADER.OR | managerPipe}}</span>-->
<!--    <span (click)="managerService.navigationManager({url: '/sign-up', current_component_index: 0})" style="font-size: 16px;">{{managerService.config.HEADER.SIGNUP | managerPipe}}</span>-->
<!--  </div>-->
<!--</div>-->
