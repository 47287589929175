<div class="vw-100 min-vw-100 d-flex flex-column align-items-center justify-content-start"
     style="height: fit-content; margin: 16px!important;">
  <p [innerHTML]="managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_ARTIST_ABOUT_UPPERCASE: managerService.language_pipe"
     class="mt-3"
     style="font-family: bebas-neue-bold, sans-serif; font-size: 22px; letter-spacing: 1px; color: white; text-align: center!important; margin-bottom: 4px!important;"></p>
  <p *ngIf="managerService.pairService.active_pair_ready | managerPipe"
     class="mb-4 mx-3" style="font-family: Roboto, sans-serif; font-size: 14px; color: white; margin-top: 4px!important;">
    <span [innerHTML]="managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_PAIR_INFO: managerService.language_pipe"></span>
    <a class="clarification-text" *ngIf="(managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_PAIR_ROUTER_LINK: managerService.language_pipe); let item;" (click)="managerService.navigationManager(item.LINK);">{{item.TEXT | managerPipe}}</a>
  </p>
</div>

