import {Component, OnDestroy, OnInit} from '@angular/core';
import {ManagerService} from '../../../services/manager.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit, OnDestroy {

  public sign_up_successful: boolean = false;  // obsolete

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
    this.managerService.show_sign_up_or_login = false;
    if (this.managerService.checkURLPrefix('/sign-up/successful')) {
      this.sign_up_successful = true;
    }
  }

  ngOnDestroy(): void {
    this.managerService.show_sign_up_or_login = true;
  }

}
