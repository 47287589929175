import { Component, OnInit } from '@angular/core';
import {ManagerService} from '../../../../services/manager.service';

@Component({
  selector: 'app-about-artist-desktop',
  templateUrl: './about-artist-desktop.component.html',
  styleUrls: ['./about-artist-desktop.component.css']
})
export class AboutArtistDesktopComponent implements OnInit {

  constructor(public managerService: ManagerService) { }

  ngOnInit(): void {
  }

}
