<div class="w-100 d-flex flex-row align-items-start justify-content-start mt-2 mb-1"
     style="font-family: Roboto, sans-serif;font-size: 14px; letter-spacing: 0.9px; color: #404352;">
  <span class="font-weight-bold" style="padding-left: 34px;">
    <div class="d-flex flex-column align-items-center justify-content-center">
      <div class="d-flex flex-row align-items-center justify-content-center">
          <app-tooltip [direction]="managerService.tooltipService.tooltip_direction_template.TOP | managerPipe" class="d-none position-relative" id="trade-history-header-tooltip">
            {{managerService.config.TOOLTIP.ADVANCED.TRADE_HISTORY_HEADER_TOOLTIP | managerPipe}}
          </app-tooltip>
      </div>
      <div class="subtitle2 exo bold" style="line-height: 2">
        {{managerService.config.ADVANCED.TRADE_HISTORY.HEADER}}
      </div>
    </div>
  </span>
</div>
<div class="d-flex flex-row align-items-center justify-content-around py-1 body2 exo bold" style=" color: #404352;">
  <span class="text-center" style="width: 33%">{{managerService.config.ADVANCED.TRADE_HISTORY.PRICE | managerPipe}}</span>
  <span class="text-center" style="width: 34%">{{managerService.config.ADVANCED.TRADE_HISTORY.AMOUNT | managerPipe}}</span>
  <span class="text-center" style="width: 33%">{{managerService.config.ADVANCED.TRADE_HISTORY.TIME | managerPipe}}</span>
</div>
<div class="d-flex flex-column overflow-auto" style="min-height: 210px; max-height: 210px;">
  <div *ngFor="let trade of (managerService.trade_history_pipe | managerPipe: managerService.mpa_template.GET_TRADE_HISTORY)" class="d-flex flex-row align-items-center justify-content-around py-1 caption barlow medium"
       style=" color: #4a4e70; line-height: 1.3; min-height: 23px;">
    <span [style]="trade | managerPipe: managerService.mpa_template.GET_TRADE_HISTORY_BACKGROUND_COLOR" class="text-center"
          style="width: 33%">{{trade.price | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
    <span class="text-center" style="width: 34%">{{trade.amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
    <span class="text-center" style="width: 33%; color: #9da5b7;">{{trade.creation_time | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_TIME}}</span>
  </div>
</div>
