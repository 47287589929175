import { Component, OnInit } from '@angular/core';
import {ManagerService} from '../../../../services/manager.service';

@Component({
  selector: 'app-page-not-found-mobile',
  templateUrl: './page-not-found-mobile.component.html',
  styleUrls: ['./page-not-found-mobile.component.css']
})
export class PageNotFoundMobileComponent implements OnInit {

  constructor(public managerService: ManagerService) { }

  ngOnInit(): void {
  }

  backToHome(): void {
    if (this.managerService.logged_in || !this.managerService.utilityService.checkReleaseUrl()) {
      this.managerService.navigationManager({url: '/home', current_component_index: 0});
    } else {
      window.open('https://tr.artiox.com/', '_self');
    }
    this.managerService.navigationService.clearNavigationHistory();
  }

}
