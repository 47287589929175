<div *ngIf="((!managerService.logged_in) || (0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO)) | managerPipe"
     class="px-4" style="box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22);  background-color: #fcfdff; min-height: 650px">
  <div class="pt-3 mb-2" style="font-family: Roboto, sans-serif; font-size: 20px; font-weight: bold; line-height: 2; letter-spacing: 1px; color: #4a4e70;">
    {{managerService.config.DEPOSIT.NO_KYC_HEADING | managerPipe}}
  </div>
  <div class="my-2" style="font-family: Roboto, sans-serif; font-size: 14px; color: #4a4e70;">
    {{managerService.config.DEPOSIT.NO_KYC_DESCRIPTION | managerPipe}}
  </div>

  <button (click)="managerService.navigationManager({url: '/account/security', active_security_panel: managerService.active_security_panel_template.IDENTITY_VERIFICATION, current_component_index: 0});"
          class="btn-native sign-up-login-button mx-4 my-3"
          style="height: 40px; width:160px; border-radius: 20px;
              font-family: Roboto, sans-serif; font-size: 16px; line-height: 1.3; letter-spacing: 0.3px; color: #ffffff;">
    {{managerService.config.DASHBOARD.LEVEL_UP | managerPipe}}
  </button>
</div>

<div *ngIf="(managerService.logged_in && !(0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO)) | managerPipe">
  <div *ngIf="!managerService.config.WITHDRAWAL.TL_SUSPENSION.SUSPENDED | managerPipe"
       class="w-100 d-flex flex-column align-items-start justify-content-start pb-3"
       style="box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff; max-height: 1160px; border-radius: 4px;">
    <div class="w-100 mt-4 ml-5"
         style="font-family: Roboto, sans-serif; font-size: 14px; font-weight: bold; line-height: 2; letter-spacing: 1px; color: #4a4e70;">
      {{managerService.config.WITHDRAWAL.LIMITS | managerPipe}}
    </div>
    <div class="d-flex flex-row align-items-start justify-content-start mt-3 ml-5"
         style="box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff; border-radius: 4px; margin-bottom: 10px;">
      <div class="d-flex flex-column m-3">
      <span class="my-1 text-center"
            style="font-family: 'Roboto Condensed', sans-serif; font-size: 13px; line-height: 1.25; color: #272a3b; letter-spacing: 1px">
        {{managerService.config.WITHDRAWAL.DAILY_WITHDRAWAL_LIMIT | managerPipe}}
      </span>
        <span class="m-1 text-center"
              style="font-family: 'Roboto Condensed', sans-serif; font-size: 14px; line-height: 1.25; color: #272a3b; letter-spacing: 1px">
        {{managerService.userService.user?.daily_fiat_withdrawal_limit | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
          ₺
      </span>
      </div>
      <span
        style="height: 40px; width: 1px; min-width: 2px; margin: auto 10px; border-radius:1px; background-color: #dddddd;"></span>
      <div class="h-100 d-flex flex-column m-3">
      <span class="my-1 text-center"
            style="font-family: 'Roboto Condensed', sans-serif; font-size: 13px; line-height: 1.25; color: #272a3b; letter-spacing: 1px">
        {{managerService.config.WITHDRAWAL.MONTHLY_WITHDRAWAL_LIMIT | managerPipe}}
      </span>
        <span class="m-1 text-center"
              style="font-family: 'Roboto Condensed', sans-serif; font-size: 13px; line-height: 1.25; color: #272a3b; letter-spacing: 1px">
        {{managerService.userService.user?.monthly_fiat_withdrawal_limit | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
          ₺
      </span>
      </div>
    </div>

    <div class="w-100 mt-4 ml-5"
         style="font-family: Roboto, sans-serif; font-size: 14px; font-weight: bold; line-height: 2; letter-spacing: 1px; color: #4a4e70;">
      {{managerService.config.WITHDRAWAL.BANK_ACCOUNT | managerPipe}}
    </div>
    <div class="w-100 mt-2 ml-5" style="margin-bottom: 10px;">
      <div class="w-100 mt-2 ml-3"
           style="font-family: Roboto, sans-serif; font-size: 12px; line-height: 1.2; letter-spacing: 0.6px; color: #272a3b;">
        {{managerService.config.WITHDRAWAL.PLEASE_SELECT_BANK | managerPipe}}
      </div>
      <div class="d-flex flex-row mt-2 ml-3" style="height: 40px;">
        <div class="h-100 bank-account-dropdown-native-button">
          <button class="btn-native"
                  style="width: 250px; padding: 0 4px 0 22px; font-family: Roboto, sans-serif; font-size: 14px; line-height: 1.2; letter-spacing: 0.4px; color: #4a4e70;
                  border-radius: 4px; box-shadow: 1px 1px 2px 0 #dbdbdf; background-color: #ffffff;" type="button">
            {{managerService.config.WITHDRAWAL.SAVED_BANK_ACCOUNTS_PLACEHOLDER | managerPipe}}
            <svg style="height: 40px; width: 20px;">
              <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#4a4e70"/>
            </svg>
          </button>
          <div style="width: 246px; margin-left: 2px; padding-top: 4px">
            <div #bank_account_dropdown_native_menu class="bank-account-dropdown-native-menu">
              <button
                *ngIf="! (managerService.user_bank_accounts_pipe | managerPipe: managerService.mpa_template.GET_USER_BANK_ACCOUNT_LIST)?.length"
                class="position-relative bank-account-dropdown-native-item d-flex flex-column btn-native align-items-center justify-content-around p-2"
                style="width: 246px; font-size: 12px; font-weight: 300;font-style: italic;color: #595e7e; margin: 0; padding: 0">
                <div>
                  {{managerService.config.WITHDRAWAL.NO_SAVED_BANK_ACCOUNT | managerPipe}}
                </div>
              </button>
              <button
                (click)="selectBankAccount(bank_account);"
                *ngFor="let bank_account of (managerService.user_bank_accounts_pipe | managerPipe: managerService.mpa_template.GET_USER_BANK_ACCOUNT_LIST)"
                class="position-relative bank-account-dropdown-native-item d-flex flex-column btn-native align-items-start justify-content-around p-2"
                style="width: 246px; margin: 0; height: fit-content"
                type="button">
                <div
                  style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500; line-height: 1.2; letter-spacing: 0.6px; color: #595e7e; ">
                  {{bank_account.label | managerPipe}}
                </div>
                <div style="font-size: 11px; line-height: 1.3; letter-spacing: 0.5px; color: #abafc7; padding: 4px 0;">
                  {{bank_account.bank | managerPipe}}
                </div>
                <div
                  style="font-family: Roboto, sans-serif; font-size: 12px; line-height: 1.3; letter-spacing: 0.5px; color: #595e7e; ">
                  {{bank_account.iban | managerPipe}}
                </div>
                <div (click)="removeUserBankAccount(bank_account)"
                     class="position-absolute p-1"
                     style="top:0; right:0; font-family: Roboto, sans-serif; font-size: 10px; line-height: 1.25; color: #abafc7;">
                  {{this.managerService.config.WITHDRAWAL.REMOVE_BANK_ACCOUNT | managerPipe}}
                </div>
              </button>
            </div>
          </div>
        </div>
        <button (click)="managerService.openModal('add-bank-account-modal')"
                class="mt-4 ml-3 btn-native"
                style="font-family: Roboto, sans-serif; font-size: 10px; font-weight: 500; line-height: 1; letter-spacing: 0.5px; color: #595e7e;"
                type="button">
          {{managerService.config.WITHDRAWAL.ADD_NEW_BANK_ACCOUNT | managerPipe}}
        </button>
      </div>
      <div *ngIf="managerService.operationsService.selected_user_bank_account_ready | managerPipe" class="mt-4">
        <div class="d-flex flex-row align-items-start justify-content-start p-3"
             style="width: fit-content; border-radius: 4px; box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22);">
          <div class="mx-3">
            <img [src]="managerService.operationsService.selected_user_bank_account.bank | managerPipe: managerService.mpa_template.GET_BANK_ACCOUNT_IMG_SRC" alt="bank" width="150px" #bank
                 (error)="bank.src = bank.src.replace('.webp', '.jpg')">
          </div>
          <div class="d-flex flex-column">
            <div class="my-1"
                 style="font-family: Roboto, sans-serif;font-size: 14px; font-weight: 500; line-height: 1.25; letter-spacing: 0.7px; color: #4a4e70;">
              {{managerService.operationsService.selected_user_bank_account.bank | managerPipe}}
            </div>
            <div class="my-1"
                 style="font-family: Roboto, sans-serif; font-size: 14px; font-weight: 500; line-height: 1.25; letter-spacing: 0.7px; color: #4a4e70;">
              {{managerService.operationsService.selected_user_bank_account.iban | managerPipe}}
            </div>
            <div class="my-1"
                 style="font-family: Roboto, sans-serif; font-size: 14px; line-height: 1.25; color: #4a4e70;">
              {{managerService.operationsService.selected_user_bank_account.name | managerPipe}}
            </div>
            <div class="my-1"
                 style="font-family: Roboto, sans-serif; font-size: 14px; line-height: 1.25; color: #4a4e70;">
              {{managerService.operationsService.selected_user_bank_account.label | managerPipe}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mt-4 ml-5"
         style="font-family: Roboto, sans-serif; font-size: 14px; font-weight: bold; line-height: 2; letter-spacing: 1px; color: #4a4e70; ">
      {{managerService.config.WITHDRAWAL.TRANSACTION | managerPipe}}
    </div>

    <form [formGroup]="bankWithdrawalRequestForm" class="w-100 mt-2 ml-5">
      <label class="ml-2 d-flex flex-row" style="width: 250px; height: 30px;">
        <input class="w-100 input-field-underline text-right" formControlName="amount" min="100.00" placeholder="0"
               required type="number">
        <div class="h-100 d-flex flex-column align-content-center justify-content-end pb-1"
             style="font-family: 'Roboto Condensed', sans-serif;  font-size: 13px; line-height: 1.15; color: #abafc7;">(₺)
        </div>
      </label>
      <div class="mt-2 ml-2" style="font-family: Roboto, sans-serif; font-size: 13px; line-height: 1.25; color: #4a4e70;">
        {{managerService.config.WITHDRAWAL.AVAILABLE_TRY | managerPipe}}
        <strong>{{managerService.pair_pipe | managerPipe: managerService.mpa_template.USER_TRY_CURRENCY_AVAILABLE_AMOUNT: managerService.user_pipe}}
          ₺</strong>
      </div>
      <div class="mt-2 ml-2"
           style="font-family: Roboto, sans-serif; font-size: 13px; font-weight: 300; line-height: 1.2; color: #9da5b7;">
        {{managerService.config.WITHDRAWAL.MINIMUM_FIAT_WITHDRAWAL_AMOUNT | managerPipe}}
      </div>
      <div class="mt-3 ml-1 text-info" style="color: var(--artiox-blue)!important;">
        <svg style="height: 14px; width: 14px; margin-top: -2px">
          <use [attr.xlink:href]='"./assets/images/info.svg#info"' fill="var(--artiox-blue)"/>
        </svg>
        {{managerService.config.WITHDRAWAL.WITHDRAWAL_WARNING | managerPipe}}
      </div>
      <button (click)="managerService.openFiatWithdrawalRequestModal(bankWithdrawalRequestForm.get('amount')?.value);" class="my-5 btn-native" id="withdrawal-request-button">
        {{managerService.config.WITHDRAWAL.WITHDRAWAL_REQUEST | managerPipe}}
      </button>
    </form>
  </div>

  <div *ngIf="managerService.config.WITHDRAWAL.TL_SUSPENSION.SUSPENDED | managerPipe"
       class="w-100 pt-1 pb-3"
       style="height: 400px; box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff; max-height: 1160px; border-radius: 4px;">
    <div class="w-100 mt-4 ml-5"
         style="font-family: Roboto, sans-serif; font-size: 14px; font-weight: bold; line-height: 2; letter-spacing: 1px; color: #4a4e70;"
         [innerHTML]="managerService.config.WITHDRAWAL.TL_SUSPENSION.TITLE | managerPipe">
    </div>

    <div class="w-100 mt-3 ml-5"
         style="font-family: Roboto, sans-serif; font-size: 12px; line-height: 1.7; color: #272a3b;"
         [innerHTML]="managerService.config.WITHDRAWAL.TL_SUSPENSION.DESCRIPTION | managerPipe">
    </div>
  </div>
</div>

<app-withdrawal-history-desktop [currency_type]="'fiat'" [currency_id]="0"></app-withdrawal-history-desktop>


