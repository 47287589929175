<h1>Müzayede Kuralları </h1>
<p>Artiox Teknoloji Yazılım A.Ş.’nin (kısaca “Artiox” veya “Şirket” olarak anılacaktır) düzenlediği online müzayedelere katılan üyeler/müşteriler aşağıdaki şartları peşinen kabul ederler;</p>
<ol>

    <li><p>Artiox satıcı için aracı olup, elektronik ortamda gerçekleştirdiği aracılık hizmetinden dolayı satış anında Alıcı’dan Alıma Aracılık Komisyonu’na (kısaca “Alıcı Komisyonu” olarak anılacaktır) hak kazanır. Alıcıya kesilen faturada son teklif ﬁyatı üzerine Alıcı Komisyonu ve mevzuat gereği son teklif ﬁyatı ve alıcı komisyonu üzerinden KDV eklenir. Alıcı Komisyonu  net 10% dur. Mevzuat gereği hem son teklif ﬁyatı hem Alıcı Komisyonu üzerine 20% KDV eklenir. Yasal KDV oranlarındaki artış veya azalış olması halinde güncel KDV oranı uygulanır. Alıcı müzayede konusu eseri almaktan vazgeçse dahi cezai şart yanında %10 komisyon bedeli ve komisyon bedeline ilave edilecek olan yasal KDV’yi ödemekle mükelleftir. Artiox’un bu kapsamda sunduğu hizmet; elektronik ortamda anında ifa edilen aracılık hizmeti olup cayma hakkı kapsamı dışındadır.</p></li>
    <li><p>Online Müzayedemizde satışa sunulan eserler 2863 sayılı Kültür ve Tabiat Varlıkları Kanunu uyarınca müze uzmanları tarafından kontrol edilmekte olup, Kanun kapsamına giren ve gerek görülenlerine belge verilmektedir.</p></li>
    <li><p>Online müzayedelerimize katılabilmek için müşterilerin www.artiox.com adresinden website’mize üye olması gerekmekte veya Artiox mobil uygulaması üzerinden üye olması gerekmektedir.</p></li>
    <li><p>Şirket’in düzenlediği online müzayedelerde yer alan eserler/ürünler satış öncesinde online katalog website’mizde yayına girdikten itibaren Artiox’un Kızılırmak Mah. Dumlupınar Bul. A Blok No:9A D:287 Çankaya Ankara adresinde teşhir edilerek müşterilerin incelemesine sunulmaktadır. Müzayedeye katılanlar eserleri/ürünleri Artiox’un yukarıdaki adresinde görmüş ve incelemiş olduklarını beyan ve kabul ederler. Müşteriler, müzayedede satışa sunulan tüm eserlerin “haliyle” satışa sunulduğunu, satılan eser/ürün için teklif vermeden evvel yeterince incelediğini ve Borçlar Kanunu’nun 223. Maddesi’nde belirtilen hükümlerden feragat ettiğini kabul ve beyan eder. Her eserin/ürünün yanında “haliyle” yazma zorunluluğu yoktur. Yayına giren müzayede ile ilgili olarak mobil uygulama ve website’mizde tanıtımlarda verilen tüm bilgiler Şirket çalışanlarımızın kanaatleri olup, taahhüt niteliğinde değil genel bilgi niteliğindedir. Katılımcılar bu durumu peşinen kabul ederler. Artiox satılan eserin/ürünün tekellüfü (garantisi) ile mükellef değildir. Alıcı bu hususta Artiox’dan herhangi bir itiraz ve talepte bulunmayacağını kabul ve beyan eder. Online ortamda düzenlenen müzayedelerimizde her ne kadar teklif verme süresi gün/günler önce başlıyor olsa da müzayede bitiş tarihinde müzayede sonlanmadan eser satışı yapılmamaktadır.</p></li>
    <li><p>Online müzayedelerimizde ﬁyat artırımı “Teklif Ver” veya “Hızlı Teklif Ver” butonuna tıklanarak yapılır. Müşteri online olarak vermiş olduğu teklif ile bağlıdır. Müzayedede online olarak satışa sunulan eser/ürün, Artiox tarafından belirlenen müzayede bitimi itibari ile en yüksek tekliﬁ vermiş olan müşteriye ihale edilir. Alıcı, her ne sebeple olursa olsun müzayedenin butlanını dermeyan edemez. Katılımcılar her bir eser/ürün için verilen sürenin sonuna kadar teklif verebilirler, sözkonusu sürenin bitimi itibari ile en yüksek tekliﬁ vermiş olan müşteri sözkonusu eser/ürün ile ilgili artırmayı kazanmış olur.</p></li>
    <li><p>Artiox her “Teklif Ver” veya “Hızlı Teklif Ver” butonuna tıklandığındaki değer artışlarını belirleme ve gerekli gördüğünde değer artış aralıklarını değiştirme hakkına sahiptir.</p></li>
    <li><p>Artiox satışa çıkarılan bir eserin/ürünün başlangıç ﬁyatını muhammen (konsinye tesellüm) bedelinin altında belirleme hakkına sahiptir.</p></li>
    <li><p>Online müzayedelerimize katılabilmek için, müşterilerin mobil uygulama veya Website’mize üyelik esnasında oluşturduğu şifre’yi kullanması gerekmektedir. “şifre” kişiye özeldir, üye müşteri dışında birinin kullanması ve/veya üye müşteri’nin kendisi dışında birine kullandırması yasaktır. “şifre” sahibi müşteri kendisine ait “şifre”nin başkaları tarafından kullanımı durumunda, Artiox’un hiçbir sorumluluk kabul etmeyeceğini peşinen kabul eder.</p></li>
    <li><p>Artiox verilen tekliﬂer, teklif verenler, en yüksek tekliﬁ veren vs gibi bilgileri kendi nezdinde güvenlik altına almaktadır, katılımcılar bu kayıtlara herhangi bir itiraz haklarının olmadıklarını peşinen kabul ve beyan ederler.</p></li>
    <li><p>Satın alınan tüm eserler için, eserleri satın alan müşterilerin mobil uygulama veya website üyelik kayıtlarındaki ad, soyad, ve adrese veya müşterilerin belirlediği şirket adına ve adrese Artiox tarafından fatura düzenlenir. Ancak, fatura düzenlenmesi, Artiox’un satıcı olduğu anlamına gelmez, Artiox satıcı değil, satışa aracılık eden aracıdır.</p></li>
    <li><p>Müzayedede satılan her eser/ürün için Artiox’un Alıcı Komisyonu müzayede sona erdiği andan itibaren muaccel hale gelir. Alıcı müzayedede satın aldığı eserlerin/ürünlerin toplam bedelini (satış bedeli, Satış Komisyonu, ilgili KDV’ler vs) işbu Artiox Müzayede Katılım Şartnamesi’ne uygun olarak tümüyle ve zamanında ödemediği takdirde, Artiox sözkonusu satışı iptal etmek hakkına sahiptir, ancak bu durumda işbu Müzayede Katılım Şartnamesi’ne uygun olarak ödeme yükümlülüğünü yerine getirmemiş olan alıcı Alıcı Komisyonu’na ek olarak eser/ürün sahiplerinden alınan Satıma Aracılık Komisyonu (kısaca “Satıcı Komisyonu” olarak anılacaktır), komisyonlara dair KDV’ler, Artiox’un uğradığı bilcümle zararlarını fatura tarihinden itibaren ticari faizi ile birlikte ödemekle yükümlüdür. Artiox’un sair dava ve talep hakları saklıdır.</p></li>
    <li><p>Artiox’un online olarak sunmuş olduğu müzayede hizmeti, elektronik ortamda anında ifa edilen aracılık hizmeti olup, cayma hakkı kapsamı dışındadır. Artiox’un elektronik ortamda düzenlediği müzayedeler TBK 274 ve devamı maddelerindeki düzenlemeye göre gerçekleştiğinden ve Tüketici Kanunu’nun 48. Maddesinde düzenlenen mesafeli satış sözleşmesi olarak kabul edilemeyeceğinden müzayedeye katılanların cayma hakkı yoktur. Müzayede de son tekliﬁ vererek müzayedeyi kazanan kişi veya kuruluş veya açık artırma üzerine kalan kişi eserin bedelini 7 (yedi) gün içinde tam olarak ödemediği takdirde Artiox dilerse satış akdini feshe, %20 cezai şartı ve uğradığı bilcümle zararlarını ticari faiz ile fatura tarihinden itibaren işletilecek aylık %10 faiz ile birlikte tahsile yetkilidir. Artiox’un sair dava ve talep hakları saklıdır. Fatura bedeli tahsil edilinceye kadar geçecek süre içinde eserin muhafazası için Artiox’un yapacağı bilcümle masraf alıcı tarafından ödenmedikçe satılan eser alıcıya teslim edilmez.</p></li>
    <li><p>Alıcı alım bedeli, alıcı komisyonu ve KDV’den oluşan toplam Alım Bedeli’ni tümüyle
        müzayede bitim tarihinden itibaren en geç 7 (yedi) gün içinde ödemekle yükümlüdür. Alıcı Artiox’dan satın almış olduğu eserleri/ürünleri teslim alabilmek için Toplam Alım Bedeli’ni tümüyle ödemiş olması gerekmektedir.</p></li>
    <li><p>Alıcı Toplam Alım Bedeli’ni tümüyle ödedikten sonra satın aldığı eserleri/ürünleri Artiox adresinden kendi teslim alır. Alıcı maliyeti kendine ait olmak üzere Artiox’un araçları ile, nakliye ya da kargo yoluyla satın alınanların teslimatını talep edebilir, bu durumda nakliye sırasında oluşabilecek herhangi hasardan Şirket’i sorumlu tutmayacağını peşinen kabul ve taahhüt eder. Ayrıca kargo ﬁrmalarından kaynaklanan gecikmelerden Artiox sorumlu tutulamaz. Şehir içi teslimlerde 7 (yedi) işgünü, şehir dışı teslimlerde 14 (ondört) işgünü içinde Artiox ürünü/eşyayı alıcıya kargo yoluyla gönderecektir/teslim edecektir. Eşyanın kargoya verildiği Artiox tarafından web sitesi veya mobil uygulamadaki üyelik ekranından, e-mail, telefon, kısa mesaj vebenzeri vasıtaların herhangi biriyle alıcıya bildirilebilir.</p></li>
    <li><p>Alıcıların mobil uygulama veya website’mize üyelik kaydında belirttikleri açık posta adresi, e-posta, cep telefonu gibi iletişim bilgilerine posta, e-posta, SMS, veya telefonla yapılan bildirim, tebligatların, kargo ile eser/ürün gönderimlerinin üyelik kaydında verilen bilgilerdeki yanlışlık, değişiklik nedeniyle alıcıya geç ulaşmasından veya hiç ulaşmamasından veya P.T.T./kargodan kaynaklanan gecikmelerden Artiox sorumlu tutulamaz.</p></li>
    <li><p>Artiox satışa sunulan eserlerin/ürünlerin beyan edilen niteliklere haiz olmaması, orijin itibari ile beyana uygun olmaması, hak sahibinin rızası dışında elden çıkmış olması, veya sair nedenlerden ayıplı olması durumlarında, Artiox’un satıcı olmaması nedeniyle hukuken sorumlu tutulamaz. Sözkonusu durumlarda, hukuki sorumluluk bu eserlerin/ürünlerin satışa arzını talep eden(ler)e aittir.</p></li>
    <li><p>Artiox satışa sunulan herhangi bir veya birden fazla eser(ler)i/ürün(ler)i, müzayededen geri çekmek, başka eser(ler)/ürün(ler) ile birlikte satmak, birbirinden ayırmak ve satışını reddetmek hakkına sahiptir. Şirket düzenlediği online müzayedelerde yer alan eser(ler)/ürün(ler) hakkında müzayede bitimine kadar ortaya çıkan her türlü itiraz ve bildirimin değerlendirilmesi sonucunda sözkonusu eser(ler)i/ürün(ler)i müzayede kapsamından çıkarmak ve gerekli gördüğü durumlarda yasal prosedür uygulamak hakkına sahiptir.</p></li>
    <li><p>18 yaşından küçükler üye olamaz, müzayedelere katılamaz. Artiox herhangi bir mazaret göstermeksizin şüpheli ve/veya sakıncalı gördüğü kişileri üye olarak kabul etmeme, üye olmuş olsalar dahi müzayedeye kabul etmeme, üyelikten çıkarma, ve/veya devam eden müzayedede vermiş olduğu tekliﬂerin bir kısmını veya tümünü iptal etme yetkisine sahiptir. Ayrıca Artiox hakkında, Şirket’in satıcı için aracı konumunda bulunmasından dolayı BK 214 ve 218 maddelerinde işlenen zapttan sorumluluk hükümleri uygulanamaz.</p></li>
    <li><p>5846 sayılı kanunun 45. Maddesi ve Bakanlar Kurulu’nun 27.09.2006 tarihli ve 2006/10880 sayılı kararı uyarınca, bu kapsamda, belge düzenine uyulması koşulu ile sanatçılara veya hak sahiplerine ödenmesi gereken paylar ile ilgili olarak müzayedede eserleri satılacak olan eserler sahiplerine Artiox’un rücu hakkı saklıdır.</p></li>
    <li><p>20. Artırmada bulunup online müzayedelere katılan müşteriler Artiox’un elektronik ortamda düzenlediği müzayedelere katılım şartlarını düzenleyen işbu Artiox Müzayede Katılım
        Şartnamesi’nde yer alan şartların tümünü ve ayrıca bunlara ilişkin Artiox’un diğer hak ve vecibelerini peşinen kabul ettiklerini beyan ederler. Katılımcılar satış gerçekleştikten sonra herhangi bir kusur itirazında bulunmayacaklarını peşinen kabul, beyan ve taahhüt ederler.</p></li>
    <li><p>Yayına girmiş ve/veya başlamış online müzayedelerde yazım hataları olması olması halinde Artiox’un açıkladığı düzeltmeler esas alınır.</p></li>
    <li><p>Artiox’un mobil uygulama veya websitesi’nde ve dolayısıyla online ortamda düzenlediği müzayedelerde yayınladığı her türlü yazı ve fotoğraf Şirket’in izni olmadan kısmen veya tümüyle yayınlanamaz.</p></li>
    <li><p>Şirket’in online ortamda düzenlediği müzayede yayına girdikten sonra çıkan yeni yasalar ve/veya vergiler, yasa ve/veya vergi oranlarında değişiklikler alıcılara yansıtılacak olup, Artiox sorumlu tutulmayacaktır.</p></li>
    <li><p>Türkiye dışında yerleşim alıcılar müzayedede ilgilendikleri eserlerin/ürünlerin Türkiye dışına çıkarılması ve bulundukları ülkeye girişi ile ilgili yasaları, vergileri ve nakliye dahil tüm maliyeti müzayedeye katılmadan önce incelemekle yükümlüdür. Alıcılar satın almış oldukları eserlerin yurtdışına çıkarılması ya da bulundukları ülkeye girişi ile ilgili karşılaştıkları yasal, vergisel, nakliye ve/veya maliyet sorunlardan Artiox’u sorumlu tutamaz, satın aldıklarını iade edemez.</p></li>
    <li><p>Artiox mobil uygulaması veya website’sine üye olan ve online ortamda düzenlediğimiz müzayedelere katılan herkes işbu Artiox Müzayede Katılım Şartnamesi’ni tümüyle okuduğunu ve kabul ettiğini beyan etmiş sayılır.</p></li>
    <li><p>İşbu katılım şartlarından doğan uyuşmazlıklarda Ankara Mahkemeleri ve İcra Daireleri yetkilidir.</p></li>


</ol>