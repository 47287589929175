<div  class="d-flex flex-row h-100 " style="min-height: 100%; margin-bottom: 0; ">
    <!--    Login elements half-->
    <div *ngIf="!loading_animation" class="d-flex position-relative w-100">
        <div class="position-absolute" style="left: 120px; top: 64px; cursor: pointer"
             (click)="this.managerService.backToHome()">
            <img src="./assets/images/artiox_logo.svg#artiox_logo" alt="artiox_logo" width="130px" height="30px"
                 style="fill: black">
        </div>
        <div class="d-flex justify-content-center h-100 w-100">
            <div class="d-flex flex-column justify-content-center" style="max-width: 364px">
                <span class="verification-header mb-2">{{ managerService.config.ACCOUNT_VERIFICATION.HEADER }}</span>
                <span [innerHTML]=" [emailBold()] | managerPipe: managerService.mpa_template.EVALUATE_STRING:  managerService.config.ACCOUNT_VERIFICATION.FIRST_INFO " class="verification-info mb-2">   </span>
                <span [innerHTML]="managerService.config.ACCOUNT_VERIFICATION.SECOND_INFO" class="verification-info"
                      style="margin-bottom: 32px"></span>

                <div class="d-flex form-group focused" style="margin-bottom: 32px">
                    <label class=" d-flex form-label body2 barlow bold mb-2" for="verification" style="color: var(--text-color-artiox-black)">
                        {{ managerService.config.ACCOUNT_VERIFICATION.INPUT_HEADER | managerPipe }} </label>

                    <input #verification_input class="w-100 form-input body1 barlow regular" [formControl]="verificationControl"
                           id="verification"  type="text"  placeholder="{{managerService.config.ACCOUNT_VERIFICATION.INPUT_PLACEHOLDER}}" oninput="this.value=this.value.replace(/\D+/,'')">
                    <div #verification_error class="d-flex d-none flex-row" style="gap: 8px; margin-top: 12px">
                        <svg style=" height: 16px; width: 16px; ">
                            <use [attr.xlink:href]='"./assets/images/error_icon.svg#error_icon"' fill="#EA3943"/>
                        </svg>
                        <span class="caption barlow regular"
                              style="color: var(--secondary-colors-artiox-red-base)"></span>
                    </div>
                </div>
                <div class="d-flex w-100 justify-content-center" style="margin-bottom: 32px">
                <span (click)="resendEmail()" [ngStyle]="{color: isTextClickable ? 'var(--main-color-artiox-purple-base' : 'var(--secondary-colors-artiox-gray-500)', cursor: isTextClickable? 'pointer': 'default'}"
                      class="body1 regular barlow clickable_text"
                      style="text-decoration: underline">
                    {{managerService.config.FORGOT_PASSWORD.RESEND_REQUEST}} <span
                        *ngIf="!isTextClickable">({{countdown}}{{managerService.config.FORGOT_PASSWORD.SECOND_ABBREVIATION}}
                    )</span>
                </span>
                </div>
                <button class="w-100  d-flex justify-content-center py-2 login-button" (click)="sendVerification()"
                        id="submit-button">
                    <span class="body1 exo "
                          style="color:#FFFFFF; font-weight: 600">{{ managerService.config.ACCOUNT_VERIFICATION.BUTTON_TEXT | managerPipe }}</span>
                </button>
            </div>

        </div>
    </div>

    <div *ngIf="loading_animation" class="d-flex flex-row h-100 w-100 align-items-center justify-content-center">
        <div class="position-absolute" style="left: 120px; top: 64px; cursor: pointer">
            <img src="./assets/images/artiox_logo.svg#artiox_logo" alt="artiox_logo" width="130px" height="30px"
                 style="fill: black">
        </div>
        <div class="d-flex  align-items-center justify-content-center p-3">
            <div class="position-absolute d-flex flex-column align-items-center justify-content-center">
                <svg class="loader" style="height:100px; width:100px;">
                    <use [attr.xlink:href]='"./assets/images/artiox_icon.svg#artiox_icon"' class="h-100 w-100" fill="#8266f0"/>
                </svg>
            </div>
        </div>
    </div>

    <!--    Artiox Purple background and image -->


    <div class="d-flex flex-column align-items-center justify-content-end w-100"
         style="background: linear-gradient(180deg, var(--main-color-artiox-purple-500) 0%, var(--main-color-artiox-purple-base) 100%);">
        <div class="h-100 d-flex justify-content-center align-items-center">
            <span class="header3 medium exo text-center"
                  style="color: var(--text-color-artiox-white, #FFFFFF); margin-top: 120px; margin-bottom: 120px; width: 480px">{{ managerService.config.LOGIN.ART_INVESTMENT }}</span>
        </div>
        <div>
            <img src="./assets/screen_shots/desktop/login_page_phone_mock_up.png" alt="market"
                 style="width: auto;height: 50dvh; min-height: 500px;">
        </div>
    </div>


</div>

