import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legal-cookie-clarification-text-desktop',
  templateUrl: './legal-cookie-clarification-text-desktop.component.html',
  styleUrls: ['./legal-cookie-clarification-text-desktop.component.css']
})
export class LegalCookieClarificationTextDesktopComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
