<div style="width: 557px; background: #FFFFFF;height: 100vh;">
    <div class="d-flex w-100 justify-content-start">
        <div class="d-flex flex-column" style="gap: 16px;padding: 64px 48px 48px;">
            <div class="d-flex header6 exo bold"
               style="color: #66BB6A">{{ managerService.config.AUCTION.BID_APPROVED.APPROVED | managerPipe }}
            </div>

            <div class="d-flex barlow body2 regular" [innerHTML]="managerService.config.AUCTION.BID_APPROVED.SUCCESS | managerPipe"
                 style="color: #6F6F6F;">
            </div>
        </div>
    </div>

    <div class="d-flex w-100 justify-content-center align-items-center">
        <div style="display: flex;width: 180px;height: 180px;padding: 36px;justify-content: center;align-items: center;flex-shrink: 0;border-radius: 100px;background: var(--secondary-colors-artiox-green-100, #F0F8F0);">
<!--            Todo not working-->
<!--            <svg style="height: 108px; width: 108px">-->
<!--                <use [attr.xlink:href]='"./assets/images/double_tick.svg#double_tick"' fill="none" stroke="#66BB6A"/>-->
<!--            </svg>-->
            <svg width="108" height="108" viewBox="0 0 108 108" fill="none">
                <g clip-path="url(#clip0_2512_41778)">
                    <path d="M31.5 54L54 76.5L99 31.5" stroke="#66BB6A" stroke-width="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9 54L31.5 76.5M54 54L76.5 31.5" stroke="#66BB6A" stroke-width="10" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_2512_41778">
                        <rect width="108" height="108" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </div>
    </div>


    <div class="pl-5 pr-5 mb-4 d-flex w-100" style="margin-top: 48px">
        <div style="color: #6F6F6F">
            {{ managerService.config.AUCTION.BID_APPROVED.HISTORY | managerPipe }}
        </div>
    </div>

    <div class="d-flex w-100 justify-content-center align-items-end" style="position:absolute; bottom: 10px">
        <svg style=" height: 32px; width: 138px; margin-top: 32px; margin-bottom: 60px">
            <use [attr.xlink:href]='"./assets/images/artiox_logo.svg#artiox_logo"' fill="#000"/>
        </svg>
    </div>
</div>
