import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';

@Component({
    selector: 'app-pair-list-mobile',
    templateUrl: './pair-list-mobile.component.html',
    styleUrls: ['./pair-list-mobile.component.css']
})
export class PairListMobileComponent implements OnInit {

    constructor(public managerService: ManagerService) {
    }

    ngOnInit(): void {
    }
}
