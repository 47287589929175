<div class="pt-5 px-5 d-flex flex-column align-items-center justify-content-center"
     style="width: 600px; background-image: radial-gradient(circle 1000px at 72.3% 1991.3%, #1e2028 0%, #21232d 100%);">
  <div class="d-flex w-100 justify-content-end">
    <svg style="margin-top: -16px; margin-right: -8px; height: 18px; width: 18px;" (click)="managerService.closeModal(modal_id)">
      <use [attr.xlink:href]='"./assets/images/exit_icon.svg#exit_icon"' fill="white"/>
    </svg>
  </div>

  <div class="text-center my-3 px-5"
       style="font-family: Roboto, sans-serif; font-size: 20px; line-height: 1.2; letter-spacing: 2.5px; color: #f4f5f9;">
    {{managerService.config.WITHDRAWAL.ADD_CRYPTO_ACCOUNT.HEADING | managerPipe}}
  </div>
  <form [formGroup]="addCryptoAccountForm" class="w-100 px-5 d-flex flex-column align-items-center justify-content-center" style="width: 100%; height: fit-content; z-index: 2">
    <span class='input-heading mt-3' style="color: #f4f5f9">{{managerService.config.WITHDRAWAL.ADD_CRYPTO_ACCOUNT.BLOCK_CHAIN | managerPipe}}</span>
    <div *ngIf="(managerService.operationsService.block_chain_list.length > 1) | managerPipe"
         class="position-relative w-100 mb-3 dropdown-native-button" style="width: 90%; margin: 2px auto; padding: 0 20px;">
      <label (click)="blockchain_dropdown.classList.remove('d-none')" class="position-relative d-flex flex-row" style="height: 40px; border-radius: 4px; margin: 0;">
        <input #blockchain_warning class="w-100 input-field-underline" readonly required style="height: 40px; border-radius: 4px; cursor: pointer" type="text"
               [value]="selected_blockchain.name | managerPipe">
        <svg class="dropdown-icon" style="height: 24px; margin: 8px 2px;">
          <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#9DA5B7"/>
        </svg>
      </label>
      <div #blockchain_dropdown class="dropdown-native-menu-white d-none" style="width: 464px; height: 70px;">
        <button (click)="blockchain_dropdown.classList.add('d-none'); selectBlockchain(item.id);"
                *ngFor="let item of managerService.operationsService.block_chain_list | managerPipe"
                class="dropdown-native-item-white btn-native justify-content-center" type="button">
          {{item.name | managerPipe}}
        </button>
      </div>
    </div>
    <div *ngIf="(managerService.operationsService.block_chain_list.length === 1) | managerPipe"
         class="position-relative w-100 mb-3 dropdown-native-button" style="width: 90%; margin: 2px auto; padding: 0 20px;">
      <label class="d-flex flex-row" style="height: 40px; border-radius: 4px; margin: 0;">
        <input #blockchain_warning class="w-100 input-field-underline" readonly required style="height: 40px; border-radius: 4px; cursor: pointer" type="text"
               [value]="selected_blockchain.name | managerPipe">
      </label>
    </div>
    <label class="position-relative my-3">
      <span class='input-heading' style="color: #f4f5f9">{{managerService.config.WITHDRAWAL.ADD_CRYPTO_ACCOUNT.ACCOUNT_ADDRESS | managerPipe}}</span>
      <input #address_warning class="input-field" formControlName="address" placeholder="0x" required style="border-radius: 4px; border: solid 1px #ffffff; background-color: #ffffff; box-shadow: none;" type="text">
      <svg (click)="pasteAddress()" class="position-absolute"
           style="top: 50px; right: 32px; width: 24px; height: 24px;">
        <use [attr.xlink:href]='"./assets/images/paste.svg#paste"' fill="#9DA5B7"/>
      </svg>
    </label>
    <label class="my-3">
      <span class='input-heading' style="color: #f4f5f9">{{managerService.config.WITHDRAWAL.ADD_CRYPTO_ACCOUNT.CRYPTO_ACCOUNT_NAME | managerPipe}}</span>
      <input #label_warning class="input-field" formControlName="label" required style="border-radius: 4px; border: solid 1px #ffffff; background-color: #ffffff; box-shadow: none;" type="text">
    </label>
    <button (click)="addCryptoAccount()"
            [class.button-inactive]="addCryptoAccountForm.invalid || selected_blockchain.id === -1 | managerPipe"
            class="mt-4 mb-5 btn-native" id="save-crypto-account-button">
      {{managerService.config.WITHDRAWAL.ADD_CRYPTO_ACCOUNT.SAVE_CRYPTO_ACCOUNT | managerPipe}}
    </button>
  </form>

</div>
