<div class="w-100 d-flex flex-column align-items-center justify-content-start"
     style="background-image: radial-gradient(circle 1000px at 72.3% 1991.3%, #1e2028 0%, #21232d 100%); z-index: 1;">
  <div class="w-100 d-flex flex-row overflow-hidden position-relative" style="z-index: 0" (swipeleft)="swipeLeft()" (swiperight)="swipeRight()">
    <svg (click)="swipeRight()" class="position-absolute" style="height:16px; width: 16px; min-width: 16px; margin-right: 20px; z-index: 1; left: 20px; margin-top: 110px;">
      <use [attr.xlink:href]='"./assets/images/left_arrow.svg#left_arrow"' class="w-100" fill="white"/>
    </svg>
    <svg (click)="swipeLeft()" class="position-absolute" style="height:16px; width: 16px; min-width: 16px; margin-left: 20px; z-index: 1; right: 20px;  margin-top: 110px;">
      <use [attr.xlink:href]='"./assets/images/right_arrow.svg#right_arrow"' class="w-100" fill="white"/>
    </svg>
    <div *ngIf="!managerService.environment.app | managerPipe"
      [style]="current_slider_index | managerPipe : managerService.mpa_template.SLIDER_BY_VW"
      class="vw-100 min-vw-100 d-flex flex-column align-items-center justify-content-center"
      style="transition: 0.4s ease-in-out;">
      <div class="h-100 d-flex flex-column align-items-center justify-content-center mx-3">
        <img alt="app_announcement"
             src="{{managerService.config.APP_ANNOUNCEMENT.MOBILE_IMAGE_THUMBNAIL | managerPipe: managerService.mpa_template.LOCAL_IMAGE_MANAGER_TO_BE}}"
             style="max-width: 200px;  margin-bottom: 20px" #app_announcement
             (error)="app_announcement.src = app_announcement.src.replace('.webp', '.png');">
      </div>
      <div class="h-100 d-flex flex-column align-items-center justify-content-center mx-3" style="width: 280px;">
        <div class="d-flex flex-column align-items-center justify-content-start" style="height: 240px;">
          <p class="text-center"
             style="font-family: Roboto, sans-serif; font-size: 18px; font-weight: normal; line-height: 1.2; letter-spacing: 1px; color: white; margin-bottom: 60px"
             [innerHTML]="managerService.config.APP_ANNOUNCEMENT.DESCRIPTION | managerPipe">
          </p>
          <div>
            <span>
              <a href="{{managerService.config.MISC.ONELINK | managerPipe}}" class="m-2" (click)="managerService.marketingService.logEvent('app_store_navigation', {})">
                <svg style="height:40px; width: 120px;">
                  <use [attr.xlink:href]="managerService.config.APP_ANNOUNCEMENT.APP_STORE_BADGE | managerPipe" fill="#f9f9f9"/>
                </svg>
              </a>
            </span>
            <span>
              <a href="{{managerService.config.MISC.ONELINK | managerPipe}}" class="m-2" (click)="managerService.marketingService.logEvent('google_play_navigation', {})">
                <svg style="height:40px; width: 120px;">
                  <use [attr.xlink:href]="managerService.config.APP_ANNOUNCEMENT.GOOGLE_PLAY_BADGE | managerPipe" fill="#f9f9f9"/>
                </svg>
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngFor="let pair of (managerService.pair_pipe | managerPipe: managerService.mpa_template.GET_FEATURED_PAIR_LIST: (slider_window-(managerService.environment.app ? 0 : 1)))"
      [style]="current_slider_index | managerPipe : managerService.mpa_template.SLIDER_BY_VW"
      class="vw-100 min-vw-100 d-flex flex-column align-items-center justify-content-center"
      style="transition: 0.4s ease-in-out;">
      <div class="h-100 d-flex flex-column align-items-center justify-content-center mx-auto my-4">
        <img (click)="managerService.photoSwipeOpen(pair)"
             alt="art_product"
             src="{{(pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_IMAGE_MOBILE)[0] | managerPipe: managerService.mpa_template.LOCAL_IMAGE_MANAGER}}"
             style="max-width: 200px; max-height: 200px;" #art_product
             (error)="art_product.src = art_product.src.replace('.webp', '.jpg');">
      </div>
      <div class="h-100 d-flex flex-column align-items-center justify-content-center mx-3" style="width: 300px;">
        <div class="d-flex flex-column align-items-center justify-content-start" style="height: 240px;">
          <p class="mt-0 mb-0 text-center" style="border-bottom: 1px solid white;
           font-family: Roboto, sans-serif; font-size: 18px; font-weight: bold; letter-spacing: 1px; color: white;">
            "{{pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_NAME}}"
          </p>
          <span class="d-flex flex-row align-items-center justify-content-center mb-2">
            <p class="mt-1 mb-0" style="font-family: Roboto, sans-serif; font-size: 16px;;
             letter-spacing: 1px; color: white; font-weight: normal">{{pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_ARTIST_NAME: managerService.language_pipe}}</p>
            <p [class.d-none]="(pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_ARTIST_BORN_DEATH) | managerPipe: managerService.mpa_template.CHECK_LENGTH_SHORTER_THAN : 4"
               class="mt-1 mb-0" style="font-family: Roboto, sans-serif; font-size: 14px; font-weight: 300;
             letter-spacing: 1px; color: white;">({{pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_ARTIST_BORN_DEATH}}
              )</p>
          </span>
          <p class="mt-0 mb-0 text-center" style="font-family: Roboto, sans-serif; font-size: 13px; font-weight: 300;
             letter-spacing: 1px; color: white;">{{pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_SIGNATURE: managerService.language_pipe}}</p>
          <p class="mt-0 mb-0 text-center" style="font-family: Roboto, sans-serif; font-size: 13px; font-weight: 300;
             letter-spacing: 1px; color: white;">{{pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_MEDIUM: managerService.language_pipe}}</p>
          <p class="mt-0 mb-0 text-center" style="font-family: Roboto, sans-serif; font-size: 13px; font-weight: 300;
             letter-spacing: 1px; color: white;">{{pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_SIZE: managerService.language_pipe}}</p>
          <p class="mt-0 mb-0 text-center" style="font-family: Roboto, sans-serif; font-size: 13px; font-weight: 300;
             letter-spacing: 1px; color: white;">{{pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_YEAR: managerService.language_pipe}}</p>
          <button (click)="managerService.exchangeNavigate(pair);"
                  *ngIf="pair.id[0] !== -1 | managerPipe" class="mt-3 btn-native btn-white">
            {{pair.sale_state | managerPipe: managerService.mpa_template.SALE_STATE_BUTTON: managerService.language_pipe}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
