import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';
import {HttpClient} from '@angular/common/http';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {first} from 'rxjs/operators';
import {BlockChain} from '../../../../../services/operations.service';
import {keccak256} from 'js-sha3';

@Component({
  selector: 'app-add-crypto-account-desktop',
  templateUrl: './add-crypto-account-desktop.component.html',
  styleUrls: ['./add-crypto-account-desktop.component.css']
})
export class AddCryptoAccountDesktopComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() modal_id: string = '';
  @ViewChild('blockchain_warning') blockchain_warning: any;
  @ViewChild('address_warning') address_warning: any;
  @ViewChild('label_warning') label_warning: any;

  public selected_blockchain: BlockChain = BlockChain.empty_blockchain;

  addCryptoAccountForm = new UntypedFormGroup({
    address: new UntypedFormControl('', [Validators.required]),
    label: new UntypedFormControl('', [Validators.required]),
  });

  public subscription_list: (Subscription | undefined)[] = [];

  constructor(public managerService: ManagerService, private httpClient: HttpClient) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.subscription_list.push(
      this.addCryptoAccountForm.get('address')?.valueChanges.subscribe(() => {
        this.validateAddress();
      })
    );

    this.subscription_list.push(
      this.addCryptoAccountForm.get('label')?.valueChanges.subscribe(() => {
        this.validateLabel();
      })
    );

    this.subscription_list.push(
      this.managerService.operationsService.block_chain_list_changed.pipe(first()).subscribe(() => {
        this.selectBlockchain(0, false);
      })
    );

    this.subscription_list.push(
      this.managerService.language_changed.subscribe(() => {
        this.selectBlockchain(this.selected_blockchain.id);
      })
    );
  }

  selectBlockchain(blockchain_id: number, validate: boolean = true): void {
    if (blockchain_id === -1) {
      this.selected_blockchain = BlockChain.empty_blockchain;
    } else {
      this.selected_blockchain = this.managerService.operationsService.block_chain_list[blockchain_id];
    }
    if (validate) {
      this.validateBlockchain();
      this.validateAddress();
    }
  }

  pasteAddress(): void {
    this.managerService.pasteFromClipboard((text) => {
      this.addCryptoAccountForm.get('address')?.setValue(text);
    });
  }

  validateBlockchain(): boolean {
    const valid: boolean = this.managerService.operationsService.block_chain_list[this.selected_blockchain.id] !== undefined;

    if (valid){
      this.blockchain_warning.nativeElement.classList.remove('input-field-required');
    } else {
      this.blockchain_warning.nativeElement.classList.add('input-field-required');
    }
    return valid;
  }

  validateEthereumAddress(address: string): boolean {
    if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
      return false;
    } else if (/^(0x)?[0-9a-f]{40}$/.test(address) || /^(0x)?[0-9A-F]{40}$/.test(address)) {
      return true;
    } else {
      return this.isChecksumAddress(address);
    }
  }

  isChecksumAddress(address: string): boolean {
    address = address.replace('0x', '');
    const addressHash = keccak256(address.toLowerCase());
    for (let i = 0; i < 40; i++ ) {
      if ((parseInt(addressHash[i], 16) > 7 && address[i].toUpperCase() !== address[i]) || (parseInt(addressHash[i], 16) <= 7 && address[i].toLowerCase() !== address[i])) {
        return false;
      }
    }
    return true;
  }

  validateAddress(): boolean {
    let valid: boolean = false;

    if (this.selected_blockchain.name === 'Ethereum Main Net') {
      valid = this.validateEthereumAddress(this.addCryptoAccountForm.get('address')?.value);
    }
    // else if (this.selected_blockchain.name === 'Ethereum Goerli-Prater Test Net') {
    //   valid = this.validateEthereumAddress(this.addCryptoAccountForm.get('address')?.value);
    // }
    else if (this.selected_blockchain.name === 'Ethereum Sepolia Test Net') {
      valid = this.validateEthereumAddress(this.addCryptoAccountForm.get('address')?.value);
    }

    if (valid){
      this.address_warning.nativeElement.classList.remove('input-field-required');
    } else {
      this.address_warning.nativeElement.classList.add('input-field-required');
    }

    return valid;
  }

  validateLabel(): boolean {
    const valid: boolean = !!this.addCryptoAccountForm.get('label')?.value;

    if (valid){
      this.label_warning.nativeElement.classList.remove('input-field-required');
    } else {
      this.label_warning.nativeElement.classList.add('input-field-required');
    }

    return valid;
  }

  clearAddCryptoAccountForm(): void {
    this.selectBlockchain(0, false);
    this.addCryptoAccountForm.get('address')?.setValue('');
    this.addCryptoAccountForm.get('label')?.setValue('');

    this.blockchain_warning.nativeElement.classList.remove('input-field-required');
    this.address_warning.nativeElement.classList.remove('input-field-required');
    this.label_warning.nativeElement.classList.remove('input-field-required');
  }

  addCryptoAccount(): void {
    if (!(this.validateBlockchain() && this.validateAddress() && this.validateLabel())) {
      // @ts-ignore
      const content = this.managerService.config.RESPONSE_TABLE['2003'];
      this.managerService.popSnackBar(content.is_okay, content.message);
      return;
    }

    if (this.addCryptoAccountForm.invalid || this.selected_blockchain.id === -1) {
      // @ts-ignore
      const content = this.managerService.config.RESPONSE_TABLE['2003'];
      this.managerService.popSnackBar(content.is_okay, content.message);
      return;
    }

    const data = {
      block_chain_id: parseInt(this.selected_blockchain.id, 10),
      address: this.addCryptoAccountForm.value.address,
      label: this.addCryptoAccountForm.value.label
    };
    this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'add_user_crypto_account_service', data).subscribe(
      response => {
        // @ts-ignore
        const content = this.managerService.config.RESPONSE_TABLE[response.code];
        this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
      })
    );
    this.clearAddCryptoAccountForm();
    this.managerService.closeModal(this.modal_id);
    this.managerService.userService.user_crypto_account_added.emit();
  }

  ngOnDestroy(): void {
    this.subscription_list.forEach((subscription) => {
      subscription?.unsubscribe();
    });
  }
}
