export const announcements_titles = {  // outdated
  router_tr_old: {
    'sanat_eserlerinin_degerindeki_artis': 28,
    'nft_satislari_3.5_milyar_dolara_ulasti': 27,
    'empresyonizm_nedir': 26,
    'sanat_yatirimi_hakkinda_raporlar': 25,
    'artioxa_kimler_yatirim_yapti': 24,
    'sanat_piyasasina_yon_verecek_5_trend': 23,
    'teknoloji_sanat_dunyasini_demokratik_hale_getirebilir_mi': 22,
    'sanat_eserlerini_nasil_seciyoruz': 21,
    'frida_kahlonun_otoportresi': 20,
    'sanatseverlere_alabileceginiz_8_kitap': 19,
    'pablo_picasso': 18,
    'sanat_eserlerine_talep': 17,
    'sanat_dunyasinin_gelecegi': 16,
    'sanat_marketi_ve_pandemi': 15,
    'nft_ve_artiox': 14,
    'acemiler_icin_sanat_yatirimi': 13,
    'sanat_eserinin_degeri': 12,
    'sanatcilar_icin_nft': 11,
    'kuran_okuyan_kiz': 10,
    'yatirimci_olma': 9,
    'nft_donusturme': 8,
    'blockchain_nedir': 7,
    'geleneksel_ve_kripto': 6,
    'kisa_hikayemiz': 5,
    'geleneksel_ile_farklar': 4,
    'artiox_nedir': 3,
    'yatirim_turu': 2,
    'art.ist_sauna': 1,
    'tl_yatirma': 0,
  },
  router_tr: {
    'degerini_20_kat_artiran_eser_four_marilyns': 28,
    'nft_satislari_3.5_milyar_dolara_ulasti': 27,
    'empresyonizm_nedir': 26,
    '2021_yili_sanat_pazarinda_one_cikan_veriler': 25,
    'artioxa_kimler_yatirim_yapti': 24,
    'sanat_piyasasina_yon_verecek_5_trend': 23,
    'teknoloji_sanat_dunyasi_ve_demokrasi': 22,
    'sanat_eserlerini_nasil_seciyoruz': 21,
    'frida_kahlonun_otoportresinin_degeri': 20,
    'sanatseverlere_alabileceginiz_8_kitap': 19,
    'pablo_picasso_eserlerinin_degeri': 18,
    'sanat_eserlerine_talep': 17,
    'nft_sanat_dunyasinin_gelecegi': 16,
    'sanat_pazari_pandemi_ve_sonrasi': 15,
    'artioxtaki_tokenler_ile_nftlerin_farki': 14,
    'acemiler_icin_sanat_yatirimciligi': 13,
    'sanat_eserinin_ticari_degeri': 12,
    'sanatcilar_icin_nft_kavrami': 11,
    'bir_turk_sanatciya_ait_en_pahali_tablo': 10,
    'nasil_yatirimci_olunur_baslangic_rehberi': 9,
    'sanat_eseri_nasil_nftye_donusturulur': 8,
    'nedir_bu_blockchain': 7,
    'geleneksel_yatirim_ve_kripto_para': 6,
    'artioxun_kisa_hikayesi': 5,
    'geleneksel_sanat_yatirimi_ile_farkimiz': 4,
    'artiox_nedir': 3,
    'yatirim_turu': 2,
    'art.ist_sauna_ile_is_birligi': 1,
    'turk_lirasi_yatirma_islemi': 0,
  },
  router_en: {
    'artwork_increased_its_value_20_times': 28,
    'nft_artwork_sales_reach_3.5_billion': 27,
    'what_is_impressionism': 26,
    'highlights_of_the_art_market_in_2021': 25,
    'who_invested_in_artiox': 24,
    '5_trends_that_will_shape_the_art_market': 23,
    'technology_art_world_and_democracy': 22,
    'how_do_we_choose_artworks_to_list': 21,
    'the_value_of_frida_kahlo_selfportrait': 20,
    'the_8_best_books_for_art_lovers': 19,
    'the_value_of_pablo_picasso_artworks': 18,
    'demand_for_artworks': 17,
    'nft_future_of_the_art_world': 16,
    'art_market_pandemic_and_after': 15,
    'nfts_vs_artworks_tokens_of_artiox': 14,
    'art_investing_for_novices': 13,
    'commercial_value_of_an_artwork': 12,
    'the_concept_of_nft_for_artists': 11,
    'the_most_expensive_painting_by_a_turk': 10,
    'become_an_investor_guide_for_newbies': 9,
    'how_to_convert_artwork_to_nft': 8,
    'what_is_this_blockchain': 7,
    'traditional_investment_and_cryptocurrency': 6,
    'short_story_of_artiox': 5,
    'difference_between_traditional_investments': 4,
    'what_is_artiox': 3,
    'investment_round': 2,
    'partnership_with_art.ist_sauna': 1,
    'turkish_lira_deposit_process': 0,
  },
  getAnnouncementIndex: ((key: string): any => {
    if (Object.keys(announcements_titles.router_tr).includes(key)) {

      return {
        // @ts-ignore
        operation_announcement_index: Object.keys(announcements_titles.router_tr).length - announcements_titles.router_tr[key] - 1,
        language: 'TR'
      };
    } else if (Object.keys(announcements_titles.router_en).includes(key)) {
      return {
        // @ts-ignore
        operation_announcement_index: Object.keys(announcements_titles.router_en).length - announcements_titles.router_en[key] - 1,
        language: 'EN'
      };
    } else if (Object.keys(announcements_titles.router_tr_old).includes(key)) {
      return {
        // @ts-ignore
        operation_announcement_index: Object.keys(announcements_titles.router_tr_old).length - announcements_titles.router_tr_old[key] - 1,
        language: 'TR'
      };
    } else {
      return {};
    }
  }),
  zendesk_active: true
};
