<div class="w-80 mx-auto">
  <div *ngIf="(managerService.navigationService.artist_announcement_active_index === -1 | managerPipe)">
    <div class="px-4" style=" border-radius: 4px; box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22);  background-color: #fcfdff;">
      <div class="pt-5 pb-3" style="font-family: bebas-neue-bold, sans-serif; font-size: 39px; letter-spacing: 4px; color: #404352;">
        {{managerService.config.ANNOUNCEMENTS.HEADERS.ARTISTS_ANNOUNCEMENTS | managerPipe: managerService.mpa_template.TO_LOCAL_UPPER_CASE}}
      </div>
    </div>

    <div class="d-flex flex-row flex-wrap pb-5">
      <div (click)="managerService.navigationManager({url: '/about-artist', artist_announcement_index: i, current_component_index: 0})"
           *ngFor="let artist_announcement of (managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST | managerPipe); let i=index;"
           class="position-relative"
           style="flex-basis: calc(25% - 30px); margin: 10px 14px; border-radius: 8px; box-shadow: 5px 5px 9px 0 rgba(45, 52, 104, 0.5); cursor: pointer;">
        <img #artist style=""
             (error)="artist.src = artist.src.replace('.webp', '.jpg');" alt="artist" class="artist-image"
             src="{{artist_announcement.ARTIST_IMAGE_SRC}}">
        <div class="w-100 d-flex flex-column align-items-center justify-content-end position-absolute" style="color: #ffffff; bottom: 40px;">
          <div style="font-family: bebas-neue-bold, sans-serif; font-size: 26px; letter-spacing: 0.6px; line-height: 1.1; text-align: center; transform: translateY(50%)">
            {{artist_announcement.NAME_UPPERCASE | managerPipe}}
          </div>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="(managerService.navigationService.artist_announcement_active_index !== -1 | managerPipe)" class="mx-auto"
       style="width: 100%; max-width: 1200px;">
    <div [class]="!managerService.navigationService.artist_announcement_gallery_active ? 'd-flex' : 'd-none'">

      <div *ngIf="(managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].TEMPLATE === 0) | managerPipe"
           class="w-100 position-relative d-flex flex-column justify-content-start align-items-center">
        <img src="{{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].IMAGE_BACKGROUND_DESKTOP}}"
             class=" w-100" style="height: 400px; width: 100%; object-fit: cover; pointer-events: none" alt="background" #background
             (error)="background.src = background.src.replace('.webp', '.jpg');">
        <img src="{{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].ARTIST_IMAGE_SRC}}"
             style="position: absolute; top: 303px; height: 174px; width: 174px; border-radius: 174px;pointer-events: none" alt="artist" #artist
             (error)="artist.src = artist.src.replace('.webp', '.jpg');">
        <div class="w-100 d-flex flex-row align-items-center justify-content-center"
             style="font-family: bebas-neue-bold, sans-serif; font-size: 30px; letter-spacing: 0.6px; line-height: 1.2; color: #404352; margin-top: 120px;">
          {{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].TOPIC_UPPERCASE| managerPipe}}
        </div>
        <div class="w-100 announcement-article-container" style="margin-top: 40px;">
          <div class="announcement-article-text">
        <span
            [innerHTML]="( '“'+  managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].TEXT) | managerPipe"></span>
            <div [innerHTML]="managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].REFERENCES | managerPipe"></div>
          </div>
        </div>
      </div>

      <div *ngIf="(managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].TEMPLATE === 1) | managerPipe"
           class="w-100 position-relative d-flex flex-column justify-content-start align-items-center">
        <img src="{{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].IMAGE_BACKGROUND_DESKTOP}}"
             class=" w-100" style="height: 400px; width: 100%; object-fit: cover;pointer-events: none" alt="background" #background
             (error)="background.src = background.src.replace('.webp', '.jpg');">
        <img src="{{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].ARTIST_IMAGE_SRC}}"
             style="position: absolute; top: 303px; height: 174px; width: 174px; border-radius: 174px;pointer-events: none" alt="artist" #artist
             (error)="artist.src = artist.src.replace('.webp', '.jpg');">
        <div class="w-100 d-flex flex-row align-items-center justify-content-center"
             style="font-family: bebas-neue-bold, sans-serif; font-size: 30px; letter-spacing: 0.6px; line-height: 1.2; color: #404352; margin-top: 120px;">
          {{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].TOPIC_UPPERCASE | managerPipe}}
        </div>
        <div class="w-100 announcement-article-container" style="margin-top: 40px;">
          <div class="announcement-article-text">
            <span
                [innerHTML]="( '“'+  managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].TEXT) | managerPipe">
            </span>
          </div>
        </div>
        <div class="w-100 d-flex flex-row align-items-center justify-content-center "
             style="font-family: bebas-neue-bold, sans-serif; font-size: 30px; letter-spacing: 0.6px; line-height: 1.2; color: #404352; margin: 42px 0 20px 0;">
          {{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].ARTIST_LIST_HEADING_UPPERCASE | managerPipe}}
        </div>
        <div class="w-100 d-flex flex-row justify-content-between flex-wrap ">
          <div
              *ngFor="let artist of managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].NESTED_ARTIST_LIST | managerPipe"
              class="d-flex flex-row" style="flex-basis: 48%; margin: 6px 0;">
            <div
                [style]="{'background-image': 'url(' + (artist.PHOTO) + ')'} | managerPipe"
                style="height: 100px; min-width: 100px; border-radius: 50px; background-size: contain;">
            </div>
            <div class="ml-3 announcement-article-text" style="margin-top: 0px">
              <div style="color: var(--artiox-blue); font-weight: 500;">
                {{artist.HEADING}}
              </div>
              <div [innerHTML]="artist.TEXT"></div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div [class]="managerService.navigationService.artist_announcement_gallery_active ? 'd-flex' : 'd-none'" class="w-100 flex-column justify-content-start align-items-center"
         style="background-color: #fcfdff;">
      <div style="height: 210px; padding: 80px 4px; font-family: bebas-neue-bold, sans-serif; font-size: 50px; letter-spacing: 1px; text-align: center; color: #404352;">
        {{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].ARTWORKS_UPPERCASE | managerPipe}}
      </div>

      <div class="w-100 d-flex flex-row">
        <div class="w-50 d-flex flex-column justify-content-start align-items-center"> <!-- left -->
          <div
              *ngFor="let artwork of (managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].ARTWORKS_LIST_DESKTOP | managerPipe); let i = index"
              class="position-relative"
              style="width: 90%;">
            <div #artwork_div [class.invisible]="i%2===1">
              <img [alt]="artwork.DESCRIPTION" [src]="artwork.SRC" class="w-100" #artwork_img
                   (error)="artwork_img.src = artwork_img.src.replace('.webp', '.jpg')">
              <div style="margin: 18px 2px 2px 2px; font-family: Roboto, sans-serif; font-size: 14px; letter-spacing: 0.40px; color: #1e1f25;">
                {{artwork.DESCRIPTION}}
              </div>
            </div>
            <div
                [class.invisible]="i%2===0 || i===0 || i===managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].ARTWORKS_LIST_DESKTOP.length-1"
                [style]="{height: (artwork_div.offsetHeight - 100) + 'px'}"
                class="position-absolute" style="width: 2px; min-width: 2px; border-radius:2px; background-color: #1e1f25; top: 50px; left: 50%;"></div>
            <div
                [class.invisible]="i%2===0 || i!==0 && i!==managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].ARTWORKS_LIST_DESKTOP.length-1"
                [style]="{width: (artwork_div.offsetWidth - 100) + 'px'}"
                class="position-absolute" style="height: 2px; min-height: 2px; border-radius:2px; background-color: #1e1f25; top: 50%; left: 50px;"></div>
          </div>
        </div>

        <div class="w-50 d-flex flex-column justify-content-start align-items-center"> <!-- right -->
          <div
              *ngFor="let artwork of (managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].ARTWORKS_LIST_DESKTOP | managerPipe); let i = index"
              class="position-relative"
              style="width: 90%;">
            <div #artwork_div [class.invisible]="i%2===0">
              <img [alt]="artwork.DESCRIPTION" [src]="artwork.SRC" class="w-100" #artwork_img
                   (error)="artwork_img.src = artwork_img.src.replace('.webp', '.jpg')">
              <div style="margin: 18px 2px 2px 2px; font-family: Roboto, sans-serif; font-size: 14px; letter-spacing: 0.40px; color: #1e1f25;">
                {{artwork.DESCRIPTION}}
              </div>
            </div>
            <div
                [class.invisible]="i%2===1 || i===0 || i===managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].ARTWORKS_LIST_DESKTOP.length-1"
                [style]="{height: (artwork_div.offsetHeight - 100) + 'px'}"
                class="position-absolute" style="width: 2px; min-width: 2px; border-radius:2px; background-color: #1e1f25; top: 50px; left: 50%;"></div>
            <div
                [class.invisible]="i%2===1 || i!==0 && i!==managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].ARTWORKS_LIST_DESKTOP.length-1"
                [style]="{width: (artwork_div.offsetWidth - 100) + 'px'}"
                class="position-absolute" style="height: 2px; min-height: 2px; border-radius:2px; background-color: #1e1f25; top: 50%; left: 50px;"></div>
          </div>
        </div>
      </div>

      <div style="margin-top: 50px; margin-left: 50px; font-family: Roboto, sans-serif; font-size: 14px; letter-spacing: 0.4px; color: #1e1f25; font-weight: 500;">
        <div
            *ngFor="let reference of (managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST[managerService.navigationService.artist_announcement_active_index].ARTWORK_REFERENCES | managerPipe)"
            [innerHTML]="reference | managerPipe" style="margin-left: -10px; font-size: 12px; color: #b1b3c5; font-style: italic; margin-bottom: 6px;">
        </div>
      </div>
    </div>

    <div class="w-100 d-flex justify-content-between position-sticky"
         style="bottom: 0; height: 90px; padding: 25px 4px; font-family: Roboto, sans-serif; font-size: 13px; letter-spacing: 0.4px; color: #1e1f25;">
      <div (click)="managerService.navigationManager({url: '/about-artist', current_component_index: 0})">
        <div class="btn-native btn-simple navigation-button" type="button">
          <svg style="height: 14px; width: 14px; margin-bottom: 2px;">
            <use [attr.xlink:href]='"./assets/images/announcement_up_arrow.svg#announcement_up_arrow"' fill="#ffffff"/>
          </svg>
          {{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.FOOTER.ARTIST_ARCHIVE | managerPipe}}
        </div>
      </div>

      <div (click)="managerService.navigationService.seeArtworks()" [class.d-none]="managerService.navigationService.artist_announcement_gallery_active">
        <div class="btn-native btn-simple navigation-button" type="button">
          <svg style="height: 14px; width: 14px; margin-bottom: 2px;">
            <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"' fill="#ffffff"/>
          </svg>
          {{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.FOOTER.SEE_ARTWORKS | managerPipe}}
        </div>
      </div>
      <div (click)="managerService.navigationService.backToArtworks()" [class.d-none]="!managerService.navigationService.artist_announcement_gallery_active">
        <div class="btn-native btn-simple navigation-button" type="button">

          <svg style="height: 14px; width: 14px; margin-bottom: 2px;">
            <use [attr.xlink:href]='"./assets/images/announcement_up_arrow.svg#announcement_up_arrow"' fill="#ffffff"/>
          </svg>
          {{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.FOOTER.BACK_TO_ARTIST | managerPipe}}
        </div>
      </div>

      <div class="d-flex flex-row">
        <div (click)="managerService.navigationService.artist_announcement_active_index=managerService.navigationService.artist_announcement_active_index-1;
        managerService.navigationService.artist_announcement_active_index=managerService.navigationService.artist_announcement_active_index + managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST.length;
        managerService.navigationService.artist_announcement_active_index=managerService.navigationService.artist_announcement_active_index % managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST.length;
        managerService.navigationService.setActiveArtist(managerService.navigationService.artist_announcement_active_index);">
          <div class="btn-native btn-simple navigation-button" type="button">
            <svg style="height: 14px; width: 14px; margin-bottom: 2px;">
              <use [attr.xlink:href]='"./assets/images/announcement_left_arrow.svg#announcement_left_arrow"' fill="#ffffff"/>
            </svg>
            {{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.FOOTER.PREVIOUS_ARTIST | managerPipe}}
          </div>
        </div>
        <div (click)="managerService.navigationService.artist_announcement_active_index=managerService.navigationService.artist_announcement_active_index+1;
        managerService.navigationService.artist_announcement_active_index=managerService.navigationService.artist_announcement_active_index + managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST.length;
        managerService.navigationService.artist_announcement_active_index=managerService.navigationService.artist_announcement_active_index % managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.ARTIST_LIST.length;
        managerService.navigationService.setActiveArtist(managerService.navigationService.artist_announcement_active_index);">
          <div class="btn-native btn-simple navigation-button ml-2" type="button">
            {{managerService.config.ANNOUNCEMENTS.ARTIST_ANNOUNCEMENTS.FOOTER.NEXT_ARTIST | managerPipe}}
            <svg style="height: 14px; width: 14px; margin-bottom: 2px;">
              <use [attr.xlink:href]='"./assets/images/announcement_right_arrow.svg#announcement_right_arrow"' fill="#ffffff"/>
            </svg>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>


