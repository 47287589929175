<div class="d-flex flex-row h-100" style="min-height: 100%; margin-bottom: 0 ">
    <!--    Artiox Purple background and image -->
    <div class="d-flex flex-column align-items-center position-relative justify-content-end w-100"
         style="background: linear-gradient(180deg, var(--main-color-artiox-purple-500) 0%, var(--main-color-artiox-purple-base) 100%);">

        <div class="position-absolute" style="left: 120px; top: 64px; cursor: pointer" (click)="this.managerService.backToHome()">
            <svg style="height: 30px; width: 130px;">
                <use [attr.xlink:href]='"./assets/images/artiox_logo.svg#artiox_logo"' fill="white"/>
            </svg>
        </div>
        <div class="h-100 d-flex justify-content-center align-items-center">

            <span class="header3 medium exo text-center"
                  style="color: var(--text-color-artiox-white, #FFFFFF); margin-top: 200px; margin-bottom: 120px; width: 480px">{{managerService.config.FORGOT_PASSWORD.HEADING}}</span>
        </div>
        <div>
            <img alt="market" src="./assets/screen_shots/desktop/forgot_password_phone_mock_up.png"
                 style="width: auto;height: 50dvh; min-height: 500px">
        </div>
    </div>

    <!--    Login elements half-->
    <div class="d-flex  w-100">

        <div *ngIf="!isRequestSent" class="d-flex justify-content-center h-100 w-100">
            <div class="d-flex flex-column justify-content-center" style="max-width: 364px">
                <span class="mb-2 header5 medium exo"
                      style="color: var(--text-color-artiox-black) ">{{managerService.config.FORGOT_PASSWORD.HEADING}}</span>
                <span class="body2 regular barlow"
                      style="color: var(--text-color-artiox-grey); margin-bottom: 32px">{{managerService.config.FORGOT_PASSWORD.INFO}}</span>

                <div [formGroup]="forgotPasswordForm" class="d-flex form-group focused mb-4">
                    <label class=" d-flex form-label body2 barlow medium" for="email">
                        {{managerService.config.FORGOT_PASSWORD.E_MAIL | managerPipe}}</label>
                    <input #email_input class="w-100 form-input body1 barlow regular" formControlName="email" id="email"
                           placeholder="{{managerService.config.FORGOT_PASSWORD.E_MAIL_PLACEHOLDER}}" type="text">
                    <div #email_error class="d-flex d-none flex-row " style="gap: 8px; margin-top: 12px">
                        <svg style=" height: 16px; width: 16px; ">
                            <use [attr.xlink:href]='"./assets/images/error_icon.svg#error_icon"' fill="#EA3943"/>
                        </svg>
                        <div class="body2 barlow regular" style="color: var(--secondary-colors-artiox-red-base)"></div>
                    </div>
                </div>

                <div (click)="validateEmail()" class="w-100  d-flex justify-content-center py-2 login-button">
                    <span class="body1 exo "
                          style="color:#FFFFFF; font-weight: 600">{{managerService.config.FORGOT_PASSWORD.SEND}}</span>
                </div>

            </div>
        </div>
        <div *ngIf="isRequestSent" class="d-flex justify-content-center h-100 w-100">
            <div class="d-flex flex-column justify-content-center" style="max-width: 364px">
                <span class="mb-2 header5 medium exo"
                      style="color: var(--text-color-artiox-black) ">{{managerService.config.FORGOT_PASSWORD.HEADING}}</span>
                <span class="body0 medium barlow"
                      style="color: var(--text-color-artiox-grey); margin-bottom: 32px">{{managerService.config.FORGOT_PASSWORD.SEND_SUCCESFULLY_BEGGINING}}
                    <span class="body0 barlow bold"
                          style="color: var(--main-color-artiox-purple-base)">{{forgotPasswordForm.get('email')?.value}}</span>{{managerService.config.FORGOT_PASSWORD.SEND_SUCCESFULLY_END}}</span>

                <div class="d-flex w-100 justify-content-center">
                <span (click)="resendEmail()" [ngStyle]="{color: isTextClickable ? 'var(--main-color-artiox-purple-base' : 'var(--secondary-colors-artiox-gray-500)', cursor: isTextClickable? 'pointer': 'default'}"
                      class="body1 regular barlow clickable_text"
                      style="text-decoration: underline">
                    {{managerService.config.FORGOT_PASSWORD.RESEND_REQUEST}} <span
                        *ngIf="!isTextClickable">({{countdown}}{{managerService.config.FORGOT_PASSWORD.SECOND_ABBREVIATION}}
                    )</span>
                </span>
                </div>

            </div>
        </div>
    </div>


</div>
