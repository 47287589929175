import {Component, Input, ViewChild} from '@angular/core';
import {AppModule} from '../../../../../app.module';
import {NgForOf, NgIf} from '@angular/common';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
  selector: 'app-ethereum-deposit-rules-desktop',
  templateUrl: './ethereum-deposit-rules-desktop.component.html',
  styleUrl: './ethereum-deposit-rules-desktop.component.css'
})
export class EthereumDepositRulesDesktopComponent {
  @ViewChild('checkbox_field_desktop') checkbox_field_desktop: any = undefined;

  @Input() modal_id: string = '';

  constructor(public managerService: ManagerService) { }

  ngOnInit(): void {
  }

  onAccept(): void {
    this.managerService.operationsService.ethereum_deposit_rules_accepted_status = true;
    this.managerService.closeModal(this.modal_id);
    this.checkbox_field_desktop.checked = false;
    this.managerService.marketingService.logEvent('deposit_rules_approved', {symbol: 'ETH'});
  }
}
