import {Injectable, ApplicationRef, ComponentFactoryResolver, Injector} from '@angular/core';
import {SnackBarComponent} from './snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  public snackBarRefList: any[] = [];
  public snackBarComponentFactory: any;

  constructor(private applicationRef: ApplicationRef, private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector) {
    this.snackBarComponentFactory = this.componentFactoryResolver.resolveComponentFactory(SnackBarComponent);
  }

  push(status: boolean, heading: string, message: string): void {
    this.snackBarRefList.forEach(element => {
      element.instance.shift();
    });
    const snackBarRef = this.snackBarComponentFactory.create(this.injector);
    document.getElementById('snack-bar-container')?.appendChild(snackBarRef.location.nativeElement);
    this.applicationRef.attachView(snackBarRef.hostView);
    this.snackBarRefList.push(snackBarRef);
    if (this.snackBarRefList.length > 5) {
      this.pop();
    }
    snackBarRef.instance.open(status, heading, message);
  }

  pop(): void {
    const snackBarRef = this.snackBarRefList.shift();
    snackBarRef.instance.close(false);
    setTimeout(() => {
      snackBarRef.destroy();
    }, 300);
  }

}
