<div *ngIf="this.delivery_fetched" class="d-flex justify-content-center">

    <div class="d-flex  "
         style="box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08); background: #FCFDFF; border-radius: 6px;">
        <div class="d-flex flex-column m-5 " style="min-width: 912px">
            <!--    Kazanilan Eser Bilgileri -->
            <div class="d-flex flex-column ">
                <div class="title mb-4">
                    {{managerService.config.PAYMENT.HEADER | managerPipe}}
                </div>
                <div class="d-flex flex-column w-100 pb-4 mb-4"
                     style="box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.15);border-radius: 6px;">
                    <!--Lot Number div-->
                    <div class="d-flex justify-content-center  align-items-center mb-4">
                        <div class="lot-number"
                             style="background: #28A79B; box-shadow: 0px 4px 4px 0px #FFFFFF40; border-radius: 0px 0px 4px 4px; gap: 8px; color: #FFFFFF; width: 80px;
                   height: 21px; text-align: center; align-content: center;
                   font-weight: 700; font-size: 10px; line-height: 20px; letter-spacing: 0.25px">
                            LOT {{auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_LOT }}
                        </div>
                    </div>

                    <div class="d-flex flex-row justify-content-around align-items-center  w-100 h-100">
                        <div class="d-flex w-100  justify-content-center ml-2">
                            <img #catalog_img
                                 (error)="catalog_img.src = catalog_img.src.replace('.webp', '.jpg');" alt="catalog_img"
                                 src="{{auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_THUMBNAIL | managerPipe: managerService.mpa_template.LOCAL_IMAGE_MANAGER_TO_BE}}"
                                 style="max-height: 103px;border-radius: 0.375rem;background:#FCFDFF;box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.15); object-position:center;overflow: clip"
                            >
                        </div>
                        <div class="d-flex flex-column h-100 justify-content-center align-items-center w-100">
                            <div style="font-family: Roboto, sans-serif; text-align: center; max-width: 200px">
                                <div class="mb-2 "
                                     style="font-family: Roboto, sans-serif;font-style: normal;font-weight: 500;font-size: 14px;line-height: 16px;letter-spacing: 0.001em;color: #404352;">
                                    {{ auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_ARTIST_NAME }}
                                </div>
                                <div class="mb-2 "
                                     style="font-family: Roboto, sans-serif;font-style: italic;font-weight: 500;font-size: 13px;line-height: 15px;letter-spacing: 0.001em;color: #686C75;">
                                    {{ auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_NAME}}
                                </div>
                                <div
                                    [innerHTML]="auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_TEXT"
                                    class="mb-2 "
                                    style="font-family: Roboto, sans-serif;font-style: italic;font-weight: 500;font-size: 13px;line-height: 15px;letter-spacing: 0.001em;color: #686C75;">
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-center  h-100 w-100">

                            <div class="mb-1"
                                 style="font-family: Roboto, sans-serif;font-style: normal;font-weight: 700;font-size: 16px;line-height: 19px;letter-spacing: 0.0015em;color: #28A79B;">
                                {{auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_TOTAL_PRICE}}

                            </div>
                            <div
                                style="font-family: Roboto, sans-serif;font-style: normal;font-weight: 400;font-size: 12px;line-height: 14px;letter-spacing: 0.06px;color: #AAAEB5;">
                                {{managerService.config.ACCOUNT_AUCTION.TOTAL_PRICE | managerPipe}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="selected_user_delivery.status === 'pending'">
                <div [innerHTML]="managerService.config.PAYMENT.MANDATORY_FIELDS | managerPipe"
                     class="d-flex mb-4 mandatory-fields-text"></div>
                <!--    Teslimat Secenekleri -->
                <div class="d-flex flex-column" style="margin-bottom: 36px">
                    <div [innerHTML]="managerService.config.PAYMENT.DELIVERY_OPTIONS.HEADER | managerPipe"
                         class="title mb-4">
                    </div>
                    <div class="d-flex flex-row justify-content-between">
                        <div (click)="selected_delivery_type = DeliveryType.Cargo"
                             [class.selected]="selected_delivery_type===DeliveryType.Cargo"
                             class="d-flex flex-row justify-content-between p-3 "
                             style=" box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08); border-radius: 8px; cursor: pointer; min-width: 388px">
                            <div class="d-flex flex-row justify-content-between w-100">
                                <div class="d-flex flex-row justify-content-start align-items-center">
                                    <!--                Kargo Icon -->
                                    <div class="d-flex mr-2 align-items-center justify-content-center"
                                         style="border-radius: 100px; width: 32px; height: 32px; background: #4BC4D6; box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);">
                                        <img alt="cargo" src="./assets/images/cargo.svg"
                                             style="width: 20px; height: 20px;">
                                    </div>
                                    <!--                    Yazilar-->
                                    <div class="d-flex flex-column">
                                    <span
                                        class="shipment-title">{{managerService.config.PAYMENT.DELIVERY_OPTIONS.SHIPPING | managerPipe}}</span>
                                        <span
                                            class="shipment-text">{{managerService.config.PAYMENT.DELIVERY_OPTIONS.SHIPPING_TEXT| managerPipe}}</span>
                                    </div>

                                </div>
                                <div class="d-flex align-items-center ">
                                    <div
                                        [style.border]="selected_delivery_type=== DeliveryType.Cargo ? '1px solid #4BC4D6' : '1px solid #9DA5B7' "
                                        class="d-flex align-items-center justify-content-center"
                                        style=" width: 20px; height: 20px; border-radius: 100px;">
                                        <div [class.selected_circle]="selected_delivery_type===DeliveryType.Cargo"
                                             class="d-flex"></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div (click)="selected_delivery_type = DeliveryType.HandDelivery"
                             [class.selected]="selected_delivery_type===DeliveryType.HandDelivery"
                             class="d-flex flex-row justify-content-between p-3 "
                             style="box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08); border-radius: 8px; cursor: pointer; min-width: 388px">
                            <div class="d-flex flex-row justify-content-between w-100">
                                <div class="d-flex flex-row align-items-center">
                                    <!--                Kargo Icon -->
                                    <div class="d-flex mr-2 align-items-center justify-content-center"
                                         style="border-radius: 100px; width: 32px; height: 32px; background: #4BC4D6; box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);">
                                        <img alt="hand_delivery" src="./assets/images/hand_delivery.svg"
                                             style="width: 20px; height: 20px;">
                                    </div>
                                    <!--                    Yazilar-->
                                    <div class="d-flex flex-column">
                                    <span class="shipment-title">
                                        {{managerService.config.PAYMENT.DELIVERY_OPTIONS.PICK_UP | managerPipe}}
                                    </span>
                                        <span class="shipment-text">
                                        {{managerService.config.PAYMENT.DELIVERY_OPTIONS.PICK_UP_TEXT | managerPipe}}
                                    </span>
                                    </div>

                                </div>
                                <div class="d-flex align-items-center">

                                    <div
                                        [style.border]="selected_delivery_type=== DeliveryType.HandDelivery ? '1px solid #4BC4D6' : '1px solid #9DA5B7' "
                                        class="d-flex align-items-center justify-content-center"
                                        style=" width: 20px; height: 20px; border-radius: 100px;">
                                        <div [class.selected_circle]="selected_delivery_type===DeliveryType.HandDelivery"
                                             class="d-flex"></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <!--    Teslimat Adresi-->
                <div class="d-flex flex-column" style="margin-bottom: 36px">
                <span [innerHTML]="managerService.config.PAYMENT.DELIVERY_ADDRESS.HEADER | managerPipe"
                      class="title mb-4">

                </span>
                    <div (click)="managerService.openAddDeliveryAddressModal(null)"
                         *ngIf="selected_delivery_type != DeliveryType.HandDelivery && delivery_address_count < 3"
                         class="d-flex flex-row "
                         style="cursor: pointer">
                        <img alt="add address" class="mr-2" src="./assets/images/add_address.svg"
                             style="width: 16px; height: 16px">

                        <div
                            class="add-address-text text-center">{{managerService.config.PAYMENT.DELIVERY_ADDRESS.TEXT | managerPipe}}</div>
                    </div>

                    <div class="d-flex flex-row" style="gap: 48px; margin-top: 32px">
                        <div
                            *ngFor="let deliveryaddress of (managerService.user_info_pipe | managerPipe: managerService.mpa_template.GET_USER_DELIVERY_ADDRESS: selected_delivery_type: managerService.language_pipe)">
                            <div (click)="selected_delivery_address = deliveryaddress;"
                                 [style.border]="selected_delivery_address === deliveryaddress ? '2px solid #4BC4D6' : '1px solid #9DA5B7' "
                                 class="d-flex flex-column"
                                 style="width: 200px; height: 140px;border-radius: 4px;">
                                <div class="d-flex w-100 flex-row justify-content-between"
                                     style="background-color: #F5F6F7;border-radius: 4px; padding: 8px 16px;">
                            <span
                                style="overflow: hidden;color: #404352;text-overflow: ellipsis;font-family: Roboto, sans-serif;font-size: 16px;font-style: normal;font-weight: 500;line-height: 19px;">
                                {{deliveryaddress.label}}
                            </span>

                                    <div (click)="managerService.stopProp($event);toggleEdit(edit_dropdown_1)"
                                         *ngIf="selected_delivery_type !== DeliveryType.HandDelivery"
                                         class="edit-button">
                                        <svg style="width:16px; height: 17px; cursor: pointer">
                                            <use [attr.xlink:href]='"./assets/images/edit_icon.svg#edit_icon"'/>
                                        </svg>
                                        <div #edit_dropdown_1 class="edit-dropdown" style="user-select: none">
                                            <div (click)="managerService.openAddDeliveryAddressModal(deliveryaddress)"
                                                 class="d-flex justify-content-center edit-select"
                                                 style="padding: 16px 48px;">
                                                <svg style="width:16px; height: 16px">
                                                    <use [attr.xlink:href]='"./assets/images/pencil.svg#pencil"'/>
                                                </svg>
                                                <div
                                                    style="color: #404352;font-family: Roboto, sans-serif;font-size: 10px;font-style: normal;font-weight: 500;line-height: 19px;margin-left: 8px">
                                                    {{managerService.config.PAYMENT.EDIT | managerPipe}}
                                                </div>
                                            </div>
                                            <div (click)="managerService.openDeleteAddressModal(deliveryaddress.address_id, 'delivery')"
                                                 class="d-flex justify-content-center edit-select"
                                                 style="padding: 16px 48px;">
                                                <svg style="width:16px; height: 16px">
                                                    <use [attr.xlink:href]='"./assets/images/trash.svg#trash"'/>
                                                </svg>
                                                <div
                                                    style="color: #404352;font-family: Roboto, sans-serif;font-size: 10px;font-style: normal;font-weight: 500;line-height: 19px;margin-left: 8px">
                                                    {{managerService.config.PAYMENT.DELETE | managerPipe}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="d-flex w-100 flex-row" style="padding: 8px; align-items: center;">
                            <span
                                style="overflow: hidden;color: #404352;text-overflow: ellipsis;white-space: nowrap;font-family: Roboto, sans-serif;font-size: 12px;font-style: normal;font-weight: 400;line-height: 19px;">{{deliveryaddress.address}} </span>
                                </div>
                            </div>


                        </div>

                    </div>
                    <!--    Fatura Adresi-->

                    <div class="d-flex flex-column" style="margin-bottom: 36px; margin-top: 36px">
                        <div [innerHTML]="managerService.config.PAYMENT.BILLING_ADDRESS.HEADER | managerPipe"
                             class=" mb-4"></div>
                        <div (click)="managerService.openAddBillingAddressModal(null)" *ngIf="billing_address_count < 3" class="d-flex flex-row "
                             style="cursor: pointer">
                            <img alt="add address" class="mr-2" src="./assets/images/add_address.svg"
                                 style="width: 16px; height: 16px">

                            <div
                                class="add-address-text text-center">{{managerService.config.PAYMENT.BILLING_ADDRESS.TEXT | managerPipe}}</div>
                        </div>
                        <div class="d-flex flex-row" style="gap: 48px; margin-top: 16px">
                            <div
                                *ngFor="let billingaddress of (managerService.user_info_pipe | managerPipe: managerService.mpa_template.GET_USER_BILLING_ADDRESS)">
                                <div (click)="selected_billing_address = billingaddress;"
                                     [style.border]="selected_billing_address === billingaddress ? '2px solid #4BC4D6' : '1px solid #9DA5B7' "
                                     class="d-flex flex-column"
                                     style="width: 200px; height: 140px;border-radius: 4px;">
                                    <div class="d-flex w-100 flex-row justify-content-between"
                                         style="background-color: #F5F6F7;border-radius: 4px; padding: 8px 16px;">
                            <span
                                style="overflow: hidden;color: #404352;text-overflow: ellipsis;font-family: Roboto, sans-serif;font-size: 16px;font-style: normal;font-weight: 500;line-height: 19px;">
                                {{billingaddress.label}}
                            </span>

                                        <div (click)="managerService.stopProp($event);toggleEdit(edit_dropdown)"
                                             class="edit-button">
                                            <svg style="width:16px; height: 17px; cursor: pointer">
                                                <use [attr.xlink:href]='"./assets/images/edit_icon.svg#edit_icon"'/>
                                            </svg>
                                            <div #edit_dropdown class="edit-dropdown" style="user-select: none">
                                                <div (click)="managerService.openAddBillingAddressModal(billingaddress)"
                                                     class="d-flex justify-content-center edit-select"
                                                     style="padding: 16px 48px;">
                                                    <svg style="width:16px; height: 16px">
                                                        <use [attr.xlink:href]='"./assets/images/pencil.svg#pencil"'/>
                                                    </svg>
                                                    <div
                                                        style="color: #404352;font-family: Roboto, sans-serif;font-size: 10px;font-style: normal;font-weight: 500;line-height: 19px;margin-left: 8px">
                                                        {{managerService.config.PAYMENT.EDIT | managerPipe}}
                                                    </div>
                                                </div>
                                                <div (click)="managerService.openDeleteAddressModal(billingaddress.address_id, 'billing')"
                                                     class="d-flex justify-content-center edit-select"
                                                     style="padding: 16px 48px;">
                                                    <svg style="width:16px; height: 16px">
                                                        <use [attr.xlink:href]='"./assets/images/trash.svg#trash"'/>
                                                    </svg>
                                                    <div
                                                        style="color: #404352;font-family: Roboto, sans-serif;font-size: 10px;font-style: normal;font-weight: 500;line-height: 19px;margin-left: 8px">
                                                        {{managerService.config.PAYMENT.DELETE | managerPipe}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="d-flex w-100 flex-row" style="padding: 8px; align-items: center;">
                            <span
                                style="overflow: hidden;color: #404352;text-overflow: ellipsis;white-space: nowrap;font-family: Roboto, sans-serif;font-size: 12px;font-style: normal;font-weight: 400;line-height: 19px;">{{billingaddress.address}} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-column " style="margin-top: 36px">
                            <span class="title mb-4">{{managerService.config.PAYMENT.PAYMENT_INFO.HEADER}}</span>
                            <div class="d-flex flex-row justify-content-center title" style="margin-bottom: 32px">
                            <span
                                style="color: #4BC4D6; margin-right: 32px">{{managerService.config.PAYMENT.PAYMENT_INFO.MONEY_ORDER}}</span>
                                <span
                                    style="color: #9DA5B7">{{managerService.config.PAYMENT.PAYMENT_INFO.CREDIT_CARD}}</span>
                            </div>
                            <div class="d-flex flex-row justify-content-between h-100">
                                <div  style="margin-right: 32px;border-radius: 4px; box-shadow: 0px 0px 4px 0px #9DA5B7; ">
                                    <img
                                        #bank
                                        (error)="bank.src = bank.src.replace('.webp', '.jpg')"
                                        [src]="'Garanti Bankası'| managerPipe: managerService.mpa_template.GET_BANK_ACCOUNT_IMG_SRC"
                                        alt="bank"
                                        width="292px" >
                                </div>
                                <div class="d-flex flex-column bank-account-text w-100 justify-content-center">
                                <span
                                    class="mb-3 bank-account-text">{{managerService.config.PAYMENT.PAYMENT_INFO.BANK_NAME}}</span>
                                    <div class="d-flex flex-row">
                                        <span [innerHTML]="managerService.config.PAYMENT.PAYMENT_INFO.ACCOUNT_TITLE"
                                              class="mb-3 bank-account-text"></span>
                                        <svg
                                            (click)="managerService.copyToClipboard(managerService.config.PAYMENT.PAYMENT_INFO.ACCOUNT_TITLE_TEXT, managerService.config.DEPOSIT.COPIED)"
                                            style=" height: 18px; width: 18px; margin-left: 4px">
                                            <use [attr.xlink:href]='"./assets/images/copy.svg#copy"' fill="#ABAFC7"/>
                                        </svg>
                                    </div>
                                    <div class="d-flex flex-row">
                                        <span [innerHTML]="managerService.config.PAYMENT.PAYMENT_INFO.IBAN"
                                              class="mb-3"></span>
                                        <svg (click)="managerService.copyToClipboard(managerService.config.PAYMENT.PAYMENT_INFO.IBAN_NO, managerService.config.DEPOSIT.COPIED)"
                                             style=" height: 18px; width: 18px; margin-left: 4px">
                                            <use [attr.xlink:href]='"./assets/images/copy.svg#copy"' fill="#ABAFC7"/>
                                        </svg>
                                    </div>
                                    <div class="d-flex flex-row">
                                    <span [innerHTML]="managerService.config.PAYMENT.PAYMENT_INFO.MESSAGE"
                                          class="mb-3"></span>
                                        <span style="color: #4BC4D6"> &nbsp; {{auction_product | managerPipe: managerService.mpa_template.AUCTION_PAYMENT_DESCRIPTION: managerService.language_pipe }}</span>
                                        <svg
                                            (click)="managerService.copyToClipboard(managerService.auctionPaymentDescription(auction_product), managerService.config.DEPOSIT.COPIED)"
                                            style=" height: 18px; width: 18px; margin-left: 4px">
                                            <use [attr.xlink:href]='"./assets/images/copy.svg#copy"' fill="#ABAFC7"/>
                                        </svg>
                                    </div>
                                    <div class="d-flex flex-row align-items-center">
                                        <svg
                                                style=" height: 10px; width: 10px; margin-right: 4px">
                                            <use [attr.xlink:href]='"./assets/images/info.svg#info"' fill="#9DA5B7"/>
                                        </svg>
                                    <span [innerHTML]="managerService.config.PAYMENT.PAYMENT_INFO.INFO"
                                          style="font-size: 12px"></span>
                                    </div>
                                </div>
                                <div class="d-flex align-items-end justify-content-end">
                                    <button  (click)="completePayment()"
                                         [disabled]="selected_billing_address === undefined || selected_delivery_address === undefined"
                                         class="d-flex align-items-center justify-content-center payment-button">
                                        <span class="bank-account-text" style="color: white">
                                            {{managerService.config.PAYMENT.PAYMENT_INFO.COMPLETE}}
                                        </span>
                                        <svg style="width:15px; height: 20px; rotate: 270deg; ">
                                            <use [attr.xlink:href]='"./assets/images/dropdown.svg#dropdown"'
                                                 fill="#FFFFFF"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="selected_user_delivery.status !== 'pending'">
                <div class="d-flex flex-column" style="margin-bottom: 36px">
                    <div class="title mb-4">
                        {{managerService.config.PAYMENT.DELIVERY_OPTIONS.HEADER_WITHOUT_ASTERIX | managerPipe}}
                    </div>
                    <div class="d-flex flex-row justify-content-between">
                        <div [style.display]="selected_delivery_type===DeliveryType.Cargo? 'flex' : 'none' "
                             class="flex-row justify-content-between p-3 "
                             style=" box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08); border-radius: 8px; cursor: pointer; min-width: 388px">
                            <div class="d-flex flex-row justify-content-between w-100">
                                <div class="d-flex flex-row justify-content-start align-items-center">
                                    <!--                Kargo Icon -->
                                    <div class="d-flex mr-2 align-items-center justify-content-center"
                                         style="border-radius: 100px; width: 32px; height: 32px; background: #4BC4D6; box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);">
                                        <img alt="cargo" src="./assets/images/cargo.svg"
                                             style="width: 20px; height: 20px;">
                                    </div>
                                    <!--                    Yazilar-->
                                    <div class="d-flex flex-column">
                                    <span
                                        class="shipment-title">{{managerService.config.PAYMENT.DELIVERY_OPTIONS.SHIPPING | managerPipe}}</span>
                                        <span
                                            class="shipment-text">{{managerService.config.PAYMENT.DELIVERY_OPTIONS.SHIPPING_TEXT| managerPipe}}</span>
                                    </div>

                                </div>
                                <div class="d-flex align-items-center ">

                                    <div
                                        [style.border]="selected_delivery_type=== DeliveryType.Cargo ? '1px solid #4BC4D6' : '1px solid #9DA5B7' "
                                        class="d-flex align-items-center justify-content-center"
                                        style=" width: 20px; height: 20px; border-radius: 100px;">
                                        <div [class.selected_circle]="selected_delivery_type===DeliveryType.Cargo"
                                             class="d-flex"></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div [style.display]="selected_delivery_type===DeliveryType.HandDelivery? 'flex' : 'none' "
                             class="flex-row justify-content-between p-3 "
                             style="box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08); border-radius: 8px; cursor: pointer; min-width: 388px">
                            <div class="d-flex flex-row justify-content-between w-100">
                                <div class="d-flex flex-row align-items-center">
                                    <!--                Kargo Icon -->
                                    <div class="d-flex mr-2 align-items-center justify-content-center"
                                         style="border-radius: 100px; width: 32px; height: 32px; background: #4BC4D6; box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);">
                                        <img alt="hand_delivery" src="./assets/images/hand_delivery.svg"
                                             style="width: 20px; height: 20px;">
                                    </div>
                                    <!--                    Yazilar-->
                                    <div class="d-flex flex-column">
                                    <span class="shipment-title">
                                        {{managerService.config.PAYMENT.DELIVERY_OPTIONS.PICK_UP | managerPipe}}
                                    </span>
                                        <span class="shipment-text">
                                        {{managerService.config.PAYMENT.DELIVERY_OPTIONS.PICK_UP_TEXT | managerPipe}}
                                    </span>
                                    </div>

                                </div>
                                <div class="d-flex align-items-center">
                                    <div
                                        [style.border]="selected_delivery_type=== DeliveryType.HandDelivery ? '1px solid #4BC4D6' : '1px solid #9DA5B7' "
                                        class="d-flex align-items-center justify-content-center"
                                        style=" width: 20px; height: 20px; border-radius: 100px;">
                                        <div [class.selected_circle]="selected_delivery_type===DeliveryType.HandDelivery"
                                             class="d-flex"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex w-100 justify-content-start flex-column" style="margin-top: 36px">
                        <p class="title mb-4">{{managerService.config.USER_INFORMATION.DELIVERY_ADDRESS | managerPipe}}</p>
                        <p class="address-title">{{selected_delivery_address?.label}}</p>
                        <p class="address-body">{{selected_delivery_address?.address}}</p>
                    </div>
                    <div class="d-flex w-100 justify-content-start flex-column" style="margin-top: 36px">
                        <p class="title mb-4">{{managerService.config.USER_INFORMATION.BILLING_ADDRESS | managerPipe}}</p>
                        <p class="address-title">{{selected_billing_address?.label}}</p>
                        <p class="address-body">{{selected_billing_address?.address}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
