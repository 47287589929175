import {Component, OnInit, ViewChild} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-auction-catalog-mobile',
    templateUrl: './auction-catalog-mobile.component.html',
    styleUrls: ['./auction-catalog-mobile.component.css']
})
export class AuctionCatalogMobileComponent implements OnInit {
    @ViewChild('clear_keyword') clear_keyword: any;
    @ViewChild('clear_price') clear_price: any;
    @ViewChild('clear_all') clear_all: any;
    @ViewChild('display_filters') display_filters: any;
    @ViewChild('display_keyword') display_keyword: any;
    @ViewChild('display_price') display_price: any;

    filterForm = new FormGroup({
        keyword: new FormControl(''),
        min: new FormControl(''),
        max: new FormControl(''),
    });
    showFilterForm: boolean = false;

    constructor(public managerService: ManagerService) {
        this.showFilterForm = false;
    }

    ngOnInit(): void {
    }

    toggleFilterForm(): void {
        this.showFilterForm = !this.showFilterForm;
        if (this.showFilterForm) {
            if (this.managerService.auctionService.auction_catalog_filter.keyword !== '') {
                this.display_filters.nativeElement.style.display = 'flex';
                this.filterForm.get('keyword')?.setValue(this.managerService.auctionService.auction_catalog_filter.keyword);
                this.clear_keyword.nativeElement.style.display = 'flex';
                this.clear_all.nativeElement.style.display = 'flex';
            }
            if (this.managerService.auctionService.auction_catalog_filter.min !== -1 || this.managerService.auctionService.auction_catalog_filter.max !== -1) {
                this.display_filters.nativeElement.style.display = 'flex';
                this.clear_price.nativeElement.style.display = 'flex';
                this.clear_all.nativeElement.style.display = 'flex';
                if (this.managerService.auctionService.auction_catalog_filter.min !== -1) {
                    this.filterForm.get('min')?.setValue(this.managerService.auctionService.auction_catalog_filter.min);
                }
                if (this.managerService.auctionService.auction_catalog_filter.max !== -1) {
                    this.filterForm.get('max')?.setValue(this.managerService.auctionService.auction_catalog_filter.max);
                }
            }
        }
    }

    addFilter(): void {
        if (this.filterForm.get('keyword')?.value !== '') {
            this.managerService.auctionService.auction_catalog_filter.keyword = this.filterForm.get('keyword')?.value;
            this.display_filters.nativeElement.style.display = 'flex';
            this.display_keyword.nativeElement.style.display = 'flex';
            this.clear_keyword.nativeElement.style.display = 'flex';
            this.clear_all.nativeElement.style.display = 'flex';
        }
        if (this.filterForm.get('min')?.value !== '' || this.filterForm.get('max')?.value !== '') {
            this.display_filters.nativeElement.style.display = 'flex';
            this.display_price.nativeElement.style.display = 'flex';
            this.clear_price.nativeElement.style.display = 'flex';
            this.clear_all.nativeElement.style.display = 'flex';
            if (this.filterForm.get('min')?.value === '') {
                this.managerService.auctionService.auction_catalog_filter.min = -1;
            } else {
                this.managerService.auctionService.auction_catalog_filter.min = this.filterForm.get('min')?.value;
            }
            if (this.filterForm.get('max')?.value === '') {
                this.managerService.auctionService.auction_catalog_filter.max = -1;
            } else {
                this.managerService.auctionService.auction_catalog_filter.max = this.filterForm.get('max')?.value;
            }
        }
        this.managerService.auctionService.auction_product_list_pipe = !this.managerService.auctionService.auction_product_list_pipe;

    }

    clearKeyword(): void {
        const filter = this.managerService.auctionService.auction_catalog_filter;
        filter.keyword = '';
        this.filterForm.get('keyword')?.setValue('');
        this.clear_keyword.nativeElement.style.display = 'none';
        if (filter.max === -1 && filter.min === -1 && filter.keyword === '') {
            this.clear_all.nativeElement.style.display = 'none';
        }
        this.managerService.auctionService.auction_product_filter_pipe = !this.managerService.auctionService.auction_product_filter_pipe;
    }

    clearPrice(): void {
        const filter = this.managerService.auctionService.auction_catalog_filter;
        filter.min = -1;
        filter.max = -1;
        this.filterForm.get('min')?.setValue('');
        this.filterForm.get('max')?.setValue('');
        this.clear_price.nativeElement.style.display = 'none';
        if (filter.max === -1 && filter.min === -1 && filter.keyword === '') {
            this.clear_all.nativeElement.style.display = 'none';
        }
        this.managerService.auctionService.auction_product_filter_pipe = !this.managerService.auctionService.auction_product_filter_pipe;
    }

    clearAll(): void {
        const filter = this.managerService.auctionService.auction_catalog_filter;
        filter.keyword = '';
        filter.min = -1;
        filter.max = -1;
        this.filterForm.get('keyword')?.setValue('');
        this.filterForm.get('min')?.setValue('');
        this.filterForm.get('max')?.setValue('');
        this.clear_keyword.nativeElement.style.display = 'none';
        this.clear_price.nativeElement.style.display = 'none';
        this.clear_all.nativeElement.style.display = 'none';
        this.managerService.auctionService.auction_product_filter_pipe = !this.managerService.auctionService.auction_product_filter_pipe;
    }

    toggleDisplay(div: HTMLDivElement): void {
        if (div.style.display === 'flex') {
            div.style.display = 'none';
        } else {
            div.style.display = 'flex';
        }
    }
}
