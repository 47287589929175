<div class="d-flex flex-row h-100 " style="min-height: 100%; margin-bottom: 0; ">
    <!--    Login elements half-->
    <div  class="d-flex position-relative w-100" >
        <div class="position-absolute" style="left: 120px; top: 64px; cursor: pointer" (click)="this.managerService.backToHome()">
            <img src="./assets/images/artiox_logo.svg#artiox_logo" alt="artiox_logo" width="130px" height="30px" style="fill: black">
        </div>
        <div class="d-flex justify-content-center h-100 w-100" [formGroup]="loginForm">
            <div *ngIf="login_step !== LoginStep.TWO_FACTOR" class="d-flex flex-column justify-content-center">
                <span class="mb-2 header5 medium exo" style="color: var(--text-color-artiox-black) ">{{ managerService.config.LOGIN.WELCOME_TO_ARTIOX | managerPipe}}</span>
                <span class="body2 regular barlow mb-5" style="color: var(--text-color-artiox-grey)">{{ managerService.config.LOGIN.SUBTITLE}}</span>

                <div class="d-flex form-group focused mb-4">
                    <label class=" d-flex form-label body2 barlow medium" for="email">
                        {{managerService.config.LOGIN.E_MAIL | managerPipe}}</label>
                    <input class="w-100 form-input body1 barlow regular" (keydown.enter)="validateFormGroup()" formControlName="email" id="email" type="text" placeholder="{{managerService.config.LOGIN.E_MAIL_PLACEHOLDER}}">
                    <div  #email_error  class="d-flex d-none flex-row " style="gap: 8px; margin-top: 12px" >
                        <svg style=" height: 16px; width: 16px; ">
                            <use [attr.xlink:href]='"./assets/images/error_icon.svg#error_icon"' fill="#EA3943"/>
                        </svg>
                        <div  class="body2 barlow regular"  style="color: var(--secondary-colors-artiox-red-base)"></div>
                    </div>
                </div>
                <div class="d-flex flex-row  " >
                    <div class="d-flex form-group focused mb-3 ">
                        <label class=" d-flex form-label body2 barlow medium position-relative" for="password">
                            {{managerService.config.LOGIN.PASSWORD | managerPipe}}
                            <svg (click)="passwordPipe = !passwordPipe" class="password-eye" style="height: 24px; width: 24px; cursor: pointer" >
                                <use *ngIf="(!passwordPipe | managerPipe)"  [attr.xlink:href]='"./assets/images/v2/ic_eye_close.svg#ic_eye_close"' />
                                <use *ngIf="(passwordPipe | managerPipe)" [attr.xlink:href]='"./assets/images/v2/ic_eye_open.svg#ic_eye_open"'/>
                            </svg>
                        </label>
                        <input class="w-100 form-input body1 barlow regular" (keydown.enter)="validateFormGroup()" formControlName="password" id="password" [type]="(passwordPipe | managerPipe) ? 'text': 'password'" placeholder="{{managerService.config.LOGIN.PASSWORD_PLACEHOLDER}}">
                        <div #password_error  class="d-flex d-none flex-row" style="gap: 8px; margin-top: 12px" >
                            <svg style=" height: 16px; width: 16px; ">
                                <use [attr.xlink:href]='"./assets/images/error_icon.svg#error_icon"' fill="#EA3943"/>
                            </svg>
                            <span class="body2 barlow regular"  style="color: var(--secondary-colors-artiox-red-base)"></span>
                        </div>
                    </div>


                </div>
                <div class="d-flex flex-row justify-content-between">
                    <div class="d-flex flex-row align-items-center" style="gap: 8px; margin-bottom: 32px">
<!--                        <div  class="d-flex login-checkbox align-items-center"  (click)="checkboxSelected = !checkboxSelected" [class.login-checkbox-selected]="checkboxSelected">-->
<!--                            <svg *ngIf="checkboxSelected" style="height: 16px; width: 16px;">-->
<!--                                <use [attr.xlink:href]='"./assets/images/checkbox.svg#checkbox"' fill="#8266F0"/>-->
<!--                            </svg>-->
<!--                        </div>-->
<!--                        <span class="d-flex body2 barlow regular" style="color: var(&#45;&#45;text-color-artiox-light-black)">Hesabimi hatirla</span>-->
                    </div>
                    <span class="body2 regular barlow clickable_text" style="margin-bottom: 48px" (click)="managerService.navigationManager({url: '/forgot-password', current_component_index: 0});" >{{managerService.config.LOGIN.FORGOT_PASSWORD}}</span>
                </div>

                <div class="w-100  d-flex justify-content-center py-2 login-button" (click)="validateFormGroup()">
                    <span class="body1 exo " style="color:#FFFFFF; font-weight: 600">{{ managerService.config.LOGIN.LOGIN | managerPipe}}</span>
                </div>
                <div class="d-flex w-100 justify-content-center mt-3">
                    <span  class="body2 regular exo" style="color: var(--text-color-artiox-light-black)">{{managerService.config.LOGIN.NO_ACCOUNT}}</span>
                    <span  class="body2 regular exo clickable_text" (click)="managerService.navigationManager({url: '/sign-up', current_component_index: 0});"> &nbsp; {{ managerService.config.LOGIN.CREATE_NEW_ACCOUNT}}</span>
                </div>

            </div>
            <div *ngIf="login_step === LoginStep.TWO_FACTOR" class="d-flex flex-column justify-content-center">
                <span class="mb-2 header5 medium exo" style="color: var(--text-color-artiox-black) ">{{managerService.config.LOGIN.AUTH_TITLE}}</span>
                <span class="body2 regular barlow mb-5" style="color: var(--text-color-artiox-grey)">{{managerService.config.LOGIN.AUTH_SUBTITLE}}</span>

                <div class="d-flex form-group focused mb-4">
                    <label class=" d-flex form-label body2 barlow medium" for="auth_password">
                        {{managerService.config.LOGIN.AUTH | managerPipe}}</label>

                    <input class="w-100 form-input body1 barlow regular" formControlName="auth_password"   id="auth_password" type="text" placeholder="{{managerService.config.LOGIN.AUTH_PLACEHOLDER}}">
                    <div id="auth_password_error"  class="d-flex d-none flex-row" style="gap: 8px; margin-top: 12px" >
                        <svg style=" height: 16px; width: 16px; ">
                            <use [attr.xlink:href]='"./assets/images/error_icon.svg#error_icon"' fill="#EA3943"/>
                        </svg>
                        <span class="caption barlow regular"  style="color: var(--secondary-colors-artiox-red-base)"></span>
                    </div>
                </div>
                <button class="w-100  d-flex justify-content-center py-2 login-button" (click)="validateFormGroup()" id="submit-button">
                    <span class="body1 exo " style="color:#FFFFFF; font-weight: 600">{{ managerService.config.LOGIN.LOGIN | managerPipe}}</span>
                </button>
            </div>

        </div>
    </div >
    <!--    Artiox Purple background and image -->



    <div class="d-flex flex-column align-items-center justify-content-end w-100" style="background: linear-gradient(180deg, var(--main-color-artiox-purple-500) 0%, var(--main-color-artiox-purple-base) 100%);">
        <div class="h-100 d-flex justify-content-center align-items-center">
            <span class="header3 medium exo text-center" style="color: var(--text-color-artiox-white, #FFFFFF); margin-top: 120px; margin-bottom: 120px; width: 480px">{{managerService.config.LOGIN.ART_INVESTMENT}}</span>
        </div>
        <div>
            <img src="./assets/screen_shots/desktop/login_page_phone_mock_up.png" alt="market" style="width: auto;height: 50dvh; min-height: 500px;">
        </div>
    </div>



</div>
<app-modal #login_captcha *ngIf="login_step === LoginStep.PUZZLE" class="d-flex" (click)="login_step = LoginStep.BASE;">
    <app-captcha-desktop  (response)="loginStepResolver($event);" (click)="$event.stopPropagation()"></app-captcha-desktop>
</app-modal>


