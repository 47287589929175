import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-navigation-side-bar-mobile',
  templateUrl: './navigation-side-bar-mobile.component.html',
  styleUrls: ['./navigation-side-bar-mobile.component.css']
})
export class NavigationSideBarMobileComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
