<div style="max-width: 600px;">
  <div class="p-5 w-100 d-flex flex-column align-items-start justify-content-center">
    <div class="p-2"
         style="font-family: bebas-neue-regular, sans-serif;font-size: 24px; font-weight: bold; line-height: 1.2; letter-spacing: 2.5px;  color: #404352;">
      {{managerService.config.TEST_ENVIRONMENT.TITLE | managerPipe}}
    </div>
    <div style="max-height: 800px; overflow: auto">
      <div class="p-2" style="font-family: Roboto, sans-serif; font-size: 17px; line-height: 1.1; letter-spacing: 0.4px; color: #4a4e70;">
        <div class="mb-4" [innerHTML]="managerService.config.TEST_ENVIRONMENT.DESCRIPTION | managerPipe">
        </div>
      </div>

      <div class="p-2" style="font-family: Roboto, sans-serif; font-size: 17px; font-style: italic; line-height: 1.1; letter-spacing: 0.4px; color: #4a4e70;">
        <div *ngFor="let rule of managerService.config.TEST_ENVIRONMENT.RULES | managerPipe" class="mb-4">
          - {{rule | managerPipe}}
        </div>
      </div>
    </div>
    <label class="p-2 d-flex flex-row align-items-start justify-content-start checkbox-text-black">
      <input #accepted class="checkbox-field" style="height: 20px; min-width: 20px;" type="checkbox">
      <span class="mx-2 my-1">{{ managerService.config.MISC.READ_ACCEPTED | managerPipe}}</span>
    </label>
    <button (click)="managerService.test_environment_accepted_changed.emit(accepted.checked)"
            [class.button-inactive]="!accepted.checked" [disabled]="!accepted.checked"
            class="btn-native checkbox-button">
      {{managerService.config.BALANCE.NEXT | managerPipe}}
    </button>
  </div>
</div>
