<div class="w-80 mx-auto pt-4">

  <div class="w-100 d-flex flex-row">
    <div>
      <svg style="height: 52px; width: 42px;">
        <use [attr.xlink:href]='"./assets/images/rules.svg#rules"' fill="var(--artiox-blue)"/>
      </svg>
    </div>
    <div class="pl-2">
      <div class="pb-1" style="font-family: Roboto, sans-serif; font-size: 20px; color: var(--artiox-blue)">
        {{managerService.config.SERVICE.RULES.HEADING | managerPipe}}
      </div>
      <div class="" style="font-family: Roboto, sans-serif; font-size: 13px; color: #707070;">
        {{managerService.config.SERVICE.RULES.SUMMARY | managerPipe}}
      </div>
    </div>
  </div>

  <div class="d-flex flex-row justify-content-center align-items-center my-3"
       style="width: 720px; height: 190px; font-family: Roboto, sans-serif; font-size: 9px; color: #ffffff; background-color: #22252a;">
    <div class="position-relative mb-3">
      <div class="position-relative text-center py-2" style="font-family: Roboto, sans-serif; font-size: 15px; font-weight: 300; color: #161615; z-index: 1">
        {{managerService.config.SERVICE.RULES.LEVEL | managerPipe}}
      </div>
      <div [innerHTML]="managerService.config.SERVICE.RULES.SIGN_UP | managerPipe" class="text-center py-2"
           style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500;line-height: 1.15; color: #c5e5eb;">
      </div>
      <table class="text-right" style="">
        <tr>
          <td class="pr-2 py-1 text-left">{{managerService.config.SERVICE.RULES.LIMITS | managerPipe}}</td>
          <td class="px-1 py-1">{{managerService.config.SERVICE.RULES.DAILY | managerPipe}}</td>
          <td class="px-1 py-1">{{managerService.config.SERVICE.RULES.MONTHLY | managerPipe}}</td>
        </tr>
        <tr style="border-bottom: solid 1px rgba(240, 242, 247, 0.3);">
          <td class="pr-2 py-1 text-left">{{managerService.config.SERVICE.RULES.DEPOSIT | managerPipe}}</td>
          <td class="px-1 py-1">{{0 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>
          <td class="px-1 py-1">{{0 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>
        </tr>
        <tr>
          <td class="pr-2 py-1 text-left" style="min-width: 54px">{{managerService.config.SERVICE.RULES.WITHDRAWAL | managerPipe}}</td>
          <td class="px-1 py-1">{{0 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>
          <td class="px-1 py-1">{{0 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>
        </tr>
      </table>
      <svg class="position-absolute" style="top:0; right: 0; transform: translate(0px, -35px); height: 100px; width: 100px;">
        <use [attr.xlink:href]='"./assets/images/level_zero.svg#level_zero"'/>
      </svg>
      <div class="position-absolute" style="top:10px; left: -30px; width: 180px; height: 120px">
        <svg preserveAspectRatio="none" width="100%">
          <use [attr.xlink:href]='"./assets/images/frame_centered.svg#frame_centered"' fill="var(--artiox-blue)"/>
        </svg>
      </div>
    </div>
    <svg style="height: 15px; width: 12px; margin: 80px 48px 0;">
      <use [attr.xlink:href]='"./assets/images/blue_arrow.svg#blue_arrow"' fill="var(--artiox-blue)"/>
    </svg>
    <div class="position-relative mb-3">
      <div class="position-relative text-center py-2" style="font-family: Roboto, sans-serif; font-size: 15px; font-weight: 300; color: #161615; z-index: 1;">
        {{managerService.config.SERVICE.RULES.LEVEL | managerPipe}}
      </div>
      <div [innerHTML]="managerService.config.SERVICE.RULES.IDENTITY_DESCRIPTION | managerPipe" class="text-center py-2"
           style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500;line-height: 1.15; color: #83c9d2;">
      </div>
      <table class="text-right" style="">
        <tr>
          <td class="pr-2 py-1 text-left">{{managerService.config.SERVICE.RULES.LIMITS | managerPipe}}</td>
          <td class="px-1 py-1">{{managerService.config.SERVICE.RULES.DAILY | managerPipe}}</td>
          <td class="px-1 py-1">{{managerService.config.SERVICE.RULES.MONTHLY | managerPipe}}</td>
        </tr>
        <tr style="border-bottom: solid 1px rgba(240, 242, 247, 0.3);">
          <td class="pr-2 py-1 text-left">{{managerService.config.SERVICE.RULES.DEPOSIT | managerPipe}}</td>
          <td class="px-1 py-1">{{5000 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>
          <td class="px-1 py-1">{{100000 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>
        </tr>
        <tr>
          <td class="pr-2 py-1 text-left" style="min-width: 54px">{{managerService.config.SERVICE.RULES.WITHDRAWAL | managerPipe}}</td>
          <td class="px-1 py-1">{{5000 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>
          <td class="px-1 py-1">{{100000 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>
        </tr>
      </table>
      <svg class="position-absolute" style="top:0; right: 0; transform: translate(-15px, -35px); height: 100px; width: 100px;">
        <use [attr.xlink:href]='"./assets/images/level_one.svg#level_one"'/>
      </svg>
      <div class="position-absolute" style="top:10px; left: -20px; width: 180px; height: 120px">
        <svg preserveAspectRatio="none" width="100%">
          <use [attr.xlink:href]='"./assets/images/frame_centered.svg#frame_centered"' fill="var(--artiox-blue)"/>
        </svg>
      </div>
    </div>
    <svg style="height: 15px; width: 12px; margin: 80px 36px 0;">
      <use [attr.xlink:href]='"./assets/images/blue_arrow.svg#blue_arrow"' fill="var(--artiox-blue)"/>
    </svg>
    <div class="position-relative mb-3">
      <div class="position-relative text-center py-2" style="font-family: Roboto, sans-serif; font-size: 15px; font-weight: 300; color: #161615; z-index: 1">
        {{managerService.config.SERVICE.RULES.LEVEL | managerPipe}}
      </div>
      <div [innerHTML]="managerService.config.SERVICE.RULES.IDENTITY_VERIFICATION | managerPipe" class="text-center py-2"
           style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500;line-height: 1.15; color: #83c9d2;">
      </div>
      <table class="text-right" style="">
        <tr>
          <td class="pr-2 py-1 text-left">{{managerService.config.SERVICE.RULES.LIMITS | managerPipe}}</td>
          <td class="px-1 py-1">{{managerService.config.SERVICE.RULES.DAILY | managerPipe}}</td>
          <td class="px-1 py-1">{{managerService.config.SERVICE.RULES.MONTHLY | managerPipe}}</td>
        </tr>
        <tr style="border-bottom: solid 1px rgba(240, 242, 247, 0.3);">
          <td class="pr-2 py-1 text-left">{{managerService.config.SERVICE.RULES.DEPOSIT | managerPipe}}</td>
          <td class="px-1 py-1">{{500000 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>
          <td class="px-1 py-1">{{3000000 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>
        </tr>
        <tr>
          <td class="pr-2 py-1 text-left" style="min-width: 54px">{{managerService.config.SERVICE.RULES.WITHDRAWAL | managerPipe}}</td>
          <td class="px-1 py-1">{{500000 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>
          <td class="px-1 py-1">{{3000000 | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</td>
        </tr>
      </table>
      <svg class="position-absolute" style="top:0; right: 0; transform: translate(-25px, -35px); height: 100px; width: 100px;">
        <use [attr.xlink:href]='"./assets/images/level_two.svg#level_two"'/>
      </svg>
      <div class="position-absolute" style="top:10px; left: -10px; width: 180px; height: 120px">
        <svg preserveAspectRatio="none" width="100%">
          <use [attr.xlink:href]='"./assets/images/frame_centered.svg#frame_centered"' fill="var(--artiox-blue)"/>
        </svg>
      </div>
    </div>
  </div>

  <div class="w-50" style="font-family: Roboto, sans-serif; font-size: 13px; letter-spacing: 0.4px; color: #707070; min-width: 550px; max-width: 700px;">
    <div>
      <span style="color: var(--artiox-blue)">
        {{managerService.config.SERVICE.RULES.LEVEL | managerPipe}} 0:
      </span>
      <span>
        {{managerService.config.SERVICE.RULES.LEVEL_0_DESCRIPTION | managerPipe}}
      </span>
    </div>
    <div>
      <span style="color: var(--artiox-blue)">
        {{managerService.config.SERVICE.RULES.LEVEL | managerPipe}} 1:
      </span>
      <span>
        {{managerService.config.SERVICE.RULES.LEVEL_1_DESCRIPTION | managerPipe}}
      </span>
    </div>
    <div>
      <span style="color: var(--artiox-blue)">
        {{managerService.config.SERVICE.RULES.LEVEL | managerPipe}} 2:
      </span>
      <span>
        {{managerService.config.SERVICE.RULES.LEVEL_2_DESCRIPTION | managerPipe}}
      </span>
    </div>
  </div>

  <div class="mt-3">
    <div class="p-2" style="font-family: Roboto, sans-serif; font-size: 14px; font-weight: 500; letter-spacing: 0.3px; color: #4a4e70;">
      {{managerService.config.SERVICE.RULES.TL_DEPOSIT_RULES_HEADING | managerPipe}}
    </div>
    <div class="w-50 p-2" style="background-color: #f4f5f9; min-width: 550px; max-width: 700px;">
      <ul style="padding-left: 20px">
        <li *ngFor="let rule of managerService.config.SERVICE.RULES.TL_DEPOSIT_RULES | managerPipe"
            style="font-family: Roboto, sans-serif; font-size: 12px; font-style: italic; letter-spacing: 0.35px; color: #4a4e70;">{{rule}}</li>
      </ul>
    </div>
  </div>

  <div class="mt-3 mb-5">
    <div class="p-2" style="font-family: Roboto, sans-serif; font-size: 14px; font-weight: 500; letter-spacing: 0.3px; color: #4a4e70;">
      {{managerService.config.SERVICE.RULES.TL_WITHDRAWAL_RULES_HEADING | managerPipe}}
    </div>
    <div class="w-50 p-2" style="background-color: #f4f5f9; min-width: 550px; max-width: 700px;">
      <ul style="padding-left: 20px">
        <li *ngFor="let rule of managerService.config.SERVICE.RULES.TL_WITHDRAWAL_RULES | managerPipe"
            style="font-family: Roboto, sans-serif; font-size: 12px; font-style: italic; letter-spacing: 0.35px; color: #4a4e70;">{{rule}}</li>
      </ul>
    </div>
  </div>

</div>
