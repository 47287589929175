<div [style]="managerService.show_sign_up_or_login | managerPipe: managerService.mpa_template.GET_MIN_BODY_HEIGHT_OBSOLETE">
    <app-auction-catalog-type-mobile *ngIf="managerService.navigationService.current_component_index === 0 | managerPipe"></app-auction-catalog-type-mobile>

    <app-auction-catalog-list-mobile *ngIf="managerService.navigationService.current_component_index === 1 | managerPipe"></app-auction-catalog-list-mobile>

    <app-auction-catalog-mobile *ngIf="managerService.navigationService.current_component_index === 2 | managerPipe" class="w-100"></app-auction-catalog-mobile>

    <div *ngIf="managerService.navigationService.current_component_index === 3 | managerPipe" class="w-100 d-flex flex-column justify-content-center align-items-center" style="background: #FFFFFF;">
        <app-auction-art-product-mobile class="w-100 d-flex justify-content-center"></app-auction-art-product-mobile>
        <app-auction-bid-mobile class="w-100 d-flex justify-content-center"></app-auction-bid-mobile>
        <app-auction-artist-bid-history-mobile class="w-100 d-flex justify-content-center"></app-auction-artist-bid-history-mobile>
    </div>

</div>
