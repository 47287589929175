<p style="text-indent: 0pt;text-align: left;"><br/></p>
<h1>BİLGİ GÜVENLİĞİ POLİTİKASI</h1>
<p style="text-indent: 0pt;text-align: left;"><br/></p>
<ol>
    <li><h1>Amaç : </h1><span>Bu politikanın amacı, hukuka, yasal, düzenleyici ya da sözleşmeye tabi yükümlülüklere ve her türlü güvenlik gereksinimlerine ilişkin ihlalleri önlemek için, üst yönetimin yaklaşımını ve hedeflerini tanımlamak, tüm çalışanlara ve ilgili taraflara bu hedefleri bildirmektir.</span>
    </li>
    <li><h1>Kapsam : </h1><span>Bu politika Şirket bünyesinde yapılan ticari faaliyetlere ve bu işlemlere ilişkin lojistik, depolama, muhasebe, finans, kalite güvence, satın alma, insan kaynakları, hukuk, satış, pazarlama, iç denetim ve bilgi işlem faaliyetlerinden elde edilen elektronik bilgi varlıkların korunması, şirket bünyesinde tutulan kişisel verilerin kanun kapsamında işlenmesi, saklanması, korunması, gizliliğinin ve bütünlüğünün  bozulmaması için kullandığı bilgi güvenliği süreçlerini kapsar.</span>
        <ol>
            <p style="text-indent: 0pt;text-align: left;"><br/></p>
            <li><h1>İç Kapsam</h1>
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p style="padding-left: 74pt;text-indent: 0pt;text-align: left;">İdare, kuruluşa ilişkin yapı, roller ve
                    yükümlülükler;</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <ol>
                    <li><p style="padding-left: 110pt;text-indent: -36pt;line-height: 112%;text-align: justify;">Şirket
                        Üst Yönetimi bünyesinde bulunan kapsam dahilindeki
                        departmanlar; Mali ve İdari İşler, Finans, Bilgi İşlem, Kurumsal İletişim ve İş Geliştirme,
                        İnsan Kaynakları,</p></li>
                    <li data-list-text="2.1.2."><p
                        style="padding-top: 10pt;padding-left: 110pt;text-indent: -35pt;line-height: 112%;text-align: justify;">
                        Genel Yönetim Organizasyon Şemasında
                        belirtilmiş roller ve görev tanımlarındaki sorumluluklar.</p></li>
                    <li data-list-text="2.1.3."><p
                        style="padding-top: 10pt;padding-left: 110pt;text-indent: -34pt;text-align: left;">Yerine
                        getirilecek politikalar, prosedürler, hedefler ve
                        stratejiler;</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p>
                        <ol id="l4">
                            <li data-list-text="2.1.3.1."><p
                                style="padding-left: 146pt;text-indent: -50pt;text-align: left;">Bilgi Güvenliği Yönetim
                                Sistemi Politikası,</p>
                                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                            <li data-list-text="2.1.3.2."><p
                                style="padding-left: 146pt;text-indent: -52pt;text-align: left;">Tüm Bilgi Güvenliği
                                yönetim sistemleri prosedürleri,</p>
                                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                            <li data-list-text="2.1.3.3."><p
                                style="padding-left: 146pt;text-indent: -53pt;line-height: 112%;text-align: justify;">
                                Yönetimce belirlenmiş yıllık Bilgi Güvenliği yönetim
                                sistemleri hedefleri,</p></li>
                            <li data-list-text="2.1.3.4."><p
                                style="padding-top: 10pt;padding-left: 146pt;text-indent: -54pt;line-height: 112%;text-align: justify;">
                                Kaynaklar ve bilgi birikimi
                                cinsinden anlaşılan yetenekler (örneğin, anapara, zaman, kişiler, süreçler, sistemler ve
                                teknolojiler),</p></li>
                            <li data-list-text="2.1.3.5."><p
                                style="padding-top: 10pt;padding-left: 146pt;text-indent: -53pt;line-height: 112%;text-align: justify;">
                                Bilgi Güvenliği Yönetim Sisteminin
                                kurulması, işletilmesi ve sürdürülmesi için yönetim tarafından atanan Yönetim
                                Temsilcileri ve Bilgi Güvenliği Yönetim Sistemi ekibi,</p></li>
                            <li data-list-text="2.1.3.6."><p
                                style="padding-top: 10pt;padding-left: 146pt;text-indent: -53pt;line-height: 112%;text-align: justify;">
                                İç paydaşlarla ilişkiler ve onların
                                algılamaları ve değerleri, kuruluşun kültürü, kuruluş tarafından uyarlanan standartlar,
                                kılavuzlar ve modeller, sözleşmeye ilişkin ilişkilerin; biçim ve genişliğini
                                kapsamaktadır.</p></li>
                        </ol>
                    </li>
                </ol>
            </li>
            <li><h1>Dış Kapsam</h1>
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <ol id="l5">
                    <li data-list-text="2.2.1."><p
                        style="padding-left: 110pt;text-indent: -35pt;line-height: 112%;text-align: justify;">
                        Uluslararası, ulusal, bölgesel veya yerel olmak üzere, sosyal
                        ve kültürel, politik, yasal, mevzuata ilişkin, finansal, teknolojik, ekonomik, doğal ve
                        rekabetçi ortam,</p></li>
                    <li data-list-text="2.2.2."><p
                        style="padding-top: 10pt;padding-left: 110pt;text-indent: -36pt;text-align: left;">Küresel
                        Rekabet Hukuku, Politikaları ve Prosedürleri,</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="2.2.3."><p style="padding-left: 110pt;text-indent: -36pt;text-align: left;">
                        Tedarikçi ve müşteri verilerinin gizliliği,</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="2.2.4."><p style="padding-left: 110pt;text-indent: -37pt;text-align: left;">
                        Kalite Odaklılık,</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="2.2.5."><p
                        style="padding-left: 110pt;text-indent: -37pt;line-height: 112%;text-align: justify;">Kuruluşun
                        hedefleri üzerinde etkisi bulunan paydaşlarla
                        ilişkiler ve onların algılamaları ve değerleri;</p></li>
                    <li data-list-text="2.2.6."><p
                        style="padding-top: 10pt;padding-left: 110pt;text-indent: -37pt;line-height: 112%;text-align: justify;">
                        Müşteri memnuniyetin sağlanması için Üst
                        Yönetim dahil tüm Şirket çalışanları,</p></li>
                    <li data-list-text="2.2.7."><p
                        style="padding-top: 10pt;padding-left: 110pt;text-indent: -37pt;line-height: 112%;text-align: justify;">
                        İlgili tüm yasal mevzuat, düzenleyici,
                        sözleşmeden doğan şartlar, standartlar,</p></li>
                    <li data-list-text="2.2.8."><p
                        style="padding-top: 10pt;padding-left: 110pt;text-indent: -36pt;line-height: 112%;text-align: justify;">
                        TSE ve diğer kuruluşlarla olan ürün
                        belgelendirmeleri dış kapsamdır.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                </ol>
            </li>
        </ol>
    </li>
    <p style="text-indent: 0pt;text-align: left;"><br/></p>
    <li><h1>Tanımlar</h1>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <ol>
            <li><h1>BGYS:</h1> <span>Bilgi Güvenliği Yönetim Sistemi.</span>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><h1>Envanter: </h1><span>Firma için önemli olan her türlü bilgi varlığı.</span>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><h1>Üst Yönetim: </h1><span>Şirket Üst Yönetimidir.</span>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><h1>Know-How: </h1><span>Bir şeyi yapabilme yetkinliğidir.</span>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><h1>Bilgi Güvenliği: </h1><span>Bilgi, tüm diğer kurumsal ve ticari varlıklar gibi, bir işletme için değeri olan ve bu nedenle uygun şekilde korunması gereken bir varlıktır. Şirket içerisinde, know-how, süreç, formül, teknik ve yöntem, müşteri kayıtları, pazarlama ve satış bilgileri, personel bilgileri, ticari, sınai ve teknolojik bilgiler ve sırlar GİZLİ BİLGİ olarak kabul edilir.</span>
            </li>
            <li><h1>Gizlilik: </h1><span>Bilginin  içeriğinin  görüntülenmesinin,  sadece  bilgiyi/veriyi görüntülemeye izin verilen kişilerin erişimi ile kısıtlanmasıdır. (Örnek: Şifreli e-posta gönderimi ile e-postanın ele geçmesi halinde dahi yetkisiz kişilerin e-postaları okuması engellenebilir - Kayıtlı elektronik posta - KEP )</span>
            </li>
            <li><h1>
                Bütünlük: </h1><span>Bilginin yetkisiz veya yanlışlıkla değiştirilmesinin, silinmesinin veya eklemeler çıkarmalar yapılmasının tespit edilebilmesi ve tespit edilebilirliğin garanti altına alınmasıdır. (Örnek: Veri tabanında saklanan verilerin özet bilgileri ile birlikte saklanması - elektronik imza - mobil imza)</span>
            </li>
            <li><h1>
                Erişilebilirlik/Kullanılabilirlik: </h1><span>Varlığın ihtiyaç duyulduğu her an kullanıma hazır olmasıdır. Diğer bir ifadeyle, sistemlerin sürekli hizmet verebilir halde bulunması ve sistemlerdeki bilginin kaybolmaması ve sürekli erişilebilir olmasıdır. (Örnek: Sunucuların güç hattı dalgalanmalarından ve güç kesintilerinden etkilenmemesi için kesintisiz güç kaynağı ve şaselerinde yedekli güç kaynağı kullanımı - UPS). Bu politikada “Erişilebilirlik” olarak kullanılacaktır.</span>
            </li>
            <li><h1>
                Bilgi Varlığı: </h1><span>Şirket’in  sahip  olduğu,  faaliyetlerini  aksatmadan yürütebilmesi için önemli olan varlıklardır. Bu politikaya konu olan süreçler kapsamında bilgi varlıkları şunlardır:</span>

                <ol>
                    <li><p
                        style="padding-top: 10pt;padding-left: 110pt;text-indent: -35pt;line-height: 112%;text-align: justify;">
                        Kağıt, elektronik, görsel veya işitsel
                        ortamda sunulan her türlü bilgi ve veri,</p></li>
                    <li><p
                        style="padding-top: 10pt;padding-left: 110pt;text-indent: -37pt;line-height: 112%;text-align: justify;">
                        Bilgiye erişmek ve bilgiyi değiştirmek için
                        kullanılan her türlü yazılım ve donanım,</p></li>
                    <li><p style="padding-top: 10pt;padding-left: 110pt;text-indent: -37pt;text-align: left;">Bilginin
                        transfer edilmesini sağlayan ağlar,</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li><p style="padding-left: 110pt;text-indent: -38pt;text-align: left;">Tesisler ve özel
                        alanlar,</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li><p style="padding-left: 110pt;text-indent: -37pt;text-align: left;">Bölümler, birimler, ekipler
                        ve çalışanlar,</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li><p style="padding-left: 110pt;text-indent: -37pt;text-align: left;">Çözüm ortakları,</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li><p style="padding-left: 110pt;text-indent: -37pt;text-align: left;">Üçüncü taraflardan sağlanan
                        servis, hizmet veya ürünlerdir.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                </ol>
            </li>
        </ol>
    </li>
    <li><h1>
        Sorumluluklar </h1><span>Sorumluluk  ve  yetkileri  belirlenmiş  görevlerin  nitelik  ve yeterlilikleri  görev  tanımlarında  tanımlanmıştır.  Bilgi  güvenliği  ile  ilgili faaliyetlerin sürdürülmesinden ve geliştirilmesinden Bilgi İşlem Ekibi ve Yönetim Temsilcisi  sorumludur.  BGYS  Ekibi  ve  Yönetim  Temsilcileri  Üst  Yönetim tarafından atanmıştır. Kapsam içindeki departmanlardan BGYS temsilcileri belirlenmiştir. BGYS ekip üyesi olarak isim bazında atamaları yapılmıştır.</span>

        <ol>
            <li><h1>Yönetim Sorumluluğu</h1>
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <ol>
                    <li><p
                        style="padding-left: 110pt;text-indent: -33pt;line-height: 112%;text-align: justify;">Şirket
                        Yönetimi, tanımlanmış, yürürlüğe konmuş ve uygulanmakta
                        olan Bilgi Güvenliği Sistemine uyacağını ve sistemin verimli şekilde çalışması için gerekli
                        kaynakları tahsis edeceğini, sistemin tüm çalışanlar tarafından anlaşılmasının
                        sağlayacağını taahhüt eder.</p></li>
                    <li><p
                        style="padding-top: 10pt;padding-left: 110pt;text-indent: -36pt;line-height: 112%;text-align: justify;">
                        BGYS kurulumu sırasında BGYS Yönetim
                        Temsilcisi atama yazısı ile atanır. Gerekli olduğu durumlarda üst yönetim tarafından doküman
                        revize edilerek atama tekrar yapılır.</p></li>
                    <li><p
                        style="padding-top: 10pt;padding-left: 110pt;text-indent: -36pt;line-height: 112%;text-align: justify;">
                        Yönetim kademesindeki yöneticiler güvenlik
                        konusunda alt kademelerde bulunan personele sorumluluk verme ve örnek olma açısından yardımcı
                        olurlar. Üst kademelerden başlayan ve uygulanan anlayış, firmanın en alt kademe
                        personeline kadar inilmesi zorunludur. Bu yüzden tüm yöneticiler yazılı yada sözlü
                        olarak güvenlik talimatlarına uymaları, güvenlik konularındaki çalışmalara
                        katılmaları yönünde çalışanlarına destek olurlar.</p></li>
                    <li><p
                        style="padding-top: 10pt;padding-left: 110pt;text-indent: -37pt;line-height: 112%;text-align: justify;">
                        Üst Yönetim, Bilgi güvenliği kapsamlı
                        çalışmalar için gerek duyulan bütçeyi oluşturur.</p></li>
                </ol>
            </li>
            <li><h1>Yönetim Temsilcisi
                Sorumluluğu</h1>
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <ol>
                    <li><p
                        style="padding-left: 110pt;text-indent: -36pt;line-height: 112%;text-align: justify;">BGYS
                        (Bilgi Güvenliği Yönetim Sistemi)&#39;nin planlanması,
                        kabul edilebilir risk seviyesinin belirlenmesi, risk değerlendirme metodolojisinin
                        belirlenmesini,</p></li>
                    <li><p
                        style="padding-top: 10pt;padding-left: 110pt;text-indent: -38pt;line-height: 112%;text-align: justify;">
                        BGYS kurulumunda destekleyici ve tamamlayıcı
                        faaliyetler için gerekli kaynakların sağlanması, kullanıcı kabiliyetlerinin
                        sağlanması/iyileştirilmesi ve farkındalığın oluşması, eğitimlerin yapılması, iletişimin
                        sağlanması,
                        dokümantasyon gereksinimlerinin sağlanması,</p></li>
                    <li><p
                        style="padding-top: 10pt;padding-left: 110pt;text-indent: -38pt;line-height: 112%;text-align: justify;">
                        BGYS uygulamalarının yürütülmesi ve
                        yönetilmesi, değerlendirmelerin, iyileştirmelerin ve risk değerlendirmelerinin sürekliliğinin
                        sağlanması,</p></li>
                    <li><p
                        style="padding-top: 10pt;padding-left: 110pt;text-indent: -39pt;line-height: 112%;text-align: justify;">
                        İç denetimler, hedeflerin ve yönetim gözden
                        geçirme toplantıları ile BGYS ve kontrollerin değerlendirilmesi,</p></li>
                    <li><p
                        style="padding-top: 10pt;padding-left: 110pt;text-indent: -38pt;line-height: 112%;text-align: justify;">
                        BGYS&#39;de mevcut yapının sürdürülmesi ve
                        sürekli iyileştirmelerin sağlanmasından sorumludur.</p></li>
                </ol>
            </li>
            <li><h1>BGYS Ekip Üyeleri Sorumluluğu</h1>
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <ol>
                    <li><p
                        style="padding-left: 110pt;text-indent: -36pt;line-height: 112%;text-align: justify;">Bölümleri
                        ile ilgili varlık envanteri ve risk analiz
                        çalışmalarının yapılması,</p></li>
                    <li><p
                        style="padding-top: 10pt;padding-left: 110pt;text-indent: -38pt;line-height: 112%;text-align: justify;">
                        Sorumluluğu altında bulunan bilgi
                        varlıklarında bilgi güvenliği risklerini etkileyecek bir değişiklik olduğunda, risk
                        değerlendirmesi yapılması için Yönetim Temsilcisini bilgilendirmesi,</p></li>
                    <li><p
                        style="padding-top: 10pt;padding-left: 110pt;text-indent: -38pt;line-height: 112%;text-align: justify;">
                        Departman çalışanlarının politika ve
                        prosedürlere uygun çalışmasını sağlanması,</p></li>
                    <li><p
                        style="padding-top: 10pt;padding-left: 110pt;text-indent: -39pt;line-height: 112%;text-align: justify;">
                        Bölümleri ile ilgili BGYS kapsamında
                        farkındalığın oluşması, iletişimin sağlanması, dokümantasyon gereksinimlerinin sağlanması,</p>
                    </li>
                    <li><p
                        style="padding-top: 10pt;padding-left: 110pt;text-indent: -38pt;line-height: 112%;text-align: justify;">
                        BGYS&#39; de mevcut yapının sürdürülmesi ve
                        sürekli iyileştirmelerin sağlanmasından sorumludur.</p></li>
                </ol>
            </li>
            <li><h1>
                İç Denetçi Sorumluluğu </h1><span>İç denetim planı doğrultusunda, görev verilen iç denetimlerde denetim faaliyetlerinin yapılmasından ve raporlanmasından sorumludur.</span>
            </li>
            <li><h1>
                Bölüm Yöneticileri Sorumluluğu </h1><span>Bilgi  Güvenliği  Politikasının uygulanması  ve  çalışanların  esaslara  uymasının  sağlanmasından,  3.
                tarafların politikadan haberdar olmasının sağlanmasından ve fark ettiği bilgi sistemleri ile ilgili güvenlik ihlal olaylarının bildirilmesinden sorumludurlar.</span>
            </li>
            <li><h1>Tüm Çalışanların Sorumluluğu</h1>
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <ol id="l12">
                    <li><p
                        style="padding-left: 110pt;text-indent: -36pt;line-height: 112%;text-align: justify;">
                        Çalışmalarını bilgi güvenliği hedeflerine, politikalarına ve
                        bilgi güvenliği yönetim sistemi dokümanlarına uygun olarak yürütmekten,</p></li>
                    <li><p
                        style="padding-top: 10pt;padding-left: 110pt;text-indent: -38pt;line-height: 112%;text-align: justify;">
                        Kendi birimi ile ilgili bilgi güvenliği
                        hedeflerinin takibini yapar ve hedeflere ulaşılmasını sağlar.</p></li>
                    <li><p
                        style="padding-top: 10pt;padding-left: 110pt;text-indent: -38pt;line-height: 112%;text-align: justify;">
                        Sistemler veya hizmetlerde gözlenen veya
                        şüphelenilen herhangi bir bilgi güvenliği açıklığına dikkat etmek ve raporlamaktan,</p></li>
                    <li><p
                        style="padding-top: 10pt;padding-left: 110pt;text-indent: -39pt;line-height: 112%;text-align: justify;">
                        Üçüncü taraflar ile yapılan ve Satınalma
                        sorumluluğunda olmayan hizmet sözleşmelerine (danışmanlık vb.) ilave olarak gizlilik sözleşmesi
                        yapmak ve bilgi güvenliği gereksinimlerini sağlamaktan sorumludur.</p></li>
                </ol>
            </li>
            <li><h1>
                Üçüncü Tarafların Sorumluluğu </h1><span>Bilgi güvenliği politikasının bilinmesi ve uygulanması ile BGYS kapsamında belirlenen davranışlara uyulmasından sorumludur.</span>
            </li>
        </ol>
    </li>
    <li><h1
        style="padding-top: 10pt;padding-left: 38pt;text-indent: -27pt;line-height: 112%;text-align: justify;">Bilgi
        Güvenliği Hedefleri </h1><span>Bilgi Güvenliği Politikası, Şirket çalışanlarına firmanın güvenlik  gereksinimlerine  uygun  şekilde  hareket  etmesi  konusunda  yol göstermek, bilinç ve farkındalık seviyelerini arttırmak ve bu şekilde şirketin temel ve destekleyici iş faaliyetlerinin en az kesinti ile devam etmesini sağlamak, güvenilirliğini ve imajını korumak ve üçüncü taraflarla yapılan sözleşmelerde belirlenmiş uygunlukları sağlamak amacıyla firmanın tüm işleyişini etkileyen fiziksel ve elektronik bilgi varlıklarının korunmasını hedefler. Yönetim Tarafından belirlenen hedefler belirlenmiş periyotlarda izlenir ve Yönetim Gözden Geçirme toplantılarında gözden geçirilir.</span>
    </li>
    <li><h1>Risk Yönetim Çerçevesi </h1><span>Firmanın risk yönetim çerçevesi; Bilgi güvenliği risklerinin  tanımlanmasını,  değerlendirilmesini  ve  işlenmesini  kapsar.  Risk Analizi, uygulanabilirlik bildirgesi ve risk işleme planı, bilgi güvenliği risklerinin nasıl  kontrol  edildiğini  tanımlar.  Risk  işleme  planının  yönetiminden  ve gerçekleştirilmesinden BGYS Yürütme ve Yönetim Komitesi sorumludur. Tüm bu çalışmalar, varlık envanteri ve risk değerlendirme talimatında detaylı olarak açıklanır.</span>
    </li>
    <li><h1>Bilgi Güvenliği Genel Esasları</h1>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <ol>
            <li><p
                style="padding-left: 74pt;text-indent: -34pt;line-height: 112%;text-align: justify;">Bu politika ile
                çerçevesi çizilen bilgi güvenliği gereksinimleri ve
                kurallarına ilişkin ayrıntılar, Şirket çalışanları ve 3. taraflar bu politika ve prosedürleri bilmek ve
                çalışmalarını bu kurallara uygun şekilde yürütmekle yükümlüdür.</p></li>
            <li data-list-text="7.2."><p
                style="padding-top: 3pt;padding-left: 74pt;text-indent: -37pt;line-height: 112%;text-align: justify;">Bu
                kural ve politikalar, aksi belirtilmedikçe, basılı
                veya elektronik ortamda depolanan ve işlenen tüm bilgiler ile bütün bilgi sistemlerinin kullanımı için
                dikkate alınması esastır.</p></li>
            <li data-list-text="7.3."><p
                style="padding-top: 10pt;padding-left: 74pt;text-indent: -37pt;line-height: 112%;text-align: justify;">
                Bilgi Güvenliği Yönetim Sistemi, TS ISO/IEC 27001 &quot;Bilgi
                Teknolojisi Güvenlik Teknikleri (Information Technology Security Techniques) ve Bilgi Güvenliği Yönetim
                Sistemleri Gereksinimler (Information Security Management Systems Requirements)&quot;
                standardını temel alarak yapılandırılır ve işletilir.</p></li>
            <li data-list-text="7.4."><p
                style="padding-top: 10pt;padding-left: 74pt;text-indent: -38pt;line-height: 112%;text-align: justify;">
                BGYS’nin hayata geçirilmesi, işletilmesi ve
                iyileştirilmesi çalışmalarını, ilgili tarafların katkısıyla yürütür. BGYS dokümanlarının gerektiği
                zamanlarda güncellenmesi BGYS Yönetim Temsilcisi sorumluluğundadır.</p></li>
            <li data-list-text="7.5."><p
                style="padding-top: 10pt;padding-left: 74pt;text-indent: -37pt;line-height: 112%;text-align: justify;">
                Şirket tarafından çalışanlara veya 3. taraflara sunulan
                bilgi sistemleri ve altyapısı ile bu sistemler kullanılarak üretilen her türlü bilgi, belge ve ürün
                aksini gerektiren kanun hükümleri veya sözleşmeler bulunmadıkça şirkete aittir.</p>
            </li>
            <li data-list-text="7.6."><p
                style="padding-top: 10pt;padding-left: 74pt;text-indent: -37pt;line-height: 112%;text-align: justify;">
                Çalışanlar, danışmanlık, hizmet alımı (Güvenlik, servis,
                yemek, temizlik firması vb.), Tedarikçi ve Stajyer ile gizlilik anlaşmaları yapılır.</p></li>
            <li data-list-text="7.7."><p
                style="padding-top: 10pt;padding-left: 74pt;text-indent: -37pt;line-height: 112%;text-align: justify;">
                İşe alım, görev değişikliği ve işten ayrılma
                süreçlerinde uygulanacak bilgi güvenliği kontrolleri belirlenir ve uygulanır.</p></li>
            <li data-list-text="7.8."><p
                style="padding-top: 10pt;padding-left: 74pt;text-indent: -37pt;line-height: 112%;text-align: justify;">
                Çalışanların bilgi güvenliği farkındalığını artıracak ve
                sistemin işleyişine katkıda bulunmasını sağlayacak eğitimler düzenli olarak mevcut şirket çalışanlarına
                ve yeni işe başlayan çalışanlara verilir.</p></li>
            <li data-list-text="7.9."><p
                style="padding-top: 10pt;padding-left: 74pt;text-indent: -37pt;line-height: 112%;text-align: justify;">
                Bilgi güvenliğinin gerçek ya da şüpheli tüm ihlalleri
                rapor edilir; ihlallere sebep olan uygunsuzluklar tespit edilir, ana sebepleri bulunarak tekrar
                edilmesini engelleyici önlemler alınır.</p>
                <ol id="l14">
                    <li data-list-text="7.10."><p
                        style="padding-top: 10pt;padding-left: 74pt;text-indent: -42pt;line-height: 112%;text-align: justify;">
                        Bilgi varlıklarının envanteri bilgi
                        güvenliği yönetim ihtiyaçları doğrultusunda oluşturulur ve varlık sahiplikleri atanır.</p>
                    </li>
                    <li data-list-text="7.11."><p
                        style="padding-top: 10pt;padding-left: 74pt;text-indent: -39pt;line-height: 112%;text-align: justify;">
                        Kurumsal veriler sınıflandırılır ve her
                        sınıftaki verilerin güvenlik ihtiyaçları ve kullanım kuralları belirlenir.</p></li>
                    <li data-list-text="7.12."><p
                        style="padding-top: 10pt;padding-left: 74pt;text-indent: -41pt;line-height: 112%;text-align: justify;">
                        Güvenli alanlarda saklanan varlıkların
                        ihtiyaçlarına paralel fiziksel güvenlik kontrolleri uygulanır.</p></li>
                    <li data-list-text="7.13."><p
                        style="padding-top: 10pt;padding-left: 74pt;text-indent: -41pt;line-height: 112%;text-align: justify;">
                        Firmaya ait bilgi varlıkları için firma
                        içinde ve dışında maruz kalabilecekleri fiziksel tehditlere karşı gerekli kontrol ve
                        politikalar geliştirilir ve uygulanır.</p></li>
                    <li data-list-text="7.14."><p
                        style="padding-top: 10pt;padding-left: 74pt;text-indent: -42pt;line-height: 112%;text-align: justify;">
                        Kapasite yönetimi, üçüncü taraflarla
                        ilişkiler, yedekleme, sistem kabulü ve diğer güvenlik süreçlerine ilişkin prosedür ve
                        talimatlar geliştirilir ve uygulanır.</p></li>
                    <li data-list-text="7.15."><p
                        style="padding-top: 10pt;padding-left: 74pt;text-indent: -41pt;line-height: 112%;text-align: justify;">
                        Ağ cihazları, işletim sistemleri, sunucular
                        ve uygulamalar için denetim kaydı üretme konfigürasyonları ilgili sistemlerin güvenlik
                        ihtiyaçlarına</p>
                        <p style="padding-top: 3pt;padding-left: 74pt;text-indent: 0pt;line-height: 112%;text-align: left;">
                            paralel biçimde ayarlanır. Denetim kayıtlarının yetkisiz erişime karşı
                            korunması sağlanır.</p></li>
                    <li data-list-text="7.16."><p
                        style="padding-top: 10pt;padding-left: 74pt;text-indent: -41pt;line-height: 112%;text-align: justify;">
                        Erişim hakları ihtiyaç nispetinde atanır.
                        Erişim kontrolü için mümkün olan en güvenli teknoloji ve teknikler kullanılır.</p></li>
                    <li data-list-text="7.17."><p
                        style="padding-top: 10pt;padding-left: 74pt;text-indent: -41pt;line-height: 112%;text-align: justify;">
                        Sistem temini ve geliştirilmesinde güvenlik
                        gereksinimleri belirlenir, sistem kabulü veya testlerinde güvenlik gereksinimlerinin
                        karşılanıp karşılanmadığı kontrol edilir.</p></li>
                    <li data-list-text="7.18."><p
                        style="padding-top: 10pt;padding-left: 74pt;text-indent: -41pt;text-align: left;">Kritik
                        altyapı için süreklilik planları hazırlanır, bakımı ve
                        tatbikatı yapılır.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
                    <li data-list-text="7.19."><p
                        style="padding-left: 74pt;text-indent: -41pt;line-height: 112%;text-align: justify;">
                        Yasalara, iç politika ve prosedürlere, teknik güvenlik
                        standartlarına uyum için gerekli süreçler tasarlanır, sürekli ve periyodik olarak yapılacak
                        gözetim ve denetim faaliyetleri ile uyum güvencesi sağlanır.</p></li>
                </ol>
            </li>
        </ol>
    </li>
    <li data-list-text="8."><h1>
        Politikanın İhlali ve Yaptırımlar </h1><span>Bilgi Güvenliği Politikasına ve Standartlarına uyulmadığının tespit edilmesi durumunda, bu ihlalden sorumlu olan çalışanlar için Disiplin  Yönergesi ve Prosedürü ’ne göre 3. Taraflar için de geçerli olan sözleşmelerde geçen ilgili maddelerinde belirlenen yaptırımlar uygulanır.</span>
    </li>
    <li data-list-text="9."><h1>Yönetimin
        Gözden Geçirmesi </h1><span>Yönetim gözden geçirme toplantıları BGYS Kalite Yönetim Temsilcisi Organize edilerek, Üst Yönetim ve Bölüm yöneticileri katılımı ile  gerçekleştirilir.  Bilgi  Güvenliği  Yönetim  Sisteminin  uygunluğunun  ve etkinliğinin  değerlendirildiği  bu  toplantılar  en  az  yılda  bir  kez gerçekleştirilmektedir.</span>
    </li>
    <li data-list-text="10."><h1>Bilgi
        Güvenliği Politika Dokümanı Güncellenmesi ve Gözden
        Geçirilmesi </h1><span>Politika dokümanının sürekliliğinin sağlanmasından ve gözden geçirilmesinden BGYS Yönetim Temsilcileri sorumludur. Politika ve prosedürler en az yılda bir kez gözden geçirilmelidir. Bunun dışında sistem yapısını veya risk değerlendirmesini etkileyecek herhangi bir değişiklikten sonra da gözden geçirilmeli ve herhangi bir değişiklik gerekiyorsa üst yönetime onaylatılarak yeni versiyon olarak kayıt altına  alınmalıdır.  Her  revizyon  tüm  kullanıcıların  erişebileceği  şekilde yayınlanmalıdır.</span>
    </li>
</ol>
