<div class="d-flex flex-row align-items-center justify-content-around py-1 font-weight-bold my-2 exo caption bold" style=" color: #404352;">
  <span class="text-center" style="width: 33%">{{managerService.config.ADVANCED.ORDER_BOOK.PRICE | managerPipe}}</span>
  <span class="text-center" style="width: 34%">{{managerService.config.ADVANCED.ORDER_BOOK.AMOUNT | managerPipe}}</span>
  <span class="text-center" style="width: 33%">{{managerService.config.ADVANCED.ORDER_BOOK.TOTAL | managerPipe}}</span>
</div>
<div class="w-100 overflow-hidde">
  <div class="d-flex flex-column-reverse overflow-auto" style="min-height: 230px; max-height: 230px;">
    <div *ngFor="let order of (managerService.order_book_pipe | managerPipe: managerService.mpa_template.GET_SELL_ORDER_BOOK)"
         [style]="order | managerPipe: managerService.mpa_template.GET_SELL_ORDER_BOOK_BACKGROUND_COLOR" class="d-flex flex-row align-items-center justify-content-around py-1 barlow caption medium"
         style=" color: #4a4e70; line-height: 1.3; height: 23px;">
      <span (click)="managerService.set_form_price.emit(order.price)" class="text-center" style="width: 33%"
            type="button">{{order.price | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
      <span class="text-center" style="width: 34%">{{order.amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
      <span class="text-center" style="width: 33%">{{order.price * order.amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
    </div>
  </div>
</div>
<div class="d-flex flex-row align-items-center justify-content-around my-2 py-2 exo caption bold" style=" color: #404352; line-height: 1.3;">
  <span class="mx-1 w-50 text-right exo caption bold">{{managerService.config.ADVANCED.ORDER_BOOK.LAST_PRICE | managerPipe}}</span>
  <span [style]="managerService.trade_history_pipe | managerPipe: managerService.mpa_template.LAST_PRICE_COLOR"
        class="mx-1 w-50 text-left exo caption bold" style=" color: #404352;">
    {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_PRICE}}
  </span>
</div>
<div class="d-flex flex-column overflow-auto" style="min-height: 230px; max-height: 230px;">
  <div *ngFor="let order of (managerService.order_book_pipe | managerPipe: managerService.mpa_template.GET_BUY_ORDER_BOOK)"
       [style]="order | managerPipe: managerService.mpa_template.GET_BUY_ORDER_BOOK_BACKGROUND_COLOR" class="d-flex flex-row align-items-center justify-content-around py-1 barlow caption medium"
       style=" color: #4a4e70; line-height: 1.3; height: 23px;">
    <span (click)="managerService.set_form_price.emit(order.price)" class="text-center" style="width: 33%" type="button">{{order.price | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
    <span class="text-center" style="width: 34%">{{order.amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
    <span class="text-center" style="width: 33%">{{order.price * order.amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
  </div>
</div>
