import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {UserBankAccount} from '../../../../../../services/user.service';
import {Subscription} from 'rxjs';
import {ManagerService} from '../../../../../../services/manager.service';
import {HttpClient} from '@angular/common/http';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-withdrawal-fiat-mobile',
  templateUrl: './withdrawal-fiat-mobile.component.html',
  styleUrls: ['./withdrawal-fiat-mobile.component.css']
})
export class WithdrawalFiatMobileComponent implements OnInit, AfterViewInit, OnDestroy {

  bankWithdrawalRequestForm = new UntypedFormGroup({
    amount: new UntypedFormControl(null, [Validators.required]),
  });

  @ViewChild('bank_account_dropdown_native_menu') bank_account_dropdown_native_menu: any = undefined;

  public subscription_list: (Subscription | undefined)[] = [];

  constructor(public managerService: ManagerService, private httpClient: HttpClient) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.subscription_list.push(this.bankWithdrawalRequestForm.get('amount')?.valueChanges.subscribe(() => {
      if (this.managerService.userService.user_ready) {
        if (this.bankWithdrawalRequestForm.get('amount')?.value === null) {
          return;
        }
        const withdrawal_amount = this.managerService.checkFiatWithdrawalAmount(this.bankWithdrawalRequestForm.get('amount')?.value);
        if (this.bankWithdrawalRequestForm.get('amount')?.value > withdrawal_amount) {
          this.bankWithdrawalRequestForm.get('amount')?.setValue(withdrawal_amount);
        }
      }
    }));

    this.subscription_list.push(this.managerService.operationsService.bank_withdrawal_request_form_submitted.subscribe(() => {
      this.submit();
    }));
  }

  selectBankAccount(bank_account: UserBankAccount, force: boolean = false): void {
    this.managerService.operationsService.selectWithdrawBankAccount(bank_account);
    this.managerService.operationsService.active_currency = this.managerService.pairService.currency_list[0];
    // this.bankWithdrawalRequestForm.get('iban')?.setValue(bank_account.iban);

    if (force) {
      this.closeBankAccountDropdownDisplay();
    } else {
      this.toggleBankAccountDropdownDisplay();
    }
  }

  closeBankAccountDropdownDisplay(): void {
    this.bank_account_dropdown_native_menu.nativeElement.classList.add('d-none');
  }

  toggleBankAccountDropdownDisplay(): void {
    this.bank_account_dropdown_native_menu.nativeElement.classList.toggle('d-none');
  }

  removeUserBankAccount(bank_account: UserBankAccount): void {
    this.managerService.operationsService.removeUserBankAccount(bank_account, this.managerService.popResponseSnackbar);
    this.toggleBankAccountDropdownDisplay();
  }

  submit(): void {
    this.managerService.operationsService.signCurrencyWithdrawalRequest(this.managerService.popResponseSnackbar);
    this.bankWithdrawalRequestForm.get('amount')?.setValue(null);
  }

  ngOnDestroy(): void {
    this.managerService.operationsService.fiat_withdrawal_rules_accepted_status = false;
    this.subscription_list.forEach((subscription) => {
      subscription?.unsubscribe();
    });
  }

}
