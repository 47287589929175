<div class="d-flex flex-row justify-content-center align-items-center" style="gap: 10vw; margin: 10vw">
    <div class="w-50 d-flex justify-content-center align-items-center">
        <div class="w-100 d-flex justify-content-center align-items-center">
            <div (click)="managerService.navigateIfLoggedIn({url: '/auction/catalog', active_auction_catalog_hash: '2024_DAILY', current_component_index: 2});"
                 class="d-flex flex-column justify-content-between"
                 style="width: 90vw; border-radius: 6px;background: #FCFDFF;box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.15);">
                <div class="d-flex" style="padding: 2vw 5vw;">
                    <div class="w-100 d-flex flex-row justify-content-between align-items-center">
                        <div class="d-flex"
                             style="color: #49566B;font-size: 2vw;font-family: Poppins,sans-serif;font-style: normal;font-weight: 600;line-height: normal;letter-spacing: 0.03px;">
                            {{managerService.config.AUCTION.CATALOG_TYPE.DAILY | managerPipe}}
                        </div>

                        <div class="d-flex"
                             style="background: #E30E4E;border-radius: 4px; padding: 1vw; color: #FFFFFF;font-family: Poppins,sans-serif;font-size: 1vw;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: 0.15px;">
                            {{managerService.config.AUCTION.CATALOG_TYPE.STATUS.LIVE | managerPipe}}
                        </div>
                    </div>
                </div>
                <div style="padding: 2vw 5vw;color: #49566B;font-size: 1.5vw;font-family: Poppins,sans-serif;font-style: normal;font-weight: 400;line-height: normal;letter-spacing: 0.05px;">
                    {{managerService.config.AUCTION.CATALOG_TYPE.DAILY_TEXT | managerPipe}}
                </div>
                <div class="d-flex justify-content-center align-content-end">
                    <img src="{{managerService.config.AUCTION.CATALOG_TYPE.DAILY_IMG | managerPipe}}" style="width: 30vw; height: auto">
                </div>
            </div>
        </div>
    </div>

    <div class="w-50 d-flex justify-content-center align-items-center">
        <div (click)="managerService.navigateIfLoggedIn({url: '/auction/catalog', current_component_index: 1});"
             class="d-flex flex-column justify-content-between"
             style="width: 90vw; border-radius: 6px;background: #FCFDFF;box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.15);">
            <div class="d-flex" style="padding: 2vw 5vw;">
                <div class="w-100 d-flex flex-row justify-content-between align-items-center">
                    <div class="d-flex"
                         style="color: #49566B;font-size: 2vw;font-family: Poppins,sans-serif;font-style: normal;font-weight: 600;line-height: normal;letter-spacing: 0.03px;">
                        {{managerService.config.AUCTION.CATALOG_TYPE.CATALOG | managerPipe}}
                    </div>

                    <div class="d-flex"
                         style="background: #E30E4E;border-radius: 4px; padding: 1vw; color: #FFFFFF;font-family: Poppins,sans-serif;font-size: 1vw;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: 0.15px;">
                        {{managerService.config.AUCTION.CATALOG_TYPE.STATUS.LIVE | managerPipe}}
                    </div>
                </div>
            </div>
            <div style="padding: 2vw 5vw;color: #49566B;font-size: 1.5vw;font-family: Poppins,sans-serif;font-style: normal;font-weight: 400;line-height: normal;letter-spacing: 0.05px;">
                {{managerService.config.AUCTION.CATALOG_TYPE.CATALOG_TEXT | managerPipe}}
            </div>
            <div class="d-flex justify-content-end align-content-end">
                <img src="{{managerService.config.AUCTION.CATALOG_TYPE.CATALOG_IMG | managerPipe}}"
                     style="width: 30vw; height: auto">
            </div>
        </div>
    </div>
</div>
