<div class="p-3" style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500;">Front-End Queries:</div>

<form [formGroup]="searchForm" class="w-100 p-3 d-flex flex-column align-items-center justify-content-start"
      style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500;">
    <div class="mx-2">
        <span class="m-1">ID: </span>
        <input class="m-1" formControlName="id" required type="number">
        <button (click)="adminService.searchID(searchForm); adminService.show_searched_users = true;" class="m-1" style="color: black">
            SEARCH BY ID
        </button>
    </div>
    <div class="mx-2">
        <span class="m-1">TC NO: </span>
        <input class="m-1" formControlName="tc_no" required type="text">
        <button (click)="adminService.searchTCNO(searchForm); adminService.show_searched_users = true;" class="m-1" style="color: black">
            SEARCH BY TC NO
        </button>
    </div>
    <div class="mx-2">
        <span class="m-1">CONCATENATED NAME FIELD: </span>
        <input class="m-1" formControlName="concatenated_name_field" required type="text">
    </div>
    <div class="mx-2">
        <button (click)="adminService.searchConcatenatedNameField(searchForm); adminService.show_searched_users = true;" class="m-1" style="color: black">
            SEARCH BY CONCATENATED NAME FIELD
        </button>
    </div>
    <div>
        <button (click)="adminService.show_searched_users = false; adminService.show_online_users = false;" class="m-1" style="color: black">
            CLEAR
        </button>
        <button (click)="adminService.clearCachedUsers()" class="m-1" style="color: black">
            PURGE
        </button>
    </div>
</form>
<div #admin_dashboard class="w-100 p-3">
    <span class="mx-2">USERS:</span>
    <div class="d-flex flex-row align-items-center justify-content-around py-1" style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500;">
    <span (click)="this.adminService.show_online_users = !this.adminService.show_online_users" class="text-center" style="width: 10%;">
      <span [style.background-color]="this.adminService.show_online_users ? '#0437F2':'#FF2400'" class="circle"></span>
      ID<br>{{adminService.online_user_id_list.length}}-{{adminService.user_count}}
    </span>
        <span class="text-center" style="width: 50%">EMAIL</span>
        <span class="text-center" style="width: 5%">LEVEL</span>
        <!--    <span class="text-center" style="width: 20%">IN ORDER BALANCE</span>-->
        <!--    <span class="text-center" style="width: 20%">AVAILABLE BALANCE</span>-->
        <span class="text-center" style="width: 27%">TOTAL</span>
        <span class="text-center" style="width: 8%">DETAILS</span>
    </div>
    <div class="d-flex flex-column overflow-auto" style="min-height: 260px; max-height: 260px;">
        <div
            *ngFor="let user of (this.adminService.user_list | managerPipe)"
            [class]="(adminService.show_online_users && !this.adminService.online_user_id_list.includes(user.id)) || (adminService.show_searched_users && !this.adminService.user_search_list.includes(user.id)) ? 'd-none':'d-flex'"
            class="flex-row align-items-center justify-content-around py-1 row-separator" style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 400;">
    <span class="text-center" style="width: 10%">
      <span [style.background-color]="this.adminService.online_user_id_list.includes(user.id) ? '#0437F2':'#FF2400'" class="circle"></span>
        {{user.id | managerPipe}}
    </span>
            <span class="text-center" style="width: 50%">{{user.email | managerPipe}}</span>
            <span class="text-center" style="width: 5%">{{user.account_level | managerPipe}}</span>
            <!--      <span class="text-center" style="width: 20%">-->
            <!--        {{getUserTotalArtProductTypeOfRootCurrency(user) - getUserAvailableArtProductTypeOfRootCurrency(user) + getUserTotalCurrencyTypeOfRootCurrency(user) - getUserAvailableCurrencyTypeOfRootCurrency(user) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}-->
            <!--      </span>-->
            <!--      <span class="text-center" style="width: 20%">-->
            <!--        {{getUserAvailableArtProductTypeOfRootCurrency(user) + getUserAvailableCurrencyTypeOfRootCurrency(user) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}-->
            <!--      </span>-->
            <span class="text-center" style="width: 27%">
        {{adminService.getUserTotalArtProductTypeOfRootCurrency(user) + adminService.getUserTotalCurrencyTypeOfRootCurrency(user) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
      </span>
            <span
                (click)="adminService.selected_user=user; executeCurrencyDepositForm.get('user_id')?.setValue(user.id); adminService.selected_user_info=adminService.getCachedUserInfo(user.id); user_dashboard.classList.remove('d-none'); admin_dashboard.classList.add('d-none');"
                class="text-center"
                style="width: 8%; cursor: pointer;">
        <svg style="height: 14px; width: 14px;">
          <use [attr.xlink:href]='"./assets/images/announcement_right_arrow.svg#announcement_right_arrow"' fill="#4D6557"/>
        </svg>
      </span>
        </div>
    </div>
</div>

<div #user_dashboard class="w-100 d-none px-3">
    <div class="d-flex flex-row align-items-center justify-content-around py-1" style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500;">
    <span class="text-center" style="width: 5%">
      <span [style.background-color]="this.adminService.show_online_users ? '#0437F2':'#FF2400'" class="circle"></span>
      ID: {{adminService.selected_user.id}}
    </span>
        <span class="text-center" style="width: 28%">EMAIL<br>{{adminService.selected_user.email.split('@')[0]}} &#64;{{adminService.selected_user.email.split('@')[1]}}</span>
        <span class="text-center" style="width: 10%">LVL<br>{{adminService.selected_user.account_level}}</span>
        <span class="text-center"
              style="width: 17%">IN ORDER<br>{{adminService.getUserTotalArtProductTypeOfRootCurrency(adminService.selected_user) - adminService.getUserAvailableArtProductTypeOfRootCurrency(adminService.selected_user) + adminService.getUserTotalCurrencyTypeOfRootCurrency(adminService.selected_user) - adminService.getUserAvailableCurrencyTypeOfRootCurrency(adminService.selected_user) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
        <span class="text-center"
              style="width: 17%">AVBL<br>{{adminService.getUserAvailableArtProductTypeOfRootCurrency(adminService.selected_user) + adminService.getUserAvailableCurrencyTypeOfRootCurrency(adminService.selected_user) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
        <span class="text-center"
              style="width: 17%">TOTAL<br>{{adminService.getUserTotalArtProductTypeOfRootCurrency(adminService.selected_user) + adminService.getUserTotalCurrencyTypeOfRootCurrency(adminService.selected_user) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
        <span (click)="admin_dashboard.classList.remove('d-none'); user_dashboard.classList.add('d-none');" class="text-center" style="width: 6%; cursor: pointer;">
      <svg style="height: 14px; width: 14px;">
        <use [attr.xlink:href]='"./assets/images/announcement_left_arrow.svg#announcement_left_arrow"' fill="#4D6557"/>
      </svg>
    </span>
    </div>
    <div class="d-flex flex-column overflow-auto" style="min-height: 260px; max-height: 260px;">
        <div *ngFor="let user_asset of (adminService.getUserAssets(adminService.selected_user) | managerPipe)"
             class="d-flex flex-row align-items-center justify-content-around py-1 row-separator" style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 400;">
      <span class="text-center"
            style="width: 5%"></span>
            <span class="text-center"
                  style="width: 28%"></span>
            <span class="text-center"
                  style="width: 10%">{{user_asset | managerPipe: managerService.mpa_template.GET_USER_ASSETS_SYMBOL}}</span>
            <span class="text-center"
                  style="width: 17%">{{user_asset.total_amount - user_asset.available_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
            <span class="text-center"
                  style="width: 17%">{{user_asset.available_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
            <span class="text-center"
                  style="width: 17%">{{user_asset.total_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
            <span class="text-center"
                  style="width: 6%"></span>
        </div>
        <div class="py-2 row-separator" style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 400;">
            <div class="w-100 d-flex flex-row justify-content-between">
                <span class="text-center"
                      style="width: 25%">{{adminService.selected_user_info.tc_no | managerPipe}}</span>
                <span class="text-center"
                      style="width: 30%">{{adminService.selected_user_info.name | managerPipe}}</span>
                <span class="text-center"
                      style="width: 30%">{{adminService.selected_user_info.surname | managerPipe}}</span>
                <span class="text-center"
                      style="width: 15%">{{adminService.selected_user_info.id | managerPipe: managerService.mpa_template.ADMIN_GET_USER_PLATFORM: adminService}}</span>
            </div>
        </div>
        <div class="py-2 row-separator" style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 400;">
            <div class="w-100 d-flex flex-row justify-content-between">
                <span class="text-center"
                      style="width: 15%">{{adminService.selected_user_info.town | managerPipe}}</span>
                <span class="text-center"
                      style="width: 10%">{{adminService.selected_user_info.birth_year | managerPipe}}</span>
                <span class="text-center"
                      style="width: 15%">{{adminService.selected_user.deposit_reference_code | managerPipe}}</span>
                <button (click)="adminService.getUserInfo(adminService.selected_user_info.id)" class="px-3" style="width: 25%; color: black">Get User Info</button>
                <button (click)="adminService.refreshUser(adminService.selected_user.id)" class="px-3" style="width: 25%; color: black">Refresh User</button>
            </div>
        </div>
        <form [formGroup]="executeCurrencyDepositForm" class="d-flex flex-column align-items-center justify-content-center py-2 row-separator"
              style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 400;">
      <span class="text-center"
            style="">CURRENCY DEPOSIT: {{adminService.selected_user_info.id | managerPipe}}</span>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">CURRENCY: </span>
                <select formControlName="currency_id" required style="height: 20px;" type="number">
                    <option value="-1">Select</option>
                    <option *ngFor="let currency of this.adminService.currency_list | managerPipe" [value]="currency.id">{{currency.symbol | managerPipe}}</option>
                </select>
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">AMOUNT: </span>
                <input formControlName="amount" required style="height: 20px;" type="number">
            </div>
            <button (click)="adminService.executeCurrencyDeposit(executeCurrencyDepositForm)" class="px-3" style="color: black">DEPOSIT</button>
        </form>
    </div>
</div>

<form [formGroup]="getUsersForm" class="w-100 p-3 d-flex flex-column align-items-center justify-content-start"
      style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500;">
    <div class="mx-2">
        <span class="m-1">Start: </span>
        <input class="m-1 w-25" formControlName="start_index" required type="number">
        <span class="m-1">End: </span>
        <input class="m-1 w-25" formControlName="end_index" required type="number">
    </div>
    <button (click)="adminService.getUsersAndInfo(getUsersForm);" class="m-1" style="color: black">
        Refresh Users In Between
    </button>

</form>

<div class="px-3 pt-3" style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500;">Back-End Queries:</div>

<form [formGroup]="queryForm" class="w-100 p-3 d-flex flex-column align-items-center justify-content-start"
      style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500;">
    <div class="mx-2">
        <span class="m-1">ID: </span>
        <input class="m-1" formControlName="user_id" required type="number">
    </div>
    <div class="d-flex flex-row mx-2">
        <button (click)="adminService.queryUserByID(queryForm)" class="m-1" style="color: black">
            SEARCH USER BY ID
        </button>
        <button (click)="adminService.getUserInfoByID(queryForm);" class="m-1" style="color: black">
            SEARCH USER INFO BY ID
        </button>
    </div>
    <div class="mx-2 mt-2">
        <span class="m-1">CONCATENATED NAME: </span>
        <input class="m-1" formControlName="concatenated_name_field" required type="text">
    </div>
    <div class="mx-2">
        <button (click)="adminService.queryUserByConcatenatedNameField(queryForm);" class="m-1" style="color: black">
            SEARCH BY CONCATENATED NAME
        </button>
    </div>
    <div class="mx-2 mt-2">
        <span class="m-1">REFERENCE CODE: </span>
        <input class="m-1" formControlName="deposit_reference_code" required type="number">
    </div>
    <div class="mx-2">
        <button (click)="adminService.queryUserByReferenceCode(queryForm);" class="m-1" style="color: black">
            SEARCH BY REFERENCE CODE
        </button>
    </div>
</form>

<div #user_search_result class="px-4 pt-2 d-none"
     style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500;">
    <div class="d-flex flex-row row-separator p-1">
        <span style="width: 50%">id: {{adminService.searched_user_raw?.id | managerPipe}}</span>
        <span style="width: 50%">email:<br>{{adminService.searched_user_raw?.email | managerPipe}}</span>
    </div>
    <div class="d-flex flex-row row-separator p-1">
        <span style="width: 50%">account_level: {{adminService.searched_user_raw?.account_level | managerPipe}}</span>
        <span style="width: 50%">vip: {{adminService.searched_user_raw?.vip | managerPipe}}</span>
    </div>
    <div class="d-flex flex-row row-separator p-1">
        <span style="width: 50%">account_verified: {{adminService.searched_user_raw?.account_verified | managerPipe}}</span>
        <span style="width: 50%">kyc_verified: {{adminService.searched_user_raw?.kyc_verified | managerPipe}}</span>
    </div>
    <div class="row-separator p-1">
        <div class="d-inline-block" style="width: 80%">adminService.art_product_list:</div>
        <div (click)="user_search_result_art_products.classList.toggle('d-none')" class="d-inline-block" style="width: 20%; text-align: center">More</div>
        <div #user_search_result_art_products class="d-none p-3">
            <div *ngFor="let art_product of adminService.searched_user_raw?.art_product_list | managerPipe" class="row-separator px-1">
                <div class="d-inline-block" style="width: 20%">{{managerService.pairService.getArtProductById(art_product.art_product_id)?.symbol | managerPipe}}</div>
                <div class="d-inline-block" style="width: 40%">available_amount: {{art_product.available_amount | managerPipe}}</div>
                <div class="d-inline-block" style="width: 40%">total_amount: {{art_product.total_amount | managerPipe}}</div>
            </div>
        </div>
    </div>
    <div class="row-separator p-1">
        <div class="d-inline-block" style="width: 80%">adminService.currency_list:</div>
        <div (click)="user_search_result_currencies.classList.toggle('d-none')" class="d-inline-block" style="width: 20%; text-align: center">More</div>
        <div #user_search_result_currencies class="d-none p-3">
            <div *ngFor="let currency of adminService.searched_user_raw?.currency_list | managerPipe" class="row-separator px-1">
                <div class="d-inline-block" style="width: 20%">{{managerService.pairService.getCurrencyById(currency.currency_id)?.symbol | managerPipe}}</div>
                <div class="d-inline-block" style="width: 40%">available_amount: {{currency.available_amount | managerPipe}}</div>
                <div class="d-inline-block" style="width: 40%">total_amount: {{currency.total_amount | managerPipe}}</div>
            </div>
        </div>
    </div>
    <div class="row-separator p-1">
        <div class="d-inline-block" style="width: 80%">pair_list:</div>
        <div (click)="user_search_result_pairs.classList.toggle('d-none')" class="d-inline-block" style="width: 20%; text-align: center">More</div>
        <div #user_search_result_pairs class="d-none p-3">
            <div *ngFor="let pair of adminService.searched_user_raw?.pair_list | managerPipe" class="row-separator px-1">
                <div class="d-inline-block" style="width: 40%">{{managerService.pairService.getPairById(pair.pair_id)?.symbol | managerPipe}}</div>
                <div class="d-inline-block" style="width:60%">is_favorite: {{pair.is_favorite | managerPipe}}</div>
            </div>
        </div>
    </div>
    <div class="d-flex flex-row row-separator p-1">
        <span style="width: 50%">second_level_kyc_verified:<br>{{adminService.searched_user_raw?.second_level_kyc_verified | managerPipe}}</span>
        <span style="width: 50%">second_level_kyc_status:<br>{{adminService.searched_user_raw?.second_level_kyc_status | managerPipe}}</span>
    </div>
    <div class="d-flex flex-row row-separator p-1">
        <span style="width: 50%">two_factor_auth: {{adminService.searched_user_raw?.two_factor_auth | managerPipe}}</span>
        <span style="width: 50%">creation_time:<br>{{adminService.searched_user_raw?.creation_time / 1000000 | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</span>
    </div>
    <div class="d-flex flex-row row-separator p-1">
        <span style="width: 50%">daily_deposit_limit:<br>{{adminService.searched_user_raw?.daily_deposit_limit | managerPipe}}</span>
        <span style="width: 50%">daily_withdrawal_limit:<br>{{adminService.searched_user_raw?.daily_withdrawal_limit | managerPipe}}</span>
    </div>
    <div class="d-flex flex-row row-separator p-1">
        <span style="width: 50%">monthly_deposit_limit:<br>{{adminService.searched_user_raw?.monthly_deposit_limit | managerPipe}}</span>
        <span style="width: 50%">monthly_withdrawal_limit:<br>{{adminService.searched_user_raw?.monthly_withdrawal_limit | managerPipe}}</span>
    </div>
    <div class="d-flex flex-row row-separator p-1">
        <span style="width: 50%">deposit_reference_code:<br>{{adminService.searched_user_raw?.deposit_reference_code | managerPipe}}</span>
        <span style="width: 50%">open_tooltip: {{adminService.searched_user_raw?.open_tooltip | managerPipe}}</span>
    </div>
</div>

<div #user_info_search_result class="px-4 pt-2 d-none"
     style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500;">
    <div class="d-flex flex-row row-separator p-1">
        <span style="width: 50%">id: {{adminService.searched_user_info_raw?.id | managerPipe}}</span>
        <span style="width: 50%">tc_no: {{adminService.searched_user_info_raw?.tc_no | managerPipe}}</span>
    </div>
    <div class="d-flex flex-row row-separator p-1">
        <span style="width: 50%">name: {{adminService.searched_user_info_raw?.name | managerPipe}}</span>
        <span style="width: 50%">surname: {{adminService.searched_user_info_raw?.surname | managerPipe}}</span>
    </div>
    <div class="d-flex flex-row row-separator p-1">
        <span style="width: 50%">birth_year: {{adminService.searched_user_info_raw?.birth_year | managerPipe}}</span>
        <span style="width: 50%">town: {{adminService.searched_user_info_raw?.town | managerPipe}}</span>
    </div>
    <div class="d-flex flex-row row-separator p-1">
<!--        <span>address: <span style="font-weight: normal;">{{adminService.searched_user_info_raw?.address | managerPipe}}</span></span>-->
        <span style="width: 50%">address: {{adminService.searched_user_info_raw?.address | managerPipe}}</span>
        <span style="width: 50%">phone_number: {{adminService.searched_user_info_raw?.phone_number | managerPipe}}</span>
    </div>
</div>

<div #concatenated_name_search_result class="px-4 pt-2 d-none"
     style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500;">
    <div *ngIf="adminService.concatenated_name_list_raw.length === 0 | managerPipe" class="text-center">Not found</div>
    <div *ngFor="let user of adminService.concatenated_name_list_raw | managerPipe" class="row-separator p-1">
        <div class="row-separator px-1">
            <div class="d-inline-block" style="width: 40%">id: {{user?.id | managerPipe}}</div>
            <div class="d-inline-block" style="width: 40%">tc_no: {{user?.tc_no | managerPipe}}</div>
            <div (click)="concatenated_name_search_more.classList.toggle('d-none')" class="d-inline-block" style="width: 20%; text-align: center">More</div>
        </div>
        <div #concatenated_name_search_more class="d-none p-3">
            <div class="row-separator px-1">
                <div class="d-inline-block" style="width: 50%">name: {{user?.name | managerPipe}}</div>
                <div class="d-inline-block" style="width: 50%">surname: {{user?.surname | managerPipe}}</div>
            </div>
            <div class="row-separator px-1">
                <div class="d-inline-block" style="width: 50%">birth_year: {{user?.birth_year | managerPipe}}</div>
                <div class="d-inline-block" style="width: 50%">town: {{user?.town | managerPipe}}</div>
            </div>
            <div class="row-separator px-1">
                <span>address: <span style="font-weight: normal;">{{user?.address | managerPipe}}</span></span>
            </div>
        </div>
    </div>
</div>


<div class="w-100 px-3 mt-4">
    <span class="mx-2">CURRENCY WIHDRAWAL REQUESTS:</span>
    <div class="d-flex flex-row align-items-center justify-content-around py-1" style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500;">
        <span class="text-center" style="width: 15%">COPY</span>
        <!--    <span class="text-center" style="width: 7.5%">TC NO</span>-->
        <span class="text-center" style="width: 10%">USER ID</span>
        <!--    <span class="text-center" style="width: 15%">CREATION TIME</span>-->
        <span class="text-center" style="width: 10%">STATUS</span>
        <span class="text-center" style="width: 15%">NAME</span>
        <span class="text-center" style="width: 15%">SURNAME</span>
        <span class="text-center" style="width: 15%">AMOUNT</span>
        <!--    <span class="text-center" style="width: 10%">BANK</span>-->
        <!--    <span class="text-center" style="width: 20%">IBAN</span>-->
        <span class="text-center" style="width: 5%"></span>
        <span class="text-center" style="width: 15%">APPROVE</span>
    </div>
    <div class="d-flex flex-column overflow-auto" style="min-height: 260px; max-height: 260px;">
        <div *ngFor="let currency_withdrawal_request of (this.adminService.lower_currency_withdrawal_request_list | managerPipe)"
             class="d-flex flex-row flex-wrap align-items-center justify-content-around py-1 row-separator" style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 400;">
            <button (click)="adminService.copyCurrencyWithdrawalRequest(currency_withdrawal_request)" class="text-center" style="width: 15%; color: black;">COPY</button>
            <span class="text-center" style="width: 10%">{{currency_withdrawal_request.user_id | managerPipe}}</span>
            <span class="text-center" style="width: 10%">{{currency_withdrawal_request.status | managerPipe}}</span>
            <span class="text-center" style="width: 15%">{{currency_withdrawal_request.name | managerPipe}}</span>
            <span class="text-center" style="width: 10%">{{currency_withdrawal_request.surname | managerPipe}}</span>
            <span class="text-center" style="width: 15%">{{currency_withdrawal_request.amount | managerPipe}}</span>
            <span (click)="more_div.classList.toggle('d-none')" class="text-center" style="width: 10%; font-weight: 500; text-decoration: underline">more</span>
            <button (click)="adminService.executeCurrencyWithdrawal(currency_withdrawal_request)" class="text-center" style="width: 15%; color: black;">APPROVE</button>
            <div #more_div class="d-none">
                <div class="text-center" style="">TC NO: {{currency_withdrawal_request.tc_no | managerPipe}}</div>
                <div class="text-center" style="">Time : {{currency_withdrawal_request.creation_time | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</div>
                <div class="text-center" style="">Bank: {{currency_withdrawal_request.bank | managerPipe}}</div>
                <div class="text-center" style="">IBAN: {{currency_withdrawal_request.iban | managerPipe}}</div>
            </div>
        </div>

        <div class="my-3" style="background: #9DA5B7;border-bottom: #9DA5B7 1px solid;"></div>

        <div *ngFor="let currency_withdrawal_request of (this.adminService.higher_currency_withdrawal_request_list | managerPipe)"
             class="d-flex flex-row flex-wrap align-items-center justify-content-around py-1 row-separator" style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 400;">
            <button (click)="adminService.copyCurrencyWithdrawalRequest(currency_withdrawal_request)" class="text-center" style="width: 15%; color: black;">COPY</button>
            <span class="text-center" style="width: 10%">{{currency_withdrawal_request.user_id | managerPipe}}</span>
            <span class="text-center" style="width: 10%">{{currency_withdrawal_request.status | managerPipe}}</span>
            <span class="text-center" style="width: 15%">{{currency_withdrawal_request.name | managerPipe}}</span>
            <span class="text-center" style="width: 10%">{{currency_withdrawal_request.surname | managerPipe}}</span>
            <span class="text-center" style="width: 15%">{{currency_withdrawal_request.amount | managerPipe}}</span>
            <span (click)="more_div.classList.toggle('d-none')" class="text-center" style="width: 10%; font-weight: 500; text-decoration: underline">more</span>
            <button (click)="adminService.executeCurrencyWithdrawal(currency_withdrawal_request)" class="text-center" style="width: 15%; color: black;">APPROVE</button>
            <div #more_div class="d-none">
                <div class="text-center" style="">TC NO: {{currency_withdrawal_request.tc_no | managerPipe}}</div>
                <div class="text-center" style="">Time : {{currency_withdrawal_request.creation_time | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</div>
                <div class="text-center" style="">Bank: {{currency_withdrawal_request.bank | managerPipe}}</div>
                <div class="text-center" style="">IBAN: {{currency_withdrawal_request.iban | managerPipe}}</div>
            </div>
        </div>
    </div>
</div>

<div class="w-100 px-3">
    <span class="mx-2">SECOND LEVEL KYC REQUESTS:</span>
    <div class="d-flex flex-row align-items-center justify-content-around py-1" style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500;">
        <span class="text-center" style="width: 20%">REJECT</span>
        <span class="text-center" style="width: 10%">ID</span>
        <span class="text-center" style="width: 20%">NAME</span>
        <span class="text-center" style="width: 25%">SURNAME</span>
        <span class="text-center" style="width: 10%"></span>
        <span class="text-center" style="width: 20%">APPROVE</span>
    </div>
    <div class="d-flex flex-column overflow-auto" style="min-height: 260px; max-height: 260px;">
        <div *ngFor="let second_level_kyc_appeal of (this.adminService.second_level_kyc_appeal_list | managerPipe)"
             class="d-flex flex-row flex-wrap align-items-center justify-content-around py-1 row-separator" style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 400;">
            <button (click)="adminService.executeSecondLevelKYC(second_level_kyc_appeal, 'rejected')" class="text-center" style="width: 18%; color: black;">REJECT</button>
            <span class="text-center" style="width: 10%">{{second_level_kyc_appeal.id | managerPipe}}</span>
            <span class="text-center"
                  style="width: 22%">{{(second_level_kyc_appeal.id | managerPipe: managerService.mpa_template.ADMIN_GET_CACHED_USER_INFO: adminService: adminService: adminService.user_info_list_pipe)?.name | managerPipe}}</span>
            <span class="text-center"
                  style="width: 22%">{{(second_level_kyc_appeal.id | managerPipe: managerService.mpa_template.ADMIN_GET_CACHED_USER_INFO: adminService: adminService: adminService.user_info_list_pipe)?.surname | managerPipe}}</span>
            <span (click)="more_div.classList.toggle('d-none')" class="text-center" style="width: 10%; font-weight: 500; text-decoration: underline">more</span>
            <button (click)="adminService.executeSecondLevelKYC(second_level_kyc_appeal, 'verified')" class="text-center" style="width: 18%; color: black;">APPROVE</button>
            <div #more_div class="d-none">
                <div class="text-center" style="">Email: {{second_level_kyc_appeal.email | managerPipe}}</div>
                <div class="text-center" style="">Status: {{second_level_kyc_appeal.second_level_kyc_status | managerPipe}}</div>
                <div class="text-center" style="">TC
                    NO: {{(second_level_kyc_appeal.id | managerPipe: managerService.mpa_template.ADMIN_GET_CACHED_USER_INFO: adminService: adminService: adminService.user_info_list_pipe)?.tc_no | managerPipe}}</div>
            </div>
        </div>
    </div>
</div>



<div class="d-flex flex-row align-items-center justify-content-between px-3 ms-2 mt-5">
  <span>DELIVERIES:</span>
  <button (click)="adminService.toggleActiveDeliveryList();" class="px-3 mt-1" style="font-family: Roboto, sans-serif; font-size: 11px; color: black">FILTER</button>
</div>
<div class="px-3 overflow-auto mb-4" style="height: 260px;">
    <div *ngFor="let delivery of (adminService.active_delivery_list | managerPipe: managerService.mpa_template.IDENTITY: adminService.delivery_list_pipe)"
         class="d-flex flex-column align-items-center justify-content-center py-1 row-separator"
         style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500;">

        <div class="w-100 d-flex flex-row pt-1">
            <span style="width: 50%">DELIVERY ID: {{delivery.delivery_id | managerPipe}}</span>
            <span style="width: 50%">USER ID: {{delivery.user_id | managerPipe}}</span>
        </div>

        <div class="w-100 d-flex flex-row pt-1">
            <span style="width: 50%">AUCTION PROD ID: {{delivery.auction_product_id | managerPipe}}</span>
            <span style="width: 50%">HASH: {{ (delivery.auction_product_id | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_BY_ID).hash }}</span>
        </div>

        <div class="w-100 d-flex flex-row pt-1">
          <span style="width: 50%">STATUS: {{delivery.status | managerPipe}}</span>
          <span style="width: 50%">TYPE: {{delivery.type | managerPipe}}</span>
        </div>

        <span class="w-100 pt-1">CREATION TIME: {{delivery.creation_time / 1000000 | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</span>

      <div class="w-100 d-flex flex-row pt-1">
        <span style="width: 80%">DELIVERY ADDRESS DETAILS:</span>
        <span (click)="delivery_address_details.classList.toggle('d-none')" style="width: 20%; text-align: center">More</span>
      </div>
      <div #delivery_address_details class="d-none w-100 px-2">
        <div class="w-100 d-flex flex-row pt-1 row-separator-dark">
          <span style="width: 50%">ID: {{ delivery.delivery_address.address_id | managerPipe }}</span>
          <span style="width: 50%">LABEL: {{ delivery.delivery_address.label | managerPipe }}</span>
        </div>
        <div class="w-100 d-flex flex-row pt-1 row-separator-dark">
          <span style="width: 50%">NAME: {{ delivery.delivery_address.name | managerPipe }}</span>
          <span style="width: 50%">SURNAME: {{ delivery.delivery_address.surname | managerPipe }}</span>
        </div>
        <div class="w-100 d-flex flex-row pt-1 row-separator-dark">
          <span style="width: 50%">TC NO: {{ delivery.delivery_address.tc_no | managerPipe }}</span>
          <span style="width: 50%">PHONE NO: {{ delivery.delivery_address.phone_number | managerPipe }}</span>
        </div>
        <div class="w-100 d-flex flex-row pt-1 font-weight-normal row-separator-dark">ADDRESS: {{ delivery.delivery_address.address | managerPipe }}</div>
        <div class="w-100 d-flex flex-row pt-1 row-separator-dark">
          <span style="width: 50%">DISTRICT: {{ delivery.delivery_address.district | managerPipe }}</span>
          <span style="width: 50%">PROVINCE: {{ delivery.delivery_address.province | managerPipe }}</span>
        </div>
      </div>

      <div class="w-100 d-flex flex-row pt-1">
        <span style="width: 80%">BILLING ADDRESS DETAILS:</span>
        <span (click)="billing_address_details.classList.toggle('d-none')" style="width: 20%; text-align: center">More</span>
      </div>
      <div #billing_address_details class="d-none w-100 px-2">
        <div class="w-100 d-flex flex-row pt-1 row-separator-dark">
          <span style="width: 50%">ID: {{ delivery.billing_address.address_id | managerPipe }}</span>
          <span style="width: 50%">LABEL: {{ delivery.billing_address.label | managerPipe }}</span>
        </div>
        <div class="w-100 d-flex flex-row pt-1 row-separator-dark">
          <span style="width: 50%">NAME: {{ delivery.billing_address.name | managerPipe }}</span>
          <span style="width: 50%">SURNAME: {{ delivery.billing_address.surname | managerPipe }}</span>
        </div>
        <div class="w-100 d-flex flex-row pt-1 row-separator-dark">
          <span style="width: 50%">TC NO: {{ delivery.billing_address.tc_no | managerPipe }}</span>
          <span style="width: 50%">PHONE NO: {{ delivery.billing_address.phone_number | managerPipe }}</span>
        </div>
        <div class="w-100 d-flex flex-row pt-1 row-separator-dark">
          <span style="width: 50%">TYPE: {{ delivery.billing_address.address_type | managerPipe }}</span>
          <span style="width: 50%">COMPANY: {{ delivery.billing_address.company_name | managerPipe }}</span>
        </div>
        <div class="w-100 d-flex flex-row pt-1 row-separator-dark">TAX ADMINISTRATION: {{ delivery.billing_address.tax_administration | managerPipe }}</div>
        <div class="w-100 d-flex flex-row pt-1 row-separator-dark">TAX NUMBER: {{ delivery.billing_address.tax_number | managerPipe }}</div>
        <div class="w-100 d-flex flex-row pt-1 font-weight-normal row-separator-dark">ADDRESS: {{ delivery.billing_address.address | managerPipe }}</div>
        <div class="w-100 d-flex flex-row pt-1 row-separator-dark">
          <span style="width: 50%">DISTRICT: {{ delivery.billing_address.district | managerPipe }}</span>
          <span style="width: 50%">PROVINCE: {{ delivery.billing_address.province | managerPipe }}</span>
        </div>
      </div>

      <div class="w-100 d-flex flex-row pt-1">
        <span style="width: 80%">CARGO DETAILS:</span>
        <span (click)="cargo_details.classList.toggle('d-none')" style="width: 20%; text-align: center">More</span>
      </div>
      <div #cargo_details class="d-none w-100 px-2">
        <div class="w-100 d-flex flex-row pt-1 row-separator-dark">COMPANY: {{ delivery.company | managerPipe }}</div>
        <div class="w-100 d-flex flex-row pt-1 row-separator-dark">TRACKING NO: {{ delivery.tracking_number | managerPipe }}</div>
        <div class="w-100 d-flex flex-row pt-1 row-separator-dark">TRACKING STATUS: {{ delivery.tracking_status | managerPipe }}</div>
        <div class="w-100 d-flex flex-row pt-1 row-separator-dark">E.T.A.: {{ delivery.estimated_arrival_time | managerPipe }}</div>
        <div (click)="managerService.navigationManager({url: delivery.tracking_link, is_inner: false, target: '_blank'})"
             class="w-100 d-flex flex-row pt-1 row-separator-dark">TRACKING LINK: {{ delivery.tracking_link | managerPipe }}</div>
      </div>
      <button (click)="changeDeliveryForm.get('delivery_id')?.setValue(delivery.delivery_id);" class="px-3 mt-1" style="color: black">UPDATE</button>
    </div>
</div>

<span class="px-3 mx-2">UPDATE DELIVERY:</span>
<div class="w-100 px-3">
    <form [formGroup]="changeDeliveryForm" class="d-flex flex-column align-items-center justify-content-center py-1 row-separator"
          style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 400;">
        <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
            <span class="me-2">DELIVERY ID: </span>
            <select formControlName="delivery_id" required style="height: 20px;" type="number">
                <option *ngFor="let delivery of (adminService.delivery_list | managerPipe)" [ngValue]="delivery.delivery_id">{{delivery.delivery_id | managerPipe}}</option>
            </select>
        </div>

        <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
            <span class="me-2">USER ID: </span>
            <input formControlName="user_id" readonly="readonly" required style="height: 20px;" type="number">
        </div>

        <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
            <span class="me-2">STATUS: </span>
            <!--      <input formControlName="status" required style="height: 20px;" type="text">-->
            <select formControlName="status" required style="height: 20px;" type="text">
                <option [ngValue]="'pending'">pending</option>
                <option [ngValue]="'confirming'">confirming</option>
                <option [ngValue]="'approved'">approved</option>
                <option [ngValue]="'packed'">packed</option>
                <option [ngValue]="'delivered'">delivered</option>
            </select>
        </div>

        <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
            <span class="me-2">COMPANY: </span>
            <input formControlName="company" required style="height: 20px;" type="text">
        </div>

        <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
            <span class="me-2">TRACKING NO: </span>
            <input formControlName="tracking_number" required style="height: 20px;" type="text">
        </div>

        <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
            <span class="me-2">TRACKING LINK: </span>
            <input formControlName="tracking_link" required style="height: 20px;" type="text">
        </div>

        <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
            <span class="me-2">TRACKING STATUS: </span>
            <!--      <input formControlName="tracking_status" required style="height: 20px;" type="text">-->
            <select formControlName="tracking_status" required style="height: 20px;" type="text">
                <option [value]="'preparing'">preparing</option>
                <option [value]="'shipped'">shipped</option>
                <option [value]="'en_route'">en_route</option>
                <option [value]="'in_distribution'">in_distribution</option>
                <option [value]="'delivered'">delivered</option>
            </select>
        </div>

        <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
            <span class="me-2">E.T.A.: </span>
            <input formControlName="estimated_arrival_time" required style="height: 20px;" type="text">
        </div>

        <button (click)="adminService.changeDelivery(changeDeliveryForm);" class="px-3" style="color: black">CHANGE</button>
    </form>
</div>


<div class="w-100 mt-5 px-3">
    <span class="mx-2">ADMIN DEPOSIT:</span>
    <div class="d-flex flex-column overflow-auto">
        <form [formGroup]="adminDepositForm" class="d-flex flex-column align-items-center justify-content-center py-1 row-separator"
              style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 400;">
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">USER ID: </span>
                <select formControlName="user_id" required style="height: 20px;" type="number">
                    <option *ngFor="let user of this.adminService.user_list | managerPipe" [value]="user.id" style="height: 20px;">{{user.id | managerPipe}}</option>
                </select>
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">OPERATION CLASS: </span>
                <select formControlName="operation_class" required style="height: 20px;" type="text">
                    <option value="Currency">Currency</option>
                    <option value="Art Product">Art Product</option>
                </select>
            </div>
            <div *ngIf="adminDepositForm.get('operation_class')?.value === 'Art Product'" class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">ART PRODUCT: </span>
                <select formControlName="collection_id" required style="height: 20px;" type="number">
                    <option value="-1">Select</option>
                    <option *ngFor="let art_product of this.adminService.art_product_list | managerPipe" [value]="art_product.id">{{art_product.symbol | managerPipe}}</option>
                </select>
            </div>
            <div *ngIf="adminDepositForm.get('operation_class')?.value === 'Currency'" class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">CURRENCY: </span>
                <select formControlName="collection_id" required style="height: 20px;" type="number">
                    <option value="-1">Select</option>
                    <option *ngFor="let currency of this.adminService.currency_list | managerPipe" [value]="currency.id">{{currency.symbol | managerPipe}}</option>
                </select>
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">AMOUNT: </span>
                <input formControlName="amount" required style="height: 20px;" type="number">
            </div>
            <div style="width: 100%">
                <div *ngIf="adminDepositForm.get('user_id')?.value" class="w-100 d-flex flex-row align-items-center justify-content-around" style="width: 100%;">
                    <span class="text-center"
                          style="width: 10%">{{(adminDepositForm.get('user_id')?.value | managerPipe: managerService.mpa_template.ADMIN_GET_CACHED_USER: adminService: adminService.user_list_pipe)?.id}}</span>
                    <span class="text-center"
                          style="width: 30%">{{(adminDepositForm.get('user_id')?.value | managerPipe: managerService.mpa_template.ADMIN_GET_CACHED_USER: adminService: adminService.user_list_pipe)?.email}}</span>
                    <span class="text-center"
                          style="width: 30%">{{(adminDepositForm.get('user_id')?.value | managerPipe: managerService.mpa_template.ADMIN_GET_CACHED_USER_INFO: adminService: adminService.user_info_list_pipe)?.name}}</span>
                    <span class="text-center"
                          style="width: 30%">{{(adminDepositForm.get('user_id')?.value | managerPipe: managerService.mpa_template.ADMIN_GET_CACHED_USER_INFO: adminService: adminService.user_info_list_pipe)?.surname}}</span>
                </div>
            </div>
            <button (click)="adminService.adminDeposit(adminDepositForm)" class="px-3" style="color: black">DEPOSIT</button>
        </form>
    </div>
</div>

<div class="w-100 mt-4 px-3">
    <span class="mx-2">ADMIN WITHDRAWAL:</span>
    <div class="d-flex flex-column overflow-auto">
        <form [formGroup]="adminWithdrawalForm" class="d-flex flex-column align-items-center justify-content-center py-1 row-separator"
              style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 400;">
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">USER ID: </span>
                <select formControlName="user_id" required style="height: 20px;" type="number">
                    <option *ngFor="let user of this.adminService.user_list | managerPipe" [value]="user.id">{{user.id | managerPipe}}</option>
                </select>
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">OPERATION CLASS: </span>
                <select formControlName="operation_class" required style="height: 20px;" type="text">
                    <option value="Currency">Currency</option>
                    <option value="Art Product">Art Product</option>
                </select>
            </div>
            <div *ngIf="adminWithdrawalForm.get('operation_class')?.value === 'Art Product'" class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">ART PRODUCT: </span>
                <select formControlName="collection_id" required style="height: 20px;" type="number">
                    <option value="-1">Select</option>
                    <option *ngFor="let art_product of this.adminService.art_product_list | managerPipe" [value]="art_product.id">{{art_product.symbol | managerPipe}}</option>
                </select>
            </div>
            <div *ngIf="adminWithdrawalForm.get('operation_class')?.value === 'Currency'" class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">CURRENCY: </span>
                <select formControlName="collection_id" required style="height: 20px;" type="number">
                    <option value="-1">Select</option>
                    <option *ngFor="let currency of this.adminService.currency_list | managerPipe" [value]="currency.id">{{currency.symbol | managerPipe}}</option>
                </select>
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">AMOUNT: </span>
                <input formControlName="amount" required style="height: 20px;" type="number">
            </div>
            <div style="width: 100%">
                <div *ngIf="adminWithdrawalForm.get('user_id')?.value" class="d-flex flex-row align-items-center justify-content-around" style="width: 100%;">
                    <span class="text-center"
                          style="width: 10%">{{(adminWithdrawalForm.get('user_id')?.value | managerPipe: managerService.mpa_template.ADMIN_GET_CACHED_USER: adminService: adminService.user_list_pipe)?.id}}</span>
                    <span class="text-center"
                          style="width: 30%">{{(adminWithdrawalForm.get('user_id')?.value | managerPipe: managerService.mpa_template.ADMIN_GET_CACHED_USER: adminService: adminService.user_list_pipe)?.email}}</span>
                    <span class="text-center"
                          style="width: 30%">{{(adminWithdrawalForm.get('user_id')?.value | managerPipe: managerService.mpa_template.ADMIN_GET_CACHED_USER_INFO: adminService: adminService.user_info_list_pipe)?.name}}</span>
                    <span class="text-center"
                          style="width: 30%">{{(adminWithdrawalForm.get('user_id')?.value | managerPipe: managerService.mpa_template.ADMIN_GET_CACHED_USER_INFO: adminService: adminService.user_info_list_pipe)?.surname}}</span>
                </div>
            </div>
            <button (click)="adminService.adminWithdrawal(adminWithdrawalForm)" class="px-3" style="color: black">WITHDRAWAL</button>
        </form>
    </div>
</div>

<div class="w-100 mt-5 px-3">
    <span class="mx-2">ADMIN ADD ART PRODUCT:</span>
    <div class="d-flex flex-column overflow-auto">
        <form [formGroup]="adminAddArtProductForm" class=" py-2 row-separator" style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 400;">
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">NAME: </span>
                <input formControlName="name" required style="height: 20px;" type="text">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">SYMBOL: </span>
                <input formControlName="symbol" required style="height: 20px;" type="text">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">VALUE: </span>
                <input formControlName="value" required style="height: 20px;" type="number">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">CONTRACT ADDRESS: </span>
                <input formControlName="contract_address" required style="height: 20px;" type="text">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">CIRCULATING SUPPLY RATIO: </span>
                <input formControlName="circulating_supply_ratio" required style="height: 20px;" type="number">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">PRE SALE SUPPLY RATIO: </span>
                <input formControlName="pre_sale_supply_ratio" required style="height: 20px;" type="number">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">TOTAL SUPPLY: </span>
                <input formControlName="total_supply" required style="height: 20px;" type="number">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">RIGHT TO OWN RATIO: </span>
                <input formControlName="right_to_own_ratio" required style="height: 20px;" type="number">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">COLOR: </span>
                <input formControlName="color" required style="height: 20px;" type="text">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">PRE SALE START DATE: </span>
                <input formControlName="pre_sale_start_date" required style="height: 20px;" type="number">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">PRE SALE END DATE: </span>
                <input formControlName="pre_sale_end_date" required style="height: 20px;" type="number">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">PUBLIC SALE START DATE: </span>
                <input formControlName="public_sale_start_date" required style="height: 20px;" type="text">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">PUBLIC SALE END DATE: </span>
                <input formControlName="public_sale_end_date" required style="height: 20px;" type="number">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">LISTING DATE: </span>
                <input formControlName="listing_date" required style="height: 20px;" type="number">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">FEATURED: </span>
                <input class="checkbox-field" formControlName="featured" required style="height: 20px; width: 20px;" type="checkbox">
            </div>
            <div class="mt-3 text-center">
                <button (click)="adminService.adminAddArtProduct(adminAddArtProductForm)" class="px-3" style="color: black">ADD</button>
            </div>
        </form>
    </div>
</div>


<div class="w-100 mt-5 mb-3 px-3">
    <span class="mx-2">ADMIN ADD CURRENCY:</span>
    <div class="d-flex flex-column overflow-auto">
        <form [formGroup]="adminAddCurrencyForm" class="py-2 row-separator"
              style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 400;">
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">NAME: </span>
                <input formControlName="name" required style="height: 20px;" type="text">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">SYMBOL: </span>
                <input formControlName="symbol" required style="height: 20px;" type="text">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">VALUE: </span>
                <input formControlName="value" required style="height: 20px;" type="number">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">COLOR: </span>
                <input formControlName="color" required style="height: 20px;" type="text">
            </div>
            <div class="mt-3 text-center">
                <button (click)="adminService.adminAddCurrency(adminAddCurrencyForm)" class="px-3" style="color: black">ADD</button>
            </div>
        </form>
    </div>
</div>

<div class="w-100 mt-5 px-3">
    <span class="mx-2">ADMIN ADD AUCTION PRODUCT:</span>
    <div class="d-flex flex-column overflow-auto">
        <form [formGroup]="adminAddAuctionProductForm" class=" py-2 row-separator" style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 400;">
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">NAME: </span>
                <input formControlName="name" required style="height: 20px;" type="text">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">YEAR: </span>
                <input formControlName="year" required style="height: 20px;" type="number">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">CATALOG: </span>
                <input formControlName="catalog" required style="height: 20px;" type="text">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">LOT: </span>
                <input formControlName="lot" required style="height: 20px;" type="number">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">OPENING VALUE: </span>
                <input formControlName="opening_value" required style="height: 20px;" type="number">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">CURRENT VALUE: </span>
                <input formControlName="current_value" required style="height: 20px;" type="number">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">VALUE STEP: </span>
                <input formControlName="value_step" required style="height: 20px;" type="number">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">VALUE TYPE: </span>
                <input formControlName="value_type" required style="height: 20px;" type="text">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">AUCTION START DATE: </span>
                <input formControlName="auction_start_date" required style="height: 20px;" type="number">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">AUCTION END DATE: </span>
                <input formControlName="auction_end_date" required style="height: 20px;" type="number">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">AUCTION INIT END DATE: </span>
                <input formControlName="auction_init_end_date" required style="height: 20px;" type="number">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">END DATE INCREMENT: </span>
                <input formControlName="end_date_increment" required style="height: 20px;" type="number">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">FEATURED: </span>
                <input class="checkbox-field" formControlName="featured" required style="height: 20px; width: 20px;" type="checkbox">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">ASSURANCE: </span>
                <input formControlName="assurance" required style="height: 20px;" type="number">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">BUYER FEE: </span>
                <input formControlName="buyer_fee" required style="height: 20px;" type="text">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">SELLER FEE: </span>
                <input formControlName="seller_fee" required style="height: 20px;" type="number">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="text-center m-1">TAX: </span>
                <input formControlName="tax" required style="height: 20px;" type="number">
            </div>
            <div class="mt-3 text-center">
                <button (click)="adminService.adminAddAuctionProduct(adminAddAuctionProductForm)" class="px-3" style="color: black">ADD</button>
            </div>
        </form>
    </div>
</div>

<div class="p-3">
    <span class="mx-1">PAIRS</span>
    <div class="d-flex flex-row align-items-center justify-content-around py-1" style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500;">
        <span class="text-center" style="width: 10%">STATUS</span>
        <span class="text-center" style="width: 10%">ID</span>
        <span class="text-center" style="width: 20%">SYMBOL</span>
        <span class="text-center" style="width: 25%">UNBLOCK</span>
        <span class="text-center" style="width: 25%">BLOCK</span>

    </div>
    <div class="d-flex flex-column overflow-auto" style="min-height: 260px; max-height: 260px;">
        <div *ngFor="let pair of (adminService.pair_list | managerPipe)"
             class="d-flex flex-row align-items-center justify-content-around py-1 row-separator" style="font-family: Roboto, sans-serif; font-size: 11px;">
      <span class="text-center" style="width: 10%">
        <span [style.background-color]="adminService.getOrderBookBlocked(pair) ? '#0437F2':'#FF2400'" class="circle"></span>
      </span>
            <span class="text-center" style="width: 10%">{{pair.id | managerPipe}}</span>
            <span class="text-center" style="width: 20%">{{pair.symbol | managerPipe}}</span>
            <button (click)="adminService.blockOrderBook(pair, false)" class="text-center" style="width: 25%; color: black;">UNBLOCK</button>
            <button #request_block (click)="confirm_block.classList.remove('d-none');request_block.classList.add('d-none');" class="text-center" style="width: 25%; color: black;">
                BLOCK
            </button>
            <button #confirm_block (click)="request_block.classList.remove('d-none');confirm_block.classList.add('d-none');adminService.blockOrderBook(pair, true);"
                    class="d-none text-center fw-bold" style="width: 30%; color: black;">
                CONFIRM
            </button>
        </div>
    </div>
</div>


<div class="w-100 mt-5 px-3">
  <span class="mx-2">DELIST:</span>
  <div class="d-flex flex-column overflow-auto">
    <form [formGroup]="adminDelistForm" class="d-flex flex-column align-items-center justify-content-center py-1 row-separator"
          style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 400;">
      <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
        <span class="text-center m-1">SYMBOL: </span>
        <select #pair_select formControlName="pair" required style="height: 20px;" (change)="adminService.setActiveDelistPairBySymbol(pair_select.value);">
          <option *ngFor="let pair of this.adminService.pair_list | managerPipe" [value]="pair.symbol" style="height: 20px;">{{ pair.symbol | managerPipe }}</option>
        </select>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
        <span class="text-center m-1">ID: {{ adminService.active_delist_pair?.id }}</span>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
        <span class="text-center m-1">SALE STATE: {{ adminService.active_delist_pair?.sale_state }}</span>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
        <span class="text-center m-1">PUBLIC SALE PRICE: {{ adminService.active_delist_pair_public_sale_price }}</span>
      </div>

      <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
        <span class="text-center m-1">PRICE:</span>
        <input formControlName="price" required type="number" style="height: 20px; width: 80%;">
      </div>

      <button #request_delist (click)="confirm_delist.classList.remove('d-none');request_delist.classList.add('d-none');" class="px-3" style="color: black">DELIST</button>
      <button #confirm_delist (click)="request_delist.classList.remove('d-none');confirm_delist.classList.add('d-none');adminService.delist(adminDelistForm);" class="px-3 d-none text-center fw-bold"
              style="color: black">CONFIRM
      </button>
    </form>
  </div>
</div>

<div class="p-4">
    <span class="mx-2">CURRENCY PAIRS</span>
    <div class="d-flex flex-row align-items-center justify-content-around py-1" style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500;">
        <span class="text-center" style="width: 10%">STATUS</span>
        <span class="text-center" style="width: 10%">ID</span>
        <span class="text-center" style="width: 20%">SYMBOL</span>
        <span class="text-center" style="width: 25%">UNBLOCK</span>
        <span class="text-center" style="width: 25%">BLOCK</span>

    </div>
    <div class="d-flex flex-column overflow-auto" style="min-height: 260px; max-height: 260px;">
        <div *ngFor="let currency_pair of (adminService.currency_pair_list | managerPipe)"
             class="d-flex flex-row align-items-center justify-content-around py-1 row-separator" style="font-family: Roboto, sans-serif; font-size: 11px;">
      <span class="text-center" style="width: 10%">
        <span [style.background-color]="adminService.getSwapOrderBookBlocked(currency_pair) ? '#0437F2':'#FF2400'" class="circle"></span>
      </span>
            <span class="text-center" style="width: 10%">{{currency_pair.id | managerPipe}}</span>
            <span class="text-center" style="width: 20%">{{currency_pair.symbol | managerPipe}}</span>
            <button (click)="adminService.blockSwapOrderBook(currency_pair, false)" class="text-center" style="width: 25%; color: black;">UNBLOCK</button>
            <button #request_block (click)="confirm_block.classList.remove('d-none');request_block.classList.add('d-none');" class="text-center" style="width: 25%; color: black;">
                BLOCK
            </button>
            <button #confirm_block (click)="request_block.classList.remove('d-none');confirm_block.classList.add('d-none');adminService.blockSwapOrderBook(currency_pair, true)"
                    class="d-none text-center fw-bold" style="width: 30%; color: black;">
                CONFIRM
            </button>
        </div>
    </div>
</div>

<div class="p-4">
    <span class="mx-2">AUCTION PRODUCTS</span>
    <div class="d-flex flex-row align-items-center justify-content-around py-1" style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500;">
        <span class="text-center" style="width: 10%">STATUS</span>
        <span class="text-center" style="width: 30%"></span>
        <span class="text-center" style="width: 25%">UNBLOCK</span>
        <span class="text-center" style="width: 25%">BLOCK</span>

    </div>
    <div class="d-flex flex-column overflow-auto" style="min-height: 260px; max-height: 260px;">
        <div class="d-flex flex-row align-items-center justify-content-around py-1 row-separator" style="font-family: Roboto, sans-serif; font-size: 11px;">
      <span class="text-center" style="width: 10%">
        <span [style.background-color]="adminService.bid_engine_status ? '#0437F2':'#FF2400'" class="circle"></span>
      </span>
            <span class="text-center" style="width: 30%">ALL AUCTION PRODUCTS</span>
            <button (click)="adminService.blockBidEngine(false);" class="text-center" style="width: 25%; color: black;">UNBLOCK</button>
            <button #request_block (click)="confirm_block.classList.remove('d-none');request_block.classList.add('d-none');" class="text-center" style="width: 25%; color: black;">
                BLOCK
            </button>
            <button #confirm_block (click)="request_block.classList.remove('d-none');confirm_block.classList.add('d-none');adminService.blockBidEngine(true)"
                    class="d-none text-center fw-bold" style="width: 30%; color: black;">
                CONFIRM
            </button>
        </div>
    </div>
</div>


<div class="p-4">
    <span class="mx-2">CURRENCIES</span>
    <div class="d-flex flex-row align-items-center justify-content-around py-1" style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 500;">
        <span class="text-center" style="width: 7.5%">ID</span>
        <span class="text-center" style="width: 15%">SYMBOL</span>
        <span class="text-center" style="width: 25%">SUPPLY STOCK</span>
        <span class="text-center" style="width: 25%">PROVIDE SUPPLY</span>
        <span class="text-center" style="width: 20%"></span>

    </div>
    <div class="d-flex flex-column overflow-auto" style="min-height: 260px; max-height: 260px;">
        <div *ngFor="let currency of (adminService.currency_list | managerPipe)"
             class="d-flex flex-row align-items-center justify-content-around py-1 row-separator" style="font-family: Roboto, sans-serif; font-size: 11px;">
            <span class="text-center" style="width: 7.5%">{{currency.id | managerPipe}}</span>
            <span class="text-center" style="width: 15%">{{currency.symbol | managerPipe}}</span>
            <span class="text-center" style="width: 25%">{{currency.supply_stock | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
            <div class="d-flex flex-row align-items-center justify-content-between" style="height: 26px; width: 25%;">
                <input #supply_stock required style="height: 20px; width: 100%;" type="number">
            </div>
            <button (click)="adminService.provideSupply(currency, supply_stock.value)" class="text-center" style="width: 20%; color: black;">SUPPLY</button>
        </div>
    </div>
</div>


<div class="w-100 mt-4 px-3">
    <span class="mx-2">CHANGE USER EMAIL:</span>
    <div class="">
        <form [formGroup]="changeUserEmailForm" class="d-flex flex-column align-items-center justify-content-center py-1 row-separator"
              style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 400;">
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="me-2">USER ID: </span>
                <select formControlName="user_id" required style="height: 20px;" type="number">
                    <option *ngFor="let user of this.adminService.user_list | managerPipe" [value]="user.id" style="height: 20px;">{{user.id | managerPipe}}</option>
                </select>
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="width: 100%">
                <span class="me-2">EMAIL: </span>
                <span *ngIf="changeUserEmailForm.get('user_id')?.value | managerPipe">
          {{(changeUserEmailForm.get('user_id')?.value | managerPipe: managerService.mpa_template.ADMIN_GET_CACHED_USER: adminService: adminService.user_list_pipe)?.email | managerPipe}}
        </span>
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="me-2">OLD EMAIL: </span>
                <input formControlName="old_email" required style="height: 20px;" type="text">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="me-2">NEW EMAIL: </span>
                <input formControlName="new_email" required style="height: 20px;" type="text">
            </div>
            <button (click)="adminService.changeUserEmail(changeUserEmailForm)" class="px-3" style="color: black">CHANGE</button>
        </form>
    </div>
</div>


<div class="w-100 mt-4 px-3">
    <span class="mx-2">CHANGE USER INFO:</span>
    <div class="">
        <form [formGroup]="changeUserInfoForm" class="d-flex flex-column align-items-center justify-content-center py-1 row-separator"
              style="font-family: Roboto, sans-serif; font-size: 11px; font-weight: 400;">
            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="me-2">USER ID: </span>
                <select formControlName="user_id" required style="height: 20px;" type="number">
                    <option *ngFor="let user of this.adminService.user_list | managerPipe" [value]="user.id" style="height: 20px;">{{user.id | managerPipe}}</option>
                </select>
            </div>

            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="me-2">NAME: </span>
                <input formControlName="name" required style="height: 20px;" type="text">
            </div>

            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="me-2">SURNAME: </span>
                <input formControlName="surname" required style="height: 20px;" type="text">
            </div>

            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="me-2">TC NO: </span>
                <input formControlName="tc_no" required style="height: 20px;" type="number">
            </div>

            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="me-2">BIRTH YEAR: </span>
                <input formControlName="birth_year" required style="height: 20px;" type="number">
            </div>

            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="me-2">TOWN: </span>
                <input formControlName="town" required style="height: 20px;" type="text">
            </div>

            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="me-2">ADDRESS: </span>
                <input formControlName="address" required style="height: 20px;" type="text">
            </div>

            <div class="d-flex flex-row align-items-center justify-content-center" style="height: 26px;">
                <span class="me-2">PHONE NO: </span>
                <input formControlName="phone_number" required style="height: 20px;" type="text">
            </div>

            <button (click)="adminService.changeUserInfo(changeUserInfoForm)" class="px-3" style="color: black">CHANGE</button>
        </form>
    </div>
</div>

