import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
  selector: 'app-balance-mobile',
  templateUrl: './balance-mobile.component.html',
  styleUrls: ['./balance-mobile.component.css']
})
export class BalanceMobileComponent implements OnInit {

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }
}
