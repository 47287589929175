import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
  selector: 'app-balance-desktop',
  templateUrl: './balance-desktop.component.html',
  styleUrls: ['./balance-desktop.component.css']
})
export class BalanceDesktopComponent implements OnInit {

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

}
