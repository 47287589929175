<div class="w-80 mx-auto pt-4">
  <div class="pb-4" style="font-family: Roboto, sans-serif; font-size: 20px; color: var(--artiox-blue)">
    {{managerService.config.CORPORATE.CONTACT.HEADING | managerPipe}}
  </div>
  <div class="d-flex pb-3">
    <svg class="mr-2" style="height: 20px; width: 20px;">
      <use [attr.xlink:href]='"./assets/images/mail.svg#mail"' fill="var(--artiox-blue)"/>
    </svg>
    <div [innerHTML]="managerService.config.CORPORATE.CONTACT.MAIL | managerPipe"
         style="font-family: Roboto, sans-serif; font-size: 13px; color: #707070;">
    </div>
  </div>
  <div class="d-flex">
    <svg class="mr-2" style="height: 20px; width: 20px;">
      <use [attr.xlink:href]='"./assets/images/address.svg#address"' fill="var(--artiox-blue)"/>
    </svg>
    <div [innerHTML]="managerService.config.CORPORATE.CONTACT.ADDRESS | managerPipe"
         style="font-family: Roboto, sans-serif; font-size: 13px; color: #707070;">
    </div>
  </div>
</div>
