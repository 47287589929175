<div  class="d-flex  position-relative w-100 h-100 px-4" style="overflow: hidden">
    <svg  style="width: 162px; height: auto; overflow: hidden; right: -81px; top: 16px; position: absolute">
        <use [attr.xlink:href]='"./assets/images/artiox_icon.svg#artiox_icon"' fill="var(--main-color-artiox-purple-100)"/>
    </svg>
<div *ngIf="!loading_animation" class="d-flex flex-column justify-content-center  h-100" style="gap: 32px">




    <div class="d-flex flex-column gap-2">
    <span class="mb-2 header3 medium exo" style="color: var(--text-color-artiox-black) ">{{managerService.config.ACCOUNT_VERIFICATION.HEADER | managerPipe}}</span>
    <span class="caption regular barlow " style="color: var(--text-color-artiox-grey); "  [innerHTML]=" [emailBold()] | managerPipe: managerService.mpa_template.EVALUATE_STRING:  managerService.config.ACCOUNT_VERIFICATION.FIRST_INFO "></span>
    <span class="caption regular barlow " style="color: var(--text-color-artiox-grey); "  [innerHTML]="managerService.config.ACCOUNT_VERIFICATION.SECOND_INFO"></span>
    </div>
    <div class="d-flex form-group focused" >
        <label class=" d-flex form-label caption barlow medium" for="verification">
            {{managerService.config.ACCOUNT_VERIFICATION.INPUT_HEADER | managerPipe}}</label>
        <input #verification_input class="w-100 form-input body2 barlow regular" [formControl]="verificationControl" id="verification"  type="text"  placeholder="{{managerService.config.ACCOUNT_VERIFICATION.INPUT_PLACEHOLDER}}" oninput="this.value=this.value.replace(/\D+/,'')">
        <div #verification_error  class="d-flex d-none flex-row" style="gap: 8px; margin-top: 12px" >
            <svg style=" height: 16px; width: 16px; ">
                <use [attr.xlink:href]='"./assets/images/error_icon.svg#error_icon"' fill="#EA3943"/>
            </svg>
            <span class="caption barlow regular"  style="color: var(--secondary-colors-artiox-red-base)"></span>
        </div>
    </div>
    <div class="d-flex w-100 justify-content-center">
                <span (click)="resendEmail()" class="caption regular barlow clickable_text" style="text-decoration: underline"
                      [ngStyle]="{color: isTextClickable ? 'var(--main-color-artiox-purple-base' : 'var(--secondary-colors-artiox-gray-500)', cursor: isTextClickable? 'pointer': 'default'}">
                    Iletiyi yeniden gonder <span *ngIf="!isTextClickable">({{countdown}}sn)</span>
                </span>
    </div>
    <div class="w-100  d-flex justify-content-center py-2 login-button" (click)="sendVerification()" id="submit-button">
        <span class="body2 exo " style="color:#FFFFFF; font-weight: 600">{{managerService.config.ACCOUNT_VERIFICATION.BUTTON_TEXT | managerPipe}}</span>
    </div>
</div>

<div *ngIf="loading_animation" class="d-flex flex-row h-100 w-100 align-items-center justify-content-center">
    <svg  style="width: 162px; height: auto; overflow: hidden; right: -81px; top: 16px; position: absolute">
        <use [attr.xlink:href]='"./assets/images/artiox_icon.svg#artiox_icon"' fill="var(--main-color-artiox-purple-100)"/>
    </svg>
    <div class="d-flex  align-items-center justify-content-center p-3">
        <div class="position-absolute d-flex flex-column align-items-center justify-content-center">
            <svg class="loader" style="height:100px; width:100px;">
                <use [attr.xlink:href]='"./assets/images/artiox_icon.svg#artiox_icon"' class="h-100 w-100" fill="#8266f0"/>
            </svg>
        </div>
    </div>
</div>
</div>