import {Component, Input, ViewChild} from '@angular/core';
import {AppModule} from '../../../../../app.module';
import {AsyncPipe, NgForOf, NgIf} from '@angular/common';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
  selector: 'app-ethereum-withdrawal-request-mobile',
  templateUrl: './ethereum-withdrawal-request-mobile.component.html',
  styleUrl: './ethereum-withdrawal-request-mobile.component.css'
})
export class EthereumWithdrawalRequestMobileComponent {
  @ViewChild('checkbox_field_desktop') checkbox_field_desktop: any = undefined;

  @Input() modal_id: string = '';

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

  onAccept(): void {
    this.managerService.operationsService.sign_withdrawal_request_data.status = 'approve';
    this.managerService.operationsService.crypto_withdrawal_rules_accepted_status = true;
    this.managerService.closeEthereumWithdrawalRequestModal();
    this.checkbox_field_desktop.checked = false;
    this.managerService.operationsService.cryptocurrency_withdrawal_request_form_submitted.emit();
    this.managerService.marketingService.logEvent('withdrawal_rules_approved', {symbol: 'ETH'});
  }
}
