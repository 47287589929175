import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ManagerService} from '../../../../../../../services/manager.service';
import {HttpClient} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';

@Component({
    selector: 'app-limit-trade-desktop',
    templateUrl: './limit-trade-desktop.component.html',
    styleUrls: ['./limit-trade-desktop.component.css']
})
export class LimitTradeDesktopComponent implements OnInit, AfterViewInit, OnDestroy {

    limitBuyForm = new UntypedFormGroup({
        price: new UntypedFormControl(null, [Validators.required, Validators.min(0)]),
        amount: new UntypedFormControl(null, [Validators.required, Validators.min(0)]),
    });

    limitSellForm = new UntypedFormGroup({
        price: new UntypedFormControl(null, [Validators.required, Validators.min(0)]),
        amount: new UntypedFormControl(null, [Validators.required, Validators.min(0)]),
    });

    public limit_buy_total: number | null = null;
    public limit_sell_total: number | null = null;

    public previous_pair_id: [number, number] = [-1, -1];

    public subscription_list: (Subscription | undefined)[] = [];

    constructor(public managerService: ManagerService, public httpClient: HttpClient) {
        if (this.managerService.orderBookService.getBuyOrderBook().length && this.managerService.orderBookService.getSellOrderBook().length) {
            this.limitSellForm.get('price')?.setValue(this.managerService.preciseRound(this.managerService.orderBookService.getBuyOrderBook()[0].price));
            this.limitBuyForm.get('price')?.setValue(this.managerService.preciseRound(this.managerService.orderBookService.getSellOrderBook()[0].price));
        }

        this.subscription_list.push(this.managerService.pairService.active_pair_changed.subscribe(() => {
            if (this.previous_pair_id[0] === this.managerService.pairService.active_pair?.id[0]
                && this.previous_pair_id[1] === this.managerService.pairService.active_pair?.id[1]) {
                return;
            }
            this.previous_pair_id = this.managerService.pairService.active_pair?.id ?? [-1, -1];

            this.subscription_list.push(this.managerService.orderBookService.order_book_changed.pipe(take(1)).subscribe(() => {
                this.limitBuyForm.get('amount')?.setValue(null);
                this.limitSellForm.get('amount')?.setValue(null);
                if (this.managerService.orderBookService.getBuyOrderBook().length && this.managerService.orderBookService.getSellOrderBook().length) {
                    this.limitSellForm.get('price')?.setValue(this.managerService.preciseRound(this.managerService.orderBookService.getBuyOrderBook()[0].price));
                    this.limitBuyForm.get('price')?.setValue(this.managerService.preciseRound(this.managerService.orderBookService.getSellOrderBook()[0].price));
                }
            }));
        }));

        this.subscription_list.push(this.managerService.set_form_price.subscribe((price) => {
            this.limitBuyForm.get('price')?.setValue(price);
            this.limitSellForm.get('price')?.setValue(price);
        }));
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.subscription_list.push(this.limitBuyForm.get('price')?.valueChanges.subscribe(() => {
            if (!(this.limitBuyForm.get('price')?.value ?? 0).toString().match(new RegExp('^[0-9]*(\\.[0-9]{0,2})?$'))) {
                this.limitBuyForm.get('price')?.setValue(this.managerService.preciseRound(this.limitBuyForm.get('price')?.value));
                return;
            }

            if (this.managerService.userService.user_ready) {
                if (this.limitBuyForm.get('amount')?.value === null) {
                    this.limit_buy_total = null;
                    return;
                } else {
                    this.limit_buy_total = this.managerService.preciseRound(this.limitBuyForm.get('price')?.value * this.limitBuyForm.get('amount')?.value);
                }
                const limit_buy_amount_check = this.managerService.checkLimitBuyAmount(this.limitBuyForm.get('price')?.value, this.limitBuyForm.get('amount')?.value);
                if (this.limitBuyForm.get('amount')?.value !== limit_buy_amount_check) {
                    this.limitBuyForm.get('amount')?.setValue(limit_buy_amount_check);
                }
            }
        }));

        this.subscription_list.push(this.limitSellForm.get('price')?.valueChanges.subscribe(() => {
            if (!(this.limitSellForm.get('price')?.value ?? 0).toString().match(new RegExp('^[0-9]*(\\.[0-9]{0,2})?$'))) {
                this.limitSellForm.get('price')?.setValue(this.managerService.preciseRound(this.limitSellForm.get('price')?.value));
                return;
            }

            if (this.managerService.userService.user_ready) {
                if (this.limitSellForm.get('amount')?.value === null) {
                    this.limit_sell_total = null;
                    return;
                } else {
                    this.limit_sell_total = this.managerService.preciseRound(this.limitSellForm.get('price')?.value * this.limitSellForm.get('amount')?.value);
                }
                const limit_sell_amount_check = this.managerService.checkLimitSellAmount(this.limitSellForm.get('price')?.value, this.limitSellForm.get('amount')?.value);
                if (this.limitSellForm.get('amount')?.value !== limit_sell_amount_check) {
                    this.limitSellForm.get('amount')?.setValue(limit_sell_amount_check);
                }
            }
        }));

        this.subscription_list.push(this.limitBuyForm.get('amount')?.valueChanges.subscribe(() => {
            if (!(this.limitBuyForm.get('amount')?.value ?? 0).toString().match(new RegExp('^[0-9]*(\\.[0-9]{0,2})?$'))) {
                this.limitBuyForm.get('amount')?.setValue(this.managerService.preciseRound(this.limitBuyForm.get('amount')?.value));
                return;
            }

            if (this.managerService.userService.user_ready) {
                if (this.limitBuyForm.get('amount')?.value === null) {
                    this.limit_buy_total = null;
                    return;
                } else {
                    this.limit_buy_total = this.managerService.preciseRound(this.limitBuyForm.get('price')?.value * this.limitBuyForm.get('amount')?.value);
                }
                const limit_buy_amount_check = this.managerService.checkLimitBuyAmount(this.limitBuyForm.get('price')?.value, this.limitBuyForm.get('amount')?.value);
                if (this.limitBuyForm.get('amount')?.value !== limit_buy_amount_check) {
                    this.limitBuyForm.get('amount')?.setValue(limit_buy_amount_check);
                }
            }
        }));

        this.subscription_list.push(this.limitSellForm.get('amount')?.valueChanges.subscribe(() => {
            if (!(this.limitSellForm.get('amount')?.value ?? 0).toString().match(new RegExp('^[0-9]*(\\.[0-9]{0,2})?$'))) {
                this.limitSellForm.get('amount')?.setValue(this.managerService.preciseRound(this.limitSellForm.get('amount')?.value));
                return;
            }

            if (this.managerService.userService.user_ready) {
                if (this.limitSellForm.get('amount')?.value === null) {
                    this.limit_sell_total = null;
                    return;
                } else {
                    this.limit_sell_total = this.managerService.preciseRound(this.limitSellForm.get('price')?.value * this.limitSellForm.get('amount')?.value);
                }
                const limit_sell_amount_check = this.managerService.checkLimitSellAmount(this.limitSellForm.get('price')?.value, this.limitSellForm.get('amount')?.value);
                if (this.limitSellForm.get('amount')?.value !== limit_sell_amount_check) {
                    this.limitSellForm.get('amount')?.setValue(limit_sell_amount_check);
                }
            }
        }));
    }

    limitBuy(): void {
        if (!this.managerService.userService.user_ready) {
            // @ts-ignore
            const content = this.managerService.config.RESPONSE_TABLE['2001'];
            this.managerService.popSnackBar(content.is_okay, content.message);
            return;
        }

        if (this.limitBuyForm.invalid) {
            return;
        }

        const pair: any = this.managerService.pairService.active_pair;
        const amount: any = this.limitBuyForm.get('amount')?.value;
        const price: any = this.limitBuyForm.get('price')?.value;

        const data = {
            pair_id: pair?.id,
            order_type: 'buy',
            amount,
            price,
        };

        if (!this.managerService.utilityService.requestCriticServicePermission('limit_buy_service', data)){
            this.managerService.popResponseSnackbar({code: 2019, is_okay: false, message: 'Service not allowed in dev mode.'});
            return;
        }

        this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'limit_buy_service', data).subscribe(
            response => {
                // @ts-ignore
                const content = this.managerService.config.RESPONSE_TABLE[response.code];
                this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));

                if (content.is_okay) {
                    // this.managerService.eventLogger('limit_buy');
                    this.managerService.marketingService.logEvent('trade', {type: 'buy', kind: 'limit', view: 'advanced', symbol: pair?.symbol, amount, volume: amount * price});
                }
            }));
        this.limitBuyForm.get('amount')?.setValue(null);

    }

    limitSell(): void {
        if (!this.managerService.userService.user_ready) {
            // @ts-ignore
            const content = this.managerService.config.RESPONSE_TABLE['2001'];
            this.managerService.popSnackBar(content.is_okay, content.message);
            return;
        }

        if (this.limitSellForm.invalid) {
            return;
        }

        const pair: any = this.managerService.pairService.active_pair;
        const amount: any = this.limitSellForm.get('amount')?.value;
        const price: any = this.limitSellForm.get('price')?.value;

        const data = {
            pair_id: pair?.id,
            order_type: 'sell',
            amount,
            price,
        };

        if (!this.managerService.utilityService.requestCriticServicePermission('limit_sell_service', data)){
            this.managerService.popResponseSnackbar({code: 2019, is_okay: false, message: 'Service not allowed in dev mode.'});
            return;
        }

        this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'limit_sell_service', data).subscribe(
            response => {
                // @ts-ignore
                const content = this.managerService.config.RESPONSE_TABLE[response.code];
                this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));

                if (content.is_okay) {
                    // this.managerService.eventLogger('limit_sell');
                    this.managerService.marketingService.logEvent('trade', {type: 'sell', kind: 'limit', view: 'advanced', symbol: pair?.symbol, amount, volume: amount * price});
                }
            }));
        this.limitSellForm.get('amount')?.setValue(null);
    }

    ngOnDestroy(): void {
        this.subscription_list.forEach((subscription) => {
            subscription?.unsubscribe();
        });
    }
}
