<div *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP" style="max-width: 600px;">
  <div class="p-5 w-100 d-flex flex-column align-items-start justify-content-center">
    <div class="d-flex w-100 justify-content-end">
      <svg style="margin-top: -16px; margin-right: -8px; height: 18px; width: 18px;" (click)="managerService.closeModal(modal_id)">
        <use [attr.xlink:href]='"./assets/images/exit_icon.svg#exit_icon"' fill="#404352"/>
      </svg>
    </div>
    <div class="p-3"
         style="font-family: bebas-neue-regular, sans-serif;font-size: 24px; font-weight: bold; line-height: 1.2; letter-spacing: 2.5px;  color: #404352;">
      {{managerService.config.DEPOSIT.RULES_TITLE | managerPipe}}
    </div>
    <div class="p-2" style="font-family: Roboto, sans-serif; font-size: 17px; font-style: italic; line-height: 1.1; letter-spacing: 0.4px; color: #4a4e70;">
      <div *ngFor="let rule of managerService.config.DEPOSIT.FIAT_DEPOSIT_RULES | managerPipe" class="mb-4">
        {{rule | managerPipe}}
      </div>
    </div>
    <label class="p-2 d-flex flex-row align-items-start justify-content-start checkbox-text-black">
      <input #checkbox_field_desktop class="checkbox-field" style="height: 20px; min-width: 20px;" type="checkbox">
      <span class="mx-2 my-1">{{ managerService.config.BALANCE.READ_ACCEPTED | managerPipe}}</span>
    </label>
    <button (click)="onAccept()"
            [class.button-inactive]="!checkbox_field_desktop.checked" [disabled]="!checkbox_field_desktop.checked"
            class="btn-native checkbox-button">
      {{managerService.config.BALANCE.NEXT | managerPipe}}
    </button>
  </div>
</div>
