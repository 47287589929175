<div class="w-100 d-flex flex-column align-items-center justify-content-start">
  <div class="w-75 d-flex flex-row my-5">
    <canvas #user_balance_chart_canvas style="z-index: 1">
    </canvas>
    <div *ngIf="managerService.userService.user_ready | managerPipe" class="position-absolute d-flex flex-column align-items-center justify-content-center  barlow subtitle1  medium" style="height: 225px; width: 225px;">
      <div>{{managerService.config.ADVANCED.USER_BALANCE.TOTAL_BALANCE | managerPipe}}</div>
      <div>{{user_balance_total_value | managerPipe}}</div>
    </div>
    <div *ngIf="!managerService.logged_in | managerPipe" class="position-absolute d-flex flex-column align-items-center justify-content-center" style="height: 225px; width: 225px; z-index: 2;">
      <div class="font-weight-bold" style="font-size: 14px;">{{managerService.config.SIGN_UP_OR_LOGIN.HEADING_BALANCE}}</div>
      <div><a (click)="managerService.navigationManager({url: '/sign-up', current_component_index: 0})" class="text-highlight text-info">{{managerService.config.SIGN_UP_OR_LOGIN.SIGN_UP}}</a>
        {{managerService.config.SIGN_UP_OR_LOGIN.OR}}
        <a (click)="managerService.openModal('login-modal')" class="text-highlight text-info">{{managerService.config.SIGN_UP_OR_LOGIN.LOGIN}}</a></div>
    </div>
  </div>
  <div *ngIf="managerService.userService.user_ready | managerPipe" class="w-100 d-flex flex-row align-items-center justify-content-center my-2 barlow caption bold">
    <span class="my-1 text-right" style="width: 20%;  line-height: 1;  color: #404352;">
    </span>
    <span class="my-1 text-right" style="width: 40%;  line-height: 1;  color: #404352;">
      {{managerService.config.ADVANCED.USER_BALANCE.AVAILABLE | managerPipe}}
    </span>
    <span class="pr-2 my-1 text-right" style="width: 40%;  line-height: 1; color: #404352;">
      {{managerService.config.ADVANCED.USER_BALANCE.TOTAL | managerPipe}}
    </span>
  </div>
  <div class="w-100 overflow-auto" style="height: 210px;">
    <div *ngFor="let user_asset of (managerService.user_pipe | managerPipe: managerService.mpa_template.GET_USER_ASSETS: managerService.pair_pipe)"
         class="w-100 d-flex flex-column align-items-center justify-content-center py-2 user-asset">
      <div class="w-100 d-flex flex-row align-items-start justify-content-end barlow caption regular">
        <span [style]="user_asset | managerPipe: managerService.mpa_template.GET_USER_ASSETS_COLOR" style="height: 18px; width: 18px; border-radius: 2px;"></span>
        <span class="ms-1 my-1 text-left" style="width: 10%;  line-height: 1;  color: #404352;">
          {{user_asset | managerPipe: managerService.mpa_template.GET_USER_ASSETS_SYMBOL}}
        </span>
        <span class="py-1 text-right" style="width: 40%;  line-height: 1; color: #404352;">
          {{user_asset.available_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
        </span>
        <span class="pr-2 my-1 text-right"
              style="width: 40%; line-height: 1; color: #404352;">
          {{user_asset.total_amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
        </span>
      </div>
    </div>
  </div>
</div>

