<div class="w-75 d-flex flex-row align-items-start justify-content-center m-auto">
  <form
    [class]="(managerService.active_trade_type_panel | managerPipe: managerService.mpa_template.ACTIVE_TRADE_PANEL_SELL) ? 'd-flex' : 'd-none'"
    [formGroup]="marketSellForm" class="flex-column"
    style="width: 42.5%">
    <h2
      [innerHTML]="[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.BASIC.TRADE.AMOUNT_TO_SELL_DESKTOP"
      class="text-center exo body2 bold"
      style=" color: #404352;">
    </h2>
    <label>
      <input class="w-100 input-field-white" formControlName="amount" min="0.00" placeholder="0"
             required type="number">
      <span class="body2 barlow regular" style=" text-align: center; color: #9d9fa6;">
        {{[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.BASIC.TRADE.AVAILABLE_AMOUNT}}
        {{managerService.pair_pipe | managerPipe: managerService.mpa_template.USER_ACTIVE_ART_PRODUCT_AVAILABLE_AMOUNT: managerService.user_pipe}}
      </span>
    </label>
  </form>
  <form
    [class]="(managerService.active_trade_type_panel | managerPipe: managerService.mpa_template.ACTIVE_TRADE_PANEL_BUY) ? 'd-flex' : 'd-none'"
    [formGroup]="marketBuyForm" class="flex-column"
    style="width: 42.5%">
    <h2
      class="text-center exo body2 bold"
      style=" color: #404352;">
      {{[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.BASIC.TRADE.AMOUNT_TO_BUY_DESKTOP}}
    </h2>
    <label>
      <input class="w-100 input-field-white" formControlName="amount" min="0.00" placeholder="0"
             required type="number">
      <span class="body2 barlow regular" style=" text-align: center; color: #9d9fa6;">
        {{[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_CURRENCY_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.BASIC.TRADE.AVAILABLE_AMOUNT}}
        {{managerService.pair_pipe | managerPipe: managerService.mpa_template.USER_ACTIVE_CURRENCY_AVAILABLE_AMOUNT: managerService.user_pipe}}
      </span>
    </label>
  </form>
  <div class="d-flex flex-column align-items-center justify-content-end" style="width: 15%;">
    <span class="pt-1 barlow caption bold"
          style=" color: #404352;">
      {{managerService.config.BASIC.TRADE.SWAP | managerPipe}}
    </span>
    <div class="d-flex flex-row align-items-center justify-content-center">
      <svg (click)="switch()"
           [style]="(managerService.active_trade_type_panel | managerPipe: managerService.mpa_template.ACTIVE_TRADE_PANEL_SELL) ? {transform: 'rotateZ(180deg)'} : {transform: 'rotateZ(0deg)'}"
           id="switch"
           style="height: 40px; width: 40px; transition: 0.4s"
           type="button">
        <use [attr.xlink:href]='"./assets/images/basic_icon.svg#basic_icon"' fill="#cfd6d8"/>
      </svg>
      <div class="d-flex flex-row align-items-center justify-content-center">
        <app-tooltip [direction]="managerService.tooltipService.tooltip_direction_template.RIGHT | managerPipe" class="d-none position-relative" id="switch-button-tooltip"
                     max_width="300px">
          {{managerService.config.TOOLTIP.BASIC.SWITCH_BUTTON_TOOLTIP | managerPipe}}
        </app-tooltip>
      </div>
    </div>
  </div>

  <div
    [class]="(managerService.active_trade_type_panel | managerPipe: managerService.mpa_template.ACTIVE_TRADE_PANEL_SELL) ? 'd-flex' : 'd-none'"
    class="flex-column"
    style="width: 42.5%">
    <h2
      [innerHTML]="[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_CURRENCY_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.BASIC.TRADE.AVERAGE_COST_DESKTOP"
      class="text-center exo body2 bold"
      style=" color: #404352;">
    </h2>
    <label>
      <input class="w-100 input-field-white" disabled min="0.00" placeholder="0" readonly
             type="number"
             value="{{market_sell_average_cost | managerPipe}}">
    </label>
  </div>
  <div
    [class]="(managerService.active_trade_type_panel | managerPipe: managerService.mpa_template.ACTIVE_TRADE_PANEL_BUY) ? 'd-flex' : 'd-none'"
    class="flex-column"
    style="width: 42.5%">
    <h2
      [innerHTML]="[managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_CURRENCY_SYMBOL] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.BASIC.TRADE.AVERAGE_COST_DESKTOP"
      class="text-center exo body2 bold"
      style=" color: #404352;">
    </h2>
    <label>
      <input class="w-100 input-field-white" disabled min="0.00" placeholder="0" readonly
             type="number"
             value="{{market_buy_average_cost | managerPipe}}">
    </label>
  </div>
</div>

<button (click)="marketBuy()"
        [class]="(managerService.active_trade_type_panel | managerPipe: managerService.mpa_template.ACTIVE_TRADE_PANEL_BUY) ? 'd-block' : 'd-none'"
        class="w-75 btn-native buy-button my-2 mx-auto p-3 exo subtitle1 bold">
  {{managerService.config.BASIC.TRADE.BUY | managerPipe}}
</button>

<button (click)="marketSell()"
        [class]="(managerService.active_trade_type_panel | managerPipe: managerService.mpa_template.ACTIVE_TRADE_PANEL_SELL) ? 'd-block' : 'd-none'"
        class="w-75 btn-native sell-button my-2 mx-auto p-3 exo subtitle1 bold">
  {{managerService.config.BASIC.TRADE.SELL | managerPipe}}
</button>
