<div class="w-80 mx-auto pt-4">
  <div class="w-100 d-flex flex-row">
    <div>
      <svg style="height: 48px; width: 48px;">
        <use [attr.xlink:href]='"./assets/images/artiox_icon.svg#artiox_icon"' fill="var(--artiox-blue)"/>
      </svg>
    </div>
    <div class="pl-3">
      <div class="pb-1" style="font-family: Roboto, sans-serif; font-size: 20px; color: var(--artiox-blue)">
        {{managerService.config.CORPORATE.ABOUT.HEADING | managerPipe}}
      </div>
      <div [innerHTML]="managerService.config.CORPORATE.ABOUT.DESCRIPTION | managerPipe"
           style="font-family: Roboto, sans-serif; font-size: 13px; color: #707070;">
      </div>
    </div>
  </div>
</div>
