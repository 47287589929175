import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../services/manager.service';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.css']
})
export class BalanceComponent implements OnInit {

  constructor(public managerService: ManagerService) {
    this.managerService.active_balance_panel = this.managerService.active_balance_panel_template.SUMMARY;
  }

  ngOnInit(): void {
  }

}
