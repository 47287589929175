import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ManagerService} from '../../../../../../../services/manager.service';
import {Chart} from 'chart.js';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-user-balance-desktop',
  templateUrl: './user-balance-desktop.component.html',
  styleUrls: ['./user-balance-desktop.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UserBalanceDesktopComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('user_balance_chart_canvas') user_balance_chart_canvas: any;
  public user_balance_chart: any = undefined;
  public user_balance_chart_init: boolean = false;
  public user_balance_total_value: string = '';

  public values: any = [];
  public colors: any = [];
  public labels: any = [];

  public subscription_list: Subscription[] = [];

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.constructUserBalanceChart();
    this.resolveChart();
    this.subscription_list.push(this.managerService.userService.user_balance_pie_chart_changed.subscribe(() => {
      this.resolveChart();
    }));
    this.subscription_list.push(this.managerService.pairService.pair_list_changed.subscribe(() => {
      this.resolveChart();
    }));
    this.subscription_list.push(this.managerService.pairService.currency_pair_list_changed.subscribe(() => {
      this.resolveChart();
    }));
  }

  resolveChart(): void {
    if (!this.user_balance_chart_init) {
      if (this.managerService.prepareUserBalancePieChartData(this.user_balance_chart_canvas.nativeElement.getContext('2d'), this.values, this.colors, this.labels)) {
        // @ts-ignore
        this.user_balance_total_value = this.managerService.fancyNumber(this.values.reduce((a, b) => a + b, 0));
        this.user_balance_chart.data.labels = this.labels;
        this.user_balance_chart.data.datasets[0].data = this.values;
        this.user_balance_chart.data.datasets[0].hoverBorderColor = this.colors;
        this.user_balance_chart.data.datasets[0].backgroundColor = this.colors;
        this.user_balance_chart.update();
        this.user_balance_chart_init = true;
      }
    } else {
      this.updateChart();
    }
  }

  updateChart(): void {
    if (this.managerService.prepareUserBalancePieChartValues(this.values)) {
      // @ts-ignore
      this.user_balance_total_value = this.managerService.fancyNumber(this.values.reduce((a, b) => a + b, 0));
      this.user_balance_chart.data.datasets[0].data = this.values;
      this.user_balance_chart.update(0);
    }
  }

  constructUserBalanceChart(): void {
    if (this.user_balance_chart === undefined) {
      this.user_balance_chart = new Chart(this.user_balance_chart_canvas.nativeElement.getContext('2d'), {
        type: 'doughnut',
        data: {
          labels: [],
          datasets: [{
            hoverBorderColor: [],
            hoverBorderWidth: 1,
            backgroundColor: [],
            data: [],
          }]
        },
        options: {
          aspectRatio: 1,
          cutoutPercentage: 85,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            callbacks: {
              title: (tooltipItem, data) => {
                // @ts-ignore
                return data.labels[tooltipItem[0].index];
              },
              label: (tooltipItem, data) => {
                // @ts-ignore
                return '~' + this.managerService.fancyNumber(data.datasets[0].data[tooltipItem.index]).toString() + ' ' + this.managerService.pairService.root_currency.symbol;
              },
              afterLabel: (tooltipItem, data) => {
                // @ts-ignore
                return (this.managerService.fancyNumber(data.datasets[0].data[tooltipItem.index] * 100 / this.user_balance_chart.data.datasets[0].data.reduce((a, b) => a + b, 0))).toString() + '%';
              }
            },
            backgroundColor: '#141414',
            titleFontFamily: 'Roboto',
            titleFontStyle: 'bold',
            titleFontSize: 12,
            titleFontColor: '#f2f3f9',
            bodyFontFamily: 'Roboto',
            bodyFontStyle: 'normal',
            bodyFontSize: 12,
            bodyFontColor: '#f2f3f9',
            displayColors: false
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription_list.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
