<div class="w-100 px-3 pb-3" style="min-height: 500px; margin-bottom: 60px">
  <div class="pt-4"
       style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 500; color: #4a4e70;">{{managerService.config.ANNOUNCEMENTS.HEADERS.OPERATION_ANNOUNCEMENTS | managerPipe}}</div>
  <div *ngFor="let operation_announcement of (managerService.config.ANNOUNCEMENTS.OPERATION_ANNOUNCEMENTS.OPERATION_LIST | managerPipe); let i=index;"
       class="w-100 my-2" #operation_announcement>
    <div *ngIf="operation_announcement.RELEASE_DATE | managerPipe: managerService.mpa_template.CHECK_TIME_ARRIVED | async">
      <div (click)="this.managerService.toggleText(i);"
           class="position-relative d-flex flex-row justify-content-between align-items-center"
           style="height: 41px; font-family: Roboto, sans-serif; border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; background-color: #ffffff;">
        <div class="px-2" style="font-size: 12px; color: #9da5b7;">{{operation_announcement.TOPIC | managerPipe}}</div>
        <div class="px-2" style="font-size: 11px; color: var(--artiox-blue)">{{managerService.config.CORPORATE.CAREER.INSPECT| managerPipe}}</div>
      </div>
      <div [class.d-none]="(!operation_announcement.TOGGLE) | managerPipe"
           class="job-advertisement-description px-2 pb-2"
           style="border-radius: 4px; box-shadow: 1px 1px 1px 0 #dbdbdf; margin-top: -20px; padding-top: 24px; background-color: #ffffff;">
        <div [innerHTML]="operation_announcement.TEXT_MOBILE | managerPipe"></div>
        <a *ngIf="operation_announcement.ROUTER_LINK.LINK.url | managerPipe"
           class="link-button" (click)="managerService.navigateIfLoggedIn(operation_announcement.ROUTER_LINK.LINK)">
          {{operation_announcement.ROUTER_LINK.TEXT | managerPipe}}
        </a>
      </div>
    </div>

  </div>
</div>
