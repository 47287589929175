<h1 style="padding-top: 4pt;padding-left: 63pt;text-indent: 0pt;text-align: center;">Artiox Kullanıcı Sözleşmesi</h1>
<p style="text-indent: 0pt;text-align: left;"><br/></p>
<ol>
    <p style="text-indent: 0pt;text-align: left;"><br/></p>
    <li>
        <h2 style="padding-left: 4px;text-indent: -11pt;text-align: left;">Taraflar ve Kapsam</h2>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <p class="s1"
           style="padding-top: 8pt;padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">
            <span
                    style=" color: black; font-family:Montserrat, serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt;">İşbu Artiox Kullanıcı Sözleşmesi (bundan sonra Sözleşme olarak anılacaktır) Artiox Teknoloji Yazılım
                A.Ş. (bundan sonra Artiox olarak anılacaktır) ile Platform kanalıyla Token sahibi olan tüm Kullanıcılar arasında,
                Platform aracılığıyla sahip oldukları Tokenlere ve Sözleşmede açıklanan diğer hizmetlere yönelik hak ve yükümlülüklerini belirlemek amacıyla düzenlenmiştir. </span>
        </p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
    <li>
        <h2 style="padding-left: 4px;text-indent: -13pt;text-align: left;">Tanımlar</h2>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <table style="border-collapse:collapse;margin-left:6.18105pt">
            <tr style="height:71pt">
                <td style="width:109pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p style="text-indent: 0pt;text-align: left;"><br/></p>
                    <p class="s2" style="padding-left: 4pt;padding-right: 4pt;text-indent: 0pt;text-align: center;">
                        Artiox</p></td>
                <td style="width:341pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s3"
                       style="padding-top: 5pt;padding-left: 4pt;padding-right: 4pt;text-indent: 0pt;line-height: 112%;text-align: justify;">
                        Kızılırmak Mah. Dumlupınar Bul. A Blok No: 9a İç Kapı No: 287 Çankaya / Ankara adresinde bulunan
                        Artiox Teknoloji Yazılım A.Ş.’yi ifade eder. </p>
                </td>
            </tr>
            <tr style="height:51pt">
                <td style="width:109pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p style="text-indent: 0pt;text-align: left;"><br/></p>
                    <p class="s2" style="padding-left: 4pt;padding-right: 4pt;text-indent: 0pt;text-align: center;">
                        Artiox Sanat
                        Eseri</p></td>
                <td style="width:341pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s3" style="padding-top: 5pt;padding-left: 4pt;text-indent: 0pt;text-align: left;">
                        İşbu Sözleşme hükümleri kapsamında Artiox tarafından
                        Tokenize edilen resim, heykel, film, enstalasyon vb. Güzel
                        Sanat Eserlerini ifade eder.</p></td>
            </tr>
            <tr style="height:79pt">
                <td style="width:109pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s4"
                       style="padding-top: 4pt;padding-left: 4pt;padding-right: 4pt;text-indent: 0pt;text-align: center;">
                        Güzel Sanat
                        Eseri</p></td>
                <td style="width:341pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s3"
                       style="padding-top: 5pt;padding-left: 4pt;padding-right: 5pt;text-indent: 0pt;text-align: justify;">
                        5846 sayılı Fikir ve Sanat Eserleri Kanunu’nun dördüncü
                        maddesinde tanımlanan eserleri ifade eder.</p></td>
            </tr>
            <tr style="height:51pt">
                <td style="width:109pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s2"
                       style="padding-top: 5pt;padding-left: 4pt;padding-right: 4pt;text-indent: 0pt;text-align: center;">
                        İnternet Sitesi</p></td>
                <td style="width:341pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s3"
                       style="padding-top: 5pt;padding-left: 4pt;padding-right: 5pt;text-indent: 0pt;text-align: justify;">
                        <a href="www.artiox.com">www.artiox.com</a> alan adından veya bu alan adına bağlı alt
                        alan adlarından oluşan Artiox’un internet sitesini ifade eder. </p></td>
            </tr>
            <tr style="height:65pt">
                <td style="width:109pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s2"
                       style="padding-top: 5pt;padding-left: 4pt;padding-right: 4pt;text-indent: 0pt;text-align: center;">
                        Mobil Uygulama</p></td>
                <td style="width:341pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s3"
                       style="padding-top: 5pt;padding-left: 4pt;padding-right: 5pt;text-indent: 0pt;text-align: justify;">
                        <a href="www.artiox.com">www.artiox.com</a> alan adı üzerinden paylaşılan ve Artiox
                        tarafından yayınlanan mobil uygulamaları ifade eder. </p></td>
            </tr>
            <tr style="height:65pt">
                <td style="width:109pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s2"
                       style="padding-top: 5pt;padding-left: 4pt;padding-right: 4pt;text-indent: 0pt;text-align: center;">
                        Platform</p></td>
                <td style="width:341pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s3"
                       style="padding-top: 5pt;padding-left: 4pt;padding-right: 5pt;text-indent: 0pt;text-align: justify;">
                        <a href="www.artiox.com">www.artiox.com</a>
                        alan adı ve Artiox mobil uygulamaları üzerinde çalışan;
                        Token alım satımı, üyelik ve para yatırma çekme gibi işlemlerin yapıldığı elektronik sistemlerin
                        bütününü ifade eder.</p></td>
            </tr>
            <tr style="height:71pt">
                <td style="width:109pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s2"
                       style="padding-top: 5pt;padding-left: 3pt;padding-right: 4pt;text-indent: 0pt;line-height: 112%;text-align: center;">
                        Kripto varlık</p></td>
                <td style="width:341pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s3"
                       style="padding-top: 5pt;padding-left: 4pt;padding-right: 5pt;text-indent: 0pt;text-align: justify;">
                        Dağıtık defter teknolojisi veya benzer bir teknoloji kullanılarak sanal olarak oluşturulup
                        dijital ağlar üzerinden dağıtımı yapılan gayri maddi varlıkları ifade eder.</p></td>
            </tr>
            <tr style="height:71pt">
                <td style="width:109pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s2"
                       style="padding-top: 5pt;padding-left: 3pt;padding-right: 4pt;text-indent: 0pt;line-height: 112%;text-align: center;">
                        Kullanıcı</p></td>
                <td style="width:341pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s3"
                       style="padding-top: 5pt;padding-left: 4pt;padding-right: 5pt;text-indent: 0pt;text-align: justify;">
                        Artiox Kullanıcı Sözleşmelersi’ni onaylayıp üye olan ve Platformda sunulan hizmetlerden
                        faydalanan gerçek veya tüzel kişiyi ifade eder.</p></td>
            </tr>
            <tr style="height:71pt">
                <td style="width:109pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s2"
                       style="padding-top: 5pt;padding-left: 3pt;padding-right: 4pt;text-indent: 0pt;line-height: 112%;text-align: center;">
                        Token </p></td>
                <td style="width:341pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s3"
                       style="padding-top: 5pt;padding-left: 4pt;padding-right: 5pt;text-indent: 0pt;text-align: justify;">
                        Artiox Sanat Eserleri üzerinde birim pay sahipliğini temsil eden kripto varlıklardır.</p></td>
            </tr>
            <tr style="height:71pt">
                <td style="width:109pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s2"
                       style="padding-top: 5pt;padding-left: 3pt;padding-right: 4pt;text-indent: 0pt;line-height: 112%;text-align: center;">
                        Tokenizasyon
                        (Tokenize edilmek) </p></td>
                <td style="width:341pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                    <p class="s3"
                       style="padding-top: 5pt;padding-left: 4pt;padding-right: 5pt;text-indent: 0pt;text-align: justify;">
                        Blok zincir sistemi üzerinde uzman sanat eksperlerinin belirlediği fiyatlar üzerinden sanat
                        eserlerinin sigortalanan miktarına karşılık gelen Token değerlerin oluşturulması işlemidir.</p>
                </td>
            </tr>
        </table>
    <li>
        <h2 style="padding-top: 10pt;padding-left: 4px;text-indent: -13pt;text-align: justify;">Tokenizasyon ve Artiox
            Sanat Eserlerine Dair Genel Hak ve Yükümlülükler </h2>
        <ol id="l2">
            <li><p
                    style="padding-top: 8pt;padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">
                Artiox, Tokenler vasıtasıyla Artiox Sanat Eserleri paylarının alım satımının yapılabileceği bir
                Platformdur. Platform nezdinde, Kullanıcılar birbirlerine Artiox Sanat Eserleri üzerindeki hisselerini
                paylı mülkiyet esaslarına göre alır veya satarlar.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p
                    style="padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">AArtiox, Tokenize
                edilmek için kendisine başvurulan Güzel Sanat Eserlerini; alanında uzman eksperlerin belirlediği
                fiyatları üzerinden sigortalayarak, sigortalanan değerine karşılık blok zincir üzerinde tokenize
                eder. </p></li>
            <!--                <p style="text-indent: 0pt;text-align: left;"><br/></p>-->
            <li><p
                    style="padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Artiox, sigorta
                değeri üzerinden Tokenize edilen sanat eserinin; eksper raporu veya sertifika belgesi, sigorta poliçesi
                ve ilgili Artiox Sanat Eserinin akıllı sözleşme teknolojisi ile sabitlenmiş toplam Token arz adedi ile
                arz edilen Token’ın ilgili Artiox Sanat Eserinin hangi oranda sahipliğini temsil ettiği bilgilerinin
                Platform üzerinde sürekli olarak yayınlanmasını taahhüt eder.</p>
            </li>
            <!--            <p style="text-indent: 0pt;text-align: left;"><br/></p>-->


            <li><p
                    style="padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Artiox, Artiox
                Sanat Eserleri’nin sigorta ve bakım masraflarını karşılar ve bakımı, korunması ve güvenli bir ortamda
                saklanması ile ilgili olağan ve önemli işlerin yerine getirilmesini temin eder. Ayrıca, sorumluluğu
                kendine ait olmak üzere çeşitli müze, galeri, sergi ve fuarlarda eseri sergileyebilir. Artiox, söz
                konusu işlemler ve işbu Sözleşme kapsamındaki diğer yükümlülükleri için Madde 10’da belirtilenlerden
                başka herhangi bir ücret talep etmeyecektir. Token sahipleri Artiox’un tokenize edilmiş Sanat
                Eserlerinin olağan ve önemli yönetim işlerini tüm token sahipleri adına yerine getirmesine ve Artiox’un
                kendi takdiriyle Artiox Sanat Eserinin müze, galeri, sergi ve fuarlarda sergilenmesine işbu madde ile
                kabul ve muvafakat ederler.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p
                    style="padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Token sahibi
                Kullanıcılar ayrıca başta Madde 6 Müzayede ve Satış bölümü olmak üzere işbu Sözleşme ile Artiox’u
                yetkilendirdikleri çerçevede Artiox’un tek başına olağanüstü yönetim işleri ve tasarruflarda bulunmaya
                yetkili olduğunu kabul, beyan ve taahhüt ederler.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p
                    style="padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Kullanıcılar,
                işbu Sözleşme kapsamında sahip oldukları Tokenlerin yalnızca maddi bir eşya olarak Artiox Sanat
                Eserlerinde pay sahipliğini temsil ettiğini ve 5846 sayılı Fikir ve Sanat Eserleri Kanunu ve sair
                mevzuat kapsamında fikri mülkiyet haklarını veya ruhsat veya yetkileri üzerinde bir pay sahipliğini
                ihtiva veya temsil etmediğini kabul ve beyan ederler.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p>

                <p style="text-indent: 0pt;text-align: left;"><br/></p>
        </ol>
    </li>
    <li><h2 style="padding-left: 4px;text-indent: -10pt;text-align: left;">Genel Satış (Arz)</h2>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <ol id="l5">
            <li><p
                    style="padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Tokenize edilmiş
                sanat eserlerinin Tokenleri münhasıran Artiox’un takdiriyle belirlenen zaman ve usul ile genel satışa
                açılır.</p></li>
            <li><p
                    style="padding-top: 3pt;padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">
                Genel satışta ilk gelen ilk alır usulü uygulanır ve kronolojik olarak daha önce girilmiş alım emirleri
                daha sonraki emirlerden önce gerçekleştirilir. Bir alım emrinin tamamını karşılayacak Token arzı
                kalmaması durumunda ilgili emir kısmi olarak gerçekleştirilir ve arzda kalan miktar Token ilgili alıcıya
                tahsis edilir.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p
                    style="padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Genel satışta
                alınan Tokenler, ilgili Token Alım Satıma açılana kadar sahibi Kullanıcıya ait olur ve herhangi bir
                şekilde satılamaz.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
        </ol>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
    </li>
    <li><h2 style="padding-left: 4px;text-indent: -14pt;text-align: left;">Alım Satım</h2>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <ol id="l6">
            <li>
                <p style="padding-top: 6pt;padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">
                    Genel satış sürecinin biterek Tokenlerin Platformda listelenmesinin ardından Kullanıcılar dilediği
                    miktarlarda Platform Kullanım Koşulları çerçevesinde alım ya da satım işlemleri yapabilir.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p
                    style="padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">
                Kullanıcı alım ve satım işlemlerine dair “Limit” veya “Piyasa” emirleri girebilir.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p
                    style="padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">
                “Limit” tip emirde Kullanıcı Tokenin alım veya satım işleminin gerçekleşmesini teklif ettiği fiyatı
                belirler ve Limit emir Kullanıcının emiri ile eşleşen karşıt alım ve satım emirinin ilgili Artiox Sanat
                Eseri tahtasında yeterli miktarda mevcut olması halinde gerçekleştirilir. İlgili Artiox Sanat Eseri
                tahtasında Kullanıcının girmiş olduğu Limit emirden daha avantajlı bir karşıt fiyat emri mevcut olması
                halinde, Kullanıcının dezavantajlı fiyattan işleminin gerçekleşmesinden Artiox sorumlu olmayacaktır.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p
                    style="padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">
                Piyasa” tip emirde ise, Kullanıcının alım veya satım emri ilgili Artiox Sanat Eseri tahtasında mevcut en
                avantajlı karşı emirler ile Kullanıcının alım veya satım emri girdiği Token adedi kadar işlem
                gerçekleştirilene kadar devam eşleştirilerek gerçekleştirilir.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p
                    style="padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">
                Kullanıcı, Platform nezdinde satın almış olduğu Tokenleri, yine bu Platform üzerinde ve yalnızca işbu
                sözleşmeyi ve Platform Kullanım Koşullarını önceden kabul etmiş kişilere satmayı; Artiox ise Platforma
                yalnızca Kullanım Koşullarını kabul etmiş Kullanıcıların erişim sağlayacağını ve Platform içerisinde
                yalnızca bu Sözleşmeyi kabul etmiş Kullanıcıların Token almasına izin verileceğini gayrikabili rücu
                kabul beyan ve taahhüt eder. </p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
        </ol>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
    </li>
    <li>
        <h2 style="padding-left: 4px;text-indent: -14pt;text-align: left;">Müzayede ve Satış</h2>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <ol id="l9">
            <li><p
                    style="padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Platformda işlem
                görmekte olan Tokenlar bakımından aşağıda belirtilen üç şarttan herhangi birinin gerçekleşmesi ile
                Artiox, Tokenlerin temsil ettiği ilgili Artiox Sanat Eserini satışa çıkarabilir. Satış doğrudan
                pazarlık, bir müzayedede açık artırma usulü gibi çeşitli yöntemlerle gerçekleştirilebilir; satış yöntemi
                münhasıran Artiox’un kendi takdiri ile belirlenir.</p>
                <ol>
                    <li><p
                            style="padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">İlgili
                        Artiox Sanat Eserinin genel satışa çıktığı tarihten itibaren asgari bir yıl geçmiş olması</p>
                    </li>
                    <li><p
                            style="padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Artiox
                        Sanat Eserinin Türk lirası piyasa değerinin, genel satış değerinin iki katına çıkmış olması</p>
                    </li>
                    <li><p
                            style="padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Herhangi
                        bir zaman veya koşulda münhasıran Artiox’un kendi takdiri ile başlatılacak bir elektronik oylama
                        ile oluşacak salt çoğunluğun, oylama ile teklif edilen satış fiyat ve koşulları üzerinden satışı
                        kabul etmesi; elektronik oylama Artiox tarafından Platform üzerinden ve asgari bir hafta önceden
                        tüm Token sahiplerine bildirimde bulunularak başlatılır ve oylama için en az bir hafta süre
                        verilir. Her bir Tokenin bir adet oy hakkı bulunur. Oylama sonucunda satış kararı alınmadığı
                        sürece Artiox oylama sonucunu Kullanıcılar veya Token sahipleri ile paylaşmak zorunda değildir.
                    </p></li>

                </ol>
            </li>
            <li><p
                    style="padding-top: 3pt;padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">
                Yukarıda belirtilen şartların herhangi birinin gerçekleşmesi halinde Artiox, ilgili Artiox Sanat
                Eserinin tamamını üçüncü bir kişiye satıp devredebilecektir. İşbu madde çerçevesinde yapılan satış,
                eserin sahipliğini oluşturan tüm mülkiyetin ve zilyetliğin toptan bir şekilde üçüncü kişiye satışını ve
                devrini ifade etmektedir. </p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p
                    style="padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Müzayede,
                Artiox’un münhasıran kendi takdirine bağlı olarak elektronik biçimde Platform üzerinde veya Güzel Sanat
                Eserleri galerileri, müzayede evi ya da müze gibi kurumlarda canlı olarak yapılabilir. Her halde satış
                değeri, Artiox Sanat Eserinin Tokenizasyon aşamasında belirlenen sigorta değerinden az olamaz. </p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p
                    style="padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Satışın
                gerçekleşmesinden sonra satış masrafları, Madde 7.2 kapsamında Artiox’un alacağı ve 5846 sayılı Fikir ve
                Sanat Eserleri Kanunu’nun 45’incı maddesi uyarınca eser sahibi veya mirasçılarına yapılması zorunlu
                ödemeler düşüldükten sonra ortaya çıkan net satış bedeli, arz edilmiş toplam Token adedine bölünür ve
                Token başına düşen satış bedeli hesaplanır. İlgili satış bedeli, Artiox tarafından gecikmeksizin Token
                sahiplerinin Artiox hesabına Türk Lirası bakiye olarak yansıtılır; eş zamanlı olarak ilgili Token sahibi
                Kullanıcıların hesabında olan satışa konu Tokenler alıcının talebine bağlı olarak alıcıya aktarılır veya
                Artiox tarafından ilgili blok zincirde yakılarak yok edilir.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li><p
                    style="padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Artiox
                faaliyetlerinin tamamı veya bir bölümü ile ilgili zorlayıcı veya Platformun faaliyetlerini kısmen veya
                tamamen durdurma, kısıtlama veya izne tabi tutma etkisine sahip yasal hükümlerin, mevzuatın veya adli
                bir karar veya idari bir işlemin yürürlüğe girmesi halinde veya Artiox Sanat Eserine dair bir herhangi
                mahkeme kararları, satışa zorlama vs. hallerinde veya Artiox’un herhangi biçim veya neden ile tasfiye
                süresine girmesi vb. hallerde, Artiox, Artiox Sanat Eserlerini üçüncü kişilere doğrudan satış, müzayede,
                pazarlık yöntemi vb. usuller ile satma ve devretme hakkını haizdir; ve böyle bir satış halinde
                Sözleşmenin 6.4. maddesi kapsamında ilgili bedeller hesaplanarak hak sahiplerine dağıtımı
                yapılacaktır.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
        </ol>
    </li>
    <li>
        <h2 style="padding-left: 4px;text-indent: -10pt;text-align: left;">Ücretlendirme</h2>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <ol id="l10">
            <li><p
                    style="padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Artiox, Token
                genel satış ve alım satım işlemlerinde Kullanıcılarından, Kullanım Şartları çerçevesinde belirlenen
                oranlar üzerinden komisyon alma hakkına sahiptir. Artiox bu oranları Platform üzerinde ilan eder ve
                oranlar üzerinde değişiklik yapma hakkı saklıdır.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
            </li>
            <li><p
                    style="padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Artiox, Madde 6
                kapsamında yapılan Artiox Sanat Eseri satışlarında, satış fiyatı üzerinden belirli bir oranda komisyon
                alma hakkına sahiptir. Komisyon oranları her bir satışa çıkarma özelinde önceden Artiox tarafından
                belirlenecek ve Platform’da ilan edilecektir. </p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
            </li>
            <li><p
                    style="padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Kullanıcılar,
                Artiox’un işbu Sözleşme ve Kullanım Şartları uyarınca hak kazanacağı ücretler düşüldükten sonra kalan
                miktar ile genel satış, alım satım veya Madde 6 kapsamında yapılacak satış emirlerinin
                gerçekleştirileceğini peşinen kabul ve beyan ederler.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
            </li>

        </ol>
    </li>
    <li>
        <h2 style="padding-left: 4px;text-indent: -14pt;text-align: justify;">Uygulanacak Hukuk ve Yetki</h2>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <ol>


            <li><p
                    style="padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Artiox internet
                sitesinin kullanımından ve yapılan işlemlerden doğan uyuşmazlıklarda işbu Kullanım Sözleşmesi ve Türk
                Hukuku esas olup, Ankara Mahkemeleri yetkilidir.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
            </li>
            <li><p
                    style="padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">İşbu Sözleşmenin
                uygulanmasından doğan veya doğacak durumlar için yapılacak olan ihbar, tebligat ve diğer bildirimler,
                Kullanıcı tarafından Artiox’a bildirilen elektronik posta adresine, elektronik posta gönderilmesi
                suretiyle yapılacaktır. Kullanıcı elektronik posta adresinde yaşanan değişiklikleri derhal Artiox’a
                bildirmekle yükümlüdür; aksi durumda değişmiş adrese yapılan bildirimlerden Artiox hiçbir surette
                sorumlu olmayacaktır.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
            </li>
            <li><p
                    style="padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">İşbu Sözleşmede
                hüküm bulunmayan hallerde önce Artiox Kullanım Şartları uygulanır; burada da düzenlenmeyen bir hal veya
                durum mevcut ise kanun hükümleri uygulanır.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
            </li>
        </ol>
    <li>
        <h2 style="padding-left: 4px;text-indent: -14pt;text-align: justify;">Yürürlük ve Kabul</h2></li>
    <p style="padding-top: 8pt;padding-left: 11pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Taraflar,
        işbu hususları hem Sözleşme yürürlüğü ve hükümlerini, Sözleşmeyi kabul ettikleri tarihte sahibi oldukları hem de
        sonradan sahibi olacakları Tokenler bakımından Türk Medeni Kanunu’nun ilgili hükümleri uyarınca gayrikabili rücu
        kabul beyan ve taahhüt eder. </p>
    <p style="text-indent: 0pt;text-align: left;"><br/></p>
</ol>
