import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-change-password-desktop',
  templateUrl: './change-password-desktop.component.html',
  styleUrls: ['./change-password-desktop.component.css']
})
export class ChangePasswordDesktopComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('info_old_password') info_old_password: any = undefined;
  @ViewChild('warning_old_password') warning_old_password: any = undefined;
  @ViewChild('info_new_password') info_new_password: any = undefined;
  @ViewChild('warning_new_password') warning_new_password: any = undefined;
  @ViewChild('info_new_password_control') info_new_password_control: any = undefined;
  @ViewChild('warning_new_password_control') warning_new_password_control: any = undefined;

  public old_password_again_visible: boolean = false;
  public new_password_visible: boolean = false;
  public new_password_control_visible: boolean = false;

  public input_fields: any = undefined;

  changePasswordForm = new UntypedFormGroup({
    old_password: new UntypedFormControl('', [Validators.required]),
    new_password: new UntypedFormControl('', [Validators.required, Validators.pattern('.*[!@#$%^&*(),.?":{}|<>_+=/;€£\\[\\]-]+.*$'), Validators.pattern('.*[a-z]+.*$'), Validators.pattern('.*[A-Z]+.*$'), Validators.pattern('.*[0-9]+.*$'), Validators.minLength(8), Validators.maxLength(50)]),
    new_password_control: new UntypedFormControl('', [Validators.required]),
  });

  public subscription_list: (Subscription | undefined)[] = [];

  constructor(public managerService: ManagerService, private httpClient: HttpClient, public elementRef: ElementRef) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.input_fields = this.elementRef.nativeElement.getElementsByClassName('input-field-underline');
    this.subscription_list.push(this.changePasswordForm.get('old_password')?.valueChanges.subscribe(() => {
      this.input_fields[0].classList.remove('input-field-underline-required');
      this.info_old_password.nativeElement.classList.remove('d-none');
      this.warning_old_password.nativeElement.classList.add('d-none');

      const errors = this.changePasswordForm.get('old_password')?.errors;
      if (errors) {
        if (errors?.required) {
          // @ts-ignore
          this.warning_old_password.nativeElement.innerHTML = this.managerService.config.RESPONSE_TABLE['2003'].message;
          this.info_old_password.nativeElement.classList.add('d-none');
          this.warning_old_password.nativeElement.classList.remove('d-none');
          this.input_fields[0].classList.add('input-field-underline-required');
        }
      }
    }));
    this.subscription_list.push(this.changePasswordForm.get('new_password')?.valueChanges.subscribe(() => {
      this.info_new_password.nativeElement.classList.remove('d-none');
      this.warning_new_password.nativeElement.classList.add('d-none');
      this.input_fields[1].classList.remove('input-field-underline-required');

      const errors = this.changePasswordForm.get('new_password')?.errors;
      if (errors) {
        if (errors?.required) {
          // @ts-ignore
          this.warning_new_password.nativeElement.innerHTML = this.managerService.config.RESPONSE_TABLE['2003'].message;
        } else {
          // @ts-ignore
          this.warning_new_password.nativeElement.innerHTML = this.managerService.config.RESPONSE_TABLE['2004'].message;
        }
        this.info_new_password.nativeElement.classList.add('d-none');
        this.warning_new_password.nativeElement.classList.remove('d-none');
        this.input_fields[1].classList.add('input-field-underline-required');
      }

      if (this.changePasswordForm.get('new_password_control')?.value.length && this.changePasswordForm.get('new_password')?.value.length
        && this.changePasswordForm.get('new_password_control')?.value !== this.changePasswordForm.get('new_password')?.value) {
        // @ts-ignore
        this.warning_new_password_control.nativeElement.innerHTML = this.managerService.config.RESPONSE_TABLE['2002'].message;
        this.info_new_password_control.nativeElement.classList.add('d-none');
        this.warning_new_password_control.nativeElement.classList.remove('d-none');
        this.input_fields[2].classList.add('input-field-underline-required');
      } else {
        this.info_new_password_control.nativeElement.classList.remove('d-none');
        this.warning_new_password_control.nativeElement.classList.add('d-none');
        this.input_fields[2].classList.remove('input-field-underline-required');
      }
    }));
    this.subscription_list.push(this.changePasswordForm.get('new_password_control')?.valueChanges.subscribe(() => {
      this.info_new_password_control.nativeElement.classList.remove('d-none');
      this.warning_new_password_control.nativeElement.classList.add('d-none');
      this.input_fields[2].classList.remove('input-field-underline-required');

      const errors = this.changePasswordForm.get('new_password_control')?.errors;
      if (errors) {
        if (errors?.required) {
          // @ts-ignore
          this.warning_new_password_control.nativeElement.innerHTML = this.managerService.config.RESPONSE_TABLE['2003'].message;
        }
        this.info_new_password_control.nativeElement.classList.add('d-none');
        this.warning_new_password_control.nativeElement.classList.remove('d-none');
        this.input_fields[2].classList.add('input-field-underline-required');
      }

      if (this.changePasswordForm.get('new_password_control')?.value.length && this.changePasswordForm.get('new_password')?.value.length
        && this.changePasswordForm.get('new_password_control')?.value !== this.changePasswordForm.get('new_password')?.value) {
        // @ts-ignore
        this.warning_new_password_control.nativeElement.innerHTML = this.managerService.config.RESPONSE_TABLE['2002'].message;
        this.info_new_password_control.nativeElement.classList.add('d-none');
        this.warning_new_password_control.nativeElement.classList.remove('d-none');
        this.input_fields[2].classList.add('input-field-underline-required');
      }
    }));
  }


  clearChangePasswordForm(): void {
    for (const input_field of this.input_fields) {
      input_field.classList.remove('input-field-required');
    }
    this.changePasswordForm.reset({old_password: '', new_password: '', new_password_control: ''});
    this.warning_old_password.nativeElement.classList.add('d-none');
    this.warning_new_password.nativeElement.classList.add('d-none');
    this.warning_new_password_control.nativeElement.classList.add('d-none');

    this.input_fields[0].classList.remove('input-field-underline-required');
    this.input_fields[1].classList.remove('input-field-underline-required');
    this.input_fields[2].classList.remove('input-field-underline-required');
  }

  changePasswordValidator(): void {
    for (const input_field of this.input_fields) {
      input_field.classList.remove('input-field-underline-required');
    }
    this.warning_old_password.nativeElement.classList.add('d-none');
    this.warning_new_password.nativeElement.classList.add('d-none');
    this.warning_new_password_control.nativeElement.classList.add('d-none');
    if (this.changePasswordForm.invalid) {
      if (this.changePasswordForm.get('warning_old_password')?.errors || this.changePasswordForm.get('new_password')?.errors || this.changePasswordForm.get('new_password_control')?.errors) {
        if (this.changePasswordForm.get('new_password')?.errors?.pattern) {
          this.input_fields[1].classList.add('input-field-underline-required');
          // @ts-ignore
          this.warning_new_password.nativeElement.innerHTML = this.managerService.config.RESPONSE_TABLE['2004'].message;
          this.warning_new_password.nativeElement.classList.remove('d-none');
          // @ts-ignore
          const content = this.managerService.config.RESPONSE_TABLE['2004'];
          this.managerService.popSnackBar(content.is_okay, content.message);
        } else {
          for (const input_field of this.input_fields) {
            input_field.classList.add('input-field-underline-required');
          }
          // @ts-ignore
          const content = this.managerService.config.RESPONSE_TABLE['2003'];
          this.managerService.popSnackBar(content.is_okay, content.message);
        }
      }
    } else {
      if (this.changePasswordForm.get('new_password')?.value !== this.changePasswordForm.get('new_password_control')?.value) {
        this.input_fields[1].classList.add('input-field-underline-required');
        // @ts-ignore
        this.warning_new_password.nativeElement.innerHTML = this.managerService.config.RESPONSE_TABLE['2002'].message;
        this.warning_new_password.nativeElement.classList.remove('d-none');
        this.input_fields[2].classList.add('input-field-underline-required');
        // @ts-ignore
        this.warning_new_password_control.nativeElement.innerHTML = this.managerService.config.RESPONSE_TABLE['2002'].message;
        this.warning_new_password_control.nativeElement.classList.remove('d-none');
        // @ts-ignore
        const content = this.managerService.config.RESPONSE_TABLE['2002'];
        this.managerService.popSnackBar(content.is_okay, content.message);
      } else {
        this.changePassword();
      }
    }
  }

  changePassword(): void {
    const data = {
      old_password: this.changePasswordForm.value.old_password,
      new_password: this.changePasswordForm.value.new_password,
      client_info: this.managerService.client_info
    };

    this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'change_user_password_service', data).subscribe(
      response => {
        if (response.is_okay) {
          this.clearChangePasswordForm();
        } else {
          if (response.code === 228) {
            this.input_fields[0].classList.add('input-field-underline-required');
            this.warning_old_password.nativeElement.innerHTML = this.managerService.codeHandler(response);
            this.warning_old_password.nativeElement.classList.remove('d-none');
          }
        }
        // @ts-ignore
        const content = this.managerService.config.RESPONSE_TABLE[response.code];
        this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));

        this.info_old_password.nativeElement.classList.add('d-none');
        this.warning_old_password.nativeElement.classList.add('d-none');
        if (content.is_okay) {
          this.info_old_password.nativeElement.classList.innerHTML = content.message;
          this.info_old_password.nativeElement.classList.remove('d-none');
        } else {
          this.warning_old_password.nativeElement.classList.innerHTML = content.message;
          this.warning_old_password.nativeElement.classList.remove('d-none');
        }
      }));
  }


  ngOnDestroy(): void {
    this.subscription_list.forEach((subscription) => {
      subscription?.unsubscribe();
    });
  }
}
