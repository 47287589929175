import {Component, Input, OnInit} from '@angular/core';
import {ManagerService} from "../../../../../services/manager.service";

@Component({
  selector: 'app-account-level-desktop',
  templateUrl: './account-level-desktop.component.html',
  styleUrls: ['./account-level-desktop.component.css']
})
export class AccountLevelDesktopComponent implements OnInit {
  @Input() modal_id: string = '';
  constructor(public managerService: ManagerService) { }

  ngOnInit(): void {
  }
}
