<div class="vw-100 min-vw-100 d-flex flex-column align-items-center justify-content-center">
  <div class="d-flex flex-row align-self-center justify-content-center">
    <span class="me-1" style="font-size: 18px; height: 14px;">
      <svg (click)="managerService.art_product_info_display_status=true"
           style="height: 14px; width: 14px;">
        <use [attr.xlink:href]='"./assets/images/info.svg#info"' fill="black"/>
      </svg>
    </span>
    <img *ngIf="managerService.pairService.active_pair_ready" (click)="managerService.photoSwipeOpen(managerService.pairService.active_pair)" alt="art_product"
         class="p-1 me-2" #art_product
         src="{{(managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_IMAGE_MOBILE) | managerPipe: managerService.mpa_template.LOCAL_IMAGE_MANAGER_TO_BE}}"
         style="max-height: 240px; max-width: 90%;" (error)="art_product.src = art_product.src.replace('.webp', '.jpg')">
  </div>
  <span class="d-flex flex-row align-items-center justify-content-center" style="line-height: 36px;">
    <p class="mx-1"
       style="font-family: Roboto, sans-serif; font-size: 14px; color: #404352;">{{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_ARTIST_NAME}}</p>
    <p [class.d-none]="(managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_ARTIST_BORN_DEATH) | managerPipe: managerService.mpa_template.CHECK_LENGTH_SHORTER_THAN : 4"
       style="font-family: Roboto, sans-serif; font-size: 14px; color: #404352;">
      ({{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_ARTIST_BORN_DEATH}})
    </p>
  </span>
</div>
