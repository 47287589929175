import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
    selector: 'app-auction-duration-rules-desktop',
    templateUrl: './auction-duration-rules-desktop.component.html',
    styleUrls: ['./auction-duration-rules-desktop.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AuctionDurationRulesDesktopComponent implements OnInit {
    @Input() modal_id: string = '';

    constructor(public managerService: ManagerService) {
    }

    ngOnInit(): void {
    }

}
