import { Component, OnInit } from '@angular/core';
import {ManagerService} from '../../../services/manager.service';
export enum LoginStep {
  BASE,
  PUZZLE,
  TWO_FACTOR
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(protected managerService: ManagerService) { }

  ngOnInit(): void {
  }

}
