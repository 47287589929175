<!--<div *ngIf="!managerService.config.ZENDESK_ACTIVE | managerPipe">-->
<!--  <link rel="stylesheet" href="footer-desktop.component.css">-->
<!--  <div class="w-100 d-flex align-items-center justify-content-center"-->
<!--       style=" height: 64px; border-top: 1px solid rgba(192, 196, 218, 0.7); border-bottom: 1px solid rgba(192, 196, 218, 0.7); background-color: rgba(41,42,49,1);">-->
<!--    <svg (click)="twitter()" class="mx-4" style="height:24px; width: 24px;" type="button">-->
<!--      <use [attr.xlink:href]='"./assets/images/twitter.svg#twitter"' class="w-100" fill="white"/>-->
<!--    </svg>-->
<!--    <svg (click)="instagram()" class="mx-4" style="height:24px; width: 24px;" type="button">-->
<!--      <use [attr.xlink:href]='"./assets/images/instagram.svg#instagram"' class="w-100" fill="white"/>-->
<!--    </svg>-->
<!--    <svg (click)="telegram()" class="mx-4" style="height:24px; width: 24px;" type="button">-->
<!--      <use [attr.xlink:href]='"./assets/images/telegram.svg#telegram"' class="w-100" fill="white"/>-->
<!--    </svg>-->
<!--    <svg (click)="linkedin()" class="mx-4" style="height:24px; width: 24px;" type="button">-->
<!--      <use [attr.xlink:href]='"./assets/images/linkedin.svg#linkedin"' class="w-100" fill="white"/>-->
<!--    </svg>-->
<!--  </div>-->

<!--  <div class="w-100 d-flex align-items-start justify-content-center p-4" style="background-color: rgba(41,42,49,1);">-->
<!--    <div class="mx-5">-->
<!--      <svg class="mr-3" style="height:64px; width: 150px; min-width: 150px; margin-bottom: 8px;">-->
<!--        <use [attr.xlink:href]='"./assets/images/artiox_logo.svg#artiox_logo"' class="w-100" fill="white"/>-->
<!--      </svg>-->
<!--      <p [innerHTML]="managerService.config.FOOTER.ADDRESS | managerPipe"-->
<!--         style="color: #c0c4da; font-family: 'Roboto Condensed', sans-serif; font-size: 14px;"></p>-->
<!--    </div>-->
<!--    <div class="mx-5">-->
<!--      <p [innerHTML]="managerService.config.FOOTER.CORPORATE_HEADER | managerPipe" class="h5 d-flex align-items-center"-->
<!--         style="height:64px; color: white; font-family: 'Roboto Condensed', sans-serif;"></p>-->
<!--      <p *ngFor="let item of managerService.config.FOOTER.CORPORATE_BODY | managerPipe"-->
<!--         style="color: #c0c4da; font-family: 'Roboto Condensed', sans-serif; font-size: 14px; margin-bottom: 4px;" type="button">-->
<!--        <a [class.a-color]="!managerService.environment.app" (click)="managerService.navigationManager(item.LINK);">{{item.TEXT | managerPipe}}</a>-->
<!--      </p>-->
<!--    </div>-->
<!--    <div class="mx-5">-->
<!--      <p [innerHTML]="managerService.config.FOOTER.LEGAL_HEADER | managerPipe" class="h5 d-flex align-items-center"-->
<!--         style="height:64px; color: white; font-family: 'Roboto Condensed', sans-serif;"></p>-->
<!--      <p *ngFor="let item of managerService.config.FOOTER.LEGAL_BODY | managerPipe" [innerHTML]="item | managerPipe: managerService.mpa_template.SET_HREF_CLASS"-->
<!--         style="color: #c0c4da; font-family: 'Roboto Condensed', sans-serif; font-size: 14px; margin-bottom: 4px;" type="button">-->
<!--      </p>-->
<!--    </div>-->
<!--    <div class="mx-5">-->
<!--      <p [innerHTML]="managerService.config.FOOTER.SERVICE_HEADER | managerPipe" class="h5 d-flex align-items-center"-->
<!--         style="height:64px; color: white; font-family: 'Roboto Condensed', sans-serif;"></p>-->
<!--      <p *ngFor="let item of managerService.config.FOOTER.SERVICE_BODY | managerPipe"-->
<!--         style="color: #c0c4da; font-family: 'Roboto Condensed', sans-serif; font-size: 14px; margin-bottom: 4px;" type="button">-->
<!--        <a [class.a-color]="!managerService.environment.app" (click)="managerService.navigationManager(item.LINK);">{{item.TEXT | managerPipe}}</a>-->
<!--      </p>-->
<!--    </div>-->
<!--    <div class="mx-5">-->
<!--      <p [innerHTML]="managerService.config.FOOTER.SUPPORT_HEADER | managerPipe" class="h5 d-flex align-items-center"-->
<!--         style="height:64px; color: white; font-family: 'Roboto Condensed', sans-serif;"></p>-->
<!--      <p *ngFor="let item of managerService.config.FOOTER.SUPPORT_BODY | managerPipe" [innerHTML]="item | managerPipe: managerService.mpa_template.SET_HREF_CLASS"-->
<!--         style="color: #c0c4da; font-family: 'Roboto Condensed', sans-serif; font-size: 14px; margin-bottom: 4px;" type="button">-->
<!--      </p>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<!--<div *ngIf="managerService.config.ZENDESK_ACTIVE | managerPipe">-->
<!--  <div class="pt-5" style="color:#f4f5f9; background-color: rgba(41,42,49,1);">-->
<!--    <div class="w-80 mx-auto mb-4 d-flex flex-row justify-content-between align-items-start" style="border-bottom: solid 1px rgba(240, 242, 247, 0.3);">-->
<!--      <div class="d-flex flex-row justify-content-start align-items-start" style="width: 60%">-->
<!--        <div>-->
<!--          <svg class="mr-3 mb-4" style="margin-top: -4px; width: 180px; height: 42px">-->
<!--            <use [attr.xlink:href]='"./assets/images/artiox_logo.svg#artiox_logo"' fill="white"/>-->
<!--          </svg>-->
<!--          <br>-->
<!--          <svg (click)="twitter()" style="height:42px; width: 42px; border: solid 1px #c0c4da; border-radius: 4px; padding: 12px; margin-right: 27px" type="button">-->
<!--            <use [attr.xlink:href]='"./assets/images/twitter.svg#twitter"' class="w-100" fill="#c0c4da"/>-->
<!--          </svg>-->
<!--          <svg (click)="instagram()" style="height:42px; width: 42px; border: solid 1px #c0c4da; border-radius: 4px; padding: 12px; margin-right: 27px" type="button">-->
<!--            <use [attr.xlink:href]='"./assets/images/instagram.svg#instagram"' class="w-100" fill="#c0c4da"/>-->
<!--          </svg>-->
<!--          <svg (click)="linkedin()" style="height:42px; width: 42px; border: solid 1px #c0c4da; border-radius: 4px; padding: 12px" type="button">-->
<!--            <use [attr.xlink:href]='"./assets/images/linkedin.svg#linkedin"' class="w-100" fill="#c0c4da"/>-->
<!--          </svg>-->
<!--        </div>-->
<!--        <div class="ms-5" style="font-family: Roboto, sans-serif; font-size: 13px; font-weight: 100; letter-spacing: 0.7px;">-->
<!--          <p [innerHTML]="managerService.config.FOOTER.ADDRESS | managerPipe" style="margin-bottom: 12px"></p>-->
<!--          <p>{{managerService.config.FOOTER.MAIL | managerPipe}}</p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div style="width: 40%; font-family: 'Roboto Condensed', sans-serif; font-size: 14px; letter-spacing: 0.7px;">-->
<!--        <div class="d-flex flex-row justify-content-between">-->
<!--          <div>-->
<!--            <div (click)="managerService.navigationManager(managerService.config.FOOTER.ARTIOX_LINK)" class="mb-5"-->
<!--                 type="button">{{managerService.config.FOOTER.ARTIOX | managerPipe}}</div>-->
<!--            <div (click)="managerService.navigationManager(managerService.config.FOOTER.ANNOUNCEMENTS_LINK)" class="mb-5"-->
<!--                 type="button">{{managerService.config.FOOTER.ANNOUNCEMENTS | managerPipe}}</div>-->
<!--          </div>-->
<!--          <div>-->
<!--            <div (click)="managerService.navigationManager(managerService.config.FOOTER.INFO_BANK_LINK)" class="mb-5"-->
<!--                 type="button">{{managerService.config.FOOTER.INFO_BANK | managerPipe}}</div>-->
<!--            <div (click)="managerService.navigationManager(managerService.config.FOOTER.ABOUT_ARTISTS_LINK)" class="mb-5"-->
<!--                 type="button">{{managerService.config.FOOTER.ABOUT_ARTISTS | managerPipe}}</div>-->
<!--          </div>-->
<!--          <div>-->
<!--            <div (click)="managerService.navigationManager(managerService.config.FOOTER.LEGAL_LINK)" class="mb-5"-->
<!--                 type="button">{{managerService.config.FOOTER.LEGAL | managerPipe}}</div>-->
<!--            <div (click)="managerService.navigationManager(managerService.config.FOOTER.CAREER_LINK)" class="mb-5"-->
<!--                 type="button">{{managerService.config.FOOTER.CAREER | managerPipe}}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<div class="d-flex w-100 flex-row justify-content-center" style="background: var(--text-color-artiox-black, #131515); padding: 48px 120px">
<div class="d-flex w-100 flex-column justify-content-center" style="max-width: 1200px;">
    <div class="d-flex w-100 flex-row justify-content-between" style="padding-bottom: 55px;">
        <div class="d-flex flex-column" style="width: 240px; gap: 32px">
            <div class="d-flex w-100">
                <svg style=" height: 38px; width: 140px">
                    <use [attr.xlink:href]='"./assets/images/artiox_logo.svg#artiox_logo"' fill="#FFF"/>
                </svg>
            </div>
            <div class="d-flex w-100 barlow caption regular" style="color: var(--secondary-colors-artiox-gray-base, #E2E2E2)">
                {{managerService.config.FOOTER.ADDRESS | managerPipe}}
            </div>
            <div class="d-flex w-100 flex-row" style="gap: 16px">
                <a (click)="managerService.marketingService.logEvent('google_play_navigation', {})" aria-label="app store"
                   href="{{managerService.config.APP_ANNOUNCEMENT.GOOGLE_PLAY_LINK | managerPipe}}">
<!--                    <png style="height:30px; width: 90px;">-->
<!--                        <use [attr.xlink:href]="'./assets/images/google-play-store.png'"/>-->
<!--                    </png>-->
                    <img src="./assets/images/google-play-store.png" width="100px" height="30px" alt="google play" />
                </a>
                <a (click)="managerService.marketingService.logEvent('app_store_navigation', {})" aria-label="google play"
                   href="{{managerService.config.APP_ANNOUNCEMENT.APP_STORE_LINK_DESKTOP | managerPipe}}">
<!--                    <svg style="height:30px; width: 90px;">-->
<!--                        <use [attr.xlink:href]="managerService.config.APP_ANNOUNCEMENT.APP_STORE_BADGE | managerPipe" fill="#f9f9f9"/>-->
<!--                    </svg>-->
                    <img src="./assets/images/apple-app-store.png" width="100px" height="30px" alt="app store" />

                </a>
            </div>
        </div>

        <div>
            <div class="d-flex barlow body1 medium" style="color: var(--text-color-artiox-white, #FFFFFF); padding-bottom: 16px">
                {{managerService.config.FOOTER.LEGAL.HEADING | managerPipe}}
            </div>
            <div class="d-flex flex-column" style="gap: 8px; color: var(--secondary-colors-artiox-gray-500, #B5B5B5)">
                <div *ngFor="let link of managerService.config.FOOTER.LEGAL.LINKS"
                     (click)="managerService.navigationManager(link.URL)" class="d-flex footer-link barlow body2 regular"
                     style="cursor: pointer">
                    {{link.TEXT | managerPipe}}
                </div>

<!--                <div (click)="managerService.navigationManager(managerService.config.FOOTER.LEGAL.LIMITS_LINK)" class="d-flex footer-link barlow body2 regular"-->
<!--                     style="cursor: pointer">-->
<!--                    {{managerService.config.FOOTER.LEGAL.LIMITS | managerPipe}}-->
<!--                </div>-->
<!--                <div (click)="managerService.navigationManager(managerService.config.FOOTER.LEGAL.USER_AGREEMENT_LINK)" class="d-flex footer-link barlow body2 regular"-->
<!--                     style="cursor: pointer">-->
<!--                    {{managerService.config.FOOTER.LEGAL.USER_AGREEMENT | managerPipe}}-->
<!--                </div>-->
<!--                <div (click)="managerService.navigationManager(managerService.config.FOOTER.LEGAL.KVKK_LINK)" class="d-flex footer-link barlow body2 regular"-->
<!--                     style="cursor: pointer">-->
<!--                    {{managerService.config.FOOTER.LEGAL.KVKK | managerPipe}}-->
<!--                </div>-->
<!--                <div (click)="managerService.navigationManager(managerService.config.FOOTER.LEGAL.COOKIES_LINK)" class="d-flex footer-link barlow body2 regular"-->
<!--                     style="cursor: pointer">-->
<!--                    {{managerService.config.FOOTER.LEGAL.COOKIES | managerPipe}}-->
<!--                </div>-->
<!--                <div (click)="managerService.navigationManager(managerService.config.FOOTER.LEGAL.COMMISSIONS_LINK)" class="d-flex footer-link barlow body2 regular"-->
<!--                     style="cursor: pointer">-->
<!--                    {{managerService.config.FOOTER.LEGAL.COMMISSIONS | managerPipe}}-->
<!--                </div>-->
            </div>
        </div>

        <div>
            <div class="d-flex barlow body1 medium" style="color: var(--text-color-artiox-white, #FFFFFF); padding-bottom: 16px">
                {{managerService.config.FOOTER.ARTIOX_SECTION.HEADER | managerPipe}}
            </div>
            <div class="d-flex flex-column" style="gap: 8px; color: var(--secondary-colors-artiox-gray-500, #B5B5B5)">
                <div (click)="managerService.navigateIfLoggedIn({url: '/exchange/basic', current_component_index: 0})" class="d-flex footer-link barlow body2 regular"
                     style="cursor: pointer">
                    {{managerService.config.FOOTER.ARTIOX_SECTION.BASIC | managerPipe}}
                </div>
                <div (click)="managerService.navigateIfLoggedIn({url: '/exchange/advanced', current_component_index: 0})" class="d-flex footer-link barlow body2 regular"
                     style="cursor: pointer">
                    {{managerService.config.FOOTER.ARTIOX_SECTION.ADVANCED | managerPipe}}
                </div>
                <div (click)="managerService.navigateIfLoggedIn({url: '/auction', current_component_index: 1})" class="d-flex footer-link barlow body2 regular"
                     style="cursor: pointer">
                    {{managerService.config.FOOTER.ARTIOX_SECTION.CATALOG | managerPipe}}
                </div>
                <div (click)="managerService.navigateIfLoggedIn({url: '/auction/catalog', active_auction_catalog_hash: '2024_DAILY', current_component_index: 2})"
                     class="d-flex footer-link barlow body2 regular"
                     style="cursor: pointer">
                    {{managerService.config.FOOTER.ARTIOX_SECTION.DAILY | managerPipe}}
                </div>
                <div (click)="managerService.navigationManager({url: 'https://tr.artiox.com/eser-kabul', is_inner: false, target: '_blank'})"
                     class="d-flex footer-link barlow body2 regular"
                     style="cursor: pointer">
                    {{managerService.config.FOOTER.ARTIOX_SECTION.SEND_ARTWORK | managerPipe}}
                </div>
                <div (click)="managerService.navigateIfLoggedIn({url: '/about-artist', current_component_index: 0})" class="d-flex footer-link barlow body2 regular"
                     style="cursor: pointer">
                    {{managerService.config.FOOTER.ARTISTS.ALL_ARTISTS | managerPipe}}
                </div>

            </div>
        </div>

        <div>
            <div class="d-flex barlow body1 medium" style="color: var(--text-color-artiox-white, #FFFFFF); padding-bottom: 16px">
                {{managerService.config.FOOTER.AUCTION.HEADER | managerPipe}}
            </div>
            <div class="d-flex flex-column" style="gap: 8px; color: var(--secondary-colors-artiox-gray-500, #B5B5B5)">
                <div (click)="managerService.navigateIfLoggedIn({url: '/legal/distance-selling-contract', current_component_index: 0})" class="d-flex footer-link barlow body2 regular"
                     style="cursor: pointer">
                    {{managerService.config.FOOTER.AUCTION.DISTANCE_SELLING | managerPipe}}
                </div>
                <div (click)="managerService.navigateIfLoggedIn({url: '/legal/auction-cancellation-and-refund-rules', current_component_index: 0})" class="d-flex footer-link barlow body2 regular"
                     style="cursor: pointer">
                    {{managerService.config.FOOTER.AUCTION.AUCTION_CANCELLATION | managerPipe}}
                </div>
                <div (click)="managerService.navigateIfLoggedIn({url: '/legal/auction-user-agreement', current_component_index: 0})" class="d-flex footer-link barlow body2 regular"
                     style="cursor: pointer">
                    {{managerService.config.FOOTER.AUCTION.AUCTION_USER_AGREEMENT | managerPipe}}
                </div>
                <div (click)="managerService.navigateIfLoggedIn({url: '/legal/auction-rules', current_component_index: 0})" class="d-flex footer-link barlow body2 regular"
                     style="cursor: pointer">
                    {{managerService.config.FOOTER.AUCTION.AUCTION_RULES| managerPipe}}
                </div>
            </div>
        </div>

<!--        <div>-->
<!--            <div class="d-flex barlow body1 medium" style="color: var(&#45;&#45;text-color-artiox-white, #FFFFFF); padding-bottom: 16px">-->
<!--                {{managerService.config.FOOTER.ARTISTS.HEADER | managerPipe}}-->
<!--            </div>-->
<!--            <div class="d-flex flex-column" style="gap: 16px; color: var(&#45;&#45;secondary-colors-artiox-gray-500, #B5B5B5)">-->
<!--                <div *ngFor="let artist of artist_list">-->
<!--                    <div (click)="managerService.navigateIfLoggedIn({url: '/about-artist', artist_announcement_index: artist.ID, current_component_index: 0})"-->
<!--                         class="d-flex footer-link barlow body2 regular" style="cursor: pointer">-->
<!--                        {{artist.NAME | managerPipe}}-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div (click)="managerService.navigateIfLoggedIn({url: '/about-artist', current_component_index: 0})" class="d-flex footer-link barlow body2 regular"-->
<!--                     style="cursor: pointer">-->
<!--                    {{managerService.config.FOOTER.ARTISTS.ALL_ARTISTS | managerPipe}}-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

        <div class="d-flex flex-row" style="gap: 24px">
            <svg (click)="instagram()" style=" height: 32px; width: 32px; cursor: pointer">
                <use [attr.xlink:href]='"./assets/images/instagram.svg#instagram"' stroke="#B5B5B5"/>
            </svg>
            <!--            why sizes differ ? -->
            <svg (click)="twitter()" style=" height: 24px; width: 24px; margin-top: 4px; cursor: pointer">
                <use [attr.xlink:href]='"./assets/images/x.svg#x"' fill="#B5B5B5"/>
            </svg>
            <svg (click)="linkedin()" style=" height: 32px; width: 32px; cursor: pointer">
                <use [attr.xlink:href]='"./assets/images/linkedin.svg#linkedin"' stroke="#B5B5B5"/>
            </svg>
        </div>
    </div>

    <div class="w-100 d-flex flex-row justify-content-between" style="padding-top: 16px; border-top: 1px solid #E2E2E2;color: var(--secondary-colors-artiox-gray-500, #B5B5B5)">
        <div [innerHTML]="managerService.config.FOOTER.RIGHTS | managerPipe: managerService.mpa_template.SET_CURRENT_YEAR" class="d-flex barlow overline regular"></div>
        <div [innerHTML]="managerService.config.FOOTER.VERSION" class="d-flex barlow overline regular"></div>
    </div>
</div>
</div>
