<div class="w-75 d-flex flex-column align-items-center justify-content-center m-auto"
     style="min-width: 300px; height: fit-content;">
  <p [innerHTML]="managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_ARTIST_ABOUT: managerService.language_pipe"
     class="my-3 exo header6 bold"
     style=" color: #404352;"></p>
  <p *ngIf="managerService.pairService.active_pair_ready | managerPipe"
     class="mt-1 mb-4 mx-3 barlow body2 regular" style=" color: #595d7e;">
    <span [innerHTML]="managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_INFO: managerService.language_pipe"></span>
    <a class="clarification-text" *ngIf="(managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_ROUTER_LINK: managerService.language_pipe); let item;"
       (click)="managerService.navigationManager(item.LINK)">{{item.TEXT | managerPipe}}</a>
  </p>
</div>

