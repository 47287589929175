import {AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
  selector: 'app-captcha-mobile',
  templateUrl: './captcha-mobile.component.html',
  styleUrls: ['./captcha-mobile.component.css']
})
export class CaptchaMobileComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() response: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('canvas') canvas: any = undefined;
  @ViewChild('block') block: any = undefined;
  @ViewChild('slider') slider: any = undefined;
  @ViewChild('slider_container') slider_container: any = undefined;
  @ViewChild('slider_mask') slider_mask: any = undefined;

  public default_config = {
    width: 200,
    height: 300,
    sliderL: 40,
    sliderR: 10,
    offset: 10,
    maxTryCount: 3,
    image: './assets/captcha/' + Math.floor(Math.random() * 10).toString() + '.m.webp',
  };

  public img: any = undefined;
  public x: number = 0;
  public y: number = 0;
  public try_count: number = 0;

  public handleDragStart: any = undefined;
  public handleDragMove: any = undefined;
  public handleDragEnd: any = undefined;

  public slider_icon: string = './assets/images/slide_icon.svg#slide_icon';

  constructor(public elementRef: ElementRef, public managerService: ManagerService) {
    this.img = new Image();
    this.img.src = this.managerService.localImageStoreManager(this.default_config.image);
    this.img.addEventListener('error', (e: any) => {
      this.img.src = this.img.src.replace('.webp', '.jpg');
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.img.onload = () => {
      this.loadImg();
    };
    this.bindEvents();
  }

  ngOnDestroy(): void {
    this.slider.nativeElement.removeEventListener('mousedown', this.handleDragStart);
    this.slider.nativeElement.removeEventListener('touchstart', this.handleDragStart);
    this.slider.nativeElement.removeEventListener('swipe', this.handleDragStart);

    document.removeEventListener('mousemove', this.handleDragMove);
    document.removeEventListener('touchmove', this.handleDragMove);

    document.removeEventListener('mouseup', this.handleDragEnd);
    document.removeEventListener('touchend', this.handleDragEnd);

    document.removeEventListener('mousedown', (): boolean => false);
    document.removeEventListener('touchstart', (): boolean => false);
    document.removeEventListener('swipe', (): boolean => false);
  }

  drawImg(ctx: any, operation: string, l: number, r: number, x: number, y: number, PI: number = Math.PI): void {
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.arc(x + l / 2, y - r + 2, r, 0.72 * PI, 2.26 * PI);
    ctx.lineTo(x + l, y);
    ctx.arc(x + l + r - 2, y + l / 2, r, 1.21 * PI, 2.78 * PI);
    ctx.lineTo(x + l, y + l);
    ctx.lineTo(x, y + l);
    ctx.arc(x + r - 2, y + l / 2, r + 0.4, 2.76 * PI, 1.24 * PI, true);
    ctx.lineTo(x, y);
    ctx.lineWidth = 2;
    ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
    ctx.strokeStyle = 'rgba(255, 255, 255, 0.8)';
    ctx.stroke();
    ctx[operation]();
    ctx.globalCompositeOperation = 'xor';
  }

  loadImg(): void {
    this.slider_container.nativeElement.classList.remove('slider-container-active', 'slider-container-success', 'slider-container-fail');
    this.slider_icon = './assets/images/slide_icon.svg#slide_icon';
    this.slider_mask.nativeElement.style.width = 0;
    this.block.nativeElement.height = this.default_config.height;
    this.canvas.nativeElement.height = this.default_config.height;
    this.block.nativeElement.width = this.default_config.width;
    this.canvas.nativeElement.width = this.default_config.width;
    const canvasCtx = this.canvas.nativeElement.getContext('2d');
    const blockCtx = this.block.nativeElement.getContext('2d');
    const L = this.default_config.sliderL + this.default_config.sliderR * 2;
    const R = this.default_config.sliderR * 2;
    const l = this.default_config.sliderL;
    const r = this.default_config.sliderR;
    const x = Math.round(Math.random() * (this.default_config.width - (L + 10) - L + 10) + L + 10);
    const y = Math.round(Math.random() * (this.default_config.height - (L + 10) - R + 10) + R + 10);
    this.drawImg(canvasCtx, 'fill', l, r, x, y);
    this.drawImg(blockCtx, 'clip', l, r, x, y);
    canvasCtx.drawImage(this.img, 0, 0, this.default_config.width, this.default_config.height);
    blockCtx.drawImage(this.img, 0, 0, this.default_config.width, this.default_config.height);
    const clip = blockCtx.getImageData(x, y - R, L, L);
    this.block.nativeElement.width = L;
    this.block.nativeElement.style.transform = 'translateX(-200px)';
    this.slider.nativeElement.style.transform = 'translateX(0px)';
    blockCtx.putImageData(clip, 0, y - R);
    this.x = x;
    this.y = y;
  }

  bindEvents(): void {
    let originX: number = 0;
    let originY: number = 0;
    let eventX: number = 0;
    let eventY: number = 0;
    let moveX: number = 0;
    let moveY: number = 0;
    let isMouseDown = false;

    const verify = (): boolean => {
      let verified: boolean = false;
      if (moveX > this.x - this.default_config.offset && moveX < this.x + this.default_config.offset) {
        verified = true;
      }
      return verified;
    };

    this.handleDragStart = (e: any): void => {
      originX = e.clientX || e.touches[0].clientX;
      originY = e.clientY || e.touches[0].clientY;
      isMouseDown = true;
    };

    this.handleDragMove = (e: any): void => {
      if (!isMouseDown) {
        return;
      }
      eventX = e.clientX || e.touches[0].clientX;
      eventY = e.clientY || e.touches[0].clientY;
      moveX = eventX - originX;
      moveY = eventY - originY;
      if (moveX < 0 || moveX + 40 > this.default_config.width) {
        return;
      }
      this.slider.nativeElement.style.transform = 'translateX(' + moveX.toString() + 'px)';
      this.block.nativeElement.style.transform = 'translateX(' + (moveX - 200).toString() + 'px)';
      this.slider_container.nativeElement.classList.add('slider-container-active');
      this.slider_mask.nativeElement.style.width = (moveX + 10).toString() + 'px';
    };

    this.handleDragEnd = (e: any): void => {
      if (!isMouseDown) {
        return;
      }
      isMouseDown = false;
      moveX = eventX - originX;
      moveY = eventY - originY;
      if (eventX === originX) {
        return;
      }
      if (verify()) {
        this.slider_icon = './assets/images/approve_icon.svg#approve_icon';
        this.slider_container.nativeElement.classList.add('slider-container-success');
        this.response.emit(true);
      } else {
        this.slider_icon = './assets/images/error_icon.svg#error_icon';
        this.slider_container.nativeElement.classList.add('slider-container-fail');
        setTimeout(() => {
          this.try_count += 1;
          if (this.try_count >= this.default_config.maxTryCount) {
            this.response.emit(false);
          } else {
            this.loadImg();
          }
        }, 500);

      }
    };

    this.slider.nativeElement.addEventListener('mousedown', this.handleDragStart);
    this.slider.nativeElement.addEventListener('touchstart', this.handleDragStart);
    this.slider.nativeElement.addEventListener('swipe', this.handleDragStart);

    document.addEventListener('mousemove', this.handleDragMove);
    document.addEventListener('touchmove', this.handleDragMove);

    document.addEventListener('mouseup', this.handleDragEnd);
    document.addEventListener('touchend', this.handleDragEnd);

    document.addEventListener('mousedown', (): boolean => false);
    document.addEventListener('touchstart', (): boolean => false);
    document.addEventListener('swipe', (): boolean => false);
  }

}
