<div class="w-80 mx-auto px-4" style=" border-radius: 4px; box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22);  background-color: #fcfdff;">
  <div class="pt-5 pb-3" style="font-family: bebas-neue-bold, sans-serif; font-size: 39px; letter-spacing: 4px; color: #404352;">
    {{managerService.config.SERVICE.HEADER.HEADING | managerPipe}}
  </div>
  <div class="w-100 d-flex flex-row">
    <div (click)="managerService.navigationManager(managerService.config.ZENDESK_ACTIVE
    ? {url: 'https://support.artiox.com/hc/tr/articles/4414505009681-Limitler-ve-Kurallar', is_inner: false, target: '_blank'}
    : {url: '/service/rules', current_component_index: 0})"
         [style.color]="(managerService.navigationService.active_service_page === managerService.navigationService.service_page_template.RULES ? 'var(--artiox-blue)' : '#595e7e') | managerPipe"
         class="btn-native pr-4"
         style="font-family: Roboto, sans-serif; font-size: 16px; font-weight: 500; letter-spacing: 1.6px; cursor: pointer">
      {{managerService.config.SERVICE.HEADER.RULES | managerPipe}}
    </div>
    <div (click)="managerService.navigationManager(managerService.config.ZENDESK_ACTIVE
    ? {url: 'https://support.artiox.com/hc/tr/articles/4414483870865-Komisyonlar', is_inner: false, target: '_blank'}
    : {url: '/service/commissions', current_component_index: 0})"
         [style.color]="(managerService.navigationService.active_service_page === managerService.navigationService.service_page_template.COMMISSIONS ? 'var(--artiox-blue)' : '#595e7e') | managerPipe"
         class="btn-native px-4"
         style="font-family: Roboto, sans-serif; font-size: 16px; font-weight: 500; letter-spacing: 1.6px; cursor: pointer">
      {{managerService.config.SERVICE.HEADER.COMMISSIONS | managerPipe}}
    </div>
  </div>
</div>
