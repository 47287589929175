import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';
import {environment} from '../../environments/environment';

export class Order {
  public id: number;
  public user_id: number;
  public pair_id: [number, number];
  public type: string;
  public amount: number;
  public init_amount: number;
  public price: number;
  public percent: number;
  public status: string;
  public creation_time: number;

  constructor(order_primitive: any) {
    this.id = order_primitive.id;
    this.user_id = order_primitive.user_id;
    this.pair_id = order_primitive.pair_id;
    this.type = order_primitive.type;
    this.amount = order_primitive.amount;
    this.init_amount = order_primitive.init_amount;
    this.price = order_primitive.price;
    this.percent = order_primitive.percent;
    this.status = order_primitive.status;
    this.creation_time = order_primitive.creation_time / 1000000;
  }
}

export class OrderBookEntry {
  public pair_id: [number, number];
  public type: string;
  public amount: number;
  public price: number;

  constructor(order_primitive: any) {
    this.pair_id = order_primitive.pair_id;
    this.type = order_primitive.type;
    this.amount = order_primitive.amount;
    this.price = order_primitive.price;
  }
}

@Injectable({
  providedIn: 'root'
})
export class OrderBookService {

  public buy_order_book_ready: boolean = false;
  public sell_order_book_ready: boolean = false;

  @Output() order_book_changed: EventEmitter<any> = new EventEmitter();

  public buy_order_book: OrderBookEntry[] = [];
  public sell_order_book: OrderBookEntry[] = [];

  public environment = environment;

  constructor(private httpClient: HttpClient) {
  }

  getBuyOrderBook(): OrderBookEntry[] {
    return this.buy_order_book;
  }

  setBuyOrderBook(buy_order_book_primitive: any[]): any {
    this.buy_order_book = buy_order_book_primitive.map(x => new OrderBookEntry(x));
  }

  addBuyOrder(order_primitive: any): void {
    for (let index = 0; index < this.buy_order_book.length; index++) {
      if (order_primitive.price > this.buy_order_book[index].price) {
        this.buy_order_book.splice(index, 0, new OrderBookEntry(order_primitive));
        return;
      }
    }
    this.buy_order_book.push(new OrderBookEntry(order_primitive));
  }

  getSellOrderBook(): OrderBookEntry[] {
    return this.sell_order_book;
  }

  setSellOrderBook(sell_order_book_primitive: any[]): void {
    this.sell_order_book = sell_order_book_primitive.map(x => new OrderBookEntry(x));
  }

  addSellOrder(order_primitive: any): void {
    for (let index = 0; index < this.sell_order_book.length; index++) {
      if (order_primitive.price > this.sell_order_book[index].price) {
        this.sell_order_book.splice(index, 0, new OrderBookEntry(order_primitive));
        return;
      }
    }
    this.sell_order_book.push(new OrderBookEntry(order_primitive));
  }

  mergeOrder(order_primitive: any): void {
    if (order_primitive.type === 'buy') {
      for (const buy_order of this.buy_order_book) {
        if (buy_order.price === order_primitive.price) {
          buy_order.amount += order_primitive.amount;
          this.order_book_changed.emit();
          return;
        }
      }
      this.addBuyOrder(order_primitive);
      this.order_book_changed.emit();
    } else if (order_primitive.type === 'sell') {
      for (const sell_order of this.sell_order_book) {
        if (sell_order.price === order_primitive.price) {
          sell_order.amount += order_primitive.amount;
          this.order_book_changed.emit();
          return;
        }
      }
      this.addSellOrder(order_primitive);
      this.order_book_changed.emit();
    }
  }

  refreshOrderBook(pair_id: [number, number] | undefined, resolve?: any): any {
    const data = {pair_id, limit: 50};
    return this.httpClient.post<any[]>(this.environment.serverAPI + 'get_order_book_service', data).subscribe(
      response => {
        this.setBuyOrderBook(response.filter(x => x.type === 'buy'));
        this.setSellOrderBook(response.filter(x => x.type === 'sell'));
        this.buy_order_book_ready = true;
        this.sell_order_book_ready = true;
        this.order_book_changed.emit();
        if (resolve !== undefined) {
          resolve();
        }
      });
  }
}
