import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ManagerService} from '../../../../../../services/manager.service';
import {Clipboard} from '@angular/cdk/clipboard';
import {HttpClient} from '@angular/common/http';
import * as QRCode from 'qrcode';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-authenticator-mobile',
  templateUrl: './authenticator-mobile.component.html',
  styleUrls: ['./authenticator-mobile.component.css']
})
export class AuthenticatorMobileComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('qr') qr: any = undefined;
  @ViewChild('qr_code') qr_code: any = undefined;

  @ViewChild('two_factor_auth_div') two_factor_auth_div: any = undefined;

  @ViewChild('warning_password') warning_password: any = undefined;
  @ViewChild('warning_auth_password') warning_auth_password: any = undefined;

  @ViewChild('remove_2fa_password_input') remove_2fa_password_input: any = undefined;
  @ViewChild('remove_2fa_auth_password_input') remove_2fa_auth_password_input: any = undefined;

  public current_password_visible: boolean = false;
  public auth_password_visible: boolean = false;

  public password_validated: boolean = false;

  public password_form_input_fields: any = undefined;
  public two_factor_authentication_form_input_fields: any = undefined;

  public two_factor_auth_key: string = '';

  passwordForm = new UntypedFormGroup({
    password: new UntypedFormControl('', [Validators.required]),
  });

  twoFactorAuthenticationForm = new UntypedFormGroup({
    auth_accepted: new UntypedFormControl(null, [Validators.required]),
    auth_password: new UntypedFormControl('', [Validators.required]),
  });

  remove2FAPasswordForm = new UntypedFormGroup({
    password: new UntypedFormControl('', [Validators.required]),
  });

  remove2FAKeyForm = new UntypedFormGroup({
    auth_password: new UntypedFormControl('', [Validators.required]),
  });

  public subscription_list: Subscription[] = [];

  constructor(public managerService: ManagerService, public elementRef: ElementRef, private clipboard: Clipboard, private httpClient: HttpClient) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.password_form_input_fields = this.elementRef.nativeElement.getElementsByClassName('password-input-field');
    this.two_factor_authentication_form_input_fields = this.elementRef.nativeElement.getElementsByClassName('two-factor-authentication-input-field');
  }


  clearPasswordFormWarnings(): void {
    this.current_password_visible = false;
    this.auth_password_visible = false;
    for (const input_field of this.password_form_input_fields) {
      input_field.classList.remove('input-field-underline-required');
    }
    this.warning_password.nativeElement.classList.add('d-none');
  }

  checkPassword(): void {
    for (const input_field of this.password_form_input_fields) {
      input_field.classList.remove('input-field-underline-required');
    }
    this.warning_password.nativeElement.classList.add('d-none');
    if (this.passwordForm.invalid) {
      if (this.passwordForm.get('password')?.errors) {
        for (const input_field of this.password_form_input_fields) {
          input_field.classList.add('input-field-underline-required');
        }
        // @ts-ignore
        const content = this.managerService.config.RESPONSE_TABLE['2003'];
        this.managerService.popSnackBar(content.is_okay, content.message);
      }
    } else {
      this.getQRCode();
    }
  }

  getQRCode(): void {
    const data = {
      password: this.passwordForm.value.password,
    };
    this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'set_two_factor_auth_service', data).subscribe(
      response => {
        if (response.is_okay) {
          this.clearPasswordFormWarnings();
          QRCode.toCanvas(this.qr.nativeElement, response.two_factor_auth_uri, {errorCorrectionLevel: 'H'});
          this.two_factor_auth_key = response.two_factor_auth_key;
          this.qr_code.nativeElement.innerHTML = response.two_factor_auth_key;
          this.two_factor_auth_div.nativeElement.classList.remove('d-none');
        } else {
          if (response.code === 223) {
            this.password_form_input_fields[0].classList.add('input-field-underline-required');
            this.warning_password.nativeElement.innerHTML = this.managerService.codeHandler(response);
            this.warning_password.nativeElement.classList.remove('d-none');
          }
        }
        // @ts-ignore
        const content = this.managerService.config.RESPONSE_TABLE[response.code];
        this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
      }));
  }

  clearTwoFactorAuthenticationForm(): void {
    this.current_password_visible = false;
    this.auth_password_visible = false;
    this.two_factor_authentication_form_input_fields[0].classList.remove('checkbox-field-required');
    this.two_factor_authentication_form_input_fields[1].classList.remove('input-field-underline-required');
    this.twoFactorAuthenticationForm.reset({auth_accepted: null, auth_password: ''});
    this.warning_auth_password.nativeElement.classList.add('d-none');
    this.two_factor_auth_div.nativeElement.classList.add('d-none');
  }

  checkAuthPassword(): void {
    this.two_factor_authentication_form_input_fields[0].classList.remove('checkbox-field-required');
    this.two_factor_authentication_form_input_fields[1].classList.remove('text-danger');
    this.two_factor_authentication_form_input_fields[2].classList.remove('input-field-underline-required');
    this.warning_auth_password.nativeElement.classList.add('d-none');
    if (this.twoFactorAuthenticationForm.invalid) {
      if (this.twoFactorAuthenticationForm.get('auth_password')?.errors || this.twoFactorAuthenticationForm.get('auth_accepted')?.errors) {
        this.two_factor_authentication_form_input_fields[0].classList.add('checkbox-field-required');
        this.two_factor_authentication_form_input_fields[1].classList.add('text-danger');
        this.two_factor_authentication_form_input_fields[2].classList.add('input-field-underline-required');
        // @ts-ignore
        const content = this.managerService.config.RESPONSE_TABLE['2003'];
        this.managerService.popSnackBar(content.is_okay, content.message);
      }
    } else {
      if (!this.twoFactorAuthenticationForm.get('auth_accepted')?.value) {
        this.two_factor_authentication_form_input_fields[0].classList.add('checkbox-field-required');
        this.two_factor_authentication_form_input_fields[1].classList.add('text-danger');
      } else {
        this.verifyTwoFactorAuthentication();
      }
    }
  }

  verifyTwoFactorAuthentication(): void {
    const data = {
      auth_password: this.twoFactorAuthenticationForm.value.auth_password,
      client_info: this.managerService.client_info
    };
    this.subscription_list.push(this.httpClient.post<any>(this.managerService.environment.serverAPI + 'verify_two_factor_auth_service', data).subscribe(
      response => {
        if (response.is_okay) {
          this.passwordForm.reset({password: ''});
          this.clearPasswordFormWarnings();
          this.clearTwoFactorAuthenticationForm();
        } else {
          if (response.code === 227) {
            this.two_factor_authentication_form_input_fields[2].classList.add('input-field-underline-required');
            this.warning_auth_password.nativeElement.innerHTML = this.managerService.codeHandler(response);
            this.warning_auth_password.nativeElement.classList.remove('d-none');
          }
        }
        // @ts-ignore
        const content = this.managerService.config.RESPONSE_TABLE[response.code];
        this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
      }));
  }

  copySecretKey(): void {
    this.clipboard.copy(this.two_factor_auth_key);
    this.managerService.popSnackBar(true, this.managerService.config.DEPOSIT.COPIED);
  }

  clearRemove2FAForms(): void {
    this.current_password_visible = false;
    this.auth_password_visible = false;
    this.password_validated = false;
    this.remove2FAPasswordForm.get('password')?.setValue(null);
    this.remove2FAKeyForm.get('auth_password')?.setValue(null);
  }

  validatePassword(): void{
    this.remove_2fa_password_input.nativeElement.classList.remove('input-field-underline-required');
    if (this.remove2FAPasswordForm.invalid){
      // @ts-ignore
      const content = this.managerService.config.RESPONSE_TABLE['2003'];
      this.managerService.popSnackBar(content.is_okay, content.message);
      this.remove_2fa_password_input.nativeElement.classList.add('input-field-underline-required');
      return;
    }

    const data = {
      auth_password: this.twoFactorAuthenticationForm.value.auth_password,
      client_info: this.managerService.client_info,
      password: this.remove2FAPasswordForm.get('password')?.value
    };
    this.subscription_list.push(
      this.httpClient.post<any>(this.managerService.environment.serverAPI + 'validate_user_password_service', data).subscribe(response => {
        if (response.is_okay){
          this.password_validated = true;
          this.remove_2fa_password_input.nativeElement.classList.remove('input-field-underline-required');
        }
        else {
          this.remove_2fa_password_input.nativeElement.classList.add('input-field-underline-required');
        }
        // @ts-ignore
        const content = this.managerService.config.RESPONSE_TABLE[response.code];
        this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
      })
    );
  }

  removeTwoFactorAuth(): void {
    this.remove_2fa_auth_password_input.nativeElement.classList.remove('input-field-underline-required');
    if (this.remove2FAKeyForm.invalid){
      // @ts-ignore
      const content = this.managerService.config.RESPONSE_TABLE['2003'];
      this.managerService.popSnackBar(content.is_okay, content.message);
      this.remove_2fa_auth_password_input.nativeElement.classList.add('input-field-underline-required');
      return;
    }

    const data = {
      auth_password: this.remove2FAKeyForm.value.auth_password,
      client_info: this.managerService.client_info,
    };
    this.subscription_list.push(
      this.httpClient.post<any>(this.managerService.environment.serverAPI + 'remove_two_factor_auth_service', data).subscribe(response => {
        if (response.is_okay){
          this.clearRemove2FAForms();
          this.remove_2fa_auth_password_input.nativeElement.classList.remove('input-field-underline-required');
        }
        else {
          this.remove_2fa_auth_password_input.nativeElement.classList.add('input-field-underline-required');
        }
        // @ts-ignore
        const content = this.managerService.config.RESPONSE_TABLE[response.code];
        this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription_list.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

}
