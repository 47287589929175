<div class="d-flex w-100 flex-column justify-content-center "
     style="margin-right: 10vw">
    <div *ngIf="(managerService.user_deliveries_pipe | managerPipe: managerService.mpa_template.GET_USER_WON).length === 0"
         class="px-4" style="box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22);  background-color: #fcfdff; min-height: 650px">
        <div class="pt-3 mb-2" style="font-family: Roboto, sans-serif; font-size: 20px; font-weight: bold; line-height: 2; letter-spacing: 1px; color: #4a4e70;">
            {{managerService.config.ACCOUNT_AUCTION.NO_USER_WON_HEADING | managerPipe}}
        </div>
        <div class="my-2" style="font-family: Roboto, sans-serif; font-size: 14px; color: #4a4e70;">
            {{managerService.config.ACCOUNT_AUCTION.NO_USER_WON_DESCRIPTION | managerPipe}}
        </div>

        <button
            (click)="managerService.navigationManager({url: '/auction', current_component_index: 1});"
            class="btn-native sign-up-login-button mx-4 my-3"
            style="height: 40px; width:160px; border-radius: 20px;
              font-family: Roboto, sans-serif; font-size: 16px; line-height: 1.3; letter-spacing: 0.3px; color: #ffffff;">
            {{managerService.config.ACCOUNT_AUCTION.SEE_ARTWORKS | managerPipe}}
        </button>
    </div>

    <div
        *ngFor="let auction_product of (managerService.user_deliveries_pipe | managerPipe: managerService.mpa_template.GET_USER_WON)"
        [ngClass]="auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_STATUS_STYLE"
        class="d-flex  flex-column  justify-content-center align-items-center lot-card"
        style="margin-top: 10px;  border-radius: 4px; box-shadow: 5px 5px 10px 0 rgba(45, 52, 104, 0.5); position: relative; padding-left: 60px; padding-right: 60px; ">


        <div
            style="overflow: hidden; position: absolute;  width: 120px; height: 120px; background: transparent; top: 0px; left: 0px; border-radius: 4px;">
            <div
                class="ownership lot-number d-flex justify-content-center align-items-end">
                <div class="d-flex text pb-1">{{managerService.config.AUCTION.MY_BIDS.WON}}</div>
            </div>
        </div>

        <div class="d-flex flex-column align-items-center w-100">
            <div class="d-flex flex-column align-items-center w-75 pb-4">
                <!--Lot Number div-->
                <div class="d-flex justify-content-center  align-items-center mb-4">
                    <div class="lot-number"
                         style="background: #28A79B; box-shadow: 0px 4px 4px 0px #FFFFFF40; border-radius: 0px 0px 4px 4px; gap: 8px; color: #FFFFFF; width: 80px;
                   height: 21px; text-align: center; align-content: center;
                   font-weight: 700; font-size: 10px; line-height: 20px; letter-spacing: 0.25px">
                        LOT {{auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_LOT }}
                    </div>
                </div>

                <div class="d-flex flex-row justify-content-between align-items-center  w-100 h-100">
                    <div class="d-flex">
                        <img #catalog_img_payed
                             (error)="catalog_img_payed.src = catalog_img_payed.src.replace('.webp', '.jpg');" alt="catalog_img"
                             src="{{auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_THUMBNAIL | managerPipe: managerService.mpa_template.LOCAL_IMAGE_MANAGER_TO_BE}}"
                             style="max-width: 80px;max-height: 80px;border-radius: 0.375rem;background:#FCFDFF;box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.15); object-position:center;overflow: clip"
                        >
                    </div>
                    <div class="d-flex flex-column h-100 justify-content-between ">
                        <div style="font-family: Roboto,sans-serif; text-align: center; max-width: 200px">
                            <div class="mb-2 line-clamp-1"
                                 style="font-family: Roboto, sans-serif;font-style: normal;font-weight: 500;font-size: 14px;line-height: 16px;letter-spacing: 0.001em;color: #404352;">
                                {{ auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_ARTIST_NAME }}
                            </div>
                            <div class="mb-2 line-clamp-1"
                                 style="font-family: Roboto, sans-serif;font-style: italic;font-weight: 500;font-size: 13px;line-height: 15px;letter-spacing: 0.001em;color: #686C75;">
                                {{ auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_NAME }}
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column justify-content-center  h-100 w-25">
                        <div class="w-100 my-4 d-flex flex-row justify-content-center">
                            <div class="my-auto text-center">
                                <div class="mb-1"
                                     style="color: #49566B;font-family: Roboto,sans-serif;font-size: 1rem;font-style: normal;font-weight: 700;line-height: normal;letter-spacing: 0.00131rem;">{{auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_OPENING_VALUE | managerPipe: managerService.mpa_template.FANCY_NUMBER_NO_DECIMAL}}
                                    TL
                                </div>
                                <div
                                    style="font-family: Roboto, sans-serif;color: #AAAEB5;font-style: normal;font-weight: 400;font-size: 12px;line-height: 14px;display: flex;align-items: center;letter-spacing: 0.06px;">{{managerService.config.ACCOUNT_AUCTION.GIVEN_BID | managerPipe}}</div>
                            </div>
                            <span class="mx-4" style="width: 1px; height: 60px; background-color: #d8d8d8;"></span>
                            <div class="my-auto text-center">
                                <div class="mb-1"
                                     style="font-family: Roboto,sans-serif;font-style: normal;font-weight: 700;font-size: 16px;line-height: 19px;letter-spacing: 0.0015em;color: #2AD8A9;">{{auction_product | managerPipe: managerService.mpa_template.AUCTION_PRODUCT_TOTAL_PRICE}}

                                </div>
                                <div
                                    style="font-family: Roboto, sans-serif;font-style: normal;font-weight: 400;font-size: 12px;line-height: 14px;letter-spacing: 0.06px;color: #AAAEB5;">{{managerService.config.ACCOUNT_AUCTION.BUYER_COST | managerPipe}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex h-100 flex-column" style=" gap: 16px">
                        <div class="d-flex justify-content-center" (click)="managerService.navigateIfLoggedIn({url: '/payment/auction', active_auction_product_hash: auction_product.hash, current_component_index: 0})"
                             style="cursor: pointer;padding: 4px 8px;border-radius: 4px;background: #0AD5A0;box-shadow: 1px 1px 2px 0px #D5D8E2;color: #FFFFFF;font-family: Roboto,sans-serif;font-size: 1rem;font-style: normal;font-weight: 400;line-height: 1.1875rem; /* 135.714% */">
                            <div *ngIf="(auction_product.id | managerPipe: managerService.mpa_template.GET_USER_WON_DELIVERY_STATUS : managerService.user_deliveries_pipe)  === 'pending'">{{managerService.config.ACCOUNT_AUCTION.MAKE_PAYMENT | managerPipe}}</div>
                            <div *ngIf="(auction_product.id | managerPipe: managerService.mpa_template.GET_USER_WON_DELIVERY_STATUS : managerService.user_deliveries_pipe)  !== 'pending'">{{managerService.config.ACCOUNT_AUCTION.PAYMENT_DETAIL | managerPipe}}</div>
                        </div>
                        <div class="d-flex flex-row justify-content-around align-items-center"
                            (click)="managerService.navigateIfLoggedIn({url: '/auction/auction_product', active_auction_product_hash: auction_product.hash, current_component_index: 3});"
                            style="width: 117px; cursor: pointer;padding: 4px 8px;border-radius: 0.875rem;background: #FFF;box-shadow: 1px 1px 2px 0px #D5D8E2;color: #9DA5B7;font-family: Roboto,sans-serif;font-size: 1rem;font-style: normal;font-weight: 400;line-height: 1.1875rem; /* 135.714% */">
                            {{managerService.config.ACCOUNT_AUCTION.PRODUCT_DETAIL | managerPipe}}
                            <svg style="height: 10px; width: 10px;">
                                <use [attr.xlink:href]='"./assets/images/right_arrow.svg#right_arrow"' fill="#9DA5B7"/>
                            </svg>
                        </div>

                    </div>
                </div>
            </div>
            <span class="w-75" style="background: #9DA5B7;border: #9DA5B7 1px solid; opacity: 0.2"></span>
            <div class="d-flex flex-column   w-75 pb-4">
                <div class="d-flex flex-column w-100">
                    <div style="color: #4A4E70;font-family: Roboto, sans-serif;font-size: 14px;font-style: normal;font-weight: 500;line-height: normal;padding-top: 16px;padding-bottom: 16px"
                    >{{managerService.config.PAYMENT.PAYMENT_STATUS.HEADER | managerPipe}}</div>
                    <div>
                        <div class="d-flex flex-row w-100 justify-content-start" style="padding-right: 36px; gap: 16px">
                            <svg style="height: 36px; width: 36px;">
                                <use [attr.xlink:href]="auction_product.id | managerPipe: managerService.mpa_template.GET_USER_WON_DELIVERY_IMAGE: managerService.language_pipe: managerService.user_deliveries_pipe"/>
                            </svg>
                            <div class="d-flex flex-column">
                                <div class="d-flex "></div>
                                <div class="d-flex flex-column" style="gap: 8px">
<!--                                    todo header color -->
                                    <div [innerHTML]="auction_product.id | managerPipe: managerService.mpa_template.GET_USER_WON_DELIVERY_HEADER: managerService.language_pipe: managerService.user_deliveries_pipe"
                                         [style.color]="auction_product.id | managerPipe: managerService.mpa_template.GET_USER_WON_DELIVERY_HEADER_COLOR: managerService.user_deliveries_pipe"
                                         style="font-family: Roboto, sans-serif;font-size: 12px;font-style: normal;font-weight: 500;line-height: normal;"></div>
                                    <div [innerHTML]="auction_product.id | managerPipe: managerService.mpa_template.GET_USER_WON_DELIVERY_TEXT: managerService.language_pipe: managerService.user_deliveries_pipe"
                                         style="color: #404352;font-family: Roboto, sans-serif;font-size: 12px;font-style: normal;font-weight: 400;line-height: normal;"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
