import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';

@Component({
  selector: 'app-trade-desktop',
  templateUrl: './trade-desktop.component.html',
  styleUrls: ['./trade-desktop.component.css']
})
export class TradeDesktopComponent implements OnInit {

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

}
