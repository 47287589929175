<div class="w-100 d-flex flex-column align-items-center justify-content-center p-3">
  <div style="font-family: bebas-neue-regular, sans-serif;font-size: 17px; font-weight: bold; line-height: 1.2; letter-spacing: 2.5px;  color: #404352;">
    {{ (this.managerService.config.DELISTING | managerPipe: this.managerService.mpa_template.RETURN_INDEX: active_art_product_symbol).TITLE }}
  </div>
  <div class="p-2" style="font-family: Roboto, sans-serif; font-size: 12px;  line-height: 1.1; letter-spacing: 0.4px; color: #4a4e70;"
       *ngFor="let rule of (this.managerService.config.DELISTING | managerPipe: this.managerService.mpa_template.RETURN_INDEX: active_art_product_symbol).TEXT">
        <span class="mb-2" [innerHTML]="rule">
        </span>
  </div>
  <button (click)="closeModal()"
          class="btn-native checkbox-button">
    {{ managerService.config.DELISTING.BUTTON_TEXT | managerPipe }}
  </button>
</div>
