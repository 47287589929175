<p style="text-indent: 0pt;text-align: left;"><br/></p>
<p style="text-indent: 0pt;text-align: left;"><br/></p>
<h1 style="padding-left: 104pt;text-indent: 0pt;text-align: center;">KİŞİSEL VERİLERİN KORUNMASI POLİTİKASI</h1>
<p style="text-indent: 0pt;text-align: left;"><br/></p>
<ol id="l1">
    <li data-list-text="1."><h1 style="padding-left: 41pt;text-indent: -26pt;line-height: 112%;text-align: justify;">Yasal Dayanak : <span class="p">Anayasa’nın  20.  maddesinde  düzenlenen;  herkesin, kendisiyle ilgili kişisel verilerin korunmasını isteme hakkına sahip olduğu, bu hakkın; kişinin kendisiyle ilgili kişisel veriler hakkında bilgilendirilme, bu verilere  erişme,  bunların düzeltilmesini veya silinmesini talep etme ve amaçları  doğrultusunda  kullanılıp  kullanılmadığını  öğrenmeyi  de kapsadığını, kişisel verilerin, ancak kanunda öngörülen hallerde veya kişinin açık rızasıyla işlenebileceğini temel yasal dayanak alarak 6698 sayılı Kişisel Verilerin Korunması Kanunu uyarınca Kişisel Verilerin hukuka uygun olarak korunması  ve  işlenmesine  azami  önem  veriyor  ve  tüm  planlama  ve faaliyetlerimizde bu özenle hareket ediyoruz. Şirket olarak, özel hayatın gizliliğinin temeli olan Kişisel Verilerin korunması ve ve işlenmesi için tüm idari  ve  teknik  tedbirleri  alıyor  personelimize  5237  sayılı  Türk  Ceza Kanununun (TCK) 135.madde ve devamında düzenlenen yasal yaptırımlar konusunda bilgilendirme ve uyarılarda bulunuyoruz.</span>
    </h1></li>
    <li data-list-text="2."><h1 style="padding-top: 10pt;padding-left: 41pt;text-indent: -26pt;line-height: 112%;text-align: justify;">Amaç: <span class="p">Yürürlükte bulunan 6698 sayılı Kişisel Verilerin Korunması hakkındaki Kanunu ile, kişisel verilerin işlenmesinde, başta özel hayatın gizliliği olmak üzere kişilerin temel hak ve özgürlüklerinin korunması ve kişisel verileri işleyen gerçek ve tüzel kişilerin yükümlülükleri ile uyacakları usul ve esaslar düzenlenmiştir. Söz konusu düzenleme de dikkate alınarak hazırlanan politikamızın amacı; kişisel verilerin korunması hakkındaki yükümlülüklere uyumun sağlanması,  Şirketimizin gerçekleştirdiği faaliyetler kapsamında temin edilen bilgilerin işlenmesi, aktarılması ve gizliliğinin korunması ile ilgili hususların risk temelli bir yaklaşımla değerlendirilerek, stratejilerin, kurum içi kontrol ve önlemlerin, işleyiş kurallarının ve sorumlulukların belirlenmesi ile kurum  çalışanlarının bu konularda bilinçlendirilmesidir. Aynı zamanda; müşterilerimiz, potansiyel müşterilerimiz, çalışanlarımız, çalışan adaylarımız, Şirket hissedarlarımız, Şirket yetkililerimiz, ziyaretçilerimiz, işbirliği içinde olduğumuz  kurum/kuruluşların  çalışanları,  hissedarları  ve  yetkilileri  ve üçüncü kişiler başta olmak üzere kişisel verileri Şirketimiz tarafından işlenen kişileri bilgilendirilerek şeffaflığı sağlamak amaçlanmaktadır.</span>
    </h1></li>
    <li data-list-text="3."><h1 style="padding-top: 10pt;padding-left: 41pt;text-indent: -26pt;line-height: 112%;text-align: justify;">Kapsam: <span class="p">Bu  politika;  müşterilerimizin,  potansiyel  müşterilerimizin, çalışanlarımızın,  çalışan  adaylarımızın,  Şirket  hissedarlarının,  Şirket yetkililerinin,  ziyaretçilerimizin,  işbirliği  içinde  olduğumuz  kurumların</span>
    </h1>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <p style="padding-top: 5pt;padding-left: 41pt;text-indent: 0pt;line-height: 112%;text-align: justify;">çalışanları, hissedarları ve yetkililerinin ve üçüncü kişilerin otomatik olan ya da
            herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla işlenen tüm kişisel verilerine ilişkindir.</p></li>
    <li data-list-text="4."><h1 style="padding-top: 10pt;padding-left: 41pt;text-indent: -28pt;text-align: left;">Tanımlar</h1>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <ol id="l2">
            <li data-list-text="4.1."><h1 style="padding-left: 77pt;text-indent: -35pt;line-height: 112%;text-align: justify;">Açık Rıza <span class="p">Belirli bir konuya ilişkin bilgilendirilmeye dayanan ve özgür iradeyle açıklanan rıza.</span>
            </h1></li>
            <li data-list-text="4.2."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -37pt;line-height: 112%;text-align: justify;">Anonim Hale Getirme <span class="p">Kişisel verinin, kimliği belli veya belirlenebilir biri ile ilişkilendirilebilme niteliğini kaybedecek ve bu durumun geri alınamayacağı   şekilde   değiştirilmesidir.   Örnek:   Maskeleme, toplulaştırma, veri bozma vb. tekniklerle kişisel verinin bir gerçek kişi ile ilişkilendirilemeyecek hale getirilmesi.</span>
            </h1></li>
            <li data-list-text="4.3."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -37pt;line-height: 112%;text-align: justify;">Çalışan <span class="p">Şirket ile arasında yapılmış olan iş akdi gereğince Şirkette çalışmakta olan kişiler</span>
            </h1></li>
            <li data-list-text="4.4."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -39pt;line-height: 112%;text-align: justify;">Çalışan Adayı <span class="p">Şirket ya herhangi bir yolla iş başvurusunda bulunmuş ya da özgeçmiş ve ilgili bilgilerini Şirketin incelemesine açmış olan gerçek kişiler</span>
            </h1></li>
            <li data-list-text="4.5."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -37pt;line-height: 112%;text-align: justify;">İşbirliği İçerisinde Olduğumuz Kurumların Çalışanları,
                Hissedarları ve Yetkilileri <span class="p">Şirketin  her  türlü  iş  ilişkisi  içerisinde  bulunduğu kurumlarda (iş ortağı, tedarikçi gibi, ancak bunlarla sınırlı olmaksızın) çalışan, bu kurumların hissedarları ve yetkilileri dahil olmak üzere, gerçek kişiler</span>
            </h1></li>
            <li data-list-text="4.6."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -38pt;line-height: 112%;text-align: justify;">Kişisel Verilerin İşlenmesi: <span class="p">Kişisel verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla  otomatik  olmayan  yollarla  elde  edilmesi,  kaydedilmesi, depolanması,   muhafaza   edilmesi,   değiştirilmesi,   yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hâle getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü işlem.</span>
            </h1></li>
            <li data-list-text="4.7."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -38pt;line-height: 112%;text-align: justify;">Kişisel Veri Sahibi <span class="p">Kişisel verisi işlenen gerçek kişi. Örneğin; Müşteriler ve çalışanlar.</span>
            </h1></li>
            <li data-list-text="4.8."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -38pt;line-height: 112%;text-align: justify;">Kişisel Veri <span class="p">Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgi. Tüzel kişilere ilişkin bilgilerin işlenmesi kanun kapsamında değildir. Örneğin; ad-soyadı, TC, e-posta, adres, doğum tarihi, kredi kartı numarası vb.</span>
            </h1></li>
            <li data-list-text="4.9."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -38pt;text-align: left;">Müşteri <span class="p">Şirket ile herhangi bir sözleşmesel ilişkisi olup olmadığına</span>
            </h1>
                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                <p style="padding-top: 5pt;padding-left: 77pt;text-indent: 0pt;line-height: 112%;text-align: left;">bakılmaksızın Şirketin sunmuş olduğu ürün ve hizmetleri kullanan veya kullanmış olan
                    gerçek kişiler</p></li>
            <li data-list-text="4.10."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -43pt;line-height: 112%;text-align: justify;">Özel Nitelikli Kişisel Veri : <span class="p">Irk, etnik köken, siyasi düşünce, felsefi inanç, din, mezhep veya diğer inançlar, kılık kıyafet, dernek vakıf ya da sendika üyeliği, sağlık, cinsel hayat, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili veriler ile biyometrik ve genetik veriler özel nitelikli verilerdir.</span>
            </h1></li>
            <li data-list-text="4.11."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -40pt;line-height: 112%;text-align: justify;">Potansiyel Müşteri <span class="p">Ürün ve hizmetlerimize kullanma talebinde veya ilgisinde bulunmuş veya bu ilgiye sahip olabileceği ticari teamül ve dürüstlük kurallarına uygun olarak değerlendirilmiş gerçek kişiler</span>
            </h1></li>
            <li data-list-text="4.12."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -41pt;text-align: left;">Şirket Hissedarı: <span class="p">Şirketin hissedarı gerçek kişiler</span>
            </h1>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="4.13."><h1 style="padding-left: 77pt;text-indent: -42pt;line-height: 112%;text-align: justify;">Şirket Yetkilisi<span class="p">: Şirketin yönetim kurulu üyesi ve diğer yetkili gerçek kişiler</span>
            </h1></li>
            <li data-list-text="4.14."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -43pt;line-height: 112%;text-align: justify;">Üçüncü Kişi <span class="p">Şirketin yukarıda bahsi geçen taraflarla arasındaki ticari işlem  güvenliğini  sağlamak  veya bahsi geçen kişilerin haklarını korumak ve menfaat temin etmek üzere bu kişilerle ilişkili olan üçüncü taraf gerçek kişiler (Örn. Aile Bireyleri ve yakınlar)</span>
            </h1></li>
            <li data-list-text="4.15."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -42pt;line-height: 112%;text-align: justify;">Veri İşleyen <span class="p">Veri sorumlusunun verdiği yetkiye dayanarak onun adına kişisel veri işleyen gerçek ve tüzel kişidir. Örneğin, Şirketin verilerini tutan firma veya şirketler vb.</span>
            </h1></li>
            <li data-list-text="4.16."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -42pt;line-height: 112%;text-align: justify;">Veri Sorumlusu <span class="p">Kişisel verilerin işlenme amaçlarını ve vasıtalarını belirleyen, verilerin sistematik bir şekilde tutulduğu yeri (veri kayıt sistemi) yöneten, veri sahibinin talebi / başvurusu neticesinde kişisel bilgileri ile ilgili veri sahibine gerekli bilgiyi sağlayan ve yönlendirmeleri yapan kişi veri sorumlusudur.</span>
            </h1></li>
            <li data-list-text="4.17."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -42pt;line-height: 112%;text-align: justify;">Ziyaretçi <span class="p">Şirketin sahip olduğu fiziksel yerleşkelere çeşitli amaçlarla girmiş olan veya internet sitelerimizi ziyaret eden gerçek kişiler</span>
            </h1></li>
        </ol>
    </li>
    <li data-list-text="5."><h1 style="padding-top: 10pt;padding-left: 41pt;text-indent: -27pt;text-align: left;">Kısaltmalar</h1>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <ol id="l3">
            <li data-list-text="5.1."><h1 style="padding-left: 77pt;text-indent: -34pt;line-height: 112%;text-align: justify;">KVKK : <span class="p">6698 sayılı Kanun 7 Nisan 2016 tarihli ve 29677 sayılı Resmi Gazete ’de yayımlanan, 24 Mart 2016 tarihli ve 6698 sayılı Kişisel Verilerin Korunması Kanunu.</span>
            </h1></li>
            <li data-list-text="5.2."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -36pt;line-height: 112%;text-align: justify;">Anayasa : <span class="p">9 Kasım 1982 tarihli ve 17863 sayılı Resmi Gazete ‘de yayımlanan; 7 Kasım 1982 tarihli ve 2709 sayılı Türkiye Cumhuriyeti Anayasası.</span>
            </h1>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="5.3."><h1 style="padding-top: 5pt;padding-left: 77pt;text-indent: -36pt;text-align: left;">KVK Kurulu <span class="p">Kişisel Verileri Koruma Kurulu</span></h1>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="5.4."><h1 style="padding-left: 77pt;text-indent: -37pt;text-align: left;">KVK Kurumu <span class="p">Kişisel Verileri Koruma Kurumu</span></h1>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="5.5."><h1 style="padding-left: 77pt;text-indent: -36pt;text-align: left;">Politika <span class="p">Şirket Kişisel Verilerin Korunması ve İşlenmesi Politikası</span>
            </h1>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="5.6."><h1 style="padding-left: 77pt;text-indent: -37pt;line-height: 112%;text-align: left;">TBK <span class="p">4 Şubat 2011 tarihli ve 27836 sayılı Resmi Gazete ‘de yayımlanan; 11 Ocak 2011 tarihli ve 6098 sayılı Türk Borçlar Kanunu.</span>
            </h1></li>
            <li data-list-text="5.7."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -37pt;line-height: 112%;text-align: left;">TCK <span class="p">12 Ekim 2004 tarihli ve 25611 sayılı Resmi Gazete ‘de yayımlanan; 26 Eylül 2004 tarihli ve 5237 sayılı Türk Ceza Kanunu.</span>
            </h1></li>
            <li data-list-text="5.8."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -37pt;line-height: 112%;text-align: left;">TTK <span class="p">14  Şubat  2011  tarihli  ve  27846  sayılı  Resmi  Gazete  ‘de yayımlanan;13 Ocak 2011 tarihli ve 6102 sayılı Türk Ticaret Kanunu</span>
            </h1></li>
        </ol>
    </li>
    <li data-list-text="6."><h1 style="padding-top: 10pt;padding-left: 41pt;text-indent: -27pt;line-height: 112%;text-align: left;">Veri Kategorileri: <span class="p">Şirket  aşağıdaki  veri  kategorilerine  ilişkin  verileri kaydedebilir, işleyebilir veya aktarabilir.</span>
    </h1>
        <ol id="l4">
            <li data-list-text="6.1."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -34pt;text-align: left;">Kimlik <span
                class="p">(ad soyad, doğum tarihi, doğum yeri, tc kimlik no)</span></h1>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="6.2."><h1 style="padding-left: 77pt;text-indent: -37pt;text-align: left;">İletişim <span class="p">(adres, e-posta adresi, iletişim adresi, telefon no)</span></h1>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="6.3."><h1 style="padding-left: 77pt;text-indent: -37pt;text-align: left;">Lokasyon <span class="p">(Bulunduğu yerin konum bilgileri)</span></h1>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="6.4."><h1 style="padding-left: 77pt;text-indent: -38pt;line-height: 112%;text-align: left;">Özlük <span class="p">(bordro bilgileri, disiplin soruşturması, işe giriş-çıkış belgesi kayıtları, özgeçmiş bilgileri, performans değerlendirme raporları)</span>
            </h1></li>
            <li data-list-text="6.5."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -37pt;line-height: 112%;text-align: left;">Hukuki İşlem <span class="p">(adli  makamlarla  yazışmalardaki  bilgiler,  dava dosyasındaki bilgiler)</span>
            </h1></li>
            <li data-list-text="6.6."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -37pt;line-height: 112%;text-align: left;">Müşteri İşlem <span class="p">(çağrı merkezi kayıtları, fatura, senet, çek bilgileri, internet sitesinde yapılan işlem tutar bilgileri)</span>
            </h1></li>
            <li data-list-text="6.7."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -37pt;line-height: 112%;text-align: left;">Fiziksel Mekân Güvenliği <span class="p">(çalışan ve ziyaretçilerin giriş çıkış kayıt bilgileri, kamera kayıtları)</span>
            </h1></li>
            <li data-list-text="6.8."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -38pt;line-height: 112%;text-align: left;">İşlem Güvenliği <span class="p">(IP adresi bilgileri, internet sitesi giriş çıkış bilgileri, şifre ve parola bilgiler)</span>
            </h1></li>
            <li data-list-text="6.9."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -37pt;line-height: 112%;text-align: left;">Risk Yönetimi <span class="p">(ticari, teknik, idari risklerin yönetilmesi için işlenen bilgiler)</span>
            </h1></li>
            <li data-list-text="6.10."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -42pt;text-align: left;">Finans <span class="p">(personel ücret bilgisi)</span></h1>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="6.11."><h1 style="padding-left: 77pt;text-indent: -39pt;line-height: 112%;text-align: left;">Mesleki Deneyim <span class="p">(diploma bilgileri, gidilen kurslar, meslek içi eğitim bilgileri, sertifikalar, transkript bilgileri)</span>
            </h1></li>
            <li data-list-text="6.12."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -41pt;text-align: left;">Pazarlama </h1> <span class="p">(alışveriş geçmişi bilgileri, anket, çerez kayıtları, kampanya çalışmasıyla elde edilen bilgiler)</span>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="6.13."><h1 style="padding-left: 77pt;text-indent: -41pt;text-align: left;">Görsel ve İşitsel Kayıtlar <span class="p">(görsel ve işitsel kayıtlar)</span></h1>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="6.14."><h1 style="padding-left: 77pt;text-indent: -42pt;line-height: 112%;text-align: left;">Sağlık Bilgileri <span class="p">(engellilik durumuna ait bilgiler, kan grubu bilgisi, kişisel sağlık bilgileri, kullanılan cihaz ve protez bilgileri)</span>
            </h1></li>
            <li data-list-text="6.15."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -41pt;line-height: 112%;text-align: left;">Ceza Mahkûmiyeti Ve Güvenlik Tedbirleri <span class="p">(ceza mahkûmiyetine ilişkin bilgiler, güvenlik tedbirlerine ilişkin bilgiler gibi)</span>
            </h1></li>
        </ol>
    </li>
    <li data-list-text="7."><h1 style="padding-top: 10pt;padding-left: 41pt;text-indent: -27pt;line-height: 112%;text-align: left;">Kişisel Veri İşleme Amaçları <span class="p">Şirket aşağıdaki amaçlara göre kişisel verileri kaydedebilir, işleyebilir veya aktarabilir.</span>
    </h1>
        <ol id="l5">
            <li data-list-text="7.1."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -34pt;text-align: left;">Acil Durum Yönetimi Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.2."><p style="padding-left: 77pt;text-indent: -36pt;text-align: left;">Bilgi Güvenliği Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.3."><p style="padding-left: 77pt;text-indent: -37pt;line-height: 112%;text-align: left;">Çalışan Adayı / Stajyer / Öğrenci Seçme Ve Yerleştirme Süreçlerinin
                Yürütülmesi</p></li>
            <li data-list-text="7.4."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -37pt;text-align: left;">Çalışan Adaylarının Başvuru Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.5."><p style="padding-left: 77pt;text-indent: -36pt;text-align: left;">Çalışan Memnuniyeti Ve Bağlılığı Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.6."><p style="padding-left: 77pt;text-indent: -37pt;line-height: 112%;text-align: left;">Çalışanlar İçin İş Akdi Ve Mevzuattan Kaynaklı Yükümlülüklerin Yerine
                Getirilmesi</p></li>
            <li data-list-text="7.7."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -37pt;text-align: left;">Çalışanlar İçin Yan Haklar Ve Menfaatleri Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.8."><p style="padding-left: 77pt;text-indent: -37pt;text-align: left;">Denetim / Etik Faaliyetlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.9."><p style="padding-left: 77pt;text-indent: -37pt;text-align: left;">Eğitim Faaliyetlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.10."><p style="padding-left: 77pt;text-indent: -42pt;text-align: left;">Erişim Yetkilerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.11."><p style="padding-left: 77pt;text-indent: -38pt;text-align: left;">Faaliyetlerin Mevzuata Uygun Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.12."><p style="padding-left: 77pt;text-indent: -41pt;text-align: left;">Finans Ve Muhasebe İşlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.13."><p style="padding-left: 77pt;text-indent: -41pt;text-align: left;">Firma / Ürün / Hizmetlere Bağlılık Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.14."><p style="padding-left: 77pt;text-indent: -42pt;text-align: left;">Fiziksel Mekan Güvenliğinin Temini</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.15."><p style="padding-left: 77pt;text-indent: -41pt;text-align: left;">Görevlendirme Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.16."><p style="padding-left: 77pt;text-indent: -41pt;text-align: left;">Hukuk İşlerinin Takibi Ve Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.17."><p style="padding-left: 77pt;text-indent: -41pt;text-align: left;">İç Denetim/ Soruşturma / İstihbarat Faaliyetlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.18."><p style="padding-top: 5pt;padding-left: 77pt;text-indent: -41pt;text-align: left;">İletişim Faaliyetlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.19."><p style="padding-left: 77pt;text-indent: -41pt;text-align: left;">İnsan Kaynakları Süreçlerinin Planlanması</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.20."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">İş Faaliyetlerinin Yürütülmesi / Denetimi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.21."><p style="padding-left: 77pt;text-indent: -41pt;text-align: left;">İş Sağlığı / Güvenliği Faaliyetlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.22."><p style="padding-left: 77pt;text-indent: -43pt;line-height: 112%;text-align: left;">İş Süreçlerinin İyileştirilmesine Yönelik Önerilerin Alınması Ve
                Değerlendirilmesi</p></li>
            <li data-list-text="7.23."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -43pt;text-align: left;">İş Sürekliliğinin Sağlanması Faaliyetlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.24."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">Lojistik Faaliyetlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.25."><p style="padding-left: 77pt;text-indent: -43pt;text-align: left;">Mal / Hizmet Satın Alım Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.26."><p style="padding-left: 77pt;text-indent: -43pt;text-align: left;">Mal / Hizmet Satış Sonrası Destek Hizmetlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.27."><p style="padding-left: 77pt;text-indent: -43pt;text-align: left;">Mal / Hizmet Satış Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.28."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">Mal / Hizmet Üretim Ve Operasyon Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.29."><p style="padding-left: 77pt;text-indent: -43pt;text-align: left;">Müşteri İlişkileri Yönetimi Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.30."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">Müşteri Memnuniyetine Yönelik Aktivitelerin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.31."><p style="padding-left: 77pt;text-indent: -41pt;text-align: left;">Organizasyon Ve Etkinlik Yönetimi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.32."><p style="padding-left: 77pt;text-indent: -43pt;text-align: left;">Pazarlama Analiz Çalışmalarının Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.33."><p style="padding-left: 77pt;text-indent: -43pt;text-align: left;">Performans Değerlendirme Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.34."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">Reklam / Kampanya / Promosyon Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.35."><p style="padding-left: 77pt;text-indent: -43pt;text-align: left;">Risk Yönetimi Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.36."><p style="padding-left: 77pt;text-indent: -43pt;text-align: left;">Saklama Ve Arşiv Faaliyetlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.37."><p style="padding-left: 77pt;text-indent: -43pt;text-align: left;">Sosyal Sorumluluk Ve Sivil Toplum Aktivitelerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.38."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">Sözleşme Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.39."><p style="padding-left: 77pt;text-indent: -43pt;text-align: left;">Sponsorluk Faaliyetlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.40."><p style="padding-left: 77pt;text-indent: -45pt;text-align: left;">Stratejik Planlama Faaliyetlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.41."><p style="padding-left: 77pt;text-indent: -42pt;text-align: left;">Talep / Şikayetlerin Takibi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.42."><p style="padding-top: 5pt;padding-left: 77pt;text-indent: -44pt;text-align: left;">Taşınır Mal Ve Kaynakların Güvenliğinin Temini</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.43."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">Tedarik Zinciri Yönetimi Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.44."><p style="padding-left: 77pt;text-indent: -45pt;text-align: left;">Ücret Politikasının Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.45."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">Ürün / Hizmetlerin Pazarlama Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.46."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">Veri Sorumlusu Operasyonlarının Güvenliğinin Temini</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.47."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">Yabancı Personel Çalışma Ve Oturma İzni İşlemleri</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.48."><p style="padding-left: 77pt;text-indent: -45pt;text-align: left;">Yatırım Süreçlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.49."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">Yetenek / Kariyer Gelişimi Faaliyetlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.50."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">Yetkili Kişi, Kurum Ve Kuruluşlara Bilgi Verilmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.51."><p style="padding-left: 77pt;text-indent: -41pt;text-align: left;">Yönetim Faaliyetlerinin Yürütülmesi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="7.52."><p style="padding-left: 77pt;text-indent: -43pt;text-align: left;">Ziyaretçi Kayıtlarının Oluşturulması Ve Takibi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
        </ol>
    </li>
    <li data-list-text="8."><h1 style="padding-left: 41pt;text-indent: -28pt;line-height: 112%;text-align: left;">Kişisel Veri Aktarım Alıcı Grupları <span class="p">Şirket aşağıdaki Kişisel Veri Aktarı Alıcı gruplarına kişisel verileri aktarabilir.</span>
    </h1>
        <ol id="l6">
            <li data-list-text="8.1."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -35pt;text-align: left;">İş Ortağı</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="8.2."><p style="padding-left: 77pt;text-indent: -37pt;text-align: left;">Tedarikçi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="8.3."><p style="padding-left: 77pt;text-indent: -37pt;text-align: left;">Yetkili Kamu Kurum Ve Kuruluşları</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
        </ol>
    </li>
    <li data-list-text="9."><h1 style="padding-left: 41pt;text-indent: -27pt;line-height: 112%;text-align: left;">Kişisel Veri Konusu Kişiler <span class="p">- Şirket aşağıdaki kişi türlerine göre kişisel verileri kaydedebilir, işleyebilir veya aktarabilir.</span>
    </h1>
        <ol id="l7">
            <li data-list-text="9.1."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -34pt;text-align: left;">Çalışan Adayı</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="9.2."><p style="padding-left: 77pt;text-indent: -37pt;text-align: left;">Çalışan</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="9.3."><p style="padding-left: 77pt;text-indent: -37pt;text-align: left;">Hissedar/Ortak</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="9.4."><p style="padding-left: 77pt;text-indent: -38pt;text-align: left;">Potansiyel Ürün Ve Hizmet Alıcısı</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="9.5."><p style="padding-left: 77pt;text-indent: -37pt;text-align: left;">Stajyer</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="9.6."><p style="padding-left: 77pt;text-indent: -37pt;text-align: left;">Tedarikçi Çalışan</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="9.7."><p style="padding-left: 77pt;text-indent: -37pt;text-align: left;">Tedarikçi Yetkilisi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="9.8."><p style="padding-left: 77pt;text-indent: -37pt;text-align: left;">Ürün Veya Hizmet Alan Kişi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="9.9."><p style="padding-top: 5pt;padding-left: 77pt;text-indent: -37pt;text-align: left;">Ziyaretçi</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
        </ol>
    </li>
    <li data-list-text="10."><h1 style="padding-left: 41pt;text-indent: -32pt;line-height: 112%;text-align: justify;">Kişisel Veri Saklama Süreleri : <span class="p">Kişisel verileri saklama süreleri Kişisel veri Saklama ve İmha politikasında ayrıntılı olarak düzenlenmiştir.</span>
    </h1></li>
    <li data-list-text="11."><h1 style="padding-top: 10pt;padding-left: 41pt;text-indent: -29pt;text-align: left;">Kişisel Verilerin Silinmesi, Yok Edilmesi veya Anonim Hale Getirilmesi :</h1>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <ol id="l8">
            <li data-list-text="11.1."><p style="padding-left: 77pt;text-indent: -36pt;line-height: 112%;text-align: justify;">Kişisel verilerin hukuka uygun olarak işlenmiş olmasına rağmen,
                işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde bu veriler, resen veya ilgili kişinin talebi üzerine veri sorumlusu tarafından silinir, yok edilir veya anonim hâle
                getirilir.</p></li>
            <li data-list-text="11.2."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -38pt;line-height: 112%;text-align: justify;">Veri sorumlusu, kişisel verileri silme, yok etme veya
                anonim hale getirme yükümlülüğünün ortaya çıktığı tarihi takip eden ilk periyodik imha işleminde, kişisel verileri siler, yok eder veya anonim hale getirir.</p></li>
            <li data-list-text="11.3."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -38pt;line-height: 112%;text-align: justify;">Bu hususlara ilişkin yapılması gereken işlemler Kişisel
                veri saklama ve imha politikasında ayrıntılı olarak açıklanmıştır.</p></li>
        </ol>
    </li>
    <li data-list-text="12."><h1 style="padding-top: 10pt;padding-left: 41pt;text-indent: -31pt;line-height: 112%;text-align: justify;">Kişisel Verilerin Aktarılması <span class="p">Kanunda belirtilen genel ilkeler çerçevesinde işlenmek üzere elde edilen kişisel veriler, ilgili kişinin açık rızası alınmak suretiyle üçüncü kişilere aktarılabilir.</span>
    </h1>
        <ol id="l9">
            <li data-list-text="12.1."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -38pt;line-height: 112%;text-align: justify;">Yurt içi aktarım: <span class="p">Kişisel veri ve özel nitelikteki kişisel verilerin yurt içinde  aktarımına  ilişkin  ayrıntılar  Kişisel  Verilerin  Aktarılması prosedüründe düzenlenmiştir.</span>
            </h1></li>
            <li data-list-text="12.2."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -41pt;line-height: 112%;text-align: justify;">Yurt dışı aktarım : <span class="p">İlgili kişinin açık rızasının bulunması şartıyla Kanunda  belirtilen  hallerin  varlığı  halinde  Yeterli  korumanın bulunduğu ülkelere kişisel veri aktarımı yapılabilir. Yeterli korumanın bulunmadığı ülkelere veri aktarımı ise Kanunda belirtilen hallerin varlığı, açık rızanın olmasına ek olarak yeterli korumanın yazılı olarak taahhüt  edilmesi  ve  Kurulun  izninin  bulunması  durumlarında gerçekleştirilebilir. Konuya ilişkin ayrıntılar Kişisel Verilerin Aktarılması Prosedürü’nde düzenlenmiştir.</span>
            </h1></li>
        </ol>
    </li>
    <li data-list-text="13."><h1 style="padding-top: 10pt;padding-left: 41pt;text-indent: -31pt;line-height: 112%;text-align: justify;">Kişisel Verilerin İşlenmesinde Genel (Temel) İlkeler: <span
        class="p">Kişisel veriler Kişisel verilerin işlenmesi prosedüründe ayrıntılı olarak belirtildiği şekilde aşağıdaki temel ilkelere uygun olarak işlenecektir.</span></h1>
        <ol id="l10">
            <li data-list-text="13.1."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -38pt;text-align: left;">Hukuka ve dürüstlük kurallarına uygun olma,</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="13.2."><p style="padding-left: 77pt;text-indent: -40pt;text-align: left;">Doğru ve gerektiğinde güncel olma,</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="13.3."><p style="padding-left: 77pt;text-indent: -40pt;text-align: left;">Belirli, açık ve meşru amaçlar için işlenme,</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="13.4."><p style="padding-top: 5pt;padding-left: 77pt;text-indent: -41pt;text-align: left;">İşlendikleri amaçla bağlantılı, sınırlı ve ölçülü olma,</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="13.5."><p style="padding-left: 77pt;text-indent: -41pt;line-height: 112%;text-align: justify;">İlgili mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre
                kadar muhafaza edilme.</p></li>
        </ol>
    </li>
    <li data-list-text="14."><h1 style="padding-top: 10pt;padding-left: 41pt;text-indent: -32pt;line-height: 112%;text-align: justify;">Açık Rıza : <span class="p">Belirli bir konuya ilişkin, bilgilendirilmeye dayanan ve özgür iradeyle açıklanan rızadır. Açık rıza alma prosedüründe ayrıntılı olarak belirtildiği üzere Açık rıza’nın Belirli bir konuya ilişkin olması, Rızanın bilgilendirmeye dayanması ve Özgür iradeyle açıklanması gereklidir.</span>
    </h1></li>
    <li data-list-text="15."><h1 style="padding-top: 10pt;padding-left: 41pt;text-indent: -31pt;line-height: 112%;text-align: justify;">Aydınlatma yükümlülüğü : <span class="p">Kişisel verilerin elde edilmesi sırasında şirket tarafından ilgili kişilerin bilgilendirilir. Aydınlatma Prosedüründe ayrıntılı olarak düzenlendiği üzere bu bilgilendirme asgari olarak aşağıdaki konuları içermektedir.</span>
    </h1>
        <ol id="l11">
            <li data-list-text="15.1."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -38pt;text-align: left;">Veri sorumlusunun ve varsa temsilcisinin kimliği,</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="15.2."><p style="padding-left: 77pt;text-indent: -40pt;text-align: left;">Kişisel verilerin hangi amaçla işleneceği,</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="15.3."><p style="padding-left: 77pt;text-indent: -40pt;text-align: left;">Kişisel verilerin kimlere ve hangi amaçla aktarılabileceği,</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="15.4."><p style="padding-left: 77pt;text-indent: -41pt;text-align: left;">Kişisel veri toplamanın yöntemi ve hukuki sebebi,</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="15.5."><p style="padding-left: 77pt;text-indent: -40pt;text-align: left;">İlgili kişinin Kanunun 11 inci maddesinde sayılan diğer hakları.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
        </ol>
    </li>
    <li data-list-text="16."><h1 style="padding-left: 41pt;text-indent: -32pt;line-height: 112%;text-align: justify;">İlgili kişinin hak arama yöntemleri: <span class="p">İlgili kişilerin, Şirkete başvurarak; kendileriyle ilgili kişisel verilerin işlenip işlenmediğini öğrenmek, işlenmişse bunları talep etmek, verinin muhtevasının eksik veya yanlış olması halinde bunların düzeltilmesini, hukuka aykırı olması halinde ise silinmesini, yok edilmesini ve buna göre yapılacak işlemlerin verilerin açıklandığı üçüncü kişilere bildirilmesini ve verilerin kanuna aykırı olarak işlenmesi sebebiyle zararlarının  giderilmesini  talep  etme  hakları  bulunmaktadır.  İlgili  kişi ayrıntıları İlgili Kişinin Hak Arama Prosedürü’nde belirtildiği üzere başvuru ve şikayet haklarını kullanabilir.</span>
    </h1>
        <ol id="l12">
            <li data-list-text="16.1."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -39pt;line-height: 112%;text-align: justify;">Başvuru : <span class="p">İlgili kişilerin, sahip oldukları hakları kullanabilmeleri için öncelikle  veri  sorumlusuna  başvurmaları  zorunludur.  Bu  yol tüketilmeden Kurula şikâyet yoluna gidilemez.</span>
            </h1></li>
            <li data-list-text="16.2."><h1 style="padding-top: 10pt;padding-left: 77pt;text-indent: -41pt;line-height: 112%;text-align: justify;">Şikayet : <span class="p">İlgili kişinin şikayet yoluna başvurulabilmesi için Şirkete başvurunun reddedilmesi, verilen cevabın yetersiz bulunması veya 30 gün içinde başvuruya cevap verilmemiş olması gereklidir. İlgili kişilerin Şirkete  başvurmadan  doğrudan  Kurula  şikayet  yoluna  gitmesi mümkün değildir.</span>
            </h1></li>
        </ol>
    </li>
    <li data-list-text="17."><h1 style="padding-top: 10pt;padding-left: 41pt;text-indent: -31pt;text-align: left;">Kurul Kararlarının Yerine Getirilmesi Yükümlülüğü : <span class="p">Kurul, şikâyet üzerine</span>
    </h1>
        <p style="text-indent: 0pt;text-align: left;"><br/></p>
        <p style="padding-top: 5pt;padding-left: 41pt;text-indent: 0pt;line-height: 112%;text-align: justify;">veya ihlal iddiasını öğrenmesi durumunda resen görev alanına giren konularda yapacağı
            inceleme sonucunda bir ihlalin varlığını tespit ederse, hukuka aykırılıkların Şirket tarafından giderilmesine karar vererek, kararı ilgililere tebliğ eder. Kurul Kararlarının Yerine
            Getirilmesi prosedüründe ayrıntılı olarak belirtildiği üzere Şirket, bu kararı, tebliğ tarihinden itibaren gecikmeksizin ve en geç otuz gün içinde yerine getirir.</p></li>
    <li data-list-text="18."><h1 style="padding-top: 10pt;padding-left: 41pt;text-indent: -32pt;line-height: 112%;text-align: justify;">Veri Sorumluları Sicili (VERBİS) kayıt yükümlülüğü: <span
        class="p">Şirket  ,  Veri sorumlularının kayıt olmak zorunda oldukları ve veri işleme faaliyetleri ile ilgili bilgileri beyan ettikleri kayıt sistemine Veri Sorumluları Sicili (VERBİS) kayıt prosedüründe belirtildiği şekilde kayıt olur ve bu kayıtları günceller.</span>
    </h1></li>
    <li data-list-text="19."><h1 style="padding-top: 10pt;padding-left: 41pt;text-indent: -32pt;line-height: 112%;text-align: justify;">Kişisel Veri İhlali : <span class="p">İşlenen kişisel verilerin kanuni olmayan yollarla başkaları tarafından elde edilmesi hâlinde, Şirket bu durumu en kısa sürede Kişisel Veri İhlali Prosedüründe belirtildiği şekilde ilgilisine ve Kurula bildirir. Kurul, gerekmesi hâlinde bu durumu, kendi internet sitesinde ya da uygun göreceği başka bir yöntemle ilan edebilir.</span>
    </h1></li>
    <li data-list-text="20."><h1 style="padding-top: 10pt;padding-left: 41pt;text-indent: -34pt;line-height: 112%;text-align: justify;">Kişisel Veri Güvenliği Tedbirleri : <span class="p">Şirket Kişisel verilerin hukuka aykırı olarak işlenmesini  önlemek,  Kişisel  verilere  hukuka  aykırı  olarak  erişilmesini önlemek, Kişisel verilerin muhafazasını sağlamak için aşağıdaki teknik ve idari tedbirleri Şirket yapısına uygun düzeyde almaktadır.</span>
    </h1>
        <ol id="l13">
            <li data-list-text="20.1."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -41pt;text-align: left;">Ağ güvenliği ve uygulama güvenliği sağlanmaktadır.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="20.2."><p style="padding-left: 77pt;text-indent: -43pt;text-align: left;">Ağ yoluyla kişisel veri aktarımlarında kapalı sistem ağ kullanılmaktadır.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="20.3."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">Anahtar yönetimi uygulanmaktadır.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="20.4."><p style="padding-left: 77pt;text-indent: -45pt;line-height: 112%;text-align: left;">Bilgi teknolojileri sistemleri tedarik, gelistirme ve bakımı kapsamındaki
                güvenlik önlemleri alınmaktadır.</p></li>
            <li data-list-text="20.5."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -44pt;line-height: 112%;text-align: left;">Çalışanlar için veri güvenliği hükümleri içeren disiplin
                düzenlemeleri mevcuttur.</p></li>
            <li data-list-text="20.6."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -44pt;line-height: 112%;text-align: left;">Çalışanlar için veri güvenliği konusunda belli aralıklarla
                eğitim ve farkındalık çalışmaları yapılmaktadır.</p></li>
            <li data-list-text="20.7."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -44pt;text-align: left;">Çalışanlar için yetki matrisi oluşturulmuştur.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="20.8."><p style="padding-left: 77pt;text-indent: -44pt;text-align: left;">Erişim logları düzenli olarak tutulmaktadır.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="20.9."><p style="padding-left: 77pt;text-indent: -44pt;line-height: 112%;text-align: left;">Erişim, bilgi güvenliği, kullanım, saklama ve imha konularında kurumsal
                politikalar hazırlanmış ve uygulamaya başlanmıştır.</p></li>
            <li data-list-text="20.10."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -49pt;text-align: left;">Gerektiğinde veri maskeleme önlemi uygulanmaktadır.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="20.11."><p style="padding-top: 5pt;padding-left: 77pt;text-indent: -46pt;text-align: left;">Gizlilik taahhütnameleri yapılmaktadır.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="20.12."><p style="padding-left: 77pt;text-indent: -48pt;line-height: 112%;text-align: justify;">Görev değişikliği olan ya da işten ayrılan çalışanların bu alandaki
                yetkileri kaldırılmaktadır.</p></li>
            <li data-list-text="20.13."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -48pt;text-align: left;">Güncel anti-virüs sistemleri kullanılmaktadır.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="20.14."><p style="padding-left: 77pt;text-indent: -49pt;text-align: left;">Güvenlik duvarları kullanılmaktadır.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="20.15."><p style="padding-left: 77pt;text-indent: -48pt;text-align: left;">İmzalanan sözleşmeler veri güvenliği hükümleri içermektedir.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="20.16."><p style="padding-left: 77pt;text-indent: -49pt;line-height: 112%;text-align: justify;">Kağıt yoluyla aktarılan kişisel veriler için ekstra güvenlik tedbirleri
                alınmakta ve ilgili evrak gizlilik dereceli belge formatında gönderilmektedir.</p></li>
            <li data-list-text="20.17."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -48pt;text-align: left;">Kişisel veri güvenliği politika ve prosedürleri belirlenmiştir.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="20.18."><p style="padding-left: 77pt;text-indent: -49pt;text-align: left;">Kişisel veri güvenliği sorunları hızlı bir şekilde raporlanmaktadır.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="20.19."><p style="padding-left: 77pt;text-indent: -48pt;text-align: left;">Kişisel veri güvenliğinin takibi yapılmaktadır.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="20.20."><p style="padding-left: 77pt;text-indent: -51pt;line-height: 112%;text-align: justify;">Kişisel veri içeren fiziksel ortamlara giriş çıkışlarla ilgili gerekli
                güvenlik önlemleri alınmaktadır.</p></li>
            <li data-list-text="20.21."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -48pt;line-height: 112%;text-align: justify;">Kişisel veri içeren fiziksel ortamların dış risklere
                (yangın, sel vb.) karşı güvenliği sağlanmaktadır.</p></li>
            <li data-list-text="20.22."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -50pt;text-align: left;">Kişisel veri içeren ortamların güvenliği sağlanmaktadır.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="20.23."><p style="padding-left: 77pt;text-indent: -50pt;text-align: left;">Kişisel veriler mümkün olduğunca azaltılmaktadır.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="20.24."><p style="padding-left: 77pt;text-indent: -51pt;line-height: 112%;text-align: justify;">Kişisel veriler yedeklenmekte ve yedeklenen kişisel verilerin güvenliği
                de sağlanmaktadır.</p></li>
            <li data-list-text="20.25."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -50pt;line-height: 112%;text-align: justify;">Kullanıcı hesap yönetimi ve yetki kontrol sistemi
                uygulanmakta olup bunların takibi de yapılmaktadır.</p></li>
            <li data-list-text="20.26."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -51pt;line-height: 112%;text-align: justify;">Kurum içi periyodik ve/veya rastgele denetimler
                yapılmakta ve yaptırılmaktadır.</p></li>
            <li data-list-text="20.27."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -50pt;text-align: left;">Log kayıtları kullanıcı müdahalesi olmayacak şekilde tutulmaktadır.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="20.28."><p style="padding-left: 77pt;text-indent: -51pt;text-align: left;">Mevcut risk ve tehditler belirlenmiştir.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="20.29."><p style="padding-left: 77pt;text-indent: -51pt;line-height: 112%;text-align: justify;">Özel nitelikli kişisel veri güvenliğine yönelik protokol ve prosedürler
                belirlenmiş ve uygulanmaktadır.</p></li>
            <li data-list-text="20.30."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -51pt;text-align: left;">Özel nitelikli kişisel veriler elektronik posta yoluyla gönderilecekse mutlaka şifreli olarak ve KEP veya kurumsal posta hesabı kullanılarak
                    gönderilmektedir.</p></li>
            <li data-list-text="20.31."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -48pt;line-height: 112%;text-align: left;">Özel nitelikli kişisel veriler için güvenli şifreleme /
                kriptografik anahtarlar kullanılmakta ve farklı birimlerce yönetilmektedir.</p></li>
            <li data-list-text="20.32."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -50pt;text-align: left;">Saldırı tespit ve önleme sistemleri kullanılmaktadır.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="20.33."><p style="padding-left: 77pt;text-indent: -50pt;text-align: left;">Sızma testi uygulanmaktadır.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="20.34."><p style="padding-left: 77pt;text-indent: -51pt;line-height: 112%;text-align: left;">Siber güvenlik önlemleri alınmış olup uygulanması sürekli takip
                edilmektedir.</p></li>
            <li data-list-text="20.35."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -50pt;text-align: left;">Şifreleme yapılmaktadır.</p>
                <p style="text-indent: 0pt;text-align: left;"><br/></p></li>
            <li data-list-text="20.36."><p style="padding-left: 77pt;text-indent: -51pt;line-height: 112%;text-align: left;">Veri işleyen hizmet sağlayıcılarının veri güvenliği konusunda belli
                aralıklarla denetimi sağlanmaktadır.</p></li>
            <li data-list-text="20.37."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -51pt;line-height: 112%;text-align: left;">Veri işleyen hizmet sağlayıcılarının, veri güvenliği
                konusunda farkındalığı sağlanmaktadır.</p></li>
            <li data-list-text="20.38."><p style="padding-top: 10pt;padding-left: 77pt;text-indent: -51pt;text-align: left;">Veri kaybı önleme yazılımları kullanılmaktadır.</p></li>
        </ol>
    </li>
</ol>
<p style="text-indent: 0pt;text-align: left;"><br/></p>
<h1 style="padding-left: 5pt;text-indent: 0pt;line-height: 229%;text-align: left;">Veri Sorumlusu Unvan: Artiox Teknoloji Yazılım Anonim Şirketi</h1>
<h1> Mersis no:              <span class="s1">   </span>0085093196500001
</h1>
<p style="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;"><a class="a" href="mailto:info@artiox.com" target="_blank">E-posta adresi : </a><a href="mailto:info@artiox.com"
                                                                                                                                                                     target="_blank">info&#64;artiox.com</a>
</p>
<p style="text-indent: 0pt;text-align: left;"><br/></p>
<h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Kayıtlı Elektronik Posta Adresi:</h1>
<p style="text-indent: 0pt;text-align: left;"><br/></p>
<h1 style="padding-left: 5pt;text-indent: 0pt;line-height: 185%;text-align: left;">Fiziki Posta adresi : Mustafa Kemal Mah. Dumlıpınar Blv. No:280 G İç Kapı No: 1233 Çankaya/Ankara</h1>
