import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-auction-art-product-mobile',
    templateUrl: './auction-art-product-mobile.component.html',
    styleUrls: ['./auction-art-product-mobile.component.css']
})
export class AuctionArtProductMobileComponent implements OnInit, AfterViewInit, OnDestroy {

    current_slider_index: number = 0;
    slider_window: number = 0;
    // interval: any;

    subscription_list: Subscription[] = [];

    @ViewChild('gavel') gavel: any;

    constructor(public managerService: ManagerService) {
    }

    ngOnInit(): void {
        this.current_slider_index = 0;
        if (this.managerService.auctionService.auction_product_list_ready) {
            this.slider_window = this.managerService.auctionService.active_auction_product_config.IMAGE_DESKTOP.length;
        }

        this.subscription_list.push(
            this.managerService.auctionService.active_auction_product_changed.subscribe(() => {
                if (this.managerService.auctionService.auction_product_list_ready) {
                    this.slider_window = this.managerService.auctionService.active_auction_product_config.IMAGE_DESKTOP.length;
                }
            })
        );
    }

    ngAfterViewInit(): void {
        // this.interval = setInterval(() => {
        //     this.swipeLeft();
        // }, 10000);
    }

    swipeLeft(): void {
        // clearInterval(this.interval);
        // this.interval = setInterval(() => {
        //     this.swipeLeft();
        // }, 10000);
        this.current_slider_index += 1;
        this.current_slider_index += this.slider_window;
        this.current_slider_index %= this.slider_window;
    }

    swipeRight(): void {
        // clearInterval(this.interval);
        // this.interval = setInterval(() => {
        //     this.swipeLeft();
        // }, 10000);
        this.current_slider_index -= 1;
        this.current_slider_index += this.slider_window;
        this.current_slider_index %= this.slider_window;
    }

    ngOnDestroy(): void {
        this.subscription_list.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }
}
