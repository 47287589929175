import {Component, ElementRef, OnInit, ViewEncapsulation} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-art-product-report-list-mobile',
  templateUrl: './art-product-report-list-mobile.component.html',
  styleUrls: ['./art-product-report-list-mobile.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ArtProductReportListMobileComponent implements OnInit {

  constructor(public managerService: ManagerService, public element: ElementRef, public router: Router) {
    this.element.nativeElement.addEventListener('click', (el: any) => {
      if (el.target.id === 'art_product_info_container') {
        this.managerService.art_product_info_display_status = false;
      }
    });
  }

  ngOnInit(): void {
  }

}
