import {AfterViewInit, Component, ElementRef, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import {ModalService} from '../modal.service';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements AfterViewInit {
    public element: any = undefined;
    @Input() id: string = '';
    @Input() drawer: boolean = false;

    @Input() time_threshold: number = -1;
    @Input() close_disabled: boolean = false;
    @Input() background_touch_close_disabled: boolean = false;

    @ViewChild('parent') parent: any = undefined;
    @ViewChild('child') child: any = undefined;

    private close_enabled: boolean = true;
    private close_asap: boolean = false;
    private active: boolean = false;

    constructor(public modalService: ModalService, public el: ElementRef) {
        this.element = el.nativeElement;
    }

    ngAfterViewInit(): void {
        this.element.addEventListener('click', (el: any) => {
            if (this.close_enabled && !this.background_touch_close_disabled && el.target === this.parent.nativeElement) {
                this.close();
            }
        });

        if (this.drawer) {
            this.parent.nativeElement.classList.add('drawer-native');
            this.parent.nativeElement.classList.remove('modal-native');
            this.child.nativeElement.classList.add('drawer-content-native');
            this.child.nativeElement.classList.remove('modal-content-native');
        }

        this.modalService.add(this);
    }

    isActive(): any {
        return this.active;
    }

    open(): void {
        if (this.time_threshold > 0) {
            this.close_enabled = false;
            this.close_asap = false;
            setTimeout(() => {
                this.close_enabled = true;
                if (this.close_asap) {
                    this.close();
                }
            }, this.time_threshold);
        }
        this.element.classList.remove('d-none');
        this.active = true;
    }

    close(): void {
        if (this.close_disabled) {
            return;
        }
        if (this.close_enabled) {
            this.element.classList.add('d-none');
            this.active = false;
        } else {
            this.close_asap = true;
        }
    }

}
