import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';

@Component({
  selector: 'app-art-product-repost-list-desktop',
  templateUrl: './art-product-repost-list-desktop.component.html',
  styleUrls: ['./art-product-repost-list-desktop.component.css']
})
export class ArtProductRepostListDesktopComponent implements OnInit {

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

}
