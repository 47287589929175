<div *ngIf="!managerService.environment.app | managerPipe" class="w-100 d-flex flex-column">
  <div class="w-100 d-flex flex-column">
    <div class="w-100 d-flex flex-column" style="background-color: #2f3449;">
      <div class="my-3 ml-4" style="font-family: Roboto, sans-serif; line-height: 1">
        <span style="color: #ffffff; font-size: 15px; font-weight: normal; letter-spacing: 1.55px;">{{managerService.config.DASHBOARD.WELCOME | managerPipe}}<br></span>
        <span style="color: #ffffff; font-size: 12px; font-weight: 300; letter-spacing: 1.25px;">{{managerService.userService.user?.email | managerPipe}}</span>
      </div>
    </div>
    <div class="w-100 d-flex flex-column" style="color: #FFFFFF; background-color: #272a3b;">
      <div class="w-100" style="min-height: 180px;">
        <canvas #user_balance_history_chart_canvas style="z-index: 1"></canvas>
      </div>
      <div class="w-100 d-flex flex-row align-items-center justify-content-center">
        <div (click)="managerService.navigationManager({url: '/account/deposit', current_component_index: 0})" class="transaction-button" style="color: #272a3b">
          <svg style="height:20px; width: 20px; margin-right: 4px;">
            <use [attr.xlink:href]='"./assets/images/deposit.svg#deposit"' class="w-100" fill="#272a3b"/>
          </svg>
          {{managerService.config.DASHBOARD.DEPOSIT| managerPipe}}
        </div>
        <div (click)="managerService.navigationManager({url: '/account/withdrawal', current_component_index: 0})" class="transaction-button" style="color: #272a3b">
          <svg style="height:20px; width: 20px; margin-right: 4px;">
            <use [attr.xlink:href]='"./assets/images/withdrawal.svg#withdrawal"' class="w-100" fill="#272a3b"/>
          </svg>
          {{managerService.config.DASHBOARD.WITHDRAWAL| managerPipe}}
        </div>
      </div>
    </div>
  </div>
  <div class="w-100"
       style="box-shadow: 2px 2px 9px 0 rgba(193, 196, 206, 0.5); background-color: #fcfdff; max-height: 500px; min-width: 300px;">
    <div class="d-flex flex-column align-items-start justify-content-start overflow-auto" style="height: 400px;">
      <div class="w-100 mt-4 mb-1 pl-3"
           style="font-family: bebas-neue-regular, sans-serif; font-size: 22px; font-weight: bold; line-height: 1.1; letter-spacing: 1.8px; color: #404352;">
        {{managerService.config.DASHBOARD.LAST_ACTIVITIES | managerPipe}}</div>
      <div
        *ngFor="let user_activity of (managerService.user_activity_records_pipe | managerPipe : managerService.mpa_template.GET_USER_ACTIVITY_RECORDS)"
        class="w-100 d-flex flex-row pl-3 mb-1" style="font-family: Roboto, sans-serif;font-size: 13px; font-weight: 300; line-height: 1.3; letter-spacing: 0.65px; color: #404352">
        <div style="width: 80%;">
          <div [class]="(user_activity.attempt  | managerPipe ) ? 'color-success' : 'color-fail'" class="mb-1">
            <span [class]="(user_activity.attempt | managerPipe) ? 'success-icon':'fail-icon'"></span>
            <span style="font-weight: bold;">
              {{user_activity | managerPipe: managerService.mpa_template.GET_USER_ACTIVITY_TITLE: managerService.language_pipe}}
            </span>
          </div>
          <div class="ml-3" style="line-height: 1.1;">
            <div class="w-100">{{user_activity.ip | managerPipe}}</div>
            <div class="w-100">{{(managerService.config.ACTIVITY_TABLE | managerPipe:managerService.mpa_template.RETURN_INDEX: user_activity.code).reason | managerPipe}}</div>
            <div
              class="w-100">{{user_activity.creation_time | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}, {{user_activity.city | managerPipe}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<div *ngIf="managerService.environment.app | managerPipe" class="w-100 d-flex flex-column">-->
<!--  <div class="w-100 d-flex flex-column">-->
<!--    <div class="position-relative pt-3 pl-3" style="color: #ffffff; background-color: #272a3b;">-->
<!--        <span style="color: #ffffff; font-size: 12px; font-weight: 300;">{{managerService.userService.user?.email | managerPipe}}</span><br>-->
<!--        <span style="font-family: Roboto, sans-serif; font-size: 12px; letter-spacing: 0.35px;">-->
<!--          {{managerService.config.BALANCE.TOTAL_BALANCE | managerPipe}}-->
<!--        </span>-->
<!--      <div style="font-family: 'Roboto Condensed', sans-serif; font-size: 18px; letter-spacing: 0.9px;">-->
<!--        <span>-->
<!--          {{!balance_visible ? ('***' | managerPipe) :-->
<!--          (managerService.user_pipe | managerPipe: managerService.mpa_template.GET_USER_TOTAL_ART_PRODUCT_TYPE_OF_CURRENCY: managerService.pair_pipe: managerService.currency_pair_pipe) + (managerService.user_pipe | managerPipe: managerService.mpa_template.GET_USER_TOTAL_CURRENCY_TYPE_OF_CURRENCY: managerService.pair_pipe: managerService.currency_pair_pipe) | managerPipe: managerService.mpa_template.FANCY_NUMBER}}-->
<!--          ₺-->
<!--        </span>-->
<!--        <svg (click)="balance_visible = !balance_visible" class="password-eye">-->
<!--          <use *ngIf="(!balance_visible | managerPipe)" [attr.xlink:href]='"./assets/images/eye_hidden.svg#eye_hidden"' fill="#9DA5B7"/>-->
<!--          <use *ngIf="(balance_visible | managerPipe)" [attr.xlink:href]='"./assets/images/eye_visible.svg#eye_visible"' fill="#9DA5B7"/>-->
<!--        </svg>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="position-relative w-100 d-flex flex-column" style="color: #FFFFFF; background-color: #272a3b;">-->
<!--      <div class="w-100" style="min-height: 180px; margin-bottom: 28px">-->
<!--        <canvas #user_balance_history_chart_canvas style="z-index: 1"></canvas>-->
<!--      </div>-->
<!--      <div class="position-absolute w-100 d-flex flex-row align-items-center justify-content-center" style="bottom: -32px">-->
<!--        <div (click)="managerService.navigationManager({url: '/account/deposit', current_component_index: 0})" class="transaction-button" style="box-shadow: 0 3px 4px 0 rgba(213, 213, 213, 0.83);">-->
<!--          <svg style="height:20px; width: 20px; margin-right: 4px;">-->
<!--            <use [attr.xlink:href]='"./assets/images/deposit.svg#deposit"' class="w-100" fill="#9da5b7"/>-->
<!--          </svg>-->
<!--          {{managerService.config.DASHBOARD.DEPOSIT| managerPipe}}-->
<!--        </div>-->
<!--        <div (click)="managerService.navigationManager({url: '/account/withdrawal', current_component_index: 0})" class="transaction-button" style="box-shadow: 0 3px 4px 0 rgba(213, 213, 213, 0.83);">-->
<!--          <svg style="height:20px; width: 20px; margin-right: 4px;">-->
<!--            <use [attr.xlink:href]='"./assets/images/withdrawal.svg#withdrawal"' class="w-100" fill="#9da5b7"/>-->
<!--          </svg>-->
<!--          {{managerService.config.DASHBOARD.WITHDRAWAL| managerPipe}}-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="w-100"-->
<!--       style="box-shadow: 2px 2px 9px 0 rgba(193, 196, 206, 0.5); background-color: #fcfdff; max-height: 500px; min-width: 300px;">-->
<!--    <div class="d-flex flex-column align-items-start justify-content-start overflow-auto" style="height: 400px;">-->
<!--      <div class="w-100 mb-1 pl-3"-->
<!--           style="margin-top: 36px; font-family: bebas-neue-regular, sans-serif; font-size: 22px; font-weight: bold; line-height: 1.1; letter-spacing: 1.8px; color: #404352;">-->
<!--        {{managerService.config.DASHBOARD.LAST_ACTIVITIES | managerPipe}}</div>-->
<!--      <div-->
<!--        *ngFor="let user_activity of (managerService.user_activity_records_pipe | managerPipe : managerService.mpa_template.GET_USER_ACTIVITY_RECORDS)"-->
<!--        class="w-100 d-flex flex-row pl-3 mb-1 flex-shrink-0" style="font-family: Roboto, sans-serif;font-size: 13px; font-weight: 300; line-height: 1.3; letter-spacing: 0.65px; color: #404352">-->
<!--        <div style="width: 80%;">-->
<!--          <div [class]="(user_activity.attempt  | managerPipe ) ? 'color-success' : 'color-fail'" class="mb-1">-->
<!--            <span [class]="(user_activity.attempt | managerPipe) ? 'success-icon':'fail-icon'"></span>-->
<!--            <span style="font-weight: bold;">-->
<!--              {{user_activity | managerPipe: managerService.mpa_template.GET_USER_ACTIVITY_TITLE: managerService.language_pipe}}-->
<!--            </span>-->
<!--          </div>-->
<!--          <div class="ml-3" style="line-height: 1.1;">-->
<!--            <div class="w-100">{{user_activity.ip | managerPipe}}</div>-->
<!--            <div class="w-100">{{(managerService.config.ACTIVITY_TABLE | managerPipe:managerService.mpa_template.RETURN_INDEX: user_activity.code).reason | managerPipe}}</div>-->
<!--            <div-->
<!--              class="w-100">{{user_activity.creation_time | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}, {{user_activity.city | managerPipe}}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
