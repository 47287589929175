<h1 class="btn-native w-100 m-auto pl-4 py-3 font-weight-bold"
    style="font-family: Roboto, sans-serif; font-size: 14px; letter-spacing: 0.4px; color: #d9dbe4;">
  {{managerService.config.ADVANCED.USER_TRADE_HISTORY.HEADING | managerPipe}}
</h1>
<div class="d-flex flex-row align-items-center justify-content-around py-1"
     style="font-family: Roboto, sans-serif; font-size: 10px; color: #d9dbe4;">
  <span class="pl-4 text-left"
        style="width: 26%">{{managerService.config.ADVANCED.USER_TRADE_HISTORY.PAIR | managerPipe}}</span>
  <span class="text-center"
        style="width: 18%">{{managerService.config.ADVANCED.USER_TRADE_HISTORY.TYPE | managerPipe}}</span>
  <span class="text-center"
        style="width: 18%">{{managerService.config.ADVANCED.USER_TRADE_HISTORY.PRICE | managerPipe}}</span>
  <span class="text-center"
        style="width: 18%">{{managerService.config.ADVANCED.USER_TRADE_HISTORY.AMOUNT | managerPipe}}</span>
  <span class="pr-4 text-right" style="width: 20%"></span>
</div>
<div class="d-flex flex-column overflow-auto mb-5" style="min-height: 160px; max-height: 160px;">
  <div
    *ngFor="let user_trade of (managerService.user_trade_history_pipe | managerPipe: managerService.mpa_template.GET_USER_TRADE_HISTORY)"
    class="d-flex flex-row align-items-center justify-content-around flex-wrap py-1 user-trade-history flex-shrink-0"
    style="font-family: Roboto, sans-serif; font-size: 10px; color: #d9dbe4; line-height: 1.2;">
    <span class="pl-4 text-left"
          style="width: 26%;">{{user_trade.pair_id | managerPipe: managerService.mpa_template.PAIR_SYMBOL: managerService.pair_pipe}}</span>
    <span [style]="user_trade | managerPipe: managerService.mpa_template.GET_USER_TRADE_BACKGROUND_COLOR"
          class="text-center"
          style="width: 18%">{{user_trade.side | managerPipe: managerService.mpa_template.USER_TRADE_TYPE}}</span>
    <span class="text-center"
          style="width: 18%">{{user_trade.price | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
    <span class="text-center"
          style="width: 18%">{{user_trade.amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
    <span (click)="more_div.classList.toggle('d-none'); more_div.classList.toggle('d-flex')" class="pr-4 text-right"
          style="width: 20%">{{managerService.config.ADVANCED.USER_TRADE_HISTORY.MORE | managerPipe}}</span>
    <div #more_div class="w-100 d-none flex-row flex-wrap align-items-center justify-content-around py-1"
         style="font-family: Roboto, sans-serif; font-size: 10px; color: #d9dbe4; line-height: 1.2;">
      <span class="w-50 pl-4 my-1 text-left">{{managerService.config.ADVANCED.USER_TRADE_HISTORY.TOTAL | managerPipe}}</span>
      <span class="w-50 pr-4 my-1 text-right">{{user_trade.amount * user_trade.price | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
      <span class="w-50 pl-4 my-1 text-left">{{managerService.config.ADVANCED.USER_TRADE_HISTORY.TIME | managerPipe}}</span>
      <span class="w-50 pr-4 my-1 text-right">{{user_trade.creation_time | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</span>
    </div>
  </div>
</div>
