<div class="w-100 d-flex flex-column justify-content-start align-items-center"
     style="background-image: radial-gradient(circle 1000px at 72.3% 1991.3%, #1e2028 0%, #21232d 100%); z-index: 1; min-height: 600px;">
  <svg class="position-absolute w-100" style="z-index: 0; height: 500px!important;">
    <use [attr.xlink:href]='"./assets/images/landing_page_bg_texture.svg#landing_page_bg_texture"' class="h-100" fill="#2a2b35"
         transform="translate(-400 0)"/>
  </svg>
  <form [formGroup]="loginForm" class="d-flex flex-column align-items-center justify-content-center m-4 p-4" style="width: 50%; height: fit-content; z-index: 2">
    <div class="w-100 p-3 text-center" style="font-family: Raleway,sans-serif; font-size: 40px; line-height: 1.2; letter-spacing: 4px; color: #ffffff;">
      {{managerService.config.LOGIN.WELCOME_TO_ARTIOX}}
    </div>
    <div class="d-flex flex-column" style="width: 40%; min-width: 240px;">
      <div class="w-100 pt-5 text-center" style="font-family: bebas-neue-regular, sans-serif;
     font-size: 36px; font-weight: bold; line-height: 1.2; letter-spacing: 4.5px; color: #f4f5f9;">
        {{managerService.config.LOGIN.LOGIN}}
      </div>
      <div *ngIf="login_step === 0 | managerPipe" class="w-100 d-flex flex-column">
        <span class='input-heading' style="color: #f4f5f9">{{managerService.config.LOGIN.E_MAIL | managerPipe}}</span>
        <label>
          <input class="input-field" formControlName="email" placeholder="{{managerService.config.LOGIN.E_MAIL_PLACEHOLDER | managerPipe}}"
                 required style="border-radius: 4px; border: solid 1px #ffffff; background-color: #ffffff; box-shadow: none;"
                 type="email">
        </label>
        <span class='input-heading' style="color: #f4f5f9">{{managerService.config.LOGIN.PASSWORD | managerPipe}}</span>
        <label class="position-relative">
          <svg (click)="password_visible = !password_visible" class="password-eye">
            <use *ngIf="(!password_visible | managerPipe)" [attr.xlink:href]='"./assets/images/eye_hidden.svg#eye_hidden"' fill="#9DA5B7"/>
            <use *ngIf="(password_visible | managerPipe)" [attr.xlink:href]='"./assets/images/eye_visible.svg#eye_visible"' fill="#9DA5B7"/>
          </svg>
          <input [type]="(password_visible | managerPipe) ? 'text' : 'password'" class="input-field"
                 formControlName="password"
                 placeholder="{{managerService.config.LOGIN.PASSWORD_PLACEHOLDER | managerPipe}}"
                 required style="border-radius: 4px; border: solid 1px #ffffff; background-color: #ffffff; box-shadow: none; padding-right: 40px;">
        </label>
      </div>
      <div *ngIf="login_step === 1 | managerPipe" class="w-100 d-flex flex-column">
        <span class='input-heading' style="color: #f4f5f9">{{managerService.config.LOGIN.AUTH | managerPipe}}</span>
        <label>
          <input class="input-field" formControlName="auth_password" placeholder="{{managerService.config.LOGIN.AUTH_PLACEHOLDER | managerPipe}}"
                 required
                 style="border-radius: 4px; border: solid 1px #ffffff; background-color: #ffffff; box-shadow: none;" type="text">
        </label>
      </div>
      <button (click)="this.managerService.openModal('forgot-password-modal');" class="btn-native my-1" style="color: #4bc4d6; padding: 0 20px;" type="button">
        {{managerService.config.LOGIN.FORGOT_PASSWORD | managerPipe}}
      </button>
      <button (click)="loginFormValidator()" class="btn-native" id="submit-button">
        {{managerService.config.LOGIN.SUBMIT | managerPipe}}
      </button>
      <div #warning class="d-none warning" style="margin: 10px 20px 0"></div>
    </div>
  </form>

</div>
