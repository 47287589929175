<div class="d-flex flex-row align-items-center justify-content-around py-1" style="font-family: Roboto, sans-serif; font-size: 10px; color: #d9dbe4;">
  <span class="text-center" style="width: 33%">{{managerService.config.ADVANCED.ORDER_BOOK.PRICE | managerPipe}}</span>
  <span class="text-center" style="width: 34%">{{managerService.config.ADVANCED.ORDER_BOOK.AMOUNT | managerPipe}}</span>
  <span class="text-center" style="width: 33%">{{managerService.config.ADVANCED.ORDER_BOOK.TOTAL | managerPipe}}</span>
</div>
<div class="d-flex flex-column-reverse overflow-auto" style="min-height: 160px; max-height: 160px;">
  <div *ngFor="let order of (managerService.order_book_pipe | managerPipe: managerService.mpa_template.GET_SELL_ORDER_BOOK)"
       [style]="order | managerPipe: managerService.mpa_template.GET_SELL_ORDER_BOOK_BACKGROUND_COLOR_MOBILE" class="d-flex flex-row align-items-center justify-content-around py-1"
       style="font-family: Roboto, sans-serif; font-size: 10px; color: #d9dbe4; line-height: 1.2;">
    <span (click)="managerService.set_form_price.emit(order.price)" class="text-center" style="width: 33%">{{order.price | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
    <span class="text-center" style="width: 34%">{{order.amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
    <span class="text-center" style="width: 33%">{{order.price * order.amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
  </div>
</div>
<div class="d-flex flex-row align-items-center justify-content-around my-1 py-1" style="font-family: sfui-text-regular, sans-serif; font-size: 10px; color: #d9dbe4; line-height: 1.2;">
  <span class="mx-1 w-50 text-right">{{managerService.config.ADVANCED.ORDER_BOOK.LAST_PRICE | managerPipe}}</span>
  <span [style]="managerService.trade_history_pipe | managerPipe: managerService.mpa_template.LAST_PRICE_COLOR"
        class="mx-1 w-50 text-left" style="font-family: sfui-text-regular, sans-serif; font-size: 10px; color: #d9dbe4;">
    {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_PRICE}}
  </span>
</div>
<div class="d-flex flex-column overflow-auto mb-2" style="min-height: 160px; max-height: 160px;">
  <div *ngFor="let order of (managerService.order_book_pipe | managerPipe: managerService.mpa_template.GET_BUY_ORDER_BOOK)"
       [style]="order | managerPipe: managerService.mpa_template.GET_BUY_ORDER_BOOK_BACKGROUND_COLOR_MOBILE" class="d-flex flex-row align-items-center justify-content-around py-1"
       style="font-family: Roboto, sans-serif; font-size: 10px; color: #d9dbe4; line-height: 1.2;">
    <span (click)="managerService.set_form_price.emit(order.price)" class="text-center" style="width: 33%">{{order.price | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
    <span class="text-center" style="width: 34%">{{order.amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
    <span class="text-center" style="width: 33%">{{order.price * order.amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
  </div>
</div>
