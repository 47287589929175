<div class="d-flex flex-row align-items-center justify-content-around py-1 my-1 exo caption bold"
     style=" line-height: 2.7;  color: #4a4e70;">
  <span class="pl-4 text-center"
        style="width: 16%">{{managerService.config.ADVANCED.USER_TRADE_HISTORY.PAIR | managerPipe}}</span>
  <span class="text-center"
        style="width: 16%">{{managerService.config.ADVANCED.USER_TRADE_HISTORY.TYPE | managerPipe}}</span>
  <span class="text-center"
        style="width: 12%">{{managerService.config.ADVANCED.USER_TRADE_HISTORY.PRICE | managerPipe}}</span>
  <span class="text-center"
        style="width: 16%">{{managerService.config.ADVANCED.USER_TRADE_HISTORY.AMOUNT | managerPipe}}</span>
  <span class="text-center"
        style="width: 16%">{{managerService.config.ADVANCED.USER_TRADE_HISTORY.TOTAL | managerPipe}}</span>
  <span class="text-center"
        style="width: 20%">{{managerService.config.ADVANCED.USER_TRADE_HISTORY.TIME | managerPipe}}</span>
</div>
<div class="d-flex flex-column overflow-auto" style="min-height: 200px; max-height: 200px;">
  <div
    *ngFor="let user_trade of (managerService.user_trade_history_pipe | managerPipe: managerService.mpa_template.GET_USER_TRADE_HISTORY)"
    class="d-flex flex-row align-items-center justify-content-around flex-wrap user-trade-history flex-shrink-0 barlow caption medium"
    style=" line-height: 2.7;  color: #4a4e70; padding: 2px 0; height: fit-content;">
    <span class="pl-4 text-center"
          style="width: 16%;">{{user_trade.pair_id | managerPipe: managerService.mpa_template.PAIR_SYMBOL: managerService.pair_pipe}}</span>
    <span [style]="user_trade | managerPipe: managerService.mpa_template.GET_USER_TRADE_BACKGROUND_COLOR"
          class="text-center"
          style="width: 16%">{{user_trade.side | managerPipe: managerService.mpa_template.USER_TRADE_TYPE}}</span>
    <span class="text-center"
          style="width: 12%">{{user_trade.price | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
    <span class="text-center"
          style="width: 16%">{{user_trade.amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
    <span class="text-center"
          style="width: 16%">{{user_trade.amount * user_trade.price | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
    <span class="text-center"
          style="width: 20%">{{user_trade.creation_time | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</span>
  </div>
</div>
