import {EventEmitter, Injectable, Output} from '@angular/core';
// import * as moment from 'moment/moment';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

export enum CatalogSortingTypeTemplate {
    DEFAULT,
    PRICE_INCREASING,
    PRICE_DECREASING,
    ACTIVE_PRICE_INCREASING,
    ACTIVE_PRICE_DECREASING,
    LOT_NUMBER_INCREASING,
    LOT_NUMBER_DECREASING
}

export class AuctionProduct {
    public id: number;
    public name: string;
    public year: number;
    public catalog: string;
    public lot: number;
    public hash: string;

    public opening_value: number;
    public current_value: number;
    public value_step: number;
    public value_type: string;

    // public auction_start_date: string;
    // public auction_end_date: string;
    // public auction_init_end_date: string;
    public auction_start_date: number;
    public auction_end_date: number;
    public auction_init_end_date: number;
    public end_date_increment: number;

    public featured: boolean;
    public auction_state: 'auction' | 'live' | 'knockdown';
    public auction_status: string;

    public assurance: number;
    public buyer_fee: number;
    public seller_fee: number;
    public tax: number;

    public active_bid_price: number;
    public active_bid_price_pipe: boolean;

    // public creation_time: number;
    public creation_time: number;

    constructor(pair_primitive: any) {
        this.id = pair_primitive.id;
        this.name = pair_primitive.name;
        this.year = pair_primitive.year;
        if (pair_primitive.catalog === 'Daily') {
            this.catalog = `${pair_primitive.year}_DAILY`;
        } else {
            this.catalog = `${pair_primitive.year}_${pair_primitive.catalog}`.replace(/\s/g, '_').toUpperCase();
        }
        this.lot = pair_primitive.lot;
        this.hash = `${pair_primitive.year}_${pair_primitive.catalog}_${this.lot}`.replace(/\s/g, '_').toUpperCase();

        this.opening_value = pair_primitive.opening_value;
        this.current_value = pair_primitive.current_value;
        this.value_step = pair_primitive.value_step;
        this.value_type = pair_primitive.value_type;

        // this.auction_start_date = moment(pair_primitive.auction_start_date / 1000000).toISOString();
        // this.auction_end_date = moment(pair_primitive.auction_end_date / 1000000).toISOString();
        // this.auction_init_end_date = moment(pair_primitive.auction_init_end_date / 1000000).toISOString();
        this.auction_start_date = pair_primitive.auction_start_date / 1000000;
        this.auction_end_date = pair_primitive.auction_end_date / 1000000;
        this.auction_init_end_date = pair_primitive.auction_init_end_date / 1000000;
        this.end_date_increment = pair_primitive.end_date_increment;

        this.featured = pair_primitive.featured;
        this.auction_state = pair_primitive.auction_state;
        this.auction_status = pair_primitive.auction_status;

        this.assurance = pair_primitive.assurance;
        this.buyer_fee = pair_primitive.buyer_fee;
        this.seller_fee = pair_primitive.seller_fee;
        this.tax = pair_primitive.tax;

        this.creation_time = pair_primitive.creation_time / 1000000;

        this.active_bid_price = this.getMinBid();
        this.active_bid_price_pipe = false;
    }

    getValue(): number {
        if (!this.hasBids()) {
            return this.opening_value;
        } else {
            return this.current_value;
        }
    }

    getMinBid(): number {
        if (!this.hasBids()) {
            return this.opening_value;
        } else {
            return this.current_value + this.value_step;
        }
    }

    setActiveBidPrice(price: number): void {
        this.active_bid_price = price;
        this.active_bid_price_pipe = !this.active_bid_price_pipe;
    }

    getCommission(): number {
        return this.getValue() * this.buyer_fee;
    }

    getTax(): number {
        return (this.getValue() + this.getCommission()) * this.tax;
    }

    getTotalPrice(): number {
        return this.getValue() + this.getCommission() + this.getTax();
    }

    isStarted(): boolean {
        return this.auction_state !== 'auction';
    }

    isFinished(): boolean {
        return this.auction_state === 'knockdown';
    }

    hasBids(): boolean {
        return this.current_value !== 0;
    }
}

export class AuctionCatalog {
    public hash: string;

    public auction_product_list: { string: AuctionProduct } = {} as { string: AuctionProduct };
    // public auction_product_list_pipe: boolean = false;  // move here to lighten

    // public auction_start_date: string = '';
    // public auction_end_date: string = '';
    public auction_start_date: number = 0;
    public auction_end_date: number = 0;

    public is_daily: boolean = false;

    constructor(catalog_primitive: any) {
        this.hash = catalog_primitive.hash;
        if (this.hash.endsWith('_DAILY')) {
            this.is_daily = true;
        }
    }

    addAuctionProduct(auction_product: AuctionProduct): void {  // overwrites if exists
        // @ts-ignore
        this.auction_product_list[auction_product.hash] = auction_product;
        // this.auction_product_list_pipe = !this.auction_product_list_pipe;

        if (auction_product.lot === 1) {
            this.auction_start_date = auction_product.auction_start_date;
            this.auction_end_date = auction_product.auction_init_end_date;
        }
    }

    getAuctionProductByHash(auction_product_hash: string): AuctionProduct {
        // @ts-ignore
        return this.auction_product_list[auction_product_hash];
    }

    getAuctionProductById(auction_product_id: number): AuctionProduct {
        for (const auction_product of this.getAuctionProductArray()) {
            if (auction_product.id === auction_product_id) {
                return auction_product;
            }
        }

        return AuctionService.empty_auction_product;
    }

    getAuctionProductArray(): AuctionProduct[] {
        return Object.values(this.auction_product_list);
    }

    isStarted(): boolean {
        for (const key in this.auction_product_list) {
            // @ts-ignore
            if (this.auction_product_list[key].auction_state.isStarted()) {  // at least one started
                return true;
            }
        }
        return false;

    }

    isLive(): boolean {
        for (const key in this.auction_product_list) {
            // @ts-ignore
            if (this.auction_product_list[key].auction_state === 'live') {  // at least one live
                return true;
            }
        }
        return false;

    }

    isFinished(): boolean {
        for (const key in this.auction_product_list) {
            // @ts-ignore
            if (this.auction_product_list[key].auction_state !== 'knockdown') {  // at least one not finished
                return false;
            }
        }
        return true;

    }
}

@Injectable({
    providedIn: 'root'
})
export class AuctionService {
    static empty_auction_product: AuctionProduct = new AuctionProduct(
        {
            id: -1,
            name: '',
            year: 0,
            catalog: '',
            lot: 0,
            opening_value: 0,
            current_value: 0,
            value_step: 0,
            value_type: '',
            auction_start_date: 0,
            auction_end_date: 0,
            auction_init_end_date: 0,
            end_date_increment: 0,
            featured: true,
            auction_state: '',
            auction_status: '',
            assurance: 0,
            buyer_fee: 0,
            seller_fee: 0,
            creation_time: 0,
        },
    );
    static empty_auction_catalog: AuctionCatalog = new AuctionCatalog(
        {
            hash: '',
        },
    );

    public auction_product_list_ready: boolean = false;

    public auction_product_list_uncatalogued: AuctionProduct[] = [];
    public started_auction_product_list_uncatalogued: AuctionProduct[] = [];
    public auction_product_list: AuctionCatalog = new AuctionCatalog({hash: '*'});
    public auction_catalog_list: { string: AuctionCatalog } = {} as { string: AuctionCatalog };

    public active_auction_product: AuctionProduct = AuctionService.empty_auction_product;
    public active_auction_catalog: AuctionCatalog = AuctionService.empty_auction_catalog;

    public auction_product_list_pipe: boolean = false;
    public active_auction_product_pipe: boolean = false;

    @Output() auction_product_list_changed: EventEmitter<any> = new EventEmitter();
    @Output() active_auction_product_changed: EventEmitter<any> = new EventEmitter();

    public active_auction_product_config: any;
    public active_auction_product_artist_config: any;
    public active_auction_catalog_config: any;

    public auction_catalog_filter: { min: any, max: any, keyword: any } = {min: -1, max: -1, keyword: ''};
    public auction_product_filter_pipe: boolean = false;

    public catalog_sorting_type_template = CatalogSortingTypeTemplate;
    public active_catalog_sorting_type: CatalogSortingTypeTemplate = CatalogSortingTypeTemplate.DEFAULT;
    public active_catalog_sorting_type_pipe: boolean = false;

    public active_bid_price: number = 0;
    public active_bid_price_pipe: boolean = false;

    public environment = environment;

    constructor(private httpClient: HttpClient) {
    }

    refreshAuctionProductList(resolve?: any): void {
        this.httpClient.get<any[]>(this.environment.serverAPI + 'get_auction_products_service').subscribe(
            response => {
                this.setAuctionProductList(response);
                if (resolve !== undefined) {
                    resolve();
                }
            });
    }

    refreshAuctionProduct(auction_product_id: number, resolve?: any): void {
        const data = {auction_product_id};
        this.httpClient.post<any>(this.environment.serverAPI + 'get_auction_product_service', data).subscribe(
            response => {

                this.setAuctionProduct(response);
                if (resolve !== undefined) {
                    resolve();
                }
            });
    }

    setAuctionProductList(auction_product_list_primitive: any[]): void {
        auction_product_list_primitive.forEach((x: any) => {
            const temp: AuctionProduct = new AuctionProduct(x);

            this.auction_product_list_uncatalogued.push(temp);
            if (temp.auction_state !== 'auction') {
                this.started_auction_product_list_uncatalogued.push(temp);
            }

            this.auction_product_list.addAuctionProduct(temp);

            // @ts-ignore
            if (this.auction_catalog_list[temp.catalog] === undefined) {
                // @ts-ignore
                this.auction_catalog_list[temp.catalog] = new AuctionCatalog({hash: temp.catalog});
            }
            // @ts-ignore
            this.auction_catalog_list[temp.catalog].addAuctionProduct(temp);
        });

        // this.cropFinishedAuctionProducts('2023_DAILY');
        // this.cropFinishedAuctionProducts('2024_DAILY');

        this.auction_product_list_ready = true;

        this.auction_product_list_changed.emit();
        this.auction_product_list_pipe = !this.auction_product_list_pipe;
    }

    setAuctionProduct(auction_product_primitive: any): void {
        const temp = new AuctionProduct(auction_product_primitive);

        for (let i = 0; i < this.auction_product_list_uncatalogued.length; i++) {
            if (temp.id === this.auction_product_list_uncatalogued[i].id) {
                this.auction_product_list_uncatalogued[i] = temp;
                break;
            }
        }

        this.auction_product_list.addAuctionProduct(temp);

        // @ts-ignore
        this.auction_catalog_list[temp.catalog].addAuctionProduct(temp);

        this.auction_product_list_changed.emit();
        this.auction_product_list_pipe = !this.auction_product_list_pipe;
    }

    getAuctionProductById(id: number){
      return this.auction_product_list_uncatalogued.find((e: AuctionProduct) => e.id === id);
    }

    setActiveAuctionProduct(auction_product: AuctionProduct): void {
        this.active_auction_product = auction_product;
        this.setActiveAuctionProductBidPrice(auction_product.active_bid_price);
        // @ts-ignore
        this.setActiveAuctionCatalog(this.auction_catalog_list[auction_product.catalog]);
    }

    setActiveAuctionCatalog(auctionCatalog: AuctionCatalog): void {
        this.active_auction_catalog = auctionCatalog;
    }

    cropFinishedAuctionProducts(catalog: AuctionCatalog): AuctionCatalog {
        const auc_prod_array = catalog.getAuctionProductArray();
        const live_auc_prod_array = auc_prod_array.filter((e) => e.auction_state === 'live');
        const last_finished_auc_prod_array = auc_prod_array.filter((e) => e.auction_state === 'knockdown').sort((x, y) => {
            return y.lot - x.lot;
        }).slice(0, 15);

        const temp: AuctionCatalog = new AuctionCatalog({hash: catalog.hash});
        for (const auc_prod of live_auc_prod_array) {
            temp.addAuctionProduct(auc_prod);
        }
        for (const auc_prod of last_finished_auc_prod_array) {
            temp.addAuctionProduct(auc_prod);
        }

        // if(!this.environment.production){  // do NOT uncomment
        //     const new_auc_prod_array = auc_prod_array.filter((e) => e.auction_state === 'auction');  // uncomment to inspect newly-addeds
        //     for (const auc_prod of new_auc_prod_array) {
        //         temp.addAuctionProduct(auc_prod);
        //     }
        // }

        return temp;
    }

    getActiveAuctionProductBidPrice(): number {
        return this.active_auction_product.active_bid_price;
    }

    setActiveAuctionProductBidPrice(price: number): void {
        this.setActiveBidPrice(price);
        this.active_auction_product.setActiveBidPrice(price);
    }

    setActiveBidPrice(bid_price: number): void {
        this.active_bid_price = bid_price;
        this.active_bid_price_pipe = !this.active_bid_price_pipe;
    }

    setActiveCatalogSortingType(option: CatalogSortingTypeTemplate): void {
        this.active_catalog_sorting_type = option;
        this.active_catalog_sorting_type_pipe = !this.active_catalog_sorting_type_pipe;
    }

    getAuctionCatalogArray(): AuctionCatalog[] {
        return Object.values(this.auction_catalog_list);
    }

    getActiveCommission(): number {
        return this.active_bid_price * this.active_auction_product.buyer_fee;
    }

    getActiveTax(): number {
        return (this.active_bid_price + this.getActiveCommission()) * this.active_auction_product.tax;
    }

    getActiveTotalPrice(): number {
        return this.active_bid_price + this.getActiveCommission() + this.getActiveTax();
    }
}
