import {test_auction_config_en} from './test_config_en';

const auction_products_config_en = {
  "2023_DAILY_1": {
    "LOT_NAME": "LOT 1",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Ali Atmaca (1947 - )",
    "ARTIST_HASH": "ALI_ATMACA",
    "NAME": "\"Soyut Figüratif\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>80 x 100 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_1/0.d.webp?w=1200&h=967",
      "./assets/auction_products/2023_DAILY_1/1.d.webp?w=1200&h=909"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_1/0.m.webp?w=559&h=450",
      "./assets/auction_products/2023_DAILY_1/1.m.webp?w=594&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_2": {
    "LOT_NAME": "LOT 2",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Mustafa Ayaz (1938 - )",
    "ARTIST_HASH": "MUSTAFA_AYAZ",
    "NAME": "\"Figüratif\"",
    "TEXT": "Signed, Mixed Technique on Cardboard<br>30 x 25 cm<br>2019",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_2/0.d.webp?w=900&h=1200",
      "./assets/auction_products/2023_DAILY_2/1.d.webp?w=800&h=1200",
      "./assets/auction_products/2023_DAILY_2/2.d.webp?w=900&h=1200",
      "./assets/auction_products/2023_DAILY_2/3.d.webp?w=1200&h=849"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_2/0.m.webp?w=337&h=450",
      "./assets/auction_products/2023_DAILY_2/1.m.webp?w=300&h=450",
      "./assets/auction_products/2023_DAILY_2/2.m.webp?w=338&h=450",
      "./assets/auction_products/2023_DAILY_2/3.m.webp?w=636&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_3": {
    "LOT_NAME": "LOT 3",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Adem Genç (1944 - )",
    "ARTIST_HASH": "ADEM_GENC",
    "NAME": "\"Soyut Geometrik\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>63,5 x 77 cm<br>2018",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_3/0.d.webp?w=1200&h=1044",
      "./assets/auction_products/2023_DAILY_3/1.d.webp?w=1200&h=998"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_3/0.m.webp?w=450&h=391",
      "./assets/auction_products/2023_DAILY_3/1.m.webp?w=450&h=374"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_4": {
    "LOT_NAME": "LOT 4",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Alev Özas (1977 - )",
    "ARTIST_HASH": "ALEV_OZAS",
    "NAME": "\"İstanbul\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>100 x 80 cm<br>2020",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_4/0.d.webp?w=963&h=1200",
      "./assets/auction_products/2023_DAILY_4/1.d.webp?w=999&h=1199"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_4/0.m.webp?w=361&h=450",
      "./assets/auction_products/2023_DAILY_4/1.m.webp?w=375&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_5": {
    "LOT_NAME": "LOT 5",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Fikret Mualla (1903 - 1967)",
    "ARTIST_HASH": "FIKRET_MUALLA",
    "NAME": "\"La Conversation\"",
    "TEXT": "Signed, Gouache on Cardboard<br>19,5 x 24,5 cm<br>1955",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_5/0.d.webp?w=1200&h=1033",
      "./assets/auction_products/2023_DAILY_5/1.d.webp?w=1200&h=889",
      "./assets/auction_products/2023_DAILY_5/2.d.webp?w=1200&h=1089",
      "./assets/auction_products/2023_DAILY_5/3.d.webp?w=971&h=1200",
      "./assets/auction_products/2023_DAILY_5/4.d.webp?w=880&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_5/0.m.webp?w=523&h=450",
      "./assets/auction_products/2023_DAILY_5/1.m.webp?w=607&h=450",
      "./assets/auction_products/2023_DAILY_5/2.m.webp?w=497&h=450",
      "./assets/auction_products/2023_DAILY_5/3.m.webp?w=364&h=450",
      "./assets/auction_products/2023_DAILY_5/4.m.webp?w=330&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_6": {
    "LOT_NAME": "LOT 6",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Ahmet Oran (1957 - )",
    "ARTIST_HASH": "AHMET_ORAN",
    "NAME": "\"Soyut\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>120 x 100 cm<br>2022",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_6/0.d.webp?w=946&h=1200",
      "./assets/auction_products/2023_DAILY_6/1.d.webp?w=942&h=1200",
      "./assets/auction_products/2023_DAILY_6/2.d.webp?w=970&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_6/0.m.webp?w=354&h=450",
      "./assets/auction_products/2023_DAILY_6/1.m.webp?w=353&h=450",
      "./assets/auction_products/2023_DAILY_6/2.m.webp?w=364&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_7": {
    "LOT_NAME": "LOT 7",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "İlyas Ergin İnan (1943 - )",
    "ARTIST_HASH": "ILYAS_ERGIN_INAN",
    "NAME": "\"Berlin Serisi\"",
    "TEXT": "Signed, Oil Paint on Duralite<br>70 x 100 cm<br>2004",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_7/0.d.webp?w=1200&h=806",
      "./assets/auction_products/2023_DAILY_7/1.d.webp?w=1200&h=777",
      "./assets/auction_products/2023_DAILY_7/2.d.webp?w=951&h=1200",
      "./assets/auction_products/2023_DAILY_7/3.d.webp?w=1200&h=807"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_7/0.m.webp?w=450&h=302",
      "./assets/auction_products/2023_DAILY_7/1.m.webp?w=695&h=450",
      "./assets/auction_products/2023_DAILY_7/2.m.webp?w=356&h=450",
      "./assets/auction_products/2023_DAILY_7/3.m.webp?w=450&h=303"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_8": {
    "LOT_NAME": "LOT 8",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Burhan Doğançay (1929 - 2013)",
    "ARTIST_HASH": "BURHAN_DOGANCAY",
    "NAME": "\"Ribbon\"",
    "TEXT": "Signed, Gouache on Cardboard<br>75,8 x 56,5 cm<br>1978",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_8/0.d.webp?w=970&h=1200",
      "./assets/auction_products/2023_DAILY_8/1.d.webp?w=879&h=1200",
      "./assets/auction_products/2023_DAILY_8/2.d.webp?w=1119&h=1200",
      "./assets/auction_products/2023_DAILY_8/3.d.webp?w=819&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_8/0.m.webp?w=364&h=450",
      "./assets/auction_products/2023_DAILY_8/1.m.webp?w=329&h=450",
      "./assets/auction_products/2023_DAILY_8/2.m.webp?w=419&h=449",
      "./assets/auction_products/2023_DAILY_8/3.m.webp?w=307&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_9": {
    "LOT_NAME": "LOT 9",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Ali Atmaca (1947 - )",
    "ARTIST_HASH": "ALI_ATMACA",
    "NAME": "\"Soyut Figüratif\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>70 x 130 cm<br>2022",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_9/0.d.webp?w=1200&h=739",
      "./assets/auction_products/2023_DAILY_9/1.d.webp?w=1200&h=711",
      "./assets/auction_products/2023_DAILY_9/2.d.webp?w=1200&h=668"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_9/0.m.webp?w=730&h=450",
      "./assets/auction_products/2023_DAILY_9/1.m.webp?w=759&h=450",
      "./assets/auction_products/2023_DAILY_9/2.m.webp?w=808&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_10": {
    "LOT_NAME": "LOT 10",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Hüseyin Feyzullah (1990 - )",
    "ARTIST_HASH": "HUSEYIN_FEYZULLAH",
    "NAME": "\"Natürmort\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>80 x 60 cm<br>2014",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_10/0.d.webp?w=960&h=1200",
      "./assets/auction_products/2023_DAILY_10/1.d.webp?w=954&h=1200",
      "./assets/auction_products/2023_DAILY_10/2.d.webp?w=900&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_10/0.m.webp?w=360&h=450",
      "./assets/auction_products/2023_DAILY_10/1.m.webp?w=358&h=450",
      "./assets/auction_products/2023_DAILY_10/2.m.webp?w=338&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_11": {
    "LOT_NAME": "LOT 11",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Javad Ghaffari (1980 - )",
    "ARTIST_HASH": "JAVAD_GHAFFARI",
    "NAME": "\"İstanbul\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>120 x 85 cm<br>",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_11/0.d.webp?w=872&h=1200",
      "./assets/auction_products/2023_DAILY_11/1.d.webp?w=961&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_11/0.m.webp?w=327&h=449",
      "./assets/auction_products/2023_DAILY_11/1.m.webp?w=360&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_12": {
    "LOT_NAME": "LOT 12",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Mahir Güven (1958 - )",
    "ARTIST_HASH": "MAHIR_GUVEN",
    "NAME": "\"Figüratif\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>70 x 60 cm<br>2022",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_12/0.d.webp?w=1075&h=1200",
      "./assets/auction_products/2023_DAILY_12/1.d.webp?w=1058&h=1200",
      "./assets/auction_products/2023_DAILY_12/2.d.webp?w=1084&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_12/0.m.webp?w=402&h=449",
      "./assets/auction_products/2023_DAILY_12/1.m.webp?w=397&h=450",
      "./assets/auction_products/2023_DAILY_12/2.m.webp?w=407&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_13": {
    "LOT_NAME": "LOT 13",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Tanju Demirci (1961 - 2016)",
    "ARTIST_HASH": "TANJU_DEMIRCI",
    "NAME": "\"Soyut\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>150 x 150 cm<br>2009",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_13/0.d.webp?w=1200&h=1194",
      "./assets/auction_products/2023_DAILY_13/1.d.webp?w=1200&h=1197"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_13/0.m.webp?w=450&h=448",
      "./assets/auction_products/2023_DAILY_13/1.m.webp?w=450&h=449"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_14": {
    "LOT_NAME": "LOT 14",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Müslüm Teke (1974 - )",
    "ARTIST_HASH": "MUSLUM_TEKE",
    "NAME": "\"Yüzler\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>100 x 80 cm<br>2020",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_14/0.d.webp?w=994&h=1199",
      "./assets/auction_products/2023_DAILY_14/1.d.webp?w=963&h=1199"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_14/0.m.webp?w=373&h=450",
      "./assets/auction_products/2023_DAILY_14/1.m.webp?w=362&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_15": {
    "LOT_NAME": "LOT 15",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925 - 2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Müzisyenler\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>100 x 100 cm<br>2015",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_15/0.d.webp?w=1200&h=1171",
      "./assets/auction_products/2023_DAILY_15/1.d.webp?w=1200&h=1175",
      "./assets/auction_products/2023_DAILY_15/2.d.webp?w=858&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_15/0.m.webp?w=461&h=450",
      "./assets/auction_products/2023_DAILY_15/1.m.webp?w=459&h=450",
      "./assets/auction_products/2023_DAILY_15/2.m.webp?w=322&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_16": {
    "LOT_NAME": "LOT 16",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Şahin Paksoy (1952 - )",
    "ARTIST_HASH": "SAHIN_PAKSOY",
    "NAME": "\"Figüratif\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>90 x 120 cm<br>2022",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_16/0.d.webp?w=1200&h=908",
      "./assets/auction_products/2023_DAILY_16/1.d.webp?w=1200&h=898",
      "./assets/auction_products/2023_DAILY_16/2.d.webp?w=803&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_16/0.m.webp?w=450&h=340",
      "./assets/auction_products/2023_DAILY_16/1.m.webp?w=450&h=337",
      "./assets/auction_products/2023_DAILY_16/2.m.webp?w=301&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_17": {
    "LOT_NAME": "LOT 17",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Kenan Işık (1981 - )",
    "ARTIST_HASH": "KENAN_ISIK",
    "NAME": "\"Kırmızı İstanbul\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>60 x 60 cm<br>2020",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_17/0.d.webp?w=1197&h=1200",
      "./assets/auction_products/2023_DAILY_17/1.d.webp?w=1194&h=1200",
      "./assets/auction_products/2023_DAILY_17/2.d.webp?w=1200&h=1159"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_17/0.m.webp?w=449&h=450",
      "./assets/auction_products/2023_DAILY_17/1.m.webp?w=447&h=450",
      "./assets/auction_products/2023_DAILY_17/2.m.webp?w=466&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_18": {
    "LOT_NAME": "LOT 18",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Selim Turan (1915 - 1994)",
    "ARTIST_HASH": "SELIM_TURAN",
    "NAME": "\"Soyut Kompozisyon\"",
    "TEXT": "Signed, Gouache on Cardboard Marufle on Canvas<br>50 x 65 cm<br>1957",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_18/0.d.webp?w=1200&h=959",
      "./assets/auction_products/2023_DAILY_18/1.d.webp?w=1200&h=929",
      "./assets/auction_products/2023_DAILY_18/2.d.webp?w=900&h=1200",
      "./assets/auction_products/2023_DAILY_18/3.d.webp?w=1200&h=973",
      "./assets/auction_products/2023_DAILY_18/4.d.webp?w=897&h=1200",
      "./assets/auction_products/2023_DAILY_18/5.d.webp?w=924&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_18/0.m.webp?w=564&h=450",
      "./assets/auction_products/2023_DAILY_18/1.m.webp?w=581&h=450",
      "./assets/auction_products/2023_DAILY_18/2.m.webp?w=338&h=450",
      "./assets/auction_products/2023_DAILY_18/3.m.webp?w=555&h=450",
      "./assets/auction_products/2023_DAILY_18/4.m.webp?w=336&h=450",
      "./assets/auction_products/2023_DAILY_18/5.m.webp?w=346&h=450"
    ],
    "STATEMENT": 'The artwork is included in the book "Tez - Antitez - Sentez".',
  },
  "2023_DAILY_19": {
    "LOT_NAME": "LOT 19",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Hüseyin Feyzullah",
    "ARTIST_HASH": "HUSEYIN_FEYZULLAH",
    "NAME": "\"Natürmort\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>60 x 50 cm<br>2018",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_19/0.d.webp?w=995&h=1200",
      "./assets/auction_products/2023_DAILY_19/1.d.webp?w=993&h=1200",
      "./assets/auction_products/2023_DAILY_19/2.d.webp?w=900&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_19/0.m.webp?w=373&h=450",
      "./assets/auction_products/2023_DAILY_19/1.m.webp?w=372&h=450",
      "./assets/auction_products/2023_DAILY_19/2.m.webp?w=337&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_20": {
    "LOT_NAME": "LOT 20",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Mümin Candaş (1971 - )",
    "ARTIST_HASH": "MUMIN_CANDAS",
    "NAME": "\"Barınaklar\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>50 x 50 cm<br>2019",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_20/0.d.webp?w=1200&h=1193",
      "./assets/auction_products/2023_DAILY_20/1.d.webp?w=1200&h=1194",
      "./assets/auction_products/2023_DAILY_20/2.d.webp?w=1196&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_20/0.m.webp?w=452&h=450",
      "./assets/auction_products/2023_DAILY_20/1.m.webp?w=453&h=450",
      "./assets/auction_products/2023_DAILY_20/2.m.webp?w=449&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_21": {
    "LOT_NAME": "LOT 21",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925-2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Kemancı Kız\"",
    "TEXT": "Signed, Mixed Technique on Cardboard<br>70 x 50 cm<br>2007",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_21/0.d.webp?w=1200&h=939",
      "./assets/auction_products/2023_DAILY_21/1.d.webp?w=1199&h=916",
      "./assets/auction_products/2023_DAILY_21/2.d.webp?w=846&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_21/0.m.webp?w=448&h=351",
      "./assets/auction_products/2023_DAILY_21/1.m.webp?w=589&h=450",
      "./assets/auction_products/2023_DAILY_21/2.m.webp?w=317&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_22": {
    "LOT_NAME": "LOT 22",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Can Genca (1985 - )",
    "ARTIST_HASH": "CAN_GENCA",
    "NAME": "\"İç İçe Portreler\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>70 x 100 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_22/0.d.webp?w=1200&h=832",
      "./assets/auction_products/2023_DAILY_22/1.d.webp?w=1200&h=841",
      "./assets/auction_products/2023_DAILY_22/2.d.webp?w=806&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_22/0.m.webp?w=650&h=450",
      "./assets/auction_products/2023_DAILY_22/1.m.webp?w=642&h=450",
      "./assets/auction_products/2023_DAILY_22/2.m.webp?w=302&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_23": {
    "LOT_NAME": "LOT 23",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Güven Araz (1964-)",
    "ARTIST_HASH": "GUVEN_ARAZ",
    "NAME": "\"Lighthouse of the Universe\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>100 x 70 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_23/0.d.webp?w=842&h=1200",
      "./assets/auction_products/2023_DAILY_23/1.d.webp?w=867&h=1200",
      "./assets/auction_products/2023_DAILY_23/2.d.webp?w=849&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_23/0.m.webp?w=315&h=449",
      "./assets/auction_products/2023_DAILY_23/1.m.webp?w=325&h=450",
      "./assets/auction_products/2023_DAILY_23/2.m.webp?w=318&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_24": {
    "LOT_NAME": "LOT 24",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Ertuğrul Ateş (1954 - )",
    "ARTIST_HASH": "ERTUGRUL_ATES",
    "NAME": "\"Rüzgarlı Gün - Windyday\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>150 x 150 cm<br>2017",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_24/0.d.webp?w=1144&h=1200",
      "./assets/auction_products/2023_DAILY_24/1.d.webp?w=1200&h=1171"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_24/0.m.webp?w=429&h=450",
      "./assets/auction_products/2023_DAILY_24/1.m.webp?w=461&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_25": {
    "LOT_NAME": "LOT 25",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Adem Genç (1944 - )",
    "ARTIST_HASH": "ADEM_GENC",
    "NAME": "\"Tılsım\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>85 x 85 cm<br>2019",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_25/0.d.webp?w=1133&h=1200",
      "./assets/auction_products/2023_DAILY_25/1.d.webp?w=1182&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_25/0.m.webp?w=425&h=450",
      "./assets/auction_products/2023_DAILY_25/1.m.webp?w=443&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_26": {
    "LOT_NAME": "LOT 26",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Ahmet Güneştekin (1966 - )",
    "ARTIST_HASH": "AHMET_GUNESTEKIN",
    "NAME": "\"Asaletin Rengi\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>100 x 100 cm<br>2022",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_26/0.d.webp?w=1191&h=1200",
      "./assets/auction_products/2023_DAILY_26/1.d.webp?w=1185&h=1200",
      "./assets/auction_products/2023_DAILY_26/2.d.webp?w=1200&h=1142"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_26/0.m.webp?w=447&h=450",
      "./assets/auction_products/2023_DAILY_26/1.m.webp?w=445&h=450",
      "./assets/auction_products/2023_DAILY_26/2.m.webp?w=473&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_27": {
    "LOT_NAME": "LOT 27",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Memduh Kuzay (1957 - )",
    "ARTIST_HASH": "MEMDUH_KUZAY",
    "NAME": "\"İstanbul\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>110 x 140 cm<br>2022",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_27/0.d.webp?w=1200&h=900",
      "./assets/auction_products/2023_DAILY_27/1.d.webp?w=1200&h=900"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_27/0.m.webp?w=600&h=450",
      "./assets/auction_products/2023_DAILY_27/1.m.webp?w=600&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_28": {
    "LOT_NAME": "LOT 28",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Behzat Hasan Feyzullah (1990 - )",
    "ARTIST_HASH": "BEHZAT_HASAN",
    "NAME": "\"Nü\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>110 x 110 cm<br>2014",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_28/0.d.webp?w=1200&h=1189",
      "./assets/auction_products/2023_DAILY_28/1.d.webp?w=1200&h=1188",
      "./assets/auction_products/2023_DAILY_28/2.d.webp?w=1193&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_28/0.m.webp?w=454&h=450",
      "./assets/auction_products/2023_DAILY_28/1.m.webp?w=454&h=450",
      "./assets/auction_products/2023_DAILY_28/2.m.webp?w=448&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_29": {
    "LOT_NAME": "LOT 29",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Fikret Mualla (1903 - 1967)",
    "ARTIST_HASH": "FIKRET_MUALLA",
    "NAME": "\"Natürmort\"",
    "TEXT": "Signed, Gouache on Paper<br>30 x 22 cm<br>-",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_29/0.d.webp?w=1200&h=1075",
      "./assets/auction_products/2023_DAILY_29/1.d.webp?w=1200&h=954",
      "./assets/auction_products/2023_DAILY_29/2.d.webp?w=1200&h=1087"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_29/0.m.webp?w=503&h=450",
      "./assets/auction_products/2023_DAILY_29/1.m.webp?w=566&h=450",
      "./assets/auction_products/2023_DAILY_29/2.m.webp?w=497&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_30": {
    "LOT_NAME": "LOT 30",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Şahin Paksoy (1952 - )",
    "ARTIST_HASH": "SAHIN_PAKSOY",
    "NAME": "\"Figüratif \"",
    "TEXT": "Signed, Oil Paint on Canvas<br>100 x 100 cm<br>2022",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_30/0.d.webp?w=1175&h=1200",
      "./assets/auction_products/2023_DAILY_30/1.d.webp?w=1191&h=1200",
      "./assets/auction_products/2023_DAILY_30/2.d.webp?w=821&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_30/0.m.webp?w=440&h=450",
      "./assets/auction_products/2023_DAILY_30/1.m.webp?w=447&h=450",
      "./assets/auction_products/2023_DAILY_30/2.m.webp?w=308&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_31": {
    "LOT_NAME": "LOT 31",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Can Genca (1985 - )",
    "ARTIST_HASH": "CAN_GENCA",
    "NAME": "\"Gözler\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>40 x 40 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_31/0.d.webp?w=1200&h=1188",
      "./assets/auction_products/2023_DAILY_31/1.d.webp?w=1200&h=1197",
      "./assets/auction_products/2023_DAILY_31/2.d.webp?w=813&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_31/0.m.webp?w=454&h=450",
      "./assets/auction_products/2023_DAILY_31/1.m.webp?w=451&h=450",
      "./assets/auction_products/2023_DAILY_31/2.m.webp?w=305&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_32": {
    "LOT_NAME": "LOT 32",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925 - 2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Viyolonsel Çalan Kız\"",
    "TEXT": "Signed, Mixed Technique on Cardboard<br>50 x 70 cm<br>2011",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_32/0.d.webp?w=1200&h=893",
      "./assets/auction_products/2023_DAILY_32/1.d.webp?w=1200&h=833",
      "./assets/auction_products/2023_DAILY_32/2.d.webp?w=1200&h=921",
      "./assets/auction_products/2023_DAILY_32/3.d.webp?w=825&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_32/0.m.webp?w=605&h=450",
      "./assets/auction_products/2023_DAILY_32/1.m.webp?w=648&h=450",
      "./assets/auction_products/2023_DAILY_32/2.m.webp?w=586&h=450",
      "./assets/auction_products/2023_DAILY_32/3.m.webp?w=309&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_33": {
    "LOT_NAME": "LOT 33",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "İbrahim Tayfur (1971 - )",
    "ARTIST_HASH": "IBRAHIM_TAYFUR",
    "NAME": "\"Figüratif Soyut\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>100 x 80 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_33/0.d.webp?w=966&h=1200",
      "./assets/auction_products/2023_DAILY_33/1.d.webp?w=1020&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_33/0.m.webp?w=362&h=450",
      "./assets/auction_products/2023_DAILY_33/1.m.webp?w=383&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_34": {
    "LOT_NAME": "LOT 34",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Alaaddin Hız (1989 - )",
    "ARTIST_HASH": "ALAADDIN_HIZ",
    "NAME": "\"Kargaşa Serisi\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>80 x 80 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_34/0.d.webp?w=1200&h=1191",
      "./assets/auction_products/2023_DAILY_34/1.d.webp?w=1200&h=1121"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_34/0.m.webp?w=453&h=450",
      "./assets/auction_products/2023_DAILY_34/1.m.webp?w=482&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_35": {
    "LOT_NAME": "LOT 35",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Can Genca (1985 - )",
    "ARTIST_HASH": "CAN_GENCA",
    "NAME": "\"İç İçe Portreler\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>60x80 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_35/0.d.webp?w=1200&h=892",
      "./assets/auction_products/2023_DAILY_35/1.d.webp?w=1200&h=899",
      "./assets/auction_products/2023_DAILY_35/2.d.webp?w=772&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_35/0.m.webp?w=605&h=450",
      "./assets/auction_products/2023_DAILY_35/1.m.webp?w=600&h=450",
      "./assets/auction_products/2023_DAILY_35/2.m.webp?w=290&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_36": {
    "LOT_NAME": "LOT 36",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Ali Atmaca (1947 - )",
    "ARTIST_HASH": "ALI_ATMACA",
    "NAME": "\"Soyut Figüratif\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>90 x 80 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_36/0.d.webp?w=1069&h=1200",
      "./assets/auction_products/2023_DAILY_36/1.d.webp?w=1084&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_36/0.m.webp?w=401&h=450",
      "./assets/auction_products/2023_DAILY_36/1.m.webp?w=406&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_37": {
    "LOT_NAME": "LOT 37",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Faruk Cimok (1956 - )",
    "ARTIST_HASH": "FARUK_CIMOK",
    "NAME": "\"Ortaköy\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>95 x 75 cm<br>-",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_37/0.d.webp?w=1200&h=1073",
      "./assets/auction_products/2023_DAILY_37/1.d.webp?w=1200&h=1020",
      "./assets/auction_products/2023_DAILY_37/2.d.webp?w=1200&h=1033"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_37/0.m.webp?w=503&h=450",
      "./assets/auction_products/2023_DAILY_37/1.m.webp?w=530&h=450",
      "./assets/auction_products/2023_DAILY_37/2.m.webp?w=522&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_38": {
    "LOT_NAME": "LOT 38",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Fehim Güler (1964 - )",
    "ARTIST_HASH": "FEHIM_GULER",
    "NAME": "\"Mavi İstanbul\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>50 x 150 cm<br>2012",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_38/0.d.webp?w=1200&h=381",
      "./assets/auction_products/2023_DAILY_38/1.d.webp?w=1200&h=430",
      "./assets/auction_products/2023_DAILY_38/2.d.webp?w=827&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_38/0.m.webp?w=1418&h=450",
      "./assets/auction_products/2023_DAILY_38/1.m.webp?w=1256&h=450",
      "./assets/auction_products/2023_DAILY_38/2.m.webp?w=310&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_39": {
    "LOT_NAME": "LOT 39",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Güven Araz (1964 - )",
    "ARTIST_HASH": "GUVEN_ARAZ",
    "NAME": "\"Ghost Wreck\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>60 x 120 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_39/0.d.webp?w=1200&h=600",
      "./assets/auction_products/2023_DAILY_39/1.d.webp?w=848&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_39/0.m.webp?w=900&h=450",
      "./assets/auction_products/2023_DAILY_39/1.m.webp?w=318&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_40": {
    "LOT_NAME": "LOT 40",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925 - 2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Trio\"",
    "TEXT": "Signed, Mixed Technique on Cardboard<br>50 x 70 cm<br>2009",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_40/0.d.webp?w=1200&h=875",
      "./assets/auction_products/2023_DAILY_40/1.d.webp?w=1200&h=857",
      "./assets/auction_products/2023_DAILY_40/2.d.webp?w=1200&h=911",
      "./assets/auction_products/2023_DAILY_40/3.d.webp?w=839&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_40/0.m.webp?w=617&h=450",
      "./assets/auction_products/2023_DAILY_40/1.m.webp?w=630&h=450",
      "./assets/auction_products/2023_DAILY_40/2.m.webp?w=593&h=450",
      "./assets/auction_products/2023_DAILY_40/3.m.webp?w=314&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_41": {
    "LOT_NAME": "LOT 41",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Alev Özas (1977 - )",
    "ARTIST_HASH": "ALEV_OZAS",
    "NAME": "\"Kırmızı Galata\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>50 x 50 cm<br>2020",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_41/0.d.webp?w=1200&h=1192"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_41/0.m.webp?w=453&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_42": {
    "LOT_NAME": "LOT 42",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Alaaddin Hız (1989 - )",
    "ARTIST_HASH": "ALAADDIN_HIZ",
    "NAME": "\"Vertigo\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>130 x 130 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_42/0.d.webp?w=1200&h=1166",
      "./assets/auction_products/2023_DAILY_42/1.d.webp?w=1186&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_42/0.m.webp?w=463&h=450",
      "./assets/auction_products/2023_DAILY_42/1.m.webp?w=444&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_43": {
    "LOT_NAME": "LOT 43",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Tanju Demirci (1961 - 2016)",
    "ARTIST_HASH": "TANJU_DEMIRCI",
    "NAME": "\"Soyut\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>150 x 150 cm<br>",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_43/0.d.webp?w=1184&h=1200",
      "./assets/auction_products/2023_DAILY_43/1.d.webp?w=1184&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_43/0.m.webp?w=444&h=450",
      "./assets/auction_products/2023_DAILY_43/1.m.webp?w=444&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_46": {
    "LOT_NAME": "LOT 46",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Can Genca (1985 - )",
    "ARTIST_HASH": "CAN_GENCA",
    "NAME": "\"Yüzler Serisi\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>50 x 50 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_46/0.d.webp?w=1199&h=1196",
      "./assets/auction_products/2023_DAILY_46/1.d.webp?w=816&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_46/0.m.webp?w=451&h=450",
      "./assets/auction_products/2023_DAILY_46/1.m.webp?w=306&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_48": {
    "LOT_NAME": "LOT 48",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Zafer Dilekçi (1978 - )",
    "ARTIST_HASH": "ZAFER_DILEKCI",
    "NAME": "\"İsimsiz\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>120 x 120 cm<br>2018",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_48/0.d.webp?w=1187&h=1200",
      "./assets/auction_products/2023_DAILY_48/1.d.webp?w=1200&h=900"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_48/0.m.webp?w=445&h=450",
      "./assets/auction_products/2023_DAILY_48/1.m.webp?w=600&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_52": {
    "LOT_NAME": "LOT 52",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Bige Aksoy (1976 - )",
    "ARTIST_HASH": "BIGE_AKSOY",
    "NAME": "\"Başlangıç\"",
    "TEXT": "Signed, Mixed Technique on Canvas<br>120 x 120  cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_52/0.d.webp?w=1200&h=1189",
      "./assets/auction_products/2023_DAILY_52/1.d.webp?w=1200&h=1199",
      "./assets/auction_products/2023_DAILY_52/2.d.webp?w=1200&h=777",
      "./assets/auction_products/2023_DAILY_52/3.d.webp?w=900&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_52/0.m.webp?w=454&h=450",
      "./assets/auction_products/2023_DAILY_52/1.m.webp?w=450&h=450",
      "./assets/auction_products/2023_DAILY_52/2.m.webp?w=695&h=450",
      "./assets/auction_products/2023_DAILY_52/3.m.webp?w=338&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_55": {
    "LOT_NAME": "LOT 55",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Serap Can (1981 - )",
    "ARTIST_HASH": "SERAP_CAN",
    "NAME": "\"Pedestrians\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>60 x 60 cm<br>2022",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_55/0.d.webp?w=1149&h=1200",
      "./assets/auction_products/2023_DAILY_55/1.d.webp?w=1080&h=674",
      "./assets/auction_products/2023_DAILY_55/2.d.webp?w=849&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_55/0.m.webp?w=431&h=450",
      "./assets/auction_products/2023_DAILY_55/1.m.webp?w=721&h=450",
      "./assets/auction_products/2023_DAILY_55/2.m.webp?w=319&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_56": {
    "LOT_NAME": "LOT 56",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Can Genca (1985 - )",
    "ARTIST_HASH": "CAN_GENCA",
    "NAME": "\"İç İçe Portreler\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>60 x 80 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_56/0.d.webp?w=1200&h=901",
      "./assets/auction_products/2023_DAILY_56/1.d.webp?w=1200&h=749",
      "./assets/auction_products/2023_DAILY_56/2.d.webp?w=832&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_56/0.m.webp?w=599&h=450",
      "./assets/auction_products/2023_DAILY_56/1.m.webp?w=721&h=450",
      "./assets/auction_products/2023_DAILY_56/2.m.webp?w=312&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_68": {
    "LOT_NAME": "LOT 68",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Muhittin Arıkan (1976 - )",
    "ARTIST_HASH": "MUHITTIN_ARIKAN",
    "NAME": "\"Anadolu Yılkı Atı\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>80 x 120 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_68/0.d.webp?w=1200&h=890",
      "./assets/auction_products/2023_DAILY_68/1.d.webp?w=1200&h=811",
      "./assets/auction_products/2023_DAILY_68/2.d.webp?w=1200&h=777",
      "./assets/auction_products/2023_DAILY_68/3.d.webp?w=900&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_68/0.m.webp?w=606&h=450",
      "./assets/auction_products/2023_DAILY_68/1.m.webp?w=666&h=450",
      "./assets/auction_products/2023_DAILY_68/2.m.webp?w=695&h=450",
      "./assets/auction_products/2023_DAILY_68/3.m.webp?w=338&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_69": {
    "LOT_NAME": "LOT 69",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Zlata Shyshman (1995 -)",
    "ARTIST_HASH": "ZLATA_SHYSHMAN",
    "NAME": "\"Legends of Galata\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>70 x 50 cm<br>2021",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_69/0.d.webp?w=827&h=1200",
      "./assets/auction_products/2023_DAILY_69/1.d.webp?w=833&h=1200",
      "./assets/auction_products/2023_DAILY_69/2.d.webp?w=1200&h=749",
      "./assets/auction_products/2023_DAILY_69/3.d.webp?w=891&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_69/0.m.webp?w=310&h=450",
      "./assets/auction_products/2023_DAILY_69/1.m.webp?w=313&h=450",
      "./assets/auction_products/2023_DAILY_69/2.m.webp?w=721&h=450",
      "./assets/auction_products/2023_DAILY_69/3.m.webp?w=334&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_70": {
    "LOT_NAME": "LOT 70",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Remzi Karabulut (1963 -)",
    "ARTIST_HASH": "REMZI_KARABULUT",
    "NAME": "\"İsimsiz \"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>130 x 100 cm<br>2021",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_70/0.d.webp?w=919&h=1200",
      "./assets/auction_products/2023_DAILY_70/1.d.webp?w=954&h=1200",
      "./assets/auction_products/2023_DAILY_70/2.d.webp?w=1200&h=724",
      "./assets/auction_products/2023_DAILY_70/3.d.webp?w=900&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_70/0.m.webp?w=345&h=450",
      "./assets/auction_products/2023_DAILY_70/1.m.webp?w=358&h=450",
      "./assets/auction_products/2023_DAILY_70/2.m.webp?w=745&h=450",
      "./assets/auction_products/2023_DAILY_70/3.m.webp?w=337&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_73": {
    "LOT_NAME": "LOT 73",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Zafer Dilekçi (1978 - )",
    "ARTIST_HASH": "ZAFER_DILEKCI",
    "NAME": "\"Yılkı Atları \"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>100 x 120 cm<br>2007",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_73/0.d.webp?w=1200&h=1012",
      "./assets/auction_products/2023_DAILY_73/1.d.webp?w=1200&h=1010",
      "./assets/auction_products/2023_DAILY_73/2.d.webp?w=1200&h=1023",
      "./assets/auction_products/2023_DAILY_73/3.d.webp?w=1200&h=718",
      "./assets/auction_products/2023_DAILY_73/4.d.webp?w=855&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_73/0.m.webp?w=533&h=450",
      "./assets/auction_products/2023_DAILY_73/1.m.webp?w=535&h=450",
      "./assets/auction_products/2023_DAILY_73/2.m.webp?w=528&h=450",
      "./assets/auction_products/2023_DAILY_73/3.m.webp?w=752&h=450",
      "./assets/auction_products/2023_DAILY_73/4.m.webp?w=321&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_74": {
    "LOT_NAME": "LOT 74",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Güven Araz (1964 - )",
    "ARTIST_HASH": "GUVEN_ARAZ",
    "NAME": "\"Whale Effect \"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>100 x 100  cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_74/0.d.webp?w=1200&h=1185",
      "./assets/auction_products/2023_DAILY_74/1.d.webp?w=1200&h=1174",
      "./assets/auction_products/2023_DAILY_74/2.d.webp?w=1200&h=1104",
      "./assets/auction_products/2023_DAILY_74/3.d.webp?w=848&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_74/0.m.webp?w=455&h=450",
      "./assets/auction_products/2023_DAILY_74/1.m.webp?w=460&h=450",
      "./assets/auction_products/2023_DAILY_74/2.m.webp?w=489&h=450",
      "./assets/auction_products/2023_DAILY_74/3.m.webp?w=318&h=450"
    ],
    "STATEMENT": "-"
  },
  "2023_DAILY_75": {
    "LOT_NAME": "LOT 75",
    "CATALOG_HASH": "2023_DAILY",
    "ARTIST_NAME": "Elif Şule Şanlı (2000 - )",
    "ARTIST_HASH": "ELIF_SULE_SANLI",
    "NAME": "\"Persona'nın Ardındaki Gölge\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>100 x 70  cm<br>2022",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2023_DAILY_75/0.d.webp?w=840&h=1200",
      "./assets/auction_products/2023_DAILY_75/1.d.webp?w=876&h=1200",
      "./assets/auction_products/2023_DAILY_75/2.d.webp?w=1200&h=724",
      "./assets/auction_products/2023_DAILY_75/3.d.webp?w=900&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2023_DAILY_75/0.m.webp?w=315&h=450",
      "./assets/auction_products/2023_DAILY_75/1.m.webp?w=328&h=450",
      "./assets/auction_products/2023_DAILY_75/2.m.webp?w=746&h=450",
      "./assets/auction_products/2023_DAILY_75/3.m.webp?w=337&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_17": {
    "LOT_NAME": "LOT 17",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Can Genca (1985 - )",
    "ARTIST_HASH": "CAN_GENCA",
    "NAME": "\"Yüzler Serisi\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>40 x 40 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_17/0.d.webp?w=1194&h=1200",
      "./assets/auction_products/2024_DAILY_17/1.d.webp?w=1200&h=1191",
      "./assets/auction_products/2024_DAILY_17/2.d.webp?w=1200&h=1120",
      "./assets/auction_products/2024_DAILY_17/3.d.webp?w=760&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_17/0.m.webp?w=448&h=450",
      "./assets/auction_products/2024_DAILY_17/1.m.webp?w=454&h=450",
      "./assets/auction_products/2024_DAILY_17/2.m.webp?w=482&h=450",
      "./assets/auction_products/2024_DAILY_17/3.m.webp?w=285&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_18": {
    "LOT_NAME": "LOT 18",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Hülya Elibol (1994 - )",
    "ARTIST_HASH": "HULYA_ELIBOL",
    "NAME": "\"Varoluşa Yolculuk \"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>100 x 80  cm<br>2019",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_18/0.d.webp?w=920&h=1200",
      "./assets/auction_products/2024_DAILY_18/1.d.webp?w=964&h=1200",
      "./assets/auction_products/2024_DAILY_18/2.d.webp?w=1200&h=749",
      "./assets/auction_products/2024_DAILY_18/3.d.webp?w=879&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_18/0.m.webp?w=345&h=450",
      "./assets/auction_products/2024_DAILY_18/1.m.webp?w=361&h=450",
      "./assets/auction_products/2024_DAILY_18/2.m.webp?w=721&h=450",
      "./assets/auction_products/2024_DAILY_18/3.m.webp?w=329&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_21": {
    "LOT_NAME": "LOT 21",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Bige Aksoy (1976 - )",
    "ARTIST_HASH": "BIGE_AKSOY",
    "NAME": "\"Hayal \"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>120 x 120  cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_21/0.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_21/1.d.webp?w=1188&h=1200",
      "./assets/auction_products/2024_DAILY_21/2.d.webp?w=1200&h=777",
      "./assets/auction_products/2024_DAILY_21/3.d.webp?w=765&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_21/0.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_21/1.m.webp?w=445&h=450",
      "./assets/auction_products/2024_DAILY_21/2.m.webp?w=695&h=450",
      "./assets/auction_products/2024_DAILY_21/3.m.webp?w=287&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_22": {
    "LOT_NAME": "LOT 22",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Ensieh Najarian (1982 - ) ",
    "ARTIST_HASH": "ENSIEH_NAJARIAN",
    "NAME": "\"Kadın Özgürlüğü \"",
    "TEXT": "Signed, Oil Paint on Canvas<br>100 x 100 cm<br>2022",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_22/0.d.webp?w=1165&h=1200",
      "./assets/auction_products/2024_DAILY_22/1.d.webp?w=1200&h=1087",
      "./assets/auction_products/2024_DAILY_22/2.d.webp?w=1200&h=724",
      "./assets/auction_products/2024_DAILY_22/3.d.webp?w=907&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_22/0.m.webp?w=437&h=450",
      "./assets/auction_products/2024_DAILY_22/1.m.webp?w=497&h=450",
      "./assets/auction_products/2024_DAILY_22/2.m.webp?w=745&h=450",
      "./assets/auction_products/2024_DAILY_22/3.m.webp?w=340&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_23": {
    "LOT_NAME": "LOT 23",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Sevgi Ağanoğlu (1966 - ) ",
    "ARTIST_HASH": "SEVGI_AGANOGLU",
    "NAME": "\"Sayuri \"",
    "TEXT": "Signed, Oil Paint on Canvas<br>70 x 50  cm<br>2012",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_23/0.d.webp?w=857&h=1200",
      "./assets/auction_products/2024_DAILY_23/1.d.webp?w=834&h=1200",
      "./assets/auction_products/2024_DAILY_23/2.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_23/3.d.webp?w=841&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_23/0.m.webp?w=321&h=450",
      "./assets/auction_products/2024_DAILY_23/1.m.webp?w=313&h=450",
      "./assets/auction_products/2024_DAILY_23/2.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_23/3.m.webp?w=315&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_29": {
    "LOT_NAME": "LOT 29",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Bige Aksoy (1976 - )",
    "ARTIST_HASH": "BIGE_AKSOY",
    "NAME": "\"Tohum\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>46 x 56  cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_29/0.d.webp?w=980&h=1200",
      "./assets/auction_products/2024_DAILY_29/1.d.webp?w=956&h=1200",
      "./assets/auction_products/2024_DAILY_29/2.d.webp?w=1200&h=1120",
      "./assets/auction_products/2024_DAILY_29/3.d.webp?w=755&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_29/0.m.webp?w=368&h=450",
      "./assets/auction_products/2024_DAILY_29/1.m.webp?w=359&h=450",
      "./assets/auction_products/2024_DAILY_29/2.m.webp?w=482&h=450",
      "./assets/auction_products/2024_DAILY_29/3.m.webp?w=283&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_31": {
    "LOT_NAME": "LOT 31",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Sevgi Ağanoğlu (1966 - ) ",
    "ARTIST_HASH": "SEVGI_AGANOGLU",
    "NAME": "\"Fakir Kızlar \"",
    "TEXT": "Signed, Oil Paint on Canvas<br>120 x 80  cm<br>2013",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_31/0.d.webp?w=812&h=1200",
      "./assets/auction_products/2024_DAILY_31/1.d.webp?w=814&h=1200",
      "./assets/auction_products/2024_DAILY_31/2.d.webp?w=1200&h=749",
      "./assets/auction_products/2024_DAILY_31/3.d.webp?w=715&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_31/0.m.webp?w=304&h=450",
      "./assets/auction_products/2024_DAILY_31/1.m.webp?w=305&h=450",
      "./assets/auction_products/2024_DAILY_31/2.m.webp?w=721&h=450",
      "./assets/auction_products/2024_DAILY_31/3.m.webp?w=268&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_33": {
    "LOT_NAME": "LOT 33",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Çetin Pireci (1960 - )",
    "ARTIST_HASH": "CETIN_PIRECI",
    "NAME": "\"Son Şans - I\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>120 x 90 cm<br>2016",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_33/0.d.webp?w=897&h=1200",
      "./assets/auction_products/2024_DAILY_33/1.d.webp?w=914&h=1200",
      "./assets/auction_products/2024_DAILY_33/2.d.webp?w=1200&h=749",
      "./assets/auction_products/2024_DAILY_33/3.d.webp?w=849&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_33/0.m.webp?w=336&h=450",
      "./assets/auction_products/2024_DAILY_33/1.m.webp?w=343&h=450",
      "./assets/auction_products/2024_DAILY_33/2.m.webp?w=721&h=450",
      "./assets/auction_products/2024_DAILY_33/3.m.webp?w=319&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_35": {
    "LOT_NAME": "LOT 35",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Can Genca (1985 - )",
    "ARTIST_HASH": "CAN_GENCA",
    "NAME": "\"Yüzler \"",
    "TEXT": "Signed, Mixed Technique on Canvas<br>50 x 50  cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_35/0.d.webp?w=1185&h=1200",
      "./assets/auction_products/2024_DAILY_35/1.d.webp?w=1196&h=1200",
      "./assets/auction_products/2024_DAILY_35/2.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_35/3.d.webp?w=844&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_35/0.m.webp?w=444&h=450",
      "./assets/auction_products/2024_DAILY_35/1.m.webp?w=449&h=450",
      "./assets/auction_products/2024_DAILY_35/2.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_35/3.m.webp?w=316&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_37": {
    "LOT_NAME": "LOT 37",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Remzi Karabulut (1963 -) ",
    "ARTIST_HASH": "REMZI_KARABULUT",
    "NAME": "\"İsimsiz \"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>130 x 100 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_37/0.d.webp?w=927&h=1200",
      "./assets/auction_products/2024_DAILY_37/1.d.webp?w=909&h=1200",
      "./assets/auction_products/2024_DAILY_37/2.d.webp?w=1200&h=777",
      "./assets/auction_products/2024_DAILY_37/3.d.webp?w=814&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_37/0.m.webp?w=348&h=450",
      "./assets/auction_products/2024_DAILY_37/1.m.webp?w=341&h=450",
      "./assets/auction_products/2024_DAILY_37/2.m.webp?w=695&h=450",
      "./assets/auction_products/2024_DAILY_37/3.m.webp?w=305&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_40": {
    "LOT_NAME": "LOT 40",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Erdem Baytemur (1998 - ) ",
    "ARTIST_HASH": "ERDEM_BAYTEMUR",
    "NAME": "\"Kadın Modelin Dansı \"",
    "TEXT": "Signed, Oil Paint on Canvas<br>40 x 40 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_40/0.d.webp?w=1177&h=1200",
      "./assets/auction_products/2024_DAILY_40/1.d.webp?w=1184&h=1200",
      "./assets/auction_products/2024_DAILY_40/2.d.webp?w=1200&h=1120",
      "./assets/auction_products/2024_DAILY_40/3.d.webp?w=863&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_40/0.m.webp?w=442&h=450",
      "./assets/auction_products/2024_DAILY_40/1.m.webp?w=444&h=450",
      "./assets/auction_products/2024_DAILY_40/2.m.webp?w=482&h=450",
      "./assets/auction_products/2024_DAILY_40/3.m.webp?w=324&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_41": {
    "LOT_NAME": "LOT 41",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Filiz Aksu (1977 - ) ",
    "ARTIST_HASH": "FILIZ_AKSU",
    "NAME": "\"Aklıma Takılanlar \"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>70 x 100 cm<br>2022",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_41/0.d.webp?w=1200&h=844",
      "./assets/auction_products/2024_DAILY_41/1.d.webp?w=1200&h=830",
      "./assets/auction_products/2024_DAILY_41/2.d.webp?w=1200&h=777",
      "./assets/auction_products/2024_DAILY_41/3.d.webp?w=829&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_41/0.m.webp?w=640&h=450",
      "./assets/auction_products/2024_DAILY_41/1.m.webp?w=650&h=450",
      "./assets/auction_products/2024_DAILY_41/2.m.webp?w=695&h=450",
      "./assets/auction_products/2024_DAILY_41/3.m.webp?w=311&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_42": {
    "LOT_NAME": "LOT 42",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Çetin Pireci (1960 - )",
    "ARTIST_HASH": "CETIN_PIRECI",
    "NAME": "\"Uyuyan Güzel - IV\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>90 x 120  cm<br>2017",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_42/0.d.webp?w=1200&h=894",
      "./assets/auction_products/2024_DAILY_42/1.d.webp?w=1200&h=885",
      "./assets/auction_products/2024_DAILY_42/2.d.webp?w=1200&h=900",
      "./assets/auction_products/2024_DAILY_42/3.d.webp?w=849&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_42/0.m.webp?w=604&h=450",
      "./assets/auction_products/2024_DAILY_42/1.m.webp?w=610&h=450",
      "./assets/auction_products/2024_DAILY_42/2.m.webp?w=600&h=450",
      "./assets/auction_products/2024_DAILY_42/3.m.webp?w=319&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_43": {
    "LOT_NAME": "LOT 43",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Elif Şule Şanlı (2000 - ) ",
    "ARTIST_HASH": "ELIF_SULE_SANLI",
    "NAME": "\"Panettone\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>50 x 35 cm<br>2021",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_43/0.d.webp?w=900&h=1200",
      "./assets/auction_products/2024_DAILY_43/1.d.webp?w=893&h=1200",
      "./assets/auction_products/2024_DAILY_43/2.d.webp?w=1200&h=1120",
      "./assets/auction_products/2024_DAILY_43/3.d.webp?w=872&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_43/0.m.webp?w=338&h=450",
      "./assets/auction_products/2024_DAILY_43/1.m.webp?w=335&h=450",
      "./assets/auction_products/2024_DAILY_43/2.m.webp?w=482&h=450",
      "./assets/auction_products/2024_DAILY_43/3.m.webp?w=327&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_49": {
    "LOT_NAME": "LOT 49",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Zafer Dilekçi (1978 - )",
    "ARTIST_HASH": "ZAFER_DILEKCI",
    "NAME": "\"Üç Kız Kardeş \"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>100 x 120  cm<br>2018",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_49/0.d.webp?w=1200&h=990",
      "./assets/auction_products/2024_DAILY_49/1.d.webp?w=1200&h=964",
      "./assets/auction_products/2024_DAILY_49/2.d.webp?w=1200&h=777",
      "./assets/auction_products/2024_DAILY_49/3.d.webp?w=852&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_49/0.m.webp?w=545&h=450",
      "./assets/auction_products/2024_DAILY_49/1.m.webp?w=560&h=450",
      "./assets/auction_products/2024_DAILY_49/2.m.webp?w=695&h=450",
      "./assets/auction_products/2024_DAILY_49/3.m.webp?w=320&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_51": {
    "LOT_NAME": "LOT 51",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Bige Aksoy (1976 - )",
    "ARTIST_HASH": "BIGE_AKSOY",
    "NAME": "\"İsimsiz\"",
    "TEXT": "Signed, Mixed Technique on Canvas<br>110 x 110  cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_51/0.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_51/1.d.webp?w=1200&h=1194",
      "./assets/auction_products/2024_DAILY_51/2.d.webp?w=1200&h=1105",
      "./assets/auction_products/2024_DAILY_51/3.d.webp?w=800&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_51/0.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_51/1.m.webp?w=452&h=450",
      "./assets/auction_products/2024_DAILY_51/2.m.webp?w=489&h=450",
      "./assets/auction_products/2024_DAILY_51/3.m.webp?w=300&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_55": {
    "LOT_NAME": "LOT 55",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Filiz Aksu (1977 - ) ",
    "ARTIST_HASH": "FILIZ_AKSU",
    "NAME": "\"Sıkışmışlık\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>100 x 70 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_55/0.d.webp?w=844&h=1200",
      "./assets/auction_products/2024_DAILY_55/1.d.webp?w=850&h=1200",
      "./assets/auction_products/2024_DAILY_55/2.d.webp?w=1200&h=749",
      "./assets/auction_products/2024_DAILY_55/3.d.webp?w=820&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_55/0.m.webp?w=316&h=450",
      "./assets/auction_products/2024_DAILY_55/1.m.webp?w=319&h=450",
      "./assets/auction_products/2024_DAILY_55/2.m.webp?w=721&h=450",
      "./assets/auction_products/2024_DAILY_55/3.m.webp?w=307&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_57": {
    "LOT_NAME": "LOT 57",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925 - 2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Kemancı Kız I\"",
    "TEXT": "Signed, Mixed Technique on Cardboard<br>50 x 70 cm<br>2010",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_57/0.d.webp?w=1200&h=872",
      "./assets/auction_products/2024_DAILY_57/1.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_57/2.d.webp?w=859&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_57/0.m.webp?w=619&h=450",
      "./assets/auction_products/2024_DAILY_57/1.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_57/2.m.webp?w=322&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_58": {
    "LOT_NAME": "LOT 58",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Mehmet Demir (1995 - ) ",
    "ARTIST_HASH": "MEHMET_DEMIR",
    "NAME": "\"Space\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>100 x 100 cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_58/0.d.webp?w=1168&h=1200",
      "./assets/auction_products/2024_DAILY_58/1.d.webp?w=1196&h=1200",
      "./assets/auction_products/2024_DAILY_58/2.d.webp?w=1200&h=718",
      "./assets/auction_products/2024_DAILY_58/3.d.webp?w=862&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_58/0.m.webp?w=438&h=450",
      "./assets/auction_products/2024_DAILY_58/1.m.webp?w=449&h=450",
      "./assets/auction_products/2024_DAILY_58/2.m.webp?w=752&h=450",
      "./assets/auction_products/2024_DAILY_58/3.m.webp?w=323&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_59": {
    "LOT_NAME": "LOT 59",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Can Genca (1985 - )",
    "ARTIST_HASH": "CAN_GENCA",
    "NAME": "\"İç İçe Portreler\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>60 x 80  cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_59/0.d.webp?w=1200&h=900",
      "./assets/auction_products/2024_DAILY_59/1.d.webp?w=1200&h=901",
      "./assets/auction_products/2024_DAILY_59/2.d.webp?w=1200&h=777",
      "./assets/auction_products/2024_DAILY_59/3.d.webp?w=897&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_59/0.m.webp?w=600&h=450",
      "./assets/auction_products/2024_DAILY_59/1.m.webp?w=599&h=450",
      "./assets/auction_products/2024_DAILY_59/2.m.webp?w=695&h=450",
      "./assets/auction_products/2024_DAILY_59/3.m.webp?w=336&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_60": {
    "LOT_NAME": "LOT 60",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925 - 2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Trio\"",
    "TEXT": "Signed, Mixed Technique on Cardboard<br>50 x 70 cm<br>2011",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_60/0.d.webp?w=1200&h=814",
      "./assets/auction_products/2024_DAILY_60/1.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_60/2.d.webp?w=868&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_60/0.m.webp?w=664&h=450",
      "./assets/auction_products/2024_DAILY_60/1.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_60/2.m.webp?w=325&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_61": {
    "LOT_NAME": "LOT 61",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Müge Cücü (1978 - )",
    "ARTIST_HASH": "MUGE_CUCU",
    "NAME": "\"Humanity \"",
    "TEXT": "Signed, Mixed Technique on Canvas<br>60 x 70 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_61/0.d.webp?w=1200&h=1039",
      "./assets/auction_products/2024_DAILY_61/1.d.webp?w=1200&h=938",
      "./assets/auction_products/2024_DAILY_61/2.d.webp?w=1200&h=749",
      "./assets/auction_products/2024_DAILY_61/3.d.webp?w=850&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_61/0.m.webp?w=520&h=450",
      "./assets/auction_products/2024_DAILY_61/1.m.webp?w=576&h=450",
      "./assets/auction_products/2024_DAILY_61/2.m.webp?w=721&h=450",
      "./assets/auction_products/2024_DAILY_61/3.m.webp?w=319&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_62": {
    "LOT_NAME": "LOT 62",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Erdem Baytemur (1998 - ) ",
    "ARTIST_HASH": "ERDEM_BAYTEMUR",
    "NAME": "\"İsimsiz \"",
    "TEXT": "Signed, Acrylic Paint on Paper<br>70x100 cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_62/0.d.webp?w=1200&h=851",
      "./assets/auction_products/2024_DAILY_62/1.d.webp?w=1200&h=834",
      "./assets/auction_products/2024_DAILY_62/2.d.webp?w=1200&h=839",
      "./assets/auction_products/2024_DAILY_62/3.d.webp?w=1200&h=777",
      "./assets/auction_products/2024_DAILY_62/4.d.webp?w=834&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_62/0.m.webp?w=635&h=450",
      "./assets/auction_products/2024_DAILY_62/1.m.webp?w=648&h=450",
      "./assets/auction_products/2024_DAILY_62/2.m.webp?w=644&h=450",
      "./assets/auction_products/2024_DAILY_62/3.m.webp?w=695&h=450",
      "./assets/auction_products/2024_DAILY_62/4.m.webp?w=313&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_63": {
    "LOT_NAME": "LOT 63",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Can Genca (1985 - )",
    "ARTIST_HASH": "CAN_GENCA",
    "NAME": "\"İç İçe Portreler\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>60 x 80 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_63/0.d.webp?w=1200&h=895",
      "./assets/auction_products/2024_DAILY_63/1.d.webp?w=1200&h=900",
      "./assets/auction_products/2024_DAILY_63/2.d.webp?w=1200&h=749",
      "./assets/auction_products/2024_DAILY_63/3.d.webp?w=861&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_63/0.m.webp?w=603&h=450",
      "./assets/auction_products/2024_DAILY_63/1.m.webp?w=600&h=450",
      "./assets/auction_products/2024_DAILY_63/2.m.webp?w=721&h=450",
      "./assets/auction_products/2024_DAILY_63/3.m.webp?w=323&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_64": {
    "LOT_NAME": "LOT 64",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925 - 2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Kemancı Kız II\"",
    "TEXT": "Signed, Mixed Technique on Cardboard<br>50 x 70 cm<br>2011",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_64/0.d.webp?w=1200&h=870",
      "./assets/auction_products/2024_DAILY_64/1.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_64/2.d.webp?w=862&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_64/0.m.webp?w=621&h=450",
      "./assets/auction_products/2024_DAILY_64/1.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_64/2.m.webp?w=323&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_65": {
    "LOT_NAME": "LOT 65",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Ferhat Arat (1996 - ) ",
    "ARTIST_HASH": "FERHAT_ARAT",
    "NAME": "\"İsimsiz \"",
    "TEXT": "Signed, Oil Paint on Canvas<br>40 x 40 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_65/0.d.webp?w=1197&h=1200",
      "./assets/auction_products/2024_DAILY_65/1.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_65/2.d.webp?w=1200&h=1120",
      "./assets/auction_products/2024_DAILY_65/3.d.webp?w=844&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_65/0.m.webp?w=449&h=450",
      "./assets/auction_products/2024_DAILY_65/1.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_65/2.m.webp?w=482&h=450",
      "./assets/auction_products/2024_DAILY_65/3.m.webp?w=316&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_67": {
    "LOT_NAME": "LOT 67",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Yusuf Duran (1985 - )",
    "ARTIST_HASH": "YUSUF_DURAN",
    "NAME": "\"Dalga\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>80 x 120  cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_67/0.d.webp?w=1200&h=821",
      "./assets/auction_products/2024_DAILY_67/1.d.webp?w=1200&h=801",
      "./assets/auction_products/2024_DAILY_67/2.d.webp?w=1200&h=804",
      "./assets/auction_products/2024_DAILY_67/3.d.webp?w=1200&h=777",
      "./assets/auction_products/2024_DAILY_67/4.d.webp?w=853&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_67/0.m.webp?w=658&h=450",
      "./assets/auction_products/2024_DAILY_67/1.m.webp?w=674&h=450",
      "./assets/auction_products/2024_DAILY_67/2.m.webp?w=672&h=450",
      "./assets/auction_products/2024_DAILY_67/3.m.webp?w=695&h=450",
      "./assets/auction_products/2024_DAILY_67/4.m.webp?w=320&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_68": {
    "LOT_NAME": "LOT 68",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Alaaddin Hız (1989 - )",
    "ARTIST_HASH": "ALAADDIN_HIZ",
    "NAME": "\"Kargaşa Serisi\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>80 x 80  cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_68/0.d.webp?w=1200&h=1195",
      "./assets/auction_products/2024_DAILY_68/1.d.webp?w=1192&h=1200",
      "./assets/auction_products/2024_DAILY_68/2.d.webp?w=1200&h=862",
      "./assets/auction_products/2024_DAILY_68/3.d.webp?w=848&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_68/0.m.webp?w=452&h=450",
      "./assets/auction_products/2024_DAILY_68/1.m.webp?w=447&h=450",
      "./assets/auction_products/2024_DAILY_68/2.m.webp?w=626&h=450",
      "./assets/auction_products/2024_DAILY_68/3.m.webp?w=318&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_70": {
    "LOT_NAME": "LOT 70",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Hakan Esmer (1973 - )",
    "ARTIST_HASH": "HAKAN_ESMER",
    "NAME": "\"Cafe-Paris \"",
    "TEXT": "Signed, Oil Paint on Canvas<br>116 x 89 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_70/0.d.webp?w=965&h=1200",
      "./assets/auction_products/2024_DAILY_70/1.d.webp?w=940&h=1200",
      "./assets/auction_products/2024_DAILY_70/2.d.webp?w=1200&h=1105",
      "./assets/auction_products/2024_DAILY_70/3.d.webp?w=1200&h=857"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_70/0.m.webp?w=362&h=450",
      "./assets/auction_products/2024_DAILY_70/1.m.webp?w=353&h=450",
      "./assets/auction_products/2024_DAILY_70/2.m.webp?w=489&h=450",
      "./assets/auction_products/2024_DAILY_70/3.m.webp?w=631&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_71": {
    "LOT_NAME": "LOT 71",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Elif Şule Şanlı (2000 - )",
    "ARTIST_HASH": "ELIF_SULE_SANLI",
    "NAME": "\"An\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>40 x 80  cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_71/0.d.webp?w=1200&h=594",
      "./assets/auction_products/2024_DAILY_71/1.d.webp?w=1200&h=620",
      "./assets/auction_products/2024_DAILY_71/2.d.webp?w=1200&h=1120",
      "./assets/auction_products/2024_DAILY_71/3.d.webp?w=901&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_71/0.m.webp?w=909&h=450",
      "./assets/auction_products/2024_DAILY_71/1.m.webp?w=871&h=450",
      "./assets/auction_products/2024_DAILY_71/2.m.webp?w=482&h=450",
      "./assets/auction_products/2024_DAILY_71/3.m.webp?w=338&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_75": {
    "LOT_NAME": "LOT 75",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Erdem Baytemur (1998 - )",
    "ARTIST_HASH": "ERDEM_BAYTEMUR",
    "NAME": "\"Köyde Bir Gün \"",
    "TEXT": "Signed, Oil Paint on Canvas<br>50 x 80 cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_75/0.d.webp?w=1200&h=728",
      "./assets/auction_products/2024_DAILY_75/1.d.webp?w=1200&h=743",
      "./assets/auction_products/2024_DAILY_75/2.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_75/3.d.webp?w=848&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_75/0.m.webp?w=742&h=450",
      "./assets/auction_products/2024_DAILY_75/1.m.webp?w=726&h=450",
      "./assets/auction_products/2024_DAILY_75/2.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_75/3.m.webp?w=318&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_79": {
    "LOT_NAME": "LOT 79",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Mümin Candaş (1971 - )",
    "ARTIST_HASH": "MUMIN_CANDAS",
    "NAME": "\"Barınaklar\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>50 x 50  cm<br>2019",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_79/0.d.webp?w=1200&h=1195",
      "./assets/auction_products/2024_DAILY_79/1.d.webp?w=1200&h=1194",
      "./assets/auction_products/2024_DAILY_79/2.d.webp?w=1200&h=1179",
      "./assets/auction_products/2024_DAILY_79/3.d.webp?w=1200&h=1120"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_79/0.m.webp?w=452&h=450",
      "./assets/auction_products/2024_DAILY_79/1.m.webp?w=452&h=450",
      "./assets/auction_products/2024_DAILY_79/2.m.webp?w=458&h=450",
      "./assets/auction_products/2024_DAILY_79/3.m.webp?w=482&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_81": {
    "LOT_NAME": "LOT 81",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925 - 2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Horoz\"",
    "TEXT": "Signed, Mixed Technique on Cardboard<br>50 x 70  cm<br>2007",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_81/0.d.webp?w=1200&h=855",
      "./assets/auction_products/2024_DAILY_81/1.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_81/2.d.webp?w=848&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_81/0.m.webp?w=632&h=450",
      "./assets/auction_products/2024_DAILY_81/1.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_81/2.m.webp?w=318&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_83": {
    "LOT_NAME": "LOT 83",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Hakan Esmer (1973 - )",
    "ARTIST_HASH": "HAKAN_ESMER",
    "NAME": "\"Heyamola\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>116 x 89 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_83/0.d.webp?w=967&h=1200",
      "./assets/auction_products/2024_DAILY_83/1.d.webp?w=944&h=1200",
      "./assets/auction_products/2024_DAILY_83/2.d.webp?w=1200&h=1105",
      "./assets/auction_products/2024_DAILY_83/3.d.webp?w=1200&h=859"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_83/0.m.webp?w=362&h=450",
      "./assets/auction_products/2024_DAILY_83/1.m.webp?w=354&h=450",
      "./assets/auction_products/2024_DAILY_83/2.m.webp?w=489&h=450",
      "./assets/auction_products/2024_DAILY_83/3.m.webp?w=629&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_86": {
    "LOT_NAME": "LOT 86",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "İbrahim Mısırlıoğlu (1959 - ) ",
    "ARTIST_HASH": "IBRAHIM_MISIRLIOGLU",
    "NAME": "\"İsimsiz \"",
    "TEXT": "Signed, Oil Paint on Canvas<br>100 x 85 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_86/0.d.webp?w=1032&h=1200",
      "./assets/auction_products/2024_DAILY_86/1.d.webp?w=1045&h=1200",
      "./assets/auction_products/2024_DAILY_86/2.d.webp?w=1200&h=749"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_86/0.m.webp?w=387&h=450",
      "./assets/auction_products/2024_DAILY_86/1.m.webp?w=392&h=450",
      "./assets/auction_products/2024_DAILY_86/2.m.webp?w=721&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_89": {
    "LOT_NAME": "LOT 89",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Can Genca (1985 - )",
    "ARTIST_HASH": "CAN_GENCA",
    "NAME": "\"İç İçe Portreler\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>50 x 70 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_89/0.d.webp?w=1200&h=845",
      "./assets/auction_products/2024_DAILY_89/1.d.webp?w=1200&h=843",
      "./assets/auction_products/2024_DAILY_89/2.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_89/3.d.webp?w=837&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_89/0.m.webp?w=639&h=450",
      "./assets/auction_products/2024_DAILY_89/1.m.webp?w=641&h=450",
      "./assets/auction_products/2024_DAILY_89/2.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_89/3.m.webp?w=314&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_90": {
    "LOT_NAME": "LOT 90",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Zafer Dilekçi (1978 - )",
    "ARTIST_HASH": "ZAFER_DILEKCI",
    "NAME": "\"Sırlardaki Kadın\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>160 x 120 cm<br>2021",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_90/0.d.webp?w=921&h=1200",
      "./assets/auction_products/2024_DAILY_90/1.d.webp?w=890&h=1200",
      "./assets/auction_products/2024_DAILY_90/2.d.webp?w=1200&h=777",
      "./assets/auction_products/2024_DAILY_90/3.d.webp?w=871&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_90/0.m.webp?w=345&h=450",
      "./assets/auction_products/2024_DAILY_90/1.m.webp?w=333&h=450",
      "./assets/auction_products/2024_DAILY_90/2.m.webp?w=695&h=450",
      "./assets/auction_products/2024_DAILY_90/3.m.webp?w=326&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_91": {
    "LOT_NAME": "LOT 91",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Bige Aksoy (1976 - )",
    "ARTIST_HASH": "BIGE_AKSOY",
    "NAME": "\"İsimsiz \"",
    "TEXT": "Signed, Mixed Technique on Canvas<br>120 x 120  cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_91/0.d.webp?w=1200&h=1199",
      "./assets/auction_products/2024_DAILY_91/1.d.webp?w=1200&h=1163",
      "./assets/auction_products/2024_DAILY_91/2.d.webp?w=1200&h=777",
      "./assets/auction_products/2024_DAILY_91/3.d.webp?w=752&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_91/0.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_91/1.m.webp?w=464&h=450",
      "./assets/auction_products/2024_DAILY_91/2.m.webp?w=695&h=450",
      "./assets/auction_products/2024_DAILY_91/3.m.webp?w=282&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_92": {
    "LOT_NAME": "LOT 92",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Gülşah Kurt (1995 - ) ",
    "ARTIST_HASH": "GULSAH_KURT",
    "NAME": "\"Eski I\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>80 x 100 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_92/0.d.webp?w=900&h=1200",
      "./assets/auction_products/2024_DAILY_92/1.d.webp?w=900&h=1200",
      "./assets/auction_products/2024_DAILY_92/2.d.webp?w=1200&h=1105",
      "./assets/auction_products/2024_DAILY_92/3.d.webp?w=883&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_92/0.m.webp?w=338&h=450",
      "./assets/auction_products/2024_DAILY_92/1.m.webp?w=338&h=450",
      "./assets/auction_products/2024_DAILY_92/2.m.webp?w=489&h=450",
      "./assets/auction_products/2024_DAILY_92/3.m.webp?w=331&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_93": {
    "LOT_NAME": "LOT 93",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925 - 2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Trio\"",
    "TEXT": "Signed, Mixed Technique on Cardboard<br>50 x 70 cm<br>2015",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_93/0.d.webp?w=1200&h=857",
      "./assets/auction_products/2024_DAILY_93/1.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_93/2.d.webp?w=849&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_93/0.m.webp?w=629&h=450",
      "./assets/auction_products/2024_DAILY_93/1.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_93/2.m.webp?w=319&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_95": {
    "LOT_NAME": "LOT 95",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925 - 2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Trio\"",
    "TEXT": "Signed, Mixed Technique on Cardboard<br>50 x 70 cm<br>2010",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_95/0.d.webp?w=1200&h=879",
      "./assets/auction_products/2024_DAILY_95/1.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_95/2.d.webp?w=852&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_95/0.m.webp?w=614&h=450",
      "./assets/auction_products/2024_DAILY_95/1.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_95/2.m.webp?w=320&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_96": {
    "LOT_NAME": "LOT 96",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Zafer Dilekçi (1978 - )",
    "ARTIST_HASH": "ZAFER_DILEKCI",
    "NAME": "\"Güç ve Hız \"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>100 x 100 cm<br>2018",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_96/0.d.webp?w=1200&h=1195",
      "./assets/auction_products/2024_DAILY_96/1.d.webp?w=1071&h=1200",
      "./assets/auction_products/2024_DAILY_96/2.d.webp?w=1200&h=718",
      "./assets/auction_products/2024_DAILY_96/3.d.webp?w=856&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_96/0.m.webp?w=452&h=450",
      "./assets/auction_products/2024_DAILY_96/1.m.webp?w=402&h=450",
      "./assets/auction_products/2024_DAILY_96/2.m.webp?w=752&h=450",
      "./assets/auction_products/2024_DAILY_96/3.m.webp?w=321&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_97": {
    "LOT_NAME": "LOT 97",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Uğur Pekcan (1992 - ) ",
    "ARTIST_HASH": "UGUR_PEKCAN",
    "NAME": "\"Döngü \"",
    "TEXT": "Signed, Mixed Technique on Canvas<br>70 x 100 cm<br>2018",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_97/0.d.webp?w=1200&h=849",
      "./assets/auction_products/2024_DAILY_97/1.d.webp?w=1200&h=863",
      "./assets/auction_products/2024_DAILY_97/2.d.webp?w=1200&h=749",
      "./assets/auction_products/2024_DAILY_97/3.d.webp?w=845&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_97/0.m.webp?w=636&h=450",
      "./assets/auction_products/2024_DAILY_97/1.m.webp?w=626&h=450",
      "./assets/auction_products/2024_DAILY_97/2.m.webp?w=721&h=450",
      "./assets/auction_products/2024_DAILY_97/3.m.webp?w=317&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_98": {
    "LOT_NAME": "LOT 98",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Bige Aksoy (1976 - )",
    "ARTIST_HASH": "BIGE_AKSOY",
    "NAME": "\"İsimsiz \"",
    "TEXT": "Signed, Mixed Technique on Canvas<br>90 x 90  cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_98/0.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_98/1.d.webp?w=1200&h=1197",
      "./assets/auction_products/2024_DAILY_98/2.d.webp?w=1200&h=900",
      "./assets/auction_products/2024_DAILY_98/3.d.webp?w=781&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_98/0.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_98/1.m.webp?w=451&h=450",
      "./assets/auction_products/2024_DAILY_98/2.m.webp?w=600&h=450",
      "./assets/auction_products/2024_DAILY_98/3.m.webp?w=293&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_99": {
    "LOT_NAME": "LOT 99",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Erdem Baytemur (1998 - ) ",
    "ARTIST_HASH": "ERDEM_BAYTEMUR",
    "NAME": "\"İsimsiz \"",
    "TEXT": "Signed, Oil Paint on Canvas<br>40 x 40  cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_99/0.d.webp?w=1200&h=1175",
      "./assets/auction_products/2024_DAILY_99/1.d.webp?w=1200&h=1178",
      "./assets/auction_products/2024_DAILY_99/2.d.webp?w=1200&h=1120",
      "./assets/auction_products/2024_DAILY_99/3.d.webp?w=825&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_99/0.m.webp?w=460&h=450",
      "./assets/auction_products/2024_DAILY_99/1.m.webp?w=459&h=450",
      "./assets/auction_products/2024_DAILY_99/2.m.webp?w=482&h=450",
      "./assets/auction_products/2024_DAILY_99/3.m.webp?w=309&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_100": {
    "LOT_NAME": "LOT 100",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Kıvanç Gülhan (1963 - )",
    "ARTIST_HASH": "KIVANC_GULHAN",
    "NAME": "\"İsimsiz\"",
    "TEXT": "Signed, Watercolor Paint on Cardboard<br>22 x 35 cm<br>2010",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_100/0.d.webp?w=960&h=1200",
      "./assets/auction_products/2024_DAILY_100/1.d.webp?w=1200&h=1120",
      "./assets/auction_products/2024_DAILY_100/2.d.webp?w=847&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_100/0.m.webp?w=360&h=450",
      "./assets/auction_products/2024_DAILY_100/1.m.webp?w=482&h=450",
      "./assets/auction_products/2024_DAILY_100/2.m.webp?w=318&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_103": {
    "LOT_NAME": "LOT 103",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925 - 2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Trio\"",
    "TEXT": "Signed, Mixed Technique on Cardboard<br>50 x 70 cm<br>2014",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_103/0.d.webp?w=1200&h=858",
      "./assets/auction_products/2024_DAILY_103/1.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_103/2.d.webp?w=881&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_103/0.m.webp?w=629&h=450",
      "./assets/auction_products/2024_DAILY_103/1.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_103/2.m.webp?w=330&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_106": {
    "LOT_NAME": "LOT 106",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925 - 2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Trio\"",
    "TEXT": "Signed, Mixed Technique on Cardboard<br>50 x 70 cm<br>2014",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_106/0.d.webp?w=1200&h=865",
      "./assets/auction_products/2024_DAILY_106/1.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_106/2.d.webp?w=900&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_106/0.m.webp?w=624&h=450",
      "./assets/auction_products/2024_DAILY_106/1.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_106/2.m.webp?w=337&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_107": {
    "LOT_NAME": "LOT 107",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Zafer Dilekçi (1978 - )",
    "ARTIST_HASH": "ZAFER_DILEKCI",
    "NAME": "\"Şahlanışa Doğru\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>50 x 70 cm<br>2021",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_107/0.d.webp?w=1200&h=842",
      "./assets/auction_products/2024_DAILY_107/1.d.webp?w=1200&h=838",
      "./assets/auction_products/2024_DAILY_107/2.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_107/3.d.webp?w=845&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_107/0.m.webp?w=641&h=450",
      "./assets/auction_products/2024_DAILY_107/1.m.webp?w=645&h=450",
      "./assets/auction_products/2024_DAILY_107/2.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_107/3.m.webp?w=317&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_108": {
    "LOT_NAME": "LOT 108",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Işıl Çalık (1965 - )",
    "ARTIST_HASH": "ISIL_CALIK",
    "NAME": "\"Mor Salkımlı Avlu\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>80 x 60 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_108/0.d.webp?w=899&h=1200",
      "./assets/auction_products/2024_DAILY_108/1.d.webp?w=899&h=1200",
      "./assets/auction_products/2024_DAILY_108/2.d.webp?w=1200&h=749",
      "./assets/auction_products/2024_DAILY_108/3.d.webp?w=832&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_108/0.m.webp?w=337&h=450",
      "./assets/auction_products/2024_DAILY_108/1.m.webp?w=337&h=450",
      "./assets/auction_products/2024_DAILY_108/2.m.webp?w=721&h=450",
      "./assets/auction_products/2024_DAILY_108/3.m.webp?w=312&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_109": {
    "LOT_NAME": "LOT 109",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Elif Şule Şanlı (2000 - )",
    "ARTIST_HASH": "ELIF_SULE_SANLI",
    "NAME": "\"Karanlığın Gölgesinde \"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>80 x 60 cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_109/0.d.webp?w=908&h=1200",
      "./assets/auction_products/2024_DAILY_109/1.d.webp?w=915&h=1200",
      "./assets/auction_products/2024_DAILY_109/2.d.webp?w=1200&h=749",
      "./assets/auction_products/2024_DAILY_109/3.d.webp?w=814&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_109/0.m.webp?w=341&h=450",
      "./assets/auction_products/2024_DAILY_109/1.m.webp?w=343&h=450",
      "./assets/auction_products/2024_DAILY_109/2.m.webp?w=721&h=450",
      "./assets/auction_products/2024_DAILY_109/3.m.webp?w=305&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_110": {
    "LOT_NAME": "LOT 110",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Erdem Baytemur (1998 - )",
    "ARTIST_HASH": "ERDEM_BAYTEMUR",
    "NAME": "\"Mavi Boşluk\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>25 x 35 cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_110/0.d.webp?w=1200&h=851",
      "./assets/auction_products/2024_DAILY_110/1.d.webp?w=1200&h=839",
      "./assets/auction_products/2024_DAILY_110/2.d.webp?w=1200&h=1120",
      "./assets/auction_products/2024_DAILY_110/3.d.webp?w=815&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_110/0.m.webp?w=635&h=450",
      "./assets/auction_products/2024_DAILY_110/1.m.webp?w=643&h=450",
      "./assets/auction_products/2024_DAILY_110/2.m.webp?w=482&h=450",
      "./assets/auction_products/2024_DAILY_110/3.m.webp?w=306&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_111": {
    "LOT_NAME": "LOT 111",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Zafer Dilekçi (1978 - )",
    "ARTIST_HASH": "ZAFER_DILEKCI",
    "NAME": "\"Mavi Yalnızlık\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>50 x 70  cm<br>2021",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_111/0.d.webp?w=1200&h=839",
      "./assets/auction_products/2024_DAILY_111/1.d.webp?w=1200&h=850",
      "./assets/auction_products/2024_DAILY_111/2.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_111/3.d.webp?w=846&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_111/0.m.webp?w=644&h=450",
      "./assets/auction_products/2024_DAILY_111/1.m.webp?w=635&h=450",
      "./assets/auction_products/2024_DAILY_111/2.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_111/3.m.webp?w=317&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_112": {
    "LOT_NAME": "LOT 112",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925 - 2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Trio\"",
    "TEXT": "Signed, Mixed Technique on Cardboard<br>50 x 70  cm<br>2014",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_112/0.d.webp?w=1200&h=863",
      "./assets/auction_products/2024_DAILY_112/1.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_112/2.d.webp?w=858&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_112/0.m.webp?w=625&h=450",
      "./assets/auction_products/2024_DAILY_112/1.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_112/2.m.webp?w=322&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_113": {
    "LOT_NAME": "LOT 113",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Işıl Çalık (1965 - )",
    "ARTIST_HASH": "ISIL_CALIK",
    "NAME": "\"Keyifli Bir Yürüyüş \"",
    "TEXT": "Signed, Oil Paint on Canvas<br>80 x 60 cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_113/0.d.webp?w=839&h=1200",
      "./assets/auction_products/2024_DAILY_113/1.d.webp?w=889&h=1200",
      "./assets/auction_products/2024_DAILY_113/2.d.webp?w=1200&h=749",
      "./assets/auction_products/2024_DAILY_113/3.d.webp?w=888&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_113/0.m.webp?w=315&h=450",
      "./assets/auction_products/2024_DAILY_113/1.m.webp?w=333&h=450",
      "./assets/auction_products/2024_DAILY_113/2.m.webp?w=721&h=450",
      "./assets/auction_products/2024_DAILY_113/3.m.webp?w=333&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_117": {
    "LOT_NAME": "LOT 117",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Uğur Pekcan (1992 - ) ",
    "ARTIST_HASH": "UGUR_PEKCAN",
    "NAME": "\"Yaşlı Ağaç\"",
    "TEXT": "Signed, Mixed Technique on Canvas<br>100 x 100 cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_117/0.d.webp?w=1155&h=1200",
      "./assets/auction_products/2024_DAILY_117/1.d.webp?w=1200&h=1090",
      "./assets/auction_products/2024_DAILY_117/2.d.webp?w=1200&h=900",
      "./assets/auction_products/2024_DAILY_117/3.d.webp?w=705&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_117/0.m.webp?w=433&h=450",
      "./assets/auction_products/2024_DAILY_117/1.m.webp?w=495&h=450",
      "./assets/auction_products/2024_DAILY_117/2.m.webp?w=600&h=450",
      "./assets/auction_products/2024_DAILY_117/3.m.webp?w=264&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_120": {
    "LOT_NAME": "LOT 120",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Müge Cücü (1978 - ) ",
    "ARTIST_HASH": "MUGE_CUCU",
    "NAME": "\"Depresyon \"",
    "TEXT": "Signed, Mixed Technique on Canvas<br>100 x 70 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_120/0.d.webp?w=847&h=1200",
      "./assets/auction_products/2024_DAILY_120/1.d.webp?w=952&h=1200",
      "./assets/auction_products/2024_DAILY_120/2.d.webp?w=1200&h=749",
      "./assets/auction_products/2024_DAILY_120/3.d.webp?w=822&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_120/0.m.webp?w=318&h=450",
      "./assets/auction_products/2024_DAILY_120/1.m.webp?w=357&h=450",
      "./assets/auction_products/2024_DAILY_120/2.m.webp?w=721&h=450",
      "./assets/auction_products/2024_DAILY_120/3.m.webp?w=308&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_121": {
    "LOT_NAME": "LOT 121",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Erdem Baytemur (1998 - ) ",
    "ARTIST_HASH": "ERDEM_BAYTEMUR",
    "NAME": "\"Huseyingazi Mount\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>40 x 40  cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_121/0.d.webp?w=1200&h=1190",
      "./assets/auction_products/2024_DAILY_121/1.d.webp?w=1200&h=1166",
      "./assets/auction_products/2024_DAILY_121/2.d.webp?w=1200&h=1120",
      "./assets/auction_products/2024_DAILY_121/3.d.webp?w=675&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_121/0.m.webp?w=454&h=450",
      "./assets/auction_products/2024_DAILY_121/1.m.webp?w=463&h=450",
      "./assets/auction_products/2024_DAILY_121/2.m.webp?w=482&h=450",
      "./assets/auction_products/2024_DAILY_121/3.m.webp?w=253&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_123": {
    "LOT_NAME": "LOT 123",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Filiz Aksu (1977 - ) ",
    "ARTIST_HASH": "FILIZ_AKSU",
    "NAME": "\"İz Bırakanlar \"",
    "TEXT": "Signed, -<br>100 x 150 cm<br>2022",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_123/0.d.webp?w=1200&h=796",
      "./assets/auction_products/2024_DAILY_123/1.d.webp?w=1200&h=778",
      "./assets/auction_products/2024_DAILY_123/2.d.webp?w=1200&h=900",
      "./assets/auction_products/2024_DAILY_123/3.d.webp?w=831&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_123/0.m.webp?w=678&h=450",
      "./assets/auction_products/2024_DAILY_123/1.m.webp?w=694&h=450",
      "./assets/auction_products/2024_DAILY_123/2.m.webp?w=600&h=450",
      "./assets/auction_products/2024_DAILY_123/3.m.webp?w=312&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_125": {
    "LOT_NAME": "LOT 125",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Erdem Baytemur (1998 - ) ",
    "ARTIST_HASH": "ERDEM_BAYTEMUR",
    "NAME": "\"Bağışlayan\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>130 x 200 cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_125/0.d.webp?w=1200&h=778",
      "./assets/auction_products/2024_DAILY_125/1.d.webp?w=1200&h=796",
      "./assets/auction_products/2024_DAILY_125/2.d.webp?w=1200&h=725",
      "./assets/auction_products/2024_DAILY_125/3.d.webp?w=849&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_125/0.m.webp?w=694&h=450",
      "./assets/auction_products/2024_DAILY_125/1.m.webp?w=679&h=450",
      "./assets/auction_products/2024_DAILY_125/2.m.webp?w=745&h=450",
      "./assets/auction_products/2024_DAILY_125/3.m.webp?w=319&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_128": {
    "LOT_NAME": "LOT 128",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Yonca Usta (1992 - )",
    "ARTIST_HASH": "YONCA_USTA",
    "NAME": "\"Son Rüya - No: 12\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>35 x 35 cm<br>-",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_128/0.d.webp?w=1197&h=1200",
      "./assets/auction_products/2024_DAILY_128/1.d.webp?w=1200&h=1198",
      "./assets/auction_products/2024_DAILY_128/2.d.webp?w=1200&h=1120",
      "./assets/auction_products/2024_DAILY_128/3.d.webp?w=851&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_128/0.m.webp?w=449&h=450",
      "./assets/auction_products/2024_DAILY_128/1.m.webp?w=451&h=450",
      "./assets/auction_products/2024_DAILY_128/2.m.webp?w=482&h=450",
      "./assets/auction_products/2024_DAILY_128/3.m.webp?w=319&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_129": {
    "LOT_NAME": "LOT 129",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Erdem Baytemur (1998 - )",
    "ARTIST_HASH": "ERDEM_BAYTEMUR",
    "NAME": "\"Ebrar\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>60 x 50  cm<br>2020",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_129/0.d.webp?w=964&h=1200",
      "./assets/auction_products/2024_DAILY_129/1.d.webp?w=987&h=1200",
      "./assets/auction_products/2024_DAILY_129/2.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_129/3.d.webp?w=675&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_129/0.m.webp?w=362&h=450",
      "./assets/auction_products/2024_DAILY_129/1.m.webp?w=370&h=450",
      "./assets/auction_products/2024_DAILY_129/2.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_129/3.m.webp?w=253&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_131": {
    "LOT_NAME": "LOT 131",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Uğur Pekcan (1992 - )",
    "ARTIST_HASH": "UGUR_PEKCAN",
    "NAME": "\"Şaman Yolculuğu\"",
    "TEXT": "Signed, Mixed Technique on Canvas<br>160 x 90  cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_131/0.d.webp?w=1200&h=696",
      "./assets/auction_products/2024_DAILY_131/1.d.webp?w=1200&h=744",
      "./assets/auction_products/2024_DAILY_131/2.d.webp?w=1200&h=725",
      "./assets/auction_products/2024_DAILY_131/3.d.webp?w=745&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_131/0.m.webp?w=776&h=450",
      "./assets/auction_products/2024_DAILY_131/1.m.webp?w=725&h=450",
      "./assets/auction_products/2024_DAILY_131/2.m.webp?w=745&h=450",
      "./assets/auction_products/2024_DAILY_131/3.m.webp?w=279&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_132": {
    "LOT_NAME": "LOT 132",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Adnan Turani (1925 - 2016)",
    "ARTIST_HASH": "ADNAN_TURANI",
    "NAME": "\"Trio\"",
    "TEXT": "Signed, Mixed Technique on Cardboard<br>50 x 70 cm<br>2016",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_132/0.d.webp?w=1200&h=853",
      "./assets/auction_products/2024_DAILY_132/1.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_132/2.d.webp?w=866&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_132/0.m.webp?w=633&h=450",
      "./assets/auction_products/2024_DAILY_132/1.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_132/2.m.webp?w=325&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_134": {
    "LOT_NAME": "LOT 134",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Yonca Usta (1992 - )",
    "ARTIST_HASH": "YONCA_USTA",
    "NAME": "\"Zen - No 1\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>40 x 40 cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_134/0.d.webp?w=1193&h=1200",
      "./assets/auction_products/2024_DAILY_134/1.d.webp?w=1195&h=1200",
      "./assets/auction_products/2024_DAILY_134/2.d.webp?w=1200&h=1120",
      "./assets/auction_products/2024_DAILY_134/3.d.webp?w=840&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_134/0.m.webp?w=448&h=450",
      "./assets/auction_products/2024_DAILY_134/1.m.webp?w=448&h=450",
      "./assets/auction_products/2024_DAILY_134/2.m.webp?w=482&h=450",
      "./assets/auction_products/2024_DAILY_134/3.m.webp?w=315&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_135": {
    "LOT_NAME": "LOT 135",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Uğur Pekcan (1992 - ) ",
    "ARTIST_HASH": "UGUR_PEKCAN",
    "NAME": "\"Şaman Eli\"",
    "TEXT": "Signed, Mixed Technique on Canvas<br>112 x 112 cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_135/0.d.webp?w=1200&h=1185",
      "./assets/auction_products/2024_DAILY_135/1.d.webp?w=1200&h=1128",
      "./assets/auction_products/2024_DAILY_135/2.d.webp?w=1200&h=718",
      "./assets/auction_products/2024_DAILY_135/3.d.webp?w=696&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_135/0.m.webp?w=456&h=450",
      "./assets/auction_products/2024_DAILY_135/1.m.webp?w=479&h=450",
      "./assets/auction_products/2024_DAILY_135/2.m.webp?w=752&h=450",
      "./assets/auction_products/2024_DAILY_135/3.m.webp?w=261&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_138": {
    "LOT_NAME": "LOT 138",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Yonca Usta (1992 - )",
    "ARTIST_HASH": "YONCA_USTA",
    "NAME": "\"Zen - No 2\"",
    "TEXT": "Signed, Acrylic Paint on Canvas<br>40 x 40 cm<br>2024",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_138/0.d.webp?w=1195&h=1200",
      "./assets/auction_products/2024_DAILY_138/1.d.webp?w=1200&h=1197",
      "./assets/auction_products/2024_DAILY_138/2.d.webp?w=1200&h=1120",
      "./assets/auction_products/2024_DAILY_138/3.d.webp?w=838&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_138/0.m.webp?w=448&h=450",
      "./assets/auction_products/2024_DAILY_138/1.m.webp?w=451&h=450",
      "./assets/auction_products/2024_DAILY_138/2.m.webp?w=482&h=450",
      "./assets/auction_products/2024_DAILY_138/3.m.webp?w=314&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_139": {
    "LOT_NAME": "LOT 139",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Ertuğrul Ateş (1954 - )",
    "ARTIST_HASH": "ERTUGRUL_ATES",
    "NAME": "\"Arka Bahçe VII\"",
    "TEXT": "Signed, Oil Paint on Canvas<br>150 (çap)  cm<br>2022",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_139/0.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_139/1.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_139/2.d.webp?w=1200&h=725",
      "./assets/auction_products/2024_DAILY_139/3.d.webp?w=842&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_139/0.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_139/1.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_139/2.m.webp?w=745&h=450",
      "./assets/auction_products/2024_DAILY_139/3.m.webp?w=316&h=450"
    ],
    "STATEMENT": "-"
  },
  "2024_DAILY_142": {
    "LOT_NAME": "LOT 142",
    "CATALOG_HASH": "2024_DAILY",
    "ARTIST_NAME": "Müge Cücü (1978 - ) ",
    "ARTIST_HASH": "MUGE_CUCU",
    "NAME": "\"Kafamdaki Kaos\"",
    "TEXT": "Signed, Mixed Technique on Canvas<br>50 x 60 cm<br>2023",
    "IMAGE_DESKTOP": [
      "./assets/auction_products/2024_DAILY_142/0.d.webp?w=960&h=1200",
      "./assets/auction_products/2024_DAILY_142/1.d.webp?w=1045&h=1200",
      "./assets/auction_products/2024_DAILY_142/2.d.webp?w=1200&h=1200",
      "./assets/auction_products/2024_DAILY_142/3.d.webp?w=826&h=1200"
    ],
    "IMAGE_MOBILE": [
      "./assets/auction_products/2024_DAILY_142/0.m.webp?w=360&h=450",
      "./assets/auction_products/2024_DAILY_142/1.m.webp?w=392&h=450",
      "./assets/auction_products/2024_DAILY_142/2.m.webp?w=450&h=450",
      "./assets/auction_products/2024_DAILY_142/3.m.webp?w=310&h=450"
    ],
    "STATEMENT": "-"
  }
};

// @ts-ignore
export const auction_config_en = {
  AUCTION_STATE_MAP: {
    AUCTION: 'Auction',
    LIVE: 'Live',
    KNOCKDOWN: 'Finished',
  },
  CATALOG_LOT_IS_YOURS: 'is Yours',
  LOT_IS_YOURS: 'Lot is Yours',
  REQUIRED_PARTS: '* Required',
  NO_KYC_DESCRIPTION: 'For auction bids, you are required to make an "Identity Declaration" for security reasons.',
  BILLING: {
    SAVE_ADDRESS: 'Save Address',
    CANCEL: 'Cancel',
  },
  APPROVE_PAYMENT: {
    HEADER: 'APPROVE PAYMENT',
    BODY: 'I confirm that I have made the Transfer / Eft transaction according to the specified rules.',
    CANCEL: 'Cancel',
    APPROVE: 'Approve Payment',
  },
  DELETE_ADDRESS: {
    HEADER: 'DELETE ADDRESS',
    BODY: 'Are you sure you want to delete your saved address?',
    CANCEL: 'Cancel',
    APPROVE: 'Delete',
  },
  LIVE_CATALOG: 'Artiox Live Auctions',
  PAST_CATALOG: 'Artiox Past Auctions',
  PRODUCT_INFO: 'Art Product Information',
  CATALOG_TYPE: {
    CATALOG: 'Catalog',
    DAILY: 'Daily',
    STATUS: {
      LIVE: 'Live',
      UPCOMING: 'Soon',
      ENDED: 'Ended',
    },
    CATALOG_TEXT: 'Catalog Auctions are Artiox Auction systems that offer selected artworks within the auction.',
    CATALOG_IMG: './assets/images/iphone_artiox_rotated.png',
    DAILY_TEXT: 'Daily Auctions are Artiox Auction systems that offers selected artworks within the auction.',
    DAILY_IMG: './assets/images/iphone_artiox.png',
  },
  FOLLOW: 'Follow',
  SHARE: {
    SHARE: 'Share',
    HEADER: 'Share Auction',
    DESCRIPTION: 'You can share the auction item on your social media accounts.',
    OR: 'or',
    TWITTER: {
      TEXT: '',
      // URL: '',
      HASHTAGS: '',
      VIA: '',
      // RELATED: '',
    },
    LINKEDIN: {
      TITLE: '',
      SUMMARY: '',
      // URL: '',
      // SOURCE: '',
      // MINI: '',
    },
    WHATSAPP: {
      TEXT: '',
    },
  },
  AGREEMENT: {
      TITLE: 'Artiox Auction Participation Rules, Terms and Agreements',
      SUBTITLE: 'You must read and agree following rules, terms and agreements to participate in online auctions organized by Artiox Kripto Varlık Alım Satım Platformu A.Ş.',
      RULES: ['Members/customers participating in online auctions organized by Artiox Kripto Varlık Alım Satım Platformu A.Ş. (hereinafter referred to as "Artiox" or "Company") accept the following conditions in advance;'
        ,
        '1. Artiox is an intermediary for the seller and is entitled to the Purchase Intermediary Commission from the Buyer at the time of sale (hereinafter referred to as the "Buyer’s Commission") due to the intermediary service it provides electronically. In the invoice issued to the buyer, the Buyer\'s Commission is added to the final bid price and, in accordance with the legislation, KDV is added to the final bid price and buyer\'s commission. Buyer Commission is 10% net. In accordance with the legislation, 20% KDV is added to both the final offer price and the Buyer\'s Commission. In case of an increase or decrease in legal KDV rates, the current KDV rate is applied. Even if the buyer gives up purchasing the work subject to auction, he is obliged to pay the 10% commission fee and the legal KDV that will be added to the commission fee, in addition to the penalty. The service Artiox offers in this context is an intermediary service performed instantly in an electronic environment and is outside the scope of the right of withdrawal.'
        ,
        '2. The works offered for sale in our Online Auction are checked by museum experts in accordance with the Cultural and Natural Assets Law No. 2863, and documents are issued for those that fall within the scope of the Law and are deemed necessary.'
        ,
        '3. In order to participate in our online auctions, customers must sign up to our website at www.artiox.com or sign up via the Artiox mobile application.\n'
        ,
        '4. The works/products included in the online auctions organized by Artiox will be available at Artiox\'s Ankara location after they are published on our online catalog website before the sale. They will be displayed in the Artiox office at Kızılırmak Mah. Dumlupınar Bul. A Blok No:9A D:287 Çankaya/Ankara and offered for customers\' inspection. Participants in the auction declare and accept that they have seen and examined the works/products at Artiox\'s address above. Customers accept and declare that all works offered for sale at the auction are offered for sale "as is", that they have sufficiently examined the work/item before bidding for it, and that they waive the provisions specified in Article 223 of the Code of Obligations. There is no obligation to write "as it is" next to each work/product. All information provided in the promotions on our mobile application and website regarding the auction are the opinions of our Company employees and are general information, not commitments. Participants accept this situation in advance. Artiox is not responsible for the monopoly (guarantee) of the work/product sold. The Buyer accepts and declares that he will not raise any objection or request from Artiox in this regard. In our auctions held online, although the bidding period starts day/days before, no works are sold until the auction ends on the auction end date.'
        ,
        '5. In our online auctions, price increases are made by clicking the "Make a Bid" button. The customer is bound by the offer he made online. The work/product offered for sale online in the auction is auctioned to the customer who made the highest bid at the end of the auction. The buyer cannot declare the auction void for any reason. Participants can bid for each work/product until the end of the given period, and the customer who has made the highest bid at the end of the said period will win the auction for the work/product in question.'
        ,
        '6. Artiox has the right to determine the value increases each time the "Make an Offer" button is clicked and to change the value increase intervals when deemed necessary.'
        ,
        '7. Artiox has the right to set the starting price of a work/product offered for sale below the consignment price.'
        ,
        '8. In order to participate in our online auctions, customers must use the password they created during membership to our mobile application or Website. "Password" is personal, it is prohibited for anyone other than the member customer to use it and/or for the member customer to have it used by anyone other than himself. The customer who owns the "password" accepts in advance that Artiox will not accept any liability in case his/her "password" is used by others.'
        ,
        '9. Artiox secures information such as bids, bidders, highest bidder, etc., and participants accept and declare in advance that they have no right to object to these records.'
        ,
        '10. For all purchased works, Artiox issues an invoice to the name, surname, and address in the mobile application or website membership records of the customers who purchased the works, or to the company name and address determined by the customers. However, issuing an invoice does not mean that Artiox is the seller, Artiox is not the seller, but the intermediary who mediates the sale.'
        ,
        '11. The Buyer\'s Commission of Aritox becomes due for every item/product sold at the auction from the moment the auction concludes. If the buyer fails to fully and promptly pay the total amount for the items/products purchased at the auction (including the sale price, Sales Commission, applicable KDV, etc.) in accordance with this Aritox Auction Participation Terms and Conditions, Aritox reserves the right to cancel the said sale. However, in such a case, the buyer, who has not fulfilled the payment obligation in accordance with these Auction Participation Terms and Conditions, shall be obligated to pay, in addition to the Buyer\'s Commission, the Sales Commission (referred to as "Seller\'s Commission") received from the owners of the items/products, KDV related to commissions, and all losses incurred by Aritox, from the date of the invoice, along with commercial interest. Aritox reserves all other rights to legal action and claims.'
        ,
        '12. The auction service offered by Artiox online is a brokerage service performed instantly in electronic environment and is outside the scope of the right of withdrawal. Since the auctions organized by Artiox electronically are carried out in accordance with the regulation in Article 274 and subsequent articles of the Turkish Code of Obligations and cannot be considered as a distance sales contract regulated in Article 48 of the Consumer Law, participants in the auction do not have the right to withdraw. If the person or organization that wins the auction by making the last bid at the auction or the person who wins the auction does not pay the price of the work in full within 7 (seven) days, Artiox may, if it wishes, terminate the sales contract, impose a 20% penalty and compensate all losses incurred with commercial interest on a monthly basis starting from the invoice date. It is authorized to be collected together with 10% interest. Artiox reserves all other lawsuit and demand rights. The sold work will not be delivered to the buyer unless all expenses incurred by Artiox for the preservation of the work within the period until the invoice amount is collected are paid by the buyer.'
        ,
        '13. The buyer is obliged to pay the total Purchase Price, consisting of the purchase price, buyer\'s commission and KDV, within 7 (seven) days at the latest from the end of the auction. The Buyer must have paid the Total Purchase Price in full in order to receive the works/products purchased from Artiox.'
        ,
        '14. After paying the Total Purchase Price in full, the Buyer receives the purchased works/products from Artiox address. The Buyer may request the delivery of the purchased items by Artiox\'s vehicles, transportation or courier at his own cost, in which case he accepts and undertakes in advance that he will not hold the Company responsible for any damage that may occur during transportation. Additionally, Artiox cannot be held responsible for delays caused by cargo companies. Artiox will send/deliver the product/item to the buyer via cargo within 7 (seven) working days for local deliveries and 14 (fourteen) working days for out-of-city deliveries. Artiox can notify the buyer that the goods have been shipped via the membership screen on the website or mobile application, via e-mail, telephone, text message or similar means.'
        ,
        '15. Inaccuracy in the information provided in the membership registration of notifications, notifications, and product/product shipments by cargo to the contact information such as the full mailing address, e-mail, mobile phone number specified by the buyers in their mobile application or website membership registration, Artiox cannot be held responsible for late or no delivery to the recipient due to changes or delays caused by P.T.T./cargo.'
        ,
        '16. Artiox cannot be held legally responsible in cases where the works/products offered for sale do not have the declared qualities, do not comply with the declaration in terms of origin, are disposed of without the consent of the right holder, or are defective for other reasons, since Artiox is not the seller. In such cases, legal responsibility belongs to the person(s) requesting these works/products to be offered for sale.'
        ,
        '17. Artiox has the right to withdraw any one or more artworks/products offered for sale, sell them together with other artworks/products, separate them, and refuse the sale. The company, in the online auctions it organizes, has the right to evaluate any and all objections and notifications regarding the artworks/products until the end of the auction, and, as necessary, has the right to remove the said artworks/products from the scope of the auction and to apply legal procedures.'
        ,
        '18. Individuals under the age of 18 cannot become members and cannot participate in auctions. Artiox has the authority to refrain from accepting individuals whom it deems suspicious and/or problematic as members, even without providing any excuses, and has the right not to admit them to the auction, to cancel their membership, and/or to cancel a portion or all of the bids they have made in an ongoing auction. In addition, the liability provisions set forth in Article 214 and Article 218 of the Turkish Code of Obligations cannot be applied to Artiox due to the Company\'s intermediary role for the seller.'
        ,
        '19. In accordance with Article 45 of Law No. 5846 and the decision dated 27.09.2006, numbered 2006/10880 by the Council of Ministers, within this scope, with the condition of complying with the documentation, Artiox reserves the right of recourse regarding the shares that must be paid to artists or rights holders in relation to the artworks to be sold at the auction.'
        ,
        '20. In accordance with Article 45 of Law No. 5846 and the decision dated 27.09.2006, numbered 2006/10880 by the Council of Ministers, within this scope, with the condition of complying with the documentation, Artiox reserves the right of recourse regarding the shares that must be paid to artists or rights holders in relation to the artworks to be sold at the auction.'
        ,
        '21. In online auctions that have been published and/or started, any spelling errors, if present, will be corrected based on the corrections provided by Artiox.'
        ,
        '22. Any kind of text and photographs published by Artiox in the mobile application or website, and therefore in the online environment, for the auctions organized by the Company, cannot be partially or entirely published without the Company\'s permission.'
        ,
        '23. After the auction organized by the Company is published in the online environment, any new laws and/or taxes, as well as changes in the law and/or tax rates, will be reflected to the buyers, and Artiox will not be held responsible.'
        ,
        '24. Buyers residing outside of Turkey are obligated to examine the laws, taxes, and all costs related to the export of the artworks/products they are interested in and their entry into their respective country before participating in the auction. Buyers cannot hold Artiox responsible for any legal, tax, shipping, and/or cost issues they encounter in relation to the export of the purchased artworks/products or their entry into their respective country, and they cannot return the items they have purchased.'
        ,
        '25. Everyone who becomes a member of the Artiox mobile application or website and participates in the online auctions organized by us is considered to have declared that they have read and accepted this Artiox Auction Participation Agreement in its entirety.'
        ,
        '26. In case of disputes arising from these participation terms, the Courts and Enforcement Offices in Ankara shall have jurisdiction.'],
      CHECKBOX: 'I confirm the legal obligations specified and rules by Artiox Auction',
      ACCEPT: 'Confirm',
      CANCEL: 'Cancel',
  },
  BIDS: {STRING: 'Bids ($AMOUNT)', TOKENS: ['$AMOUNT']},
  MY_BIDS: {
    ONGOING: 'LOT IS YOURS',
    EXCEEDED: 'EXCEEDED',
    WON: 'You Won'
  },
  AUCTION_START: 'Auction Start',
  AUCTION_END: 'Auction End',
  AUCTION_FINALIZED: {
    HEADER: {STRING: 'Auction has ended as of $END. Thank you for your interest.', TOKENS: ['$END']},
    CLOSE: 'Close Price',
    COST: 'Buyer Cost',
    OPEN: {STRING: 'Opening price of the artwork: $OPEN', TOKENS: ['$OPEN']},
  },
  OPENING_PRICE: 'Open Price',
  CURRENT_PRICE: 'Current Price',
  BID: 'Make Bid',
  QUICK_BID: 'Quick Bid',
  BID_RULES: {
    TITLE: 'Artiox Auction Bidding Rules',
    SUBTITLE: 'Artiox Auction bidding rules and bidding processes are as follows. Click to see the complete <span><a class="red-link" href="../assets/pdfs/Auction Rules.pdf" target="_blank">auction rules</a></span>.',
    TEXT: [
      'When you bid in auctions, you  <span class="purple-highlight">automatically accept</span> the Artiox auction rules. You can review the full Artiox Auction Rules by clicking <span><a class="green-link" href="../assets/pdfs/Auction Rules.pdf" target="_blank">here</a></span>. ',
      '<span class="purple-highlight">Commission and taxes </span> are added to each bid. You can see the final version of your offer on the offer confirmation screen.',
      'The <span class="purple-highlight">minimum bid amount</span> that can be placed when the auction starts is stated in the artwork information. Bids below this amount will be deemed invalid.',
      'The bid <span class="purple-highlight">increase rates</span> of auctions organized by Artiox are predetermined. To review bid increase rates click <span><a class="green-link" href="../assets/pdfs/Bid Rules.pdf" target="_blank">here</a></span>. ',
      'When you bid on an artwork, you also <span class="purple-highlight">agree to purchase</span> that artwork in the case of your bid winning the auction.',
    ]
  },
  DURATION_RULES: {
    TITLE: 'Artiox Duration Time Rules',
    SUBTITLE: 'Artiox Auction duration rules are as follows. Click to see the complete <span><a class="red-link" href="../assets/pdfs/Auction Rules.pdf" target="_blank">auction rules</a></span>.',
    TEXT: [
      'The starting and closing dates of the Catalog and Daily Auctions are <span class="purple-highlight">predetermined</span>. While the works listed in Catalog Auctions are auctioned at the same time, the designated periods of the works in the catalogs <span class="purple-highlight">may differ</span>. ',
      'In auctions, if a bid is placed within the last three minutes, +3 minutes will be added to the duration. For each subsequent bid,  <span class="purple-highlight">the auction is extended  </span>  by an additional three minutes. When the auction is extended in this way and there is no other bid, the last bid placed becomes the winner of the auction.',
      'You can follow <span class="purple-highlight">the status of the auctions</span> by the color of the timer located under the item description. Ongoing auctions are indicated in purple. Yellow indicates that an offer came in the last three minutes and the deadline is extended. Closed auctions are marked in red. ',
    ]
  },
  PRICE_ESTIMATION_RULES: {
    TITLE: 'Fiyat Nasıl Tahmin Edilir',
    TEXT: [
      '-  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. ',
      '-  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. ',
      '-  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. ',
      '-  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. ',
    ],
  },
  PAST_PRODUCT_INFO: {
    TITLE: 'Artiox Auction Artwork Detail',
    DESCRIPTION: 'The sale details of the Artiox Auction finalized work are given below.',
    OPEN: 'Starting Price',
    CLOSE: 'Closing Price',
    TOTAL: 'Closing Cost (Taxes and Commission included)',
  },
  BID_REPORT: {
    LOT_IS_YOURS: {
      TITLE: 'Reminder: Lot is Yours',
      TEXT: 'You are the highest bidder for this item',
      BODY: 'Are you sure you want to raise your bid?',
      CANCEL: 'Cancel',
      BID: 'Raise My Bid',
    },
    TITLE: 'Artiox Auction Make Bid',
    DESCRIPTION: {
      STRING: 'You must confirm your bid for Lot $LOT of the $YEAR Daily Auction. Cost of the work to the buyer:',
      TOKENS: ['$YEAR', '$LOT']
    },
    WARNING: '"I accept my offer knowing that it is irrecoverable and that I have no right of withdrawal."',
    LEGAL: 'I accept the legal obligations set out in the <a href="../../assets/pdfs/Müzayede%20Kullanıcı%20Sözleşmesi.pdf" target="_blank"> auction contract </a> and <a href="../../assets/pdfs/Müzayede%20Kuralları.pdf" target="_blank"> rules. </a>',
    BID_PRICE: 'Bid Amount',
    COMMISSIONS: {STRING: 'Commission Amount ($BUYER_FEE%)', TOKENS: ['$BUYER_FEE']},
    TAX: {STRING: 'VAT ($TAX_RATE%)', TOKENS: ['$TAX_RATE']},
    TOTAL: 'Cost to Buyer',
    ACCEPT: 'Accept',
    REJECT: 'Reject',
  },
  ACCOUNT_LEVEL: {
    TITLE: 'Account Level Not Sufficient',
    TEXT: 'Your account level is not enough to bid in the auction. You can continue trading by upgrading your account to level 1.',
    CANCEL: 'Cancel',
    LEVELUP: 'Level Up',
  },
  BID_APPROVED: {
    APPROVED: 'Your Offer Approved',
    SUCCESS: 'Your bid has been&nbsp;<span class="green-text">“successfully”</span>&nbsp;completed.',
    HISTORY: 'You can access your bids from the "Profile" tab.',
  },
  END_TEXT: 'Auction Ended',
  ARTIST: 'Artist',
  LAST_BIDS: {
    TEXT: 'Last Bids',
    BIDDER: 'Bidder',
    DATE: 'Bid Time',
    PRICE: 'Bid',
  },
  FILTER: {
    TITLE: 'Filter',
    ARTIST_PRODUCT: 'Artist/Product',
    FILTERS: 'Filters',
    CLEAR: 'Clear',
    APPLY: 'Apply',
    NO_RESULT: 'Artwork couldn\'t found.',
    PRICE: 'Price',
    KEYWORD: 'Keywords',
    PRICE_MIN: 'TL min',
    PRICE_MAX: 'TL max',
  },
  AUCTION_CATALOG_SORT_OPTIONS: {
    OPTIONS: [
      'Smart Sorting',
      'Price Low to High',
      'Price High to Low',
      'Current Price Low to High',
      'Current Price High to Low',
      'Lot Number Low to High',
      'Lot Number High to Low',
    ]
  },
  NO_LIVE_CATALOG: 'There is no live catalog.',
  NO_PAST_CATALOG: 'There is no past catalog.',
  ARTWORKS: 'Artworks',
  STATEMENT_HEADER: 'Statement: ',
  AUCTION_PRODUCTS: {
    '2023_DAILY_1': auction_products_config_en['2023_DAILY_1'],
    '2023_DAILY_2': auction_products_config_en['2023_DAILY_2'],
    '2023_DAILY_3': auction_products_config_en['2023_DAILY_3'],
    '2023_DAILY_4': auction_products_config_en['2023_DAILY_4'],
    '2023_DAILY_5': auction_products_config_en['2023_DAILY_5'],
    '2023_DAILY_6': auction_products_config_en['2023_DAILY_6'],
    '2023_DAILY_7': auction_products_config_en['2023_DAILY_7'],
    '2023_DAILY_8': auction_products_config_en['2023_DAILY_8'],
    '2023_DAILY_9': auction_products_config_en['2023_DAILY_9'],
    '2023_DAILY_10': auction_products_config_en['2023_DAILY_10'],
    '2023_DAILY_11': auction_products_config_en['2023_DAILY_11'],
    '2023_DAILY_12': auction_products_config_en['2023_DAILY_12'],
    '2023_DAILY_13': auction_products_config_en['2023_DAILY_13'],
    '2023_DAILY_14': auction_products_config_en['2023_DAILY_14'],
    '2023_DAILY_15': auction_products_config_en['2023_DAILY_15'],
    '2023_DAILY_16': auction_products_config_en['2023_DAILY_16'],
    '2023_DAILY_17': auction_products_config_en['2023_DAILY_17'],
    '2023_DAILY_18': auction_products_config_en['2023_DAILY_18'],
    '2023_DAILY_19': auction_products_config_en['2023_DAILY_19'],
    '2023_DAILY_20': auction_products_config_en['2023_DAILY_20'],
    '2023_DAILY_21': auction_products_config_en['2023_DAILY_21'],
    '2023_DAILY_22': auction_products_config_en['2023_DAILY_22'],
    '2023_DAILY_23': auction_products_config_en['2023_DAILY_23'],
    '2023_DAILY_24': auction_products_config_en['2023_DAILY_24'],
    '2023_DAILY_25': auction_products_config_en['2023_DAILY_25'],
    '2023_DAILY_26': auction_products_config_en['2023_DAILY_26'],
    '2023_DAILY_27': auction_products_config_en['2023_DAILY_27'],
    '2023_DAILY_28': auction_products_config_en['2023_DAILY_28'],
    '2023_DAILY_29': auction_products_config_en['2023_DAILY_29'],
    '2023_DAILY_30': auction_products_config_en['2023_DAILY_30'],
    '2023_DAILY_31': auction_products_config_en['2023_DAILY_31'],
    '2023_DAILY_32': auction_products_config_en['2023_DAILY_32'],
    '2023_DAILY_33': auction_products_config_en['2023_DAILY_33'],
    '2023_DAILY_34': auction_products_config_en['2023_DAILY_34'],
    '2023_DAILY_35': auction_products_config_en['2023_DAILY_35'],
    '2023_DAILY_36': auction_products_config_en['2023_DAILY_36'],
    '2023_DAILY_37': auction_products_config_en['2023_DAILY_37'],
    '2023_DAILY_38': auction_products_config_en['2023_DAILY_38'],
    '2023_DAILY_39': auction_products_config_en['2023_DAILY_39'],
    '2023_DAILY_40': auction_products_config_en['2023_DAILY_40'],
    '2023_DAILY_41': auction_products_config_en['2023_DAILY_41'],
    '2023_DAILY_42': auction_products_config_en['2023_DAILY_42'],
    '2023_DAILY_43': auction_products_config_en['2023_DAILY_43'],
    '2023_DAILY_44': auction_products_config_en['2023_DAILY_6'],
    '2023_DAILY_45': auction_products_config_en['2023_DAILY_10'],
    '2023_DAILY_46': auction_products_config_en['2023_DAILY_46'],
    '2023_DAILY_47': auction_products_config_en['2023_DAILY_3'],
    '2023_DAILY_48': auction_products_config_en['2023_DAILY_48'],
    '2023_DAILY_49': auction_products_config_en['2023_DAILY_17'],
    '2023_DAILY_50': auction_products_config_en['2023_DAILY_18'],
    '2023_DAILY_51': auction_products_config_en['2023_DAILY_1'],
    '2023_DAILY_52': auction_products_config_en['2023_DAILY_52'],
    '2023_DAILY_53': auction_products_config_en['2023_DAILY_12'],
    '2023_DAILY_54': auction_products_config_en['2023_DAILY_14'],
    '2023_DAILY_55': auction_products_config_en['2023_DAILY_55'],
    '2023_DAILY_56': auction_products_config_en['2023_DAILY_56'],
    '2023_DAILY_57': auction_products_config_en['2023_DAILY_5'],
    '2023_DAILY_58': auction_products_config_en['2023_DAILY_15'],
    '2023_DAILY_59': auction_products_config_en['2023_DAILY_27'],
    '2023_DAILY_60': auction_products_config_en['2023_DAILY_19'],
    '2023_DAILY_61': auction_products_config_en['2023_DAILY_13'],
    '2023_DAILY_62': auction_products_config_en['2023_DAILY_32'],
    '2023_DAILY_63': auction_products_config_en['2023_DAILY_18'],
    '2023_DAILY_64': auction_products_config_en['2023_DAILY_16'],
    '2023_DAILY_65': auction_products_config_en['2023_DAILY_33'],
    '2023_DAILY_66': auction_products_config_en['2023_DAILY_21'],
    '2023_DAILY_67': auction_products_config_en['2023_DAILY_25'],
    '2023_DAILY_68': auction_products_config_en['2023_DAILY_68'],
    '2023_DAILY_69': auction_products_config_en['2023_DAILY_69'],
    '2023_DAILY_70': auction_products_config_en['2023_DAILY_70'],
    '2023_DAILY_71': auction_products_config_en['2023_DAILY_26'],
    '2023_DAILY_72': auction_products_config_en['2023_DAILY_29'],
    '2023_DAILY_73': auction_products_config_en['2023_DAILY_73'],
    '2023_DAILY_74': auction_products_config_en['2023_DAILY_74'],
    '2023_DAILY_75': auction_products_config_en['2023_DAILY_75'],
    '2024_DAILY_1': auction_products_config_en['2023_DAILY_19'],
    '2024_DAILY_2': auction_products_config_en['2023_DAILY_13'],
    '2024_DAILY_3': auction_products_config_en['2023_DAILY_32'],
    '2024_DAILY_4': auction_products_config_en['2023_DAILY_18'],
    '2024_DAILY_5': auction_products_config_en['2023_DAILY_16'],
    '2024_DAILY_6': auction_products_config_en['2023_DAILY_33'],
    '2024_DAILY_7': auction_products_config_en['2023_DAILY_21'],
    '2024_DAILY_8': auction_products_config_en['2023_DAILY_25'],
    '2024_DAILY_9': auction_products_config_en['2023_DAILY_68'],
    '2024_DAILY_10': auction_products_config_en['2023_DAILY_69'],
    '2024_DAILY_11': auction_products_config_en['2023_DAILY_70'],
    '2024_DAILY_12': auction_products_config_en['2023_DAILY_26'],
    '2024_DAILY_13': auction_products_config_en['2023_DAILY_29'],
    '2024_DAILY_14': auction_products_config_en['2023_DAILY_73'],
    '2024_DAILY_15': auction_products_config_en['2023_DAILY_74'],
    '2024_DAILY_16': auction_products_config_en['2023_DAILY_75'],
    '2024_DAILY_17': auction_products_config_en['2024_DAILY_17'],
    '2024_DAILY_18': auction_products_config_en['2024_DAILY_18'],
    '2024_DAILY_19': auction_products_config_en['2023_DAILY_27'],
    '2024_DAILY_20': auction_products_config_en['2023_DAILY_37'],
    '2024_DAILY_21': auction_products_config_en['2024_DAILY_21'],
    '2024_DAILY_22': auction_products_config_en['2024_DAILY_22'],
    '2024_DAILY_23': auction_products_config_en['2024_DAILY_23'],
    '2024_DAILY_24': auction_products_config_en['2023_DAILY_7'],
    '2024_DAILY_25': auction_products_config_en['2023_DAILY_32'],
    '2024_DAILY_26': auction_products_config_en['2023_DAILY_6'],
    '2024_DAILY_27': auction_products_config_en['2023_DAILY_10'],
    '2024_DAILY_28': auction_products_config_en['2023_DAILY_1'],
    '2024_DAILY_29': auction_products_config_en['2024_DAILY_29'],
    '2024_DAILY_30': auction_products_config_en['2023_DAILY_12'],
    '2024_DAILY_31': auction_products_config_en['2024_DAILY_31'],
    '2024_DAILY_32': auction_products_config_en['2023_DAILY_5'],
    '2024_DAILY_33': auction_products_config_en['2024_DAILY_33'],
    '2024_DAILY_34': auction_products_config_en['2023_DAILY_3'],
    '2024_DAILY_35': auction_products_config_en['2024_DAILY_35'],
    '2024_DAILY_36': auction_products_config_en['2023_DAILY_18'],
    '2024_DAILY_37': auction_products_config_en['2024_DAILY_37'],
    '2024_DAILY_38': auction_products_config_en['2023_DAILY_15'],
    '2024_DAILY_39': auction_products_config_en['2023_DAILY_27'],
    '2024_DAILY_40': auction_products_config_en['2024_DAILY_40'],
    '2024_DAILY_41': auction_products_config_en['2024_DAILY_41'],
    '2024_DAILY_42': auction_products_config_en['2024_DAILY_42'],
    '2024_DAILY_43': auction_products_config_en['2024_DAILY_43'],
    '2024_DAILY_44': auction_products_config_en['2023_DAILY_26'],
    '2024_DAILY_45': auction_products_config_en['2023_DAILY_25'],
    '2024_DAILY_46': auction_products_config_en['2023_DAILY_33'],
    '2024_DAILY_47': auction_products_config_en['2024_DAILY_18'],
    '2024_DAILY_48': auction_products_config_en['2023_DAILY_42'],
    '2024_DAILY_49': auction_products_config_en['2024_DAILY_49'],
    '2024_DAILY_50': auction_products_config_en['2023_DAILY_2'],
    '2024_DAILY_51': auction_products_config_en['2024_DAILY_51'],
    '2024_DAILY_52': auction_products_config_en['2023_DAILY_5'],
    '2024_DAILY_53': auction_products_config_en['2024_DAILY_23'],
    '2024_DAILY_54': auction_products_config_en['2023_DAILY_12'],
    '2024_DAILY_55': auction_products_config_en['2024_DAILY_55'],
    '2024_DAILY_56': auction_products_config_en['2024_DAILY_21'],
    '2024_DAILY_57': auction_products_config_en['2024_DAILY_57'],
    '2024_DAILY_58': auction_products_config_en['2024_DAILY_58'],
    '2024_DAILY_59': auction_products_config_en['2024_DAILY_59'],
    '2024_DAILY_60': auction_products_config_en['2024_DAILY_60'],
    '2024_DAILY_61': auction_products_config_en['2024_DAILY_61'],
    '2024_DAILY_62': auction_products_config_en['2024_DAILY_62'],
    '2024_DAILY_63': auction_products_config_en['2024_DAILY_63'],
    '2024_DAILY_64': auction_products_config_en['2024_DAILY_64'],
    '2024_DAILY_65': auction_products_config_en['2024_DAILY_65'],
    '2024_DAILY_66': auction_products_config_en['2023_DAILY_24'],
    '2024_DAILY_67': auction_products_config_en['2024_DAILY_67'],
    '2024_DAILY_68': auction_products_config_en['2024_DAILY_68'],
    '2024_DAILY_69': auction_products_config_en['2023_DAILY_15'],
    '2024_DAILY_70': auction_products_config_en['2024_DAILY_70'],
    '2024_DAILY_71': auction_products_config_en['2024_DAILY_71'],
    '2024_DAILY_72': auction_products_config_en['2023_DAILY_18'],
    '2024_DAILY_73': auction_products_config_en['2023_DAILY_3'],
    '2024_DAILY_74': auction_products_config_en['2023_DAILY_28'],
    '2024_DAILY_75': auction_products_config_en['2024_DAILY_75'],
    '2024_DAILY_76': auction_products_config_en['2023_DAILY_13'],
    '2024_DAILY_77': auction_products_config_en['2023_DAILY_26'],
    '2024_DAILY_78': auction_products_config_en['2023_DAILY_29'],
    '2024_DAILY_79': auction_products_config_en['2024_DAILY_79'],
    '2024_DAILY_80': auction_products_config_en['2023_DAILY_42'],
    '2024_DAILY_81': auction_products_config_en['2024_DAILY_81'],
    '2024_DAILY_82': auction_products_config_en['2023_DAILY_12'],
    '2024_DAILY_83': auction_products_config_en['2024_DAILY_83'],
    '2024_DAILY_84': auction_products_config_en['2023_DAILY_27'],
    '2024_DAILY_85': auction_products_config_en['2023_DAILY_33'],
    '2024_DAILY_86': auction_products_config_en['2024_DAILY_86'],
    '2024_DAILY_87': auction_products_config_en['2023_DAILY_25'],
    '2024_DAILY_88': auction_products_config_en['2024_DAILY_43'],
    '2024_DAILY_89': auction_products_config_en['2024_DAILY_89'],
    '2024_DAILY_90': auction_products_config_en['2024_DAILY_90'],
    '2024_DAILY_91': auction_products_config_en['2024_DAILY_91'],
    '2024_DAILY_92': auction_products_config_en['2024_DAILY_92'],
    '2024_DAILY_93': auction_products_config_en['2024_DAILY_93'],
    '2024_DAILY_94': auction_products_config_en['2023_DAILY_2'],
    '2024_DAILY_95': auction_products_config_en['2024_DAILY_95'],
    '2024_DAILY_96': auction_products_config_en['2024_DAILY_96'],
    '2024_DAILY_97': auction_products_config_en['2024_DAILY_97'],
    '2024_DAILY_98': auction_products_config_en['2024_DAILY_98'],
    '2024_DAILY_99': auction_products_config_en['2024_DAILY_99'],
    '2024_DAILY_100': auction_products_config_en['2024_DAILY_100'],
    '2024_DAILY_101': auction_products_config_en['2024_DAILY_71'],
    '2024_DAILY_102': auction_products_config_en['2023_DAILY_24'],
    '2024_DAILY_103': auction_products_config_en['2024_DAILY_103'],
    '2024_DAILY_104': auction_products_config_en['2023_DAILY_3'],
    '2024_DAILY_105': auction_products_config_en['2023_DAILY_26'],
    '2024_DAILY_106': auction_products_config_en['2024_DAILY_106'],
    '2024_DAILY_107': auction_products_config_en['2024_DAILY_107'],
    '2024_DAILY_108': auction_products_config_en['2024_DAILY_108'],
    '2024_DAILY_109': auction_products_config_en['2024_DAILY_109'],
    '2024_DAILY_110': auction_products_config_en['2024_DAILY_110'],
    '2024_DAILY_111': auction_products_config_en['2024_DAILY_111'],
    '2024_DAILY_112': auction_products_config_en['2024_DAILY_112'],
    '2024_DAILY_113': auction_products_config_en['2024_DAILY_113'],
    '2024_DAILY_114': auction_products_config_en['2024_DAILY_68'],
    '2024_DAILY_115': auction_products_config_en['2023_DAILY_12'],
    '2024_DAILY_116': auction_products_config_en['2024_DAILY_81'],
    '2024_DAILY_117': auction_products_config_en['2024_DAILY_117'],
    '2024_DAILY_118': auction_products_config_en['2024_DAILY_79'],
    '2024_DAILY_119': auction_products_config_en['2023_DAILY_26'],
    '2024_DAILY_120': auction_products_config_en['2024_DAILY_120'],
    '2024_DAILY_121': auction_products_config_en['2024_DAILY_121'],
    '2024_DAILY_122': auction_products_config_en['2023_DAILY_18'],
    '2024_DAILY_123': auction_products_config_en['2024_DAILY_123'],
    '2024_DAILY_124': auction_products_config_en['2023_DAILY_29'],
    '2024_DAILY_125': auction_products_config_en['2024_DAILY_125'],
    '2024_DAILY_126': auction_products_config_en['2024_DAILY_83'],
    '2024_DAILY_127': auction_products_config_en['2023_DAILY_13'],
    '2024_DAILY_128': auction_products_config_en['2024_DAILY_128'],
    '2024_DAILY_129': auction_products_config_en['2024_DAILY_129'],
    '2024_DAILY_131': auction_products_config_en['2024_DAILY_131'],
    '2024_DAILY_132': auction_products_config_en['2024_DAILY_132'],
    '2024_DAILY_133': auction_products_config_en['2024_DAILY_86'],
    '2024_DAILY_134': auction_products_config_en['2024_DAILY_134'],
    '2024_DAILY_135': auction_products_config_en['2024_DAILY_135'],
    '2024_DAILY_136': auction_products_config_en['2024_DAILY_109'],
    '2024_DAILY_137': auction_products_config_en['2023_DAILY_29'],
    '2024_DAILY_138': auction_products_config_en['2024_DAILY_138'],
    '2024_DAILY_139': auction_products_config_en['2024_DAILY_139'],
    '2024_DAILY_140': auction_products_config_en['2023_DAILY_15'],
    '2024_DAILY_141': auction_products_config_en['2024_DAILY_110'],
    '2024_DAILY_142': auction_products_config_en['2024_DAILY_142'],
    '2024_DAILY_143': auction_products_config_en['2023_DAILY_32'],
    '2024_DAILY_144': auction_products_config_en['2023_DAILY_40'],
    '2024_DAILY_145': auction_products_config_en['2024_DAILY_93'],
    '2024_DAILY_146': auction_products_config_en['2023_DAILY_2'],
    '2024_DAILY_147': auction_products_config_en['2023_DAILY_26'],
    '2024_DAILY_148': auction_products_config_en['2023_DAILY_33'],
    '2024_DAILY_149': auction_products_config_en['2023_DAILY_7'],
    '2024_DAILY_150': auction_products_config_en['2024_DAILY_83'],
    '2024_DAILY_151': auction_products_config_en['2023_DAILY_24'],
    '2024_DAILY_152': auction_products_config_en['2023_DAILY_12'],
    '2024_DAILY_153': auction_products_config_en['2024_DAILY_79'],
    '2024_DAILY_154': auction_products_config_en['2024_DAILY_109'],
    '2024_DAILY_155': auction_products_config_en['2024_DAILY_68'],
    '2024_DAILY_156': auction_products_config_en['2024_DAILY_70'],
    '2024_DAILY_157': auction_products_config_en['2024_DAILY_86'],
    '2024_DAILY_158': auction_products_config_en['2023_DAILY_13'],
    '2024_DAILY_159': auction_products_config_en['2023_DAILY_15'],
    '2024_DAILY_160': auction_products_config_en['2024_DAILY_139'],
    '2024_DAILY_161': auction_products_config_en['2024_DAILY_110'],

    ...test_auction_config_en.AUCTION_PRODUCTS,
  },
  AUCTION_CATALOGS: {
    '2023_DAILY': {
      NAME: 'Daily Auction',
      TITLE: 'Each Day New Artwork',
      TEXT: 'Daily Auctions is an Artiox Auction system that offers selected artworks for auction between specified dates.',
      IMAGE_THUMBNAIL: './assets/auction_products/2023_DAILY_1/0.d.jpg',
    },
    '2024_DAILY': {
      NAME: 'Daily Auction',
      TITLE: 'Each Day New Artwork',
      TEXT: 'Daily Auctions is an Artiox Auction system that offers selected artworks for auction between specified dates.',
      IMAGE_THUMBNAIL: './assets/auction_products/2023_DAILY_1/0.d.jpg',
    },

    ...test_auction_config_en.AUCTION_CATALOGS,
  },
  AUCTION_ARTISTS: {
    ALI_ATMACA: {
      TITLE: 'Ali Atmaca (1947 - )',
      TEXT: 'Ali Atmaca, born in Elbistan, Kahramanmaraş in 1947, completed his primary and middle school education in Kadirli and continued his high school studies in Adana. Due to his interest in art, he participated in studio studies as a visiting student at the Istanbul Faculty of Fine Arts between 1971 and 1972. Later, in 1982, he continued his education at the Paris School of Fine Arts, and from 1986 to 1988, he continued his painting studies in Rome upon the invitation of a collector.\n' +
        '\n' +
        'Ali Atmaca\'s works are based on a colorful artistic approach, and he portrays figures in a balanced manner with abstract form formations. His canvas is dominated by round lines and vibrant colors. The artist\'s works are not only found in collections in Turkey but also in many countries such as France, Italy, Japan, Canada, and the United States. In addition, his works are exhibited in prestigious institutions such as the Nice Museum of Modern Art and the Antibes Picasso Museum in France.\n'
    },
    MUSTAFA_AYAZ: {
      TITLE: 'Mustafa Ayaz (1938 - )',
      TEXT: 'Prof. Dr. Mustafa Ayaz, who was born in Trabzon, is known as a painter, academic, and professor of fine arts. He began his educational journey in the Painting Department of Gazi Education Institute and successfully completed this program in 1963. In 1987, Mustafa Ayaz obtained the title of professor and was appointed to the Faculty of Fine Arts at Bilkent University in the same year. However, he left this position in 1988 and continues to live and work in Ankara.\n' +
        '\n' +
        'The artist has participated in numerous solo exhibitions and also exhibited his work in group shows and biennials in many countries. He expresses the feeling of "thinking, loving, and painting by drawing and coloring" while creating his art. He draws inspiration from daily life and aims to capture and relive these events, emotions, and thoughts through his paintings. Throughout his art career, Mustafa Ayaz has received numerous awards and recognition.\n'
    },
    ADEM_GENC: {
      TITLE: 'Adem Genç (1944 - )',
      TEXT: 'Adem Genç was born in 1944 in the Ardeşen district of Rize. After successfully completing the Painting Department program at Gazi Education Institute, he began his career as an art teacher in Adana and Rize. In 1969, he was awarded a government scholarship and sent to England.\n' +
        '\n' +
        'In England, he received education in drawing and printmaking at Bournemouth College of Art. Later, in 1971, he was admitted to the "Postgraduate/Advanced Painting" program at St. Martin\'s School of Art in London. Additionally, during the 1991-92 academic year, he completed post-doctoral studies as a Fulbright visiting faculty member at the University of California. The artist has held numerous solo exhibitions both in Turkey and abroad and has participated in international art events.\n'
    },
    ALEV_OZAS: {
      TITLE: 'Alev Özas (1977 - )',
      TEXT: 'Painter Alev Özas was born in Istanbul in 1977. After completing high school, she joined the Theater Department of the Private Istanbul Academy of Fine Arts. During this time, in addition to her theater training, she also created charcoal sketches. In 1998, she transitioned to the field of painting and was admitted to the Painting Department of Marmara University.\n' +
        '\n' +
        'Starting in 2005, she began to embrace an abstract expressive figurative style as her artistic approach. In the same year, she started working as the head assistant in the private studio of Prof. Dr. Devrim ERBİL, a position she held for 8 years. The artist used her experienced expressive style to depict the vast and complex structure of Istanbul. A distinctive feature of Alev Özas\'s paintings is the prominent use of color.\n'
    },
    FIKRET_MUALLA: {
      TITLE: 'Fikret Mualla (1903 - 1967)',
      TEXT: 'Painter Fikret Mualla was born in Istanbul in 1903. He began his education at Saint Joseph French School and then studied at Galatasaray High School for a while. He was sent to Germany for engineering education and during this time, he traveled around Europe, visiting museums. His talent for painting emerged during this period, and he quickly gained a strong knowledge of drawing. He created fashion drawings, engravings, and successful illustrations, and his drawings were published in German magazines.\n' +
        '\n' +
        'Living in Paris for many years, Fikret Mualla focused on depicting the details of Parisian life, such as cafes, circuses, and streets. He portrayed the people of his bohemian surroundings in his works. He was known for his ability to work quickly with the gouache technique, and he mastered oil painting, watercolor, and gouache. He did not participate in contemporary art movements and did not engage in the theoretical issues of art. Today, his works are found in private collections.\n'
    },
    AHMET_ORAN: {
      TITLE: 'Ahmet Oran (1957 - )',
      TEXT: 'Abstract painter Ahmet Oran, born in Çanakkale, entered the Istanbul State Academy of Fine Arts in 1977 and graduated in 1985. During the same period, he also received education at the Vienna University of Applied Arts. Oran\'s artwork has been featured in a book titled "Art in Austria" published in Austria, and in 1999, he organized a major solo exhibition at the Linz Museum of Modern Art. The artist continues his work in both Istanbul and Vienna.\n' +
        '\n' +
        'In Oran\'s works, spatial depth and layers of color take center stage. He often uses large canvases to create abstract paintings with geometric or lyrical lines. Inspired by Istanbul\'s history, movement, and contemporary life, Oran creates original works with color and graphic lines. Participating in international exhibitions since 1980, Oran is one of Turkey\'s prominent painters.\n'
    },
    ILYAS_ERGIN_INAN: {
      TITLE: 'İlyas Ergin İnan (1943 - )',
      TEXT: 'Ergin İnan, a renowned figure in contemporary Turkish art, is a painter who has gained international recognition with his abstract and conceptual works. Born in Malatya, İnan graduated from the Istanbul State Academy of Applied Fine Arts and continued his art career intensively.\n' +
        '\n' +
        'İnan reflects Sufi philosophy in his works and delves deeply into spiritual and metaphysical elements in his paintings. In his works, which bear traces of traditional Turkish Islamic art, he exhibits an original approach by using old book pages. With an academic background, the artist worked as a research assistant and a faculty member in Berlin for an extended period. İnan has won numerous national and international awards. Since his retirement in 2000, Ergin İnan has continued his artistic endeavors in his studio in Istanbul.\n'
    },
    BURHAN_DOGANCAY: {
      TITLE: 'Burhan Doğançay (1929 - 2013)',
      TEXT: 'Turkish painter Burhan Doğançay was born in Istanbul in 1929. He began his art education under the guidance of his father and the painter Arif Kaptan. After completing his education in law and economics, he continued his art studies in Paris. He became the first Turkish painter to be accepted into the Guggenheim Museum\'s collection after moving to New York. He became known for his "Walls" series, where he worked on the theme of walls.\n' +
        '\n' +
        'In Doğançay\'s works, it is evident that he draws inspiration from life and uses collage and fumage techniques to enhance this influence. Additionally, in his works, he expressed his artistic messages through various materials such as papers, posters, strings, old broken signs, doorknobs, wires, locks, and painted boards. These materials played a significant role in reflecting various aspects and textures of life in Doğançay\'s works. The artist passed away in Istanbul in 2013 and was buried in Bodrum.\n'
    },
    HUSEYIN_FEYZULLAH: {
      TITLE: 'Hüseyin Feyzullah (1990 - )',
      TEXT: 'Hüseyin Feyzullah was born in Tashkent in 1990. He began his art education in 2009 at Hacettepe University\'s Faculty of Fine Arts, Department of Painting. He graduated from the same university in 2013. Later, in 2017, he completed his master\'s degree in the Department of Painting at Gazi University\'s Faculty of Fine Arts. He is currently continuing his painting studies at the Firdevsi Feyzullah Atelier. Hüseyin Feyzullah has held four solo exhibitions and participated in approximately 50 group projects.'
    },
    JAVAD_GHAFFARI: {
      TITLE: 'Javad Ghaffari ( - )',
      TEXT: 'Iranian painter Javad Ghaffari moved to Turkey just before the year 2020 and settled there. He creates paintings using a technique known as the "şorre" in Farsi, also called the syringe technique. He developed this technique himself. In his paintings, he uses linear and striking colors to reflect Neo-Impressionist influences. Ghaffari is striving to take his work in Turkey to a different level, and for this reason, he decided to move from Van to Antalya to continue his artistic endeavors there.'
    },
    MAHIR_GUVEN: {
      TITLE: 'Mahir Güven (1958 - )',
      TEXT: 'Mahir Güven, born in Istanbul in 1958, received education at the Istanbul State Academy of Fine Arts, Department of Painting, between 1976 and 1981. In his works, which include Renaissance figures, he adopts a critical approach and prioritizes societal messages, allowing for a deep examination of the transition from traditional Turkish culture to Western culture. The artist is currently living and continuing his artistic endeavors in Istanbul.'
    },
    TANJU_DEMIRCI: {
      TITLE: 'Tanju Demirci (1961 - )',
      TEXT: 'Tanju Demirci was born in Keşan, a district of Edirne, in 1961. After graduating from the Marmara University Faculty of Fine Arts, Department of Painting, he won awards in many national and international competitions. In 1984, he conducted research in the cities of Rome, Florence, Assisi, and Padua, and in 1988, he did research in Cologne, Germany. In 1988, he received awards at the Esbank Yunus Emre 5th Painting Competition, and in 1990, he received success awards at the 11th Contemporary Artists of Istanbul Exhibition. In 1991, he received an honorable mention award in the 52nd State Painting and Sculpture Exhibition Painting.'
    },
    MUSLUM_TEKE: {
      TITLE: 'Müslüm Teke (1974 - )',
      TEXT: 'Born in Gaziantep in 1974, Müslüm Teke graduated from the Yüzüncü Yıl University\'s Department of Painting Teaching. Since 2003, he has participated in numerous domestic and international exhibitions, workshops, and biennials, receiving awards. The artist Müslüm Teke develops a unique expressive technique in his portraits using intense colors and textures. His special emphasis on eye details enhances the emotional depth of his works.'
    },
    ADNAN_TURANI: {
      TITLE: 'Adnan Turani (1925 - 2016) ',
      TEXT: 'Adnan Turani is one of the prominent representatives of abstract art. Born in Istanbul in 1925, Turani received his art education in Germany and later returned to Turkey, where he worked as an instructor at several universities. He has made significant contributions in the fields of art history, Turkish painting, and art philosophy. Turani possesses an abstract painting approach and continued to maintain an abstract perspective, despite incorporating figurative elements and nature in later periods. He passed away in 2016.'
    },
    SAHIN_PAKSOY: {
      TITLE: 'Şahin Paksoy (1952 - )',
      TEXT: 'Şahin Paksoy was born in 1952 in the Ceyhan district of Adana, Turkey. He completed his primary, middle, and high school education in Adana. In 1973, he began his ceramic education at the Istanbul State Academy of Fine Arts and opened his first solo exhibition in Istanbul in 1980 after graduating. Inspired by traditional Turkish arts, Paksoy developed an original style in contemporary art with his figurative paintings and small-sized ceramic sculptures. Elements such as miniatures, church icons, and carpet patterns served as sources of inspiration for his work.\n' +
        '\n' +
        'Drawing inspiration from everyday life, the artist focused on human figures and created his unique language, reflecting the lifestyle and values of Anatolia in his artwork. Throughout his artistic career, Paksoy participated in numerous exhibitions and in recent years, he has turned to collecting art.\n'
    },
    KENAN_ISIK: {
      TITLE: 'Kenan Işık (1981 - )',
      TEXT: 'Kenan Işık was born in the Van province in 1981 and he received his undergraduate education in the Department of Painting at Erzurum Atatürk University Faculty of Fine Arts. Later, he settled in Istanbul and became known for reflecting the dynamism and variability of the city in his artwork. Bringing together the beauty and complexity of the city in his works, the artist held his first solo exhibition in 2007 and has organized numerous exhibitions both in Turkey and abroad.'
    },
    SELIM_TURAN: {
      TITLE: 'Selim Turan (1915 - 1994)',
      TEXT: 'Born in 1915, Selim Turan received his painting education at the Istanbul Academy of Fine Arts, where he studied under important artists like Nazmi Ziya Güran, Feyhaman Duran, Leopold Levy, and Zeki Kocamemi. After graduating from the Academy in 1938, he continued his career as a painting teacher and focused on miniature works. In 1947, he went to Paris with a scholarship from the French government and achieved significant works there.\n' +
        '\n' +
        'Turan turned to abstract art and produced original works inspired by Eastern art. He became known for his mobiles and kinetic sculptures, and in 1993, he placed a large mobile sculpture in Ankara\'s Kurtuluş Park. A prominent artist whose path intersected with Nuri İyem, Abidin Dino, Turgut Atalay, Ferruh, Başağa, Agop Arad, Avni Arbaş, Mümtaz Yener, Fethi Karakaş, and Haşmet Akal, Selim Turan was among the founders of the Yeniler Group based on the concept of "Social Realism."\n' +
        '\n' +
        'Turan had many works in France, and he passed away in Paris in 1994. His body was brought to Turkey and he was buried in Istanbul.\n'
    },
    MUMIN_CANDAS: {
      TITLE: 'Mümin Candaş (1975 - )',
      TEXT: 'Mümin Candaş was born in Trabzon in 1971 and received his education in painting teaching at Karadeniz Technical University. After completing his master\'s degree at Istanbul Technical University, he worked as a visual arts teacher at Akçaabat Fine Arts High School. Candaş, whose works focus on the inner and physical depths of humans and the relationship between humans and nature, has held ten solo exhibitions and participated in both national and international exhibitions. The artist has also showcased his art at SANKO Art Gallery, emphasizing life and human-environment relationships in his works.'
    },
    CAN_GENCA: {
      TITLE: 'Can Genca (1985 - )',
      TEXT: '1985 yılında İstanbul\'da doğan Can Genca, küçük yaşlardan itibaren resim ve tasarım alanına ilgi duymaya başladı. Kolej yıllarını sanat dolu bir atmosferde geçirdikten sonra, eğitimine İstanbul Üniversitesi Yabancı Diller Fakültesi ve Marmara Üniversitesi Güzel Sanatlar Fakültesi\'nde devam etti. Sanat tarihi üzerine yaptığı araştırmalar sırasında, London Camberwell College of Arts\'ta sanat eğitimine devam etti. Sanatçı özellikle konstrüktivizm, kübizm ve minimalizm akımlarından ilham almakta ve bu alanlarda birçok eser ortaya koymaktadır. Tablolarında renkleri ayıran kontur hatları kullanarak, renk kombinasyonları ve kontrast değerleri üzerinde titizlikle çalışmaktadır. Sanatçı, İstanbul\'da çalışmalarını sürdürmekte ve sanatına olan tutkusunu her geçen gün daha da derinleştirmektedir.\n' +
        'Born in Istanbul in 1985, the artist developed an interest in painting and design from a young age. After spending college years immersed in the world of art, the artist pursued education at Istanbul University Faculty of Foreign Languages and Marmara University Faculty of Fine Arts. During the period of researching art history, the artist continued art education at London Camberwell College of Arts. Drawing inspiration particularly from constructivism, cubism, and minimalism, the artist has created numerous works in these movements. Using contour lines that separate colors in their paintings, the artist meticulously captures color combinations and contrast values.'
    },
    GUVEN_ARAZ: {
      TITLE: 'Güven Araz (1964 - )',
      TEXT: '1964 yılında İstanbul\'da doğan Güven Araz, İstanbul Teknik Üniversitesi\'nde 3 yıl Fizik Mühendisliği okuduktan sonra Marmara Üniversitesi Güzel Sanatlar Fakültesi Heykel bölümüne giriş yaptı. 1989 yılında mezun olduktan sonra Heykel ve Desen dersleri vermeye başladı. 2012 yılından itibaren 6 yıl boyunca özel bir güzel sanatlar lisesinde resim, heykel ve akrilik boya dersleri verdi ve hâlâ özel bir Anadolu kolejinde resim dersleri vermektedir. Yıllar içinde birçok karma sergiye katılan sanatçı, şu anda tuval üzerine akrilik resimler üretmektedir.\n' +
        'Güven Araz was born in Istanbul in 1964. After studying Physics Engineering at Istanbul Technical University for 3 years, he entered the Sculpture department of Marmara University Faculty of Fine Arts. Upon graduating in 1989, he began teaching Sculpture and Drawing courses. Starting from 2012, he taught painting, sculpture, and acrylic painting at a private fine arts high school for 6 years and currently teaches painting at a private Anatolian high school. Over the years, he has participated in numerous group exhibitions and is currently creating acrylic paintings on canvas.'
    },
    ERTUGRUL_ATES: {
      TITLE: 'Ertuğrul Ateş (1954 - )',
      TEXT: 'Artist Ertuğrul Ateş was born in Adana in 1954 and graduated from Gazi Education Institute Painting Department in 1976. After studying in England, he opened his first exhibition in Istanbul in 1980. He settled in America in 1987 and continued his studies until 2006. It was included in the "Emerging Artists" exhibition by the famous Terry Dintanfass gallery and the Chicago Museum of Modern Art. In 2004, he wrote and directed the dance show Hürrem Sultan and was invited to the 2006 Beijing Olympics Biennial. The artist, who organizes many exhibitions and events at home and abroad, met art lovers with his works in cities such as New York, Boston, Los Angeles, Dallas, Chicago, Beijing, Shanghai, Taipei, Istanbul, Ankara, Izmir and Adana. Ertuğrul Ateş, who has the title of "Ribbon Painter", advocates the creation of an imaginary and universal world rather than realism, with the belief that seeking answers to big questions is the starting point of art.'
    },
    AHMET_GUNESTEKIN: {
      TITLE: 'Ahmet Güneştekin (1966 - )',
      TEXT: 'Ahmet Güneştekin was born in Batman in 1966. In 1981, he was accepted to Mimar Sinan Fine Arts University but chose to reject academic education and left school. In 1997, he established his first workshop in Beyoğlu, Istanbul. Around the same time, he began working with documentary filmmaker and journalist Coşkun Aral, traveling across the Anatolian region.\n' +
        '\n' +
        'Güneştekin initiated his own documentary series called "In the Footsteps of the Sun." Within this documentary framework, he organized dual exhibitions with significant Turkish contemporary artists in the historical locations of the Anatolian cities he visited, conducting painting workshops with thousands of children. He held personal exhibitions and participated in international contemporary art fairs in various cities, including New York, Miami, Budapest, Madrid, Barcelona, Monaco, Hong Kong, and Venice.\n'
    },
    MEMDUH_KUZAY: {
      TITLE: 'Memduh Kuzay (1957 - )',
      TEXT: 'Memduh Kuzay, born in 1957 in Kayseri, after graduating from Mimar Sinan University, went to the United States and served as a painter and art consultant at Galeri Matisse. After working with Glimmer Art Gallery in Chicago, the artist became a faculty member at Mersin University Faculty of Fine Arts in 1994, undertaking roles such as department chair and deputy dean. They left the university in 2000. The artist\'s works encompass large-scale compositions integrating fabric and paper collages with vibrant color elements and figurative drawings. In 2001, the artist received the Best Artist award at the Outdoor Show in Chicago.\n'
    },
    BEHZAT_HASAN_FEYZULLAH: {
      TITLE: 'Behzat Hasan Feyzullah (1990 - )',
      TEXT: 'Behzat Feyzullah, born in Taşkent in 1990, entered the Graphic Department of the Faculty of Fine Arts at Hacettepe University in 2009 and graduated from the same department in 2013. Among the artist\'s solo exhibitions, there is the 2019 exhibition at TürkerArt Art Gallery in Istanbul, the \'Color of Loss\' exhibition at Galeri Soyut\'s B Salonu in Ankara in 2018, and an exhibition held at Yağmur Sanat Galerisi in Ankara in 2011. The artist is influenced by the Cubism and Surrealism movements. He continues his works themed "Resurrection of Troy," influenced by mythological descriptions in a surrealist manner.'
    },
    IBRAHIM_TAYFUR: {
      TITLE: 'İbrahim Tayfur (1971 - )',
      TEXT: 'İbrahim Tayfur was born in 1971 in Adana. The artist, who graduated from the Department of Fine Arts, Painting at Hacettepe University, held his first solo exhibition at Artıköy Sanat Evi in 2009. He has organized a total of eight solo exhibitions in various art galleries in Istanbul to date. Additionally, he has participated in approximately fifty group exhibitions. The artist\'s works are featured in private collections both domestically and internationally. Engaging in both classical and abstract works, Tayfur predominantly focuses on Istanbul in his pieces. The asymmetrical, non-holistic linear distributions and rhythmic arrangements in his works are noteworthy.'
    },
    ALAADDIN_HIZ: {
      TITLE: 'Alaaddin Hız (1989 - )',
      TEXT: 'Alaaddin Hız was born in Akşehir in 1989 and graduated from the Department of Fine Arts at Akdeniz University in 2018 with a third-place ranking. He is currently pursuing a master\'s degree at the Department of Art and Design at Akdeniz University Graduate School. Throughout his art career, he has participated in national and international exhibitions, won awards, and served on exhibition organizing committees. Throughout his art career, he has participated in national and international exhibitions, won awards, and served on exhibition organizing committees.Hız\'s works are displayed in numerous institutions and museums. He serves as a student representative at Akdeniz University and maintains his artistic endeavors in his personal studio in Antalya. His productions include landscape paintings, typography works, and abstract layered works.',
    },
    FARUK_CIMOK: {
      TITLE: 'Faruk Cimok (1956 - )',
      TEXT: 'Faruk Cimok was born in 1956 in Reyhanlı, Hatay. He graduated from the Department of Painting at the State Academy of Fine Arts, now known as Mimar Sinan Fine Arts University, where he studied in the workshop of Devrim Erbil, in 1979. He held his first solo exhibition in 1980. In his early years, he portrayed cotton workers in Çukurova in a figurative manner, later shifting to depict old scenes of Istanbul with vibrant colors. His works often captured the social life of neighborhoods like Beyoğlu, Nişantaşı, and Kumkapı from the 1950s in a nostalgic style. He earned the nickname "Kuşçu" (Birdman) due to frequently featuring pigeon figures in his art. Faruk Cimok has organized 35 solo exhibitions throughout his artistic career, winning various awards in competitions such as the Turkish Culture Foundation, DYO, and Kartal Municipality Culture and Art Festival. Currently, he continues his artistic endeavors at Mimar Sinan Fine Arts University and his personal studio in Suadiye. Additionally, he has specialized in the restoration of paintings.',
    },
    FEHIM_GULER: {
      TITLE: 'Fehim Güler (1964 - )',
      TEXT: 'Fehim Güler, born in 1964, graduated from the Department of Painting in the Faculty of Fine Arts at Marmara University in 1987. In 1991, he conducted a study titled "Creativity and Functions" in the Graphic Design department at Hacettepe University\'s Faculty of Fine Arts. Between 1994 and 1998, Güler pursued thesis research in the Visual and Environmental Arts Department at Istanbul Technical University\'s Institute of Social Sciences. In 2009, the artist founded the Beyoğlu Plastic Arts Association and undertook the role of founding president for two terms. From 2013 to 2015, he completed a second master\'s thesis on "The Impact of Expressionism on Turkish Painting" in the Art Theory and Criticism Master\'s Program at FMV Işık University\'s Faculty of Fine Arts (covering the years 1915-2015). In 2023, Güler established Ziba Art Gallery and assumed the role of art consultant. Currently, he serves as a board member and general secretary of the International Plastic Arts Association (UPSD).',
    },
    ZAFER_DILEKCI: {
      TITLE: "Zafer Dilekçi (1978 - )",
      TEXT: 'The artist was born in 1978 in Ankara and educated at Erciyes University\'s Department of Painting and Kocaeli University. Worked as a visual arts teacher in Kocaeli and Ankara, and has been serving in the same role in Eskişehir since 2008. In 2002, the artist received an honorable mention for the work "Backgammon" in a painting competition organized by Erciyes University and has works in private collections in various cities. Since 2000, the artist has also been interested in cartoon art, with works published in magazines like Gırgır and Leman, and won an award in the Nehar Tüblek Cartoon Contest. The artist\'s cartoons are included in over 20 cartoon catalogs in China, Italy, Azerbaijan, and Turkey. Since 1998, the artist has been creating mosaic paintings.',
    },
    BIGE_AKSOY: {
      TITLE: "Bige Aksoy (1976 - )",
      TEXT: 'Bige Aksoy, born in Istanbul in 1976 and currently residing in Bodrum, skillfully blends art and design in her works. She emphasizes the use of paper not just as a material, but as a central element that enhances meaning in her paintings and employs an original technique in her designs to create a cohesive whole. Her art focuses on capturing the essence of pre-formed emotions, images, and abstract thoughts just before they are shaped by the creator\'s known experiences and feelings, aiming for a profound connection with the viewer.\n',
    },
    SERAP_CAN: {
      TITLE: "Serap Can (1981 - )",
      TEXT: "Serap Can, who graduated from the Painting Department of the Faculty of Fine Arts at Sakarya University in 2007, received Pedagogical Formation education at the Faculty of Education of Anadolu University in 2010. She was awarded the Young Artist of the Year Award in 2015 and has participated in many exhibitions both domestically and internationally. Currently, she continues her life and art practices in Istanbul and teaches Visual Arts at Ulus Private Jewish High School."
    },
    MUHITTIN_ARIKAN: {
      TITLE: 'Muhittin Arıkan (1976 - )',
      TEXT: 'Muhittin Arıkan completed his primary and secondary education in Mersin. He finished primary school at İleri Elementary School and middle school at Mersin Municipal High School. Between 2008 and 2023, he worked as a visual arts teacher at a private institution in Kayseri. He continues his artistic activities at Çağrı Sanat Evi.'
    },
    ZLATA_SHYSHMAN: {
      TITLE: 'Zlata Shyshman (1995 -)',
      TEXT: "Zlata Shyshman was born on October 23, 1995 in Ishmael, Ukraine, in the family of Ukraine's well-known artist Ivan Shyshman. The impressionist artist generally works with oil painting technique in his works. Shyshman's works are included in various collections around the world and have been awarded many awards. She is a member of the National Union of Ukrainian Artists and currently, in parallel with her work, K.K. She teaches painting, painting and composition at Kostandi Art School."
    },
    REMZI_KARABULUT: {
      TITLE: 'Remzi Karabulut (1963 -)',
      TEXT: "The artist, who was born in Sarıkamış in 1963, is also a writer. He was interested in abstract painting for a while and painted the texts that influenced him. He opened three exhibitions with his paintings, two of which were personal. His articles have been published in many important publications such as Varlık, Çağdaş Türk Dili, Can Öykü Newspaper. Remzi Karabulut has been living in Tarsus since 1975.\n"
    },
    ELIF_SULE_SANLI: {
      TITLE: "Elif Şule Şanlı (2000 - )",
      TEXT: "Elif Şule Şanlı, born in 2000 in Ankara as the daughter of a painter mother, graduated from the psychology department of METU in 2023. Initially receiving art education from her mother and then from various painting workshops, she blended this training with psychological knowledge to create works featured in various exhibitions and fairs. Throughout her undergraduate life, she was actively involved in the field of \"art therapy\" and conducted painting analysis studies and art therapy workshops with clients at a private psychiatric hospital. She is currently continuing to produce as an interdisciplinary artist and works as an art consultant.\n"
    },
    HULYA_ELIBOL:{
      TITLE: "Hülya Elibol (1994 - )",
      TEXT: "Hülya Elibol, born in 1993 in Van, completed her primary and secondary education in Konya, and graduated in 2012 from Zübyde Hanım Girls' Technical Vocational and Anatolian High School with a specialization in graphics and photography. She continued her education at the Faculty of Fine Arts, Department of Painting, Selçuk University, from which she successfully graduated in 2018. After completing her art education, Elibol received pedagogical formation training at the Ahmet Keleşoğlu Faculty of Education, Necmettin Erbakan University. Displaying her artistic talents in 12 mixed exhibitions, Elibol is a continuously evolving, successful artist and educator in the fields of art and education."
    },
    ENSIEH_NAJARIAN:{
      TITLE: "Ensieh Najarian (1982 - )",
      TEXT: "Ensieh Najarian was born in 1982 in Hamadan. From her childhood, she was highly interested in learning and creating artistic works. While receiving her education, she won numerous certificates in Hamadan's artistic competitions. In 2001, she was admitted to university and later graduated with a Bachelor's Degree in Mathematics from the University of Tehran. After graduation, she dedicated herself to learning miniature painting (traditional Iranian drawing). She has created a new style that merges traditional and modern art. Najarian was selected as the first prize winner at several prestigious events, including the 5th International Fajr Visual Arts Festival, the 3rd Tolo Khordad Visual Arts Festival, and the 14th Imam Reza festival. She is currently successfully building her career as a designer, organizing Fashion and Art details."
    },
    SEVGI_AGANOGLU: {
      TITLE: "Sevgi Ağanoğlu (1966 - )",
      TEXT: "Sevgi Ağanoğlu, born in 1966, started her artistic journey during her middle school years with pencil drawing and has continued with oil painting. She completed her education in Public Relations and Advertising Management at Anadolu University and also received training in Project Writing and Execution at both the Politics Academy and Sakarya University. Her travels to different countries throughout her career have provided cultural insights that have inspired her paintings since 2010. The artist has recently left her role as General Coordinator in the software industry to pursue her art career professionally.\n"
    },
    CETIN_PIRECI: {
      TITLE: "Çetin Pireci (1960 - )",
      TEXT: "Çetin Pireci graduated from the Marmara University Faculty of Fine Arts, Department of Ceramics, after a successful educational process, and then obtained a master's degree in the Department of Ceramics-Glass at the Mimar Sinan University Fine Arts Institute in 1987. Throughout his art career, he has participated in more than eighty exhibitions and biennials, including six solo shows, making his name known on both national and international stages.\n"
    },
    ERDEM_BAYTEMUR: {
      TITLE: "Erdem Baytemur (1998 - ) ",
      TEXT: "Erdem Baytemur was born in Elazığ in 1998. After completing elementary and middle school there, he attended the Malatya Abdulkadir Eriş Fine Arts High School for 4 years as a boarding student. Then, he continued his education in the Department of Fine Arts at İnönü University and is currently continuing his work in Ankara.\n"
    },
    FILIZ_AKSU: {
      TITLE: "Filiz Aksu (1977 - ) ",
      TEXT: "Filiz Aksu was born in Istanbul in 1977 and received an education in interior design. She has worked in the industry as an interior designer for many years and has been a lecturer teaching architectural design courses for the last 8 years. Her interest in art dates back many years, and she has continuously developed herself in this field. In particular, she enjoys creating designs with acrylic paint on canvas with great pleasure and care. She has also had the opportunity to work on many pieces as a manuscript illuminator after receiving training in the art of illumination."
    },
    MEHMET_DEMIR: {
      TITLE: 'Mehmet Demir (1995 - )',
      TEXT: 'Born in 1995 in Gaziantep, they completed their high school education at the Gaziantep Fine Arts High School between 2009 and 2014. Between 2015 and 2019, they completed their undergraduate studies in the Painting Department of the Faculty of Fine Arts at Akdeniz University and won a place in the Painting Main Art branch of the Akdeniz University Institute of Fine Arts in 2019. They finished their master\'s degree in 2021 and continue to produce art in Antalya. In their work, compositions made up of forms and colors emerge as a stance against societal discord.'
    },
    MUGE_CUCU: {
      TITLE: 'Müge Cücü (1978 - )',
      TEXT: 'Müge Cücü was born in Istanbul and graduated from Anadolu University\'s Public Relations and Advertising department in 2002. Having been interested in the art of painting since her childhood, Cücü volunteered to teach painting classes in the Street Children Rehabilitation program at TEGV between 2003 and 2004. She settled in Antalya in 2005, where she received ceramic and painting training from proven experts in the field for four years. In addition to participating in many mixed and online exhibitions, Müge Cücü has illustrated a children\'s book and her collages and illustrations have been published in numerous magazines.'
    },
    FERHAT_ARAT: {
      TITLE: 'Ferhat Arat (1996 - )',
      TEXT: 'Ferhat Arat was born in Konya in 1996 and graduated from the Painting Department of the Faculty of Fine Arts at Akdeniz University between 2015 and 2019. He then completed the Master\'s Program in the Department of Painting at the Institute of Fine Arts of the same university from 2019 to 2021. Arat\'s works are featured in many private and state collections. He continues his art practice in his studio located in Antalya.'
    },
    YUSUF_DURAN: {
      TITLE: 'Yusuf Duran (1985 - )',
      TEXT: 'Yusuf Duran is an artist born in 1985 in Ankara. From his childhood, he has shown a great interest in the art of painting and has developed himself in this field. He began his education in Ankara, earned his undergraduate degree from Anadolu University in Eskişehir, and completed his master\'s degree in Business Administration at Atılım University in Ankara. Duran continues his professional career in a global company in the fields of interior architecture and design, while also engaging in artistic activities themed around sustainability and conducting academic research in business management. In 2020, he established his own workshop in Ankara and continues his professional painting career there, influenced by realism movements and adopting this style in his works.'
    },
    HAKAN_ESMER: {
      TITLE: 'Hakan Esmer (1973 - )',
      TEXT: 'Born in 1973 in the Vakfıkebir district of Trabzon, Hakan Esmer graduated in 1994 with a master\'s degree from Karadeniz Technical University and Anadolu University the same year. Since 1996, he has worked as a teaching assistant at Anadolu University, where he taught various art courses. Esmer has opened 37 personal exhibitions and won 15 awards, including international competitions. His works were included in international collections as part of a project run by the Ministry of Foreign Affairs of Turkey. In 2012, he left his academic position to continue his art in his own studio.'
    },
    IBRAHIM_MISIRLIOGLU: {
      TITLE: 'İbrahim Mısırlıoğlu (1959 - )',
      TEXT: 'Born in İzmit in 1959, İbrahim Mısırlıoğlu graduated from Marmara University Faculty of Fine Arts in 1985. He received his painting education under the guidance of masters such as Hüsamettin Koçan, Ergin İnan, and Mustafa Pilevneli. Mısırlıoğlu continues his art life in his workshop in Istanbul, where he continues to develop various aspects of his art.'
    },
    GULSAH_KURT: {
      TITLE: 'Gülşah Kurt (1995 - )',
      TEXT: 'Gülşah Kurt was born in Bilecik in 1995. She graduated from Bilecik Şeyh Edebali University, Faculty of Fine Arts and Design in 2023, achieving second place in the faculty and first place in the painting department. In addition to her university education, she also completed a Pedagogical Formation training. Influenced by the Abstract Expressionism movement in her works, she developed a unique style expressing herself freely using colors and various texture techniques. Art for her has been a realm encompassing all the colors of life.'
    },
    UGUR_PEKCAN: {
      TITLE: 'Uğur Pekcan (1992 - )',
      TEXT: 'Uğur Pekcan attended Hanife Şefik Elementary School in Çorlu from 1998 to 2003. He continued his secondary education at Uncular Süleyman Peker Middle School and then graduated from Tekirdağ Fine Arts and Sports High School. The artist completed his university education at the Faculty of Fine Arts at Trakya University, and later earned a master\'s degree from Tekirdağ Namık Kemal University\'s Faculty of Fine Arts and Architecture between 2016 and 2018. As a visual arts instructor, his personal exhibitions include the "Complex" exhibition held at the Siyah Beyaz Art Gallery in Edirne Karaağaç on May 2, 2016, and the "Tree of Life" exhibition opened at Trakya University Faculty of Fine Arts in Edirne Karaağaç on March 21, 2016.'
    },
    KIVANC_GULHAN: {
      TITLE: 'Kıvanç Gülhan (1963 - )',
      TEXT: 'Kıvanç Gülhan was born on February 15, 1963, and has lived in various cities. The artist, who received engineering education in Ankara, has opened 26 personal exhibitions. His works are found in private collections and in the Moseo Di Villa Vecchia in Rome. Parallel to his career in civil engineering, Gülhan, who is also involved in art, was a finalist in the Design Turkey \'05 Award. Gülhan, who teaches at the Faculties of Fine Arts, lives in Gaziantep where he has opened his 27th personal exhibition. The exhibition, organized by Gaziantep Metropolitan Municipality and Gaski on the occasion of "World Water Day", features works produced using the watercolor technique.'
    },
    ISIL_CALIK: {
      TITLE: 'Işıl Çalık (1965 - )',
      TEXT: 'Işıl Çalık was born in Ankara in 1965. Married with three children, she graduated from the Faculty of Language, History, and Geography at Ankara University in 1987. However, her passion for painting has always been a priority since her primary school days, which led her to pursue education in painting. Over time, she took courses from various schools and master painters. She has had the opportunity to participate in numerous mixed exhibitions. She has continued her art career for about 20 years as a painting instructor.'
    },
    YONCA_USTA: {
      TITLE: 'Yonca Usta (1992 - )',
      TEXT: 'Yonca Usta was born on September 30, 1992, in Trabzon. She graduated from the Fine Arts High School Painting Department in 2010. In 2017, she graduated from Yeditepe University with a degree in Plastic Arts, specializing in Painting, Sculpture, and Ceramics. She has 14 years of education in various fields of art, including painting. She worked as an assistant in Mustafa Ata\'s studio for many years. The first movement that influenced her was abstract expressionism, and her works are centered on original and modern art. She creates many collections and writes the special story of each collection in an introspective way. She expresses herself through the best and highest quality art. She has participated in many exhibitions, including her solo exhibition.'
    },
    ...test_auction_config_en.AUCTION_ARTISTS,
  },
};
