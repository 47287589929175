<div class="w-100 d-flex flex-column align-items-center justify-content-center">
  <div class="w-100 d-flex flex-row align-items-center justify-content-start px-3">
    <svg (click)="resolveChart('Line')"
         [class.d-none]="trade_chart_type === 'Line'" class="p-1 mr-4"
         style="height:25px; width: 30px; border-radius: 4px;">
      <use [attr.fill]="active_chart_type === 'TradeChart' ? '#8266f0' : '#9da5b7'" [attr.xlink:href]='"./assets/images/line_chart.svg#line_chart"' class="w-100"/>
    </svg>
    <svg (click)="resolveChart('Candlestick')" [class.d-none]="trade_chart_type === 'Candlestick'" class="p-1 mr-4"
         style="height:25px; width: 30px; border-radius: 4px;">
      <use [attr.fill]="active_chart_type === 'TradeChart' ? '#8266f0' : '#9da5b7'" [attr.xlink:href]='"./assets/images/candlestick_chart.svg#candlestick_chart"'/>
    </svg>
    <div (mouseenter)="trade_chart_dropdown_menu_minutes.classList.remove('d-none'); trade_chart_dropdown_button_minutes.classList.add('trade-chart-dropdown-button-hover')"
         (mouseleave)="trade_chart_dropdown_menu_minutes.classList.add('d-none'); trade_chart_dropdown_button_minutes.classList.remove('trade-chart-dropdown-button-hover')" class="mr-4">
      <button #trade_chart_dropdown_button_minutes (click)="trade_chart_dropdown_menu_minutes.classList.remove('d-none');
      trade_chart_dropdown_menu_hours.classList.add('d-none');"
              [class.trade-chart-dropdown-button-active]="['OneMin', 'FiveMins', 'FifteenMins', 'ThirtyMins'].includes(managerService.tradeHistoryService.zoom_level)"
              class="trade-chart-dropdown-button btn-native">
        {{managerService.config.ADVANCED.TRADE_CHART | managerPipe: managerService.mpa_template.RETURN_INDEX: min_selected}}
      </button>
      <div #trade_chart_dropdown_menu_minutes class="trade-chart-dropdown-menu d-none">
        <button (click)="adjustZoomLevel('OneMin'); min_selected='ONE_MIN'; trade_chart_dropdown_menu_minutes.classList.add('d-none');" class="trade-chart-dropdown-item btn-native">
          {{managerService.config.ADVANCED.TRADE_CHART.ONE_MIN | managerPipe}}
        </button>
        <button (click)="adjustZoomLevel('FiveMins'); min_selected='FIVE_MINS'; trade_chart_dropdown_menu_minutes.classList.add('d-none');" class="trade-chart-dropdown-item btn-native">
          {{managerService.config.ADVANCED.TRADE_CHART.FIVE_MINS | managerPipe}}
        </button>
        <button (click)="adjustZoomLevel('FifteenMins'); min_selected='FIFTEEN_MINS'; trade_chart_dropdown_menu_minutes.classList.add('d-none');" class="trade-chart-dropdown-item btn-native">
          {{managerService.config.ADVANCED.TRADE_CHART.FIFTEEN_MINS | managerPipe}}
        </button>
        <button (click)="adjustZoomLevel('ThirtyMins'); min_selected='THIRTY_MINS'; trade_chart_dropdown_menu_minutes.classList.add('d-none');" class="trade-chart-dropdown-item btn-native">
          {{managerService.config.ADVANCED.TRADE_CHART.THIRTY_MINS | managerPipe}}
        </button>
      </div>
    </div>
    <div (mouseenter)="trade_chart_dropdown_menu_hours.classList.remove('d-none'); trade_chart_dropdown_button_hours.classList.add('trade-chart-dropdown-button-hover')"
         (mouseleave)="trade_chart_dropdown_menu_hours.classList.add('d-none'); trade_chart_dropdown_button_hours.classList.remove('trade-chart-dropdown-button-hover')" class="mr-4">
      <button #trade_chart_dropdown_button_hours
              (click)="trade_chart_dropdown_menu_minutes.classList.add('d-none');
        trade_chart_dropdown_menu_hours.classList.remove('d-none');"
              [class.trade-chart-dropdown-button-active]="['OneHour', 'FourHours', 'TwelveHours'].includes(managerService.tradeHistoryService.zoom_level)"
              class="trade-chart-dropdown-button btn-native">
        {{managerService.config.ADVANCED.TRADE_CHART | managerPipe: managerService.mpa_template.RETURN_INDEX: hour_selected}}
      </button>
      <div #trade_chart_dropdown_menu_hours class="trade-chart-dropdown-menu d-none">
        <button (click)="adjustZoomLevel('OneHour'); hour_selected='ONE_HOUR'; trade_chart_dropdown_menu_hours.classList.add('d-none');" class="trade-chart-dropdown-item btn-native">
          {{managerService.config.ADVANCED.TRADE_CHART.ONE_HOUR | managerPipe}}
        </button>
        <button (click)="adjustZoomLevel('FourHours'); hour_selected='FOUR_HOURS'; trade_chart_dropdown_menu_hours.classList.add('d-none');" class="trade-chart-dropdown-item btn-native">
          {{managerService.config.ADVANCED.TRADE_CHART.FOUR_HOURS | managerPipe}}
        </button>
        <button (click)="adjustZoomLevel('TwelveHours'); hour_selected='TWELVE_HOURS'; trade_chart_dropdown_menu_hours.classList.add('d-none');" class="trade-chart-dropdown-item btn-native">
          {{managerService.config.ADVANCED.TRADE_CHART.TWELVE_HOURS | managerPipe}}
        </button>
      </div>
    </div>
    <button (click)="adjustZoomLevel('OneDay');
    trade_chart_dropdown_menu_minutes.classList.add('d-none');
    trade_chart_dropdown_menu_hours.classList.add('d-none');"
            [class.trade-chart-dropdown-button-active]="['OneDay'].includes(managerService.tradeHistoryService.zoom_level)"
            class="trade-chart-dropdown-button btn-native mr-4">
      {{managerService.config.ADVANCED.TRADE_CHART.DAY | managerPipe}}
    </button>
    <button (click)="adjustZoomLevel('SevenDays');
      trade_chart_dropdown_menu_minutes.classList.add('d-none');
      trade_chart_dropdown_menu_hours.classList.add('d-none');"
            [class.trade-chart-dropdown-button-active]="['SevenDays'].includes(managerService.tradeHistoryService.zoom_level)"
            class="trade-chart-dropdown-button btn-native mr-4">
      {{managerService.config.ADVANCED.TRADE_CHART.WEEK | managerPipe}}
    </button>
    <button (click)="adjustZoomLevel('ThirtyDays');
      trade_chart_dropdown_menu_minutes.classList.add('d-none');
      trade_chart_dropdown_menu_hours.classList.add('d-none');"
            [class.trade-chart-dropdown-button-active]="['ThirtyDays'].includes(managerService.tradeHistoryService.zoom_level)"
            class="trade-chart-dropdown-button btn-native">
      {{managerService.config.ADVANCED.TRADE_CHART.MONTHLY | managerPipe}}
    </button>

    <div class="d-flex flex-column align-items-center justify-content-center ml-auto">
      <div class="h-100 d-flex flex-row align-items-center justify-content-center">
        <svg (click)="resolveChart(toggleChartType())" class="p-1 ml-auto" style="height:25px; width: 30px;">
          <svg class="w-100" id="depth_chart" viewBox="0 0 818 706" xmlns="http://www.w3.org/2000/svg">
            <polygon [attr.fill]="active_chart_type === 'DepthChart' ? 'var(--secondary-colors-artiox-green-300)' : '#9da5b7'" points="424 706 818 706 818 0 424 706"/>
            <polygon [attr.fill]="active_chart_type === 'DepthChart' ? 'var(--secondary-colors-artiox-red-300)' : '#9da5b7'" points="394 706 0 706 0 0 394 706"/>
          </svg>
        </svg>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-center">
        <app-tooltip [direction]="managerService.tooltipService.tooltip_direction_template.BOTTOM | managerPipe" class="d-none position-relative" id="depth-chart-tooltip">
          {{managerService.config.TOOLTIP.ADVANCED.DEPTH_CHART_TOOLTIP | managerPipe}}
        </app-tooltip>
      </div>
    </div>

  </div>
  <div #trade_chart_container [class]="active_chart_type === 'DepthChart' ? 'd-none':'d-flex'" class="h-100 w-100 align-items-center justify-content-center position-relative">
    <div class="position-absolute" style="left: 15px; bottom: 25px; z-index: 100;">artiox.com</div>
    <div #trade_chart_container_overlay
         class="position-absolute d-flex flex-column align-items-center justify-content-center">
      <svg class="invisible" style="height:40px; width:40px; margin-right: 27px; margin-bottom: 15px;">
        <use [attr.xlink:href]='"./assets/images/artiox_icon.svg#artiox_icon"' class="h-100 w-100" fill="black"/>
      </svg>
    </div>
  </div>
  <div #depth_chart_container [class]="active_chart_type === 'TradeChart'? 'd-none':'d-flex'" class="h-100 w-100 align-items-center justify-content-center">
    <div #depth_chart_container_overlay
         class="position-absolute d-flex flex-column align-items-center justify-content-center">
      <svg class="d-none" style="height:40px; width:40px; margin-right: 27px; margin-bottom: 15px;">
        <use [attr.xlink:href]='"./assets/images/artiox_icon.svg#artiox_icon"' class="h-100 w-100" fill="black"/>
      </svg>
    </div>
  </div>
</div>
