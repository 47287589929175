<div class="d-flex flex-column "
     style="max-width: 1200px; width: calc(100vw - 240px);background-color: #ffffff; padding: 48px 64px 48px 64px; border-radius: 32px;border: 1px solid var(--secondary-colors-artiox-gray-base, #E2E2E2);box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.24), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);">
    <div class="w-100 d-flex flex-row align-items-center justify-content-center">
        <div [style]="managerService.active_auction_info_panel !== managerService.active_auction_info_panel_template.LAST_BIDS ? {'background': 'linear-gradient(to right, #8266F0, #f3f0fe)'} : {'background': 'linear-gradient(to left, #8266F0, #f3f0fe)'}" class="d-flex flex-row barlow body0 regular"
             style="padding: 1px; border-radius: 16px;cursor: pointer; height: 60px; ">
            <div (click)="managerService.active_auction_info_panel = managerService.active_auction_info_panel_template.ARTIST;"
                 [style]="managerService.active_auction_info_panel !== managerService.active_auction_info_panel_template.ARTIST ? { color: '#B5B5B5'} : { color: '#8266F0'}"
                 class="h-100 d-flex flex-column align-items-center justify-content-center text-center"
                 style="padding:8px 40px 8px 16px;background: #FFFFFF;border-top-left-radius: 16px;border-bottom-left-radius: 16px;">
                <div>
                    {{managerService.config.AUCTION.ARTIST | managerPipe}}
                </div>
            </div>

            <div (click)="managerService.active_auction_info_panel = managerService.active_auction_info_panel_template.LAST_BIDS;"
                 [style]="managerService.active_auction_info_panel !== managerService.active_auction_info_panel_template.LAST_BIDS ? { color: '#B5B5B5'} : { color: '#8266F0'}"
                 class="h-100 d-flex flex-column align-items-center justify-content-center text-center"
                 style="padding:8px 16px 8px 40px;background: #FFFFFF;border-bottom-right-radius: 16px;border-top-right-radius: 16px;">
                <div>
                    {{[managerService.bidService.bid_list_pipe | managerPipe: managerService.mpa_template.GET_LAST_BID_COUNT] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.AUCTION.BIDS}}
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="managerService.active_auction_info_panel | managerPipe: managerService.mpa_template.COMPARE: managerService.active_auction_info_panel_template.ARTIST"
         class="w-100 d-flex flex-column">
        <div class="exo header6 bold" style="color: #49566B; padding-top: 48px; padding-bottom: 16px">
            {{ managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_ARTIST_TITLE }}
        </div>
        <div class="barlow body1 regular" style="color: #49566B">
            {{ managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_ARTIST_TEXT : managerService.language_pipe}}
        </div>
    </div>

    <div *ngIf="managerService.active_auction_info_panel | managerPipe: managerService.mpa_template.COMPARE: managerService.active_auction_info_panel_template.LAST_BIDS"
         class="w-100 d-flex flex-column align-items-center mt-5">
        <div class="d-flex w-100 flex-row align-items-center justify-content-around exo header6 medium"
             style="color: var(--text-color-artiox-grey, #6f6f6f); max-width: 1440px">
            <span class="text-center" style="width: 15%">{{managerService.config.AUCTION.LAST_BIDS.BIDDER | managerPipe}}</span>
            <span class="text-center" style="width: 70%">{{managerService.config.AUCTION.LAST_BIDS.DATE | managerPipe}}</span>
            <span class="text-center" style="width: 15%">{{managerService.config.AUCTION.LAST_BIDS.PRICE | managerPipe}}</span>
        </div>
        <div class="d-flex w-100 flex-column align-items-center overflow-auto" style="min-height: 200px; max-height: 300px;">
            <div
                *ngFor="let bid of (managerService.bidService.bid_list_pipe | managerPipe: managerService.mpa_template.GET_LAST_BIDS)"
                class="d-flex flex-row align-items-center justify-content-around flex-wrap  flex-shrink-0 row-separator body1 barlow regular my-4 w-100"
                style=" background-color: #FFFFFF; color: var(--text-color-artiox-grey, #6f6f6f); max-width: 1440px">
                <span class="text-center " style="width: 15%">********</span>
                <span class="text-center " style="width: 70%">{{bid.creation_time | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_DATE_TIME}}</span>
                <span class="text-center " style="width: 15%; color: #66BB6A; font-weight: 700">{{bid.price | managerPipe: managerService.mpa_template.FANCY_NUMBER}} TL</span>
            </div>
        </div>
    </div>
</div>
