<div class="w-100 pt-3 px-3" style="background-color: #fcfdff;">
  <div class="mr-2 mb-1 pt-1" style="float: left">
    <svg style="height: 44px; width: 44px;">
      <use [attr.xlink:href]='"./assets/images/artiox_icon.svg#artiox_icon"' fill="var(--artiox-blue)"/>
    </svg>
  </div>
  <div [innerHTML]="managerService.config.CORPORATE.ABOUT.DESCRIPTION | managerPipe"
       style="font-family: Roboto, sans-serif; font-size: 12px; line-height: 1.6; color: #404352;">
  </div>
</div>
