<div [style]="managerService.show_sign_up_or_login | managerPipe: managerService.mpa_template.GET_MIN_BODY_HEIGHT_OBSOLETE" class="w-80 mx-auto pb-4">
    <div class="px-4" style=" border-radius: 4px; box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22);  background-color: #fcfdff;">
        <div class="pt-5 pb-3" style="font-family: bebas-neue-bold, sans-serif; font-size: 39px; letter-spacing: 4px; color: #404352;">
            {{managerService.config.LEGAL.HEADING | managerPipe: managerService.mpa_template.TO_LOCAL_UPPER_CASE}}
        </div>
    </div>

    <div class="d-flex flex-row py-5">
        <div class="w-33">
            <div (click)="active_path = legalRoutes[i]; managerService.navigationManager({url:'/legal/' +legalRoutes[i], current_component_index: 0})"
                 *ngFor="let text of managerService.config.LEGAL.TEXT_NAMES | managerPipe; let i = index"
                 [style]="{'background-color': (active_path === legalRoutes[i] ? '#eceef7' : '#fcfdff') | managerPipe}"
                 class="my-3 whitish-hover"
                 style="font-family: Roboto, sans-serif; border-radius: 4px; box-shadow: 1px 1px 1px 1px #dbdbdf;">
                <div class="ps-5 pe-3 py-4" style="font-size: 16px; color: #9da5b7;">{{text| managerPipe}}</div>
            </div>
        </div>
        <div class="w-50 px-5">
<!--            <div *ngFor="let text of managerService.config.LEGAL.TEXTS| managerPipe; let i = index"-->
<!--                 [class.d-none]="active_text_id !== i | managerPipe"-->
<!--                 class="px-5">-->
<!--                <div class="py-3" style="font-family: Roboto,sans-serif;font-size: 18px;font-weight: 500;letter-spacing: 0.9px;color: var(&#45;&#45;artiox-blue)">-->
<!--                    {{text.HEADING | managerPipe}}-->
<!--                </div>-->
<!--                <embed [src]="text.SRC | managerPipe: managerService.mpa_template.BYPASS_SECURITY_TRUST_RESOURCE_URL"-->
<!--                       style="width: 100%; min-height: 900px; " type="application/pdf"-->
<!--                />-->
<!--            </div>-->

            <app-legal-user-agreement-desktop *ngIf="active_path === LegalRoutes.USER_AGREEMENT | managerPipe"></app-legal-user-agreement-desktop>
            <app-legal-protection-law-policy-desktop *ngIf="active_path === LegalRoutes.PROTECTION_LAW_POLICY | managerPipe"></app-legal-protection-law-policy-desktop>
            <app-legal-information-security-policy-desktop *ngIf="active_path === LegalRoutes.INFORMATION_SECURITY_POLICY | managerPipe"></app-legal-information-security-policy-desktop>
            <app-legal-data-deletion-policy-desktop *ngIf="active_path === LegalRoutes.DATA_DELETION_POLICY | managerPipe"></app-legal-data-deletion-policy-desktop>
            <app-legal-kvkk-application-form-desktop *ngIf="active_path === LegalRoutes.KVKK_APPLICATION_FORM | managerPipe"></app-legal-kvkk-application-form-desktop>
            <app-legal-cookie-clarification-text-desktop *ngIf="active_path === LegalRoutes.COOKIE_CLARIFICATION_TEXT | managerPipe"></app-legal-cookie-clarification-text-desktop>
            <app-legal-kvkk-clarification-desktop *ngIf="active_path === LegalRoutes.KVKK_CLARIFICATION | managerPipe"></app-legal-kvkk-clarification-desktop>
            <app-legal-marketing-consent-desktop *ngIf="active_path === LegalRoutes.MARKETING_CONSENT | managerPipe"></app-legal-marketing-consent-desktop>
            <app-legal-foreign-consent-desktop *ngIf="active_path === LegalRoutes.FOREIGN_CONSENT | managerPipe"></app-legal-foreign-consent-desktop>
            <app-legal-distance-selling-contract-desktop *ngIf="active_path === LegalRoutes.DISTANCE_SELLING"></app-legal-distance-selling-contract-desktop>
            <app-legal-auction-cancellation-and-refund-agreement-desktop *ngIf="active_path === LegalRoutes.AUCTION_CANCELLATION"></app-legal-auction-cancellation-and-refund-agreement-desktop>
            <app-legal-auction-user-agreement-desktop *ngIf="active_path === LegalRoutes.AUCTION_USER_AGREEMENT"></app-legal-auction-user-agreement-desktop>
            <app-legal-auction-rules-desktop *ngIf="active_path === LegalRoutes.AUCTION_RULES"></app-legal-auction-rules-desktop>
            <app-legal-commissions-desktop *ngIf="active_path === LegalRoutes.COMMISSIONS"></app-legal-commissions-desktop>
            <app-legal-limits-desktop *ngIf="active_path === LegalRoutes.LIMITS"></app-legal-limits-desktop>
           </div>
    </div>
</div>
