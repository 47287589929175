import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren, ViewEncapsulation} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
  selector: 'app-operation-announcement-list-desktop',
  templateUrl: './operation-announcement-list-desktop.component.html',
  styleUrls: ['./operation-announcement-list-desktop.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class OperationAnnouncementListDesktopComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren('operation_announcement') operation_announcements: QueryList<ElementRef> = new QueryList();

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const i = this.managerService.navigationService.operation_announcement_active_index;
    if (i !== -1){
      setTimeout(() => {
        this.operation_announcements.get(i)?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      });
    }
  }

  ngOnDestroy(): void {
    for (let i = 0; i < this.managerService.config.ANNOUNCEMENTS.OPERATION_ANNOUNCEMENTS.OPERATION_LIST.length; i++){
      this.managerService.resetToggleText(i);
    }
  }

}
