import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {UserCryptoAccount} from '../../../../../../services/user.service';
import {Subscription} from 'rxjs';
import {ManagerService} from '../../../../../../services/manager.service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-withdrawal-cryptocurrency-mobile',
  templateUrl: './withdrawal-cryptocurrency-mobile.component.html',
  styleUrls: ['./withdrawal-cryptocurrency-mobile.component.css']
})
export class WithdrawalCryptocurrencyMobileComponent implements OnInit, AfterViewInit, OnDestroy {
  cryptocurrencyWithdrawalRequestForm = new UntypedFormGroup({
    amount: new UntypedFormControl(null, [Validators.required, Validators.min(50)]),
    address: new UntypedFormControl(null, [Validators.required]),
  });

  @ViewChild('crypto_account_dropdown_native_menu') crypto_account_dropdown_native_menu: any = undefined;

  public subscription_list: (Subscription | undefined)[] = [];

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.subscription_list.push(this.cryptocurrencyWithdrawalRequestForm.get('amount')?.valueChanges.subscribe(() => {
      if (this.managerService.userService.user_ready) {
        if (this.cryptocurrencyWithdrawalRequestForm.get('amount')?.value === null) {
          return;
        }
        const withdrawal_amount = this.managerService.checkCryptoWithdrawalAmount(this.cryptocurrencyWithdrawalRequestForm.get('amount')?.value, 1);
        if (this.cryptocurrencyWithdrawalRequestForm.get('amount')?.value > withdrawal_amount) {
          this.cryptocurrencyWithdrawalRequestForm.get('amount')?.setValue(withdrawal_amount);
        }
      }
    }));
    this.subscription_list.push(this.managerService.operationsService.cryptocurrency_withdrawal_request_form_submitted.subscribe(() => {
      this.submit();
    }));
  }

  selectCryptoAccount(crypto_account: UserCryptoAccount, force: boolean = false): void {
    this.managerService.operationsService.selectWithdrawCryptoAccount(crypto_account);
    this.managerService.operationsService.active_currency = this.managerService.pairService.currency_list[1];

    if (force) {
      this.closeCryptoAccountDropdownDisplay();
    } else {
      this.toggleCryptoAccountDropdownDisplay();
    }
  }

  closeCryptoAccountDropdownDisplay(): void {
    this.crypto_account_dropdown_native_menu.nativeElement.classList.add('d-none');
  }

  toggleCryptoAccountDropdownDisplay(): void {
    this.crypto_account_dropdown_native_menu.nativeElement.classList.toggle('d-none');
  }


  submit(): void {
    this.managerService.operationsService.signCurrencyWithdrawalRequest(this.managerService.popResponseSnackbar);
    this.cryptocurrencyWithdrawalRequestForm.get('amount')?.setValue(null);
  }

  ngOnDestroy(): void {
    this.managerService.operationsService.crypto_withdrawal_rules_accepted_status = false;
    this.subscription_list.forEach((subscription) => {
      subscription?.unsubscribe();
    });
  }
}
