<div [style]="managerService.show_sign_up_or_login | managerPipe: managerService.mpa_template.GET_MIN_BODY_HEIGHT_OBSOLETE"
     class="d-flex flex-column w-100 h-100 mx-3">
    <div class="w-100 d-flex flex-column"
         style="box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff; max-height: 150px; min-height: 150px; border-radius: 4px; margin-bottom: 10px;">
        <div class="my-3 ml-4"
             style="font-family: Roboto, sans-serif; font-size: 15px; line-height: 0.7; color: #404352;">
            {{managerService.userService.user?.email | managerPipe}}
        </div>
        <div class="my-2 ml-4"
             style="font-family: bebas-neue-regular, sans-serif;font-size: 40px; font-weight: bold; line-height: 1.2; letter-spacing: 4px; color: #404352;">
            {{managerService.config.USER_INFORMATION.HEADING | managerPipe}}
        </div>
        <div class="my-1 ml-4">
            <button (click)="managerService.active_user_information_panel = managerService.active_user_information_panel_template.PROFILE"
                    [class.btn-account-navigator-active]="managerService.active_user_information_panel | managerPipe: managerService.mpa_template.ACTIVE_BALANCE_PANEL_SUMMARY"
                    class="btn-native btn-account-navigator mr-5" type="button">{{managerService.config.USER_INFORMATION.PROFILE| managerPipe}}</button>
            <button (click)="managerService.active_user_information_panel = managerService.active_user_information_panel_template.AUCTION_ADDRESS"
                    [class.btn-account-navigator-active]="managerService.active_user_information_panel | managerPipe: managerService.mpa_template.ACTIVE_BALANCE_PANEL_ORDER_HISTORY"
                    class="btn-native btn-account-navigator mr-5" type="button">{{managerService.config.USER_INFORMATION.AUCTION_ADDRESS| managerPipe}}</button>
        </div>
    </div>

    <div
        *ngIf="(managerService.logged_in && (0 | managerPipe: managerService.mpa_template.CHECK_ACCOUNT_LEVEL_EQUALS_TO)) | managerPipe"
        class="px-4"
        style="box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22);  background-color: #fcfdff; min-height: 650px">
        <div class="pt-3 mb-2"
             style="font-family: Roboto, sans-serif; font-size: 20px; font-weight: bold; line-height: 2; letter-spacing: 1px; color: #4a4e70;">
            {{managerService.config.USER_INFORMATION.NO_KYC_HEADING | managerPipe}}
        </div>
        <div class="my-2" style="font-family: Roboto, sans-serif; font-size: 14px; color: #4a4e70;">
            {{managerService.config.USER_INFORMATION.NO_KYC_DESCRIPTION | managerPipe}}
        </div>

        <button
            (click)="managerService.navigationManager({url: '/account/security', active_security_panel: managerService.active_security_panel_template.IDENTITY_VERIFICATION, current_component_index: 0});"
            class="btn-native sign-up-login-button mx-4 my-3"
            style="height: 40px; width:160px; border-radius: 20px;
              font-family: Roboto, sans-serif; font-size: 16px; line-height: 1.3; letter-spacing: 0.3px; color: #ffffff;">
            {{managerService.config.DASHBOARD.LEVEL_UP | managerPipe}}
        </button>
    </div>

    <app-user-info-profile-desktop
        *ngIf="managerService.active_user_information_panel | managerPipe: managerService.mpa_template.ACTIVE_BALANCE_PANEL_SUMMARY"></app-user-info-profile-desktop>
    <app-user-info-auction-address-desktop
        *ngIf="managerService.active_user_information_panel | managerPipe: managerService.mpa_template.ACTIVE_BALANCE_PANEL_ORDER_HISTORY"></app-user-info-auction-address-desktop>
</div>
