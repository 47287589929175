<div class="barlow body1 medium" style="color: var(--text-color-artiox-grey,#6f6f6f)">
    <div style="width: 500px;">
        <div class="w-100 d-flex flex-column align-items-center justify-content-start">
            <div (dblclick)="managerService.photoSwipeOpen(managerService.auctionService.active_auction_product, current_slider_index)"
                 (swipeleft)="swipeLeft()" (swiperight)="swipeRight()" class="w-100 d-flex flex-row overflow-hidden">
                <div
                    *ngFor="let src of (managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_IMAGES)"
                    [style]="current_slider_index | managerPipe : managerService.mpa_template.SLIDER_BY_PIXEL: 500"
                    class="d-flex flex-column align-items-center justify-content-center"
                    style="transition: 0.4s ease-in-out; width: 500px; height: 500px;">
                    <img #auction_product
                         (error)="auction_product.src = auction_product.src.replace('.webp', '.jpg');"
                         alt="auction_product"
                         src="{{src}}"
                         style="width: 500px; height: 500px; pointer-events: none">
                </div>
            </div>

            <div *ngIf="slider_window > 1" class="d-flex flex-row align-items-center justify-content-center mb-4 mt-3" style="height: 15px;">
                <button (click)="current_slider_index = i"
                        *ngFor="let i = index; let pair of (managerService.auctionService.active_auction_product_pipe | managerPipe: managerService.mpa_template.ACTIVE_AUCTION_PRODUCT_IMAGES)"
                        [style]="current_slider_index | managerPipe: managerService.mpa_template.SLIDER_BUTTON: i"
                        aria-label="Slider" class="btn-native"
                        style="width: 15px; height: 15px; margin: 7px; border-radius: 1000px; background-color: #E2E2E2; transition: 0.3s ease-in-out;"></button>
            </div>
        </div>
    </div>

    <div class="d-flex flex-row justify-content-between" style="border-top: 1px solid #E2E2E2;padding-top: 16px">

        <div (click)="managerService.setUserFavoriteAuctionProduct(managerService.auctionService.active_auction_product)" class="me-4" style="cursor: pointer; user-select: none"
             [style.color]="managerService.userService.user?.isAuctionProductFavorite(managerService.auctionService.active_auction_product) ? '#FFC300' : '#6f6f6f'">
            <svg [style.fill]="managerService.userService.user?.isAuctionProductFavorite(managerService.auctionService.active_auction_product) ? '#FFC300' : '#FFF'" style="width: 24px; height: 24px; margin-top: -4px"
                 [style.stroke]="managerService.userService.user?.isAuctionProductFavorite(managerService.auctionService.active_auction_product) ? '#ffc300' : '#6f6f6f'"
                 >
                <use [attr.xlink:href]='"./assets/images/fav_star.svg#fav_star"'/>
            </svg>
            {{ managerService.config.AUCTION.FOLLOW | managerPipe }}
        </div>
        <div (click)="managerService.openModal('auction-share-modal')" style="cursor: pointer">
            <svg class="me-1" style="height: 24px; width: 24px; margin-top: -4px;">
                <use [attr.xlink:href]='"./assets/images/v2/ic_share.svg#ic_share"' fill="none" stroke="#6F6F6F"/>
            </svg>
            {{ managerService.config.AUCTION.SHARE.SHARE | managerPipe }}
        </div>

        <div #gavel (click)="scrollBids()" style="cursor: pointer">
            <svg class="me-1" style="height: 24px; width: 24px; margin-top: -4px;">
                <use [attr.xlink:href]='"./assets/images/gavel.svg#gavel"' fill="none" stroke="#6F6F6F"/>
            </svg>
            {{[managerService.bidService.bid_list_pipe | managerPipe: managerService.mpa_template.GET_LAST_BID_COUNT] | managerPipe: managerService.mpa_template.EVALUATE_STRING: managerService.config.AUCTION.BIDS}}
        </div>
    </div>
</div>
