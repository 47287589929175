import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ManagerService} from '../../../../../../services/manager.service';

@Component({
  selector: 'app-art-product-info-desktop',
  templateUrl: './art-product-info-desktop.component.html',
  styleUrls: ['./art-product-info-desktop.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ArtProductInfoDesktopComponent implements OnInit {

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

}
