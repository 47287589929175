<div class="d-flex justify-content-center" style="margin-bottom: 140px; margin-top: 72px">
    <div class="d-flex flex-column main-template " style=" gap: 64px; ">
        <div class="d-flex flex-column align-items-center gap-5">
        <span class="main-headers">
            {{ managerService.config.SUPPORT.SUPPORT_HEADER | managerPipe }}
        </span>

            <div class="d-flex flex-wrap justify-content-center" style="row-gap: 48px; column-gap: 30px;">
                @for (category of managerService.config.SUPPORT.CATEGORIES;track $index;) {
                    <app-support-card style="width: 300px"
                            (click)="navigateToCategory($index)"
                            [cardTitle]="category.HEADER"
                            [cardBody]="category.BODY">
                        <svg width="32px" height="32px">
                            <use [attr.xlink:href]="category.SVG_PATH" fill="none" stroke="white"/>
                        </svg>
                    </app-support-card>
                }
            </div>

        </div>
        <div class="d-flex flex-column align-items-center gap-5">
        <span class="main-headers">
            {{ managerService.config.SUPPORT.QUESTIONS_HEADER | managerPipe }}
        </span>
            <div class="d-flex flex-column" style="gap: 32px">
                @for (question of managerService.config.SUPPORT.QUESTIONS;track $index) {
                    <app-support-drawer [drawerHeader]="question.TITLE"
                                                [drawerText]="question.BODY"></app-support-drawer>

                }
            </div>
        </div>
        <div class="d-flex flex-column align-items-center gap-5 ">
            <span class="main-headers">
                {{managerService.config.SUPPORT.ALTERNATIVE_HEADER}}
            </span>

            <div class="d-flex flex-wrap justify-content-start " style="row-gap: 48px; column-gap: 30px;">
                @for (category of managerService.config.SUPPORT.ALTERNATIVE_SUPPORT;track $index) {
                    <app-support-card style="width: 300px"
                            [clickable]="false"
                            [cardTitle]="category.HEADER"
                            [cardBody]="category.BODY">
                        <svg width="32px" height="32px">
                            <use [attr.xlink:href]="category.SVG_PATH" fill="none" stroke="white"/>
                        </svg>
                    </app-support-card>
                }
            </div>
        </div>
    </div>
</div>
