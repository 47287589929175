<div class="d-flex flex-row align-items-center justify-content-around my-1 py-1" style="font-family: Roboto, sans-serif; font-size: 10px; color: #d9dbe4;">
  <span class="text-center" style="width: 33%">{{managerService.config.ADVANCED.TRADE_HISTORY.PRICE | managerPipe}}</span>
  <span class="text-center" style="width: 34%">{{managerService.config.ADVANCED.TRADE_HISTORY.AMOUNT | managerPipe}}</span>
  <span class="text-center" style="width: 33%">{{managerService.config.ADVANCED.TRADE_HISTORY.TIME | managerPipe}}</span>
</div>
<div class="d-flex flex-column overflow-auto" style="min-height: 160px; max-height: 340px;">
  <div *ngFor="let trade of (managerService.trade_history_pipe | managerPipe: managerService.mpa_template.GET_TRADE_HISTORY)"
       [style]="trade | managerPipe: managerService.mpa_template.GET_TRADE_HISTORY_BACKGROUND_COLOR" class="d-flex flex-row align-items-center justify-content-around py-1"
       style="font-family: Roboto, sans-serif; font-size: 10px; color: #d9dbe4; line-height: 1.2;">
    <span class="text-center" style="width: 33%">{{trade.price | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
    <span class="text-center" style="width: 34%">{{trade.amount | managerPipe: managerService.mpa_template.FANCY_NUMBER}}</span>
    <span class="text-center" style="width: 33%">{{trade.creation_time | managerPipe: managerService.mpa_template.FORMAT_EUROPEAN_TIME}}</span>
  </div>
</div>
