import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
  selector: 'app-user-agreement-desktop',
  templateUrl: './user-agreement-desktop.component.html',
  styleUrls: ['./user-agreement-desktop.component.css']
})
export class UserAgreementDesktopComponent implements OnInit {
    @Input() modal_id: string = '';
    @ViewChild('agreement_button') agreementButton: any;
    public checked: boolean = false;

    constructor(public managerService: ManagerService) {
    }

    ngOnInit(): void {
    }

    acceptUserAgreement(): void {
        new Promise((resolve, reject) => {
            this.managerService.userService.setUserAgreementAccepted(resolve, reject);
        }).then(() => {
            this.managerService.modalService.close(this.modal_id);
        });
    }

    rejectUserAgreement(): void {
        this.managerService.modalService.close(this.modal_id);
        // this.managerService.navigationManager({url: '/home', current_component_index: 0});
        this.managerService.logout();
    }

    checkBoxToggle(): void {
        this.checked = !this.checked;
        this.agreementButton.nativeElement.disabled = !this.checked;
    }

}
