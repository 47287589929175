import {Component, OnInit} from '@angular/core';
import {ManagerService} from '../../../../../services/manager.service';

@Component({
  selector: 'app-security-mobile',
  templateUrl: './security-mobile.component.html',
  styleUrls: ['./security-mobile.component.css']
})
export class SecurityMobileComponent implements OnInit {

  constructor(public managerService: ManagerService) {
  }

  ngOnInit(): void {
  }

}
