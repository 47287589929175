<div [style]="managerService.show_sign_up_or_login | managerPipe: managerService.mpa_template.GET_MIN_BODY_HEIGHT_OBSOLETE"
     class="d-flex flex-column w-100 h-100 mx-3">
    <div class="w-100 d-flex flex-column"
         style="box-shadow: 0 2px 9px 0 rgba(96, 134, 195, 0.22); background-color: #fcfdff; max-height: 150px; min-height: 150px; border-radius: 4px; margin-bottom: 10px;">
        <div class="my-3 ml-4"
             style="font-family: Roboto, sans-serif; font-size: 15px; line-height: 0.7; color: #404352;">
            {{managerService.userService.user?.email | managerPipe}}
        </div>
        <div class="my-2 ml-4"
             style="font-family: bebas-neue-regular, sans-serif;font-size: 40px; font-weight: bold; line-height: 1.2; letter-spacing: 4px; color: #404352;">
            {{managerService.config.DEPOSIT.HEADING | managerPipe}}
        </div>
        <div class="my-1 ml-4">
            <button (click)="managerService.navigationService.active_deposit_page = managerService.navigationService.deposit_page_template.TURKISH_LIRA"
                    [class.btn-account-navigator-active]="managerService.navigationService.active_deposit_page === managerService.navigationService.deposit_page_template.TURKISH_LIRA | managerPipe"
                    class="btn-native btn-account-navigator mr-5" type="button">{{managerService.config.DEPOSIT.BANK | managerPipe}}</button>
            <button (click)="managerService.navigationService.active_deposit_page = managerService.navigationService.deposit_page_template.TETHER; changeDepositCurrencySection(0)"
                    [class.btn-account-navigator-active]="managerService.navigationService.active_deposit_page === managerService.navigationService.deposit_page_template.TETHER | managerPipe"
                    class="btn-native btn-account-navigator mr-5" type="button">{{managerService.config.DEPOSIT.TETHER | managerPipe}}</button>
            <button (click)="managerService.navigationService.active_deposit_page = managerService.navigationService.deposit_page_template.ETHEREUM; changeDepositCurrencySection(1)"
                    [class.btn-account-navigator-active]="managerService.navigationService.active_deposit_page === managerService.navigationService.deposit_page_template.ETHEREUM | managerPipe"
                    class="btn-native btn-account-navigator mr-5" type="button">{{managerService.config.DEPOSIT.ETHEREUM | managerPipe}}</button>
        </div>
    </div>
    <app-deposit-fiat-desktop
        [class.d-none]="managerService.navigationService.active_deposit_page !== managerService.navigationService.deposit_page_template.TURKISH_LIRA | managerPipe"></app-deposit-fiat-desktop>
    <app-deposit-cryptocurrency-desktop
        [class.d-none]="managerService.navigationService.active_deposit_page !== managerService.navigationService.deposit_page_template.TETHER | managerPipe"></app-deposit-cryptocurrency-desktop>
    <app-deposit-ethereum-desktop
        [class.d-none]="managerService.navigationService.active_deposit_page !== managerService.navigationService.deposit_page_template.ETHEREUM | managerPipe"></app-deposit-ethereum-desktop>
</div>
