import {Injectable} from '@angular/core';
import {Delivery, User, UserInfo} from './user.service';
import {HttpClient} from '@angular/common/http';
import {Clipboard} from '@angular/cdk/clipboard';
import {ManagerService} from './manager.service';
import {environment} from '../../environments/environment';
import {FormGroup} from '@angular/forms';
import {UtilityService} from './utility.service';
import {Pair} from "./pair.service";

class UserSession {
    // user_id: number;
    platform: string;
    // session_key: string;
    // sid: string;

    constructor(user_session_primitive: any) {
        this.platform = user_session_primitive.platform;
    }
}

@Injectable({
    providedIn: 'root'
})
export class AdminService {

    public user_count: number = 0;
    public user_list: User[] = [];
    public user_index_list: number[] = [];
    public user_list_pipe: boolean = false;
    public user_info_list: UserInfo[] = [];
    public user_info_index_list: number[] = [];
    public user_info_list_pipe: boolean = false;

    public online_user_id_list: any = [];
    public online_user_session_map: Map<number, UserSession> = new Map();
    public user_search_list: any = [];

    public currency_list: any[] = [];
    public art_product_list: any[] = [];
    public pair_list: any[] = [];
    public currency_pair_list: any[] = [];

    public currency_withdrawal_request_list = [];
    public lower_currency_withdrawal_request_list = [];
    public higher_currency_withdrawal_request_list = [];
    public second_level_kyc_appeal_list = [];

    public order_book_status_list: any = [];
    public swap_order_book_status_list: any = [];
    public bid_engine_status: boolean = false;

    public active_delist_pair: Pair | undefined;
    public active_delist_pair_public_sale_price: number = 0;

    public show_online_users: boolean = false;
    public show_searched_users: boolean = false;
    public time_interval: any = undefined;

    public searched_user_raw: any;
    public searched_user_info_raw: any;
    public concatenated_name_list_raw: any[] = [];

    public selected_user: User = User.empty_user;
    public selected_user_info: UserInfo = UserInfo.empty_user_info;

    public user_search_result: any = undefined;
    public user_info_search_result: any = undefined;
    public concatenated_name_search_result: any = undefined;

    public delivery_list: Delivery[] = [];
    public delivery_list_pipe: boolean = false;

    public filtered_delivery_list: Delivery[] = [];
    public active_delivery_list: Delivery[] = [];

    public environment = environment;

    constructor(public managerService: ManagerService, private httpClient: HttpClient, private clipboard: Clipboard) {
    }

    getUserCount(): void {
        const data = {};
        this.httpClient.post<any>(this.environment.serverAPI + 'get_user_count_service', data).subscribe(
            response => {
                if (response.is_okay !== undefined && !response.is_okay) {
                    // @ts-ignore
                    const content = this.managerService.config.RESPONSE_TABLE[response.code];
                    this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                } else {
                    this.user_count = response.user_count;
                }
            }
        );
    }

    getUsersHelper(getUsersForm: any): void {
        if (getUsersForm.invalid) {
            // @ts-ignore
            const content = this.managerService.config.RESPONSE_TABLE['2003'];
            this.managerService.popSnackBar(content.is_okay, content.message);
            return;
        }

        const data = {
            start_index: getUsersForm.value.start_index,
            end_index: getUsersForm.value.end_index
        };

        this.httpClient.post<any>(this.environment.serverAPI + 'get_users_service', data).subscribe(
            response => {
                if (response.is_okay !== undefined && !response.is_okay) {
                    // @ts-ignore
                    const content = this.managerService.config.RESPONSE_TABLE[response.code];
                    this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                } else {
                    response.forEach((x: any) => {
                        const user = new User(x);
                        if (this.user_index_list.indexOf(user.id) !== -1) {
                            this.user_list[this.user_index_list.indexOf(user.id)] = user;
                        } else {
                            this.user_list.push(user);
                            this.user_index_list.push(user.id);
                        }
                    });
                }
            });
    }

    getUsersInfoHelper(getUsersForm: any): void {
        if (getUsersForm.invalid) {
            // @ts-ignore
            const content = this.managerService.config.RESPONSE_TABLE['2003'];
            this.managerService.popSnackBar(content.is_okay, content.message);
            return;
        }

        const data = {
            start_index: getUsersForm.value.start_index,
            end_index: getUsersForm.value.end_index
        };
        this.httpClient.post<any>(this.environment.serverAPI + 'get_users_info_service', data).subscribe(
            response => {
                if (response.is_okay !== undefined && !response.is_okay) {
                    // @ts-ignore
                    const content = this.managerService.config.RESPONSE_TABLE[response.code];
                    this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                } else {
                    response.forEach((x: any) => {
                        const user_info = new UserInfo(x);
                        if (this.user_info_index_list.indexOf(user_info.id) !== -1) {
                            this.user_info_list[this.user_info_index_list.indexOf(user_info.id)] = user_info;
                        } else {
                            this.user_info_list.push(user_info);
                            this.user_info_index_list.push(user_info.id);
                        }
                    });
                    this.user_info_list = this.user_info_list.concat(response.map((x: any) => new UserInfo(x)));
                }
            });
    }

    getUsersAndInfo(getUsersForm: any): void {
        this.getUsersHelper(getUsersForm);
        this.getUsersInfoHelper(getUsersForm);
    }

    getOnlineUsers(): void {
        const data = {};
        this.time_interval = setInterval(() => {
            // this.httpClient.post<any>(this.environment.serverAPI + 'get_live_clients_service', data).subscribe(
            this.httpClient.post<any>(this.environment.serverAPI + 'get_user_sessions_service', data).subscribe(
                response => {
                    if (response.is_okay !== undefined && !response.is_okay) {
                        // @ts-ignore
                        const content = this.managerService.config.RESPONSE_TABLE[response.code];
                        this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                        clearInterval(this.time_interval);
                    } else {
                        // this.online_user_id_list = response.live_client_id_list;
                        this.online_user_id_list = Object.keys(response).map(e => +e);
                        Object.entries(response).forEach(([key, value]) => {
                            this.online_user_session_map.set(+key, new UserSession(value));
                        });

                        this.getUsersAndInfoBulk(this.online_user_id_list);
                    }
                });
        }, 15000);
    }

    getCurrencies(): void {
        this.httpClient.get<any>(this.environment.serverAPI + 'get_currencies_service').subscribe(
            response => {
                this.currency_list = response;
            });
    }

    getArtProducts(): void {
        this.httpClient.get<any>(this.environment.serverAPI + 'get_art_products_service').subscribe(
            response => {
                this.art_product_list = response;
            });
    }

    getPairs(): void {
        this.httpClient.get<any>(this.environment.serverAPI + 'get_pairs_service').subscribe(
            response => {
                this.pair_list = response;
                this.order_book_status_list = [];
                this.pair_list.forEach((pair: any) => {
                    this.getOrderBookStatus(pair);
                });
            });
    }

    getCurrencyPairs(): void {
        this.httpClient.get<any>(this.environment.serverAPI + 'get_currency_pairs_service').subscribe(
            response => {
                this.currency_pair_list = response;
                this.swap_order_book_status_list = [];
                this.currency_pair_list.forEach((currency_pair: any) => {
                    this.getSwapOrderBookStatus(currency_pair);
                });
            });
    }

    getWithdrawalRequests(): void {
        const data = {};
        this.httpClient.post<any>(this.environment.serverAPI + 'get_currency_withdrawals_service', data).subscribe(
            response => {
                if (response.is_okay !== undefined && !response.is_okay) {
                    // @ts-ignore
                    const content = this.managerService.config.RESPONSE_TABLE[response.code];
                    this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                } else {
                    response.forEach((x: any) => {
                        x.creation_time = x.creation_time / 1000000;
                    });
                    this.currency_withdrawal_request_list = response;
                    this.lower_currency_withdrawal_request_list = this.currency_withdrawal_request_list.filter((e: any ) => e.amount < 10000);
                    this.higher_currency_withdrawal_request_list = this.currency_withdrawal_request_list.filter((e: any ) => e.amount >= 10000);

                    this.getUsersAndInfoBulk(this.currency_withdrawal_request_list.map((e: any) => e.user_id));
                }
            });
    }

    getDeliveries(): void {
        const data = {};
        this.httpClient.post<any>(this.environment.serverAPI + 'get_deliveries_service', data).subscribe(
            response => {
                this.delivery_list = response.map((x: any) => new Delivery(x)).reverse();
                this.filtered_delivery_list = this.delivery_list.filter((e: Delivery) => {return ![1, 15, 450].includes(e.user_id);});  // 66, 137 ?
                this.active_delivery_list = this.delivery_list;
                this.delivery_list_pipe = !this.delivery_list_pipe;
            });
    }

    toggleActiveDeliveryList(): void {
        if(this.active_delivery_list !== this.delivery_list){
            this.active_delivery_list = this.delivery_list;
        } else {
            this.active_delivery_list = this.filtered_delivery_list;
        }
        this.delivery_list_pipe = !this.delivery_list_pipe;
    }

    // getSecondLevelKYCAppeals(get_user_infos: boolean = true): void {
    getSecondLevelKYCAppeals(): void {
        const data = {};
        this.httpClient.post<any>(this.environment.serverAPI + 'get_second_level_kyc_appeals_service', data).subscribe(
            response => {
                if (response.is_okay !== undefined && !response.is_okay) {
                    // @ts-ignore
                    const content = this.managerService.config.RESPONSE_TABLE[response.code];
                    this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                } else {
                    this.second_level_kyc_appeal_list = response;
                    // if (get_user_infos) {
                    // this.second_level_kyc_appeal_list.forEach((e: any) => {
                    //     this.getUserInfo(e.id);
                    // });
                    this.getUsersAndInfoBulk(this.second_level_kyc_appeal_list.map((e: any) => e.id));
                    // }
                }
            });
    }

    getUserAvailableArtProductTypeOfRootCurrency(user: User): number {
        if (user !== undefined) {
            let available_art_product_type_of_root_currency_value = 0;
            for (const art_product_wallet of user.art_product_list) {
                available_art_product_type_of_root_currency_value = available_art_product_type_of_root_currency_value +
                    (art_product_wallet.available_amount * this.managerService.artProductToRootCurrencyMultiplier(art_product_wallet.art_product_id));
            }
            return available_art_product_type_of_root_currency_value;
        }
        return 0;
    }

    getUserAvailableCurrencyTypeOfRootCurrency(user: User): number {
        if (user !== undefined) {
            let available_currency_type_of_root_currency_value = 0;
            for (const currency_wallet of user.currency_list) {
                available_currency_type_of_root_currency_value = available_currency_type_of_root_currency_value +
                    (currency_wallet.available_amount * this.managerService.currencyToRootCurrencyMultiplier(currency_wallet.currency_id));
            }
            return available_currency_type_of_root_currency_value;
        }
        return 0;
    }

    getUserTotalArtProductTypeOfRootCurrency(user: User): number {
        if (user !== undefined) {
            let total_art_product_type_of_root_currency_value = 0;
            for (const art_product_wallet of user.art_product_list) {
                total_art_product_type_of_root_currency_value = total_art_product_type_of_root_currency_value +
                    (art_product_wallet.total_amount * this.managerService.artProductToRootCurrencyMultiplier(art_product_wallet.art_product_id));
            }
            return total_art_product_type_of_root_currency_value;
        }
        return 0;
    }

    getUserTotalCurrencyTypeOfRootCurrency(user: User): number {
        if (user !== undefined) {
            let total_currency_type_of_root_currency_value = 0;
            for (const currency_wallet of user.currency_list) {
                total_currency_type_of_root_currency_value = total_currency_type_of_root_currency_value +
                    (currency_wallet.total_amount * this.managerService.currencyToRootCurrencyMultiplier(currency_wallet.currency_id));
            }
            return total_currency_type_of_root_currency_value;
        }
        return 0;
    }

    getUserAssets(user: any): any {
        return [...user.currency_list, ...user.art_product_list];
    }

    copyCurrencyWithdrawalRequest(currency_withdrawal_request: any): void {
        this.clipboard.copy(currency_withdrawal_request.name + ' ' + currency_withdrawal_request.surname + '\n' + currency_withdrawal_request.tc_no + '\n'
            + currency_withdrawal_request.iban + '\n' + currency_withdrawal_request.bank + '\n' + (currency_withdrawal_request.amount - 4));  // -4 hardcoded ?
        this.managerService.popSnackBar(true, this.managerService.config.DEPOSIT.COPIED);
    }

    searchID(searchForm: any): void {
        this.user_search_list = [searchForm.get('id')?.value];
    }

    searchTCNO(searchForm: any): void {
        this.user_search_list = this.user_info_list
            .filter((x: any) => x.tc_no.startsWith(searchForm.get('tc_no')?.value)).map((x: any) => x.id);
    }

    searchConcatenatedNameField(searchForm: any): void {
        this.user_search_list = this.user_info_list
            .filter((x: any) => (x.name + x.surname).toLowerCase().startsWith(searchForm.get('concatenated_name_field')?.value.toLowerCase())).map((x: any) => x.id);
    }

    executeCurrencyDeposit(executeCurrencyDepositForm: any): void {
        if (executeCurrencyDepositForm.invalid) {
            // @ts-ignore
            const content = this.managerService.config.RESPONSE_TABLE['2003'];
            this.managerService.popSnackBar(content.is_okay, content.message);
            return;
        }

        const data = {
            user_id: this.selected_user.id,
            currency_id: executeCurrencyDepositForm.value.currency_id,
            operation_type: 'deposit',
            amount: executeCurrencyDepositForm.value.amount,
        };

        this.httpClient.post<any>(this.environment.serverAPI + 'execute_currency_deposit_service', data).subscribe(
            response => {
                // @ts-ignore
                const content = this.managerService.config.RESPONSE_TABLE[response.code];
                this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                if (response.is_okay) {
                    this.refreshUser(this.selected_user.id);
                }
            });
    }

    executeCurrencyWithdrawal(operation: any): void {
        const data = {
            operation_id: operation.id,
            currency_id: operation.currency_id,
            operation_type: 'withdrawal',
        };
        this.httpClient.post<any>(this.environment.serverAPI + 'execute_currency_withdrawal_service', data).subscribe(
            response => {
                // @ts-ignore
                const content = this.managerService.config.RESPONSE_TABLE[response.code];
                this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                this.getWithdrawalRequests();
            });
    }

    // approveSecondLevelKYCAppeal(second_level_kyc_appeal: any): void {
    //     const data = {
    //         user_id: second_level_kyc_appeal.id,
    //         status: 'verified'
    //     };
    //
    //     this.httpClient.post<any>(this.environment.serverAPI + 'execute_second_level_kyc_appeals_service', data).subscribe(
    //         response => {
    //             // @ts-ignore
    //             const content = this.managerService.config.RESPONSE_TABLE[response.code];
    //             this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
    //             this.getSecondLevelKYCAppeals();
    //         });
    // }
    //
    // rejectSecondLevelKYC(second_level_kyc_appeal: any): void {
    //     const data = {
    //         user_id: second_level_kyc_appeal.id,
    //         status: 'rejected'
    //     };
    //     this.httpClient.post<any>(this.environment.serverAPI + 'execute_second_level_kyc_appeals_service', data).subscribe(
    //         response => {
    //             // @ts-ignore
    //             const content = this.managerService.config.RESPONSE_TABLE[response.code];
    //             this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
    //             this.getSecondLevelKYCAppeals();
    //         });
    // }

    executeSecondLevelKYC(second_level_kyc_appeal: any, status: 'verified' | 'rejected'): void {
        const data = {
            user_id: second_level_kyc_appeal.id,
            status,
        };
        this.httpClient.post<any>(this.environment.serverAPI + 'execute_second_level_kyc_appeals_service', data).subscribe(
            response => {
                // @ts-ignore
                const content = this.managerService.config.RESPONSE_TABLE[response.code];
                this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                this.getSecondLevelKYCAppeals();

                this.managerService.marketingService.logEvent('second_level_kyc_executed', {status});
            });
    }

    adminDeposit(adminDepositForm: any): void {
        if (adminDepositForm.get('operation_class')?.value === 'Art Product') {
            const data = {
                user_id: adminDepositForm.value.user_id,
                art_product_id: adminDepositForm.value.collection_id,
                operation_type: 'deposit',
                amount: adminDepositForm.value.amount,
            };
            this.httpClient.post<JSON>(this.environment.serverAPI + 'art_product_deposit_service', data).subscribe(
                response => {
                    // @ts-ignore
                    const content = this.managerService.config.RESPONSE_TABLE[response.code];
                    this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                });
        } else if (adminDepositForm.get('operation_class')?.value === 'Currency') {
            const data = {
                user_id: adminDepositForm.value.user_id,
                currency_id: adminDepositForm.value.collection_id,
                operation_type: 'deposit',
                amount: adminDepositForm.value.amount,
            };
            this.httpClient.post<JSON>(this.environment.serverAPI + 'currency_deposit_service', data).subscribe(
                response => {
                    // @ts-ignore
                    const content = this.managerService.config.RESPONSE_TABLE[response.code];
                    this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                });
        }
    }

    adminWithdrawal(adminWithdrawalForm: any): void {
        if (adminWithdrawalForm.get('operation_class')?.value === 'Art Product') {
            const data = {
                user_id: adminWithdrawalForm.value.user_id,
                art_product_id: adminWithdrawalForm.value.collection_id,
                operation_type: 'withdrawal',
                amount: adminWithdrawalForm.value.amount,
            };
            this.httpClient.post<JSON>(this.environment.serverAPI + 'art_product_withdrawal_service', data).subscribe(
                response => {
                    // @ts-ignore
                    const content = this.managerService.config.RESPONSE_TABLE[response.code];
                    this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                });
        } else if (adminWithdrawalForm.get('operation_class')?.value === 'Currency') {
            const data = {
                user_id: adminWithdrawalForm.value.user_id,
                currency_id: adminWithdrawalForm.value.collection_id,
                operation_type: 'withdrawal',
                amount: adminWithdrawalForm.value.amount,
            };
            this.httpClient.post<JSON>(this.environment.serverAPI + 'currency_withdrawal_service', data).subscribe(
                response => {
                    // @ts-ignore
                    const content = this.managerService.config.RESPONSE_TABLE[response.code];
                    this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                });
        }
    }

    adminAddArtProduct(adminAddArtProductForm: any): void {
        const data = {
            name: adminAddArtProductForm.value.name,
            symbol: adminAddArtProductForm.value.symbol,
            value: adminAddArtProductForm.value.value,
            contract_address: adminAddArtProductForm.value.contract_address,
            circulating_supply_ratio: adminAddArtProductForm.value.circulating_supply_ratio,
            pre_sale_supply_ratio: adminAddArtProductForm.value.pre_sale_supply_ratio,
            total_supply: adminAddArtProductForm.value.total_supply,
            right_to_own_ratio: adminAddArtProductForm.value.right_to_own_ratio,
            color: adminAddArtProductForm.value.color,
            pre_sale_start_date: adminAddArtProductForm.value.pre_sale_start_date,
            pre_sale_end_date: adminAddArtProductForm.value.pre_sale_end_date,
            public_sale_start_date: adminAddArtProductForm.value.public_sale_start_date,
            public_sale_end_date: adminAddArtProductForm.value.public_sale_end_date,
            listing_date: adminAddArtProductForm.value.listing_date,
            featured: adminAddArtProductForm.value.featured,
            status: 'active'  // todo hardcoded
        };
        this.httpClient.post<JSON>(this.environment.serverAPI + 'add_art_product_service', data).subscribe(
            response => {
                // @ts-ignore
                const content = this.managerService.config.RESPONSE_TABLE[response.code];
                this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                this.getArtProducts();
            });
    }

    adminAddCurrency(adminAddCurrencyForm: any): void {
        const data = {
            name: adminAddCurrencyForm.value.name,
            symbol: adminAddCurrencyForm.value.symbol,
            value: adminAddCurrencyForm.value.value,
            color: adminAddCurrencyForm.value.color,
        };
        this.httpClient.post<JSON>(this.environment.serverAPI + 'add_currency_service', data).subscribe(
            response => {
                // @ts-ignore
                const content = this.managerService.config.RESPONSE_TABLE[response.code];
                this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                this.getCurrencies();
            });
    }

    adminAddAuctionProduct(adminAddAuctionProductForm: FormGroup): void {
        if (adminAddAuctionProductForm.invalid) {
            // @ts-ignore
            const content = this.managerService.config.RESPONSE_TABLE['2003'];
            this.managerService.popSnackBar(content.is_okay, content.message);
            return;
        }

        const data = {
            name: adminAddAuctionProductForm.value.name,
            year: adminAddAuctionProductForm.value.year,
            catalog: adminAddAuctionProductForm.value.catalog,
            lot: adminAddAuctionProductForm.value.lot,
            opening_value: adminAddAuctionProductForm.value.opening_value,
            current_value: adminAddAuctionProductForm.value.current_value,
            value_step: adminAddAuctionProductForm.value.value_step,
            value_type: adminAddAuctionProductForm.value.value_type,
            auction_start_date: adminAddAuctionProductForm.value.auction_start_date,
            auction_end_date: adminAddAuctionProductForm.value.auction_end_date,
            auction_init_end_date: adminAddAuctionProductForm.value.auction_init_end_date,
            end_date_increment: adminAddAuctionProductForm.value.end_date_increment,
            featured: adminAddAuctionProductForm.value.featured,
            assurance: adminAddAuctionProductForm.value.assurance,
            buyer_fee: adminAddAuctionProductForm.value.buyer_fee,
            seller_fee: adminAddAuctionProductForm.value.seller_fee,
            tax: adminAddAuctionProductForm.value.tax,
        };
        this.httpClient.post<JSON>(this.environment.serverAPI + 'add_auction_product_service', data).subscribe(
            response => {
                // @ts-ignore
                const content = this.managerService.config.RESPONSE_TABLE[response.code];
                this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
            });
    }

    queryUserByID(queryForm: any): void {
        this.getUserInfoByID(queryForm);
        const data = {user_id: queryForm.value.user_id};
        this.httpClient.post<any>(this.environment.serverAPI + 'refresh_user_service', data).subscribe(
            response => {
                if (response.is_okay !== undefined && !response.is_okay) {
                    // @ts-ignore
                    const content = this.managerService.config.RESPONSE_TABLE[response.code];
                    this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                } else {
                    const user = new User(response);
                    if (this.user_index_list.indexOf(user.id) !== -1) {
                        this.user_list[this.user_index_list.indexOf(user.id)] = user;
                    } else {
                        this.user_list.push(user);
                        this.user_index_list.push(user.id);
                    }
                    if (this.selected_user.id === user.id) {
                        this.selected_user = user;
                    }

                    this.user_info_search_result.nativeElement.classList.add('d-none');
                    this.concatenated_name_search_result.nativeElement.classList.add('d-none');

                    this.searched_user_raw = response;
                    this.user_search_result.nativeElement.classList.remove('d-none');

                    this.getUserInfo(user.id);
                }
            });
    }

    queryUserByConcatenatedNameField(queryForm: any): void {
        const data = {concatenated_name_field: queryForm.value.concatenated_name_field};
        this.httpClient.post<any>(this.environment.serverAPI + 'query_user_service', data).subscribe(
            response => {
                if (response.is_okay !== undefined && !response.is_okay) {
                    // @ts-ignore
                    const content = this.managerService.config.RESPONSE_TABLE[response.code];
                    this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                } else {
                    if (response.length === 0) {
                        // @ts-ignore
                        const content = this.managerService.config.RESPONSE_TABLE[2009];
                        this.managerService.popSnackBar(content.is_okay, content.message);
                        return;
                    }
                    const user_info = new UserInfo(response[0]);
                    if (this.user_info_index_list.indexOf(user_info.id) !== -1) {
                        this.user_info_list[this.user_info_index_list.indexOf(user_info.id)] = user_info;
                    } else {
                        this.user_info_list.push(user_info);
                        this.user_info_index_list.push(user_info.id);
                    }
                    if (this.selected_user_info.id === user_info.id) {
                        this.selected_user_info = new UserInfo(response[0]);
                    }
                    this.user_search_result.nativeElement.classList.add('d-none');
                    this.user_info_search_result.nativeElement.classList.add('d-none');

                    this.concatenated_name_list_raw = response;
                    this.concatenated_name_search_result.nativeElement.classList.remove('d-none');

                    this.refreshUser(user_info.id);
                }
            });
    }

    queryUserByReferenceCode(queryForm: any): void {
        const data = {
            deposit_reference_code: queryForm.value.deposit_reference_code
        };
        this.httpClient.post<any>(this.environment.serverAPI + 'find_user_service', data).subscribe(
            response => {
                if (response.is_okay !== undefined && !response.is_okay) {
                    // @ts-ignore
                    const content = this.managerService.config.RESPONSE_TABLE[response.code];
                    this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                } else {
                    const user = new User(response);
                    if (this.user_index_list.indexOf(user.id) !== -1) {
                        this.user_list[this.user_index_list.indexOf(user.id)] = user;
                    } else {
                        this.user_list.push(user);
                        this.user_index_list.push(user.id);
                    }
                    if (this.selected_user.id === user.id) {
                        this.selected_user = user;
                    }

                    this.user_info_search_result.nativeElement.classList.add('d-none');
                    this.concatenated_name_search_result.nativeElement.classList.add('d-none');

                    this.searched_user_raw = response;
                    this.user_search_result.nativeElement.classList.remove('d-none');

                    this.getUserInfo(user.id);
                }
            });
    }

    getUserInfoByID(queryForm: any): void {
        const data = {user_id: queryForm.value.user_id};
        this.httpClient.post<any>(this.environment.serverAPI + 'get_user_info_service', data).subscribe(
            response => {
                if (response.is_okay !== undefined && !response.is_okay) {
                    // @ts-ignore
                    const content = this.managerService.config.RESPONSE_TABLE[response.code];
                    this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                } else {
                    const user_info = new UserInfo(response);
                    if (this.user_info_index_list.indexOf(user_info.id) !== -1) {
                        this.user_info_list[this.user_info_index_list.indexOf(user_info.id)] = user_info;
                    } else {
                        this.user_info_list.push(user_info);
                        this.user_info_index_list.push(user_info.id);
                    }
                    if (this.selected_user_info.id === user_info.id) {
                        this.selected_user_info = new UserInfo(response);
                    }
                    this.user_search_result.nativeElement.classList.add('d-none');
                    this.concatenated_name_search_result.nativeElement.classList.add('d-none');

                    this.searched_user_info_raw = response;
                    this.user_info_search_result.nativeElement.classList.remove('d-none');

                    this.refreshUser(user_info.id);
                }
            });
    }


    refreshUser(user_id: number): void {
        const data = {user_id};
        this.httpClient.post<any>(this.environment.serverAPI + 'refresh_user_service', data).subscribe(
            response => {
                if (response.is_okay !== undefined && !response.is_okay) {
                    // @ts-ignore
                    const content = this.managerService.config.RESPONSE_TABLE[response.code];
                    this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                } else {
                    const user = new User(response);
                    if (this.user_index_list.indexOf(user.id) !== -1) {
                        this.user_list[this.user_index_list.indexOf(user.id)] = user;
                    } else {
                        this.user_list.push(user);
                        this.user_index_list.push(user.id);
                    }
                    if (this.selected_user.id === user.id) {
                        this.selected_user = user;
                    }
                    this.user_list_pipe = !this.user_list_pipe;

                    this.getUserInfo(user.id);
                }
            });
    }

    getUserInfo(user_id: number): void {
        const data = {user_id};
        this.httpClient.post<any>(this.environment.serverAPI + 'get_user_info_service', data).subscribe(
            response => {
                if (response.is_okay !== undefined && !response.is_okay) {
                    // @ts-ignore
                    const content = this.managerService.config.RESPONSE_TABLE[response.code];
                    this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                } else {
                    const user_info = new UserInfo(response);
                    if (this.user_info_index_list.indexOf(user_info.id) !== -1) {
                        this.user_info_list[this.user_info_index_list.indexOf(user_info.id)] = user_info;
                    } else {
                        this.user_info_list.push(user_info);
                        this.user_info_index_list.push(user_info.id);
                    }
                    if (this.selected_user_info.id === user_info.id) {
                        this.selected_user_info = user_info;
                    }
                    this.user_info_list_pipe = !this.user_info_list_pipe;
                }
            });
    }

    getUsersBulkHelper(id_list: number[]): void {
        const data = {id_list: UtilityService.dropDuplicate(id_list)};
        this.httpClient.post<any>(this.environment.serverAPI + 'get_users_bulk_service', data).subscribe(
            response => {
                if (response.is_okay !== undefined && !response.is_okay) {
                    // @ts-ignore
                    const content = this.managerService.config.RESPONSE_TABLE[response.code];
                    this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                } else {
                    for (const user_primitive of response) {
                        const user = new User(user_primitive);
                        if (this.user_index_list.indexOf(user.id) !== -1) {
                            this.user_list[this.user_index_list.indexOf(user.id)] = user;
                        } else {
                            this.user_list.push(user);
                            this.user_index_list.push(user.id);
                        }
                        if (this.selected_user.id === user.id) {
                            this.selected_user = user;
                        }
                    }
                    this.user_list_pipe = !this.user_list_pipe;
                }
            });
    }

    getUsersInfoBulkHelper(id_list: number[]): void {
        const data = {id_list: UtilityService.dropDuplicate(id_list)};
        this.httpClient.post<any>(this.environment.serverAPI + 'get_users_info_bulk_service', data).subscribe(
            response => {
                if (response.is_okay !== undefined && !response.is_okay) {
                    // @ts-ignore
                    const content = this.managerService.config.RESPONSE_TABLE[response.code];
                    this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                } else {
                    for (const user_info_primitive of response) {
                        const user_info = new UserInfo(user_info_primitive);
                        if (this.user_info_index_list.indexOf(user_info.id) !== -1) {
                            this.user_info_list[this.user_info_index_list.indexOf(user_info.id)] = user_info;
                        } else {
                            this.user_info_list.push(user_info);
                            this.user_info_index_list.push(user_info.id);
                        }
                        if (this.selected_user_info.id === user_info.id) {
                            this.selected_user_info = user_info;
                        }
                    }
                    this.user_info_list_pipe = !this.user_info_list_pipe;
                }
            });
    }

    getUsersAndInfoBulk(id_list: number[]): void {
        this.getUsersBulkHelper(id_list);
        this.getUsersInfoBulkHelper(id_list);
    }

    getDelivery(delivery_id: number): void {
        const data = {delivery_id};
        this.httpClient.post<any>(this.environment.serverAPI + 'get_delivery_service', data).subscribe(
            response => {
                if (response.is_okay !== undefined && !response.is_okay) {
                    // @ts-ignore
                    const content = this.managerService.config.RESPONSE_TABLE[response.code];
                    this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                } else {
                    for (let i = 0; i < this.delivery_list.length - 1; i++) {
                        if (this.delivery_list[i].delivery_id === delivery_id) {
                            this.delivery_list[i] = new Delivery(response);
                            break;
                        }
                    }
                    this.delivery_list_pipe = !this.delivery_list_pipe;
                }
            });
    }

    getOrderBookStatus(pair: any): void {
        const data = {
            pair_id: pair.id
        };
        this.httpClient.post<any>(this.environment.serverAPI + 'order_book_status_service', data).subscribe(
            response => {
                response.id = pair.id;
                this.order_book_status_list.push(response);
            });
    }

    getSwapOrderBookStatus(currency_pair: any): void {
        const data = {
            currency_pair_id: currency_pair.id
        };
        this.httpClient.post<any>(this.environment.serverAPI + 'swap_order_book_status_service', data).subscribe(
            response => {
                response.id = currency_pair.id;
                this.swap_order_book_status_list.push(response);
            });
    }

    getBidEngineStatus(): void {
        const data = {};
        this.httpClient.post<any>(this.environment.serverAPI + 'bid_engine_status_service', data).subscribe(
            response => {
                this.bid_engine_status = !response.blocked;
            });
    }

    blockOrderBook(pair: any, block: boolean): void {
        const data = {
            pair_id: pair.id, block
        };
        this.httpClient.post<JSON>(this.environment.serverAPI + 'block_order_book_service', data).subscribe(
            response => {
                // @ts-ignore
                const content = this.managerService.config.RESPONSE_TABLE[response.code];
                this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                this.getPairs();
            });
    }

    blockSwapOrderBook(currency_pair: any, block: boolean): void {
        const data = {
            currency_pair_id: currency_pair.id, block
        };
        this.httpClient.post<JSON>(this.environment.serverAPI + 'block_swap_order_book_service', data).subscribe(
            response => {
                // @ts-ignore
                const content = this.managerService.config.RESPONSE_TABLE[response.code];
                this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                this.getCurrencyPairs();
            });
    }

    blockBidEngine(block: boolean): void {
        const data = {
            block
        };
        this.httpClient.post<JSON>(this.environment.serverAPI + 'block_bid_engine_service', data).subscribe(
            response => {
                // @ts-ignore
                const content = this.managerService.config.RESPONSE_TABLE[response.code];
                this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                this.getBidEngineStatus();  // todo slow
            });
    }

    setActiveDelistPairBySymbol(symbol: any): void {
        const pair = this.pair_list.find(x => x.symbol === symbol);
        this.active_delist_pair = pair;
        const art_product = this.art_product_list.find(x => x.id === pair.id[0]);
        this.active_delist_pair_public_sale_price = art_product.total_supply * pair.public_sale_price;
    }

    delist(adminDelistForm: any): void {
        if(!this.active_delist_pair){
            // @ts-ignore
            const content = this.managerService.config.RESPONSE_TABLE['2003'];
            this.managerService.popSnackBar(content.is_okay, content.message);
            return;
        }

        if(this.active_delist_pair.sale_state !== 'listing'){
            this.managerService.popSnackBar(false, 'Sale state must be listing.');
            return;
        }

        if(this.getOrderBookBlocked(this.active_delist_pair)){
            this.managerService.popSnackBar(false, 'Order book of this pair is not blocked.');
            return;
        }

        const price = adminDelistForm.value.price;
        if (price < this.active_delist_pair_public_sale_price) { // todo
            this.managerService.popSnackBar(false, 'Delist price should not be lower than public sale price.');
            return;
        }

        const data = {pair_id: this.active_delist_pair.id, total_price: price};

        this.httpClient.post<JSON>(this.environment.serverAPI + 'delist_service', data).subscribe(
          response => {
              // @ts-ignore
              const content = this.managerService.config.RESPONSE_TABLE[response.code];
              this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
              this.getPairs();
          });
    }

    getOrderBookBlocked(_pair: any): any {
        for (const pair_status of this.order_book_status_list) {
            if (pair_status.id[0] === _pair.id[0] && pair_status.id[1] === _pair.id[1]) {
                return !pair_status.blocked;
            }
        }
        return true;
    }

    getSwapOrderBookBlocked(_currency_pair: any): any {
        for (const currency_pair_status of this.swap_order_book_status_list) {
            if (currency_pair_status.id[0] === _currency_pair.id[0] && currency_pair_status.id[1] === _currency_pair.id[1]) {
                return !currency_pair_status.blocked;
            }
        }
        return false;
    }

    changeDelivery(changeDeliveryForm: any): void {
        if (changeDeliveryForm.invalid) {
            // @ts-ignore
            const content = this.managerService.config.RESPONSE_TABLE['2003'];
            this.managerService.popSnackBar(content.is_okay, content.message);
            return;
        }

        const delivery_id = changeDeliveryForm.value.delivery_id;
        const user_id = changeDeliveryForm.value.user_id;
        const status = changeDeliveryForm.value.status;
        const company = changeDeliveryForm.value.company;
        const tracking_number = changeDeliveryForm.value.tracking_number;
        const tracking_link = changeDeliveryForm.value.tracking_link;
        const tracking_status = changeDeliveryForm.value.tracking_status;
        const estimated_arrival_time = changeDeliveryForm.value.estimated_arrival_time;

        const data = {
            delivery_id,
            user_id,
            status,
            company,
            tracking_number,
            tracking_link,
            tracking_status,
            estimated_arrival_time,
        };

        this.httpClient.post<JSON>(this.environment.serverAPI + 'update_delivery_status_service', data).subscribe(
            response => {
                // @ts-ignore
                const content = this.managerService.config.RESPONSE_TABLE[response.code];
                this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                if (content.is_okay) {
                    this.getDelivery(delivery_id);
                }
            });
    }

    provideSupply(currency: any, supply_stock: any): void {
        const data = {
            currency_id: currency.id, supply_stock
        };
        this.httpClient.post<JSON>(this.environment.serverAPI + 'provide_supply_service', data).subscribe(
            response => {
                // @ts-ignore
                const content = this.managerService.config.RESPONSE_TABLE[response.code];
                this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                this.getCurrencies();
            });
    }

    changeUserEmail(changeUserEmailForm: any): void {
        if (changeUserEmailForm.invalid) {
            // @ts-ignore
            const content = this.managerService.config.RESPONSE_TABLE['2003'];
            this.managerService.popSnackBar(content.is_okay, content.message);
            return;
        }

        const user_id = changeUserEmailForm.value.user_id;
        const old_email = changeUserEmailForm.value.old_email;
        const new_email = changeUserEmailForm.value.new_email;

        if (old_email === new_email && old_email === this.getCachedUser(user_id).email) {
            // @ts-ignore
            const content = this.managerService.config.RESPONSE_TABLE['2003'];
            this.managerService.popSnackBar(content.is_okay, content.message);
            return;
        }

        const data = {
            user_id,
            old_email,
            new_email,
        };

        this.httpClient.post<JSON>(this.environment.serverAPI + 'change_user_email_service', data).subscribe(
            response => {
                // @ts-ignore
                const content = this.managerService.config.RESPONSE_TABLE[response.code];
                this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                if (content.is_okay) {
                    this.refreshUser(user_id);
                    // this.getUserInfo(user_id);
                }
            });
    }

    changeUserInfo(changeUserInfoForm: any): void {
        if (changeUserInfoForm.invalid) {
            // @ts-ignore
            const content = this.managerService.config.RESPONSE_TABLE['2003'];
            this.managerService.popSnackBar(content.is_okay, content.message);
            return;
        }

        const user_id = changeUserInfoForm.value.user_id;
        const tc_no = changeUserInfoForm.value.tc_no;
        const name = changeUserInfoForm.value.name;
        const surname = changeUserInfoForm.value.surname;
        const birth_year = changeUserInfoForm.value.birth_year;
        const town = changeUserInfoForm.value.town;
        const address = changeUserInfoForm.value.address;
        const phone_number = changeUserInfoForm.value.phone_number;

        const data = {
            user_id,
            tc_no,
            name,
            surname,
            birth_year,
            town,
            address,
            phone_number,
        };

        this.httpClient.post<JSON>(this.environment.serverAPI + 'update_user_info_service', data).subscribe(
            response => {
                // @ts-ignore
                const content = this.managerService.config.RESPONSE_TABLE[response.code];
                this.managerService.popSnackBar(content.is_okay, this.managerService.codeHandler(response));
                if (content.is_okay) {
                    // this.refreshUser(user_id);
                    this.getUserInfo(user_id);
                }
            });
    }

    getCachedUser(user_id: any): any {
        user_id = parseInt(user_id, 10);
        if (this.user_index_list.indexOf(user_id) !== -1) {
            return this.user_list[this.user_index_list.indexOf(user_id)];
        } else {
            this.refreshUser(user_id);
            return undefined;
        }
    }

    getCachedUserInfo(user_id: any): any {
        user_id = parseInt(user_id, 10);
        if (this.user_info_index_list.indexOf(user_id) !== -1) {
            return this.user_info_list[this.user_info_index_list.indexOf(user_id)];
        } else {
            // this.getUserInfo(user_id);  // todo ??
            return undefined;
        }
    }

    getUserPlatform(user_id: number): string {
        if (this.online_user_session_map.has(user_id)){
            // tslint:disable-next-line:no-non-null-assertion
            return this.online_user_session_map.get(user_id)!.platform;
        } else {
            return 'logged out';
        }
    }

    clearCachedUsers(): any {
        this.user_list = [];
        this.user_index_list = [];
        this.user_info_list = [];
        this.user_info_index_list = [];

        this.getUsersAndInfoBulk(this.currency_withdrawal_request_list.map((e: any) => e.user_id));
        this.getUsersAndInfoBulk(this.second_level_kyc_appeal_list.map((e: any) => e.id));
    }

}
