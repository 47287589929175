<div class="d-flex flex-row align-items-center justify-content-between m-auto"
     style="height: 60px; width: calc(45% + 700px); min-width: 1200px">
    <div class="h-100 d-flex flex-row align-items-center justify-content-center">
        <svg class="mr-3" style="height:40px; width: 40px; min-width: 40px;">
            <use [attr.xlink:href]='"./assets/images/art_product_token.svg#art_product_token"' class="w-100"
                 fill="white"/>
        </svg>
        <span class="pr-3 text-center"
              style="font-family: 'Manrope', sans-serif; font-size: 42px; font-weight:bold; color: white;">
      {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SYMBOL: managerService.pairService.active_featured_pair_pipe}}
    </span>
        <div class="h-100 d-flex flex-column align-items-start justify-content-center" style="width: 220px;">
      <span style="font-family: Roboto, sans-serif; font-size: 16px; color: white;">
        "{{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_NAME: managerService.pairService.active_featured_pair_pipe}}
          "
      </span>
            <span style="font-family: Roboto, sans-serif; font-size: 16px; font-weight:bold; color: white;">
      {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_ARTIST_NAME: managerService.language_pipe: managerService.pairService.active_featured_pair_pipe}}
      </span>
        </div>
    </div>
    <div class="h-100 d-flex flex-row align-items-center justify-content-center"
         style="font-family: Roboto, sans-serif;font-size: 12px; font-weight: 500; line-height: 1.2; letter-spacing: 1.25px; color: #ffffff; width: 360px; ">
        <div
                *ngIf="(managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_CIRCULATING_SUPPLY_RATIO: managerService.pairService.active_featured_pair_pipe) !== 100"
                class="h-100 d-flex flex-column align-items-center justify-content-center m-1">
            <span>{{managerService.config.PRE_SALE.LOCKED | managerPipe}}</span>
            <div [style]="managerService.pair_pipe | managerPipe: managerService.mpa_template.LOCKED_SUPPLY_BAR: managerService.pairService.active_featured_pair_pipe"
                 class="locked-bar my-1"
                 style="height: 12px;"></div>
            <span>{{managerService.pair_pipe | managerPipe : managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_LOCKED_SUPPLY_RATIO: managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
                %</span>
        </div>
        <div
                *ngIf="(managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_SUPPLY_RATIO: managerService.pairService.active_featured_pair_pipe) !== 0"
                class="h-100 d-flex flex-column align-items-center justify-content-center m-1">
            <span>{{managerService.config.PRE_SALE.PRE_SALE | managerPipe}}</span>
            <div
                    *ngIf="(managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_CIRCULATING_SUPPLY_RATIO: managerService.pairService.active_featured_pair_pipe) !== 100"
                    [style]="managerService.pair_pipe | managerPipe: managerService.mpa_template.PRE_SALE_SUPPLY_BAR: managerService.pairService.active_featured_pair_pipe"
                    class="pre-sale-center-bar my-1"
                    style="height: 12px;"></div>
            <div
                    *ngIf="(managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_CIRCULATING_SUPPLY_RATIO: managerService.pairService.active_featured_pair_pipe) === 100"
                    [style]="managerService.pair_pipe | managerPipe: managerService.mpa_template.PRE_SALE_SUPPLY_BAR: managerService.pairService.active_featured_pair_pipe"
                    class="pre-sale-left-bar  my-1"
                    style="height: 12px;"></div>
            <span>{{managerService.pair_pipe | managerPipe : managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PRE_SALE_SUPPLY_RATIO: managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
                %</span>
        </div>
        <div class="h-100 d-flex flex-column align-items-center justify-content-center m-1">
            <span>{{managerService.config.PRE_SALE.PUBLIC_SALE | managerPipe}}</span>
            <div [style]="managerService.pair_pipe | managerPipe: managerService.mpa_template.PUBLIC_SALE_SUPPLY_BAR: managerService.pairService.active_featured_pair_pipe"
                 class="public-sale-bar my-1"
                 style="height: 12px;"></div>
            <span>{{managerService.pair_pipe | managerPipe : managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_PUBLIC_SALE_SUPPLY_RATIO: managerService.pairService.active_featured_pair_pipe | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
                %</span>
        </div>
    </div>

    <div class="h-100 d-flex flex-column align-items-center justify-content-center" style="width: 260px;">
      <span class="pr-1 text-center"
            style="font-family: Roboto, sans-serif; font-size: 14px; font-weight: 500; color: #e2e3e7; width: 250px;">
        {{managerService.pair_pipe | managerPipe: managerService.mpa_template.TOKEN_LEFT_TITLE: managerService.language_pipe: managerService.pairService.active_featured_pair_pipe: managerService.art_product_pipe}}
      </span>
        <span class="text-center"
              style="font-family: bebas-neue-regular, sans-serif; font-size: 18px; font-weight:bold; color: white; width: 250px; letter-spacing: 1px;">
        {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_TOKEN_LEFT: managerService.pairService.active_featured_pair_pipe: managerService.art_product_pipe | managerPipe: managerService.mpa_template.FANCY_NUMBER}}
            <span
                    class="mx-1">{{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_FEATURED_ART_PRODUCT_SYMBOL: managerService.pairService.active_featured_pair_pipe}}</span>
      </span>
    </div>
</div>


<div *ngIf="(managerService.pairService.pair_list_ready && 1 < (managerService.pair_pipe | managerPipe: managerService.mpa_template.GET_NOT_LISTED_PAIR_LIST).length) | managerPipe"
     class="w100 d-flex flex-row justify-content-start align-items-center"
     style="height: 50px; border-top: 1px solid #272a3b;">
    <div class="w-33 text-end pe-5"
         style="font-family: bebas-neue-bold, sans-serif; font-size: 27px; letter-spacing: 2.75px; color: #d5d8e2;">
        {{managerService.config.PRE_SALE.PUBLIC_SALE_ARTWORKS | managerPipe}}
    </div>
    <div class="w-33 d-flex flex-row align-items-center justify-content-center ms-5">
        <div *ngFor="let pair of managerService.pair_pipe | managerPipe: managerService.mpa_template.GET_NOT_LISTED_PAIR_LIST; let i = index;"
             class="d-flex flex-row" style="cursor: pointer">
            <div (click)="managerService.navigateIfLoggedIn({url: '/presale', active_featured_pair_id: pair.id, current_component_index: 0})"
                 [style.opacity]="((pair | managerPipe: managerService.mpa_template.CHECK_ACTIVE_FEATURED_PAIR: managerService.pairService.active_featured_pair_pipe) ? 1 : 0.3) | managerPipe"
                 class="px-5"
                 style="font-family: bebas-neue-bold, sans-serif; font-size: 27px; letter-spacing: 2.75px; color: #d5d8e2;">
                {{pair.id[0] | managerPipe: managerService.mpa_template.ART_PRODUCT_SYMBOL}}
            </div>
            <div [class.d-none]="((managerService.pair_pipe | managerPipe: managerService.mpa_template.GET_NOT_LISTED_PAIR_LIST).length === i+1) | managerPipe"
                 style="width: 0px; height: 36px; border-right: solid 1px #d5d8e2; opacity: 0.3;"></div>
        </div>
    </div>
</div>

