<div class="h-100 d-flex flex-row align-items-center justify-content-around m-auto">
  <div class="h-100 d-flex flex-row align-items-center justify-content-center">
    <svg class="mr-1" style="height:40px; width: 40px; min-width: 40px;">
      <use [attr.xlink:href]='"./assets/images/art_product_token.svg#art_product_token"' class="w-100" fill="#8266F0"/>
    </svg>
    <span class="pr-3 text-center" style="font-family: 'Manrope', sans-serif; font-size: 50px; font-weight:bold; color: #8266F0;">
      {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_SYMBOL}}
    </span>
    <div class="h-100 d-flex flex-column align-items-start justify-content-center" s>
      <span class="text-center exo body1 medium" style="color: #37324D;">
        "{{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_NAME}}"
      </span>
      <span class="text-center barlow body1 bold" style=" color: #37324D;">
      {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_ARTIST_NAME}}
      </span>
    </div>
  </div>

  <div class="h-100 d-flex flex-column align-items-center justify-content-center pt-1 pr-3" >
    <div >
      <span class="text-center exo body2 bold" style="color: #37324D;">
        {{managerService.config.BASIC.PAIR_HEADER.LAST_PRICE | managerPipe}}
      </span>
      <div class="d-flex flex-row align-items-center justify-content-center">
        <span class="pr-1 text-center barlow body2 medium"
              style=" color: #6f6f6f; ">
        1 {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_ART_PRODUCT_SYMBOL}}:
        </span>
        <span
          [style]="managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_CHANGE | managerPipe: managerService.mpa_template.PAIR_CHANGE_COLOR"
          class="pr-1 text-center barlow body2 medium"
          style="">
        {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_PRICE}}
        </span>
        <span class="text-center barlow body2 medium" style=" color: #6f6f6f;">
        {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_CURRENCY_SYMBOL}}
        </span>
      </div>
    </div>
    <div class="w-100 d-flex flex-row align-items-center justify-content-center">
      <app-tooltip [direction]="managerService.tooltipService.tooltip_direction_template.BOTTOM | managerPipe" class="d-none position-relative" id="active-pair-price-tooltip" max_width="150px">
        {{managerService.config.TOOLTIP.ADVANCED.ACTIVE_PAIR_PRICE_TOOLTIP | managerPipe}}
      </app-tooltip>
    </div>
  </div>

  <div class="h-100 d-flex flex-column align-items-center justify-content-center pt-1">
    <div>
        <span class="text-center exo body2 bold" style="color: #37324D;">
          {{managerService.config.BASIC.PAIR_HEADER.CHANGE_24_HOUR_DESKTOP | managerPipe}}
        </span>
      <div class="d-flex flex-row align-items-center justify-content-center">
          <span
            [style]="managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_CHANGE | managerPipe: managerService.mpa_template.PAIR_CHANGE_COLOR"
            class="pr-1 text-center barlow body2 medium">
          {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_CHANGE}}%
          </span>
      </div>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-center">
      <app-tooltip [direction]="managerService.tooltipService.tooltip_direction_template.BOTTOM | managerPipe" class="d-none position-relative" id="active-pair-change-tooltip" max_width="100px">
        {{managerService.config.TOOLTIP.ADVANCED.ACTIVE_PAIR_CHANGE_TOOLTIP | managerPipe}}
      </app-tooltip>
    </div>
  </div>

  <div class="h-100 d-flex flex-column align-items-center justify-content-center pt-1">
    <div>
      <span class="text-center exo body2 bold"
            style="color: #37324D; ">
      {{managerService.config.BASIC.PAIR_HEADER.VOLUME_24_HOUR_DESKTOP | managerPipe}}
    </span>
      <div class="d-flex flex-row align-items-center justify-content-center">
      <span class="pr-1 text-center barlow body2 medium" style=" color: #6f6f6f;">
        {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_VOLUME}}
      </span>
        <span class="text-center barlow body2 medium"
              style="color: #6F6F6F">
          {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_CURRENCY_SYMBOL}}
        </span>
      </div>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-center">
      <app-tooltip [direction]="managerService.tooltipService.tooltip_direction_template.BOTTOM | managerPipe" class="d-none position-relative" id="active-pair-volume-tooltip" max_width="150px">
        {{managerService.config.TOOLTIP.ADVANCED.ACTIVE_PAIR_VOLUME_TOOLTIP | managerPipe}}
      </app-tooltip>
    </div>
  </div>

  <div class="h-100 d-flex flex-column align-items-center justify-content-center pt-1">
    <div>
      <span class="text-center exo body2 bold"
            style="color: #37324D; ">
      {{managerService.config.ADVANCED.HIGH_24_HOUR | managerPipe}}
    </span>
      <div class="d-flex flex-row align-items-center justify-content-center">
      <span class="pr-1 text-center barlow body2 medium" style=" color: #6f6f6f;">
        {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_HIGH}}
      </span>
        <span class="text-center barlow body2 medium"
              style="color: #6F6F6F">
          {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_CURRENCY_SYMBOL}}
        </span>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center justify-content-center" style="margin-left: -30px">
      <app-tooltip [direction]="managerService.tooltipService.tooltip_direction_template.RIGHT | managerPipe" class="d-none position-relative" id="active-pair-max-tooltip">
        {{managerService.config.TOOLTIP.ADVANCED.ACTIVE_PAIR_MIN_MAX_TOOLTIP | managerPipe}}
      </app-tooltip>
    </div>
  </div>

  <div class="h-100 d-flex flex-column align-items-center justify-content-center pt-1">
    <div>
      <span class="text-center exo body2 bold"
            style="color: #37324D; ">
      {{managerService.config.ADVANCED.LOW_24_HOUR | managerPipe}}
    </span>
      <div class="d-flex flex-row align-items-center justify-content-center">
      <span class="pr-1 text-center barlow body2 medium" style=" color: #6f6f6f;">
        {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_PAIR_LOW}}
      </span>
        <span class="text-center barlow body2 medium"
              style="color: #6F6F6F">
          {{managerService.pair_pipe | managerPipe: managerService.mpa_template.ACTIVE_CURRENCY_SYMBOL}}
        </span>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center justify-content-center" style="margin-left: -30px">
      <app-tooltip [direction]="managerService.tooltipService.tooltip_direction_template.RIGHT | managerPipe" class="d-none position-relative" id="active-pair-min-tooltip">
        {{managerService.config.TOOLTIP.ADVANCED.ACTIVE_PAIR_MIN_MAX_TOOLTIP | managerPipe}}
      </app-tooltip>
    </div>
  </div>


</div>
